import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Link } from 'src/app/core/models/headers/link.model';

@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss']
})
export class ClientHeaderComponent {
  @Input() links!: Link[];
  @Input() selectedLink!: Link
  @Output() selected: EventEmitter<Link> = new EventEmitter<Link> ()

  handleClick(link: Link) {
    this.selected.emit(link)
  }
}
