import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-select-segment',
  templateUrl: './select-segment.component.html',
  styleUrls: ['./select-segment.component.scss']
})
export class SelectSegmentComponent {
  @Output() segmentChange: EventEmitter<string> = new EventEmitter<string>();

  public selectedSegment: string = 'TODOS LOS PLANES';
  public arraySegment: string[] = ['TODOS LOS PLANES', 'BUSINESS', 'INDIVIDUAL', 'FREELANCE'];

  OnSegmentChange(segmentId: string): void {
    this.selectedSegment = segmentId;
    this.segmentChange.emit(segmentId);
  }
}