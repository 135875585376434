import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterParams } from 'src/app/core/models/filters/filters.model';
import { ActivityListMockService } from 'src/app/core/services/selects/activityLists';

@Component({
  selector: 'app-filters-business',
/*   standalone: true,
  imports: [], */
  templateUrl: './filters-business.component.html',
  styleUrl: './filters-business.component.scss'
})
export class FiltersBusinessComponent {
  public params = new HttpParams();
  public filterForm!: FormGroup;
  public cnaeList: any;
  public maxDate = new Date();
  @Output() filtersEvent = new EventEmitter<FilterParams>();

  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, private cnae: ActivityListMockService){
    this.filterForm = this.formBuilder.group({
      createdFrom: [],
      createdTo: [],
      cnae: []
    })
    this.cnae.cnaeSelectList().subscribe(res => {
      this.cnaeList = res;
    })
  }

  ngOnInit() {
   this.filterForm.valueChanges.subscribe(res => {
    this.onSubmit()
   })
  }

  onSubmit(){

    const dataFromForm = {
      createdFrom: this.datePipe.transform(this.filterForm.get('createdFrom')?.value, 'yyyy-MM-ddTHH:mm:ss'),
      createdTo: this.datePipe.transform(this.filterForm.get('createdTo')?.value, 'yyyy-MM-ddTHH:mm:ss'),
      cnae: this.filterForm.get('cnae')?.value
    }

    const filtersToSend: FilterParams = {}
    dataFromForm.createdFrom ? filtersToSend.createdFrom = dataFromForm.createdFrom : null;
    dataFromForm.createdTo ? filtersToSend.createdTo = dataFromForm.createdTo : null;
    dataFromForm.cnae ? filtersToSend.cnae = dataFromForm.cnae : null;
    this.filtersEvent.emit(filtersToSend)
  }

  resetAll() {
    this.filterForm.reset()
  }

}
