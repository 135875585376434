import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Coin } from '../../models/selects/selects';

@Injectable({
  providedIn: 'root',
})
export class ActivityListMockService {
  public iaeSelectList(): Observable<any> {
    const iaeList = [
      {
        "code": "1.011",
        "name": "EXPLOTACION EXTENSIVA GANADO BOVINO"
      },
      {
        "code": "1.012",
        "name": "EXPLOTAC. INTENSI.GANADO BOVINO DE LECHE"
      },
      {
        "code": "1.013",
        "name": "EXPLOT.INTENSIVA GANADO BOVINO DE CEBO"
      },
      {
        "code": "1.021",
        "name": "EXPLOTACION EXTENSIVA DE GANADO OVINO"
      },
      {
        "code": "1.022",
        "name": "EXPLOT.INTENSIV.GANADO OVINO DE CRIA"
      },
      {
        "code": "1.023",
        "name": "EXPLOTAC.INTENSIVA GANADO OVINO DE CEBO"
      },
      {
        "code": "1.024",
        "name": "EXPLOTACION DE GANADO CAPRINO"
      },
      {
        "code": "1.031",
        "name": "EXPLOTACION EXTENSIVA GANADO PORCINO"
      },
      {
        "code": "1.032",
        "name": "EXPLOTAC.INTENSIVA GANADO PORCINO CRIA"
      },
      {
        "code": "1.033",
        "name": "EXPLOTAC. INTENSIVA GANADO PORCINO CEBO"
      },
      {
        "code": "1.041.1",
        "name": "REPRODUCTORAS DE PUESTA"
      },
      {
        "code": "1.041.2",
        "name": "PONEDORAS HUEVOS A PARTIR CUATRO MESES"
      },
      {
        "code": "1.042.1",
        "name": "REPRODUCTORAS DE CARNE"
      },
      {
        "code": "1.042.2",
        "name": "POLLOS Y PATOS PARA CARNE"
      },
      {
        "code": "1.042.3",
        "name": "PAVOS, FAISANES Y PALMIPEDAS_REPRODUCTOR"
      },
      {
        "code": "1.042.4",
        "name": "PAVOS, FAISANES Y PALMIPEDAS PARA CARNE"
      },
      {
        "code": "1.042.5",
        "name": "CODORNICES PARA CARNE"
      },
      {
        "code": "1.051",
        "name": "CUNICULTURA"
      },
      {
        "code": "1.061",
        "name": "EXPLOT.GANADO CABALLAR,MULAR Y ASNAL"
      },
      {
        "code": "1.062",
        "name": "APICULTURA"
      },
      {
        "code": "1.069",
        "name": "OTRAS EXPLOTACIONES GANADERAS"
      },
      {
        "code": "1.071",
        "name": "EXPLOTACIONES MIXTAS"
      },
      {
        "code": "1.111.1",
        "name": "EXTRACCION Y PREPARACION DE HULLA"
      },
      {
        "code": "1.111.2",
        "name": "EXTRACC. Y PREP. HULLA SUBBITUMINOSA"
      },
      {
        "code": "1.111.3",
        "name": "PREPARAC. HULLA FACTORIA INDEPENDIENTE"
      },
      {
        "code": "1.111.4",
        "name": "AGLOMERACION DE HULLA"
      },
      {
        "code": "1.112.1",
        "name": "EXTRACC. Y PREPARACION DE ANTRACITA"
      },
      {
        "code": "1.112.2",
        "name": "PREPARACION ANTRACITA FACTORIA INDEPEND."
      },
      {
        "code": "1.113.1",
        "name": "EXTRACC. Y PREP. LIGNITO PARDO"
      },
      {
        "code": "1.113.2",
        "name": "PREP. LIGNITO PARDO EN FACTORIA INDEPEN."
      },
      {
        "code": "1.114",
        "name": "FABRICACION DE COQUE"
      },
      {
        "code": "1.121.1",
        "name": "TRABAJOS DE TESTIFICACION EN SONDEOS"
      },
      {
        "code": "1.121.2",
        "name": "DESVIACION SONDEOS Y CIMENTACION POZOS"
      },
      {
        "code": "1.121.3",
        "name": "TOMAS MEDIDA PRESION FONDO DE POZOS"
      },
      {
        "code": "1.121.9",
        "name": "OTRAS ACTIV. DE PROSPECCION"
      },
      {
        "code": "1.122.1",
        "name": "EXTRACCION DE CRUDOS DE PETROLEO"
      },
      {
        "code": "1.122.2",
        "name": "DISTRIBUCION DE CRUDOS DE PETROLEO"
      },
      {
        "code": "1.123.1",
        "name": "EXTRACCION Y DEPURACION DE GAS NATURAL"
      },
      {
        "code": "1.123.2",
        "name": "DISTRIBUCION DE GAS NATURAL"
      },
      {
        "code": "1.124",
        "name": "EXTRACCION DE PIZARRAS BITUMINOSAS"
      },
      {
        "code": "1.130",
        "name": "REFINO DE PETROLEO"
      },
      {
        "code": "1.141",
        "name": "EXTRAC.Y PREP. DE MINERALES RADIACTIVOS"
      },
      {
        "code": "1.142",
        "name": "PREP.MINERALES RADIACTIVOS FACT.INDEPEN."
      },
      {
        "code": "1.143.1",
        "name": "TRANSFORMACION DE MINERALES RADIOACTIVOS"
      },
      {
        "code": "1.143.2",
        "name": "TRATAMIENTO DE RESIDUOS RADIOACTIVOS"
      },
      {
        "code": "1.143.3",
        "name": "ALMACENAMIENTO DE RESIDUOS RADIOACTIVOS"
      },
      {
        "code": "1.151.1",
        "name": "PRODUCCION DE ENERGIA HIDROELECTRICA"
      },
      {
        "code": "1.151.2",
        "name": "PRODUCCION ENERGIA TERMOELECTRICA"
      },
      {
        "code": "1.151.3",
        "name": "PRODUCCION ENERGIA ELECTRONUCLEAR"
      },
      {
        "code": "1.151.4",
        "name": "OTRAS PRODUCCIONES DE ENERGIA"
      },
      {
        "code": "1.151.5",
        "name": "TTE. Y DISTRIBUCION DE ELECTRICIDAD"
      },
      {
        "code": "1.152",
        "name": "FABRICACION Y DISTRIBUCION DE GAS"
      },
      {
        "code": "1.153",
        "name": "PRODUCC. Y DISTRIB.VAPOR Y AGUA CALIENTE"
      },
      {
        "code": "1.161.1",
        "name": "CAPTACION,TRATAMIENTO Y DISTRIB. DE AGUA"
      },
      {
        "code": "1.161.2",
        "name": "CAPTACION DE AGUA PARA SU SUMINISTRO"
      },
      {
        "code": "1.161.3",
        "name": "TRATAMIENTO DEL AGUA PARA SU SUMINISTRO"
      },
      {
        "code": "1.161.4",
        "name": "DISTRIBUCION DE AGUA EN NUCLEOS URBANOS"
      },
      {
        "code": "1.162",
        "name": "FABRICACION DE HIELO PARA LA VENTA"
      },
      {
        "code": "1.211.1",
        "name": "EXTRACC. Y PREP. MINERALES FERREOS"
      },
      {
        "code": "1.211.2",
        "name": "PREP.MINERALES FERREOS EN FACT. INDEPEN."
      },
      {
        "code": "1.212.1",
        "name": "EXTRAC.Y PREP. MINERALES METALICOS"
      },
      {
        "code": "1.212.2",
        "name": "PREP.MINERALES METALICOS EN FACT.INDEP."
      },
      {
        "code": "1.221",
        "name": "SIDERURGIA INTEGRAL"
      },
      {
        "code": "1.221.1",
        "name": "PTOS.SIDERURGICOS PRIMARIOS (S.I.)"
      },
      {
        "code": "1.221.2",
        "name": "ACERO BRUTO (S.I.)"
      },
      {
        "code": "1.221.3",
        "name": "SEMIPRODUCTOS (S.I.)"
      },
      {
        "code": "1.221.4",
        "name": "PTOS.LAMINADOS EN CALIENTE (S.I.)"
      },
      {
        "code": "1.221.5",
        "name": "PTOS.LAMINADOS EN FRIO (S.I.)"
      },
      {
        "code": "1.221.6",
        "name": "PTOS.SIDERURGICOS DERIVADOS (S.I.)"
      },
      {
        "code": "1.221.7",
        "name": "OTROS PTOS. Y SUBPRODUCTOS (S.I.)"
      },
      {
        "code": "1.222",
        "name": "SIDERURGIA NO INTEGRAL"
      },
      {
        "code": "1.222.1",
        "name": "ACERO BRUTO (S.N.I.)"
      },
      {
        "code": "1.222.2",
        "name": "SEMIPRODUCTOS (S.N.I.)"
      },
      {
        "code": "1.222.3",
        "name": "LAMINADOS EN CALIENTE (S.N.I.)"
      },
      {
        "code": "1.222.4",
        "name": "LAMINADOS EN FRIO (S.N.I.)"
      },
      {
        "code": "1.222.5",
        "name": "PTOS.DERIVADOS (S.N.I.)"
      },
      {
        "code": "1.222.6",
        "name": "OTROS PTOS. Y SUBPRODUCTOS (S.N.I.)"
      },
      {
        "code": "1.223",
        "name": "FABRICACION DE TUBOS DE ACERO"
      },
      {
        "code": "1.223.1",
        "name": "PTOS. TUBULARES DE ACERO SIN SOLDADURA"
      },
      {
        "code": "1.223.2",
        "name": "PTOS. TUBULARES DE ACERO SOLDADOS"
      },
      {
        "code": "1.223.3",
        "name": "PTOS. TUBULARES SOLDADOS HELICOIDALMENTE"
      },
      {
        "code": "1.223.4",
        "name": "ACCESORIOS PARA TUBERIAS"
      },
      {
        "code": "1.224",
        "name": "TREFILADO,ESTIRADO Y LAMINADO DE ACERO"
      },
      {
        "code": "1.224.1",
        "name": "ALAMBRE DE ACERO"
      },
      {
        "code": "1.224.2",
        "name": "PTOS. CALIBRADOS DE ESTIRADO"
      },
      {
        "code": "1.224.3",
        "name": "PTOS. CALIBRADOS POR TORNEADO"
      },
      {
        "code": "1.224.4",
        "name": "PTOS. CALIBRADOS POR RECTIFICADO"
      },
      {
        "code": "1.224.5",
        "name": "PERFILES CONFORMADOS EN FRIO"
      },
      {
        "code": "1.224.6",
        "name": "FLEJE LAMINADO EN FRIO"
      },
      {
        "code": "1.224.7",
        "name": "FLEJE MAGNETICO LAMINADO EN FRIO"
      },
      {
        "code": "1.224.8",
        "name": "FLEJE RECUBIERTO"
      },
      {
        "code": "1.225.1",
        "name": "PROD. Y PRIM. TRANSFORMACION ALUMINIO"
      },
      {
        "code": "1.225.2",
        "name": "PROD. Y PRIM. TRANSFORMACION DE COBRE"
      },
      {
        "code": "1.225.9",
        "name": "PROD. Y PRIM. TRANSF. OTROS METALES"
      },
      {
        "code": "1.231.1",
        "name": "EXTRACCION SUSTANCIAS ARCILLOSAS"
      },
      {
        "code": "1.231.2",
        "name": "EXTRACC. ROCAS Y PIZARRAS CONSTRUCCION"
      },
      {
        "code": "1.231.3",
        "name": "EXTRACC. ARENAS Y GRAVAS CONSTRUCCION"
      },
      {
        "code": "1.231.4",
        "name": "EXTRACCION DE YESO"
      },
      {
        "code": "1.231.9",
        "name": "EXTRACCION OTROS MATERIALES CONSTRUCCION"
      },
      {
        "code": "1.232.1",
        "name": "EXTRACCION SALES POTASICAS"
      },
      {
        "code": "1.232.2",
        "name": "EXTRACCION FOSFATOS Y NITRATOS"
      },
      {
        "code": "1.233.1",
        "name": "EXTRACCION DE SAL MARINA"
      },
      {
        "code": "1.233.2",
        "name": "EXTRACC. SAL MANANTIAL Y GEMA"
      },
      {
        "code": "1.234",
        "name": "EXTRACCION DE PIRITAS Y AZUFRE"
      },
      {
        "code": "1.234.1",
        "name": "PIRITAS DE HIERRO SIN TOSTAR"
      },
      {
        "code": "1.234.2",
        "name": "AZUFRE NATURAL"
      },
      {
        "code": "1.239.1",
        "name": "EXTRACCION DE FLUORITA"
      },
      {
        "code": "1.239.2",
        "name": "EXTRACCION DE TURBA"
      },
      {
        "code": "1.239.9",
        "name": "EXTRACC. DE OTROS MINERALES NCOP"
      },
      {
        "code": "1.241",
        "name": "FAB.PTOS.TIERRAS COCIDAS CONSTRUCCION"
      },
      {
        "code": "1.241.1",
        "name": "LADRILLOS BLOQUES Y FORJADOS"
      },
      {
        "code": "1.241.2",
        "name": "TEJAS, BALDOSAS Y OTROS MATERIALES"
      },
      {
        "code": "1.242.1",
        "name": "FAB. CEMENTOS ARTIFICIALES"
      },
      {
        "code": "1.242.2",
        "name": "FAB. CEMENTOS NATURALES"
      },
      {
        "code": "1.242.3",
        "name": "FAB. CALES Y YESOS"
      },
      {
        "code": "1.243.1",
        "name": "FAB. HORMIGONES PREPARADOS"
      },
      {
        "code": "1.243.2",
        "name": "FAB. PTOS. EN FIBROCEMENTO"
      },
      {
        "code": "1.243.3",
        "name": "FAB. OTROS ARTICULOS DERIVADOS CEMENTO"
      },
      {
        "code": "1.243.4",
        "name": "FAB. PAVIMENTOS DERIVADOS DEL CEMENTO"
      },
      {
        "code": "1.243.5",
        "name": "FAB. ARTICULOS DERIVADOS YESO Y ESCAYOLA"
      },
      {
        "code": "1.244",
        "name": "INDUSTRIAS DE LA PIEDRA NATURAL"
      },
      {
        "code": "1.244.1",
        "name": "PIEDRA NATURAL TRITURADA Y CLASIFICADA"
      },
      {
        "code": "1.244.2",
        "name": "PIEDRA NATURAL TALLADA Y ASERRADA"
      },
      {
        "code": "1.244.3",
        "name": "PIEDRA ELABORADA"
      },
      {
        "code": "1.245",
        "name": "FAB. DE ABRASIVOS"
      },
      {
        "code": "1.245.1",
        "name": "MUELAS Y ARTICULOS SIMILARES"
      },
      {
        "code": "1.245.2",
        "name": "OTROS ABRASIVOS"
      },
      {
        "code": "1.246.1",
        "name": "FAB. VIDRIO PLANO"
      },
      {
        "code": "1.246.2",
        "name": "FAB. VIDRIO HUECO"
      },
      {
        "code": "1.246.3",
        "name": "FAB. VIDRIO TECNICO"
      },
      {
        "code": "1.246.4",
        "name": "FAB. FIBRA DE VIDRIO"
      },
      {
        "code": "1.246.5",
        "name": "MANIPULADO DE VIDRIO"
      },
      {
        "code": "1.246.6",
        "name": "FABRICAC. DE FIBRAS Y ESMALTES CERAMICOS"
      },
      {
        "code": "1.247.1",
        "name": "FAB. ARTICULOS REFRACTARIOS"
      },
      {
        "code": "1.247.2",
        "name": "FAB. BALDOSAS PARA PAVIMENTOS"
      },
      {
        "code": "1.247.3",
        "name": "FAB. BALDOSAS ESMALTADAS PARA PAVIMENTOS"
      },
      {
        "code": "1.247.4",
        "name": "FAB. VAJILLAS Y ARTICULOS PARA EL HOGAR"
      },
      {
        "code": "1.247.5",
        "name": "FAB. APARATOS SANITARIOS LOZA Y PORCEL."
      },
      {
        "code": "1.247.6",
        "name": "FAB. AISLADORES EN MATERIAL CERAMICO"
      },
      {
        "code": "1.247.9",
        "name": "FAB. OTROS PTOS. CERAMICOS NCOP"
      },
      {
        "code": "1.249",
        "name": "IND. OTROS PTOS. MINERALES NO MET."
      },
      {
        "code": "1.249.1",
        "name": "FAB. PTOS. ASFALTICOS"
      },
      {
        "code": "1.249.2",
        "name": "FAB. PTOS. A BASE DE AMIANTO"
      },
      {
        "code": "1.249.9",
        "name": "FAB. PTOS. CON OTROS MINERALES NO METAL"
      },
      {
        "code": "1.251.1",
        "name": "FAB.PTO.QUIMICOS ORGANICOS PETROQUIMICOS"
      },
      {
        "code": "1.251.2",
        "name": "FAB. OTROS PTOS. QUIMICOS ORGANICOS"
      },
      {
        "code": "1.251.3",
        "name": "FAB. PTOS. QUIMICOS INORGANICOS"
      },
      {
        "code": "1.251.4",
        "name": "FAB. PRIMERA MATERIAS PLASTICAS"
      },
      {
        "code": "1.251.5",
        "name": "FAB. CAUCHO Y LATEX SINTETICOS"
      },
      {
        "code": "1.251.6",
        "name": "FAB. FIBRAS ARTIFICIALES Y SINTETICAS"
      },
      {
        "code": "1.251.7",
        "name": "FAB. ACIDO ANHIDRICO FTALICO Y MALEICO"
      },
      {
        "code": "1.252.1",
        "name": "FAB. DE ABONOS"
      },
      {
        "code": "1.252.2",
        "name": "FAB. DE PLAGUICIDAS"
      },
      {
        "code": "1.253.1",
        "name": "FAB. GASES COMPRIMIDOS"
      },
      {
        "code": "1.253.2",
        "name": "FAB. COLORANTES Y PIGMENTOS"
      },
      {
        "code": "1.253.3",
        "name": "FAB. PINTURAS, BARNICES Y LACAS"
      },
      {
        "code": "1.253.4",
        "name": "FAB. DE TINTAS DE IMPRENTA"
      },
      {
        "code": "1.253.5",
        "name": "TRATAMIENTO ACEITES PARA USO INDUSTRIAL"
      },
      {
        "code": "1.253.6",
        "name": "FAB. ACEITES ESENCIALES Y SUST. AROMAT."
      },
      {
        "code": "1.253.7",
        "name": "FAB. COLAS Y GELATINAS"
      },
      {
        "code": "1.253.8",
        "name": "FAB. EXPLOSIVOS"
      },
      {
        "code": "1.253.9",
        "name": "FAB. OTROS PTOS. QUIMICOS USO INDUSTRIAL"
      },
      {
        "code": "1.254.1",
        "name": "FAB. PTOS. FARMACEUTICOS DE BASE"
      },
      {
        "code": "1.254.2",
        "name": "FAB. ESPECIALIDADES FARMACEUTICAS"
      },
      {
        "code": "1.255.1",
        "name": "FAB. JABONES COMUNES, DETERGENTES, LEJIA"
      },
      {
        "code": "1.255.2",
        "name": "FAB. JABONES TOCADOR Y PTOS. PERFUMERIA"
      },
      {
        "code": "1.255.3",
        "name": "FAB. DERIVADOS DE CERAS Y PARAFINAS"
      },
      {
        "code": "1.255.4",
        "name": "FAB. MATERIAL FOTOGRAFICO SENSIBLE"
      },
      {
        "code": "1.255.5",
        "name": "FAB. ART. PIROTECNICOS, Y FOSFOROS"
      },
      {
        "code": "1.255.9",
        "name": "FAB. OTROS PTOS. QUIMICOS DE CONSUMO"
      },
      {
        "code": "1.311.1",
        "name": "FUNDICION PIEZAS DE HIERRO Y ACERO"
      },
      {
        "code": "1.311.2",
        "name": "FUNDICION PIEZAS METALES NO FERREOS"
      },
      {
        "code": "1.312",
        "name": "FORJA,ESTAMPADO,EMBUTICION,TROQUELADO"
      },
      {
        "code": "1.312.1",
        "name": "PIEZAS FORJADAS"
      },
      {
        "code": "1.312.2",
        "name": "PIEZAS ESTAMPADAS O TROQUELADAS"
      },
      {
        "code": "1.312.3",
        "name": "PIEZAS EMBUTIDAS, CORTADAS"
      },
      {
        "code": "1.313",
        "name": "TRATAMIENTO Y RECUBRIMIENTO DE METALES"
      },
      {
        "code": "1.313.1",
        "name": "TRATAMIENTO DE METALES"
      },
      {
        "code": "1.313.2",
        "name": "RECUBRIMIENTOS METALICOS"
      },
      {
        "code": "1.313.9",
        "name": "TRAT. PROTECCION DE METALES"
      },
      {
        "code": "1.314.1",
        "name": "FAB. ART. CARPINTERIA METALICA"
      },
      {
        "code": "1.314.2",
        "name": "FAB. ESTRUCTURAS METALICAS"
      },
      {
        "code": "1.315",
        "name": "CONSTRUCC. DEPOSITOS Y CALDERERIA"
      },
      {
        "code": "1.315.1",
        "name": "GRANDES CALDERAS"
      },
      {
        "code": "1.315.2",
        "name": "GRANDES DEPOSITOS METALICOS"
      },
      {
        "code": "1.316.1",
        "name": "FAB. HERRAMIENTAS MANUALES"
      },
      {
        "code": "1.316.2",
        "name": "FAB. ARTICULOS DE FERRETERIA/CERRAJERIA"
      },
      {
        "code": "1.316.3",
        "name": "TORNILLERIA Y ART. DERIVADOS ALAMBRE"
      },
      {
        "code": "1.316.4",
        "name": "FAB. ART. MENAJE"
      },
      {
        "code": "1.316.5",
        "name": "FAB. COCINAS, CALENTADORES, CALEFACCION"
      },
      {
        "code": "1.316.6",
        "name": "FAB. MOBILIARIO METALICO"
      },
      {
        "code": "1.316.7",
        "name": "FAB. RECIPIENTES Y ENVASES METALICOS"
      },
      {
        "code": "1.316.8",
        "name": "FAB. ARMAS LIGERAS Y SUS MUNICIONES"
      },
      {
        "code": "1.316.9",
        "name": "OTROS ART. ACABADOS EN METALES NCOP"
      },
      {
        "code": "1.319.1",
        "name": "TALLERES DE MECANICA EN GENERAL"
      },
      {
        "code": "1.319.9",
        "name": "TALLERES MECANICOS NCOP"
      },
      {
        "code": "1.321.1",
        "name": "CONSTRUCC. MAQ. AGRICOLAS"
      },
      {
        "code": "1.321.2",
        "name": "CONSTRUCC. TRACTORES AGRICOLAS"
      },
      {
        "code": "1.322.1",
        "name": "CONS. MAQUINAS PARA TRABAJAR METALES"
      },
      {
        "code": "1.322.2",
        "name": "CONST. MAQUINAS TRABAJAR MADERA/CORCHO"
      },
      {
        "code": "1.322.3",
        "name": "FAB.UTILES PARA MAQUINAS HERRAMIENTAS"
      },
      {
        "code": "1.323.1",
        "name": "CONST. MAQUINAS TEXTILES Y ACCESORIOS"
      },
      {
        "code": "1.323.2",
        "name": "CONST. MAQUINAS INDUST. CUERO Y CALZADO"
      },
      {
        "code": "1.323.3",
        "name": "FAB. MAQUINAS DE COSER"
      },
      {
        "code": "1.324.1",
        "name": "MAQUINAS IND. ALIMENT.,BEBIDAS Y TAB."
      },
      {
        "code": "1.324.2",
        "name": "CONST.MAQUINAS PARA INDUSTRIA QUIMICA"
      },
      {
        "code": "1.324.3",
        "name": "CONST.MAQ.IND._DEL CAUCHO Y PLASTICOS"
      },
      {
        "code": "1.325.1",
        "name": "CONST.MAQ. MINERIA Y CONSTRUCCION"
      },
      {
        "code": "1.325.2",
        "name": "CONST. MAQUINAS IND. MINERALES NO METAL"
      },
      {
        "code": "1.325.3",
        "name": "CONST. MAQ. PARA SIDERURGIA Y FUNDICION"
      },
      {
        "code": "1.325.4",
        "name": "CONST. MAQ. ELEVACION Y MANIPULACION"
      },
      {
        "code": "1.326.1",
        "name": "FAB.ENGRANAJES,CADENAS DE TRANSMISION"
      },
      {
        "code": "1.326.2",
        "name": "FAB. DE RODAMIENTOS"
      },
      {
        "code": "1.329.1",
        "name": "CONST. MAQ. IND. PAPEL, CARTON Y AA.GG."
      },
      {
        "code": "1.329.2",
        "name": "CONST.MAQ. DE LAVADO Y LIMPIEZA EN SECO"
      },
      {
        "code": "1.329.3",
        "name": "CONST. MOTORES Y TURBINAS (EXC.TRANSPOR)"
      },
      {
        "code": "1.329.4",
        "name": "CONST. MAQ. MANIPULACION DE FLUIDOS"
      },
      {
        "code": "1.329.9",
        "name": "CONST. OTRAS MAQ. Y EQUIPOS NCOP"
      },
      {
        "code": "1.330",
        "name": "CONS.MAQ.DE OFICINA Y ORDENADORES"
      },
      {
        "code": "1.330.1",
        "name": "CONS. MAQ. DE OFICINA Y ORDENADORES"
      },
      {
        "code": "1.330.2",
        "name": "INSTALAC. MAQ. OFICINA Y ORDENADORES"
      },
      {
        "code": "1.341",
        "name": "FAB.HILOS Y CABLES ELECTRICOS"
      },
      {
        "code": "1.341.1",
        "name": "FAB. HILOS Y CABLES AISLADOS COMUNICAC."
      },
      {
        "code": "1.341.2",
        "name": "FAB.HILOS Y CABLES PARA ELECTRICIDAD"
      },
      {
        "code": "1.341.3",
        "name": "FAB. HILOS Y CABLES PARA BOBINAS"
      },
      {
        "code": "1.341.4",
        "name": "FAB.CORDONES FLEXIBLES E HILOS AISLADOS"
      },
      {
        "code": "1.341.5",
        "name": "FAB. HILOS Y CABLES PARA CONSTRUCCION"
      },
      {
        "code": "1.341.9",
        "name": "FAB. OTROS HILOS Y CABLES AISLADOS"
      },
      {
        "code": "1.342",
        "name": "FAB.MATERIAL ELECTRICO DE USO Y EQUIP."
      },
      {
        "code": "1.342.1",
        "name": "MAQUINAS TRASFORMACION ELECTRICIDAD"
      },
      {
        "code": "1.342.2",
        "name": "OTRO MATERIAL ELECTRICO"
      },
      {
        "code": "1.343",
        "name": "FABRICACION PILAS Y ACUMULADORES"
      },
      {
        "code": "1.343.1",
        "name": "FAB. PILAS ELECTRICAS"
      },
      {
        "code": "1.343.2",
        "name": "FAB. ACUMULADORES ELECTRICOS"
      },
      {
        "code": "1.343.3",
        "name": "FAB. ACCESORIOS DE PILAS Y ACUMULADORES"
      },
      {
        "code": "1.344",
        "name": "FAB. CONTADORES Y APARATOS DE MEDIDA"
      },
      {
        "code": "1.345",
        "name": "FAB. APARATOS ELECTRODOMESTICOS"
      },
      {
        "code": "1.345.1",
        "name": "FAB.COCINAS,HORNOS,PLACAS, Y DEMAS"
      },
      {
        "code": "1.345.2",
        "name": "FAB.REFRIGERADORES Y CONGELADORES"
      },
      {
        "code": "1.345.3",
        "name": "FAB. LAVAVAJILLAS,LAVADORAS Y SECADORAS"
      },
      {
        "code": "1.345.4",
        "name": "FAB.CALENTADORES Y CALEFACC. ELECTRICA"
      },
      {
        "code": "1.345.5",
        "name": "FAB.VENTILADORES Y ACONDICIONADORES"
      },
      {
        "code": "1.345.6",
        "name": "FAB.APARATOS ELEC. AUXILIARES COCINA"
      },
      {
        "code": "1.345.7",
        "name": "FAB.APAR.ELECTRICOS PARA EL HOGAR"
      },
      {
        "code": "1.345.8",
        "name": "FAB. OTROS APARATOS ELECTRICOS"
      },
      {
        "code": "1.345.9",
        "name": "FAB. ACCESORIOS DE APAR. ELECTRODOMEST."
      },
      {
        "code": "1.346",
        "name": "FAB. LAMPARAS Y MATERIAL DE ALUMBRADO"
      },
      {
        "code": "1.346.1",
        "name": "FAB. LAMPARAS ELECTRICAS"
      },
      {
        "code": "1.346.2",
        "name": "FAB.LUMINARIAS DE ALTA INTENSIDAD"
      },
      {
        "code": "1.346.3",
        "name": "FAB. ART. CARBON Y GRAFITO USO ELECTRIC."
      },
      {
        "code": "1.346.4",
        "name": "FAB. OTRO MATERIAL DE ALUMBRADO"
      },
      {
        "code": "1.346.5",
        "name": "FAB. ACCESORIOS MATERIAL ALUMBRADO"
      },
      {
        "code": "1.351.1",
        "name": "FAB. APARATOS TELEFONICOS Y TELEGRAFICOS"
      },
      {
        "code": "1.351.2",
        "name": "FAB.APARATOS TELECOMUNICACION RADIODIF."
      },
      {
        "code": "1.352",
        "name": "FAB. APARATOS USO PROFESIONAL CIENTIFICO"
      },
      {
        "code": "1.353",
        "name": "FAB.APARAT.SEÑALIZACION,CONTROL"
      },
      {
        "code": "1.354",
        "name": "FAB.COMPONENTES ELECTRONICOS Y CIRCUITOS"
      },
      {
        "code": "1.354.1",
        "name": "FAB. VALVULAS Y TUBOS ELECTRONICOS"
      },
      {
        "code": "1.354.2",
        "name": "FAB. OTROS COMPONENTES ELECTRONICOS"
      },
      {
        "code": "1.354.3",
        "name": "FAB. COMPONENTES ELECTRONICOS PASIVOS"
      },
      {
        "code": "1.354.4",
        "name": "ACCESORIOS DE COMPONENTES ELECTRONICOS"
      },
      {
        "code": "1.355.1",
        "name": "FAB. RECEPTORES RADIO, TV, Y SONIDO"
      },
      {
        "code": "1.355.2",
        "name": "EDICION SOPORTES AUDIO,VIDEO,INFORMATICA"
      },
      {
        "code": "1.361",
        "name": "CONSTRUCCION DE VEHICULOS AUTOMOVILES"
      },
      {
        "code": "1.361.1",
        "name": "AUTOMOVILES DE TURISMO"
      },
      {
        "code": "1.361.2",
        "name": "AUTOBUSES Y AUTOCARES"
      },
      {
        "code": "1.361.3",
        "name": "CAMIONES"
      },
      {
        "code": "1.361.4",
        "name": "OTROS VEHICULOS AUTOMOVILES"
      },
      {
        "code": "1.361.5",
        "name": "MOTORES PARA VEHICULOS AUTOMOVILES"
      },
      {
        "code": "1.361.6",
        "name": "CHASIS CON MOTOR PARA AUTOMOVILES"
      },
      {
        "code": "1.361.7",
        "name": "COMPONENTES PARA VEHICULOS AUTOMOVILES"
      },
      {
        "code": "1.362",
        "name": "CONSTRUCC. CARROCERIAS, REMOLQUES..."
      },
      {
        "code": "1.362.1",
        "name": "CARROCERIAS AUTOMOVILES Y REMOLQUES"
      },
      {
        "code": "1.362.2",
        "name": "REMOLQUES Y VOLQUETES"
      },
      {
        "code": "1.363",
        "name": "FAB.EQUIPO,COMPONENTES,REPUESTOS, COCHES"
      },
      {
        "code": "1.363.1",
        "name": "REPUESTOS Y ACCESORIOS MOTORES AUTOS"
      },
      {
        "code": "1.363.2",
        "name": "ACCESORIOS Y REPUESTOS CARROCERIAS AUTO"
      },
      {
        "code": "1.363.9",
        "name": "OTROS ACCESORIOS Y REPUESTOS AUTOMOVILES"
      },
      {
        "code": "1.371",
        "name": "CONSTRUCCION NAVAL"
      },
      {
        "code": "1.371.1",
        "name": "BUQUES DE CASCO DE ACERO"
      },
      {
        "code": "1.371.2",
        "name": "BUQUES DE CASCO DE MADERA"
      },
      {
        "code": "1.371.3",
        "name": "BUQUES DE CASCO DE PLASTICO"
      },
      {
        "code": "1.371.4",
        "name": "ARTEFACTOS FLOTANTES"
      },
      {
        "code": "1.371.5",
        "name": "MOTORES, Y TURBINAS PARA EMBARCACIONES"
      },
      {
        "code": "1.371.6",
        "name": "ACCESORIOS Y REPUESTOS EMBARCACIONES"
      },
      {
        "code": "1.372",
        "name": "REPARACION Y MANTENIMIENTO DE BUQUES"
      },
      {
        "code": "1.372.1",
        "name": "SERV.REPARACION Y MANTENIM. BUQUES"
      },
      {
        "code": "1.372.2",
        "name": "SERV.DESGUACE DE EMBARCACIONES"
      },
      {
        "code": "1.381",
        "name": "CONST.REPARAC.MANTENIM. FERROCARRILES"
      },
      {
        "code": "1.381.1",
        "name": "MATERIAL FERROVIARIO"
      },
      {
        "code": "1.381.2",
        "name": "SERV. REPARACION MATERIAL FERROVIARIO"
      },
      {
        "code": "1.382",
        "name": "CONSTRUCC.REPARACION, MANT. AERONAVES"
      },
      {
        "code": "1.382.1",
        "name": "AERONAVES E INGENIOS ESPACIALES"
      },
      {
        "code": "1.382.2",
        "name": "SERV. REPARACION Y MANTENIMIENTO AVIONES"
      },
      {
        "code": "1.383",
        "name": "CONSTRUCC. BICICLETAS Y MOTOCICLETAS"
      },
      {
        "code": "1.383.1",
        "name": "MOTOCICLETAS, Y CICLOMOTORES"
      },
      {
        "code": "1.383.2",
        "name": "BICICLETAS, TRICICLOS Y MONOCICLOS"
      },
      {
        "code": "1.383.3",
        "name": "VEHICULOS ESPECIALES CON MEC. PROPULSION"
      },
      {
        "code": "1.383.4",
        "name": "MOTORES PARA MOTOS, CICLIMOTORES"
      },
      {
        "code": "1.383.5",
        "name": "ACCESORIOS PARA MOTOS Y BICICLETAS"
      },
      {
        "code": "1.389",
        "name": "CONSTRUCC. OTRO MATERIAL TRANSPORTE NCOP"
      },
      {
        "code": "1.389.1",
        "name": "REMOLQUES AGRICOLAS"
      },
      {
        "code": "1.389.2",
        "name": "VEHICULOS ACCIONADOS A MANO"
      },
      {
        "code": "1.389.9",
        "name": "ACCESORIOS PARA OTRO MATERIAL TTE. NCOP"
      },
      {
        "code": "1.391",
        "name": "FAB.INSTRUM. PRECISION,MEDIDA,CONTROL"
      },
      {
        "code": "1.391.1",
        "name": "CONTADORES NO ELECTRICOS"
      },
      {
        "code": "1.391.2",
        "name": "INSTRUMENTOS PARA LA NAVEGACION"
      },
      {
        "code": "1.391.3",
        "name": "INSTRUMENTOS TOPOGRAFIA,METEREOLOGIA,..."
      },
      {
        "code": "1.391.4",
        "name": "INSTRUM. MEDIDA DENSIDAD, TEMPERATURA..."
      },
      {
        "code": "1.391.5",
        "name": "INSTRUM. ENSAYOS MECANICOS DE MATERIALES"
      },
      {
        "code": "1.391.6",
        "name": "BALANZAS DE PRECISION"
      },
      {
        "code": "1.391.7",
        "name": "OTROS INSTRUM. PRECISION"
      },
      {
        "code": "1.391.8",
        "name": "ACCESORIOS INSTRUM. APARAT. PRECISION"
      },
      {
        "code": "1.392.1",
        "name": "FAB. MATERIAL MEDICO-QUIRURGICO"
      },
      {
        "code": "1.392.2",
        "name": "FAB. APARATOS PROTESIS Y ORTOPEDIA"
      },
      {
        "code": "1.393.1",
        "name": "FAB.INSTRUM.OPTICOS Y MAT. FOTOGRAFICO"
      },
      {
        "code": "1.393.2",
        "name": "FAB. MONTURAS PARA GAFAS NO PLASTICAS"
      },
      {
        "code": "1.399",
        "name": "FAB. RELOJES Y OTROS INSTRUMENTOS NCOP"
      },
      {
        "code": "1.399.1",
        "name": "RELOJES Y CRONOGRAFOS PULSERA Y BOLSILLO"
      },
      {
        "code": "1.399.2",
        "name": "RELOJES DESPERTADORES"
      },
      {
        "code": "1.399.3",
        "name": "RELOJES DE PARED Y SOBREMESA"
      },
      {
        "code": "1.399.4",
        "name": "RELOJES PARA VEHICULOS"
      },
      {
        "code": "1.399.5",
        "name": "RELOJES DE TORRE, ESTACION,..."
      },
      {
        "code": "1.399.6",
        "name": "APARATOS CON MECANISMOS DE RELOJERIA"
      },
      {
        "code": "1.399.7",
        "name": "MAQUINAS DE RELOJ MONTADAS"
      },
      {
        "code": "1.399.8",
        "name": "CAJAS DE RELOJES"
      },
      {
        "code": "1.399.9",
        "name": "ACCESORIOS DE RELOJES Y OTROS INSTRUM."
      },
      {
        "code": "1.411.1",
        "name": "FAB. Y ENVASADO DE ACEITE DE OLIVA"
      },
      {
        "code": "1.411.2",
        "name": "FAB. ACEITE DE OLIVA"
      },
      {
        "code": "1.411.3",
        "name": "ENVASADO DE ACEITE DE OLIVA"
      },
      {
        "code": "1.412.1",
        "name": "EXTRACC. Y ENVASADO ACEITES SEMILLAS"
      },
      {
        "code": "1.412.2",
        "name": "OBTENC.Y ENVASADO GRASAS ANIMALES MAR"
      },
      {
        "code": "1.412.3",
        "name": "REFINADO Y OTROS TRATAMIENTOS GRASAS"
      },
      {
        "code": "1.412.4",
        "name": "OBTENCION Y ENVASADO MARGARINA"
      },
      {
        "code": "1.413.1",
        "name": "SACRIFICIO Y DESPIECE DE GANADO"
      },
      {
        "code": "1.413.2",
        "name": "FAB.PTOS.CARNICOS DE TODAS CLASES"
      },
      {
        "code": "1.413.3",
        "name": "SALAS DESPIECE AUTONOMAS"
      },
      {
        "code": "1.413.4",
        "name": "INCUBACION Y VENTA DE POLLUELOS"
      },
      {
        "code": "1.414.1",
        "name": "PREPARACION DE LECHE"
      },
      {
        "code": "1.414.2",
        "name": "PREPARACION LECHE EN CONSERVA"
      },
      {
        "code": "1.414.3",
        "name": "FAB. QUESO Y MANTEQUILLA"
      },
      {
        "code": "1.414.4",
        "name": "ELABORACION DE HELADOS Y SIMILARES"
      },
      {
        "code": "1.415",
        "name": "FAB. JUGOS Y CONSERVAS VEGETALES"
      },
      {
        "code": "1.415.1",
        "name": "CONSERVAS VEGETALES"
      },
      {
        "code": "1.415.2",
        "name": "EXTRACTOS,ZUMOS Y OTROS PREPARADOS"
      },
      {
        "code": "1.415.3",
        "name": "LIMPIEZA,CLASIFIC.Y ENVASE FRUTAS"
      },
      {
        "code": "1.416",
        "name": "FAB. CONSERVAS PESCADO"
      },
      {
        "code": "1.416.1",
        "name": "CONSERVAS DE PESCADO"
      },
      {
        "code": "1.416.2",
        "name": "PTOS.RESIDUALES CONSERVACION PESCADO"
      },
      {
        "code": "1.417.1",
        "name": "FAB. HARINAS Y SEMOLAS"
      },
      {
        "code": "1.417.2",
        "name": "FAB. OTROS PTOS. MOLINERIA"
      },
      {
        "code": "1.418.1",
        "name": "FAB. PASTAS ALIMENTICIAS"
      },
      {
        "code": "1.418.2",
        "name": "FAB. PTOS. AMILACEOS"
      },
      {
        "code": "1.419.1",
        "name": "INDUSTRIA DEL PAN Y BOLLERIA"
      },
      {
        "code": "1.419.2",
        "name": "INDUST. BOLLERIA Y PASTELERIA"
      },
      {
        "code": "1.419.3",
        "name": "INDUST. ELABORACION MASAS FRITAS"
      },
      {
        "code": "1.420",
        "name": "INDUSTRIAS DEL AZUCAR"
      },
      {
        "code": "1.420.1",
        "name": "AZUCAR Y JARABES DE AZUCAR"
      },
      {
        "code": "1.420.2",
        "name": "PTOS. RESIDUALES DE LA INDUSTRIA AZUCAR"
      },
      {
        "code": "1.421.1",
        "name": "INDUSTRIA CACAO Y CHOCOLATE"
      },
      {
        "code": "1.421.2",
        "name": "ELABORACION PTOS. CONFITERIA"
      },
      {
        "code": "1.422.1",
        "name": "FORRAJES DESHIDRATADOS ALIMENTAC. ANIMAL"
      },
      {
        "code": "1.422.2",
        "name": "HARINAS PESCADO Y OTROS PTOS. PIENSOS"
      },
      {
        "code": "1.422.3",
        "name": "ELAB.PIENSOS COMP. CUALQ.CLASE(EXC.4224)"
      },
      {
        "code": "1.422.4",
        "name": "ELAB.PIENSOS COMP. ANIMALES DOMESTICOS"
      },
      {
        "code": "1.423.1",
        "name": "ELAB. CAFE, TE, Y SUCEDANEOS"
      },
      {
        "code": "1.423.2",
        "name": "ELAB. SOPAS PREPARADAS Y CONDIMENTOS"
      },
      {
        "code": "1.423.3",
        "name": "ELAB. PTOS. DIETETICOS Y DE REGIMEN"
      },
      {
        "code": "1.423.9",
        "name": "ELAB. OTROS PTOS. ALIMENTICIOS NCOP"
      },
      {
        "code": "1.424.1",
        "name": "DESTILACION Y RECTIFICACION ALCOHOLES"
      },
      {
        "code": "1.424.2",
        "name": "OBTENCION AGUARDIENTES NATURALES"
      },
      {
        "code": "1.424.3",
        "name": "OBTENC. AGUARDIENTES COMPUESTOS"
      },
      {
        "code": "1.425.1",
        "name": "ELABORACION Y CRIANZA DE VINOS"
      },
      {
        "code": "1.425.2",
        "name": "ELABORACION DE VINOS ESPUMOSOS"
      },
      {
        "code": "1.425.3",
        "name": "ELABORACION OTROS VINOS ESPECIALES"
      },
      {
        "code": "1.425.9",
        "name": "OTRAS INDUSTRIAS VINICOLAS NCOP"
      },
      {
        "code": "1.426",
        "name": "SIDRERIAS"
      },
      {
        "code": "1.426.1",
        "name": "SIDRA Y OTRAS BEBIDAS FERMENTADAS"
      },
      {
        "code": "1.426.2",
        "name": "PTOS. RESIDUALES DE SIDRERIAS"
      },
      {
        "code": "1.427",
        "name": "FAB. CERVEZA Y MALTA DE CERVEZA"
      },
      {
        "code": "1.427.1",
        "name": "CERVEZA Y MALTA DE CERVEZA"
      },
      {
        "code": "1.427.2",
        "name": "SUBPRODUCTOS RESIDUALES DE CERVEZA"
      },
      {
        "code": "1.428.1",
        "name": "PREP. Y ENVASADO DE AGUAS MINERALES"
      },
      {
        "code": "1.428.2",
        "name": "FAB. AGUAS GASEOSAS Y OTRAS BEBIDAS SIN"
      },
      {
        "code": "1.429.1",
        "name": "ELAB. CIGARROS, CIGARRILLOS DE TABACO"
      },
      {
        "code": "1.429.2",
        "name": "PRIMERA TRANSFORMACION TABACO"
      },
      {
        "code": "1.431.1",
        "name": "PREP. FIBRAS DE ALGODON"
      },
      {
        "code": "1.431.2",
        "name": "HILADO Y RETORCIDO DEL ALGODON"
      },
      {
        "code": "1.431.3",
        "name": "TEJIDO ALGODON Y SUS MEZCLAS"
      },
      {
        "code": "1.432.1",
        "name": "PREP. FIBRAS LANA"
      },
      {
        "code": "1.432.2",
        "name": "HILADO Y RETORCIDO LANA"
      },
      {
        "code": "1.432.3",
        "name": "TEJIDO DE LANA"
      },
      {
        "code": "1.433",
        "name": "INDUSTRIA DE LA SEDA NATURAL"
      },
      {
        "code": "1.433.1",
        "name": "PTOS. IND. SEDA NATURAL Y SUS MEZCLAS"
      },
      {
        "code": "1.433.2",
        "name": "PREP. HILADO Y TEJIDO DE FIBRAS SINTETIC"
      },
      {
        "code": "1.434",
        "name": "INDUSTRIA DE LAS FIBRAS DURAS Y MEZCLAS"
      },
      {
        "code": "1.434.1",
        "name": "FIBRAS DURAS PREPARADAS PARA HILADO"
      },
      {
        "code": "1.434.2",
        "name": "SUBPRODUCTOS DE LA PREP. FIBRAS DURAS"
      },
      {
        "code": "1.434.3",
        "name": "HILADOS Y RETORCIDOS DE FIBRAS DURAS"
      },
      {
        "code": "1.434.4",
        "name": "TEJIDOS DE FIBRAS DURAS Y SUS MEZCLAS"
      },
      {
        "code": "1.435.1",
        "name": "FAB. GENEROS DE PUNTO EN PIEZA"
      },
      {
        "code": "1.435.2",
        "name": "FAB. CALCETERIA"
      },
      {
        "code": "1.435.3",
        "name": "FAB. PRENDAS INTERIORES PUNTO"
      },
      {
        "code": "1.435.4",
        "name": "FAB. PRENDAS EXTERIORES DE PUNTO"
      },
      {
        "code": "1.436",
        "name": "ACABADO DE TEXTILES"
      },
      {
        "code": "1.436.1",
        "name": "TEXTILES BLANQUEADOS"
      },
      {
        "code": "1.436.2",
        "name": "TEXTILES TEåIDOS"
      },
      {
        "code": "1.436.3",
        "name": "TEXTILES ESTAMPADOS"
      },
      {
        "code": "1.436.9",
        "name": "TEXTILES APRESTADOS Y MERCERIZADOS"
      },
      {
        "code": "1.437.1",
        "name": "FAB. ALFOMBRAS Y TAPICES"
      },
      {
        "code": "1.437.2",
        "name": "FAB. TEJIDOS IMPREGNADOS"
      },
      {
        "code": "1.439.1",
        "name": "CORDELERIA"
      },
      {
        "code": "1.439.2",
        "name": "FAB. FIELTROS, TULES, ENCAJES, ETC."
      },
      {
        "code": "1.439.3",
        "name": "FAB. TEXTILES CON FIBRAS RECUPERACION"
      },
      {
        "code": "1.439.9",
        "name": "OTRAS INDUSTRIAS TEXTILES NCOP"
      },
      {
        "code": "1.441",
        "name": "CURTICION Y ACABADO DE CUEROS Y PIELES"
      },
      {
        "code": "1.441.1",
        "name": "CUEROS Y PIELES NO ACABADAS"
      },
      {
        "code": "1.441.2",
        "name": "CUEROS Y PIELES ACABADAS"
      },
      {
        "code": "1.441.3",
        "name": "CUEROS Y PIELES REGENERADAS, SUBPRODUCT."
      },
      {
        "code": "1.442.1",
        "name": "FAB.ART. MARROQUINERIA Y VIAJE"
      },
      {
        "code": "1.442.2",
        "name": "FAB. GUANTES DE PIEL"
      },
      {
        "code": "1.442.9",
        "name": "FAB. OTROS ARTICULOS DE CUERO NCOP"
      },
      {
        "code": "1.451",
        "name": "FAB. SERIE CALZADO (EXCEP. CAUCHO/MADER)"
      },
      {
        "code": "1.451.1",
        "name": "PTOS. INTERMEDIOS DE FAB. CALZADO"
      },
      {
        "code": "1.451.2",
        "name": "CALZADO DE CALLE FAB. EN SERIE"
      },
      {
        "code": "1.451.3",
        "name": "ZAPATILLAS DE CASA, CALZADOS ESPECIALES"
      },
      {
        "code": "1.451.4",
        "name": "RECORTES Y DESPERDICIOS DE CUERO"
      },
      {
        "code": "1.452",
        "name": "FAB. CALZADO ARTESANIA Y A MEDIDA"
      },
      {
        "code": "1.452.1",
        "name": "CALZADO ARTESANIA Y MEDIDA"
      },
      {
        "code": "1.452.2",
        "name": "CALZADO ORTOPEDICO"
      },
      {
        "code": "1.453",
        "name": "CONFECC. TODA CLASE PRENDAS VESTIR"
      },
      {
        "code": "1.454",
        "name": "CONFECC. MEDIDA PRENDAS VESTIR"
      },
      {
        "code": "1.454.1",
        "name": "PRENDAS VESTIR HECHAS A MEDIDA"
      },
      {
        "code": "1.454.2",
        "name": "SOMBREROS Y ACCESORIOS PARA EL VESTIDO"
      },
      {
        "code": "1.455.1",
        "name": "CONFECC. ART. TEXTILES HOGAR/TAPICERIA"
      },
      {
        "code": "1.455.9",
        "name": "CONFECC. OTROS ART. TEXTILES NCOP"
      },
      {
        "code": "1.456.1",
        "name": "PELETERIA NATURAL"
      },
      {
        "code": "1.456.2",
        "name": "PELETERIA ARTIFICIAL"
      },
      {
        "code": "1.461",
        "name": "ASERRADO Y PREP. INDUSTRIAL DE LA MADERA"
      },
      {
        "code": "1.461.1",
        "name": "PTOS. ASERRADO Y PREP. IND. MADERA"
      },
      {
        "code": "1.461.2",
        "name": "PTOS. RESIDUALES IND. MADERA"
      },
      {
        "code": "1.462.1",
        "name": "CHAPAS DE MADERA"
      },
      {
        "code": "1.462.2",
        "name": "MADERAS CHAPADAS, CONTRACHAPADAS, ETC"
      },
      {
        "code": "1.462.3",
        "name": "TABLEROS, PANELES DE FIBRAS Y DE PART."
      },
      {
        "code": "1.462.4",
        "name": "MADERAS MEJORADAS"
      },
      {
        "code": "1.463",
        "name": "FAB. SERIE PIEZAS DE CARPINTERIA"
      },
      {
        "code": "1.463.1",
        "name": "PUERTAS Y VENTANAS DE MADERA"
      },
      {
        "code": "1.463.2",
        "name": "PARQUET, ENTARIMADO Y ADOQUINES MADERA"
      },
      {
        "code": "1.463.3",
        "name": "OTRAS PIEZAS CARPINTERIA PARA CONSTRUCC."
      },
      {
        "code": "1.463.4",
        "name": "ELEM. ESTRUCTURALES Y PREFABRIC. MADERA"
      },
      {
        "code": "1.464",
        "name": "FAB. ENVASES Y EMBALAJES DE MADERA"
      },
      {
        "code": "1.464.1",
        "name": "ENVASES Y EMBALAJES INDUSTRIALES MADERA"
      },
      {
        "code": "1.464.2",
        "name": "TONELERIA"
      },
      {
        "code": "1.464.3",
        "name": "ESTUCHES, BAULES, MALETAS DE MADERA"
      },
      {
        "code": "1.465",
        "name": "FAB. OBJETOS DIVERSOS DE MADERA"
      },
      {
        "code": "1.465.1",
        "name": "OBJETOS MADERA DE USO DOMESTICO"
      },
      {
        "code": "1.465.2",
        "name": "HERRAMIENTAS, MANGOS, MONTURAS DE MADERA"
      },
      {
        "code": "1.465.3",
        "name": "ART. MADERA PARA FAB. CALZADO"
      },
      {
        "code": "1.465.4",
        "name": "ART. MADERA PARA IND. TEXTIL"
      },
      {
        "code": "1.465.5",
        "name": "CALZADO DE MADERA"
      },
      {
        "code": "1.465.6",
        "name": "HARINA Y LANA DE MADERA"
      },
      {
        "code": "1.465.9",
        "name": "OTROS OBJETOS MADERA NCOP"
      },
      {
        "code": "1.466",
        "name": "FAB. PRODUCTOS CORCHO"
      },
      {
        "code": "1.466.1",
        "name": "PRODUCTOS DE CORCHO"
      },
      {
        "code": "1.466.2",
        "name": "PTOS. RESIDUALES FAB. CORCHO"
      },
      {
        "code": "1.467",
        "name": "FAB. ART. JUNCO, CAÑA Y CESTERIA"
      },
      {
        "code": "1.467.1",
        "name": "ARTICULOS DE MATERIAS TRENZABLES"
      },
      {
        "code": "1.467.2",
        "name": "CEPILLOS, BROCHAS, ESCOBAS Y SIMILARES"
      },
      {
        "code": "1.468.1",
        "name": "FAB. MOBILIARIO DE MADERA PARA EL HOGAR"
      },
      {
        "code": "1.468.2",
        "name": "FAB. MOBILIARIO MADERA ESCOLAR Y OFICINA"
      },
      {
        "code": "1.468.3",
        "name": "FAB. MUEBLES DIV. MADERA, JUNCO, MIMBRE"
      },
      {
        "code": "1.468.4",
        "name": "FAB. ATAUDES"
      },
      {
        "code": "1.468.5",
        "name": "ACTIV. ANEXAS IND. MUEBLE"
      },
      {
        "code": "1.471",
        "name": "FAB. PASTA PAPELERA"
      },
      {
        "code": "1.471.1",
        "name": "PASTA PAPELERA"
      },
      {
        "code": "1.471.2",
        "name": "SUBPRODUCTOS PASTA PAPELERA"
      },
      {
        "code": "1.472",
        "name": "FAB. PAPEL Y CARTON"
      },
      {
        "code": "1.472.1",
        "name": "PAPEL Y CARTON"
      },
      {
        "code": "1.472.2",
        "name": "PTOS. RESIDUALES FAB. PAPEL Y CARTON"
      },
      {
        "code": "1.473.1",
        "name": "FAB. CARTON ONDULADO Y SUS ARTICULOS"
      },
      {
        "code": "1.473.2",
        "name": "FAB. OTROS ART. Y ENVASES PAPEL Y CARTON"
      },
      {
        "code": "1.473.3",
        "name": "FAB. ART. OFICINA, ESCRITORIO, EN PAPEL"
      },
      {
        "code": "1.473.4",
        "name": "FAB. ART. DECORACION EN PAPEL Y CARTON"
      },
      {
        "code": "1.473.9",
        "name": "FAB. OTROS MANIPULADOS PAPEL Y CARTON"
      },
      {
        "code": "1.474",
        "name": "ARTES GRAFICAS (IMPRESION GRAFICA)"
      },
      {
        "code": "1.474.1",
        "name": "IMPRESION TEXTOS E IMAGENES"
      },
      {
        "code": "1.474.2",
        "name": "IMPRESION DE PRENSA DIARIA"
      },
      {
        "code": "1.474.3",
        "name": "REPRODUCCION TEXTOS E IMAGENES"
      },
      {
        "code": "1.475",
        "name": "ACTIVIDADES ANEXAS ARTES GRAFICAS"
      },
      {
        "code": "1.475.1",
        "name": "ESTEREOTIPIA, GOMAS CAUCHO, RODILLOS..."
      },
      {
        "code": "1.475.2",
        "name": "COMPOSICION TEXTOS"
      },
      {
        "code": "1.475.3",
        "name": "REPRODUCCION TEXTOS"
      },
      {
        "code": "1.475.4",
        "name": "ENCUADERNACION"
      },
      {
        "code": "1.476.1",
        "name": "EDICION DE LIBROS"
      },
      {
        "code": "1.476.2",
        "name": "EDICION DE PERIODICOS Y REVISTAS"
      },
      {
        "code": "1.476.9",
        "name": "OTRAS EDICIONES NCOP"
      },
      {
        "code": "1.481.1",
        "name": "FAB. CUBIERTAS Y CAMARAS"
      },
      {
        "code": "1.481.2",
        "name": "RECAUCHUTADO Y RECONSTRUCCION CUBIERTAS"
      },
      {
        "code": "1.481.9",
        "name": "FAB. OTROS ART. CAUCHO NCOP"
      },
      {
        "code": "1.482.1",
        "name": "FAB. PTOS. SEMIELABORADOS PLASTICO"
      },
      {
        "code": "1.482.2",
        "name": "FAB. ART. ACABADOS MATERIAS PLASTICAS"
      },
      {
        "code": "1.491.1",
        "name": "JOYERIA"
      },
      {
        "code": "1.491.2",
        "name": "BISUTERIA"
      },
      {
        "code": "1.492",
        "name": "FAB. INSTRUMENTOS DE MUSICA"
      },
      {
        "code": "1.492.1",
        "name": "INSTRUMENTOS DE CUERDA DE TECLADO"
      },
      {
        "code": "1.492.2",
        "name": "INSTRUMENTOS DE VIENTO DE TECLADO"
      },
      {
        "code": "1.492.3",
        "name": "INSTRUMENTOS DE CUERDA"
      },
      {
        "code": "1.492.4",
        "name": "INSTRUMENTOS VIENTO"
      },
      {
        "code": "1.492.5",
        "name": "INSTRUMENTOS PERCUSION"
      },
      {
        "code": "1.492.6",
        "name": "INSTRUMENTOS MUSICALES ELECTRONICOS"
      },
      {
        "code": "1.492.7",
        "name": "OTROS INSTRUMENTOS MUSICALES"
      },
      {
        "code": "1.492.8",
        "name": "PARTES, PIEZAS SUELTAS INSTRUM.MUSICALES"
      },
      {
        "code": "1.493",
        "name": "LABORATORIOS FOTOGRAFICOS Y CINEMAT."
      },
      {
        "code": "1.493.1",
        "name": "PELICULAS Y COPIAS CINE REVELADAS"
      },
      {
        "code": "1.493.2",
        "name": "PLACAS, PELICULAS, NAGATIVAS Y DIAPOSIT."
      },
      {
        "code": "1.493.3",
        "name": "COPIAS FOTOGRAFICAS Y AMPLIACIONES"
      },
      {
        "code": "1.494.1",
        "name": "FAB. JUEGOS, JUGUETES Y ART. PUERICULT."
      },
      {
        "code": "1.494.2",
        "name": "FAB. ART. DEPORTE"
      },
      {
        "code": "1.495.1",
        "name": "FAB. ART. ESCRITORIO"
      },
      {
        "code": "1.495.9",
        "name": "FAB. OTROS ART. NCOP"
      },
      {
        "code": "1.501.1",
        "name": "CONSTRUCCION COMPLETA, REPAR. Y CONSERV."
      },
      {
        "code": "1.501.2",
        "name": "CONSTRUCC. COMPLETA OBRAS CIVILES"
      },
      {
        "code": "1.501.3",
        "name": "ALBAÑILERIA Y PEQ. TRABAJOS CONSTRUCCION"
      },
      {
        "code": "1.502.1",
        "name": "DEMOLICIONES Y DERRIBOS EN GENERAL"
      },
      {
        "code": "1.502.2",
        "name": "CONSOLIDACION Y PREPARACION DE TERRENOS"
      },
      {
        "code": "1.502.3",
        "name": "CONSOLID. Y PREP. TERRENOS OBRAS CIVILES"
      },
      {
        "code": "1.502.4",
        "name": "CIMENTACIONES Y PAVIMENTACIONES"
      },
      {
        "code": "1.502.5",
        "name": "CIMENTACIONES Y PAVIMENTAC.OBRAS CIVILES"
      },
      {
        "code": "1.502.6",
        "name": "PERFORACIONES ALUMBRAMIENTO DE AGUAS"
      },
      {
        "code": "1.503.1",
        "name": "PREP. MONTAJE ESTRUCTURAS Y CUBIERTAS"
      },
      {
        "code": "1.503.2",
        "name": "PREP. ESTRUC. CUBIERTAS EN OBRAS CIVILES"
      },
      {
        "code": "1.503.3",
        "name": "MONTAJE ESTRUC. METALICAS TTE. Y OBRAS"
      },
      {
        "code": "1.503.4",
        "name": "OBRAS SIN MAQUINAS Y SIN MAS 4 OBREROS"
      },
      {
        "code": "1.504.1",
        "name": "INSTALACIONES ELECTRICAS EN GENERAL"
      },
      {
        "code": "1.504.2",
        "name": "INSTALACIONES FONTANERIA"
      },
      {
        "code": "1.504.3",
        "name": "INSTALACIONES FRIO Y CALOR"
      },
      {
        "code": "1.504.4",
        "name": "INSTALACIONES PARARRAYOS"
      },
      {
        "code": "1.504.5",
        "name": "INSTALACION COCINAS"
      },
      {
        "code": "1.504.6",
        "name": "INSTALACION APARATOS ELEVADORES"
      },
      {
        "code": "1.504.7",
        "name": "INSTALACIONES TELEFONICAS"
      },
      {
        "code": "1.504.8",
        "name": "MONTAJES METALICOS E INSTALAC. INDUSTR."
      },
      {
        "code": "1.505.1",
        "name": "REVESTIMIENTOS EXTERIORES E INTERIORES"
      },
      {
        "code": "1.505.2",
        "name": "SOLADOS Y PAVIMENTOS"
      },
      {
        "code": "1.505.3",
        "name": "SOLADOS Y PAVIMENTOS DE MADERA"
      },
      {
        "code": "1.505.4",
        "name": "COLOCACION DE AISLAMIENTOS EN EDIFICIOS"
      },
      {
        "code": "1.505.5",
        "name": "CARPINTERIA Y CERRAJERIA"
      },
      {
        "code": "1.505.6",
        "name": "PINTURA, Y REVESTIMIENTOS EN PAPEL"
      },
      {
        "code": "1.505.7",
        "name": "ESCAYOLA Y YESOS"
      },
      {
        "code": "1.506",
        "name": "INSTALACION DE ANDAMIOS, CIMBRAS, ETC."
      },
      {
        "code": "1.507",
        "name": "CONSTRUCCION DE TODA CLASE DE OBRAS"
      },
      {
        "code": "1.508",
        "name": "AGRUPACIONES Y UNIONES TEMPOR. EMPRESAS"
      },
      {
        "code": "1.611",
        "name": "COM. MAY. TODA CLASE DE MERCANCIAS"
      },
      {
        "code": "1.612.1",
        "name": "COM.MAY.PTOS.ALIMENTIC.BEBIDAS Y TABACOS"
      },
      {
        "code": "1.612.2",
        "name": "COM.MAY.CEREALES,PLANTAS,ABONOS,ANIMALES"
      },
      {
        "code": "1.612.3",
        "name": "COM.MAY.FRUTAS Y VERDURAS"
      },
      {
        "code": "1.612.4",
        "name": "COM.MAY. CARNES,HUEVOS,AVES Y CAZA"
      },
      {
        "code": "1.612.5",
        "name": "COM.MAY.LECHE,PTOS.LACTEOS, MIEL, ACEITE"
      },
      {
        "code": "1.612.6",
        "name": "COM.MAY.BEBIDAS Y TABACO"
      },
      {
        "code": "1.612.7",
        "name": "COM.MAY. VINOS Y VINAGRES DEL PAIS"
      },
      {
        "code": "1.612.8",
        "name": "COM.MAY. PESCADOS"
      },
      {
        "code": "1.612.9",
        "name": "COM.MAY.OTROS PTOS. ALIMENT.,HELADOS ETC"
      },
      {
        "code": "1.613.1",
        "name": "COM.MAY.PTOS.TEXTILES,CONFECC, Y CALZADO"
      },
      {
        "code": "1.613.2",
        "name": "COM.MAY.TEJIDOS POR METROS, ALFOMBRAS"
      },
      {
        "code": "1.613.3",
        "name": "COM.MAY. PRENDAS EXTERIORES VESTIR"
      },
      {
        "code": "1.613.4",
        "name": "COM.MAY.CALZADO,PELETERIA,MARROQUINERIA"
      },
      {
        "code": "1.613.5",
        "name": "COM.MAY.CAMISERIA,LENCERIA,MERCERIA,..."
      },
      {
        "code": "1.613.9",
        "name": "COM.MAY.ACCESORIOS VESTIDO NCOP"
      },
      {
        "code": "1.614.1",
        "name": "COM.MAY.PTOS.FARMACEUTICOS Y MEDICAMENTO"
      },
      {
        "code": "1.614.2",
        "name": "COM.MAY.PTOS.PERFUMERIA,DROGUERIA"
      },
      {
        "code": "1.614.3",
        "name": "COM.MAY.PTOS.MANTENIMIENTO HOGAR"
      },
      {
        "code": "1.614.4",
        "name": "COM. MAY. PRODUCTOS ZOOSANITARIOS"
      },
      {
        "code": "1.615.1",
        "name": "COM.MAY.VEHICULOS Y SUS ACCESORIOS"
      },
      {
        "code": "1.615.2",
        "name": "COM.MAY. DE MUEBLES"
      },
      {
        "code": "1.615.3",
        "name": "COM. MAY. APARATOS ELECTRODOMESTICOS"
      },
      {
        "code": "1.615.4",
        "name": "COM.MAY.APAR.Y MAT. ELECTRONICO"
      },
      {
        "code": "1.615.5",
        "name": "COMERCIO AL POR MAYOR DE OBRAS DE ARTE"
      },
      {
        "code": "1.615.6",
        "name": "GALERIAS DE ARTE"
      },
      {
        "code": "1.615.9",
        "name": "COM.MAY.OTROS ART. CONSUMO DURADERO NCOP"
      },
      {
        "code": "1.616.1",
        "name": "COM.MAY. CARBON"
      },
      {
        "code": "1.616.2",
        "name": "COM.MAY. HIERRO Y ACERO"
      },
      {
        "code": "1.616.3",
        "name": "COM.MAY. MINERALES"
      },
      {
        "code": "1.616.4",
        "name": "COM.MAY. METALES NO FERREOS"
      },
      {
        "code": "1.616.5",
        "name": "COM.MAY. PETROLEO Y LUBRICANTES"
      },
      {
        "code": "1.616.6",
        "name": "COM.MAY.PTOS. QUIMICOS INDUSTRIALES"
      },
      {
        "code": "1.616.9",
        "name": "COM.MAY.PTOS.INTERINDUSTRIALES Y QUIMIC."
      },
      {
        "code": "1.617.1",
        "name": "COM.MAY.FIBRAS TEXTILES BRUTAS"
      },
      {
        "code": "1.617.2",
        "name": "COM.MAY. CUEROS Y PIELES EN BRUTO"
      },
      {
        "code": "1.617.3",
        "name": "COM.MAY. MADERA Y CORCHO"
      },
      {
        "code": "1.617.4",
        "name": "COM.MAY. MATERIALES CONSTRUCCION"
      },
      {
        "code": "1.617.5",
        "name": "COM.MAY. MAQUINARIA PARA MADERA Y METAL"
      },
      {
        "code": "1.617.6",
        "name": "COM.MAY. MAQUINARIA AGRICOLA"
      },
      {
        "code": "1.617.7",
        "name": "COM.MAY. MAQUINARIA TEXTIL"
      },
      {
        "code": "1.617.8",
        "name": "COM.MAY. MAQUINAS Y MATERIAL OFICINA"
      },
      {
        "code": "1.617.9",
        "name": "COM.MAY.INTERINDUSTRIAL EXCEP. QUIMICA"
      },
      {
        "code": "1.618.1",
        "name": "EXPORTACION TODA CLASE MERCANCIAS"
      },
      {
        "code": "1.618.2",
        "name": "COM.MAY.MERCANCIAS EN ZONAS FRANCAS"
      },
      {
        "code": "1.619.1",
        "name": "COM.MAY. JUGUETES Y ARTICULOS DEPORTE"
      },
      {
        "code": "1.619.2",
        "name": "COM.MAY. INSTRUM. MEDICOS Y ORTOPEDICOS"
      },
      {
        "code": "1.619.3",
        "name": "COM.MAY. METALES PRECIOSOS, Y JOYERIA"
      },
      {
        "code": "1.619.4",
        "name": "COM.MAY.PTOS. DE PAPEL Y CARTON"
      },
      {
        "code": "1.619.5",
        "name": "COM.MAY.ART.PAPELERIA Y ESCRITORIO"
      },
      {
        "code": "1.619.6",
        "name": "COM.MAY. LIBROS, PERIODICOS Y REVISTAS"
      },
      {
        "code": "1.619.7",
        "name": "COM.MAY.INSTRUM.PRECISION Y MEDIDA"
      },
      {
        "code": "1.619.8",
        "name": "COMPRA-VENTA DE GANADO"
      },
      {
        "code": "1.619.9",
        "name": "COM.MAY.OTROS PRODUCTOS NCOP"
      },
      {
        "code": "1.621",
        "name": "COM.MAY.CHATARRA Y METALES DESECHO"
      },
      {
        "code": "1.622",
        "name": "COM.MAY.OTROS PTOS. RECUPERACION"
      },
      {
        "code": "1.623",
        "name": "RECUPERACION Y COM. RESIDUOS SIN ESTABL."
      },
      {
        "code": "1.631",
        "name": "INTERMEDIARIOS DEL COMERCIO"
      },
      {
        "code": "1.641",
        "name": "COM.MEN.FRUTAS,VERDURAS"
      },
      {
        "code": "1.642.1",
        "name": "COM.MEN.CARNES,HUEVOS,CAZA Y GRANJA"
      },
      {
        "code": "1.642.2",
        "name": "COM.MEN.CARNICERIAS-CHARCUTERIAS"
      },
      {
        "code": "1.642.3",
        "name": "COM.MEN.CARNICERIAS-SALCHICHERIAS"
      },
      {
        "code": "1.642.4",
        "name": "COM.MEN.CARNICERIAS"
      },
      {
        "code": "1.642.5",
        "name": "COM.MEN.HUEVOS,AVES,GRANJA Y CAZA"
      },
      {
        "code": "1.642.6",
        "name": "COM.MEN.CASQUERIAS"
      },
      {
        "code": "1.643.1",
        "name": "COM.MEN.PESCADOS"
      },
      {
        "code": "1.643.2",
        "name": "COM.MEN.BACALAO Y SALAZONES"
      },
      {
        "code": "1.644.1",
        "name": "COM.MEN.PAN,PASTELES,CONFITERIA,LACTEOS"
      },
      {
        "code": "1.644.2",
        "name": "DESPACHOS PAN, PAN ESPECIAL, Y BOLLERIA"
      },
      {
        "code": "1.644.3",
        "name": "COM.MEN.PTOS.PASTELERIA,BOLLERIA"
      },
      {
        "code": "1.644.4",
        "name": "COM.MEN.HELADOS"
      },
      {
        "code": "1.644.5",
        "name": "COM.MEN.BOMBONES Y CARAMELOS"
      },
      {
        "code": "1.644.6",
        "name": "COM.MEN.MASAS FRITAS"
      },
      {
        "code": "1.645",
        "name": "COM.MEN.VINOS Y BEBIDAS"
      },
      {
        "code": "1.646.1",
        "name": "COM.MEN.TABACOS EN EXPENDIDURIA"
      },
      {
        "code": "1.646.2",
        "name": "COM.MEN.TABACO EXTENSIONES TRANSITORIAS"
      },
      {
        "code": "1.646.3",
        "name": "COM.MEN.TABACO EXPENDIDURIAS COMPLEMENT."
      },
      {
        "code": "1.646.4",
        "name": "COM.MEN.TABACO CON VENTA POR RECARGO"
      },
      {
        "code": "1.646.5",
        "name": "COM.MEN.TABACO MAQUINAS AUTOMATICAS"
      },
      {
        "code": "1.646.6",
        "name": "COM.MEN.TABACOS VENTA NO ESTANCADA"
      },
      {
        "code": "1.646.7",
        "name": "COM.MEN.ARTICULOS FUMADORES POR MINUSV."
      },
      {
        "code": "1.646.8",
        "name": "COM.MEN. ARTICULOS PARA FUMADORES"
      },
      {
        "code": "1.647.1",
        "name": "COM.MEN.PTOS.ALIMENTICIOS Y BEBIDAS"
      },
      {
        "code": "1.647.2",
        "name": "COM.MEN.PTOS.ALIMENTICIOS MENOS 120 M2"
      },
      {
        "code": "1.647.3",
        "name": "COM.MEN.PTOS.ALIMENTICIOS 120 - 399 M2"
      },
      {
        "code": "1.647.4",
        "name": "COM.MEN.PTOS.ALIMENTICIOS SUP.400 M2"
      },
      {
        "code": "1.647.5",
        "name": "PTOS.ALIMENTICIOS Y BEB. MAQUINAS"
      },
      {
        "code": "1.651.1",
        "name": "COM.MEN.PTOS.TEXTILES PARA EL HOGAR"
      },
      {
        "code": "1.651.2",
        "name": "COM.MEN.PRENDAS DE VESTIR Y TOCADO"
      },
      {
        "code": "1.651.3",
        "name": "COM.MEN.LENCERIA Y CORSETERIA"
      },
      {
        "code": "1.651.4",
        "name": "COM.MEN.MERCERIA Y PAQUETERIA"
      },
      {
        "code": "1.651.5",
        "name": "COM.MEN.PRENDAS ESPECIALES"
      },
      {
        "code": "1.651.6",
        "name": "COM.MEN.CALZADO Y COMPLEMENTOS PIEL"
      },
      {
        "code": "1.651.7",
        "name": "COM.MEN.CONFECCIONES DE PELETERIA"
      },
      {
        "code": "1.652.1",
        "name": "FARMACIAS"
      },
      {
        "code": "1.652.2",
        "name": "COM.MEN.PTOS.DROGUERIA,PERFUMERIA"
      },
      {
        "code": "1.652.3",
        "name": "COM.MEN.PTOS.PERFUMERIA Y COSMETICA"
      },
      {
        "code": "1.652.4",
        "name": "COM.MEN.PLANTAS Y HIERBAS,HERBOLARIOS"
      },
      {
        "code": "1.653.1",
        "name": "COM.MEN.MUEBLES (EXCEPTO OFICINA)"
      },
      {
        "code": "1.653.2",
        "name": "COM.MEN.APARATOS DE USO DOMESTICO"
      },
      {
        "code": "1.653.3",
        "name": "COM.MEN.ART.MENAJE,FERRETERIA,ADORNO"
      },
      {
        "code": "1.653.4",
        "name": "COM.MEN.MATERIALES DE CONSTRUCCION"
      },
      {
        "code": "1.653.5",
        "name": "COM.MEN.PUERTAS,VENTANAS Y PERSIANAS"
      },
      {
        "code": "1.653.6",
        "name": "COM. MEN. ARTICULOS DE BRICOLAGE"
      },
      {
        "code": "1.653.9",
        "name": "COM.MEN.ART.HOGAR NCOP"
      },
      {
        "code": "1.654.1",
        "name": "COM.MEN.VEHICULOS TERRESTRES"
      },
      {
        "code": "1.654.2",
        "name": "COM.MEN.ACCESORIOS Y RECAMBIOS VEHICULOS"
      },
      {
        "code": "1.654.3",
        "name": "COM.MEN.VEHICULOS AEREOS"
      },
      {
        "code": "1.654.4",
        "name": "COM.MEN.VEHICULOS FLUVIALES Y MARITIMOS"
      },
      {
        "code": "1.654.5",
        "name": "COM.MEN.DE TODA CLASE DE MAQUINARIA"
      },
      {
        "code": "1.654.6",
        "name": "COM.MEN.CUBIERTAS,BANDAS Y CAMARAS AIRE"
      },
      {
        "code": "1.655.1",
        "name": "COM.MEN.COMBUSTIBLES TODAS CLASES"
      },
      {
        "code": "1.655.2",
        "name": "COM.MEN.GASES COMBUSTIBLES"
      },
      {
        "code": "1.655.3",
        "name": "COM.MEN.CARBURANTES Y ACEITES VEHICULOS"
      },
      {
        "code": "1.656",
        "name": "COM. MEN. DE BIENES USADOS"
      },
      {
        "code": "1.657",
        "name": "COM.MEN. INSTRUMENTOS MUSICA Y ACCESORIO"
      },
      {
        "code": "1.659.1",
        "name": "COM.MEN.SELLOS,MONEDAS,MEDALLAS,COLECC."
      },
      {
        "code": "1.659.2",
        "name": "COM.MEN.MUEBLES Y MAQUINAS DE OFICINA"
      },
      {
        "code": "1.659.3",
        "name": "COM.MEN.APARATOS MEDICOS, ORTOPEDICOS"
      },
      {
        "code": "1.659.4",
        "name": "COM.MEN.LIBROS,PERIODICOS,REVISTAS."
      },
      {
        "code": "1.659.5",
        "name": "COM.MEN.ART.JOYERIA,RELOJERIA,BISUTERIA"
      },
      {
        "code": "1.659.6",
        "name": "COM.MEN.JUGUETES,ART.DEPORTE,ARMAS..."
      },
      {
        "code": "1.659.7",
        "name": "COM.MEN.SEMILLAS,ABONOS,FLORES,PLANTAS"
      },
      {
        "code": "1.659.8",
        "name": "COM.MEN.\"SEX-SHOP\""
      },
      {
        "code": "1.659.9",
        "name": "COM.MEN.OTROS PTOS. NCOP"
      },
      {
        "code": "1.661.1",
        "name": "COM.MEN.GRANDES ALMACENES"
      },
      {
        "code": "1.661.2",
        "name": "COM.MEN.EN HIPERMERCADOS"
      },
      {
        "code": "1.661.3",
        "name": "COM.MEN.ALMACENES POPULARES"
      },
      {
        "code": "1.662.1",
        "name": "COM.MEN.EN ECONOMATOS Y COOP. DE CONSUMO"
      },
      {
        "code": "1.662.2",
        "name": "COM.MEN.TODA CLASE ART. EN OTROS LOCALES"
      },
      {
        "code": "1.663.1",
        "name": "COM.MEN.PTOS.ALIMENTICIOS SIN ESTABLEC."
      },
      {
        "code": "1.663.2",
        "name": "COM.MEN.TEXTILES Y CONFECC.SIN ESTABLEC."
      },
      {
        "code": "1.663.3",
        "name": "COM.MEN.CALZADO SIN ESTABLECIMIENTO"
      },
      {
        "code": "1.663.4",
        "name": "COM.MEN.DROGUERIA SIN ESTABLECIMIENTO"
      },
      {
        "code": "1.663.9",
        "name": "COM.MEN.OTRAS MERCANCIAS SIN ESTABLECIM."
      },
      {
        "code": "1.664.1",
        "name": "VENTA ARTICULOS DIVERSOS EN EXPOSITORES"
      },
      {
        "code": "1.664.9",
        "name": "COM.MEN.ART.DIVERSOS EN MAQUINAS"
      },
      {
        "code": "1.665",
        "name": "COM.MEN.POR CORREO O CATALOGO"
      },
      {
        "code": "1.671.1",
        "name": "RESTAURANTES CINCO TENEDORES"
      },
      {
        "code": "1.671.2",
        "name": "RESTAURANTES CUATRO TENEDORES"
      },
      {
        "code": "1.671.3",
        "name": "RESTAURANTES DE TRES TENEDORES"
      },
      {
        "code": "1.671.4",
        "name": "RESTAURANTES DE DOS TENEDORES"
      },
      {
        "code": "1.671.5",
        "name": "RESTAURANTES DE UN TENEDOR"
      },
      {
        "code": "1.672.1",
        "name": "CAFETERIAS TRES TAZAS"
      },
      {
        "code": "1.672.2",
        "name": "CAFETERIAS DOS TAZAS"
      },
      {
        "code": "1.672.3",
        "name": "CAFETERIAS DE UNA TAZA"
      },
      {
        "code": "1.673.1",
        "name": "BARES CATEGORIA ESPECIAL"
      },
      {
        "code": "1.673.2",
        "name": "OTROS CAFES Y BARES"
      },
      {
        "code": "1.674.1",
        "name": "CAFE-BAR EN VEHICULO"
      },
      {
        "code": "1.674.2",
        "name": "CAFE-BAR EN FERROCARRILES"
      },
      {
        "code": "1.674.3",
        "name": "CAFE-BAR EN BARCOS"
      },
      {
        "code": "1.674.4",
        "name": "CAFE-BAR AERONAVES"
      },
      {
        "code": "1.674.5",
        "name": "CAFE-BAR SOCIEDADES,CASINOS,CLUBES..."
      },
      {
        "code": "1.674.6",
        "name": "CAFE-BAR TEATROS Y CINES"
      },
      {
        "code": "1.674.7",
        "name": "SERVICIOS EN PARQUES O RECINTOS FERIALES"
      },
      {
        "code": "1.675",
        "name": "CAFES-BARES EN QUIOSCOS,CAJONES,BARRACAS"
      },
      {
        "code": "1.676",
        "name": "CHOCOLATERIAS,HELADERIAS Y HORCHATERIAS"
      },
      {
        "code": "1.677.1",
        "name": "HOSTELERIA Y RESTAURACION SIN ESTABLEC."
      },
      {
        "code": "1.677.9",
        "name": "OTROS SERV. ALIMENTACION - RESTAURACION"
      },
      {
        "code": "1.681",
        "name": "HOSPEDAJE EN HOTELES Y MOTELES"
      },
      {
        "code": "1.682",
        "name": "HOSPEDAJE EN HOSTALES Y PENSIONES"
      },
      {
        "code": "1.683",
        "name": "HOSPEDAJES EN FONDAS Y CASAS HUESPEDES"
      },
      {
        "code": "1.684",
        "name": "HOSPEDAJE EN APARTA-HOTELES"
      },
      {
        "code": "1.685",
        "name": "ALOJAMIENTOS TURISTICOS EXTRAHOTELEROS"
      },
      {
        "code": "1.686",
        "name": "EXPLOTACION APARTAMENTOS PRIVADOS"
      },
      {
        "code": "1.687.1",
        "name": "CAMPAMENTOS DE LUJO"
      },
      {
        "code": "1.687.2",
        "name": "CAMPAMENTOS DE PRIMERA CLASE"
      },
      {
        "code": "1.687.3",
        "name": "CAMPAMENTOS DE SEGUNDA CLASE"
      },
      {
        "code": "1.687.4",
        "name": "CAMPAMENTOS DE TERCERA CLASE"
      },
      {
        "code": "1.691.1",
        "name": "REPARACION ART. ELECTRODOMESTICOS"
      },
      {
        "code": "1.691.2",
        "name": "REPARACION AUTOMOVILES Y BICICLETAS"
      },
      {
        "code": "1.691.9",
        "name": "REPARACION OTROS BIENES CONSUMO NCOP"
      },
      {
        "code": "1.692",
        "name": "REPARACION DE MAQUINARIA INDUSTRIAL"
      },
      {
        "code": "1.699",
        "name": "OTRAS REPARACIONES NCOP"
      },
      {
        "code": "1.711",
        "name": "TTE. FERROCARRIL DE VIA NORMAL"
      },
      {
        "code": "1.711.1",
        "name": "TTE.FERROVIARIO DE VIAJEROS VIA NORMAL"
      },
      {
        "code": "1.711.2",
        "name": "TTE.FERROVIARIO MERCACIAS VIA NORMAL"
      },
      {
        "code": "1.712",
        "name": "TTE. FERROVIARIO DE VIA ESTRECHA"
      },
      {
        "code": "1.712.1",
        "name": "TTE.FERROVIARIO VIAJEROS VIA ESTRECHA"
      },
      {
        "code": "1.712.2",
        "name": "TTE.FERROVIARIO MERCANCIAS VIA ESTRECHA"
      },
      {
        "code": "1.721.1",
        "name": "TTE. URBANO COLECTIVO"
      },
      {
        "code": "1.721.2",
        "name": "TTE. POR AUTOTAXIS"
      },
      {
        "code": "1.721.3",
        "name": "TTE. VIAJEROS POR CARRETERA"
      },
      {
        "code": "1.721.4",
        "name": "TRANSPORTE SANITARIO EN AMBULANCIAS"
      },
      {
        "code": "1.722",
        "name": "TTE. MERCANCIAS POR CARRETERA"
      },
      {
        "code": "1.729",
        "name": "OTROS TTES. TERRESTRES NCOP"
      },
      {
        "code": "1.729.1",
        "name": "TTE. FERROCARRIL DE CREMALLERA"
      },
      {
        "code": "1.729.2",
        "name": "TTE. TELEFERICOS Y FUNICULARES"
      },
      {
        "code": "1.729.3",
        "name": "OTROS SERVICIOS DE TTE. TERRESTRE"
      },
      {
        "code": "1.731.1",
        "name": "TTE. MARITIMO INTERNACIONAL PASAJEROS"
      },
      {
        "code": "1.731.2",
        "name": "TTE. MARITIMO INTERNACIONAL MERCANCIAS"
      },
      {
        "code": "1.732",
        "name": "TTE. MARITIMO DE CRUDOS Y GASES"
      },
      {
        "code": "1.732.1",
        "name": "TTE. MARITIMO INTERNACIONAL PTOS PETROL."
      },
      {
        "code": "1.732.2",
        "name": "TTE. CABOTAJE PTOS. PETROLIFEROS Y GAS"
      },
      {
        "code": "1.732.3",
        "name": "TTE. VIAS NAVEGABLES INTERIOR PETROL Y G"
      },
      {
        "code": "1.733.1",
        "name": "TTE. CABOTAJE Y VIAS INTERIOR VIAJEROS"
      },
      {
        "code": "1.733.2",
        "name": "TTE. CABOTAJE Y VIAS INTERIOR MERCANCIAS"
      },
      {
        "code": "1.733.3",
        "name": "TRANSBORDADORES, FERRY BOATS Y SIMIL"
      },
      {
        "code": "1.733.4",
        "name": "TTE.MARITIMO PASAJEROS EN JET-FOIL"
      },
      {
        "code": "1.741.1",
        "name": "TTE. AEREO NACIONAL DE VIAJEROS"
      },
      {
        "code": "1.741.2",
        "name": "TTE. AEREO NACIONAL MERCANCIAS"
      },
      {
        "code": "1.741.3",
        "name": "TTE. AEREO INTERNACIONAL DE VIAJEROS"
      },
      {
        "code": "1.741.4",
        "name": "TTE. AEREO INTERNACIONAL MERCANCIAS"
      },
      {
        "code": "1.742.1",
        "name": "TTE. AEREO NACIONAL VIAJEROS"
      },
      {
        "code": "1.742.2",
        "name": "TTE. AEREO NACIONAL DE MERCANCIAS"
      },
      {
        "code": "1.742.3",
        "name": "TTE. AEREO INTERNAC.VIAJEROS CHARTER"
      },
      {
        "code": "1.742.4",
        "name": "TTE. AEREO INTERNAC. MERCANCIAS CHARTER"
      },
      {
        "code": "1.751.1",
        "name": "GUARDIA Y CUSTODIA VEHICULOS EN GARAJES"
      },
      {
        "code": "1.751.2",
        "name": "GUARDIA Y CUSTODIA VEHICULOS PARKING"
      },
      {
        "code": "1.751.3",
        "name": "GUARDIA Y CUSTODIA VEHICULOS EN SOLARES"
      },
      {
        "code": "1.751.4",
        "name": "EXPLOTACION AUTOPISTAS Y TUNELES PEAJE"
      },
      {
        "code": "1.751.5",
        "name": "ENGRASE Y LAVADO DE VEHICULOS"
      },
      {
        "code": "1.751.6",
        "name": "SERVICIOS DE CARGA Y DESCARGA MERCANCIAS"
      },
      {
        "code": "1.752.1",
        "name": "SERVICIOS PILOTAJE Y PRACTICOS PUERTOS"
      },
      {
        "code": "1.752.2",
        "name": "SERVICIOS TRANSBORDO DE BARCOS"
      },
      {
        "code": "1.752.3",
        "name": "SERVICIOS DE REMOLQUE DE NAVIOS"
      },
      {
        "code": "1.752.4",
        "name": "SERVICIOS LIMPIEZA Y DESINFECCION BARCOS"
      },
      {
        "code": "1.752.5",
        "name": "SERV. SALVAMENTO Y RECUPERACION BARCOS"
      },
      {
        "code": "1.752.6",
        "name": "SERVICIOS CARGA Y DESCARGA DE BUQUES"
      },
      {
        "code": "1.752.7",
        "name": "EXPLOTACION DE PUERTOS,CANALES Y DIQUES"
      },
      {
        "code": "1.752.8",
        "name": "SERV. SEÑALES MARITIMAS Y COSTERAS"
      },
      {
        "code": "1.753.1",
        "name": "TERMINALES LINEAS TTE. AEREO AEROPUERTOS"
      },
      {
        "code": "1.753.2",
        "name": "SERVICIO DE CONTROL DE NAVEGACION AEREA"
      },
      {
        "code": "1.753.3",
        "name": "SERV. HANGARES Y ESTACIONAMIENTO AVIONES"
      },
      {
        "code": "1.753.4",
        "name": "SERV. REMOLQUE, Y MANTENIMIENTO AVIONES"
      },
      {
        "code": "1.753.5",
        "name": "EXPLOTACION INTEGRAL DE AEROPUERTOS"
      },
      {
        "code": "1.753.9",
        "name": "OTROS SERV. ANEXOS AL TTE. AEREO NCOP"
      },
      {
        "code": "1.754.1",
        "name": "DEPOSITOS Y ALMACENES GENERALES"
      },
      {
        "code": "1.754.2",
        "name": "DEPOSITOS Y ALMACENES DE VEHICULOS"
      },
      {
        "code": "1.754.3",
        "name": "SILOS Y OTROS ALMACENES DE GRANOS"
      },
      {
        "code": "1.754.4",
        "name": "ALMACENES FRIGORIFICOS"
      },
      {
        "code": "1.754.5",
        "name": "ALMACENES Y DEPOSITOS DE LIQUIDOS"
      },
      {
        "code": "1.754.6",
        "name": "GUARDAMUEBLES"
      },
      {
        "code": "1.754.9",
        "name": "OTROS DEPOSITOS ESPECIALES NCOP"
      },
      {
        "code": "1.755",
        "name": "AGENCIAS DE VIAJES"
      },
      {
        "code": "1.755.1",
        "name": "SERVICIOS A OTRAS AGENCIAS DE VIAJES"
      },
      {
        "code": "1.755.2",
        "name": "SERV. AL PUBLICO DE AGENCIAS DE VIAJES"
      },
      {
        "code": "1.756",
        "name": "ACTIV. AUXILIARES Y COMPLEMENTARIAS TTE."
      },
      {
        "code": "1.756.1",
        "name": "AGENCIAS DE TTE., TRANSITARIOS"
      },
      {
        "code": "1.756.2",
        "name": "CONSIGNATARIOS DE BUQUES"
      },
      {
        "code": "1.756.9",
        "name": "OTROS SERVICIOS DE MEDIACION DEL TTE."
      },
      {
        "code": "1.757",
        "name": "SERVICIO DE MUDANZAS"
      },
      {
        "code": "1.761.1",
        "name": "SERVICIOS DE TELEFONIA FIJA"
      },
      {
        "code": "1.761.2",
        "name": "SERVICIOS DE TELEFONIA MOVIL"
      },
      {
        "code": "1.769",
        "name": "OTROS SERVICIOS DE TELECOMUNICACION"
      },
      {
        "code": "1.769.1",
        "name": "TELECOMUNICACION MOVIL"
      },
      {
        "code": "1.769.2",
        "name": "TELETRANSMISION DE DATOS"
      },
      {
        "code": "1.769.3",
        "name": "TELECOMUNICACION VIA SATELITE"
      },
      {
        "code": "1.769.9",
        "name": "OTROS SERV. PRIVADOS TELECOMUNICACION"
      },
      {
        "code": "1.811",
        "name": "BANCA"
      },
      {
        "code": "1.812",
        "name": "CAJAS DE AHORRO"
      },
      {
        "code": "1.819.1",
        "name": "INSTITUCIONES DE CREDITO"
      },
      {
        "code": "1.819.2",
        "name": "ESTABLECIMIENTOS FINANCIEROS DE CREDITO"
      },
      {
        "code": "1.819.3",
        "name": "ESTABLEC.FINANCIEROS CREDITO \"PRESTAMOS\""
      },
      {
        "code": "1.819.4",
        "name": "ESTABLEC.FINANCIEROS CREDITO \"FACTORING\""
      },
      {
        "code": "1.819.5",
        "name": "ESTABLEC.FINANCIEROS CREDITO \"ARRENDAM.\""
      },
      {
        "code": "1.819.6",
        "name": "ENTIDADES DE CAMBIO DE MONEDA"
      },
      {
        "code": "1.819.9",
        "name": "OTRAS ENTIDADES FINANCIERAS NCOP"
      },
      {
        "code": "1.821",
        "name": "ENTIDADES ASEGURADORES DE VIDA Y CAPIT."
      },
      {
        "code": "1.821.1",
        "name": "SEGUROS DE VIDA"
      },
      {
        "code": "1.821.2",
        "name": "SEGUROS DE CAPITALIZACION"
      },
      {
        "code": "1.821.3",
        "name": "SEGUROS MIXTOS DE VIDA Y CAPITALIZACION"
      },
      {
        "code": "1.822",
        "name": "ASEGURADORAS DE ENFERMEDAD Y RIESGOS"
      },
      {
        "code": "1.822.1",
        "name": "SEGUROS DE ASISTENCIA SANITARIA"
      },
      {
        "code": "1.822.2",
        "name": "SEGUROS DE ENTIERRO"
      },
      {
        "code": "1.822.3",
        "name": "SEGUROS DE DAåOS MATERIALES"
      },
      {
        "code": "1.822.4",
        "name": "SEGUROS DE TRANSPORTES"
      },
      {
        "code": "1.822.9",
        "name": "OTROS SEGUROS"
      },
      {
        "code": "1.823",
        "name": "OTRAS ENTIDADES ASEGURADORAS"
      },
      {
        "code": "1.831.1",
        "name": "SERV.COMPRA-VTA. DE VALORES MOBILIARIOS"
      },
      {
        "code": "1.831.2",
        "name": "SER. FINANC. CONTRATACION PRODUCTOS"
      },
      {
        "code": "1.831.3",
        "name": "SERV. DE COMPENSACION BANCARIA"
      },
      {
        "code": "1.831.9",
        "name": "OTROS SERVICIOS FINANCIEROS NCOP"
      },
      {
        "code": "1.832.1",
        "name": "AGENCIAS DE SEGUROS Y CORREDURIAS"
      },
      {
        "code": "1.832.2",
        "name": "SERV. TASACION Y TARIFICACION SEGUROS"
      },
      {
        "code": "1.832.9",
        "name": "OTROS SERV. AUXILIARES DE SEGUROS"
      },
      {
        "code": "1.833.1",
        "name": "PROMOCION INMOBILIARIA DE TERRENOS"
      },
      {
        "code": "1.833.2",
        "name": "PROMOCION INMOBILIARIA DE EDIFICACIONES"
      },
      {
        "code": "1.834",
        "name": "SERV. PROPIEDAD INMOBILIARIA E INDUSTRIA"
      },
      {
        "code": "1.841",
        "name": "SERVICIOS JURIDICOS"
      },
      {
        "code": "1.842",
        "name": "SERVICIOS FINANCIEROS Y CONTABLES"
      },
      {
        "code": "1.843.1",
        "name": "SERVICIOS TECNICOS DE INGENIERIA"
      },
      {
        "code": "1.843.2",
        "name": "SERV. TEC. DE ARQUITECTURA"
      },
      {
        "code": "1.843.3",
        "name": "SERV. TEC. PROSPECCIONES Y GEOLOGIA"
      },
      {
        "code": "1.843.4",
        "name": "SERV. TECNICOS DE TOPOGRAFIA"
      },
      {
        "code": "1.843.5",
        "name": "SERV. TECNICOS DE DELINEACION"
      },
      {
        "code": "1.843.6",
        "name": "INSPECCION TECNICA DE VEHICULOS"
      },
      {
        "code": "1.843.9",
        "name": "OTROS SERVICIOS TECNICOS NCOP"
      },
      {
        "code": "1.844",
        "name": "SERVICIOS PUBLICIDAD, RELACIONES PUBLIC."
      },
      {
        "code": "1.845",
        "name": "EXPLOTACION ELECTRONICA POR TERCEROS"
      },
      {
        "code": "1.846",
        "name": "EMPRESAS DE ESTUDIO DE MERCADO"
      },
      {
        "code": "1.847",
        "name": "SERV.INTEGRALES DE CORREOS Y TELECOMUNIC"
      },
      {
        "code": "1.849.1",
        "name": "COBROS DE DEUDAS Y CONFECCION FACTURAS"
      },
      {
        "code": "1.849.2",
        "name": "SERV. MECANOGRAFICOS, TAQUIGRAFICOS, ETC"
      },
      {
        "code": "1.849.3",
        "name": "SERV. TRADUCCION Y SIMILARES"
      },
      {
        "code": "1.849.4",
        "name": "SERV. CUSTODIA, SEGURIDAD Y PROTECCION"
      },
      {
        "code": "1.849.5",
        "name": "SERVICIOS MENSAJERIA,RECADER., Y REPARTO"
      },
      {
        "code": "1.849.6",
        "name": "SERV.COLOCACION Y SUMINISTRO DE PERSONAL"
      },
      {
        "code": "1.849.7",
        "name": "SERV. GESTION ADMINISTRATIVA"
      },
      {
        "code": "1.849.8",
        "name": "MULTISERVICIOS INTENSIVOS EN PERSONAL"
      },
      {
        "code": "1.849.9",
        "name": "OTROS SERVICIOS INDEPENDIENTES NCOP"
      },
      {
        "code": "1.851",
        "name": "ALQUILER MAQUINARIA Y EQUIPO AGRICOLA"
      },
      {
        "code": "1.852",
        "name": "ALQUILER MAQUINARIA Y EQUIP. CONSTRUCC."
      },
      {
        "code": "1.853",
        "name": "ALQUILER MAQ. Y EQUIPO CONTABLE"
      },
      {
        "code": "1.854.1",
        "name": "ALQUILER AUTOMOVILES SIN CONDUCTOR"
      },
      {
        "code": "1.854.2",
        "name": "ALQUILER AUTOMOVILES EN \"RENTING\""
      },
      {
        "code": "1.855.1",
        "name": "ALQUILER DE AERONAVES DE TODAS CLASES"
      },
      {
        "code": "1.855.2",
        "name": "ALQUILER DE EMBARCACIONES"
      },
      {
        "code": "1.855.3",
        "name": "ALQUILER DE BICICLETAS"
      },
      {
        "code": "1.855.9",
        "name": "ALQUILER OTROS MEDIOS TRANSP. N.C.O.P."
      },
      {
        "code": "1.856.1",
        "name": "ALQUILER DE BIENES DE CONSUMO"
      },
      {
        "code": "1.856.2",
        "name": "ALQUILER DE PELICULAS DE VIDEO"
      },
      {
        "code": "1.857.1",
        "name": "ALQUILER DE APARATOS DE MEDIDA"
      },
      {
        "code": "1.857.2",
        "name": "SERV.DE PESA A MEDIDA SIN ALQUILER"
      },
      {
        "code": "1.857.3",
        "name": "ALQUILER CONTADORES PARA AUTOMOVILES"
      },
      {
        "code": "1.857.4",
        "name": "ALQUILER, LEC., CONS., CONTADORES DE LUZ"
      },
      {
        "code": "1.857.5",
        "name": "LECTURA Y CONS. CONTAD. LUZ TANTO ALZADO"
      },
      {
        "code": "1.857.6",
        "name": "ALQUILER, LEC. Y CONS. CONTADORES GAS"
      },
      {
        "code": "1.857.7",
        "name": "LECTURA Y CONS. DE CONTADORES DE GAS"
      },
      {
        "code": "1.857.8",
        "name": "ALQUILER, LEC. Y CONS. CONTADORES AGUA"
      },
      {
        "code": "1.857.9",
        "name": "LECTURA Y CONS. DE CONTADORES DE AGUA"
      },
      {
        "code": "1.859",
        "name": "ALQUILER OTROS BIENES MUEBLES NCOP"
      },
      {
        "code": "1.861.1",
        "name": "ALQUILER DE VIVIENDAS"
      },
      {
        "code": "1.861.2",
        "name": "ALQUILER LOCALES INDUSTRIALES"
      },
      {
        "code": "1.862",
        "name": "ALQUILER INMUEBLES RUSTICOS"
      },
      {
        "code": "1.911",
        "name": "SERVICIOS AGRICOLAS Y GANADEROS"
      },
      {
        "code": "1.912",
        "name": "SERVICIOS FORESTALES,PESCA Y ACUICULTURA"
      },
      {
        "code": "1.921.1",
        "name": "SERV. LIMPIEZA DE VIAS Y JARDINES"
      },
      {
        "code": "1.921.2",
        "name": "SERV. RECOGIDA DE BASURAS Y DESECHOS"
      },
      {
        "code": "1.921.3",
        "name": "EXTERMINIO ANIMALES DAÑINOS Y DESIFECC."
      },
      {
        "code": "1.921.4",
        "name": "SERV.ALCANTARILLADO, EVACUACION AGUAS"
      },
      {
        "code": "1.921.5",
        "name": "SERVICIOS INCINERACION, ELIM. BASURAS"
      },
      {
        "code": "1.921.6",
        "name": "SERV.PROTECCION Y ACONDIC. AMBIENTAL"
      },
      {
        "code": "1.921.7",
        "name": "SERV. PROTECCION CONTRA INCENDIOS"
      },
      {
        "code": "1.921.8",
        "name": "SERV. ADMON. CEMENTERIOS"
      },
      {
        "code": "1.921.9",
        "name": "OTROS SERVICIOS DE SANEAMIENTO N.C.O.P."
      },
      {
        "code": "1.922",
        "name": "SERVICIOS DE LIMPIEZA"
      },
      {
        "code": "1.922.1",
        "name": "SERV. LIMPIEZA INTERIORES"
      },
      {
        "code": "1.922.2",
        "name": "SERV. ESPECIALIZADOS DE LIMPIEZA"
      },
      {
        "code": "1.931.1",
        "name": "GUARDERIA Y ENSEÑANZA INFANTIL,EXCLUSIV."
      },
      {
        "code": "1.931.2",
        "name": "ENSEÑANZA DE EDUCACION BASICA"
      },
      {
        "code": "1.931.3",
        "name": "ENSEÑANZA BACHILLERATO, F.P., Y C.O.U."
      },
      {
        "code": "1.931.4",
        "name": "ENSEÑANZA REGLADA (PREESC,EGB,BUP,FP,COU"
      },
      {
        "code": "1.931.5",
        "name": "ENSEÑANZA DE EDUCACION SUPERIOR"
      },
      {
        "code": "1.932.1",
        "name": "ENSEÑANZA FORMACION PROF. NO SUPERIOR"
      },
      {
        "code": "1.932.2",
        "name": "ENSEÑANZA FORM. PROFESIONAL SUPERIOR"
      },
      {
        "code": "1.933.1",
        "name": "ENSEÑANZA CONDUCCION VEHICULOS"
      },
      {
        "code": "1.933.2",
        "name": "PROMOC. CURSOS Y ESTUDIOS EN EL EXTRANJ."
      },
      {
        "code": "1.933.9",
        "name": "OTROS ACTIV. ENSEÑANZA"
      },
      {
        "code": "1.934",
        "name": "ENSEÑANZA FUERA ESTABLECIMIENTO PERMANEN"
      },
      {
        "code": "1.935.1",
        "name": "COLEGIOS MAYORES"
      },
      {
        "code": "1.935.2",
        "name": "RESIDENCIAS DE ESTUDIANTES"
      },
      {
        "code": "1.936",
        "name": "INVESTIGACION CIENTIFICA Y TECNICA"
      },
      {
        "code": "1.936.1",
        "name": "INVESTIGACION CC. EXACTAS Y NATURALES"
      },
      {
        "code": "1.936.2",
        "name": "INVESTIGACION CC. MEDICAS"
      },
      {
        "code": "1.936.3",
        "name": "INVESTIGACIONES AGRARIAS"
      },
      {
        "code": "1.936.4",
        "name": "INVEST. CC. SOCIALES,HUMANIDADES"
      },
      {
        "code": "1.936.5",
        "name": "INVESTIGACION TECNICA INDUSTRIAL"
      },
      {
        "code": "1.936.9",
        "name": "OTRAS INVESTIGACIONES CIENTIFICAS NCOP"
      },
      {
        "code": "1.941.1",
        "name": "HOSPITALES GENERALES"
      },
      {
        "code": "1.941.2",
        "name": "HOSPITALES ESPECIALIZADOS"
      },
      {
        "code": "1.942.1",
        "name": "CONSULTORIOS MEDICOS, SANITARIOS..."
      },
      {
        "code": "1.942.2",
        "name": "BALNEARIOS Y BAÑOS"
      },
      {
        "code": "1.942.9",
        "name": "OTROS SERV. SANITARIOS"
      },
      {
        "code": "1.943",
        "name": "CONSULTAS CLINICAS ESTOMAT. Y ODONTOLOG."
      },
      {
        "code": "1.944",
        "name": "SERV. NATUROPATIA,ACUPUNTURA,PARASANIT."
      },
      {
        "code": "1.945",
        "name": "CONSULTAS Y CLINICAS VETERINARIAS"
      },
      {
        "code": "1.951",
        "name": "ASISTENCIA Y SERV. SOCIALES DISMINUIDOS"
      },
      {
        "code": "1.952",
        "name": "ASIST. Y SERV. DISMINUIDOS NO RESIDENC."
      },
      {
        "code": "1.961.1",
        "name": "PRODUCCION PELICULAS CINEMATOGRAFICAS"
      },
      {
        "code": "1.961.2",
        "name": "DOBLAJE, MONTAJE DE PELICULAS"
      },
      {
        "code": "1.961.3",
        "name": "DECORACIONES ESCENICAS PELICULAS"
      },
      {
        "code": "1.962.1",
        "name": "DISTRIBUCION Y VENTA DE PELICULAS"
      },
      {
        "code": "1.962.2",
        "name": "DISTRIBUCION Y VTA MAY. PELICULAS"
      },
      {
        "code": "1.963.1",
        "name": "EXHIBICION DE PELICULAS CINEMATOGRAFICAS"
      },
      {
        "code": "1.963.2",
        "name": "EXHIBICION PELICULAS AIRE LIBRE"
      },
      {
        "code": "1.963.3",
        "name": "EXHIBICION PELICULAS SIN ESTABLECIMIENTO"
      },
      {
        "code": "1.963.4",
        "name": "EXHIBICION PELICULAS ESTABLEC. DISTINTOS"
      },
      {
        "code": "1.964.1",
        "name": "SERVICIOS DE RADIODIFUSION"
      },
      {
        "code": "1.964.2",
        "name": "SERVICIOS DE TELEVISION"
      },
      {
        "code": "1.964.3",
        "name": "SERV.TRANSMISION Y ENLACE SEÑALES TV"
      },
      {
        "code": "1.965.1",
        "name": "ESPECTACULOS EN SALAS Y LOCALES"
      },
      {
        "code": "1.965.2",
        "name": "ESPECTACULOS AL AIRE LIBRE"
      },
      {
        "code": "1.965.3",
        "name": "ESPECTACULOS FUERA ESTABLECIMIENTO"
      },
      {
        "code": "1.965.4",
        "name": "EMPRESAS DE ESPECTACULOS"
      },
      {
        "code": "1.965.5",
        "name": "ESPECTACULOS TAURINOS"
      },
      {
        "code": "1.966.1",
        "name": "BIBLIOTECAS Y MUSEOS"
      },
      {
        "code": "1.966.2",
        "name": "ZOOLOGICOS, BOTANICOS Y SIMILARES"
      },
      {
        "code": "1.966.9",
        "name": "OTROS SERVICIOS CULTURALES NCOP"
      },
      {
        "code": "1.967.1",
        "name": "INSTALACIONES DEPORTIVAS"
      },
      {
        "code": "1.967.2",
        "name": "ESCUELAS Y SERV. PERFECC. DEL DEPORTE"
      },
      {
        "code": "1.967.3",
        "name": "ALQUILER ARTICULOS DEPORTE EN INST. DEP."
      },
      {
        "code": "1.968.1",
        "name": "INSTALAC. CELEB. ESPECTACULOS DEPORTIVOS"
      },
      {
        "code": "1.968.2",
        "name": "ORGANIZACION ESPECTACULOS DEPORTIVOS"
      },
      {
        "code": "1.968.3",
        "name": "ORGANIZ.ESPECT.DEPORT. FEDERAC. Y CLUBES"
      },
      {
        "code": "1.969.1",
        "name": "SALAS DE BAILE Y DISCOTECAS"
      },
      {
        "code": "1.969.2",
        "name": "CASINOS DE JUEGO"
      },
      {
        "code": "1.969.3",
        "name": "JUEGOS DE BINGO"
      },
      {
        "code": "1.969.4",
        "name": "MAQUINAS RECREATIVAS Y DE AZAR"
      },
      {
        "code": "1.969.5",
        "name": "JUEGOS BILLAR, PING-PONG, BOLOS Y OTROS"
      },
      {
        "code": "1.969.6",
        "name": "SALONES RECREATIVOS Y DE JUEGO"
      },
      {
        "code": "1.969.7",
        "name": "OTRAS MAQUINAS AUTOMATICAS"
      },
      {
        "code": "1.971.1",
        "name": "TINTE,LIMP. SECO, LAVADO Y PLANCHADO"
      },
      {
        "code": "1.971.2",
        "name": "LIMPIEZA Y TEÑIDO DEL CALZADO"
      },
      {
        "code": "1.971.3",
        "name": "ZURCIDO Y REPARACION DE ROPAS"
      },
      {
        "code": "1.972.1",
        "name": "SERV. PELUQUERIA SEÑORAS Y CABALLEROS"
      },
      {
        "code": "1.972.2",
        "name": "SALONES E INSTITUTOS DE BELLEZA"
      },
      {
        "code": "1.973.1",
        "name": "SERVICIOS FOTOGRAFICOS"
      },
      {
        "code": "1.973.2",
        "name": "MAQUINAS AUTOMAT. FOTOGRAFIAS Y FOTOCOP."
      },
      {
        "code": "1.973.3",
        "name": "SERV. COPIAS DOCUMENTOS MAQ.FOTOCOPIA"
      },
      {
        "code": "1.974",
        "name": "AGENCIAS PRESTACION SERV. DOMESTICOS"
      },
      {
        "code": "1.975",
        "name": "SERVICIOS DE ENMARCACION"
      },
      {
        "code": "1.979.1",
        "name": "SERV. POMPAS FUNEBRES"
      },
      {
        "code": "1.979.2",
        "name": "ADORNO DE TEMPLOS Y OTROS LOCALES"
      },
      {
        "code": "1.979.3",
        "name": "AGENCIAS MATRIMONIALES Y OTROS SERVICIOS"
      },
      {
        "code": "1.979.4",
        "name": "ADIESTRAMIENTO Y CUIDADOS DE ANIMALES"
      },
      {
        "code": "1.979.9",
        "name": "OTROS SERVICIOS PERSONALES NCOP"
      },
      {
        "code": "1.981.1",
        "name": "CURIOSIDADES EN PARQUES, GRUTAS, ..."
      },
      {
        "code": "1.981.2",
        "name": "JARDINES DE RECREO"
      },
      {
        "code": "1.981.3",
        "name": "PARQUES DE ATRACCIONES (TAMB. ACUATICOS)"
      },
      {
        "code": "1.982.1",
        "name": "TOMBOLAS Y RIFAS AUTORIZADAS ESTABLECIM."
      },
      {
        "code": "1.982.2",
        "name": "TOMBOLAS Y RIFAS AUTOR. SIN ESTABLECIM."
      },
      {
        "code": "1.982.3",
        "name": "EXPOSICION FIGURAS DE CERA ESTABLECIM."
      },
      {
        "code": "1.982.4",
        "name": "OTRAS ATRACC.,SERVICIOS, FUERA ESTABLEC."
      },
      {
        "code": "1.982.5",
        "name": "ORGANIZ.Y CELEB.APUESTAS DEPORT.LOTERIA"
      },
      {
        "code": "1.983",
        "name": "AGENCIAS DE COLOCACION DE ARTISTAS"
      },
      {
        "code": "1.989.1",
        "name": "EXPEDICION BILLETES ESPECTACULOS"
      },
      {
        "code": "1.989.2",
        "name": "SERV. ORGANIZ. CONGRESOS,ASAMBLEAS, ..."
      },
      {
        "code": "1.989.3",
        "name": "PARQUES O RECINTOS FERIALES"
      },
      {
        "code": "1.991",
        "name": "PREST.SERV.SOCIED.DESARROLLO INDUS. REG."
      },
      {
        "code": "1.999",
        "name": "OTROS SERVICIOS N.C.O.P."
      },
      {
        "code": "2.011",
        "name": "DOCTORES Y LICENCIADOS EN BIOLOGIA"
      },
      {
        "code": "2.012",
        "name": "INGENIEROS AGRONOMOS Y DE MONTES"
      },
      {
        "code": "2.013",
        "name": "VETERINARIOS"
      },
      {
        "code": "2.021",
        "name": "TECNICOS BIOLOGIA, AGRONOMIA Y SILVICULT"
      },
      {
        "code": "2.022",
        "name": "INGENIEROS TEC. AGRICOLAS Y FORESTALES"
      },
      {
        "code": "2.023",
        "name": "INGENIEROS TECNICOS TOPOGRAFOS"
      },
      {
        "code": "2.024",
        "name": "AUXILIARES Y AYUDANTES VETERINARIA"
      },
      {
        "code": "2.099",
        "name": "OTRO PROFESIONALES AGRICULTURA... NCOP"
      },
      {
        "code": "2.111",
        "name": "DRES.Y LICENCIADOS FISICAS Y GEOLOGICAS"
      },
      {
        "code": "2.112",
        "name": "INGENIEROS DE MINAS"
      },
      {
        "code": "2.121",
        "name": "DRES. Y LICENCIADOS EN QUIMICAS"
      },
      {
        "code": "2.131",
        "name": "INGENIEROS TEC. MINAS FACULT. Y PERITOS"
      },
      {
        "code": "2.199",
        "name": "OTROS PROF. RELAC. ENERGIA,MINERIA,QUIM."
      },
      {
        "code": "2.211",
        "name": "INGENIEROS AERONAUTICOS"
      },
      {
        "code": "2.212",
        "name": "INGENIEROS NAVALES"
      },
      {
        "code": "2.213",
        "name": "INGENIEROS TELECOMUNICACION"
      },
      {
        "code": "2.214",
        "name": "INGENIEROS ARMAMENTO"
      },
      {
        "code": "2.221",
        "name": "INGENIEROS TEC. AERONAUTICOS"
      },
      {
        "code": "2.222",
        "name": "INGENIEROS TEC. TELECOMUNICACIONES"
      },
      {
        "code": "2.223",
        "name": "AYUDANTES INGENIEROS Y TECNICOS ICAI"
      },
      {
        "code": "2.224",
        "name": "DIBUJANTES TECNICOS"
      },
      {
        "code": "2.225",
        "name": "TECNICOS EN TELECOMUNICACION"
      },
      {
        "code": "2.226",
        "name": "TECNICOS EN SONIDO"
      },
      {
        "code": "2.227",
        "name": "TECNICOS EN ILUMINACION"
      },
      {
        "code": "2.228",
        "name": "INGENIEROS TECN. NAVALES,AYTES Y PERITOS"
      },
      {
        "code": "2.299",
        "name": "OTROS PROF.RELACION AERONAUTICA,COMUNIC."
      },
      {
        "code": "2.311",
        "name": "INGENIEROS INDUSTRIALES Y TEXTILES"
      },
      {
        "code": "2.321",
        "name": "INGENIEROS TEC. INDUSTRIALES Y TEXTILES"
      },
      {
        "code": "2.322",
        "name": "TECNICOS EN ARTES GRAFICAS"
      },
      {
        "code": "2.399",
        "name": "OTROS PROFESIONALES INDUSTRIAS MANUFAC."
      },
      {
        "code": "2.411",
        "name": "ARQUITECTOS"
      },
      {
        "code": "2.412",
        "name": "INGENIEROS CAMINOS, CANALES Y PUERTOS"
      },
      {
        "code": "2.421",
        "name": "ARQUITECTOS TECNICOS Y APAREJADORES"
      },
      {
        "code": "2.422",
        "name": "INGENIEROS TEC. OBRAS PUBLICAS"
      },
      {
        "code": "2.431",
        "name": "DELINEANTES"
      },
      {
        "code": "2.432",
        "name": "DECORADORES-DISEÑADORES DE INTERIORES"
      },
      {
        "code": "2.441",
        "name": "TEC. SUPERIORES URBANISTICOS Y TOPOGRAFI"
      },
      {
        "code": "2.451",
        "name": "INGENIEROS EN GEODESIA Y CARTOGRAFIA"
      },
      {
        "code": "2.499",
        "name": "OTROS PROFES. RELACIONADOS CONSTRUCCION"
      },
      {
        "code": "2.511",
        "name": "AGENTES COMERCIALES"
      },
      {
        "code": "2.521",
        "name": "TECNICOS EN HOSTELERIA"
      },
      {
        "code": "2.599",
        "name": "OTROS PROF. RELAC. COMERCIO Y HOSTELERIA"
      },
      {
        "code": "2.611",
        "name": "AGENTES DE FERROCARRILES"
      },
      {
        "code": "2.612",
        "name": "CONDUCTORES DE VEHICULOS TERRESTRES"
      },
      {
        "code": "2.699",
        "name": "OTROS PROFESIONALES RELAC. TRANSPORTE"
      },
      {
        "code": "2.711",
        "name": "ACTUARIOS DE SEGUROS"
      },
      {
        "code": "2.712",
        "name": "AGENTES Y CORREDORES DE SEGUROS"
      },
      {
        "code": "2.721",
        "name": "AGENTES COLEGIADOS PROPIEDAD IND.E INMOB"
      },
      {
        "code": "2.722",
        "name": "GESTORES ADMINISTRATIVOS"
      },
      {
        "code": "2.723",
        "name": "ADMINISTRADORES DE FINCAS"
      },
      {
        "code": "2.724",
        "name": "INTERMEDIARIOS PROMOCION EDIFICACIONES"
      },
      {
        "code": "2.725",
        "name": "HABILITADOS DE CLASES PASIVAS"
      },
      {
        "code": "2.726",
        "name": "GRADUADOS SOCIALES"
      },
      {
        "code": "2.727",
        "name": "AGENTES O INTERMEDIARIOS PRESTAMOS"
      },
      {
        "code": "2.728",
        "name": "AGENTES DE ADUANAS"
      },
      {
        "code": "2.731",
        "name": "ABOGADOS"
      },
      {
        "code": "2.732",
        "name": "PROCURADORES"
      },
      {
        "code": "2.733",
        "name": "NOTARIOS"
      },
      {
        "code": "2.734",
        "name": "REGISTRADORES"
      },
      {
        "code": "2.741",
        "name": "ECONOMISTAS"
      },
      {
        "code": "2.742",
        "name": "INTENDENTES Y PROFESORES MERCANTILES"
      },
      {
        "code": "2.743",
        "name": "PERITOS MERCANTILES"
      },
      {
        "code": "2.744",
        "name": "DIPLOMADOS EN CIENCIAS EMPRESARIALES"
      },
      {
        "code": "2.746",
        "name": "CORREDORES DE COMERCIO LIBRES"
      },
      {
        "code": "2.747",
        "name": "AUDITORES DE CUENTAS Y CENSORES JURADOS"
      },
      {
        "code": "2.748",
        "name": "ADMINISTRADORES DE CARTERAS DE VALORES"
      },
      {
        "code": "2.749",
        "name": "CORREDORES INTERPRETES Y MARITIMOS"
      },
      {
        "code": "2.751",
        "name": "PROFESIONALES PUBLICIDAD, RELAC.PUBLICAS"
      },
      {
        "code": "2.761",
        "name": "DOCTORES Y LICENCIADOS EXACTAS Y ESTAD."
      },
      {
        "code": "2.762",
        "name": "DOCTORES,LICENCIADOS,INGENIEROS INFORMAT"
      },
      {
        "code": "2.763",
        "name": "PROGRAMADORES Y ANALISTAS INFORMATICA"
      },
      {
        "code": "2.764",
        "name": "DIPLOMADOS EN INFORMATICA"
      },
      {
        "code": "2.765",
        "name": "GRABADORES, Y OTROS PROF. INFORMATICA"
      },
      {
        "code": "2.771",
        "name": "AGENTES COBRADORES"
      },
      {
        "code": "2.772",
        "name": "ESTENOTIPISTAS, TAQUIMECANOGRAFOS,..."
      },
      {
        "code": "2.773",
        "name": "DETECTIVES PRIVADOS, VIGILANCIA, PROTECC"
      },
      {
        "code": "2.774",
        "name": "TRADUCTORES E INTERPRETES"
      },
      {
        "code": "2.775",
        "name": "DOCTORES Y LICENCIADOS EN FILOSOFIA"
      },
      {
        "code": "2.776",
        "name": "DRES.LICENC.POLITICAS,SOCIALES,LETRAS"
      },
      {
        "code": "2.777",
        "name": "ESPECIALISTAS ORIENTACION Y ANALISIS PER"
      },
      {
        "code": "2.778",
        "name": "DIPL. EN BIBLIOTECOMANIA Y DOCUMENTACION"
      },
      {
        "code": "2.799",
        "name": "OTROS PROF. ACTIV. FINANC. JURID. SEGUR."
      },
      {
        "code": "2.811",
        "name": "PROFESIONALES LIMPIEZA"
      },
      {
        "code": "2.821",
        "name": "PERSONAL DOCENTE ENSEÑANZA SUPERIOR"
      },
      {
        "code": "2.822",
        "name": "PERSONAL DOCENTE ENSEÑANZA MEDIA"
      },
      {
        "code": "2.823",
        "name": "DOCENTES ENSEÑANZA GRAL. BASICA"
      },
      {
        "code": "2.824",
        "name": "PROFESORES FORMACION PROFESIONAL"
      },
      {
        "code": "2.825",
        "name": "PROFESORES CONDUCCION VEHICULOS"
      },
      {
        "code": "2.826",
        "name": "PERSONAL DOCENTE ENSEÑANZAS DIVERSAS"
      },
      {
        "code": "2.831",
        "name": "MEDICOS MEDICINA GENERAL"
      },
      {
        "code": "2.832",
        "name": "MEDICOS ESPECIALISTAS"
      },
      {
        "code": "2.833",
        "name": "ESTOMATOLOGOS"
      },
      {
        "code": "2.834",
        "name": "ODONTOLOGOS"
      },
      {
        "code": "2.835",
        "name": "FARMACEUTICOS"
      },
      {
        "code": "2.836",
        "name": "AYUD. TECN. SANITARIOS Y FISIOTERAPEUTAS"
      },
      {
        "code": "2.837",
        "name": "PROTESICOS E HIGIENISTAS DENTALES"
      },
      {
        "code": "2.838",
        "name": "OPTICOS-OPTOMETRISTAS Y PODOLOGOS"
      },
      {
        "code": "2.839",
        "name": "MASAJISTAS, DIETISTAS, Y AUX.ENFERMERIA"
      },
      {
        "code": "2.841",
        "name": "ACUPUNTORES, NATUROPATAS Y OTROS"
      },
      {
        "code": "2.851",
        "name": "REPRESENTANTES TECNICOS DEL ESPECTACULO"
      },
      {
        "code": "2.852",
        "name": "APODERADOS Y REPRESENTANTES TAURINOS"
      },
      {
        "code": "2.853",
        "name": "AGENTES COLOCADORES ARTISTAS"
      },
      {
        "code": "2.854",
        "name": "EXPERTOS ORGANIZACION CONGRESOS"
      },
      {
        "code": "2.855",
        "name": "AGENTES Y CORREDORES APUESTAS"
      },
      {
        "code": "2.861",
        "name": "PINTORES,ESCULTORES,CERAMISTAS,ARTESANOS"
      },
      {
        "code": "2.862",
        "name": "RESTAURADORES OBRAS DE ARTE"
      },
      {
        "code": "2.863",
        "name": "PERIODISTAS Y OTROS PROFESIONALES DE LA INFORMACIÓN Y LA COMUNICACIÓN"
      },
      {
        "code": "2.871",
        "name": "EXPENDEDORES OFICIALES LOTERIAS"
      },
      {
        "code": "2.872",
        "name": "EXPENDEDORES OFICIALES APUESTAS"
      },
      {
        "code": "2.873",
        "name": "EXPENDEDORES NO OFICIALES APUESTAS"
      },
      {
        "code": "2.881",
        "name": "ASTROLOGOS Y SIMILARES"
      },
      {
        "code": "2.882",
        "name": "GUIAS DE TURISMO"
      },
      {
        "code": "2.883",
        "name": "GUIAS INTERPRETES DE TURISMO"
      },
      {
        "code": "2.884",
        "name": "PERITOS TASADORES:SEGUROS,ALHAJAS,..."
      },
      {
        "code": "2.885",
        "name": "LIQUIDADORES Y COMISARIOS DE AVERIAS"
      },
      {
        "code": "2.886",
        "name": "CRONOMETRADORES"
      },
      {
        "code": "2.887",
        "name": "MAQUILLADORES Y ESTETICISTAS"
      },
      {
        "code": "2.888",
        "name": "GRAFOLOGOS"
      },
      {
        "code": "2.899",
        "name": "OTROS PROF. RELACIONADOS CON SERVICIOS"
      },
      {
        "code": "3.011",
        "name": "DIRECTORES DE CINE Y TEATRO"
      },
      {
        "code": "3.012",
        "name": "AYUDANTES DE DIRECCION"
      },
      {
        "code": "3.013",
        "name": "ACTORES DE CINE Y TEATRO"
      },
      {
        "code": "3.014",
        "name": "EXTRAS ESPECIALISTAS, DOBLES, COMPARSAS"
      },
      {
        "code": "3.015",
        "name": "OPERADORES CAMARA:CINE,TV.,VIDEO"
      },
      {
        "code": "3.016",
        "name": "HUMORISTAS,CARICATOS,EXCENTRICOS"
      },
      {
        "code": "3.017",
        "name": "APUNTADORES Y REGIDORES"
      },
      {
        "code": "3.018",
        "name": "ARTISTAS CIRCENSES"
      },
      {
        "code": "3.019",
        "name": "ACTIVIDADES CINE,TEATRO, Y CIRCO NCOP"
      },
      {
        "code": "3.021",
        "name": "DIRECTORES COREOGRAFICOS"
      },
      {
        "code": "3.022",
        "name": "BAILARINES"
      },
      {
        "code": "3.029",
        "name": "OTRAS ACTIV. DEL BAILE NCOP"
      },
      {
        "code": "3.031",
        "name": "MAESTROS Y DIRECTORES DE MUSICA"
      },
      {
        "code": "3.032",
        "name": "INTERPRETES DE INSTRUMENTOS MUSICALES"
      },
      {
        "code": "3.033",
        "name": "CANTANTES"
      },
      {
        "code": "3.039",
        "name": "OTRAS ACTIV. RELAC. MUSICA NCOP"
      },
      {
        "code": "3.041",
        "name": "JUGADORES Y ENTRENADORES DE FUTBOL"
      },
      {
        "code": "3.042",
        "name": "JUGADORES,ENTRENADORES, DE TENIS Y GOLF"
      },
      {
        "code": "3.043",
        "name": "PILOTOS,ENTRENADORES MOTO Y AUTOCICLISMO"
      },
      {
        "code": "3.044",
        "name": "BOXEADORES,ENTRENADORES DE BOXEO"
      },
      {
        "code": "3.045",
        "name": "JUGADORES,ENTRENADORES DE BALONCESTO"
      },
      {
        "code": "3.046",
        "name": "CORREDORES,ENTRENADORES DE CICLISMO"
      },
      {
        "code": "3.047",
        "name": "BALONMANO,VOLEIBOL,PELOTA,HIPICA,LUCHA"
      },
      {
        "code": "3.048",
        "name": "ARBITROS DE ESPECTACULOS DEPORTIVOS"
      },
      {
        "code": "3.049",
        "name": "OTRAS ACTIV. RELACIONADAS DEPORTE NCOP"
      },
      {
        "code": "3.051",
        "name": "MATADORES DE TOROS"
      },
      {
        "code": "3.052",
        "name": "REJONEADORES"
      },
      {
        "code": "3.053",
        "name": "SUBALTERNOS"
      },
      {
        "code": "3.054",
        "name": "JEFES DE CUADRILLAS COMICAS Y SIMILARES"
      },
      {
        "code": "3.055",
        "name": "OTRO PERSONAL CUADRILLAS COMICAS Y SIMIL"
      },
      {
        "code": "3.059",
        "name": "OTRAS ACTIV. ESPEC.TAURINOS NCOP"
      }

    ]
    
    return of (iaeList);
  }
  public cnoSelectList(): Observable<any>{
    const cnoList = [
      {
        "code": "11",
        "name": "OFICIALES DE LAS FUERZAS ARMADAS"
      },
      {
        "code": "12",
        "name": "SUBOFICIALES DE LAS FUERZAS ARMADAS"
      },
      {
        "code": "20",
        "name": "TROPA Y MARINERÍA DE LAS FUERZAS ARMADAS"
      },
      {
        "code": "1111",
        "name": "MIEMBROS DEL PODER EJECUTIVO (NACIONAL, AUTONÓMICO Y LOCAL) Y DEL PODER LEGISLATIVO"
      },
      {
        "code": "1112",
        "name": "PERSONAL DIRECTIVO DE LA ADMINISTRACIÓN PÚBLICA"
      },
      {
        "code": "1113",
        "name": "DIRECTORES DE ORGANIZACIONES DE INTERÉS SOCIAL"
      },
      {
        "code": "1120",
        "name": "DIRECTORES GENERALES Y PRESIDENTES EJECUTIVOS"
      },
      {
        "code": "1211",
        "name": "DIRECTORES FINANCIEROS"
      },
      {
        "code": "1212",
        "name": "DIRECTORES DE RECURSOS HUMANOS"
      },
      {
        "code": "1219",
        "name": "DIRECTORES DE POLÍTICAS Y PLANIFICACIÓN Y DE OTROS DEPARTAMENTOS ADMINISTRATIVOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "1221",
        "name": "DIRECTORES COMERCIALES Y DE VENTAS"
      },
      {
        "code": "1222",
        "name": "DIRECTORES DE PUBLICIDAD Y RELACIONES PÚBLICAS"
      },
      {
        "code": "1223",
        "name": "DIRECTORES DE INVESTIGACIÓN Y DESARROLLO"
      },
      {
        "code": "1311",
        "name": "DIRECTORES DE PRODUCCIÓN DE EXPLOTACIONES AGROPECUARIAS Y FORESTALES"
      },
      {
        "code": "1312",
        "name": "DIRECTORES DE PRODUCCIÓN DE EXPLOTACIONES PESQUERAS Y ACUÍCOLAS"
      },
      {
        "code": "1313",
        "name": "DIRECTORES DE INDUSTRIAS MANUFACTURERAS"
      },
      {
        "code": "1314",
        "name": "DIRECTORES DE EXPLOTACIONES MINERAS"
      },
      {
        "code": "1315",
        "name": "DIRECTORES DE EMPRESAS DE ABASTECIMIENTO, TRANSPORTE, DISTRIBUCIÓN Y AFINES"
      },
      {
        "code": "1316",
        "name": "DIRECTORES DE EMPRESAS DE CONSTRUCCIÓN"
      },
      {
        "code": "1321",
        "name": "DIRECTORES DE SERVICIOS DE TECNOLOGÍAS DE LA INFORMACIÓN Y LAS COMUNICACIONES (TIC)"
      },
      {
        "code": "1322",
        "name": "DIRECTORES DE SERVICIOS SOCIALES PARA NIÑOS"
      },
      {
        "code": "1323",
        "name": "DIRECTORES-GERENTES DE CENTROS SANITARIOS"
      },
      {
        "code": "1324",
        "name": "DIRECTORES DE SERVICIOS SOCIALES PARA PERSONAS MAYORES"
      },
      {
        "code": "1325",
        "name": "DIRECTORES DE OTROS SERVICIOS SOCIALES"
      },
      {
        "code": "1326",
        "name": "DIRECTORES DE SERVICIOS DE EDUCACIÓN"
      },
      {
        "code": "1327",
        "name": "DIRECTORES DE SUCURSALES DE BANCOS, DE SERVICIOS FINANCIEROS Y DE SEGUROS"
      },
      {
        "code": "1329",
        "name": "DIRECTORES DE OTRAS EMPRESAS DE SERVICIOS PROFESIONALES NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "1411",
        "name": "DIRECTORES Y GERENTES DE HOTELES"
      },
      {
        "code": "1419",
        "name": "DIRECTORES Y GERENTES DE OTRAS EMPRESAS DE SERVICIOS DE ALOJAMIENTO"
      },
      {
        "code": "1421",
        "name": "DIRECTORES Y GERENTES DE RESTAURANTES"
      },
      {
        "code": "1422",
        "name": "DIRECTORES Y GERENTES DE BARES, CAFETERÍAS Y SIMILARES"
      },
      {
        "code": "1429",
        "name": "DIRECTORES Y GERENTES DE EMPRESAS DE CATERING Y OTRAS EMPRESAS DE RESTAURACIÓN"
      },
      {
        "code": "1431",
        "name": "DIRECTORES Y GERENTES DE EMPRESAS DE COMERCIO AL POR MAYOR"
      },
      {
        "code": "1432",
        "name": "DIRECTORES Y GERENTES DE EMPRESAS DE COMERCIO AL POR MENOR"
      },
      {
        "code": "1501",
        "name": "DIRECTORES Y GERENTES DE EMPRESAS DE ACTIVIDADES RECREATIVAS, CULTURALES Y DEPORTIVAS"
      },
      {
        "code": "1509",
        "name": "DIRECTORES Y GERENTES DE EMPRESAS DE GESTIÓN DE RESIDUOS Y DE OTRAS EMPRESAS DE SERVICIOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2111",
        "name": "MÉDICOS DE FAMILIA"
      },
      {
        "code": "2112",
        "name": "OTROS MÉDICOS ESPECIALISTAS"
      },
      {
        "code": "2121",
        "name": "ENFERMEROS NO ESPECIALIZADOS"
      },
      {
        "code": "2122",
        "name": "ENFERMEROS ESPECIALIZADOS (EXCEPTO MATRONOS)"
      },
      {
        "code": "2123",
        "name": "MATRONOS"
      },
      {
        "code": "2130",
        "name": "VETERINARIOS"
      },
      {
        "code": "2140",
        "name": "FARMACÉUTICOS"
      },
      {
        "code": "2151",
        "name": "ODONTÓLOGOS Y ESTOMATÓLOGOS"
      },
      {
        "code": "2152",
        "name": "FISIOTERAPEUTAS"
      },
      {
        "code": "2153",
        "name": "DIETISTAS Y NUTRICIONISTAS"
      },
      {
        "code": "2154",
        "name": "LOGOPEDAS"
      },
      {
        "code": "2155",
        "name": "ÓPTICOS-OPTOMETRISTAS"
      },
      {
        "code": "2156",
        "name": "TERAPEUTAS OCUPACIONALES"
      },
      {
        "code": "2157",
        "name": "PODÓLOGOS"
      },
      {
        "code": "2158",
        "name": "PROFESIONALES DE LA SALUD Y LA HIGIENE LABORAL Y AMBIENTAL"
      },
      {
        "code": "2159",
        "name": "PROFESIONALES DE LA SALUD NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2210",
        "name": "PROFESORES DE UNIVERSIDADES Y OTRA ENSEÑANZA SUPERIOR (EXCEPTO FORMACIÓN PROFESIONAL)"
      },
      {
        "code": "2220",
        "name": "PROFESORES DE FORMACIÓN PROFESIONAL (MATERIAS ESPECÍFICAS)"
      },
      {
        "code": "2230",
        "name": "PROFESORES DE ENSEÑANZA SECUNDARIA (EXCEPTO MATERIAS ESPECÍFICAS DE FORMACIÓN PROFESIONAL)"
      },
      {
        "code": "2240",
        "name": "PROFESORES DE ENSEÑANZA PRIMARIA"
      },
      {
        "code": "2251",
        "name": "MAESTROS DE EDUCACIÓN INFANTIL"
      },
      {
        "code": "2252",
        "name": "TÉCNICOS EN EDUCACIÓN INFANTIL"
      },
      {
        "code": "2311",
        "name": "PROFESORES DE EDUCACIÓN ESPECIAL"
      },
      {
        "code": "2312",
        "name": "TÉCNICOS EDUCADORES DE EDUCACIÓN ESPECIAL"
      },
      {
        "code": "2321",
        "name": "ESPECIALISTAS EN MÉTODOS DIDÁCTICOS Y PEDAGÓGICOS"
      },
      {
        "code": "2322",
        "name": "PROFESORES DE ENSEÑANZA NO REGLADA DE IDIOMAS"
      },
      {
        "code": "2323",
        "name": "PROFESORES DE ENSEÑANZA NO REGLADA DE MÚSICA Y DANZA"
      },
      {
        "code": "2324",
        "name": "PROFESORES DE ENSEÑANZA NO REGLADA DE ARTES"
      },
      {
        "code": "2325",
        "name": "INSTRUCTORES EN TECNOLOGÍAS DE LA INFORMACIÓN EN ENSEÑANZA NO REGLADA"
      },
      {
        "code": "2326",
        "name": "PROFESIONALES DE LA EDUCACIÓN AMBIENTAL"
      },
      {
        "code": "2329",
        "name": "PROFESORES Y PROFESIONALES DE LA ENSEÑANZA NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2411",
        "name": "FÍSICOS Y ASTRÓNOMOS"
      },
      {
        "code": "2412",
        "name": "METEORÓLOGOS"
      },
      {
        "code": "2413",
        "name": "QUÍMICOS"
      },
      {
        "code": "2414",
        "name": "GEÓLOGOS Y GEOFÍSICOS"
      },
      {
        "code": "2415",
        "name": "MATEMÁTICOS Y ACTUARIOS"
      },
      {
        "code": "2416",
        "name": "ESTADÍSTICOS"
      },
      {
        "code": "2421",
        "name": "BIÓLOGOS, BOTÁNICOS, ZOÓLOGOS Y AFINES"
      },
      {
        "code": "2422",
        "name": "INGENIEROS AGRÓNOMOS"
      },
      {
        "code": "2423",
        "name": "INGENIEROS DE MONTES"
      },
      {
        "code": "2424",
        "name": "INGENIEROS TÉCNICOS AGRÍCOLAS"
      },
      {
        "code": "2425",
        "name": "INGENIEROS TÉCNICOS FORESTALES Y DEL MEDIO NATURAL"
      },
      {
        "code": "2426",
        "name": "PROFESIONALES DE LA PROTECCIÓN AMBIENTAL"
      },
      {
        "code": "2427",
        "name": "ENÓLOGOS"
      },
      {
        "code": "2431",
        "name": "INGENIEROS INDUSTRIALES Y DE PRODUCCIÓN"
      },
      {
        "code": "2432",
        "name": "INGENIEROS EN CONSTRUCCIÓN Y OBRA CIVIL"
      },
      {
        "code": "2433",
        "name": "INGENIEROS MECÁNICOS"
      },
      {
        "code": "2434",
        "name": "INGENIEROS AERONÁUTICOS"
      },
      {
        "code": "2435",
        "name": "INGENIEROS QUÍMICOS"
      },
      {
        "code": "2436",
        "name": "INGENIEROS DE MINAS, METALÚRGICOS Y AFINES"
      },
      {
        "code": "2437",
        "name": "INGENIEROS AMBIENTALES"
      },
      {
        "code": "2439",
        "name": "INGENIEROS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2441",
        "name": "INGENIEROS EN ELECTRICIDAD"
      },
      {
        "code": "2442",
        "name": "INGENIEROS ELECTRÓNICOS"
      },
      {
        "code": "2443",
        "name": "INGENIEROS EN TELECOMUNICACIONES"
      },
      {
        "code": "2451",
        "name": "ARQUITECTOS (EXCEPTO ARQUITECTOS PAISAJISTAS Y URBANISTAS)"
      },
      {
        "code": "2452",
        "name": "ARQUITECTOS PAISAJISTAS"
      },
      {
        "code": "2453",
        "name": "URBANISTAS E INGENIEROS DE TRÁFICO"
      },
      {
        "code": "2454",
        "name": "INGENIEROS GEÓGRAFOS Y CARTÓGRAFOS"
      },
      {
        "code": "2461",
        "name": "INGENIEROS TÉCNICOS INDUSTRIALES Y DE PRODUCCIÓN"
      },
      {
        "code": "2462",
        "name": "INGENIEROS TÉCNICOS DE OBRAS PÚBLICAS"
      },
      {
        "code": "2463",
        "name": "INGENIEROS TÉCNICOS MECÁNICOS"
      },
      {
        "code": "2464",
        "name": "INGENIEROS TÉCNICOS AERONÁUTICOS"
      },
      {
        "code": "2465",
        "name": "INGENIEROS TÉCNICOS QUÍMICOS"
      },
      {
        "code": "2466",
        "name": "INGENIEROS TÉCNICOS DE MINAS, METALÚRGICOS Y AFINES"
      },
      {
        "code": "2469",
        "name": "INGENIEROS TÉCNICOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2471",
        "name": "INGENIEROS TÉCNICOS EN ELECTRICIDAD"
      },
      {
        "code": "2472",
        "name": "INGENIEROS TÉCNICOS EN ELECTRÓNICA"
      },
      {
        "code": "2473",
        "name": "INGENIEROS TÉCNICOS EN TELECOMUNICACIONES"
      },
      {
        "code": "2481",
        "name": "ARQUITECTOS TÉCNICOS Y TÉCNICOS URBANISTAS"
      },
      {
        "code": "2482",
        "name": "DISEÑADORES DE PRODUCTOS Y DE PRENDAS"
      },
      {
        "code": "2483",
        "name": "INGENIEROS TÉCNICOS EN TOPOGRAFÍA"
      },
      {
        "code": "2484",
        "name": "DISEÑADORES GRÁFICOS Y MULTIMEDIA"
      },
      {
        "code": "2511",
        "name": "ABOGADOS"
      },
      {
        "code": "2512",
        "name": "FISCALES"
      },
      {
        "code": "2513",
        "name": "JUECES Y MAGISTRADOS"
      },
      {
        "code": "2591",
        "name": "NOTARIOS Y REGISTRADORES"
      },
      {
        "code": "2592",
        "name": "PROCURADORES"
      },
      {
        "code": "2599",
        "name": "PROFESIONALES DEL DERECHO NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2611",
        "name": "ESPECIALISTAS EN CONTABILIDAD"
      },
      {
        "code": "2612",
        "name": "ASESORES FINANCIEROS Y EN INVERSIONES"
      },
      {
        "code": "2613",
        "name": "ANALISTAS FINANCIEROS"
      },
      {
        "code": "2621",
        "name": "ANALISTAS DE GESTIÓN Y ORGANIZACIÓN"
      },
      {
        "code": "2622",
        "name": "ESPECIALISTAS EN ADMINISTRACIÓN DE POLÍTICA DE EMPRESAS"
      },
      {
        "code": "2623",
        "name": "ESPECIALISTAS DE LA ADMINISTRACIÓN PÚBLICA"
      },
      {
        "code": "2624",
        "name": "ESPECIALISTAS EN POLÍTICAS Y SERVICIOS DE PERSONAL Y AFINES"
      },
      {
        "code": "2625",
        "name": "ESPECIALISTAS EN FORMACIÓN DE PERSONAL"
      },
      {
        "code": "2630",
        "name": "TÉCNICOS DE EMPRESAS Y ACTIVIDADES TURÍSTICAS"
      },
      {
        "code": "2640",
        "name": "PROFESIONALES DE VENTAS TÉCNICAS Y MÉDICAS (EXCEPTO LAS TIC)"
      },
      {
        "code": "2651",
        "name": "PROFESIONALES DE LA PUBLICIDAD Y LA COMERCIALIZACIÓN"
      },
      {
        "code": "2652",
        "name": "PROFESIONALES DE RELACIONES PÚBLICAS"
      },
      {
        "code": "2653",
        "name": "PROFESIONALES DE LA VENTA DE TECNOLOGÍAS DE LA INFORMACIÓN Y LAS COMUNICACIONES"
      },
      {
        "code": "2711",
        "name": "ANALISTAS DE SISTEMAS"
      },
      {
        "code": "2712",
        "name": "ANALISTAS Y DISEÑADORES DE SOFTWARE"
      },
      {
        "code": "2713",
        "name": "ANALISTAS, PROGRAMADORES Y DISEÑADORES WEB Y MULTIMEDIA"
      },
      {
        "code": "2719",
        "name": "ANALISTAS Y DISEÑADORES DE SOFTWARE Y MULTIMEDIA NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2721",
        "name": "DISEÑADORES Y ADMINISTRADORES DE BASES DE DATOS"
      },
      {
        "code": "2722",
        "name": "ADMINISTRADORES DE SISTEMAS Y REDES"
      },
      {
        "code": "2723",
        "name": "ANALISTAS DE REDES INFORMÁTICAS"
      },
      {
        "code": "2729",
        "name": "ESPECIALISTAS EN BASES DE DATOS Y EN REDES INFORMÁTICAS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "2810",
        "name": "ECONOMISTAS"
      },
      {
        "code": "2821",
        "name": "SOCIÓLOGOS, GEÓGRAFOS, ANTROPÓLOGOS, ARQUEÓLOGOS Y AFINES"
      },
      {
        "code": "2822",
        "name": "FILÓSOFOS, HISTORIADORES Y PROFESIONALES EN CIENCIAS POLÍTICAS"
      },
      {
        "code": "2823",
        "name": "PSICÓLOGOS"
      },
      {
        "code": "2824",
        "name": "PROFESIONALES DEL TRABAJO Y LA EDUCACIÓN SOCIAL"
      },
      {
        "code": "2825",
        "name": "AGENTES DE IGUALDAD DE OPORTUNIDADES ENTRE MUJERES Y HOMBRES"
      },
      {
        "code": "2830",
        "name": "SACERDOTES DE LAS DISTINTAS RELIGIONES"
      },
      {
        "code": "2911",
        "name": "ARCHIVISTAS Y CONSERVADORES DE MUSEOS"
      },
      {
        "code": "2912",
        "name": "BIBLIOTECARIOS, DOCUMENTALISTAS Y AFINES"
      },
      {
        "code": "2921",
        "name": "ESCRITORES"
      },
      {
        "code": "2922",
        "name": "PERIODISTAS"
      },
      {
        "code": "2923",
        "name": "FILÓLOGOS, INTÉRPRETES Y TRADUCTORES"
      },
      {
        "code": "2931",
        "name": "ARTISTAS DE ARTES PLÁSTICAS Y VISUALES"
      },
      {
        "code": "2932",
        "name": "COMPOSITORES, MÚSICOS Y CANTANTES"
      },
      {
        "code": "2933",
        "name": "COREÓGRAFOS Y BAILARINES"
      },
      {
        "code": "2934",
        "name": "DIRECTORES DE CINE, DE TEATRO Y AFINES"
      },
      {
        "code": "2935",
        "name": "ACTORES"
      },
      {
        "code": "2936",
        "name": "LOCUTORES DE RADIO, TELEVISIÓN Y OTROS PRESENTADORES"
      },
      {
        "code": "2937",
        "name": "PROFESIONALES DE ESPECTÁCULOS TAURINOS"
      },
      {
        "code": "2939",
        "name": "ARTISTAS CREATIVOS E INTERPRETATIVOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "3110",
        "name": "DELINEANTES Y DIBUJANTES TÉCNICOS"
      },
      {
        "code": "3121",
        "name": "TÉCNICOS EN CIENCIAS FÍSICAS Y QUÍMICAS"
      },
      {
        "code": "3122",
        "name": "TÉCNICOS EN CONSTRUCCIÓN"
      },
      {
        "code": "3123",
        "name": "TÉCNICOS EN ELECTRICIDAD"
      },
      {
        "code": "3124",
        "name": "TÉCNICOS EN ELECTRÓNICA (EXCEPTO ELECTROMEDICINA)"
      },
      {
        "code": "3125",
        "name": "TÉCNICOS EN ELECTRÓNICA, ESPECIALIDAD EN ELECTROMEDICINA"
      },
      {
        "code": "3126",
        "name": "TÉCNICOS EN MECÁNICA"
      },
      {
        "code": "3127",
        "name": "TÉCNICOS Y ANALISTAS DE LABORATORIO EN QUÍMICA INDUSTRIAL"
      },
      {
        "code": "3128",
        "name": "TÉCNICOS EN METALURGIA Y MINAS"
      },
      {
        "code": "3129",
        "name": "OTROS TÉCNICOS DE LAS CIENCIAS FÍSICAS, QUÍMICAS, MEDIOAMBIENTALES Y DE LAS INGENIERÍAS"
      },
      {
        "code": "3131",
        "name": "TÉCNICOS EN INSTALACIONES DE PRODUCCIÓN DE ENERGÍA"
      },
      {
        "code": "3132",
        "name": "TÉCNICOS EN INSTALACIONES DE TRATAMIENTO DE RESIDUOS, DE AGUAS Y OTROS OPERADORES EN PLANTAS SIMILARES"
      },
      {
        "code": "3133",
        "name": "TÉCNICOS EN CONTROL DE INSTALACIONES DE PROCESAMIENTO DE PRODUCTOS QUÍMICOS"
      },
      {
        "code": "3134",
        "name": "TÉCNICOS DE REFINERÍAS DE PETRÓLEO Y GAS NATURAL"
      },
      {
        "code": "3135",
        "name": "TÉCNICOS EN CONTROL DE PROCESOS DE PRODUCCIÓN DE METALES"
      },
      {
        "code": "3139",
        "name": "TÉCNICOS EN CONTROL DE PROCESOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "3141",
        "name": "TÉCNICOS EN CIENCIAS BIOLÓGICAS (EXCEPTO EN ÁREAS SANITARIAS)"
      },
      {
        "code": "3142",
        "name": "TÉCNICOS AGROPECUARIOS"
      },
      {
        "code": "3143",
        "name": "TÉCNICOS FORESTALES Y DEL MEDIO NATURAL"
      },
      {
        "code": "3151",
        "name": "JEFES Y OFICIALES DE MÁQUINAS"
      },
      {
        "code": "3152",
        "name": "CAPITANES Y OFICIALES DE PUENTE"
      },
      {
        "code": "3153",
        "name": "PILOTOS DE AVIACIÓN Y PROFESIONALES AFINES"
      },
      {
        "code": "3154",
        "name": "CONTROLADORES DE TRÁFICO AÉREO"
      },
      {
        "code": "3155",
        "name": "TÉCNICOS EN SEGURIDAD AERONÁUTICA"
      },
      {
        "code": "3160",
        "name": "TÉCNICOS DE CONTROL DE CALIDAD DE LAS CIENCIAS FÍSICAS, QUÍMICAS Y DE LAS INGENIERÍAS"
      },
      {
        "code": "3201",
        "name": "SUPERVISORES EN INGENIERÍA DE MINAS"
      },
      {
        "code": "3202",
        "name": "SUPERVISORES DE LA CONSTRUCCIÓN"
      },
      {
        "code": "3203",
        "name": "SUPERVISORES DE INDUSTRIAS ALIMENTICIAS Y DEL TABACO"
      },
      {
        "code": "3204",
        "name": "SUPERVISORES DE INDUSTRIAS QUÍMICA Y FARMACÉUTICA"
      },
      {
        "code": "3205",
        "name": "SUPERVISORES DE INDUSTRIAS DE TRANSFORMACIÓN DE PLÁSTICOS, CAUCHO Y RESINAS NATURALES"
      },
      {
        "code": "3206",
        "name": "SUPERVISORES DE INDUSTRIAS DE LA MADERA Y PASTERO PAPELERAS"
      },
      {
        "code": "3207",
        "name": "SUPERVISORES DE LA PRODUCCIÓN EN INDUSTRIAS DE ARTES GRÁFICAS Y EN LA FABRICACIÓN DE PRODUCTOS DE PAPEL"
      },
      {
        "code": "3209",
        "name": "SUPERVISORES DE OTRAS INDUSTRIAS MANUFACTURERAS"
      },
      {
        "code": "3311",
        "name": "TÉCNICOS EN RADIOTERAPIA"
      },
      {
        "code": "3312",
        "name": "TÉCNICOS EN IMAGEN PARA EL DIAGNÓSTICO"
      },
      {
        "code": "3313",
        "name": "TÉCNICOS EN ANATOMÍA PATOLÓGICA Y CITOLOGÍA"
      },
      {
        "code": "3314",
        "name": "TÉCNICOS EN LABORATORIO DE DIAGNÓSTICO CLÍNICO"
      },
      {
        "code": "3315",
        "name": "TÉCNICOS EN ORTOPRÓTESIS"
      },
      {
        "code": "3316",
        "name": "TÉCNICOS EN PRÓTESIS DENTALES"
      },
      {
        "code": "3317",
        "name": "TÉCNICOS EN AUDIOPRÓTESIS"
      },
      {
        "code": "3321",
        "name": "TÉCNICOS SUPERIORES EN HIGIENE BUCODENTAL"
      },
      {
        "code": "3322",
        "name": "TÉCNICOS SUPERIORES EN DOCUMENTACIÓN SANITARIA"
      },
      {
        "code": "3323",
        "name": "TÉCNICOS SUPERIORES EN DIETÉTICA"
      },
      {
        "code": "3324",
        "name": "TÉCNICOS EN OPTOMETRÍA"
      },
      {
        "code": "3325",
        "name": "AYUDANTES FISIOTERAPEUTAS"
      },
      {
        "code": "3326",
        "name": "TÉCNICOS EN PREVENCIÓN DE RIESGOS LABORALES Y SALUD AMBIENTAL"
      },
      {
        "code": "3327",
        "name": "AYUDANTES DE VETERINARIA"
      },
      {
        "code": "3329",
        "name": "TÉCNICOS DE LA SANIDAD NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "3331",
        "name": "PROFESIONALES DE LA ACUPUNTURA, LA NATUROPATÍA, LA HOMEOPATÍA, LA MEDICINA TRADICIONAL CHINA Y LA AYURVEDA"
      },
      {
        "code": "3339",
        "name": "OTROS PROFESIONALES DE LAS TERAPIAS ALTERNATIVAS"
      },
      {
        "code": "3401",
        "name": "PROFESIONALES DE APOYO E INTERMEDIARIOS DE CAMBIO, BOLSA Y FINANZAS"
      },
      {
        "code": "3402",
        "name": "COMERCIALES DE PRÉSTAMOS Y CRÉDITOS"
      },
      {
        "code": "3403",
        "name": "TENEDORES DE LIBROS"
      },
      {
        "code": "3404",
        "name": "PROFESIONALES DE APOYO EN SERVICIOS ESTADÍSTICOS, MATEMÁTICOS Y AFINES"
      },
      {
        "code": "3405",
        "name": "TASADORES"
      },
      {
        "code": "3510",
        "name": "AGENTES Y REPRESENTANTES COMERCIALES"
      },
      {
        "code": "3521",
        "name": "MEDIADORES Y AGENTES DE SEGUROS"
      },
      {
        "code": "3522",
        "name": "AGENTES DE COMPRAS"
      },
      {
        "code": "3523",
        "name": "CONSIGNATARIOS"
      },
      {
        "code": "3531",
        "name": "REPRESENTANTES DE ADUANAS"
      },
      {
        "code": "3532",
        "name": "ORGANIZADORES DE CONFERENCIAS Y EVENTOS"
      },
      {
        "code": "3533",
        "name": "AGENTES O INTERMEDIARIOS EN LA CONTRATACIÓN DE LA MANO DE OBRA (EXCEPTO REPRESENTANTES DE ESPECTÁCULOS)"
      },
      {
        "code": "3534",
        "name": "AGENTES Y ADMINISTRADORES DE LA PROPIEDAD INMOBILIARIA"
      },
      {
        "code": "3535",
        "name": "PORTAVOCES Y AGENTES DE RELACIONES PÚBLICAS"
      },
      {
        "code": "3539",
        "name": "REPRESENTANTES ARTÍSTICOS Y DEPORTIVOS Y OTROS AGENTES DE SERVICIOS COMERCIALES NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "3611",
        "name": "SUPERVISORES DE SECRETARÍA"
      },
      {
        "code": "3612",
        "name": "ASISTENTES JURÍDICO-LEGALES"
      },
      {
        "code": "3613",
        "name": "ASISTENTES DE DIRECCIÓN Y ADMINISTRATIVOS"
      },
      {
        "code": "3614",
        "name": "SECRETARIOS DE CENTROS MÉDICOS O CLÍNICAS"
      },
      {
        "code": "3621",
        "name": "PROFESIONALES DE APOYO DE LA ADMINISTRACIÓN PÚBLICA DE TRIBUTOS"
      },
      {
        "code": "3622",
        "name": "PROFESIONALES DE APOYO DE LA ADMINISTRACIÓN PÚBLICA DE SERVICIOS SOCIALES"
      },
      {
        "code": "3623",
        "name": "PROFESIONALES DE APOYO DE LA ADMINISTRACIÓN PÚBLICA DE SERVICIOS DE EXPEDICIÓN DE LICENCIAS"
      },
      {
        "code": "3629",
        "name": "OTROS PROFESIONALES DE APOYO DE LA ADMINISTRACIÓN PÚBLICA PARA TAREAS DE INSPECCIÓN Y CONTROL Y TAREAS SIMILARES"
      },
      {
        "code": "3631",
        "name": "TÉCNICOS DE LA POLICÍA NACIONAL, AUTONÓMICA Y LOCAL"
      },
      {
        "code": "3632",
        "name": "SUBOFICIALES DE LA GUARDIA CIVIL"
      },
      {
        "code": "3711",
        "name": "PROFESIONALES DE APOYO DE SERVICIOS JURÍDICOS Y SERVICIOS SIMILARES"
      },
      {
        "code": "3712",
        "name": "DETECTIVES PRIVADOS"
      },
      {
        "code": "3713",
        "name": "PROFESIONALES DE APOYO AL TRABAJO Y A LA EDUCACIÓN SOCIAL"
      },
      {
        "code": "3714",
        "name": "PROMOTORES DE IGUALDAD DE OPORTUNIDADES ENTRE MUJERES Y HOMBRES"
      },
      {
        "code": "3715",
        "name": "ANIMADORES COMUNITARIOS"
      },
      {
        "code": "3716",
        "name": "AUXILIARES LAICOS DE LAS RELIGIONES"
      },
      {
        "code": "3721",
        "name": "ATLETAS Y DEPORTISTAS"
      },
      {
        "code": "3722",
        "name": "ENTRENADORES Y ÁRBITROS DE ACTIVIDADES DEPORTIVAS"
      },
      {
        "code": "3723",
        "name": "INSTRUCTORES DE ACTIVIDADES DEPORTIVAS"
      },
      {
        "code": "3724",
        "name": "MONITORES DE ACTIVIDADES RECREATIVAS Y DE ENTRETENIMIENTO"
      },
      {
        "code": "3731",
        "name": "FOTÓGRAFOS"
      },
      {
        "code": "3732",
        "name": "DISEÑADORES Y DECORADORES DE INTERIOR"
      },
      {
        "code": "3733",
        "name": "TÉCNICOS EN GALERÍAS DE ARTE, MUSEOS Y BIBLIOTECAS"
      },
      {
        "code": "3734",
        "name": "CHEFS"
      },
      {
        "code": "3739",
        "name": "OTROS TÉCNICOS Y PROFESIONALES DE APOYO DE ACTIVIDADES CULTURALES Y ARTÍSTICAS"
      },
      {
        "code": "3811",
        "name": "TÉCNICOS EN OPERACIONES DE SISTEMAS INFORMÁTICOS"
      },
      {
        "code": "3812",
        "name": "TÉCNICOS EN ASISTENCIA AL USUARIO DE TECNOLOGÍAS DE LA INFORMACIÓN"
      },
      {
        "code": "3813",
        "name": "TÉCNICOS EN REDES"
      },
      {
        "code": "3814",
        "name": "TÉCNICOS DE LA WEB"
      },
      {
        "code": "3820",
        "name": "PROGRAMADORES INFORMÁTICOS"
      },
      {
        "code": "3831",
        "name": "TÉCNICOS DE GRABACIÓN AUDIOVISUAL"
      },
      {
        "code": "3832",
        "name": "TÉCNICOS DE RADIODIFUSIÓN"
      },
      {
        "code": "3833",
        "name": "TÉCNICOS DE INGENIERÍA DE LAS TELECOMUNICACIONES"
      },
      {
        "code": "4111",
        "name": "EMPLEADOS DE CONTABILIDAD"
      },
      {
        "code": "4112",
        "name": "EMPLEADOS DE CONTROL DE PERSONAL Y NÓMINAS"
      },
      {
        "code": "4113",
        "name": "EMPLEADOS DE OFICINA DE SERVICIOS ESTADÍSTICOS, FINANCIEROS Y BANCARIOS"
      },
      {
        "code": "4121",
        "name": "EMPLEADOS DE CONTROL DE ABASTECIMIENTOS E INVENTARIO"
      },
      {
        "code": "4122",
        "name": "EMPLEADOS DE OFICINA DE SERVICIOS DE APOYO A LA PRODUCCIÓN"
      },
      {
        "code": "4123",
        "name": "EMPLEADOS DE LOGÍSTICA Y TRANSPORTE DE PASAJEROS Y MERCANCÍAS"
      },
      {
        "code": "4210",
        "name": "EMPLEADOS DE BIBLIOTECAS Y ARCHIVOS"
      },
      {
        "code": "4221",
        "name": "EMPLEADOS DE SERVICIOS DE CORREOS (EXCEPTO EMPLEADOS DE MOSTRADOR)"
      },
      {
        "code": "4222",
        "name": "CODIFICADORES Y CORRECTORES DE IMPRENTA"
      },
      {
        "code": "4223",
        "name": "EMPLEADOS DE SERVICIO DE PERSONAL"
      },
      {
        "code": "4301",
        "name": "GRABADORES DE DATOS"
      },
      {
        "code": "4309",
        "name": "EMPLEADOS ADMINISTRATIVOS SIN TAREAS DE ATENCIÓN AL PÚBLICO NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "4411",
        "name": "EMPLEADOS DE INFORMACIÓN AL USUARIO"
      },
      {
        "code": "4412",
        "name": "RECEPCIONISTAS (EXCEPTO DE HOTELES)"
      },
      {
        "code": "4421",
        "name": "EMPLEADOS DE AGENCIAS DE VIAJES"
      },
      {
        "code": "4422",
        "name": "RECEPCIONISTAS DE HOTELES"
      },
      {
        "code": "4423",
        "name": "TELEFONISTAS"
      },
      {
        "code": "4424",
        "name": "TELEOPERADORES"
      },
      {
        "code": "4430",
        "name": "AGENTES DE ENCUESTAS"
      },
      {
        "code": "4441",
        "name": "CAJEROS DE BANCOS Y AFINES"
      },
      {
        "code": "4442",
        "name": "EMPLEADOS DE VENTA DE APUESTAS"
      },
      {
        "code": "4443",
        "name": "EMPLEADOS DE SALA DE JUEGOS Y AFINES"
      },
      {
        "code": "4444",
        "name": "EMPLEADOS DE CASAS DE EMPEÑO Y DE PRÉSTAMOS"
      },
      {
        "code": "4445",
        "name": "COBRADORES DE FACTURAS, DEUDAS Y EMPLEADOS AFINES"
      },
      {
        "code": "4446",
        "name": "EMPLEADOS DE MOSTRADOR DE CORREOS"
      },
      {
        "code": "4500",
        "name": "EMPLEADOS ADMINISTRATIVOS CON TAREAS DE ATENCIÓN AL PÚBLICO NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "5000",
        "name": "CAMAREROS Y COCINEROS PROPIETARIOS"
      },
      {
        "code": "5110",
        "name": "COCINEROS ASALARIADOS"
      },
      {
        "code": "5120",
        "name": "CAMAREROS ASALARIADOS"
      },
      {
        "code": "5210",
        "name": "JEFES DE SECCIÓN DE TIENDAS Y ALMACENES"
      },
      {
        "code": "5220",
        "name": "VENDEDORES EN TIENDAS Y ALMACENES"
      },
      {
        "code": "5300",
        "name": "COMERCIANTES PROPIETARIOS DE TIENDAS"
      },
      {
        "code": "5411",
        "name": "VENDEDORES EN QUIOSCOS"
      },
      {
        "code": "5412",
        "name": "VENDEDORES EN MERCADOS OCASIONALES Y MERCADILLOS"
      },
      {
        "code": "5420",
        "name": "OPERADORES DE TELEMARKETING"
      },
      {
        "code": "5430",
        "name": "EXPENDEDORES DE GASOLINERAS"
      },
      {
        "code": "5491",
        "name": "VENDEDORES A DOMICILIO"
      },
      {
        "code": "5492",
        "name": "PROMOTORES DE VENTA"
      },
      {
        "code": "5493",
        "name": "MODELOS DE MODA, ARTE Y PUBLICIDAD"
      },
      {
        "code": "5499",
        "name": "VENDEDORES NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "5500",
        "name": "CAJEROS Y TAQUILLEROS (EXCEPTO BANCOS)"
      },
      {
        "code": "5611",
        "name": "AUXILIARES DE ENFERMERÍA HOSPITALARIA"
      },
      {
        "code": "5612",
        "name": "AUXILIARES DE ENFERMERÍA DE ATENCIÓN PRIMARIA"
      },
      {
        "code": "5621",
        "name": "TÉCNICOS AUXILIARES DE FARMACIA"
      },
      {
        "code": "5622",
        "name": "TÉCNICOS DE EMERGENCIAS SANITARIAS"
      },
      {
        "code": "5629",
        "name": "TRABAJADORES DE LOS CUIDADOS A LAS PERSONAS EN SERVICIOS DE SALUD NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "5710",
        "name": "TRABAJADORES DE LOS CUIDADOS PERSONALES A DOMICILIO"
      },
      {
        "code": "5721",
        "name": "CUIDADORES DE NIÑOS EN GUARDERÍAS Y CENTROS EDUCATIVOS"
      },
      {
        "code": "5722",
        "name": "CUIDADORES DE NIÑOS EN DOMICILIOS"
      },
      {
        "code": "5811",
        "name": "PELUQUEROS"
      },
      {
        "code": "5812",
        "name": "ESPECIALISTAS EN TRATAMIENTOS DE ESTÉTICA, BIENESTAR Y AFINES"
      },
      {
        "code": "5821",
        "name": "AUXILIARES DE VUELO Y CAMAREROS DE AVIÓN, BARCO Y TREN"
      },
      {
        "code": "5822",
        "name": "REVISORES Y COBRADORES DE TRANSPORTE TERRESTRE"
      },
      {
        "code": "5823",
        "name": "ACOMPAÑANTES TURÍSTICOS"
      },
      {
        "code": "5824",
        "name": "AZAFATOS DE TIERRA"
      },
      {
        "code": "5825",
        "name": "GUÍAS DE TURISMO"
      },
      {
        "code": "5831",
        "name": "SUPERVISORES DE MANTENIMIENTO Y LIMPIEZA EN OFICINAS, HOTELES Y OTROS ESTABLECIMIENTOS"
      },
      {
        "code": "5832",
        "name": "MAYORDOMOS DEL SERVICIO DOMÉSTICO"
      },
      {
        "code": "5833",
        "name": "CONSERJES DE EDIFICIOS"
      },
      {
        "code": "5840",
        "name": "TRABAJADORES PROPIETARIOS DE PEQUEÑOS ALOJAMIENTOS"
      },
      {
        "code": "5891",
        "name": "ASISTENTES PERSONALES O PERSONAS DE COMPAÑÍA"
      },
      {
        "code": "5892",
        "name": "EMPLEADOS DE POMPAS FÚNEBRES Y EMBALSAMADORES"
      },
      {
        "code": "5893",
        "name": "CUIDADORES DE ANIMALES Y ADIESTRADORES"
      },
      {
        "code": "5894",
        "name": "INSTRUCTORES DE AUTOESCUELA"
      },
      {
        "code": "5895",
        "name": "ASTRÓLOGOS, ADIVINADORES Y AFINES"
      },
      {
        "code": "5899",
        "name": "TRABAJADORES DE SERVICIOS PERSONALES NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "5910",
        "name": "GUARDIAS CIVILES"
      },
      {
        "code": "5921",
        "name": "POLICÍAS NACIONALES"
      },
      {
        "code": "5922",
        "name": "POLICÍAS AUTONÓMICOS"
      },
      {
        "code": "5923",
        "name": "POLICÍAS LOCALES"
      },
      {
        "code": "5931",
        "name": "BOMBEROS (EXCEPTO FORESTALES)"
      },
      {
        "code": "5932",
        "name": "BOMBEROS FORESTALES"
      },
      {
        "code": "5941",
        "name": "VIGILANTES DE SEGURIDAD Y SIMILARES HABILITADOS PARA IR ARMADOS"
      },
      {
        "code": "5942",
        "name": "AUXILIARES DE VIGILANTE DE SEGURIDAD Y SIMILARES NO HABILITADOS PARA IR ARMADOS"
      },
      {
        "code": "5991",
        "name": "VIGILANTES DE PRISIONES"
      },
      {
        "code": "5992",
        "name": "BAÑISTAS-SOCORRISTAS"
      },
      {
        "code": "5993",
        "name": "AGENTES FORESTALES Y MEDIOAMBIENTALES"
      },
      {
        "code": "5999",
        "name": "TRABAJADORES DE LOS SERVICIOS DE PROTECCIÓN Y SEGURIDAD NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "6110",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES AGRÍCOLAS (EXCEPTO EN HUERTAS, INVERNADEROS, VIVEROS Y JARDINES)"
      },
      {
        "code": "6120",
        "name": "TRABAJADORES CUALIFICADOS EN HUERTAS, INVERNADEROS, VIVEROS Y JARDINES"
      },
      {
        "code": "6201",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS DE VACUNO"
      },
      {
        "code": "6202",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS DE OVINO Y CAPRINO"
      },
      {
        "code": "6203",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS DE PORCINO"
      },
      {
        "code": "6204",
        "name": "TRABAJADORES CUALIFICADOS EN APICULTURA Y SERICICULTURA"
      },
      {
        "code": "6205",
        "name": "TRABAJADORES CUALIFICADOS EN LA AVICULTURA Y LA CUNICULTURA"
      },
      {
        "code": "6209",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES GANADERAS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "6300",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES AGROPECUARIAS MIXTAS"
      },
      {
        "code": "6410",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES FORESTALES Y DEL MEDIO NATURAL"
      },
      {
        "code": "6421",
        "name": "TRABAJADORES CUALIFICADOS EN LA ACUICULTURA"
      },
      {
        "code": "6422",
        "name": "PESCADORES DE AGUAS COSTERAS Y AGUAS DULCES"
      },
      {
        "code": "6423",
        "name": "PESCADORES DE ALTURA"
      },
      {
        "code": "6430",
        "name": "TRABAJADORES CUALIFICADOS EN ACTIVIDADES CINEGÉTICAS"
      },
      {
        "code": "7111",
        "name": "ENCOFRADORES Y OPERARIOS DE PUESTA EN OBRA DE HORMIGÓN"
      },
      {
        "code": "7112",
        "name": "MONTADORES DE PREFABRICADOS ESTRUCTURALES (SÓLO HORMIGÓN)"
      },
      {
        "code": "7121",
        "name": "ALBAÑILES"
      },
      {
        "code": "7122",
        "name": "CANTEROS, TRONZADORES, LABRANTES Y GRABADORES DE PIEDRAS"
      },
      {
        "code": "7131",
        "name": "CARPINTEROS (EXCEPTO EBANISTAS)"
      },
      {
        "code": "7132",
        "name": "INSTALADORES DE CERRAMIENTOS METÁLICOS Y CARPINTEROS METÁLICOS (EXCEPTO MONTADORES DE ESTRUCTURAS METÁLICAS)"
      },
      {
        "code": "7191",
        "name": "MANTENEDORES DE EDIFICIOS"
      },
      {
        "code": "7192",
        "name": "INSTALADORES DE FACHADAS TÉCNICAS"
      },
      {
        "code": "7193",
        "name": "INSTALADORES DE SISTEMAS DE IMPERMEABILIZACIÓN EN EDIFICIOS"
      },
      {
        "code": "7199",
        "name": "OTROS TRABAJADORES DE LAS OBRAS ESTRUCTURALES DE CONSTRUCCIÓN NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "7211",
        "name": "ESCAYOLISTAS"
      },
      {
        "code": "7212",
        "name": "APLICADORES DE REVESTIMIENTOS DE PASTA Y MORTERO"
      },
      {
        "code": "7221",
        "name": "FONTANEROS"
      },
      {
        "code": "7222",
        "name": "MONTADORES-INSTALADORES DE GAS EN EDIFICIOS"
      },
      {
        "code": "7223",
        "name": "INSTALADORES DE CONDUCTOS EN OBRA PÚBLICA"
      },
      {
        "code": "7231",
        "name": "PINTORES Y EMPAPELADORES"
      },
      {
        "code": "7232",
        "name": "PINTORES EN LAS INDUSTRIAS MANUFACTURERAS"
      },
      {
        "code": "7240",
        "name": "SOLADORES, COLOCADORES DE PARQUET Y AFINES"
      },
      {
        "code": "7250",
        "name": "MECÁNICOS-INSTALADORES DE REFRIGERACIÓN Y CLIMATIZACIÓN"
      },
      {
        "code": "7291",
        "name": "MONTADORES DE CUBIERTAS"
      },
      {
        "code": "7292",
        "name": "INSTALADORES DE MATERIAL AISLANTE TÉRMICO Y DE INSONORIZACIÓN"
      },
      {
        "code": "7293",
        "name": "CRISTALEROS"
      },
      {
        "code": "7294",
        "name": "MONTADORES-INSTALADORES DE PLACAS DE ENERGÍA SOLAR"
      },
      {
        "code": "7295",
        "name": "PERSONAL DE LIMPIEZA DE FACHADAS DE EDIFICIOS Y CHIMENEAS"
      },
      {
        "code": "7311",
        "name": "MOLDEADORES Y MACHEROS"
      },
      {
        "code": "7312",
        "name": "SOLDADORES Y OXICORTADORES"
      },
      {
        "code": "7313",
        "name": "CHAPISTAS Y CALDEREROS"
      },
      {
        "code": "7314",
        "name": "MONTADORES DE ESTRUCTURAS METÁLICAS"
      },
      {
        "code": "7315",
        "name": "MONTADORES DE ESTRUCTURAS CABLEADAS Y EMPALMADORES DE CABLES"
      },
      {
        "code": "7321",
        "name": "HERREROS Y FORJADORES"
      },
      {
        "code": "7322",
        "name": "TRABAJADORES DE LA FABRICACIÓN DE HERRAMIENTAS, MECÁNICO-AJUSTADORES, MODELISTAS, MATRICEROS Y AFINES"
      },
      {
        "code": "7323",
        "name": "AJUSTADORES Y OPERADORES DE MÁQUINAS-HERRAMIENTA"
      },
      {
        "code": "7324",
        "name": "PULIDORES DE METALES Y AFILADORES DE HERRAMIENTAS"
      },
      {
        "code": "7401",
        "name": "MECÁNICOS Y AJUSTADORES DE VEHÍCULOS DE MOTOR"
      },
      {
        "code": "7402",
        "name": "MECÁNICOS Y AJUSTADORES DE MOTORES DE AVIÓN"
      },
      {
        "code": "7403",
        "name": "MECÁNICOS Y AJUSTADORES DE MAQUINARIA AGRÍCOLA E INDUSTRIAL"
      },
      {
        "code": "7404",
        "name": "MECÁNICOS Y AJUSTADORES DE MAQUINARIA NAVAL Y FERROVIARIA"
      },
      {
        "code": "7405",
        "name": "REPARADORES DE BICICLETAS Y AFINES"
      },
      {
        "code": "7510",
        "name": "ELECTRICISTAS DE LA CONSTRUCCIÓN Y AFINES"
      },
      {
        "code": "7521",
        "name": "MECÁNICOS Y REPARADORES DE EQUIPOS ELÉCTRICOS"
      },
      {
        "code": "7522",
        "name": "INSTALADORES Y REPARADORES DE LÍNEAS ELÉCTRICAS"
      },
      {
        "code": "7531",
        "name": "MECÁNICOS Y REPARADORES DE EQUIPOS ELECTRÓNICOS"
      },
      {
        "code": "7532",
        "name": "INSTALADORES Y REPARADORES EN ELECTROMEDICINA"
      },
      {
        "code": "7533",
        "name": "INSTALADORES Y REPARADORES EN TECNOLOGÍAS DE LA INFORMACIÓN Y LAS COMUNICACIONES"
      },
      {
        "code": "7611",
        "name": "RELOJEROS Y MECÁNICOS DE INSTRUMENTOS DE PRECISIÓN"
      },
      {
        "code": "7612",
        "name": "LUTIERES Y SIMILARES; AFINADORES DE INSTRUMENTOS MUSICALES"
      },
      {
        "code": "7613",
        "name": "JOYEROS, ORFEBRES Y PLATEROS"
      },
      {
        "code": "7614",
        "name": "TRABAJADORES DE LA CERÁMICA, ALFAREROS Y AFINES"
      },
      {
        "code": "7615",
        "name": "SOPLADORES, MODELADORES, LAMINADORES, CORTADORES Y PULIDORES DE VIDRIO"
      },
      {
        "code": "7616",
        "name": "ROTULISTAS, GRABADORES DE VIDRIO, PINTORES DECORATIVOS DE ARTÍCULOS DIVERSOS"
      },
      {
        "code": "7617",
        "name": "ARTESANOS EN MADERA Y MATERIALES SIMILARES; CESTEROS, BRUCEROS Y TRABAJADORES AFINES"
      },
      {
        "code": "7618",
        "name": "ARTESANOS EN TEJIDOS, CUEROS Y MATERIALES SIMILARES, PREPARADORES DE FIBRA Y TEJEDORES CON TELARES ARTESANOS O DE TEJIDOS DE PUNTO Y AFINES"
      },
      {
        "code": "7619",
        "name": "ARTESANOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "7621",
        "name": "TRABAJADORES DE PROCESOS DE PREIMPRESIÓN"
      },
      {
        "code": "7622",
        "name": "TRABAJADORES DE PROCESOS DE IMPRESIÓN"
      },
      {
        "code": "7623",
        "name": "TRABAJADORES DE PROCESOS DE ENCUADERNACIÓN"
      },
      {
        "code": "7701",
        "name": "MATARIFES Y TRABAJADORES DE LAS INDUSTRIAS CÁRNICAS"
      },
      {
        "code": "7702",
        "name": "TRABAJADORES DE LAS INDUSTRIAS DEL PESCADO"
      },
      {
        "code": "7703",
        "name": "PANADEROS, PASTELEROS Y CONFITEROS"
      },
      {
        "code": "7704",
        "name": "TRABAJADORES DEL TRATAMIENTO DE LA LECHE Y ELABORACIÓN DE PRODUCTOS LÁCTEOS (INCLUIDOS HELADOS)"
      },
      {
        "code": "7705",
        "name": "TRABAJADORES CONSERVEROS DE FRUTAS Y HORTALIZAS Y TRABAJADORES DE LA ELABORACIÓN DE BEBIDAS NO ALCOHÓLICAS"
      },
      {
        "code": "7706",
        "name": "TRABAJADORES DE LA ELABORACIÓN DE BEBIDAS ALCOHÓLICAS DISTINTAS DEL VINO"
      },
      {
        "code": "7707",
        "name": "TRABAJADORES DE LA ELABORACIÓN DEL VINO"
      },
      {
        "code": "7708",
        "name": "PREPARADORES Y ELABORADORES DEL TABACO Y SUS PRODUCTOS"
      },
      {
        "code": "7709",
        "name": "CATADORES Y CLASIFICADORES DE ALIMENTOS Y BEBIDAS"
      },
      {
        "code": "7811",
        "name": "TRABAJADORES DEL TRATAMIENTO DE LA MADERA"
      },
      {
        "code": "7812",
        "name": "AJUSTADORES Y OPERADORES DE MÁQUINAS PARA TRABAJAR LA MADERA"
      },
      {
        "code": "7820",
        "name": "EBANISTAS Y TRABAJADORES AFINES"
      },
      {
        "code": "7831",
        "name": "SASTRES, MODISTOS, PELETEROS Y SOMBREREROS"
      },
      {
        "code": "7832",
        "name": "PATRONISTAS PARA PRODUCTOS EN TEXTIL Y PIEL"
      },
      {
        "code": "7833",
        "name": "CORTADORES DE TEJIDOS, CUERO, PIEL Y OTROS MATERIALES"
      },
      {
        "code": "7834",
        "name": "COSTUREROS A MANO, BORDADORES Y AFINES"
      },
      {
        "code": "7835",
        "name": "TAPICEROS, COLCHONEROS Y AFINES"
      },
      {
        "code": "7836",
        "name": "CURTIDORES Y PREPARADORES DE PIELES"
      },
      {
        "code": "7837",
        "name": "ZAPATEROS Y AFINES"
      },
      {
        "code": "7891",
        "name": "BUCEADORES"
      },
      {
        "code": "7892",
        "name": "PEGADORES"
      },
      {
        "code": "7893",
        "name": "CLASIFICADORES Y PROBADORES DE PRODUCTOS (EXCEPTO ALIMENTOS, BEBIDAS Y TABACO)"
      },
      {
        "code": "7894",
        "name": "FUMIGADORES Y OTROS CONTROLADORES DE PLAGAS Y MALAS HIERBAS"
      },
      {
        "code": "7899",
        "name": "OFICIALES, OPERARIOS Y ARTESANOS DE OTROS OFICIOS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "8111",
        "name": "MINEROS Y OTROS OPERADORES EN INSTALACIONES MINERAS"
      },
      {
        "code": "8112",
        "name": "OPERADORES EN INSTALACIONES PARA LA PREPARACIÓN DE MINERALES Y ROCAS"
      },
      {
        "code": "8113",
        "name": "SONDISTAS Y TRABAJADORES AFINES"
      },
      {
        "code": "8114",
        "name": "OPERADORES DE MAQUINARIA PARA FABRICAR PRODUCTOS DERIVADOS DE MINERALES NO METÁLICOS"
      },
      {
        "code": "8121",
        "name": "OPERADORES EN INSTALACIONES PARA LA OBTENCIÓN Y TRANSFORMACIÓN DE METALES"
      },
      {
        "code": "8122",
        "name": "OPERADORES DE MÁQUINAS PULIDORAS, GALVANIZADORAS Y RECUBRIDORAS DE METALES"
      },
      {
        "code": "8131",
        "name": "OPERADORES EN PLANTAS INDUSTRIALES QUÍMICAS"
      },
      {
        "code": "8132",
        "name": "OPERADORES DE MÁQUINAS PARA FABRICAR PRODUCTOS FARMACÉUTICOS, COSMÉTICOS Y AFINES"
      },
      {
        "code": "8133",
        "name": "OPERADORES DE LABORATORIOS FOTOGRÁFICOS Y AFINES"
      },
      {
        "code": "8141",
        "name": "OPERADORES DE MÁQUINAS PARA FABRICAR PRODUCTOS DE CAUCHO Y DERIVADOS DE RESINAS NATURALES"
      },
      {
        "code": "8142",
        "name": "OPERADORES DE MÁQUINAS PARA FABRICAR PRODUCTOS DE MATERIAL PLÁSTICO"
      },
      {
        "code": "8143",
        "name": "OPERADORES DE MÁQUINAS PARA FABRICAR PRODUCTOS DE PAPEL Y CARTÓN"
      },
      {
        "code": "8144",
        "name": "OPERADORES DE SERRERÍAS, DE MÁQUINAS DE FABRICACIÓN DE TABLEROS Y DE INSTALACIONES AFINES PARA EL TRATAMIENTO DE LA MADERA Y EL CORCHO"
      },
      {
        "code": "8145",
        "name": "OPERADORES EN INSTALACIONES PARA LA PREPARACIÓN DE PASTA DE PAPEL Y FABRICACIÓN DE PAPEL"
      },
      {
        "code": "8151",
        "name": "OPERADORES DE MÁQUINAS PARA PREPARAR FIBRAS, HILAR Y DEVANAR"
      },
      {
        "code": "8152",
        "name": "OPERADORES DE TELARES Y OTRAS MÁQUINAS TEJEDORAS"
      },
      {
        "code": "8153",
        "name": "OPERADORES DE MÁQUINAS DE COSER Y BORDAR"
      },
      {
        "code": "8154",
        "name": "OPERADORES DE MÁQUINAS DE BLANQUEAR, TEÑIR, ESTAMPAR Y ACABAR TEXTILES"
      },
      {
        "code": "8155",
        "name": "OPERADORES DE MÁQUINAS PARA TRATAR PIELES Y CUERO"
      },
      {
        "code": "8156",
        "name": "OPERADORES DE MÁQUINAS PARA LA FABRICACIÓN DEL CALZADO, MARROQUINERÍA Y GUANTERÍA DE PIEL"
      },
      {
        "code": "8159",
        "name": "OPERADORES DE MÁQUINAS PARA FABRICAR PRODUCTOS TEXTILES NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "8160",
        "name": "OPERADORES DE MÁQUINAS PARA ELABORAR PRODUCTOS ALIMENTICIOS, BEBIDAS Y TABACO"
      },
      {
        "code": "8170",
        "name": "OPERADORES DE MÁQUINAS DE LAVANDERÍA Y TINTORERÍA"
      },
      {
        "code": "8191",
        "name": "OPERADORES DE HORNOS E INSTALACIONES DE VIDRIERÍA Y CERÁMICA"
      },
      {
        "code": "8192",
        "name": "OPERADORES DE CALDERAS Y MÁQUINAS DE VAPOR"
      },
      {
        "code": "8193",
        "name": "OPERADORES DE MÁQUINAS DE EMBALAJE, EMBOTELLAMIENTO Y ETIQUETADO"
      },
      {
        "code": "8199",
        "name": "OPERADORES DE INSTALACIONES Y MAQUINARIA FIJAS NO CLASIFICADOS BAJO OTROS EPÍGRAFES"
      },
      {
        "code": "8201",
        "name": "ENSAMBLADORES DE MAQUINARIA MECÁNICA"
      },
      {
        "code": "8202",
        "name": "ENSAMBLADORES DE EQUIPOS ELÉCTRICOS Y ELECTRÓNICOS"
      },
      {
        "code": "8209",
        "name": "MONTADORES Y ENSAMBLADORES NO CLASIFICADOS EN OTROS EPÍGRAFES"
      },
      {
        "code": "8311",
        "name": "MAQUINISTAS DE LOCOMOTORAS"
      },
      {
        "code": "8312",
        "name": "AGENTES DE MANIOBRAS FERROVIARIAS"
      },
      {
        "code": "8321",
        "name": "OPERADORES DE MAQUINARIA AGRÍCOLA MÓVIL"
      },
      {
        "code": "8322",
        "name": "OPERADORES DE MAQUINARIA FORESTAL MÓVIL"
      },
      {
        "code": "8331",
        "name": "OPERADORES DE MAQUINARIA DE MOVIMIENTOS DE TIERRAS Y EQUIPOS SIMILARES"
      },
      {
        "code": "8332",
        "name": "OPERADORES DE GRÚAS, MONTACARGAS Y DE MAQUINARIA SIMILAR DE MOVIMIENTO DE MATERIALES"
      },
      {
        "code": "8333",
        "name": "OPERADORES DE CARRETILLAS ELEVADORAS"
      },
      {
        "code": "8340",
        "name": "MARINEROS DE PUENTE, MARINEROS DE MÁQUINAS Y AFINES"
      },
      {
        "code": "8411",
        "name": "CONDUCTORES PROPIETARIOS DE AUTOMÓVILES, TAXIS Y FURGONETAS"
      },
      {
        "code": "8412",
        "name": "CONDUCTORES ASALARIADOS DE AUTOMÓVILES, TAXIS Y FURGONETAS"
      },
      {
        "code": "8420",
        "name": "CONDUCTORES DE AUTOBUSES Y TRANVÍAS"
      },
      {
        "code": "8431",
        "name": "CONDUCTORES PROPIETARIOS DE CAMIONES"
      },
      {
        "code": "8432",
        "name": "CONDUCTORES ASALARIADOS DE CAMIONES"
      },
      {
        "code": "8440",
        "name": "CONDUCTORES DE MOTOCICLETAS Y CICLOMOTORES"
      },
      {
        "code": "9100",
        "name": "EMPLEADOS DOMÉSTICOS"
      },
      {
        "code": "9210",
        "name": "PERSONAL DE LIMPIEZA DE OFICINAS, HOTELES Y OTROS ESTABLECIMIENTOS SIMILARES"
      },
      {
        "code": "9221",
        "name": "LIMPIADORES EN SECO A MANO Y AFINES"
      },
      {
        "code": "9222",
        "name": "LIMPIADORES DE VEHÍCULOS"
      },
      {
        "code": "9223",
        "name": "LIMPIADORES DE VENTANAS"
      },
      {
        "code": "9229",
        "name": "OTRO PERSONAL DE LIMPIEZA"
      },
      {
        "code": "9310",
        "name": "AYUDANTES DE COCINA"
      },
      {
        "code": "9320",
        "name": "PREPARADORES DE COMIDAS RÁPIDAS"
      },
      {
        "code": "9410",
        "name": "VENDEDORES CALLEJEROS"
      },
      {
        "code": "9420",
        "name": "REPARTIDORES DE PUBLICIDAD, LIMPIABOTAS Y OTROS TRABAJADORES DE OFICIOS CALLEJEROS"
      },
      {
        "code": "9431",
        "name": "ORDENANZAS"
      },
      {
        "code": "9432",
        "name": "MOZOS DE EQUIPAJE Y AFINES"
      },
      {
        "code": "9433",
        "name": "REPARTIDORES, RECADISTAS Y MENSAJEROS A PIE"
      },
      {
        "code": "9434",
        "name": "LECTORES DE CONTADORES Y RECAUDADORES DE MÁQUINAS RECREATIVAS Y EXPENDEDORAS"
      },
      {
        "code": "9441",
        "name": "RECOGEDORES DE RESIDUOS"
      },
      {
        "code": "9442",
        "name": "CLASIFICADORES DE DESECHOS, OPERARIOS DE PUNTO LIMPIO Y RECOGEDORES DE CHATARRA"
      },
      {
        "code": "9443",
        "name": "BARRENDEROS Y AFINES"
      },
      {
        "code": "9490",
        "name": "OTRAS OCUPACIONES ELEMENTALES"
      },
      {
        "code": "9511",
        "name": "PEONES AGRÍCOLAS (EXCEPTO EN HUERTAS, INVERNADEROS, VIVEROS Y JARDINES)"
      },
      {
        "code": "9512",
        "name": "PEONES AGRÍCOLAS EN HUERTAS, INVERNADEROS, VIVEROS Y JARDINES"
      },
      {
        "code": "9520",
        "name": "PEONES GANADEROS"
      },
      {
        "code": "9530",
        "name": "PEONES AGROPECUARIOS"
      },
      {
        "code": "9541",
        "name": "PEONES DE LA PESCA"
      },
      {
        "code": "9542",
        "name": "PEONES DE LA ACUICULTURA"
      },
      {
        "code": "9543",
        "name": "PEONES FORESTALES Y DE LA CAZA"
      },
      {
        "code": "9601",
        "name": "PEONES DE OBRAS PÚBLICAS"
      },
      {
        "code": "9602",
        "name": "PEONES DE LA CONSTRUCCIÓN DE EDIFICIOS"
      },
      {
        "code": "9603",
        "name": "PEONES DE LA MINERÍA, CANTERAS Y OTRAS INDUSTRIAS EXTRACTIVAS"
      },
      {
        "code": "9700",
        "name": "PEONES DE LAS INDUSTRIAS MANUFACTURERAS"
      },
      {
        "code": "9811",
        "name": "PEONES DEL TRANSPORTE DE MERCANCÍAS Y DESCARGADORES"
      },
      {
        "code": "9812",
        "name": "CONDUCTORES DE VEHÍCULOS DE TRACCIÓN ANIMAL PARA EL TRANSPORTE DE PERSONAS Y SIMILARES"
      },
      {
        "code": "9820",
        "name": "REPONEDORES"
      }
     ]
     return of (cnoList)
  }
  public cnaeSelectList(): Observable<any>{
    const cnaeList = [
      {
        "code": "0111",
        "name": "CULTIVO DE CEREALES (EXCEPTO ARROZ), LEGUMINOSAS Y SEMILLAS OLEAGINOSAS"
      },
      {
        "code": "0112",
        "name": "CULTIVO DE ARROZ"
      },
      {
        "code": "0113",
        "name": "CULTIVO DE HORTALIZAS, RAÍCES Y TUBÉRCULOS"
      },
      {
        "code": "0114",
        "name": "CULTIVO DE CAÑA DE AZÚCAR"
      },
      {
        "code": "0115",
        "name": "CULTIVO DE TABACO"
      },
      {
        "code": "0116",
        "name": "CULTIVO DE PLANTAS PARA FIBRAS TEXTILES"
      },
      {
        "code": "0119",
        "name": "OTROS CULTIVOS NO PERENNES"
      },
      {
        "code": "0121",
        "name": "CULTIVO DE LA VID"
      },
      {
        "code": "0122",
        "name": "CULTIVO DE FRUTOS TROPICALES Y SUBTROPICALES"
      },
      {
        "code": "0123",
        "name": "CULTIVO DE CÍTRICOS"
      },
      {
        "code": "0124",
        "name": "CULTIVO DE FRUTOS CON HUESO Y PEPITAS"
      },
      {
        "code": "0125",
        "name": "CULTIVO DE OTROS ÁRBOLES Y ARBUSTOS FRUTALES Y FRUTOS SECOS"
      },
      {
        "code": "0126",
        "name": "CULTIVO DE FRUTOS OLEAGINOSOS"
      },
      {
        "code": "0127",
        "name": "CULTIVO DE PLANTAS PARA BEBIDAS"
      },
      {
        "code": "0128",
        "name": "CULTIVO DE ESPECIAS, PLANTAS AROMÁTICAS, MEDICINALES Y FARMACÉUTICAS"
      },
      {
        "code": "0129",
        "name": "OTROS CULTIVOS PERENNES"
      },
      {
        "code": "0130",
        "name": "PROPAGACIÓN DE PLANTAS"
      },
      {
        "code": "0141",
        "name": "EXPLOTACIÓN DE GANADO BOVINO PARA LA PRODUCCIÓN DE LECHE"
      },
      {
        "code": "0142",
        "name": "EXPLOTACIÓN DE OTRO GANADO BOVINO Y BÚFALOS"
      },
      {
        "code": "0143",
        "name": "EXPLOTACIÓN DE CABALLOS Y OTROS EQUINOS"
      },
      {
        "code": "0144",
        "name": "EXPLOTACIÓN DE CAMELLOS Y OTROS CAMÉLIDOS"
      },
      {
        "code": "0145",
        "name": "EXPLOTACIÓN DE GANADO OVINO Y CAPRINO"
      },
      {
        "code": "0146",
        "name": "EXPLOTACIÓN DE GANADO PORCINO"
      },
      {
        "code": "0147",
        "name": "AVICULTURA"
      },
      {
        "code": "0149",
        "name": "OTRAS EXPLOTACIONES DE GANADO"
      },
      {
        "code": "0150",
        "name": "PRODUCCIÓN AGRÍCOLA COMBINADA CON LA PRODUCCIÓN GANADERA"
      },
      {
        "code": "0161",
        "name": "ACTIVIDADES DE APOYO A LA AGRICULTURA"
      },
      {
        "code": "0162",
        "name": "ACTIVIDADES DE APOYO A LA GANADERÍA"
      },
      {
        "code": "0163",
        "name": "ACTIVIDADES DE PREPARACIÓN POSTERIOR A LA COSECHA"
      },
      {
        "code": "0164",
        "name": "TRATAMIENTO DE SEMILLAS PARA REPRODUCCIÓN"
      },
      {
        "code": "0170",
        "name": "CAZA, CAPTURA DE ANIMALES Y SERVICIOS RELACIONADOS CON LAS MISMAS"
      },
      {
        "code": "0210",
        "name": "SILVICULTURA Y OTRAS ACTIVIDADES FORESTALES"
      },
      {
        "code": "0220",
        "name": "EXPLOTACIÓN DE LA MADERA"
      },
      {
        "code": "0230",
        "name": "RECOLECCIÓN DE PRODUCTOS SILVESTRES, EXCEPTO MADERA"
      },
      {
        "code": "0240",
        "name": "SERVICIOS DE APOYO A LA SILVICULTURA"
      },
      {
        "code": "0311",
        "name": "PESCA MARINA"
      },
      {
        "code": "0312",
        "name": "PESCA EN AGUA DULCE"
      },
      {
        "code": "0321",
        "name": "ACUICULTURA MARINA"
      },
      {
        "code": "0322",
        "name": "ACUICULTURA EN AGUA DULCE"
      },
      {
        "code": "0510",
        "name": "EXTRACCIÓN DE ANTRACITA Y HULLA"
      },
      {
        "code": "0520",
        "name": "EXTRACCIÓN DE LIGNITO"
      },
      {
        "code": "0610",
        "name": "EXTRACCIÓN DE CRUDO DE PETRÓLEO"
      },
      {
        "code": "0620",
        "name": "EXTRACCIÓN DE GAS NATURAL"
      },
      {
        "code": "0710",
        "name": "EXTRACCIÓN DE MINERALES DE HIERRO"
      },
      {
        "code": "0721",
        "name": "EXTRACCIÓN DE MINERALES DE URANIO Y TORIO"
      },
      {
        "code": "0729",
        "name": "EXTRACCIÓN DE OTROS MINERALES METÁLICOS NO FÉRREOS"
      },
      {
        "code": "0811",
        "name": "EXTRACCIÓN DE PIEDRA ORNAMENTAL Y PARA LA CONSTRUCCIÓN, PIEDRA CALIZA, YESO, CRETA Y PIZARRA"
      },
      {
        "code": "0812",
        "name": "EXTRACCIÓN DE GRAVAS Y ARENAS; EXTRACCIÓN DE ARCILLA Y CAOLÍN"
      },
      {
        "code": "0891",
        "name": "EXTRACCIÓN DE MINERALES PARA PRODUCTOS QUÍMICOS Y FERTILIZANTES"
      },
      {
        "code": "0892",
        "name": "EXTRACCIÓN DE TURBA"
      },
      {
        "code": "0893",
        "name": "EXTRACCIÓN DE SAL"
      },
      {
        "code": "0899",
        "name": "OTRAS INDUSTRIAS EXTRACTIVAS N.C.O.P."
      },
      {
        "code": "0910",
        "name": "ACTIVIDADES DE APOYO A LA EXTRACCIÓN DE PETRÓLEO Y GAS NATURAL"
      },
      {
        "code": "0990",
        "name": "ACTIVIDADES DE APOYO A OTRAS INDUSTRIAS EXTRACTIVAS"
      },
      {
        "code": "1011",
        "name": "PROCESADO Y CONSERVACIÓN DE CARNE"
      },
      {
        "code": "1012",
        "name": "PROCESADO Y CONSERVACIÓN DE VOLATERÍA"
      },
      {
        "code": "1013",
        "name": "ELABORACIÓN DE PRODUCTOS CÁRNICOS Y DE VOLATERÍA"
      },
      {
        "code": "1021",
        "name": "PROCESADO DE PESCADOS, CRUSTÁCEOS Y MOLUSCOS"
      },
      {
        "code": "1022",
        "name": "FABRICACIÓN DE CONSERVAS DE PESCADO"
      },
      {
        "code": "1031",
        "name": "PROCESADO Y CONSERVACIÓN DE PATATAS"
      },
      {
        "code": "1032",
        "name": "ELABORACIÓN DE ZUMOS DE FRUTAS Y HORTALIZAS"
      },
      {
        "code": "1039",
        "name": "OTRO PROCESADO Y CONSERVACIÓN DE FRUTAS Y HORTALIZAS"
      },
      {
        "code": "1042",
        "name": "FABRICACIÓN DE MARGARINA Y GRASAS COMESTIBLES SIMILARES"
      },
      {
        "code": "1043",
        "name": "FABRICACIÓN DE ACEITE DE OLIVA"
      },
      {
        "code": "1044",
        "name": "FABRICACIÓN DE OTROS ACEITES Y GRASAS"
      },
      {
        "code": "1052",
        "name": "ELABORACIÓN DE HELADOS"
      },
      {
        "code": "1053",
        "name": "FABRICACIÓN DE QUESOS"
      },
      {
        "code": "1054",
        "name": "PREPARACIÓN DE LECHE Y OTROS PRODUCTOS LÁCTEOS"
      },
      {
        "code": "1061",
        "name": "FABRICACIÓN DE PRODUCTOS DE MOLINERÍA"
      },
      {
        "code": "1062",
        "name": "FABRICACIÓN DE ALMIDONES Y PRODUCTOS AMILÁCEOS"
      },
      {
        "code": "1071",
        "name": "FABRICACIÓN DE PAN Y DE PRODUCTOS FRESCOS DE PANADERÍA Y PASTELERÍA"
      },
      {
        "code": "1072",
        "name": "FABRICACIÓN DE GALLETAS Y PRODUCTOS DE PANADERÍA Y PASTELERÍA DE LARGA DURACIÓN"
      },
      {
        "code": "1073",
        "name": "FABRICACIÓN DE PASTAS ALIMENTICIAS, CUSCÚS Y PRODUCTOS SIMILARES"
      },
      {
        "code": "1081",
        "name": "FABRICACIÓN DE AZÚCAR"
      },
      {
        "code": "1082",
        "name": "FABRICACIÓN DE CACAO, CHOCOLATE Y PRODUCTOS DE CONFITERÍA"
      },
      {
        "code": "1083",
        "name": "ELABORACIÓN DE CAFÉ, TÉ E INFUSIONES"
      },
      {
        "code": "1084",
        "name": "ELABORACIÓN DE ESPECIAS, SALSAS Y CONDIMENTOS"
      },
      {
        "code": "1085",
        "name": "ELABORACIÓN DE PLATOS Y COMIDAS PREPARADOS"
      },
      {
        "code": "1086",
        "name": "ELABORACIÓN DE PREPARADOS ALIMENTICIOS HOMOGENEIZADOS Y ALIMENTOS DIETÉTICOS"
      },
      {
        "code": "1089",
        "name": "ELABORACIÓN DE OTROS PRODUCTOS ALIMENTICIOS N.C.O.P."
      },
      {
        "code": "1091",
        "name": "FABRICACIÓN DE PRODUCTOS PARA LA ALIMENTACIÓN DE ANIMALES DE GRANJA"
      },
      {
        "code": "1092",
        "name": "FABRICACIÓN DE PRODUCTOS PARA LA ALIMENTACIÓN DE ANIMALES DE COMPAÑÍA"
      },
      {
        "code": "1101",
        "name": "DESTILACIÓN, RECTIFICACIÓN Y MEZCLA DE BEBIDAS ALCOHÓLICAS"
      },
      {
        "code": "1102",
        "name": "ELABORACIÓN DE VINOS"
      },
      {
        "code": "1103",
        "name": "ELABORACIÓN DE SIDRA Y OTRAS BEBIDAS FERMENTADAS A PARTIR DE FRUTAS"
      },
      {
        "code": "1104",
        "name": "ELABORACIÓN DE OTRAS BEBIDAS NO DESTILADAS, PROCEDENTES DE LA FERMENTACIÓN"
      },
      {
        "code": "1105",
        "name": "FABRICACIÓN DE CERVEZA"
      },
      {
        "code": "1106",
        "name": "FABRICACIÓN DE MALTA"
      },
      {
        "code": "1107",
        "name": "FABRICACIÓN DE BEBIDAS NO ALCOHÓLICAS; PRODUCCIÓN DE AGUAS MINERALES Y OTRAS AGUAS EMBOTELLADAS"
      },
      {
        "code": "1200",
        "name": "INDUSTRIA DEL TABACO"
      },
      {
        "code": "1310",
        "name": "PREPARACIÓN E HILADO DE FIBRAS TEXTILES"
      },
      {
        "code": "1320",
        "name": "FABRICACIÓN DE TEJIDOS TEXTILES"
      },
      {
        "code": "1330",
        "name": "ACABADO DE TEXTILES"
      },
      {
        "code": "1391",
        "name": "FABRICACIÓN DE TEJIDOS DE PUNTO"
      },
      {
        "code": "1392",
        "name": "FABRICACIÓN DE ARTÍCULOS CONFECCIONADOS CON TEXTILES, EXCEPTO PRENDAS DE VESTIR"
      },
      {
        "code": "1393",
        "name": "FABRICACIÓN DE ALFOMBRAS Y MOQUETAS"
      },
      {
        "code": "1394",
        "name": "FABRICACIÓN DE CUERDAS, CORDELES, BRAMANTES Y REDES"
      },
      {
        "code": "1395",
        "name": "FABRICACIÓN DE TELAS NO TEJIDAS Y ARTÍCULOS CONFECCIONADOS CON ELLAS, EXCEPTO PRENDAS DE VESTIR"
      },
      {
        "code": "1396",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS TEXTILES DE USO TÉCNICO E INDUSTRIAL"
      },
      {
        "code": "1399",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS TEXTILES N.C.O.P."
      },
      {
        "code": "1411",
        "name": "CONFECCIÓN DE PRENDAS DE VESTIR DE CUERO"
      },
      {
        "code": "1412",
        "name": "CONFECCIÓN DE ROPA DE TRABAJO"
      },
      {
        "code": "1413",
        "name": "CONFECCIÓN DE OTRAS PRENDAS DE VESTIR EXTERIORES"
      },
      {
        "code": "1414",
        "name": "CONFECCIÓN DE ROPA INTERIOR"
      },
      {
        "code": "1419",
        "name": "CONFECCIÓN DE OTRAS PRENDAS DE VESTIR Y ACCESORIOS"
      },
      {
        "code": "1420",
        "name": "FABRICACIÓN DE ARTÍCULOS DE PELETERÍA"
      },
      {
        "code": "1431",
        "name": "CONFECCIÓN DE CALCETERÍA"
      },
      {
        "code": "1439",
        "name": "CONFECCIÓN DE OTRAS PRENDAS DE VESTIR DE PUNTO"
      },
      {
        "code": "1511",
        "name": "PREPARACIÓN, CURTIDO Y ACABADO DEL CUERO; PREPARACIÓN Y TEÑIDO DE PIELES"
      },
      {
        "code": "1512",
        "name": "FABRICACIÓN DE ARTÍCULOS DE MARROQUINERÍA, VIAJE Y DE GUARNICIONERÍA Y TALABARTERÍA"
      },
      {
        "code": "1520",
        "name": "FABRICACIÓN DE CALZADO"
      },
      {
        "code": "1610",
        "name": "ASERRADO Y CEPILLADO DE LA MADERA"
      },
      {
        "code": "1621",
        "name": "FABRICACIÓN DE CHAPAS Y TABLEROS DE MADERA"
      },
      {
        "code": "1622",
        "name": "FABRICACIÓN DE SUELOS DE MADERA ENSAMBLADOS"
      },
      {
        "code": "1623",
        "name": "FABRICACIÓN DE OTRAS ESTRUCTURAS DE MADERA Y PIEZAS DE CARPINTERÍA Y EBANISTERÍA PARA LA CONSTRUCCIÓN"
      },
      {
        "code": "1624",
        "name": "FABRICACIÓN DE ENVASES Y EMBALAJES DE MADERA"
      },
      {
        "code": "1629",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS DE MADERA; ARTÍCULOS DE CORCHO, CESTERÍA Y ESPARTERÍA"
      },
      {
        "code": "1711",
        "name": "FABRICACIÓN DE PASTA PAPELERA"
      },
      {
        "code": "1712",
        "name": "FABRICACIÓN DE PAPEL Y CARTÓN"
      },
      {
        "code": "1721",
        "name": "FABRICACIÓN DE PAPEL Y CARTÓN ONDULADOS; FABRICACIÓN DE ENVASES Y EMBALAJES DE PAPEL Y CARTÓN"
      },
      {
        "code": "1722",
        "name": "FABRICACIÓN DE ARTÍCULOS DE PAPEL Y CARTÓN PARA USO DOMÉSTICO, SANITARIO E HIGIÉNICO"
      },
      {
        "code": "1723",
        "name": "FABRICACIÓN DE ARTÍCULOS DE PAPELERÍA"
      },
      {
        "code": "1724",
        "name": "FABRICACIÓN DE PAPELES PINTADOS"
      },
      {
        "code": "1729",
        "name": "FABRICACIÓN DE OTROS ARTÍCULOS DE PAPEL Y CARTÓN"
      },
      {
        "code": "1811",
        "name": "ARTES GRÁFICAS Y SERVICIOS RELACIONADOS CON LAS MISMAS"
      },
      {
        "code": "1812",
        "name": "OTRAS ACTIVIDADES DE IMPRESIÓN Y ARTES GRÁFICAS"
      },
      {
        "code": "1813",
        "name": "SERVICIOS DE PREIMPRESIÓN Y PREPARACIÓN DE SOPORTES"
      },
      {
        "code": "1814",
        "name": "ENCUADERNACIÓN Y SERVICIOS RELACIONADOS CON LA MISMA"
      },
      {
        "code": "1820",
        "name": "REPRODUCCIÓN DE SOPORTES GRABADOS"
      },
      {
        "code": "1910",
        "name": "COQUERÍAS"
      },
      {
        "code": "1920",
        "name": "REFINO DE PETRÓLEO"
      },
      {
        "code": "2011",
        "name": "FABRICACIÓN DE GASES INDUSTRIALES"
      },
      {
        "code": "2012",
        "name": "FABRICACIÓN DE COLORANTES Y PIGMENTOS"
      },
      {
        "code": "2013",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS BÁSICOS DE QUÍMICA INORGÁNICA"
      },
      {
        "code": "2014",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS BÁSICOS DE QUÍMICA ORGÁNICA"
      },
      {
        "code": "2015",
        "name": "FABRICACIÓN DE FERTILIZANTES Y COMPUESTOS NITROGENADOS"
      },
      {
        "code": "2016",
        "name": "FABRICACIÓN DE PLÁSTICOS EN FORMAS PRIMARIAS"
      },
      {
        "code": "2017",
        "name": "FABRICACIÓN DE CAUCHO SINTÉTICO EN FORMAS PRIMARIAS"
      },
      {
        "code": "2020",
        "name": "FABRICACIÓN DE PESTICIDAS Y OTROS PRODUCTOS AGROQUÍMICOS"
      },
      {
        "code": "2030",
        "name": "FABRICACIÓN DE PINTURAS, BARNICES Y REVESTIMIENTOS SIMILARES; TINTAS DE IMPRENTA Y MASILLAS"
      },
      {
        "code": "2041",
        "name": "FABRICACIÓN DE JABONES, DETERGENTES Y OTROS ARTÍCULOS DE LIMPIEZA Y ABRILLANTAMIENTO"
      },
      {
        "code": "2042",
        "name": "FABRICACIÓN DE PERFUMES Y COSMÉTICOS"
      },
      {
        "code": "2051",
        "name": "FABRICACIÓN DE EXPLOSIVOS"
      },
      {
        "code": "2052",
        "name": "FABRICACIÓN DE COLAS"
      },
      {
        "code": "2053",
        "name": "FABRICACIÓN DE ACEITES ESENCIALES"
      },
      {
        "code": "2059",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS QUÍMICOS N.C.O.P."
      },
      {
        "code": "2060",
        "name": "FABRICACIÓN DE FIBRAS ARTIFICIALES Y SINTÉTICAS"
      },
      {
        "code": "2110",
        "name": "FABRICACIÓN DE PRODUCTOS FARMACÉUTICOS DE BASE"
      },
      {
        "code": "2120",
        "name": "FABRICACIÓN DE ESPECIALIDADES FARMACÉUTICAS"
      },
      {
        "code": "2211",
        "name": "FABRICACIÓN DE NEUMÁTICOS Y CÁMARAS DE CAUCHO; RECONSTRUCCIÓN Y RECAUCHUTADO DE NEUMÁTICOS"
      },
      {
        "code": "2219",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS DE CAUCHO"
      },
      {
        "code": "2221",
        "name": "FABRICACIÓN DE PLACAS, HOJAS, TUBOS Y PERFILES DE PLÁSTICO"
      },
      {
        "code": "2222",
        "name": "FABRICACIÓN DE ENVASES Y EMBALAJES DE PLÁSTICO"
      },
      {
        "code": "2223",
        "name": "FABRICACIÓN DE PRODUCTOS DE PLÁSTICO PARA LA CONSTRUCCIÓN"
      },
      {
        "code": "2229",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS DE PLÁSTICO"
      },
      {
        "code": "2311",
        "name": "FABRICACIÓN DE VIDRIO PLANO"
      },
      {
        "code": "2312",
        "name": "MANIPULADO Y TRANSFORMACIÓN DE VIDRIO PLANO"
      },
      {
        "code": "2313",
        "name": "FABRICACIÓN DE VIDRIO HUECO"
      },
      {
        "code": "2314",
        "name": "FABRICACIÓN DE FIBRA DE VIDRIO"
      },
      {
        "code": "2319",
        "name": "FABRICACIÓN Y MANIPULADO DE OTRO VIDRIO, INCLUIDO EL VIDRIO TÉCNICO"
      },
      {
        "code": "2320",
        "name": "FABRICACIÓN DE PRODUCTOS CERÁMICOS REFRACTARIOS"
      },
      {
        "code": "2331",
        "name": "FABRICACIÓN DE AZULEJOS Y BALDOSAS DE CERÁMICA"
      },
      {
        "code": "2332",
        "name": "FABRICACIÓN DE LADRILLOS, TEJAS Y PRODUCTOS DE TIERRAS COCIDAS PARA LA CONSTRUCCIÓN"
      },
      {
        "code": "2341",
        "name": "FABRICACIÓN DE ARTÍCULOS CERÁMICOS DE USO DOMÉSTICO Y ORNAMENTAL"
      },
      {
        "code": "2342",
        "name": "FABRICACIÓN DE APARATOS SANITARIOS CERÁMICOS"
      },
      {
        "code": "2343",
        "name": "FABRICACIÓN DE AISLADORES Y PIEZAS AISLANTES DE MATERIAL CERÁMICO"
      },
      {
        "code": "2344",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS CERÁMICOS DE USO TÉCNICO"
      },
      {
        "code": "2349",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS CERÁMICOS"
      },
      {
        "code": "2351",
        "name": "FABRICACIÓN DE CEMENTO"
      },
      {
        "code": "2352",
        "name": "FABRICACIÓN DE CAL Y YESO"
      },
      {
        "code": "2361",
        "name": "FABRICACIÓN DE ELEMENTOS DE HORMIGÓN PARA LA CONSTRUCCIÓN"
      },
      {
        "code": "2362",
        "name": "FABRICACIÓN DE ELEMENTOS DE YESO PARA LA CONSTRUCCIÓN"
      },
      {
        "code": "2363",
        "name": "FABRICACIÓN DE HORMIGÓN FRESCO"
      },
      {
        "code": "2364",
        "name": "FABRICACIÓN DE MORTERO"
      },
      {
        "code": "2365",
        "name": "FABRICACIÓN DE FIBROCEMENTO"
      },
      {
        "code": "2369",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS DE HORMIGÓN, YESO Y CEMENTO"
      },
      {
        "code": "2370",
        "name": "CORTE, TALLADO Y ACABADO DE LA PIEDRA"
      },
      {
        "code": "2391",
        "name": "FABRICACIÓN DE PRODUCTOS ABRASIVOS"
      },
      {
        "code": "2399",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS MINERALES NO METÁLICOS N.C.O.P."
      },
      {
        "code": "2410",
        "name": "FABRICACIÓN DE PRODUCTOS BÁSICOS DE HIERRO, ACERO Y FERROALEACIONES"
      },
      {
        "code": "2420",
        "name": "FABRICACIÓN DE TUBOS, TUBERÍAS, PERFILES HUECOS Y SUS ACCESORIOS, DE ACERO"
      },
      {
        "code": "2431",
        "name": "ESTIRADO EN FRÍO"
      },
      {
        "code": "2432",
        "name": "LAMINACIÓN EN FRÍO"
      },
      {
        "code": "2433",
        "name": "PRODUCCIÓN DE PERFILES EN FRÍO POR CONFORMACIÓN CON PLEGADO"
      },
      {
        "code": "2434",
        "name": "TREFILADO EN FRÍO"
      },
      {
        "code": "2441",
        "name": "PRODUCCIÓN DE METALES PRECIOSOS"
      },
      {
        "code": "2442",
        "name": "PRODUCCIÓN DE ALUMINIO"
      },
      {
        "code": "2443",
        "name": "PRODUCCIÓN DE PLOMO, ZINC Y ESTAÑO"
      },
      {
        "code": "2444",
        "name": "PRODUCCIÓN DE COBRE"
      },
      {
        "code": "2445",
        "name": "PRODUCCIÓN DE OTROS METALES NO FÉRREOS"
      },
      {
        "code": "2446",
        "name": "PROCESAMIENTO DE COMBUSTIBLES NUCLEARES"
      },
      {
        "code": "2451",
        "name": "FUNDICIÓN DE HIERRO"
      },
      {
        "code": "2452",
        "name": "FUNDICIÓN DE ACERO"
      },
      {
        "code": "2453",
        "name": "FUNDICIÓN DE METALES LIGEROS"
      },
      {
        "code": "2454",
        "name": "FUNDICIÓN DE OTROS METALES NO FÉRREOS"
      },
      {
        "code": "2511",
        "name": "FABRICACIÓN DE ESTRUCTURAS METÁLICAS Y SUS COMPONENTES"
      },
      {
        "code": "2512",
        "name": "FABRICACIÓN DE CARPINTERÍA METÁLICA"
      },
      {
        "code": "2521",
        "name": "FABRICACIÓN DE RADIADORES Y CALDERAS PARA CALEFACCIÓN CENTRAL"
      },
      {
        "code": "2529",
        "name": "FABRICACIÓN DE OTRAS CISTERNAS, GRANDES DEPÓSITOS Y CONTENEDORES DE METAL"
      },
      {
        "code": "2530",
        "name": "FABRICACIÓN DE GENERADORES DE VAPOR, EXCEPTO CALDERAS DE CALEFACCIÓN CENTRAL"
      },
      {
        "code": "2540",
        "name": "FABRICACIÓN DE ARMAS Y MUNICIONES"
      },
      {
        "code": "2550",
        "name": "FORJA, ESTAMPACIÓN Y EMBUTICIÓN DE METALES; METALURGIA DE POLVOS"
      },
      {
        "code": "2561",
        "name": "TRATAMIENTO Y REVESTIMIENTO DE METALES"
      },
      {
        "code": "2562",
        "name": "INGENIERÍA MECÁNICA POR CUENTA DE TERCEROS"
      },
      {
        "code": "2571",
        "name": "FABRICACIÓN DE ARTÍCULOS DE CUCHILLERÍA Y CUBERTERÍA"
      },
      {
        "code": "2572",
        "name": "FABRICACIÓN DE CERRADURAS Y HERRAJES"
      },
      {
        "code": "2573",
        "name": "FABRICACIÓN DE HERRAMIENTAS"
      },
      {
        "code": "2591",
        "name": "FABRICACIÓN DE BIDONES Y TONELES DE HIERRO O ACERO"
      },
      {
        "code": "2592",
        "name": "FABRICACIÓN DE ENVASES Y EMBALAJES METÁLICOS LIGEROS"
      },
      {
        "code": "2593",
        "name": "FABRICACIÓN DE PRODUCTOS DE ALAMBRE, CADENAS Y MUELLES"
      },
      {
        "code": "2594",
        "name": "FABRICACIÓN DE PERNOS Y PRODUCTOS DE TORNILLERÍA"
      },
      {
        "code": "2599",
        "name": "FABRICACIÓN DE OTROS PRODUCTOS METÁLICOS N.C.O.P."
      },
      {
        "code": "2611",
        "name": "FABRICACIÓN DE COMPONENTES ELECTRÓNICOS"
      },
      {
        "code": "2612",
        "name": "FABRICACIÓN DE CIRCUITOS IMPRESOS ENSAMBLADOS"
      },
      {
        "code": "2620",
        "name": "FABRICACIÓN DE ORDENADORES Y EQUIPOS PERIFÉRICOS"
      },
      {
        "code": "2630",
        "name": "FABRICACIÓN DE EQUIPOS DE TELECOMUNICACIONES"
      },
      {
        "code": "2640",
        "name": "FABRICACIÓN DE PRODUCTOS ELECTRÓNICOS DE CONSUMO"
      },
      {
        "code": "2651",
        "name": "FABRICACIÓN DE INSTRUMENTOS Y APARATOS DE MEDIDA, VERIFICACIÓN Y NAVEGACIÓN"
      },
      {
        "code": "2652",
        "name": "FABRICACIÓN DE RELOJES"
      },
      {
        "code": "2660",
        "name": "FABRICACIÓN DE EQUIPOS DE RADIACIÓN, ELECTROMÉDICOS Y ELECTROTERAPÉUTICOS"
      },
      {
        "code": "2670",
        "name": "FABRICACIÓN DE INSTRUMENTOS DE ÓPTICA Y EQUIPO FOTOGRÁFICO"
      },
      {
        "code": "2680",
        "name": "FABRICACIÓN DE SOPORTES MAGNÉTICOS Y ÓPTICOS"
      },
      {
        "code": "2711",
        "name": "FABRICACIÓN DE MOTORES, GENERADORES Y TRANSFORMADORES ELÉCTRICOS"
      },
      {
        "code": "2712",
        "name": "FABRICACIÓN DE APARATOS DE DISTRIBUCIÓN Y CONTROL ELÉCTRICO"
      },
      {
        "code": "2720",
        "name": "FABRICACIÓN DE PILAS Y ACUMULADORES ELÉCTRICOS"
      },
      {
        "code": "2731",
        "name": "FABRICACIÓN DE CABLES DE FIBRA ÓPTICA"
      },
      {
        "code": "2732",
        "name": "FABRICACIÓN DE OTROS HILOS Y CABLES ELECTRÓNICOS Y ELÉCTRICOS"
      },
      {
        "code": "2733",
        "name": "FABRICACIÓN DE DISPOSITIVOS DE CABLEADO"
      },
      {
        "code": "2740",
        "name": "FABRICACIÓN DE LÁMPARAS Y APARATOS ELÉCTRICOS DE ILUMINACIÓN"
      },
      {
        "code": "2751",
        "name": "FABRICACIÓN DE ELECTRODOMÉSTICOS"
      },
      {
        "code": "2752",
        "name": "FABRICACIÓN DE APARATOS DOMÉSTICOS NO ELÉCTRICOS"
      },
      {
        "code": "2790",
        "name": "FABRICACIÓN DE OTRO MATERIAL Y EQUIPO ELÉCTRICO"
      },
      {
        "code": "2811",
        "name": "FABRICACIÓN DE MOTORES Y TURBINAS, EXCEPTO LOS DESTINADOS A AERONAVES, VEHÍCULOS AUTOMÓVILES Y CICLOMOTORES"
      },
      {
        "code": "2812",
        "name": "FABRICACIÓN DE EQUIPOS DE TRANSMISIÓN HIDRÁULICA Y NEUMÁTICA"
      },
      {
        "code": "2813",
        "name": "FABRICACIÓN DE OTRAS BOMBAS Y COMPRESORES"
      },
      {
        "code": "2814",
        "name": "FABRICACIÓN DE OTRA GRIFERÍA Y VÁLVULAS"
      },
      {
        "code": "2815",
        "name": "FABRICACIÓN DE COJINETES, ENGRANAJES Y ÓRGANOS MECÁNICOS DE TRANSMISIÓN"
      },
      {
        "code": "2821",
        "name": "FABRICACIÓN DE HORNOS Y QUEMADORES"
      },
      {
        "code": "2822",
        "name": "FABRICACIÓN DE MAQUINARIA DE ELEVACIÓN Y MANIPULACIÓN"
      },
      {
        "code": "2823",
        "name": "FABRICACIÓN DE MÁQUINAS Y EQUIPOS DE OFICINA, EXCEPTO EQUIPOS INFORMÁTICOS"
      },
      {
        "code": "2824",
        "name": "FABRICACIÓN DE HERRAMIENTAS ELÉCTRICAS MANUALES"
      },
      {
        "code": "2825",
        "name": "FABRICACIÓN DE MAQUINARIA DE VENTILACIÓN Y REFRIGERACIÓN NO DOMÉSTICA"
      },
      {
        "code": "2829",
        "name": "FABRICACIÓN DE OTRA MAQUINARIA DE USO GENERAL N.C.O.P."
      },
      {
        "code": "2830",
        "name": "FABRICACIÓN DE MAQUINARIA AGRARIA Y FORESTAL"
      },
      {
        "code": "2841",
        "name": "FABRICACIÓN DE MÁQUINAS HERRAMIENTA PARA TRABAJAR EL METAL"
      },
      {
        "code": "2849",
        "name": "FABRICACIÓN DE OTRAS MÁQUINAS HERRAMIENTA"
      },
      {
        "code": "2891",
        "name": "FABRICACIÓN DE MAQUINARIA PARA LA INDUSTRIA METALÚRGICA"
      },
      {
        "code": "2892",
        "name": "FABRICACIÓN DE MAQUINARIA PARA LAS INDUSTRIAS EXTRACTIVAS Y DE LA CONSTRUCCIÓN"
      },
      {
        "code": "2893",
        "name": "FABRICACIÓN DE MAQUINARIA PARA LA INDUSTRIA DE LA ALIMENTACIÓN, BEBIDAS Y TABACO"
      },
      {
        "code": "2894",
        "name": "FABRICACIÓN DE MAQUINARIA PARA LAS INDUSTRIAS TEXTIL, DE LA CONFECCIÓN Y DEL CUERO"
      },
      {
        "code": "2895",
        "name": "FABRICACIÓN DE MAQUINARIA PARA LA INDUSTRIA DEL PAPEL Y DEL CARTÓN"
      },
      {
        "code": "2896",
        "name": "FABRICACIÓN DE MAQUINARIA PARA LA INDUSTRIA DEL PLÁSTICO Y EL CAUCHO"
      },
      {
        "code": "2899",
        "name": "FABRICACIÓN DE OTRA MAQUINARIA PARA USOS ESPECÍFICOS N.C.O.P."
      },
      {
        "code": "2910",
        "name": "FABRICACIÓN DE VEHÍCULOS DE MOTOR"
      },
      {
        "code": "2920",
        "name": "FABRICACIÓN DE CARROCERÍAS PARA VEHÍCULOS DE MOTOR; FABRICACIÓN DE REMOLQUES Y SEMIRREMOLQUES"
      },
      {
        "code": "2931",
        "name": "FABRICACIÓN DE EQUIPOS ELÉCTRICOS Y ELECTRÓNICOS PARA VEHÍCULOS DE MOTOR"
      },
      {
        "code": "2932",
        "name": "FABRICACIÓN DE OTROS COMPONENTES, PIEZAS Y ACCESORIOS PARA VEHÍCULOS DE MOTOR"
      },
      {
        "code": "3011",
        "name": "CONSTRUCCIÓN DE BARCOS Y ESTRUCTURAS FLOTANTES"
      },
      {
        "code": "3012",
        "name": "CONSTRUCCIÓN DE EMBARCACIONES DE RECREO Y DEPORTE"
      },
      {
        "code": "3020",
        "name": "FABRICACIÓN DE LOCOMOTORAS Y MATERIAL FERROVIARIO"
      },
      {
        "code": "3030",
        "name": "CONSTRUCCIÓN AERONÁUTICA Y ESPACIAL Y SU MAQUINARIA"
      },
      {
        "code": "3040",
        "name": "FABRICACIÓN DE VEHÍCULOS MILITARES DE COMBATE"
      },
      {
        "code": "3091",
        "name": "FABRICACIÓN DE MOTOCICLETAS"
      },
      {
        "code": "3092",
        "name": "FABRICACIÓN DE BICICLETAS Y DE VEHÍCULOS PARA PERSONAS CON DISCAPACIDAD"
      },
      {
        "code": "3099",
        "name": "FABRICACIÓN DE OTRO MATERIAL DE TRANSPORTE N.C.O.P."
      },
      {
        "code": "3101",
        "name": "FABRICACIÓN DE MUEBLES DE OFICINA Y DE ESTABLECIMIENTOS COMERCIALES"
      },
      {
        "code": "3102",
        "name": "FABRICACIÓN DE MUEBLES DE COCINA"
      },
      {
        "code": "3103",
        "name": "FABRICACIÓN DE COLCHONES"
      },
      {
        "code": "3109",
        "name": "FABRICACIÓN DE OTROS MUEBLES"
      },
      {
        "code": "3211",
        "name": "FABRICACIÓN DE MONEDAS"
      },
      {
        "code": "3212",
        "name": "FABRICACIÓN DE ARTÍCULOS DE JOYERÍA Y ARTÍCULOS SIMILARES"
      },
      {
        "code": "3213",
        "name": "FABRICACIÓN DE ARTÍCULOS DE BISUTERÍA Y ARTÍCULOS SIMILARES"
      },
      {
        "code": "3220",
        "name": "FABRICACIÓN DE INSTRUMENTOS MUSICALES"
      },
      {
        "code": "3230",
        "name": "FABRICACIÓN DE ARTÍCULOS DE DEPORTE"
      },
      {
        "code": "3240",
        "name": "FABRICACIÓN DE JUEGOS Y JUGUETES"
      },
      {
        "code": "3250",
        "name": "FABRICACIÓN DE INSTRUMENTOS Y SUMINISTROS MÉDICOS Y ODONTOLÓGICOS"
      },
      {
        "code": "3291",
        "name": "FABRICACIÓN DE ESCOBAS, BROCHAS Y CEPILLOS"
      },
      {
        "code": "3299",
        "name": "OTRAS INDUSTRIAS MANUFACTURERAS N.C.O.P."
      },
      {
        "code": "3311",
        "name": "REPARACIÓN DE PRODUCTOS METÁLICOS"
      },
      {
        "code": "3312",
        "name": "REPARACIÓN DE MAQUINARIA"
      },
      {
        "code": "3313",
        "name": "REPARACIÓN DE EQUIPOS ELECTRÓNICOS Y ÓPTICOS"
      },
      {
        "code": "3314",
        "name": "REPARACIÓN DE EQUIPOS ELÉCTRICOS"
      },
      {
        "code": "3315",
        "name": "REPARACIÓN Y MANTENIMIENTO NAVAL"
      },
      {
        "code": "3316",
        "name": "REPARACIÓN Y MANTENIMIENTO AERONÁUTICO Y ESPACIAL"
      },
      {
        "code": "3317",
        "name": "REPARACIÓN Y MANTENIMIENTO DE OTRO MATERIAL DE TRANSPORTE"
      },
      {
        "code": "3319",
        "name": "REPARACIÓN DE OTROS EQUIPOS"
      },
      {
        "code": "3320",
        "name": "INSTALACIÓN DE MÁQUINAS Y EQUIPOS INDUSTRIALES"
      },
      {
        "code": "3512",
        "name": "TRANSPORTE DE ENERGÍA ELÉCTRICA"
      },
      {
        "code": "3513",
        "name": "DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA"
      },
      {
        "code": "3514",
        "name": "COMERCIO DE ENERGÍA ELÉCTRICA"
      },
      {
        "code": "3515",
        "name": "PRODUCCIÓN DE ENERGÍA HIDROELÉCTRICA"
      },
      {
        "code": "3516",
        "name": "PRODUCCIÓN DE ENERGÍA ELÉCTRICA DE ORIGEN TÉRMICO CONVENCIONAL"
      },
      {
        "code": "3517",
        "name": "PRODUCCIÓN DE ENERGÍA ELÉCTRICA DE ORIGEN NUCLEAR"
      },
      {
        "code": "3518",
        "name": "PRODUCCIÓN DE ENERGÍA ELÉCTRICA DE ORIGEN EÓLICO"
      },
      {
        "code": "3519",
        "name": "PRODUCCIÓN DE ENERGÍA ELÉCTRICA DE OTROS TIPOS"
      },
      {
        "code": "3521",
        "name": "PRODUCCIÓN DE GAS"
      },
      {
        "code": "3522",
        "name": "DISTRIBUCIÓN POR TUBERÍA DE COMBUSTIBLES GASEOSOS"
      },
      {
        "code": "3523",
        "name": "COMERCIO DE GAS POR TUBERÍA"
      },
      {
        "code": "3530",
        "name": "SUMINISTRO DE VAPOR Y AIRE ACONDICIONADO"
      },
      {
        "code": "3600",
        "name": "CAPTACIÓN, DEPURACIÓN Y DISTRIBUCIÓN DE AGUA"
      },
      {
        "code": "3700",
        "name": "RECOGIDA Y TRATAMIENTO DE AGUAS RESIDUALES"
      },
      {
        "code": "3811",
        "name": "RECOGIDA DE RESIDUOS NO PELIGROSOS"
      },
      {
        "code": "3812",
        "name": "RECOGIDA DE RESIDUOS PELIGROSOS"
      },
      {
        "code": "3821",
        "name": "TRATAMIENTO Y ELIMINACIÓN DE RESIDUOS NO PELIGROSOS"
      },
      {
        "code": "3822",
        "name": "TRATAMIENTO Y ELIMINACIÓN DE RESIDUOS PELIGROSOS"
      },
      {
        "code": "3831",
        "name": "SEPARACIÓN Y CLASIFICACIÓN DE MATERIALES"
      },
      {
        "code": "3832",
        "name": "VALORIZACIÓN DE MATERIALES YA CLASIFICADOS"
      },
      {
        "code": "3900",
        "name": "ACTIVIDADES DE DESCONTAMINACIÓN Y OTROS SERVICIOS DE GESTIÓN DE RESIDUOS"
      },
      {
        "code": "4110",
        "name": "PROMOCIÓN INMOBILIARIA"
      },
      {
        "code": "4121",
        "name": "CONSTRUCCIÓN DE EDIFICIOS RESIDENCIALES"
      },
      {
        "code": "4122",
        "name": "CONSTRUCCIÓN DE EDIFICIOS NO RESIDENCIALES"
      },
      {
        "code": "4211",
        "name": "CONSTRUCCIÓN DE CARRETERAS Y AUTOPISTAS"
      },
      {
        "code": "4212",
        "name": "CONSTRUCCIÓN DE VÍAS FÉRREAS DE SUPERFICIE Y SUBTERRÁNEAS"
      },
      {
        "code": "4213",
        "name": "CONSTRUCCIÓN DE PUENTES Y TÚNELES"
      },
      {
        "code": "4221",
        "name": "CONSTRUCCIÓN DE REDES PARA FLUIDOS"
      },
      {
        "code": "4222",
        "name": "CONSTRUCCIÓN DE REDES ELÉCTRICAS Y DE TELECOMUNICACIONES"
      },
      {
        "code": "4291",
        "name": "OBRAS HIDRÁULICAS"
      },
      {
        "code": "4299",
        "name": "CONSTRUCCIÓN DE OTROS PROYECTOS DE INGENIERÍA CIVIL N.C.O.P."
      },
      {
        "code": "4311",
        "name": "DEMOLICIÓN"
      },
      {
        "code": "4312",
        "name": "PREPARACIÓN DE TERRENOS"
      },
      {
        "code": "4313",
        "name": "PERFORACIONES Y SONDEOS"
      },
      {
        "code": "4321",
        "name": "INSTALACIONES ELÉCTRICAS"
      },
      {
        "code": "4322",
        "name": "FONTANERÍA, INSTALACIONES DE SISTEMAS DE CALEFACCIÓN Y AIRE ACONDICIONADO"
      },
      {
        "code": "4329",
        "name": "OTRAS INSTALACIONES EN OBRAS DE CONSTRUCCIÓN"
      },
      {
        "code": "4331",
        "name": "REVOCAMIENTO"
      },
      {
        "code": "4332",
        "name": "INSTALACIÓN DE CARPINTERÍA"
      },
      {
        "code": "4333",
        "name": "REVESTIMIENTO DE SUELOS Y PAREDES"
      },
      {
        "code": "4334",
        "name": "PINTURA Y ACRISTALAMIENTO"
      },
      {
        "code": "4339",
        "name": "OTRO ACABADO DE EDIFICIOS"
      },
      {
        "code": "4391",
        "name": "CONSTRUCCIÓN DE CUBIERTAS"
      },
      {
        "code": "4399",
        "name": "OTRAS ACTIVIDADES DE CONSTRUCCIÓN ESPECIALIZADA N.C.O.P."
      },
      {
        "code": "4511",
        "name": "VENTA DE AUTOMÓVILES Y VEHÍCULOS DE MOTOR LIGEROS"
      },
      {
        "code": "4519",
        "name": "VENTA DE OTROS VEHÍCULOS DE MOTOR"
      },
      {
        "code": "4520",
        "name": "MANTENIMIENTO Y REPARACIÓN DE VEHÍCULOS DE MOTOR"
      },
      {
        "code": "4531",
        "name": "COMERCIO AL POR MAYOR DE REPUESTOS Y ACCESORIOS DE VEHÍCULOS DE MOTOR"
      },
      {
        "code": "4532",
        "name": "COMERCIO AL POR MENOR DE REPUESTOS Y ACCESORIOS DE VEHÍCULOS DE MOTOR"
      },
      {
        "code": "4540",
        "name": "VENTA, MANTENIMIENTO Y REPARACIÓN DE MOTOCICLETAS Y DE SUS REPUESTOS Y ACCESORIOS"
      },
      {
        "code": "4611",
        "name": "INTERMEDIARIOS DEL COMERCIO DE MATERIAS PRIMAS AGRARIAS, ANIMALES VIVOS, MATERIAS PRIMAS TEXTILES Y PRODUCTOS SEMIELABORADOS"
      },
      {
        "code": "4612",
        "name": "INTERMEDIARIOS DEL COMERCIO DE COMBUSTIBLES, MINERALES, METALES Y PRODUCTOS QUÍMICOS INDUSTRIALES"
      },
      {
        "code": "4613",
        "name": "INTERMEDIARIOS DEL COMERCIO DE LA MADERA Y MATERIALES DE CONSTRUCCIÓN"
      },
      {
        "code": "4614",
        "name": "INTERMEDIARIOS DEL COMERCIO DE MAQUINARIA, EQUIPO INDUSTRIAL, EMBARCACIONES Y AERONAVES"
      },
      {
        "code": "4615",
        "name": "INTERMEDIARIOS DEL COMERCIO DE MUEBLES, ARTÍCULOS PARA EL HOGAR Y FERRETERÍA"
      },
      {
        "code": "4616",
        "name": "INTERMEDIARIOS DEL COMERCIO DE TEXTILES, PRENDAS DE VESTIR, PELETERÍA, CALZADO Y ARTÍCULOS DE CUERO"
      },
      {
        "code": "4617",
        "name": "INTERMEDIARIOS DEL COMERCIO DE PRODUCTOS ALIMENTICIOS, BEBIDAS Y TABACO"
      },
      {
        "code": "4618",
        "name": "INTERMEDIARIOS DEL COMERCIO ESPECIALIZADOS EN LA VENTA DE OTROS PRODUCTOS ESPECÍFICOS"
      },
      {
        "code": "4619",
        "name": "INTERMEDIARIOS DEL COMERCIO DE PRODUCTOS DIVERSOS"
      },
      {
        "code": "4621",
        "name": "COMERCIO AL POR MAYOR DE CEREALES, TABACO EN RAMA, SIMIENTES Y ALIMENTOS PARA ANIMALES"
      },
      {
        "code": "4622",
        "name": "COMERCIO AL POR MAYOR DE FLORES Y PLANTAS"
      },
      {
        "code": "4623",
        "name": "COMERCIO AL POR MAYOR DE ANIMALES VIVOS"
      },
      {
        "code": "4624",
        "name": "COMERCIO AL POR MAYOR DE CUEROS Y PIELES"
      },
      {
        "code": "4631",
        "name": "COMERCIO AL POR MAYOR DE FRUTAS Y HORTALIZAS"
      },
      {
        "code": "4632",
        "name": "COMERCIO AL POR MAYOR DE CARNE Y PRODUCTOS CÁRNICOS"
      },
      {
        "code": "4633",
        "name": "COMERCIO AL POR MAYOR DE PRODUCTOS LÁCTEOS, HUEVOS, ACEITES Y GRASAS COMESTIBLES"
      },
      {
        "code": "4634",
        "name": "COMERCIO AL POR MAYOR DE BEBIDAS"
      },
      {
        "code": "4635",
        "name": "COMERCIO AL POR MAYOR DE PRODUCTOS DEL TABACO"
      },
      {
        "code": "4636",
        "name": "COMERCIO AL POR MAYOR DE AZÚCAR, CHOCOLATE Y CONFITERÍA"
      },
      {
        "code": "4637",
        "name": "COMERCIO AL POR MAYOR DE CAFÉ, TÉ, CACAO Y ESPECIAS"
      },
      {
        "code": "4638",
        "name": "COMERCIO AL POR MAYOR DE PESCADOS Y MARISCOS Y OTROS PRODUCTOS ALIMENTICIOS"
      },
      {
        "code": "4639",
        "name": "COMERCIO AL POR MAYOR, NO ESPECIALIZADO, DE PRODUCTOS ALIMENTICIOS, BEBIDAS Y TABACO"
      },
      {
        "code": "4641",
        "name": "COMERCIO AL POR MAYOR DE TEXTILES"
      },
      {
        "code": "4642",
        "name": "COMERCIO AL POR MAYOR DE PRENDAS DE VESTIR Y CALZADO"
      },
      {
        "code": "4643",
        "name": "COMERCIO AL POR MAYOR DE APARATOS ELECTRODOMÉSTICOS"
      },
      {
        "code": "4644",
        "name": "COMERCIO AL POR MAYOR DE PORCELANA, CRISTALERÍA Y ARTÍCULOS DE LIMPIEZA"
      },
      {
        "code": "4645",
        "name": "COMERCIO AL POR MAYOR DE PRODUCTOS PERFUMERÍA Y COSMÉTICA"
      },
      {
        "code": "4646",
        "name": "COMERCIO AL POR MAYOR DE PRODUCTOS FARMACÉUTICOS"
      },
      {
        "code": "4647",
        "name": "COMERCIO AL POR MAYOR DE MUEBLES, ALFOMBRAS Y APARATOS DE ILUMINACIÓN"
      },
      {
        "code": "4648",
        "name": "COMERCIO AL POR MAYOR DE ARTÍCULOS DE RELOJERÍA Y JOYERÍA"
      },
      {
        "code": "4649",
        "name": "COMERCIO AL POR MAYOR DE OTROS ARTÍCULOS DE USO DOMÉSTICO"
      },
      {
        "code": "4651",
        "name": "COMERCIO AL POR MAYOR DE ORDENADORES, EQUIPOS PERIFÉRICOS Y PROGRAMAS INFORMÁTICOS"
      },
      {
        "code": "4652",
        "name": "COMERCIO AL POR MAYOR DE EQUIPOS ELECTRÓNICOS Y DE TELECOMUNICACIONES Y SUS COMPONENTES"
      },
      {
        "code": "4661",
        "name": "COMERCIO AL POR MAYOR DE MAQUINARIA, EQUIPOS Y SUMINISTROS AGRÍCOLAS"
      },
      {
        "code": "4662",
        "name": "COMERCIO AL POR MAYOR DE MÁQUINAS HERRAMIENTA"
      },
      {
        "code": "4663",
        "name": "COMERCIO AL POR MAYOR DE MAQUINARIA PARA LA MINERÍA, LA CONSTRUCCIÓN Y LA INGENIERÍA CIVIL"
      },
      {
        "code": "4664",
        "name": "COMERCIO AL POR MAYOR DE MAQUINARIA PARA LA INDUSTRIA TEXTIL Y DE MÁQUINAS DE COSER Y TRICOTAR"
      },
      {
        "code": "4665",
        "name": "COMERCIO AL POR MAYOR DE MUEBLES DE OFICINA"
      },
      {
        "code": "4666",
        "name": "COMERCIO AL POR MAYOR DE OTRA MAQUINARIA Y EQUIPO DE OFICINA"
      },
      {
        "code": "4669",
        "name": "COMERCIO AL POR MAYOR DE OTRA MAQUINARIA Y EQUIPO"
      },
      {
        "code": "4671",
        "name": "COMERCIO AL POR MAYOR DE COMBUSTIBLES SÓLIDOS, LÍQUIDOS Y GASEOSOS, Y PRODUCTOS SIMILARES"
      },
      {
        "code": "4672",
        "name": "COMERCIO AL POR MAYOR DE METALES Y MINERALES METÁLICOS"
      },
      {
        "code": "4673",
        "name": "COMERCIO AL POR MAYOR DE MADERA, MATERIALES DE CONSTRUCCIÓN Y APARATOS SANITARIOS"
      },
      {
        "code": "4674",
        "name": "COMERCIO AL POR MAYOR DE FERRETERÍA, FONTANERÍA Y CALEFACCIÓN"
      },
      {
        "code": "4675",
        "name": "COMERCIO AL POR MAYOR DE PRODUCTOS QUÍMICOS"
      },
      {
        "code": "4676",
        "name": "COMERCIO AL POR MAYOR DE OTROS PRODUCTOS SEMIELABORADOS"
      },
      {
        "code": "4677",
        "name": "COMERCIO AL POR MAYOR DE CHATARRA Y PRODUCTOS DE DESECHO"
      },
      {
        "code": "4690",
        "name": "COMERCIO AL POR MAYOR NO ESPECIALIZADO"
      },
      {
        "code": "4711",
        "name": "COMERCIO AL POR MENOR EN ESTABLECIMIENTOS NO ESPECIALIZADOS, CON PREDOMINIO EN PRODUCTOS ALIMENTICIOS, BEBIDAS Y TABACO"
      },
      {
        "code": "4719",
        "name": "OTRO COMERCIO AL POR MENOR EN ESTABLECIMIENTOS NO ESPECIALIZADOS"
      },
      {
        "code": "4721",
        "name": "COMERCIO AL POR MENOR DE FRUTAS Y HORTALIZAS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4722",
        "name": "COMERCIO AL POR MENOR DE CARNE Y PRODUCTOS CÁRNICOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4723",
        "name": "COMERCIO AL POR MENOR DE PESCADOS Y MARISCOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4724",
        "name": "COMERCIO AL POR MENOR DE PAN Y PRODUCTOS DE PANADERÍA, CONFITERÍA Y PASTELERÍA EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4725",
        "name": "COMERCIO AL POR MENOR DE BEBIDAS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4726",
        "name": "COMERCIO AL POR MENOR DE PRODUCTOS DE TABACO EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4729",
        "name": "OTRO COMERCIO AL POR MENOR DE PRODUCTOS ALIMENTICIOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4730",
        "name": "COMERCIO AL POR MENOR DE COMBUSTIBLE PARA LA AUTOMOCIÓN EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4741",
        "name": "COMERCIO AL POR MENOR DE ORDENADORES, EQUIPOS PERIFÉRICOS Y PROGRAMAS INFORMÁTICOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4742",
        "name": "COMERCIO AL POR MENOR DE EQUIPOS DE TELECOMUNICACIONES EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4743",
        "name": "COMERCIO AL POR MENOR DE EQUIPOS DE AUDIO Y VÍDEO EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4751",
        "name": "COMERCIO AL POR MENOR DE TEXTILES EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4752",
        "name": "COMERCIO AL POR MENOR DE FERRETERÍA, PINTURA Y VIDRIO EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4753",
        "name": "COMERCIO AL POR MENOR DE ALFOMBRAS, MOQUETAS Y REVESTIMIENTOS DE PAREDES Y SUELOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4754",
        "name": "COMERCIO AL POR MENOR DE APARATOS ELECTRODOMÉSTICOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4759",
        "name": "COMERCIO AL POR MENOR DE MUEBLES, APARATOS DE ILUMINACIÓN Y OTROS ARTÍCULOS DE USO DOMÉSTICO EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4761",
        "name": "COMERCIO AL POR MENOR DE LIBROS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4762",
        "name": "COMERCIO AL POR MENOR DE PERIÓDICOS Y ARTÍCULOS DE PAPELERÍA EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4763",
        "name": "COMERCIO AL POR MENOR DE GRABACIONES DE MÚSICA Y VÍDEO EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4764",
        "name": "COMERCIO AL POR MENOR DE ARTÍCULOS DEPORTIVOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4765",
        "name": "COMERCIO AL POR MENOR DE JUEGOS Y JUGUETES EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4771",
        "name": "COMERCIO AL POR MENOR DE PRENDAS DE VESTIR EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4772",
        "name": "COMERCIO AL POR MENOR DE CALZADO Y ARTÍCULOS DE CUERO EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4773",
        "name": "COMERCIO AL POR MENOR DE PRODUCTOS FARMACÉUTICOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4774",
        "name": "COMERCIO AL POR MENOR DE ARTÍCULOS MÉDICOS Y ORTOPÉDICOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4775",
        "name": "COMERCIO AL POR MENOR DE PRODUCTOS COSMÉTICOS E HIGIÉNICOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4776",
        "name": "COMERCIO AL POR MENOR DE FLORES, PLANTAS, SEMILLAS, FERTILIZANTES, ANIMALES DE COMPAÑÍA Y ALIMENTOS PARA LOS MISMOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4777",
        "name": "COMERCIO AL POR MENOR DE ARTÍCULOS DE RELOJERÍA Y JOYERÍA EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4778",
        "name": "OTRO COMERCIO AL POR MENOR DE ARTÍCULOS NUEVOS EN ESTABLECIMIENTOS ESPECIALIZADOS"
      },
      {
        "code": "4779",
        "name": "COMERCIO AL POR MENOR DE ARTÍCULOS DE SEGUNDA MANO EN ESTABLECIMIENTOS"
      },
      {
        "code": "4781",
        "name": "COMERCIO AL POR MENOR DE PRODUCTOS ALIMENTICIOS, BEBIDAS Y TABACO EN PUESTOS DE VENTA Y EN MERCADILLOS"
      },
      {
        "code": "4782",
        "name": "COMERCIO AL POR MENOR DE PRODUCTOS TEXTILES, PRENDAS DE VESTIR Y CALZADO EN PUESTOS DE VENTA Y EN MERCADILLOS"
      },
      {
        "code": "4789",
        "name": "COMERCIO AL POR MENOR DE OTROS PRODUCTOS EN PUESTOS DE VENTA Y EN MERCADILLOS"
      },
      {
        "code": "4791",
        "name": "COMERCIO AL POR MENOR POR CORRESPONDENCIA O INTERNET"
      },
      {
        "code": "4799",
        "name": "OTRO COMERCIO AL POR MENOR NO REALIZADO NI EN ESTABLECIMIENTOS, NI EN PUESTOS DE VENTA NI EN MERCADILLOS"
      },
      {
        "code": "4910",
        "name": "TRANSPORTE INTERURBANO DE PASAJEROS POR FERROCARRIL"
      },
      {
        "code": "4920",
        "name": "TRANSPORTE DE MERCANCÍAS POR FERROCARRIL"
      },
      {
        "code": "4931",
        "name": "TRANSPORTE TERRESTRE URBANO Y SUBURBANO DE PASAJEROS"
      },
      {
        "code": "4932",
        "name": "TRANSPORTE POR TAXI"
      },
      {
        "code": "4939",
        "name": "TIPOS DE TRANSPORTE TERRESTRE DE PASAJEROS N.C.O.P."
      },
      {
        "code": "4941",
        "name": "TRANSPORTE DE MERCANCÍAS POR CARRETERA"
      },
      {
        "code": "4942",
        "name": "SERVICIOS DE MUDANZA"
      },
      {
        "code": "4950",
        "name": "TRANSPORTE POR TUBERÍA"
      },
      {
        "code": "5010",
        "name": "TRANSPORTE MARÍTIMO DE PASAJEROS"
      },
      {
        "code": "5020",
        "name": "TRANSPORTE MARÍTIMO DE MERCANCÍAS"
      },
      {
        "code": "5030",
        "name": "TRANSPORTE DE PASAJEROS POR VÍAS NAVEGABLES INTERIORES"
      },
      {
        "code": "5040",
        "name": "TRANSPORTE DE MERCANCÍAS POR VÍAS NAVEGABLES INTERIORES"
      },
      {
        "code": "5110",
        "name": "TRANSPORTE AÉREO DE PASAJEROS"
      },
      {
        "code": "5121",
        "name": "TRANSPORTE AÉREO DE MERCANCÍAS"
      },
      {
        "code": "5122",
        "name": "TRANSPORTE ESPACIAL"
      },
      {
        "code": "5210",
        "name": "DEPÓSITO Y ALMACENAMIENTO"
      },
      {
        "code": "5221",
        "name": "ACTIVIDADES ANEXAS AL TRANSPORTE TERRESTRE"
      },
      {
        "code": "5222",
        "name": "ACTIVIDADES ANEXAS AL TRANSPORTE MARÍTIMO Y POR VÍAS NAVEGABLES INTERIORES"
      },
      {
        "code": "5223",
        "name": "ACTIVIDADES ANEXAS AL TRANSPORTE AÉREO"
      },
      {
        "code": "5224",
        "name": "MANIPULACIÓN DE MERCANCÍAS"
      },
      {
        "code": "5229",
        "name": "OTRAS ACTIVIDADES ANEXAS AL TRANSPORTE"
      },
      {
        "code": "5310",
        "name": "ACTIVIDADES POSTALES SOMETIDAS A LA OBLIGACIÓN DEL SERVICIO UNIVERSAL"
      },
      {
        "code": "5320",
        "name": "OTRAS ACTIVIDADES POSTALES Y DE CORREOS"
      },
      {
        "code": "5510",
        "name": "HOTELES Y ALOJAMIENTOS SIMILARES"
      },
      {
        "code": "5520",
        "name": "ALOJAMIENTOS TURÍSTICOS Y OTROS ALOJAMIENTOS DE CORTA ESTANCIA"
      },
      {
        "code": "5530",
        "name": "CAMPINGS Y APARCAMIENTOS PARA CARAVANAS"
      },
      {
        "code": "5590",
        "name": "OTROS ALOJAMIENTOS"
      },
      {
        "code": "5610",
        "name": "RESTAURANTES Y PUESTOS DE COMIDAS"
      },
      {
        "code": "5621",
        "name": "PROVISIÓN DE COMIDAS PREPARADAS PARA EVENTOS"
      },
      {
        "code": "5629",
        "name": "OTROS SERVICIOS DE COMIDAS"
      },
      {
        "code": "5630",
        "name": "ESTABLECIMIENTOS DE BEBIDAS"
      },
      {
        "code": "5811",
        "name": "EDICIÓN DE LIBROS"
      },
      {
        "code": "5812",
        "name": "EDICIÓN DE DIRECTORIOS Y GUÍAS DE DIRECCIONES POSTALES"
      },
      {
        "code": "5813",
        "name": "EDICIÓN DE PERIÓDICOS"
      },
      {
        "code": "5814",
        "name": "EDICIÓN DE REVISTAS"
      },
      {
        "code": "5819",
        "name": "OTRAS ACTIVIDADES EDITORIALES"
      },
      {
        "code": "5821",
        "name": "EDICIÓN DE VIDEOJUEGOS"
      },
      {
        "code": "5829",
        "name": "EDICIÓN DE OTROS PROGRAMAS INFORMÁTICOS"
      },
      {
        "code": "5912",
        "name": "ACTIVIDADES DE POSTPRODUCCIÓN CINEMATOGRÁFICA, DE VÍDEO Y DE PROGRAMAS DE TELEVISIÓN"
      },
      {
        "code": "5914",
        "name": "ACTIVIDADES DE EXHIBICIÓN CINEMATOGRÁFICA"
      },
      {
        "code": "5915",
        "name": "ACTIVIDADES DE PRODUCCIÓN CINEMATOGRÁFICA Y DE VÍDEO"
      },
      {
        "code": "5916",
        "name": "ACTIVIDADES DE PRODUCCIONES DE PROGRAMAS DE TELEVISIÓN"
      },
      {
        "code": "5917",
        "name": "ACTIVIDADES DE DISTRIBUCIÓN CINEMATOGRÁFICA Y DE VÍDEO"
      },
      {
        "code": "5918",
        "name": "ACTIVIDADES DE DISTRIBUCIÓN DE PROGRAMAS DE TELEVISIÓN"
      },
      {
        "code": "5920",
        "name": "ACTIVIDADES DE GRABACIÓN DE SONIDO Y EDICIÓN MUSICAL"
      },
      {
        "code": "6010",
        "name": "ACTIVIDADES DE RADIODIFUSIÓN"
      },
      {
        "code": "6020",
        "name": "ACTIVIDADES DE PROGRAMACIÓN Y EMISIÓN DE TELEVISIÓN"
      },
      {
        "code": "6110",
        "name": "TELECOMUNICACIONES POR CABLE"
      },
      {
        "code": "6120",
        "name": "TELECOMUNICACIONES INALÁMBRICAS"
      },
      {
        "code": "6130",
        "name": "TELECOMUNICACIONES POR SATÉLITE"
      },
      {
        "code": "6190",
        "name": "OTRAS ACTIVIDADES DE TELECOMUNICACIONES"
      },
      {
        "code": "6201",
        "name": "ACTIVIDADES DE PROGRAMACIÓN INFORMÁTICA"
      },
      {
        "code": "6202",
        "name": "ACTIVIDADES DE CONSULTORÍA INFORMÁTICA"
      },
      {
        "code": "6203",
        "name": "GESTIÓN DE RECURSOS INFORMÁTICOS"
      },
      {
        "code": "6209",
        "name": "OTROS SERVICIOS RELACIONADOS CON LAS TECNOLOGÍAS DE LA INFORMACIÓN Y LA INFORMÁTICA"
      },
      {
        "code": "6311",
        "name": "PROCESO DE DATOS, HOSTING Y ACTIVIDADES RELACIONADAS"
      },
      {
        "code": "6312",
        "name": "PORTALES WEB"
      },
      {
        "code": "6391",
        "name": "ACTIVIDADES DE LAS AGENCIAS DE NOTICIAS"
      },
      {
        "code": "6399",
        "name": "OTROS SERVICIOS DE INFORMACIÓN N.C.O.P."
      },
      {
        "code": "6411",
        "name": "BANCO CENTRAL"
      },
      {
        "code": "6419",
        "name": "OTRA INTERMEDIACIÓN MONETARIA"
      },
      {
        "code": "6420",
        "name": "ACTIVIDADES DE LAS SOCIEDADES HOLDING"
      },
      {
        "code": "6430",
        "name": "INVERSIÓN COLECTIVA, FONDOS Y ENTIDADES FINANCIERAS SIMILARES"
      },
      {
        "code": "6491",
        "name": "ARRENDAMIENTO FINANCIERO"
      },
      {
        "code": "6492",
        "name": "OTRAS ACTIVIDADES CREDITICIAS"
      },
      {
        "code": "6499",
        "name": "OTROS SERVICIOS FINANCIEROS, EXCEPTO SEGUROS Y FONDOS DE PENSIONES N.C.O.P."
      },
      {
        "code": "6511",
        "name": "SEGUROS DE VIDA"
      },
      {
        "code": "6512",
        "name": "SEGUROS DISTINTOS DE LOS SEGUROS DE VIDA"
      },
      {
        "code": "6520",
        "name": "REASEGUROS"
      },
      {
        "code": "6530",
        "name": "FONDOS DE PENSIONES"
      },
      {
        "code": "6611",
        "name": "ADMINISTRACIÓN DE MERCADOS FINANCIEROS"
      },
      {
        "code": "6612",
        "name": "ACTIVIDADES DE INTERMEDIACIÓN EN OPERACIONES CON VALORES Y OTROS ACTIVOS"
      },
      {
        "code": "6619",
        "name": "OTRAS ACTIVIDADES AUXILIARES A LOS SERVICIOS FINANCIEROS, EXCEPTO SEGUROS Y FONDOS DE PENSIONES"
      },
      {
        "code": "6621",
        "name": "EVALUACIÓN DE RIESGOS Y DAÑOS"
      },
      {
        "code": "6622",
        "name": "ACTIVIDADES DE AGENTES Y CORREDORES DE SEGUROS"
      },
      {
        "code": "6629",
        "name": "OTRAS ACTIVIDADES AUXILIARES A SEGUROS Y FONDOS DE PENSIONES"
      },
      {
        "code": "6630",
        "name": "ACTIVIDADES DE GESTIÓN DE FONDOS"
      },
      {
        "code": "6810",
        "name": "COMPRAVENTA DE BIENES INMOBILIARIOS POR CUENTA PROPIA"
      },
      {
        "code": "6820",
        "name": "ALQUILER DE BIENES INMOBILIARIOS POR CUENTA PROPIA"
      },
      {
        "code": "6831",
        "name": "AGENTES DE LA PROPIEDAD INMOBILIARIA"
      },
      {
        "code": "6832",
        "name": "GESTIÓN Y ADMINISTRACIÓN DE LA PROPIEDAD INMOBILIARIA"
      },
      {
        "code": "6910",
        "name": "ACTIVIDADES JURÍDICAS"
      },
      {
        "code": "6920",
        "name": "ACTIVIDADES DE CONTABILIDAD, TENEDURÍA DE LIBROS, AUDITORÍA Y ASESORÍA FISCAL"
      },
      {
        "code": "7010",
        "name": "ACTIVIDADES DE LAS SEDES CENTRALES"
      },
      {
        "code": "7021",
        "name": "RELACIONES PÚBLICAS Y COMUNICACIÓN"
      },
      {
        "code": "7022",
        "name": "OTRAS ACTIVIDADES DE CONSULTORÍA DE GESTIÓN EMPRESARIAL"
      },
      {
        "code": "7111",
        "name": "SERVICIOS TÉCNICOS DE ARQUITECTURA"
      },
      {
        "code": "7112",
        "name": "SERVICIOS TÉCNICOS DE INGENIERÍA Y OTRAS ACTIVIDADES RELACIONADAS CON EL ASESORAMIENTO TÉCNICO"
      },
      {
        "code": "7120",
        "name": "ENSAYOS Y ANÁLISIS TÉCNICOS"
      },
      {
        "code": "7211",
        "name": "INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN BIOTECNOLOGÍA"
      },
      {
        "code": "7219",
        "name": "OTRA INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN CIENCIAS NATURALES Y TÉCNICAS"
      },
      {
        "code": "7220",
        "name": "INVESTIGACIÓN Y DESARROLLO EXPERIMENTAL EN CIENCIAS SOCIALES Y HUMANIDADES"
      },
      {
        "code": "7311",
        "name": "AGENCIAS DE PUBLICIDAD"
      },
      {
        "code": "7312",
        "name": "SERVICIOS DE REPRESENTACIÓN DE MEDIOS DE COMUNICACIÓN"
      },
      {
        "code": "7320",
        "name": "ESTUDIO DE MERCADO Y REALIZACIÓN DE ENCUESTAS DE OPINIÓN PÚBLICA"
      },
      {
        "code": "7410",
        "name": "ACTIVIDADES DE DISEÑO ESPECIALIZADO"
      },
      {
        "code": "7420",
        "name": "ACTIVIDADES DE FOTOGRAFÍA"
      },
      {
        "code": "7430",
        "name": "ACTIVIDADES DE TRADUCCIÓN E INTERPRETACIÓN"
      },
      {
        "code": "7490",
        "name": "OTRAS ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS N.C.O.P."
      },
      {
        "code": "7500",
        "name": "ACTIVIDADES VETERINARIAS"
      },
      {
        "code": "7711",
        "name": "ALQUILER DE AUTOMÓVILES Y VEHÍCULOS DE MOTOR LIGEROS"
      },
      {
        "code": "7712",
        "name": "ALQUILER DE CAMIONES"
      },
      {
        "code": "7721",
        "name": "ALQUILER DE ARTÍCULOS DE OCIO Y DEPORTIVOS"
      },
      {
        "code": "7722",
        "name": "ALQUILER DE CINTAS DE VÍDEO Y DISCOS"
      },
      {
        "code": "7729",
        "name": "ALQUILER DE OTROS EFECTOS PERSONALES Y ARTÍCULOS DE USO DOMÉSTICO"
      },
      {
        "code": "7731",
        "name": "ALQUILER DE MAQUINARIA Y EQUIPO DE USO AGRÍCOLA"
      },
      {
        "code": "7732",
        "name": "ALQUILER DE MAQUINARIA Y EQUIPO PARA LA CONSTRUCCIÓN E INGENIERÍA CIVIL"
      },
      {
        "code": "7733",
        "name": "ALQUILER DE MAQUINARIA Y EQUIPO DE OFICINA, INCLUIDOS ORDENADORES"
      },
      {
        "code": "7734",
        "name": "ALQUILER DE MEDIOS DE NAVEGACIÓN"
      },
      {
        "code": "7735",
        "name": "ALQUILER DE MEDIOS DE TRANSPORTE AÉREO"
      },
      {
        "code": "7739",
        "name": "ALQUILER DE OTRA MAQUINARIA, EQUIPOS Y BIENES TANGIBLES N.C.O.P."
      },
      {
        "code": "7740",
        "name": "ARRENDAMIENTO DE LA PROPIEDAD INTELECTUAL Y PRODUCTOS SIMILARES, EXCEPTO TRABAJOS PROTEGIDOS POR LOS DERECHOS DE AUTOR"
      },
      {
        "code": "7810",
        "name": "ACTIVIDADES DE LAS AGENCIAS DE COLOCACIÓN"
      },
      {
        "code": "7820",
        "name": "ACTIVIDADES DE LAS EMPRESAS DE TRABAJO TEMPORAL"
      },
      {
        "code": "7830",
        "name": "OTRA PROVISIÓN DE RECURSOS HUMANOS"
      },
      {
        "code": "7911",
        "name": "ACTIVIDADES DE LAS AGENCIAS DE VIAJES"
      },
      {
        "code": "7912",
        "name": "ACTIVIDADES DE LOS OPERADORES TURÍSTICOS"
      },
      {
        "code": "7990",
        "name": "OTROS SERVICIOS DE RESERVAS Y ACTIVIDADES RELACIONADAS CON LOS MISMOS"
      },
      {
        "code": "8010",
        "name": "ACTIVIDADES DE SEGURIDAD PRIVADA"
      },
      {
        "code": "8020",
        "name": "SERVICIOS DE SISTEMAS DE SEGURIDAD"
      },
      {
        "code": "8030",
        "name": "ACTIVIDADES DE INVESTIGACIÓN"
      },
      {
        "code": "8110",
        "name": "SERVICIOS INTEGRALES A EDIFICIOS E INSTALACIONES"
      },
      {
        "code": "8121",
        "name": "LIMPIEZA GENERAL DE EDIFICIOS"
      },
      {
        "code": "8122",
        "name": "OTRAS ACTIVIDADES DE LIMPIEZA INDUSTRIAL Y DE EDIFICIOS"
      },
      {
        "code": "8129",
        "name": "OTRAS ACTIVIDADES DE LIMPIEZA"
      },
      {
        "code": "8130",
        "name": "ACTIVIDADES DE JARDINERÍA"
      },
      {
        "code": "8211",
        "name": "SERVICIOS ADMINISTRATIVOS COMBINADOS"
      },
      {
        "code": "8219",
        "name": "ACTIVIDADES DE FOTOCOPIADO, PREPARACIÓN DE DOCUMENTOS Y OTRAS ACTIVIDADES ESPECIALIZADAS DE OFICINA"
      },
      {
        "code": "8220",
        "name": "ACTIVIDADES DE LOS CENTROS DE LLAMADAS"
      },
      {
        "code": "8230",
        "name": "ORGANIZACIÓN DE CONVENCIONES Y FERIAS DE MUESTRAS"
      },
      {
        "code": "8291",
        "name": "ACTIVIDADES DE LAS AGENCIAS DE COBROS Y DE INFORMACIÓN COMERCIAL"
      },
      {
        "code": "8292",
        "name": "ACTIVIDADES DE ENVASADO Y EMPAQUETADO"
      },
      {
        "code": "8299",
        "name": "OTRAS ACTIVIDADES DE APOYO A LAS EMPRESAS N.C.O.P."
      },
      {
        "code": "8411",
        "name": "ACTIVIDADES GENERALES DE LA ADMINISTRACIÓN PÚBLICA"
      },
      {
        "code": "8412",
        "name": "REGULACIÓN DE LAS ACTIVIDADES SANITARIAS, EDUCATIVAS Y CULTURALES Y OTROS SERVICIOS SOCIALES, EXCEPTO SEGURIDAD SOCIAL"
      },
      {
        "code": "8413",
        "name": "REGULACIÓN DE LA ACTIVIDAD ECONÓMICA Y CONTRIBUCIÓN A SU MAYOR EFICIENCIA"
      },
      {
        "code": "8421",
        "name": "ASUNTOS EXTERIORES"
      },
      {
        "code": "8422",
        "name": "DEFENSA"
      },
      {
        "code": "8423",
        "name": "JUSTICIA"
      },
      {
        "code": "8424",
        "name": "ORDEN PÚBLICO Y SEGURIDAD"
      },
      {
        "code": "8425",
        "name": "PROTECCIÓN CIVIL"
      },
      {
        "code": "8430",
        "name": "SEGURIDAD SOCIAL OBLIGATORIA"
      },
      {
        "code": "8510",
        "name": "EDUCACIÓN PREPRIMARIA"
      },
      {
        "code": "8520",
        "name": "EDUCACIÓN PRIMARIA"
      },
      {
        "code": "8531",
        "name": "EDUCACIÓN SECUNDARIA GENERAL"
      },
      {
        "code": "8532",
        "name": "EDUCACIÓN SECUNDARIA TÉCNICA Y PROFESIONAL"
      },
      {
        "code": "8541",
        "name": "EDUCACIÓN POSTSECUNDARIA NO TERCIARIA"
      },
      {
        "code": "8543",
        "name": "EDUCACIÓN UNIVERSITARIA"
      },
      {
        "code": "8544",
        "name": "EDUCACIÓN TERCIARIA NO UNIVERSITARIA"
      },
      {
        "code": "8551",
        "name": "EDUCACIÓN DEPORTIVA Y RECREATIVA"
      },
      {
        "code": "8552",
        "name": "EDUCACIÓN CULTURAL"
      },
      {
        "code": "8553",
        "name": "ACTIVIDADES DE LAS ESCUELAS DE CONDUCCIÓN Y PILOTAJE"
      },
      {
        "code": "8559",
        "name": "OTRA EDUCACIÓN N.C.O.P."
      },
      {
        "code": "8560",
        "name": "ACTIVIDADES AUXILIARES A LA EDUCACIÓN"
      },
      {
        "code": "8610",
        "name": "ACTIVIDADES HOSPITALARIAS"
      },
      {
        "code": "8621",
        "name": "ACTIVIDADES DE MEDICINA GENERAL"
      },
      {
        "code": "8622",
        "name": "ACTIVIDADES DE MEDICINA ESPECIALIZADA"
      },
      {
        "code": "8623",
        "name": "ACTIVIDADES ODONTOLÓGICAS"
      },
      {
        "code": "8690",
        "name": "OTRAS ACTIVIDADES SANITARIAS"
      },
      {
        "code": "8710",
        "name": "ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES CON CUIDADOS SANITARIOS"
      },
      {
        "code": "8720",
        "name": "ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES PARA PERSONAS CON DISCAPACIDAD INTELECTUAL, ENFERMEDAD MENTAL Y DROGODEPENDENCIA"
      },
      {
        "code": "8731",
        "name": "ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES PARA PERSONAS MAYORES"
      },
      {
        "code": "8732",
        "name": "ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES PARA PERSONAS CON DISCAPACIDAD FÍSICA"
      },
      {
        "code": "8790",
        "name": "OTRAS ACTIVIDADES DE ASISTENCIA EN ESTABLECIMIENTOS RESIDENCIALES"
      },
      {
        "code": "8811",
        "name": "ACTIVIDADES DE SERVICIOS SOCIALES SIN ALOJAMIENTO PARA PERSONAS MAYORES"
      },
      {
        "code": "8812",
        "name": "ACTIVIDADES DE SERVICIOS SOCIALES SIN ALOJAMIENTO PARA PERSONAS CON DISCAPACIDAD"
      },
      {
        "code": "8891",
        "name": "ACTIVIDADES DE CUIDADO DIURNO DE NIÑOS"
      },
      {
        "code": "8899",
        "name": "OTROS ACTIVIDADES DE SERVICIOS SOCIALES SIN ALOJAMIENTO N.C.O.P."
      },
      {
        "code": "9001",
        "name": "ARTES ESCÉNICAS"
      },
      {
        "code": "9002",
        "name": "ACTIVIDADES AUXILIARES A LAS ARTES ESCÉNICAS"
      },
      {
        "code": "9003",
        "name": "CREACIÓN ARTÍSTICA Y LITERARIA"
      },
      {
        "code": "9004",
        "name": "GESTIÓN DE SALAS DE ESPECTÁCULOS"
      },
      {
        "code": "9102",
        "name": "ACTIVIDADES DE MUSEOS"
      },
      {
        "code": "9103",
        "name": "GESTIÓN DE LUGARES Y EDIFICIOS HISTÓRICOS"
      },
      {
        "code": "9104",
        "name": "ACTIVIDADES DE LOS JARDINES BOTÁNICOS, PARQUES ZOOLÓGICOS Y RESERVAS NATURALES"
      },
      {
        "code": "9105",
        "name": "ACTIVIDADES DE BIBLIOTECAS"
      },
      {
        "code": "9106",
        "name": "ACTIVIDADES DE ARCHIVOS"
      },
      {
        "code": "9200",
        "name": "ACTIVIDADES DE JUEGOS DE AZAR Y APUESTAS"
      },
      {
        "code": "9311",
        "name": "GESTIÓN DE INSTALACIONES DEPORTIVAS"
      },
      {
        "code": "9312",
        "name": "ACTIVIDADES DE LOS CLUBES DEPORTIVOS"
      },
      {
        "code": "9313",
        "name": "ACTIVIDADES DE LOS GIMNASIOS"
      },
      {
        "code": "9319",
        "name": "OTRAS ACTIVIDADES DEPORTIVAS"
      },
      {
        "code": "9321",
        "name": "ACTIVIDADES DE LOS PARQUES DE ATRACCIONES Y LOS PARQUES TEMÁTICOS"
      },
      {
        "code": "9329",
        "name": "OTRAS ACTIVIDADES RECREATIVAS Y DE ENTRETENIMIENTO"
      },
      {
        "code": "9411",
        "name": "ACTIVIDADES DE ORGANIZACIONES EMPRESARIALES Y PATRONALES"
      },
      {
        "code": "9412",
        "name": "ACTIVIDADES DE ORGANIZACIONES PROFESIONALES"
      },
      {
        "code": "9420",
        "name": "ACTIVIDADES SINDICALES"
      },
      {
        "code": "9491",
        "name": "ACTIVIDADES DE ORGANIZACIONES RELIGIOSAS"
      },
      {
        "code": "9492",
        "name": "ACTIVIDADES DE ORGANIZACIONES POLÍTICAS"
      },
      {
        "code": "9499",
        "name": "OTRAS ACTIVIDADES ASOCIATIVAS N.C.O.P."
      },
      {
        "code": "9511",
        "name": "REPARACIÓN DE ORDENADORES Y EQUIPOS PERIFÉRICOS"
      },
      {
        "code": "9512",
        "name": "REPARACIÓN DE EQUIPOS DE COMUNICACIÓN"
      },
      {
        "code": "9521",
        "name": "REPARACIÓN DE APARATOS ELECTRÓNICOS DE AUDIO Y VÍDEO DE USO DOMÉSTICO"
      },
      {
        "code": "9522",
        "name": "REPARACIÓN DE APARATOS ELECTRODOMÉSTICOS Y DE EQUIPOS PARA EL HOGAR Y EL JARDÍN"
      },
      {
        "code": "9523",
        "name": "REPARACIÓN DE CALZADO Y ARTÍCULOS DE CUERO"
      },
      {
        "code": "9524",
        "name": "REPARACIÓN DE MUEBLES Y ARTÍCULOS DE MENAJE"
      },
      {
        "code": "9525",
        "name": "REPARACIÓN DE RELOJES Y JOYERÍA"
      },
      {
        "code": "9529",
        "name": "REPARACIÓN DE OTROS EFECTOS PERSONALES Y ARTÍCULOS DE USO DOMÉSTICO"
      },
      {
        "code": "9601",
        "name": "LAVADO Y LIMPIEZA DE PRENDAS TEXTILES Y DE PIEL"
      },
      {
        "code": "9602",
        "name": "PELUQUERÍA Y OTROS TRATAMIENTOS DE BELLEZA"
      },
      {
        "code": "9603",
        "name": "POMPAS FÚNEBRES Y ACTIVIDADES RELACIONADAS"
      },
      {
        "code": "9604",
        "name": "ACTIVIDADES DE MANTENIMIENTO FÍSICO"
      },
      {
        "code": "9609",
        "name": "OTRAS SERVICIOS PERSONALES N.C.O.P."
      },
      {
        "code": "9700",
        "name": "ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES DE PERSONAL DOMÉSTICO"
      },
      {
        "code": "9810",
        "name": "ACTIVIDADES DE LOS HOGARES COMO PRODUCTORES DE BIENES PARA USO PROPIO"
      },
      {
        "code": "9820",
        "name": "ACTIVIDADES DE LOS HOGARES COMO PRODUCTORES DE SERVICIOS PARA USO PROPIO"
      },
      {
        "code": "9900",
        "name": "ACTIVIDADES DE ORGANIZACIONES Y ORGANISMOS EXTRATERRITORIALES"
      }
    ]
     return of (cnaeList)
  }

  public pepRole(): Observable<any>{
    const pepRoleList = [
      {
        "code": "BC",
        "name": "Miembros de Consejos de Bancos Centrales"
      },
      {
        "code": "EM",
        "name": "Embajadores"
      },
      {
        "code": "JE",
        "name": "Jefes de Estado"
      },
      {
        "code": "JG",
        "name": "Jefes de Gobierno"
      },
      {
        "code": "MA",
        "name": "Magistrados de Tribunales Supremos o Tribunales Constitucionales"
      },
      {
        "code": "MI",
        "name": "Ministros"
      },
      {
        "code": "PA",
        "name": "Parlamentarios"
      },
      {
        "code": "SE",
        "name": "Secretarios de Estado o Subsecretarios"
      },
      {
        "code": "TC",
        "name": "Miembros de Tribunales de Cuentas"
      }
     ]
    return of (pepRoleList)
  }

}


 