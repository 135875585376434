import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Plans } from '../../models/plans/plans';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor(private http: HttpClient) { }

  public createPlan(body: any): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/products`, body)
  }

  public getPlansList(): Observable<Plans[]> {
    return this.http.get<Plans[]>(`${environment.userUrl}/products`)
  }

  public deletePlan(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.userUrl}/products/${id}`);
  }

}

