import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit{
  public cashOut: FormControl = new FormControl([])
  public billing: FormControl = new FormControl([])
  public cards: FormControl = new FormControl([])
  _userData: any;

    //inputData viene de Host. Son los datos de company
    @Input() set userData(val: any) {
      if (!val) {
        return
      }
      this._userData = val
    }
    

  constructor(){
    
  }
  ngOnInit(): void {
   
  }
}
