<div class="info_container">
    <p class="info_title"><mat-icon>fingerprint</mat-icon> KYC</p>
    <table class="info_table">
        <tr *ngFor="let item of kycInfo">
            <td class="table_key">{{item.title}}</td>
            <td>
                <p *ngIf="!item.editing" class="td">{{item.value}} <mat-icon (click)="showEdit(item.key)"
                        *ngIf="item.editable">edit</mat-icon></p>
                <div class="td" *ngIf="item.editing">
                    <input type="text" [formControl]="editedField" autofocus (keydown.enter)="editField(item.key)">
                    <div class="buttons_container">
                        <button (click)="editField(item.key)" mat-icon-button>
                            <mat-icon>check</mat-icon>
                        </button>
                        <button (click)="hideEdit(item.key)" mat-icon-button>
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
