<div class="selector_background">
    <div class="selector_component">
      <div class="selector_icons">
        <mat-form-field class="accounts" appearance="fill">
          <mat-select style="text-transform: uppercase" [formControl]="accountSelected" (selectionChange)="onSubmit()">
            <mat-option style="text-transform: uppercase;" class="mat-option" value="{{item.id}}"
              *ngFor="let item of _userAccounts"> CUENTA {{item.alias}}: {{item.iban}}</mat-option>
          </mat-select>
          <mat-label>Cuentas</mat-label>
        </mat-form-field>
        <button *ngIf="!loader" class="pointer-container" mat-icon-button [cdkCopyToClipboard]="accountData.iban" >
          <mat-icon class="pointer" >content_copy</mat-icon>
        </button>
      </div>
      <div class="selector_bottom" >
        <div class="selector_info">
          <div *ngIf="!loader" class="money_info">
            <p>Saldo disponible</p>
            <h3 *ngIf="visibleAmount">{{accountData.balance | currency: "€":"symbol":"1.2-2":"es"}}</h3>
            <h3 *ngIf="!visibleAmount">******** €</h3>
          </div>
          <div *ngIf="loader" class="skeleton">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px' }">
            </ngx-skeleton-loader>
          </div>
          <div *ngIf="!loader" class="money_info">
            <p>Saldo contable</p>
            <h3 *ngIf="visibleAmount">{{accountData.balance | currency: "€":"symbol":"1.2-2":"es"}}</h3>
            <h3 *ngIf="!visibleAmount">******** €</h3>
          </div>
          <ng-container class="icons-content">
            <button *ngIf="!loader" mat-icon-button (click)="openDialog()">
              <mat-icon class="pointer">settings_suggest</mat-icon>
            </button>
            <ngx-skeleton-loader *ngIf="loader"  count="1" appearance="circle" >
            </ngx-skeleton-loader>
            <ngx-skeleton-loader *ngIf="loader"  count="1" appearance="circle" >
            </ngx-skeleton-loader>
          </ng-container>
        </div>
      </div>
<!--       <div class="selector_actions">
        <button class="main-filled-button">Nuevo cargo</button>
        <button class="main-filled-button">Nuevo abono</button>
      </div> -->
    </div>
  </div>