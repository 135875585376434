<div class="dialog-header">
  <h2 mat-dialog-title>Estados</h2>
  <button mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <ul>
    <li *ngFor="let status of data.statusList; let i = index" >
      <span class="circle" >{{ i + 1 }}</span>
      <span class="explanationStatus-span">{{ status.explanation }}</span>
    </li>
  </ul>
  <div>
  </div>
</mat-dialog-content>  