import { ZipCodeModel } from '../../models/selects/selects';
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ZipcodelistMockService {
  
  public cpList(): Observable<ZipCodeModel[]> {
    const zipCodeList = 
            [
                {
                    "zipcode": "01001",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01002",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01003",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01004",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01005",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01006",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01007",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01008",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01009",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01010",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01012",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01012",
                    "province": "Álava",
                    "city": "Labastida/Bastida",
                    "multiple": "1"
                },
                {
                    "zipcode": "01013",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01015",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01110",
                    "province": "Álava",
                    "city": "Campezo/Kanpezu",
                    "multiple": "0"
                },
                {
                    "zipcode": "01117",
                    "province": "Álava",
                    "city": "Harana/Valle de Arana",
                    "multiple": "0"
                },
                {
                    "zipcode": "01117",
                    "province": "Álava",
                    "city": "Campezo/Kanpezu",
                    "multiple": "1"
                },
                {
                    "zipcode": "01118",
                    "province": "Álava",
                    "city": "Lagrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "01118",
                    "province": "Álava",
                    "city": "Bernedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "01118",
                    "province": "Álava",
                    "city": "Condado de Treviño",
                    "multiple": "1"
                },
                {
                    "zipcode": "01120",
                    "province": "Álava",
                    "city": "Arraia-Maeztu",
                    "multiple": "0"
                },
                {
                    "zipcode": "01128",
                    "province": "Álava",
                    "city": "Campezo/Kanpezu",
                    "multiple": "0"
                },
                {
                    "zipcode": "01128",
                    "province": "Álava",
                    "city": "Arraia-Maeztu",
                    "multiple": "1"
                },
                {
                    "zipcode": "01128",
                    "province": "Álava",
                    "city": "Bernedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "01129",
                    "province": "Álava",
                    "city": "Arraia-Maeztu",
                    "multiple": "0"
                },
                {
                    "zipcode": "01130",
                    "province": "Álava",
                    "city": "Zuia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01138",
                    "province": "Álava",
                    "city": "Zigoitia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01139",
                    "province": "Álava",
                    "city": "Zuia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01160",
                    "province": "Álava",
                    "city": "Aramaio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01165",
                    "province": "Álava",
                    "city": "Aramaio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01169",
                    "province": "Álava",
                    "city": "Aramaio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01170",
                    "province": "Álava",
                    "city": "Legutio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01171",
                    "province": "Álava",
                    "city": "Legutio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01191",
                    "province": "Álava",
                    "city": "Iruña Oka/Iruña de Oca",
                    "multiple": "0"
                },
                {
                    "zipcode": "01191",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "01192",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01192",
                    "province": "Álava",
                    "city": "Elburgo/Burgelu",
                    "multiple": "1"
                },
                {
                    "zipcode": "01193",
                    "province": "Álava",
                    "city": "Iruraiz-Gauna",
                    "multiple": "0"
                },
                {
                    "zipcode": "01193",
                    "province": "Álava",
                    "city": "Alegría-Dulantzi",
                    "multiple": "1"
                },
                {
                    "zipcode": "01193",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "01193",
                    "province": "Álava",
                    "city": "Elburgo/Burgelu",
                    "multiple": "1"
                },
                {
                    "zipcode": "01194",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01194",
                    "province": "Álava",
                    "city": "Bernedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "01195",
                    "province": "Álava",
                    "city": "Iruña Oka/Iruña de Oca",
                    "multiple": "0"
                },
                {
                    "zipcode": "01195",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "01196",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01196",
                    "province": "Álava",
                    "city": "Zigoitia",
                    "multiple": "1"
                },
                {
                    "zipcode": "01200",
                    "province": "Álava",
                    "city": "Salvatierra/Agurain",
                    "multiple": "0"
                },
                {
                    "zipcode": "01206",
                    "province": "Álava",
                    "city": "Iruraiz-Gauna",
                    "multiple": "0"
                },
                {
                    "zipcode": "01206",
                    "province": "Álava",
                    "city": "Barrundia",
                    "multiple": "1"
                },
                {
                    "zipcode": "01207",
                    "province": "Álava",
                    "city": "Salvatierra/Agurain",
                    "multiple": "0"
                },
                {
                    "zipcode": "01207",
                    "province": "Álava",
                    "city": "San Millán/Donemiliaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "01207",
                    "province": "Álava",
                    "city": "Iruraiz-Gauna",
                    "multiple": "1"
                },
                {
                    "zipcode": "01208",
                    "province": "Álava",
                    "city": "Asparrena",
                    "multiple": "0"
                },
                {
                    "zipcode": "01208",
                    "province": "Álava",
                    "city": "Zalduondo",
                    "multiple": "1"
                },
                {
                    "zipcode": "01208",
                    "province": "Álava",
                    "city": "Barrundia",
                    "multiple": "1"
                },
                {
                    "zipcode": "01208",
                    "province": "Álava",
                    "city": "San Millán/Donemiliaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "01211",
                    "province": "Álava",
                    "city": "Armiñón",
                    "multiple": "0"
                },
                {
                    "zipcode": "01211",
                    "province": "Álava",
                    "city": "Lagrán",
                    "multiple": "1"
                },
                {
                    "zipcode": "01211",
                    "province": "Álava",
                    "city": "Peñacerrada-Urizaharra",
                    "multiple": "1"
                },
                {
                    "zipcode": "01211",
                    "province": "Álava",
                    "city": "Berantevilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "01211",
                    "province": "Álava",
                    "city": "Condado de Treviño",
                    "multiple": "1"
                },
                {
                    "zipcode": "01212",
                    "province": "Álava",
                    "city": "Labastida/Bastida",
                    "multiple": "0"
                },
                {
                    "zipcode": "01212",
                    "province": "Álava",
                    "city": "Zambrana",
                    "multiple": "1"
                },
                {
                    "zipcode": "01212",
                    "province": "Álava",
                    "city": "Peñacerrada-Urizaharra",
                    "multiple": "1"
                },
                {
                    "zipcode": "01212",
                    "province": "Álava",
                    "city": "Berantevilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "01213",
                    "province": "Álava",
                    "city": "Ribera Baja/Erribera Beitia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01213",
                    "province": "Álava",
                    "city": "Erriberagoitia/Ribera Alta",
                    "multiple": "1"
                },
                {
                    "zipcode": "01213",
                    "province": "Álava",
                    "city": "Lantarón",
                    "multiple": "1"
                },
                {
                    "zipcode": "01216",
                    "province": "Álava",
                    "city": "Bernedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "01216",
                    "province": "Álava",
                    "city": "Peñacerrada-Urizaharra",
                    "multiple": "1"
                },
                {
                    "zipcode": "01220",
                    "province": "Álava",
                    "city": "Berantevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "01220",
                    "province": "Álava",
                    "city": "Armiñón",
                    "multiple": "1"
                },
                {
                    "zipcode": "01220",
                    "province": "Álava",
                    "city": "Ribera Baja/Erribera Beitia",
                    "multiple": "1"
                },
                {
                    "zipcode": "01220",
                    "province": "Álava",
                    "city": "Erriberagoitia/Ribera Alta",
                    "multiple": "1"
                },
                {
                    "zipcode": "01230",
                    "province": "Álava",
                    "city": "Iruña Oka/Iruña de Oca",
                    "multiple": "0"
                },
                {
                    "zipcode": "01240",
                    "province": "Álava",
                    "city": "Alegría-Dulantzi",
                    "multiple": "0"
                },
                {
                    "zipcode": "01250",
                    "province": "Álava",
                    "city": "Asparrena",
                    "multiple": "0"
                },
                {
                    "zipcode": "01260",
                    "province": "Álava",
                    "city": "Asparrena",
                    "multiple": "0"
                },
                {
                    "zipcode": "01300",
                    "province": "Álava",
                    "city": "Laguardia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01306",
                    "province": "Álava",
                    "city": "Lapuebla de Labarca",
                    "multiple": "0"
                },
                {
                    "zipcode": "01307",
                    "province": "Álava",
                    "city": "Samaniego",
                    "multiple": "0"
                },
                {
                    "zipcode": "01307",
                    "province": "Álava",
                    "city": "Baños de Ebro/Mañueta",
                    "multiple": "1"
                },
                {
                    "zipcode": "01307",
                    "province": "Álava",
                    "city": "Villabuena de Álava/Eskuernaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "01308",
                    "province": "Álava",
                    "city": "Laguardia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01308",
                    "province": "Álava",
                    "city": "Kripan",
                    "multiple": "1"
                },
                {
                    "zipcode": "01308",
                    "province": "Álava",
                    "city": "Lanciego/Lantziego",
                    "multiple": "1"
                },
                {
                    "zipcode": "01309",
                    "province": "Álava",
                    "city": "Navaridas",
                    "multiple": "0"
                },
                {
                    "zipcode": "01309",
                    "province": "Álava",
                    "city": "Laguardia",
                    "multiple": "1"
                },
                {
                    "zipcode": "01309",
                    "province": "Álava",
                    "city": "Leza",
                    "multiple": "1"
                },
                {
                    "zipcode": "01309",
                    "province": "Álava",
                    "city": "Elvillar/Bilar",
                    "multiple": "1"
                },
                {
                    "zipcode": "01320",
                    "province": "Álava",
                    "city": "Oyón-Oion",
                    "multiple": "0"
                },
                {
                    "zipcode": "01321",
                    "province": "Álava",
                    "city": "Laguardia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01322",
                    "province": "Álava",
                    "city": "Oyón-Oion",
                    "multiple": "0"
                },
                {
                    "zipcode": "01322",
                    "province": "Álava",
                    "city": "Yécora/Iekora",
                    "multiple": "1"
                },
                {
                    "zipcode": "01322",
                    "province": "Álava",
                    "city": "Moreda de Álava/Moreda Araba",
                    "multiple": "1"
                },
                {
                    "zipcode": "01330",
                    "province": "Álava",
                    "city": "Labastida/Bastida",
                    "multiple": "0"
                },
                {
                    "zipcode": "01340",
                    "province": "Álava",
                    "city": "Elciego",
                    "multiple": "0"
                },
                {
                    "zipcode": "01400",
                    "province": "Álava",
                    "city": "Laudio/Llodio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01408",
                    "province": "Álava",
                    "city": "Ayala/Aiara",
                    "multiple": "0"
                },
                {
                    "zipcode": "01409",
                    "province": "Álava",
                    "city": "Okondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "01420",
                    "province": "Álava",
                    "city": "Erriberagoitia/Ribera Alta",
                    "multiple": "0"
                },
                {
                    "zipcode": "01423",
                    "province": "Álava",
                    "city": "Valdegovía/Gaubea",
                    "multiple": "0"
                },
                {
                    "zipcode": "01423",
                    "province": "Álava",
                    "city": "Añana",
                    "multiple": "1"
                },
                {
                    "zipcode": "01423",
                    "province": "Álava",
                    "city": "Erriberagoitia/Ribera Alta",
                    "multiple": "1"
                },
                {
                    "zipcode": "01423",
                    "province": "Álava",
                    "city": "Lantarón",
                    "multiple": "1"
                },
                {
                    "zipcode": "01426",
                    "province": "Álava",
                    "city": "Añana",
                    "multiple": "0"
                },
                {
                    "zipcode": "01426",
                    "province": "Álava",
                    "city": "Erriberagoitia/Ribera Alta",
                    "multiple": "1"
                },
                {
                    "zipcode": "01426",
                    "province": "Álava",
                    "city": "Valdegovía/Gaubea",
                    "multiple": "1"
                },
                {
                    "zipcode": "01427",
                    "province": "Álava",
                    "city": "Valdegovía/Gaubea",
                    "multiple": "0"
                },
                {
                    "zipcode": "01427",
                    "province": "Álava",
                    "city": "Berberana",
                    "multiple": "1"
                },
                {
                    "zipcode": "01427",
                    "province": "Álava",
                    "city": "Jurisdicción de San Zadornil",
                    "multiple": "1"
                },
                {
                    "zipcode": "01428",
                    "province": "Álava",
                    "city": "Valdegovía/Gaubea",
                    "multiple": "0"
                },
                {
                    "zipcode": "01428",
                    "province": "Álava",
                    "city": "Erriberagoitia/Ribera Alta",
                    "multiple": "1"
                },
                {
                    "zipcode": "01428",
                    "province": "Álava",
                    "city": "Iruña Oka/Iruña de Oca",
                    "multiple": "1"
                },
                {
                    "zipcode": "01430",
                    "province": "Álava",
                    "city": "Kuartango",
                    "multiple": "0"
                },
                {
                    "zipcode": "01439",
                    "province": "Álava",
                    "city": "Kuartango",
                    "multiple": "0"
                },
                {
                    "zipcode": "01440",
                    "province": "Álava",
                    "city": "Urkabustaiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01449",
                    "province": "Álava",
                    "city": "Urkabustaiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01450",
                    "province": "Álava",
                    "city": "Zuia",
                    "multiple": "0"
                },
                {
                    "zipcode": "01450",
                    "province": "Álava",
                    "city": "Urkabustaiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "01450",
                    "province": "Álava",
                    "city": "Amurrio",
                    "multiple": "1"
                },
                {
                    "zipcode": "01468",
                    "province": "Álava",
                    "city": "Amurrio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01470",
                    "province": "Álava",
                    "city": "Amurrio",
                    "multiple": "0"
                },
                {
                    "zipcode": "01470",
                    "province": "Álava",
                    "city": "Ayala/Aiara",
                    "multiple": "1"
                },
                {
                    "zipcode": "01474",
                    "province": "Álava",
                    "city": "Artziniega",
                    "multiple": "0"
                },
                {
                    "zipcode": "01476",
                    "province": "Álava",
                    "city": "Ayala/Aiara",
                    "multiple": "0"
                },
                {
                    "zipcode": "01477",
                    "province": "Álava",
                    "city": "Ayala/Aiara",
                    "multiple": "0"
                },
                {
                    "zipcode": "01478",
                    "province": "Álava",
                    "city": "Ayala/Aiara",
                    "multiple": "0"
                },
                {
                    "zipcode": "01478",
                    "province": "Álava",
                    "city": "Artziniega",
                    "multiple": "1"
                },
                {
                    "zipcode": "01479",
                    "province": "Álava",
                    "city": "Ayala/Aiara",
                    "multiple": "0"
                },
                {
                    "zipcode": "01510",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01510",
                    "province": "Álava",
                    "city": "Arratzua-Ubarrundia",
                    "multiple": "1"
                },
                {
                    "zipcode": "01510",
                    "province": "Álava",
                    "city": "Legutio",
                    "multiple": "1"
                },
                {
                    "zipcode": "01520",
                    "province": "Álava",
                    "city": "Vitoria-Gasteiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "01520",
                    "province": "Álava",
                    "city": "Arratzua-Ubarrundia",
                    "multiple": "1"
                },
                {
                    "zipcode": "02001",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02002",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02003",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02004",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02005",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02006",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02008",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02013",
                    "province": "Albacete",
                    "city": "Peñascosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "02049",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02071",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02099",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02099",
                    "province": "Albacete",
                    "city": "Pozo Cañada",
                    "multiple": "1"
                },
                {
                    "zipcode": "02100",
                    "province": "Albacete",
                    "city": "Tarazona de la Mancha",
                    "multiple": "0"
                },
                {
                    "zipcode": "02110",
                    "province": "Albacete",
                    "city": "Tarazona de la Mancha",
                    "multiple": "0"
                },
                {
                    "zipcode": "02110",
                    "province": "Albacete",
                    "city": "Gineta, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "02120",
                    "province": "Albacete",
                    "city": "Peñas de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "02124",
                    "province": "Albacete",
                    "city": "Liétor",
                    "multiple": "0"
                },
                {
                    "zipcode": "02124",
                    "province": "Albacete",
                    "city": "Alcadozo",
                    "multiple": "1"
                },
                {
                    "zipcode": "02124",
                    "province": "Albacete",
                    "city": "Ayna",
                    "multiple": "1"
                },
                {
                    "zipcode": "02125",
                    "province": "Albacete",
                    "city": "Ayna",
                    "multiple": "0"
                },
                {
                    "zipcode": "02126",
                    "province": "Albacete",
                    "city": "Ayna",
                    "multiple": "0"
                },
                {
                    "zipcode": "02127",
                    "province": "Albacete",
                    "city": "Peñas de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "02128",
                    "province": "Albacete",
                    "city": "Peñas de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "02128",
                    "province": "Albacete",
                    "city": "Alcadozo",
                    "multiple": "1"
                },
                {
                    "zipcode": "02129",
                    "province": "Albacete",
                    "city": "Pozohondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02130",
                    "province": "Albacete",
                    "city": "Bogarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02136",
                    "province": "Albacete",
                    "city": "Paterna del Madera",
                    "multiple": "0"
                },
                {
                    "zipcode": "02137",
                    "province": "Albacete",
                    "city": "Paterna del Madera",
                    "multiple": "0"
                },
                {
                    "zipcode": "02138",
                    "province": "Albacete",
                    "city": "Bogarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02138",
                    "province": "Albacete",
                    "city": "Peñascosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "02138",
                    "province": "Albacete",
                    "city": "Paterna del Madera",
                    "multiple": "1"
                },
                {
                    "zipcode": "02139",
                    "province": "Albacete",
                    "city": "Ayna",
                    "multiple": "0"
                },
                {
                    "zipcode": "02139",
                    "province": "Albacete",
                    "city": "Bogarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "02140",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02141",
                    "province": "Albacete",
                    "city": "Pozohondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02142",
                    "province": "Albacete",
                    "city": "Pozohondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02142",
                    "province": "Albacete",
                    "city": "Liétor",
                    "multiple": "1"
                },
                {
                    "zipcode": "02150",
                    "province": "Albacete",
                    "city": "Valdeganga",
                    "multiple": "0"
                },
                {
                    "zipcode": "02151",
                    "province": "Albacete",
                    "city": "Casas de Juan Núñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "02152",
                    "province": "Albacete",
                    "city": "Alatoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "02153",
                    "province": "Albacete",
                    "city": "Carcelén",
                    "multiple": "0"
                },
                {
                    "zipcode": "02154",
                    "province": "Albacete",
                    "city": "Pozo-Lorente",
                    "multiple": "0"
                },
                {
                    "zipcode": "02154",
                    "province": "Albacete",
                    "city": "Villavaliente",
                    "multiple": "1"
                },
                {
                    "zipcode": "02155",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02156",
                    "province": "Albacete",
                    "city": "Chinchilla de Monte-Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "02160",
                    "province": "Albacete",
                    "city": "Lezuza",
                    "multiple": "0"
                },
                {
                    "zipcode": "02161",
                    "province": "Albacete",
                    "city": "Lezuza",
                    "multiple": "0"
                },
                {
                    "zipcode": "02162",
                    "province": "Albacete",
                    "city": "Herrera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "02200",
                    "province": "Albacete",
                    "city": "Casas-Ibáñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "02210",
                    "province": "Albacete",
                    "city": "Alcalá del Júcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02211",
                    "province": "Albacete",
                    "city": "Alcalá del Júcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02212",
                    "province": "Albacete",
                    "city": "Casas de Ves",
                    "multiple": "0"
                },
                {
                    "zipcode": "02213",
                    "province": "Albacete",
                    "city": "Balsa de Ves",
                    "multiple": "0"
                },
                {
                    "zipcode": "02213",
                    "province": "Albacete",
                    "city": "Villa de Ves",
                    "multiple": "1"
                },
                {
                    "zipcode": "02214",
                    "province": "Albacete",
                    "city": "Alcalá del Júcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02214",
                    "province": "Albacete",
                    "city": "Balsa de Ves",
                    "multiple": "1"
                },
                {
                    "zipcode": "02215",
                    "province": "Albacete",
                    "city": "Villatoya",
                    "multiple": "0"
                },
                {
                    "zipcode": "02215",
                    "province": "Albacete",
                    "city": "Alborea",
                    "multiple": "1"
                },
                {
                    "zipcode": "02220",
                    "province": "Albacete",
                    "city": "Motilleja",
                    "multiple": "0"
                },
                {
                    "zipcode": "02230",
                    "province": "Albacete",
                    "city": "Madrigueras",
                    "multiple": "0"
                },
                {
                    "zipcode": "02240",
                    "province": "Albacete",
                    "city": "Mahora",
                    "multiple": "0"
                },
                {
                    "zipcode": "02246",
                    "province": "Albacete",
                    "city": "Navas de Jorquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "02247",
                    "province": "Albacete",
                    "city": "Cenizate",
                    "multiple": "0"
                },
                {
                    "zipcode": "02248",
                    "province": "Albacete",
                    "city": "Jorquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "02249",
                    "province": "Albacete",
                    "city": "Jorquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "02249",
                    "province": "Albacete",
                    "city": "Fuentealbilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "02249",
                    "province": "Albacete",
                    "city": "Recueja, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "02250",
                    "province": "Albacete",
                    "city": "Abengibre",
                    "multiple": "0"
                },
                {
                    "zipcode": "02251",
                    "province": "Albacete",
                    "city": "Fuentealbilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "02252",
                    "province": "Albacete",
                    "city": "Alcalá del Júcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02253",
                    "province": "Albacete",
                    "city": "Golosalvo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02260",
                    "province": "Albacete",
                    "city": "Fuentealbilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "02270",
                    "province": "Albacete",
                    "city": "Villamalea",
                    "multiple": "0"
                },
                {
                    "zipcode": "02300",
                    "province": "Albacete",
                    "city": "Alcaraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "02310",
                    "province": "Albacete",
                    "city": "Viveros",
                    "multiple": "0"
                },
                {
                    "zipcode": "02311",
                    "province": "Albacete",
                    "city": "Povedilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "02312",
                    "province": "Albacete",
                    "city": "Alcaraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "02313",
                    "province": "Albacete",
                    "city": "Peñascosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "02314",
                    "province": "Albacete",
                    "city": "Alcaraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "02314",
                    "province": "Albacete",
                    "city": "Peñascosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "02314",
                    "province": "Albacete",
                    "city": "Masegoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "02315",
                    "province": "Albacete",
                    "city": "Vianos",
                    "multiple": "0"
                },
                {
                    "zipcode": "02316",
                    "province": "Albacete",
                    "city": "Salobre",
                    "multiple": "0"
                },
                {
                    "zipcode": "02320",
                    "province": "Albacete",
                    "city": "Balazote",
                    "multiple": "0"
                },
                {
                    "zipcode": "02326",
                    "province": "Albacete",
                    "city": "San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "02327",
                    "province": "Albacete",
                    "city": "Pozuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02328",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02329",
                    "province": "Albacete",
                    "city": "Casas de Lázaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "02330",
                    "province": "Albacete",
                    "city": "Alcaraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "02331",
                    "province": "Albacete",
                    "city": "Robledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02332",
                    "province": "Albacete",
                    "city": "Robledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02340",
                    "province": "Albacete",
                    "city": "Robledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02350",
                    "province": "Albacete",
                    "city": "Villapalacios",
                    "multiple": "0"
                },
                {
                    "zipcode": "02360",
                    "province": "Albacete",
                    "city": "Bienservida",
                    "multiple": "0"
                },
                {
                    "zipcode": "02400",
                    "province": "Albacete",
                    "city": "Hellín",
                    "multiple": "0"
                },
                {
                    "zipcode": "02409",
                    "province": "Albacete",
                    "city": "Hellín",
                    "multiple": "0"
                },
                {
                    "zipcode": "02410",
                    "province": "Albacete",
                    "city": "Liétor",
                    "multiple": "0"
                },
                {
                    "zipcode": "02420",
                    "province": "Albacete",
                    "city": "Hellín",
                    "multiple": "0"
                },
                {
                    "zipcode": "02430",
                    "province": "Albacete",
                    "city": "Elche de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02434",
                    "province": "Albacete",
                    "city": "Letur",
                    "multiple": "0"
                },
                {
                    "zipcode": "02435",
                    "province": "Albacete",
                    "city": "Socovos",
                    "multiple": "0"
                },
                {
                    "zipcode": "02436",
                    "province": "Albacete",
                    "city": "Férez",
                    "multiple": "0"
                },
                {
                    "zipcode": "02437",
                    "province": "Albacete",
                    "city": "Socovos",
                    "multiple": "0"
                },
                {
                    "zipcode": "02439",
                    "province": "Albacete",
                    "city": "Elche de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02440",
                    "province": "Albacete",
                    "city": "Molinicos",
                    "multiple": "0"
                },
                {
                    "zipcode": "02448",
                    "province": "Albacete",
                    "city": "Bogarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02448",
                    "province": "Albacete",
                    "city": "Molinicos",
                    "multiple": "1"
                },
                {
                    "zipcode": "02449",
                    "province": "Albacete",
                    "city": "Elche de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02449",
                    "province": "Albacete",
                    "city": "Molinicos",
                    "multiple": "1"
                },
                {
                    "zipcode": "02450",
                    "province": "Albacete",
                    "city": "Riópar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02459",
                    "province": "Albacete",
                    "city": "Riópar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02459",
                    "province": "Albacete",
                    "city": "Paterna del Madera",
                    "multiple": "1"
                },
                {
                    "zipcode": "02460",
                    "province": "Albacete",
                    "city": "Villaverde de Guadalimar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02461",
                    "province": "Albacete",
                    "city": "Cotillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "02462",
                    "province": "Albacete",
                    "city": "Riópar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02462",
                    "province": "Albacete",
                    "city": "Villaverde de Guadalimar",
                    "multiple": "1"
                },
                {
                    "zipcode": "02470",
                    "province": "Albacete",
                    "city": "Astorga",
                    "multiple": "0"
                },
                {
                    "zipcode": "02470",
                    "province": "Albacete",
                    "city": "Elche de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "02480",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02482",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02484",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02485",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02486",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02487",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02488",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02489",
                    "province": "Albacete",
                    "city": "Yeste",
                    "multiple": "0"
                },
                {
                    "zipcode": "02490",
                    "province": "Albacete",
                    "city": "Hellín",
                    "multiple": "0"
                },
                {
                    "zipcode": "02498",
                    "province": "Albacete",
                    "city": "Hellín",
                    "multiple": "0"
                },
                {
                    "zipcode": "02499",
                    "province": "Albacete",
                    "city": "Hellín",
                    "multiple": "0"
                },
                {
                    "zipcode": "02500",
                    "province": "Albacete",
                    "city": "Tobarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02510",
                    "province": "Albacete",
                    "city": "Chinchilla de Monte-Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "02510",
                    "province": "Albacete",
                    "city": "Pozo Cañada",
                    "multiple": "1"
                },
                {
                    "zipcode": "02511",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02512",
                    "province": "Albacete",
                    "city": "Albacete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02513",
                    "province": "Albacete",
                    "city": "Tobarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02514",
                    "province": "Albacete",
                    "city": "Tobarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "02520",
                    "province": "Albacete",
                    "city": "Chinchilla de Monte-Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "02529",
                    "province": "Albacete",
                    "city": "Chinchilla de Monte-Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "02530",
                    "province": "Albacete",
                    "city": "Nerpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02534",
                    "province": "Albacete",
                    "city": "Nerpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02535",
                    "province": "Albacete",
                    "city": "Nerpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02536",
                    "province": "Albacete",
                    "city": "Nerpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02537",
                    "province": "Albacete",
                    "city": "Nerpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02538",
                    "province": "Albacete",
                    "city": "Nerpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02539",
                    "province": "Albacete",
                    "city": "Nerpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02600",
                    "province": "Albacete",
                    "city": "Villarrobledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02610",
                    "province": "Albacete",
                    "city": "Bonillo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "02611",
                    "province": "Albacete",
                    "city": "Ossa de Montiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "02612",
                    "province": "Albacete",
                    "city": "Munera",
                    "multiple": "0"
                },
                {
                    "zipcode": "02612",
                    "province": "Albacete",
                    "city": "Villarrobledo",
                    "multiple": "1"
                },
                {
                    "zipcode": "02613",
                    "province": "Albacete",
                    "city": "Bonillo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "02614",
                    "province": "Albacete",
                    "city": "Ballestero, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "02620",
                    "province": "Albacete",
                    "city": "Minaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "02630",
                    "province": "Albacete",
                    "city": "Roda, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "02636",
                    "province": "Albacete",
                    "city": "Villalgordo del Júcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "02636",
                    "province": "Albacete",
                    "city": "Casas de Benítez",
                    "multiple": "1"
                },
                {
                    "zipcode": "02636",
                    "province": "Albacete",
                    "city": "Villalgordo del Júcar",
                    "multiple": "1"
                },
                {
                    "zipcode": "02637",
                    "province": "Albacete",
                    "city": "Fuensanta",
                    "multiple": "0"
                },
                {
                    "zipcode": "02638",
                    "province": "Albacete",
                    "city": "Montalvos",
                    "multiple": "0"
                },
                {
                    "zipcode": "02639",
                    "province": "Albacete",
                    "city": "Barrax",
                    "multiple": "0"
                },
                {
                    "zipcode": "02639",
                    "province": "Albacete",
                    "city": "Roda, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "02640",
                    "province": "Albacete",
                    "city": "Almansa",
                    "multiple": "0"
                },
                {
                    "zipcode": "02650",
                    "province": "Albacete",
                    "city": "Montealegre del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02651",
                    "province": "Albacete",
                    "city": "Fuente-Álamo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02652",
                    "province": "Albacete",
                    "city": "Ontur",
                    "multiple": "0"
                },
                {
                    "zipcode": "02653",
                    "province": "Albacete",
                    "city": "Albatana",
                    "multiple": "0"
                },
                {
                    "zipcode": "02660",
                    "province": "Albacete",
                    "city": "Caudete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02690",
                    "province": "Albacete",
                    "city": "Higueruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "02690",
                    "province": "Albacete",
                    "city": "Alpera",
                    "multiple": "1"
                },
                {
                    "zipcode": "02691",
                    "province": "Albacete",
                    "city": "Bonete",
                    "multiple": "0"
                },
                {
                    "zipcode": "02692",
                    "province": "Albacete",
                    "city": "Chinchilla de Monte-Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "02692",
                    "province": "Albacete",
                    "city": "Pétrola",
                    "multiple": "1"
                },
                {
                    "zipcode": "02693",
                    "province": "Albacete",
                    "city": "Corral-Rubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "02694",
                    "province": "Albacete",
                    "city": "Higueruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "02695",
                    "province": "Albacete",
                    "city": "Chinchilla de Monte-Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "02696",
                    "province": "Albacete",
                    "city": "Hoya-Gonzalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "02999",
                    "province": "Albacete",
                    "city": "Ayna",
                    "multiple": "0"
                },
                {
                    "zipcode": "03001",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03002",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03003",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03004",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03005",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03006",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03007",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03008",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03009",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03010",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03011",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03012",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03013",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03014",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03015",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03016",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03096",
                    "province": "Alicante",
                    "city": "Onil",
                    "multiple": "0"
                },
                {
                    "zipcode": "03100",
                    "province": "Alicante",
                    "city": "Jijona/Xixona",
                    "multiple": "0"
                },
                {
                    "zipcode": "03108",
                    "province": "Alicante",
                    "city": "Torremanzanas/Torre de les Maçanes, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "03109",
                    "province": "Alicante",
                    "city": "Tibi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03110",
                    "province": "Alicante",
                    "city": "Mutxamel",
                    "multiple": "0"
                },
                {
                    "zipcode": "03111",
                    "province": "Alicante",
                    "city": "Busot",
                    "multiple": "0"
                },
                {
                    "zipcode": "03112",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03113",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03113",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "1"
                },
                {
                    "zipcode": "03114",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03115",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03130",
                    "province": "Alicante",
                    "city": "Santa Pola",
                    "multiple": "0"
                },
                {
                    "zipcode": "03138",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03139",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03140",
                    "province": "Alicante",
                    "city": "Guardamar del Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "03150",
                    "province": "Alicante",
                    "city": "Dolores",
                    "multiple": "0"
                },
                {
                    "zipcode": "03158",
                    "province": "Alicante",
                    "city": "Catral",
                    "multiple": "0"
                },
                {
                    "zipcode": "03158",
                    "province": "Alicante",
                    "city": "Crevillent",
                    "multiple": "1"
                },
                {
                    "zipcode": "03159",
                    "province": "Alicante",
                    "city": "Daya Nueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "03160",
                    "province": "Alicante",
                    "city": "Almoradí",
                    "multiple": "0"
                },
                {
                    "zipcode": "03169",
                    "province": "Alicante",
                    "city": "Algorfa",
                    "multiple": "0"
                },
                {
                    "zipcode": "03170",
                    "province": "Alicante",
                    "city": "Rojales",
                    "multiple": "0"
                },
                {
                    "zipcode": "03176",
                    "province": "Alicante",
                    "city": "Algorfa",
                    "multiple": "0"
                },
                {
                    "zipcode": "03177",
                    "province": "Alicante",
                    "city": "San Fulgencio",
                    "multiple": "0"
                },
                {
                    "zipcode": "03177",
                    "province": "Alicante",
                    "city": "Formentera del Segura",
                    "multiple": "1"
                },
                {
                    "zipcode": "03177",
                    "province": "Alicante",
                    "city": "Daya Vieja",
                    "multiple": "1"
                },
                {
                    "zipcode": "03178",
                    "province": "Alicante",
                    "city": "Benijófar",
                    "multiple": "0"
                },
                {
                    "zipcode": "03179",
                    "province": "Alicante",
                    "city": "Formentera del Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "03179",
                    "province": "Alicante",
                    "city": "Almoradí",
                    "multiple": "1"
                },
                {
                    "zipcode": "03180",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03181",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03182",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03183",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03184",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03185",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03186",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03187",
                    "province": "Alicante",
                    "city": "Montesinos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "03188",
                    "province": "Alicante",
                    "city": "Torrevieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03189",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03190",
                    "province": "Alicante",
                    "city": "Pilar de la Horadada",
                    "multiple": "0"
                },
                {
                    "zipcode": "03191",
                    "province": "Alicante",
                    "city": "Pilar de la Horadada",
                    "multiple": "0"
                },
                {
                    "zipcode": "03191",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "03192",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03193",
                    "province": "Alicante",
                    "city": "San Miguel de Salinas",
                    "multiple": "0"
                },
                {
                    "zipcode": "03194",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03195",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03201",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03202",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03203",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03204",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03205",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03206",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03207",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03208",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03290",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03291",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03292",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03293",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03294",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03295",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03296",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03300",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03310",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03310",
                    "province": "Alicante",
                    "city": "Jacarilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "03311",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03312",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03313",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03314",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03315",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03316",
                    "province": "Alicante",
                    "city": "Benferri",
                    "multiple": "0"
                },
                {
                    "zipcode": "03316",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "03317",
                    "province": "Alicante",
                    "city": "Callosa de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "03320",
                    "province": "Alicante",
                    "city": "Elche/Elx",
                    "multiple": "0"
                },
                {
                    "zipcode": "03330",
                    "province": "Alicante",
                    "city": "Crevillent",
                    "multiple": "0"
                },
                {
                    "zipcode": "03339",
                    "province": "Alicante",
                    "city": "Crevillent",
                    "multiple": "0"
                },
                {
                    "zipcode": "03340",
                    "province": "Alicante",
                    "city": "Albatera",
                    "multiple": "0"
                },
                {
                    "zipcode": "03348",
                    "province": "Alicante",
                    "city": "Granja de Rocamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "03349",
                    "province": "Alicante",
                    "city": "San Isidro",
                    "multiple": "0"
                },
                {
                    "zipcode": "03350",
                    "province": "Alicante",
                    "city": "Cox",
                    "multiple": "0"
                },
                {
                    "zipcode": "03360",
                    "province": "Alicante",
                    "city": "Callosa de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "03369",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "03369",
                    "province": "Alicante",
                    "city": "Rafal",
                    "multiple": "1"
                },
                {
                    "zipcode": "03370",
                    "province": "Alicante",
                    "city": "Redován",
                    "multiple": "0"
                },
                {
                    "zipcode": "03380",
                    "province": "Alicante",
                    "city": "Bigastro",
                    "multiple": "0"
                },
                {
                    "zipcode": "03390",
                    "province": "Alicante",
                    "city": "Benejúzar",
                    "multiple": "0"
                },
                {
                    "zipcode": "03392",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "03400",
                    "province": "Alicante",
                    "city": "Villena",
                    "multiple": "0"
                },
                {
                    "zipcode": "03408",
                    "province": "Alicante",
                    "city": "Villena",
                    "multiple": "0"
                },
                {
                    "zipcode": "03409",
                    "province": "Alicante",
                    "city": "Cañada",
                    "multiple": "0"
                },
                {
                    "zipcode": "03409",
                    "province": "Alicante",
                    "city": "Salinas",
                    "multiple": "1"
                },
                {
                    "zipcode": "03409",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "1"
                },
                {
                    "zipcode": "03409",
                    "province": "Alicante",
                    "city": "Villena",
                    "multiple": "1"
                },
                {
                    "zipcode": "03410",
                    "province": "Alicante",
                    "city": "Biar",
                    "multiple": "0"
                },
                {
                    "zipcode": "03420",
                    "province": "Alicante",
                    "city": "Castalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "03430",
                    "province": "Alicante",
                    "city": "Onil",
                    "multiple": "0"
                },
                {
                    "zipcode": "03440",
                    "province": "Alicante",
                    "city": "Ibi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03450",
                    "province": "Alicante",
                    "city": "Banyeres de Mariola",
                    "multiple": "0"
                },
                {
                    "zipcode": "03460",
                    "province": "Alicante",
                    "city": "Beneixama",
                    "multiple": "0"
                },
                {
                    "zipcode": "03469",
                    "province": "Alicante",
                    "city": "Campo de Mirra/Camp de Mirra, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "03469",
                    "province": "Alicante",
                    "city": "Beneixama",
                    "multiple": "1"
                },
                {
                    "zipcode": "03501",
                    "province": "Alicante",
                    "city": "Benidorm",
                    "multiple": "0"
                },
                {
                    "zipcode": "03502",
                    "province": "Alicante",
                    "city": "Benidorm",
                    "multiple": "0"
                },
                {
                    "zipcode": "03503",
                    "province": "Alicante",
                    "city": "Benidorm",
                    "multiple": "0"
                },
                {
                    "zipcode": "03509",
                    "province": "Alicante",
                    "city": "Finestrat",
                    "multiple": "0"
                },
                {
                    "zipcode": "03510",
                    "province": "Alicante",
                    "city": "Callosa d'en Sarrià",
                    "multiple": "0"
                },
                {
                    "zipcode": "03516",
                    "province": "Alicante",
                    "city": "Benimantell",
                    "multiple": "0"
                },
                {
                    "zipcode": "03517",
                    "province": "Alicante",
                    "city": "Confrides",
                    "multiple": "0"
                },
                {
                    "zipcode": "03517",
                    "province": "Alicante",
                    "city": "Beniardá",
                    "multiple": "1"
                },
                {
                    "zipcode": "03517",
                    "province": "Alicante",
                    "city": "Castell de Guadalest, el",
                    "multiple": "1"
                },
                {
                    "zipcode": "03517",
                    "province": "Alicante",
                    "city": "Benifato",
                    "multiple": "1"
                },
                {
                    "zipcode": "03518",
                    "province": "Alicante",
                    "city": "Bolulla",
                    "multiple": "0"
                },
                {
                    "zipcode": "03518",
                    "province": "Alicante",
                    "city": "Tàrbena",
                    "multiple": "1"
                },
                {
                    "zipcode": "03520",
                    "province": "Alicante",
                    "city": "Polop",
                    "multiple": "0"
                },
                {
                    "zipcode": "03530",
                    "province": "Alicante",
                    "city": "Nucia, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "03540",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03550",
                    "province": "Alicante",
                    "city": "Sant Joan d'Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03559",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03560",
                    "province": "Alicante",
                    "city": "Campello, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "03569",
                    "province": "Alicante",
                    "city": "Aigües",
                    "multiple": "0"
                },
                {
                    "zipcode": "03570",
                    "province": "Alicante",
                    "city": "Villajoyosa/Vila Joiosa, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "03578",
                    "province": "Alicante",
                    "city": "Relleu",
                    "multiple": "0"
                },
                {
                    "zipcode": "03579",
                    "province": "Alicante",
                    "city": "Orxeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "03579",
                    "province": "Alicante",
                    "city": "Sella",
                    "multiple": "1"
                },
                {
                    "zipcode": "03580",
                    "province": "Alicante",
                    "city": "Alfàs del Pi, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "03581",
                    "province": "Alicante",
                    "city": "Alfàs del Pi, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "03590",
                    "province": "Alicante",
                    "city": "Altea",
                    "multiple": "0"
                },
                {
                    "zipcode": "03599",
                    "province": "Alicante",
                    "city": "Altea",
                    "multiple": "0"
                },
                {
                    "zipcode": "03600",
                    "province": "Alicante",
                    "city": "Elda",
                    "multiple": "0"
                },
                {
                    "zipcode": "03610",
                    "province": "Alicante",
                    "city": "Petrer",
                    "multiple": "0"
                },
                {
                    "zipcode": "03630",
                    "province": "Alicante",
                    "city": "Sax",
                    "multiple": "0"
                },
                {
                    "zipcode": "03638",
                    "province": "Alicante",
                    "city": "Salinas",
                    "multiple": "0"
                },
                {
                    "zipcode": "03639",
                    "province": "Alicante",
                    "city": "Villena",
                    "multiple": "0"
                },
                {
                    "zipcode": "03640",
                    "province": "Alicante",
                    "city": "Monóvar/Monòver",
                    "multiple": "0"
                },
                {
                    "zipcode": "03649",
                    "province": "Alicante",
                    "city": "Monóvar/Monòver",
                    "multiple": "0"
                },
                {
                    "zipcode": "03650",
                    "province": "Alicante",
                    "city": "Pinós, el/Pinoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "03657",
                    "province": "Alicante",
                    "city": "Yecla",
                    "multiple": "0"
                },
                {
                    "zipcode": "03657",
                    "province": "Alicante",
                    "city": "Pinós, el/Pinoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "03658",
                    "province": "Alicante",
                    "city": "Salinas",
                    "multiple": "0"
                },
                {
                    "zipcode": "03658",
                    "province": "Alicante",
                    "city": "Pinós, el/Pinoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "03659",
                    "province": "Alicante",
                    "city": "Pinós, el/Pinoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "03660",
                    "province": "Alicante",
                    "city": "Oliva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "03660",
                    "province": "Alicante",
                    "city": "Novelda",
                    "multiple": "1"
                },
                {
                    "zipcode": "03668",
                    "province": "Alicante",
                    "city": "Algueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "03669",
                    "province": "Alicante",
                    "city": "Romana, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "03670",
                    "province": "Alicante",
                    "city": "Monforte del Cid",
                    "multiple": "0"
                },
                {
                    "zipcode": "03679",
                    "province": "Alicante",
                    "city": "Monforte del Cid",
                    "multiple": "0"
                },
                {
                    "zipcode": "03680",
                    "province": "Alicante",
                    "city": "Aspe",
                    "multiple": "0"
                },
                {
                    "zipcode": "03688",
                    "province": "Alicante",
                    "city": "Fondó de les Neus, el/Hondón de las Nieves",
                    "multiple": "0"
                },
                {
                    "zipcode": "03689",
                    "province": "Alicante",
                    "city": "Hondón de los Frailes",
                    "multiple": "0"
                },
                {
                    "zipcode": "03689",
                    "province": "Alicante",
                    "city": "Fondó de les Neus, el/Hondón de las Nieves",
                    "multiple": "1"
                },
                {
                    "zipcode": "03689",
                    "province": "Alicante",
                    "city": "Orihuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "03690",
                    "province": "Alicante",
                    "city": "San Vicente del Raspeig/Sant Vicent del Raspeig",
                    "multiple": "0"
                },
                {
                    "zipcode": "03698",
                    "province": "Alicante",
                    "city": "Agost",
                    "multiple": "0"
                },
                {
                    "zipcode": "03699",
                    "province": "Alicante",
                    "city": "Alicante/Alacant",
                    "multiple": "0"
                },
                {
                    "zipcode": "03700",
                    "province": "Alicante",
                    "city": "Dénia",
                    "multiple": "0"
                },
                {
                    "zipcode": "03709",
                    "province": "Alicante",
                    "city": "Dénia",
                    "multiple": "0"
                },
                {
                    "zipcode": "03710",
                    "province": "Alicante",
                    "city": "Calp",
                    "multiple": "0"
                },
                {
                    "zipcode": "03720",
                    "province": "Alicante",
                    "city": "Benissa",
                    "multiple": "0"
                },
                {
                    "zipcode": "03723",
                    "province": "Alicante",
                    "city": "Alcalalí",
                    "multiple": "0"
                },
                {
                    "zipcode": "03724",
                    "province": "Alicante",
                    "city": "Teulada",
                    "multiple": "0"
                },
                {
                    "zipcode": "03725",
                    "province": "Alicante",
                    "city": "Teulada",
                    "multiple": "0"
                },
                {
                    "zipcode": "03726",
                    "province": "Alicante",
                    "city": "Benitachell/Poble Nou de Benitatxell, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "03727",
                    "province": "Alicante",
                    "city": "Xaló",
                    "multiple": "0"
                },
                {
                    "zipcode": "03728",
                    "province": "Alicante",
                    "city": "Alcalalí",
                    "multiple": "0"
                },
                {
                    "zipcode": "03729",
                    "province": "Alicante",
                    "city": "Senija",
                    "multiple": "0"
                },
                {
                    "zipcode": "03729",
                    "province": "Alicante",
                    "city": "Llíber",
                    "multiple": "1"
                },
                {
                    "zipcode": "03730",
                    "province": "Alicante",
                    "city": "Jávea/Xàbia",
                    "multiple": "0"
                },
                {
                    "zipcode": "03737",
                    "province": "Alicante",
                    "city": "Jávea/Xàbia",
                    "multiple": "0"
                },
                {
                    "zipcode": "03738",
                    "province": "Alicante",
                    "city": "Jávea/Xàbia",
                    "multiple": "0"
                },
                {
                    "zipcode": "03739",
                    "province": "Alicante",
                    "city": "Jávea/Xàbia",
                    "multiple": "0"
                },
                {
                    "zipcode": "03740",
                    "province": "Alicante",
                    "city": "Gata de Gorgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "03749",
                    "province": "Alicante",
                    "city": "Dénia",
                    "multiple": "0"
                },
                {
                    "zipcode": "03750",
                    "province": "Alicante",
                    "city": "Pedreguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "03759",
                    "province": "Alicante",
                    "city": "Benidoleig",
                    "multiple": "0"
                },
                {
                    "zipcode": "03760",
                    "province": "Alicante",
                    "city": "Ondara",
                    "multiple": "0"
                },
                {
                    "zipcode": "03769",
                    "province": "Alicante",
                    "city": "Benimeli",
                    "multiple": "0"
                },
                {
                    "zipcode": "03769",
                    "province": "Alicante",
                    "city": "Ràfol d'Almúnia, el",
                    "multiple": "1"
                },
                {
                    "zipcode": "03769",
                    "province": "Alicante",
                    "city": "Sanet y Negrals",
                    "multiple": "1"
                },
                {
                    "zipcode": "03770",
                    "province": "Alicante",
                    "city": "Verger, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "03770",
                    "province": "Alicante",
                    "city": "Dénia",
                    "multiple": "1"
                },
                {
                    "zipcode": "03778",
                    "province": "Alicante",
                    "city": "Beniarbeig",
                    "multiple": "0"
                },
                {
                    "zipcode": "03779",
                    "province": "Alicante",
                    "city": "Poblets, els",
                    "multiple": "0"
                },
                {
                    "zipcode": "03779",
                    "province": "Alicante",
                    "city": "Dénia",
                    "multiple": "1"
                },
                {
                    "zipcode": "03780",
                    "province": "Alicante",
                    "city": "Ràfol d'Almúnia, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "03780",
                    "province": "Alicante",
                    "city": "Dénia",
                    "multiple": "1"
                },
                {
                    "zipcode": "03780",
                    "province": "Alicante",
                    "city": "Pego",
                    "multiple": "1"
                },
                {
                    "zipcode": "03786",
                    "province": "Alicante",
                    "city": "Atzúbia, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "03787",
                    "province": "Alicante",
                    "city": "Vall de Gallinera",
                    "multiple": "0"
                },
                {
                    "zipcode": "03788",
                    "province": "Alicante",
                    "city": "Vall de Gallinera",
                    "multiple": "0"
                },
                {
                    "zipcode": "03788",
                    "province": "Alicante",
                    "city": "Vall d'Alcalà, la",
                    "multiple": "1"
                },
                {
                    "zipcode": "03789",
                    "province": "Alicante",
                    "city": "Vall d'Ebo, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "03790",
                    "province": "Alicante",
                    "city": "Orba",
                    "multiple": "0"
                },
                {
                    "zipcode": "03791",
                    "province": "Alicante",
                    "city": "Orba",
                    "multiple": "0"
                },
                {
                    "zipcode": "03791",
                    "province": "Alicante",
                    "city": "Vall de Laguar, la",
                    "multiple": "1"
                },
                {
                    "zipcode": "03792",
                    "province": "Alicante",
                    "city": "Orba",
                    "multiple": "0"
                },
                {
                    "zipcode": "03792",
                    "province": "Alicante",
                    "city": "Murla",
                    "multiple": "1"
                },
                {
                    "zipcode": "03792",
                    "province": "Alicante",
                    "city": "Parcent",
                    "multiple": "1"
                },
                {
                    "zipcode": "03793",
                    "province": "Alicante",
                    "city": "Castell de Castells",
                    "multiple": "0"
                },
                {
                    "zipcode": "03794",
                    "province": "Alicante",
                    "city": "Benigembla",
                    "multiple": "0"
                },
                {
                    "zipcode": "03795",
                    "province": "Alicante",
                    "city": "Orba",
                    "multiple": "0"
                },
                {
                    "zipcode": "03795",
                    "province": "Alicante",
                    "city": "Sagra",
                    "multiple": "1"
                },
                {
                    "zipcode": "03795",
                    "province": "Alicante",
                    "city": "Tormos",
                    "multiple": "1"
                },
                {
                    "zipcode": "03800",
                    "province": "Alicante",
                    "city": "Alcoy/Alcoi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03801",
                    "province": "Alicante",
                    "city": "Alcoy/Alcoi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03802",
                    "province": "Alicante",
                    "city": "Alcoy/Alcoi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03803",
                    "province": "Alicante",
                    "city": "Alcoy/Alcoi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03804",
                    "province": "Alicante",
                    "city": "Alcoy/Alcoi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03810",
                    "province": "Alicante",
                    "city": "Benilloba",
                    "multiple": "0"
                },
                {
                    "zipcode": "03811",
                    "province": "Alicante",
                    "city": "Quatretondeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "03811",
                    "province": "Alicante",
                    "city": "Gorga",
                    "multiple": "1"
                },
                {
                    "zipcode": "03812",
                    "province": "Alicante",
                    "city": "Benimassot",
                    "multiple": "0"
                },
                {
                    "zipcode": "03812",
                    "province": "Alicante",
                    "city": "Balones",
                    "multiple": "1"
                },
                {
                    "zipcode": "03812",
                    "province": "Alicante",
                    "city": "Millena",
                    "multiple": "1"
                },
                {
                    "zipcode": "03813",
                    "province": "Alicante",
                    "city": "Tollos",
                    "multiple": "0"
                },
                {
                    "zipcode": "03813",
                    "province": "Alicante",
                    "city": "Famorca",
                    "multiple": "1"
                },
                {
                    "zipcode": "03813",
                    "province": "Alicante",
                    "city": "Facheca",
                    "multiple": "1"
                },
                {
                    "zipcode": "03814",
                    "province": "Alicante",
                    "city": "Alcoleja",
                    "multiple": "0"
                },
                {
                    "zipcode": "03814",
                    "province": "Alicante",
                    "city": "Benasau",
                    "multiple": "1"
                },
                {
                    "zipcode": "03815",
                    "province": "Alicante",
                    "city": "Penàguila",
                    "multiple": "0"
                },
                {
                    "zipcode": "03816",
                    "province": "Alicante",
                    "city": "Benifallim",
                    "multiple": "0"
                },
                {
                    "zipcode": "03818",
                    "province": "Alicante",
                    "city": "Alcoy/Alcoi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03819",
                    "province": "Alicante",
                    "city": "Alcoy/Alcoi",
                    "multiple": "0"
                },
                {
                    "zipcode": "03820",
                    "province": "Alicante",
                    "city": "Cocentaina",
                    "multiple": "0"
                },
                {
                    "zipcode": "03827",
                    "province": "Alicante",
                    "city": "Benimarfull",
                    "multiple": "0"
                },
                {
                    "zipcode": "03827",
                    "province": "Alicante",
                    "city": "Almudaina",
                    "multiple": "1"
                },
                {
                    "zipcode": "03827",
                    "province": "Alicante",
                    "city": "Benillup",
                    "multiple": "1"
                },
                {
                    "zipcode": "03828",
                    "province": "Alicante",
                    "city": "Planes",
                    "multiple": "0"
                },
                {
                    "zipcode": "03829",
                    "province": "Alicante",
                    "city": "Alqueria d'Asnar, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "03829",
                    "province": "Alicante",
                    "city": "Cocentaina",
                    "multiple": "1"
                },
                {
                    "zipcode": "03830",
                    "province": "Alicante",
                    "city": "Muro de Alcoy",
                    "multiple": "0"
                },
                {
                    "zipcode": "03837",
                    "province": "Alicante",
                    "city": "Agres",
                    "multiple": "0"
                },
                {
                    "zipcode": "03838",
                    "province": "Alicante",
                    "city": "Alfafara",
                    "multiple": "0"
                },
                {
                    "zipcode": "03839",
                    "province": "Alicante",
                    "city": "Muro de Alcoy",
                    "multiple": "0"
                },
                {
                    "zipcode": "03840",
                    "province": "Alicante",
                    "city": "Gaianes",
                    "multiple": "0"
                },
                {
                    "zipcode": "03841",
                    "province": "Alicante",
                    "city": "Alcocer de Planes",
                    "multiple": "0"
                },
                {
                    "zipcode": "03850",
                    "province": "Alicante",
                    "city": "Beniarrés",
                    "multiple": "0"
                },
                {
                    "zipcode": "03860",
                    "province": "Alicante",
                    "city": "Lorcha/Orxa, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "04001",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04002",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04003",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04004",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04005",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04006",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04007",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04008",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04009",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04010",
                    "province": "Almería",
                    "city": "Santa Marta del Cerro",
                    "multiple": "0"
                },
                {
                    "zipcode": "04100",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04110",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04113",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04114",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04114",
                    "province": "Almería",
                    "city": "Lucainena de las Torres",
                    "multiple": "1"
                },
                {
                    "zipcode": "04115",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04116",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04117",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04118",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04119",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04120",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04130",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04131",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04140",
                    "province": "Almería",
                    "city": "Carboneras",
                    "multiple": "0"
                },
                {
                    "zipcode": "04147",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04149",
                    "province": "Almería",
                    "city": "Carboneras",
                    "multiple": "0"
                },
                {
                    "zipcode": "04149",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "1"
                },
                {
                    "zipcode": "04149",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "04150",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04151",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04160",
                    "province": "Almería",
                    "city": "Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04188",
                    "province": "Almería",
                    "city": "Níjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04200",
                    "province": "Almería",
                    "city": "Tabernas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04210",
                    "province": "Almería",
                    "city": "Lucainena de las Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "04211",
                    "province": "Almería",
                    "city": "Tabernas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04211",
                    "province": "Almería",
                    "city": "Lucainena de las Torres",
                    "multiple": "1"
                },
                {
                    "zipcode": "04211",
                    "province": "Almería",
                    "city": "Turrillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "04212",
                    "province": "Almería",
                    "city": "Olula de Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "04212",
                    "province": "Almería",
                    "city": "Velefique",
                    "multiple": "1"
                },
                {
                    "zipcode": "04212",
                    "province": "Almería",
                    "city": "Castro de Filabres",
                    "multiple": "1"
                },
                {
                    "zipcode": "04213",
                    "province": "Almería",
                    "city": "Senés",
                    "multiple": "0"
                },
                {
                    "zipcode": "04230",
                    "province": "Almería",
                    "city": "Huércal de Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04240",
                    "province": "Almería",
                    "city": "Viator",
                    "multiple": "0"
                },
                {
                    "zipcode": "04248",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04250",
                    "province": "Almería",
                    "city": "Pechina",
                    "multiple": "0"
                },
                {
                    "zipcode": "04259",
                    "province": "Almería",
                    "city": "Pechina",
                    "multiple": "0"
                },
                {
                    "zipcode": "04260",
                    "province": "Almería",
                    "city": "Rioja",
                    "multiple": "0"
                },
                {
                    "zipcode": "04270",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04271",
                    "province": "Almería",
                    "city": "Lubrín",
                    "multiple": "0"
                },
                {
                    "zipcode": "04271",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "1"
                },
                {
                    "zipcode": "04274",
                    "province": "Almería",
                    "city": "Benizalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "04274",
                    "province": "Almería",
                    "city": "Uleila del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "04275",
                    "province": "Almería",
                    "city": "Tíjola",
                    "multiple": "0"
                },
                {
                    "zipcode": "04275",
                    "province": "Almería",
                    "city": "Tahal",
                    "multiple": "1"
                },
                {
                    "zipcode": "04276",
                    "province": "Almería",
                    "city": "Benizalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "04276",
                    "province": "Almería",
                    "city": "Alcudia de Monteagud",
                    "multiple": "1"
                },
                {
                    "zipcode": "04276",
                    "province": "Almería",
                    "city": "Benitagla",
                    "multiple": "1"
                },
                {
                    "zipcode": "04277",
                    "province": "Almería",
                    "city": "Turre",
                    "multiple": "0"
                },
                {
                    "zipcode": "04277",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "1"
                },
                {
                    "zipcode": "04278",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04279",
                    "province": "Almería",
                    "city": "Tahal",
                    "multiple": "0"
                },
                {
                    "zipcode": "04279",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "1"
                },
                {
                    "zipcode": "04279",
                    "province": "Almería",
                    "city": "Uleila del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "04280",
                    "province": "Almería",
                    "city": "Gallardos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "04288",
                    "province": "Almería",
                    "city": "Gallardos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "04288",
                    "province": "Almería",
                    "city": "Bédar",
                    "multiple": "1"
                },
                {
                    "zipcode": "04289",
                    "province": "Almería",
                    "city": "Sorbas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04289",
                    "province": "Almería",
                    "city": "Bédar",
                    "multiple": "1"
                },
                {
                    "zipcode": "04289",
                    "province": "Almería",
                    "city": "Gallardos, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "04400",
                    "province": "Almería",
                    "city": "Alhama de Almería",
                    "multiple": "0"
                },
                {
                    "zipcode": "04409",
                    "province": "Almería",
                    "city": "Alicún",
                    "multiple": "0"
                },
                {
                    "zipcode": "04409",
                    "province": "Almería",
                    "city": "Huécija",
                    "multiple": "1"
                },
                {
                    "zipcode": "04410",
                    "province": "Almería",
                    "city": "Benahadux",
                    "multiple": "0"
                },
                {
                    "zipcode": "04420",
                    "province": "Almería",
                    "city": "Santa Fe de Mondújar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04430",
                    "province": "Almería",
                    "city": "Instinción",
                    "multiple": "0"
                },
                {
                    "zipcode": "04431",
                    "province": "Almería",
                    "city": "Illar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04440",
                    "province": "Almería",
                    "city": "Rágol",
                    "multiple": "0"
                },
                {
                    "zipcode": "04450",
                    "province": "Almería",
                    "city": "Canjáyar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04450",
                    "province": "Almería",
                    "city": "Gérgal",
                    "multiple": "1"
                },
                {
                    "zipcode": "04458",
                    "province": "Almería",
                    "city": "Padules",
                    "multiple": "0"
                },
                {
                    "zipcode": "04458",
                    "province": "Almería",
                    "city": "Almócita",
                    "multiple": "1"
                },
                {
                    "zipcode": "04458",
                    "province": "Almería",
                    "city": "Beires",
                    "multiple": "1"
                },
                {
                    "zipcode": "04459",
                    "province": "Almería",
                    "city": "Ohanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "04460",
                    "province": "Almería",
                    "city": "Fondón",
                    "multiple": "0"
                },
                {
                    "zipcode": "04470",
                    "province": "Almería",
                    "city": "Láujar de Andarax",
                    "multiple": "0"
                },
                {
                    "zipcode": "04479",
                    "province": "Almería",
                    "city": "Bayárcal",
                    "multiple": "0"
                },
                {
                    "zipcode": "04479",
                    "province": "Almería",
                    "city": "Fondón",
                    "multiple": "1"
                },
                {
                    "zipcode": "04479",
                    "province": "Almería",
                    "city": "Paterna del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "04480",
                    "province": "Almería",
                    "city": "Alcolea",
                    "multiple": "0"
                },
                {
                    "zipcode": "04500",
                    "province": "Almería",
                    "city": "Fiñana",
                    "multiple": "0"
                },
                {
                    "zipcode": "04510",
                    "province": "Almería",
                    "city": "Abla",
                    "multiple": "0"
                },
                {
                    "zipcode": "04520",
                    "province": "Almería",
                    "city": "Abrucena",
                    "multiple": "0"
                },
                {
                    "zipcode": "04530",
                    "province": "Almería",
                    "city": "Tres Villas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "04531",
                    "province": "Almería",
                    "city": "Alboloduy",
                    "multiple": "0"
                },
                {
                    "zipcode": "04532",
                    "province": "Almería",
                    "city": "Tres Villas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "04533",
                    "province": "Almería",
                    "city": "Abrucena",
                    "multiple": "0"
                },
                {
                    "zipcode": "04540",
                    "province": "Almería",
                    "city": "Nacimiento",
                    "multiple": "0"
                },
                {
                    "zipcode": "04549",
                    "province": "Almería",
                    "city": "Gérgal",
                    "multiple": "0"
                },
                {
                    "zipcode": "04550",
                    "province": "Almería",
                    "city": "Gérgal",
                    "multiple": "0"
                },
                {
                    "zipcode": "04558",
                    "province": "Almería",
                    "city": "Gérgal",
                    "multiple": "0"
                },
                {
                    "zipcode": "04558",
                    "province": "Almería",
                    "city": "Alboloduy",
                    "multiple": "1"
                },
                {
                    "zipcode": "04559",
                    "province": "Almería",
                    "city": "Gérgal",
                    "multiple": "0"
                },
                {
                    "zipcode": "04560",
                    "province": "Almería",
                    "city": "Gádor",
                    "multiple": "0"
                },
                {
                    "zipcode": "04567",
                    "province": "Almería",
                    "city": "Alhabia",
                    "multiple": "0"
                },
                {
                    "zipcode": "04568",
                    "province": "Almería",
                    "city": "Santa Cruz de Marchena",
                    "multiple": "0"
                },
                {
                    "zipcode": "04568",
                    "province": "Almería",
                    "city": "Alsodux",
                    "multiple": "1"
                },
                {
                    "zipcode": "04569",
                    "province": "Almería",
                    "city": "Bentarique",
                    "multiple": "0"
                },
                {
                    "zipcode": "04569",
                    "province": "Almería",
                    "city": "Terque",
                    "multiple": "1"
                },
                {
                    "zipcode": "04600",
                    "province": "Almería",
                    "city": "Albox",
                    "multiple": "0"
                },
                {
                    "zipcode": "04600",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "1"
                },
                {
                    "zipcode": "04610",
                    "province": "Almería",
                    "city": "Cuevas del Almanzora",
                    "multiple": "0"
                },
                {
                    "zipcode": "04616",
                    "province": "Almería",
                    "city": "Cuevas del Almanzora",
                    "multiple": "0"
                },
                {
                    "zipcode": "04617",
                    "province": "Almería",
                    "city": "Cuevas del Almanzora",
                    "multiple": "0"
                },
                {
                    "zipcode": "04618",
                    "province": "Almería",
                    "city": "Cuevas del Almanzora",
                    "multiple": "0"
                },
                {
                    "zipcode": "04619",
                    "province": "Almería",
                    "city": "Cuevas del Almanzora",
                    "multiple": "0"
                },
                {
                    "zipcode": "04620",
                    "province": "Almería",
                    "city": "Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "04628",
                    "province": "Almería",
                    "city": "Antas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04629",
                    "province": "Almería",
                    "city": "Antas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04630",
                    "province": "Almería",
                    "city": "Garrucha",
                    "multiple": "0"
                },
                {
                    "zipcode": "04638",
                    "province": "Almería",
                    "city": "Mojácar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04639",
                    "province": "Almería",
                    "city": "Mojácar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04639",
                    "province": "Almería",
                    "city": "Turre",
                    "multiple": "1"
                },
                {
                    "zipcode": "04640",
                    "province": "Almería",
                    "city": "Oria",
                    "multiple": "0"
                },
                {
                    "zipcode": "04640",
                    "province": "Almería",
                    "city": "Pulpí",
                    "multiple": "1"
                },
                {
                    "zipcode": "04647",
                    "province": "Almería",
                    "city": "Pulpí",
                    "multiple": "0"
                },
                {
                    "zipcode": "04647",
                    "province": "Almería",
                    "city": "Cuevas del Almanzora",
                    "multiple": "1"
                },
                {
                    "zipcode": "04648",
                    "province": "Almería",
                    "city": "Cuevas del Almanzora",
                    "multiple": "0"
                },
                {
                    "zipcode": "04648",
                    "province": "Almería",
                    "city": "Pulpí",
                    "multiple": "1"
                },
                {
                    "zipcode": "04649",
                    "province": "Almería",
                    "city": "Pulpí",
                    "multiple": "0"
                },
                {
                    "zipcode": "04650",
                    "province": "Almería",
                    "city": "Zurgena",
                    "multiple": "0"
                },
                {
                    "zipcode": "04659",
                    "province": "Almería",
                    "city": "Terque",
                    "multiple": "0"
                },
                {
                    "zipcode": "04660",
                    "province": "Almería",
                    "city": "Arboleas",
                    "multiple": "0"
                },
                {
                    "zipcode": "04661",
                    "province": "Almería",
                    "city": "Zurgena",
                    "multiple": "0"
                },
                {
                    "zipcode": "04661",
                    "province": "Almería",
                    "city": "Taberno",
                    "multiple": "1"
                },
                {
                    "zipcode": "04662",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "0"
                },
                {
                    "zipcode": "04662",
                    "province": "Almería",
                    "city": "Zurgena",
                    "multiple": "1"
                },
                {
                    "zipcode": "04690",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "0"
                },
                {
                    "zipcode": "04691",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "0"
                },
                {
                    "zipcode": "04692",
                    "province": "Almería",
                    "city": "Taberno",
                    "multiple": "0"
                },
                {
                    "zipcode": "04692",
                    "province": "Almería",
                    "city": "Albox",
                    "multiple": "1"
                },
                {
                    "zipcode": "04692",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "1"
                },
                {
                    "zipcode": "04693",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "0"
                },
                {
                    "zipcode": "04694",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "0"
                },
                {
                    "zipcode": "04700",
                    "province": "Almería",
                    "city": "Ejido, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "04710",
                    "province": "Almería",
                    "city": "Ejido, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "04711",
                    "province": "Almería",
                    "city": "Ejido, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "04712",
                    "province": "Almería",
                    "city": "Ejido, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "04713",
                    "province": "Almería",
                    "city": "Balanegra",
                    "multiple": "0"
                },
                {
                    "zipcode": "04715",
                    "province": "Almería",
                    "city": "Ejido, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "04716",
                    "province": "Almería",
                    "city": "Ejido, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "04720",
                    "province": "Almería",
                    "city": "Roquetas de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04721",
                    "province": "Almería",
                    "city": "Vícar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04727",
                    "province": "Almería",
                    "city": "Vícar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04728",
                    "province": "Almería",
                    "city": "Felix",
                    "multiple": "0"
                },
                {
                    "zipcode": "04729",
                    "province": "Almería",
                    "city": "Enix",
                    "multiple": "0"
                },
                {
                    "zipcode": "04738",
                    "province": "Almería",
                    "city": "Vícar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04740",
                    "province": "Almería",
                    "city": "Roquetas de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04741",
                    "province": "Almería",
                    "city": "Roquetas de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04743",
                    "province": "Almería",
                    "city": "Roquetas de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04745",
                    "province": "Almería",
                    "city": "Mojonera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "04746",
                    "province": "Almería",
                    "city": "Mojonera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "04750",
                    "province": "Almería",
                    "city": "Dalías",
                    "multiple": "0"
                },
                {
                    "zipcode": "04760",
                    "province": "Almería",
                    "city": "Berja",
                    "multiple": "0"
                },
                {
                    "zipcode": "04768",
                    "province": "Almería",
                    "city": "Berja",
                    "multiple": "0"
                },
                {
                    "zipcode": "04768",
                    "province": "Almería",
                    "city": "Alcolea",
                    "multiple": "1"
                },
                {
                    "zipcode": "04769",
                    "province": "Almería",
                    "city": "Berja",
                    "multiple": "0"
                },
                {
                    "zipcode": "04770",
                    "province": "Almería",
                    "city": "Adra",
                    "multiple": "0"
                },
                {
                    "zipcode": "04778",
                    "province": "Almería",
                    "city": "Adra",
                    "multiple": "0"
                },
                {
                    "zipcode": "04779",
                    "province": "Almería",
                    "city": "Paterna del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "04779",
                    "province": "Almería",
                    "city": "Bayárcal",
                    "multiple": "1"
                },
                {
                    "zipcode": "04779",
                    "province": "Almería",
                    "city": "Adra",
                    "multiple": "1"
                },
                {
                    "zipcode": "04800",
                    "province": "Almería",
                    "city": "Albox",
                    "multiple": "0"
                },
                {
                    "zipcode": "04810",
                    "province": "Almería",
                    "city": "Oria",
                    "multiple": "0"
                },
                {
                    "zipcode": "04810",
                    "province": "Almería",
                    "city": "Partaloa",
                    "multiple": "1"
                },
                {
                    "zipcode": "04811",
                    "province": "Almería",
                    "city": "Oria",
                    "multiple": "0"
                },
                {
                    "zipcode": "04812",
                    "province": "Almería",
                    "city": "Albox",
                    "multiple": "0"
                },
                {
                    "zipcode": "04813",
                    "province": "Almería",
                    "city": "Oria",
                    "multiple": "0"
                },
                {
                    "zipcode": "04813",
                    "province": "Almería",
                    "city": "Albox",
                    "multiple": "1"
                },
                {
                    "zipcode": "04814",
                    "province": "Almería",
                    "city": "Albox",
                    "multiple": "0"
                },
                {
                    "zipcode": "04815",
                    "province": "Almería",
                    "city": "Cantoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "04820",
                    "province": "Almería",
                    "city": "Vélez-Rubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "04825",
                    "province": "Almería",
                    "city": "Chirivel",
                    "multiple": "0"
                },
                {
                    "zipcode": "04826",
                    "province": "Almería",
                    "city": "Vélez-Rubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "04827",
                    "province": "Almería",
                    "city": "Vélez-Rubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "04828",
                    "province": "Almería",
                    "city": "Vélez-Rubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "04829",
                    "province": "Almería",
                    "city": "Vélez-Rubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "04829",
                    "province": "Almería",
                    "city": "Vélez-Blanco",
                    "multiple": "1"
                },
                {
                    "zipcode": "04830",
                    "province": "Almería",
                    "city": "Vélez-Blanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "04838",
                    "province": "Almería",
                    "city": "María",
                    "multiple": "0"
                },
                {
                    "zipcode": "04839",
                    "province": "Almería",
                    "city": "María",
                    "multiple": "0"
                },
                {
                    "zipcode": "04839",
                    "province": "Almería",
                    "city": "Vélez-Blanco",
                    "multiple": "1"
                },
                {
                    "zipcode": "04850",
                    "province": "Almería",
                    "city": "Cantoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "04857",
                    "province": "Almería",
                    "city": "Albánchez",
                    "multiple": "0"
                },
                {
                    "zipcode": "04858",
                    "province": "Almería",
                    "city": "Albánchez",
                    "multiple": "0"
                },
                {
                    "zipcode": "04858",
                    "province": "Almería",
                    "city": "Cantoria",
                    "multiple": "1"
                },
                {
                    "zipcode": "04859",
                    "province": "Almería",
                    "city": "Cóbdar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04859",
                    "province": "Almería",
                    "city": "Líjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "04859",
                    "province": "Almería",
                    "city": "Chercos",
                    "multiple": "1"
                },
                {
                    "zipcode": "04860",
                    "province": "Almería",
                    "city": "Olula del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "04867",
                    "province": "Almería",
                    "city": "Macael",
                    "multiple": "0"
                },
                {
                    "zipcode": "04868",
                    "province": "Almería",
                    "city": "Laroya",
                    "multiple": "0"
                },
                {
                    "zipcode": "04869",
                    "province": "Almería",
                    "city": "Fines",
                    "multiple": "0"
                },
                {
                    "zipcode": "04870",
                    "province": "Almería",
                    "city": "Purchena",
                    "multiple": "0"
                },
                {
                    "zipcode": "04877",
                    "province": "Almería",
                    "city": "Lúcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04877",
                    "province": "Almería",
                    "city": "Somontín",
                    "multiple": "1"
                },
                {
                    "zipcode": "04878",
                    "province": "Almería",
                    "city": "Sierro",
                    "multiple": "0"
                },
                {
                    "zipcode": "04878",
                    "province": "Almería",
                    "city": "Suflí",
                    "multiple": "1"
                },
                {
                    "zipcode": "04879",
                    "province": "Almería",
                    "city": "Urrácal",
                    "multiple": "0"
                },
                {
                    "zipcode": "04880",
                    "province": "Almería",
                    "city": "Tíjola",
                    "multiple": "0"
                },
                {
                    "zipcode": "04887",
                    "province": "Almería",
                    "city": "Tíjola",
                    "multiple": "0"
                },
                {
                    "zipcode": "04887",
                    "province": "Almería",
                    "city": "Lúcar",
                    "multiple": "1"
                },
                {
                    "zipcode": "04888",
                    "province": "Almería",
                    "city": "Bayarque",
                    "multiple": "0"
                },
                {
                    "zipcode": "04888",
                    "province": "Almería",
                    "city": "Armuña de Almanzora",
                    "multiple": "1"
                },
                {
                    "zipcode": "04889",
                    "province": "Almería",
                    "city": "Bacares",
                    "multiple": "0"
                },
                {
                    "zipcode": "04889",
                    "province": "Almería",
                    "city": "Tíjola",
                    "multiple": "1"
                },
                {
                    "zipcode": "04890",
                    "province": "Almería",
                    "city": "Serón",
                    "multiple": "0"
                },
                {
                    "zipcode": "04897",
                    "province": "Almería",
                    "city": "Serón",
                    "multiple": "0"
                },
                {
                    "zipcode": "04897",
                    "province": "Almería",
                    "city": "Alcóntar",
                    "multiple": "1"
                },
                {
                    "zipcode": "04898",
                    "province": "Almería",
                    "city": "Alcóntar",
                    "multiple": "0"
                },
                {
                    "zipcode": "04898",
                    "province": "Almería",
                    "city": "Serón",
                    "multiple": "1"
                },
                {
                    "zipcode": "04899",
                    "province": "Almería",
                    "city": "Oria",
                    "multiple": "0"
                },
                {
                    "zipcode": "04899",
                    "province": "Almería",
                    "city": "Serón",
                    "multiple": "1"
                },
                {
                    "zipcode": "04964",
                    "province": "Almería",
                    "city": "Huércal-Overa",
                    "multiple": "0"
                },
                {
                    "zipcode": "05001",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05002",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05003",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05004",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05005",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05100",
                    "province": "Ávila",
                    "city": "Navaluenga",
                    "multiple": "0"
                },
                {
                    "zipcode": "05110",
                    "province": "Ávila",
                    "city": "Barraco, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05111",
                    "province": "Ávila",
                    "city": "San Juan de la Nava",
                    "multiple": "0"
                },
                {
                    "zipcode": "05113",
                    "province": "Ávila",
                    "city": "Burgohondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05114",
                    "province": "Ávila",
                    "city": "Villanueva de Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05115",
                    "province": "Ávila",
                    "city": "Serranillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05115",
                    "province": "Ávila",
                    "city": "Navarrevisca",
                    "multiple": "1"
                },
                {
                    "zipcode": "05116",
                    "province": "Ávila",
                    "city": "Serranillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05120",
                    "province": "Ávila",
                    "city": "Navalmoral",
                    "multiple": "0"
                },
                {
                    "zipcode": "05120",
                    "province": "Ávila",
                    "city": "Navarredondilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "05120",
                    "province": "Ávila",
                    "city": "San Juan del Molinillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05122",
                    "province": "Ávila",
                    "city": "Navaquesera",
                    "multiple": "0"
                },
                {
                    "zipcode": "05122",
                    "province": "Ávila",
                    "city": "Navatalgordo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05123",
                    "province": "Ávila",
                    "city": "Hoyocasero",
                    "multiple": "0"
                },
                {
                    "zipcode": "05123",
                    "province": "Ávila",
                    "city": "Navalosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "05125",
                    "province": "Ávila",
                    "city": "Donvidas",
                    "multiple": "0"
                },
                {
                    "zipcode": "05130",
                    "province": "Ávila",
                    "city": "Solosancho",
                    "multiple": "0"
                },
                {
                    "zipcode": "05131",
                    "province": "Ávila",
                    "city": "Narros del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "05131",
                    "province": "Ávila",
                    "city": "Hija de Dios, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "05131",
                    "province": "Ávila",
                    "city": "Mengamuñoz",
                    "multiple": "1"
                },
                {
                    "zipcode": "05132",
                    "province": "Ávila",
                    "city": "San Martín del Pimpollar",
                    "multiple": "0"
                },
                {
                    "zipcode": "05132",
                    "province": "Ávila",
                    "city": "Cepeda la Mora",
                    "multiple": "1"
                },
                {
                    "zipcode": "05132",
                    "province": "Ávila",
                    "city": "Hoyos de Miguel Muñoz",
                    "multiple": "1"
                },
                {
                    "zipcode": "05133",
                    "province": "Ávila",
                    "city": "San Martín de la Vega del Alberche",
                    "multiple": "0"
                },
                {
                    "zipcode": "05134",
                    "province": "Ávila",
                    "city": "Navadijos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05134",
                    "province": "Ávila",
                    "city": "Navalacruz",
                    "multiple": "1"
                },
                {
                    "zipcode": "05134",
                    "province": "Ávila",
                    "city": "Garganta del Villar",
                    "multiple": "1"
                },
                {
                    "zipcode": "05140",
                    "province": "Ávila",
                    "city": "Marlín",
                    "multiple": "0"
                },
                {
                    "zipcode": "05140",
                    "province": "Ávila",
                    "city": "Bularros",
                    "multiple": "1"
                },
                {
                    "zipcode": "05140",
                    "province": "Ávila",
                    "city": "Martiherrero",
                    "multiple": "1"
                },
                {
                    "zipcode": "05140",
                    "province": "Ávila",
                    "city": "Casasola",
                    "multiple": "1"
                },
                {
                    "zipcode": "05141",
                    "province": "Ávila",
                    "city": "Narrillos del Rebollar",
                    "multiple": "0"
                },
                {
                    "zipcode": "05141",
                    "province": "Ávila",
                    "city": "Gallegos de Altamiros",
                    "multiple": "1"
                },
                {
                    "zipcode": "05141",
                    "province": "Ávila",
                    "city": "Chamartín",
                    "multiple": "1"
                },
                {
                    "zipcode": "05141",
                    "province": "Ávila",
                    "city": "Sanchorreja",
                    "multiple": "1"
                },
                {
                    "zipcode": "05143",
                    "province": "Ávila",
                    "city": "Valdecasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "05145",
                    "province": "Ávila",
                    "city": "Manjabálago y Ortigosa de Rioalmar",
                    "multiple": "0"
                },
                {
                    "zipcode": "05145",
                    "province": "Ávila",
                    "city": "San Juan del Olmo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05145",
                    "province": "Ávila",
                    "city": "Muñico",
                    "multiple": "1"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "Mirueña de los Infanzones",
                    "multiple": "0"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "Vita",
                    "multiple": "1"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "San García de Ingelmos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "Parral, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "Blascomillán",
                    "multiple": "1"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "Mancera de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "Arenal, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "05146",
                    "province": "Ávila",
                    "city": "Herreros de Suso",
                    "multiple": "1"
                },
                {
                    "zipcode": "05147",
                    "province": "Ávila",
                    "city": "Gallegos de Sobrinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05147",
                    "province": "Ávila",
                    "city": "Hurtumpascual",
                    "multiple": "1"
                },
                {
                    "zipcode": "05148",
                    "province": "Ávila",
                    "city": "Cabezas del Villar",
                    "multiple": "0"
                },
                {
                    "zipcode": "05149",
                    "province": "Ávila",
                    "city": "Solana de Rioalmar",
                    "multiple": "0"
                },
                {
                    "zipcode": "05149",
                    "province": "Ávila",
                    "city": "Cillán",
                    "multiple": "1"
                },
                {
                    "zipcode": "05150",
                    "province": "Ávila",
                    "city": "Riocabado",
                    "multiple": "0"
                },
                {
                    "zipcode": "05150",
                    "province": "Ávila",
                    "city": "Pascualcobo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05150",
                    "province": "Ávila",
                    "city": "San Miguel de Serrezuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "05151",
                    "province": "Ávila",
                    "city": "Diego del Carpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "05151",
                    "province": "Ávila",
                    "city": "Martínez",
                    "multiple": "1"
                },
                {
                    "zipcode": "05152",
                    "province": "Ávila",
                    "city": "Martínez",
                    "multiple": "0"
                },
                {
                    "zipcode": "05153",
                    "province": "Ávila",
                    "city": "Mirón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05153",
                    "province": "Ávila",
                    "city": "Becedillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "05153",
                    "province": "Ávila",
                    "city": "Collado del Mirón",
                    "multiple": "1"
                },
                {
                    "zipcode": "05153",
                    "province": "Ávila",
                    "city": "Malpartida de Corneja",
                    "multiple": "1"
                },
                {
                    "zipcode": "05153",
                    "province": "Ávila",
                    "city": "Arevalillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05154",
                    "province": "Ávila",
                    "city": "Mirón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05154",
                    "province": "Ávila",
                    "city": "Zapardiel de la Cañada",
                    "multiple": "1"
                },
                {
                    "zipcode": "05154",
                    "province": "Ávila",
                    "city": "Narrillos del Álamo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05160",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05161",
                    "province": "Ávila",
                    "city": "Mingorría",
                    "multiple": "0"
                },
                {
                    "zipcode": "05162",
                    "province": "Ávila",
                    "city": "Berlanas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "05163",
                    "province": "Ávila",
                    "city": "Gotarrendura",
                    "multiple": "0"
                },
                {
                    "zipcode": "05163",
                    "province": "Ávila",
                    "city": "Peñalba de Ávila",
                    "multiple": "1"
                },
                {
                    "zipcode": "05163",
                    "province": "Ávila",
                    "city": "Monsalupe",
                    "multiple": "1"
                },
                {
                    "zipcode": "05164",
                    "province": "Ávila",
                    "city": "Hernansancho",
                    "multiple": "0"
                },
                {
                    "zipcode": "05164",
                    "province": "Ávila",
                    "city": "Oso, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "05164",
                    "province": "Ávila",
                    "city": "Riocabado",
                    "multiple": "1"
                },
                {
                    "zipcode": "05164",
                    "province": "Ávila",
                    "city": "San Pascual",
                    "multiple": "1"
                },
                {
                    "zipcode": "05164",
                    "province": "Ávila",
                    "city": "Villanueva de Gómez",
                    "multiple": "1"
                },
                {
                    "zipcode": "05165",
                    "province": "Ávila",
                    "city": "Bohodón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05165",
                    "province": "Ávila",
                    "city": "Cabizuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "05165",
                    "province": "Ávila",
                    "city": "Pedro-Rodríguez",
                    "multiple": "1"
                },
                {
                    "zipcode": "05165",
                    "province": "Ávila",
                    "city": "Tiñosillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05170",
                    "province": "Ávila",
                    "city": "Tiemblo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05190",
                    "province": "Ávila",
                    "city": "Riofrío",
                    "multiple": "0"
                },
                {
                    "zipcode": "05191",
                    "province": "Ávila",
                    "city": "Mironcillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05191",
                    "province": "Ávila",
                    "city": "Niharra",
                    "multiple": "1"
                },
                {
                    "zipcode": "05192",
                    "province": "Ávila",
                    "city": "Serrada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "05192",
                    "province": "Ávila",
                    "city": "Muñopepe",
                    "multiple": "1"
                },
                {
                    "zipcode": "05192",
                    "province": "Ávila",
                    "city": "Colilla, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "05193",
                    "province": "Ávila",
                    "city": "Ojos-Albos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05193",
                    "province": "Ávila",
                    "city": "Santa María del Cubillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05194",
                    "province": "Ávila",
                    "city": "Berrocalejo de Aragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "05194",
                    "province": "Ávila",
                    "city": "Mediana de Voltoya",
                    "multiple": "1"
                },
                {
                    "zipcode": "05194",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "1"
                },
                {
                    "zipcode": "05195",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05196",
                    "province": "Ávila",
                    "city": "Tornadizos de Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05196",
                    "province": "Ávila",
                    "city": "Herradón de Pinares",
                    "multiple": "1"
                },
                {
                    "zipcode": "05197",
                    "province": "Ávila",
                    "city": "Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05197",
                    "province": "Ávila",
                    "city": "Fresno, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "05197",
                    "province": "Ávila",
                    "city": "Gemuño",
                    "multiple": "1"
                },
                {
                    "zipcode": "05198",
                    "province": "Ávila",
                    "city": "Sotalbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05200",
                    "province": "Ávila",
                    "city": "Arévalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05210",
                    "province": "Ávila",
                    "city": "San Juan de la Encinilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "05210",
                    "province": "Ávila",
                    "city": "Horcajo de las Torres",
                    "multiple": "1"
                },
                {
                    "zipcode": "05211",
                    "province": "Ávila",
                    "city": "Cabezas del Pozo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05211",
                    "province": "Ávila",
                    "city": "Cantiveros",
                    "multiple": "1"
                },
                {
                    "zipcode": "05211",
                    "province": "Ávila",
                    "city": "Bernuy-Zapardiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "05211",
                    "province": "Ávila",
                    "city": "Cisla",
                    "multiple": "1"
                },
                {
                    "zipcode": "05212",
                    "province": "Ávila",
                    "city": "Fuentes de Año",
                    "multiple": "0"
                },
                {
                    "zipcode": "05212",
                    "province": "Ávila",
                    "city": "Fuente el Saúz",
                    "multiple": "1"
                },
                {
                    "zipcode": "05212",
                    "province": "Ávila",
                    "city": "Villanueva del Aceral",
                    "multiple": "1"
                },
                {
                    "zipcode": "05212",
                    "province": "Ávila",
                    "city": "Canales",
                    "multiple": "1"
                },
                {
                    "zipcode": "05212",
                    "province": "Ávila",
                    "city": "Aldeaseca",
                    "multiple": "1"
                },
                {
                    "zipcode": "05213",
                    "province": "Ávila",
                    "city": "Langa",
                    "multiple": "0"
                },
                {
                    "zipcode": "05215",
                    "province": "Ávila",
                    "city": "Donvidas",
                    "multiple": "0"
                },
                {
                    "zipcode": "05215",
                    "province": "Ávila",
                    "city": "Palacios de Goda",
                    "multiple": "1"
                },
                {
                    "zipcode": "05215",
                    "province": "Ávila",
                    "city": "Sinlabajos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05216",
                    "province": "Ávila",
                    "city": "Nava de Arévalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05217",
                    "province": "Ávila",
                    "city": "Constanzana",
                    "multiple": "0"
                },
                {
                    "zipcode": "05217",
                    "province": "Ávila",
                    "city": "Cabezas de Alambre",
                    "multiple": "1"
                },
                {
                    "zipcode": "05217",
                    "province": "Ávila",
                    "city": "Donjimeno",
                    "multiple": "1"
                },
                {
                    "zipcode": "05217",
                    "province": "Ávila",
                    "city": "San Vicente de Arévalo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05220",
                    "province": "Ávila",
                    "city": "Madrigal de las Altas Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "05229",
                    "province": "Ávila",
                    "city": "San Esteban de Zapardiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "05229",
                    "province": "Ávila",
                    "city": "Castellanos de Zapardiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "05229",
                    "province": "Ávila",
                    "city": "Barromán",
                    "multiple": "1"
                },
                {
                    "zipcode": "05229",
                    "province": "Ávila",
                    "city": "Bercial de Zapardiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "05230",
                    "province": "Ávila",
                    "city": "Navas del Marqués, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "05239",
                    "province": "Ávila",
                    "city": "Peguerinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05239",
                    "province": "Ávila",
                    "city": "Navas del Marqués, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "05240",
                    "province": "Ávila",
                    "city": "Navalperal de Pinares",
                    "multiple": "0"
                },
                {
                    "zipcode": "05250",
                    "province": "Ávila",
                    "city": "Hoyo de Pinares, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05260",
                    "province": "Ávila",
                    "city": "Cebreros",
                    "multiple": "0"
                },
                {
                    "zipcode": "05267",
                    "province": "Ávila",
                    "city": "San Bartolomé de Pinares",
                    "multiple": "0"
                },
                {
                    "zipcode": "05268",
                    "province": "Ávila",
                    "city": "Santa Cruz de Pinares",
                    "multiple": "0"
                },
                {
                    "zipcode": "05268",
                    "province": "Ávila",
                    "city": "Herradón de Pinares",
                    "multiple": "1"
                },
                {
                    "zipcode": "05270",
                    "province": "Ávila",
                    "city": "Tiemblo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05278",
                    "province": "Ávila",
                    "city": "Tiemblo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05278",
                    "province": "Ávila",
                    "city": "Barraco, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "05279",
                    "province": "Ávila",
                    "city": "Tiemblo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05280",
                    "province": "Ávila",
                    "city": "Mingorría",
                    "multiple": "0"
                },
                {
                    "zipcode": "05289",
                    "province": "Ávila",
                    "city": "Tolbaños",
                    "multiple": "0"
                },
                {
                    "zipcode": "05289",
                    "province": "Ávila",
                    "city": "San Esteban de los Patos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05290",
                    "province": "Ávila",
                    "city": "Blascosancho",
                    "multiple": "0"
                },
                {
                    "zipcode": "05290",
                    "province": "Ávila",
                    "city": "Sanchidrián",
                    "multiple": "1"
                },
                {
                    "zipcode": "05291",
                    "province": "Ávila",
                    "city": "Maello",
                    "multiple": "0"
                },
                {
                    "zipcode": "05292",
                    "province": "Ávila",
                    "city": "Santo Domingo de las Posadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "05292",
                    "province": "Ávila",
                    "city": "Pozanco",
                    "multiple": "1"
                },
                {
                    "zipcode": "05292",
                    "province": "Ávila",
                    "city": "Pajares de Adaja",
                    "multiple": "1"
                },
                {
                    "zipcode": "05292",
                    "province": "Ávila",
                    "city": "Vega de Santa María",
                    "multiple": "1"
                },
                {
                    "zipcode": "05292",
                    "province": "Ávila",
                    "city": "Velayos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05294",
                    "province": "Ávila",
                    "city": "Herradón de Pinares",
                    "multiple": "0"
                },
                {
                    "zipcode": "05296",
                    "province": "Ávila",
                    "city": "Espinosa de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "05296",
                    "province": "Ávila",
                    "city": "Gutierre-Muñoz",
                    "multiple": "1"
                },
                {
                    "zipcode": "05296",
                    "province": "Ávila",
                    "city": "Adanero",
                    "multiple": "1"
                },
                {
                    "zipcode": "05296",
                    "province": "Ávila",
                    "city": "Orbita",
                    "multiple": "1"
                },
                {
                    "zipcode": "05298",
                    "province": "Ávila",
                    "city": "Rasueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "05298",
                    "province": "Ávila",
                    "city": "Tolbaños",
                    "multiple": "1"
                },
                {
                    "zipcode": "05298",
                    "province": "Ávila",
                    "city": "Mamblas",
                    "multiple": "1"
                },
                {
                    "zipcode": "05299",
                    "province": "Ávila",
                    "city": "Moraleja de Matacabras",
                    "multiple": "0"
                },
                {
                    "zipcode": "05299",
                    "province": "Ávila",
                    "city": "Blasconuño de Matacabras",
                    "multiple": "1"
                },
                {
                    "zipcode": "05300",
                    "province": "Ávila",
                    "city": "Crespos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05309",
                    "province": "Ávila",
                    "city": "Collado de Contreras",
                    "multiple": "0"
                },
                {
                    "zipcode": "05309",
                    "province": "Ávila",
                    "city": "Crespos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05309",
                    "province": "Ávila",
                    "city": "Muñogrande",
                    "multiple": "1"
                },
                {
                    "zipcode": "05309",
                    "province": "Ávila",
                    "city": "Viñegra de Moraña",
                    "multiple": "1"
                },
                {
                    "zipcode": "05309",
                    "province": "Ávila",
                    "city": "Rivilla de Barajas",
                    "multiple": "1"
                },
                {
                    "zipcode": "05310",
                    "province": "Ávila",
                    "city": "Fontiveros",
                    "multiple": "0"
                },
                {
                    "zipcode": "05320",
                    "province": "Ávila",
                    "city": "Cardeñosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "05340",
                    "province": "Ávila",
                    "city": "Bularros",
                    "multiple": "0"
                },
                {
                    "zipcode": "05340",
                    "province": "Ávila",
                    "city": "Aveinte",
                    "multiple": "1"
                },
                {
                    "zipcode": "05350",
                    "province": "Ávila",
                    "city": "San Pedro del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05357",
                    "province": "Ávila",
                    "city": "Grandes y San Martín",
                    "multiple": "0"
                },
                {
                    "zipcode": "05357",
                    "province": "Ávila",
                    "city": "Santo Tomé de Zabarcos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05357",
                    "province": "Ávila",
                    "city": "Aveinte",
                    "multiple": "1"
                },
                {
                    "zipcode": "05357",
                    "province": "Ávila",
                    "city": "Villaflor",
                    "multiple": "1"
                },
                {
                    "zipcode": "05357",
                    "province": "Ávila",
                    "city": "Sigeres",
                    "multiple": "1"
                },
                {
                    "zipcode": "05357",
                    "province": "Ávila",
                    "city": "Brabos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05357",
                    "province": "Ávila",
                    "city": "Muñogrande",
                    "multiple": "1"
                },
                {
                    "zipcode": "05358",
                    "province": "Ávila",
                    "city": "San Juan de la Encinilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "05358",
                    "province": "Ávila",
                    "city": "Papatrigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05358",
                    "province": "Ávila",
                    "city": "Muñomer del Peco",
                    "multiple": "1"
                },
                {
                    "zipcode": "05358",
                    "province": "Ávila",
                    "city": "Narros de Saldueña",
                    "multiple": "1"
                },
                {
                    "zipcode": "05358",
                    "province": "Ávila",
                    "city": "Albornos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05364",
                    "province": "Ávila",
                    "city": "Hoyos del Espino",
                    "multiple": "0"
                },
                {
                    "zipcode": "05370",
                    "province": "Ávila",
                    "city": "Narros del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05370",
                    "province": "Ávila",
                    "city": "Flores de Ávila",
                    "multiple": "1"
                },
                {
                    "zipcode": "05380",
                    "province": "Ávila",
                    "city": "Gimialcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "05380",
                    "province": "Ávila",
                    "city": "Salvadiós",
                    "multiple": "1"
                },
                {
                    "zipcode": "05380",
                    "province": "Ávila",
                    "city": "Muñosancho",
                    "multiple": "1"
                },
                {
                    "zipcode": "05400",
                    "province": "Ávila",
                    "city": "Arenas de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "05410",
                    "province": "Ávila",
                    "city": "Mombeltrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "05412",
                    "province": "Ávila",
                    "city": "San Esteban del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "05413",
                    "province": "Ávila",
                    "city": "Santa Cruz del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "05413",
                    "province": "Ávila",
                    "city": "Villarejo del Valle",
                    "multiple": "1"
                },
                {
                    "zipcode": "05414",
                    "province": "Ávila",
                    "city": "Cuevas del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "05415",
                    "province": "Ávila",
                    "city": "Hornillo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05416",
                    "province": "Ávila",
                    "city": "Arenal, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05417",
                    "province": "Ávila",
                    "city": "Guisando",
                    "multiple": "0"
                },
                {
                    "zipcode": "05418",
                    "province": "Ávila",
                    "city": "Arenas de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "05420",
                    "province": "Ávila",
                    "city": "Sotillo de la Adrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "05427",
                    "province": "Ávila",
                    "city": "Fresnedilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "05427",
                    "province": "Ávila",
                    "city": "Higuera de las Dueñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "05428",
                    "province": "Ávila",
                    "city": "Casillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "05429",
                    "province": "Ávila",
                    "city": "Santa María del Tiétar",
                    "multiple": "0"
                },
                {
                    "zipcode": "05429",
                    "province": "Ávila",
                    "city": "Casillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "05429",
                    "province": "Ávila",
                    "city": "Navahondilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "05430",
                    "province": "Ávila",
                    "city": "Adrada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "05440",
                    "province": "Ávila",
                    "city": "Piedralaves",
                    "multiple": "0"
                },
                {
                    "zipcode": "05450",
                    "province": "Ávila",
                    "city": "Casavieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "05460",
                    "province": "Ávila",
                    "city": "Gavilanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "05461",
                    "province": "Ávila",
                    "city": "Mijares",
                    "multiple": "0"
                },
                {
                    "zipcode": "05470",
                    "province": "Ávila",
                    "city": "Pedro Bernardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05480",
                    "province": "Ávila",
                    "city": "Candeleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "05480",
                    "province": "Ávila",
                    "city": "Candeleda",
                    "multiple": "1"
                },
                {
                    "zipcode": "05489",
                    "province": "Ávila",
                    "city": "Candeleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "05490",
                    "province": "Ávila",
                    "city": "Lanzahíta",
                    "multiple": "0"
                },
                {
                    "zipcode": "05491",
                    "province": "Ávila",
                    "city": "Mombeltrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "05492",
                    "province": "Ávila",
                    "city": "Poyales del Hoyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05500",
                    "province": "Ávila",
                    "city": "Piedrahíta",
                    "multiple": "0"
                },
                {
                    "zipcode": "05510",
                    "province": "Ávila",
                    "city": "Santa María del Berrocal",
                    "multiple": "0"
                },
                {
                    "zipcode": "05511",
                    "province": "Ávila",
                    "city": "Santa María del Berrocal",
                    "multiple": "0"
                },
                {
                    "zipcode": "05513",
                    "province": "Ávila",
                    "city": "Burgohondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05514",
                    "province": "Ávila",
                    "city": "San Miguel de Corneja",
                    "multiple": "0"
                },
                {
                    "zipcode": "05514",
                    "province": "Ávila",
                    "city": "Tórtoles",
                    "multiple": "1"
                },
                {
                    "zipcode": "05514",
                    "province": "Ávila",
                    "city": "Bonilla de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "05514",
                    "province": "Ávila",
                    "city": "Mesegar de Corneja",
                    "multiple": "1"
                },
                {
                    "zipcode": "05514",
                    "province": "Ávila",
                    "city": "Navaescurial",
                    "multiple": "1"
                },
                {
                    "zipcode": "05515",
                    "province": "Ávila",
                    "city": "Piedrahíta",
                    "multiple": "0"
                },
                {
                    "zipcode": "05516",
                    "province": "Ávila",
                    "city": "Hoyorredondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05516",
                    "province": "Ávila",
                    "city": "Villar de Corneja",
                    "multiple": "1"
                },
                {
                    "zipcode": "05516",
                    "province": "Ávila",
                    "city": "San Bartolomé de Corneja",
                    "multiple": "1"
                },
                {
                    "zipcode": "05520",
                    "province": "Ávila",
                    "city": "Torre, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "05520",
                    "province": "Ávila",
                    "city": "Salobral",
                    "multiple": "1"
                },
                {
                    "zipcode": "05520",
                    "province": "Ávila",
                    "city": "Padiernos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05530",
                    "province": "Ávila",
                    "city": "Muñogalindo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05530",
                    "province": "Ávila",
                    "city": "Santa María del Arroyo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05540",
                    "province": "Ávila",
                    "city": "Piedralaves",
                    "multiple": "0"
                },
                {
                    "zipcode": "05540",
                    "province": "Ávila",
                    "city": "Muñana",
                    "multiple": "1"
                },
                {
                    "zipcode": "05540",
                    "province": "Ávila",
                    "city": "Torre, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "05560",
                    "province": "Ávila",
                    "city": "Muñotello",
                    "multiple": "0"
                },
                {
                    "zipcode": "05560",
                    "province": "Ávila",
                    "city": "Vadillo de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "05560",
                    "province": "Ávila",
                    "city": "Poveda",
                    "multiple": "1"
                },
                {
                    "zipcode": "05560",
                    "province": "Ávila",
                    "city": "Amavida",
                    "multiple": "1"
                },
                {
                    "zipcode": "05560",
                    "province": "Ávila",
                    "city": "Pradosegar",
                    "multiple": "1"
                },
                {
                    "zipcode": "05560",
                    "province": "Ávila",
                    "city": "Villatoro",
                    "multiple": "1"
                },
                {
                    "zipcode": "05570",
                    "province": "Ávila",
                    "city": "Bonilla de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "05571",
                    "province": "Ávila",
                    "city": "Navacepedilla de Corneja",
                    "multiple": "0"
                },
                {
                    "zipcode": "05571",
                    "province": "Ávila",
                    "city": "Villafranca de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "05571",
                    "province": "Ávila",
                    "city": "Casas del Puerto",
                    "multiple": "1"
                },
                {
                    "zipcode": "05580",
                    "province": "Ávila",
                    "city": "Avellaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "05580",
                    "province": "Ávila",
                    "city": "Aldeanueva de Santa Cruz",
                    "multiple": "1"
                },
                {
                    "zipcode": "05580",
                    "province": "Ávila",
                    "city": "Santa María de los Caballeros",
                    "multiple": "1"
                },
                {
                    "zipcode": "05581",
                    "province": "Ávila",
                    "city": "Santa María de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "05591",
                    "province": "Ávila",
                    "city": "Villanueva del Campillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05592",
                    "province": "Ávila",
                    "city": "Santiago del Collado",
                    "multiple": "0"
                },
                {
                    "zipcode": "05593",
                    "province": "Ávila",
                    "city": "Aldehuela, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "05600",
                    "province": "Ávila",
                    "city": "Barco de Ávila, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05610",
                    "province": "Ávila",
                    "city": "Becedas",
                    "multiple": "0"
                },
                {
                    "zipcode": "05619",
                    "province": "Ávila",
                    "city": "Neila de San Miguel",
                    "multiple": "0"
                },
                {
                    "zipcode": "05619",
                    "province": "Ávila",
                    "city": "Gilbuena",
                    "multiple": "1"
                },
                {
                    "zipcode": "05619",
                    "province": "Ávila",
                    "city": "Medinilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "05619",
                    "province": "Ávila",
                    "city": "San Bartolomé de Béjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "05620",
                    "province": "Ávila",
                    "city": "Carrera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "05621",
                    "province": "Ávila",
                    "city": "Puerto Castilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "05621",
                    "province": "Ávila",
                    "city": "Solana de Ávila",
                    "multiple": "1"
                },
                {
                    "zipcode": "05630",
                    "province": "Ávila",
                    "city": "Santiago del Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "05630",
                    "province": "Ávila",
                    "city": "Bohoyo",
                    "multiple": "1"
                },
                {
                    "zipcode": "05631",
                    "province": "Ávila",
                    "city": "Navalperal de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "05631",
                    "province": "Ávila",
                    "city": "Zapardiel de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "05632",
                    "province": "Ávila",
                    "city": "Navalperal de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "05632",
                    "province": "Ávila",
                    "city": "San Juan de Gredos",
                    "multiple": "1"
                },
                {
                    "zipcode": "05633",
                    "province": "Ávila",
                    "city": "San Juan de Gredos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05634",
                    "province": "Ávila",
                    "city": "Hoyos del Collado",
                    "multiple": "0"
                },
                {
                    "zipcode": "05634",
                    "province": "Ávila",
                    "city": "Hoyos del Espino",
                    "multiple": "1"
                },
                {
                    "zipcode": "05635",
                    "province": "Ávila",
                    "city": "Navarredonda de Gredos",
                    "multiple": "0"
                },
                {
                    "zipcode": "05640",
                    "province": "Ávila",
                    "city": "Gavilanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "05690",
                    "province": "Ávila",
                    "city": "Bohoyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "05690",
                    "province": "Ávila",
                    "city": "Llanos de Tormes, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "05691",
                    "province": "Ávila",
                    "city": "Solana de Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "05692",
                    "province": "Ávila",
                    "city": "Losar del Barco, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "05693",
                    "province": "Ávila",
                    "city": "Gil García",
                    "multiple": "0"
                },
                {
                    "zipcode": "05693",
                    "province": "Ávila",
                    "city": "Umbrías",
                    "multiple": "1"
                },
                {
                    "zipcode": "05694",
                    "province": "Ávila",
                    "city": "Junciana",
                    "multiple": "0"
                },
                {
                    "zipcode": "05694",
                    "province": "Ávila",
                    "city": "Becedas",
                    "multiple": "1"
                },
                {
                    "zipcode": "05695",
                    "province": "Ávila",
                    "city": "Horcajada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "05696",
                    "province": "Ávila",
                    "city": "Horcajada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "05696",
                    "province": "Ávila",
                    "city": "San Lorenzo de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "05697",
                    "province": "Ávila",
                    "city": "Tormellas",
                    "multiple": "0"
                },
                {
                    "zipcode": "05697",
                    "province": "Ávila",
                    "city": "Nava del Barco",
                    "multiple": "1"
                },
                {
                    "zipcode": "05697",
                    "province": "Ávila",
                    "city": "Navalonguilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "05697",
                    "province": "Ávila",
                    "city": "Navatejares",
                    "multiple": "1"
                },
                {
                    "zipcode": "06001",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06002",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06003",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06004",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06005",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06006",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06007",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06008",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06009",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06010",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06011",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06100",
                    "province": "Badajoz",
                    "city": "Olivenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "06105",
                    "province": "Badajoz",
                    "city": "Cheles",
                    "multiple": "0"
                },
                {
                    "zipcode": "06106",
                    "province": "Badajoz",
                    "city": "Olivenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "06107",
                    "province": "Badajoz",
                    "city": "Olivenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "06108",
                    "province": "Badajoz",
                    "city": "Olivenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "06109",
                    "province": "Badajoz",
                    "city": "Olivenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "06110",
                    "province": "Badajoz",
                    "city": "Villanueva del Fresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "06120",
                    "province": "Badajoz",
                    "city": "Oliva de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "06129",
                    "province": "Badajoz",
                    "city": "Zahínos",
                    "multiple": "0"
                },
                {
                    "zipcode": "06130",
                    "province": "Badajoz",
                    "city": "Valverde de Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "06131",
                    "province": "Badajoz",
                    "city": "Alconchel",
                    "multiple": "0"
                },
                {
                    "zipcode": "06132",
                    "province": "Badajoz",
                    "city": "Higuera de Vargas",
                    "multiple": "0"
                },
                {
                    "zipcode": "06133",
                    "province": "Badajoz",
                    "city": "Táliga",
                    "multiple": "0"
                },
                {
                    "zipcode": "06134",
                    "province": "Badajoz",
                    "city": "Valencia del Mombuey",
                    "multiple": "0"
                },
                {
                    "zipcode": "06140",
                    "province": "Badajoz",
                    "city": "Talavera la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "06150",
                    "province": "Badajoz",
                    "city": "Santa Marta",
                    "multiple": "0"
                },
                {
                    "zipcode": "06160",
                    "province": "Badajoz",
                    "city": "Barcarrota",
                    "multiple": "0"
                },
                {
                    "zipcode": "06170",
                    "province": "Badajoz",
                    "city": "Albuera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06170",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "1"
                },
                {
                    "zipcode": "06171",
                    "province": "Badajoz",
                    "city": "Almendral",
                    "multiple": "0"
                },
                {
                    "zipcode": "06172",
                    "province": "Badajoz",
                    "city": "Torre de Miguel Sesmero",
                    "multiple": "0"
                },
                {
                    "zipcode": "06173",
                    "province": "Badajoz",
                    "city": "Nogales",
                    "multiple": "0"
                },
                {
                    "zipcode": "06174",
                    "province": "Badajoz",
                    "city": "Salvaleón",
                    "multiple": "0"
                },
                {
                    "zipcode": "06174",
                    "province": "Badajoz",
                    "city": "Haba, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "06175",
                    "province": "Badajoz",
                    "city": "Salvatierra de los Barros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06176",
                    "province": "Badajoz",
                    "city": "Parra, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06176",
                    "province": "Badajoz",
                    "city": "Morera, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "06177",
                    "province": "Badajoz",
                    "city": "Valle de Matamoros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06178",
                    "province": "Badajoz",
                    "city": "Valle de Santa Ana",
                    "multiple": "0"
                },
                {
                    "zipcode": "06180",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06181",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06182",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06183",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06184",
                    "province": "Badajoz",
                    "city": "Pueblonuevo del Guadiana",
                    "multiple": "0"
                },
                {
                    "zipcode": "06185",
                    "province": "Badajoz",
                    "city": "Valdelacalzada",
                    "multiple": "0"
                },
                {
                    "zipcode": "06186",
                    "province": "Badajoz",
                    "city": "Guadiana del Caudillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06187",
                    "province": "Badajoz",
                    "city": "Lobón",
                    "multiple": "0"
                },
                {
                    "zipcode": "06190",
                    "province": "Badajoz",
                    "city": "Roca de la Sierra, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06191",
                    "province": "Badajoz",
                    "city": "Puebla de Obando",
                    "multiple": "0"
                },
                {
                    "zipcode": "06192",
                    "province": "Badajoz",
                    "city": "Villar del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "06193",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06194",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06195",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06196",
                    "province": "Badajoz",
                    "city": "Corte de Peleas",
                    "multiple": "0"
                },
                {
                    "zipcode": "06196",
                    "province": "Badajoz",
                    "city": "Solana de los Barros",
                    "multiple": "1"
                },
                {
                    "zipcode": "06197",
                    "province": "Badajoz",
                    "city": "Entrín Bajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06198",
                    "province": "Badajoz",
                    "city": "Solana de los Barros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06200",
                    "province": "Badajoz",
                    "city": "Almendralejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06207",
                    "province": "Badajoz",
                    "city": "Aceuchal",
                    "multiple": "0"
                },
                {
                    "zipcode": "06208",
                    "province": "Badajoz",
                    "city": "Villalba de los Barros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06209",
                    "province": "Badajoz",
                    "city": "Solana de los Barros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06210",
                    "province": "Badajoz",
                    "city": "Torremejía",
                    "multiple": "0"
                },
                {
                    "zipcode": "06220",
                    "province": "Badajoz",
                    "city": "Villafranca de los Barros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06225",
                    "province": "Badajoz",
                    "city": "Ribera del Fresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "06226",
                    "province": "Badajoz",
                    "city": "Hinojosa del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "06227",
                    "province": "Badajoz",
                    "city": "Llera",
                    "multiple": "0"
                },
                {
                    "zipcode": "06228",
                    "province": "Badajoz",
                    "city": "Hornachos",
                    "multiple": "0"
                },
                {
                    "zipcode": "06229",
                    "province": "Badajoz",
                    "city": "Puebla del Prior",
                    "multiple": "0"
                },
                {
                    "zipcode": "06230",
                    "province": "Badajoz",
                    "city": "Santos de Maimona, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "06240",
                    "province": "Badajoz",
                    "city": "Fuente de Cantos",
                    "multiple": "0"
                },
                {
                    "zipcode": "06249",
                    "province": "Badajoz",
                    "city": "Calzadilla de los Barros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06249",
                    "province": "Badajoz",
                    "city": "Carmonita",
                    "multiple": "1"
                },
                {
                    "zipcode": "06250",
                    "province": "Badajoz",
                    "city": "Bienvenida",
                    "multiple": "0"
                },
                {
                    "zipcode": "06260",
                    "province": "Badajoz",
                    "city": "Monesterio",
                    "multiple": "0"
                },
                {
                    "zipcode": "06270",
                    "province": "Badajoz",
                    "city": "Segura de León",
                    "multiple": "0"
                },
                {
                    "zipcode": "06275",
                    "province": "Badajoz",
                    "city": "Salvatierra de los Barros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06280",
                    "province": "Badajoz",
                    "city": "Fuentes de León",
                    "multiple": "0"
                },
                {
                    "zipcode": "06290",
                    "province": "Badajoz",
                    "city": "Usagre",
                    "multiple": "0"
                },
                {
                    "zipcode": "06291",
                    "province": "Badajoz",
                    "city": "Montemolín",
                    "multiple": "0"
                },
                {
                    "zipcode": "06292",
                    "province": "Badajoz",
                    "city": "Calera de León",
                    "multiple": "0"
                },
                {
                    "zipcode": "06293",
                    "province": "Badajoz",
                    "city": "Cabeza la Vaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "06300",
                    "province": "Badajoz",
                    "city": "Zafra",
                    "multiple": "0"
                },
                {
                    "zipcode": "06310",
                    "province": "Badajoz",
                    "city": "Puebla de Sancho Pérez",
                    "multiple": "0"
                },
                {
                    "zipcode": "06320",
                    "province": "Badajoz",
                    "city": "Medina de las Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "06329",
                    "province": "Badajoz",
                    "city": "Atalaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "06330",
                    "province": "Badajoz",
                    "city": "Valencia del Ventoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "06340",
                    "province": "Badajoz",
                    "city": "Fregenal de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "06350",
                    "province": "Badajoz",
                    "city": "Higuera la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "06360",
                    "province": "Badajoz",
                    "city": "Fuente del Maestre",
                    "multiple": "0"
                },
                {
                    "zipcode": "06370",
                    "province": "Badajoz",
                    "city": "Burguillos del Cerro",
                    "multiple": "0"
                },
                {
                    "zipcode": "06378",
                    "province": "Badajoz",
                    "city": "Valverde de Burguillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "06380",
                    "province": "Badajoz",
                    "city": "Jerez de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06389",
                    "province": "Badajoz",
                    "city": "Jerez de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06390",
                    "province": "Badajoz",
                    "city": "Feria",
                    "multiple": "0"
                },
                {
                    "zipcode": "06391",
                    "province": "Badajoz",
                    "city": "Lapa, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06392",
                    "province": "Badajoz",
                    "city": "Puebla de Sancho Pérez",
                    "multiple": "0"
                },
                {
                    "zipcode": "06393",
                    "province": "Badajoz",
                    "city": "Alconera",
                    "multiple": "0"
                },
                {
                    "zipcode": "06394",
                    "province": "Badajoz",
                    "city": "Bodonal de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "06400",
                    "province": "Badajoz",
                    "city": "Don Benito",
                    "multiple": "0"
                },
                {
                    "zipcode": "06400",
                    "province": "Badajoz",
                    "city": "Olivenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "06410",
                    "province": "Badajoz",
                    "city": "Santa Amalia",
                    "multiple": "0"
                },
                {
                    "zipcode": "06410",
                    "province": "Badajoz",
                    "city": "Don Benito",
                    "multiple": "1"
                },
                {
                    "zipcode": "06410",
                    "province": "Badajoz",
                    "city": "Aceuchal",
                    "multiple": "1"
                },
                {
                    "zipcode": "06410",
                    "province": "Badajoz",
                    "city": "Guareña",
                    "multiple": "1"
                },
                {
                    "zipcode": "06411",
                    "province": "Badajoz",
                    "city": "Medellín",
                    "multiple": "0"
                },
                {
                    "zipcode": "06412",
                    "province": "Badajoz",
                    "city": "Don Benito",
                    "multiple": "0"
                },
                {
                    "zipcode": "06413",
                    "province": "Badajoz",
                    "city": "Mengabril",
                    "multiple": "0"
                },
                {
                    "zipcode": "06420",
                    "province": "Badajoz",
                    "city": "Castuera",
                    "multiple": "0"
                },
                {
                    "zipcode": "06427",
                    "province": "Badajoz",
                    "city": "Monterrubio de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06428",
                    "province": "Badajoz",
                    "city": "Benquerencia de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06429",
                    "province": "Badajoz",
                    "city": "Benquerencia de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06430",
                    "province": "Badajoz",
                    "city": "Zalamea de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06439",
                    "province": "Badajoz",
                    "city": "Esparragosa de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06440",
                    "province": "Badajoz",
                    "city": "Malpartida de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06441",
                    "province": "Badajoz",
                    "city": "Higuera de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06442",
                    "province": "Badajoz",
                    "city": "Zalamea de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06442",
                    "province": "Badajoz",
                    "city": "Retamal de Llerena",
                    "multiple": "1"
                },
                {
                    "zipcode": "06443",
                    "province": "Badajoz",
                    "city": "Campillo de Llerena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06444",
                    "province": "Badajoz",
                    "city": "Valencia de las Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "06445",
                    "province": "Badajoz",
                    "city": "Higuera de Llerena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06450",
                    "province": "Badajoz",
                    "city": "Quintana de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06458",
                    "province": "Badajoz",
                    "city": "Valle de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06459",
                    "province": "Badajoz",
                    "city": "Campanario",
                    "multiple": "0"
                },
                {
                    "zipcode": "06460",
                    "province": "Badajoz",
                    "city": "Campanario",
                    "multiple": "0"
                },
                {
                    "zipcode": "06468",
                    "province": "Badajoz",
                    "city": "Magacela",
                    "multiple": "0"
                },
                {
                    "zipcode": "06469",
                    "province": "Badajoz",
                    "city": "Coronada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06470",
                    "province": "Badajoz",
                    "city": "Guareña",
                    "multiple": "0"
                },
                {
                    "zipcode": "06473",
                    "province": "Badajoz",
                    "city": "Villagonzalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06474",
                    "province": "Badajoz",
                    "city": "Valdetorres",
                    "multiple": "0"
                },
                {
                    "zipcode": "06475",
                    "province": "Badajoz",
                    "city": "Oliva de Mérida",
                    "multiple": "0"
                },
                {
                    "zipcode": "06476",
                    "province": "Badajoz",
                    "city": "Palomas",
                    "multiple": "0"
                },
                {
                    "zipcode": "06477",
                    "province": "Badajoz",
                    "city": "Puebla de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "06478",
                    "province": "Badajoz",
                    "city": "Manchita",
                    "multiple": "0"
                },
                {
                    "zipcode": "06479",
                    "province": "Badajoz",
                    "city": "Cristina",
                    "multiple": "0"
                },
                {
                    "zipcode": "06480",
                    "province": "Badajoz",
                    "city": "Montijo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06486",
                    "province": "Badajoz",
                    "city": "Nava de Santiago, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06487",
                    "province": "Badajoz",
                    "city": "Cordobilla de Lácara",
                    "multiple": "0"
                },
                {
                    "zipcode": "06488",
                    "province": "Badajoz",
                    "city": "Carmonita",
                    "multiple": "0"
                },
                {
                    "zipcode": "06489",
                    "province": "Badajoz",
                    "city": "Montijo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06490",
                    "province": "Badajoz",
                    "city": "Puebla de la Calzada",
                    "multiple": "0"
                },
                {
                    "zipcode": "06498",
                    "province": "Badajoz",
                    "city": "Lobón",
                    "multiple": "0"
                },
                {
                    "zipcode": "06499",
                    "province": "Badajoz",
                    "city": "Montijo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06500",
                    "province": "Badajoz",
                    "city": "San Vicente de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "06510",
                    "province": "Badajoz",
                    "city": "Alburquerque",
                    "multiple": "0"
                },
                {
                    "zipcode": "06518",
                    "province": "Badajoz",
                    "city": "Codosera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06519",
                    "province": "Badajoz",
                    "city": "Alburquerque",
                    "multiple": "0"
                },
                {
                    "zipcode": "06600",
                    "province": "Badajoz",
                    "city": "Cabeza del Buey",
                    "multiple": "0"
                },
                {
                    "zipcode": "06610",
                    "province": "Badajoz",
                    "city": "Peñalsordo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06611",
                    "province": "Badajoz",
                    "city": "Zarza-Capilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "06612",
                    "province": "Badajoz",
                    "city": "Capilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "06613",
                    "province": "Badajoz",
                    "city": "Benquerencia de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06614",
                    "province": "Badajoz",
                    "city": "Cabeza del Buey",
                    "multiple": "0"
                },
                {
                    "zipcode": "06615",
                    "province": "Badajoz",
                    "city": "Benquerencia de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06620",
                    "province": "Badajoz",
                    "city": "Esparragosa de Lares",
                    "multiple": "0"
                },
                {
                    "zipcode": "06630",
                    "province": "Badajoz",
                    "city": "Puebla de Alcocer",
                    "multiple": "0"
                },
                {
                    "zipcode": "06640",
                    "province": "Badajoz",
                    "city": "Talarrubias",
                    "multiple": "0"
                },
                {
                    "zipcode": "06650",
                    "province": "Badajoz",
                    "city": "Siruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "06655",
                    "province": "Badajoz",
                    "city": "Sancti-Spíritus",
                    "multiple": "0"
                },
                {
                    "zipcode": "06656",
                    "province": "Badajoz",
                    "city": "Garlitos",
                    "multiple": "0"
                },
                {
                    "zipcode": "06657",
                    "province": "Badajoz",
                    "city": "Risco",
                    "multiple": "0"
                },
                {
                    "zipcode": "06658",
                    "province": "Badajoz",
                    "city": "Tamurejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06659",
                    "province": "Badajoz",
                    "city": "Baterno",
                    "multiple": "0"
                },
                {
                    "zipcode": "06660",
                    "province": "Badajoz",
                    "city": "Fuenlabrada de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "06670",
                    "province": "Badajoz",
                    "city": "Herrera del Duque",
                    "multiple": "0"
                },
                {
                    "zipcode": "06678",
                    "province": "Badajoz",
                    "city": "Villarta de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "06679",
                    "province": "Badajoz",
                    "city": "Herrera del Duque",
                    "multiple": "0"
                },
                {
                    "zipcode": "06680",
                    "province": "Badajoz",
                    "city": "Castilblanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "06689",
                    "province": "Badajoz",
                    "city": "Valdecaballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "06690",
                    "province": "Badajoz",
                    "city": "Garbayuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "06692",
                    "province": "Badajoz",
                    "city": "Helechosa de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "06700",
                    "province": "Badajoz",
                    "city": "Villanueva de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06710",
                    "province": "Badajoz",
                    "city": "Villanueva de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06711",
                    "province": "Badajoz",
                    "city": "Don Benito",
                    "multiple": "0"
                },
                {
                    "zipcode": "06712",
                    "province": "Badajoz",
                    "city": "Villanueva de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06713",
                    "province": "Badajoz",
                    "city": "Acedera",
                    "multiple": "0"
                },
                {
                    "zipcode": "06714",
                    "province": "Badajoz",
                    "city": "Haba, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06715",
                    "province": "Badajoz",
                    "city": "Rena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06716",
                    "province": "Badajoz",
                    "city": "Villar de Rena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06717",
                    "province": "Badajoz",
                    "city": "Villar de Rena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06717",
                    "province": "Badajoz",
                    "city": "Don Benito",
                    "multiple": "1"
                },
                {
                    "zipcode": "06718",
                    "province": "Badajoz",
                    "city": "Villanueva de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06720",
                    "province": "Badajoz",
                    "city": "Villanueva de la Serena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06730",
                    "province": "Badajoz",
                    "city": "Acedera",
                    "multiple": "0"
                },
                {
                    "zipcode": "06730",
                    "province": "Badajoz",
                    "city": "Navalvillar de Pela",
                    "multiple": "1"
                },
                {
                    "zipcode": "06731",
                    "province": "Badajoz",
                    "city": "Navalvillar de Pela",
                    "multiple": "0"
                },
                {
                    "zipcode": "06740",
                    "province": "Badajoz",
                    "city": "Orellana la Vieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "06750",
                    "province": "Badajoz",
                    "city": "Orellana de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "06760",
                    "province": "Badajoz",
                    "city": "Navalvillar de Pela",
                    "multiple": "0"
                },
                {
                    "zipcode": "06770",
                    "province": "Badajoz",
                    "city": "Casas de Don Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "06800",
                    "province": "Badajoz",
                    "city": "Badajoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "06800",
                    "province": "Badajoz",
                    "city": "Mérida",
                    "multiple": "1"
                },
                {
                    "zipcode": "06810",
                    "province": "Badajoz",
                    "city": "Calamonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "06820",
                    "province": "Badajoz",
                    "city": "Don Álvaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "06830",
                    "province": "Badajoz",
                    "city": "Zarza, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06840",
                    "province": "Badajoz",
                    "city": "Alange",
                    "multiple": "0"
                },
                {
                    "zipcode": "06850",
                    "province": "Badajoz",
                    "city": "Arroyo de San Serván",
                    "multiple": "0"
                },
                {
                    "zipcode": "06860",
                    "province": "Badajoz",
                    "city": "Esparragalejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06870",
                    "province": "Badajoz",
                    "city": "Garrovilla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "06880",
                    "province": "Badajoz",
                    "city": "Torremayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "06890",
                    "province": "Badajoz",
                    "city": "Valverde de Mérida",
                    "multiple": "0"
                },
                {
                    "zipcode": "06891",
                    "province": "Badajoz",
                    "city": "Mirandilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "06892",
                    "province": "Badajoz",
                    "city": "Trujillanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "06893",
                    "province": "Badajoz",
                    "city": "San Pedro de Mérida",
                    "multiple": "0"
                },
                {
                    "zipcode": "06894",
                    "province": "Badajoz",
                    "city": "Aljucén",
                    "multiple": "0"
                },
                {
                    "zipcode": "06894",
                    "province": "Badajoz",
                    "city": "Carrascalejo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "06900",
                    "province": "Badajoz",
                    "city": "Llerena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06906",
                    "province": "Badajoz",
                    "city": "Puebla del Maestre",
                    "multiple": "0"
                },
                {
                    "zipcode": "06907",
                    "province": "Badajoz",
                    "city": "Montemolín",
                    "multiple": "0"
                },
                {
                    "zipcode": "06908",
                    "province": "Badajoz",
                    "city": "Montemolín",
                    "multiple": "0"
                },
                {
                    "zipcode": "06909",
                    "province": "Badajoz",
                    "city": "Trasierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "06910",
                    "province": "Badajoz",
                    "city": "Granja de Torrehermosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "06919",
                    "province": "Badajoz",
                    "city": "Peraleda del Zaucejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "06920",
                    "province": "Badajoz",
                    "city": "Azuaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "06927",
                    "province": "Badajoz",
                    "city": "Valverde de Llerena",
                    "multiple": "0"
                },
                {
                    "zipcode": "06928",
                    "province": "Badajoz",
                    "city": "Malcocinado",
                    "multiple": "0"
                },
                {
                    "zipcode": "06929",
                    "province": "Badajoz",
                    "city": "Azuaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "06930",
                    "province": "Badajoz",
                    "city": "Berlanga",
                    "multiple": "0"
                },
                {
                    "zipcode": "06939",
                    "province": "Badajoz",
                    "city": "Maguilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "06940",
                    "province": "Badajoz",
                    "city": "Ahillones",
                    "multiple": "0"
                },
                {
                    "zipcode": "06950",
                    "province": "Badajoz",
                    "city": "Villagarcía de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "06960",
                    "province": "Badajoz",
                    "city": "Casas de Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "06970",
                    "province": "Badajoz",
                    "city": "Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "06980",
                    "province": "Badajoz",
                    "city": "Fuente del Arco",
                    "multiple": "0"
                },
                {
                    "zipcode": "07001",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07002",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07003",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07004",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07005",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07006",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07007",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07008",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07009",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07010",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07011",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07012",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07013",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07014",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07015",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07071",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07100",
                    "province": "Illes Balears",
                    "city": "Sóller",
                    "multiple": "0"
                },
                {
                    "zipcode": "07101",
                    "province": "Illes Balears",
                    "city": "Sóller",
                    "multiple": "0"
                },
                {
                    "zipcode": "07108",
                    "province": "Illes Balears",
                    "city": "Sóller",
                    "multiple": "0"
                },
                {
                    "zipcode": "07109",
                    "province": "Illes Balears",
                    "city": "Fornalutx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07110",
                    "province": "Illes Balears",
                    "city": "Bunyola",
                    "multiple": "0"
                },
                {
                    "zipcode": "07120",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07140",
                    "province": "Illes Balears",
                    "city": "Sencelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "07141",
                    "province": "Illes Balears",
                    "city": "Marratxí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07142",
                    "province": "Illes Balears",
                    "city": "Santa Eugènia",
                    "multiple": "0"
                },
                {
                    "zipcode": "07143",
                    "province": "Illes Balears",
                    "city": "Sencelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "07144",
                    "province": "Illes Balears",
                    "city": "Costitx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07144",
                    "province": "Illes Balears",
                    "city": "Sencelles",
                    "multiple": "1"
                },
                {
                    "zipcode": "07150",
                    "province": "Illes Balears",
                    "city": "Andratx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07157",
                    "province": "Illes Balears",
                    "city": "Andratx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07159",
                    "province": "Illes Balears",
                    "city": "Andratx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07160",
                    "province": "Illes Balears",
                    "city": "Calvià",
                    "multiple": "0"
                },
                {
                    "zipcode": "07160",
                    "province": "Illes Balears",
                    "city": "Andratx",
                    "multiple": "1"
                },
                {
                    "zipcode": "07170",
                    "province": "Illes Balears",
                    "city": "Valldemossa",
                    "multiple": "0"
                },
                {
                    "zipcode": "07179",
                    "province": "Illes Balears",
                    "city": "Deià",
                    "multiple": "0"
                },
                {
                    "zipcode": "07179",
                    "province": "Illes Balears",
                    "city": "Sóller",
                    "multiple": "1"
                },
                {
                    "zipcode": "07180",
                    "province": "Illes Balears",
                    "city": "Calvià",
                    "multiple": "0"
                },
                {
                    "zipcode": "07181",
                    "province": "Illes Balears",
                    "city": "Calvià",
                    "multiple": "0"
                },
                {
                    "zipcode": "07183",
                    "province": "Illes Balears",
                    "city": "Calvià",
                    "multiple": "0"
                },
                {
                    "zipcode": "07184",
                    "province": "Illes Balears",
                    "city": "Calvià",
                    "multiple": "0"
                },
                {
                    "zipcode": "07190",
                    "province": "Illes Balears",
                    "city": "Banyalbufar",
                    "multiple": "0"
                },
                {
                    "zipcode": "07190",
                    "province": "Illes Balears",
                    "city": "Esporles",
                    "multiple": "1"
                },
                {
                    "zipcode": "07190",
                    "province": "Illes Balears",
                    "city": "Valldemossa",
                    "multiple": "1"
                },
                {
                    "zipcode": "07191",
                    "province": "Illes Balears",
                    "city": "Banyalbufar",
                    "multiple": "0"
                },
                {
                    "zipcode": "07192",
                    "province": "Illes Balears",
                    "city": "Estellencs",
                    "multiple": "0"
                },
                {
                    "zipcode": "07193",
                    "province": "Illes Balears",
                    "city": "Bunyola",
                    "multiple": "0"
                },
                {
                    "zipcode": "07194",
                    "province": "Illes Balears",
                    "city": "Puigpunyent",
                    "multiple": "0"
                },
                {
                    "zipcode": "07195",
                    "province": "Illes Balears",
                    "city": "Puigpunyent",
                    "multiple": "0"
                },
                {
                    "zipcode": "07196",
                    "province": "Illes Balears",
                    "city": "Calvià",
                    "multiple": "0"
                },
                {
                    "zipcode": "07198",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07199",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07200",
                    "province": "Illes Balears",
                    "city": "Felanitx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07208",
                    "province": "Illes Balears",
                    "city": "Felanitx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07209",
                    "province": "Illes Balears",
                    "city": "Felanitx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07210",
                    "province": "Illes Balears",
                    "city": "Algaida",
                    "multiple": "0"
                },
                {
                    "zipcode": "07220",
                    "province": "Illes Balears",
                    "city": "Algaida",
                    "multiple": "0"
                },
                {
                    "zipcode": "07230",
                    "province": "Illes Balears",
                    "city": "Montuïri",
                    "multiple": "0"
                },
                {
                    "zipcode": "07240",
                    "province": "Illes Balears",
                    "city": "Sant Joan",
                    "multiple": "0"
                },
                {
                    "zipcode": "07250",
                    "province": "Illes Balears",
                    "city": "Vilafranca de Bonany",
                    "multiple": "0"
                },
                {
                    "zipcode": "07259",
                    "province": "Illes Balears",
                    "city": "Ariany",
                    "multiple": "0"
                },
                {
                    "zipcode": "07260",
                    "province": "Illes Balears",
                    "city": "Porreres",
                    "multiple": "0"
                },
                {
                    "zipcode": "07300",
                    "province": "Illes Balears",
                    "city": "Inca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07310",
                    "province": "Illes Balears",
                    "city": "Campanet",
                    "multiple": "0"
                },
                {
                    "zipcode": "07311",
                    "province": "Illes Balears",
                    "city": "Búger",
                    "multiple": "0"
                },
                {
                    "zipcode": "07312",
                    "province": "Illes Balears",
                    "city": "Mancor de la Vall",
                    "multiple": "0"
                },
                {
                    "zipcode": "07313",
                    "province": "Illes Balears",
                    "city": "Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "07314",
                    "province": "Illes Balears",
                    "city": "Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "07315",
                    "province": "Illes Balears",
                    "city": "Escorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07316",
                    "province": "Illes Balears",
                    "city": "Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "07320",
                    "province": "Illes Balears",
                    "city": "Santa María del Camí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07330",
                    "province": "Illes Balears",
                    "city": "Consell",
                    "multiple": "0"
                },
                {
                    "zipcode": "07340",
                    "province": "Illes Balears",
                    "city": "Alaró",
                    "multiple": "0"
                },
                {
                    "zipcode": "07349",
                    "province": "Illes Balears",
                    "city": "Bunyola",
                    "multiple": "0"
                },
                {
                    "zipcode": "07350",
                    "province": "Illes Balears",
                    "city": "Binissalem",
                    "multiple": "0"
                },
                {
                    "zipcode": "07360",
                    "province": "Illes Balears",
                    "city": "Lloseta",
                    "multiple": "0"
                },
                {
                    "zipcode": "07369",
                    "province": "Illes Balears",
                    "city": "Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "07400",
                    "province": "Illes Balears",
                    "city": "Alcúdia",
                    "multiple": "0"
                },
                {
                    "zipcode": "07420",
                    "province": "Illes Balears",
                    "city": "Pobla, Sa",
                    "multiple": "0"
                },
                {
                    "zipcode": "07430",
                    "province": "Illes Balears",
                    "city": "Llubí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07440",
                    "province": "Illes Balears",
                    "city": "Muro",
                    "multiple": "0"
                },
                {
                    "zipcode": "07450",
                    "province": "Illes Balears",
                    "city": "Santa Margalida",
                    "multiple": "0"
                },
                {
                    "zipcode": "07458",
                    "province": "Illes Balears",
                    "city": "Santa Margalida",
                    "multiple": "0"
                },
                {
                    "zipcode": "07458",
                    "province": "Illes Balears",
                    "city": "Muro",
                    "multiple": "1"
                },
                {
                    "zipcode": "07459",
                    "province": "Illes Balears",
                    "city": "Santa Margalida",
                    "multiple": "0"
                },
                {
                    "zipcode": "07460",
                    "province": "Illes Balears",
                    "city": "Pollença",
                    "multiple": "0"
                },
                {
                    "zipcode": "07469",
                    "province": "Illes Balears",
                    "city": "Pollença",
                    "multiple": "0"
                },
                {
                    "zipcode": "07470",
                    "province": "Illes Balears",
                    "city": "Pollença",
                    "multiple": "0"
                },
                {
                    "zipcode": "07500",
                    "province": "Illes Balears",
                    "city": "Manacor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07509",
                    "province": "Illes Balears",
                    "city": "Manacor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07510",
                    "province": "Illes Balears",
                    "city": "Sineu",
                    "multiple": "0"
                },
                {
                    "zipcode": "07511",
                    "province": "Illes Balears",
                    "city": "Sencelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "07518",
                    "province": "Illes Balears",
                    "city": "Lloret de Vistalegre",
                    "multiple": "0"
                },
                {
                    "zipcode": "07519",
                    "province": "Illes Balears",
                    "city": "Maria de la Salut",
                    "multiple": "0"
                },
                {
                    "zipcode": "07520",
                    "province": "Illes Balears",
                    "city": "Petra",
                    "multiple": "0"
                },
                {
                    "zipcode": "07529",
                    "province": "Illes Balears",
                    "city": "Ariany",
                    "multiple": "0"
                },
                {
                    "zipcode": "07530",
                    "province": "Illes Balears",
                    "city": "Sant Llorenç des Cardassar",
                    "multiple": "0"
                },
                {
                    "zipcode": "07540",
                    "province": "Illes Balears",
                    "city": "Sant Llorenç des Cardassar",
                    "multiple": "0"
                },
                {
                    "zipcode": "07550",
                    "province": "Illes Balears",
                    "city": "Son Servera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07559",
                    "province": "Illes Balears",
                    "city": "Son Servera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07560",
                    "province": "Illes Balears",
                    "city": "Sant Llorenç des Cardassar",
                    "multiple": "0"
                },
                {
                    "zipcode": "07560",
                    "province": "Illes Balears",
                    "city": "Son Servera",
                    "multiple": "1"
                },
                {
                    "zipcode": "07560",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "1"
                },
                {
                    "zipcode": "07570",
                    "province": "Illes Balears",
                    "city": "Artà",
                    "multiple": "0"
                },
                {
                    "zipcode": "07579",
                    "province": "Illes Balears",
                    "city": "Artà",
                    "multiple": "0"
                },
                {
                    "zipcode": "07580",
                    "province": "Illes Balears",
                    "city": "Capdepera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07589",
                    "province": "Illes Balears",
                    "city": "Capdepera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07590",
                    "province": "Illes Balears",
                    "city": "Son Servera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07590",
                    "province": "Illes Balears",
                    "city": "Capdepera",
                    "multiple": "1"
                },
                {
                    "zipcode": "07600",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07600",
                    "province": "Illes Balears",
                    "city": "Llucmajor",
                    "multiple": "1"
                },
                {
                    "zipcode": "07608",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07609",
                    "province": "Illes Balears",
                    "city": "Llucmajor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07610",
                    "province": "Illes Balears",
                    "city": "Palma de Mallorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07620",
                    "province": "Illes Balears",
                    "city": "Llucmajor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07629",
                    "province": "Illes Balears",
                    "city": "Algaida",
                    "multiple": "0"
                },
                {
                    "zipcode": "07630",
                    "province": "Illes Balears",
                    "city": "Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "07638",
                    "province": "Illes Balears",
                    "city": "Salines, Ses",
                    "multiple": "0"
                },
                {
                    "zipcode": "07639",
                    "province": "Illes Balears",
                    "city": "Llucmajor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07639",
                    "province": "Illes Balears",
                    "city": "Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "07640",
                    "province": "Illes Balears",
                    "city": "Salines, Ses",
                    "multiple": "0"
                },
                {
                    "zipcode": "07650",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07659",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07660",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07669",
                    "province": "Illes Balears",
                    "city": "Felanitx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07669",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "1"
                },
                {
                    "zipcode": "07670",
                    "province": "Illes Balears",
                    "city": "Felanitx",
                    "multiple": "0"
                },
                {
                    "zipcode": "07680",
                    "province": "Illes Balears",
                    "city": "Manacor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07687",
                    "province": "Illes Balears",
                    "city": "Sant Llorenç des Cardassar",
                    "multiple": "0"
                },
                {
                    "zipcode": "07687",
                    "province": "Illes Balears",
                    "city": "Manacor",
                    "multiple": "1"
                },
                {
                    "zipcode": "07688",
                    "province": "Illes Balears",
                    "city": "Manacor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07689",
                    "province": "Illes Balears",
                    "city": "Manacor",
                    "multiple": "0"
                },
                {
                    "zipcode": "07690",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07691",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "0"
                },
                {
                    "zipcode": "07700",
                    "province": "Illes Balears",
                    "city": "Maó-Mahón",
                    "multiple": "0"
                },
                {
                    "zipcode": "07701",
                    "province": "Illes Balears",
                    "city": "Maó-Mahón",
                    "multiple": "0"
                },
                {
                    "zipcode": "07702",
                    "province": "Illes Balears",
                    "city": "Maó-Mahón",
                    "multiple": "0"
                },
                {
                    "zipcode": "07703",
                    "province": "Illes Balears",
                    "city": "Maó-Mahón",
                    "multiple": "0"
                },
                {
                    "zipcode": "07710",
                    "province": "Illes Balears",
                    "city": "Sant Lluís",
                    "multiple": "0"
                },
                {
                    "zipcode": "07711",
                    "province": "Illes Balears",
                    "city": "Sant Lluís",
                    "multiple": "0"
                },
                {
                    "zipcode": "07712",
                    "province": "Illes Balears",
                    "city": "Maó-Mahón",
                    "multiple": "0"
                },
                {
                    "zipcode": "07713",
                    "province": "Illes Balears",
                    "city": "Sant Lluís",
                    "multiple": "0"
                },
                {
                    "zipcode": "07714",
                    "province": "Illes Balears",
                    "city": "Maó-Mahón",
                    "multiple": "0"
                },
                {
                    "zipcode": "07720",
                    "province": "Illes Balears",
                    "city": "Castell, Es",
                    "multiple": "0"
                },
                {
                    "zipcode": "07720",
                    "province": "Illes Balears",
                    "city": "Sant Joan",
                    "multiple": "1"
                },
                {
                    "zipcode": "07730",
                    "province": "Illes Balears",
                    "city": "Mercadal, Es",
                    "multiple": "0"
                },
                {
                    "zipcode": "07730",
                    "province": "Illes Balears",
                    "city": "Alaior",
                    "multiple": "1"
                },
                {
                    "zipcode": "07740",
                    "province": "Illes Balears",
                    "city": "Mercadal, Es",
                    "multiple": "0"
                },
                {
                    "zipcode": "07748",
                    "province": "Illes Balears",
                    "city": "Mercadal, Es",
                    "multiple": "0"
                },
                {
                    "zipcode": "07749",
                    "province": "Illes Balears",
                    "city": "Migjorn Gran, Es",
                    "multiple": "0"
                },
                {
                    "zipcode": "07750",
                    "province": "Illes Balears",
                    "city": "Ferreries",
                    "multiple": "0"
                },
                {
                    "zipcode": "07750",
                    "province": "Illes Balears",
                    "city": "Ciutadella de Menorca",
                    "multiple": "1"
                },
                {
                    "zipcode": "07750",
                    "province": "Illes Balears",
                    "city": "Son Servera",
                    "multiple": "1"
                },
                {
                    "zipcode": "07760",
                    "province": "Illes Balears",
                    "city": "Ciutadella de Menorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07760",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "1"
                },
                {
                    "zipcode": "07769",
                    "province": "Illes Balears",
                    "city": "Ciutadella de Menorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "07800",
                    "province": "Illes Balears",
                    "city": "Eivissa",
                    "multiple": "0"
                },
                {
                    "zipcode": "07800",
                    "province": "Illes Balears",
                    "city": "Sant Antoni de Portmany",
                    "multiple": "1"
                },
                {
                    "zipcode": "07800",
                    "province": "Illes Balears",
                    "city": "Santa Eulària des Riu",
                    "multiple": "1"
                },
                {
                    "zipcode": "07810",
                    "province": "Illes Balears",
                    "city": "Sant Joan de Labritja",
                    "multiple": "0"
                },
                {
                    "zipcode": "07811",
                    "province": "Illes Balears",
                    "city": "Sant Joan de Labritja",
                    "multiple": "0"
                },
                {
                    "zipcode": "07812",
                    "province": "Illes Balears",
                    "city": "Sant Joan de Labritja",
                    "multiple": "0"
                },
                {
                    "zipcode": "07814",
                    "province": "Illes Balears",
                    "city": "Santa Eulària des Riu",
                    "multiple": "0"
                },
                {
                    "zipcode": "07815",
                    "province": "Illes Balears",
                    "city": "Sant Joan de Labritja",
                    "multiple": "0"
                },
                {
                    "zipcode": "07816",
                    "province": "Illes Balears",
                    "city": "Sant Antoni de Portmany",
                    "multiple": "0"
                },
                {
                    "zipcode": "07817",
                    "province": "Illes Balears",
                    "city": "Sant Josep de sa Talaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "07818",
                    "province": "Illes Balears",
                    "city": "Sant Josep de sa Talaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "07819",
                    "province": "Illes Balears",
                    "city": "Santa Eulària des Riu",
                    "multiple": "0"
                },
                {
                    "zipcode": "07820",
                    "province": "Illes Balears",
                    "city": "Sant Antoni de Portmany",
                    "multiple": "0"
                },
                {
                    "zipcode": "07828",
                    "province": "Illes Balears",
                    "city": "Sant Antoni de Portmany",
                    "multiple": "0"
                },
                {
                    "zipcode": "07829",
                    "province": "Illes Balears",
                    "city": "Sant Josep de sa Talaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "07830",
                    "province": "Illes Balears",
                    "city": "Sant Josep de sa Talaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "07839",
                    "province": "Illes Balears",
                    "city": "Sant Josep de sa Talaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "07840",
                    "province": "Illes Balears",
                    "city": "Santa Eulària des Riu",
                    "multiple": "0"
                },
                {
                    "zipcode": "07849",
                    "province": "Illes Balears",
                    "city": "Santa Eulària des Riu",
                    "multiple": "0"
                },
                {
                    "zipcode": "07850",
                    "province": "Illes Balears",
                    "city": "Santa Eulària des Riu",
                    "multiple": "0"
                },
                {
                    "zipcode": "07850",
                    "province": "Illes Balears",
                    "city": "Santanyí",
                    "multiple": "1"
                },
                {
                    "zipcode": "07860",
                    "province": "Illes Balears",
                    "city": "Formentera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07870",
                    "province": "Illes Balears",
                    "city": "Formentera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07871",
                    "province": "Illes Balears",
                    "city": "Formentera",
                    "multiple": "0"
                },
                {
                    "zipcode": "07872",
                    "province": "Illes Balears",
                    "city": "Formentera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08001",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08002",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08003",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08004",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08005",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08006",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08007",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08008",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08009",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08010",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08011",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08012",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08013",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08014",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08015",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08016",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08017",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08018",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08019",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08020",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08021",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08022",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08023",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08024",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08025",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08026",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "1"
                },
                {
                    "zipcode": "08026",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "1"
                },
                {
                    "zipcode": "08027",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08028",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08029",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08030",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08031",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08032",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08033",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08034",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08035",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08036",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08037",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08038",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08039",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08040",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08041",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08042",
                    "province": "Barcelona",
                    "city": "Barcelona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08100",
                    "province": "Barcelona",
                    "city": "Mollet del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08104",
                    "province": "Barcelona",
                    "city": "Mollet del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08105",
                    "province": "Barcelona",
                    "city": "Sant Fost de Campsentelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08106",
                    "province": "Barcelona",
                    "city": "Santa Maria de Martorelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08107",
                    "province": "Barcelona",
                    "city": "Martorelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08110",
                    "province": "Barcelona",
                    "city": "Montcada i Reixac",
                    "multiple": "0"
                },
                {
                    "zipcode": "08120",
                    "province": "Barcelona",
                    "city": "Llagosta, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "08130",
                    "province": "Barcelona",
                    "city": "Santa Perpètua de Mogoda",
                    "multiple": "0"
                },
                {
                    "zipcode": "08140",
                    "province": "Barcelona",
                    "city": "Caldes de Montbui",
                    "multiple": "0"
                },
                {
                    "zipcode": "08146",
                    "province": "Barcelona",
                    "city": "Gallifa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08148",
                    "province": "Barcelona",
                    "city": "Estany, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08148",
                    "province": "Barcelona",
                    "city": "Castellolí",
                    "multiple": "1"
                },
                {
                    "zipcode": "08150",
                    "province": "Barcelona",
                    "city": "Parets del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08160",
                    "province": "Barcelona",
                    "city": "Montmeló",
                    "multiple": "0"
                },
                {
                    "zipcode": "08161",
                    "province": "Barcelona",
                    "city": "Rubí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08170",
                    "province": "Barcelona",
                    "city": "Montornès del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08172",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08173",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08174",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08175",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08178",
                    "province": "Barcelona",
                    "city": "Collsuspina",
                    "multiple": "0"
                },
                {
                    "zipcode": "08180",
                    "province": "Barcelona",
                    "city": "Moià",
                    "multiple": "0"
                },
                {
                    "zipcode": "08181",
                    "province": "Barcelona",
                    "city": "Sentmenat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08182",
                    "province": "Barcelona",
                    "city": "Sant Feliu de Codines",
                    "multiple": "0"
                },
                {
                    "zipcode": "08183",
                    "province": "Barcelona",
                    "city": "Granera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08183",
                    "province": "Barcelona",
                    "city": "Castellcir",
                    "multiple": "1"
                },
                {
                    "zipcode": "08183",
                    "province": "Barcelona",
                    "city": "Castellterçol",
                    "multiple": "1"
                },
                {
                    "zipcode": "08184",
                    "province": "Barcelona",
                    "city": "Palau-solità i Plegamans",
                    "multiple": "0"
                },
                {
                    "zipcode": "08184",
                    "province": "Barcelona",
                    "city": "Estany, L'",
                    "multiple": "1"
                },
                {
                    "zipcode": "08185",
                    "province": "Barcelona",
                    "city": "Lliçà de Vall",
                    "multiple": "0"
                },
                {
                    "zipcode": "08186",
                    "province": "Barcelona",
                    "city": "Lliçà d'Amunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "08187",
                    "province": "Barcelona",
                    "city": "Santa Eulàlia de Ronçana",
                    "multiple": "0"
                },
                {
                    "zipcode": "08188",
                    "province": "Barcelona",
                    "city": "Vallromanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "08189",
                    "province": "Barcelona",
                    "city": "Sant Quirze Safaja",
                    "multiple": "0"
                },
                {
                    "zipcode": "08190",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08191",
                    "province": "Barcelona",
                    "city": "Rubí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08192",
                    "province": "Barcelona",
                    "city": "Sant Quirze del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08193",
                    "province": "Barcelona",
                    "city": "Cerdanyola del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08194",
                    "province": "Barcelona",
                    "city": "Sant Quirze del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08195",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08196",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08197",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08198",
                    "province": "Barcelona",
                    "city": "Sant Cugat del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08201",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08202",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08203",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08204",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08205",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08206",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08207",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08208",
                    "province": "Barcelona",
                    "city": "Sabadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08210",
                    "province": "Barcelona",
                    "city": "Barberà del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08211",
                    "province": "Barcelona",
                    "city": "Castellar del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08212",
                    "province": "Barcelona",
                    "city": "Sant Llorenç Savall",
                    "multiple": "0"
                },
                {
                    "zipcode": "08213",
                    "province": "Barcelona",
                    "city": "Polinyà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08214",
                    "province": "Barcelona",
                    "city": "Badia del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08220",
                    "province": "Barcelona",
                    "city": "Sant Boi de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08221",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08222",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08223",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08224",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08225",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08226",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08227",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08228",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08230",
                    "province": "Barcelona",
                    "city": "Matadepera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08231",
                    "province": "Barcelona",
                    "city": "Ullastrell",
                    "multiple": "1"
                },
                {
                    "zipcode": "08231",
                    "province": "Barcelona",
                    "city": "Nou de Berguedà, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "08232",
                    "province": "Barcelona",
                    "city": "Viladecavalls",
                    "multiple": "0"
                },
                {
                    "zipcode": "08233",
                    "province": "Barcelona",
                    "city": "Vacarisses",
                    "multiple": "0"
                },
                {
                    "zipcode": "08240",
                    "province": "Barcelona",
                    "city": "Manresa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08241",
                    "province": "Barcelona",
                    "city": "Manresa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08242",
                    "province": "Barcelona",
                    "city": "Manresa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08243",
                    "province": "Barcelona",
                    "city": "Manresa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08250",
                    "province": "Barcelona",
                    "city": "Sant Joan de Vilatorrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "08251",
                    "province": "Barcelona",
                    "city": "Castellnou de Bages",
                    "multiple": "0"
                },
                {
                    "zipcode": "08251",
                    "province": "Barcelona",
                    "city": "Santpedor",
                    "multiple": "1"
                },
                {
                    "zipcode": "08253",
                    "province": "Barcelona",
                    "city": "Sant Salvador de Guardiola",
                    "multiple": "0"
                },
                {
                    "zipcode": "08254",
                    "province": "Barcelona",
                    "city": "Pont de Vilomara i Rocafort, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "08254",
                    "province": "Barcelona",
                    "city": "Manresa",
                    "multiple": "1"
                },
                {
                    "zipcode": "08255",
                    "province": "Barcelona",
                    "city": "Castellfollit del Boix",
                    "multiple": "0"
                },
                {
                    "zipcode": "08256",
                    "province": "Barcelona",
                    "city": "Rajadell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08256",
                    "province": "Barcelona",
                    "city": "Aguilar de Segarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "08259",
                    "province": "Barcelona",
                    "city": "Fonollosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08260",
                    "province": "Barcelona",
                    "city": "Súria",
                    "multiple": "0"
                },
                {
                    "zipcode": "08261",
                    "province": "Barcelona",
                    "city": "Cardona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08262",
                    "province": "Barcelona",
                    "city": "Callús",
                    "multiple": "0"
                },
                {
                    "zipcode": "08263",
                    "province": "Barcelona",
                    "city": "Sant Mateu de Bages",
                    "multiple": "0"
                },
                {
                    "zipcode": "08269",
                    "province": "Barcelona",
                    "city": "Navàs",
                    "multiple": "0"
                },
                {
                    "zipcode": "08269",
                    "province": "Barcelona",
                    "city": "Sant Mateu de Bages",
                    "multiple": "1"
                },
                {
                    "zipcode": "08270",
                    "province": "Barcelona",
                    "city": "Navarcles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08270",
                    "province": "Barcelona",
                    "city": "Navàs",
                    "multiple": "1"
                },
                {
                    "zipcode": "08271",
                    "province": "Barcelona",
                    "city": "Artés",
                    "multiple": "0"
                },
                {
                    "zipcode": "08272",
                    "province": "Barcelona",
                    "city": "Sant Fruitós de Bages",
                    "multiple": "0"
                },
                {
                    "zipcode": "08273",
                    "province": "Barcelona",
                    "city": "Santa Maria d'Oló",
                    "multiple": "0"
                },
                {
                    "zipcode": "08274",
                    "province": "Barcelona",
                    "city": "Sant Feliu Sasserra",
                    "multiple": "0"
                },
                {
                    "zipcode": "08275",
                    "province": "Barcelona",
                    "city": "Monistrol de Calders",
                    "multiple": "0"
                },
                {
                    "zipcode": "08275",
                    "province": "Barcelona",
                    "city": "Calders",
                    "multiple": "1"
                },
                {
                    "zipcode": "08278",
                    "province": "Barcelona",
                    "city": "Talamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "08278",
                    "province": "Barcelona",
                    "city": "Mura",
                    "multiple": "1"
                },
                {
                    "zipcode": "08279",
                    "province": "Barcelona",
                    "city": "Avinyó",
                    "multiple": "0"
                },
                {
                    "zipcode": "08280",
                    "province": "Barcelona",
                    "city": "Calaf",
                    "multiple": "0"
                },
                {
                    "zipcode": "08281",
                    "province": "Barcelona",
                    "city": "Prats de Rei, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "08281",
                    "province": "Barcelona",
                    "city": "Calonge de Segarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "08281",
                    "province": "Barcelona",
                    "city": "Sant Pere Sallavinera",
                    "multiple": "1"
                },
                {
                    "zipcode": "08281",
                    "province": "Barcelona",
                    "city": "Pujalt",
                    "multiple": "1"
                },
                {
                    "zipcode": "08281",
                    "province": "Barcelona",
                    "city": "Molsosa, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "08281",
                    "province": "Barcelona",
                    "city": "Castellfollit de Riubregós",
                    "multiple": "1"
                },
                {
                    "zipcode": "08282",
                    "province": "Barcelona",
                    "city": "Pujalt",
                    "multiple": "0"
                },
                {
                    "zipcode": "08282",
                    "province": "Barcelona",
                    "city": "Sant Martí Sesgueioles",
                    "multiple": "1"
                },
                {
                    "zipcode": "08283",
                    "province": "Barcelona",
                    "city": "Castellfollit de Riubregós",
                    "multiple": "0"
                },
                {
                    "zipcode": "08289",
                    "province": "Barcelona",
                    "city": "Sant Guim de Freixenet",
                    "multiple": "0"
                },
                {
                    "zipcode": "08289",
                    "province": "Barcelona",
                    "city": "Veciana",
                    "multiple": "1"
                },
                {
                    "zipcode": "08289",
                    "province": "Barcelona",
                    "city": "Copons",
                    "multiple": "1"
                },
                {
                    "zipcode": "08290",
                    "province": "Barcelona",
                    "city": "Cerdanyola del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08291",
                    "province": "Barcelona",
                    "city": "Ripollet",
                    "multiple": "0"
                },
                {
                    "zipcode": "08292",
                    "province": "Barcelona",
                    "city": "Esparreguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08293",
                    "province": "Barcelona",
                    "city": "Collbató",
                    "multiple": "0"
                },
                {
                    "zipcode": "08294",
                    "province": "Barcelona",
                    "city": "Bruc, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "08295",
                    "province": "Barcelona",
                    "city": "Sant Vicenç de Castellet",
                    "multiple": "0"
                },
                {
                    "zipcode": "08296",
                    "province": "Barcelona",
                    "city": "Castellbell i el Vilar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08297",
                    "province": "Barcelona",
                    "city": "Castellgalí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08298",
                    "province": "Barcelona",
                    "city": "Marganell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08299",
                    "province": "Barcelona",
                    "city": "Rellinars",
                    "multiple": "0"
                },
                {
                    "zipcode": "08301",
                    "province": "Barcelona",
                    "city": "Mataró",
                    "multiple": "0"
                },
                {
                    "zipcode": "08302",
                    "province": "Barcelona",
                    "city": "Mataró",
                    "multiple": "0"
                },
                {
                    "zipcode": "08303",
                    "province": "Barcelona",
                    "city": "Mataró",
                    "multiple": "0"
                },
                {
                    "zipcode": "08304",
                    "province": "Barcelona",
                    "city": "Mataró",
                    "multiple": "0"
                },
                {
                    "zipcode": "08310",
                    "province": "Barcelona",
                    "city": "Argentona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08317",
                    "province": "Barcelona",
                    "city": "Òrrius",
                    "multiple": "0"
                },
                {
                    "zipcode": "08318",
                    "province": "Barcelona",
                    "city": "Dosrius",
                    "multiple": "0"
                },
                {
                    "zipcode": "08319",
                    "province": "Barcelona",
                    "city": "Dosrius",
                    "multiple": "0"
                },
                {
                    "zipcode": "08320",
                    "province": "Barcelona",
                    "city": "Masnou, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "08328",
                    "province": "Barcelona",
                    "city": "Alella",
                    "multiple": "0"
                },
                {
                    "zipcode": "08329",
                    "province": "Barcelona",
                    "city": "Teià",
                    "multiple": "0"
                },
                {
                    "zipcode": "08330",
                    "province": "Barcelona",
                    "city": "Premià de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08338",
                    "province": "Barcelona",
                    "city": "Premià de Dalt",
                    "multiple": "0"
                },
                {
                    "zipcode": "08338",
                    "province": "Barcelona",
                    "city": "Premià de Dalt",
                    "multiple": "1"
                },
                {
                    "zipcode": "08339",
                    "province": "Barcelona",
                    "city": "Vilassar de Dalt",
                    "multiple": "0"
                },
                {
                    "zipcode": "08340",
                    "province": "Barcelona",
                    "city": "Vilassar de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08348",
                    "province": "Barcelona",
                    "city": "Cabrils",
                    "multiple": "0"
                },
                {
                    "zipcode": "08349",
                    "province": "Barcelona",
                    "city": "Cabrera de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08350",
                    "province": "Barcelona",
                    "city": "Arenys de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08350",
                    "province": "Barcelona",
                    "city": "Arenys de Munt",
                    "multiple": "1"
                },
                {
                    "zipcode": "08358",
                    "province": "Barcelona",
                    "city": "Arenys de Munt",
                    "multiple": "0"
                },
                {
                    "zipcode": "08359",
                    "province": "Barcelona",
                    "city": "Sant Iscle de Vallalta",
                    "multiple": "0"
                },
                {
                    "zipcode": "08360",
                    "province": "Barcelona",
                    "city": "Canet de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08370",
                    "province": "Barcelona",
                    "city": "Calella",
                    "multiple": "0"
                },
                {
                    "zipcode": "08380",
                    "province": "Barcelona",
                    "city": "Malgrat de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08384",
                    "province": "Barcelona",
                    "city": "Cabrils",
                    "multiple": "0"
                },
                {
                    "zipcode": "08389",
                    "province": "Barcelona",
                    "city": "Palafolls",
                    "multiple": "0"
                },
                {
                    "zipcode": "08390",
                    "province": "Barcelona",
                    "city": "Montgat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08391",
                    "province": "Barcelona",
                    "city": "Tiana",
                    "multiple": "0"
                },
                {
                    "zipcode": "08392",
                    "province": "Barcelona",
                    "city": "Sant Andreu de Llavaneres",
                    "multiple": "0"
                },
                {
                    "zipcode": "08393",
                    "province": "Barcelona",
                    "city": "Caldes d'Estrac",
                    "multiple": "0"
                },
                {
                    "zipcode": "08394",
                    "province": "Barcelona",
                    "city": "Sant Vicenç de Montalt",
                    "multiple": "0"
                },
                {
                    "zipcode": "08395",
                    "province": "Barcelona",
                    "city": "Sant Pol de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08396",
                    "province": "Barcelona",
                    "city": "Sant Cebrià de Vallalta",
                    "multiple": "0"
                },
                {
                    "zipcode": "08397",
                    "province": "Barcelona",
                    "city": "Pineda de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08398",
                    "province": "Barcelona",
                    "city": "Santa Susanna",
                    "multiple": "0"
                },
                {
                    "zipcode": "08401",
                    "province": "Barcelona",
                    "city": "Granollers",
                    "multiple": "0"
                },
                {
                    "zipcode": "08401",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "1"
                },
                {
                    "zipcode": "08402",
                    "province": "Barcelona",
                    "city": "Granollers",
                    "multiple": "0"
                },
                {
                    "zipcode": "08403",
                    "province": "Barcelona",
                    "city": "Granollers",
                    "multiple": "0"
                },
                {
                    "zipcode": "08410",
                    "province": "Barcelona",
                    "city": "Vilanova del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08415",
                    "province": "Barcelona",
                    "city": "Bigues i Riells",
                    "multiple": "0"
                },
                {
                    "zipcode": "08416",
                    "province": "Barcelona",
                    "city": "Bigues i Riells",
                    "multiple": "0"
                },
                {
                    "zipcode": "08420",
                    "province": "Barcelona",
                    "city": "Canovelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08430",
                    "province": "Barcelona",
                    "city": "Roca del Vallès, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "08440",
                    "province": "Barcelona",
                    "city": "Cànoves i Samalús",
                    "multiple": "0"
                },
                {
                    "zipcode": "08440",
                    "province": "Barcelona",
                    "city": "Cardedeu",
                    "multiple": "1"
                },
                {
                    "zipcode": "08445",
                    "province": "Barcelona",
                    "city": "Cànoves i Samalús",
                    "multiple": "0"
                },
                {
                    "zipcode": "08450",
                    "province": "Barcelona",
                    "city": "Llinars del Vallès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08455",
                    "province": "Barcelona",
                    "city": "Vilalba Sasserra",
                    "multiple": "0"
                },
                {
                    "zipcode": "08458",
                    "province": "Barcelona",
                    "city": "Sant Llorenç Savall",
                    "multiple": "0"
                },
                {
                    "zipcode": "08458",
                    "province": "Barcelona",
                    "city": "Sant Pere de Vilamajor",
                    "multiple": "1"
                },
                {
                    "zipcode": "08459",
                    "province": "Barcelona",
                    "city": "Sant Pere de Vilamajor",
                    "multiple": "0"
                },
                {
                    "zipcode": "08459",
                    "province": "Barcelona",
                    "city": "Sant Antoni de Vilamajor",
                    "multiple": "1"
                },
                {
                    "zipcode": "08460",
                    "province": "Barcelona",
                    "city": "Santa Maria de Palautordera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08460",
                    "province": "Barcelona",
                    "city": "Sant Pere de Vilamajor",
                    "multiple": "1"
                },
                {
                    "zipcode": "08460",
                    "province": "Barcelona",
                    "city": "Sant Esteve de Palautordera",
                    "multiple": "1"
                },
                {
                    "zipcode": "08461",
                    "province": "Barcelona",
                    "city": "Sant Esteve de Palautordera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08469",
                    "province": "Barcelona",
                    "city": "Montseny",
                    "multiple": "0"
                },
                {
                    "zipcode": "08469",
                    "province": "Barcelona",
                    "city": "Fogars de Montclús",
                    "multiple": "1"
                },
                {
                    "zipcode": "08470",
                    "province": "Barcelona",
                    "city": "Vallgorguina",
                    "multiple": "0"
                },
                {
                    "zipcode": "08470",
                    "province": "Barcelona",
                    "city": "Sant Celoni",
                    "multiple": "1"
                },
                {
                    "zipcode": "08471",
                    "province": "Barcelona",
                    "city": "Vallgorguina",
                    "multiple": "0"
                },
                {
                    "zipcode": "08472",
                    "province": "Barcelona",
                    "city": "Campins",
                    "multiple": "0"
                },
                {
                    "zipcode": "08474",
                    "province": "Barcelona",
                    "city": "Gualba",
                    "multiple": "0"
                },
                {
                    "zipcode": "08476",
                    "province": "Barcelona",
                    "city": "Sant Celoni",
                    "multiple": "0"
                },
                {
                    "zipcode": "08479",
                    "province": "Barcelona",
                    "city": "Fogars de Montclús",
                    "multiple": "0"
                },
                {
                    "zipcode": "08480",
                    "province": "Barcelona",
                    "city": "Ametlla del Vallès, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08490",
                    "province": "Barcelona",
                    "city": "Tordera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08495",
                    "province": "Barcelona",
                    "city": "Fogars de la Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "08500",
                    "province": "Barcelona",
                    "city": "Vic",
                    "multiple": "0"
                },
                {
                    "zipcode": "08503",
                    "province": "Barcelona",
                    "city": "Sant Bartomeu del Grau",
                    "multiple": "0"
                },
                {
                    "zipcode": "08503",
                    "province": "Barcelona",
                    "city": "Gurb",
                    "multiple": "1"
                },
                {
                    "zipcode": "08504",
                    "province": "Barcelona",
                    "city": "Sant Sadurní d'Osormort",
                    "multiple": "0"
                },
                {
                    "zipcode": "08504",
                    "province": "Barcelona",
                    "city": "Sant Julià de Vilatorta",
                    "multiple": "1"
                },
                {
                    "zipcode": "08505",
                    "province": "Barcelona",
                    "city": "Santa Eulàlia de Riuprimer",
                    "multiple": "0"
                },
                {
                    "zipcode": "08506",
                    "province": "Barcelona",
                    "city": "Calldetenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "08507",
                    "province": "Barcelona",
                    "city": "Santa Eugènia de Berga",
                    "multiple": "0"
                },
                {
                    "zipcode": "08508",
                    "province": "Barcelona",
                    "city": "Sant Quirze de Besora",
                    "multiple": "0"
                },
                {
                    "zipcode": "08508",
                    "province": "Barcelona",
                    "city": "Masies de Voltregà, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "08509",
                    "province": "Barcelona",
                    "city": "Masies de Voltregà, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "08509",
                    "province": "Barcelona",
                    "city": "Santa Cecília de Voltregà",
                    "multiple": "1"
                },
                {
                    "zipcode": "08510",
                    "province": "Barcelona",
                    "city": "Masies de Roda, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "08510",
                    "province": "Barcelona",
                    "city": "Roda de Ter",
                    "multiple": "1"
                },
                {
                    "zipcode": "08511",
                    "province": "Barcelona",
                    "city": "Esquirol, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08511",
                    "province": "Barcelona",
                    "city": "Tavertet",
                    "multiple": "1"
                },
                {
                    "zipcode": "08512",
                    "province": "Barcelona",
                    "city": "Sant Hipòlit de Voltregà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08513",
                    "province": "Barcelona",
                    "city": "Prats de Lluçanès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08514",
                    "province": "Barcelona",
                    "city": "Lluçà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08515",
                    "province": "Barcelona",
                    "city": "Sant Martí d'Albars",
                    "multiple": "0"
                },
                {
                    "zipcode": "08516",
                    "province": "Barcelona",
                    "city": "Olost",
                    "multiple": "0"
                },
                {
                    "zipcode": "08517",
                    "province": "Barcelona",
                    "city": "Sagàs",
                    "multiple": "0"
                },
                {
                    "zipcode": "08517",
                    "province": "Barcelona",
                    "city": "Santa Maria de Merlès",
                    "multiple": "1"
                },
                {
                    "zipcode": "08518",
                    "province": "Barcelona",
                    "city": "Oristà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08519",
                    "province": "Barcelona",
                    "city": "Vilanova de Sau",
                    "multiple": "0"
                },
                {
                    "zipcode": "08519",
                    "province": "Barcelona",
                    "city": "Malla",
                    "multiple": "1"
                },
                {
                    "zipcode": "08519",
                    "province": "Barcelona",
                    "city": "Folgueroles",
                    "multiple": "1"
                },
                {
                    "zipcode": "08519",
                    "province": "Barcelona",
                    "city": "Tavèrnoles",
                    "multiple": "1"
                },
                {
                    "zipcode": "08519",
                    "province": "Barcelona",
                    "city": "Vic",
                    "multiple": "1"
                },
                {
                    "zipcode": "08520",
                    "province": "Barcelona",
                    "city": "Franqueses del Vallès, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "08521",
                    "province": "Barcelona",
                    "city": "Franqueses del Vallès, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "08529",
                    "province": "Barcelona",
                    "city": "Muntanyola",
                    "multiple": "0"
                },
                {
                    "zipcode": "08530",
                    "province": "Barcelona",
                    "city": "Cànoves i Samalús",
                    "multiple": "0"
                },
                {
                    "zipcode": "08530",
                    "province": "Barcelona",
                    "city": "Garriga, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "08540",
                    "province": "Barcelona",
                    "city": "Centelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08550",
                    "province": "Barcelona",
                    "city": "Balenyà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08551",
                    "province": "Barcelona",
                    "city": "Tona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08552",
                    "province": "Barcelona",
                    "city": "Taradell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08553",
                    "province": "Barcelona",
                    "city": "Seva",
                    "multiple": "0"
                },
                {
                    "zipcode": "08554",
                    "province": "Barcelona",
                    "city": "Seva",
                    "multiple": "0"
                },
                {
                    "zipcode": "08559",
                    "province": "Barcelona",
                    "city": "Brull, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "08560",
                    "province": "Barcelona",
                    "city": "Manlleu",
                    "multiple": "0"
                },
                {
                    "zipcode": "08569",
                    "province": "Barcelona",
                    "city": "Esquirol, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08569",
                    "province": "Barcelona",
                    "city": "Rupit i Pruit",
                    "multiple": "1"
                },
                {
                    "zipcode": "08570",
                    "province": "Barcelona",
                    "city": "Torelló",
                    "multiple": "0"
                },
                {
                    "zipcode": "08571",
                    "province": "Barcelona",
                    "city": "Sant Vicenç de Torelló",
                    "multiple": "0"
                },
                {
                    "zipcode": "08572",
                    "province": "Barcelona",
                    "city": "Sant Pere de Torelló",
                    "multiple": "0"
                },
                {
                    "zipcode": "08573",
                    "province": "Barcelona",
                    "city": "Orís",
                    "multiple": "0"
                },
                {
                    "zipcode": "08580",
                    "province": "Barcelona",
                    "city": "Sant Quirze de Besora",
                    "multiple": "0"
                },
                {
                    "zipcode": "08584",
                    "province": "Barcelona",
                    "city": "Santa Maria de Besora",
                    "multiple": "0"
                },
                {
                    "zipcode": "08585",
                    "province": "Barcelona",
                    "city": "Montesquiu",
                    "multiple": "0"
                },
                {
                    "zipcode": "08586",
                    "province": "Barcelona",
                    "city": "Sant Agustí de Lluçanès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08587",
                    "province": "Barcelona",
                    "city": "Alpens",
                    "multiple": "0"
                },
                {
                    "zipcode": "08588",
                    "province": "Barcelona",
                    "city": "Sora",
                    "multiple": "0"
                },
                {
                    "zipcode": "08589",
                    "province": "Barcelona",
                    "city": "Sant Boi de Lluçanès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08589",
                    "province": "Barcelona",
                    "city": "Perafita",
                    "multiple": "1"
                },
                {
                    "zipcode": "08589",
                    "province": "Barcelona",
                    "city": "Sobremunt",
                    "multiple": "1"
                },
                {
                    "zipcode": "08589",
                    "province": "Barcelona",
                    "city": "Santa Maria de Besora",
                    "multiple": "1"
                },
                {
                    "zipcode": "08590",
                    "province": "Barcelona",
                    "city": "Figaró-Montmany",
                    "multiple": "0"
                },
                {
                    "zipcode": "08591",
                    "province": "Barcelona",
                    "city": "Aiguafreda",
                    "multiple": "0"
                },
                {
                    "zipcode": "08591",
                    "province": "Barcelona",
                    "city": "Prats de Rei, Els",
                    "multiple": "1"
                },
                {
                    "zipcode": "08592",
                    "province": "Barcelona",
                    "city": "Sant Martí de Centelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08593",
                    "province": "Barcelona",
                    "city": "Tagamanent",
                    "multiple": "0"
                },
                {
                    "zipcode": "08600",
                    "province": "Barcelona",
                    "city": "Berga",
                    "multiple": "0"
                },
                {
                    "zipcode": "08604",
                    "province": "Barcelona",
                    "city": "Castell de l'Areny",
                    "multiple": "0"
                },
                {
                    "zipcode": "08605",
                    "province": "Barcelona",
                    "city": "Montclar",
                    "multiple": "0"
                },
                {
                    "zipcode": "08607",
                    "province": "Barcelona",
                    "city": "Sant Jaume de Frontanyà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08610",
                    "province": "Barcelona",
                    "city": "Avià",
                    "multiple": "0"
                },
                {
                    "zipcode": "08611",
                    "province": "Barcelona",
                    "city": "Olvan",
                    "multiple": "0"
                },
                {
                    "zipcode": "08612",
                    "province": "Barcelona",
                    "city": "Montmajor",
                    "multiple": "0"
                },
                {
                    "zipcode": "08613",
                    "province": "Barcelona",
                    "city": "Vilada",
                    "multiple": "0"
                },
                {
                    "zipcode": "08614",
                    "province": "Barcelona",
                    "city": "Espunyola, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08619",
                    "province": "Barcelona",
                    "city": "Quar, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "08619",
                    "province": "Barcelona",
                    "city": "Borredà",
                    "multiple": "1"
                },
                {
                    "zipcode": "08619",
                    "province": "Barcelona",
                    "city": "Santa Maria de Merlès",
                    "multiple": "1"
                },
                {
                    "zipcode": "08619",
                    "province": "Barcelona",
                    "city": "Sant Jaume de Frontanyà",
                    "multiple": "1"
                },
                {
                    "zipcode": "08619",
                    "province": "Barcelona",
                    "city": "Capolat",
                    "multiple": "1"
                },
                {
                    "zipcode": "08619",
                    "province": "Barcelona",
                    "city": "Sagàs",
                    "multiple": "1"
                },
                {
                    "zipcode": "08619",
                    "province": "Barcelona",
                    "city": "Castellar del Riu",
                    "multiple": "1"
                },
                {
                    "zipcode": "08620",
                    "province": "Barcelona",
                    "city": "Sant Vicenç dels Horts",
                    "multiple": "0"
                },
                {
                    "zipcode": "08629",
                    "province": "Barcelona",
                    "city": "Torrelles de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08630",
                    "province": "Barcelona",
                    "city": "Abrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08635",
                    "province": "Barcelona",
                    "city": "Sant Esteve Sesrovires",
                    "multiple": "0"
                },
                {
                    "zipcode": "08640",
                    "province": "Barcelona",
                    "city": "Olesa de Montserrat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08650",
                    "province": "Barcelona",
                    "city": "Sallent",
                    "multiple": "0"
                },
                {
                    "zipcode": "08660",
                    "province": "Barcelona",
                    "city": "Balsareny",
                    "multiple": "0"
                },
                {
                    "zipcode": "08670",
                    "province": "Barcelona",
                    "city": "Navàs",
                    "multiple": "0"
                },
                {
                    "zipcode": "08672",
                    "province": "Barcelona",
                    "city": "Puig-reig",
                    "multiple": "0"
                },
                {
                    "zipcode": "08672",
                    "province": "Barcelona",
                    "city": "Gaià",
                    "multiple": "1"
                },
                {
                    "zipcode": "08673",
                    "province": "Barcelona",
                    "city": "Viver i Serrateix",
                    "multiple": "0"
                },
                {
                    "zipcode": "08680",
                    "province": "Barcelona",
                    "city": "Gironella",
                    "multiple": "0"
                },
                {
                    "zipcode": "08690",
                    "province": "Barcelona",
                    "city": "Santa Coloma de Cervelló",
                    "multiple": "0"
                },
                {
                    "zipcode": "08691",
                    "province": "Barcelona",
                    "city": "Monistrol de Montserrat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08692",
                    "province": "Barcelona",
                    "city": "Puig-reig",
                    "multiple": "0"
                },
                {
                    "zipcode": "08693",
                    "province": "Barcelona",
                    "city": "Castellar del Riu",
                    "multiple": "0"
                },
                {
                    "zipcode": "08693",
                    "province": "Barcelona",
                    "city": "Casserres",
                    "multiple": "1"
                },
                {
                    "zipcode": "08694",
                    "province": "Barcelona",
                    "city": "Guardiola de Berguedà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08694",
                    "province": "Barcelona",
                    "city": "Sant Julià de Cerdanyola",
                    "multiple": "1"
                },
                {
                    "zipcode": "08695",
                    "province": "Barcelona",
                    "city": "Bagà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08695",
                    "province": "Barcelona",
                    "city": "Gisclareny",
                    "multiple": "1"
                },
                {
                    "zipcode": "08696",
                    "province": "Barcelona",
                    "city": "Castellar de n'Hug",
                    "multiple": "0"
                },
                {
                    "zipcode": "08696",
                    "province": "Barcelona",
                    "city": "Pobla de Lillet, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "08697",
                    "province": "Barcelona",
                    "city": "Josa i Tuixén",
                    "multiple": "0"
                },
                {
                    "zipcode": "08697",
                    "province": "Barcelona",
                    "city": "Saldes",
                    "multiple": "1"
                },
                {
                    "zipcode": "08698",
                    "province": "Barcelona",
                    "city": "Fígols",
                    "multiple": "0"
                },
                {
                    "zipcode": "08698",
                    "province": "Barcelona",
                    "city": "Nou de Berguedà, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "08698",
                    "province": "Barcelona",
                    "city": "Cercs",
                    "multiple": "1"
                },
                {
                    "zipcode": "08699",
                    "province": "Barcelona",
                    "city": "Fígols",
                    "multiple": "0"
                },
                {
                    "zipcode": "08699",
                    "province": "Barcelona",
                    "city": "Avià",
                    "multiple": "1"
                },
                {
                    "zipcode": "08699",
                    "province": "Barcelona",
                    "city": "Olvan",
                    "multiple": "1"
                },
                {
                    "zipcode": "08699",
                    "province": "Barcelona",
                    "city": "Vallcebre",
                    "multiple": "1"
                },
                {
                    "zipcode": "08699",
                    "province": "Barcelona",
                    "city": "Saldes",
                    "multiple": "1"
                },
                {
                    "zipcode": "08700",
                    "province": "Barcelona",
                    "city": "Igualada",
                    "multiple": "0"
                },
                {
                    "zipcode": "08710",
                    "province": "Barcelona",
                    "city": "Santa Margarida de Montbui",
                    "multiple": "0"
                },
                {
                    "zipcode": "08711",
                    "province": "Barcelona",
                    "city": "Òdena",
                    "multiple": "0"
                },
                {
                    "zipcode": "08712",
                    "province": "Barcelona",
                    "city": "Sant Martí de Tous",
                    "multiple": "0"
                },
                {
                    "zipcode": "08714",
                    "province": "Barcelona",
                    "city": "Santa Eulària des Riu",
                    "multiple": "0"
                },
                {
                    "zipcode": "08717",
                    "province": "Barcelona",
                    "city": "Montmaneu",
                    "multiple": "0"
                },
                {
                    "zipcode": "08717",
                    "province": "Barcelona",
                    "city": "Talavera",
                    "multiple": "1"
                },
                {
                    "zipcode": "08717",
                    "province": "Barcelona",
                    "city": "Argençola",
                    "multiple": "1"
                },
                {
                    "zipcode": "08718",
                    "province": "Barcelona",
                    "city": "Cabrera d'Anoia",
                    "multiple": "0"
                },
                {
                    "zipcode": "08719",
                    "province": "Barcelona",
                    "city": "Castellolí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08719",
                    "province": "Barcelona",
                    "city": "Rubió",
                    "multiple": "1"
                },
                {
                    "zipcode": "08719",
                    "province": "Barcelona",
                    "city": "Jorba",
                    "multiple": "1"
                },
                {
                    "zipcode": "08719",
                    "province": "Barcelona",
                    "city": "Bellprat",
                    "multiple": "1"
                },
                {
                    "zipcode": "08720",
                    "province": "Barcelona",
                    "city": "Vilafranca del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08729",
                    "province": "Barcelona",
                    "city": "Castellet i la Gornal",
                    "multiple": "0"
                },
                {
                    "zipcode": "08730",
                    "province": "Barcelona",
                    "city": "Santa Margarida i els Monjos",
                    "multiple": "0"
                },
                {
                    "zipcode": "08731",
                    "province": "Barcelona",
                    "city": "Sant Martí Sarroca",
                    "multiple": "0"
                },
                {
                    "zipcode": "08731",
                    "province": "Barcelona",
                    "city": "Avinyonet del Penedès",
                    "multiple": "1"
                },
                {
                    "zipcode": "08732",
                    "province": "Barcelona",
                    "city": "Castellet i la Gornal",
                    "multiple": "0"
                },
                {
                    "zipcode": "08732",
                    "province": "Barcelona",
                    "city": "Castellví de la Marca",
                    "multiple": "1"
                },
                {
                    "zipcode": "08733",
                    "province": "Barcelona",
                    "city": "Pla del Penedès, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "08734",
                    "province": "Barcelona",
                    "city": "Olèrdola",
                    "multiple": "0"
                },
                {
                    "zipcode": "08734",
                    "province": "Barcelona",
                    "city": "Avinyonet del Penedès",
                    "multiple": "1"
                },
                {
                    "zipcode": "08735",
                    "province": "Barcelona",
                    "city": "Vilobí del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08736",
                    "province": "Barcelona",
                    "city": "Font-rubí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08737",
                    "province": "Barcelona",
                    "city": "Torrelles de Foix",
                    "multiple": "0"
                },
                {
                    "zipcode": "08738",
                    "province": "Barcelona",
                    "city": "Pontons",
                    "multiple": "0"
                },
                {
                    "zipcode": "08739",
                    "province": "Barcelona",
                    "city": "Font-rubí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08739",
                    "province": "Barcelona",
                    "city": "Subirats",
                    "multiple": "1"
                },
                {
                    "zipcode": "08740",
                    "province": "Barcelona",
                    "city": "Sant Andreu de la Barca",
                    "multiple": "0"
                },
                {
                    "zipcode": "08750",
                    "province": "Barcelona",
                    "city": "Molins de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "08753",
                    "province": "Barcelona",
                    "city": "Pallejà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08754",
                    "province": "Barcelona",
                    "city": "Papiol, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "08755",
                    "province": "Barcelona",
                    "city": "Castellbisbal",
                    "multiple": "0"
                },
                {
                    "zipcode": "08756",
                    "province": "Barcelona",
                    "city": "Palma de Cervelló, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "08757",
                    "province": "Barcelona",
                    "city": "Corbera de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08758",
                    "province": "Barcelona",
                    "city": "Cervelló",
                    "multiple": "0"
                },
                {
                    "zipcode": "08759",
                    "province": "Barcelona",
                    "city": "Vallirana",
                    "multiple": "0"
                },
                {
                    "zipcode": "08760",
                    "province": "Barcelona",
                    "city": "Martorell",
                    "multiple": "0"
                },
                {
                    "zipcode": "08769",
                    "province": "Barcelona",
                    "city": "Castellví de Rosanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "08770",
                    "province": "Barcelona",
                    "city": "Sant Sadurní d'Anoia",
                    "multiple": "0"
                },
                {
                    "zipcode": "08770",
                    "province": "Barcelona",
                    "city": "Sant Celoni",
                    "multiple": "1"
                },
                {
                    "zipcode": "08773",
                    "province": "Barcelona",
                    "city": "Mediona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08775",
                    "province": "Barcelona",
                    "city": "Torrelavit",
                    "multiple": "0"
                },
                {
                    "zipcode": "08776",
                    "province": "Barcelona",
                    "city": "Sant Pere de Riudebitlles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08777",
                    "province": "Barcelona",
                    "city": "Sant Quintí de Mediona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08779",
                    "province": "Barcelona",
                    "city": "Llacuna, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "08780",
                    "province": "Barcelona",
                    "city": "Pallejà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08781",
                    "province": "Barcelona",
                    "city": "Hostalets de Pierola, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "08782",
                    "province": "Barcelona",
                    "city": "Masquefa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08783",
                    "province": "Barcelona",
                    "city": "Masquefa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08784",
                    "province": "Barcelona",
                    "city": "Piera",
                    "multiple": "0"
                },
                {
                    "zipcode": "08785",
                    "province": "Barcelona",
                    "city": "Vallbona d'Anoia",
                    "multiple": "0"
                },
                {
                    "zipcode": "08786",
                    "province": "Barcelona",
                    "city": "Capellades",
                    "multiple": "0"
                },
                {
                    "zipcode": "08787",
                    "province": "Barcelona",
                    "city": "Carme",
                    "multiple": "0"
                },
                {
                    "zipcode": "08787",
                    "province": "Barcelona",
                    "city": "Pobla de Claramunt, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "08787",
                    "province": "Barcelona",
                    "city": "Orpí",
                    "multiple": "1"
                },
                {
                    "zipcode": "08787",
                    "province": "Barcelona",
                    "city": "Santa Maria de Miralles",
                    "multiple": "1"
                },
                {
                    "zipcode": "08788",
                    "province": "Barcelona",
                    "city": "Vilanova del Camí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08789",
                    "province": "Barcelona",
                    "city": "Torre de Claramunt, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "08790",
                    "province": "Barcelona",
                    "city": "Gelida",
                    "multiple": "0"
                },
                {
                    "zipcode": "08791",
                    "province": "Barcelona",
                    "city": "Sant Llorenç d'Hortons",
                    "multiple": "0"
                },
                {
                    "zipcode": "08792",
                    "province": "Barcelona",
                    "city": "Santa Fe del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08792",
                    "province": "Barcelona",
                    "city": "Granada, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "08793",
                    "province": "Barcelona",
                    "city": "Avinyonet del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08794",
                    "province": "Barcelona",
                    "city": "Cabanyes, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "08795",
                    "province": "Barcelona",
                    "city": "Olesa de Bonesvalls",
                    "multiple": "0"
                },
                {
                    "zipcode": "08796",
                    "province": "Barcelona",
                    "city": "Pacs del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08797",
                    "province": "Barcelona",
                    "city": "Puigdàlber",
                    "multiple": "0"
                },
                {
                    "zipcode": "08798",
                    "province": "Barcelona",
                    "city": "Avinyonet del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "08798",
                    "province": "Barcelona",
                    "city": "Sant Cugat Sesgarrigues",
                    "multiple": "1"
                },
                {
                    "zipcode": "08799",
                    "province": "Barcelona",
                    "city": "Olèrdola",
                    "multiple": "0"
                },
                {
                    "zipcode": "08800",
                    "province": "Barcelona",
                    "city": "Vilanova i la Geltrú",
                    "multiple": "0"
                },
                {
                    "zipcode": "08801",
                    "province": "Barcelona",
                    "city": "Terrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "08810",
                    "province": "Barcelona",
                    "city": "Sant Pere de Ribes",
                    "multiple": "0"
                },
                {
                    "zipcode": "08811",
                    "province": "Barcelona",
                    "city": "Canyelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08812",
                    "province": "Barcelona",
                    "city": "Sant Pere de Ribes",
                    "multiple": "0"
                },
                {
                    "zipcode": "08818",
                    "province": "Barcelona",
                    "city": "Olivella",
                    "multiple": "0"
                },
                {
                    "zipcode": "08820",
                    "province": "Barcelona",
                    "city": "Prat de Llobregat, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "08830",
                    "province": "Barcelona",
                    "city": "Sant Boi de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08840",
                    "province": "Barcelona",
                    "city": "Cardedeu",
                    "multiple": "0"
                },
                {
                    "zipcode": "08840",
                    "province": "Barcelona",
                    "city": "Viladecans",
                    "multiple": "1"
                },
                {
                    "zipcode": "08849",
                    "province": "Barcelona",
                    "city": "Sant Climent de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08850",
                    "province": "Barcelona",
                    "city": "Gavà",
                    "multiple": "0"
                },
                {
                    "zipcode": "08859",
                    "province": "Barcelona",
                    "city": "Begues",
                    "multiple": "0"
                },
                {
                    "zipcode": "08860",
                    "province": "Barcelona",
                    "city": "Castelldefels",
                    "multiple": "0"
                },
                {
                    "zipcode": "08870",
                    "province": "Barcelona",
                    "city": "Sitges",
                    "multiple": "0"
                },
                {
                    "zipcode": "08871",
                    "province": "Barcelona",
                    "city": "Sitges",
                    "multiple": "0"
                },
                {
                    "zipcode": "08880",
                    "province": "Barcelona",
                    "city": "Cubelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "08901",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08902",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08903",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08904",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08905",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08906",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08907",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08908",
                    "province": "Barcelona",
                    "city": "Hospitalet de Llobregat, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "08911",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08912",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08913",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08914",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08915",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08916",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08917",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08918",
                    "province": "Barcelona",
                    "city": "Badalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "08921",
                    "province": "Barcelona",
                    "city": "Santa Coloma de Gramenet",
                    "multiple": "0"
                },
                {
                    "zipcode": "08922",
                    "province": "Barcelona",
                    "city": "Santa Coloma de Gramenet",
                    "multiple": "0"
                },
                {
                    "zipcode": "08923",
                    "province": "Barcelona",
                    "city": "Santa Coloma de Gramenet",
                    "multiple": "0"
                },
                {
                    "zipcode": "08924",
                    "province": "Barcelona",
                    "city": "Santa Coloma de Gramenet",
                    "multiple": "0"
                },
                {
                    "zipcode": "08930",
                    "province": "Barcelona",
                    "city": "Sant Adrià de Besòs",
                    "multiple": "0"
                },
                {
                    "zipcode": "08940",
                    "province": "Barcelona",
                    "city": "Cornellà de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08950",
                    "province": "Barcelona",
                    "city": "Esplugues de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "08960",
                    "province": "Barcelona",
                    "city": "Sant Just Desvern",
                    "multiple": "0"
                },
                {
                    "zipcode": "08970",
                    "province": "Barcelona",
                    "city": "Sant Joan Despí",
                    "multiple": "0"
                },
                {
                    "zipcode": "08980",
                    "province": "Barcelona",
                    "city": "Sant Feliu de Llobregat",
                    "multiple": "0"
                },
                {
                    "zipcode": "09001",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09002",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09003",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09004",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09004",
                    "province": "Burgos",
                    "city": "Sotragero",
                    "multiple": "1"
                },
                {
                    "zipcode": "09005",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09006",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09007",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09100",
                    "province": "Burgos",
                    "city": "Melgar de Fernamental",
                    "multiple": "0"
                },
                {
                    "zipcode": "09107",
                    "province": "Burgos",
                    "city": "Arenillas de Riopisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "09107",
                    "province": "Burgos",
                    "city": "Palacios de Riopisuerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "09107",
                    "province": "Burgos",
                    "city": "Itero del Castillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09107",
                    "province": "Burgos",
                    "city": "Castrillo Mota de Judíos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09108",
                    "province": "Burgos",
                    "city": "Rezmondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09108",
                    "province": "Burgos",
                    "city": "Melgar de Fernamental",
                    "multiple": "1"
                },
                {
                    "zipcode": "09108",
                    "province": "Burgos",
                    "city": "Castrillo de Riopisuerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "09108",
                    "province": "Burgos",
                    "city": "Padilla de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09108",
                    "province": "Burgos",
                    "city": "Zarzosa de Río Pisuerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "09109",
                    "province": "Burgos",
                    "city": "Villasandino",
                    "multiple": "0"
                },
                {
                    "zipcode": "09109",
                    "province": "Burgos",
                    "city": "Sasamón",
                    "multiple": "1"
                },
                {
                    "zipcode": "09109",
                    "province": "Burgos",
                    "city": "Castrojeriz",
                    "multiple": "1"
                },
                {
                    "zipcode": "09109",
                    "province": "Burgos",
                    "city": "Padilla de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09110",
                    "province": "Burgos",
                    "city": "Castrojeriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "09119",
                    "province": "Burgos",
                    "city": "Villaquirán de la Puebla",
                    "multiple": "0"
                },
                {
                    "zipcode": "09119",
                    "province": "Burgos",
                    "city": "Balbases, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "09119",
                    "province": "Burgos",
                    "city": "Pedrosa del Príncipe",
                    "multiple": "1"
                },
                {
                    "zipcode": "09119",
                    "province": "Burgos",
                    "city": "Castrojeriz",
                    "multiple": "1"
                },
                {
                    "zipcode": "09120",
                    "province": "Burgos",
                    "city": "Villadiego",
                    "multiple": "0"
                },
                {
                    "zipcode": "09121",
                    "province": "Burgos",
                    "city": "Melgar de Fernamental",
                    "multiple": "0"
                },
                {
                    "zipcode": "09123",
                    "province": "Burgos",
                    "city": "Sasamón",
                    "multiple": "0"
                },
                {
                    "zipcode": "09124",
                    "province": "Burgos",
                    "city": "Humada",
                    "multiple": "0"
                },
                {
                    "zipcode": "09124",
                    "province": "Burgos",
                    "city": "Villadiego",
                    "multiple": "1"
                },
                {
                    "zipcode": "09125",
                    "province": "Burgos",
                    "city": "Villadiego",
                    "multiple": "0"
                },
                {
                    "zipcode": "09125",
                    "province": "Burgos",
                    "city": "Úrbel del Castillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09125",
                    "province": "Burgos",
                    "city": "Basconcillos del Tozo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09125",
                    "province": "Burgos",
                    "city": "Montorio",
                    "multiple": "1"
                },
                {
                    "zipcode": "09125",
                    "province": "Burgos",
                    "city": "Huérmeces",
                    "multiple": "1"
                },
                {
                    "zipcode": "09126",
                    "province": "Burgos",
                    "city": "Basconcillos del Tozo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09126",
                    "province": "Burgos",
                    "city": "Humada",
                    "multiple": "1"
                },
                {
                    "zipcode": "09127",
                    "province": "Burgos",
                    "city": "Valle de Valdelucio",
                    "multiple": "0"
                },
                {
                    "zipcode": "09127",
                    "province": "Burgos",
                    "city": "Basconcillos del Tozo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09128",
                    "province": "Burgos",
                    "city": "Sordillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09128",
                    "province": "Burgos",
                    "city": "Grijalba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09128",
                    "province": "Burgos",
                    "city": "Villadiego",
                    "multiple": "1"
                },
                {
                    "zipcode": "09128",
                    "province": "Burgos",
                    "city": "Ibeas de Juarros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09128",
                    "province": "Burgos",
                    "city": "Villegas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09128",
                    "province": "Burgos",
                    "city": "Villamayor de Treviño",
                    "multiple": "1"
                },
                {
                    "zipcode": "09129",
                    "province": "Burgos",
                    "city": "Villadiego",
                    "multiple": "0"
                },
                {
                    "zipcode": "09130",
                    "province": "Burgos",
                    "city": "Rabé de las Calzadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "09130",
                    "province": "Burgos",
                    "city": "Tardajos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09131",
                    "province": "Burgos",
                    "city": "Quintanillas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "09131",
                    "province": "Burgos",
                    "city": "Valle de Santibáñez",
                    "multiple": "1"
                },
                {
                    "zipcode": "09131",
                    "province": "Burgos",
                    "city": "Alfoz de Quintanadueñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09131",
                    "province": "Burgos",
                    "city": "Pedrosa de Río Úrbel",
                    "multiple": "1"
                },
                {
                    "zipcode": "09133",
                    "province": "Burgos",
                    "city": "Pedrosa del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09133",
                    "province": "Burgos",
                    "city": "Villadiego",
                    "multiple": "1"
                },
                {
                    "zipcode": "09133",
                    "province": "Burgos",
                    "city": "Tobar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09133",
                    "province": "Burgos",
                    "city": "Hormazas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "09133",
                    "province": "Burgos",
                    "city": "Isar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09133",
                    "province": "Burgos",
                    "city": "Manciles",
                    "multiple": "1"
                },
                {
                    "zipcode": "09133",
                    "province": "Burgos",
                    "city": "Susinos del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09135",
                    "province": "Burgos",
                    "city": "Sotresgudo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09136",
                    "province": "Burgos",
                    "city": "Sotresgudo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09140",
                    "province": "Burgos",
                    "city": "Quintanaortuño",
                    "multiple": "0"
                },
                {
                    "zipcode": "09140",
                    "province": "Burgos",
                    "city": "Quintanilla Vivar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09140",
                    "province": "Burgos",
                    "city": "Merindad de Río Ubierna",
                    "multiple": "1"
                },
                {
                    "zipcode": "09141",
                    "province": "Burgos",
                    "city": "Abajas",
                    "multiple": "0"
                },
                {
                    "zipcode": "09141",
                    "province": "Burgos",
                    "city": "Merindad de Río Ubierna",
                    "multiple": "1"
                },
                {
                    "zipcode": "09142",
                    "province": "Burgos",
                    "city": "Merindad de Río Ubierna",
                    "multiple": "0"
                },
                {
                    "zipcode": "09142",
                    "province": "Burgos",
                    "city": "Valle de Sedano",
                    "multiple": "1"
                },
                {
                    "zipcode": "09143",
                    "province": "Burgos",
                    "city": "Tubilla del Agua",
                    "multiple": "0"
                },
                {
                    "zipcode": "09143",
                    "province": "Burgos",
                    "city": "Sargentes de la Lora",
                    "multiple": "1"
                },
                {
                    "zipcode": "09144",
                    "province": "Burgos",
                    "city": "Sargentes de la Lora",
                    "multiple": "0"
                },
                {
                    "zipcode": "09145",
                    "province": "Burgos",
                    "city": "Sargentes de la Lora",
                    "multiple": "0"
                },
                {
                    "zipcode": "09145",
                    "province": "Burgos",
                    "city": "Tubilla del Agua",
                    "multiple": "1"
                },
                {
                    "zipcode": "09145",
                    "province": "Burgos",
                    "city": "Valle de Sedano",
                    "multiple": "1"
                },
                {
                    "zipcode": "09146",
                    "province": "Burgos",
                    "city": "Altos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "09146",
                    "province": "Burgos",
                    "city": "Valle de Sedano",
                    "multiple": "1"
                },
                {
                    "zipcode": "09146",
                    "province": "Burgos",
                    "city": "Valle de Zamanzas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09150",
                    "province": "Burgos",
                    "city": "Hormazas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "09150",
                    "province": "Burgos",
                    "city": "Valle de Santibáñez",
                    "multiple": "1"
                },
                {
                    "zipcode": "09150",
                    "province": "Burgos",
                    "city": "Huérmeces",
                    "multiple": "1"
                },
                {
                    "zipcode": "09159",
                    "province": "Burgos",
                    "city": "Valle de Santibáñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "09163",
                    "province": "Burgos",
                    "city": "Revilla y Ahedo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "09191",
                    "province": "Burgos",
                    "city": "Valle de las Navas",
                    "multiple": "0"
                },
                {
                    "zipcode": "09191",
                    "province": "Burgos",
                    "city": "Hurones",
                    "multiple": "1"
                },
                {
                    "zipcode": "09191",
                    "province": "Burgos",
                    "city": "Villayerno Morquillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09192",
                    "province": "Burgos",
                    "city": "Cardeñuela Riopico",
                    "multiple": "0"
                },
                {
                    "zipcode": "09192",
                    "province": "Burgos",
                    "city": "Orbaneja Riopico",
                    "multiple": "1"
                },
                {
                    "zipcode": "09192",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09193",
                    "province": "Burgos",
                    "city": "Cardeñajimeno",
                    "multiple": "0"
                },
                {
                    "zipcode": "09193",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09193",
                    "province": "Burgos",
                    "city": "Carcedo de Burgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09193",
                    "province": "Burgos",
                    "city": "Castrillo del Val",
                    "multiple": "1"
                },
                {
                    "zipcode": "09194",
                    "province": "Burgos",
                    "city": "Carcedo de Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09194",
                    "province": "Burgos",
                    "city": "Ausines, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "09194",
                    "province": "Burgos",
                    "city": "Cardeñadijo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09194",
                    "province": "Burgos",
                    "city": "Ibeas de Juarros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09194",
                    "province": "Burgos",
                    "city": "Revilla del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09195",
                    "province": "Burgos",
                    "city": "Villalbilla de Burgos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09195",
                    "province": "Burgos",
                    "city": "Arcos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09195",
                    "province": "Burgos",
                    "city": "Villagonzalo Pedernales",
                    "multiple": "1"
                },
                {
                    "zipcode": "09195",
                    "province": "Burgos",
                    "city": "Villariezo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09197",
                    "province": "Burgos",
                    "city": "Villaescusa la Sombría",
                    "multiple": "0"
                },
                {
                    "zipcode": "09197",
                    "province": "Burgos",
                    "city": "Villalbilla de Burgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09197",
                    "province": "Burgos",
                    "city": "Merindad de Río Ubierna",
                    "multiple": "1"
                },
                {
                    "zipcode": "09197",
                    "province": "Burgos",
                    "city": "Alfoz de Quintanadueñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09197",
                    "province": "Burgos",
                    "city": "Sotragero",
                    "multiple": "1"
                },
                {
                    "zipcode": "09198",
                    "province": "Burgos",
                    "city": "San Adrián de Juarros",
                    "multiple": "0"
                },
                {
                    "zipcode": "09198",
                    "province": "Burgos",
                    "city": "Ibeas de Juarros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09198",
                    "province": "Burgos",
                    "city": "Castrillo del Val",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Cardeñajimeno",
                    "multiple": "0"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Burgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Arlanzón",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Atapuerca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Villasur de Herreros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Rábanos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Rubena",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Barrios de Colina",
                    "multiple": "1"
                },
                {
                    "zipcode": "09199",
                    "province": "Burgos",
                    "city": "Pineda de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09200",
                    "province": "Burgos",
                    "city": "Miranda de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "09210",
                    "province": "Burgos",
                    "city": "Valle de Tobalina",
                    "multiple": "0"
                },
                {
                    "zipcode": "09211",
                    "province": "Burgos",
                    "city": "Oña",
                    "multiple": "0"
                },
                {
                    "zipcode": "09211",
                    "province": "Burgos",
                    "city": "Frías",
                    "multiple": "1"
                },
                {
                    "zipcode": "09211",
                    "province": "Burgos",
                    "city": "Valle de Tobalina",
                    "multiple": "1"
                },
                {
                    "zipcode": "09211",
                    "province": "Burgos",
                    "city": "Partido de la Sierra en Tobalina",
                    "multiple": "1"
                },
                {
                    "zipcode": "09212",
                    "province": "Burgos",
                    "city": "Merindad de Cuesta-Urria",
                    "multiple": "0"
                },
                {
                    "zipcode": "09212",
                    "province": "Burgos",
                    "city": "Valle de Tobalina",
                    "multiple": "1"
                },
                {
                    "zipcode": "09212",
                    "province": "Burgos",
                    "city": "Miranda de Ebro",
                    "multiple": "1"
                },
                {
                    "zipcode": "09213",
                    "province": "Burgos",
                    "city": "Trespaderne",
                    "multiple": "0"
                },
                {
                    "zipcode": "09213",
                    "province": "Burgos",
                    "city": "Valle de Tobalina",
                    "multiple": "1"
                },
                {
                    "zipcode": "09213",
                    "province": "Burgos",
                    "city": "Cillaperlata",
                    "multiple": "1"
                },
                {
                    "zipcode": "09214",
                    "province": "Burgos",
                    "city": "Bozoó",
                    "multiple": "0"
                },
                {
                    "zipcode": "09215",
                    "province": "Burgos",
                    "city": "Condado de Treviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "09216",
                    "province": "Burgos",
                    "city": "Condado de Treviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "09217",
                    "province": "Burgos",
                    "city": "Condado de Treviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "09218",
                    "province": "Burgos",
                    "city": "Miranda de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "09218",
                    "province": "Burgos",
                    "city": "Condado de Treviño",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Miranda de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Valluércanes",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Santa Gadea del Cid",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Encío",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Santa María Rivarredonda",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Bozoó",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Ameyugo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Altable",
                    "multiple": "1"
                },
                {
                    "zipcode": "09219",
                    "province": "Burgos",
                    "city": "Villanueva de Teba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09220",
                    "province": "Burgos",
                    "city": "Pampliega",
                    "multiple": "0"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Celada del Camino",
                    "multiple": "0"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Barrio de Muñó",
                    "multiple": "1"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Villazopeque",
                    "multiple": "1"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Villaverde-Mogina",
                    "multiple": "1"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Pampliega",
                    "multiple": "1"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Belbimbre",
                    "multiple": "1"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Villaquirán de los Infantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "09226",
                    "province": "Burgos",
                    "city": "Palazuelos de Muñó",
                    "multiple": "1"
                },
                {
                    "zipcode": "09227",
                    "province": "Burgos",
                    "city": "Tamarón",
                    "multiple": "0"
                },
                {
                    "zipcode": "09227",
                    "province": "Burgos",
                    "city": "Castellanos de Castro",
                    "multiple": "1"
                },
                {
                    "zipcode": "09227",
                    "province": "Burgos",
                    "city": "Hontanas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09227",
                    "province": "Burgos",
                    "city": "Villaldemiro",
                    "multiple": "1"
                },
                {
                    "zipcode": "09227",
                    "province": "Burgos",
                    "city": "Iglesias",
                    "multiple": "1"
                },
                {
                    "zipcode": "09228",
                    "province": "Burgos",
                    "city": "Villaverde del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "09228",
                    "province": "Burgos",
                    "city": "Olmillos de Muñó",
                    "multiple": "1"
                },
                {
                    "zipcode": "09228",
                    "province": "Burgos",
                    "city": "Presencio",
                    "multiple": "1"
                },
                {
                    "zipcode": "09228",
                    "province": "Burgos",
                    "city": "Mazuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "09228",
                    "province": "Burgos",
                    "city": "Mahamud",
                    "multiple": "1"
                },
                {
                    "zipcode": "09228",
                    "province": "Burgos",
                    "city": "Ciadoncha",
                    "multiple": "1"
                },
                {
                    "zipcode": "09230",
                    "province": "Burgos",
                    "city": "Buniel",
                    "multiple": "0"
                },
                {
                    "zipcode": "09230",
                    "province": "Burgos",
                    "city": "Hornillos del Camino",
                    "multiple": "1"
                },
                {
                    "zipcode": "09230",
                    "province": "Burgos",
                    "city": "Frandovínez",
                    "multiple": "1"
                },
                {
                    "zipcode": "09230",
                    "province": "Burgos",
                    "city": "San Mamés de Burgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09230",
                    "province": "Burgos",
                    "city": "Estépar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09233",
                    "province": "Burgos",
                    "city": "Alfoz de Bricia",
                    "multiple": "0"
                },
                {
                    "zipcode": "09239",
                    "province": "Burgos",
                    "city": "Arcos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09239",
                    "province": "Burgos",
                    "city": "Cavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "09239",
                    "province": "Burgos",
                    "city": "Cayuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "09239",
                    "province": "Burgos",
                    "city": "Estépar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09239",
                    "province": "Burgos",
                    "city": "Albillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09240",
                    "province": "Burgos",
                    "city": "Briviesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "09244",
                    "province": "Burgos",
                    "city": "Quintanaélez",
                    "multiple": "0"
                },
                {
                    "zipcode": "09244",
                    "province": "Burgos",
                    "city": "Fuentebureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09244",
                    "province": "Burgos",
                    "city": "Busto de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09245",
                    "province": "Burgos",
                    "city": "Berzosa de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09245",
                    "province": "Burgos",
                    "city": "Briviesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09245",
                    "province": "Burgos",
                    "city": "Zuñeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "09245",
                    "province": "Burgos",
                    "city": "Grisaleña",
                    "multiple": "1"
                },
                {
                    "zipcode": "09245",
                    "province": "Burgos",
                    "city": "Vallarta de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Carcedo de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Rojas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Barrios de Bureba, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Piérnigas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Poza de la Sal",
                    "multiple": "1"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Quintanabureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Llano de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09246",
                    "province": "Burgos",
                    "city": "Oña",
                    "multiple": "1"
                },
                {
                    "zipcode": "09247",
                    "province": "Burgos",
                    "city": "Salinillas de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09247",
                    "province": "Burgos",
                    "city": "Galbarros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09248",
                    "province": "Burgos",
                    "city": "Prádanos de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09248",
                    "province": "Burgos",
                    "city": "Belorado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09248",
                    "province": "Burgos",
                    "city": "Briviesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09248",
                    "province": "Burgos",
                    "city": "Carrias",
                    "multiple": "1"
                },
                {
                    "zipcode": "09248",
                    "province": "Burgos",
                    "city": "Bañuelos de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09248",
                    "province": "Burgos",
                    "city": "Reinoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "09249",
                    "province": "Burgos",
                    "city": "Navas de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09249",
                    "province": "Burgos",
                    "city": "Aguilar de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09249",
                    "province": "Burgos",
                    "city": "Barrios de Bureba, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "09249",
                    "province": "Burgos",
                    "city": "Vid de Bureba, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "09249",
                    "province": "Burgos",
                    "city": "Oña",
                    "multiple": "1"
                },
                {
                    "zipcode": "09249",
                    "province": "Burgos",
                    "city": "Vileña",
                    "multiple": "1"
                },
                {
                    "zipcode": "09249",
                    "province": "Burgos",
                    "city": "Briviesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09250",
                    "province": "Burgos",
                    "city": "Miraveche",
                    "multiple": "0"
                },
                {
                    "zipcode": "09250",
                    "province": "Burgos",
                    "city": "Belorado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09251",
                    "province": "Burgos",
                    "city": "Cubo de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09252",
                    "province": "Burgos",
                    "city": "Miraveche",
                    "multiple": "0"
                },
                {
                    "zipcode": "09257",
                    "province": "Burgos",
                    "city": "Villafranca Montes de Oca",
                    "multiple": "0"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Alcocero de Mola",
                    "multiple": "0"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Villafranca Montes de Oca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Villambistia",
                    "multiple": "1"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Tosantos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Castil de Peones",
                    "multiple": "1"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Espinosa del Camino",
                    "multiple": "1"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Belorado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09258",
                    "province": "Burgos",
                    "city": "Valle de Oca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09259",
                    "province": "Burgos",
                    "city": "Bascuñana",
                    "multiple": "0"
                },
                {
                    "zipcode": "09259",
                    "province": "Burgos",
                    "city": "Castildelgado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09259",
                    "province": "Burgos",
                    "city": "Viloria de Rioja",
                    "multiple": "1"
                },
                {
                    "zipcode": "09259",
                    "province": "Burgos",
                    "city": "Ibrillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09259",
                    "province": "Burgos",
                    "city": "Redecilla del Camino",
                    "multiple": "1"
                },
                {
                    "zipcode": "09259",
                    "province": "Burgos",
                    "city": "Redecilla del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09259",
                    "province": "Burgos",
                    "city": "Fresneña",
                    "multiple": "1"
                },
                {
                    "zipcode": "09260",
                    "province": "Burgos",
                    "city": "Pradoluengo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09267",
                    "province": "Burgos",
                    "city": "Fresneda de la Sierra Tirón",
                    "multiple": "0"
                },
                {
                    "zipcode": "09267",
                    "province": "Burgos",
                    "city": "Belorado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09268",
                    "province": "Burgos",
                    "city": "San Vicente del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "09268",
                    "province": "Burgos",
                    "city": "Pradoluengo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09268",
                    "province": "Burgos",
                    "city": "Villagalijo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09268",
                    "province": "Burgos",
                    "city": "Rábanos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09268",
                    "province": "Burgos",
                    "city": "Valmala",
                    "multiple": "1"
                },
                {
                    "zipcode": "09268",
                    "province": "Burgos",
                    "city": "Santa Cruz del Valle Urbión",
                    "multiple": "1"
                },
                {
                    "zipcode": "09269",
                    "province": "Burgos",
                    "city": "Rábanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09270",
                    "province": "Burgos",
                    "city": "Redecilla del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09270",
                    "province": "Burgos",
                    "city": "Cerezo de Río Tirón",
                    "multiple": "1"
                },
                {
                    "zipcode": "09271",
                    "province": "Burgos",
                    "city": "Quintanilla San García",
                    "multiple": "0"
                },
                {
                    "zipcode": "09272",
                    "province": "Burgos",
                    "city": "Fresno de Río Tirón",
                    "multiple": "0"
                },
                {
                    "zipcode": "09272",
                    "province": "Burgos",
                    "city": "Belorado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09280",
                    "province": "Burgos",
                    "city": "Pancorbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09280",
                    "province": "Burgos",
                    "city": "Cascajares de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09280",
                    "province": "Burgos",
                    "city": "Encío",
                    "multiple": "1"
                },
                {
                    "zipcode": "09285",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "0"
                },
                {
                    "zipcode": "09290",
                    "province": "Burgos",
                    "city": "Fresno de Rodilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "09290",
                    "province": "Burgos",
                    "city": "Quintanapalla",
                    "multiple": "1"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Santa Olalla de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Quintanavides",
                    "multiple": "1"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Villaescusa la Sombría",
                    "multiple": "1"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Briviesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Cerratón de Juarros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Monasterio de Rodilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Santa María del Invierno",
                    "multiple": "1"
                },
                {
                    "zipcode": "09292",
                    "province": "Burgos",
                    "city": "Arraya de Oca",
                    "multiple": "1"
                },
                {
                    "zipcode": "09293",
                    "province": "Burgos",
                    "city": "Bugedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09293",
                    "province": "Burgos",
                    "city": "Miranda de Ebro",
                    "multiple": "1"
                },
                {
                    "zipcode": "09294",
                    "province": "Burgos",
                    "city": "Puebla de Arganzón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "09294",
                    "province": "Burgos",
                    "city": "Condado de Treviño",
                    "multiple": "1"
                },
                {
                    "zipcode": "09300",
                    "province": "Burgos",
                    "city": "Roa",
                    "multiple": "0"
                },
                {
                    "zipcode": "09310",
                    "province": "Burgos",
                    "city": "Torresandino",
                    "multiple": "0"
                },
                {
                    "zipcode": "09310",
                    "province": "Burgos",
                    "city": "Tórtoles de Esgueva",
                    "multiple": "1"
                },
                {
                    "zipcode": "09310",
                    "province": "Burgos",
                    "city": "Villatuelda",
                    "multiple": "1"
                },
                {
                    "zipcode": "09311",
                    "province": "Burgos",
                    "city": "Horra, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "09311",
                    "province": "Burgos",
                    "city": "Olmedillo de Roa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09312",
                    "province": "Burgos",
                    "city": "Tórtoles de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "09312",
                    "province": "Burgos",
                    "city": "Anguix",
                    "multiple": "1"
                },
                {
                    "zipcode": "09314",
                    "province": "Burgos",
                    "city": "Pedrosa de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "09314",
                    "province": "Burgos",
                    "city": "Villaescusa de Roa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09315",
                    "province": "Burgos",
                    "city": "Cueva de Roa, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "09315",
                    "province": "Burgos",
                    "city": "Fuentecén",
                    "multiple": "1"
                },
                {
                    "zipcode": "09315",
                    "province": "Burgos",
                    "city": "Fuentemolinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09316",
                    "province": "Burgos",
                    "city": "Berlangas de Roa",
                    "multiple": "0"
                },
                {
                    "zipcode": "09316",
                    "province": "Burgos",
                    "city": "Hoyales de Roa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09317",
                    "province": "Burgos",
                    "city": "Pedrosa de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "09317",
                    "province": "Burgos",
                    "city": "San Martín de Rubiales",
                    "multiple": "1"
                },
                {
                    "zipcode": "09317",
                    "province": "Burgos",
                    "city": "Mambrilla de Castrejón",
                    "multiple": "1"
                },
                {
                    "zipcode": "09318",
                    "province": "Burgos",
                    "city": "Fuentelisendo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09318",
                    "province": "Burgos",
                    "city": "Nava de Roa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09318",
                    "province": "Burgos",
                    "city": "Valdezate",
                    "multiple": "1"
                },
                {
                    "zipcode": "09320",
                    "province": "Burgos",
                    "city": "Madrigal del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "09320",
                    "province": "Burgos",
                    "city": "Valdorros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09320",
                    "province": "Burgos",
                    "city": "Cogollos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09330",
                    "province": "Burgos",
                    "city": "Madrigalejo del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "09338",
                    "province": "Burgos",
                    "city": "Valles de Palenzuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "09339",
                    "province": "Burgos",
                    "city": "Villangómez",
                    "multiple": "0"
                },
                {
                    "zipcode": "09339",
                    "province": "Burgos",
                    "city": "Zael",
                    "multiple": "1"
                },
                {
                    "zipcode": "09339",
                    "province": "Burgos",
                    "city": "Villamayor de los Montes",
                    "multiple": "1"
                },
                {
                    "zipcode": "09339",
                    "province": "Burgos",
                    "city": "Villaverde del Monte",
                    "multiple": "1"
                },
                {
                    "zipcode": "09340",
                    "province": "Burgos",
                    "city": "Lerma",
                    "multiple": "0"
                },
                {
                    "zipcode": "09341",
                    "province": "Burgos",
                    "city": "Santa Cecilia",
                    "multiple": "0"
                },
                {
                    "zipcode": "09341",
                    "province": "Burgos",
                    "city": "Tordómar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09341",
                    "province": "Burgos",
                    "city": "Lerma",
                    "multiple": "1"
                },
                {
                    "zipcode": "09342",
                    "province": "Burgos",
                    "city": "Santa María del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09342",
                    "province": "Burgos",
                    "city": "Peral de Arlanza",
                    "multiple": "1"
                },
                {
                    "zipcode": "09342",
                    "province": "Burgos",
                    "city": "Torrepadre",
                    "multiple": "1"
                },
                {
                    "zipcode": "09343",
                    "province": "Burgos",
                    "city": "Villahoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "09344",
                    "province": "Burgos",
                    "city": "Villafruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "09344",
                    "province": "Burgos",
                    "city": "Royuela de Río Franco",
                    "multiple": "1"
                },
                {
                    "zipcode": "09345",
                    "province": "Burgos",
                    "city": "Royuela de Río Franco",
                    "multiple": "0"
                },
                {
                    "zipcode": "09345",
                    "province": "Burgos",
                    "city": "Torrepadre",
                    "multiple": "1"
                },
                {
                    "zipcode": "09345",
                    "province": "Burgos",
                    "city": "Iglesiarrubia",
                    "multiple": "1"
                },
                {
                    "zipcode": "09345",
                    "province": "Burgos",
                    "city": "Avellanosa de Muñó",
                    "multiple": "1"
                },
                {
                    "zipcode": "09346",
                    "province": "Burgos",
                    "city": "Covarrubias",
                    "multiple": "0"
                },
                {
                    "zipcode": "09346",
                    "province": "Burgos",
                    "city": "Mecerreyes",
                    "multiple": "1"
                },
                {
                    "zipcode": "09347",
                    "province": "Burgos",
                    "city": "Puentedura",
                    "multiple": "0"
                },
                {
                    "zipcode": "09347",
                    "province": "Burgos",
                    "city": "Lerma",
                    "multiple": "1"
                },
                {
                    "zipcode": "09347",
                    "province": "Burgos",
                    "city": "Quintanilla del Agua y Tordueles",
                    "multiple": "1"
                },
                {
                    "zipcode": "09347",
                    "province": "Burgos",
                    "city": "Covarrubias",
                    "multiple": "1"
                },
                {
                    "zipcode": "09347",
                    "province": "Burgos",
                    "city": "Retuerta",
                    "multiple": "1"
                },
                {
                    "zipcode": "09348",
                    "province": "Burgos",
                    "city": "Solarana",
                    "multiple": "0"
                },
                {
                    "zipcode": "09348",
                    "province": "Burgos",
                    "city": "Lerma",
                    "multiple": "1"
                },
                {
                    "zipcode": "09348",
                    "province": "Burgos",
                    "city": "Nebreda",
                    "multiple": "1"
                },
                {
                    "zipcode": "09348",
                    "province": "Burgos",
                    "city": "Cebrecos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09348",
                    "province": "Burgos",
                    "city": "Quintanilla del Coco",
                    "multiple": "1"
                },
                {
                    "zipcode": "09349",
                    "province": "Burgos",
                    "city": "Pineda Trasmonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "09349",
                    "province": "Burgos",
                    "city": "Cilleruelo de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09349",
                    "province": "Burgos",
                    "city": "Cilleruelo de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09349",
                    "province": "Burgos",
                    "city": "Quintanilla de la Mata",
                    "multiple": "1"
                },
                {
                    "zipcode": "09349",
                    "province": "Burgos",
                    "city": "Fontioso",
                    "multiple": "1"
                },
                {
                    "zipcode": "09349",
                    "province": "Burgos",
                    "city": "Lerma",
                    "multiple": "1"
                },
                {
                    "zipcode": "09350",
                    "province": "Burgos",
                    "city": "Santibáñez de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "09350",
                    "province": "Burgos",
                    "city": "Cabañes de Esgueva",
                    "multiple": "1"
                },
                {
                    "zipcode": "09350",
                    "province": "Burgos",
                    "city": "Oquillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09350",
                    "province": "Burgos",
                    "city": "Bahabón de Esgueva",
                    "multiple": "1"
                },
                {
                    "zipcode": "09351",
                    "province": "Burgos",
                    "city": "Ausines, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "09351",
                    "province": "Burgos",
                    "city": "Hontoria de la Cantera",
                    "multiple": "1"
                },
                {
                    "zipcode": "09352",
                    "province": "Burgos",
                    "city": "Cubillo del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09353",
                    "province": "Burgos",
                    "city": "Santa María del Mercadillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09354",
                    "province": "Burgos",
                    "city": "Pinilla Trasmonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "09370",
                    "province": "Burgos",
                    "city": "Gumiel de Izán",
                    "multiple": "0"
                },
                {
                    "zipcode": "09370",
                    "province": "Burgos",
                    "city": "Aranda de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "09370",
                    "province": "Burgos",
                    "city": "Quintana del Pidio",
                    "multiple": "1"
                },
                {
                    "zipcode": "09390",
                    "province": "Burgos",
                    "city": "Madrigalejo del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "09390",
                    "province": "Burgos",
                    "city": "Santa Inés",
                    "multiple": "1"
                },
                {
                    "zipcode": "09390",
                    "province": "Burgos",
                    "city": "Torrecilla del Monte",
                    "multiple": "1"
                },
                {
                    "zipcode": "09390",
                    "province": "Burgos",
                    "city": "Villalmanzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09391",
                    "province": "Burgos",
                    "city": "Castrillo de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "09400",
                    "province": "Burgos",
                    "city": "Aranda de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "09410",
                    "province": "Burgos",
                    "city": "Coruña del Conde",
                    "multiple": "0"
                },
                {
                    "zipcode": "09410",
                    "province": "Burgos",
                    "city": "Arandilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "09410",
                    "province": "Burgos",
                    "city": "Peñaranda de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "09430",
                    "province": "Burgos",
                    "city": "Huerta de Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "09436",
                    "province": "Burgos",
                    "city": "Haza",
                    "multiple": "0"
                },
                {
                    "zipcode": "09440",
                    "province": "Burgos",
                    "city": "Sotillo de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "09441",
                    "province": "Burgos",
                    "city": "Sotillo de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "09442",
                    "province": "Burgos",
                    "city": "Terradillos de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "09443",
                    "province": "Burgos",
                    "city": "Villalba de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "09443",
                    "province": "Burgos",
                    "city": "Gumiel de Mercado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09450",
                    "province": "Burgos",
                    "city": "Villanueva de Gumiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "09450",
                    "province": "Burgos",
                    "city": "Aranda de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "09450",
                    "province": "Burgos",
                    "city": "Hontoria de Valdearados",
                    "multiple": "1"
                },
                {
                    "zipcode": "09450",
                    "province": "Burgos",
                    "city": "Baños de Valdearados",
                    "multiple": "1"
                },
                {
                    "zipcode": "09451",
                    "province": "Burgos",
                    "city": "Arauzo de Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "09451",
                    "province": "Burgos",
                    "city": "Arauzo de Salce",
                    "multiple": "1"
                },
                {
                    "zipcode": "09451",
                    "province": "Burgos",
                    "city": "Caleruega",
                    "multiple": "1"
                },
                {
                    "zipcode": "09451",
                    "province": "Burgos",
                    "city": "Arauzo de Miel",
                    "multiple": "1"
                },
                {
                    "zipcode": "09453",
                    "province": "Burgos",
                    "city": "Tubilla del Lago",
                    "multiple": "0"
                },
                {
                    "zipcode": "09453",
                    "province": "Burgos",
                    "city": "Villalbilla de Gumiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "09453",
                    "province": "Burgos",
                    "city": "Valdeande",
                    "multiple": "1"
                },
                {
                    "zipcode": "09454",
                    "province": "Burgos",
                    "city": "Quemada",
                    "multiple": "0"
                },
                {
                    "zipcode": "09454",
                    "province": "Burgos",
                    "city": "Huerta de Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "09460",
                    "province": "Burgos",
                    "city": "Milagros",
                    "multiple": "0"
                },
                {
                    "zipcode": "09461",
                    "province": "Burgos",
                    "city": "Fuentenebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "09462",
                    "province": "Burgos",
                    "city": "Moradillo de Roa",
                    "multiple": "0"
                },
                {
                    "zipcode": "09462",
                    "province": "Burgos",
                    "city": "Adrada de Haza",
                    "multiple": "1"
                },
                {
                    "zipcode": "09462",
                    "province": "Burgos",
                    "city": "Pardilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "09462",
                    "province": "Burgos",
                    "city": "Hontangas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09462",
                    "province": "Burgos",
                    "city": "Sequera de Haza, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "09463",
                    "province": "Burgos",
                    "city": "Haza",
                    "multiple": "0"
                },
                {
                    "zipcode": "09471",
                    "province": "Burgos",
                    "city": "Fuentespina",
                    "multiple": "0"
                },
                {
                    "zipcode": "09471",
                    "province": "Burgos",
                    "city": "Vid y Barrios, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "09471",
                    "province": "Burgos",
                    "city": "Fuentelcésped",
                    "multiple": "1"
                },
                {
                    "zipcode": "09471",
                    "province": "Burgos",
                    "city": "Santa Cruz de la Salceda",
                    "multiple": "1"
                },
                {
                    "zipcode": "09490",
                    "province": "Burgos",
                    "city": "Brazacorta",
                    "multiple": "0"
                },
                {
                    "zipcode": "09490",
                    "province": "Burgos",
                    "city": "San Juan del Monte",
                    "multiple": "1"
                },
                {
                    "zipcode": "09490",
                    "province": "Burgos",
                    "city": "Zazuar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09490",
                    "province": "Burgos",
                    "city": "Peñaranda de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "09491",
                    "province": "Burgos",
                    "city": "Vadocondes",
                    "multiple": "0"
                },
                {
                    "zipcode": "09491",
                    "province": "Burgos",
                    "city": "Fresnillo de las Dueñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09491",
                    "province": "Burgos",
                    "city": "Vid y Barrios, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "09493",
                    "province": "Burgos",
                    "city": "Campillo de Aranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "09493",
                    "province": "Burgos",
                    "city": "Torregalindo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09500",
                    "province": "Burgos",
                    "city": "Medina de Pomar",
                    "multiple": "0"
                },
                {
                    "zipcode": "09510",
                    "province": "Burgos",
                    "city": "Junta de Traslaloma",
                    "multiple": "0"
                },
                {
                    "zipcode": "09510",
                    "province": "Burgos",
                    "city": "Valle de Losa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09511",
                    "province": "Burgos",
                    "city": "Junta de Villalba de Losa",
                    "multiple": "0"
                },
                {
                    "zipcode": "09511",
                    "province": "Burgos",
                    "city": "Berberana",
                    "multiple": "1"
                },
                {
                    "zipcode": "09511",
                    "province": "Burgos",
                    "city": "Valle de Losa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09512",
                    "province": "Burgos",
                    "city": "Valle de Losa",
                    "multiple": "0"
                },
                {
                    "zipcode": "09512",
                    "province": "Burgos",
                    "city": "Medina de Pomar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09513",
                    "province": "Burgos",
                    "city": "Medina de Pomar",
                    "multiple": "0"
                },
                {
                    "zipcode": "09513",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "1"
                },
                {
                    "zipcode": "09514",
                    "province": "Burgos",
                    "city": "Junta de Traslaloma",
                    "multiple": "0"
                },
                {
                    "zipcode": "09514",
                    "province": "Burgos",
                    "city": "Medina de Pomar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09514",
                    "province": "Burgos",
                    "city": "Merindad de Montija",
                    "multiple": "1"
                },
                {
                    "zipcode": "09515",
                    "province": "Burgos",
                    "city": "Merindad de Cuesta-Urria",
                    "multiple": "0"
                },
                {
                    "zipcode": "09515",
                    "province": "Burgos",
                    "city": "Medina de Pomar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09530",
                    "province": "Burgos",
                    "city": "Oña",
                    "multiple": "0"
                },
                {
                    "zipcode": "09540",
                    "province": "Burgos",
                    "city": "Trespaderne",
                    "multiple": "0"
                },
                {
                    "zipcode": "09545",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "0"
                },
                {
                    "zipcode": "09549",
                    "province": "Burgos",
                    "city": "Valle de Tobalina",
                    "multiple": "0"
                },
                {
                    "zipcode": "09549",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "1"
                },
                {
                    "zipcode": "09549",
                    "province": "Burgos",
                    "city": "Trespaderne",
                    "multiple": "1"
                },
                {
                    "zipcode": "09549",
                    "province": "Burgos",
                    "city": "Merindad de Cuesta-Urria",
                    "multiple": "1"
                },
                {
                    "zipcode": "09550",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "09551",
                    "province": "Burgos",
                    "city": "Altos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "09551",
                    "province": "Burgos",
                    "city": "Valle de Sedano",
                    "multiple": "1"
                },
                {
                    "zipcode": "09553",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "09554",
                    "province": "Burgos",
                    "city": "Oña",
                    "multiple": "0"
                },
                {
                    "zipcode": "09554",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "1"
                },
                {
                    "zipcode": "09555",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "09556",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "09557",
                    "province": "Burgos",
                    "city": "Merindad de Valdeporres",
                    "multiple": "0"
                },
                {
                    "zipcode": "09557",
                    "province": "Burgos",
                    "city": "Merindad de Sotoscueva",
                    "multiple": "1"
                },
                {
                    "zipcode": "09557",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "1"
                },
                {
                    "zipcode": "09558",
                    "province": "Burgos",
                    "city": "Villarcayo de Merindad de Castilla la Vieja",
                    "multiple": "0"
                },
                {
                    "zipcode": "09558",
                    "province": "Burgos",
                    "city": "Valle de Manzanedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09559",
                    "province": "Burgos",
                    "city": "Merindad de Valdivielso",
                    "multiple": "0"
                },
                {
                    "zipcode": "09559",
                    "province": "Burgos",
                    "city": "Oña",
                    "multiple": "1"
                },
                {
                    "zipcode": "09559",
                    "province": "Burgos",
                    "city": "Altos, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "09560",
                    "province": "Burgos",
                    "city": "Espinosa de los Monteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "09566",
                    "province": "Burgos",
                    "city": "Espinosa de los Monteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "09567",
                    "province": "Burgos",
                    "city": "Merindad de Sotoscueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "09567",
                    "province": "Burgos",
                    "city": "Espinosa de los Monteros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09568",
                    "province": "Burgos",
                    "city": "Merindad de Sotoscueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "09569",
                    "province": "Burgos",
                    "city": "Espinosa de los Monteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "09569",
                    "province": "Burgos",
                    "city": "Merindad de Montija",
                    "multiple": "1"
                },
                {
                    "zipcode": "09569",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "1"
                },
                {
                    "zipcode": "09570",
                    "province": "Burgos",
                    "city": "Alfoz de Santa Gadea",
                    "multiple": "0"
                },
                {
                    "zipcode": "09570",
                    "province": "Burgos",
                    "city": "Arija",
                    "multiple": "1"
                },
                {
                    "zipcode": "09571",
                    "province": "Burgos",
                    "city": "Alfoz de Bricia",
                    "multiple": "0"
                },
                {
                    "zipcode": "09571",
                    "province": "Burgos",
                    "city": "Alfoz de Santa Gadea",
                    "multiple": "1"
                },
                {
                    "zipcode": "09571",
                    "province": "Burgos",
                    "city": "Valle de Valdebezana",
                    "multiple": "1"
                },
                {
                    "zipcode": "09572",
                    "province": "Burgos",
                    "city": "Valle de Manzanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09572",
                    "province": "Burgos",
                    "city": "Alfoz de Bricia",
                    "multiple": "1"
                },
                {
                    "zipcode": "09572",
                    "province": "Burgos",
                    "city": "Valle de Valdebezana",
                    "multiple": "1"
                },
                {
                    "zipcode": "09573",
                    "province": "Burgos",
                    "city": "Merindad de Valdeporres",
                    "multiple": "0"
                },
                {
                    "zipcode": "09574",
                    "province": "Burgos",
                    "city": "Merindad de Valdeporres",
                    "multiple": "0"
                },
                {
                    "zipcode": "09580",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "0"
                },
                {
                    "zipcode": "09585",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "0"
                },
                {
                    "zipcode": "09586",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "0"
                },
                {
                    "zipcode": "09587",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "0"
                },
                {
                    "zipcode": "09588",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "0"
                },
                {
                    "zipcode": "09589",
                    "province": "Burgos",
                    "city": "Pampliega",
                    "multiple": "0"
                },
                {
                    "zipcode": "09589",
                    "province": "Burgos",
                    "city": "Valle de Mena",
                    "multiple": "1"
                },
                {
                    "zipcode": "09591",
                    "province": "Burgos",
                    "city": "Merindad de Río Ubierna",
                    "multiple": "0"
                },
                {
                    "zipcode": "09591",
                    "province": "Burgos",
                    "city": "Valle de las Navas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09592",
                    "province": "Burgos",
                    "city": "Abajas",
                    "multiple": "0"
                },
                {
                    "zipcode": "09592",
                    "province": "Burgos",
                    "city": "Rublacedo de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09592",
                    "province": "Burgos",
                    "city": "Poza de la Sal",
                    "multiple": "1"
                },
                {
                    "zipcode": "09592",
                    "province": "Burgos",
                    "city": "Carcedo de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09593",
                    "province": "Burgos",
                    "city": "Padrones de Bureba",
                    "multiple": "0"
                },
                {
                    "zipcode": "09593",
                    "province": "Burgos",
                    "city": "Aguas Cándidas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09593",
                    "province": "Burgos",
                    "city": "Oña",
                    "multiple": "1"
                },
                {
                    "zipcode": "09593",
                    "province": "Burgos",
                    "city": "Salas de Bureba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09593",
                    "province": "Burgos",
                    "city": "Cantabrana",
                    "multiple": "1"
                },
                {
                    "zipcode": "09593",
                    "province": "Burgos",
                    "city": "Rucandio",
                    "multiple": "1"
                },
                {
                    "zipcode": "09594",
                    "province": "Burgos",
                    "city": "Merindad de Cuesta-Urria",
                    "multiple": "0"
                },
                {
                    "zipcode": "09600",
                    "province": "Burgos",
                    "city": "Salas de los Infantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "09610",
                    "province": "Burgos",
                    "city": "Espinosa de Cervera",
                    "multiple": "0"
                },
                {
                    "zipcode": "09610",
                    "province": "Burgos",
                    "city": "Ciruelos de Cervera",
                    "multiple": "1"
                },
                {
                    "zipcode": "09610",
                    "province": "Burgos",
                    "city": "Santo Domingo de Silos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09611",
                    "province": "Burgos",
                    "city": "Villanueva de Carazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09611",
                    "province": "Burgos",
                    "city": "Carazo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09611",
                    "province": "Burgos",
                    "city": "Hacinas",
                    "multiple": "1"
                },
                {
                    "zipcode": "09612",
                    "province": "Burgos",
                    "city": "Pinilla de los Barruecos",
                    "multiple": "0"
                },
                {
                    "zipcode": "09612",
                    "province": "Burgos",
                    "city": "Mamolar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09612",
                    "province": "Burgos",
                    "city": "Gallega, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "09612",
                    "province": "Burgos",
                    "city": "Cabezón de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09613",
                    "province": "Burgos",
                    "city": "Barbadillo del Mercado",
                    "multiple": "0"
                },
                {
                    "zipcode": "09613",
                    "province": "Burgos",
                    "city": "Revilla y Ahedo, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "09613",
                    "province": "Burgos",
                    "city": "Salas de los Infantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "09613",
                    "province": "Burgos",
                    "city": "Monasterio de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09613",
                    "province": "Burgos",
                    "city": "Vizcaínos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09613",
                    "province": "Burgos",
                    "city": "Contreras",
                    "multiple": "1"
                },
                {
                    "zipcode": "09613",
                    "province": "Burgos",
                    "city": "Pinilla de los Moros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09614",
                    "province": "Burgos",
                    "city": "Barbadillo del Pez",
                    "multiple": "0"
                },
                {
                    "zipcode": "09614",
                    "province": "Burgos",
                    "city": "Huerta de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "09614",
                    "province": "Burgos",
                    "city": "Valle de Valdelaguna",
                    "multiple": "1"
                },
                {
                    "zipcode": "09615",
                    "province": "Burgos",
                    "city": "Monterrubio de la Demanda",
                    "multiple": "0"
                },
                {
                    "zipcode": "09615",
                    "province": "Burgos",
                    "city": "Riocavado de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09615",
                    "province": "Burgos",
                    "city": "Salas de los Infantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "09615",
                    "province": "Burgos",
                    "city": "Barbadillo de Herreros",
                    "multiple": "1"
                },
                {
                    "zipcode": "09616",
                    "province": "Burgos",
                    "city": "Tejada",
                    "multiple": "0"
                },
                {
                    "zipcode": "09617",
                    "province": "Burgos",
                    "city": "Santibáñez del Val",
                    "multiple": "0"
                },
                {
                    "zipcode": "09618",
                    "province": "Burgos",
                    "city": "Santibáñez del Val",
                    "multiple": "0"
                },
                {
                    "zipcode": "09619",
                    "province": "Burgos",
                    "city": "Hontoria del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "09620",
                    "province": "Burgos",
                    "city": "Modúbar de la Emparedada",
                    "multiple": "0"
                },
                {
                    "zipcode": "09620",
                    "province": "Burgos",
                    "city": "Sarracín",
                    "multiple": "1"
                },
                {
                    "zipcode": "09620",
                    "province": "Burgos",
                    "city": "Saldaña de Burgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "09620",
                    "province": "Burgos",
                    "city": "Revillarruz",
                    "multiple": "1"
                },
                {
                    "zipcode": "09631",
                    "province": "Burgos",
                    "city": "Arauzo de Miel",
                    "multiple": "0"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Villoruebo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Jaramillo Quemado",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Jurisdicción de Lara",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Villaespasa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Tinieblas de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Hortigüela",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Jaramillo de la Fuente",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "San Millán de Lara",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Cascajares de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09640",
                    "province": "Burgos",
                    "city": "Mambrillas de Lara",
                    "multiple": "1"
                },
                {
                    "zipcode": "09641",
                    "province": "Burgos",
                    "city": "Cuevas de San Clemente",
                    "multiple": "0"
                },
                {
                    "zipcode": "09642",
                    "province": "Burgos",
                    "city": "Ausines, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "09642",
                    "province": "Burgos",
                    "city": "Mambrillas de Lara",
                    "multiple": "1"
                },
                {
                    "zipcode": "09645",
                    "province": "Burgos",
                    "city": "Torrelara",
                    "multiple": "0"
                },
                {
                    "zipcode": "09646",
                    "province": "Burgos",
                    "city": "Villoruebo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09647",
                    "province": "Burgos",
                    "city": "Revilla del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "09649",
                    "province": "Burgos",
                    "city": "Tinieblas de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "09649",
                    "province": "Burgos",
                    "city": "Villamiel de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09649",
                    "province": "Burgos",
                    "city": "Palazuelos de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "09650",
                    "province": "Burgos",
                    "city": "Campolara",
                    "multiple": "0"
                },
                {
                    "zipcode": "09650",
                    "province": "Burgos",
                    "city": "Villaespasa",
                    "multiple": "1"
                },
                {
                    "zipcode": "09651",
                    "province": "Burgos",
                    "city": "Jurisdicción de Lara",
                    "multiple": "0"
                },
                {
                    "zipcode": "09652",
                    "province": "Burgos",
                    "city": "Villanueva de Argaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "09653",
                    "province": "Burgos",
                    "city": "Isar",
                    "multiple": "0"
                },
                {
                    "zipcode": "09654",
                    "province": "Burgos",
                    "city": "Isar",
                    "multiple": "0"
                },
                {
                    "zipcode": "09660",
                    "province": "Burgos",
                    "city": "Rabanera del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "09660",
                    "province": "Burgos",
                    "city": "Hontoria del Pinar",
                    "multiple": "1"
                },
                {
                    "zipcode": "09670",
                    "province": "Burgos",
                    "city": "Quintanar de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "09678",
                    "province": "Burgos",
                    "city": "Iglesiarrubia",
                    "multiple": "0"
                },
                {
                    "zipcode": "09679",
                    "province": "Burgos",
                    "city": "Neila",
                    "multiple": "0"
                },
                {
                    "zipcode": "09680",
                    "province": "Burgos",
                    "city": "Palacios de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "09690",
                    "province": "Burgos",
                    "city": "Vilviestre del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "09691",
                    "province": "Burgos",
                    "city": "Castrillo de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "09691",
                    "province": "Burgos",
                    "city": "Moncalvillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "09692",
                    "province": "Burgos",
                    "city": "Canicosa de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "09693",
                    "province": "Burgos",
                    "city": "Regumiel de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "10000",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10001",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10002",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10003",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10004",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10005",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10100",
                    "province": "Cáceres",
                    "city": "Miajadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10109",
                    "province": "Cáceres",
                    "city": "Miajadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10110",
                    "province": "Cáceres",
                    "city": "Losar de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10110",
                    "province": "Cáceres",
                    "city": "Madrigalejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10120",
                    "province": "Cáceres",
                    "city": "Logrosán",
                    "multiple": "0"
                },
                {
                    "zipcode": "10129",
                    "province": "Cáceres",
                    "city": "Berzocana",
                    "multiple": "0"
                },
                {
                    "zipcode": "10130",
                    "province": "Cáceres",
                    "city": "Zorita",
                    "multiple": "0"
                },
                {
                    "zipcode": "10131",
                    "province": "Cáceres",
                    "city": "Valdemorales",
                    "multiple": "0"
                },
                {
                    "zipcode": "10132",
                    "province": "Cáceres",
                    "city": "Almoharín",
                    "multiple": "0"
                },
                {
                    "zipcode": "10133",
                    "province": "Cáceres",
                    "city": "Escurial",
                    "multiple": "0"
                },
                {
                    "zipcode": "10134",
                    "province": "Cáceres",
                    "city": "Campo Lugar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10135",
                    "province": "Cáceres",
                    "city": "Alcollarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "10136",
                    "province": "Cáceres",
                    "city": "Cañamero",
                    "multiple": "0"
                },
                {
                    "zipcode": "10137",
                    "province": "Cáceres",
                    "city": "Alía",
                    "multiple": "0"
                },
                {
                    "zipcode": "10140",
                    "province": "Cáceres",
                    "city": "Guadalupe",
                    "multiple": "0"
                },
                {
                    "zipcode": "10160",
                    "province": "Cáceres",
                    "city": "Alcuéscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10161",
                    "province": "Cáceres",
                    "city": "Arroyomolinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "10162",
                    "province": "Cáceres",
                    "city": "Casas de Don Antonio",
                    "multiple": "0"
                },
                {
                    "zipcode": "10163",
                    "province": "Cáceres",
                    "city": "Aldea del Cano",
                    "multiple": "0"
                },
                {
                    "zipcode": "10164",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10169",
                    "province": "Cáceres",
                    "city": "Zarza de Montánchez",
                    "multiple": "0"
                },
                {
                    "zipcode": "10170",
                    "province": "Cáceres",
                    "city": "Montánchez",
                    "multiple": "0"
                },
                {
                    "zipcode": "10170",
                    "province": "Cáceres",
                    "city": "Zarza de Granadilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "10180",
                    "province": "Cáceres",
                    "city": "Valdefuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "10181",
                    "province": "Cáceres",
                    "city": "Sierra de Fuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "10182",
                    "province": "Cáceres",
                    "city": "Villanueva de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "10182",
                    "province": "Cáceres",
                    "city": "Torreorgaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "10183",
                    "province": "Cáceres",
                    "city": "Torrequemada",
                    "multiple": "0"
                },
                {
                    "zipcode": "10184",
                    "province": "Cáceres",
                    "city": "Torremocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "10185",
                    "province": "Cáceres",
                    "city": "Botija",
                    "multiple": "0"
                },
                {
                    "zipcode": "10185",
                    "province": "Cáceres",
                    "city": "Benquerencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "10186",
                    "province": "Cáceres",
                    "city": "Torre de Santa María",
                    "multiple": "0"
                },
                {
                    "zipcode": "10187",
                    "province": "Cáceres",
                    "city": "Albalá",
                    "multiple": "0"
                },
                {
                    "zipcode": "10189",
                    "province": "Cáceres",
                    "city": "Ruanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "10189",
                    "province": "Cáceres",
                    "city": "Salvatierra de Santiago",
                    "multiple": "1"
                },
                {
                    "zipcode": "10189",
                    "province": "Cáceres",
                    "city": "Zarza de Montánchez",
                    "multiple": "1"
                },
                {
                    "zipcode": "10189",
                    "province": "Cáceres",
                    "city": "Santa Ana",
                    "multiple": "1"
                },
                {
                    "zipcode": "10190",
                    "province": "Cáceres",
                    "city": "Casar de Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10190",
                    "province": "Cáceres",
                    "city": "Malpartida de Cáceres",
                    "multiple": "1"
                },
                {
                    "zipcode": "10191",
                    "province": "Cáceres",
                    "city": "Santiago del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10192",
                    "province": "Cáceres",
                    "city": "Hinojal",
                    "multiple": "0"
                },
                {
                    "zipcode": "10193",
                    "province": "Cáceres",
                    "city": "Talaván",
                    "multiple": "0"
                },
                {
                    "zipcode": "10194",
                    "province": "Cáceres",
                    "city": "Monroy",
                    "multiple": "0"
                },
                {
                    "zipcode": "10195",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10197",
                    "province": "Cáceres",
                    "city": "Albalá",
                    "multiple": "0"
                },
                {
                    "zipcode": "10198",
                    "province": "Cáceres",
                    "city": "Santa Marta de Magasca",
                    "multiple": "0"
                },
                {
                    "zipcode": "10198",
                    "province": "Cáceres",
                    "city": "Santiago de Alcántara",
                    "multiple": "1"
                },
                {
                    "zipcode": "10199",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10200",
                    "province": "Cáceres",
                    "city": "Trujillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10210",
                    "province": "Cáceres",
                    "city": "Madroñera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10220",
                    "province": "Cáceres",
                    "city": "Trujillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10230",
                    "province": "Cáceres",
                    "city": "Herguijuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "10240",
                    "province": "Cáceres",
                    "city": "Conquista de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "10250",
                    "province": "Cáceres",
                    "city": "Garciaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "10251",
                    "province": "Cáceres",
                    "city": "Aldeacentenera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10252",
                    "province": "Cáceres",
                    "city": "Torrecillas de la Tiesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "10260",
                    "province": "Cáceres",
                    "city": "Santa Cruz de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "10261",
                    "province": "Cáceres",
                    "city": "Puerto de Santa Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "10261",
                    "province": "Cáceres",
                    "city": "Robledillo de Trujillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10262",
                    "province": "Cáceres",
                    "city": "Abertura",
                    "multiple": "0"
                },
                {
                    "zipcode": "10263",
                    "province": "Cáceres",
                    "city": "Villamesías",
                    "multiple": "0"
                },
                {
                    "zipcode": "10269",
                    "province": "Cáceres",
                    "city": "Robledillo de Trujillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10270",
                    "province": "Cáceres",
                    "city": "Cumbre, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "10271",
                    "province": "Cáceres",
                    "city": "Plasenzuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "10280",
                    "province": "Cáceres",
                    "city": "Ibahernando",
                    "multiple": "0"
                },
                {
                    "zipcode": "10290",
                    "province": "Cáceres",
                    "city": "Trujillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10291",
                    "province": "Cáceres",
                    "city": "Aldea del Obispo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "10291",
                    "province": "Cáceres",
                    "city": "Trujillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10292",
                    "province": "Cáceres",
                    "city": "Trujillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10300",
                    "province": "Cáceres",
                    "city": "Navalmoral de la Mata",
                    "multiple": "0"
                },
                {
                    "zipcode": "10310",
                    "province": "Cáceres",
                    "city": "Talayuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "10317",
                    "province": "Cáceres",
                    "city": "Collado de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10318",
                    "province": "Cáceres",
                    "city": "Pueblonuevo de Miramontes",
                    "multiple": "0"
                },
                {
                    "zipcode": "10318",
                    "province": "Cáceres",
                    "city": "Talayuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "10319",
                    "province": "Cáceres",
                    "city": "Tiétar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10319",
                    "province": "Cáceres",
                    "city": "Talayuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "10320",
                    "province": "Cáceres",
                    "city": "Bohonal de Ibor",
                    "multiple": "0"
                },
                {
                    "zipcode": "10328",
                    "province": "Cáceres",
                    "city": "Fresnedoso de Ibor",
                    "multiple": "0"
                },
                {
                    "zipcode": "10329",
                    "province": "Cáceres",
                    "city": "Campillo de Deleitosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "10329",
                    "province": "Cáceres",
                    "city": "Valdecañas de Tajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10329",
                    "province": "Cáceres",
                    "city": "Mesas de Ibor",
                    "multiple": "1"
                },
                {
                    "zipcode": "10330",
                    "province": "Cáceres",
                    "city": "Villar del Pedroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "10331",
                    "province": "Cáceres",
                    "city": "Villar del Pedroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "10331",
                    "province": "Cáceres",
                    "city": "Carrascalejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10332",
                    "province": "Cáceres",
                    "city": "Valdelacasa de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10332",
                    "province": "Cáceres",
                    "city": "Valdecañas de Tajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10333",
                    "province": "Cáceres",
                    "city": "Garvín",
                    "multiple": "0"
                },
                {
                    "zipcode": "10334",
                    "province": "Cáceres",
                    "city": "Peraleda de San Román",
                    "multiple": "0"
                },
                {
                    "zipcode": "10335",
                    "province": "Cáceres",
                    "city": "Peraleda de la Mata",
                    "multiple": "0"
                },
                {
                    "zipcode": "10340",
                    "province": "Cáceres",
                    "city": "Castañar de Ibor",
                    "multiple": "0"
                },
                {
                    "zipcode": "10341",
                    "province": "Cáceres",
                    "city": "Navalvillar de Ibor",
                    "multiple": "0"
                },
                {
                    "zipcode": "10350",
                    "province": "Cáceres",
                    "city": "Almaraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "10359",
                    "province": "Cáceres",
                    "city": "Higuera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10359",
                    "province": "Cáceres",
                    "city": "Romangordo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10360",
                    "province": "Cáceres",
                    "city": "Casas de Miravete",
                    "multiple": "0"
                },
                {
                    "zipcode": "10370",
                    "province": "Cáceres",
                    "city": "Deleitosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "10370",
                    "province": "Cáceres",
                    "city": "Hernán-Pérez",
                    "multiple": "1"
                },
                {
                    "zipcode": "10371",
                    "province": "Cáceres",
                    "city": "Robledollano",
                    "multiple": "0"
                },
                {
                    "zipcode": "10372",
                    "province": "Cáceres",
                    "city": "Cabañas del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10373",
                    "province": "Cáceres",
                    "city": "Cabañas del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10374",
                    "province": "Cáceres",
                    "city": "Navezuelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10380",
                    "province": "Cáceres",
                    "city": "Jaraicejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10390",
                    "province": "Cáceres",
                    "city": "Saucedilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10391",
                    "province": "Cáceres",
                    "city": "Rosalejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10392",
                    "province": "Cáceres",
                    "city": "Berrocalejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10392",
                    "province": "Cáceres",
                    "city": "Gordo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "10393",
                    "province": "Cáceres",
                    "city": "Valdehúncar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10394",
                    "province": "Cáceres",
                    "city": "Belvís de Monroy",
                    "multiple": "0"
                },
                {
                    "zipcode": "10394",
                    "province": "Cáceres",
                    "city": "Millanes",
                    "multiple": "1"
                },
                {
                    "zipcode": "10400",
                    "province": "Cáceres",
                    "city": "Jaraíz de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10410",
                    "province": "Cáceres",
                    "city": "Arroyomolinos de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10411",
                    "province": "Cáceres",
                    "city": "Pasarón de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10412",
                    "province": "Cáceres",
                    "city": "Garganta la Olla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10413",
                    "province": "Cáceres",
                    "city": "Torremenga",
                    "multiple": "0"
                },
                {
                    "zipcode": "10414",
                    "province": "Cáceres",
                    "city": "Collado de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10420",
                    "province": "Cáceres",
                    "city": "Tejeda de Tiétar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10430",
                    "province": "Cáceres",
                    "city": "Cuacos de Yuste",
                    "multiple": "0"
                },
                {
                    "zipcode": "10440",
                    "province": "Cáceres",
                    "city": "Aldeanueva de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10450",
                    "province": "Cáceres",
                    "city": "Jarandilla de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10459",
                    "province": "Cáceres",
                    "city": "Guijo de Santa Bárbara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10460",
                    "province": "Cáceres",
                    "city": "Losar de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10470",
                    "province": "Cáceres",
                    "city": "Villanueva de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10480",
                    "province": "Cáceres",
                    "city": "Madrigal de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10484",
                    "province": "Cáceres",
                    "city": "Vegaviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "10490",
                    "province": "Cáceres",
                    "city": "Villanueva de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10490",
                    "province": "Cáceres",
                    "city": "Valverde de la Vera",
                    "multiple": "1"
                },
                {
                    "zipcode": "10491",
                    "province": "Cáceres",
                    "city": "Talaveruela de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10492",
                    "province": "Cáceres",
                    "city": "Viandar de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10493",
                    "province": "Cáceres",
                    "city": "Robledillo de la Vera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10500",
                    "province": "Cáceres",
                    "city": "Valencia de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10509",
                    "province": "Cáceres",
                    "city": "Valencia de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10510",
                    "province": "Cáceres",
                    "city": "Santiago de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10511",
                    "province": "Cáceres",
                    "city": "Carbajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10512",
                    "province": "Cáceres",
                    "city": "Herrera de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10513",
                    "province": "Cáceres",
                    "city": "Cedillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10514",
                    "province": "Cáceres",
                    "city": "Valencia de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10515",
                    "province": "Cáceres",
                    "city": "Valencia de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10516",
                    "province": "Cáceres",
                    "city": "Valencia de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10519",
                    "province": "Cáceres",
                    "city": "Valencia de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10520",
                    "province": "Cáceres",
                    "city": "Toril",
                    "multiple": "0"
                },
                {
                    "zipcode": "10520",
                    "province": "Cáceres",
                    "city": "Casatejada",
                    "multiple": "1"
                },
                {
                    "zipcode": "10528",
                    "province": "Cáceres",
                    "city": "Serrejón",
                    "multiple": "0"
                },
                {
                    "zipcode": "10529",
                    "province": "Cáceres",
                    "city": "Majadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10530",
                    "province": "Cáceres",
                    "city": "Serradilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10540",
                    "province": "Cáceres",
                    "city": "Mirabel",
                    "multiple": "0"
                },
                {
                    "zipcode": "10550",
                    "province": "Cáceres",
                    "city": "Aliseda",
                    "multiple": "0"
                },
                {
                    "zipcode": "10560",
                    "province": "Cáceres",
                    "city": "Herreruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "10570",
                    "province": "Cáceres",
                    "city": "Salorino",
                    "multiple": "0"
                },
                {
                    "zipcode": "10580",
                    "province": "Cáceres",
                    "city": "Membrío",
                    "multiple": "0"
                },
                {
                    "zipcode": "10590",
                    "province": "Cáceres",
                    "city": "Malpartida de Plasencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "10591",
                    "province": "Cáceres",
                    "city": "Herguijuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "10591",
                    "province": "Cáceres",
                    "city": "Toril",
                    "multiple": "1"
                },
                {
                    "zipcode": "10591",
                    "province": "Cáceres",
                    "city": "Malpartida de Plasencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "10592",
                    "province": "Cáceres",
                    "city": "Casas de Millán",
                    "multiple": "0"
                },
                {
                    "zipcode": "10593",
                    "province": "Cáceres",
                    "city": "Casar de Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10600",
                    "province": "Cáceres",
                    "city": "Plasencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "10610",
                    "province": "Cáceres",
                    "city": "Cabezuela del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "10611",
                    "province": "Cáceres",
                    "city": "Tornavacas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10612",
                    "province": "Cáceres",
                    "city": "Jerte",
                    "multiple": "0"
                },
                {
                    "zipcode": "10613",
                    "province": "Cáceres",
                    "city": "Navaconcejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10614",
                    "province": "Cáceres",
                    "city": "Valdastillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10615",
                    "province": "Cáceres",
                    "city": "Piornal",
                    "multiple": "0"
                },
                {
                    "zipcode": "10616",
                    "province": "Cáceres",
                    "city": "Cabrero",
                    "multiple": "0"
                },
                {
                    "zipcode": "10616",
                    "province": "Cáceres",
                    "city": "Guijo de Granadilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "10616",
                    "province": "Cáceres",
                    "city": "Casas del Castañar",
                    "multiple": "1"
                },
                {
                    "zipcode": "10617",
                    "province": "Cáceres",
                    "city": "Rebollar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10617",
                    "province": "Cáceres",
                    "city": "Torno, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "10620",
                    "province": "Cáceres",
                    "city": "Caminomorisco",
                    "multiple": "0"
                },
                {
                    "zipcode": "10623",
                    "province": "Cáceres",
                    "city": "Caminomorisco",
                    "multiple": "0"
                },
                {
                    "zipcode": "10623",
                    "province": "Cáceres",
                    "city": "Nuñomoral",
                    "multiple": "1"
                },
                {
                    "zipcode": "10624",
                    "province": "Cáceres",
                    "city": "Ladrillar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10624",
                    "province": "Cáceres",
                    "city": "Caminomorisco",
                    "multiple": "1"
                },
                {
                    "zipcode": "10625",
                    "province": "Cáceres",
                    "city": "Ladrillar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10626",
                    "province": "Cáceres",
                    "city": "Nuñomoral",
                    "multiple": "0"
                },
                {
                    "zipcode": "10627",
                    "province": "Cáceres",
                    "city": "Nuñomoral",
                    "multiple": "0"
                },
                {
                    "zipcode": "10628",
                    "province": "Cáceres",
                    "city": "Casares de las Hurdes",
                    "multiple": "0"
                },
                {
                    "zipcode": "10628",
                    "province": "Cáceres",
                    "city": "Nuñomoral",
                    "multiple": "1"
                },
                {
                    "zipcode": "10629",
                    "province": "Cáceres",
                    "city": "Nuñomoral",
                    "multiple": "0"
                },
                {
                    "zipcode": "10629",
                    "province": "Cáceres",
                    "city": "Caminomorisco",
                    "multiple": "1"
                },
                {
                    "zipcode": "10630",
                    "province": "Cáceres",
                    "city": "Pinofranqueado",
                    "multiple": "0"
                },
                {
                    "zipcode": "10630",
                    "province": "Cáceres",
                    "city": "Caminomorisco",
                    "multiple": "1"
                },
                {
                    "zipcode": "10630",
                    "province": "Cáceres",
                    "city": "Casar de Palomero",
                    "multiple": "1"
                },
                {
                    "zipcode": "10638",
                    "province": "Cáceres",
                    "city": "Pinofranqueado",
                    "multiple": "0"
                },
                {
                    "zipcode": "10639",
                    "province": "Cáceres",
                    "city": "Pinofranqueado",
                    "multiple": "0"
                },
                {
                    "zipcode": "10640",
                    "province": "Cáceres",
                    "city": "Casar de Palomero",
                    "multiple": "0"
                },
                {
                    "zipcode": "10649",
                    "province": "Cáceres",
                    "city": "Pesga, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "10649",
                    "province": "Cáceres",
                    "city": "Casar de Palomero",
                    "multiple": "1"
                },
                {
                    "zipcode": "10650",
                    "province": "Cáceres",
                    "city": "Ahigal",
                    "multiple": "0"
                },
                {
                    "zipcode": "10660",
                    "province": "Cáceres",
                    "city": "Oliva de Plasencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "10660",
                    "province": "Cáceres",
                    "city": "Santa Cruz de Paniagua",
                    "multiple": "1"
                },
                {
                    "zipcode": "10660",
                    "province": "Cáceres",
                    "city": "Palomero",
                    "multiple": "1"
                },
                {
                    "zipcode": "10661",
                    "province": "Cáceres",
                    "city": "Santa Cruz de Paniagua",
                    "multiple": "0"
                },
                {
                    "zipcode": "10662",
                    "province": "Cáceres",
                    "city": "Marchagaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "10663",
                    "province": "Cáceres",
                    "city": "Jaraicejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10663",
                    "province": "Cáceres",
                    "city": "Cerezo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10664",
                    "province": "Cáceres",
                    "city": "Mohedas de Granadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10665",
                    "province": "Cáceres",
                    "city": "Guijo de Granadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10666",
                    "province": "Cáceres",
                    "city": "Aceituna",
                    "multiple": "0"
                },
                {
                    "zipcode": "10666",
                    "province": "Cáceres",
                    "city": "Santibáñez el Bajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10667",
                    "province": "Cáceres",
                    "city": "Oliva de Plasencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "10670",
                    "province": "Cáceres",
                    "city": "Carcaboso",
                    "multiple": "0"
                },
                {
                    "zipcode": "10671",
                    "province": "Cáceres",
                    "city": "Aldehuela de Jerte",
                    "multiple": "0"
                },
                {
                    "zipcode": "10671",
                    "province": "Cáceres",
                    "city": "Carcaboso",
                    "multiple": "1"
                },
                {
                    "zipcode": "10671",
                    "province": "Cáceres",
                    "city": "Plasencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "10672",
                    "province": "Cáceres",
                    "city": "Valdeobispo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10680",
                    "province": "Cáceres",
                    "city": "Malpartida de Plasencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "10690",
                    "province": "Cáceres",
                    "city": "Galisteo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10690",
                    "province": "Cáceres",
                    "city": "Plasencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "10690",
                    "province": "Cáceres",
                    "city": "Alagón del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "10691",
                    "province": "Cáceres",
                    "city": "Galisteo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10692",
                    "province": "Cáceres",
                    "city": "Guijo de Galisteo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10693",
                    "province": "Cáceres",
                    "city": "Riolobos",
                    "multiple": "0"
                },
                {
                    "zipcode": "10694",
                    "province": "Cáceres",
                    "city": "Torrejón el Rubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "10694",
                    "province": "Cáceres",
                    "city": "Toril",
                    "multiple": "1"
                },
                {
                    "zipcode": "10695",
                    "province": "Cáceres",
                    "city": "Serradilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10696",
                    "province": "Cáceres",
                    "city": "Gargüera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10696",
                    "province": "Cáceres",
                    "city": "Barrado",
                    "multiple": "1"
                },
                {
                    "zipcode": "10697",
                    "province": "Cáceres",
                    "city": "Malpartida de Plasencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "10697",
                    "province": "Cáceres",
                    "city": "Tejeda de Tiétar",
                    "multiple": "1"
                },
                {
                    "zipcode": "10697",
                    "province": "Cáceres",
                    "city": "Toril",
                    "multiple": "1"
                },
                {
                    "zipcode": "10700",
                    "province": "Cáceres",
                    "city": "Hervás",
                    "multiple": "0"
                },
                {
                    "zipcode": "10701",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10710",
                    "province": "Cáceres",
                    "city": "Zarza de Granadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10711",
                    "province": "Cáceres",
                    "city": "Granja, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "10712",
                    "province": "Cáceres",
                    "city": "Guijo de Granadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10720",
                    "province": "Cáceres",
                    "city": "Villar de Plasencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "10728",
                    "province": "Cáceres",
                    "city": "Jarilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10729",
                    "province": "Cáceres",
                    "city": "Cabezabellosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "10730",
                    "province": "Cáceres",
                    "city": "Casas del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "10739",
                    "province": "Cáceres",
                    "city": "Segura de Toro",
                    "multiple": "0"
                },
                {
                    "zipcode": "10740",
                    "province": "Cáceres",
                    "city": "Aldeanueva del Camino",
                    "multiple": "0"
                },
                {
                    "zipcode": "10748",
                    "province": "Cáceres",
                    "city": "Abadía",
                    "multiple": "0"
                },
                {
                    "zipcode": "10749",
                    "province": "Cáceres",
                    "city": "Gargantilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10750",
                    "province": "Cáceres",
                    "city": "Baños de Montemayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "10759",
                    "province": "Cáceres",
                    "city": "Garganta, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "10800",
                    "province": "Cáceres",
                    "city": "Coria",
                    "multiple": "0"
                },
                {
                    "zipcode": "10810",
                    "province": "Cáceres",
                    "city": "Valdefuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "10810",
                    "province": "Cáceres",
                    "city": "Montehermoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "10811",
                    "province": "Cáceres",
                    "city": "Morcillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10811",
                    "province": "Cáceres",
                    "city": "Coria",
                    "multiple": "1"
                },
                {
                    "zipcode": "10811",
                    "province": "Cáceres",
                    "city": "Guijo de Galisteo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10812",
                    "province": "Cáceres",
                    "city": "Villanueva de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "10813",
                    "province": "Cáceres",
                    "city": "Pozuelo de Zarzón",
                    "multiple": "0"
                },
                {
                    "zipcode": "10814",
                    "province": "Cáceres",
                    "city": "Villa del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10815",
                    "province": "Cáceres",
                    "city": "Guijo de Coria",
                    "multiple": "0"
                },
                {
                    "zipcode": "10816",
                    "province": "Cáceres",
                    "city": "Casas de Don Gómez",
                    "multiple": "0"
                },
                {
                    "zipcode": "10816",
                    "province": "Cáceres",
                    "city": "Guijo de Galisteo",
                    "multiple": "1"
                },
                {
                    "zipcode": "10817",
                    "province": "Cáceres",
                    "city": "Calzadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10818",
                    "province": "Cáceres",
                    "city": "Casas de Don Gómez",
                    "multiple": "0"
                },
                {
                    "zipcode": "10818",
                    "province": "Cáceres",
                    "city": "Casillas de Coria",
                    "multiple": "1"
                },
                {
                    "zipcode": "10820",
                    "province": "Cáceres",
                    "city": "Cañaveral",
                    "multiple": "0"
                },
                {
                    "zipcode": "10824",
                    "province": "Cáceres",
                    "city": "Holguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10828",
                    "province": "Cáceres",
                    "city": "Portezuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10829",
                    "province": "Cáceres",
                    "city": "Holguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "10829",
                    "province": "Cáceres",
                    "city": "Pedroso de Acim",
                    "multiple": "1"
                },
                {
                    "zipcode": "10829",
                    "province": "Cáceres",
                    "city": "Cañaveral",
                    "multiple": "1"
                },
                {
                    "zipcode": "10829",
                    "province": "Cáceres",
                    "city": "Riolobos",
                    "multiple": "1"
                },
                {
                    "zipcode": "10830",
                    "province": "Cáceres",
                    "city": "Torrejoncillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10839",
                    "province": "Cáceres",
                    "city": "Torrejoncillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10840",
                    "province": "Cáceres",
                    "city": "Moraleja",
                    "multiple": "0"
                },
                {
                    "zipcode": "10848",
                    "province": "Cáceres",
                    "city": "Vegaviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "10849",
                    "province": "Cáceres",
                    "city": "Huélaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "10849",
                    "province": "Cáceres",
                    "city": "Gata",
                    "multiple": "1"
                },
                {
                    "zipcode": "10850",
                    "province": "Cáceres",
                    "city": "Hoyos",
                    "multiple": "0"
                },
                {
                    "zipcode": "10857",
                    "province": "Cáceres",
                    "city": "Acebo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10858",
                    "province": "Cáceres",
                    "city": "Villasbuenas de Gata",
                    "multiple": "0"
                },
                {
                    "zipcode": "10859",
                    "province": "Cáceres",
                    "city": "Santibáñez el Alto",
                    "multiple": "0"
                },
                {
                    "zipcode": "10860",
                    "province": "Cáceres",
                    "city": "Gata",
                    "multiple": "0"
                },
                {
                    "zipcode": "10864",
                    "province": "Cáceres",
                    "city": "Torre de Don Miguel",
                    "multiple": "0"
                },
                {
                    "zipcode": "10865",
                    "province": "Cáceres",
                    "city": "Cadalso",
                    "multiple": "0"
                },
                {
                    "zipcode": "10866",
                    "province": "Cáceres",
                    "city": "Descargamaría",
                    "multiple": "0"
                },
                {
                    "zipcode": "10867",
                    "province": "Cáceres",
                    "city": "Robledillo de Gata",
                    "multiple": "0"
                },
                {
                    "zipcode": "10868",
                    "province": "Cáceres",
                    "city": "Hernán-Pérez",
                    "multiple": "0"
                },
                {
                    "zipcode": "10869",
                    "province": "Cáceres",
                    "city": "Torrecilla de los Ángeles",
                    "multiple": "0"
                },
                {
                    "zipcode": "10870",
                    "province": "Cáceres",
                    "city": "Ceclavín",
                    "multiple": "0"
                },
                {
                    "zipcode": "10879",
                    "province": "Cáceres",
                    "city": "Acehúche",
                    "multiple": "0"
                },
                {
                    "zipcode": "10880",
                    "province": "Cáceres",
                    "city": "Zarza la Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "10880",
                    "province": "Cáceres",
                    "city": "Gata",
                    "multiple": "1"
                },
                {
                    "zipcode": "10881",
                    "province": "Cáceres",
                    "city": "Cachorrilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "10882",
                    "province": "Cáceres",
                    "city": "Pescueza",
                    "multiple": "0"
                },
                {
                    "zipcode": "10883",
                    "province": "Cáceres",
                    "city": "Portaje",
                    "multiple": "0"
                },
                {
                    "zipcode": "10890",
                    "province": "Cáceres",
                    "city": "Valverde del Fresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "10891",
                    "province": "Cáceres",
                    "city": "Eljas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10892",
                    "province": "Cáceres",
                    "city": "San Martín de Trevejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "10893",
                    "province": "Cáceres",
                    "city": "Villamiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "10894",
                    "province": "Cáceres",
                    "city": "Villamiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "10895",
                    "province": "Cáceres",
                    "city": "Cilleros",
                    "multiple": "0"
                },
                {
                    "zipcode": "10896",
                    "province": "Cáceres",
                    "city": "Perales del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "10897",
                    "province": "Cáceres",
                    "city": "Cañaveral",
                    "multiple": "0"
                },
                {
                    "zipcode": "10900",
                    "province": "Cáceres",
                    "city": "Arroyo de la Luz",
                    "multiple": "0"
                },
                {
                    "zipcode": "10910",
                    "province": "Cáceres",
                    "city": "Malpartida de Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10920",
                    "province": "Cáceres",
                    "city": "Cáceres",
                    "multiple": "0"
                },
                {
                    "zipcode": "10930",
                    "province": "Cáceres",
                    "city": "Navas del Madroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "10940",
                    "province": "Cáceres",
                    "city": "Garrovillas de Alconétar",
                    "multiple": "0"
                },
                {
                    "zipcode": "10950",
                    "province": "Cáceres",
                    "city": "Brozas",
                    "multiple": "0"
                },
                {
                    "zipcode": "10960",
                    "province": "Cáceres",
                    "city": "Villa del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "10970",
                    "province": "Cáceres",
                    "city": "Mata de Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10980",
                    "province": "Cáceres",
                    "city": "Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10989",
                    "province": "Cáceres",
                    "city": "Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10990",
                    "province": "Cáceres",
                    "city": "Alcántara",
                    "multiple": "0"
                },
                {
                    "zipcode": "10991",
                    "province": "Cáceres",
                    "city": "Piedras Albas",
                    "multiple": "0"
                },
                {
                    "zipcode": "11001",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11002",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11003",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11004",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11005",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11006",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11007",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11008",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11009",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11010",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11011",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11012",
                    "province": "Cádiz",
                    "city": "Cádiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11100",
                    "province": "Cádiz",
                    "city": "San Fernando",
                    "multiple": "0"
                },
                {
                    "zipcode": "11130",
                    "province": "Cádiz",
                    "city": "Chiclana de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11139",
                    "province": "Cádiz",
                    "city": "Chiclana de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11140",
                    "province": "Cádiz",
                    "city": "Conil de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11149",
                    "province": "Cádiz",
                    "city": "Conil de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11150",
                    "province": "Cádiz",
                    "city": "Vejer de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11150",
                    "province": "Cádiz",
                    "city": "Barbate",
                    "multiple": "1"
                },
                {
                    "zipcode": "11158",
                    "province": "Cádiz",
                    "city": "Vejer de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11159",
                    "province": "Cádiz",
                    "city": "Barbate",
                    "multiple": "0"
                },
                {
                    "zipcode": "11159",
                    "province": "Cádiz",
                    "city": "Vejer de la Frontera",
                    "multiple": "1"
                },
                {
                    "zipcode": "11160",
                    "province": "Cádiz",
                    "city": "Barbate",
                    "multiple": "0"
                },
                {
                    "zipcode": "11170",
                    "province": "Cádiz",
                    "city": "Medina-Sidonia",
                    "multiple": "0"
                },
                {
                    "zipcode": "11178",
                    "province": "Cádiz",
                    "city": "Paterna de Rivera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11179",
                    "province": "Cádiz",
                    "city": "Medina-Sidonia",
                    "multiple": "0"
                },
                {
                    "zipcode": "11179",
                    "province": "Cádiz",
                    "city": "Vejer de la Frontera",
                    "multiple": "1"
                },
                {
                    "zipcode": "11180",
                    "province": "Cádiz",
                    "city": "Alcalá de los Gazules",
                    "multiple": "0"
                },
                {
                    "zipcode": "11190",
                    "province": "Cádiz",
                    "city": "Benalup-Casas Viejas",
                    "multiple": "0"
                },
                {
                    "zipcode": "11201",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11202",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11203",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11204",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11205",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11206",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11207",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11300",
                    "province": "Cádiz",
                    "city": "Puerto Serrano",
                    "multiple": "0"
                },
                {
                    "zipcode": "11300",
                    "province": "Cádiz",
                    "city": "Línea de la Concepción, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "11310",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "0"
                },
                {
                    "zipcode": "11311",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "0"
                },
                {
                    "zipcode": "11311",
                    "province": "Cádiz",
                    "city": "Sanlúcar de Barrameda",
                    "multiple": "1"
                },
                {
                    "zipcode": "11312",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "0"
                },
                {
                    "zipcode": "11313",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "0"
                },
                {
                    "zipcode": "11314",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "0"
                },
                {
                    "zipcode": "11320",
                    "province": "Cádiz",
                    "city": "Jimena de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11330",
                    "province": "Cádiz",
                    "city": "Jimena de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11339",
                    "province": "Cádiz",
                    "city": "Jimena de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11340",
                    "province": "Cádiz",
                    "city": "Jimena de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11350",
                    "province": "Cádiz",
                    "city": "Castellar de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11360",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "0"
                },
                {
                    "zipcode": "11368",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "0"
                },
                {
                    "zipcode": "11369",
                    "province": "Cádiz",
                    "city": "Sanlúcar de Barrameda",
                    "multiple": "0"
                },
                {
                    "zipcode": "11369",
                    "province": "Cádiz",
                    "city": "San Roque",
                    "multiple": "1"
                },
                {
                    "zipcode": "11370",
                    "province": "Cádiz",
                    "city": "Barrios, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "11379",
                    "province": "Cádiz",
                    "city": "Barrios, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "11380",
                    "province": "Cádiz",
                    "city": "Tarifa",
                    "multiple": "0"
                },
                {
                    "zipcode": "11389",
                    "province": "Cádiz",
                    "city": "Algodonales",
                    "multiple": "0"
                },
                {
                    "zipcode": "11390",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "0"
                },
                {
                    "zipcode": "11390",
                    "province": "Cádiz",
                    "city": "Tarifa",
                    "multiple": "1"
                },
                {
                    "zipcode": "11391",
                    "province": "Cádiz",
                    "city": "Tarifa",
                    "multiple": "0"
                },
                {
                    "zipcode": "11391",
                    "province": "Cádiz",
                    "city": "Algeciras",
                    "multiple": "1"
                },
                {
                    "zipcode": "11392",
                    "province": "Cádiz",
                    "city": "Tarifa",
                    "multiple": "0"
                },
                {
                    "zipcode": "11393",
                    "province": "Cádiz",
                    "city": "Barbate",
                    "multiple": "0"
                },
                {
                    "zipcode": "11393",
                    "province": "Cádiz",
                    "city": "Tarifa",
                    "multiple": "1"
                },
                {
                    "zipcode": "11400",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11401",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11402",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11403",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11404",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11405",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11406",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11407",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11408",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11500",
                    "province": "Cádiz",
                    "city": "Puerto de Santa María, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "11510",
                    "province": "Cádiz",
                    "city": "Puerto Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "11518",
                    "province": "Cádiz",
                    "city": "Puerto Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "11519",
                    "province": "Cádiz",
                    "city": "Puerto Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "11520",
                    "province": "Cádiz",
                    "city": "Bornos",
                    "multiple": "0"
                },
                {
                    "zipcode": "11520",
                    "province": "Cádiz",
                    "city": "Rota",
                    "multiple": "1"
                },
                {
                    "zipcode": "11540",
                    "province": "Cádiz",
                    "city": "Sanlúcar de Barrameda",
                    "multiple": "0"
                },
                {
                    "zipcode": "11549",
                    "province": "Cádiz",
                    "city": "Sanlúcar de Barrameda",
                    "multiple": "0"
                },
                {
                    "zipcode": "11550",
                    "province": "Cádiz",
                    "city": "Chipiona",
                    "multiple": "0"
                },
                {
                    "zipcode": "11560",
                    "province": "Cádiz",
                    "city": "Trebujena",
                    "multiple": "0"
                },
                {
                    "zipcode": "11570",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11579",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11580",
                    "province": "Cádiz",
                    "city": "San José del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "11589",
                    "province": "Cádiz",
                    "city": "San José del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "11590",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11591",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11592",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11593",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11594",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11595",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11596",
                    "province": "Cádiz",
                    "city": "Jerez de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11600",
                    "province": "Cádiz",
                    "city": "Ubrique",
                    "multiple": "0"
                },
                {
                    "zipcode": "11610",
                    "province": "Cádiz",
                    "city": "Grazalema",
                    "multiple": "0"
                },
                {
                    "zipcode": "11611",
                    "province": "Cádiz",
                    "city": "Villaluenga del Rosario",
                    "multiple": "0"
                },
                {
                    "zipcode": "11612",
                    "province": "Cádiz",
                    "city": "Benaocaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "11620",
                    "province": "Cádiz",
                    "city": "Arcos de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11630",
                    "province": "Cádiz",
                    "city": "Arcos de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11638",
                    "province": "Cádiz",
                    "city": "Arcos de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11639",
                    "province": "Cádiz",
                    "city": "Algar",
                    "multiple": "0"
                },
                {
                    "zipcode": "11640",
                    "province": "Cádiz",
                    "city": "Bornos",
                    "multiple": "0"
                },
                {
                    "zipcode": "11648",
                    "province": "Cádiz",
                    "city": "Espera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11649",
                    "province": "Cádiz",
                    "city": "Bornos",
                    "multiple": "0"
                },
                {
                    "zipcode": "11650",
                    "province": "Cádiz",
                    "city": "Villamartín",
                    "multiple": "0"
                },
                {
                    "zipcode": "11659",
                    "province": "Cádiz",
                    "city": "Puerto Serrano",
                    "multiple": "0"
                },
                {
                    "zipcode": "11660",
                    "province": "Cádiz",
                    "city": "Prado del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "11670",
                    "province": "Cádiz",
                    "city": "Bosque, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "11679",
                    "province": "Cádiz",
                    "city": "Grazalema",
                    "multiple": "0"
                },
                {
                    "zipcode": "11680",
                    "province": "Cádiz",
                    "city": "Algodonales",
                    "multiple": "0"
                },
                {
                    "zipcode": "11687",
                    "province": "Cádiz",
                    "city": "Gastor, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "11688",
                    "province": "Cádiz",
                    "city": "Zahara",
                    "multiple": "0"
                },
                {
                    "zipcode": "11689",
                    "province": "Cádiz",
                    "city": "Algodonales",
                    "multiple": "0"
                },
                {
                    "zipcode": "11690",
                    "province": "Cádiz",
                    "city": "Olvera",
                    "multiple": "0"
                },
                {
                    "zipcode": "11691",
                    "province": "Cádiz",
                    "city": "Torre Alháquime",
                    "multiple": "0"
                },
                {
                    "zipcode": "11692",
                    "province": "Cádiz",
                    "city": "Setenil de las Bodegas",
                    "multiple": "0"
                },
                {
                    "zipcode": "11693",
                    "province": "Cádiz",
                    "city": "Alcalá del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "12001",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12002",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12003",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12004",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12005",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12006",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12100",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12100",
                    "province": "Castellón",
                    "city": "Alcora, l'",
                    "multiple": "1"
                },
                {
                    "zipcode": "12110",
                    "province": "Castellón",
                    "city": "Alcora, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "12118",
                    "province": "Castellón",
                    "city": "Useras/Useres, les",
                    "multiple": "0"
                },
                {
                    "zipcode": "12119",
                    "province": "Castellón",
                    "city": "Alcora, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "12119",
                    "province": "Castellón",
                    "city": "Costur",
                    "multiple": "1"
                },
                {
                    "zipcode": "12120",
                    "province": "Castellón",
                    "city": "Lucena del Cid",
                    "multiple": "0"
                },
                {
                    "zipcode": "12121",
                    "province": "Castellón",
                    "city": "Alcora, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "12122",
                    "province": "Castellón",
                    "city": "Useras/Useres, les",
                    "multiple": "0"
                },
                {
                    "zipcode": "12122",
                    "province": "Castellón",
                    "city": "Figueroles",
                    "multiple": "1"
                },
                {
                    "zipcode": "12123",
                    "province": "Castellón",
                    "city": "Ludiente",
                    "multiple": "0"
                },
                {
                    "zipcode": "12123",
                    "province": "Castellón",
                    "city": "Castillo de Villamalefa",
                    "multiple": "1"
                },
                {
                    "zipcode": "12124",
                    "province": "Castellón",
                    "city": "Villahermosa del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "12124",
                    "province": "Castellón",
                    "city": "Chodos/Xodos",
                    "multiple": "1"
                },
                {
                    "zipcode": "12125",
                    "province": "Castellón",
                    "city": "Zucaina",
                    "multiple": "0"
                },
                {
                    "zipcode": "12126",
                    "province": "Castellón",
                    "city": "Cortes de Arenoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "12127",
                    "province": "Castellón",
                    "city": "Cortes de Arenoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "12130",
                    "province": "Castellón",
                    "city": "Sant Joan de Moró",
                    "multiple": "0"
                },
                {
                    "zipcode": "12131",
                    "province": "Castellón",
                    "city": "Useras/Useres, les",
                    "multiple": "0"
                },
                {
                    "zipcode": "12132",
                    "province": "Castellón",
                    "city": "Atzeneta del Maestrat",
                    "multiple": "0"
                },
                {
                    "zipcode": "12133",
                    "province": "Castellón",
                    "city": "Atzeneta del Maestrat",
                    "multiple": "0"
                },
                {
                    "zipcode": "12134",
                    "province": "Castellón",
                    "city": "Benafigos",
                    "multiple": "0"
                },
                {
                    "zipcode": "12134",
                    "province": "Castellón",
                    "city": "Chodos/Xodos",
                    "multiple": "1"
                },
                {
                    "zipcode": "12135",
                    "province": "Castellón",
                    "city": "Geldo",
                    "multiple": "0"
                },
                {
                    "zipcode": "12135",
                    "province": "Castellón",
                    "city": "Vistabella del Maestrat",
                    "multiple": "1"
                },
                {
                    "zipcode": "12140",
                    "province": "Castellón",
                    "city": "Albocàsser",
                    "multiple": "0"
                },
                {
                    "zipcode": "12150",
                    "province": "Castellón",
                    "city": "Villafranca del Cid/Vilafranca",
                    "multiple": "0"
                },
                {
                    "zipcode": "12159",
                    "province": "Castellón",
                    "city": "Castellfort",
                    "multiple": "0"
                },
                {
                    "zipcode": "12160",
                    "province": "Castellón",
                    "city": "Benassal",
                    "multiple": "0"
                },
                {
                    "zipcode": "12161",
                    "province": "Castellón",
                    "city": "Torre d'En Besora, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12162",
                    "province": "Castellón",
                    "city": "Vilar de Canes",
                    "multiple": "0"
                },
                {
                    "zipcode": "12163",
                    "province": "Castellón",
                    "city": "Culla",
                    "multiple": "0"
                },
                {
                    "zipcode": "12164",
                    "province": "Castellón",
                    "city": "Sierra Engarcerán",
                    "multiple": "0"
                },
                {
                    "zipcode": "12165",
                    "province": "Castellón",
                    "city": "Ares del Maestrat",
                    "multiple": "0"
                },
                {
                    "zipcode": "12166",
                    "province": "Castellón",
                    "city": "Sierra Engarcerán",
                    "multiple": "0"
                },
                {
                    "zipcode": "12170",
                    "province": "Castellón",
                    "city": "Sant Mateu",
                    "multiple": "0"
                },
                {
                    "zipcode": "12179",
                    "province": "Castellón",
                    "city": "Tírig",
                    "multiple": "0"
                },
                {
                    "zipcode": "12180",
                    "province": "Castellón",
                    "city": "Cabanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "12181",
                    "province": "Castellón",
                    "city": "Benlloch",
                    "multiple": "0"
                },
                {
                    "zipcode": "12182",
                    "province": "Castellón",
                    "city": "Sierra Engarcerán",
                    "multiple": "0"
                },
                {
                    "zipcode": "12183",
                    "province": "Castellón",
                    "city": "Vilanova d'Alcolea",
                    "multiple": "0"
                },
                {
                    "zipcode": "12184",
                    "province": "Castellón",
                    "city": "Serratella, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12184",
                    "province": "Castellón",
                    "city": "Torre d'en Doménec, la",
                    "multiple": "1"
                },
                {
                    "zipcode": "12185",
                    "province": "Castellón",
                    "city": "Coves de Vinromà, les",
                    "multiple": "0"
                },
                {
                    "zipcode": "12186",
                    "province": "Castellón",
                    "city": "Salzadella, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12190",
                    "province": "Castellón",
                    "city": "Vall d'Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "12190",
                    "province": "Castellón",
                    "city": "Borriol",
                    "multiple": "1"
                },
                {
                    "zipcode": "12191",
                    "province": "Castellón",
                    "city": "Pobla Tornesa, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12192",
                    "province": "Castellón",
                    "city": "Vilafamés",
                    "multiple": "0"
                },
                {
                    "zipcode": "12193",
                    "province": "Castellón",
                    "city": "Vall d'Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "12193",
                    "province": "Castellón",
                    "city": "Sierra Engarcerán",
                    "multiple": "1"
                },
                {
                    "zipcode": "12194",
                    "province": "Castellón",
                    "city": "Vall d'Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "12200",
                    "province": "Castellón",
                    "city": "Onda",
                    "multiple": "0"
                },
                {
                    "zipcode": "12210",
                    "province": "Castellón",
                    "city": "Ribesalbes",
                    "multiple": "0"
                },
                {
                    "zipcode": "12220",
                    "province": "Castellón",
                    "city": "Onda",
                    "multiple": "0"
                },
                {
                    "zipcode": "12221",
                    "province": "Castellón",
                    "city": "Tales",
                    "multiple": "0"
                },
                {
                    "zipcode": "12222",
                    "province": "Castellón",
                    "city": "Alcudia de Veo",
                    "multiple": "0"
                },
                {
                    "zipcode": "12222",
                    "province": "Castellón",
                    "city": "Aín",
                    "multiple": "1"
                },
                {
                    "zipcode": "12223",
                    "province": "Castellón",
                    "city": "Sueras/Suera",
                    "multiple": "0"
                },
                {
                    "zipcode": "12224",
                    "province": "Castellón",
                    "city": "Ayódar",
                    "multiple": "0"
                },
                {
                    "zipcode": "12224",
                    "province": "Castellón",
                    "city": "Villamalur",
                    "multiple": "1"
                },
                {
                    "zipcode": "12225",
                    "province": "Castellón",
                    "city": "Torralba del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "12225",
                    "province": "Castellón",
                    "city": "Fuentes de Ayódar",
                    "multiple": "1"
                },
                {
                    "zipcode": "12230",
                    "province": "Castellón",
                    "city": "Espadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "12230",
                    "province": "Castellón",
                    "city": "Argelita",
                    "multiple": "1"
                },
                {
                    "zipcode": "12230",
                    "province": "Castellón",
                    "city": "Fanzara",
                    "multiple": "1"
                },
                {
                    "zipcode": "12230",
                    "province": "Castellón",
                    "city": "Toga",
                    "multiple": "1"
                },
                {
                    "zipcode": "12230",
                    "province": "Castellón",
                    "city": "Vallat",
                    "multiple": "1"
                },
                {
                    "zipcode": "12231",
                    "province": "Castellón",
                    "city": "Cirat",
                    "multiple": "0"
                },
                {
                    "zipcode": "12232",
                    "province": "Castellón",
                    "city": "Cirat",
                    "multiple": "0"
                },
                {
                    "zipcode": "12232",
                    "province": "Castellón",
                    "city": "Arañuel",
                    "multiple": "1"
                },
                {
                    "zipcode": "12232",
                    "province": "Castellón",
                    "city": "Torrechiva",
                    "multiple": "1"
                },
                {
                    "zipcode": "12300",
                    "province": "Castellón",
                    "city": "Morella",
                    "multiple": "0"
                },
                {
                    "zipcode": "12310",
                    "province": "Castellón",
                    "city": "Forcall",
                    "multiple": "0"
                },
                {
                    "zipcode": "12311",
                    "province": "Castellón",
                    "city": "Palanques",
                    "multiple": "0"
                },
                {
                    "zipcode": "12311",
                    "province": "Castellón",
                    "city": "Zorita del Maestrazgo",
                    "multiple": "1"
                },
                {
                    "zipcode": "12311",
                    "province": "Castellón",
                    "city": "Villores",
                    "multiple": "1"
                },
                {
                    "zipcode": "12312",
                    "province": "Castellón",
                    "city": "Todolella",
                    "multiple": "0"
                },
                {
                    "zipcode": "12312",
                    "province": "Castellón",
                    "city": "Mata de Morella, la",
                    "multiple": "1"
                },
                {
                    "zipcode": "12312",
                    "province": "Castellón",
                    "city": "Olocau del Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "12315",
                    "province": "Castellón",
                    "city": "Vallibona",
                    "multiple": "0"
                },
                {
                    "zipcode": "12317",
                    "province": "Castellón",
                    "city": "Herbés",
                    "multiple": "0"
                },
                {
                    "zipcode": "12318",
                    "province": "Castellón",
                    "city": "Portell de Morella",
                    "multiple": "0"
                },
                {
                    "zipcode": "12318",
                    "province": "Castellón",
                    "city": "Cinctorres",
                    "multiple": "1"
                },
                {
                    "zipcode": "12319",
                    "province": "Castellón",
                    "city": "Castell de Cabres",
                    "multiple": "0"
                },
                {
                    "zipcode": "12320",
                    "province": "Castellón",
                    "city": "Sant Jordi/San Jorge",
                    "multiple": "0"
                },
                {
                    "zipcode": "12330",
                    "province": "Castellón",
                    "city": "Traiguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "12330",
                    "province": "Castellón",
                    "city": "San Rafael del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "12340",
                    "province": "Castellón",
                    "city": "Jana, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12350",
                    "province": "Castellón",
                    "city": "Canet lo Roig",
                    "multiple": "0"
                },
                {
                    "zipcode": "12360",
                    "province": "Castellón",
                    "city": "Xert",
                    "multiple": "0"
                },
                {
                    "zipcode": "12370",
                    "province": "Castellón",
                    "city": "Xert",
                    "multiple": "0"
                },
                {
                    "zipcode": "12400",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12400",
                    "province": "Castellón",
                    "city": "Segorbe",
                    "multiple": "1"
                },
                {
                    "zipcode": "12410",
                    "province": "Castellón",
                    "city": "Altura",
                    "multiple": "0"
                },
                {
                    "zipcode": "12412",
                    "province": "Castellón",
                    "city": "Segorbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "12412",
                    "province": "Castellón",
                    "city": "Geldo",
                    "multiple": "1"
                },
                {
                    "zipcode": "12413",
                    "province": "Castellón",
                    "city": "Castellnovo",
                    "multiple": "0"
                },
                {
                    "zipcode": "12413",
                    "province": "Castellón",
                    "city": "Almedíjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "12414",
                    "province": "Castellón",
                    "city": "Vall de Almonacid",
                    "multiple": "0"
                },
                {
                    "zipcode": "12414",
                    "province": "Castellón",
                    "city": "Segorbe",
                    "multiple": "1"
                },
                {
                    "zipcode": "12414",
                    "province": "Castellón",
                    "city": "Algimia de Almonacid",
                    "multiple": "1"
                },
                {
                    "zipcode": "12415",
                    "province": "Castellón",
                    "city": "Gaibiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "12415",
                    "province": "Castellón",
                    "city": "Matet",
                    "multiple": "1"
                },
                {
                    "zipcode": "12420",
                    "province": "Castellón",
                    "city": "Barracas",
                    "multiple": "0"
                },
                {
                    "zipcode": "12428",
                    "province": "Castellón",
                    "city": "Puebla de Arenoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "12428",
                    "province": "Castellón",
                    "city": "Fuente la Reina",
                    "multiple": "1"
                },
                {
                    "zipcode": "12428",
                    "province": "Castellón",
                    "city": "Villanueva de Viver",
                    "multiple": "1"
                },
                {
                    "zipcode": "12429",
                    "province": "Castellón",
                    "city": "Pina de Montalgrao",
                    "multiple": "0"
                },
                {
                    "zipcode": "12429",
                    "province": "Castellón",
                    "city": "Toro, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "12430",
                    "province": "Castellón",
                    "city": "Bejís",
                    "multiple": "0"
                },
                {
                    "zipcode": "12431",
                    "province": "Castellón",
                    "city": "Torás",
                    "multiple": "0"
                },
                {
                    "zipcode": "12440",
                    "province": "Castellón",
                    "city": "Caudiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "12447",
                    "province": "Castellón",
                    "city": "Montán",
                    "multiple": "0"
                },
                {
                    "zipcode": "12448",
                    "province": "Castellón",
                    "city": "Montanejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "12449",
                    "province": "Castellón",
                    "city": "Benafer",
                    "multiple": "0"
                },
                {
                    "zipcode": "12449",
                    "province": "Castellón",
                    "city": "Higueras",
                    "multiple": "1"
                },
                {
                    "zipcode": "12449",
                    "province": "Castellón",
                    "city": "Pavías",
                    "multiple": "1"
                },
                {
                    "zipcode": "12450",
                    "province": "Castellón",
                    "city": "Jérica",
                    "multiple": "0"
                },
                {
                    "zipcode": "12460",
                    "province": "Castellón",
                    "city": "Viver",
                    "multiple": "0"
                },
                {
                    "zipcode": "12469",
                    "province": "Castellón",
                    "city": "Sacañet",
                    "multiple": "0"
                },
                {
                    "zipcode": "12469",
                    "province": "Castellón",
                    "city": "Teresa",
                    "multiple": "1"
                },
                {
                    "zipcode": "12469",
                    "province": "Castellón",
                    "city": "Viver",
                    "multiple": "1"
                },
                {
                    "zipcode": "12470",
                    "province": "Castellón",
                    "city": "Navajas",
                    "multiple": "0"
                },
                {
                    "zipcode": "12480",
                    "province": "Castellón",
                    "city": "Soneja",
                    "multiple": "0"
                },
                {
                    "zipcode": "12489",
                    "province": "Castellón",
                    "city": "Sot de Ferrer",
                    "multiple": "0"
                },
                {
                    "zipcode": "12490",
                    "province": "Castellón",
                    "city": "Azuébar",
                    "multiple": "0"
                },
                {
                    "zipcode": "12499",
                    "province": "Castellón",
                    "city": "Chóvar",
                    "multiple": "0"
                },
                {
                    "zipcode": "12500",
                    "province": "Castellón",
                    "city": "Vinaròs",
                    "multiple": "0"
                },
                {
                    "zipcode": "12510",
                    "province": "Castellón",
                    "city": "San Rafael del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "12511",
                    "province": "Castellón",
                    "city": "Rossell",
                    "multiple": "0"
                },
                {
                    "zipcode": "12512",
                    "province": "Castellón",
                    "city": "Rossell",
                    "multiple": "0"
                },
                {
                    "zipcode": "12513",
                    "province": "Castellón",
                    "city": "Catí",
                    "multiple": "0"
                },
                {
                    "zipcode": "12520",
                    "province": "Castellón",
                    "city": "Nules",
                    "multiple": "0"
                },
                {
                    "zipcode": "12526",
                    "province": "Castellón",
                    "city": "Vilavella, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12527",
                    "province": "Castellón",
                    "city": "Artana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12528",
                    "province": "Castellón",
                    "city": "Eslida",
                    "multiple": "0"
                },
                {
                    "zipcode": "12529",
                    "province": "Castellón",
                    "city": "Nules",
                    "multiple": "0"
                },
                {
                    "zipcode": "12530",
                    "province": "Castellón",
                    "city": "Benicarló",
                    "multiple": "0"
                },
                {
                    "zipcode": "12530",
                    "province": "Castellón",
                    "city": "Borriana/Burriana",
                    "multiple": "1"
                },
                {
                    "zipcode": "12539",
                    "province": "Castellón",
                    "city": "Alqueries, les/Alquerías del Niño Perdido",
                    "multiple": "0"
                },
                {
                    "zipcode": "12540",
                    "province": "Castellón",
                    "city": "Vila-real",
                    "multiple": "0"
                },
                {
                    "zipcode": "12549",
                    "province": "Castellón",
                    "city": "Betxí",
                    "multiple": "0"
                },
                {
                    "zipcode": "12550",
                    "province": "Castellón",
                    "city": "Almazora/Almassora",
                    "multiple": "0"
                },
                {
                    "zipcode": "12560",
                    "province": "Castellón",
                    "city": "Castellón de la Plana/Castelló de la Plana",
                    "multiple": "0"
                },
                {
                    "zipcode": "12560",
                    "province": "Castellón",
                    "city": "Benicasim/Benicàssim",
                    "multiple": "1"
                },
                {
                    "zipcode": "12570",
                    "province": "Castellón",
                    "city": "Alcalà de Xivert",
                    "multiple": "0"
                },
                {
                    "zipcode": "12578",
                    "province": "Castellón",
                    "city": "Cervera del Maestre",
                    "multiple": "0"
                },
                {
                    "zipcode": "12579",
                    "province": "Castellón",
                    "city": "Alcalà de Xivert",
                    "multiple": "0"
                },
                {
                    "zipcode": "12580",
                    "province": "Castellón",
                    "city": "Benicarló",
                    "multiple": "0"
                },
                {
                    "zipcode": "12589",
                    "province": "Castellón",
                    "city": "Càlig",
                    "multiple": "0"
                },
                {
                    "zipcode": "12590",
                    "province": "Castellón",
                    "city": "Almenara",
                    "multiple": "0"
                },
                {
                    "zipcode": "12591",
                    "province": "Castellón",
                    "city": "Llosa, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12592",
                    "province": "Castellón",
                    "city": "Chilches/Xilxes",
                    "multiple": "0"
                },
                {
                    "zipcode": "12593",
                    "province": "Castellón",
                    "city": "Moncofa",
                    "multiple": "0"
                },
                {
                    "zipcode": "12594",
                    "province": "Castellón",
                    "city": "Oropesa del Mar/Orpesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "12595",
                    "province": "Castellón",
                    "city": "Cabanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "12596",
                    "province": "Castellón",
                    "city": "Torreblanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "12597",
                    "province": "Castellón",
                    "city": "Santa Magdalena de Pulpis",
                    "multiple": "0"
                },
                {
                    "zipcode": "12598",
                    "province": "Castellón",
                    "city": "Peníscola/Peñíscola",
                    "multiple": "0"
                },
                {
                    "zipcode": "12599",
                    "province": "Castellón",
                    "city": "Rossell",
                    "multiple": "0"
                },
                {
                    "zipcode": "12599",
                    "province": "Castellón",
                    "city": "Pobla de Benifassà, la",
                    "multiple": "1"
                },
                {
                    "zipcode": "12600",
                    "province": "Castellón",
                    "city": "Vall d'Uixó, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "12609",
                    "province": "Castellón",
                    "city": "Alfondeguilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "12952",
                    "province": "Castellón",
                    "city": "Chilches/Xilxes",
                    "multiple": "0"
                },
                {
                    "zipcode": "13001",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13002",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13003",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13004",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13005",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13100",
                    "province": "Ciudad Real",
                    "city": "Piedrabuena",
                    "multiple": "0"
                },
                {
                    "zipcode": "13107",
                    "province": "Ciudad Real",
                    "city": "Alcolea de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13108",
                    "province": "Ciudad Real",
                    "city": "Luciana",
                    "multiple": "0"
                },
                {
                    "zipcode": "13109",
                    "province": "Ciudad Real",
                    "city": "Puebla de Don Rodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13110",
                    "province": "Ciudad Real",
                    "city": "Horcajo de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "13110",
                    "province": "Ciudad Real",
                    "city": "Helechosa de los Montes",
                    "multiple": "1"
                },
                {
                    "zipcode": "13114",
                    "province": "Ciudad Real",
                    "city": "Robledo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "13115",
                    "province": "Ciudad Real",
                    "city": "Alcoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "13116",
                    "province": "Ciudad Real",
                    "city": "Alcoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "13117",
                    "province": "Ciudad Real",
                    "city": "Anchuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "13118",
                    "province": "Ciudad Real",
                    "city": "Anchuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "13118",
                    "province": "Ciudad Real",
                    "city": "Porzuna",
                    "multiple": "1"
                },
                {
                    "zipcode": "13120",
                    "province": "Ciudad Real",
                    "city": "Porzuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "13129",
                    "province": "Ciudad Real",
                    "city": "Porzuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "13130",
                    "province": "Ciudad Real",
                    "city": "Fuencaliente",
                    "multiple": "0"
                },
                {
                    "zipcode": "13140",
                    "province": "Ciudad Real",
                    "city": "Fernán Caballero",
                    "multiple": "0"
                },
                {
                    "zipcode": "13150",
                    "province": "Ciudad Real",
                    "city": "Carrión de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13160",
                    "province": "Ciudad Real",
                    "city": "Torralba de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13170",
                    "province": "Ciudad Real",
                    "city": "Argamasilla de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "13170",
                    "province": "Ciudad Real",
                    "city": "Miguelturra",
                    "multiple": "1"
                },
                {
                    "zipcode": "13179",
                    "province": "Ciudad Real",
                    "city": "Pozuelo de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13179",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "13180",
                    "province": "Ciudad Real",
                    "city": "Abenójar",
                    "multiple": "0"
                },
                {
                    "zipcode": "13189",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13190",
                    "province": "Ciudad Real",
                    "city": "Corral de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13190",
                    "province": "Ciudad Real",
                    "city": "Miguelturra",
                    "multiple": "1"
                },
                {
                    "zipcode": "13191",
                    "province": "Ciudad Real",
                    "city": "Caracuel de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13191",
                    "province": "Ciudad Real",
                    "city": "Pozuelos de Calatrava, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "13192",
                    "province": "Ciudad Real",
                    "city": "Cabezarados",
                    "multiple": "0"
                },
                {
                    "zipcode": "13192",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "13193",
                    "province": "Ciudad Real",
                    "city": "Arroba de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "13193",
                    "province": "Ciudad Real",
                    "city": "Fontanarejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "13193",
                    "province": "Ciudad Real",
                    "city": "Navalpino",
                    "multiple": "1"
                },
                {
                    "zipcode": "13194",
                    "province": "Ciudad Real",
                    "city": "Malagón",
                    "multiple": "0"
                },
                {
                    "zipcode": "13194",
                    "province": "Ciudad Real",
                    "city": "Porzuna",
                    "multiple": "1"
                },
                {
                    "zipcode": "13194",
                    "province": "Ciudad Real",
                    "city": "Navas de Estena",
                    "multiple": "1"
                },
                {
                    "zipcode": "13194",
                    "province": "Ciudad Real",
                    "city": "Retuerta del Bullaque",
                    "multiple": "1"
                },
                {
                    "zipcode": "13195",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13195",
                    "province": "Ciudad Real",
                    "city": "Poblete",
                    "multiple": "1"
                },
                {
                    "zipcode": "13196",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13196",
                    "province": "Ciudad Real",
                    "city": "Piedrabuena",
                    "multiple": "1"
                },
                {
                    "zipcode": "13196",
                    "province": "Ciudad Real",
                    "city": "Picón",
                    "multiple": "1"
                },
                {
                    "zipcode": "13197",
                    "province": "Ciudad Real",
                    "city": "Ciudad Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "13200",
                    "province": "Ciudad Real",
                    "city": "Manzanares",
                    "multiple": "0"
                },
                {
                    "zipcode": "13210",
                    "province": "Ciudad Real",
                    "city": "Villarta de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "13220",
                    "province": "Ciudad Real",
                    "city": "Llanos del Caudillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13230",
                    "province": "Ciudad Real",
                    "city": "Membrilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "13240",
                    "province": "Ciudad Real",
                    "city": "Solana, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "13247",
                    "province": "Ciudad Real",
                    "city": "San Carlos del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "13248",
                    "province": "Ciudad Real",
                    "city": "Alhambra",
                    "multiple": "0"
                },
                {
                    "zipcode": "13249",
                    "province": "Ciudad Real",
                    "city": "Ruidera",
                    "multiple": "0"
                },
                {
                    "zipcode": "13250",
                    "province": "Ciudad Real",
                    "city": "Daimiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "13260",
                    "province": "Ciudad Real",
                    "city": "Bolaños de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13260",
                    "province": "Ciudad Real",
                    "city": "Almagro",
                    "multiple": "1"
                },
                {
                    "zipcode": "13270",
                    "province": "Ciudad Real",
                    "city": "Almagro",
                    "multiple": "0"
                },
                {
                    "zipcode": "13279",
                    "province": "Ciudad Real",
                    "city": "Valenzuela de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13300",
                    "province": "Ciudad Real",
                    "city": "Alcázar de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "13300",
                    "province": "Ciudad Real",
                    "city": "Valdepeñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "13310",
                    "province": "Ciudad Real",
                    "city": "Valdepeñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "13320",
                    "province": "Ciudad Real",
                    "city": "Villanueva de los Infantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "13326",
                    "province": "Ciudad Real",
                    "city": "Montiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "13327",
                    "province": "Ciudad Real",
                    "city": "Santa Cruz de los Cáñamos",
                    "multiple": "0"
                },
                {
                    "zipcode": "13328",
                    "province": "Ciudad Real",
                    "city": "Almedina",
                    "multiple": "0"
                },
                {
                    "zipcode": "13329",
                    "province": "Ciudad Real",
                    "city": "Carrizosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "13330",
                    "province": "Ciudad Real",
                    "city": "Villanueva de la Fuente",
                    "multiple": "0"
                },
                {
                    "zipcode": "13331",
                    "province": "Ciudad Real",
                    "city": "Villahermosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "13332",
                    "province": "Ciudad Real",
                    "city": "Villahermosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "13333",
                    "province": "Ciudad Real",
                    "city": "Fuenllana",
                    "multiple": "0"
                },
                {
                    "zipcode": "13340",
                    "province": "Ciudad Real",
                    "city": "Albaladejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13341",
                    "province": "Ciudad Real",
                    "city": "Terrinches",
                    "multiple": "0"
                },
                {
                    "zipcode": "13342",
                    "province": "Ciudad Real",
                    "city": "Puebla del Príncipe",
                    "multiple": "0"
                },
                {
                    "zipcode": "13343",
                    "province": "Ciudad Real",
                    "city": "Villamanrique",
                    "multiple": "0"
                },
                {
                    "zipcode": "13344",
                    "province": "Ciudad Real",
                    "city": "Torre de Juan Abad",
                    "multiple": "0"
                },
                {
                    "zipcode": "13345",
                    "province": "Ciudad Real",
                    "city": "Cózar",
                    "multiple": "0"
                },
                {
                    "zipcode": "13350",
                    "province": "Ciudad Real",
                    "city": "Moral de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13360",
                    "province": "Ciudad Real",
                    "city": "Granátula de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13370",
                    "province": "Ciudad Real",
                    "city": "Calzada de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13379",
                    "province": "Ciudad Real",
                    "city": "Villanueva de San Carlos",
                    "multiple": "0"
                },
                {
                    "zipcode": "13380",
                    "province": "Ciudad Real",
                    "city": "Aldea del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "13390",
                    "province": "Ciudad Real",
                    "city": "Alhambra",
                    "multiple": "0"
                },
                {
                    "zipcode": "13391",
                    "province": "Ciudad Real",
                    "city": "Alcubillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "13400",
                    "province": "Ciudad Real",
                    "city": "Almadén",
                    "multiple": "0"
                },
                {
                    "zipcode": "13410",
                    "province": "Ciudad Real",
                    "city": "Agudo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13411",
                    "province": "Ciudad Real",
                    "city": "Valdemanco del Esteras",
                    "multiple": "0"
                },
                {
                    "zipcode": "13412",
                    "province": "Ciudad Real",
                    "city": "Chillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "13413",
                    "province": "Ciudad Real",
                    "city": "Alamillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13414",
                    "province": "Ciudad Real",
                    "city": "Almadenejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "13414",
                    "province": "Ciudad Real",
                    "city": "Saceruela",
                    "multiple": "1"
                },
                {
                    "zipcode": "13415",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13420",
                    "province": "Ciudad Real",
                    "city": "Malagón",
                    "multiple": "0"
                },
                {
                    "zipcode": "13427",
                    "province": "Ciudad Real",
                    "city": "Cortijos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "13428",
                    "province": "Ciudad Real",
                    "city": "Fuente el Fresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "13428",
                    "province": "Ciudad Real",
                    "city": "Malagón",
                    "multiple": "1"
                },
                {
                    "zipcode": "13429",
                    "province": "Ciudad Real",
                    "city": "Malagón",
                    "multiple": "0"
                },
                {
                    "zipcode": "13430",
                    "province": "Ciudad Real",
                    "city": "Cañada de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13431",
                    "province": "Ciudad Real",
                    "city": "Villar del Pozo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13432",
                    "province": "Ciudad Real",
                    "city": "Ballesteros de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13440",
                    "province": "Ciudad Real",
                    "city": "Argamasilla de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13449",
                    "province": "Ciudad Real",
                    "city": "Brazatortas",
                    "multiple": "0"
                },
                {
                    "zipcode": "13450",
                    "province": "Ciudad Real",
                    "city": "Brazatortas",
                    "multiple": "0"
                },
                {
                    "zipcode": "13459",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13460",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13470",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13473",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13473",
                    "province": "Ciudad Real",
                    "city": "Abenójar",
                    "multiple": "1"
                },
                {
                    "zipcode": "13480",
                    "province": "Ciudad Real",
                    "city": "Almadenejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "13490",
                    "province": "Ciudad Real",
                    "city": "Guadalmez",
                    "multiple": "0"
                },
                {
                    "zipcode": "13499",
                    "province": "Ciudad Real",
                    "city": "Guadalmez",
                    "multiple": "0"
                },
                {
                    "zipcode": "13500",
                    "province": "Ciudad Real",
                    "city": "Puertollano",
                    "multiple": "0"
                },
                {
                    "zipcode": "13580",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13590",
                    "province": "Ciudad Real",
                    "city": "Hinojosas de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13591",
                    "province": "Ciudad Real",
                    "city": "Cabezarrubias del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "13592",
                    "province": "Ciudad Real",
                    "city": "Mestanza",
                    "multiple": "0"
                },
                {
                    "zipcode": "13593",
                    "province": "Ciudad Real",
                    "city": "Solana del Pino",
                    "multiple": "0"
                },
                {
                    "zipcode": "13594",
                    "province": "Ciudad Real",
                    "city": "Mestanza",
                    "multiple": "0"
                },
                {
                    "zipcode": "13595",
                    "province": "Ciudad Real",
                    "city": "Villamayor de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13596",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13597",
                    "province": "Ciudad Real",
                    "city": "Puertollano",
                    "multiple": "0"
                },
                {
                    "zipcode": "13598",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13600",
                    "province": "Ciudad Real",
                    "city": "Alcázar de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "13610",
                    "province": "Ciudad Real",
                    "city": "Campo de Criptana",
                    "multiple": "0"
                },
                {
                    "zipcode": "13619",
                    "province": "Ciudad Real",
                    "city": "Arenales de San Gregorio",
                    "multiple": "0"
                },
                {
                    "zipcode": "13620",
                    "province": "Ciudad Real",
                    "city": "Pedro Muñoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "13629",
                    "province": "Ciudad Real",
                    "city": "Tomelloso",
                    "multiple": "0"
                },
                {
                    "zipcode": "13630",
                    "province": "Ciudad Real",
                    "city": "Socuéllamos",
                    "multiple": "0"
                },
                {
                    "zipcode": "13640",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "13640",
                    "province": "Ciudad Real",
                    "city": "Herencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "13650",
                    "province": "Ciudad Real",
                    "city": "Puerto Lápice",
                    "multiple": "0"
                },
                {
                    "zipcode": "13660",
                    "province": "Ciudad Real",
                    "city": "Labores, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "13670",
                    "province": "Ciudad Real",
                    "city": "Villarrubia de los Ojos",
                    "multiple": "0"
                },
                {
                    "zipcode": "13679",
                    "province": "Ciudad Real",
                    "city": "Arenas de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "13680",
                    "province": "Ciudad Real",
                    "city": "Fuente el Fresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "13690",
                    "province": "Ciudad Real",
                    "city": "Alcázar de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "13700",
                    "province": "Ciudad Real",
                    "city": "Tomelloso",
                    "multiple": "0"
                },
                {
                    "zipcode": "13710",
                    "province": "Ciudad Real",
                    "city": "Argamasilla de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "13720",
                    "province": "Ciudad Real",
                    "city": "Alcázar de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "13730",
                    "province": "Ciudad Real",
                    "city": "Santa Cruz de Mudela",
                    "multiple": "0"
                },
                {
                    "zipcode": "13738",
                    "province": "Ciudad Real",
                    "city": "Viso del Marqués",
                    "multiple": "0"
                },
                {
                    "zipcode": "13739",
                    "province": "Ciudad Real",
                    "city": "Calzada de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13739",
                    "province": "Ciudad Real",
                    "city": "Viso del Marqués",
                    "multiple": "1"
                },
                {
                    "zipcode": "13740",
                    "province": "Ciudad Real",
                    "city": "Torrenueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "13750",
                    "province": "Ciudad Real",
                    "city": "Castellar de Santiago",
                    "multiple": "0"
                },
                {
                    "zipcode": "13760",
                    "province": "Ciudad Real",
                    "city": "Almuradiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "13768",
                    "province": "Ciudad Real",
                    "city": "Viso del Marqués",
                    "multiple": "0"
                },
                {
                    "zipcode": "13768",
                    "province": "Ciudad Real",
                    "city": "Almuradiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "13770",
                    "province": "Ciudad Real",
                    "city": "Viso del Marqués",
                    "multiple": "0"
                },
                {
                    "zipcode": "13779",
                    "province": "Ciudad Real",
                    "city": "San Lorenzo de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "13779",
                    "province": "Ciudad Real",
                    "city": "Calzada de Calatrava",
                    "multiple": "1"
                },
                {
                    "zipcode": "14001",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14002",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14003",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14004",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14005",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14006",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14007",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14008",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14009",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14010",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14011",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14012",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14013",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14014",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14015",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14016",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14029",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14045",
                    "province": "Córdoba",
                    "city": "Cardeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "14071",
                    "province": "Córdoba",
                    "city": "Villafranca de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14100",
                    "province": "Córdoba",
                    "city": "Carlota, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "14110",
                    "province": "Córdoba",
                    "city": "Fuente Palmera",
                    "multiple": "0"
                },
                {
                    "zipcode": "14111",
                    "province": "Córdoba",
                    "city": "Carlota, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "14112",
                    "province": "Córdoba",
                    "city": "Fuente Palmera",
                    "multiple": "0"
                },
                {
                    "zipcode": "14113",
                    "province": "Córdoba",
                    "city": "Fuente Palmera",
                    "multiple": "0"
                },
                {
                    "zipcode": "14115",
                    "province": "Córdoba",
                    "city": "Carpio, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "14115",
                    "province": "Córdoba",
                    "city": "Fuente Palmera",
                    "multiple": "1"
                },
                {
                    "zipcode": "14120",
                    "province": "Córdoba",
                    "city": "Fuente Palmera",
                    "multiple": "0"
                },
                {
                    "zipcode": "14129",
                    "province": "Córdoba",
                    "city": "Fuente Palmera",
                    "multiple": "0"
                },
                {
                    "zipcode": "14129",
                    "province": "Córdoba",
                    "city": "Hornachuelos",
                    "multiple": "1"
                },
                {
                    "zipcode": "14130",
                    "province": "Córdoba",
                    "city": "Guadalcázar",
                    "multiple": "0"
                },
                {
                    "zipcode": "14140",
                    "province": "Córdoba",
                    "city": "Victoria, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "14150",
                    "province": "Córdoba",
                    "city": "San Sebastián de los Ballesteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "14190",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14190",
                    "province": "Córdoba",
                    "city": "Benamejí",
                    "multiple": "1"
                },
                {
                    "zipcode": "14191",
                    "province": "Córdoba",
                    "city": "Carlota, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "14192",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14193",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14193",
                    "province": "Córdoba",
                    "city": "Carlota, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "14200",
                    "province": "Córdoba",
                    "city": "Peñarroya-Pueblonuevo",
                    "multiple": "0"
                },
                {
                    "zipcode": "14206",
                    "province": "Córdoba",
                    "city": "Valsequillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "14207",
                    "province": "Córdoba",
                    "city": "Granjuela, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "14208",
                    "province": "Córdoba",
                    "city": "Blázquez, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "14209",
                    "province": "Córdoba",
                    "city": "Belmez",
                    "multiple": "0"
                },
                {
                    "zipcode": "14209",
                    "province": "Córdoba",
                    "city": "Hornachuelos",
                    "multiple": "1"
                },
                {
                    "zipcode": "14209",
                    "province": "Córdoba",
                    "city": "Fuente Obejuna",
                    "multiple": "1"
                },
                {
                    "zipcode": "14210",
                    "province": "Córdoba",
                    "city": "Espiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "14210",
                    "province": "Córdoba",
                    "city": "Villaharta",
                    "multiple": "1"
                },
                {
                    "zipcode": "14220",
                    "province": "Córdoba",
                    "city": "Espiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "14230",
                    "province": "Córdoba",
                    "city": "Villanueva del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "14240",
                    "province": "Córdoba",
                    "city": "Belmez",
                    "multiple": "0"
                },
                {
                    "zipcode": "14248",
                    "province": "Córdoba",
                    "city": "Fuente Obejuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "14248",
                    "province": "Córdoba",
                    "city": "Belmez",
                    "multiple": "1"
                },
                {
                    "zipcode": "14249",
                    "province": "Córdoba",
                    "city": "Fuente Obejuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "14249",
                    "province": "Córdoba",
                    "city": "Belmez",
                    "multiple": "1"
                },
                {
                    "zipcode": "14250",
                    "province": "Córdoba",
                    "city": "Villanueva del Duque",
                    "multiple": "0"
                },
                {
                    "zipcode": "14260",
                    "province": "Córdoba",
                    "city": "Fuente la Lancha",
                    "multiple": "0"
                },
                {
                    "zipcode": "14270",
                    "province": "Córdoba",
                    "city": "Villanueva del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "14270",
                    "province": "Córdoba",
                    "city": "Hinojosa del Duque",
                    "multiple": "1"
                },
                {
                    "zipcode": "14280",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14280",
                    "province": "Córdoba",
                    "city": "Belalcázar",
                    "multiple": "1"
                },
                {
                    "zipcode": "14290",
                    "province": "Córdoba",
                    "city": "Fuente Obejuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "14297",
                    "province": "Córdoba",
                    "city": "Fuente Obejuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "14298",
                    "province": "Córdoba",
                    "city": "Fuente Obejuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "14299",
                    "province": "Córdoba",
                    "city": "Fuente Obejuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "14300",
                    "province": "Córdoba",
                    "city": "Villaviciosa de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14310",
                    "province": "Córdoba",
                    "city": "Obejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "14320",
                    "province": "Córdoba",
                    "city": "Espiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "14320",
                    "province": "Córdoba",
                    "city": "Villaviciosa de Córdoba",
                    "multiple": "1"
                },
                {
                    "zipcode": "14320",
                    "province": "Córdoba",
                    "city": "Obejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "14340",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14350",
                    "province": "Córdoba",
                    "city": "Obejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "14350",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "1"
                },
                {
                    "zipcode": "14400",
                    "province": "Córdoba",
                    "city": "Pozoblanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "14410",
                    "province": "Córdoba",
                    "city": "Torrecampo",
                    "multiple": "0"
                },
                {
                    "zipcode": "14412",
                    "province": "Córdoba",
                    "city": "Pedroche",
                    "multiple": "0"
                },
                {
                    "zipcode": "14413",
                    "province": "Córdoba",
                    "city": "Guijo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "14420",
                    "province": "Córdoba",
                    "city": "Villafranca de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14430",
                    "province": "Córdoba",
                    "city": "Adamuz",
                    "multiple": "0"
                },
                {
                    "zipcode": "14439",
                    "province": "Córdoba",
                    "city": "Adamuz",
                    "multiple": "0"
                },
                {
                    "zipcode": "14440",
                    "province": "Córdoba",
                    "city": "Cardeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "14440",
                    "province": "Córdoba",
                    "city": "Villanueva de Córdoba",
                    "multiple": "1"
                },
                {
                    "zipcode": "14445",
                    "province": "Córdoba",
                    "city": "Belmez",
                    "multiple": "0"
                },
                {
                    "zipcode": "14445",
                    "province": "Córdoba",
                    "city": "Cardeña",
                    "multiple": "1"
                },
                {
                    "zipcode": "14446",
                    "province": "Córdoba",
                    "city": "Cardeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "14447",
                    "province": "Córdoba",
                    "city": "Cardeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "14448",
                    "province": "Córdoba",
                    "city": "Conquista",
                    "multiple": "0"
                },
                {
                    "zipcode": "14449",
                    "province": "Ciudad Real",
                    "city": "Almodóvar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "14450",
                    "province": "Córdoba",
                    "city": "Montilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "14450",
                    "province": "Córdoba",
                    "city": "Añora",
                    "multiple": "1"
                },
                {
                    "zipcode": "14460",
                    "province": "Córdoba",
                    "city": "Dos Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "14470",
                    "province": "Córdoba",
                    "city": "Villaviciosa de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14470",
                    "province": "Córdoba",
                    "city": "Viso, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "14480",
                    "province": "Córdoba",
                    "city": "Alcaracejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "14490",
                    "province": "Córdoba",
                    "city": "Villaralto",
                    "multiple": "0"
                },
                {
                    "zipcode": "14491",
                    "province": "Córdoba",
                    "city": "Santa Eufemia",
                    "multiple": "0"
                },
                {
                    "zipcode": "14500",
                    "province": "Córdoba",
                    "city": "Puente Genil",
                    "multiple": "0"
                },
                {
                    "zipcode": "14510",
                    "province": "Córdoba",
                    "city": "Moriles",
                    "multiple": "0"
                },
                {
                    "zipcode": "14511",
                    "province": "Córdoba",
                    "city": "Lucena",
                    "multiple": "0"
                },
                {
                    "zipcode": "14512",
                    "province": "Córdoba",
                    "city": "Puente Genil",
                    "multiple": "0"
                },
                {
                    "zipcode": "14520",
                    "province": "Córdoba",
                    "city": "Fernán-Núñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "14520",
                    "province": "Córdoba",
                    "city": "Montemayor",
                    "multiple": "1"
                },
                {
                    "zipcode": "14530",
                    "province": "Córdoba",
                    "city": "Montemayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "14540",
                    "province": "Córdoba",
                    "city": "Rambla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "14546",
                    "province": "Córdoba",
                    "city": "Santaella",
                    "multiple": "0"
                },
                {
                    "zipcode": "14547",
                    "province": "Córdoba",
                    "city": "Santaella",
                    "multiple": "0"
                },
                {
                    "zipcode": "14548",
                    "province": "Córdoba",
                    "city": "Montalbán de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14549",
                    "province": "Córdoba",
                    "city": "Santaella",
                    "multiple": "0"
                },
                {
                    "zipcode": "14550",
                    "province": "Córdoba",
                    "city": "Montilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "14550",
                    "province": "Córdoba",
                    "city": "Añora",
                    "multiple": "1"
                },
                {
                    "zipcode": "14600",
                    "province": "Córdoba",
                    "city": "Montoro",
                    "multiple": "0"
                },
                {
                    "zipcode": "14610",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14610",
                    "province": "Córdoba",
                    "city": "Adamuz",
                    "multiple": "1"
                },
                {
                    "zipcode": "14620",
                    "province": "Córdoba",
                    "city": "Carpio, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "14630",
                    "province": "Córdoba",
                    "city": "Pedro Abad",
                    "multiple": "0"
                },
                {
                    "zipcode": "14640",
                    "province": "Córdoba",
                    "city": "Villa del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "14650",
                    "province": "Córdoba",
                    "city": "Bujalance",
                    "multiple": "0"
                },
                {
                    "zipcode": "14656",
                    "province": "Córdoba",
                    "city": "Bujalance",
                    "multiple": "0"
                },
                {
                    "zipcode": "14659",
                    "province": "Córdoba",
                    "city": "Bujalance",
                    "multiple": "0"
                },
                {
                    "zipcode": "14660",
                    "province": "Córdoba",
                    "city": "Cañete de las Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "14670",
                    "province": "Córdoba",
                    "city": "Valenzuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "14700",
                    "province": "Córdoba",
                    "city": "Palma del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "14709",
                    "province": "Córdoba",
                    "city": "Hornachuelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "14710",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14711",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14719",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14720",
                    "province": "Córdoba",
                    "city": "Almodóvar del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "14729",
                    "province": "Córdoba",
                    "city": "Almodóvar del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "14730",
                    "province": "Córdoba",
                    "city": "Posadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "14739",
                    "province": "Córdoba",
                    "city": "Posadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "14740",
                    "province": "Córdoba",
                    "city": "Hornachuelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "14800",
                    "province": "Córdoba",
                    "city": "Priego de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14810",
                    "province": "Córdoba",
                    "city": "Carcabuey",
                    "multiple": "0"
                },
                {
                    "zipcode": "14811",
                    "province": "Córdoba",
                    "city": "Priego de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14811",
                    "province": "Córdoba",
                    "city": "Cabra",
                    "multiple": "1"
                },
                {
                    "zipcode": "14811",
                    "province": "Córdoba",
                    "city": "Carcabuey",
                    "multiple": "1"
                },
                {
                    "zipcode": "14812",
                    "province": "Córdoba",
                    "city": "Almedinilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "14813",
                    "province": "Córdoba",
                    "city": "Almedinilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "14814",
                    "province": "Córdoba",
                    "city": "Priego de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14815",
                    "province": "Córdoba",
                    "city": "Fuente-Tójar",
                    "multiple": "0"
                },
                {
                    "zipcode": "14815",
                    "province": "Córdoba",
                    "city": "Priego de Córdoba",
                    "multiple": "1"
                },
                {
                    "zipcode": "14816",
                    "province": "Córdoba",
                    "city": "Carcabuey",
                    "multiple": "0"
                },
                {
                    "zipcode": "14816",
                    "province": "Córdoba",
                    "city": "Priego de Córdoba",
                    "multiple": "1"
                },
                {
                    "zipcode": "14817",
                    "province": "Córdoba",
                    "city": "Priego de Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14820",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14830",
                    "province": "Córdoba",
                    "city": "Espejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "14840",
                    "province": "Córdoba",
                    "city": "Castro del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "14850",
                    "province": "Córdoba",
                    "city": "Baena",
                    "multiple": "0"
                },
                {
                    "zipcode": "14857",
                    "province": "Córdoba",
                    "city": "Nueva Carteya",
                    "multiple": "0"
                },
                {
                    "zipcode": "14857",
                    "province": "Córdoba",
                    "city": "Castro del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "14858",
                    "province": "Córdoba",
                    "city": "Castro del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "14859",
                    "province": "Córdoba",
                    "city": "Baena",
                    "multiple": "0"
                },
                {
                    "zipcode": "14860",
                    "province": "Córdoba",
                    "city": "Doña Mencía",
                    "multiple": "0"
                },
                {
                    "zipcode": "14870",
                    "province": "Córdoba",
                    "city": "Zuheros",
                    "multiple": "0"
                },
                {
                    "zipcode": "14880",
                    "province": "Córdoba",
                    "city": "Luque",
                    "multiple": "0"
                },
                {
                    "zipcode": "14889",
                    "province": "Córdoba",
                    "city": "Luque",
                    "multiple": "0"
                },
                {
                    "zipcode": "14900",
                    "province": "Córdoba",
                    "city": "Lucena",
                    "multiple": "0"
                },
                {
                    "zipcode": "14910",
                    "province": "Córdoba",
                    "city": "Benamejí",
                    "multiple": "0"
                },
                {
                    "zipcode": "14911",
                    "province": "Córdoba",
                    "city": "Lucena",
                    "multiple": "0"
                },
                {
                    "zipcode": "14912",
                    "province": "Córdoba",
                    "city": "Córdoba",
                    "multiple": "0"
                },
                {
                    "zipcode": "14913",
                    "province": "Córdoba",
                    "city": "Encinas Reales",
                    "multiple": "0"
                },
                {
                    "zipcode": "14914",
                    "province": "Córdoba",
                    "city": "Palenciana",
                    "multiple": "0"
                },
                {
                    "zipcode": "14915",
                    "province": "Córdoba",
                    "city": "Benamejí",
                    "multiple": "0"
                },
                {
                    "zipcode": "14920",
                    "province": "Córdoba",
                    "city": "Aguilar de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "14930",
                    "province": "Córdoba",
                    "city": "Cabra",
                    "multiple": "0"
                },
                {
                    "zipcode": "14930",
                    "province": "Córdoba",
                    "city": "Monturque",
                    "multiple": "1"
                },
                {
                    "zipcode": "14940",
                    "province": "Córdoba",
                    "city": "Cabra",
                    "multiple": "0"
                },
                {
                    "zipcode": "14940",
                    "province": "Córdoba",
                    "city": "Villa del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "14941",
                    "province": "Córdoba",
                    "city": "Santa Eufemia",
                    "multiple": "0"
                },
                {
                    "zipcode": "14949",
                    "province": "Córdoba",
                    "city": "Cabra",
                    "multiple": "0"
                },
                {
                    "zipcode": "14950",
                    "province": "Córdoba",
                    "city": "Baena",
                    "multiple": "0"
                },
                {
                    "zipcode": "14950",
                    "province": "Córdoba",
                    "city": "Hornachuelos",
                    "multiple": "1"
                },
                {
                    "zipcode": "14950",
                    "province": "Córdoba",
                    "city": "Rute",
                    "multiple": "1"
                },
                {
                    "zipcode": "14960",
                    "province": "Córdoba",
                    "city": "Rute",
                    "multiple": "0"
                },
                {
                    "zipcode": "14970",
                    "province": "Córdoba",
                    "city": "Iznájar",
                    "multiple": "0"
                },
                {
                    "zipcode": "14978",
                    "province": "Córdoba",
                    "city": "Iznájar",
                    "multiple": "0"
                },
                {
                    "zipcode": "14979",
                    "province": "Córdoba",
                    "city": "Iznájar",
                    "multiple": "0"
                },
                {
                    "zipcode": "15001",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15002",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15003",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15004",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15005",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15006",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15007",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15008",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15008",
                    "province": "La Coruña",
                    "city": "Arteixo",
                    "multiple": "1"
                },
                {
                    "zipcode": "15009",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15010",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15011",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15100",
                    "province": "La Coruña",
                    "city": "Carballo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15102",
                    "province": "La Coruña",
                    "city": "Carballo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15105",
                    "province": "La Coruña",
                    "city": "Carballo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15106",
                    "province": "La Coruña",
                    "city": "Carballo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15107",
                    "province": "La Coruña",
                    "city": "Carballo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15108",
                    "province": "La Coruña",
                    "city": "Carballo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15109",
                    "province": "La Coruña",
                    "city": "Carballo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15110",
                    "province": "La Coruña",
                    "city": "Ponteceso",
                    "multiple": "0"
                },
                {
                    "zipcode": "15110",
                    "province": "La Coruña",
                    "city": "Cabana de Bergantiños",
                    "multiple": "1"
                },
                {
                    "zipcode": "15111",
                    "province": "La Coruña",
                    "city": "Malpica de Bergantiños",
                    "multiple": "0"
                },
                {
                    "zipcode": "15112",
                    "province": "La Coruña",
                    "city": "Malpica de Bergantiños",
                    "multiple": "0"
                },
                {
                    "zipcode": "15113",
                    "province": "La Coruña",
                    "city": "Malpica de Bergantiños",
                    "multiple": "0"
                },
                {
                    "zipcode": "15114",
                    "province": "La Coruña",
                    "city": "Ponteceso",
                    "multiple": "0"
                },
                {
                    "zipcode": "15115",
                    "province": "La Coruña",
                    "city": "Cabana de Bergantiños",
                    "multiple": "0"
                },
                {
                    "zipcode": "15116",
                    "province": "La Coruña",
                    "city": "Cabana de Bergantiños",
                    "multiple": "0"
                },
                {
                    "zipcode": "15117",
                    "province": "La Coruña",
                    "city": "Laxe",
                    "multiple": "0"
                },
                {
                    "zipcode": "15118",
                    "province": "La Coruña",
                    "city": "Laxe",
                    "multiple": "0"
                },
                {
                    "zipcode": "15119",
                    "province": "La Coruña",
                    "city": "Cabana de Bergantiños",
                    "multiple": "0"
                },
                {
                    "zipcode": "15121",
                    "province": "La Coruña",
                    "city": "Camariñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15121",
                    "province": "La Coruña",
                    "city": "Vimianzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "15122",
                    "province": "La Coruña",
                    "city": "Camariñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15123",
                    "province": "La Coruña",
                    "city": "Camariñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15124",
                    "province": "La Coruña",
                    "city": "Muxía",
                    "multiple": "0"
                },
                {
                    "zipcode": "15125",
                    "province": "La Coruña",
                    "city": "Muxía",
                    "multiple": "0"
                },
                {
                    "zipcode": "15126",
                    "province": "La Coruña",
                    "city": "Vimianzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15126",
                    "province": "La Coruña",
                    "city": "Muxía",
                    "multiple": "1"
                },
                {
                    "zipcode": "15127",
                    "province": "La Coruña",
                    "city": "Vimianzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15128",
                    "province": "La Coruña",
                    "city": "Vimianzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15129",
                    "province": "La Coruña",
                    "city": "Vimianzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15130",
                    "province": "La Coruña",
                    "city": "Corcubión",
                    "multiple": "0"
                },
                {
                    "zipcode": "15137",
                    "province": "La Coruña",
                    "city": "Corcubión",
                    "multiple": "0"
                },
                {
                    "zipcode": "15138",
                    "province": "La Coruña",
                    "city": "Cee",
                    "multiple": "0"
                },
                {
                    "zipcode": "15139",
                    "province": "La Coruña",
                    "city": "Culleredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15140",
                    "province": "La Coruña",
                    "city": "Arteixo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15141",
                    "province": "La Coruña",
                    "city": "Arteixo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15142",
                    "province": "La Coruña",
                    "city": "Arteixo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15144",
                    "province": "La Coruña",
                    "city": "Arteixo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15145",
                    "province": "La Coruña",
                    "city": "Laracha, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15146",
                    "province": "La Coruña",
                    "city": "Laracha, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15147",
                    "province": "La Coruña",
                    "city": "Coristanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "15148",
                    "province": "La Coruña",
                    "city": "Coristanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "15149",
                    "province": "La Coruña",
                    "city": "Cabana de Bergantiños",
                    "multiple": "0"
                },
                {
                    "zipcode": "15150",
                    "province": "La Coruña",
                    "city": "Vimianzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15150",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "1"
                },
                {
                    "zipcode": "15151",
                    "province": "La Coruña",
                    "city": "Dumbría",
                    "multiple": "0"
                },
                {
                    "zipcode": "15152",
                    "province": "La Coruña",
                    "city": "Dumbría",
                    "multiple": "0"
                },
                {
                    "zipcode": "15153",
                    "province": "La Coruña",
                    "city": "Fisterra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15154",
                    "province": "La Coruña",
                    "city": "Fisterra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15155",
                    "province": "La Coruña",
                    "city": "Fisterra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15160",
                    "province": "La Coruña",
                    "city": "Sada",
                    "multiple": "0"
                },
                {
                    "zipcode": "15165",
                    "province": "La Coruña",
                    "city": "Bergondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15166",
                    "province": "La Coruña",
                    "city": "Bergondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15167",
                    "province": "La Coruña",
                    "city": "Bergondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15168",
                    "province": "La Coruña",
                    "city": "Sada",
                    "multiple": "0"
                },
                {
                    "zipcode": "15169",
                    "province": "La Coruña",
                    "city": "Sada",
                    "multiple": "0"
                },
                {
                    "zipcode": "15170",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15171",
                    "province": "La Coruña",
                    "city": "Oleiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15172",
                    "province": "La Coruña",
                    "city": "Oleiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15173",
                    "province": "La Coruña",
                    "city": "Oleiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15174",
                    "province": "La Coruña",
                    "city": "Culleredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15175",
                    "province": "La Coruña",
                    "city": "Carral",
                    "multiple": "0"
                },
                {
                    "zipcode": "15176",
                    "province": "La Coruña",
                    "city": "Oleiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15177",
                    "province": "La Coruña",
                    "city": "Oleiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15178",
                    "province": "La Coruña",
                    "city": "Oleiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15179",
                    "province": "La Coruña",
                    "city": "Oleiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15180",
                    "province": "La Coruña",
                    "city": "Culleredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15181",
                    "province": "La Coruña",
                    "city": "Cambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "15182",
                    "province": "La Coruña",
                    "city": "Carral",
                    "multiple": "0"
                },
                {
                    "zipcode": "15183",
                    "province": "La Coruña",
                    "city": "Carral",
                    "multiple": "0"
                },
                {
                    "zipcode": "15184",
                    "province": "La Coruña",
                    "city": "Carral",
                    "multiple": "0"
                },
                {
                    "zipcode": "15185",
                    "province": "La Coruña",
                    "city": "Cerceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "15186",
                    "province": "La Coruña",
                    "city": "Cerceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "15187",
                    "province": "La Coruña",
                    "city": "Cerceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "15188",
                    "province": "La Coruña",
                    "city": "Cerceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "15189",
                    "province": "La Coruña",
                    "city": "Culleredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15190",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15191",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15192",
                    "province": "La Coruña",
                    "city": "Coruña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15198",
                    "province": "La Coruña",
                    "city": "Culleredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15199",
                    "province": "La Coruña",
                    "city": "Culleredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15200",
                    "province": "La Coruña",
                    "city": "Noia",
                    "multiple": "0"
                },
                {
                    "zipcode": "15210",
                    "province": "La Coruña",
                    "city": "Noia",
                    "multiple": "0"
                },
                {
                    "zipcode": "15211",
                    "province": "La Coruña",
                    "city": "Noia",
                    "multiple": "0"
                },
                {
                    "zipcode": "15212",
                    "province": "La Coruña",
                    "city": "Lousame",
                    "multiple": "0"
                },
                {
                    "zipcode": "15213",
                    "province": "La Coruña",
                    "city": "Noia",
                    "multiple": "0"
                },
                {
                    "zipcode": "15214",
                    "province": "La Coruña",
                    "city": "Lousame",
                    "multiple": "0"
                },
                {
                    "zipcode": "15215",
                    "province": "La Coruña",
                    "city": "Lousame",
                    "multiple": "0"
                },
                {
                    "zipcode": "15216",
                    "province": "La Coruña",
                    "city": "Lousame",
                    "multiple": "0"
                },
                {
                    "zipcode": "15218",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15218",
                    "province": "La Coruña",
                    "city": "Noia",
                    "multiple": "1"
                },
                {
                    "zipcode": "15218",
                    "province": "La Coruña",
                    "city": "Lousame",
                    "multiple": "1"
                },
                {
                    "zipcode": "15220",
                    "province": "La Coruña",
                    "city": "Ames",
                    "multiple": "0"
                },
                {
                    "zipcode": "15228",
                    "province": "La Coruña",
                    "city": "Ames",
                    "multiple": "0"
                },
                {
                    "zipcode": "15229",
                    "province": "La Coruña",
                    "city": "Ames",
                    "multiple": "0"
                },
                {
                    "zipcode": "15230",
                    "province": "La Coruña",
                    "city": "Outes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15236",
                    "province": "La Coruña",
                    "city": "Outes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15237",
                    "province": "La Coruña",
                    "city": "Outes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15239",
                    "province": "La Coruña",
                    "city": "Outes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15240",
                    "province": "La Coruña",
                    "city": "Muros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15250",
                    "province": "La Coruña",
                    "city": "Muros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15256",
                    "province": "La Coruña",
                    "city": "Mazaricos",
                    "multiple": "0"
                },
                {
                    "zipcode": "15258",
                    "province": "La Coruña",
                    "city": "Mazaricos",
                    "multiple": "0"
                },
                {
                    "zipcode": "15259",
                    "province": "La Coruña",
                    "city": "Muros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15270",
                    "province": "La Coruña",
                    "city": "Cee",
                    "multiple": "0"
                },
                {
                    "zipcode": "15280",
                    "province": "La Coruña",
                    "city": "Brión",
                    "multiple": "0"
                },
                {
                    "zipcode": "15281",
                    "province": "La Coruña",
                    "city": "Rois",
                    "multiple": "0"
                },
                {
                    "zipcode": "15282",
                    "province": "La Coruña",
                    "city": "Rois",
                    "multiple": "0"
                },
                {
                    "zipcode": "15284",
                    "province": "La Coruña",
                    "city": "Muros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15286",
                    "province": "La Coruña",
                    "city": "Outes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15287",
                    "province": "La Coruña",
                    "city": "Outes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15288",
                    "province": "La Coruña",
                    "city": "Outes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15290",
                    "province": "La Coruña",
                    "city": "Muros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15291",
                    "province": "La Coruña",
                    "city": "Muros",
                    "multiple": "0"
                },
                {
                    "zipcode": "15292",
                    "province": "La Coruña",
                    "city": "Carnota",
                    "multiple": "0"
                },
                {
                    "zipcode": "15293",
                    "province": "La Coruña",
                    "city": "Carnota",
                    "multiple": "0"
                },
                {
                    "zipcode": "15295",
                    "province": "La Coruña",
                    "city": "Carnota",
                    "multiple": "0"
                },
                {
                    "zipcode": "15296",
                    "province": "La Coruña",
                    "city": "Carnota",
                    "multiple": "0"
                },
                {
                    "zipcode": "15297",
                    "province": "La Coruña",
                    "city": "Dumbría",
                    "multiple": "0"
                },
                {
                    "zipcode": "15298",
                    "province": "La Coruña",
                    "city": "Cee",
                    "multiple": "0"
                },
                {
                    "zipcode": "15299",
                    "province": "La Coruña",
                    "city": "Cee",
                    "multiple": "0"
                },
                {
                    "zipcode": "15300",
                    "province": "La Coruña",
                    "city": "Betanzos",
                    "multiple": "0"
                },
                {
                    "zipcode": "15310",
                    "province": "La Coruña",
                    "city": "Curtis",
                    "multiple": "0"
                },
                {
                    "zipcode": "15313",
                    "province": "La Coruña",
                    "city": "Irixoa",
                    "multiple": "0"
                },
                {
                    "zipcode": "15314",
                    "province": "La Coruña",
                    "city": "Paderne",
                    "multiple": "0"
                },
                {
                    "zipcode": "15315",
                    "province": "La Coruña",
                    "city": "Monfero",
                    "multiple": "0"
                },
                {
                    "zipcode": "15316",
                    "province": "La Coruña",
                    "city": "Coirós",
                    "multiple": "0"
                },
                {
                    "zipcode": "15317",
                    "province": "La Coruña",
                    "city": "Aranga",
                    "multiple": "0"
                },
                {
                    "zipcode": "15318",
                    "province": "La Coruña",
                    "city": "Abegondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15319",
                    "province": "La Coruña",
                    "city": "Betanzos",
                    "multiple": "0"
                },
                {
                    "zipcode": "15319",
                    "province": "La Coruña",
                    "city": "Paderne",
                    "multiple": "1"
                },
                {
                    "zipcode": "15319",
                    "province": "La Coruña",
                    "city": "Bergondo",
                    "multiple": "1"
                },
                {
                    "zipcode": "15320",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15324",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15325",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15326",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15327",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15328",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15329",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15330",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15332",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15337",
                    "province": "La Coruña",
                    "city": "Mañón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15338",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15339",
                    "province": "La Coruña",
                    "city": "Mañón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15339",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "1"
                },
                {
                    "zipcode": "15340",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15347",
                    "province": "La Coruña",
                    "city": "Cedeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15349",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15350",
                    "province": "La Coruña",
                    "city": "Cedeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15357",
                    "province": "La Coruña",
                    "city": "Cedeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15358",
                    "province": "La Coruña",
                    "city": "Cedeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15359",
                    "province": "La Coruña",
                    "city": "Cedeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15360",
                    "province": "La Coruña",
                    "city": "Cariño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15365",
                    "province": "La Coruña",
                    "city": "Cariño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15366",
                    "province": "La Coruña",
                    "city": "Cariño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15367",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15368",
                    "province": "La Coruña",
                    "city": "Ortigueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15369",
                    "province": "La Coruña",
                    "city": "Cariño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15379",
                    "province": "La Coruña",
                    "city": "Curtis",
                    "multiple": "0"
                },
                {
                    "zipcode": "15380",
                    "province": "La Coruña",
                    "city": "Oza-Cesuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "15386",
                    "province": "La Coruña",
                    "city": "Oza-Cesuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "15387",
                    "province": "La Coruña",
                    "city": "Oza-Cesuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "15388",
                    "province": "La Coruña",
                    "city": "Oza-Cesuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "15389",
                    "province": "La Coruña",
                    "city": "Oza-Cesuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "15390",
                    "province": "La Coruña",
                    "city": "Oza-Cesuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "15391",
                    "province": "La Coruña",
                    "city": "Oza-Cesuras",
                    "multiple": "0"
                },
                {
                    "zipcode": "15401",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15402",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15403",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15404",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15405",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15406",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15407",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15500",
                    "province": "La Coruña",
                    "city": "Fene",
                    "multiple": "0"
                },
                {
                    "zipcode": "15509",
                    "province": "La Coruña",
                    "city": "Fene",
                    "multiple": "0"
                },
                {
                    "zipcode": "15510",
                    "province": "La Coruña",
                    "city": "Neda",
                    "multiple": "0"
                },
                {
                    "zipcode": "15520",
                    "province": "La Coruña",
                    "city": "Fene",
                    "multiple": "0"
                },
                {
                    "zipcode": "15528",
                    "province": "La Coruña",
                    "city": "Fene",
                    "multiple": "0"
                },
                {
                    "zipcode": "15530",
                    "province": "La Coruña",
                    "city": "Cerdido",
                    "multiple": "0"
                },
                {
                    "zipcode": "15540",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15541",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15542",
                    "province": "La Coruña",
                    "city": "Valdoviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15543",
                    "province": "La Coruña",
                    "city": "Valdoviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15550",
                    "province": "La Coruña",
                    "city": "Valdoviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15551",
                    "province": "La Coruña",
                    "city": "Valdoviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15552",
                    "province": "La Coruña",
                    "city": "Valdoviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15553",
                    "province": "La Coruña",
                    "city": "Valdoviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15554",
                    "province": "La Coruña",
                    "city": "Valdoviño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15555",
                    "province": "La Coruña",
                    "city": "Cedeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15560",
                    "province": "La Coruña",
                    "city": "San Sadurniño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15561",
                    "province": "La Coruña",
                    "city": "San Sadurniño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15562",
                    "province": "La Coruña",
                    "city": "San Sadurniño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15563",
                    "province": "La Coruña",
                    "city": "Moeche",
                    "multiple": "0"
                },
                {
                    "zipcode": "15564",
                    "province": "La Coruña",
                    "city": "Moeche",
                    "multiple": "0"
                },
                {
                    "zipcode": "15565",
                    "province": "La Coruña",
                    "city": "Somozas, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15566",
                    "province": "La Coruña",
                    "city": "Somozas, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15567",
                    "province": "La Coruña",
                    "city": "Somozas, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15568",
                    "province": "La Coruña",
                    "city": "Somozas, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "15569",
                    "province": "La Coruña",
                    "city": "Cerdido",
                    "multiple": "0"
                },
                {
                    "zipcode": "15570",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15572",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15573",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15576",
                    "province": "La Coruña",
                    "city": "San Sadurniño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15577",
                    "province": "La Coruña",
                    "city": "Neda",
                    "multiple": "0"
                },
                {
                    "zipcode": "15577",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "1"
                },
                {
                    "zipcode": "15578",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15579",
                    "province": "La Coruña",
                    "city": "Neda",
                    "multiple": "0"
                },
                {
                    "zipcode": "15580",
                    "province": "La Coruña",
                    "city": "San Sadurniño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15582",
                    "province": "La Coruña",
                    "city": "Boqueixón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15582",
                    "province": "La Coruña",
                    "city": "Pontes de García Rodríguez, As",
                    "multiple": "1"
                },
                {
                    "zipcode": "15587",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15590",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15591",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15592",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15593",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15594",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15595",
                    "province": "La Coruña",
                    "city": "Ferrol",
                    "multiple": "0"
                },
                {
                    "zipcode": "15596",
                    "province": "La Coruña",
                    "city": "Narón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15598",
                    "province": "La Coruña",
                    "city": "San Sadurniño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15600",
                    "province": "La Coruña",
                    "city": "Pontedeume",
                    "multiple": "0"
                },
                {
                    "zipcode": "15607",
                    "province": "La Coruña",
                    "city": "Pontedeume",
                    "multiple": "0"
                },
                {
                    "zipcode": "15608",
                    "province": "La Coruña",
                    "city": "Pontedeume",
                    "multiple": "0"
                },
                {
                    "zipcode": "15608",
                    "province": "La Coruña",
                    "city": "Miño",
                    "multiple": "1"
                },
                {
                    "zipcode": "15609",
                    "province": "La Coruña",
                    "city": "Monfero",
                    "multiple": "0"
                },
                {
                    "zipcode": "15609",
                    "province": "La Coruña",
                    "city": "Pontedeume",
                    "multiple": "1"
                },
                {
                    "zipcode": "15611",
                    "province": "La Coruña",
                    "city": "Cabanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15612",
                    "province": "La Coruña",
                    "city": "Cabanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15613",
                    "province": "La Coruña",
                    "city": "Capela, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15614",
                    "province": "La Coruña",
                    "city": "Pontedeume",
                    "multiple": "0"
                },
                {
                    "zipcode": "15615",
                    "province": "La Coruña",
                    "city": "Vilarmaior",
                    "multiple": "0"
                },
                {
                    "zipcode": "15616",
                    "province": "La Coruña",
                    "city": "Vilarmaior",
                    "multiple": "0"
                },
                {
                    "zipcode": "15617",
                    "province": "La Coruña",
                    "city": "Monfero",
                    "multiple": "0"
                },
                {
                    "zipcode": "15619",
                    "province": "La Coruña",
                    "city": "Monfero",
                    "multiple": "0"
                },
                {
                    "zipcode": "15620",
                    "province": "La Coruña",
                    "city": "Mugardos",
                    "multiple": "0"
                },
                {
                    "zipcode": "15621",
                    "province": "La Coruña",
                    "city": "Cabanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15622",
                    "province": "La Coruña",
                    "city": "Cabanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15623",
                    "province": "La Coruña",
                    "city": "Ares",
                    "multiple": "0"
                },
                {
                    "zipcode": "15624",
                    "province": "La Coruña",
                    "city": "Ares",
                    "multiple": "0"
                },
                {
                    "zipcode": "15625",
                    "province": "La Coruña",
                    "city": "Ares",
                    "multiple": "0"
                },
                {
                    "zipcode": "15626",
                    "province": "La Coruña",
                    "city": "Mugardos",
                    "multiple": "0"
                },
                {
                    "zipcode": "15627",
                    "province": "La Coruña",
                    "city": "Fene",
                    "multiple": "0"
                },
                {
                    "zipcode": "15627",
                    "province": "La Coruña",
                    "city": "Mugardos",
                    "multiple": "1"
                },
                {
                    "zipcode": "15630",
                    "province": "La Coruña",
                    "city": "Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15635",
                    "province": "La Coruña",
                    "city": "Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15637",
                    "province": "La Coruña",
                    "city": "Vilarmaior",
                    "multiple": "0"
                },
                {
                    "zipcode": "15638",
                    "province": "La Coruña",
                    "city": "Vilarmaior",
                    "multiple": "0"
                },
                {
                    "zipcode": "15639",
                    "province": "La Coruña",
                    "city": "Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "15640",
                    "province": "La Coruña",
                    "city": "Bergondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15650",
                    "province": "La Coruña",
                    "city": "Cambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "15656",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15659",
                    "province": "La Coruña",
                    "city": "Cambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "15660",
                    "province": "La Coruña",
                    "city": "Cambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "15668",
                    "province": "La Coruña",
                    "city": "Cambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "15669",
                    "province": "La Coruña",
                    "city": "Cambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "15670",
                    "province": "La Coruña",
                    "city": "Culleredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15679",
                    "province": "La Coruña",
                    "city": "Cambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "15680",
                    "province": "La Coruña",
                    "city": "Ordes",
                    "multiple": "0"
                },
                {
                    "zipcode": "15683",
                    "province": "La Coruña",
                    "city": "Tordoia",
                    "multiple": "0"
                },
                {
                    "zipcode": "15684",
                    "province": "La Coruña",
                    "city": "Oroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "15684",
                    "province": "La Coruña",
                    "city": "Ordes",
                    "multiple": "1"
                },
                {
                    "zipcode": "15684",
                    "province": "La Coruña",
                    "city": "Tordoia",
                    "multiple": "1"
                },
                {
                    "zipcode": "15685",
                    "province": "La Coruña",
                    "city": "Mesía",
                    "multiple": "0"
                },
                {
                    "zipcode": "15685",
                    "province": "La Coruña",
                    "city": "Ordes",
                    "multiple": "1"
                },
                {
                    "zipcode": "15685",
                    "province": "La Coruña",
                    "city": "Frades",
                    "multiple": "1"
                },
                {
                    "zipcode": "15686",
                    "province": "La Coruña",
                    "city": "Trazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15686",
                    "province": "La Coruña",
                    "city": "Mesía",
                    "multiple": "1"
                },
                {
                    "zipcode": "15686",
                    "province": "La Coruña",
                    "city": "Frades",
                    "multiple": "1"
                },
                {
                    "zipcode": "15687",
                    "province": "La Coruña",
                    "city": "Trazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15687",
                    "province": "La Coruña",
                    "city": "Ordes",
                    "multiple": "1"
                },
                {
                    "zipcode": "15688",
                    "province": "La Coruña",
                    "city": "Oroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "15688",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "1"
                },
                {
                    "zipcode": "15689",
                    "province": "La Coruña",
                    "city": "Tordoia",
                    "multiple": "0"
                },
                {
                    "zipcode": "15689",
                    "province": "La Coruña",
                    "city": "Ordes",
                    "multiple": "1"
                },
                {
                    "zipcode": "15689",
                    "province": "La Coruña",
                    "city": "Mesía",
                    "multiple": "1"
                },
                {
                    "zipcode": "15690",
                    "province": "La Coruña",
                    "city": "Arteixo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15701",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15702",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15703",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15704",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15705",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15706",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15707",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15800",
                    "province": "La Coruña",
                    "city": "Melide",
                    "multiple": "0"
                },
                {
                    "zipcode": "15805",
                    "province": "La Coruña",
                    "city": "Melide",
                    "multiple": "0"
                },
                {
                    "zipcode": "15806",
                    "province": "La Coruña",
                    "city": "Toques",
                    "multiple": "0"
                },
                {
                    "zipcode": "15807",
                    "province": "La Coruña",
                    "city": "Vilasantar",
                    "multiple": "0"
                },
                {
                    "zipcode": "15808",
                    "province": "La Coruña",
                    "city": "Santiso",
                    "multiple": "0"
                },
                {
                    "zipcode": "15809",
                    "province": "La Coruña",
                    "city": "Melide",
                    "multiple": "0"
                },
                {
                    "zipcode": "15810",
                    "province": "La Coruña",
                    "city": "Arzúa",
                    "multiple": "0"
                },
                {
                    "zipcode": "15813",
                    "province": "La Coruña",
                    "city": "Sobrado",
                    "multiple": "0"
                },
                {
                    "zipcode": "15814",
                    "province": "La Coruña",
                    "city": "Sobrado",
                    "multiple": "0"
                },
                {
                    "zipcode": "15815",
                    "province": "La Coruña",
                    "city": "Sobrado",
                    "multiple": "0"
                },
                {
                    "zipcode": "15816",
                    "province": "La Coruña",
                    "city": "Boimorto",
                    "multiple": "0"
                },
                {
                    "zipcode": "15817",
                    "province": "La Coruña",
                    "city": "Boimorto",
                    "multiple": "0"
                },
                {
                    "zipcode": "15818",
                    "province": "La Coruña",
                    "city": "Boimorto",
                    "multiple": "0"
                },
                {
                    "zipcode": "15819",
                    "province": "La Coruña",
                    "city": "Melide",
                    "multiple": "0"
                },
                {
                    "zipcode": "15819",
                    "province": "La Coruña",
                    "city": "Arzúa",
                    "multiple": "1"
                },
                {
                    "zipcode": "15820",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15821",
                    "province": "La Coruña",
                    "city": "Pino, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "15822",
                    "province": "La Coruña",
                    "city": "Touro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15823",
                    "province": "La Coruña",
                    "city": "Touro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15823",
                    "province": "La Coruña",
                    "city": "Pino, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "15824",
                    "province": "La Coruña",
                    "city": "Touro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15824",
                    "province": "La Coruña",
                    "city": "Pino, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "15825",
                    "province": "La Coruña",
                    "city": "Pino, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "15826",
                    "province": "La Coruña",
                    "city": "Arzúa",
                    "multiple": "0"
                },
                {
                    "zipcode": "15828",
                    "province": "La Coruña",
                    "city": "Touro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15830",
                    "province": "La Coruña",
                    "city": "Negreira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15837",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15838",
                    "province": "La Coruña",
                    "city": "Negreira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15839",
                    "province": "La Coruña",
                    "city": "Brión",
                    "multiple": "0"
                },
                {
                    "zipcode": "15839",
                    "province": "La Coruña",
                    "city": "Baña, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "15839",
                    "province": "La Coruña",
                    "city": "Negreira",
                    "multiple": "1"
                },
                {
                    "zipcode": "15840",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15841",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15845",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15846",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15847",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15848",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15850",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15851",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15855",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15856",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15857",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15858",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15859",
                    "province": "La Coruña",
                    "city": "Zas",
                    "multiple": "0"
                },
                {
                    "zipcode": "15860",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15861",
                    "province": "La Coruña",
                    "city": "Santa Comba",
                    "multiple": "0"
                },
                {
                    "zipcode": "15862",
                    "province": "La Coruña",
                    "city": "Baña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15863",
                    "province": "La Coruña",
                    "city": "Negreira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15863",
                    "province": "La Coruña",
                    "city": "Baña, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "15864",
                    "province": "La Coruña",
                    "city": "Ames",
                    "multiple": "0"
                },
                {
                    "zipcode": "15865",
                    "province": "La Coruña",
                    "city": "Brión",
                    "multiple": "0"
                },
                {
                    "zipcode": "15866",
                    "province": "La Coruña",
                    "city": "Teo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15870",
                    "province": "La Coruña",
                    "city": "Ames",
                    "multiple": "0"
                },
                {
                    "zipcode": "15871",
                    "province": "La Coruña",
                    "city": "Val do Dubra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15872",
                    "province": "La Coruña",
                    "city": "Val do Dubra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15873",
                    "province": "La Coruña",
                    "city": "Val do Dubra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15874",
                    "province": "La Coruña",
                    "city": "Val do Dubra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15880",
                    "province": "La Coruña",
                    "city": "Vedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15881",
                    "province": "La Coruña",
                    "city": "Vedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15881",
                    "province": "La Coruña",
                    "city": "Boqueixón",
                    "multiple": "1"
                },
                {
                    "zipcode": "15882",
                    "province": "La Coruña",
                    "city": "Boqueixón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15883",
                    "province": "La Coruña",
                    "city": "Teo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15884",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15884",
                    "province": "La Coruña",
                    "city": "Oroso",
                    "multiple": "1"
                },
                {
                    "zipcode": "15885",
                    "province": "La Coruña",
                    "city": "Vedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "15886",
                    "province": "La Coruña",
                    "city": "Teo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15887",
                    "province": "La Coruña",
                    "city": "Pino, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "15888",
                    "province": "La Coruña",
                    "city": "Oroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "15890",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15892",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15893",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15894",
                    "province": "La Coruña",
                    "city": "Teo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15895",
                    "province": "La Coruña",
                    "city": "Ames",
                    "multiple": "0"
                },
                {
                    "zipcode": "15896",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15897",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15898",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15899",
                    "province": "La Coruña",
                    "city": "Santiago de Compostela",
                    "multiple": "0"
                },
                {
                    "zipcode": "15900",
                    "province": "La Coruña",
                    "city": "Padrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15910",
                    "province": "La Coruña",
                    "city": "Padrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15911",
                    "province": "La Coruña",
                    "city": "Rois",
                    "multiple": "0"
                },
                {
                    "zipcode": "15912",
                    "province": "La Coruña",
                    "city": "Rois",
                    "multiple": "0"
                },
                {
                    "zipcode": "15913",
                    "province": "La Coruña",
                    "city": "Rois",
                    "multiple": "0"
                },
                {
                    "zipcode": "15914",
                    "province": "La Coruña",
                    "city": "Padrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15915",
                    "province": "La Coruña",
                    "city": "Padrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15916",
                    "province": "La Coruña",
                    "city": "Dodro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15917",
                    "province": "La Coruña",
                    "city": "Padrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "15920",
                    "province": "La Coruña",
                    "city": "Rianxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15928",
                    "province": "La Coruña",
                    "city": "Rianxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15930",
                    "province": "La Coruña",
                    "city": "Boiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15937",
                    "province": "La Coruña",
                    "city": "Boiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15938",
                    "province": "La Coruña",
                    "city": "Boiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15939",
                    "province": "La Coruña",
                    "city": "Boiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15940",
                    "province": "La Coruña",
                    "city": "Pobra do Caramiñal, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15948",
                    "province": "La Coruña",
                    "city": "Pobra do Caramiñal, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15949",
                    "province": "La Coruña",
                    "city": "Pobra do Caramiñal, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "15950",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15959",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15960",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15965",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15966",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15967",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15968",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15969",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15970",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15978",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15979",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15980",
                    "province": "La Coruña",
                    "city": "Rois",
                    "multiple": "0"
                },
                {
                    "zipcode": "15980",
                    "province": "La Coruña",
                    "city": "Padrón",
                    "multiple": "1"
                },
                {
                    "zipcode": "15981",
                    "province": "La Coruña",
                    "city": "Dodro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15982",
                    "province": "La Coruña",
                    "city": "Dodro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15983",
                    "province": "La Coruña",
                    "city": "Rianxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15984",
                    "province": "La Coruña",
                    "city": "Rianxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15985",
                    "province": "La Coruña",
                    "city": "Rianxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "15990",
                    "province": "La Coruña",
                    "city": "Boiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15991",
                    "province": "La Coruña",
                    "city": "Boiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15992",
                    "province": "La Coruña",
                    "city": "Boiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "15993",
                    "province": "La Coruña",
                    "city": "Ribeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "15994",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15995",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15996",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15998",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "15999",
                    "province": "La Coruña",
                    "city": "Porto do Son",
                    "multiple": "0"
                },
                {
                    "zipcode": "16001",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "16002",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "16003",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "16004",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "16043",
                    "province": "Cuenca",
                    "city": "Campillos-Paravientos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16100",
                    "province": "Cuenca",
                    "city": "Valverde de Júcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "16111",
                    "province": "Cuenca",
                    "city": "Villaverde y Pasaconsol",
                    "multiple": "0"
                },
                {
                    "zipcode": "16111",
                    "province": "Cuenca",
                    "city": "Albaladejo del Cuende",
                    "multiple": "1"
                },
                {
                    "zipcode": "16113",
                    "province": "Cuenca",
                    "city": "Parra de las Vegas, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "16114",
                    "province": "Cuenca",
                    "city": "Buenache de Alarcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "16118",
                    "province": "Cuenca",
                    "city": "Olmedilla de Alarcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "16118",
                    "province": "Cuenca",
                    "city": "Piqueras del Castillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16118",
                    "province": "Cuenca",
                    "city": "Hontecillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "16118",
                    "province": "Cuenca",
                    "city": "Barchín del Hoyo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16120",
                    "province": "Cuenca",
                    "city": "Valeras, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "16122",
                    "province": "Cuenca",
                    "city": "Valdetórtola",
                    "multiple": "0"
                },
                {
                    "zipcode": "16122",
                    "province": "Cuenca",
                    "city": "Fresneda de Altarejos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16123",
                    "province": "Cuenca",
                    "city": "Arcas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16140",
                    "province": "Cuenca",
                    "city": "Villalba de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "16141",
                    "province": "Cuenca",
                    "city": "Castillejo-Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "16141",
                    "province": "Cuenca",
                    "city": "Portilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "16141",
                    "province": "Cuenca",
                    "city": "Arcos de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "16141",
                    "province": "Cuenca",
                    "city": "Fresneda de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "16142",
                    "province": "Cuenca",
                    "city": "Majadas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "16143",
                    "province": "Cuenca",
                    "city": "Mariana",
                    "multiple": "0"
                },
                {
                    "zipcode": "16143",
                    "province": "Cuenca",
                    "city": "Sotorribas",
                    "multiple": "1"
                },
                {
                    "zipcode": "16144",
                    "province": "Cuenca",
                    "city": "Frontera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "16144",
                    "province": "Cuenca",
                    "city": "Sotorribas",
                    "multiple": "1"
                },
                {
                    "zipcode": "16145",
                    "province": "Cuenca",
                    "city": "Sotorribas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16146",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "16146",
                    "province": "Cuenca",
                    "city": "Zarzuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "16147",
                    "province": "Cuenca",
                    "city": "Mariana",
                    "multiple": "0"
                },
                {
                    "zipcode": "16150",
                    "province": "Cuenca",
                    "city": "Vega del Codorno",
                    "multiple": "0"
                },
                {
                    "zipcode": "16150",
                    "province": "Cuenca",
                    "city": "Tragacete",
                    "multiple": "1"
                },
                {
                    "zipcode": "16152",
                    "province": "Cuenca",
                    "city": "Uña",
                    "multiple": "0"
                },
                {
                    "zipcode": "16152",
                    "province": "Cuenca",
                    "city": "Huélamo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16152",
                    "province": "Cuenca",
                    "city": "Beamud",
                    "multiple": "1"
                },
                {
                    "zipcode": "16152",
                    "province": "Cuenca",
                    "city": "Valdemeca",
                    "multiple": "1"
                },
                {
                    "zipcode": "16160",
                    "province": "Cuenca",
                    "city": "Palomares del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16160",
                    "province": "Cuenca",
                    "city": "Chiloeches",
                    "multiple": "1"
                },
                {
                    "zipcode": "16161",
                    "province": "Cuenca",
                    "city": "Torrejoncillo del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16162",
                    "province": "Cuenca",
                    "city": "Torrejoncillo del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16190",
                    "province": "Cuenca",
                    "city": "Chillarón de Cuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "16190",
                    "province": "Cuenca",
                    "city": "Fuentenava de Jábaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "16191",
                    "province": "Cuenca",
                    "city": "Fuentenava de Jábaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "16191",
                    "province": "Cuenca",
                    "city": "Bascuñana de San Pedro",
                    "multiple": "1"
                },
                {
                    "zipcode": "16191",
                    "province": "Cuenca",
                    "city": "Villar de Domingo García",
                    "multiple": "1"
                },
                {
                    "zipcode": "16191",
                    "province": "Cuenca",
                    "city": "Chillarón de Cuenca",
                    "multiple": "1"
                },
                {
                    "zipcode": "16191",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "1"
                },
                {
                    "zipcode": "16192",
                    "province": "Cuenca",
                    "city": "Palomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "16192",
                    "province": "Cuenca",
                    "city": "Buenache de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "16193",
                    "province": "Cuenca",
                    "city": "Fuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "16193",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "1"
                },
                {
                    "zipcode": "16194",
                    "province": "Cuenca",
                    "city": "Chillarón de Cuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "16194",
                    "province": "Cuenca",
                    "city": "Fuentenava de Jábaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "16194",
                    "province": "Cuenca",
                    "city": "Cuenca",
                    "multiple": "1"
                },
                {
                    "zipcode": "16195",
                    "province": "Cuenca",
                    "city": "Torrejoncillo del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16195",
                    "province": "Cuenca",
                    "city": "Abia de la Obispalía",
                    "multiple": "1"
                },
                {
                    "zipcode": "16195",
                    "province": "Cuenca",
                    "city": "Villar de Olalla",
                    "multiple": "1"
                },
                {
                    "zipcode": "16195",
                    "province": "Cuenca",
                    "city": "Altarejos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16195",
                    "province": "Cuenca",
                    "city": "Huerta de la Obispalía",
                    "multiple": "1"
                },
                {
                    "zipcode": "16196",
                    "province": "Cuenca",
                    "city": "Villar de Olalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "16200",
                    "province": "Cuenca",
                    "city": "Motilla del Palancar",
                    "multiple": "0"
                },
                {
                    "zipcode": "16210",
                    "province": "Cuenca",
                    "city": "Campillo de Altobuey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16211",
                    "province": "Cuenca",
                    "city": "Picazo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16212",
                    "province": "Cuenca",
                    "city": "Pozorrubielos de la Mancha",
                    "multiple": "0"
                },
                {
                    "zipcode": "16214",
                    "province": "Cuenca",
                    "city": "Valhermoso de la Fuente",
                    "multiple": "0"
                },
                {
                    "zipcode": "16214",
                    "province": "Cuenca",
                    "city": "Alarcón",
                    "multiple": "1"
                },
                {
                    "zipcode": "16214",
                    "province": "Cuenca",
                    "city": "Valverdejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16214",
                    "province": "Cuenca",
                    "city": "Gabaldón",
                    "multiple": "1"
                },
                {
                    "zipcode": "16215",
                    "province": "Cuenca",
                    "city": "Almodóvar del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "16216",
                    "province": "Cuenca",
                    "city": "Valeras, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "16216",
                    "province": "Cuenca",
                    "city": "Chumillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "16216",
                    "province": "Cuenca",
                    "city": "Olmeda del Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "16216",
                    "province": "Cuenca",
                    "city": "Solera de Gabaldón",
                    "multiple": "1"
                },
                {
                    "zipcode": "16220",
                    "province": "Cuenca",
                    "city": "Quintanar del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16230",
                    "province": "Cuenca",
                    "city": "Villanueva de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "16235",
                    "province": "Cuenca",
                    "city": "Iniesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16236",
                    "province": "Cuenca",
                    "city": "Villagarcía del Llano",
                    "multiple": "0"
                },
                {
                    "zipcode": "16236",
                    "province": "Cuenca",
                    "city": "Villanueva de la Jara",
                    "multiple": "1"
                },
                {
                    "zipcode": "16236",
                    "province": "Cuenca",
                    "city": "Iniesta",
                    "multiple": "1"
                },
                {
                    "zipcode": "16237",
                    "province": "Cuenca",
                    "city": "Ledaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "16238",
                    "province": "Cuenca",
                    "city": "Villagarcía del Llano",
                    "multiple": "0"
                },
                {
                    "zipcode": "16238",
                    "province": "Cuenca",
                    "city": "Villanueva de la Jara",
                    "multiple": "1"
                },
                {
                    "zipcode": "16239",
                    "province": "Cuenca",
                    "city": "Casasimarro",
                    "multiple": "0"
                },
                {
                    "zipcode": "16240",
                    "province": "Cuenca",
                    "city": "Peral, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16250",
                    "province": "Cuenca",
                    "city": "Castillejo de Iniesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16251",
                    "province": "Cuenca",
                    "city": "Graja de Iniesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16260",
                    "province": "Cuenca",
                    "city": "Minglanilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "16269",
                    "province": "Cuenca",
                    "city": "Puebla del Salvador",
                    "multiple": "0"
                },
                {
                    "zipcode": "16269",
                    "province": "Cuenca",
                    "city": "Pesquera, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "16270",
                    "province": "Cuenca",
                    "city": "Villalpardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16280",
                    "province": "Cuenca",
                    "city": "Villarta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16290",
                    "province": "Cuenca",
                    "city": "Iniesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16290",
                    "province": "Cuenca",
                    "city": "Herrumblar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "16300",
                    "province": "Cuenca",
                    "city": "Cañete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16311",
                    "province": "Cuenca",
                    "city": "Huérguina",
                    "multiple": "0"
                },
                {
                    "zipcode": "16311",
                    "province": "Cuenca",
                    "city": "Fuentelespino de Moya",
                    "multiple": "1"
                },
                {
                    "zipcode": "16311",
                    "province": "Cuenca",
                    "city": "Boniches",
                    "multiple": "1"
                },
                {
                    "zipcode": "16311",
                    "province": "Cuenca",
                    "city": "Campillos-Paravientos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16312",
                    "province": "Cuenca",
                    "city": "Garaballa",
                    "multiple": "0"
                },
                {
                    "zipcode": "16312",
                    "province": "Cuenca",
                    "city": "Henarejos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16313",
                    "province": "Cuenca",
                    "city": "Aliaguilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "16315",
                    "province": "Cuenca",
                    "city": "Boniches",
                    "multiple": "0"
                },
                {
                    "zipcode": "16315",
                    "province": "Cuenca",
                    "city": "Alcalá de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "16316",
                    "province": "Cuenca",
                    "city": "Valdemoro-Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "16316",
                    "province": "Cuenca",
                    "city": "Laguna del Marquesado",
                    "multiple": "1"
                },
                {
                    "zipcode": "16316",
                    "province": "Cuenca",
                    "city": "Huerta del Marquesado",
                    "multiple": "1"
                },
                {
                    "zipcode": "16316",
                    "province": "Cuenca",
                    "city": "Campillos-Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "16317",
                    "province": "Cuenca",
                    "city": "Tejadillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16317",
                    "province": "Cuenca",
                    "city": "Salinas del Manzano",
                    "multiple": "1"
                },
                {
                    "zipcode": "16317",
                    "province": "Cuenca",
                    "city": "Zafrilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "16318",
                    "province": "Cuenca",
                    "city": "Salvacañete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16320",
                    "province": "Cuenca",
                    "city": "Talayuelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16321",
                    "province": "Cuenca",
                    "city": "Talayuelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16330",
                    "province": "Cuenca",
                    "city": "Landete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16336",
                    "province": "Cuenca",
                    "city": "Santa Cruz de Moya",
                    "multiple": "0"
                },
                {
                    "zipcode": "16337",
                    "province": "Cuenca",
                    "city": "Moya",
                    "multiple": "0"
                },
                {
                    "zipcode": "16338",
                    "province": "Cuenca",
                    "city": "Casas de Garcimolina",
                    "multiple": "0"
                },
                {
                    "zipcode": "16338",
                    "province": "Cuenca",
                    "city": "Algarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "16338",
                    "province": "Cuenca",
                    "city": "Moya",
                    "multiple": "1"
                },
                {
                    "zipcode": "16339",
                    "province": "Cuenca",
                    "city": "Graja de Campalbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16339",
                    "province": "Cuenca",
                    "city": "Santa Cruz de Moya",
                    "multiple": "1"
                },
                {
                    "zipcode": "16339",
                    "province": "Cuenca",
                    "city": "Landete",
                    "multiple": "1"
                },
                {
                    "zipcode": "16340",
                    "province": "Cuenca",
                    "city": "Cañada del Hoyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16340",
                    "province": "Cuenca",
                    "city": "Valdemorillo de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "16340",
                    "province": "Cuenca",
                    "city": "Cierva, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "16350",
                    "province": "Cuenca",
                    "city": "Carboneras de Guadazaón",
                    "multiple": "0"
                },
                {
                    "zipcode": "16360",
                    "province": "Cuenca",
                    "city": "Monteagudo de las Salinas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16360",
                    "province": "Cuenca",
                    "city": "Arguisuelas",
                    "multiple": "1"
                },
                {
                    "zipcode": "16370",
                    "province": "Cuenca",
                    "city": "Villar del Humo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16370",
                    "province": "Cuenca",
                    "city": "San Martín de Boniches",
                    "multiple": "1"
                },
                {
                    "zipcode": "16371",
                    "province": "Cuenca",
                    "city": "Víllora",
                    "multiple": "0"
                },
                {
                    "zipcode": "16371",
                    "province": "Cuenca",
                    "city": "Narboneta",
                    "multiple": "1"
                },
                {
                    "zipcode": "16372",
                    "province": "Cuenca",
                    "city": "Paracuellos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16372",
                    "province": "Cuenca",
                    "city": "Enguídanos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16373",
                    "province": "Cuenca",
                    "city": "Yémeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "16373",
                    "province": "Cuenca",
                    "city": "Paracuellos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16373",
                    "province": "Cuenca",
                    "city": "Cardenete",
                    "multiple": "1"
                },
                {
                    "zipcode": "16390",
                    "province": "Cuenca",
                    "city": "Pajarón",
                    "multiple": "0"
                },
                {
                    "zipcode": "16390",
                    "province": "Cuenca",
                    "city": "Pajaroncillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16390",
                    "province": "Cuenca",
                    "city": "Reíllo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16393",
                    "province": "Cuenca",
                    "city": "Mira",
                    "multiple": "0"
                },
                {
                    "zipcode": "16400",
                    "province": "Cuenca",
                    "city": "Tarancón",
                    "multiple": "0"
                },
                {
                    "zipcode": "16410",
                    "province": "Cuenca",
                    "city": "Horcajo de Santiago",
                    "multiple": "0"
                },
                {
                    "zipcode": "16411",
                    "province": "Cuenca",
                    "city": "Fuente de Pedro Naharro",
                    "multiple": "0"
                },
                {
                    "zipcode": "16412",
                    "province": "Cuenca",
                    "city": "Acebrón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16413",
                    "province": "Cuenca",
                    "city": "Torrubia del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16414",
                    "province": "Cuenca",
                    "city": "Pozorrubio de Santiago",
                    "multiple": "0"
                },
                {
                    "zipcode": "16415",
                    "province": "Cuenca",
                    "city": "Villamayor de Santiago",
                    "multiple": "0"
                },
                {
                    "zipcode": "16417",
                    "province": "Cuenca",
                    "city": "Hinojosos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "16420",
                    "province": "Cuenca",
                    "city": "Villarrubio",
                    "multiple": "0"
                },
                {
                    "zipcode": "16420",
                    "province": "Cuenca",
                    "city": "Almendros",
                    "multiple": "1"
                },
                {
                    "zipcode": "16421",
                    "province": "Cuenca",
                    "city": "Hontanaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "16421",
                    "province": "Cuenca",
                    "city": "Puebla de Almenara",
                    "multiple": "1"
                },
                {
                    "zipcode": "16422",
                    "province": "Cuenca",
                    "city": "Tresjuncos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16423",
                    "province": "Cuenca",
                    "city": "Osa de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "16430",
                    "province": "Cuenca",
                    "city": "Saelices",
                    "multiple": "0"
                },
                {
                    "zipcode": "16431",
                    "province": "Cuenca",
                    "city": "Almonacid del Marquesado",
                    "multiple": "0"
                },
                {
                    "zipcode": "16432",
                    "province": "Cuenca",
                    "city": "Rozalén del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "16432",
                    "province": "Cuenca",
                    "city": "Villarejo de Fuentes",
                    "multiple": "1"
                },
                {
                    "zipcode": "16433",
                    "province": "Cuenca",
                    "city": "Villar de Cañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16433",
                    "province": "Cuenca",
                    "city": "Alconchel de la Estrella",
                    "multiple": "1"
                },
                {
                    "zipcode": "16434",
                    "province": "Cuenca",
                    "city": "Montalbanejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16435",
                    "province": "Cuenca",
                    "city": "Hinojosa, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "16440",
                    "province": "Cuenca",
                    "city": "Montalbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16441",
                    "province": "Cuenca",
                    "city": "Hito, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16442",
                    "province": "Cuenca",
                    "city": "Villares del Saz",
                    "multiple": "0"
                },
                {
                    "zipcode": "16444",
                    "province": "Cuenca",
                    "city": "Cervera del Llano",
                    "multiple": "0"
                },
                {
                    "zipcode": "16452",
                    "province": "Cuenca",
                    "city": "Rozalén del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "16452",
                    "province": "Cuenca",
                    "city": "Tribaldos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16452",
                    "province": "Cuenca",
                    "city": "Uclés",
                    "multiple": "1"
                },
                {
                    "zipcode": "16460",
                    "province": "Cuenca",
                    "city": "Barajas de Melo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16461",
                    "province": "Cuenca",
                    "city": "Leganiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "16463",
                    "province": "Cuenca",
                    "city": "Saceda-Trasierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "16464",
                    "province": "Cuenca",
                    "city": "Alcázar del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16465",
                    "province": "Cuenca",
                    "city": "Paredes",
                    "multiple": "0"
                },
                {
                    "zipcode": "16465",
                    "province": "Cuenca",
                    "city": "Huelves",
                    "multiple": "1"
                },
                {
                    "zipcode": "16470",
                    "province": "Cuenca",
                    "city": "Barajas de Melo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16470",
                    "province": "Cuenca",
                    "city": "Belinchón",
                    "multiple": "1"
                },
                {
                    "zipcode": "16470",
                    "province": "Cuenca",
                    "city": "Zarza de Tajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16500",
                    "province": "Cuenca",
                    "city": "Huete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16510",
                    "province": "Cuenca",
                    "city": "Valle de Altomira, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16510",
                    "province": "Cuenca",
                    "city": "Vellisca",
                    "multiple": "1"
                },
                {
                    "zipcode": "16512",
                    "province": "Cuenca",
                    "city": "Valle de Altomira, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16512",
                    "province": "Cuenca",
                    "city": "Buendía",
                    "multiple": "1"
                },
                {
                    "zipcode": "16514",
                    "province": "Cuenca",
                    "city": "Valle de Altomira, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16520",
                    "province": "Cuenca",
                    "city": "Huete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16521",
                    "province": "Cuenca",
                    "city": "Huete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16522",
                    "province": "Cuenca",
                    "city": "Portalrubio de Guadamejud",
                    "multiple": "0"
                },
                {
                    "zipcode": "16522",
                    "province": "Cuenca",
                    "city": "Tinajas",
                    "multiple": "1"
                },
                {
                    "zipcode": "16532",
                    "province": "Cuenca",
                    "city": "Huete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16532",
                    "province": "Cuenca",
                    "city": "Gascueña",
                    "multiple": "1"
                },
                {
                    "zipcode": "16532",
                    "province": "Cuenca",
                    "city": "Villanueva de Guadamejud",
                    "multiple": "1"
                },
                {
                    "zipcode": "16532",
                    "province": "Cuenca",
                    "city": "Peraleja, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "16535",
                    "province": "Cuenca",
                    "city": "Villalba del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16537",
                    "province": "Cuenca",
                    "city": "Cañaveruelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16537",
                    "province": "Cuenca",
                    "city": "Alcohujate",
                    "multiple": "1"
                },
                {
                    "zipcode": "16540",
                    "province": "Cuenca",
                    "city": "Huete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16541",
                    "province": "Cuenca",
                    "city": "Valdecolmenas, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "16541",
                    "province": "Cuenca",
                    "city": "Huete",
                    "multiple": "1"
                },
                {
                    "zipcode": "16541",
                    "province": "Cuenca",
                    "city": "Pineda de Gigüela",
                    "multiple": "1"
                },
                {
                    "zipcode": "16541",
                    "province": "Cuenca",
                    "city": "Villarejo de la Peñuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "16542",
                    "province": "Cuenca",
                    "city": "Villar y Velasco",
                    "multiple": "0"
                },
                {
                    "zipcode": "16550",
                    "province": "Cuenca",
                    "city": "Huete",
                    "multiple": "0"
                },
                {
                    "zipcode": "16550",
                    "province": "Cuenca",
                    "city": "Campos del Paraíso",
                    "multiple": "1"
                },
                {
                    "zipcode": "16555",
                    "province": "Cuenca",
                    "city": "Campos del Paraíso",
                    "multiple": "0"
                },
                {
                    "zipcode": "16600",
                    "province": "Cuenca",
                    "city": "San Clemente",
                    "multiple": "0"
                },
                {
                    "zipcode": "16610",
                    "province": "Cuenca",
                    "city": "Casas de Fernando Alonso",
                    "multiple": "0"
                },
                {
                    "zipcode": "16611",
                    "province": "Cuenca",
                    "city": "Casas de Haro",
                    "multiple": "0"
                },
                {
                    "zipcode": "16612",
                    "province": "Cuenca",
                    "city": "Casas de los Pinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16620",
                    "province": "Cuenca",
                    "city": "Alberca de Záncara, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "16621",
                    "province": "Cuenca",
                    "city": "Santa María del Campo Rus",
                    "multiple": "0"
                },
                {
                    "zipcode": "16622",
                    "province": "Cuenca",
                    "city": "Pinarejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16623",
                    "province": "Cuenca",
                    "city": "Castillo de Garcimuñoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "16630",
                    "province": "Cuenca",
                    "city": "Mota del Cuervo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16638",
                    "province": "Cuenca",
                    "city": "Pedernoso, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16639",
                    "province": "Cuenca",
                    "city": "Santa María de los Llanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16640",
                    "province": "Cuenca",
                    "city": "Belmonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "16646",
                    "province": "Cuenca",
                    "city": "Villalgordo del Marquesado",
                    "multiple": "0"
                },
                {
                    "zipcode": "16647",
                    "province": "Cuenca",
                    "city": "Fuentelespino de Haro",
                    "multiple": "0"
                },
                {
                    "zipcode": "16647",
                    "province": "Cuenca",
                    "city": "Villaescusa de Haro",
                    "multiple": "1"
                },
                {
                    "zipcode": "16648",
                    "province": "Cuenca",
                    "city": "Villar de la Encina",
                    "multiple": "0"
                },
                {
                    "zipcode": "16649",
                    "province": "Cuenca",
                    "city": "Carrascosa de Haro",
                    "multiple": "0"
                },
                {
                    "zipcode": "16649",
                    "province": "Cuenca",
                    "city": "Rada de Haro",
                    "multiple": "1"
                },
                {
                    "zipcode": "16649",
                    "province": "Cuenca",
                    "city": "Monreal del Llano",
                    "multiple": "1"
                },
                {
                    "zipcode": "16650",
                    "province": "Cuenca",
                    "city": "Mesas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "16660",
                    "province": "Cuenca",
                    "city": "Pedroñeras, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "16670",
                    "province": "Cuenca",
                    "city": "Provencio, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16700",
                    "province": "Cuenca",
                    "city": "Sisante",
                    "multiple": "0"
                },
                {
                    "zipcode": "16707",
                    "province": "Cuenca",
                    "city": "Casas de Benítez",
                    "multiple": "0"
                },
                {
                    "zipcode": "16708",
                    "province": "Cuenca",
                    "city": "Pozoamargo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16708",
                    "province": "Cuenca",
                    "city": "Casas de Guijarro",
                    "multiple": "1"
                },
                {
                    "zipcode": "16709",
                    "province": "Cuenca",
                    "city": "Vara de Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "16710",
                    "province": "Cuenca",
                    "city": "Atalaya del Cañavate",
                    "multiple": "0"
                },
                {
                    "zipcode": "16710",
                    "province": "Cuenca",
                    "city": "Tébar",
                    "multiple": "1"
                },
                {
                    "zipcode": "16720",
                    "province": "Cuenca",
                    "city": "Cañada Juncosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "16730",
                    "province": "Cuenca",
                    "city": "Honrubia",
                    "multiple": "0"
                },
                {
                    "zipcode": "16738",
                    "province": "Cuenca",
                    "city": "Cañavate, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "16739",
                    "province": "Cuenca",
                    "city": "Torrubia del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16740",
                    "province": "Cuenca",
                    "city": "Belinchón",
                    "multiple": "0"
                },
                {
                    "zipcode": "16740",
                    "province": "Cuenca",
                    "city": "Almarcha, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "16760",
                    "province": "Cuenca",
                    "city": "Olivares de Júcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "16770",
                    "province": "Cuenca",
                    "city": "San Lorenzo de la Parrilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "16771",
                    "province": "Cuenca",
                    "city": "Villarejo-Periesteban",
                    "multiple": "0"
                },
                {
                    "zipcode": "16771",
                    "province": "Cuenca",
                    "city": "Zafra de Záncara",
                    "multiple": "1"
                },
                {
                    "zipcode": "16779",
                    "province": "Cuenca",
                    "city": "Belmontejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16780",
                    "province": "Cuenca",
                    "city": "Mota de Altarejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16780",
                    "province": "Cuenca",
                    "city": "Altarejos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16781",
                    "province": "Cuenca",
                    "city": "Fresneda de Altarejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "16800",
                    "province": "Cuenca",
                    "city": "Priego",
                    "multiple": "0"
                },
                {
                    "zipcode": "16812",
                    "province": "Cuenca",
                    "city": "Alcantud",
                    "multiple": "0"
                },
                {
                    "zipcode": "16812",
                    "province": "Cuenca",
                    "city": "Pozoamargo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16812",
                    "province": "Cuenca",
                    "city": "Pozuelo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "16812",
                    "province": "Cuenca",
                    "city": "Vindel",
                    "multiple": "1"
                },
                {
                    "zipcode": "16812",
                    "province": "Cuenca",
                    "city": "Arandilla del Arroyo",
                    "multiple": "1"
                },
                {
                    "zipcode": "16812",
                    "province": "Cuenca",
                    "city": "Albendea",
                    "multiple": "1"
                },
                {
                    "zipcode": "16813",
                    "province": "Cuenca",
                    "city": "San Pedro Palmiches",
                    "multiple": "0"
                },
                {
                    "zipcode": "16813",
                    "province": "Cuenca",
                    "city": "Salmeroncillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16813",
                    "province": "Cuenca",
                    "city": "Villar del Infantado",
                    "multiple": "1"
                },
                {
                    "zipcode": "16813",
                    "province": "Cuenca",
                    "city": "Valdeolivas",
                    "multiple": "1"
                },
                {
                    "zipcode": "16840",
                    "province": "Cuenca",
                    "city": "Villar de Domingo García",
                    "multiple": "0"
                },
                {
                    "zipcode": "16841",
                    "province": "Cuenca",
                    "city": "Albalate de las Nogueras",
                    "multiple": "0"
                },
                {
                    "zipcode": "16842",
                    "province": "Cuenca",
                    "city": "Torralba",
                    "multiple": "0"
                },
                {
                    "zipcode": "16843",
                    "province": "Cuenca",
                    "city": "Villas de la Ventosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "16850",
                    "province": "Cuenca",
                    "city": "Cañaveras",
                    "multiple": "0"
                },
                {
                    "zipcode": "16851",
                    "province": "Cuenca",
                    "city": "Buciegas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16852",
                    "province": "Cuenca",
                    "city": "Olmeda de la Cuesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16853",
                    "province": "Cuenca",
                    "city": "Olmedilla de Eliz",
                    "multiple": "0"
                },
                {
                    "zipcode": "16854",
                    "province": "Cuenca",
                    "city": "Castillo-Albaráñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "16855",
                    "province": "Cuenca",
                    "city": "Arrancacepas",
                    "multiple": "0"
                },
                {
                    "zipcode": "16856",
                    "province": "Cuenca",
                    "city": "Castejón",
                    "multiple": "0"
                },
                {
                    "zipcode": "16857",
                    "province": "Cuenca",
                    "city": "Canalejas del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "16860",
                    "province": "Cuenca",
                    "city": "Villaconejos de Trabaque",
                    "multiple": "0"
                },
                {
                    "zipcode": "16870",
                    "province": "Cuenca",
                    "city": "Beteta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16878",
                    "province": "Cuenca",
                    "city": "Masegosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "16878",
                    "province": "Cuenca",
                    "city": "Poyatos",
                    "multiple": "1"
                },
                {
                    "zipcode": "16878",
                    "province": "Cuenca",
                    "city": "Lagunaseca",
                    "multiple": "1"
                },
                {
                    "zipcode": "16878",
                    "province": "Cuenca",
                    "city": "Santa María del Val",
                    "multiple": "1"
                },
                {
                    "zipcode": "16879",
                    "province": "Cuenca",
                    "city": "Beteta",
                    "multiple": "0"
                },
                {
                    "zipcode": "16879",
                    "province": "Cuenca",
                    "city": "Valsalobre",
                    "multiple": "1"
                },
                {
                    "zipcode": "16879",
                    "province": "Cuenca",
                    "city": "Carrascosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "16879",
                    "province": "Cuenca",
                    "city": "Cueva del Hierro",
                    "multiple": "1"
                },
                {
                    "zipcode": "16890",
                    "province": "Cuenca",
                    "city": "Fuertescusa",
                    "multiple": "0"
                },
                {
                    "zipcode": "16890",
                    "province": "Cuenca",
                    "city": "Cañizares",
                    "multiple": "1"
                },
                {
                    "zipcode": "16890",
                    "province": "Cuenca",
                    "city": "Cañamares",
                    "multiple": "1"
                },
                {
                    "zipcode": "16891",
                    "province": "Cuenca",
                    "city": "Carrascosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "16891",
                    "province": "Cuenca",
                    "city": "Cañizares",
                    "multiple": "1"
                },
                {
                    "zipcode": "16892",
                    "province": "Cuenca",
                    "city": "Cañizares",
                    "multiple": "0"
                },
                {
                    "zipcode": "16893",
                    "province": "Cuenca",
                    "city": "Beteta",
                    "multiple": "0"
                },
                {
                    "zipcode": "17001",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17002",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17003",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17004",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17005",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17005",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "1"
                },
                {
                    "zipcode": "17006",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17007",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17100",
                    "province": "Girona",
                    "city": "Bisbal d'Empordà, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17110",
                    "province": "Girona",
                    "city": "Forallac",
                    "multiple": "0"
                },
                {
                    "zipcode": "17111",
                    "province": "Girona",
                    "city": "Forallac",
                    "multiple": "0"
                },
                {
                    "zipcode": "17113",
                    "province": "Girona",
                    "city": "Forallac",
                    "multiple": "0"
                },
                {
                    "zipcode": "17114",
                    "province": "Girona",
                    "city": "Ullastret",
                    "multiple": "0"
                },
                {
                    "zipcode": "17116",
                    "province": "Girona",
                    "city": "Cruïlles, Monells i Sant Sadurní de l'Heura",
                    "multiple": "0"
                },
                {
                    "zipcode": "17117",
                    "province": "Girona",
                    "city": "Cruïlles, Monells i Sant Sadurní de l'Heura",
                    "multiple": "0"
                },
                {
                    "zipcode": "17118",
                    "province": "Girona",
                    "city": "Cruïlles, Monells i Sant Sadurní de l'Heura",
                    "multiple": "0"
                },
                {
                    "zipcode": "17120",
                    "province": "Girona",
                    "city": "Pera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17121",
                    "province": "Girona",
                    "city": "Corçà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17121",
                    "province": "Girona",
                    "city": "Cruïlles, Monells i Sant Sadurní de l'Heura",
                    "multiple": "1"
                },
                {
                    "zipcode": "17123",
                    "province": "Girona",
                    "city": "Torrent",
                    "multiple": "0"
                },
                {
                    "zipcode": "17124",
                    "province": "Girona",
                    "city": "Palafrugell",
                    "multiple": "0"
                },
                {
                    "zipcode": "17130",
                    "province": "Girona",
                    "city": "Escala, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "17130",
                    "province": "Girona",
                    "city": "Torroella de Montgrí",
                    "multiple": "1"
                },
                {
                    "zipcode": "17131",
                    "province": "Girona",
                    "city": "Rupià",
                    "multiple": "0"
                },
                {
                    "zipcode": "17132",
                    "province": "Girona",
                    "city": "Foixà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17133",
                    "province": "Girona",
                    "city": "Ultramort",
                    "multiple": "0"
                },
                {
                    "zipcode": "17133",
                    "province": "Girona",
                    "city": "Parlavà",
                    "multiple": "1"
                },
                {
                    "zipcode": "17133",
                    "province": "Girona",
                    "city": "Foixà",
                    "multiple": "1"
                },
                {
                    "zipcode": "17133",
                    "province": "Girona",
                    "city": "Serra de Daró",
                    "multiple": "1"
                },
                {
                    "zipcode": "17134",
                    "province": "Girona",
                    "city": "Tallada d'Empordà, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17136",
                    "province": "Girona",
                    "city": "Albons",
                    "multiple": "0"
                },
                {
                    "zipcode": "17137",
                    "province": "Girona",
                    "city": "Ventalló",
                    "multiple": "0"
                },
                {
                    "zipcode": "17137",
                    "province": "Girona",
                    "city": "Viladamat",
                    "multiple": "1"
                },
                {
                    "zipcode": "17140",
                    "province": "Girona",
                    "city": "Ullà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17141",
                    "province": "Girona",
                    "city": "Bellcaire d'Empordà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17141",
                    "province": "Girona",
                    "city": "Torroella de Montgrí",
                    "multiple": "1"
                },
                {
                    "zipcode": "17142",
                    "province": "Girona",
                    "city": "Verges",
                    "multiple": "0"
                },
                {
                    "zipcode": "17143",
                    "province": "Girona",
                    "city": "Jafre",
                    "multiple": "0"
                },
                {
                    "zipcode": "17144",
                    "province": "Girona",
                    "city": "Vilopriu",
                    "multiple": "0"
                },
                {
                    "zipcode": "17144",
                    "province": "Girona",
                    "city": "Colomers",
                    "multiple": "1"
                },
                {
                    "zipcode": "17150",
                    "province": "Girona",
                    "city": "Sant Gregori",
                    "multiple": "0"
                },
                {
                    "zipcode": "17151",
                    "province": "Girona",
                    "city": "Sant Gregori",
                    "multiple": "0"
                },
                {
                    "zipcode": "17152",
                    "province": "Girona",
                    "city": "Sant Martí de Llémena",
                    "multiple": "0"
                },
                {
                    "zipcode": "17153",
                    "province": "Girona",
                    "city": "Sant Martí de Llémena",
                    "multiple": "0"
                },
                {
                    "zipcode": "17154",
                    "province": "Girona",
                    "city": "Sant Aniol de Finestres",
                    "multiple": "0"
                },
                {
                    "zipcode": "17160",
                    "province": "Girona",
                    "city": "Anglès",
                    "multiple": "0"
                },
                {
                    "zipcode": "17161",
                    "province": "Girona",
                    "city": "Osor",
                    "multiple": "0"
                },
                {
                    "zipcode": "17162",
                    "province": "Girona",
                    "city": "Bescanó",
                    "multiple": "0"
                },
                {
                    "zipcode": "17163",
                    "province": "Girona",
                    "city": "Bescanó",
                    "multiple": "0"
                },
                {
                    "zipcode": "17164",
                    "province": "Girona",
                    "city": "Sant Julià del Llor i Bonmatí",
                    "multiple": "0"
                },
                {
                    "zipcode": "17164",
                    "province": "Girona",
                    "city": "Sant Gregori",
                    "multiple": "1"
                },
                {
                    "zipcode": "17164",
                    "province": "Girona",
                    "city": "Sant Martí de Llémena",
                    "multiple": "1"
                },
                {
                    "zipcode": "17165",
                    "province": "Girona",
                    "city": "Cellera de Ter, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17166",
                    "province": "Girona",
                    "city": "Cellera de Ter, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17166",
                    "province": "Girona",
                    "city": "Susqueda",
                    "multiple": "1"
                },
                {
                    "zipcode": "17170",
                    "province": "Girona",
                    "city": "Amer",
                    "multiple": "0"
                },
                {
                    "zipcode": "17171",
                    "province": "Girona",
                    "city": "Sant Aniol de Finestres",
                    "multiple": "0"
                },
                {
                    "zipcode": "17171",
                    "province": "Girona",
                    "city": "Susqueda",
                    "multiple": "1"
                },
                {
                    "zipcode": "17172",
                    "province": "Girona",
                    "city": "Planes d'Hostoles, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "17173",
                    "province": "Girona",
                    "city": "Planes d'Hostoles, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "17174",
                    "province": "Girona",
                    "city": "Sant Feliu de Pallerols",
                    "multiple": "0"
                },
                {
                    "zipcode": "17176",
                    "province": "Girona",
                    "city": "Vall d'en Bas, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17177",
                    "province": "Girona",
                    "city": "Vall d'en Bas, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17178",
                    "province": "Girona",
                    "city": "Preses, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "17178",
                    "province": "Girona",
                    "city": "Vall d'en Bas, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "17179",
                    "province": "Girona",
                    "city": "Riudaura",
                    "multiple": "0"
                },
                {
                    "zipcode": "17179",
                    "province": "Girona",
                    "city": "Vall d'en Bas, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "17180",
                    "province": "Girona",
                    "city": "Vilablareix",
                    "multiple": "0"
                },
                {
                    "zipcode": "17181",
                    "province": "Girona",
                    "city": "Aiguaviva",
                    "multiple": "0"
                },
                {
                    "zipcode": "17182",
                    "province": "Girona",
                    "city": "Bescanó",
                    "multiple": "0"
                },
                {
                    "zipcode": "17183",
                    "province": "Girona",
                    "city": "Vilobí d'Onyar",
                    "multiple": "0"
                },
                {
                    "zipcode": "17184",
                    "province": "Girona",
                    "city": "Vilobí d'Onyar",
                    "multiple": "0"
                },
                {
                    "zipcode": "17185",
                    "province": "Girona",
                    "city": "Vilobí d'Onyar",
                    "multiple": "0"
                },
                {
                    "zipcode": "17190",
                    "province": "Girona",
                    "city": "Salt",
                    "multiple": "0"
                },
                {
                    "zipcode": "17199",
                    "province": "Girona",
                    "city": "Sant Gregori",
                    "multiple": "0"
                },
                {
                    "zipcode": "17199",
                    "province": "Girona",
                    "city": "Canet d'Adri",
                    "multiple": "1"
                },
                {
                    "zipcode": "17200",
                    "province": "Girona",
                    "city": "Palafrugell",
                    "multiple": "0"
                },
                {
                    "zipcode": "17210",
                    "province": "Girona",
                    "city": "Palafrugell",
                    "multiple": "0"
                },
                {
                    "zipcode": "17211",
                    "province": "Girona",
                    "city": "Palafrugell",
                    "multiple": "0"
                },
                {
                    "zipcode": "17212",
                    "province": "Girona",
                    "city": "Palafrugell",
                    "multiple": "0"
                },
                {
                    "zipcode": "17213",
                    "province": "Girona",
                    "city": "Begur",
                    "multiple": "0"
                },
                {
                    "zipcode": "17213",
                    "province": "Girona",
                    "city": "Palafrugell",
                    "multiple": "1"
                },
                {
                    "zipcode": "17214",
                    "province": "Girona",
                    "city": "Regencós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17220",
                    "province": "Girona",
                    "city": "Sant Feliu de Guíxols",
                    "multiple": "0"
                },
                {
                    "zipcode": "17230",
                    "province": "Girona",
                    "city": "Palamós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17240",
                    "province": "Girona",
                    "city": "Santa Cristina d'Aro",
                    "multiple": "0"
                },
                {
                    "zipcode": "17240",
                    "province": "Girona",
                    "city": "Llagostera",
                    "multiple": "1"
                },
                {
                    "zipcode": "17241",
                    "province": "Girona",
                    "city": "Quart",
                    "multiple": "0"
                },
                {
                    "zipcode": "17242",
                    "province": "Girona",
                    "city": "Quart",
                    "multiple": "0"
                },
                {
                    "zipcode": "17243",
                    "province": "Girona",
                    "city": "Llambilles",
                    "multiple": "0"
                },
                {
                    "zipcode": "17244",
                    "province": "Girona",
                    "city": "Cruïlles, Monells i Sant Sadurní de l'Heura",
                    "multiple": "0"
                },
                {
                    "zipcode": "17244",
                    "province": "Girona",
                    "city": "Cassà de la Selva",
                    "multiple": "1"
                },
                {
                    "zipcode": "17245",
                    "province": "Girona",
                    "city": "Santa Cristina d'Aro",
                    "multiple": "0"
                },
                {
                    "zipcode": "17246",
                    "province": "Girona",
                    "city": "Santa Cristina d'Aro",
                    "multiple": "0"
                },
                {
                    "zipcode": "17248",
                    "province": "Girona",
                    "city": "Castell-Platja d'Aro",
                    "multiple": "0"
                },
                {
                    "zipcode": "17248",
                    "province": "Girona",
                    "city": "Sant Feliu de Guíxols",
                    "multiple": "1"
                },
                {
                    "zipcode": "17249",
                    "province": "Girona",
                    "city": "Castell-Platja d'Aro",
                    "multiple": "0"
                },
                {
                    "zipcode": "17250",
                    "province": "Girona",
                    "city": "Castell-Platja d'Aro",
                    "multiple": "0"
                },
                {
                    "zipcode": "17251",
                    "province": "Girona",
                    "city": "Calonge",
                    "multiple": "0"
                },
                {
                    "zipcode": "17252",
                    "province": "Girona",
                    "city": "Calonge",
                    "multiple": "0"
                },
                {
                    "zipcode": "17253",
                    "province": "Girona",
                    "city": "Vall-llobrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "17253",
                    "province": "Girona",
                    "city": "Mont-ras",
                    "multiple": "1"
                },
                {
                    "zipcode": "17255",
                    "province": "Girona",
                    "city": "Begur",
                    "multiple": "0"
                },
                {
                    "zipcode": "17256",
                    "province": "Girona",
                    "city": "Pals",
                    "multiple": "0"
                },
                {
                    "zipcode": "17256",
                    "province": "Girona",
                    "city": "Palau-sator",
                    "multiple": "1"
                },
                {
                    "zipcode": "17257",
                    "province": "Girona",
                    "city": "Gualta",
                    "multiple": "0"
                },
                {
                    "zipcode": "17257",
                    "province": "Girona",
                    "city": "Torroella de Montgrí",
                    "multiple": "1"
                },
                {
                    "zipcode": "17257",
                    "province": "Girona",
                    "city": "Fontanilles",
                    "multiple": "1"
                },
                {
                    "zipcode": "17258",
                    "province": "Girona",
                    "city": "Torroella de Montgrí",
                    "multiple": "0"
                },
                {
                    "zipcode": "17300",
                    "province": "Girona",
                    "city": "Blanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "17310",
                    "province": "Girona",
                    "city": "Lloret de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "17320",
                    "province": "Girona",
                    "city": "Palamós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17320",
                    "province": "Girona",
                    "city": "Tossa de Mar",
                    "multiple": "1"
                },
                {
                    "zipcode": "17400",
                    "province": "Girona",
                    "city": "Breda",
                    "multiple": "0"
                },
                {
                    "zipcode": "17401",
                    "province": "Girona",
                    "city": "Arbúcies",
                    "multiple": "0"
                },
                {
                    "zipcode": "17402",
                    "province": "Girona",
                    "city": "Arbúcies",
                    "multiple": "0"
                },
                {
                    "zipcode": "17403",
                    "province": "Girona",
                    "city": "Sant Hilari Sacalm",
                    "multiple": "0"
                },
                {
                    "zipcode": "17404",
                    "province": "Girona",
                    "city": "Riells i Viabrea",
                    "multiple": "0"
                },
                {
                    "zipcode": "17405",
                    "province": "Girona",
                    "city": "Espinelves",
                    "multiple": "0"
                },
                {
                    "zipcode": "17406",
                    "province": "Girona",
                    "city": "Viladrau",
                    "multiple": "0"
                },
                {
                    "zipcode": "17410",
                    "province": "Girona",
                    "city": "Sils",
                    "multiple": "0"
                },
                {
                    "zipcode": "17411",
                    "province": "Girona",
                    "city": "Vidreres",
                    "multiple": "0"
                },
                {
                    "zipcode": "17412",
                    "province": "Girona",
                    "city": "Maçanet de la Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "17420",
                    "province": "Girona",
                    "city": "Sils",
                    "multiple": "0"
                },
                {
                    "zipcode": "17421",
                    "province": "Girona",
                    "city": "Riudarenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "17430",
                    "province": "Girona",
                    "city": "Lloret de Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "17430",
                    "province": "Girona",
                    "city": "Santa Coloma de Farners",
                    "multiple": "1"
                },
                {
                    "zipcode": "17441",
                    "province": "Girona",
                    "city": "Brunyola",
                    "multiple": "0"
                },
                {
                    "zipcode": "17450",
                    "province": "Girona",
                    "city": "Hostalric",
                    "multiple": "0"
                },
                {
                    "zipcode": "17451",
                    "province": "Girona",
                    "city": "Sant Feliu de Buixalleu",
                    "multiple": "0"
                },
                {
                    "zipcode": "17452",
                    "province": "Girona",
                    "city": "Massanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "17454",
                    "province": "Girona",
                    "city": "Sant Andreu Salou",
                    "multiple": "0"
                },
                {
                    "zipcode": "17455",
                    "province": "Girona",
                    "city": "Caldes de Malavella",
                    "multiple": "0"
                },
                {
                    "zipcode": "17456",
                    "province": "Girona",
                    "city": "Caldes de Malavella",
                    "multiple": "0"
                },
                {
                    "zipcode": "17457",
                    "province": "Girona",
                    "city": "Riudellots de la Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "17458",
                    "province": "Girona",
                    "city": "Fornells de la Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "17459",
                    "province": "Girona",
                    "city": "Campllong",
                    "multiple": "0"
                },
                {
                    "zipcode": "17460",
                    "province": "Girona",
                    "city": "Celrà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17461",
                    "province": "Girona",
                    "city": "Girona",
                    "multiple": "0"
                },
                {
                    "zipcode": "17462",
                    "province": "Girona",
                    "city": "Juià",
                    "multiple": "0"
                },
                {
                    "zipcode": "17462",
                    "province": "Girona",
                    "city": "Madremanya",
                    "multiple": "1"
                },
                {
                    "zipcode": "17462",
                    "province": "Girona",
                    "city": "Bordils",
                    "multiple": "1"
                },
                {
                    "zipcode": "17462",
                    "province": "Girona",
                    "city": "Sant Martí Vell",
                    "multiple": "1"
                },
                {
                    "zipcode": "17463",
                    "province": "Girona",
                    "city": "Sant Joan de Mollet",
                    "multiple": "0"
                },
                {
                    "zipcode": "17463",
                    "province": "Girona",
                    "city": "Flaçà",
                    "multiple": "1"
                },
                {
                    "zipcode": "17463",
                    "province": "Girona",
                    "city": "Foixà",
                    "multiple": "1"
                },
                {
                    "zipcode": "17464",
                    "province": "Girona",
                    "city": "Cervià de Ter",
                    "multiple": "0"
                },
                {
                    "zipcode": "17464",
                    "province": "Girona",
                    "city": "Sant Jordi Desvalls",
                    "multiple": "1"
                },
                {
                    "zipcode": "17464",
                    "province": "Girona",
                    "city": "Viladasens",
                    "multiple": "1"
                },
                {
                    "zipcode": "17465",
                    "province": "Girona",
                    "city": "Saus, Camallera i Llampaies",
                    "multiple": "0"
                },
                {
                    "zipcode": "17466",
                    "province": "Girona",
                    "city": "Garrigoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "17466",
                    "province": "Girona",
                    "city": "Vilopriu",
                    "multiple": "1"
                },
                {
                    "zipcode": "17467",
                    "province": "Girona",
                    "city": "Saus, Camallera i Llampaies",
                    "multiple": "0"
                },
                {
                    "zipcode": "17467",
                    "province": "Girona",
                    "city": "Sant Mori",
                    "multiple": "1"
                },
                {
                    "zipcode": "17468",
                    "province": "Girona",
                    "city": "Vilademuls",
                    "multiple": "0"
                },
                {
                    "zipcode": "17468",
                    "province": "Girona",
                    "city": "Bàscara",
                    "multiple": "1"
                },
                {
                    "zipcode": "17469",
                    "province": "Girona",
                    "city": "Far d'Empordà, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "17469",
                    "province": "Girona",
                    "city": "Vilamalla",
                    "multiple": "1"
                },
                {
                    "zipcode": "17469",
                    "province": "Girona",
                    "city": "Siurana",
                    "multiple": "1"
                },
                {
                    "zipcode": "17469",
                    "province": "Girona",
                    "city": "Riumors",
                    "multiple": "1"
                },
                {
                    "zipcode": "17469",
                    "province": "Girona",
                    "city": "Fortià",
                    "multiple": "1"
                },
                {
                    "zipcode": "17470",
                    "province": "Girona",
                    "city": "Sant Pere Pescador",
                    "multiple": "0"
                },
                {
                    "zipcode": "17472",
                    "province": "Girona",
                    "city": "Armentera, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "17472",
                    "province": "Girona",
                    "city": "Ventalló",
                    "multiple": "1"
                },
                {
                    "zipcode": "17473",
                    "province": "Girona",
                    "city": "Ventalló",
                    "multiple": "0"
                },
                {
                    "zipcode": "17474",
                    "province": "Girona",
                    "city": "Torroella de Fluvià",
                    "multiple": "0"
                },
                {
                    "zipcode": "17474",
                    "province": "Girona",
                    "city": "Vilamacolum",
                    "multiple": "1"
                },
                {
                    "zipcode": "17475",
                    "province": "Girona",
                    "city": "Ventalló",
                    "multiple": "0"
                },
                {
                    "zipcode": "17475",
                    "province": "Girona",
                    "city": "Sant Miquel de Fluvià",
                    "multiple": "1"
                },
                {
                    "zipcode": "17476",
                    "province": "Girona",
                    "city": "Palau de Santa Eulàlia",
                    "multiple": "0"
                },
                {
                    "zipcode": "17476",
                    "province": "Girona",
                    "city": "Garrigàs",
                    "multiple": "1"
                },
                {
                    "zipcode": "17480",
                    "province": "Girona",
                    "city": "Roses",
                    "multiple": "0"
                },
                {
                    "zipcode": "17481",
                    "province": "Girona",
                    "city": "Sant Julià de Ramis",
                    "multiple": "0"
                },
                {
                    "zipcode": "17482",
                    "province": "Girona",
                    "city": "Sant Julià de Ramis",
                    "multiple": "0"
                },
                {
                    "zipcode": "17483",
                    "province": "Girona",
                    "city": "Vilaür",
                    "multiple": "0"
                },
                {
                    "zipcode": "17483",
                    "province": "Girona",
                    "city": "Bàscara",
                    "multiple": "1"
                },
                {
                    "zipcode": "17484",
                    "province": "Girona",
                    "city": "Figueres",
                    "multiple": "0"
                },
                {
                    "zipcode": "17485",
                    "province": "Girona",
                    "city": "Vila-sacra",
                    "multiple": "0"
                },
                {
                    "zipcode": "17486",
                    "province": "Girona",
                    "city": "Figueres",
                    "multiple": "0"
                },
                {
                    "zipcode": "17486",
                    "province": "Girona",
                    "city": "Castelló d'Empúries",
                    "multiple": "1"
                },
                {
                    "zipcode": "17487",
                    "province": "Girona",
                    "city": "Castelló d'Empúries",
                    "multiple": "0"
                },
                {
                    "zipcode": "17488",
                    "province": "Girona",
                    "city": "Cadaqués",
                    "multiple": "0"
                },
                {
                    "zipcode": "17489",
                    "province": "Girona",
                    "city": "Port de la Selva, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "17489",
                    "province": "Girona",
                    "city": "Selva de Mar, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "17490",
                    "province": "Girona",
                    "city": "Llançà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17491",
                    "province": "Girona",
                    "city": "Peralada",
                    "multiple": "0"
                },
                {
                    "zipcode": "17492",
                    "province": "Girona",
                    "city": "Peralada",
                    "multiple": "0"
                },
                {
                    "zipcode": "17493",
                    "province": "Girona",
                    "city": "Vilajuïga",
                    "multiple": "0"
                },
                {
                    "zipcode": "17493",
                    "province": "Girona",
                    "city": "Pedret i Marzà",
                    "multiple": "1"
                },
                {
                    "zipcode": "17494",
                    "province": "Girona",
                    "city": "Pau",
                    "multiple": "0"
                },
                {
                    "zipcode": "17495",
                    "province": "Girona",
                    "city": "Palau-saverdera",
                    "multiple": "0"
                },
                {
                    "zipcode": "17496",
                    "province": "Girona",
                    "city": "Colera",
                    "multiple": "0"
                },
                {
                    "zipcode": "17497",
                    "province": "Girona",
                    "city": "Portbou",
                    "multiple": "0"
                },
                {
                    "zipcode": "17500",
                    "province": "Girona",
                    "city": "Ripoll",
                    "multiple": "0"
                },
                {
                    "zipcode": "17512",
                    "province": "Girona",
                    "city": "Llosses, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "17513",
                    "province": "Girona",
                    "city": "Llosses, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "17515",
                    "province": "Girona",
                    "city": "Vidrà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17520",
                    "province": "Girona",
                    "city": "Puigcerdà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17527",
                    "province": "Girona",
                    "city": "Llívia",
                    "multiple": "0"
                },
                {
                    "zipcode": "17528",
                    "province": "Girona",
                    "city": "Guils de Cerdanya",
                    "multiple": "0"
                },
                {
                    "zipcode": "17529",
                    "province": "Girona",
                    "city": "Puigcerdà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17530",
                    "province": "Girona",
                    "city": "Campdevànol",
                    "multiple": "0"
                },
                {
                    "zipcode": "17531",
                    "province": "Girona",
                    "city": "Gombrèn",
                    "multiple": "0"
                },
                {
                    "zipcode": "17531",
                    "province": "Girona",
                    "city": "Campdevànol",
                    "multiple": "1"
                },
                {
                    "zipcode": "17532",
                    "province": "Girona",
                    "city": "Montesquiu",
                    "multiple": "0"
                },
                {
                    "zipcode": "17532",
                    "province": "Girona",
                    "city": "Llosses, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "17534",
                    "province": "Girona",
                    "city": "Campelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "17534",
                    "province": "Girona",
                    "city": "Queralbs",
                    "multiple": "1"
                },
                {
                    "zipcode": "17534",
                    "province": "Girona",
                    "city": "Ribes de Freser",
                    "multiple": "1"
                },
                {
                    "zipcode": "17534",
                    "province": "Girona",
                    "city": "Pardines",
                    "multiple": "1"
                },
                {
                    "zipcode": "17535",
                    "province": "Girona",
                    "city": "Planoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "17536",
                    "province": "Girona",
                    "city": "Toses",
                    "multiple": "0"
                },
                {
                    "zipcode": "17537",
                    "province": "Girona",
                    "city": "Alp",
                    "multiple": "0"
                },
                {
                    "zipcode": "17538",
                    "province": "Girona",
                    "city": "Urús",
                    "multiple": "0"
                },
                {
                    "zipcode": "17538",
                    "province": "Girona",
                    "city": "Alp",
                    "multiple": "1"
                },
                {
                    "zipcode": "17538",
                    "province": "Girona",
                    "city": "Fontanals de Cerdanya",
                    "multiple": "1"
                },
                {
                    "zipcode": "17538",
                    "province": "Girona",
                    "city": "Das",
                    "multiple": "1"
                },
                {
                    "zipcode": "17539",
                    "province": "Girona",
                    "city": "Ger",
                    "multiple": "0"
                },
                {
                    "zipcode": "17539",
                    "province": "Girona",
                    "city": "Meranges",
                    "multiple": "1"
                },
                {
                    "zipcode": "17539",
                    "province": "Girona",
                    "city": "Isòvol",
                    "multiple": "1"
                },
                {
                    "zipcode": "17539",
                    "province": "Girona",
                    "city": "Bolvir",
                    "multiple": "1"
                },
                {
                    "zipcode": "17600",
                    "province": "Girona",
                    "city": "Figueres",
                    "multiple": "0"
                },
                {
                    "zipcode": "17700",
                    "province": "Girona",
                    "city": "Borrassà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17700",
                    "province": "Girona",
                    "city": "Jonquera, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "17706",
                    "province": "Girona",
                    "city": "Pont de Molins",
                    "multiple": "0"
                },
                {
                    "zipcode": "17707",
                    "province": "Girona",
                    "city": "Agullana",
                    "multiple": "0"
                },
                {
                    "zipcode": "17707",
                    "province": "Girona",
                    "city": "Vajol, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "17708",
                    "province": "Girona",
                    "city": "Cantallops",
                    "multiple": "0"
                },
                {
                    "zipcode": "17709",
                    "province": "Girona",
                    "city": "Jonquera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17720",
                    "province": "Girona",
                    "city": "Maçanet de Cabrenys",
                    "multiple": "0"
                },
                {
                    "zipcode": "17721",
                    "province": "Girona",
                    "city": "Maçanet de Cabrenys",
                    "multiple": "0"
                },
                {
                    "zipcode": "17722",
                    "province": "Girona",
                    "city": "Darnius",
                    "multiple": "0"
                },
                {
                    "zipcode": "17723",
                    "province": "Girona",
                    "city": "Boadella i les Escaules",
                    "multiple": "0"
                },
                {
                    "zipcode": "17723",
                    "province": "Girona",
                    "city": "Biure",
                    "multiple": "1"
                },
                {
                    "zipcode": "17730",
                    "province": "Girona",
                    "city": "Llers",
                    "multiple": "0"
                },
                {
                    "zipcode": "17731",
                    "province": "Girona",
                    "city": "Terrades",
                    "multiple": "0"
                },
                {
                    "zipcode": "17732",
                    "province": "Girona",
                    "city": "Sant Llorenç de la Muga",
                    "multiple": "0"
                },
                {
                    "zipcode": "17733",
                    "province": "Girona",
                    "city": "Albanyà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17734",
                    "province": "Girona",
                    "city": "Albanyà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17740",
                    "province": "Girona",
                    "city": "Vilafant",
                    "multiple": "0"
                },
                {
                    "zipcode": "17741",
                    "province": "Girona",
                    "city": "Vilanant",
                    "multiple": "0"
                },
                {
                    "zipcode": "17741",
                    "province": "Girona",
                    "city": "Cistella",
                    "multiple": "1"
                },
                {
                    "zipcode": "17742",
                    "province": "Girona",
                    "city": "Avinyonet de Puigventós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17743",
                    "province": "Girona",
                    "city": "Vilanant",
                    "multiple": "0"
                },
                {
                    "zipcode": "17744",
                    "province": "Girona",
                    "city": "Navata",
                    "multiple": "0"
                },
                {
                    "zipcode": "17745",
                    "province": "Girona",
                    "city": "Lladó",
                    "multiple": "0"
                },
                {
                    "zipcode": "17746",
                    "province": "Girona",
                    "city": "Cabanelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "17747",
                    "province": "Girona",
                    "city": "Cabanelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "17750",
                    "province": "Girona",
                    "city": "Capmany",
                    "multiple": "0"
                },
                {
                    "zipcode": "17751",
                    "province": "Girona",
                    "city": "Sant Climent Sescebes",
                    "multiple": "0"
                },
                {
                    "zipcode": "17752",
                    "province": "Girona",
                    "city": "Mollet de Peralada",
                    "multiple": "0"
                },
                {
                    "zipcode": "17753",
                    "province": "Girona",
                    "city": "Espolla",
                    "multiple": "0"
                },
                {
                    "zipcode": "17754",
                    "province": "Girona",
                    "city": "Rabós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17755",
                    "province": "Girona",
                    "city": "Rabós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17760",
                    "province": "Girona",
                    "city": "Vilabertran",
                    "multiple": "0"
                },
                {
                    "zipcode": "17761",
                    "province": "Girona",
                    "city": "Cabanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "17762",
                    "province": "Girona",
                    "city": "Masarac",
                    "multiple": "0"
                },
                {
                    "zipcode": "17763",
                    "province": "Girona",
                    "city": "Masarac",
                    "multiple": "0"
                },
                {
                    "zipcode": "17770",
                    "province": "Girona",
                    "city": "Borrassà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17771",
                    "province": "Girona",
                    "city": "Santa Llogaia d'Àlguema",
                    "multiple": "0"
                },
                {
                    "zipcode": "17772",
                    "province": "Girona",
                    "city": "Ordis",
                    "multiple": "0"
                },
                {
                    "zipcode": "17773",
                    "province": "Girona",
                    "city": "Pontós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17780",
                    "province": "Girona",
                    "city": "Garriguella",
                    "multiple": "0"
                },
                {
                    "zipcode": "17781",
                    "province": "Girona",
                    "city": "Vilamaniscle",
                    "multiple": "0"
                },
                {
                    "zipcode": "17800",
                    "province": "Girona",
                    "city": "Olot",
                    "multiple": "0"
                },
                {
                    "zipcode": "17810",
                    "province": "Girona",
                    "city": "Olot",
                    "multiple": "0"
                },
                {
                    "zipcode": "17811",
                    "province": "Girona",
                    "city": "Santa Pau",
                    "multiple": "0"
                },
                {
                    "zipcode": "17812",
                    "province": "Girona",
                    "city": "Olot",
                    "multiple": "0"
                },
                {
                    "zipcode": "17813",
                    "province": "Girona",
                    "city": "Vall de Bianya, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17820",
                    "province": "Girona",
                    "city": "Banyoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "17830",
                    "province": "Girona",
                    "city": "Sant Ferriol",
                    "multiple": "0"
                },
                {
                    "zipcode": "17830",
                    "province": "Girona",
                    "city": "Mieres",
                    "multiple": "1"
                },
                {
                    "zipcode": "17831",
                    "province": "Girona",
                    "city": "Sant Miquel de Campmajor",
                    "multiple": "0"
                },
                {
                    "zipcode": "17832",
                    "province": "Girona",
                    "city": "Crespià",
                    "multiple": "0"
                },
                {
                    "zipcode": "17832",
                    "province": "Girona",
                    "city": "Esponellà",
                    "multiple": "1"
                },
                {
                    "zipcode": "17833",
                    "province": "Girona",
                    "city": "Fontcoberta",
                    "multiple": "0"
                },
                {
                    "zipcode": "17833",
                    "province": "Girona",
                    "city": "Vilademuls",
                    "multiple": "1"
                },
                {
                    "zipcode": "17834",
                    "province": "Girona",
                    "city": "Camós",
                    "multiple": "0"
                },
                {
                    "zipcode": "17834",
                    "province": "Girona",
                    "city": "Porqueres",
                    "multiple": "1"
                },
                {
                    "zipcode": "17840",
                    "province": "Girona",
                    "city": "Sarrià de Ter",
                    "multiple": "0"
                },
                {
                    "zipcode": "17843",
                    "province": "Girona",
                    "city": "Palol de Revardit",
                    "multiple": "0"
                },
                {
                    "zipcode": "17844",
                    "province": "Girona",
                    "city": "Cornellà del Terri",
                    "multiple": "0"
                },
                {
                    "zipcode": "17846",
                    "province": "Girona",
                    "city": "Porqueres",
                    "multiple": "0"
                },
                {
                    "zipcode": "17850",
                    "province": "Girona",
                    "city": "Sant Ferriol",
                    "multiple": "0"
                },
                {
                    "zipcode": "17850",
                    "province": "Girona",
                    "city": "Besalú",
                    "multiple": "1"
                },
                {
                    "zipcode": "17850",
                    "province": "Girona",
                    "city": "Beuda",
                    "multiple": "1"
                },
                {
                    "zipcode": "17851",
                    "province": "Girona",
                    "city": "Beuda",
                    "multiple": "0"
                },
                {
                    "zipcode": "17851",
                    "province": "Girona",
                    "city": "Maià de Montcal",
                    "multiple": "1"
                },
                {
                    "zipcode": "17852",
                    "province": "Girona",
                    "city": "Serinyà",
                    "multiple": "0"
                },
                {
                    "zipcode": "17853",
                    "province": "Girona",
                    "city": "Argelaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "17853",
                    "province": "Girona",
                    "city": "Tortellà",
                    "multiple": "1"
                },
                {
                    "zipcode": "17853",
                    "province": "Girona",
                    "city": "Sales de Llierca",
                    "multiple": "1"
                },
                {
                    "zipcode": "17854",
                    "province": "Girona",
                    "city": "Sant Jaume de Llierca",
                    "multiple": "0"
                },
                {
                    "zipcode": "17854",
                    "province": "Girona",
                    "city": "Camós",
                    "multiple": "1"
                },
                {
                    "zipcode": "17855",
                    "province": "Girona",
                    "city": "Montagut i Oix",
                    "multiple": "0"
                },
                {
                    "zipcode": "17856",
                    "province": "Girona",
                    "city": "Castellfollit de la Roca",
                    "multiple": "0"
                },
                {
                    "zipcode": "17856",
                    "province": "Girona",
                    "city": "Montagut i Oix",
                    "multiple": "1"
                },
                {
                    "zipcode": "17857",
                    "province": "Girona",
                    "city": "Sant Joan les Fonts",
                    "multiple": "0"
                },
                {
                    "zipcode": "17858",
                    "province": "Girona",
                    "city": "Vall de Bianya, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "17860",
                    "province": "Girona",
                    "city": "Sant Joan de les Abadesses",
                    "multiple": "0"
                },
                {
                    "zipcode": "17861",
                    "province": "Girona",
                    "city": "Ogassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "17862",
                    "province": "Girona",
                    "city": "Vallfogona de Ripollès",
                    "multiple": "0"
                },
                {
                    "zipcode": "17864",
                    "province": "Girona",
                    "city": "Sant Pau de Segúries",
                    "multiple": "0"
                },
                {
                    "zipcode": "17865",
                    "province": "Girona",
                    "city": "Sant Pau de Segúries",
                    "multiple": "0"
                },
                {
                    "zipcode": "17867",
                    "province": "Girona",
                    "city": "Camprodon",
                    "multiple": "0"
                },
                {
                    "zipcode": "17868",
                    "province": "Girona",
                    "city": "Molló",
                    "multiple": "0"
                },
                {
                    "zipcode": "17869",
                    "province": "Girona",
                    "city": "Vilallonga de Ter",
                    "multiple": "0"
                },
                {
                    "zipcode": "17869",
                    "province": "Girona",
                    "city": "Llanars",
                    "multiple": "1"
                },
                {
                    "zipcode": "17869",
                    "province": "Girona",
                    "city": "Setcases",
                    "multiple": "1"
                },
                {
                    "zipcode": "18001",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18002",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18003",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18004",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18005",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18006",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18007",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18008",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18009",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18010",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18011",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18012",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18013",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18014",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18015",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18016",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18100",
                    "province": "Granada",
                    "city": "Armilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "18101",
                    "province": "Granada",
                    "city": "Vegas del Genil",
                    "multiple": "0"
                },
                {
                    "zipcode": "18102",
                    "province": "Granada",
                    "city": "Vegas del Genil",
                    "multiple": "0"
                },
                {
                    "zipcode": "18110",
                    "province": "Granada",
                    "city": "Gabias, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "18120",
                    "province": "Granada",
                    "city": "Alhama de Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18126",
                    "province": "Granada",
                    "city": "Arenas del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "18127",
                    "province": "Granada",
                    "city": "Játar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18127",
                    "province": "Granada",
                    "city": "Jayena",
                    "multiple": "1"
                },
                {
                    "zipcode": "18127",
                    "province": "Granada",
                    "city": "Arenas del Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "18128",
                    "province": "Granada",
                    "city": "Alhama de Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18128",
                    "province": "Granada",
                    "city": "Zafarraya",
                    "multiple": "1"
                },
                {
                    "zipcode": "18129",
                    "province": "Granada",
                    "city": "Santa Cruz del Comercio",
                    "multiple": "0"
                },
                {
                    "zipcode": "18129",
                    "province": "Granada",
                    "city": "Arenas del Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "18129",
                    "province": "Granada",
                    "city": "Cacín",
                    "multiple": "1"
                },
                {
                    "zipcode": "18130",
                    "province": "Granada",
                    "city": "Escúzar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18130",
                    "province": "Granada",
                    "city": "Malahá, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "18131",
                    "province": "Granada",
                    "city": "Ventas de Huelma",
                    "multiple": "0"
                },
                {
                    "zipcode": "18132",
                    "province": "Granada",
                    "city": "Agrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "18140",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "0"
                },
                {
                    "zipcode": "18140",
                    "province": "Granada",
                    "city": "Zubia, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "18140",
                    "province": "Granada",
                    "city": "Horche",
                    "multiple": "1"
                },
                {
                    "zipcode": "18150",
                    "province": "Granada",
                    "city": "Padul",
                    "multiple": "0"
                },
                {
                    "zipcode": "18150",
                    "province": "Granada",
                    "city": "Gójar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18151",
                    "province": "Granada",
                    "city": "Ogíjares",
                    "multiple": "0"
                },
                {
                    "zipcode": "18152",
                    "province": "Granada",
                    "city": "Dílar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18160",
                    "province": "Granada",
                    "city": "Güéjar Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "18170",
                    "province": "Granada",
                    "city": "Alfacar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18179",
                    "province": "Granada",
                    "city": "Alfacar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18179",
                    "province": "Granada",
                    "city": "Víznar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18180",
                    "province": "Granada",
                    "city": "Diezma",
                    "multiple": "0"
                },
                {
                    "zipcode": "18181",
                    "province": "Granada",
                    "city": "Peza, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "18181",
                    "province": "Granada",
                    "city": "Darro",
                    "multiple": "1"
                },
                {
                    "zipcode": "18182",
                    "province": "Granada",
                    "city": "Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18183",
                    "province": "Granada",
                    "city": "Huétor de Santillán",
                    "multiple": "0"
                },
                {
                    "zipcode": "18184",
                    "province": "Granada",
                    "city": "Beas de Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18185",
                    "province": "Granada",
                    "city": "Huétor de Santillán",
                    "multiple": "0"
                },
                {
                    "zipcode": "18190",
                    "province": "Granada",
                    "city": "Cenes de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "18191",
                    "province": "Granada",
                    "city": "Pinos Genil",
                    "multiple": "0"
                },
                {
                    "zipcode": "18191",
                    "province": "Granada",
                    "city": "Güéjar Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "18192",
                    "province": "Granada",
                    "city": "Quéntar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18192",
                    "province": "Granada",
                    "city": "Dúdar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18193",
                    "province": "Granada",
                    "city": "Monachil",
                    "multiple": "0"
                },
                {
                    "zipcode": "18194",
                    "province": "Granada",
                    "city": "Churriana de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "18195",
                    "province": "Granada",
                    "city": "Cúllar Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "18196",
                    "province": "Granada",
                    "city": "Monachil",
                    "multiple": "0"
                },
                {
                    "zipcode": "18197",
                    "province": "Granada",
                    "city": "Pulianas",
                    "multiple": "0"
                },
                {
                    "zipcode": "18198",
                    "province": "Granada",
                    "city": "Huétor Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "18199",
                    "province": "Granada",
                    "city": "Cájar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18200",
                    "province": "Granada",
                    "city": "Maracena",
                    "multiple": "0"
                },
                {
                    "zipcode": "18210",
                    "province": "Granada",
                    "city": "Peligros",
                    "multiple": "0"
                },
                {
                    "zipcode": "18211",
                    "province": "Granada",
                    "city": "Cogollos de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "18212",
                    "province": "Granada",
                    "city": "Güevéjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18213",
                    "province": "Granada",
                    "city": "Jun",
                    "multiple": "0"
                },
                {
                    "zipcode": "18214",
                    "province": "Granada",
                    "city": "Nívar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18220",
                    "province": "Granada",
                    "city": "Albolote",
                    "multiple": "0"
                },
                {
                    "zipcode": "18230",
                    "province": "Granada",
                    "city": "Atarfe",
                    "multiple": "0"
                },
                {
                    "zipcode": "18240",
                    "province": "Granada",
                    "city": "Pinos Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "18247",
                    "province": "Granada",
                    "city": "Moclín",
                    "multiple": "0"
                },
                {
                    "zipcode": "18248",
                    "province": "Granada",
                    "city": "Moclín",
                    "multiple": "0"
                },
                {
                    "zipcode": "18249",
                    "province": "Granada",
                    "city": "Moclín",
                    "multiple": "0"
                },
                {
                    "zipcode": "18250",
                    "province": "Granada",
                    "city": "Pinos Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "18250",
                    "province": "Granada",
                    "city": "Valderrubio",
                    "multiple": "1"
                },
                {
                    "zipcode": "18260",
                    "province": "Granada",
                    "city": "Illora",
                    "multiple": "0"
                },
                {
                    "zipcode": "18270",
                    "province": "Granada",
                    "city": "Montefrío",
                    "multiple": "0"
                },
                {
                    "zipcode": "18280",
                    "province": "Granada",
                    "city": "Algarinejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "18290",
                    "province": "Granada",
                    "city": "Calicasas",
                    "multiple": "0"
                },
                {
                    "zipcode": "18290",
                    "province": "Granada",
                    "city": "Albolote",
                    "multiple": "1"
                },
                {
                    "zipcode": "18290",
                    "province": "Granada",
                    "city": "Atarfe",
                    "multiple": "1"
                },
                {
                    "zipcode": "18291",
                    "province": "Granada",
                    "city": "Pinos Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "18293",
                    "province": "Granada",
                    "city": "Illora",
                    "multiple": "0"
                },
                {
                    "zipcode": "18294",
                    "province": "Granada",
                    "city": "Illora",
                    "multiple": "0"
                },
                {
                    "zipcode": "18295",
                    "province": "Granada",
                    "city": "Algarinejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "18300",
                    "province": "Granada",
                    "city": "Loja",
                    "multiple": "0"
                },
                {
                    "zipcode": "18310",
                    "province": "Granada",
                    "city": "Salar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18311",
                    "province": "Granada",
                    "city": "Zagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "18311",
                    "province": "Granada",
                    "city": "Loja",
                    "multiple": "1"
                },
                {
                    "zipcode": "18312",
                    "province": "Córdoba",
                    "city": "Loja",
                    "multiple": "0"
                },
                {
                    "zipcode": "18312",
                    "province": "Córdoba",
                    "city": "Iznájar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18313",
                    "province": "Granada",
                    "city": "Loja",
                    "multiple": "0"
                },
                {
                    "zipcode": "18314",
                    "province": "Granada",
                    "city": "Loja",
                    "multiple": "0"
                },
                {
                    "zipcode": "18320",
                    "province": "Granada",
                    "city": "Santa Fe",
                    "multiple": "0"
                },
                {
                    "zipcode": "18327",
                    "province": "Granada",
                    "city": "Láchar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18328",
                    "province": "Granada",
                    "city": "Láchar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18328",
                    "province": "Granada",
                    "city": "Pinos Puente",
                    "multiple": "1"
                },
                {
                    "zipcode": "18329",
                    "province": "Granada",
                    "city": "Chimeneas",
                    "multiple": "0"
                },
                {
                    "zipcode": "18329",
                    "province": "Granada",
                    "city": "Santa Fe",
                    "multiple": "1"
                },
                {
                    "zipcode": "18330",
                    "province": "Granada",
                    "city": "Chauchina",
                    "multiple": "0"
                },
                {
                    "zipcode": "18330",
                    "province": "Granada",
                    "city": "Santa Fe",
                    "multiple": "1"
                },
                {
                    "zipcode": "18330",
                    "province": "Granada",
                    "city": "Atarfe",
                    "multiple": "1"
                },
                {
                    "zipcode": "18339",
                    "province": "Granada",
                    "city": "Cijuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "18339",
                    "province": "Granada",
                    "city": "Chauchina",
                    "multiple": "1"
                },
                {
                    "zipcode": "18340",
                    "province": "Granada",
                    "city": "Fuente Vaqueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "18350",
                    "province": "Granada",
                    "city": "Illora",
                    "multiple": "0"
                },
                {
                    "zipcode": "18360",
                    "province": "Granada",
                    "city": "Huétor Tájar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18360",
                    "province": "Granada",
                    "city": "Loja",
                    "multiple": "1"
                },
                {
                    "zipcode": "18369",
                    "province": "Granada",
                    "city": "Villanueva Mesía",
                    "multiple": "0"
                },
                {
                    "zipcode": "18370",
                    "province": "Granada",
                    "city": "Moraleda de Zafayona",
                    "multiple": "0"
                },
                {
                    "zipcode": "18380",
                    "province": "Granada",
                    "city": "Illora",
                    "multiple": "0"
                },
                {
                    "zipcode": "18381",
                    "province": "Granada",
                    "city": "Illora",
                    "multiple": "0"
                },
                {
                    "zipcode": "18400",
                    "province": "Granada",
                    "city": "Órgiva",
                    "multiple": "0"
                },
                {
                    "zipcode": "18410",
                    "province": "Granada",
                    "city": "Soportújar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18410",
                    "province": "Granada",
                    "city": "Carataunas",
                    "multiple": "1"
                },
                {
                    "zipcode": "18410",
                    "province": "Granada",
                    "city": "Órgiva",
                    "multiple": "1"
                },
                {
                    "zipcode": "18411",
                    "province": "Granada",
                    "city": "Pampaneira",
                    "multiple": "0"
                },
                {
                    "zipcode": "18412",
                    "province": "Granada",
                    "city": "Bubión",
                    "multiple": "0"
                },
                {
                    "zipcode": "18413",
                    "province": "Granada",
                    "city": "Capileira",
                    "multiple": "0"
                },
                {
                    "zipcode": "18414",
                    "province": "Granada",
                    "city": "Taha, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "18415",
                    "province": "Granada",
                    "city": "Pórtugos",
                    "multiple": "0"
                },
                {
                    "zipcode": "18416",
                    "province": "Granada",
                    "city": "Busquístar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18416",
                    "province": "Granada",
                    "city": "Taha, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "18417",
                    "province": "Granada",
                    "city": "Trevélez",
                    "multiple": "0"
                },
                {
                    "zipcode": "18418",
                    "province": "Granada",
                    "city": "Órgiva",
                    "multiple": "0"
                },
                {
                    "zipcode": "18418",
                    "province": "Granada",
                    "city": "Cáñar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18418",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "1"
                },
                {
                    "zipcode": "18420",
                    "province": "Granada",
                    "city": "Alpujarra de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "18420",
                    "province": "Granada",
                    "city": "Lanjarón",
                    "multiple": "1"
                },
                {
                    "zipcode": "18430",
                    "province": "Granada",
                    "city": "Torvizcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "18438",
                    "province": "Granada",
                    "city": "Almegíjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18439",
                    "province": "Granada",
                    "city": "Cástaras",
                    "multiple": "0"
                },
                {
                    "zipcode": "18440",
                    "province": "Granada",
                    "city": "Cádiar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18448",
                    "province": "Granada",
                    "city": "Cádiar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18449",
                    "province": "Granada",
                    "city": "Lobras",
                    "multiple": "0"
                },
                {
                    "zipcode": "18450",
                    "province": "Granada",
                    "city": "Alpujarra de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "18451",
                    "province": "Granada",
                    "city": "Bérchules",
                    "multiple": "0"
                },
                {
                    "zipcode": "18452",
                    "province": "Granada",
                    "city": "Juviles",
                    "multiple": "0"
                },
                {
                    "zipcode": "18460",
                    "province": "Granada",
                    "city": "Alpujarra de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "18470",
                    "province": "Granada",
                    "city": "Válor",
                    "multiple": "0"
                },
                {
                    "zipcode": "18480",
                    "province": "Granada",
                    "city": "Ugíjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18490",
                    "province": "Granada",
                    "city": "Murtas",
                    "multiple": "0"
                },
                {
                    "zipcode": "18491",
                    "province": "Granada",
                    "city": "Turón",
                    "multiple": "0"
                },
                {
                    "zipcode": "18492",
                    "province": "Granada",
                    "city": "Ugíjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18492",
                    "province": "Granada",
                    "city": "Murtas",
                    "multiple": "1"
                },
                {
                    "zipcode": "18493",
                    "province": "Granada",
                    "city": "Nevada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18494",
                    "province": "Granada",
                    "city": "Nevada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18494",
                    "province": "Granada",
                    "city": "Ugíjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18500",
                    "province": "Granada",
                    "city": "Guadix",
                    "multiple": "0"
                },
                {
                    "zipcode": "18510",
                    "province": "Granada",
                    "city": "Benalúa",
                    "multiple": "0"
                },
                {
                    "zipcode": "18511",
                    "province": "Granada",
                    "city": "Valle del Zalabí",
                    "multiple": "0"
                },
                {
                    "zipcode": "18512",
                    "province": "Granada",
                    "city": "Calahorra, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "18512",
                    "province": "Granada",
                    "city": "Dólar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18512",
                    "province": "Granada",
                    "city": "Huéneja",
                    "multiple": "1"
                },
                {
                    "zipcode": "18513",
                    "province": "Granada",
                    "city": "Ferreira",
                    "multiple": "0"
                },
                {
                    "zipcode": "18514",
                    "province": "Granada",
                    "city": "Aldeire",
                    "multiple": "0"
                },
                {
                    "zipcode": "18515",
                    "province": "Granada",
                    "city": "Guadix",
                    "multiple": "0"
                },
                {
                    "zipcode": "18515",
                    "province": "Granada",
                    "city": "Fonelas",
                    "multiple": "1"
                },
                {
                    "zipcode": "18515",
                    "province": "Granada",
                    "city": "Purullena",
                    "multiple": "1"
                },
                {
                    "zipcode": "18516",
                    "province": "Granada",
                    "city": "Polícar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18516",
                    "province": "Granada",
                    "city": "Lugros",
                    "multiple": "1"
                },
                {
                    "zipcode": "18516",
                    "province": "Granada",
                    "city": "Marchal",
                    "multiple": "1"
                },
                {
                    "zipcode": "18516",
                    "province": "Granada",
                    "city": "Beas de Guadix",
                    "multiple": "1"
                },
                {
                    "zipcode": "18517",
                    "province": "Granada",
                    "city": "Quéntar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18517",
                    "province": "Granada",
                    "city": "Peza, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "18517",
                    "province": "Granada",
                    "city": "Cortes y Graena",
                    "multiple": "1"
                },
                {
                    "zipcode": "18518",
                    "province": "Granada",
                    "city": "Cogollos de Guadix",
                    "multiple": "0"
                },
                {
                    "zipcode": "18518",
                    "province": "Granada",
                    "city": "Albuñol",
                    "multiple": "1"
                },
                {
                    "zipcode": "18518",
                    "province": "Granada",
                    "city": "Lanteira",
                    "multiple": "1"
                },
                {
                    "zipcode": "18518",
                    "province": "Granada",
                    "city": "Alquife",
                    "multiple": "1"
                },
                {
                    "zipcode": "18518",
                    "province": "Granada",
                    "city": "Jerez del Marquesado",
                    "multiple": "1"
                },
                {
                    "zipcode": "18518",
                    "province": "Granada",
                    "city": "Albuñán",
                    "multiple": "1"
                },
                {
                    "zipcode": "18519",
                    "province": "Granada",
                    "city": "Guadix",
                    "multiple": "0"
                },
                {
                    "zipcode": "18519",
                    "province": "Granada",
                    "city": "Purullena",
                    "multiple": "1"
                },
                {
                    "zipcode": "18520",
                    "province": "Granada",
                    "city": "Alamedilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "18530",
                    "province": "Granada",
                    "city": "Pedro Martínez",
                    "multiple": "0"
                },
                {
                    "zipcode": "18538",
                    "province": "Granada",
                    "city": "Dehesas de Guadix",
                    "multiple": "0"
                },
                {
                    "zipcode": "18538",
                    "province": "Granada",
                    "city": "Alicún de Ortega",
                    "multiple": "1"
                },
                {
                    "zipcode": "18539",
                    "province": "Granada",
                    "city": "Villanueva de las Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "18540",
                    "province": "Granada",
                    "city": "Alpujarra de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "18540",
                    "province": "Granada",
                    "city": "Morelábor",
                    "multiple": "1"
                },
                {
                    "zipcode": "18540",
                    "province": "Granada",
                    "city": "Gobernador",
                    "multiple": "1"
                },
                {
                    "zipcode": "18540",
                    "province": "Granada",
                    "city": "Huélago",
                    "multiple": "1"
                },
                {
                    "zipcode": "18550",
                    "province": "Granada",
                    "city": "Domingo Pérez de Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18550",
                    "province": "Granada",
                    "city": "Iznalloz",
                    "multiple": "1"
                },
                {
                    "zipcode": "18560",
                    "province": "Granada",
                    "city": "Guadahortuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "18561",
                    "province": "Granada",
                    "city": "Montejícar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18562",
                    "province": "Granada",
                    "city": "Píñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18563",
                    "province": "Granada",
                    "city": "Gobernador",
                    "multiple": "0"
                },
                {
                    "zipcode": "18563",
                    "province": "Granada",
                    "city": "Torre-Cardela",
                    "multiple": "1"
                },
                {
                    "zipcode": "18564",
                    "province": "Granada",
                    "city": "Colomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "18565",
                    "province": "Granada",
                    "city": "Campotéjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18566",
                    "province": "Granada",
                    "city": "Benalúa de las Villas",
                    "multiple": "0"
                },
                {
                    "zipcode": "18567",
                    "province": "Granada",
                    "city": "Domingo Pérez de Granada",
                    "multiple": "0"
                },
                {
                    "zipcode": "18567",
                    "province": "Granada",
                    "city": "Iznalloz",
                    "multiple": "1"
                },
                {
                    "zipcode": "18567",
                    "province": "Granada",
                    "city": "Dehesas Viejas",
                    "multiple": "1"
                },
                {
                    "zipcode": "18568",
                    "province": "Granada",
                    "city": "Píñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18569",
                    "province": "Granada",
                    "city": "Moclín",
                    "multiple": "0"
                },
                {
                    "zipcode": "18569",
                    "province": "Granada",
                    "city": "Montillana",
                    "multiple": "1"
                },
                {
                    "zipcode": "18570",
                    "province": "Granada",
                    "city": "Deifontes",
                    "multiple": "0"
                },
                {
                    "zipcode": "18600",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "0"
                },
                {
                    "zipcode": "18610",
                    "province": "Granada",
                    "city": "Salobreña",
                    "multiple": "0"
                },
                {
                    "zipcode": "18611",
                    "province": "Granada",
                    "city": "Molvízar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18612",
                    "province": "Granada",
                    "city": "Ítrabo",
                    "multiple": "0"
                },
                {
                    "zipcode": "18613",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "0"
                },
                {
                    "zipcode": "18614",
                    "province": "Granada",
                    "city": "Lújar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18614",
                    "province": "Granada",
                    "city": "Gualchos",
                    "multiple": "1"
                },
                {
                    "zipcode": "18614",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "1"
                },
                {
                    "zipcode": "18615",
                    "province": "Granada",
                    "city": "Vélez de Benaudalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "18615",
                    "province": "Granada",
                    "city": "Guájares, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "18616",
                    "province": "Granada",
                    "city": "Vélez de Benaudalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "18620",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "0"
                },
                {
                    "zipcode": "18620",
                    "province": "Granada",
                    "city": "Alhendín",
                    "multiple": "1"
                },
                {
                    "zipcode": "18630",
                    "province": "Granada",
                    "city": "Villa de Otura",
                    "multiple": "0"
                },
                {
                    "zipcode": "18640",
                    "province": "Granada",
                    "city": "Padul",
                    "multiple": "0"
                },
                {
                    "zipcode": "18650",
                    "province": "Granada",
                    "city": "Dúrcal",
                    "multiple": "0"
                },
                {
                    "zipcode": "18656",
                    "province": "Granada",
                    "city": "Lecrín",
                    "multiple": "0"
                },
                {
                    "zipcode": "18657",
                    "province": "Granada",
                    "city": "Nigüelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "18658",
                    "province": "Granada",
                    "city": "Valle, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "18658",
                    "province": "Granada",
                    "city": "Pinar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "18659",
                    "province": "Granada",
                    "city": "Albuñuelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "18659",
                    "province": "Granada",
                    "city": "Villamena",
                    "multiple": "1"
                },
                {
                    "zipcode": "18660",
                    "province": "Granada",
                    "city": "Pinar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "18670",
                    "province": "Granada",
                    "city": "Vélez de Benaudalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "18680",
                    "province": "Granada",
                    "city": "Dúrcal",
                    "multiple": "0"
                },
                {
                    "zipcode": "18680",
                    "province": "Granada",
                    "city": "Salobreña",
                    "multiple": "1"
                },
                {
                    "zipcode": "18690",
                    "province": "Granada",
                    "city": "Almuñécar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18690",
                    "province": "Granada",
                    "city": "Salobreña",
                    "multiple": "1"
                },
                {
                    "zipcode": "18697",
                    "province": "Granada",
                    "city": "Almuñécar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18698",
                    "province": "Granada",
                    "city": "Otívar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18699",
                    "province": "Granada",
                    "city": "Jete",
                    "multiple": "0"
                },
                {
                    "zipcode": "18699",
                    "province": "Granada",
                    "city": "Lentegí",
                    "multiple": "1"
                },
                {
                    "zipcode": "18700",
                    "province": "Granada",
                    "city": "Albuñol",
                    "multiple": "0"
                },
                {
                    "zipcode": "18708",
                    "province": "Granada",
                    "city": "Albondón",
                    "multiple": "0"
                },
                {
                    "zipcode": "18710",
                    "province": "Granada",
                    "city": "Polopos",
                    "multiple": "0"
                },
                {
                    "zipcode": "18710",
                    "province": "Granada",
                    "city": "Órgiva",
                    "multiple": "1"
                },
                {
                    "zipcode": "18710",
                    "province": "Granada",
                    "city": "Sorvilán",
                    "multiple": "1"
                },
                {
                    "zipcode": "18711",
                    "province": "Granada",
                    "city": "Rubite",
                    "multiple": "0"
                },
                {
                    "zipcode": "18713",
                    "province": "Granada",
                    "city": "Sorvilán",
                    "multiple": "0"
                },
                {
                    "zipcode": "18720",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "0"
                },
                {
                    "zipcode": "18730",
                    "province": "Granada",
                    "city": "Motril",
                    "multiple": "0"
                },
                {
                    "zipcode": "18740",
                    "province": "Granada",
                    "city": "Gualchos",
                    "multiple": "0"
                },
                {
                    "zipcode": "18750",
                    "province": "Granada",
                    "city": "Sorvilán",
                    "multiple": "0"
                },
                {
                    "zipcode": "18750",
                    "province": "Granada",
                    "city": "Polopos",
                    "multiple": "1"
                },
                {
                    "zipcode": "18760",
                    "province": "Granada",
                    "city": "Albuñol",
                    "multiple": "0"
                },
                {
                    "zipcode": "18770",
                    "province": "Granada",
                    "city": "Albuñol",
                    "multiple": "0"
                },
                {
                    "zipcode": "18800",
                    "province": "Granada",
                    "city": "Baza",
                    "multiple": "0"
                },
                {
                    "zipcode": "18810",
                    "province": "Granada",
                    "city": "Caniles",
                    "multiple": "0"
                },
                {
                    "zipcode": "18811",
                    "province": "Granada",
                    "city": "Zújar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18812",
                    "province": "Granada",
                    "city": "Freila",
                    "multiple": "0"
                },
                {
                    "zipcode": "18813",
                    "province": "Granada",
                    "city": "Cuevas del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "18814",
                    "province": "Granada",
                    "city": "Cortes de Baza",
                    "multiple": "0"
                },
                {
                    "zipcode": "18815",
                    "province": "Granada",
                    "city": "Cortes de Baza",
                    "multiple": "0"
                },
                {
                    "zipcode": "18816",
                    "province": "Granada",
                    "city": "Castril",
                    "multiple": "0"
                },
                {
                    "zipcode": "18817",
                    "province": "Granada",
                    "city": "Benamaurel",
                    "multiple": "0"
                },
                {
                    "zipcode": "18818",
                    "province": "Granada",
                    "city": "Castilléjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18819",
                    "province": "Granada",
                    "city": "Caniles",
                    "multiple": "0"
                },
                {
                    "zipcode": "18820",
                    "province": "Granada",
                    "city": "Puebla de Don Fadrique",
                    "multiple": "0"
                },
                {
                    "zipcode": "18830",
                    "province": "Granada",
                    "city": "Illora",
                    "multiple": "0"
                },
                {
                    "zipcode": "18830",
                    "province": "Granada",
                    "city": "Huéscar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18840",
                    "province": "Granada",
                    "city": "Galera",
                    "multiple": "0"
                },
                {
                    "zipcode": "18849",
                    "province": "Granada",
                    "city": "Galera",
                    "multiple": "0"
                },
                {
                    "zipcode": "18850",
                    "province": "Granada",
                    "city": "Cúllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18850",
                    "province": "Granada",
                    "city": "Huéscar",
                    "multiple": "1"
                },
                {
                    "zipcode": "18857",
                    "province": "Granada",
                    "city": "Cúllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18858",
                    "province": "Granada",
                    "city": "Orce",
                    "multiple": "0"
                },
                {
                    "zipcode": "18859",
                    "province": "Granada",
                    "city": "Cúllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "18860",
                    "province": "Granada",
                    "city": "Baza",
                    "multiple": "0"
                },
                {
                    "zipcode": "18860",
                    "province": "Granada",
                    "city": "Guadix",
                    "multiple": "1"
                },
                {
                    "zipcode": "18870",
                    "province": "Granada",
                    "city": "Gor",
                    "multiple": "0"
                },
                {
                    "zipcode": "18880",
                    "province": "Granada",
                    "city": "Guadix",
                    "multiple": "0"
                },
                {
                    "zipcode": "18890",
                    "province": "Granada",
                    "city": "Gorafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "18891",
                    "province": "Granada",
                    "city": "Cúllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19001",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19002",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19003",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19004",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19005",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19080",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19080",
                    "province": "Guadalajara",
                    "city": "Yebes",
                    "multiple": "1"
                },
                {
                    "zipcode": "19100",
                    "province": "Guadalajara",
                    "city": "Pastrana",
                    "multiple": "0"
                },
                {
                    "zipcode": "19110",
                    "province": "Guadalajara",
                    "city": "Mondéjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19111",
                    "province": "Guadalajara",
                    "city": "Yebra",
                    "multiple": "0"
                },
                {
                    "zipcode": "19112",
                    "province": "Guadalajara",
                    "city": "Pozo de Almoguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "19112",
                    "province": "Guadalajara",
                    "city": "Albares",
                    "multiple": "1"
                },
                {
                    "zipcode": "19113",
                    "province": "Guadalajara",
                    "city": "Fuentenovilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "19114",
                    "province": "Guadalajara",
                    "city": "Mazuecos",
                    "multiple": "0"
                },
                {
                    "zipcode": "19115",
                    "province": "Guadalajara",
                    "city": "Almoguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "19116",
                    "province": "Guadalajara",
                    "city": "Driebes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19117",
                    "province": "Guadalajara",
                    "city": "Albalate de Zorita",
                    "multiple": "0"
                },
                {
                    "zipcode": "19118",
                    "province": "Guadalajara",
                    "city": "Almonacid de Zorita",
                    "multiple": "0"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Almonacid de Zorita",
                    "multiple": "0"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Albalate de Zorita",
                    "multiple": "1"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Escopete",
                    "multiple": "1"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Illana",
                    "multiple": "1"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Sayatón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Hueva",
                    "multiple": "1"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Hontoba",
                    "multiple": "1"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Escariche",
                    "multiple": "1"
                },
                {
                    "zipcode": "19119",
                    "province": "Guadalajara",
                    "city": "Zorita de los Canes",
                    "multiple": "1"
                },
                {
                    "zipcode": "19120",
                    "province": "Guadalajara",
                    "city": "Sacedón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19125",
                    "province": "Guadalajara",
                    "city": "Alcocer",
                    "multiple": "0"
                },
                {
                    "zipcode": "19126",
                    "province": "Guadalajara",
                    "city": "Salmerón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19127",
                    "province": "Guadalajara",
                    "city": "Millana",
                    "multiple": "0"
                },
                {
                    "zipcode": "19127",
                    "province": "Guadalajara",
                    "city": "Sacedón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19127",
                    "province": "Guadalajara",
                    "city": "Castilforte",
                    "multiple": "1"
                },
                {
                    "zipcode": "19127",
                    "province": "Guadalajara",
                    "city": "Alarilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19127",
                    "province": "Guadalajara",
                    "city": "Escamilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19128",
                    "province": "Guadalajara",
                    "city": "Mantiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "19128",
                    "province": "Guadalajara",
                    "city": "Sacedón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19128",
                    "province": "Guadalajara",
                    "city": "Pareja",
                    "multiple": "1"
                },
                {
                    "zipcode": "19128",
                    "province": "Guadalajara",
                    "city": "Chillarón del Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "19129",
                    "province": "Guadalajara",
                    "city": "Pareja",
                    "multiple": "0"
                },
                {
                    "zipcode": "19129",
                    "province": "Guadalajara",
                    "city": "Alique",
                    "multiple": "1"
                },
                {
                    "zipcode": "19130",
                    "province": "Guadalajara",
                    "city": "Auñón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19131",
                    "province": "Guadalajara",
                    "city": "Auñón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19132",
                    "province": "Guadalajara",
                    "city": "Valdeconcha",
                    "multiple": "0"
                },
                {
                    "zipcode": "19132",
                    "province": "Guadalajara",
                    "city": "Alhóndiga",
                    "multiple": "1"
                },
                {
                    "zipcode": "19133",
                    "province": "Guadalajara",
                    "city": "Durón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19133",
                    "province": "Guadalajara",
                    "city": "Alocén",
                    "multiple": "1"
                },
                {
                    "zipcode": "19133",
                    "province": "Guadalajara",
                    "city": "Berninches",
                    "multiple": "1"
                },
                {
                    "zipcode": "19133",
                    "province": "Guadalajara",
                    "city": "Olivar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "19133",
                    "province": "Guadalajara",
                    "city": "Budia",
                    "multiple": "1"
                },
                {
                    "zipcode": "19134",
                    "province": "Guadalajara",
                    "city": "Tendilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "19134",
                    "province": "Guadalajara",
                    "city": "Peñalver",
                    "multiple": "1"
                },
                {
                    "zipcode": "19135",
                    "province": "Guadalajara",
                    "city": "Arroyo de las Fraguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19135",
                    "province": "Guadalajara",
                    "city": "Armuña de Tajuña",
                    "multiple": "1"
                },
                {
                    "zipcode": "19139",
                    "province": "Guadalajara",
                    "city": "Yebes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19140",
                    "province": "Guadalajara",
                    "city": "Horche",
                    "multiple": "0"
                },
                {
                    "zipcode": "19141",
                    "province": "Guadalajara",
                    "city": "Aranzueque",
                    "multiple": "0"
                },
                {
                    "zipcode": "19141",
                    "province": "Guadalajara",
                    "city": "Yebes",
                    "multiple": "1"
                },
                {
                    "zipcode": "19141",
                    "province": "Guadalajara",
                    "city": "Loranca de Tajuña",
                    "multiple": "1"
                },
                {
                    "zipcode": "19141",
                    "province": "Guadalajara",
                    "city": "Valdarachas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19142",
                    "province": "Guadalajara",
                    "city": "Lupiana",
                    "multiple": "0"
                },
                {
                    "zipcode": "19142",
                    "province": "Guadalajara",
                    "city": "Valdeavellano",
                    "multiple": "1"
                },
                {
                    "zipcode": "19143",
                    "province": "Guadalajara",
                    "city": "Romanones",
                    "multiple": "0"
                },
                {
                    "zipcode": "19143",
                    "province": "Guadalajara",
                    "city": "Horche",
                    "multiple": "1"
                },
                {
                    "zipcode": "19143",
                    "province": "Guadalajara",
                    "city": "Irueste",
                    "multiple": "1"
                },
                {
                    "zipcode": "19143",
                    "province": "Guadalajara",
                    "city": "Yélamos de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19143",
                    "province": "Guadalajara",
                    "city": "Durón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19143",
                    "province": "Guadalajara",
                    "city": "Yélamos de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "19143",
                    "province": "Guadalajara",
                    "city": "San Andrés del Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "19144",
                    "province": "Guadalajara",
                    "city": "Fuentelviejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19144",
                    "province": "Guadalajara",
                    "city": "Fuentelencina",
                    "multiple": "1"
                },
                {
                    "zipcode": "19144",
                    "province": "Guadalajara",
                    "city": "Moratilla de los Meleros",
                    "multiple": "1"
                },
                {
                    "zipcode": "19145",
                    "province": "Guadalajara",
                    "city": "Renera",
                    "multiple": "0"
                },
                {
                    "zipcode": "19150",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19151",
                    "province": "Guadalajara",
                    "city": "Centenera",
                    "multiple": "0"
                },
                {
                    "zipcode": "19152",
                    "province": "Guadalajara",
                    "city": "Aldeanueva de Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19153",
                    "province": "Guadalajara",
                    "city": "Atanzón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19160",
                    "province": "Guadalajara",
                    "city": "Chiloeches",
                    "multiple": "0"
                },
                {
                    "zipcode": "19161",
                    "province": "Guadalajara",
                    "city": "Pozo de Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19162",
                    "province": "Guadalajara",
                    "city": "Pioz",
                    "multiple": "0"
                },
                {
                    "zipcode": "19170",
                    "province": "Guadalajara",
                    "city": "Casar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "19171",
                    "province": "Guadalajara",
                    "city": "Cabanillas del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19174",
                    "province": "Guadalajara",
                    "city": "Torrejón del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "19174",
                    "province": "Guadalajara",
                    "city": "Galápagos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19174",
                    "province": "Guadalajara",
                    "city": "Valdeaveruelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19180",
                    "province": "Guadalajara",
                    "city": "Marchamalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19181",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19182",
                    "province": "Guadalajara",
                    "city": "Fuentelahiguera de Albatages",
                    "multiple": "0"
                },
                {
                    "zipcode": "19182",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "1"
                },
                {
                    "zipcode": "19184",
                    "province": "Guadalajara",
                    "city": "Villaseca de Uceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "19184",
                    "province": "Guadalajara",
                    "city": "Tortuero",
                    "multiple": "1"
                },
                {
                    "zipcode": "19184",
                    "province": "Guadalajara",
                    "city": "Viñuelas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19184",
                    "province": "Guadalajara",
                    "city": "Casa de Uceda",
                    "multiple": "1"
                },
                {
                    "zipcode": "19184",
                    "province": "Guadalajara",
                    "city": "Valdepeñas de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "19185",
                    "province": "Guadalajara",
                    "city": "Valdeaveruelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19185",
                    "province": "Guadalajara",
                    "city": "Casar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "19185",
                    "province": "Guadalajara",
                    "city": "Valdenuño Fernández",
                    "multiple": "1"
                },
                {
                    "zipcode": "19186",
                    "province": "Guadalajara",
                    "city": "Cubillo de Uceda, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "19187",
                    "province": "Guadalajara",
                    "city": "Uceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "19190",
                    "province": "Guadalajara",
                    "city": "Chiloeches",
                    "multiple": "0"
                },
                {
                    "zipcode": "19190",
                    "province": "Guadalajara",
                    "city": "Torija",
                    "multiple": "1"
                },
                {
                    "zipcode": "19191",
                    "province": "Guadalajara",
                    "city": "Pozo de Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19191",
                    "province": "Guadalajara",
                    "city": "Torija",
                    "multiple": "1"
                },
                {
                    "zipcode": "19192",
                    "province": "Guadalajara",
                    "city": "Gajanejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "19192",
                    "province": "Guadalajara",
                    "city": "Trijueque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19192",
                    "province": "Guadalajara",
                    "city": "Pioz",
                    "multiple": "1"
                },
                {
                    "zipcode": "19193",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19194",
                    "province": "Guadalajara",
                    "city": "Guadalajara",
                    "multiple": "0"
                },
                {
                    "zipcode": "19196",
                    "province": "Guadalajara",
                    "city": "Utande",
                    "multiple": "0"
                },
                {
                    "zipcode": "19196",
                    "province": "Guadalajara",
                    "city": "Valdearenas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19196",
                    "province": "Guadalajara",
                    "city": "Ledanca",
                    "multiple": "1"
                },
                {
                    "zipcode": "19196",
                    "province": "Guadalajara",
                    "city": "Argecilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19196",
                    "province": "Guadalajara",
                    "city": "Muduex",
                    "multiple": "1"
                },
                {
                    "zipcode": "19197",
                    "province": "Guadalajara",
                    "city": "Ciruelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19197",
                    "province": "Guadalajara",
                    "city": "Heras de Ayuso",
                    "multiple": "1"
                },
                {
                    "zipcode": "19197",
                    "province": "Guadalajara",
                    "city": "Cañizar",
                    "multiple": "1"
                },
                {
                    "zipcode": "19197",
                    "province": "Guadalajara",
                    "city": "Torre del Burgo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19198",
                    "province": "Guadalajara",
                    "city": "Tórtola de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "19200",
                    "province": "Guadalajara",
                    "city": "Azuqueca de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "19208",
                    "province": "Guadalajara",
                    "city": "Alovera",
                    "multiple": "0"
                },
                {
                    "zipcode": "19209",
                    "province": "Guadalajara",
                    "city": "Villanueva de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "19209",
                    "province": "Guadalajara",
                    "city": "Quer",
                    "multiple": "1"
                },
                {
                    "zipcode": "19210",
                    "province": "Guadalajara",
                    "city": "Yunquera de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "19219",
                    "province": "Guadalajara",
                    "city": "Villanueva de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "19219",
                    "province": "Guadalajara",
                    "city": "Málaga del Fresno",
                    "multiple": "1"
                },
                {
                    "zipcode": "19219",
                    "province": "Guadalajara",
                    "city": "Malaguilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19220",
                    "province": "Guadalajara",
                    "city": "Humanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19222",
                    "province": "Guadalajara",
                    "city": "Tamajón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19223",
                    "province": "Guadalajara",
                    "city": "Retiendas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19223",
                    "province": "Guadalajara",
                    "city": "Campillo de Ranas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19223",
                    "province": "Guadalajara",
                    "city": "Majaelrayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19224",
                    "province": "Guadalajara",
                    "city": "Tamajón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19224",
                    "province": "Guadalajara",
                    "city": "Campillo de Ranas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19224",
                    "province": "Guadalajara",
                    "city": "Valverde de los Arroyos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19225",
                    "province": "Guadalajara",
                    "city": "Puebla de Valles",
                    "multiple": "0"
                },
                {
                    "zipcode": "19225",
                    "province": "Guadalajara",
                    "city": "Retiendas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19225",
                    "province": "Guadalajara",
                    "city": "Valdesotos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19225",
                    "province": "Guadalajara",
                    "city": "Mierla, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "19226",
                    "province": "Guadalajara",
                    "city": "Humanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19226",
                    "province": "Guadalajara",
                    "city": "Mohernando",
                    "multiple": "1"
                },
                {
                    "zipcode": "19226",
                    "province": "Guadalajara",
                    "city": "Tamajón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19227",
                    "province": "Guadalajara",
                    "city": "Robledillo de Mohernando",
                    "multiple": "0"
                },
                {
                    "zipcode": "19227",
                    "province": "Guadalajara",
                    "city": "Alarilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19227",
                    "province": "Guadalajara",
                    "city": "Taragudo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19227",
                    "province": "Guadalajara",
                    "city": "Matarrubia",
                    "multiple": "1"
                },
                {
                    "zipcode": "19229",
                    "province": "Guadalajara",
                    "city": "Humanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19229",
                    "province": "Guadalajara",
                    "city": "Montarrón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19229",
                    "province": "Guadalajara",
                    "city": "Puebla de Beleña",
                    "multiple": "1"
                },
                {
                    "zipcode": "19229",
                    "province": "Guadalajara",
                    "city": "Cogolludo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19230",
                    "province": "Guadalajara",
                    "city": "Cogolludo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19237",
                    "province": "Guadalajara",
                    "city": "San Andrés del Congosto",
                    "multiple": "0"
                },
                {
                    "zipcode": "19237",
                    "province": "Guadalajara",
                    "city": "Fuencemillán",
                    "multiple": "1"
                },
                {
                    "zipcode": "19237",
                    "province": "Guadalajara",
                    "city": "Zarzuela de Jadraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19237",
                    "province": "Guadalajara",
                    "city": "Arbancón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19237",
                    "province": "Guadalajara",
                    "city": "Cogolludo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19238",
                    "province": "Guadalajara",
                    "city": "Huerce, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "19238",
                    "province": "Guadalajara",
                    "city": "Ordial, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "19239",
                    "province": "Guadalajara",
                    "city": "Monasterio",
                    "multiple": "0"
                },
                {
                    "zipcode": "19239",
                    "province": "Guadalajara",
                    "city": "Cogolludo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19240",
                    "province": "Guadalajara",
                    "city": "Jadraque",
                    "multiple": "0"
                },
                {
                    "zipcode": "19242",
                    "province": "Guadalajara",
                    "city": "Hiendelaencina",
                    "multiple": "0"
                },
                {
                    "zipcode": "19243",
                    "province": "Guadalajara",
                    "city": "Robledo de Corpes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19243",
                    "province": "Guadalajara",
                    "city": "Gascueña de Bornova",
                    "multiple": "1"
                },
                {
                    "zipcode": "19243",
                    "province": "Guadalajara",
                    "city": "Villares de Jadraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19243",
                    "province": "Guadalajara",
                    "city": "Toba, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "19243",
                    "province": "Guadalajara",
                    "city": "Bustares",
                    "multiple": "1"
                },
                {
                    "zipcode": "19243",
                    "province": "Guadalajara",
                    "city": "Congostrina",
                    "multiple": "1"
                },
                {
                    "zipcode": "19243",
                    "province": "Guadalajara",
                    "city": "Prádena de Atienza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19244",
                    "province": "Guadalajara",
                    "city": "Navas de Jadraque, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "19244",
                    "province": "Guadalajara",
                    "city": "Condemios de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "19244",
                    "province": "Guadalajara",
                    "city": "Ordial, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Jirueque",
                    "multiple": "0"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Pálmaces de Jadraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Angón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Rebollosa de Jadraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Santiuste",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Negredo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Torremocha de Jadraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Cendejas de la Torre",
                    "multiple": "1"
                },
                {
                    "zipcode": "19245",
                    "province": "Guadalajara",
                    "city": "Cendejas de Enmedio",
                    "multiple": "1"
                },
                {
                    "zipcode": "19246",
                    "province": "Guadalajara",
                    "city": "Pinilla de Jadraque",
                    "multiple": "0"
                },
                {
                    "zipcode": "19246",
                    "province": "Guadalajara",
                    "city": "Medranda",
                    "multiple": "1"
                },
                {
                    "zipcode": "19246",
                    "province": "Guadalajara",
                    "city": "Jadraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19246",
                    "province": "Guadalajara",
                    "city": "Villanueva de Argecilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19246",
                    "province": "Guadalajara",
                    "city": "Miralrío",
                    "multiple": "1"
                },
                {
                    "zipcode": "19246",
                    "province": "Guadalajara",
                    "city": "Casas de San Galindo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19247",
                    "province": "Guadalajara",
                    "city": "Bujalaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "19247",
                    "province": "Guadalajara",
                    "city": "Espinosa de Henares",
                    "multiple": "1"
                },
                {
                    "zipcode": "19247",
                    "province": "Guadalajara",
                    "city": "Membrillera",
                    "multiple": "1"
                },
                {
                    "zipcode": "19248",
                    "province": "Guadalajara",
                    "city": "Hita",
                    "multiple": "0"
                },
                {
                    "zipcode": "19250",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "19260",
                    "province": "Guadalajara",
                    "city": "Alcolea del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19261",
                    "province": "Guadalajara",
                    "city": "Alcolea del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19261",
                    "province": "Guadalajara",
                    "city": "Luzaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "19262",
                    "province": "Guadalajara",
                    "city": "Estriégana",
                    "multiple": "0"
                },
                {
                    "zipcode": "19262",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19262",
                    "province": "Guadalajara",
                    "city": "Saúca",
                    "multiple": "1"
                },
                {
                    "zipcode": "19263",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "19264",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "19265",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "19266",
                    "province": "Guadalajara",
                    "city": "Olmeda de Jadraque, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "19266",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19267",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "19268",
                    "province": "Guadalajara",
                    "city": "Algora",
                    "multiple": "0"
                },
                {
                    "zipcode": "19268",
                    "province": "Guadalajara",
                    "city": "Mirabueno",
                    "multiple": "1"
                },
                {
                    "zipcode": "19268",
                    "province": "Guadalajara",
                    "city": "Torremocha del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19268",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19269",
                    "province": "Guadalajara",
                    "city": "Sienes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19269",
                    "province": "Guadalajara",
                    "city": "Paredes de Sigüenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19269",
                    "province": "Guadalajara",
                    "city": "Riofrío del Llano",
                    "multiple": "1"
                },
                {
                    "zipcode": "19269",
                    "province": "Guadalajara",
                    "city": "Valdelcubo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19269",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19270",
                    "province": "Guadalajara",
                    "city": "Atienza",
                    "multiple": "0"
                },
                {
                    "zipcode": "19274",
                    "province": "Guadalajara",
                    "city": "Miñosa, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "19274",
                    "province": "Guadalajara",
                    "city": "Cantalojas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19274",
                    "province": "Guadalajara",
                    "city": "Atienza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19275",
                    "province": "Guadalajara",
                    "city": "Galve de Sorbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "19275",
                    "province": "Guadalajara",
                    "city": "Cantalojas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19275",
                    "province": "Guadalajara",
                    "city": "Condemios de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19275",
                    "province": "Guadalajara",
                    "city": "Albendiego",
                    "multiple": "1"
                },
                {
                    "zipcode": "19275",
                    "province": "Guadalajara",
                    "city": "Campisábalos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19275",
                    "province": "Guadalajara",
                    "city": "Somolinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19275",
                    "province": "Guadalajara",
                    "city": "Condemios de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "19276",
                    "province": "Guadalajara",
                    "city": "Hijes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19276",
                    "province": "Guadalajara",
                    "city": "Miedes de Atienza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19276",
                    "province": "Guadalajara",
                    "city": "Romanillos de Atienza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19276",
                    "province": "Guadalajara",
                    "city": "Atienza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19276",
                    "province": "Guadalajara",
                    "city": "Ujados",
                    "multiple": "1"
                },
                {
                    "zipcode": "19276",
                    "province": "Guadalajara",
                    "city": "Bañuelos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19277",
                    "province": "Guadalajara",
                    "city": "Alcolea de las Peñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19277",
                    "province": "Guadalajara",
                    "city": "Paredes de Sigüenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19277",
                    "province": "Guadalajara",
                    "city": "Cincovillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19277",
                    "province": "Guadalajara",
                    "city": "Tordelrábano",
                    "multiple": "1"
                },
                {
                    "zipcode": "19277",
                    "province": "Guadalajara",
                    "city": "Atienza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19278",
                    "province": "Guadalajara",
                    "city": "Miñosa, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "19278",
                    "province": "Guadalajara",
                    "city": "Bodera, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "19280",
                    "province": "Guadalajara",
                    "city": "Maranchón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19281",
                    "province": "Guadalajara",
                    "city": "Maranchón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19283",
                    "province": "Guadalajara",
                    "city": "Anguita",
                    "multiple": "0"
                },
                {
                    "zipcode": "19283",
                    "province": "Guadalajara",
                    "city": "Iniéstola",
                    "multiple": "1"
                },
                {
                    "zipcode": "19284",
                    "province": "Guadalajara",
                    "city": "Alcolea del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19285",
                    "province": "Guadalajara",
                    "city": "Ciruelos del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19285",
                    "province": "Guadalajara",
                    "city": "Luzón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19286",
                    "province": "Guadalajara",
                    "city": "Anquela del Ducado",
                    "multiple": "0"
                },
                {
                    "zipcode": "19286",
                    "province": "Guadalajara",
                    "city": "Mazarete",
                    "multiple": "1"
                },
                {
                    "zipcode": "19287",
                    "province": "Guadalajara",
                    "city": "Tartanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19287",
                    "province": "Guadalajara",
                    "city": "Maranchón",
                    "multiple": "1"
                },
                {
                    "zipcode": "19287",
                    "province": "Guadalajara",
                    "city": "Anquela del Ducado",
                    "multiple": "1"
                },
                {
                    "zipcode": "19287",
                    "province": "Guadalajara",
                    "city": "Fuentelsaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "19287",
                    "province": "Guadalajara",
                    "city": "Milmarcos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19287",
                    "province": "Guadalajara",
                    "city": "Establés",
                    "multiple": "1"
                },
                {
                    "zipcode": "19290",
                    "province": "Guadalajara",
                    "city": "Fontanar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19292",
                    "province": "Guadalajara",
                    "city": "Copernal",
                    "multiple": "0"
                },
                {
                    "zipcode": "19292",
                    "province": "Guadalajara",
                    "city": "Espinosa de Henares",
                    "multiple": "1"
                },
                {
                    "zipcode": "19294",
                    "province": "Guadalajara",
                    "city": "Matillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19294",
                    "province": "Guadalajara",
                    "city": "Villaseca de Henares",
                    "multiple": "1"
                },
                {
                    "zipcode": "19294",
                    "province": "Guadalajara",
                    "city": "Castejón de Henares",
                    "multiple": "1"
                },
                {
                    "zipcode": "19294",
                    "province": "Guadalajara",
                    "city": "Mandayona",
                    "multiple": "1"
                },
                {
                    "zipcode": "19295",
                    "province": "Guadalajara",
                    "city": "Baides",
                    "multiple": "0"
                },
                {
                    "zipcode": "19295",
                    "province": "Guadalajara",
                    "city": "Viana de Jadraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "19295",
                    "province": "Guadalajara",
                    "city": "Huérmeces del Cerro",
                    "multiple": "1"
                },
                {
                    "zipcode": "19295",
                    "province": "Guadalajara",
                    "city": "Sigüenza",
                    "multiple": "1"
                },
                {
                    "zipcode": "19300",
                    "province": "Guadalajara",
                    "city": "Molina de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19310",
                    "province": "Guadalajara",
                    "city": "Alcoroches",
                    "multiple": "0"
                },
                {
                    "zipcode": "19310",
                    "province": "Guadalajara",
                    "city": "Checa",
                    "multiple": "1"
                },
                {
                    "zipcode": "19310",
                    "province": "Guadalajara",
                    "city": "Chequilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19311",
                    "province": "Guadalajara",
                    "city": "Orea",
                    "multiple": "0"
                },
                {
                    "zipcode": "19312",
                    "province": "Guadalajara",
                    "city": "Pinilla de Molina",
                    "multiple": "0"
                },
                {
                    "zipcode": "19312",
                    "province": "Guadalajara",
                    "city": "Terzaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "19312",
                    "province": "Guadalajara",
                    "city": "Traíd",
                    "multiple": "1"
                },
                {
                    "zipcode": "19313",
                    "province": "Guadalajara",
                    "city": "Peralejos de las Truchas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19314",
                    "province": "Guadalajara",
                    "city": "Taravilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "19315",
                    "province": "Guadalajara",
                    "city": "Megina",
                    "multiple": "0"
                },
                {
                    "zipcode": "19320",
                    "province": "Guadalajara",
                    "city": "Alustante",
                    "multiple": "0"
                },
                {
                    "zipcode": "19321",
                    "province": "Guadalajara",
                    "city": "Alustante",
                    "multiple": "0"
                },
                {
                    "zipcode": "19323",
                    "province": "Guadalajara",
                    "city": "Tordesilos",
                    "multiple": "0"
                },
                {
                    "zipcode": "19324",
                    "province": "Guadalajara",
                    "city": "Setiles",
                    "multiple": "0"
                },
                {
                    "zipcode": "19325",
                    "province": "Guadalajara",
                    "city": "Tordellego",
                    "multiple": "0"
                },
                {
                    "zipcode": "19325",
                    "province": "Guadalajara",
                    "city": "Piqueras",
                    "multiple": "1"
                },
                {
                    "zipcode": "19325",
                    "province": "Guadalajara",
                    "city": "Adobes",
                    "multiple": "1"
                },
                {
                    "zipcode": "19326",
                    "province": "Guadalajara",
                    "city": "Pobo de Dueñas, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "19327",
                    "province": "Guadalajara",
                    "city": "Pedregal, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "19328",
                    "province": "Guadalajara",
                    "city": "Castellar de la Muela",
                    "multiple": "0"
                },
                {
                    "zipcode": "19328",
                    "province": "Guadalajara",
                    "city": "Hombrados",
                    "multiple": "1"
                },
                {
                    "zipcode": "19328",
                    "province": "Guadalajara",
                    "city": "Morenilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19332",
                    "province": "Guadalajara",
                    "city": "Mochales",
                    "multiple": "0"
                },
                {
                    "zipcode": "19332",
                    "province": "Guadalajara",
                    "city": "Algar de Mesa",
                    "multiple": "1"
                },
                {
                    "zipcode": "19332",
                    "province": "Guadalajara",
                    "city": "Villel de Mesa",
                    "multiple": "1"
                },
                {
                    "zipcode": "19332",
                    "province": "Guadalajara",
                    "city": "Tartanedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19333",
                    "province": "Guadalajara",
                    "city": "Tartanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19334",
                    "province": "Guadalajara",
                    "city": "Tartanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19334",
                    "province": "Guadalajara",
                    "city": "Corduente",
                    "multiple": "1"
                },
                {
                    "zipcode": "19336",
                    "province": "Guadalajara",
                    "city": "Pardos",
                    "multiple": "0"
                },
                {
                    "zipcode": "19337",
                    "province": "Guadalajara",
                    "city": "Torrubia",
                    "multiple": "0"
                },
                {
                    "zipcode": "19338",
                    "province": "Guadalajara",
                    "city": "Tortuera",
                    "multiple": "0"
                },
                {
                    "zipcode": "19339",
                    "province": "Guadalajara",
                    "city": "Embid",
                    "multiple": "0"
                },
                {
                    "zipcode": "19339",
                    "province": "Guadalajara",
                    "city": "Rueda de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "19340",
                    "province": "Guadalajara",
                    "city": "Rillo de Gallo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19341",
                    "province": "Guadalajara",
                    "city": "Corduente",
                    "multiple": "0"
                },
                {
                    "zipcode": "19342",
                    "province": "Guadalajara",
                    "city": "Herrería",
                    "multiple": "0"
                },
                {
                    "zipcode": "19343",
                    "province": "Guadalajara",
                    "city": "Corduente",
                    "multiple": "0"
                },
                {
                    "zipcode": "19345",
                    "province": "Guadalajara",
                    "city": "Torremocha del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "19346",
                    "province": "Guadalajara",
                    "city": "Semillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19346",
                    "province": "Guadalajara",
                    "city": "Selas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19350",
                    "province": "Guadalajara",
                    "city": "Molina de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19351",
                    "province": "Guadalajara",
                    "city": "Molina de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19352",
                    "province": "Guadalajara",
                    "city": "Prados Redondos",
                    "multiple": "0"
                },
                {
                    "zipcode": "19353",
                    "province": "Guadalajara",
                    "city": "Prados Redondos",
                    "multiple": "0"
                },
                {
                    "zipcode": "19354",
                    "province": "Guadalajara",
                    "city": "Prados Redondos",
                    "multiple": "0"
                },
                {
                    "zipcode": "19355",
                    "province": "Guadalajara",
                    "city": "Torrecuadrada de Molina",
                    "multiple": "0"
                },
                {
                    "zipcode": "19357",
                    "province": "Guadalajara",
                    "city": "Anquela del Pedregal",
                    "multiple": "0"
                },
                {
                    "zipcode": "19360",
                    "province": "Guadalajara",
                    "city": "Campillo de Dueñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19361",
                    "province": "Guadalajara",
                    "city": "Yunta, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "19362",
                    "province": "Guadalajara",
                    "city": "Molina de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19363",
                    "province": "Guadalajara",
                    "city": "Molina de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19390",
                    "province": "Guadalajara",
                    "city": "Tierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19390",
                    "province": "Guadalajara",
                    "city": "Corduente",
                    "multiple": "1"
                },
                {
                    "zipcode": "19390",
                    "province": "Guadalajara",
                    "city": "Valhermoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "19390",
                    "province": "Guadalajara",
                    "city": "Fuentenovilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19390",
                    "province": "Guadalajara",
                    "city": "Baños de Tajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19390",
                    "province": "Guadalajara",
                    "city": "Fuembellida",
                    "multiple": "1"
                },
                {
                    "zipcode": "19391",
                    "province": "Guadalajara",
                    "city": "Torremochuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "19391",
                    "province": "Guadalajara",
                    "city": "Castilnuevo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19391",
                    "province": "Guadalajara",
                    "city": "Prados Redondos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19392",
                    "province": "Guadalajara",
                    "city": "Corduente",
                    "multiple": "0"
                },
                {
                    "zipcode": "19400",
                    "province": "Guadalajara",
                    "city": "Brihuega",
                    "multiple": "0"
                },
                {
                    "zipcode": "19410",
                    "province": "Guadalajara",
                    "city": "Brihuega",
                    "multiple": "0"
                },
                {
                    "zipcode": "19411",
                    "province": "Guadalajara",
                    "city": "Brihuega",
                    "multiple": "0"
                },
                {
                    "zipcode": "19411",
                    "province": "Guadalajara",
                    "city": "Valfermoso de Tajuña",
                    "multiple": "1"
                },
                {
                    "zipcode": "19412",
                    "province": "Guadalajara",
                    "city": "Brihuega",
                    "multiple": "0"
                },
                {
                    "zipcode": "19412",
                    "province": "Guadalajara",
                    "city": "Valdegrudas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19412",
                    "province": "Guadalajara",
                    "city": "Caspueñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19413",
                    "province": "Guadalajara",
                    "city": "Brihuega",
                    "multiple": "0"
                },
                {
                    "zipcode": "19414",
                    "province": "Guadalajara",
                    "city": "Brihuega",
                    "multiple": "0"
                },
                {
                    "zipcode": "19420",
                    "province": "Guadalajara",
                    "city": "Cifuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19428",
                    "province": "Guadalajara",
                    "city": "Cifuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19429",
                    "province": "Guadalajara",
                    "city": "Cifuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "19431",
                    "province": "Guadalajara",
                    "city": "Canredondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19431",
                    "province": "Guadalajara",
                    "city": "Torrecuadradilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "19431",
                    "province": "Guadalajara",
                    "city": "Cifuentes",
                    "multiple": "1"
                },
                {
                    "zipcode": "19432",
                    "province": "Guadalajara",
                    "city": "Ocentejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19432",
                    "province": "Guadalajara",
                    "city": "Abánades",
                    "multiple": "1"
                },
                {
                    "zipcode": "19432",
                    "province": "Guadalajara",
                    "city": "Torremocha del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19432",
                    "province": "Guadalajara",
                    "city": "Sacecorbo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19441",
                    "province": "Guadalajara",
                    "city": "Riba de Saelices",
                    "multiple": "0"
                },
                {
                    "zipcode": "19441",
                    "province": "Guadalajara",
                    "city": "Huertahernando",
                    "multiple": "1"
                },
                {
                    "zipcode": "19442",
                    "province": "Guadalajara",
                    "city": "Ablanque",
                    "multiple": "0"
                },
                {
                    "zipcode": "19443",
                    "province": "Guadalajara",
                    "city": "Cobeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "19443",
                    "province": "Guadalajara",
                    "city": "Olmeda de Cobeta",
                    "multiple": "1"
                },
                {
                    "zipcode": "19443",
                    "province": "Guadalajara",
                    "city": "Saelices de la Sal",
                    "multiple": "1"
                },
                {
                    "zipcode": "19444",
                    "province": "Guadalajara",
                    "city": "Zaorejas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19444",
                    "province": "Guadalajara",
                    "city": "Olmeda de Cobeta",
                    "multiple": "1"
                },
                {
                    "zipcode": "19445",
                    "province": "Guadalajara",
                    "city": "Esplegares",
                    "multiple": "0"
                },
                {
                    "zipcode": "19445",
                    "province": "Guadalajara",
                    "city": "Anguita",
                    "multiple": "1"
                },
                {
                    "zipcode": "19445",
                    "province": "Guadalajara",
                    "city": "Hortezuela de Océn",
                    "multiple": "1"
                },
                {
                    "zipcode": "19445",
                    "province": "Guadalajara",
                    "city": "Sotodosos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19450",
                    "province": "Guadalajara",
                    "city": "Trillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19458",
                    "province": "Guadalajara",
                    "city": "Trillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "19459",
                    "province": "Guadalajara",
                    "city": "Budia",
                    "multiple": "0"
                },
                {
                    "zipcode": "19459",
                    "province": "Guadalajara",
                    "city": "Cifuentes",
                    "multiple": "1"
                },
                {
                    "zipcode": "19460",
                    "province": "Guadalajara",
                    "city": "Villanueva de Alcorón",
                    "multiple": "0"
                },
                {
                    "zipcode": "19461",
                    "province": "Guadalajara",
                    "city": "Armallones",
                    "multiple": "0"
                },
                {
                    "zipcode": "19461",
                    "province": "Guadalajara",
                    "city": "Zaorejas",
                    "multiple": "1"
                },
                {
                    "zipcode": "19462",
                    "province": "Guadalajara",
                    "city": "Peñalén",
                    "multiple": "0"
                },
                {
                    "zipcode": "19463",
                    "province": "Guadalajara",
                    "city": "Poveda de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "19490",
                    "province": "Guadalajara",
                    "city": "Brihuega",
                    "multiple": "0"
                },
                {
                    "zipcode": "19490",
                    "province": "Guadalajara",
                    "city": "Almadrones",
                    "multiple": "1"
                },
                {
                    "zipcode": "19490",
                    "province": "Guadalajara",
                    "city": "Masegoso de Tajuña",
                    "multiple": "1"
                },
                {
                    "zipcode": "19490",
                    "province": "Guadalajara",
                    "city": "Cogollor",
                    "multiple": "1"
                },
                {
                    "zipcode": "19490",
                    "province": "Guadalajara",
                    "city": "Alaminos",
                    "multiple": "1"
                },
                {
                    "zipcode": "19490",
                    "province": "Guadalajara",
                    "city": "Valderrebollo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19490",
                    "province": "Guadalajara",
                    "city": "Barriopedro",
                    "multiple": "1"
                },
                {
                    "zipcode": "19491",
                    "province": "Guadalajara",
                    "city": "Sotillo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "19491",
                    "province": "Guadalajara",
                    "city": "Inviernas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "19491",
                    "province": "Guadalajara",
                    "city": "Henche",
                    "multiple": "1"
                },
                {
                    "zipcode": "19491",
                    "province": "Guadalajara",
                    "city": "Solanillos del Extremo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19491",
                    "province": "Guadalajara",
                    "city": "Torremocha del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19492",
                    "province": "Guadalajara",
                    "city": "Arbeteta",
                    "multiple": "0"
                },
                {
                    "zipcode": "19492",
                    "province": "Guadalajara",
                    "city": "Valtablado del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "19492",
                    "province": "Guadalajara",
                    "city": "Trillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "19492",
                    "province": "Guadalajara",
                    "city": "Pareja",
                    "multiple": "1"
                },
                {
                    "zipcode": "19492",
                    "province": "Guadalajara",
                    "city": "Recuenco, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "19493",
                    "province": "Guadalajara",
                    "city": "Peralveche",
                    "multiple": "0"
                },
                {
                    "zipcode": "19495",
                    "province": "Guadalajara",
                    "city": "Zaorejas",
                    "multiple": "0"
                },
                {
                    "zipcode": "19680",
                    "province": "Granada",
                    "city": "Salobreña",
                    "multiple": "0"
                },
                {
                    "zipcode": "20001",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20002",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20003",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20004",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20005",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20006",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20007",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20008",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20009",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20010",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20011",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20012",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20013",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20014",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20015",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20016",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20017",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20018",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20100",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "0"
                },
                {
                    "zipcode": "20100",
                    "province": "Gipuzkoa",
                    "city": "Errenteria",
                    "multiple": "1"
                },
                {
                    "zipcode": "20100",
                    "province": "Gipuzkoa",
                    "city": "Lezo",
                    "multiple": "1"
                },
                {
                    "zipcode": "20110",
                    "province": "Gipuzkoa",
                    "city": "Pasaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20115",
                    "province": "Gipuzkoa",
                    "city": "Astigarraga",
                    "multiple": "0"
                },
                {
                    "zipcode": "20120",
                    "province": "Gipuzkoa",
                    "city": "Hernani",
                    "multiple": "0"
                },
                {
                    "zipcode": "20128",
                    "province": "Gipuzkoa",
                    "city": "Hernani",
                    "multiple": "0"
                },
                {
                    "zipcode": "20130",
                    "province": "Gipuzkoa",
                    "city": "Usurbil",
                    "multiple": "0"
                },
                {
                    "zipcode": "20130",
                    "province": "Gipuzkoa",
                    "city": "Urnieta",
                    "multiple": "1"
                },
                {
                    "zipcode": "20140",
                    "province": "Gipuzkoa",
                    "city": "Andoain",
                    "multiple": "0"
                },
                {
                    "zipcode": "20150",
                    "province": "Gipuzkoa",
                    "city": "Villabona",
                    "multiple": "0"
                },
                {
                    "zipcode": "20150",
                    "province": "Gipuzkoa",
                    "city": "Aduna",
                    "multiple": "1"
                },
                {
                    "zipcode": "20159",
                    "province": "Gipuzkoa",
                    "city": "Zizurkil",
                    "multiple": "0"
                },
                {
                    "zipcode": "20159",
                    "province": "Gipuzkoa",
                    "city": "Asteasu",
                    "multiple": "1"
                },
                {
                    "zipcode": "20159",
                    "province": "Gipuzkoa",
                    "city": "Larraul",
                    "multiple": "1"
                },
                {
                    "zipcode": "20160",
                    "province": "Gipuzkoa",
                    "city": "Lasarte-Oria",
                    "multiple": "0"
                },
                {
                    "zipcode": "20160",
                    "province": "Gipuzkoa",
                    "city": "Donostia/San Sebastián",
                    "multiple": "1"
                },
                {
                    "zipcode": "20170",
                    "province": "Gipuzkoa",
                    "city": "Usurbil",
                    "multiple": "0"
                },
                {
                    "zipcode": "20180",
                    "province": "Gipuzkoa",
                    "city": "Oiartzun",
                    "multiple": "0"
                },
                {
                    "zipcode": "20200",
                    "province": "Gipuzkoa",
                    "city": "Beasain",
                    "multiple": "0"
                },
                {
                    "zipcode": "20210",
                    "province": "Gipuzkoa",
                    "city": "Lazkao",
                    "multiple": "0"
                },
                {
                    "zipcode": "20211",
                    "province": "Gipuzkoa",
                    "city": "Ataun",
                    "multiple": "0"
                },
                {
                    "zipcode": "20212",
                    "province": "Gipuzkoa",
                    "city": "Olaberria",
                    "multiple": "0"
                },
                {
                    "zipcode": "20213",
                    "province": "Gipuzkoa",
                    "city": "Idiazabal",
                    "multiple": "0"
                },
                {
                    "zipcode": "20214",
                    "province": "Gipuzkoa",
                    "city": "Zerain",
                    "multiple": "0"
                },
                {
                    "zipcode": "20214",
                    "province": "Gipuzkoa",
                    "city": "Mutiloa",
                    "multiple": "1"
                },
                {
                    "zipcode": "20214",
                    "province": "Gipuzkoa",
                    "city": "Segura",
                    "multiple": "1"
                },
                {
                    "zipcode": "20215",
                    "province": "Gipuzkoa",
                    "city": "Zegama",
                    "multiple": "0"
                },
                {
                    "zipcode": "20216",
                    "province": "Gipuzkoa",
                    "city": "Ormaiztegi",
                    "multiple": "0"
                },
                {
                    "zipcode": "20217",
                    "province": "Gipuzkoa",
                    "city": "Gabiria",
                    "multiple": "0"
                },
                {
                    "zipcode": "20218",
                    "province": "Gipuzkoa",
                    "city": "Ezkio-Itsaso",
                    "multiple": "0"
                },
                {
                    "zipcode": "20220",
                    "province": "Gipuzkoa",
                    "city": "Legazpi",
                    "multiple": "0"
                },
                {
                    "zipcode": "20230",
                    "province": "Gipuzkoa",
                    "city": "Legazpi",
                    "multiple": "0"
                },
                {
                    "zipcode": "20240",
                    "province": "Gipuzkoa",
                    "city": "Ordizia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20247",
                    "province": "Gipuzkoa",
                    "city": "Zaldibia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20248",
                    "province": "Gipuzkoa",
                    "city": "Gaintza",
                    "multiple": "0"
                },
                {
                    "zipcode": "20248",
                    "province": "Gipuzkoa",
                    "city": "Arama",
                    "multiple": "1"
                },
                {
                    "zipcode": "20248",
                    "province": "Gipuzkoa",
                    "city": "Altzaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "20249",
                    "province": "Gipuzkoa",
                    "city": "Itsasondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "20250",
                    "province": "Gipuzkoa",
                    "city": "Legorreta",
                    "multiple": "0"
                },
                {
                    "zipcode": "20259",
                    "province": "Gipuzkoa",
                    "city": "Baliarrain",
                    "multiple": "0"
                },
                {
                    "zipcode": "20260",
                    "province": "Gipuzkoa",
                    "city": "Alegia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20267",
                    "province": "Gipuzkoa",
                    "city": "Ikaztegieta",
                    "multiple": "0"
                },
                {
                    "zipcode": "20267",
                    "province": "Gipuzkoa",
                    "city": "Tolosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "20268",
                    "province": "Gipuzkoa",
                    "city": "Tolosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "20268",
                    "province": "Gipuzkoa",
                    "city": "Altzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "20268",
                    "province": "Gipuzkoa",
                    "city": "Amezketa",
                    "multiple": "1"
                },
                {
                    "zipcode": "20269",
                    "province": "Gipuzkoa",
                    "city": "Abaltzisketa",
                    "multiple": "0"
                },
                {
                    "zipcode": "20269",
                    "province": "Gipuzkoa",
                    "city": "Orendain",
                    "multiple": "1"
                },
                {
                    "zipcode": "20270",
                    "province": "Gipuzkoa",
                    "city": "Anoeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "20271",
                    "province": "Gipuzkoa",
                    "city": "Irura",
                    "multiple": "0"
                },
                {
                    "zipcode": "20280",
                    "province": "Gipuzkoa",
                    "city": "Hondarribia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20301",
                    "province": "Gipuzkoa",
                    "city": "Irun",
                    "multiple": "0"
                },
                {
                    "zipcode": "20302",
                    "province": "Gipuzkoa",
                    "city": "Irun",
                    "multiple": "0"
                },
                {
                    "zipcode": "20303",
                    "province": "Gipuzkoa",
                    "city": "Irun",
                    "multiple": "0"
                },
                {
                    "zipcode": "20304",
                    "province": "Gipuzkoa",
                    "city": "Irun",
                    "multiple": "0"
                },
                {
                    "zipcode": "20305",
                    "province": "Gipuzkoa",
                    "city": "Irun",
                    "multiple": "0"
                },
                {
                    "zipcode": "20400",
                    "province": "Gipuzkoa",
                    "city": "Tolosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "20400",
                    "province": "Gipuzkoa",
                    "city": "Ibarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "20490",
                    "province": "Gipuzkoa",
                    "city": "Orexa",
                    "multiple": "0"
                },
                {
                    "zipcode": "20490",
                    "province": "Gipuzkoa",
                    "city": "Lizartza",
                    "multiple": "1"
                },
                {
                    "zipcode": "20491",
                    "province": "Gipuzkoa",
                    "city": "Gaztelu",
                    "multiple": "0"
                },
                {
                    "zipcode": "20491",
                    "province": "Gipuzkoa",
                    "city": "Belauntza",
                    "multiple": "1"
                },
                {
                    "zipcode": "20491",
                    "province": "Gipuzkoa",
                    "city": "Leaburu",
                    "multiple": "1"
                },
                {
                    "zipcode": "20492",
                    "province": "Gipuzkoa",
                    "city": "Berastegi",
                    "multiple": "0"
                },
                {
                    "zipcode": "20493",
                    "province": "Gipuzkoa",
                    "city": "Elduain",
                    "multiple": "0"
                },
                {
                    "zipcode": "20493",
                    "province": "Gipuzkoa",
                    "city": "Berastegi",
                    "multiple": "1"
                },
                {
                    "zipcode": "20493",
                    "province": "Gipuzkoa",
                    "city": "Berrobi",
                    "multiple": "1"
                },
                {
                    "zipcode": "20494",
                    "province": "Gipuzkoa",
                    "city": "Alkiza",
                    "multiple": "0"
                },
                {
                    "zipcode": "20494",
                    "province": "Gipuzkoa",
                    "city": "Hernialde",
                    "multiple": "1"
                },
                {
                    "zipcode": "20494",
                    "province": "Gipuzkoa",
                    "city": "Tolosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "20495",
                    "province": "Gipuzkoa",
                    "city": "Albiztur",
                    "multiple": "0"
                },
                {
                    "zipcode": "20496",
                    "province": "Gipuzkoa",
                    "city": "Bidania-Goiatz",
                    "multiple": "0"
                },
                {
                    "zipcode": "20500",
                    "province": "Gipuzkoa",
                    "city": "Arrasate/Mondragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "20509",
                    "province": "Gipuzkoa",
                    "city": "Arrasate/Mondragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "20530",
                    "province": "Gipuzkoa",
                    "city": "Eskoriatza",
                    "multiple": "0"
                },
                {
                    "zipcode": "20530",
                    "province": "Gipuzkoa",
                    "city": "Leintz-Gatzaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "20540",
                    "province": "Gipuzkoa",
                    "city": "Eskoriatza",
                    "multiple": "0"
                },
                {
                    "zipcode": "20550",
                    "province": "Gipuzkoa",
                    "city": "Aretxabaleta",
                    "multiple": "0"
                },
                {
                    "zipcode": "20560",
                    "province": "Gipuzkoa",
                    "city": "Oñati",
                    "multiple": "0"
                },
                {
                    "zipcode": "20567",
                    "province": "Gipuzkoa",
                    "city": "Oñati",
                    "multiple": "0"
                },
                {
                    "zipcode": "20568",
                    "province": "Gipuzkoa",
                    "city": "Oñati",
                    "multiple": "0"
                },
                {
                    "zipcode": "20569",
                    "province": "Gipuzkoa",
                    "city": "Bergara",
                    "multiple": "0"
                },
                {
                    "zipcode": "20569",
                    "province": "Gipuzkoa",
                    "city": "Oñati",
                    "multiple": "1"
                },
                {
                    "zipcode": "20570",
                    "province": "Gipuzkoa",
                    "city": "Bergara",
                    "multiple": "0"
                },
                {
                    "zipcode": "20577",
                    "province": "Gipuzkoa",
                    "city": "Antzuola",
                    "multiple": "0"
                },
                {
                    "zipcode": "20578",
                    "province": "Gipuzkoa",
                    "city": "Bergara",
                    "multiple": "0"
                },
                {
                    "zipcode": "20580",
                    "province": "Gipuzkoa",
                    "city": "Bergara",
                    "multiple": "0"
                },
                {
                    "zipcode": "20590",
                    "province": "Gipuzkoa",
                    "city": "Soraluze-Placencia de las Armas",
                    "multiple": "0"
                },
                {
                    "zipcode": "20600",
                    "province": "Gipuzkoa",
                    "city": "Eibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "20690",
                    "province": "Gipuzkoa",
                    "city": "Elgeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "20700",
                    "province": "Gipuzkoa",
                    "city": "Urretxu",
                    "multiple": "0"
                },
                {
                    "zipcode": "20700",
                    "province": "Gipuzkoa",
                    "city": "Zumarraga",
                    "multiple": "1"
                },
                {
                    "zipcode": "20709",
                    "province": "Gipuzkoa",
                    "city": "Ezkio-Itsaso",
                    "multiple": "0"
                },
                {
                    "zipcode": "20720",
                    "province": "Gipuzkoa",
                    "city": "Azkoitia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20730",
                    "province": "Gipuzkoa",
                    "city": "Azpeitia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20737",
                    "province": "Gipuzkoa",
                    "city": "Errezil",
                    "multiple": "0"
                },
                {
                    "zipcode": "20739",
                    "province": "Gipuzkoa",
                    "city": "Beizama",
                    "multiple": "0"
                },
                {
                    "zipcode": "20740",
                    "province": "Gipuzkoa",
                    "city": "Zestoa",
                    "multiple": "0"
                },
                {
                    "zipcode": "20749",
                    "province": "Gipuzkoa",
                    "city": "Zestoa",
                    "multiple": "0"
                },
                {
                    "zipcode": "20749",
                    "province": "Gipuzkoa",
                    "city": "Aizarnazabal",
                    "multiple": "1"
                },
                {
                    "zipcode": "20750",
                    "province": "Gipuzkoa",
                    "city": "Zumaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20800",
                    "province": "Gipuzkoa",
                    "city": "Zarautz",
                    "multiple": "0"
                },
                {
                    "zipcode": "20808",
                    "province": "Gipuzkoa",
                    "city": "Getaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "20809",
                    "province": "Gipuzkoa",
                    "city": "Aia",
                    "multiple": "0"
                },
                {
                    "zipcode": "20810",
                    "province": "Gipuzkoa",
                    "city": "Orio",
                    "multiple": "0"
                },
                {
                    "zipcode": "20810",
                    "province": "Gipuzkoa",
                    "city": "Aia",
                    "multiple": "1"
                },
                {
                    "zipcode": "20820",
                    "province": "Gipuzkoa",
                    "city": "Deba",
                    "multiple": "0"
                },
                {
                    "zipcode": "20829",
                    "province": "Gipuzkoa",
                    "city": "Deba",
                    "multiple": "0"
                },
                {
                    "zipcode": "20830",
                    "province": "Gipuzkoa",
                    "city": "Mutriku",
                    "multiple": "0"
                },
                {
                    "zipcode": "20850",
                    "province": "Gipuzkoa",
                    "city": "Mendaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "20860",
                    "province": "Gipuzkoa",
                    "city": "Elgoibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "20870",
                    "province": "Gipuzkoa",
                    "city": "Elgoibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "21000",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21001",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21002",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21003",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21004",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21005",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21006",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21007",
                    "province": "Huelva",
                    "city": "Huelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21009",
                    "province": "Huelva",
                    "city": "Ayamonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21100",
                    "province": "Huelva",
                    "city": "Punta Umbría",
                    "multiple": "0"
                },
                {
                    "zipcode": "21100",
                    "province": "Huelva",
                    "city": "Aljaraque",
                    "multiple": "1"
                },
                {
                    "zipcode": "21110",
                    "province": "Huelva",
                    "city": "Aljaraque",
                    "multiple": "0"
                },
                {
                    "zipcode": "21110",
                    "province": "Huelva",
                    "city": "Punta Umbría",
                    "multiple": "1"
                },
                {
                    "zipcode": "21120",
                    "province": "Huelva",
                    "city": "Aljaraque",
                    "multiple": "0"
                },
                {
                    "zipcode": "21122",
                    "province": "Huelva",
                    "city": "Aljaraque",
                    "multiple": "0"
                },
                {
                    "zipcode": "21130",
                    "province": "Huelva",
                    "city": "Palos de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "21130",
                    "province": "Huelva",
                    "city": "Moguer",
                    "multiple": "1"
                },
                {
                    "zipcode": "21193",
                    "province": "León",
                    "city": "Villaquilambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "21200",
                    "province": "Huelva",
                    "city": "Aracena",
                    "multiple": "0"
                },
                {
                    "zipcode": "21207",
                    "province": "Huelva",
                    "city": "Linares de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "21207",
                    "province": "Huelva",
                    "city": "Aracena",
                    "multiple": "1"
                },
                {
                    "zipcode": "21208",
                    "province": "Huelva",
                    "city": "Marines, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "21208",
                    "province": "Huelva",
                    "city": "Aracena",
                    "multiple": "1"
                },
                {
                    "zipcode": "21208",
                    "province": "Huelva",
                    "city": "Cortelazor",
                    "multiple": "1"
                },
                {
                    "zipcode": "21209",
                    "province": "Huelva",
                    "city": "Puerto Moral",
                    "multiple": "0"
                },
                {
                    "zipcode": "21209",
                    "province": "Huelva",
                    "city": "Corteconcepción",
                    "multiple": "1"
                },
                {
                    "zipcode": "21209",
                    "province": "Huelva",
                    "city": "Aracena",
                    "multiple": "1"
                },
                {
                    "zipcode": "21210",
                    "province": "Huelva",
                    "city": "Zufre",
                    "multiple": "0"
                },
                {
                    "zipcode": "21220",
                    "province": "Huelva",
                    "city": "Higuera de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "21230",
                    "province": "Huelva",
                    "city": "Cortegana",
                    "multiple": "0"
                },
                {
                    "zipcode": "21239",
                    "province": "Huelva",
                    "city": "Santa Ana la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21239",
                    "province": "Huelva",
                    "city": "Aroche",
                    "multiple": "1"
                },
                {
                    "zipcode": "21239",
                    "province": "Huelva",
                    "city": "Cortegana",
                    "multiple": "1"
                },
                {
                    "zipcode": "21240",
                    "province": "Huelva",
                    "city": "Aroche",
                    "multiple": "0"
                },
                {
                    "zipcode": "21248",
                    "province": "Huelva",
                    "city": "Aroche",
                    "multiple": "0"
                },
                {
                    "zipcode": "21250",
                    "province": "Huelva",
                    "city": "Rosal de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "21260",
                    "province": "Huelva",
                    "city": "Santa Olalla del Cala",
                    "multiple": "0"
                },
                {
                    "zipcode": "21270",
                    "province": "Huelva",
                    "city": "Cala",
                    "multiple": "0"
                },
                {
                    "zipcode": "21280",
                    "province": "Huelva",
                    "city": "Lucena del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "21280",
                    "province": "Huelva",
                    "city": "Arroyomolinos de León",
                    "multiple": "1"
                },
                {
                    "zipcode": "21290",
                    "province": "Huelva",
                    "city": "Fuenteheridos",
                    "multiple": "0"
                },
                {
                    "zipcode": "21290",
                    "province": "Huelva",
                    "city": "Almonaster la Real",
                    "multiple": "1"
                },
                {
                    "zipcode": "21290",
                    "province": "Huelva",
                    "city": "Jabugo",
                    "multiple": "1"
                },
                {
                    "zipcode": "21291",
                    "province": "Huelva",
                    "city": "Galaroza",
                    "multiple": "0"
                },
                {
                    "zipcode": "21291",
                    "province": "Huelva",
                    "city": "Beas",
                    "multiple": "1"
                },
                {
                    "zipcode": "21291",
                    "province": "Huelva",
                    "city": "Valdelarco",
                    "multiple": "1"
                },
                {
                    "zipcode": "21291",
                    "province": "Huelva",
                    "city": "Nava, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "21292",
                    "province": "Huelva",
                    "city": "Castaño del Robledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "21292",
                    "province": "Huelva",
                    "city": "Fuenteheridos",
                    "multiple": "1"
                },
                {
                    "zipcode": "21300",
                    "province": "Huelva",
                    "city": "Calañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "21309",
                    "province": "Huelva",
                    "city": "Calañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "21310",
                    "province": "Huelva",
                    "city": "Calañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "21319",
                    "province": "Huelva",
                    "city": "Calañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "21320",
                    "province": "Huelva",
                    "city": "Cerro de Andévalo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "21330",
                    "province": "Huelva",
                    "city": "Almonaster la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21330",
                    "province": "Huelva",
                    "city": "Cortegana",
                    "multiple": "1"
                },
                {
                    "zipcode": "21330",
                    "province": "Huelva",
                    "city": "Cerro de Andévalo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "21340",
                    "province": "Huelva",
                    "city": "Alájar",
                    "multiple": "0"
                },
                {
                    "zipcode": "21340",
                    "province": "Huelva",
                    "city": "Aroche",
                    "multiple": "1"
                },
                {
                    "zipcode": "21342",
                    "province": "Huelva",
                    "city": "Almonaster la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21350",
                    "province": "Huelva",
                    "city": "Almonaster la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21359",
                    "province": "Huelva",
                    "city": "Santa Ana la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21359",
                    "province": "Huelva",
                    "city": "Almonaster la Real",
                    "multiple": "1"
                },
                {
                    "zipcode": "21360",
                    "province": "Huelva",
                    "city": "Jabugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "21380",
                    "province": "Huelva",
                    "city": "Cumbres Mayores",
                    "multiple": "0"
                },
                {
                    "zipcode": "21386",
                    "province": "Huelva",
                    "city": "Cumbres de San Bartolomé",
                    "multiple": "0"
                },
                {
                    "zipcode": "21387",
                    "province": "Huelva",
                    "city": "Cumbres de Enmedio",
                    "multiple": "0"
                },
                {
                    "zipcode": "21388",
                    "province": "Huelva",
                    "city": "Hinojales",
                    "multiple": "0"
                },
                {
                    "zipcode": "21388",
                    "province": "Huelva",
                    "city": "Cañaveral de León",
                    "multiple": "1"
                },
                {
                    "zipcode": "21390",
                    "province": "Huelva",
                    "city": "Encinasola",
                    "multiple": "0"
                },
                {
                    "zipcode": "21400",
                    "province": "Huelva",
                    "city": "Isla Cristina",
                    "multiple": "0"
                },
                {
                    "zipcode": "21400",
                    "province": "Huelva",
                    "city": "Lepe",
                    "multiple": "1"
                },
                {
                    "zipcode": "21400",
                    "province": "Huelva",
                    "city": "Ayamonte",
                    "multiple": "1"
                },
                {
                    "zipcode": "21403",
                    "province": "Huelva",
                    "city": "Ayamonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21409",
                    "province": "Huelva",
                    "city": "Ayamonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21410",
                    "province": "Huelva",
                    "city": "Isla Cristina",
                    "multiple": "0"
                },
                {
                    "zipcode": "21420",
                    "province": "Huelva",
                    "city": "Ayamonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21420",
                    "province": "Huelva",
                    "city": "Isla Cristina",
                    "multiple": "1"
                },
                {
                    "zipcode": "21430",
                    "province": "Huelva",
                    "city": "Isla Cristina",
                    "multiple": "0"
                },
                {
                    "zipcode": "21440",
                    "province": "Huelva",
                    "city": "Ayamonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21440",
                    "province": "Huelva",
                    "city": "Lepe",
                    "multiple": "1"
                },
                {
                    "zipcode": "21449",
                    "province": "Huelva",
                    "city": "Lepe",
                    "multiple": "0"
                },
                {
                    "zipcode": "21450",
                    "province": "Huelva",
                    "city": "Cartaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "21456",
                    "province": "Huelva",
                    "city": "Cartaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "21459",
                    "province": "Huelva",
                    "city": "Cartaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "21459",
                    "province": "Huelva",
                    "city": "Punta Umbría",
                    "multiple": "1"
                },
                {
                    "zipcode": "21460",
                    "province": "Huelva",
                    "city": "Isla Cristina",
                    "multiple": "0"
                },
                {
                    "zipcode": "21500",
                    "province": "Huelva",
                    "city": "Gibraleón",
                    "multiple": "0"
                },
                {
                    "zipcode": "21510",
                    "province": "Huelva",
                    "city": "San Bartolomé de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "21520",
                    "province": "Huelva",
                    "city": "San Bartolomé de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "21520",
                    "province": "Huelva",
                    "city": "Alosno",
                    "multiple": "1"
                },
                {
                    "zipcode": "21530",
                    "province": "Huelva",
                    "city": "Alosno",
                    "multiple": "0"
                },
                {
                    "zipcode": "21540",
                    "province": "Huelva",
                    "city": "Cartaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "21540",
                    "province": "Huelva",
                    "city": "Villanueva de los Castillejos",
                    "multiple": "1"
                },
                {
                    "zipcode": "21550",
                    "province": "Huelva",
                    "city": "Puebla de Guzmán",
                    "multiple": "0"
                },
                {
                    "zipcode": "21559",
                    "province": "Huelva",
                    "city": "Puebla de Guzmán",
                    "multiple": "0"
                },
                {
                    "zipcode": "21560",
                    "province": "Huelva",
                    "city": "Paymogo",
                    "multiple": "0"
                },
                {
                    "zipcode": "21570",
                    "province": "Huelva",
                    "city": "Santa Bárbara de Casa",
                    "multiple": "0"
                },
                {
                    "zipcode": "21580",
                    "province": "Huelva",
                    "city": "Cabezas Rubias",
                    "multiple": "0"
                },
                {
                    "zipcode": "21580",
                    "province": "Huelva",
                    "city": "Aroche",
                    "multiple": "1"
                },
                {
                    "zipcode": "21580",
                    "province": "Huelva",
                    "city": "Cerro de Andévalo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "21590",
                    "province": "Huelva",
                    "city": "Villablanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "21591",
                    "province": "Huelva",
                    "city": "San Silvestre de Guzmán",
                    "multiple": "0"
                },
                {
                    "zipcode": "21592",
                    "province": "Huelva",
                    "city": "Villanueva de las Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "21593",
                    "province": "Huelva",
                    "city": "Almendro, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "21594",
                    "province": "Huelva",
                    "city": "Granado, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "21595",
                    "province": "Huelva",
                    "city": "Sanlúcar de Guadiana",
                    "multiple": "0"
                },
                {
                    "zipcode": "21600",
                    "province": "Huelva",
                    "city": "Valverde del Camino",
                    "multiple": "0"
                },
                {
                    "zipcode": "21600",
                    "province": "Huelva",
                    "city": "Palma del Condado, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "21609",
                    "province": "Huelva",
                    "city": "Beas",
                    "multiple": "0"
                },
                {
                    "zipcode": "21610",
                    "province": "Huelva",
                    "city": "San Juan del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "21620",
                    "province": "Huelva",
                    "city": "Trigueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "21620",
                    "province": "Huelva",
                    "city": "Santa Olalla del Cala",
                    "multiple": "1"
                },
                {
                    "zipcode": "21630",
                    "province": "Huelva",
                    "city": "Beas",
                    "multiple": "0"
                },
                {
                    "zipcode": "21639",
                    "province": "Huelva",
                    "city": "San Juan del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "21639",
                    "province": "Huelva",
                    "city": "Beas",
                    "multiple": "1"
                },
                {
                    "zipcode": "21640",
                    "province": "Huelva",
                    "city": "Zalamea la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21646",
                    "province": "Huelva",
                    "city": "Almonaster la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21647",
                    "province": "Huelva",
                    "city": "Berrocal",
                    "multiple": "0"
                },
                {
                    "zipcode": "21647",
                    "province": "Huelva",
                    "city": "Zalamea la Real",
                    "multiple": "1"
                },
                {
                    "zipcode": "21649",
                    "province": "Huelva",
                    "city": "Zalamea la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21649",
                    "province": "Huelva",
                    "city": "Campillo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "21650",
                    "province": "Huelva",
                    "city": "Campillo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "21660",
                    "province": "Huelva",
                    "city": "Minas de Riotinto",
                    "multiple": "0"
                },
                {
                    "zipcode": "21667",
                    "province": "Huelva",
                    "city": "Minas de Riotinto",
                    "multiple": "0"
                },
                {
                    "zipcode": "21668",
                    "province": "Huelva",
                    "city": "Zalamea la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21668",
                    "province": "Huelva",
                    "city": "Campofrío",
                    "multiple": "1"
                },
                {
                    "zipcode": "21668",
                    "province": "Huelva",
                    "city": "Granada de Río-Tinto, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "21669",
                    "province": "Huelva",
                    "city": "Almonaster la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "21670",
                    "province": "Huelva",
                    "city": "Nerva",
                    "multiple": "0"
                },
                {
                    "zipcode": "21700",
                    "province": "Huelva",
                    "city": "Bollullos Par del Condado",
                    "multiple": "0"
                },
                {
                    "zipcode": "21700",
                    "province": "Huelva",
                    "city": "Palma del Condado, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "21710",
                    "province": "Huelva",
                    "city": "Bollullos Par del Condado",
                    "multiple": "0"
                },
                {
                    "zipcode": "21710",
                    "province": "Huelva",
                    "city": "Almonte",
                    "multiple": "1"
                },
                {
                    "zipcode": "21710",
                    "province": "Huelva",
                    "city": "Bollullos Par del Condado",
                    "multiple": "1"
                },
                {
                    "zipcode": "21720",
                    "province": "Huelva",
                    "city": "Rociana del Condado",
                    "multiple": "0"
                },
                {
                    "zipcode": "21730",
                    "province": "Huelva",
                    "city": "Almonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21739",
                    "province": "Huelva",
                    "city": "Almonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21740",
                    "province": "Huelva",
                    "city": "Hinojos",
                    "multiple": "0"
                },
                {
                    "zipcode": "21750",
                    "province": "Huelva",
                    "city": "Almonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21760",
                    "province": "Huelva",
                    "city": "Almonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "21800",
                    "province": "Huelva",
                    "city": "Moguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "21800",
                    "province": "Huelva",
                    "city": "Palos de la Frontera",
                    "multiple": "1"
                },
                {
                    "zipcode": "21810",
                    "province": "Huelva",
                    "city": "Palos de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "21819",
                    "province": "Huelva",
                    "city": "Palos de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "21820",
                    "province": "Huelva",
                    "city": "Lucena del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "21830",
                    "province": "Huelva",
                    "city": "Bonares",
                    "multiple": "0"
                },
                {
                    "zipcode": "21840",
                    "province": "Huelva",
                    "city": "Niebla",
                    "multiple": "0"
                },
                {
                    "zipcode": "21850",
                    "province": "Huelva",
                    "city": "Villarrasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "21860",
                    "province": "Huelva",
                    "city": "Villalba del Alcor",
                    "multiple": "0"
                },
                {
                    "zipcode": "21870",
                    "province": "Huelva",
                    "city": "Escacena del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "21880",
                    "province": "Huelva",
                    "city": "Paterna del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "21888",
                    "province": "Huelva",
                    "city": "Paterna del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "21890",
                    "province": "Huelva",
                    "city": "Manzanilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "21891",
                    "province": "Huelva",
                    "city": "Chucena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22000",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22001",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22002",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22003",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22004",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22005",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22006",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22080",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22090",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22092",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22094",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22097",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22100",
                    "province": "Huesca",
                    "city": "Sangarrén",
                    "multiple": "0"
                },
                {
                    "zipcode": "22110",
                    "province": "Huesca",
                    "city": "Sesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22110",
                    "province": "Huesca",
                    "city": "Salillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "22111",
                    "province": "Huesca",
                    "city": "Monflorite-Lascasas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22112",
                    "province": "Huesca",
                    "city": "Albero Alto",
                    "multiple": "0"
                },
                {
                    "zipcode": "22113",
                    "province": "Huesca",
                    "city": "Novales",
                    "multiple": "0"
                },
                {
                    "zipcode": "22120",
                    "province": "Huesca",
                    "city": "Siétamo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22121",
                    "province": "Huesca",
                    "city": "Siétamo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22122",
                    "province": "Huesca",
                    "city": "Angüés",
                    "multiple": "0"
                },
                {
                    "zipcode": "22122",
                    "province": "Huesca",
                    "city": "Siétamo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22122",
                    "province": "Huesca",
                    "city": "Ibieca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22123",
                    "province": "Huesca",
                    "city": "Angüés",
                    "multiple": "0"
                },
                {
                    "zipcode": "22124",
                    "province": "Huesca",
                    "city": "Lascellas-Ponzano",
                    "multiple": "0"
                },
                {
                    "zipcode": "22125",
                    "province": "Huesca",
                    "city": "Laluenga",
                    "multiple": "0"
                },
                {
                    "zipcode": "22126",
                    "province": "Huesca",
                    "city": "Laperdiguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "22131",
                    "province": "Huesca",
                    "city": "Berbegal",
                    "multiple": "0"
                },
                {
                    "zipcode": "22131",
                    "province": "Huesca",
                    "city": "Peralta de Alcofea",
                    "multiple": "1"
                },
                {
                    "zipcode": "22132",
                    "province": "Huesca",
                    "city": "Barbuñales",
                    "multiple": "0"
                },
                {
                    "zipcode": "22132",
                    "province": "Huesca",
                    "city": "Pertusa",
                    "multiple": "1"
                },
                {
                    "zipcode": "22132",
                    "province": "Huesca",
                    "city": "Torres de Alcanadre",
                    "multiple": "1"
                },
                {
                    "zipcode": "22133",
                    "province": "Huesca",
                    "city": "Angüés",
                    "multiple": "0"
                },
                {
                    "zipcode": "22133",
                    "province": "Huesca",
                    "city": "Antillón",
                    "multiple": "1"
                },
                {
                    "zipcode": "22133",
                    "province": "Huesca",
                    "city": "Blecua y Torres",
                    "multiple": "1"
                },
                {
                    "zipcode": "22134",
                    "province": "Huesca",
                    "city": "Blecua y Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "22135",
                    "province": "Huesca",
                    "city": "Alcalá del Obispo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22135",
                    "province": "Huesca",
                    "city": "Argavieso",
                    "multiple": "1"
                },
                {
                    "zipcode": "22140",
                    "province": "Huesca",
                    "city": "Loporzano",
                    "multiple": "0"
                },
                {
                    "zipcode": "22141",
                    "province": "Huesca",
                    "city": "Bierge",
                    "multiple": "0"
                },
                {
                    "zipcode": "22141",
                    "province": "Huesca",
                    "city": "Loporzano",
                    "multiple": "1"
                },
                {
                    "zipcode": "22141",
                    "province": "Huesca",
                    "city": "Casbas de Huesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22142",
                    "province": "Huesca",
                    "city": "Casbas de Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22143",
                    "province": "Huesca",
                    "city": "Abiego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22144",
                    "province": "Huesca",
                    "city": "Bierge",
                    "multiple": "0"
                },
                {
                    "zipcode": "22145",
                    "province": "Huesca",
                    "city": "Alquézar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22146",
                    "province": "Huesca",
                    "city": "Santa María de Dulcis",
                    "multiple": "0"
                },
                {
                    "zipcode": "22147",
                    "province": "Huesca",
                    "city": "Abiego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22147",
                    "province": "Huesca",
                    "city": "Adahuesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22148",
                    "province": "Huesca",
                    "city": "Bárcabo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22148",
                    "province": "Huesca",
                    "city": "Colungo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22149",
                    "province": "Huesca",
                    "city": "Bárcabo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22149",
                    "province": "Huesca",
                    "city": "Aínsa-Sobrarbe",
                    "multiple": "1"
                },
                {
                    "zipcode": "22150",
                    "province": "Huesca",
                    "city": "Arguis",
                    "multiple": "0"
                },
                {
                    "zipcode": "22160",
                    "province": "Huesca",
                    "city": "Sotonera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "22161",
                    "province": "Huesca",
                    "city": "Sotonera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "22162",
                    "province": "Huesca",
                    "city": "Sotonera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "22190",
                    "province": "Huesca",
                    "city": "Vicién",
                    "multiple": "0"
                },
                {
                    "zipcode": "22191",
                    "province": "Huesca",
                    "city": "Quicena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22192",
                    "province": "Huesca",
                    "city": "Loporzano",
                    "multiple": "0"
                },
                {
                    "zipcode": "22192",
                    "province": "Huesca",
                    "city": "Tierz",
                    "multiple": "1"
                },
                {
                    "zipcode": "22193",
                    "province": "Huesca",
                    "city": "Igriés",
                    "multiple": "0"
                },
                {
                    "zipcode": "22193",
                    "province": "Huesca",
                    "city": "Nueno",
                    "multiple": "1"
                },
                {
                    "zipcode": "22194",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22194",
                    "province": "Huesca",
                    "city": "Banastás",
                    "multiple": "1"
                },
                {
                    "zipcode": "22194",
                    "province": "Huesca",
                    "city": "Chimillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "22194",
                    "province": "Huesca",
                    "city": "Alerre",
                    "multiple": "1"
                },
                {
                    "zipcode": "22195",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22196",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22196",
                    "province": "Huesca",
                    "city": "Monflorite-Lascasas",
                    "multiple": "1"
                },
                {
                    "zipcode": "22197",
                    "province": "Huesca",
                    "city": "Huesca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22200",
                    "province": "Huesca",
                    "city": "Sariñena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22210",
                    "province": "Huesca",
                    "city": "Peralta de Alcofea",
                    "multiple": "0"
                },
                {
                    "zipcode": "22210",
                    "province": "Huesca",
                    "city": "Huerto",
                    "multiple": "1"
                },
                {
                    "zipcode": "22211",
                    "province": "Huesca",
                    "city": "Huerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "22212",
                    "province": "Huesca",
                    "city": "Alberuela de Tubo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22212",
                    "province": "Huesca",
                    "city": "Capdesaso",
                    "multiple": "1"
                },
                {
                    "zipcode": "22212",
                    "province": "Huesca",
                    "city": "Lalueza",
                    "multiple": "1"
                },
                {
                    "zipcode": "22213",
                    "province": "Huesca",
                    "city": "Sariñena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22213",
                    "province": "Huesca",
                    "city": "Peralta de Alcofea",
                    "multiple": "1"
                },
                {
                    "zipcode": "22213",
                    "province": "Huesca",
                    "city": "Lanaja",
                    "multiple": "1"
                },
                {
                    "zipcode": "22214",
                    "province": "Huesca",
                    "city": "Lalueza",
                    "multiple": "0"
                },
                {
                    "zipcode": "22215",
                    "province": "Huesca",
                    "city": "Castelflorite",
                    "multiple": "0"
                },
                {
                    "zipcode": "22215",
                    "province": "Huesca",
                    "city": "Peralta de Alcofea",
                    "multiple": "1"
                },
                {
                    "zipcode": "22215",
                    "province": "Huesca",
                    "city": "Sariñena",
                    "multiple": "1"
                },
                {
                    "zipcode": "22216",
                    "province": "Huesca",
                    "city": "Lanaja",
                    "multiple": "0"
                },
                {
                    "zipcode": "22216",
                    "province": "Huesca",
                    "city": "Poleñino",
                    "multiple": "1"
                },
                {
                    "zipcode": "22220",
                    "province": "Huesca",
                    "city": "Albalatillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22221",
                    "province": "Huesca",
                    "city": "Sariñena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22222",
                    "province": "Huesca",
                    "city": "Castejón de Monegros",
                    "multiple": "0"
                },
                {
                    "zipcode": "22223",
                    "province": "Huesca",
                    "city": "Valfarta",
                    "multiple": "0"
                },
                {
                    "zipcode": "22225",
                    "province": "Huesca",
                    "city": "Grañén",
                    "multiple": "0"
                },
                {
                    "zipcode": "22230",
                    "province": "Huesca",
                    "city": "Sena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22231",
                    "province": "Huesca",
                    "city": "Villanueva de Sigena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22232",
                    "province": "Huesca",
                    "city": "Ontiñena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22233",
                    "province": "Huesca",
                    "city": "Chalamera",
                    "multiple": "0"
                },
                {
                    "zipcode": "22234",
                    "province": "Huesca",
                    "city": "Ballobar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22240",
                    "province": "Huesca",
                    "city": "Tardienta",
                    "multiple": "0"
                },
                {
                    "zipcode": "22250",
                    "province": "Huesca",
                    "city": "Lanaja",
                    "multiple": "0"
                },
                {
                    "zipcode": "22251",
                    "province": "Huesca",
                    "city": "Alcubierre",
                    "multiple": "0"
                },
                {
                    "zipcode": "22252",
                    "province": "Huesca",
                    "city": "Robres",
                    "multiple": "0"
                },
                {
                    "zipcode": "22253",
                    "province": "Huesca",
                    "city": "Senés de Alcubierre",
                    "multiple": "0"
                },
                {
                    "zipcode": "22254",
                    "province": "Huesca",
                    "city": "Torralba de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "22255",
                    "province": "Huesca",
                    "city": "Grañén",
                    "multiple": "0"
                },
                {
                    "zipcode": "22255",
                    "province": "Huesca",
                    "city": "Almuniente",
                    "multiple": "1"
                },
                {
                    "zipcode": "22255",
                    "province": "Huesca",
                    "city": "Barbués",
                    "multiple": "1"
                },
                {
                    "zipcode": "22255",
                    "province": "Huesca",
                    "city": "Albero Bajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22255",
                    "province": "Huesca",
                    "city": "Torres de Barbués",
                    "multiple": "1"
                },
                {
                    "zipcode": "22260",
                    "province": "Huesca",
                    "city": "Grañén",
                    "multiple": "0"
                },
                {
                    "zipcode": "22268",
                    "province": "Huesca",
                    "city": "Grañén",
                    "multiple": "0"
                },
                {
                    "zipcode": "22268",
                    "province": "Huesca",
                    "city": "Piracés",
                    "multiple": "1"
                },
                {
                    "zipcode": "22268",
                    "province": "Huesca",
                    "city": "Lalueza",
                    "multiple": "1"
                },
                {
                    "zipcode": "22268",
                    "province": "Huesca",
                    "city": "Tramaced",
                    "multiple": "1"
                },
                {
                    "zipcode": "22269",
                    "province": "Huesca",
                    "city": "Grañén",
                    "multiple": "0"
                },
                {
                    "zipcode": "22269",
                    "province": "Huesca",
                    "city": "Almuniente",
                    "multiple": "1"
                },
                {
                    "zipcode": "22270",
                    "province": "Huesca",
                    "city": "Almudévar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22280",
                    "province": "Huesca",
                    "city": "Gurrea de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22281",
                    "province": "Huesca",
                    "city": "Gurrea de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22282",
                    "province": "Huesca",
                    "city": "Alcalá de Gurrea",
                    "multiple": "0"
                },
                {
                    "zipcode": "22283",
                    "province": "Huesca",
                    "city": "Alcalá de Gurrea",
                    "multiple": "0"
                },
                {
                    "zipcode": "22283",
                    "province": "Huesca",
                    "city": "Almudévar",
                    "multiple": "1"
                },
                {
                    "zipcode": "22300",
                    "province": "Huesca",
                    "city": "Barbastro",
                    "multiple": "0"
                },
                {
                    "zipcode": "22310",
                    "province": "Huesca",
                    "city": "Castejón del Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "22311",
                    "province": "Huesca",
                    "city": "Azlor",
                    "multiple": "0"
                },
                {
                    "zipcode": "22311",
                    "province": "Huesca",
                    "city": "Peraltilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "22311",
                    "province": "Huesca",
                    "city": "Azara",
                    "multiple": "1"
                },
                {
                    "zipcode": "22312",
                    "province": "Huesca",
                    "city": "Hoz y Costean",
                    "multiple": "0"
                },
                {
                    "zipcode": "22312",
                    "province": "Huesca",
                    "city": "Grado, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "22313",
                    "province": "Huesca",
                    "city": "Pozán de Vero",
                    "multiple": "0"
                },
                {
                    "zipcode": "22313",
                    "province": "Huesca",
                    "city": "Castillazuelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22313",
                    "province": "Huesca",
                    "city": "Santa María de Dulcis",
                    "multiple": "1"
                },
                {
                    "zipcode": "22314",
                    "province": "Huesca",
                    "city": "Salas Bajas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22314",
                    "province": "Huesca",
                    "city": "Salas Altas",
                    "multiple": "1"
                },
                {
                    "zipcode": "22315",
                    "province": "Huesca",
                    "city": "Hoz y Costean",
                    "multiple": "0"
                },
                {
                    "zipcode": "22315",
                    "province": "Huesca",
                    "city": "Barbastro",
                    "multiple": "1"
                },
                {
                    "zipcode": "22320",
                    "province": "Huesca",
                    "city": "Bárcabo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22320",
                    "province": "Huesca",
                    "city": "Naval",
                    "multiple": "1"
                },
                {
                    "zipcode": "22330",
                    "province": "Huesca",
                    "city": "Aínsa-Sobrarbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "22336",
                    "province": "Huesca",
                    "city": "Fueva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "22336",
                    "province": "Huesca",
                    "city": "Aínsa-Sobrarbe",
                    "multiple": "1"
                },
                {
                    "zipcode": "22337",
                    "province": "Huesca",
                    "city": "Foradada del Toscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22337",
                    "province": "Huesca",
                    "city": "Palo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22337",
                    "province": "Huesca",
                    "city": "Fueva, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "22338",
                    "province": "Huesca",
                    "city": "Pueyo de Araguás, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "22339",
                    "province": "Huesca",
                    "city": "Aínsa-Sobrarbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "22340",
                    "province": "Huesca",
                    "city": "Boltaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "22347",
                    "province": "Huesca",
                    "city": "Boltaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "22348",
                    "province": "Huesca",
                    "city": "Boltaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "22349",
                    "province": "Huesca",
                    "city": "Aínsa-Sobrarbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "22349",
                    "province": "Huesca",
                    "city": "Boltaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "22349",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22350",
                    "province": "Huesca",
                    "city": "Bielsa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22351",
                    "province": "Huesca",
                    "city": "Bielsa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22360",
                    "province": "Huesca",
                    "city": "Labuerda",
                    "multiple": "0"
                },
                {
                    "zipcode": "22361",
                    "province": "Huesca",
                    "city": "Laspaúles",
                    "multiple": "0"
                },
                {
                    "zipcode": "22361",
                    "province": "Huesca",
                    "city": "Laspuña",
                    "multiple": "1"
                },
                {
                    "zipcode": "22362",
                    "province": "Huesca",
                    "city": "Puértolas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22363",
                    "province": "Huesca",
                    "city": "Puértolas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22364",
                    "province": "Huesca",
                    "city": "Tella-Sin",
                    "multiple": "0"
                },
                {
                    "zipcode": "22365",
                    "province": "Huesca",
                    "city": "Tella-Sin",
                    "multiple": "0"
                },
                {
                    "zipcode": "22365",
                    "province": "Huesca",
                    "city": "Bielsa",
                    "multiple": "1"
                },
                {
                    "zipcode": "22366",
                    "province": "Huesca",
                    "city": "Tella-Sin",
                    "multiple": "0"
                },
                {
                    "zipcode": "22366",
                    "province": "Huesca",
                    "city": "Plan",
                    "multiple": "1"
                },
                {
                    "zipcode": "22367",
                    "province": "Huesca",
                    "city": "Plan",
                    "multiple": "0"
                },
                {
                    "zipcode": "22367",
                    "province": "Huesca",
                    "city": "San Juan de Plan",
                    "multiple": "1"
                },
                {
                    "zipcode": "22367",
                    "province": "Huesca",
                    "city": "Gistaín",
                    "multiple": "1"
                },
                {
                    "zipcode": "22370",
                    "province": "Huesca",
                    "city": "Broto",
                    "multiple": "0"
                },
                {
                    "zipcode": "22371",
                    "province": "Huesca",
                    "city": "Fiscal",
                    "multiple": "0"
                },
                {
                    "zipcode": "22372",
                    "province": "Huesca",
                    "city": "Fiscal",
                    "multiple": "0"
                },
                {
                    "zipcode": "22372",
                    "province": "Huesca",
                    "city": "Broto",
                    "multiple": "1"
                },
                {
                    "zipcode": "22373",
                    "province": "Huesca",
                    "city": "Broto",
                    "multiple": "0"
                },
                {
                    "zipcode": "22373",
                    "province": "Huesca",
                    "city": "Fiscal",
                    "multiple": "1"
                },
                {
                    "zipcode": "22374",
                    "province": "Huesca",
                    "city": "Broto",
                    "multiple": "0"
                },
                {
                    "zipcode": "22375",
                    "province": "Huesca",
                    "city": "Fanlo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22375",
                    "province": "Huesca",
                    "city": "Broto",
                    "multiple": "1"
                },
                {
                    "zipcode": "22376",
                    "province": "Huesca",
                    "city": "Torla-Ordesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22377",
                    "province": "Huesca",
                    "city": "Torla-Ordesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22378",
                    "province": "Huesca",
                    "city": "Torla-Ordesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22390",
                    "province": "Huesca",
                    "city": "Grado, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "22391",
                    "province": "Huesca",
                    "city": "Secastilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "22392",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22392",
                    "province": "Huesca",
                    "city": "Abizanda",
                    "multiple": "1"
                },
                {
                    "zipcode": "22393",
                    "province": "Huesca",
                    "city": "Naval",
                    "multiple": "0"
                },
                {
                    "zipcode": "22393",
                    "province": "Huesca",
                    "city": "Abizanda",
                    "multiple": "1"
                },
                {
                    "zipcode": "22394",
                    "province": "Huesca",
                    "city": "Aínsa-Sobrarbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "22394",
                    "province": "Huesca",
                    "city": "Fueva, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "22395",
                    "province": "Huesca",
                    "city": "Aínsa-Sobrarbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "22400",
                    "province": "Huelva",
                    "city": "Monzón",
                    "multiple": "0"
                },
                {
                    "zipcode": "22410",
                    "province": "Huesca",
                    "city": "Alcolea de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22411",
                    "province": "Huesca",
                    "city": "San Miguel del Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22412",
                    "province": "Huesca",
                    "city": "San Miguel del Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22413",
                    "province": "Huesca",
                    "city": "San Miguel del Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22414",
                    "province": "Huesca",
                    "city": "Monzón",
                    "multiple": "0"
                },
                {
                    "zipcode": "22415",
                    "province": "Huesca",
                    "city": "Ilche",
                    "multiple": "0"
                },
                {
                    "zipcode": "22415",
                    "province": "Huesca",
                    "city": "Monzón",
                    "multiple": "1"
                },
                {
                    "zipcode": "22416",
                    "province": "Huesca",
                    "city": "Alfántega",
                    "multiple": "0"
                },
                {
                    "zipcode": "22416",
                    "province": "Huesca",
                    "city": "Pueyo de Santa Cruz",
                    "multiple": "1"
                },
                {
                    "zipcode": "22417",
                    "province": "Huesca",
                    "city": "Almunia de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "22417",
                    "province": "Huesca",
                    "city": "Fonz",
                    "multiple": "1"
                },
                {
                    "zipcode": "22420",
                    "province": "Huesca",
                    "city": "Almunia de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "22421",
                    "province": "Huesca",
                    "city": "Azanuy-Alins",
                    "multiple": "0"
                },
                {
                    "zipcode": "22422",
                    "province": "Huesca",
                    "city": "Fonz",
                    "multiple": "0"
                },
                {
                    "zipcode": "22423",
                    "province": "Huesca",
                    "city": "Estadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "22424",
                    "province": "Huesca",
                    "city": "Estada",
                    "multiple": "0"
                },
                {
                    "zipcode": "22430",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "0"
                },
                {
                    "zipcode": "22435",
                    "province": "Huesca",
                    "city": "Puebla de Castro, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "22436",
                    "province": "Huesca",
                    "city": "Peñas de Riglos, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "22436",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "1"
                },
                {
                    "zipcode": "22437",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "0"
                },
                {
                    "zipcode": "22438",
                    "province": "Huesca",
                    "city": "Fueva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "22438",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "1"
                },
                {
                    "zipcode": "22439",
                    "province": "Huesca",
                    "city": "Secastilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "22439",
                    "province": "Huesca",
                    "city": "Olvena",
                    "multiple": "1"
                },
                {
                    "zipcode": "22440",
                    "province": "Huesca",
                    "city": "Benasque",
                    "multiple": "0"
                },
                {
                    "zipcode": "22449",
                    "province": "Huesca",
                    "city": "Benasque",
                    "multiple": "0"
                },
                {
                    "zipcode": "22450",
                    "province": "Huesca",
                    "city": "Foradada del Toscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22450",
                    "province": "Huesca",
                    "city": "Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22451",
                    "province": "Huesca",
                    "city": "Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22451",
                    "province": "Huesca",
                    "city": "Torre la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "22451",
                    "province": "Huesca",
                    "city": "Foradada del Toscar",
                    "multiple": "1"
                },
                {
                    "zipcode": "22451",
                    "province": "Huesca",
                    "city": "Valle de Lierp",
                    "multiple": "1"
                },
                {
                    "zipcode": "22451",
                    "province": "Huesca",
                    "city": "Valle de Bardají",
                    "multiple": "1"
                },
                {
                    "zipcode": "22452",
                    "province": "Huesca",
                    "city": "Foradada del Toscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22452",
                    "province": "Huesca",
                    "city": "Fueva, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "22460",
                    "province": "Huesca",
                    "city": "Perarrúa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22460",
                    "province": "Huesca",
                    "city": "Santaliestra y San Quílez",
                    "multiple": "1"
                },
                {
                    "zipcode": "22461",
                    "province": "Huesca",
                    "city": "Boltaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "22461",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "1"
                },
                {
                    "zipcode": "22461",
                    "province": "Huesca",
                    "city": "Santaliestra y San Quílez",
                    "multiple": "1"
                },
                {
                    "zipcode": "22462",
                    "province": "Huesca",
                    "city": "Foradada del Toscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22462",
                    "province": "Huesca",
                    "city": "Isábena",
                    "multiple": "1"
                },
                {
                    "zipcode": "22463",
                    "province": "Huesca",
                    "city": "Seira",
                    "multiple": "0"
                },
                {
                    "zipcode": "22464",
                    "province": "Huesca",
                    "city": "Seira",
                    "multiple": "0"
                },
                {
                    "zipcode": "22465",
                    "province": "Huesca",
                    "city": "Chía",
                    "multiple": "0"
                },
                {
                    "zipcode": "22465",
                    "province": "Huesca",
                    "city": "Castejón de Sos",
                    "multiple": "1"
                },
                {
                    "zipcode": "22466",
                    "province": "Huesca",
                    "city": "Castejón de Sos",
                    "multiple": "0"
                },
                {
                    "zipcode": "22466",
                    "province": "Huesca",
                    "city": "Bisaurri",
                    "multiple": "1"
                },
                {
                    "zipcode": "22467",
                    "province": "Huesca",
                    "city": "Castejón de Sos",
                    "multiple": "0"
                },
                {
                    "zipcode": "22467",
                    "province": "Huesca",
                    "city": "Sahún",
                    "multiple": "1"
                },
                {
                    "zipcode": "22467",
                    "province": "Huesca",
                    "city": "Sesué",
                    "multiple": "1"
                },
                {
                    "zipcode": "22467",
                    "province": "Huesca",
                    "city": "Villanova",
                    "multiple": "1"
                },
                {
                    "zipcode": "22468",
                    "province": "Huesca",
                    "city": "Sahún",
                    "multiple": "0"
                },
                {
                    "zipcode": "22469",
                    "province": "Huesca",
                    "city": "Benasque",
                    "multiple": "0"
                },
                {
                    "zipcode": "22469",
                    "province": "Huesca",
                    "city": "Sahún",
                    "multiple": "1"
                },
                {
                    "zipcode": "22470",
                    "province": "Huesca",
                    "city": "Bisaurri",
                    "multiple": "0"
                },
                {
                    "zipcode": "22471",
                    "province": "Huesca",
                    "city": "Laspaúles",
                    "multiple": "0"
                },
                {
                    "zipcode": "22472",
                    "province": "Huesca",
                    "city": "Laspaúles",
                    "multiple": "0"
                },
                {
                    "zipcode": "22473",
                    "province": "Huesca",
                    "city": "Laspaúles",
                    "multiple": "0"
                },
                {
                    "zipcode": "22473",
                    "province": "Huesca",
                    "city": "Montanuy",
                    "multiple": "1"
                },
                {
                    "zipcode": "22474",
                    "province": "Huesca",
                    "city": "Laspaúles",
                    "multiple": "0"
                },
                {
                    "zipcode": "22474",
                    "province": "Huesca",
                    "city": "Montanuy",
                    "multiple": "1"
                },
                {
                    "zipcode": "22480",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "0"
                },
                {
                    "zipcode": "22480",
                    "province": "Huesca",
                    "city": "Capella",
                    "multiple": "1"
                },
                {
                    "zipcode": "22481",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "0"
                },
                {
                    "zipcode": "22482",
                    "province": "Huesca",
                    "city": "Isábena",
                    "multiple": "0"
                },
                {
                    "zipcode": "22483",
                    "province": "Huesca",
                    "city": "Torre la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "22483",
                    "province": "Huesca",
                    "city": "Isábena",
                    "multiple": "1"
                },
                {
                    "zipcode": "22483",
                    "province": "Huesca",
                    "city": "Valle de Lierp",
                    "multiple": "1"
                },
                {
                    "zipcode": "22484",
                    "province": "Huesca",
                    "city": "Beranuy",
                    "multiple": "0"
                },
                {
                    "zipcode": "22484",
                    "province": "Huesca",
                    "city": "Torre la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "22484",
                    "province": "Huesca",
                    "city": "Isábena",
                    "multiple": "1"
                },
                {
                    "zipcode": "22485",
                    "province": "Huesca",
                    "city": "Beranuy",
                    "multiple": "0"
                },
                {
                    "zipcode": "22486",
                    "province": "Huesca",
                    "city": "Bonansa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22486",
                    "province": "Huesca",
                    "city": "Montanuy",
                    "multiple": "1"
                },
                {
                    "zipcode": "22487",
                    "province": "Huesca",
                    "city": "Montanuy",
                    "multiple": "0"
                },
                {
                    "zipcode": "22500",
                    "province": "Huesca",
                    "city": "Binéfar",
                    "multiple": "0"
                },
                {
                    "zipcode": "22510",
                    "province": "Huesca",
                    "city": "Binaced",
                    "multiple": "0"
                },
                {
                    "zipcode": "22511",
                    "province": "Huesca",
                    "city": "Binaced",
                    "multiple": "0"
                },
                {
                    "zipcode": "22512",
                    "province": "Huesca",
                    "city": "San Esteban de Litera",
                    "multiple": "0"
                },
                {
                    "zipcode": "22513",
                    "province": "Huesca",
                    "city": "Peralta de Calasanz",
                    "multiple": "0"
                },
                {
                    "zipcode": "22514",
                    "province": "Huesca",
                    "city": "Peralta de Calasanz",
                    "multiple": "0"
                },
                {
                    "zipcode": "22520",
                    "province": "Huesca",
                    "city": "Fraga",
                    "multiple": "0"
                },
                {
                    "zipcode": "22528",
                    "province": "Huesca",
                    "city": "Velilla de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22529",
                    "province": "Huesca",
                    "city": "Fraga",
                    "multiple": "0"
                },
                {
                    "zipcode": "22530",
                    "province": "Huesca",
                    "city": "Zaidín",
                    "multiple": "0"
                },
                {
                    "zipcode": "22531",
                    "province": "Huesca",
                    "city": "Osso de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22532",
                    "province": "Huesca",
                    "city": "Osso de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22533",
                    "province": "Huesca",
                    "city": "Belver de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22534",
                    "province": "Huesca",
                    "city": "Albalate de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22535",
                    "province": "Huesca",
                    "city": "Esplús",
                    "multiple": "0"
                },
                {
                    "zipcode": "22536",
                    "province": "Huesca",
                    "city": "Belver de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22540",
                    "province": "Huesca",
                    "city": "Altorricón",
                    "multiple": "0"
                },
                {
                    "zipcode": "22549",
                    "province": "Huesca",
                    "city": "Vencillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "22549",
                    "province": "Huesca",
                    "city": "Belver de Cinca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22549",
                    "province": "Huesca",
                    "city": "Tamarite de Litera",
                    "multiple": "1"
                },
                {
                    "zipcode": "22550",
                    "province": "Huesca",
                    "city": "Tamarite de Litera",
                    "multiple": "0"
                },
                {
                    "zipcode": "22558",
                    "province": "Huesca",
                    "city": "Albelda",
                    "multiple": "0"
                },
                {
                    "zipcode": "22559",
                    "province": "Huesca",
                    "city": "Tamarite de Litera",
                    "multiple": "0"
                },
                {
                    "zipcode": "22560",
                    "province": "Huesca",
                    "city": "Alcampell",
                    "multiple": "0"
                },
                {
                    "zipcode": "22569",
                    "province": "Huesca",
                    "city": "Baélls",
                    "multiple": "0"
                },
                {
                    "zipcode": "22569",
                    "province": "Huesca",
                    "city": "Peralta de Calasanz",
                    "multiple": "1"
                },
                {
                    "zipcode": "22570",
                    "province": "Huesca",
                    "city": "Castillonroy",
                    "multiple": "0"
                },
                {
                    "zipcode": "22570",
                    "province": "Huesca",
                    "city": "Camporrélls",
                    "multiple": "1"
                },
                {
                    "zipcode": "22571",
                    "province": "Huesca",
                    "city": "Baldellou",
                    "multiple": "0"
                },
                {
                    "zipcode": "22572",
                    "province": "Huesca",
                    "city": "Castillonroy",
                    "multiple": "0"
                },
                {
                    "zipcode": "22572",
                    "province": "Huesca",
                    "city": "Estopiñán del Castillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22580",
                    "province": "Huesca",
                    "city": "Benabarre",
                    "multiple": "0"
                },
                {
                    "zipcode": "22583",
                    "province": "Huesca",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "22583",
                    "province": "Huesca",
                    "city": "Sopeira",
                    "multiple": "1"
                },
                {
                    "zipcode": "22583",
                    "province": "Huesca",
                    "city": "Arén",
                    "multiple": "1"
                },
                {
                    "zipcode": "22584",
                    "province": "Huesca",
                    "city": "Viacamp y Litera",
                    "multiple": "0"
                },
                {
                    "zipcode": "22584",
                    "province": "Huesca",
                    "city": "Tremp",
                    "multiple": "1"
                },
                {
                    "zipcode": "22584",
                    "province": "Huesca",
                    "city": "Puente de Montañana",
                    "multiple": "1"
                },
                {
                    "zipcode": "22585",
                    "province": "Huesca",
                    "city": "Tolva",
                    "multiple": "0"
                },
                {
                    "zipcode": "22585",
                    "province": "Huesca",
                    "city": "Bárcabo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22585",
                    "province": "Huesca",
                    "city": "Salas Altas",
                    "multiple": "1"
                },
                {
                    "zipcode": "22585",
                    "province": "Huesca",
                    "city": "Castigaleu",
                    "multiple": "1"
                },
                {
                    "zipcode": "22585",
                    "province": "Huesca",
                    "city": "Fraga",
                    "multiple": "1"
                },
                {
                    "zipcode": "22585",
                    "province": "Huesca",
                    "city": "Viacamp y Litera",
                    "multiple": "1"
                },
                {
                    "zipcode": "22586",
                    "province": "Huesca",
                    "city": "Lascuarre",
                    "multiple": "0"
                },
                {
                    "zipcode": "22587",
                    "province": "Huesca",
                    "city": "Capella",
                    "multiple": "0"
                },
                {
                    "zipcode": "22587",
                    "province": "Huesca",
                    "city": "Castigaleu",
                    "multiple": "1"
                },
                {
                    "zipcode": "22587",
                    "province": "Huesca",
                    "city": "Monesma y Cajigar",
                    "multiple": "1"
                },
                {
                    "zipcode": "22588",
                    "province": "Huesca",
                    "city": "Benabarre",
                    "multiple": "0"
                },
                {
                    "zipcode": "22588",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "1"
                },
                {
                    "zipcode": "22589",
                    "province": "Huesca",
                    "city": "Estopiñán del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22589",
                    "province": "Huesca",
                    "city": "Benabarre",
                    "multiple": "1"
                },
                {
                    "zipcode": "22590",
                    "province": "Huesca",
                    "city": "Torrente de Cinca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22591",
                    "province": "Huesca",
                    "city": "Candasnos",
                    "multiple": "0"
                },
                {
                    "zipcode": "22592",
                    "province": "Huesca",
                    "city": "Peñalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "22600",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22609",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22610",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22610",
                    "province": "Huesca",
                    "city": "Yebra de Basa",
                    "multiple": "1"
                },
                {
                    "zipcode": "22611",
                    "province": "Huesca",
                    "city": "Yebra de Basa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22612",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22613",
                    "province": "Huesca",
                    "city": "Biescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22613",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22620",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22620",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22621",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22621",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22622",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22622",
                    "province": "Huesca",
                    "city": "Nueno",
                    "multiple": "1"
                },
                {
                    "zipcode": "22623",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22624",
                    "province": "Huesca",
                    "city": "Caldearenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22625",
                    "province": "Huesca",
                    "city": "Caldearenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22630",
                    "province": "Huesca",
                    "city": "Biescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22636",
                    "province": "Huesca",
                    "city": "Biescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22636",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22637",
                    "province": "Huesca",
                    "city": "Biescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22638",
                    "province": "Huesca",
                    "city": "Biescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22639",
                    "province": "Huesca",
                    "city": "Yésero",
                    "multiple": "0"
                },
                {
                    "zipcode": "22639",
                    "province": "Huesca",
                    "city": "Biescas",
                    "multiple": "1"
                },
                {
                    "zipcode": "22640",
                    "province": "Huesca",
                    "city": "Sallent de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22650",
                    "province": "Huesca",
                    "city": "Panticosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22660",
                    "province": "Huesca",
                    "city": "Sallent de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22661",
                    "province": "Huesca",
                    "city": "Panticosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22662",
                    "province": "Huesca",
                    "city": "Panticosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22662",
                    "province": "Huesca",
                    "city": "Hoz de Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22663",
                    "province": "Huesca",
                    "city": "Sallent de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22664",
                    "province": "Huesca",
                    "city": "Sallent de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22665",
                    "province": "Huesca",
                    "city": "Biescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "22666",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22700",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22710",
                    "province": "Huesca",
                    "city": "Castiello de Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22710",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22711",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22712",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22713",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22714",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22714",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22715",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22720",
                    "province": "Huesca",
                    "city": "Valle de Hecho",
                    "multiple": "0"
                },
                {
                    "zipcode": "22725",
                    "province": "Huesca",
                    "city": "Ansó",
                    "multiple": "0"
                },
                {
                    "zipcode": "22728",
                    "province": "Huesca",
                    "city": "Ansó",
                    "multiple": "0"
                },
                {
                    "zipcode": "22729",
                    "province": "Huesca",
                    "city": "Fago",
                    "multiple": "0"
                },
                {
                    "zipcode": "22730",
                    "province": "Huesca",
                    "city": "Aragüés del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "22731",
                    "province": "Huesca",
                    "city": "Jasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22732",
                    "province": "Huesca",
                    "city": "Valle de Hecho",
                    "multiple": "0"
                },
                {
                    "zipcode": "22740",
                    "province": "Huesca",
                    "city": "Valle de Hecho",
                    "multiple": "0"
                },
                {
                    "zipcode": "22750",
                    "province": "Huesca",
                    "city": "Puente la Reina de Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22751",
                    "province": "Huesca",
                    "city": "Bailo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22751",
                    "province": "Huesca",
                    "city": "Puente la Reina de Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22752",
                    "province": "Huesca",
                    "city": "Santa Cilia",
                    "multiple": "0"
                },
                {
                    "zipcode": "22753",
                    "province": "Huesca",
                    "city": "Puente la Reina de Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22760",
                    "province": "Huesca",
                    "city": "Bailo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22761",
                    "province": "Huesca",
                    "city": "Bailo",
                    "multiple": "0"
                },
                {
                    "zipcode": "22770",
                    "province": "Huesca",
                    "city": "Canal de Berdún",
                    "multiple": "0"
                },
                {
                    "zipcode": "22771",
                    "province": "Huesca",
                    "city": "Canal de Berdún",
                    "multiple": "0"
                },
                {
                    "zipcode": "22772",
                    "province": "Huesca",
                    "city": "Canal de Berdún",
                    "multiple": "0"
                },
                {
                    "zipcode": "22773",
                    "province": "Huesca",
                    "city": "Canal de Berdún",
                    "multiple": "0"
                },
                {
                    "zipcode": "22790",
                    "province": "Huesca",
                    "city": "Valle de Hecho",
                    "multiple": "0"
                },
                {
                    "zipcode": "22791",
                    "province": "Huesca",
                    "city": "Santa Cilia",
                    "multiple": "0"
                },
                {
                    "zipcode": "22791",
                    "province": "Huesca",
                    "city": "Santa Cruz de la Serós",
                    "multiple": "1"
                },
                {
                    "zipcode": "22792",
                    "province": "Huesca",
                    "city": "Santa Cruz de la Serós",
                    "multiple": "0"
                },
                {
                    "zipcode": "22800",
                    "province": "Huesca",
                    "city": "Ayerbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "22806",
                    "province": "Huesca",
                    "city": "Santa Eulalia de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "22807",
                    "province": "Huesca",
                    "city": "Biscarrués",
                    "multiple": "0"
                },
                {
                    "zipcode": "22807",
                    "province": "Huesca",
                    "city": "Ayerbe",
                    "multiple": "1"
                },
                {
                    "zipcode": "22808",
                    "province": "Huesca",
                    "city": "Agüero",
                    "multiple": "0"
                },
                {
                    "zipcode": "22808",
                    "province": "Huesca",
                    "city": "Peñas de Riglos, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "22808",
                    "province": "Huesca",
                    "city": "Murillo de Gállego",
                    "multiple": "1"
                },
                {
                    "zipcode": "22809",
                    "province": "Huesca",
                    "city": "Loscorrales",
                    "multiple": "0"
                },
                {
                    "zipcode": "22809",
                    "province": "Huesca",
                    "city": "Loarre",
                    "multiple": "1"
                },
                {
                    "zipcode": "22809",
                    "province": "Huesca",
                    "city": "Ayerbe",
                    "multiple": "1"
                },
                {
                    "zipcode": "22810",
                    "province": "Huesca",
                    "city": "Sotonera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "22810",
                    "province": "Huesca",
                    "city": "Lupiñén-Ortilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "22811",
                    "province": "Huesca",
                    "city": "Lupiñén-Ortilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "22820",
                    "province": "Huesca",
                    "city": "Peñas de Riglos, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "22821",
                    "province": "Huesca",
                    "city": "Peñas de Riglos, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "22822",
                    "province": "Huesca",
                    "city": "Peñas de Riglos, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "22830",
                    "province": "Huesca",
                    "city": "Graus",
                    "multiple": "0"
                },
                {
                    "zipcode": "22830",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22830",
                    "province": "Huesca",
                    "city": "Peñas de Riglos, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "22830",
                    "province": "Huesca",
                    "city": "Caldearenas",
                    "multiple": "1"
                },
                {
                    "zipcode": "22850",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "0"
                },
                {
                    "zipcode": "22850",
                    "province": "Huesca",
                    "city": "Sabiñánigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "22860",
                    "province": "Huesca",
                    "city": "Borau",
                    "multiple": "0"
                },
                {
                    "zipcode": "22860",
                    "province": "Huesca",
                    "city": "Aisa",
                    "multiple": "1"
                },
                {
                    "zipcode": "22860",
                    "province": "Huesca",
                    "city": "Castiello de Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "22870",
                    "province": "Huesca",
                    "city": "Villanúa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22880",
                    "province": "Huesca",
                    "city": "Canfranc",
                    "multiple": "0"
                },
                {
                    "zipcode": "22888",
                    "province": "Huesca",
                    "city": "Canfranc",
                    "multiple": "0"
                },
                {
                    "zipcode": "22889",
                    "province": "Huesca",
                    "city": "Aisa",
                    "multiple": "0"
                },
                {
                    "zipcode": "22889",
                    "province": "Huesca",
                    "city": "Jaca",
                    "multiple": "1"
                },
                {
                    "zipcode": "23001",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23002",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23003",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23004",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23005",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23006",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23007",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23008",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23009",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23100",
                    "province": "Jaén",
                    "city": "Mancha Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23110",
                    "province": "Jaén",
                    "city": "Pegalajar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23120",
                    "province": "Jaén",
                    "city": "Cambil",
                    "multiple": "0"
                },
                {
                    "zipcode": "23130",
                    "province": "Jaén",
                    "city": "Campillo de Arenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "23140",
                    "province": "Jaén",
                    "city": "Noalejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23150",
                    "province": "Jaén",
                    "city": "Valdepeñas de Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23159",
                    "province": "Jaén",
                    "city": "Valdepeñas de Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23160",
                    "province": "Jaén",
                    "city": "Villares, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "23170",
                    "province": "Jaén",
                    "city": "Guardia de Jaén, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "23180",
                    "province": "Jaén",
                    "city": "Fuerte del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "23190",
                    "province": "Jaén",
                    "city": "Pegalajar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23191",
                    "province": "Jaén",
                    "city": "Cárcheles",
                    "multiple": "0"
                },
                {
                    "zipcode": "23192",
                    "province": "Jaén",
                    "city": "Cárcheles",
                    "multiple": "0"
                },
                {
                    "zipcode": "23193",
                    "province": "Jaén",
                    "city": "Cambil",
                    "multiple": "0"
                },
                {
                    "zipcode": "23194",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23196",
                    "province": "Jaén",
                    "city": "Valdepeñas de Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23196",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "1"
                },
                {
                    "zipcode": "23200",
                    "province": "Jaén",
                    "city": "Carolina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "23210",
                    "province": "Jaén",
                    "city": "Guarromán",
                    "multiple": "0"
                },
                {
                    "zipcode": "23211",
                    "province": "Jaén",
                    "city": "Carboneros",
                    "multiple": "0"
                },
                {
                    "zipcode": "23212",
                    "province": "Jaén",
                    "city": "Carolina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "23213",
                    "province": "Jaén",
                    "city": "Santa Elena",
                    "multiple": "0"
                },
                {
                    "zipcode": "23214",
                    "province": "Jaén",
                    "city": "Carolina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "23214",
                    "province": "Jaén",
                    "city": "Baños de la Encina",
                    "multiple": "1"
                },
                {
                    "zipcode": "23215",
                    "province": "Jaén",
                    "city": "Aldeaquemada",
                    "multiple": "0"
                },
                {
                    "zipcode": "23220",
                    "province": "Jaén",
                    "city": "Vilches",
                    "multiple": "0"
                },
                {
                    "zipcode": "23230",
                    "province": "Jaén",
                    "city": "Arquillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23239",
                    "province": "Jaén",
                    "city": "Arquillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23240",
                    "province": "Jaén",
                    "city": "Navas de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "23250",
                    "province": "Jaén",
                    "city": "Santisteban del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "23260",
                    "province": "Jaén",
                    "city": "Castellar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23264",
                    "province": "Jaén",
                    "city": "Chiclana de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "23265",
                    "province": "Jaén",
                    "city": "Montizón",
                    "multiple": "0"
                },
                {
                    "zipcode": "23266",
                    "province": "Jaén",
                    "city": "Montizón",
                    "multiple": "0"
                },
                {
                    "zipcode": "23267",
                    "province": "Jaén",
                    "city": "Montizón",
                    "multiple": "0"
                },
                {
                    "zipcode": "23268",
                    "province": "Jaén",
                    "city": "Chiclana de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "23269",
                    "province": "Jaén",
                    "city": "Chiclana de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "23270",
                    "province": "Jaén",
                    "city": "Sorihuela del Guadalimar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23280",
                    "province": "Jaén",
                    "city": "Beas de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "23289",
                    "province": "Jaén",
                    "city": "Arroyo del Ojanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "23289",
                    "province": "Jaén",
                    "city": "Beas de Segura",
                    "multiple": "1"
                },
                {
                    "zipcode": "23290",
                    "province": "Jaén",
                    "city": "Santiago-Pontones",
                    "multiple": "0"
                },
                {
                    "zipcode": "23291",
                    "province": "Jaén",
                    "city": "Santiago-Pontones",
                    "multiple": "0"
                },
                {
                    "zipcode": "23292",
                    "province": "Jaén",
                    "city": "Hornos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23293",
                    "province": "Jaén",
                    "city": "Segura de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "23293",
                    "province": "Jaén",
                    "city": "Hornos",
                    "multiple": "1"
                },
                {
                    "zipcode": "23294",
                    "province": "Jaén",
                    "city": "Santiago-Pontones",
                    "multiple": "0"
                },
                {
                    "zipcode": "23294",
                    "province": "Jaén",
                    "city": "Segura de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "23295",
                    "province": "Jaén",
                    "city": "Segura de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "23296",
                    "province": "Jaén",
                    "city": "Santiago-Pontones",
                    "multiple": "0"
                },
                {
                    "zipcode": "23297",
                    "province": "Jaén",
                    "city": "Santiago-Pontones",
                    "multiple": "0"
                },
                {
                    "zipcode": "23298",
                    "province": "Jaén",
                    "city": "Santiago-Pontones",
                    "multiple": "0"
                },
                {
                    "zipcode": "23300",
                    "province": "Jaén",
                    "city": "Villacarrillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23310",
                    "province": "Jaén",
                    "city": "Villacarrillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23311",
                    "province": "Jaén",
                    "city": "Santo Tomé",
                    "multiple": "0"
                },
                {
                    "zipcode": "23312",
                    "province": "Jaén",
                    "city": "Villacarrillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23314",
                    "province": "Jaén",
                    "city": "Úbeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "23315",
                    "province": "Jaén",
                    "city": "Villacarrillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23320",
                    "province": "Jaén",
                    "city": "Torreperogil",
                    "multiple": "0"
                },
                {
                    "zipcode": "23330",
                    "province": "Jaén",
                    "city": "Villanueva del Arzobispo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23337",
                    "province": "Jaén",
                    "city": "Hornos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23338",
                    "province": "Jaén",
                    "city": "Iznatoraf",
                    "multiple": "0"
                },
                {
                    "zipcode": "23339",
                    "province": "Jaén",
                    "city": "Villanueva del Arzobispo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23340",
                    "province": "Jaén",
                    "city": "Arroyo del Ojanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "23340",
                    "province": "Jaén",
                    "city": "Segura de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "23350",
                    "province": "Jaén",
                    "city": "Puente de Génave",
                    "multiple": "0"
                },
                {
                    "zipcode": "23359",
                    "province": "Jaén",
                    "city": "Puente de Génave",
                    "multiple": "0"
                },
                {
                    "zipcode": "23360",
                    "province": "Jaén",
                    "city": "Puerta de Segura, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "23369",
                    "province": "Jaén",
                    "city": "Puerta de Segura, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "23370",
                    "province": "Jaén",
                    "city": "Orcera",
                    "multiple": "0"
                },
                {
                    "zipcode": "23379",
                    "province": "Jaén",
                    "city": "Segura de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "23380",
                    "province": "Jaén",
                    "city": "Siles",
                    "multiple": "0"
                },
                {
                    "zipcode": "23390",
                    "province": "Jaén",
                    "city": "Benatae",
                    "multiple": "0"
                },
                {
                    "zipcode": "23391",
                    "province": "Jaén",
                    "city": "Torres de Albánchez",
                    "multiple": "0"
                },
                {
                    "zipcode": "23392",
                    "province": "Jaén",
                    "city": "Génave",
                    "multiple": "0"
                },
                {
                    "zipcode": "23393",
                    "province": "Jaén",
                    "city": "Villarrodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23400",
                    "province": "Jaén",
                    "city": "Úbeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "23410",
                    "province": "Jaén",
                    "city": "Sabiote",
                    "multiple": "0"
                },
                {
                    "zipcode": "23411",
                    "province": "Jaén",
                    "city": "Baeza",
                    "multiple": "0"
                },
                {
                    "zipcode": "23412",
                    "province": "Jaén",
                    "city": "Rus",
                    "multiple": "0"
                },
                {
                    "zipcode": "23413",
                    "province": "Jaén",
                    "city": "Úbeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "23420",
                    "province": "Jaén",
                    "city": "Canena",
                    "multiple": "0"
                },
                {
                    "zipcode": "23430",
                    "province": "Jaén",
                    "city": "Rus",
                    "multiple": "0"
                },
                {
                    "zipcode": "23440",
                    "province": "Jaén",
                    "city": "Baeza",
                    "multiple": "0"
                },
                {
                    "zipcode": "23450",
                    "province": "Jaén",
                    "city": "Ibros",
                    "multiple": "0"
                },
                {
                    "zipcode": "23460",
                    "province": "Jaén",
                    "city": "Peal de Becerro",
                    "multiple": "0"
                },
                {
                    "zipcode": "23468",
                    "province": "Jaén",
                    "city": "Peal de Becerro",
                    "multiple": "0"
                },
                {
                    "zipcode": "23469",
                    "province": "Jaén",
                    "city": "Úbeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "23469",
                    "province": "Jaén",
                    "city": "Cazorla",
                    "multiple": "1"
                },
                {
                    "zipcode": "23470",
                    "province": "Jaén",
                    "city": "Chilluévar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23470",
                    "province": "Jaén",
                    "city": "Cazorla",
                    "multiple": "1"
                },
                {
                    "zipcode": "23476",
                    "province": "Jaén",
                    "city": "Iruela, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "23477",
                    "province": "Jaén",
                    "city": "Chilluévar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23478",
                    "province": "Jaén",
                    "city": "Santiago-Pontones",
                    "multiple": "0"
                },
                {
                    "zipcode": "23479",
                    "province": "Jaén",
                    "city": "Cazorla",
                    "multiple": "0"
                },
                {
                    "zipcode": "23479",
                    "province": "Jaén",
                    "city": "Iruela, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "23480",
                    "province": "Jaén",
                    "city": "Quesada",
                    "multiple": "0"
                },
                {
                    "zipcode": "23485",
                    "province": "Jaén",
                    "city": "Peal de Becerro",
                    "multiple": "0"
                },
                {
                    "zipcode": "23485",
                    "province": "Jaén",
                    "city": "Pozo Alcón",
                    "multiple": "1"
                },
                {
                    "zipcode": "23485",
                    "province": "Jaén",
                    "city": "Cazorla",
                    "multiple": "1"
                },
                {
                    "zipcode": "23486",
                    "province": "Jaén",
                    "city": "Pozo Alcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "23486",
                    "province": "Jaén",
                    "city": "Hinojares",
                    "multiple": "1"
                },
                {
                    "zipcode": "23487",
                    "province": "Jaén",
                    "city": "Huesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "23488",
                    "province": "Jaén",
                    "city": "Frailes",
                    "multiple": "0"
                },
                {
                    "zipcode": "23488",
                    "province": "Jaén",
                    "city": "Quesada",
                    "multiple": "1"
                },
                {
                    "zipcode": "23489",
                    "province": "Jaén",
                    "city": "Quesada",
                    "multiple": "0"
                },
                {
                    "zipcode": "23490",
                    "province": "Jaén",
                    "city": "Linares",
                    "multiple": "0"
                },
                {
                    "zipcode": "23490",
                    "province": "Jaén",
                    "city": "Ibros",
                    "multiple": "1"
                },
                {
                    "zipcode": "23499",
                    "province": "Jaén",
                    "city": "Vilches",
                    "multiple": "0"
                },
                {
                    "zipcode": "23500",
                    "province": "Jaén",
                    "city": "Jódar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23509",
                    "province": "Jaén",
                    "city": "Úbeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "23510",
                    "province": "Jaén",
                    "city": "Torreblascopedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "23519",
                    "province": "Jaén",
                    "city": "Lupión",
                    "multiple": "0"
                },
                {
                    "zipcode": "23519",
                    "province": "Jaén",
                    "city": "Torreblascopedro",
                    "multiple": "1"
                },
                {
                    "zipcode": "23520",
                    "province": "Jaén",
                    "city": "Begíjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23528",
                    "province": "Jaén",
                    "city": "Lupión",
                    "multiple": "0"
                },
                {
                    "zipcode": "23529",
                    "province": "Jaén",
                    "city": "Baeza",
                    "multiple": "0"
                },
                {
                    "zipcode": "23529",
                    "province": "Jaén",
                    "city": "Mancha Real",
                    "multiple": "1"
                },
                {
                    "zipcode": "23529",
                    "province": "Jaén",
                    "city": "Begíjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "23529",
                    "province": "Jaén",
                    "city": "Villatorres",
                    "multiple": "1"
                },
                {
                    "zipcode": "23530",
                    "province": "Jaén",
                    "city": "Jimena",
                    "multiple": "0"
                },
                {
                    "zipcode": "23537",
                    "province": "Jaén",
                    "city": "Bedmar y Garcíez",
                    "multiple": "0"
                },
                {
                    "zipcode": "23538",
                    "province": "Jaén",
                    "city": "Albanchez de Mágina",
                    "multiple": "0"
                },
                {
                    "zipcode": "23539",
                    "province": "Jaén",
                    "city": "Baeza",
                    "multiple": "0"
                },
                {
                    "zipcode": "23539",
                    "province": "Jaén",
                    "city": "Bedmar y Garcíez",
                    "multiple": "1"
                },
                {
                    "zipcode": "23540",
                    "province": "Jaén",
                    "city": "Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "23550",
                    "province": "Jaén",
                    "city": "Cabra del Santo Cristo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23560",
                    "province": "Jaén",
                    "city": "Huelma",
                    "multiple": "0"
                },
                {
                    "zipcode": "23568",
                    "province": "Jaén",
                    "city": "Bélmez de la Moraleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "23569",
                    "province": "Jaén",
                    "city": "Huelma",
                    "multiple": "0"
                },
                {
                    "zipcode": "23569",
                    "province": "Jaén",
                    "city": "Hornos",
                    "multiple": "1"
                },
                {
                    "zipcode": "23590",
                    "province": "Jaén",
                    "city": "Cabra del Santo Cristo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23591",
                    "province": "Jaén",
                    "city": "Larva",
                    "multiple": "0"
                },
                {
                    "zipcode": "23591",
                    "province": "Jaén",
                    "city": "Cabra del Santo Cristo",
                    "multiple": "1"
                },
                {
                    "zipcode": "23600",
                    "province": "Jaén",
                    "city": "Martos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23610",
                    "province": "Jaén",
                    "city": "Fuensanta de Martos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23611",
                    "province": "Jaén",
                    "city": "Higuera de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "23612",
                    "province": "Jaén",
                    "city": "Santiago de Calatrava",
                    "multiple": "0"
                },
                {
                    "zipcode": "23614",
                    "province": "Jaén",
                    "city": "Martos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23615",
                    "province": "Jaén",
                    "city": "Martos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23616",
                    "province": "Jaén",
                    "city": "Fuensanta de Martos",
                    "multiple": "0"
                },
                {
                    "zipcode": "23620",
                    "province": "Jaén",
                    "city": "Mengíbar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23628",
                    "province": "Jaén",
                    "city": "Cazalilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "23628",
                    "province": "Jaén",
                    "city": "Espelúy",
                    "multiple": "1"
                },
                {
                    "zipcode": "23629",
                    "province": "Jaén",
                    "city": "Jabalquinto",
                    "multiple": "0"
                },
                {
                    "zipcode": "23630",
                    "province": "Jaén",
                    "city": "Villatorres",
                    "multiple": "0"
                },
                {
                    "zipcode": "23638",
                    "province": "Jaén",
                    "city": "Villatorres",
                    "multiple": "0"
                },
                {
                    "zipcode": "23639",
                    "province": "Jaén",
                    "city": "Jaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23640",
                    "province": "Jaén",
                    "city": "Torredelcampo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23650",
                    "province": "Jaén",
                    "city": "Torredonjimeno",
                    "multiple": "0"
                },
                {
                    "zipcode": "23657",
                    "province": "Jaén",
                    "city": "Escañuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "23658",
                    "province": "Jaén",
                    "city": "Jamilena",
                    "multiple": "0"
                },
                {
                    "zipcode": "23659",
                    "province": "Jaén",
                    "city": "Villardompardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23660",
                    "province": "Jaén",
                    "city": "Alcaudete",
                    "multiple": "0"
                },
                {
                    "zipcode": "23669",
                    "province": "Jaén",
                    "city": "Alcaudete",
                    "multiple": "0"
                },
                {
                    "zipcode": "23670",
                    "province": "Jaén",
                    "city": "Castillo de Locubín",
                    "multiple": "0"
                },
                {
                    "zipcode": "23680",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23684",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23685",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23685",
                    "province": "Jaén",
                    "city": "Alcaudete",
                    "multiple": "1"
                },
                {
                    "zipcode": "23686",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23686",
                    "province": "Jaén",
                    "city": "Frailes",
                    "multiple": "1"
                },
                {
                    "zipcode": "23687",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23688",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23689",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23690",
                    "province": "Jaén",
                    "city": "Frailes",
                    "multiple": "0"
                },
                {
                    "zipcode": "23691",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23691",
                    "province": "Jaén",
                    "city": "Frailes",
                    "multiple": "1"
                },
                {
                    "zipcode": "23692",
                    "province": "Jaén",
                    "city": "Alcalá la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "23693",
                    "province": "Jaén",
                    "city": "Castillo de Locubín",
                    "multiple": "0"
                },
                {
                    "zipcode": "23700",
                    "province": "Jaén",
                    "city": "Linares",
                    "multiple": "0"
                },
                {
                    "zipcode": "23710",
                    "province": "Jaén",
                    "city": "Bailén",
                    "multiple": "0"
                },
                {
                    "zipcode": "23711",
                    "province": "Jaén",
                    "city": "Baños de la Encina",
                    "multiple": "0"
                },
                {
                    "zipcode": "23712",
                    "province": "Jaén",
                    "city": "Jabalquinto",
                    "multiple": "0"
                },
                {
                    "zipcode": "23713",
                    "province": "Jaén",
                    "city": "Vilches",
                    "multiple": "0"
                },
                {
                    "zipcode": "23713",
                    "province": "Jaén",
                    "city": "Linares",
                    "multiple": "1"
                },
                {
                    "zipcode": "23720",
                    "province": "Jaén",
                    "city": "Espelúy",
                    "multiple": "0"
                },
                {
                    "zipcode": "23730",
                    "province": "Jaén",
                    "city": "Villanueva de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "23740",
                    "province": "Jaén",
                    "city": "Andújar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23746",
                    "province": "Jaén",
                    "city": "Lahiguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "23747",
                    "province": "Jaén",
                    "city": "Baeza",
                    "multiple": "0"
                },
                {
                    "zipcode": "23747",
                    "province": "Jaén",
                    "city": "Andújar",
                    "multiple": "1"
                },
                {
                    "zipcode": "23748",
                    "province": "Jaén",
                    "city": "Andújar",
                    "multiple": "0"
                },
                {
                    "zipcode": "23749",
                    "province": "Jaén",
                    "city": "Villanueva de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "23749",
                    "province": "Jaén",
                    "city": "Andújar",
                    "multiple": "1"
                },
                {
                    "zipcode": "23750",
                    "province": "Jaén",
                    "city": "Arjonilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "23760",
                    "province": "Jaén",
                    "city": "Arjona",
                    "multiple": "0"
                },
                {
                    "zipcode": "23770",
                    "province": "Jaén",
                    "city": "Marmolejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "23780",
                    "province": "Jaén",
                    "city": "Lopera",
                    "multiple": "0"
                },
                {
                    "zipcode": "23790",
                    "province": "Jaén",
                    "city": "Porcuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24001",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24002",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24003",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24003",
                    "province": "León",
                    "city": "León",
                    "multiple": "1"
                },
                {
                    "zipcode": "24004",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24005",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24006",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24007",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24008",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24009",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24009",
                    "province": "León",
                    "city": "Santovenia de la Valdoncina",
                    "multiple": "1"
                },
                {
                    "zipcode": "24009",
                    "province": "León",
                    "city": "San Andrés del Rabanedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24010",
                    "province": "León",
                    "city": "San Andrés del Rabanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24010",
                    "province": "León",
                    "city": "León",
                    "multiple": "1"
                },
                {
                    "zipcode": "24100",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24110",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24111",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24112",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24113",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24114",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24120",
                    "province": "León",
                    "city": "Soto y Amío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24121",
                    "province": "León",
                    "city": "Sariegos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24122",
                    "province": "León",
                    "city": "Cuadros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24123",
                    "province": "León",
                    "city": "Carrocera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24124",
                    "province": "León",
                    "city": "Santa María de Ordás",
                    "multiple": "0"
                },
                {
                    "zipcode": "24124",
                    "province": "León",
                    "city": "Soto y Amío",
                    "multiple": "1"
                },
                {
                    "zipcode": "24125",
                    "province": "León",
                    "city": "Soto y Amío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24126",
                    "province": "León",
                    "city": "Soto y Amío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24126",
                    "province": "León",
                    "city": "Riello",
                    "multiple": "1"
                },
                {
                    "zipcode": "24127",
                    "province": "León",
                    "city": "Riello",
                    "multiple": "0"
                },
                {
                    "zipcode": "24127",
                    "province": "León",
                    "city": "Valdesamario",
                    "multiple": "1"
                },
                {
                    "zipcode": "24130",
                    "province": "León",
                    "city": "Murias de Paredes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24131",
                    "province": "León",
                    "city": "Riello",
                    "multiple": "0"
                },
                {
                    "zipcode": "24132",
                    "province": "León",
                    "city": "Riello",
                    "multiple": "0"
                },
                {
                    "zipcode": "24133",
                    "province": "León",
                    "city": "Riello",
                    "multiple": "0"
                },
                {
                    "zipcode": "24133",
                    "province": "León",
                    "city": "Murias de Paredes",
                    "multiple": "1"
                },
                {
                    "zipcode": "24134",
                    "province": "León",
                    "city": "Murias de Paredes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24134",
                    "province": "León",
                    "city": "Riello",
                    "multiple": "1"
                },
                {
                    "zipcode": "24135",
                    "province": "León",
                    "city": "Murias de Paredes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24136",
                    "province": "León",
                    "city": "Murias de Paredes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24137",
                    "province": "León",
                    "city": "Murias de Paredes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24138",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24139",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24140",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24141",
                    "province": "León",
                    "city": "Cabrillanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24142",
                    "province": "León",
                    "city": "Cabrillanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24143",
                    "province": "León",
                    "city": "Cabrillanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24143",
                    "province": "León",
                    "city": "San Emiliano",
                    "multiple": "1"
                },
                {
                    "zipcode": "24144",
                    "province": "León",
                    "city": "San Emiliano",
                    "multiple": "0"
                },
                {
                    "zipcode": "24145",
                    "province": "León",
                    "city": "San Emiliano",
                    "multiple": "0"
                },
                {
                    "zipcode": "24145",
                    "province": "León",
                    "city": "Sena de Luna",
                    "multiple": "1"
                },
                {
                    "zipcode": "24146",
                    "province": "León",
                    "city": "Sena de Luna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24148",
                    "province": "León",
                    "city": "Barrios de Luna, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "24149",
                    "province": "León",
                    "city": "Barrios de Luna, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "24150",
                    "province": "León",
                    "city": "Encinedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24150",
                    "province": "León",
                    "city": "Vegas del Condado",
                    "multiple": "1"
                },
                {
                    "zipcode": "24150",
                    "province": "León",
                    "city": "Santa Colomba de Curueño",
                    "multiple": "1"
                },
                {
                    "zipcode": "24151",
                    "province": "León",
                    "city": "Santa Colomba de Curueño",
                    "multiple": "0"
                },
                {
                    "zipcode": "24152",
                    "province": "León",
                    "city": "Vegaquemada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24153",
                    "province": "León",
                    "city": "Vegas del Condado",
                    "multiple": "0"
                },
                {
                    "zipcode": "24154",
                    "province": "León",
                    "city": "Vegas del Condado",
                    "multiple": "0"
                },
                {
                    "zipcode": "24155",
                    "province": "León",
                    "city": "Vegas del Condado",
                    "multiple": "0"
                },
                {
                    "zipcode": "24156",
                    "province": "León",
                    "city": "Valdefresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24160",
                    "province": "León",
                    "city": "Gradefes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24161",
                    "province": "León",
                    "city": "Gradefes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24161",
                    "province": "León",
                    "city": "Cubillas de Rueda",
                    "multiple": "1"
                },
                {
                    "zipcode": "24162",
                    "province": "León",
                    "city": "Villasabariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "24163",
                    "province": "León",
                    "city": "Villasabariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "24163",
                    "province": "León",
                    "city": "Vegas del Condado",
                    "multiple": "1"
                },
                {
                    "zipcode": "24164",
                    "province": "León",
                    "city": "Gradefes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24165",
                    "province": "León",
                    "city": "Gradefes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24166",
                    "province": "León",
                    "city": "Gradefes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24170",
                    "province": "León",
                    "city": "Almanza",
                    "multiple": "0"
                },
                {
                    "zipcode": "24170",
                    "province": "León",
                    "city": "Cebanico",
                    "multiple": "1"
                },
                {
                    "zipcode": "24171",
                    "province": "León",
                    "city": "Villaselán",
                    "multiple": "0"
                },
                {
                    "zipcode": "24171",
                    "province": "León",
                    "city": "Almanza",
                    "multiple": "1"
                },
                {
                    "zipcode": "24172",
                    "province": "León",
                    "city": "Villamol",
                    "multiple": "0"
                },
                {
                    "zipcode": "24172",
                    "province": "León",
                    "city": "Villazanzo de Valderaduey",
                    "multiple": "1"
                },
                {
                    "zipcode": "24172",
                    "province": "León",
                    "city": "Cea",
                    "multiple": "1"
                },
                {
                    "zipcode": "24174",
                    "province": "León",
                    "city": "Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "24175",
                    "province": "León",
                    "city": "Villamol",
                    "multiple": "0"
                },
                {
                    "zipcode": "24190",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24190",
                    "province": "León",
                    "city": "San Andrés del Rabanedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24191",
                    "province": "León",
                    "city": "San Andrés del Rabanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24192",
                    "province": "León",
                    "city": "León",
                    "multiple": "0"
                },
                {
                    "zipcode": "24192",
                    "province": "León",
                    "city": "Onzonilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "24193",
                    "province": "León",
                    "city": "Villaquilambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24194",
                    "province": "León",
                    "city": "Santovenia de la Valdoncina",
                    "multiple": "0"
                },
                {
                    "zipcode": "24195",
                    "province": "León",
                    "city": "Valdefresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24195",
                    "province": "León",
                    "city": "Villaquilambre",
                    "multiple": "1"
                },
                {
                    "zipcode": "24196",
                    "province": "León",
                    "city": "Sariegos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24197",
                    "province": "León",
                    "city": "Villaquilambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24198",
                    "province": "León",
                    "city": "Valverde de la Virgen",
                    "multiple": "0"
                },
                {
                    "zipcode": "24199",
                    "province": "León",
                    "city": "Villaturiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "24200",
                    "province": "León",
                    "city": "Valencia de Don Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "24205",
                    "province": "León",
                    "city": "Valencia de Don Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "24206",
                    "province": "León",
                    "city": "Villabraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "24206",
                    "province": "León",
                    "city": "Valdemora",
                    "multiple": "1"
                },
                {
                    "zipcode": "24206",
                    "province": "León",
                    "city": "Castilfalé",
                    "multiple": "1"
                },
                {
                    "zipcode": "24206",
                    "province": "León",
                    "city": "Fuentes de Carbajal",
                    "multiple": "1"
                },
                {
                    "zipcode": "24207",
                    "province": "León",
                    "city": "Villabraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "24207",
                    "province": "León",
                    "city": "Matanza",
                    "multiple": "1"
                },
                {
                    "zipcode": "24208",
                    "province": "León",
                    "city": "Pajares de los Oteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24209",
                    "province": "León",
                    "city": "Gusendos de los Oteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24209",
                    "province": "León",
                    "city": "Pajares de los Oteros",
                    "multiple": "1"
                },
                {
                    "zipcode": "24210",
                    "province": "León",
                    "city": "Mansilla de las Mulas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24212",
                    "province": "León",
                    "city": "Cabañas Raras",
                    "multiple": "0"
                },
                {
                    "zipcode": "24217",
                    "province": "León",
                    "city": "Mansilla Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "24218",
                    "province": "León",
                    "city": "Mansilla de las Mulas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24218",
                    "province": "León",
                    "city": "Valdepolo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24219",
                    "province": "León",
                    "city": "Villasabariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "24220",
                    "province": "León",
                    "city": "Valderas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24221",
                    "province": "León",
                    "city": "Campazas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24222",
                    "province": "León",
                    "city": "Villaornate y Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "24223",
                    "province": "León",
                    "city": "Pajares de los Oteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24223",
                    "province": "León",
                    "city": "Matallana de Torío",
                    "multiple": "1"
                },
                {
                    "zipcode": "24223",
                    "province": "León",
                    "city": "Fresno de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "24224",
                    "province": "León",
                    "city": "Pajares de los Oteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24224",
                    "province": "León",
                    "city": "Cubillas de los Oteros",
                    "multiple": "1"
                },
                {
                    "zipcode": "24224",
                    "province": "León",
                    "city": "Cabreros del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "24225",
                    "province": "León",
                    "city": "Corbillos de los Oteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24225",
                    "province": "León",
                    "city": "Campo de Villavidel",
                    "multiple": "1"
                },
                {
                    "zipcode": "24225",
                    "province": "León",
                    "city": "Villanueva de las Manzanas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24226",
                    "province": "León",
                    "city": "Villaturiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "24226",
                    "province": "León",
                    "city": "Villasabariego",
                    "multiple": "1"
                },
                {
                    "zipcode": "24227",
                    "province": "León",
                    "city": "Valdefresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24228",
                    "province": "León",
                    "city": "Valdefresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24230",
                    "province": "León",
                    "city": "Valdevimbre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24231",
                    "province": "León",
                    "city": "Onzonilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "24231",
                    "province": "León",
                    "city": "Chozas de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24232",
                    "province": "León",
                    "city": "Ardón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24233",
                    "province": "León",
                    "city": "Villamañán",
                    "multiple": "0"
                },
                {
                    "zipcode": "24233",
                    "province": "León",
                    "city": "Ardón",
                    "multiple": "1"
                },
                {
                    "zipcode": "24234",
                    "province": "León",
                    "city": "Laguna de Negrillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24234",
                    "province": "León",
                    "city": "Villamañán",
                    "multiple": "1"
                },
                {
                    "zipcode": "24235",
                    "province": "León",
                    "city": "Villaquejida",
                    "multiple": "0"
                },
                {
                    "zipcode": "24236",
                    "province": "León",
                    "city": "Villaquejida",
                    "multiple": "0"
                },
                {
                    "zipcode": "24237",
                    "province": "León",
                    "city": "Toral de los Guzmanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24237",
                    "province": "León",
                    "city": "Villademor de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "24237",
                    "province": "León",
                    "city": "San Millán de los Caballeros",
                    "multiple": "1"
                },
                {
                    "zipcode": "24238",
                    "province": "León",
                    "city": "Villamandos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24238",
                    "province": "León",
                    "city": "Algadefe",
                    "multiple": "1"
                },
                {
                    "zipcode": "24239",
                    "province": "León",
                    "city": "Cimanes de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "24240",
                    "province": "León",
                    "city": "Santa María del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24248",
                    "province": "León",
                    "city": "Laguna Dalga",
                    "multiple": "0"
                },
                {
                    "zipcode": "24248",
                    "province": "León",
                    "city": "Urdiales del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24249",
                    "province": "León",
                    "city": "Laguna Dalga",
                    "multiple": "0"
                },
                {
                    "zipcode": "24249",
                    "province": "León",
                    "city": "Zotes del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24249",
                    "province": "León",
                    "city": "Bercianos del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24249",
                    "province": "León",
                    "city": "Pobladura de Pelayo García",
                    "multiple": "1"
                },
                {
                    "zipcode": "24250",
                    "province": "León",
                    "city": "Chozas de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24250",
                    "province": "León",
                    "city": "Valdevimbre",
                    "multiple": "1"
                },
                {
                    "zipcode": "24251",
                    "province": "León",
                    "city": "Onzonilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "24251",
                    "province": "León",
                    "city": "Ardón",
                    "multiple": "1"
                },
                {
                    "zipcode": "24251",
                    "province": "León",
                    "city": "Chozas de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24252",
                    "province": "León",
                    "city": "Bercianos del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24252",
                    "province": "León",
                    "city": "San Pedro Bercianos",
                    "multiple": "1"
                },
                {
                    "zipcode": "24252",
                    "province": "León",
                    "city": "Urdiales del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24253",
                    "province": "León",
                    "city": "Valdefuentes del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24257",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24270",
                    "province": "León",
                    "city": "Carrizo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24271",
                    "province": "León",
                    "city": "Llamas de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24272",
                    "province": "León",
                    "city": "Cimanes del Tejar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24273",
                    "province": "León",
                    "city": "Cimanes del Tejar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24273",
                    "province": "León",
                    "city": "Omañas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "24274",
                    "province": "León",
                    "city": "Rioseco de Tapia",
                    "multiple": "0"
                },
                {
                    "zipcode": "24275",
                    "province": "León",
                    "city": "Santa María de Ordás",
                    "multiple": "0"
                },
                {
                    "zipcode": "24275",
                    "province": "León",
                    "city": "Rioseco de Tapia",
                    "multiple": "1"
                },
                {
                    "zipcode": "24276",
                    "province": "León",
                    "city": "Santa María de Ordás",
                    "multiple": "0"
                },
                {
                    "zipcode": "24277",
                    "province": "León",
                    "city": "Santa María de Ordás",
                    "multiple": "0"
                },
                {
                    "zipcode": "24280",
                    "province": "León",
                    "city": "Benavides",
                    "multiple": "0"
                },
                {
                    "zipcode": "24281",
                    "province": "León",
                    "city": "Benavides",
                    "multiple": "0"
                },
                {
                    "zipcode": "24282",
                    "province": "León",
                    "city": "San Andrés del Rabanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24282",
                    "province": "León",
                    "city": "Valverde de la Virgen",
                    "multiple": "1"
                },
                {
                    "zipcode": "24283",
                    "province": "León",
                    "city": "Carrizo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24284",
                    "province": "León",
                    "city": "Turcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "24285",
                    "province": "León",
                    "city": "Turcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "24285",
                    "province": "León",
                    "city": "Quintana del Castillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24285",
                    "province": "León",
                    "city": "Benavides",
                    "multiple": "1"
                },
                {
                    "zipcode": "24286",
                    "province": "León",
                    "city": "Hospital de Órbigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24287",
                    "province": "León",
                    "city": "Benavides",
                    "multiple": "0"
                },
                {
                    "zipcode": "24287",
                    "province": "León",
                    "city": "Villares de Órbigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24288",
                    "province": "León",
                    "city": "Villarejo de Órbigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24288",
                    "province": "León",
                    "city": "Villares de Órbigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24290",
                    "province": "León",
                    "city": "Santa Cristina de Valmadrigal",
                    "multiple": "0"
                },
                {
                    "zipcode": "24291",
                    "province": "León",
                    "city": "Matadeón de los Oteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24292",
                    "province": "León",
                    "city": "Valverde-Enrique",
                    "multiple": "0"
                },
                {
                    "zipcode": "24293",
                    "province": "León",
                    "city": "Izagre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24294",
                    "province": "León",
                    "city": "Gordoncillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24300",
                    "province": "León",
                    "city": "Bembibre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24310",
                    "province": "León",
                    "city": "Folgoso de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24310",
                    "province": "León",
                    "city": "Torre del Bierzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24311",
                    "province": "León",
                    "city": "Folgoso de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24312",
                    "province": "León",
                    "city": "Igüeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "24312",
                    "province": "León",
                    "city": "Folgoso de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "24313",
                    "province": "León",
                    "city": "Igüeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "24314",
                    "province": "León",
                    "city": "Castropodame",
                    "multiple": "0"
                },
                {
                    "zipcode": "24315",
                    "province": "León",
                    "city": "Folgoso de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24315",
                    "province": "León",
                    "city": "Bembibre",
                    "multiple": "1"
                },
                {
                    "zipcode": "24316",
                    "province": "León",
                    "city": "Castropodame",
                    "multiple": "0"
                },
                {
                    "zipcode": "24317",
                    "province": "León",
                    "city": "Torre del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24318",
                    "province": "León",
                    "city": "Bembibre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24319",
                    "province": "León",
                    "city": "Bembibre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24319",
                    "province": "León",
                    "city": "Noceda del Bierzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24319",
                    "province": "León",
                    "city": "Igüeña",
                    "multiple": "1"
                },
                {
                    "zipcode": "24320",
                    "province": "León",
                    "city": "Sahagún",
                    "multiple": "0"
                },
                {
                    "zipcode": "24323",
                    "province": "León",
                    "city": "Valverde-Enrique",
                    "multiple": "0"
                },
                {
                    "zipcode": "24323",
                    "province": "León",
                    "city": "Castrotierra de Valmadrigal",
                    "multiple": "1"
                },
                {
                    "zipcode": "24324",
                    "province": "León",
                    "city": "Vallecillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24324",
                    "province": "León",
                    "city": "Joarilla de las Matas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24325",
                    "province": "León",
                    "city": "Bercianos del Real Camino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24325",
                    "province": "León",
                    "city": "Gordaliza del Pino",
                    "multiple": "1"
                },
                {
                    "zipcode": "24326",
                    "province": "León",
                    "city": "Sahagún",
                    "multiple": "0"
                },
                {
                    "zipcode": "24327",
                    "province": "León",
                    "city": "Villazanzo de Valderaduey",
                    "multiple": "0"
                },
                {
                    "zipcode": "24328",
                    "province": "León",
                    "city": "Villazanzo de Valderaduey",
                    "multiple": "0"
                },
                {
                    "zipcode": "24328",
                    "province": "León",
                    "city": "Sahagún",
                    "multiple": "1"
                },
                {
                    "zipcode": "24328",
                    "province": "León",
                    "city": "Cea",
                    "multiple": "1"
                },
                {
                    "zipcode": "24329",
                    "province": "León",
                    "city": "Sahagún",
                    "multiple": "0"
                },
                {
                    "zipcode": "24330",
                    "province": "León",
                    "city": "Santas Martas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24339",
                    "province": "León",
                    "city": "Villamoratiel de las Matas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24339",
                    "province": "León",
                    "city": "Santas Martas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24340",
                    "province": "León",
                    "city": "Grajal de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24341",
                    "province": "León",
                    "city": "Escobar de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24342",
                    "province": "León",
                    "city": "Calzada del Coto",
                    "multiple": "0"
                },
                {
                    "zipcode": "24343",
                    "province": "León",
                    "city": "Burgo Ranero, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "24343",
                    "province": "León",
                    "city": "Santa María del Monte de Cea",
                    "multiple": "1"
                },
                {
                    "zipcode": "24344",
                    "province": "León",
                    "city": "Santa María del Monte de Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "24344",
                    "province": "León",
                    "city": "Villaselán",
                    "multiple": "1"
                },
                {
                    "zipcode": "24344",
                    "province": "León",
                    "city": "Burgo Ranero, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "24344",
                    "province": "León",
                    "city": "Villamartín de Don Sancho",
                    "multiple": "1"
                },
                {
                    "zipcode": "24345",
                    "province": "León",
                    "city": "Santas Martas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24346",
                    "province": "León",
                    "city": "Vega de Infanzones",
                    "multiple": "0"
                },
                {
                    "zipcode": "24347",
                    "province": "León",
                    "city": "Vega de Infanzones",
                    "multiple": "0"
                },
                {
                    "zipcode": "24347",
                    "province": "León",
                    "city": "Onzonilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "24350",
                    "province": "León",
                    "city": "Villarejo de Órbigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24356",
                    "province": "León",
                    "city": "Villazala",
                    "multiple": "0"
                },
                {
                    "zipcode": "24356",
                    "province": "León",
                    "city": "Bustillo del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24357",
                    "province": "León",
                    "city": "Bustillo del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24358",
                    "province": "León",
                    "city": "Villarejo de Órbigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24359",
                    "province": "León",
                    "city": "San Justo de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "24359",
                    "province": "León",
                    "city": "San Cristóbal de la Polantera",
                    "multiple": "1"
                },
                {
                    "zipcode": "24360",
                    "province": "León",
                    "city": "Villagatón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24367",
                    "province": "León",
                    "city": "Villagatón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24368",
                    "province": "León",
                    "city": "Villagatón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24369",
                    "province": "León",
                    "city": "Villagatón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24370",
                    "province": "León",
                    "city": "Torre del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24374",
                    "province": "León",
                    "city": "Torre del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24374",
                    "province": "León",
                    "city": "Igüeña",
                    "multiple": "1"
                },
                {
                    "zipcode": "24375",
                    "province": "León",
                    "city": "Igüeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "24376",
                    "province": "León",
                    "city": "Igüeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "24377",
                    "province": "León",
                    "city": "Igüeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "24378",
                    "province": "León",
                    "city": "Torre del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24379",
                    "province": "León",
                    "city": "Villagatón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24379",
                    "province": "León",
                    "city": "Torre del Bierzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24380",
                    "province": "León",
                    "city": "Puente de Domingo Flórez",
                    "multiple": "0"
                },
                {
                    "zipcode": "24384",
                    "province": "León",
                    "city": "Puente de Domingo Flórez",
                    "multiple": "0"
                },
                {
                    "zipcode": "24385",
                    "province": "León",
                    "city": "Puente de Domingo Flórez",
                    "multiple": "0"
                },
                {
                    "zipcode": "24386",
                    "province": "León",
                    "city": "Puente de Domingo Flórez",
                    "multiple": "0"
                },
                {
                    "zipcode": "24387",
                    "province": "León",
                    "city": "Puente de Domingo Flórez",
                    "multiple": "0"
                },
                {
                    "zipcode": "24388",
                    "province": "León",
                    "city": "Benuza",
                    "multiple": "0"
                },
                {
                    "zipcode": "24389",
                    "province": "León",
                    "city": "Puente de Domingo Flórez",
                    "multiple": "0"
                },
                {
                    "zipcode": "24389",
                    "province": "León",
                    "city": "Benuza",
                    "multiple": "1"
                },
                {
                    "zipcode": "24390",
                    "province": "León",
                    "city": "Carracedelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24391",
                    "province": "León",
                    "city": "Chozas de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24391",
                    "province": "León",
                    "city": "Santovenia de la Valdoncina",
                    "multiple": "1"
                },
                {
                    "zipcode": "24391",
                    "province": "León",
                    "city": "Valverde de la Virgen",
                    "multiple": "1"
                },
                {
                    "zipcode": "24392",
                    "province": "León",
                    "city": "Villadangos del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24392",
                    "province": "León",
                    "city": "Chozas de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24392",
                    "province": "León",
                    "city": "Cimanes del Tejar",
                    "multiple": "1"
                },
                {
                    "zipcode": "24393",
                    "province": "León",
                    "city": "Santa Marina del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "24393",
                    "province": "León",
                    "city": "Bustillo del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24393",
                    "province": "León",
                    "city": "Cimanes del Tejar",
                    "multiple": "1"
                },
                {
                    "zipcode": "24394",
                    "province": "León",
                    "city": "Valderrey",
                    "multiple": "0"
                },
                {
                    "zipcode": "24395",
                    "province": "León",
                    "city": "Valderrey",
                    "multiple": "0"
                },
                {
                    "zipcode": "24395",
                    "province": "León",
                    "city": "San Justo de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "24396",
                    "province": "León",
                    "city": "Magaz de Cepeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24397",
                    "province": "León",
                    "city": "Magaz de Cepeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24397",
                    "province": "León",
                    "city": "Quintana del Castillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24398",
                    "province": "León",
                    "city": "Castropodame",
                    "multiple": "0"
                },
                {
                    "zipcode": "24398",
                    "province": "León",
                    "city": "Molinaseca",
                    "multiple": "1"
                },
                {
                    "zipcode": "24398",
                    "province": "León",
                    "city": "Congosto",
                    "multiple": "1"
                },
                {
                    "zipcode": "24400",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24401",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24402",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24403",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24404",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24410",
                    "province": "León",
                    "city": "Arganza",
                    "multiple": "0"
                },
                {
                    "zipcode": "24410",
                    "province": "León",
                    "city": "Camponaraya",
                    "multiple": "1"
                },
                {
                    "zipcode": "24411",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24412",
                    "province": "León",
                    "city": "Cabañas Raras",
                    "multiple": "0"
                },
                {
                    "zipcode": "24413",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24413",
                    "province": "León",
                    "city": "Molinaseca",
                    "multiple": "1"
                },
                {
                    "zipcode": "24414",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24415",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24416",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24420",
                    "province": "León",
                    "city": "Fabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24424",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24428",
                    "province": "León",
                    "city": "Fabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24429",
                    "province": "León",
                    "city": "Peranzanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24429",
                    "province": "León",
                    "city": "Fabero",
                    "multiple": "1"
                },
                {
                    "zipcode": "24430",
                    "province": "León",
                    "city": "Vega de Espinareda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24433",
                    "province": "León",
                    "city": "Candín",
                    "multiple": "0"
                },
                {
                    "zipcode": "24434",
                    "province": "León",
                    "city": "Fabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24434",
                    "province": "León",
                    "city": "Vega de Espinareda",
                    "multiple": "1"
                },
                {
                    "zipcode": "24435",
                    "province": "León",
                    "city": "Vega de Espinareda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24436",
                    "province": "León",
                    "city": "Vega de Espinareda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24437",
                    "province": "León",
                    "city": "Vega de Espinareda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24438",
                    "province": "León",
                    "city": "Berlanga del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24438",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "1"
                },
                {
                    "zipcode": "24439",
                    "province": "León",
                    "city": "Vega de Espinareda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24439",
                    "province": "León",
                    "city": "Sancedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24440",
                    "province": "León",
                    "city": "Carucedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24441",
                    "province": "León",
                    "city": "Carucedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24441",
                    "province": "León",
                    "city": "Toral de los Vados",
                    "multiple": "1"
                },
                {
                    "zipcode": "24442",
                    "province": "León",
                    "city": "Carucedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24443",
                    "province": "León",
                    "city": "Borrenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24444",
                    "province": "León",
                    "city": "Borrenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24445",
                    "province": "León",
                    "city": "Priaranza del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24446",
                    "province": "León",
                    "city": "Priaranza del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24447",
                    "province": "León",
                    "city": "Priaranza del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24448",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24448",
                    "province": "León",
                    "city": "Priaranza del Bierzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24450",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24457",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24458",
                    "province": "León",
                    "city": "Noceda del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24458",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "1"
                },
                {
                    "zipcode": "24459",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24460",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24469",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24469",
                    "province": "León",
                    "city": "Páramo del Sil",
                    "multiple": "1"
                },
                {
                    "zipcode": "24470",
                    "province": "León",
                    "city": "Páramo del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24478",
                    "province": "León",
                    "city": "Páramo del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24479",
                    "province": "León",
                    "city": "Páramo del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24480",
                    "province": "León",
                    "city": "Palacios del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24488",
                    "province": "León",
                    "city": "Páramo del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24489",
                    "province": "León",
                    "city": "Palacios del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24490",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24491",
                    "province": "León",
                    "city": "Ponferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24492",
                    "province": "León",
                    "city": "Cubillos del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24493",
                    "province": "León",
                    "city": "Toreno",
                    "multiple": "0"
                },
                {
                    "zipcode": "24494",
                    "province": "León",
                    "city": "Páramo del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24495",
                    "province": "León",
                    "city": "Palacios del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24496",
                    "province": "León",
                    "city": "Palacios del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24497",
                    "province": "León",
                    "city": "Palacios del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24498",
                    "province": "León",
                    "city": "Palacios del Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24500",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24510",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24511",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24512",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24512",
                    "province": "León",
                    "city": "Valle de Altomira, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "24513",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24514",
                    "province": "León",
                    "city": "Corullón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24515",
                    "province": "León",
                    "city": "Corullón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24516",
                    "province": "León",
                    "city": "Corullón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24516",
                    "province": "León",
                    "city": "Toral de los Vados",
                    "multiple": "1"
                },
                {
                    "zipcode": "24516",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24517",
                    "province": "León",
                    "city": "Corullón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24520",
                    "province": "León",
                    "city": "Vega de Valcarce",
                    "multiple": "0"
                },
                {
                    "zipcode": "24521",
                    "province": "León",
                    "city": "Barjas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24521",
                    "province": "León",
                    "city": "Vega de Valcarce",
                    "multiple": "1"
                },
                {
                    "zipcode": "24522",
                    "province": "León",
                    "city": "Trabadelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24523",
                    "province": "León",
                    "city": "Trabadelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24524",
                    "province": "León",
                    "city": "Vega de Valcarce",
                    "multiple": "0"
                },
                {
                    "zipcode": "24525",
                    "province": "León",
                    "city": "Balboa",
                    "multiple": "0"
                },
                {
                    "zipcode": "24526",
                    "province": "León",
                    "city": "Vega de Valcarce",
                    "multiple": "0"
                },
                {
                    "zipcode": "24530",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24530",
                    "province": "León",
                    "city": "Toral de los Vados",
                    "multiple": "1"
                },
                {
                    "zipcode": "24540",
                    "province": "León",
                    "city": "Cacabelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24544",
                    "province": "León",
                    "city": "Carracedelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24545",
                    "province": "León",
                    "city": "Arganza",
                    "multiple": "0"
                },
                {
                    "zipcode": "24545",
                    "province": "León",
                    "city": "Sancedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24546",
                    "province": "León",
                    "city": "Arganza",
                    "multiple": "0"
                },
                {
                    "zipcode": "24547",
                    "province": "León",
                    "city": "Cacabelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24547",
                    "province": "León",
                    "city": "Villafranca del Bierzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24548",
                    "province": "León",
                    "city": "Cacabelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "24549",
                    "province": "León",
                    "city": "Carracedelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24550",
                    "province": "León",
                    "city": "Toral de los Vados",
                    "multiple": "0"
                },
                {
                    "zipcode": "24550",
                    "province": "León",
                    "city": "Carracedelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24560",
                    "province": "León",
                    "city": "Toral de los Vados",
                    "multiple": "0"
                },
                {
                    "zipcode": "24565",
                    "province": "León",
                    "city": "Carracedelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24566",
                    "province": "León",
                    "city": "Oencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "24567",
                    "province": "León",
                    "city": "Oencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "24567",
                    "province": "León",
                    "city": "Sobrado",
                    "multiple": "1"
                },
                {
                    "zipcode": "24568",
                    "province": "León",
                    "city": "Oencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "24569",
                    "province": "León",
                    "city": "Oencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "24569",
                    "province": "León",
                    "city": "Sobrado",
                    "multiple": "1"
                },
                {
                    "zipcode": "24569",
                    "province": "León",
                    "city": "Toral de los Vados",
                    "multiple": "1"
                },
                {
                    "zipcode": "24600",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24607",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24608",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24609",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24610",
                    "province": "León",
                    "city": "Cuadros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24620",
                    "province": "León",
                    "city": "Cuadros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24630",
                    "province": "León",
                    "city": "Cuadros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24640",
                    "province": "León",
                    "city": "Robla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24648",
                    "province": "León",
                    "city": "Robla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24648",
                    "province": "León",
                    "city": "Matallana de Torío",
                    "multiple": "1"
                },
                {
                    "zipcode": "24649",
                    "province": "León",
                    "city": "Robla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24650",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24660",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24670",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24680",
                    "province": "León",
                    "city": "Villamanín",
                    "multiple": "0"
                },
                {
                    "zipcode": "24687",
                    "province": "León",
                    "city": "Villamanín",
                    "multiple": "0"
                },
                {
                    "zipcode": "24688",
                    "province": "León",
                    "city": "Villamanín",
                    "multiple": "0"
                },
                {
                    "zipcode": "24689",
                    "province": "León",
                    "city": "Villamanín",
                    "multiple": "0"
                },
                {
                    "zipcode": "24690",
                    "province": "León",
                    "city": "Villamanín",
                    "multiple": "0"
                },
                {
                    "zipcode": "24699",
                    "province": "León",
                    "city": "Villamanín",
                    "multiple": "0"
                },
                {
                    "zipcode": "24700",
                    "province": "León",
                    "city": "Astorga",
                    "multiple": "0"
                },
                {
                    "zipcode": "24710",
                    "province": "León",
                    "city": "San Justo de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "24711",
                    "province": "León",
                    "city": "Villaobispo de Otero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24711",
                    "province": "León",
                    "city": "Villamejil",
                    "multiple": "1"
                },
                {
                    "zipcode": "24712",
                    "province": "León",
                    "city": "Villamejil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24713",
                    "province": "León",
                    "city": "Villamejil",
                    "multiple": "0"
                },
                {
                    "zipcode": "24714",
                    "province": "León",
                    "city": "Brazuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24715",
                    "province": "León",
                    "city": "Brazuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24716",
                    "province": "León",
                    "city": "Brazuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24717",
                    "province": "León",
                    "city": "Val de San Lorenzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24717",
                    "province": "León",
                    "city": "Lucillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24717",
                    "province": "León",
                    "city": "Santiago Millas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24717",
                    "province": "León",
                    "city": "Luyego",
                    "multiple": "1"
                },
                {
                    "zipcode": "24718",
                    "province": "León",
                    "city": "Brazuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24718",
                    "province": "León",
                    "city": "Astorga",
                    "multiple": "1"
                },
                {
                    "zipcode": "24719",
                    "province": "León",
                    "city": "Villaobispo de Otero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24720",
                    "province": "León",
                    "city": "Santa Colomba de Somoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "24721",
                    "province": "León",
                    "city": "Castrillo de la Valduerna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24721",
                    "province": "León",
                    "city": "Luyego",
                    "multiple": "1"
                },
                {
                    "zipcode": "24722",
                    "province": "León",
                    "city": "Santa Colomba de Somoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "24723",
                    "province": "León",
                    "city": "Lucillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24724",
                    "province": "León",
                    "city": "Lucillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24730",
                    "province": "León",
                    "city": "Destriana",
                    "multiple": "0"
                },
                {
                    "zipcode": "24731",
                    "province": "León",
                    "city": "Santiago Millas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24732",
                    "province": "León",
                    "city": "Valderrey",
                    "multiple": "0"
                },
                {
                    "zipcode": "24732",
                    "province": "León",
                    "city": "Santiago Millas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24733",
                    "province": "León",
                    "city": "Quintana y Congosto",
                    "multiple": "0"
                },
                {
                    "zipcode": "24734",
                    "province": "León",
                    "city": "Castrocontrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24735",
                    "province": "León",
                    "city": "Castrocontrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24736",
                    "province": "León",
                    "city": "Castrocontrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24738",
                    "province": "León",
                    "city": "Truchas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24739",
                    "province": "León",
                    "city": "Torre del Bierzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24740",
                    "province": "León",
                    "city": "Villablino",
                    "multiple": "0"
                },
                {
                    "zipcode": "24740",
                    "province": "León",
                    "city": "Barjas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24740",
                    "province": "León",
                    "city": "Truchas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24740",
                    "province": "León",
                    "city": "Santiago Millas",
                    "multiple": "1"
                },
                {
                    "zipcode": "24741",
                    "province": "León",
                    "city": "Truchas",
                    "multiple": "0"
                },
                {
                    "zipcode": "24742",
                    "province": "León",
                    "city": "Castrillo de Cabrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24743",
                    "province": "León",
                    "city": "Encinedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24744",
                    "province": "León",
                    "city": "Encinedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24744",
                    "province": "León",
                    "city": "Castrillo de Cabrera",
                    "multiple": "1"
                },
                {
                    "zipcode": "24745",
                    "province": "León",
                    "city": "Encinedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24746",
                    "province": "León",
                    "city": "Encinedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24750",
                    "province": "León",
                    "city": "Bañeza, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24760",
                    "province": "León",
                    "city": "Castrocalbón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24760",
                    "province": "León",
                    "city": "San Esteban de Nogales",
                    "multiple": "1"
                },
                {
                    "zipcode": "24760",
                    "province": "León",
                    "city": "Pola de Gordón, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "24761",
                    "province": "León",
                    "city": "Alija del Infantado",
                    "multiple": "0"
                },
                {
                    "zipcode": "24762",
                    "province": "León",
                    "city": "Santa Elena de Jamuz",
                    "multiple": "0"
                },
                {
                    "zipcode": "24762",
                    "province": "León",
                    "city": "Quintana del Marco",
                    "multiple": "1"
                },
                {
                    "zipcode": "24763",
                    "province": "León",
                    "city": "Soto de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "24763",
                    "province": "León",
                    "city": "Villazala",
                    "multiple": "1"
                },
                {
                    "zipcode": "24763",
                    "province": "León",
                    "city": "Regueras de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "24764",
                    "province": "León",
                    "city": "Palacios de la Valduerna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24764",
                    "province": "León",
                    "city": "Bañeza, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "24764",
                    "province": "León",
                    "city": "Soto de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "24765",
                    "province": "León",
                    "city": "Riego de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "24765",
                    "province": "León",
                    "city": "Villamontán de la Valduerna",
                    "multiple": "1"
                },
                {
                    "zipcode": "24766",
                    "province": "León",
                    "city": "Bañeza, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24766",
                    "province": "León",
                    "city": "Palacios de la Valduerna",
                    "multiple": "1"
                },
                {
                    "zipcode": "24766",
                    "province": "León",
                    "city": "Villamontán de la Valduerna",
                    "multiple": "1"
                },
                {
                    "zipcode": "24767",
                    "province": "León",
                    "city": "Santa Elena de Jamuz",
                    "multiple": "0"
                },
                {
                    "zipcode": "24767",
                    "province": "León",
                    "city": "Quintana y Congosto",
                    "multiple": "1"
                },
                {
                    "zipcode": "24768",
                    "province": "León",
                    "city": "Soto de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "24769",
                    "province": "León",
                    "city": "Cebrones del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "24790",
                    "province": "León",
                    "city": "Roperuelos del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24791",
                    "province": "León",
                    "city": "Zotes del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24791",
                    "province": "León",
                    "city": "Roperuelos del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "24792",
                    "province": "León",
                    "city": "Pozuelo del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24792",
                    "province": "León",
                    "city": "Quintana del Marco",
                    "multiple": "1"
                },
                {
                    "zipcode": "24792",
                    "province": "León",
                    "city": "Alija del Infantado",
                    "multiple": "1"
                },
                {
                    "zipcode": "24793",
                    "province": "León",
                    "city": "Valderrey",
                    "multiple": "0"
                },
                {
                    "zipcode": "24794",
                    "province": "León",
                    "city": "Riego de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "24795",
                    "province": "León",
                    "city": "San Cristóbal de la Polantera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24795",
                    "province": "León",
                    "city": "Riego de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "24795",
                    "province": "León",
                    "city": "Santa María de la Isla",
                    "multiple": "1"
                },
                {
                    "zipcode": "24796",
                    "province": "León",
                    "city": "Pozuelo del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24796",
                    "province": "León",
                    "city": "Antigua, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "24797",
                    "province": "León",
                    "city": "San Adrián del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "24800",
                    "province": "León",
                    "city": "Cistierna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24810",
                    "province": "León",
                    "city": "Sabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24811",
                    "province": "León",
                    "city": "Sabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24812",
                    "province": "León",
                    "city": "Sabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24813",
                    "province": "León",
                    "city": "Cistierna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24814",
                    "province": "León",
                    "city": "Sabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24815",
                    "province": "León",
                    "city": "Cistierna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24816",
                    "province": "León",
                    "city": "Cistierna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24820",
                    "province": "León",
                    "city": "Matallana de Torío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24820",
                    "province": "León",
                    "city": "Garrafe de Torío",
                    "multiple": "1"
                },
                {
                    "zipcode": "24830",
                    "province": "León",
                    "city": "Matallana de Torío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24836",
                    "province": "León",
                    "city": "Vegacervera",
                    "multiple": "0"
                },
                {
                    "zipcode": "24836",
                    "province": "León",
                    "city": "Matallana de Torío",
                    "multiple": "1"
                },
                {
                    "zipcode": "24837",
                    "province": "León",
                    "city": "Valdelugueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24837",
                    "province": "León",
                    "city": "Cármenes",
                    "multiple": "1"
                },
                {
                    "zipcode": "24837",
                    "province": "León",
                    "city": "Vegacervera",
                    "multiple": "1"
                },
                {
                    "zipcode": "24838",
                    "province": "León",
                    "city": "Cármenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24839",
                    "province": "León",
                    "city": "Matallana de Torío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24840",
                    "province": "León",
                    "city": "Vecilla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24843",
                    "province": "León",
                    "city": "Valdelugueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24844",
                    "province": "León",
                    "city": "Valdelugueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24845",
                    "province": "León",
                    "city": "Valdelugueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "24846",
                    "province": "León",
                    "city": "Valdepiélago",
                    "multiple": "0"
                },
                {
                    "zipcode": "24847",
                    "province": "León",
                    "city": "Valdepiélago",
                    "multiple": "0"
                },
                {
                    "zipcode": "24848",
                    "province": "León",
                    "city": "Santa Colomba de Curueño",
                    "multiple": "0"
                },
                {
                    "zipcode": "24848",
                    "province": "León",
                    "city": "Vecilla, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "24849",
                    "province": "León",
                    "city": "Valdepiélago",
                    "multiple": "0"
                },
                {
                    "zipcode": "24849",
                    "province": "León",
                    "city": "Vecilla, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "24850",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24852",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24852",
                    "province": "León",
                    "city": "Vegaquemada",
                    "multiple": "1"
                },
                {
                    "zipcode": "24853",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24854",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24855",
                    "province": "León",
                    "city": "Puebla de Lillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24856",
                    "province": "León",
                    "city": "Reyero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24857",
                    "province": "León",
                    "city": "Puebla de Lillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24857",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "1"
                },
                {
                    "zipcode": "24858",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24859",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24860",
                    "province": "León",
                    "city": "Boñar",
                    "multiple": "0"
                },
                {
                    "zipcode": "24860",
                    "province": "León",
                    "city": "Vegaquemada",
                    "multiple": "1"
                },
                {
                    "zipcode": "24869",
                    "province": "León",
                    "city": "Vegaquemada",
                    "multiple": "0"
                },
                {
                    "zipcode": "24870",
                    "province": "León",
                    "city": "Ercina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24877",
                    "province": "León",
                    "city": "Ercina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24878",
                    "province": "León",
                    "city": "Gradefes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24878",
                    "province": "León",
                    "city": "Ercina, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "24879",
                    "province": "León",
                    "city": "Ercina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "24880",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24882",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24883",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24884",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24885",
                    "province": "León",
                    "city": "Prioro",
                    "multiple": "0"
                },
                {
                    "zipcode": "24885",
                    "province": "León",
                    "city": "Boca de Huérgano",
                    "multiple": "1"
                },
                {
                    "zipcode": "24886",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24887",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24888",
                    "province": "León",
                    "city": "Cebanico",
                    "multiple": "0"
                },
                {
                    "zipcode": "24888",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "1"
                },
                {
                    "zipcode": "24888",
                    "province": "León",
                    "city": "Almanza",
                    "multiple": "1"
                },
                {
                    "zipcode": "24889",
                    "province": "León",
                    "city": "Valderrueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24890",
                    "province": "León",
                    "city": "Garrafe de Torío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24891",
                    "province": "León",
                    "city": "Garrafe de Torío",
                    "multiple": "0"
                },
                {
                    "zipcode": "24892",
                    "province": "León",
                    "city": "Cebanico",
                    "multiple": "0"
                },
                {
                    "zipcode": "24893",
                    "province": "León",
                    "city": "Prado de la Guzpeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "24900",
                    "province": "León",
                    "city": "Riaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "24911",
                    "province": "León",
                    "city": "Boca de Huérgano",
                    "multiple": "0"
                },
                {
                    "zipcode": "24912",
                    "province": "León",
                    "city": "Boca de Huérgano",
                    "multiple": "0"
                },
                {
                    "zipcode": "24913",
                    "province": "León",
                    "city": "Boca de Huérgano",
                    "multiple": "0"
                },
                {
                    "zipcode": "24914",
                    "province": "León",
                    "city": "Posada de Valdeón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24915",
                    "province": "León",
                    "city": "Posada de Valdeón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24916",
                    "province": "León",
                    "city": "Oseja de Sajambre",
                    "multiple": "0"
                },
                {
                    "zipcode": "24917",
                    "province": "León",
                    "city": "Burón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24918",
                    "province": "León",
                    "city": "Riaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "24920",
                    "province": "León",
                    "city": "Valdepolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24930",
                    "province": "León",
                    "city": "Valdepolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24940",
                    "province": "León",
                    "city": "Cubillas de Rueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "24950",
                    "province": "León",
                    "city": "Cistierna",
                    "multiple": "0"
                },
                {
                    "zipcode": "24960",
                    "province": "León",
                    "city": "Sabero",
                    "multiple": "0"
                },
                {
                    "zipcode": "24960",
                    "province": "León",
                    "city": "Crémenes",
                    "multiple": "1"
                },
                {
                    "zipcode": "24970",
                    "province": "León",
                    "city": "Crémenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24980",
                    "province": "León",
                    "city": "Crémenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24989",
                    "province": "León",
                    "city": "Crémenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24990",
                    "province": "León",
                    "city": "Crémenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24991",
                    "province": "León",
                    "city": "Crémenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "24994",
                    "province": "León",
                    "city": "Acebedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24994",
                    "province": "León",
                    "city": "Burón",
                    "multiple": "1"
                },
                {
                    "zipcode": "24995",
                    "province": "León",
                    "city": "Burón",
                    "multiple": "0"
                },
                {
                    "zipcode": "24996",
                    "province": "León",
                    "city": "Acebedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "24996",
                    "province": "León",
                    "city": "Maraña",
                    "multiple": "1"
                },
                {
                    "zipcode": "25001",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25002",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25003",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25004",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25005",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25006",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25007",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25008",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25100",
                    "province": "Lleida",
                    "city": "Almacelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "25110",
                    "province": "Lleida",
                    "city": "Torrefarrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25110",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "1"
                },
                {
                    "zipcode": "25110",
                    "province": "Lleida",
                    "city": "Alpicat",
                    "multiple": "1"
                },
                {
                    "zipcode": "25111",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25112",
                    "province": "Lleida",
                    "city": "Gimenells i el Pla de la Font",
                    "multiple": "0"
                },
                {
                    "zipcode": "25113",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25114",
                    "province": "Lleida",
                    "city": "Gimenells i el Pla de la Font",
                    "multiple": "0"
                },
                {
                    "zipcode": "25120",
                    "province": "Lleida",
                    "city": "Alfarràs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25122",
                    "province": "Lleida",
                    "city": "Ivars de Noguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25123",
                    "province": "Lleida",
                    "city": "Torrefarrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25124",
                    "province": "Lleida",
                    "city": "Rosselló",
                    "multiple": "0"
                },
                {
                    "zipcode": "25125",
                    "province": "Lleida",
                    "city": "Alguaire",
                    "multiple": "0"
                },
                {
                    "zipcode": "25126",
                    "province": "Lleida",
                    "city": "Torrefarrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25126",
                    "province": "Lleida",
                    "city": "Almenar",
                    "multiple": "1"
                },
                {
                    "zipcode": "25130",
                    "province": "Lleida",
                    "city": "Algerri",
                    "multiple": "0"
                },
                {
                    "zipcode": "25131",
                    "province": "Lleida",
                    "city": "Torre-serona",
                    "multiple": "0"
                },
                {
                    "zipcode": "25132",
                    "province": "Lleida",
                    "city": "Benavent de Segrià",
                    "multiple": "0"
                },
                {
                    "zipcode": "25133",
                    "province": "Lleida",
                    "city": "Vilanova de Segrià",
                    "multiple": "0"
                },
                {
                    "zipcode": "25134",
                    "province": "Lleida",
                    "city": "Portella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25135",
                    "province": "Lleida",
                    "city": "Albesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25136",
                    "province": "Lleida",
                    "city": "Castelló de Farfanya",
                    "multiple": "0"
                },
                {
                    "zipcode": "25137",
                    "province": "Lleida",
                    "city": "Corbins",
                    "multiple": "0"
                },
                {
                    "zipcode": "25138",
                    "province": "Lleida",
                    "city": "Torrelameu",
                    "multiple": "0"
                },
                {
                    "zipcode": "25139",
                    "province": "Lleida",
                    "city": "Menàrguens",
                    "multiple": "0"
                },
                {
                    "zipcode": "25140",
                    "province": "Lleida",
                    "city": "Arbeca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25141",
                    "province": "Lleida",
                    "city": "Torregrossa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25142",
                    "province": "Lleida",
                    "city": "Bellvís",
                    "multiple": "0"
                },
                {
                    "zipcode": "25143",
                    "province": "Lleida",
                    "city": "Poal, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25144",
                    "province": "Lleida",
                    "city": "Bellvís",
                    "multiple": "0"
                },
                {
                    "zipcode": "25150",
                    "province": "Lleida",
                    "city": "Artesa de Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25151",
                    "province": "Lleida",
                    "city": "Aspa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25152",
                    "province": "Lleida",
                    "city": "Cogul, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25153",
                    "province": "Lleida",
                    "city": "Puigverd de Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25154",
                    "province": "Lleida",
                    "city": "Castelldans",
                    "multiple": "0"
                },
                {
                    "zipcode": "25155",
                    "province": "Lleida",
                    "city": "Albagés, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "25160",
                    "province": "Lleida",
                    "city": "Granyena de les Garrigues",
                    "multiple": "0"
                },
                {
                    "zipcode": "25161",
                    "province": "Lleida",
                    "city": "Alfés",
                    "multiple": "0"
                },
                {
                    "zipcode": "25162",
                    "province": "Lleida",
                    "city": "Alcanó",
                    "multiple": "0"
                },
                {
                    "zipcode": "25163",
                    "province": "Lleida",
                    "city": "Soleràs, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25164",
                    "province": "Lleida",
                    "city": "Torms, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "25165",
                    "province": "Lleida",
                    "city": "Juncosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25170",
                    "province": "Lleida",
                    "city": "Torres de Segre",
                    "multiple": "0"
                },
                {
                    "zipcode": "25171",
                    "province": "Lleida",
                    "city": "Albatàrrec",
                    "multiple": "0"
                },
                {
                    "zipcode": "25172",
                    "province": "Lleida",
                    "city": "Montoliu de Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25173",
                    "province": "Lleida",
                    "city": "Sudanell",
                    "multiple": "0"
                },
                {
                    "zipcode": "25174",
                    "province": "Lleida",
                    "city": "Sunyer",
                    "multiple": "0"
                },
                {
                    "zipcode": "25175",
                    "province": "Lleida",
                    "city": "Sarroca de Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25176",
                    "province": "Lleida",
                    "city": "Torrebesses",
                    "multiple": "0"
                },
                {
                    "zipcode": "25177",
                    "province": "Lleida",
                    "city": "Granadella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25177",
                    "province": "Lleida",
                    "city": "Bellaguarda",
                    "multiple": "1"
                },
                {
                    "zipcode": "25178",
                    "province": "Lleida",
                    "city": "Bovera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25179",
                    "province": "Lleida",
                    "city": "Maials",
                    "multiple": "0"
                },
                {
                    "zipcode": "25180",
                    "province": "Lleida",
                    "city": "Alcarràs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25181",
                    "province": "Lleida",
                    "city": "Soses",
                    "multiple": "0"
                },
                {
                    "zipcode": "25182",
                    "province": "Lleida",
                    "city": "Aitona",
                    "multiple": "0"
                },
                {
                    "zipcode": "25183",
                    "province": "Lleida",
                    "city": "Seròs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25184",
                    "province": "Lleida",
                    "city": "Massalcoreig",
                    "multiple": "0"
                },
                {
                    "zipcode": "25185",
                    "province": "Lleida",
                    "city": "Granja d'Escarp, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25186",
                    "province": "Lleida",
                    "city": "Llardecans",
                    "multiple": "0"
                },
                {
                    "zipcode": "25187",
                    "province": "Lleida",
                    "city": "Almatret",
                    "multiple": "0"
                },
                {
                    "zipcode": "25190",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25191",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25192",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25193",
                    "province": "Lleida",
                    "city": "Alcarràs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25193",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "1"
                },
                {
                    "zipcode": "25193",
                    "province": "Lleida",
                    "city": "Sant Llorenç de Morunys",
                    "multiple": "1"
                },
                {
                    "zipcode": "25194",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25195",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25196",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25197",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25198",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25199",
                    "province": "Lleida",
                    "city": "Lleida",
                    "multiple": "0"
                },
                {
                    "zipcode": "25200",
                    "province": "Lleida",
                    "city": "Cervera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25210",
                    "province": "Lleida",
                    "city": "Guissona",
                    "multiple": "0"
                },
                {
                    "zipcode": "25211",
                    "province": "Lleida",
                    "city": "Massoteres",
                    "multiple": "0"
                },
                {
                    "zipcode": "25211",
                    "province": "Lleida",
                    "city": "Torrefeta i Florejacs",
                    "multiple": "1"
                },
                {
                    "zipcode": "25211",
                    "province": "Lleida",
                    "city": "Sant Guim de la Plana",
                    "multiple": "1"
                },
                {
                    "zipcode": "25212",
                    "province": "Lleida",
                    "city": "Torrefeta i Florejacs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25212",
                    "province": "Lleida",
                    "city": "Plans de Sió, Els",
                    "multiple": "1"
                },
                {
                    "zipcode": "25212",
                    "province": "Lleida",
                    "city": "Tarroja de Segarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "25213",
                    "province": "Lleida",
                    "city": "Cervera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25213",
                    "province": "Lleida",
                    "city": "Ribera d'Ondara",
                    "multiple": "1"
                },
                {
                    "zipcode": "25213",
                    "province": "Lleida",
                    "city": "Talavera",
                    "multiple": "1"
                },
                {
                    "zipcode": "25214",
                    "province": "Lleida",
                    "city": "Senterada",
                    "multiple": "0"
                },
                {
                    "zipcode": "25214",
                    "province": "Lleida",
                    "city": "Oluges, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "25214",
                    "province": "Lleida",
                    "city": "Estaràs",
                    "multiple": "1"
                },
                {
                    "zipcode": "25214",
                    "province": "Lleida",
                    "city": "Cervera",
                    "multiple": "1"
                },
                {
                    "zipcode": "25215",
                    "province": "Lleida",
                    "city": "Estaràs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25215",
                    "province": "Lleida",
                    "city": "Sant Ramon",
                    "multiple": "1"
                },
                {
                    "zipcode": "25216",
                    "province": "Lleida",
                    "city": "Sant Ramon",
                    "multiple": "0"
                },
                {
                    "zipcode": "25216",
                    "province": "Lleida",
                    "city": "Ivorra",
                    "multiple": "1"
                },
                {
                    "zipcode": "25217",
                    "province": "Lleida",
                    "city": "Baix Pallars",
                    "multiple": "0"
                },
                {
                    "zipcode": "25217",
                    "province": "Lleida",
                    "city": "Ribera d'Ondara",
                    "multiple": "1"
                },
                {
                    "zipcode": "25217",
                    "province": "Lleida",
                    "city": "Montoliu de Segarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "25217",
                    "province": "Lleida",
                    "city": "Granyena de Segarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "25218",
                    "province": "Lleida",
                    "city": "Granyanella",
                    "multiple": "0"
                },
                {
                    "zipcode": "25218",
                    "province": "Lleida",
                    "city": "Cervera",
                    "multiple": "1"
                },
                {
                    "zipcode": "25218",
                    "province": "Lleida",
                    "city": "Plans de Sió, Els",
                    "multiple": "1"
                },
                {
                    "zipcode": "25220",
                    "province": "Lleida",
                    "city": "Bell-lloc d'Urgell",
                    "multiple": "0"
                },
                {
                    "zipcode": "25221",
                    "province": "Lleida",
                    "city": "Alamús, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "25222",
                    "province": "Lleida",
                    "city": "Sidamon",
                    "multiple": "0"
                },
                {
                    "zipcode": "25230",
                    "province": "Lleida",
                    "city": "Mollerussa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25240",
                    "province": "Lleida",
                    "city": "Linyola",
                    "multiple": "0"
                },
                {
                    "zipcode": "25240",
                    "province": "Lleida",
                    "city": "Penelles",
                    "multiple": "1"
                },
                {
                    "zipcode": "25241",
                    "province": "Lleida",
                    "city": "Golmés",
                    "multiple": "0"
                },
                {
                    "zipcode": "25242",
                    "province": "Lleida",
                    "city": "Miralcamp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25243",
                    "province": "Lleida",
                    "city": "Palau d'Anglesola, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25244",
                    "province": "Lleida",
                    "city": "Fondarella",
                    "multiple": "0"
                },
                {
                    "zipcode": "25245",
                    "province": "Lleida",
                    "city": "Vila-sana",
                    "multiple": "0"
                },
                {
                    "zipcode": "25250",
                    "province": "Lleida",
                    "city": "Bellpuig",
                    "multiple": "0"
                },
                {
                    "zipcode": "25260",
                    "province": "Lleida",
                    "city": "Ivars d'Urgell",
                    "multiple": "0"
                },
                {
                    "zipcode": "25261",
                    "province": "Lleida",
                    "city": "Ivars d'Urgell",
                    "multiple": "0"
                },
                {
                    "zipcode": "25261",
                    "province": "Lleida",
                    "city": "Penelles",
                    "multiple": "1"
                },
                {
                    "zipcode": "25262",
                    "province": "Lleida",
                    "city": "Barbens",
                    "multiple": "0"
                },
                {
                    "zipcode": "25263",
                    "province": "Lleida",
                    "city": "Preixana",
                    "multiple": "0"
                },
                {
                    "zipcode": "25264",
                    "province": "Lleida",
                    "city": "Vilanova de Bellpuig",
                    "multiple": "0"
                },
                {
                    "zipcode": "25265",
                    "province": "Lleida",
                    "city": "Castellnou de Seana",
                    "multiple": "0"
                },
                {
                    "zipcode": "25266",
                    "province": "Lleida",
                    "city": "Maldà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25266",
                    "province": "Lleida",
                    "city": "Belianes",
                    "multiple": "1"
                },
                {
                    "zipcode": "25267",
                    "province": "Lleida",
                    "city": "Sant Martí de Riucorb",
                    "multiple": "0"
                },
                {
                    "zipcode": "25268",
                    "province": "Lleida",
                    "city": "Vallbona de les Monges",
                    "multiple": "0"
                },
                {
                    "zipcode": "25268",
                    "province": "Lleida",
                    "city": "Omells de na Gaia, Els",
                    "multiple": "1"
                },
                {
                    "zipcode": "25269",
                    "province": "Lleida",
                    "city": "Vallbona de les Monges",
                    "multiple": "0"
                },
                {
                    "zipcode": "25270",
                    "province": "Lleida",
                    "city": "Sant Guim de Freixenet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25271",
                    "province": "Lleida",
                    "city": "Sant Guim de Freixenet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25271",
                    "province": "Lleida",
                    "city": "Estaràs",
                    "multiple": "1"
                },
                {
                    "zipcode": "25280",
                    "province": "Lleida",
                    "city": "Solsona",
                    "multiple": "0"
                },
                {
                    "zipcode": "25280",
                    "province": "Lleida",
                    "city": "Olius",
                    "multiple": "1"
                },
                {
                    "zipcode": "25281",
                    "province": "Lleida",
                    "city": "Llobera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25282",
                    "province": "Lleida",
                    "city": "Sant Llorenç de Morunys",
                    "multiple": "0"
                },
                {
                    "zipcode": "25283",
                    "province": "Lleida",
                    "city": "Lladurs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25283",
                    "province": "Lleida",
                    "city": "Odèn",
                    "multiple": "1"
                },
                {
                    "zipcode": "25284",
                    "province": "Lleida",
                    "city": "Coma i la Pedra, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25285",
                    "province": "Lleida",
                    "city": "Guixers",
                    "multiple": "0"
                },
                {
                    "zipcode": "25286",
                    "province": "Lleida",
                    "city": "Pinell de Solsonès",
                    "multiple": "0"
                },
                {
                    "zipcode": "25286",
                    "province": "Lleida",
                    "city": "Olius",
                    "multiple": "1"
                },
                {
                    "zipcode": "25286",
                    "province": "Lleida",
                    "city": "Alt Àneu",
                    "multiple": "1"
                },
                {
                    "zipcode": "25286",
                    "province": "Lleida",
                    "city": "Navès",
                    "multiple": "1"
                },
                {
                    "zipcode": "25287",
                    "province": "Lleida",
                    "city": "Olius",
                    "multiple": "0"
                },
                {
                    "zipcode": "25287",
                    "province": "Lleida",
                    "city": "Pinós",
                    "multiple": "1"
                },
                {
                    "zipcode": "25287",
                    "province": "Lleida",
                    "city": "Riner",
                    "multiple": "1"
                },
                {
                    "zipcode": "25288",
                    "province": "Lleida",
                    "city": "Lladurs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25289",
                    "province": "Lleida",
                    "city": "Bassella",
                    "multiple": "0"
                },
                {
                    "zipcode": "25289",
                    "province": "Lleida",
                    "city": "Castellar de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "25290",
                    "province": "Lleida",
                    "city": "Clariana de Cardener",
                    "multiple": "0"
                },
                {
                    "zipcode": "25290",
                    "province": "Lleida",
                    "city": "Riner",
                    "multiple": "1"
                },
                {
                    "zipcode": "25300",
                    "province": "Lleida",
                    "city": "Tàrrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "25310",
                    "province": "Lleida",
                    "city": "Agramunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "25315",
                    "province": "Lleida",
                    "city": "Montgai",
                    "multiple": "0"
                },
                {
                    "zipcode": "25316",
                    "province": "Lleida",
                    "city": "Preixens",
                    "multiple": "0"
                },
                {
                    "zipcode": "25317",
                    "province": "Lleida",
                    "city": "Agramunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "25318",
                    "province": "Lleida",
                    "city": "Agramunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "25318",
                    "province": "Lleida",
                    "city": "Oliola",
                    "multiple": "1"
                },
                {
                    "zipcode": "25318",
                    "province": "Lleida",
                    "city": "Ossó de Sió",
                    "multiple": "1"
                },
                {
                    "zipcode": "25318",
                    "province": "Lleida",
                    "city": "Puigverd d'Agramunt",
                    "multiple": "1"
                },
                {
                    "zipcode": "25320",
                    "province": "Lleida",
                    "city": "Anglesola",
                    "multiple": "0"
                },
                {
                    "zipcode": "25327",
                    "province": "Lleida",
                    "city": "Vall de Boí, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25330",
                    "province": "Lleida",
                    "city": "Vilagrassa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25331",
                    "province": "Lleida",
                    "city": "Agramunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "25331",
                    "province": "Lleida",
                    "city": "Tornabous",
                    "multiple": "1"
                },
                {
                    "zipcode": "25332",
                    "province": "Lleida",
                    "city": "Fuliola, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25333",
                    "province": "Lleida",
                    "city": "Penelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "25334",
                    "province": "Lleida",
                    "city": "Castellserà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25335",
                    "province": "Lleida",
                    "city": "Penelles",
                    "multiple": "0"
                },
                {
                    "zipcode": "25336",
                    "province": "Lleida",
                    "city": "Bellmunt d'Urgell",
                    "multiple": "0"
                },
                {
                    "zipcode": "25337",
                    "province": "Lleida",
                    "city": "Bellcaire d'Urgell",
                    "multiple": "0"
                },
                {
                    "zipcode": "25340",
                    "province": "Lleida",
                    "city": "Verdú",
                    "multiple": "0"
                },
                {
                    "zipcode": "25340",
                    "province": "Lleida",
                    "city": "Montornès de Segarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "25341",
                    "province": "Lleida",
                    "city": "Guimerà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25341",
                    "province": "Lleida",
                    "city": "Nalec",
                    "multiple": "1"
                },
                {
                    "zipcode": "25341",
                    "province": "Lleida",
                    "city": "Ciutadilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "25343",
                    "province": "Lleida",
                    "city": "Sant Martí de Riucorb",
                    "multiple": "0"
                },
                {
                    "zipcode": "25344",
                    "province": "Lleida",
                    "city": "Sant Martí de Riucorb",
                    "multiple": "0"
                },
                {
                    "zipcode": "25350",
                    "province": "Lleida",
                    "city": "Tàrrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "25351",
                    "province": "Lleida",
                    "city": "Tàrrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "25352",
                    "province": "Lleida",
                    "city": "Tàrrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "25353",
                    "province": "Lleida",
                    "city": "Tàrrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "25354",
                    "province": "Lleida",
                    "city": "Tàrrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "25360",
                    "province": "Lleida",
                    "city": "Tàrrega",
                    "multiple": "0"
                },
                {
                    "zipcode": "25400",
                    "province": "Lleida",
                    "city": "Borges Blanques, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "25410",
                    "province": "Lleida",
                    "city": "Espluga Calba, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "25411",
                    "province": "Lleida",
                    "city": "Fulleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "25412",
                    "province": "Lleida",
                    "city": "Omellons, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "25413",
                    "province": "Lleida",
                    "city": "Floresta, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25420",
                    "province": "Lleida",
                    "city": "Puiggròs",
                    "multiple": "0"
                },
                {
                    "zipcode": "25430",
                    "province": "Lleida",
                    "city": "Juneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "25440",
                    "province": "Lleida",
                    "city": "Vinaixa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25450",
                    "province": "Lleida",
                    "city": "Albi, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "25460",
                    "province": "Lleida",
                    "city": "Cervià de les Garrigues",
                    "multiple": "0"
                },
                {
                    "zipcode": "25471",
                    "province": "Lleida",
                    "city": "Pobla de Cérvoles, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25480",
                    "province": "Lleida",
                    "city": "Tarrés",
                    "multiple": "0"
                },
                {
                    "zipcode": "25500",
                    "province": "Lleida",
                    "city": "Pobla de Segur, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25510",
                    "province": "Lleida",
                    "city": "Torre de Cabdella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25511",
                    "province": "Lleida",
                    "city": "Torre de Cabdella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25512",
                    "province": "Lleida",
                    "city": "Torre de Cabdella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25513",
                    "province": "Lleida",
                    "city": "Baix Pallars",
                    "multiple": "0"
                },
                {
                    "zipcode": "25513",
                    "province": "Lleida",
                    "city": "Torre de Cabdella, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "25513",
                    "province": "Lleida",
                    "city": "Pobla de Segur, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "25514",
                    "province": "Lleida",
                    "city": "Senterada",
                    "multiple": "0"
                },
                {
                    "zipcode": "25514",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "25515",
                    "province": "Lleida",
                    "city": "Torre de Cabdella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25516",
                    "province": "Lleida",
                    "city": "Pobla de Segur, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25516",
                    "province": "Lleida",
                    "city": "Conca de Dalt",
                    "multiple": "1"
                },
                {
                    "zipcode": "25517",
                    "province": "Lleida",
                    "city": "Conca de Dalt",
                    "multiple": "0"
                },
                {
                    "zipcode": "25518",
                    "province": "Lleida",
                    "city": "Conca de Dalt",
                    "multiple": "0"
                },
                {
                    "zipcode": "25520",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25526",
                    "province": "Lleida",
                    "city": "Vall de Boí, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25526",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "25527",
                    "province": "Lleida",
                    "city": "Vall de Boí, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25528",
                    "province": "Lleida",
                    "city": "Vall de Boí, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25529",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25530",
                    "province": "Lleida",
                    "city": "Vielha e Mijaran",
                    "multiple": "0"
                },
                {
                    "zipcode": "25537",
                    "province": "Lleida",
                    "city": "Vielha e Mijaran",
                    "multiple": "0"
                },
                {
                    "zipcode": "25538",
                    "province": "Lleida",
                    "city": "Vielha e Mijaran",
                    "multiple": "0"
                },
                {
                    "zipcode": "25539",
                    "province": "Lleida",
                    "city": "Vielha e Mijaran",
                    "multiple": "0"
                },
                {
                    "zipcode": "25539",
                    "province": "Lleida",
                    "city": "Naut Aran",
                    "multiple": "1"
                },
                {
                    "zipcode": "25540",
                    "province": "Lleida",
                    "city": "Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "25547",
                    "province": "Lleida",
                    "city": "Vilosell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25548",
                    "province": "Lleida",
                    "city": "Canejan",
                    "multiple": "0"
                },
                {
                    "zipcode": "25549",
                    "province": "Lleida",
                    "city": "Bausen",
                    "multiple": "0"
                },
                {
                    "zipcode": "25550",
                    "province": "Lleida",
                    "city": "Bossòst",
                    "multiple": "0"
                },
                {
                    "zipcode": "25551",
                    "province": "Lleida",
                    "city": "Arres",
                    "multiple": "0"
                },
                {
                    "zipcode": "25551",
                    "province": "Lleida",
                    "city": "Vilamòs",
                    "multiple": "1"
                },
                {
                    "zipcode": "25551",
                    "province": "Lleida",
                    "city": "Bòrdes, Es",
                    "multiple": "1"
                },
                {
                    "zipcode": "25552",
                    "province": "Lleida",
                    "city": "Vilaller",
                    "multiple": "0"
                },
                {
                    "zipcode": "25552",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "25553",
                    "province": "Lleida",
                    "city": "Vilaller",
                    "multiple": "0"
                },
                {
                    "zipcode": "25554",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25555",
                    "province": "Lleida",
                    "city": "Senterada",
                    "multiple": "0"
                },
                {
                    "zipcode": "25555",
                    "province": "Lleida",
                    "city": "Sarroca de Bellera",
                    "multiple": "1"
                },
                {
                    "zipcode": "25555",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "25556",
                    "province": "Lleida",
                    "city": "Pont de Suert, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25557",
                    "province": "Lleida",
                    "city": "Vilosell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25560",
                    "province": "Lleida",
                    "city": "Alcoletge",
                    "multiple": "0"
                },
                {
                    "zipcode": "25560",
                    "province": "Lleida",
                    "city": "Sort",
                    "multiple": "1"
                },
                {
                    "zipcode": "25566",
                    "province": "Lleida",
                    "city": "Soriguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25567",
                    "province": "Lleida",
                    "city": "Sort",
                    "multiple": "0"
                },
                {
                    "zipcode": "25568",
                    "province": "Lleida",
                    "city": "Sort",
                    "multiple": "0"
                },
                {
                    "zipcode": "25569",
                    "province": "Lleida",
                    "city": "Sort",
                    "multiple": "0"
                },
                {
                    "zipcode": "25569",
                    "province": "Lleida",
                    "city": "Soriguera",
                    "multiple": "1"
                },
                {
                    "zipcode": "25570",
                    "province": "Lleida",
                    "city": "Vall de Cardós",
                    "multiple": "0"
                },
                {
                    "zipcode": "25571",
                    "province": "Lleida",
                    "city": "Lladorre",
                    "multiple": "0"
                },
                {
                    "zipcode": "25571",
                    "province": "Lleida",
                    "city": "Esterri de Cardós",
                    "multiple": "1"
                },
                {
                    "zipcode": "25571",
                    "province": "Lleida",
                    "city": "Vall de Cardós",
                    "multiple": "1"
                },
                {
                    "zipcode": "25572",
                    "province": "Lleida",
                    "city": "Vall de Cardós",
                    "multiple": "0"
                },
                {
                    "zipcode": "25573",
                    "province": "Lleida",
                    "city": "Alins",
                    "multiple": "0"
                },
                {
                    "zipcode": "25574",
                    "province": "Lleida",
                    "city": "Alins",
                    "multiple": "0"
                },
                {
                    "zipcode": "25575",
                    "province": "Lleida",
                    "city": "Alins",
                    "multiple": "0"
                },
                {
                    "zipcode": "25576",
                    "province": "Lleida",
                    "city": "Vall de Cardós",
                    "multiple": "0"
                },
                {
                    "zipcode": "25576",
                    "province": "Lleida",
                    "city": "Lladorre",
                    "multiple": "1"
                },
                {
                    "zipcode": "25577",
                    "province": "Lleida",
                    "city": "Lladorre",
                    "multiple": "0"
                },
                {
                    "zipcode": "25580",
                    "province": "Lleida",
                    "city": "Esterri d'Àneu",
                    "multiple": "0"
                },
                {
                    "zipcode": "25583",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25584",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25586",
                    "province": "Lleida",
                    "city": "Alt Àneu",
                    "multiple": "0"
                },
                {
                    "zipcode": "25587",
                    "province": "Lleida",
                    "city": "Alt Àneu",
                    "multiple": "0"
                },
                {
                    "zipcode": "25588",
                    "province": "Lleida",
                    "city": "Guingueta d'Àneu, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25589",
                    "province": "Lleida",
                    "city": "Alt Àneu",
                    "multiple": "0"
                },
                {
                    "zipcode": "25590",
                    "province": "Lleida",
                    "city": "Baix Pallars",
                    "multiple": "0"
                },
                {
                    "zipcode": "25591",
                    "province": "Lleida",
                    "city": "Baix Pallars",
                    "multiple": "0"
                },
                {
                    "zipcode": "25592",
                    "province": "Lleida",
                    "city": "Camarasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25592",
                    "province": "Lleida",
                    "city": "Baix Pallars",
                    "multiple": "1"
                },
                {
                    "zipcode": "25593",
                    "province": "Lleida",
                    "city": "Soriguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "25594",
                    "province": "Lleida",
                    "city": "Llavorsí",
                    "multiple": "0"
                },
                {
                    "zipcode": "25594",
                    "province": "Lleida",
                    "city": "Rialp",
                    "multiple": "1"
                },
                {
                    "zipcode": "25595",
                    "province": "Lleida",
                    "city": "Llavorsí",
                    "multiple": "0"
                },
                {
                    "zipcode": "25595",
                    "province": "Lleida",
                    "city": "Farrera",
                    "multiple": "1"
                },
                {
                    "zipcode": "25595",
                    "province": "Lleida",
                    "city": "Guingueta d'Àneu, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "25595",
                    "province": "Lleida",
                    "city": "Tírvia",
                    "multiple": "1"
                },
                {
                    "zipcode": "25596",
                    "province": "Lleida",
                    "city": "Guingueta d'Àneu, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25597",
                    "province": "Lleida",
                    "city": "Guingueta d'Àneu, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25597",
                    "province": "Lleida",
                    "city": "Espot",
                    "multiple": "1"
                },
                {
                    "zipcode": "25598",
                    "province": "Lleida",
                    "city": "Naut Aran",
                    "multiple": "0"
                },
                {
                    "zipcode": "25599",
                    "province": "Lleida",
                    "city": "Naut Aran",
                    "multiple": "0"
                },
                {
                    "zipcode": "25600",
                    "province": "Lleida",
                    "city": "Balaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "25610",
                    "province": "Lleida",
                    "city": "Os de Balaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "25611",
                    "province": "Lleida",
                    "city": "Avellanes i Santa Linya, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "25611",
                    "province": "Lleida",
                    "city": "Os de Balaguer",
                    "multiple": "1"
                },
                {
                    "zipcode": "25611",
                    "province": "Lleida",
                    "city": "Àger",
                    "multiple": "1"
                },
                {
                    "zipcode": "25612",
                    "province": "Lleida",
                    "city": "Os de Balaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "25612",
                    "province": "Lleida",
                    "city": "Avellanes i Santa Linya, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "25613",
                    "province": "Lleida",
                    "city": "Camarasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25614",
                    "province": "Lleida",
                    "city": "Os de Balaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "25615",
                    "province": "Lleida",
                    "city": "Camarasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25616",
                    "province": "Lleida",
                    "city": "Montgai",
                    "multiple": "0"
                },
                {
                    "zipcode": "25617",
                    "province": "Lleida",
                    "city": "Vallfogona de Balaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "25617",
                    "province": "Lleida",
                    "city": "Sentiu de Sió, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "25620",
                    "province": "Lleida",
                    "city": "Bellver de Cerdanya",
                    "multiple": "0"
                },
                {
                    "zipcode": "25620",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "1"
                },
                {
                    "zipcode": "25630",
                    "province": "Lleida",
                    "city": "Talarn",
                    "multiple": "0"
                },
                {
                    "zipcode": "25631",
                    "province": "Lleida",
                    "city": "Castell de Mur",
                    "multiple": "0"
                },
                {
                    "zipcode": "25632",
                    "province": "Lleida",
                    "city": "Sant Esteve de la Sarga",
                    "multiple": "0"
                },
                {
                    "zipcode": "25632",
                    "province": "Lleida",
                    "city": "Castell de Mur",
                    "multiple": "1"
                },
                {
                    "zipcode": "25633",
                    "province": "Lleida",
                    "city": "Castell de Mur",
                    "multiple": "0"
                },
                {
                    "zipcode": "25633",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "1"
                },
                {
                    "zipcode": "25634",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25635",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25636",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25637",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25638",
                    "province": "Lleida",
                    "city": "Llimiana",
                    "multiple": "0"
                },
                {
                    "zipcode": "25638",
                    "province": "Lleida",
                    "city": "Gavet de la Conca",
                    "multiple": "1"
                },
                {
                    "zipcode": "25639",
                    "province": "Lleida",
                    "city": "Gavet de la Conca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25639",
                    "province": "Lleida",
                    "city": "Llimiana",
                    "multiple": "1"
                },
                {
                    "zipcode": "25640",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25650",
                    "province": "Lleida",
                    "city": "Isona i Conca Dellà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25651",
                    "province": "Lleida",
                    "city": "Isona i Conca Dellà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25651",
                    "province": "Lleida",
                    "city": "Abella de la Conca",
                    "multiple": "1"
                },
                {
                    "zipcode": "25652",
                    "province": "Lleida",
                    "city": "Abella de la Conca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25653",
                    "province": "Lleida",
                    "city": "Isona i Conca Dellà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25654",
                    "province": "Lleida",
                    "city": "Tremp",
                    "multiple": "0"
                },
                {
                    "zipcode": "25655",
                    "province": "Lleida",
                    "city": "Isona i Conca Dellà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25656",
                    "province": "Lleida",
                    "city": "Isona i Conca Dellà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25657",
                    "province": "Lleida",
                    "city": "Isona i Conca Dellà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25658",
                    "province": "Lleida",
                    "city": "Isona i Conca Dellà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25660",
                    "province": "Lleida",
                    "city": "Alcoletge",
                    "multiple": "0"
                },
                {
                    "zipcode": "25670",
                    "province": "Lleida",
                    "city": "Térmens",
                    "multiple": "0"
                },
                {
                    "zipcode": "25680",
                    "province": "Lleida",
                    "city": "Vallfogona de Balaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "25689",
                    "province": "Lleida",
                    "city": "Gavet de la Conca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25690",
                    "province": "Lleida",
                    "city": "Vilanova de la Barca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25691",
                    "province": "Lleida",
                    "city": "Àger",
                    "multiple": "0"
                },
                {
                    "zipcode": "25692",
                    "province": "Lleida",
                    "city": "Camarasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "25692",
                    "province": "Lleida",
                    "city": "Àger",
                    "multiple": "1"
                },
                {
                    "zipcode": "25693",
                    "province": "Lleida",
                    "city": "Salàs de Pallars",
                    "multiple": "0"
                },
                {
                    "zipcode": "25700",
                    "province": "Lleida",
                    "city": "Seu d'Urgell, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25710",
                    "province": "Lleida",
                    "city": "Seu d'Urgell, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25711",
                    "province": "Lleida",
                    "city": "Montferrer i Castellbò",
                    "multiple": "0"
                },
                {
                    "zipcode": "25712",
                    "province": "Lleida",
                    "city": "Montferrer i Castellbò",
                    "multiple": "0"
                },
                {
                    "zipcode": "25713",
                    "province": "Lleida",
                    "city": "Ribera d'Urgellet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25714",
                    "province": "Lleida",
                    "city": "Montferrer i Castellbò",
                    "multiple": "0"
                },
                {
                    "zipcode": "25714",
                    "province": "Lleida",
                    "city": "Ribera d'Urgellet",
                    "multiple": "1"
                },
                {
                    "zipcode": "25714",
                    "province": "Lleida",
                    "city": "Valls d'Aguilar, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "25715",
                    "province": "Lleida",
                    "city": "Alàs i Cerc",
                    "multiple": "0"
                },
                {
                    "zipcode": "25716",
                    "province": "Lleida",
                    "city": "Gósol",
                    "multiple": "0"
                },
                {
                    "zipcode": "25717",
                    "province": "Lleida",
                    "city": "Josa i Tuixén",
                    "multiple": "0"
                },
                {
                    "zipcode": "25717",
                    "province": "Lleida",
                    "city": "Vall de Cardós",
                    "multiple": "1"
                },
                {
                    "zipcode": "25717",
                    "province": "Lleida",
                    "city": "Alàs i Cerc",
                    "multiple": "1"
                },
                {
                    "zipcode": "25717",
                    "province": "Lleida",
                    "city": "Vansa i Fórnols, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "25718",
                    "province": "Lleida",
                    "city": "Alàs i Cerc",
                    "multiple": "0"
                },
                {
                    "zipcode": "25719",
                    "province": "Lleida",
                    "city": "Estamariu",
                    "multiple": "0"
                },
                {
                    "zipcode": "25720",
                    "province": "Lleida",
                    "city": "Bellver de Cerdanya",
                    "multiple": "0"
                },
                {
                    "zipcode": "25721",
                    "province": "Lleida",
                    "city": "Bellver de Cerdanya",
                    "multiple": "0"
                },
                {
                    "zipcode": "25721",
                    "province": "Lleida",
                    "city": "Prats i Sansor",
                    "multiple": "1"
                },
                {
                    "zipcode": "25721",
                    "province": "Lleida",
                    "city": "Riu de Cerdanya",
                    "multiple": "1"
                },
                {
                    "zipcode": "25722",
                    "province": "Lleida",
                    "city": "Cava",
                    "multiple": "0"
                },
                {
                    "zipcode": "25722",
                    "province": "Lleida",
                    "city": "Arsèguel",
                    "multiple": "1"
                },
                {
                    "zipcode": "25722",
                    "province": "Lleida",
                    "city": "Pont de Bar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "25723",
                    "province": "Lleida",
                    "city": "Pont de Bar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "25724",
                    "province": "Lleida",
                    "city": "Montellà i Martinet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25724",
                    "province": "Lleida",
                    "city": "Bellver de Cerdanya",
                    "multiple": "1"
                },
                {
                    "zipcode": "25725",
                    "province": "Lleida",
                    "city": "Montellà i Martinet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25726",
                    "province": "Lleida",
                    "city": "Lles de Cerdanya",
                    "multiple": "0"
                },
                {
                    "zipcode": "25727",
                    "province": "Lleida",
                    "city": "Prullans",
                    "multiple": "0"
                },
                {
                    "zipcode": "25730",
                    "province": "Lleida",
                    "city": "Artesa de Segre",
                    "multiple": "0"
                },
                {
                    "zipcode": "25735",
                    "province": "Lleida",
                    "city": "Vilanova de Meià",
                    "multiple": "0"
                },
                {
                    "zipcode": "25736",
                    "province": "Lleida",
                    "city": "Vilanova de Meià",
                    "multiple": "0"
                },
                {
                    "zipcode": "25736",
                    "province": "Lleida",
                    "city": "Artesa de Segre",
                    "multiple": "1"
                },
                {
                    "zipcode": "25737",
                    "province": "Lleida",
                    "city": "Bassella",
                    "multiple": "0"
                },
                {
                    "zipcode": "25737",
                    "province": "Lleida",
                    "city": "Foradada",
                    "multiple": "1"
                },
                {
                    "zipcode": "25737",
                    "province": "Lleida",
                    "city": "Artesa de Segre",
                    "multiple": "1"
                },
                {
                    "zipcode": "25737",
                    "province": "Lleida",
                    "city": "Cubells",
                    "multiple": "1"
                },
                {
                    "zipcode": "25737",
                    "province": "Lleida",
                    "city": "Alòs de Balaguer",
                    "multiple": "1"
                },
                {
                    "zipcode": "25738",
                    "province": "Lleida",
                    "city": "Vilanova de Meià",
                    "multiple": "0"
                },
                {
                    "zipcode": "25738",
                    "province": "Lleida",
                    "city": "Artesa de Segre",
                    "multiple": "1"
                },
                {
                    "zipcode": "25739",
                    "province": "Lleida",
                    "city": "Artesa de Segre",
                    "multiple": "0"
                },
                {
                    "zipcode": "25740",
                    "province": "Lleida",
                    "city": "Ponts",
                    "multiple": "0"
                },
                {
                    "zipcode": "25746",
                    "province": "Lleida",
                    "city": "Ponts",
                    "multiple": "0"
                },
                {
                    "zipcode": "25747",
                    "province": "Lleida",
                    "city": "Baronia de Rialb, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "25747",
                    "province": "Lleida",
                    "city": "Abella de la Conca",
                    "multiple": "1"
                },
                {
                    "zipcode": "25748",
                    "province": "Lleida",
                    "city": "Oliola",
                    "multiple": "0"
                },
                {
                    "zipcode": "25748",
                    "province": "Lleida",
                    "city": "Vilanova de l'Aguda",
                    "multiple": "1"
                },
                {
                    "zipcode": "25748",
                    "province": "Lleida",
                    "city": "Cabanabona",
                    "multiple": "1"
                },
                {
                    "zipcode": "25749",
                    "province": "Lleida",
                    "city": "Oliola",
                    "multiple": "0"
                },
                {
                    "zipcode": "25749",
                    "province": "Lleida",
                    "city": "Ponts",
                    "multiple": "1"
                },
                {
                    "zipcode": "25749",
                    "province": "Lleida",
                    "city": "Vilanova de l'Aguda",
                    "multiple": "1"
                },
                {
                    "zipcode": "25750",
                    "province": "Lleida",
                    "city": "Torà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25751",
                    "province": "Lleida",
                    "city": "Torà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25752",
                    "province": "Lleida",
                    "city": "Biosca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25753",
                    "province": "Lleida",
                    "city": "Biosca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25753",
                    "province": "Lleida",
                    "city": "Sanaüja",
                    "multiple": "1"
                },
                {
                    "zipcode": "25772",
                    "province": "Lleida",
                    "city": "Biosca",
                    "multiple": "0"
                },
                {
                    "zipcode": "25790",
                    "province": "Lleida",
                    "city": "Peramola",
                    "multiple": "0"
                },
                {
                    "zipcode": "25790",
                    "province": "Lleida",
                    "city": "Odèn",
                    "multiple": "1"
                },
                {
                    "zipcode": "25790",
                    "province": "Lleida",
                    "city": "Bassella",
                    "multiple": "1"
                },
                {
                    "zipcode": "25790",
                    "province": "Lleida",
                    "city": "Oliana",
                    "multiple": "1"
                },
                {
                    "zipcode": "25790",
                    "province": "Lleida",
                    "city": "Coll de Nargó",
                    "multiple": "1"
                },
                {
                    "zipcode": "25791",
                    "province": "Lleida",
                    "city": "Tiurana",
                    "multiple": "0"
                },
                {
                    "zipcode": "25792",
                    "province": "Lleida",
                    "city": "Bassella",
                    "multiple": "0"
                },
                {
                    "zipcode": "25793",
                    "province": "Lleida",
                    "city": "Coll de Nargó",
                    "multiple": "0"
                },
                {
                    "zipcode": "25794",
                    "province": "Lleida",
                    "city": "Organyà",
                    "multiple": "0"
                },
                {
                    "zipcode": "25794",
                    "province": "Lleida",
                    "city": "Fígols i Alinyà",
                    "multiple": "1"
                },
                {
                    "zipcode": "25794",
                    "province": "Lleida",
                    "city": "Cabó",
                    "multiple": "1"
                },
                {
                    "zipcode": "25794",
                    "province": "Lleida",
                    "city": "Coll de Nargó",
                    "multiple": "1"
                },
                {
                    "zipcode": "25795",
                    "province": "Lleida",
                    "city": "Ribera d'Urgellet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25795",
                    "province": "Lleida",
                    "city": "Valls d'Aguilar, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "25796",
                    "province": "Lleida",
                    "city": "Ribera d'Urgellet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25797",
                    "province": "Lleida",
                    "city": "Ribera d'Urgellet",
                    "multiple": "0"
                },
                {
                    "zipcode": "25798",
                    "province": "Lleida",
                    "city": "Valls de Valira, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "25799",
                    "province": "Lleida",
                    "city": "Pont de Bar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "26001",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26002",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26003",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26004",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26005",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26006",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26006",
                    "province": "La Rioja",
                    "city": "Villamediana de Iregua",
                    "multiple": "1"
                },
                {
                    "zipcode": "26007",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26008",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26009",
                    "province": "La Rioja",
                    "city": "Logroño",
                    "multiple": "0"
                },
                {
                    "zipcode": "26100",
                    "province": "La Rioja",
                    "city": "Torrecilla en Cameros",
                    "multiple": "0"
                },
                {
                    "zipcode": "26100",
                    "province": "La Rioja",
                    "city": "Almarza de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26110",
                    "province": "La Rioja",
                    "city": "Nestares",
                    "multiple": "0"
                },
                {
                    "zipcode": "26111",
                    "province": "La Rioja",
                    "city": "Pinillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "26111",
                    "province": "La Rioja",
                    "city": "Almarza de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26120",
                    "province": "La Rioja",
                    "city": "Albelda de Iregua",
                    "multiple": "0"
                },
                {
                    "zipcode": "26121",
                    "province": "La Rioja",
                    "city": "Viguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "26121",
                    "province": "La Rioja",
                    "city": "Nalda",
                    "multiple": "1"
                },
                {
                    "zipcode": "26122",
                    "province": "La Rioja",
                    "city": "Pradillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26122",
                    "province": "La Rioja",
                    "city": "Gallinero de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26123",
                    "province": "La Rioja",
                    "city": "Villanueva de Cameros",
                    "multiple": "0"
                },
                {
                    "zipcode": "26124",
                    "province": "La Rioja",
                    "city": "Rasillo de Cameros, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "26124",
                    "province": "La Rioja",
                    "city": "Ortigosa de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26124",
                    "province": "La Rioja",
                    "city": "Nieva de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26125",
                    "province": "La Rioja",
                    "city": "Villoslada de Cameros",
                    "multiple": "0"
                },
                {
                    "zipcode": "26126",
                    "province": "La Rioja",
                    "city": "Lumbreras",
                    "multiple": "0"
                },
                {
                    "zipcode": "26130",
                    "province": "La Rioja",
                    "city": "Ribafrecha",
                    "multiple": "0"
                },
                {
                    "zipcode": "26130",
                    "province": "La Rioja",
                    "city": "Alberite",
                    "multiple": "1"
                },
                {
                    "zipcode": "26130",
                    "province": "La Rioja",
                    "city": "Clavijo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26131",
                    "province": "La Rioja",
                    "city": "Robres del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26131",
                    "province": "La Rioja",
                    "city": "Lagunilla del Jubera",
                    "multiple": "1"
                },
                {
                    "zipcode": "26131",
                    "province": "La Rioja",
                    "city": "Santa Engracia del Jubera",
                    "multiple": "1"
                },
                {
                    "zipcode": "26132",
                    "province": "La Rioja",
                    "city": "Terroba",
                    "multiple": "0"
                },
                {
                    "zipcode": "26132",
                    "province": "La Rioja",
                    "city": "Leza de Río Leza",
                    "multiple": "1"
                },
                {
                    "zipcode": "26132",
                    "province": "La Rioja",
                    "city": "Santa Engracia del Jubera",
                    "multiple": "1"
                },
                {
                    "zipcode": "26132",
                    "province": "La Rioja",
                    "city": "Soto en Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26133",
                    "province": "La Rioja",
                    "city": "Ajamil de Cameros",
                    "multiple": "0"
                },
                {
                    "zipcode": "26133",
                    "province": "La Rioja",
                    "city": "Hornillos de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26133",
                    "province": "La Rioja",
                    "city": "San Román de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26133",
                    "province": "La Rioja",
                    "city": "Rabanera",
                    "multiple": "1"
                },
                {
                    "zipcode": "26134",
                    "province": "La Rioja",
                    "city": "Muro en Cameros",
                    "multiple": "0"
                },
                {
                    "zipcode": "26134",
                    "province": "La Rioja",
                    "city": "Torre en Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26134",
                    "province": "La Rioja",
                    "city": "Jalón de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26135",
                    "province": "La Rioja",
                    "city": "Laguna de Cameros",
                    "multiple": "0"
                },
                {
                    "zipcode": "26135",
                    "province": "La Rioja",
                    "city": "Cabezón de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26140",
                    "province": "La Rioja",
                    "city": "Lardero",
                    "multiple": "0"
                },
                {
                    "zipcode": "26141",
                    "province": "La Rioja",
                    "city": "Alberite",
                    "multiple": "0"
                },
                {
                    "zipcode": "26142",
                    "province": "La Rioja",
                    "city": "Villamediana de Iregua",
                    "multiple": "0"
                },
                {
                    "zipcode": "26143",
                    "province": "La Rioja",
                    "city": "Murillo de Río Leza",
                    "multiple": "0"
                },
                {
                    "zipcode": "26144",
                    "province": "La Rioja",
                    "city": "Galilea",
                    "multiple": "0"
                },
                {
                    "zipcode": "26144",
                    "province": "La Rioja",
                    "city": "Ocón",
                    "multiple": "1"
                },
                {
                    "zipcode": "26144",
                    "province": "La Rioja",
                    "city": "Corera",
                    "multiple": "1"
                },
                {
                    "zipcode": "26145",
                    "province": "La Rioja",
                    "city": "Ocón",
                    "multiple": "0"
                },
                {
                    "zipcode": "26146",
                    "province": "La Rioja",
                    "city": "Redal, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "26147",
                    "province": "La Rioja",
                    "city": "Ocón",
                    "multiple": "0"
                },
                {
                    "zipcode": "26148",
                    "province": "La Rioja",
                    "city": "Ocón",
                    "multiple": "0"
                },
                {
                    "zipcode": "26151",
                    "province": "La Rioja",
                    "city": "Arrúbal",
                    "multiple": "0"
                },
                {
                    "zipcode": "26160",
                    "province": "La Rioja",
                    "city": "Agoncillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26190",
                    "province": "La Rioja",
                    "city": "Nalda",
                    "multiple": "0"
                },
                {
                    "zipcode": "26191",
                    "province": "La Rioja",
                    "city": "Sorzano",
                    "multiple": "0"
                },
                {
                    "zipcode": "26200",
                    "province": "La Rioja",
                    "city": "Nájera",
                    "multiple": "0"
                },
                {
                    "zipcode": "26200",
                    "province": "La Rioja",
                    "city": "Haro",
                    "multiple": "1"
                },
                {
                    "zipcode": "26210",
                    "province": "La Rioja",
                    "city": "Cihuri",
                    "multiple": "0"
                },
                {
                    "zipcode": "26210",
                    "province": "La Rioja",
                    "city": "Tirgo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26210",
                    "province": "La Rioja",
                    "city": "Anguciana",
                    "multiple": "1"
                },
                {
                    "zipcode": "26211",
                    "province": "La Rioja",
                    "city": "Tirgo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26211",
                    "province": "La Rioja",
                    "city": "Foncea",
                    "multiple": "1"
                },
                {
                    "zipcode": "26211",
                    "province": "La Rioja",
                    "city": "Fonzaleche",
                    "multiple": "1"
                },
                {
                    "zipcode": "26212",
                    "province": "Burgos",
                    "city": "Fonzaleche",
                    "multiple": "0"
                },
                {
                    "zipcode": "26212",
                    "province": "Burgos",
                    "city": "Cellorigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26212",
                    "province": "Burgos",
                    "city": "Miranda de Ebro",
                    "multiple": "1"
                },
                {
                    "zipcode": "26212",
                    "province": "Burgos",
                    "city": "Galbárruli",
                    "multiple": "1"
                },
                {
                    "zipcode": "26212",
                    "province": "Burgos",
                    "city": "Sajazarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "26213",
                    "province": "La Rioja",
                    "city": "Tormantos",
                    "multiple": "0"
                },
                {
                    "zipcode": "26213",
                    "province": "La Rioja",
                    "city": "Herramélluri",
                    "multiple": "1"
                },
                {
                    "zipcode": "26213",
                    "province": "La Rioja",
                    "city": "Leiva",
                    "multiple": "1"
                },
                {
                    "zipcode": "26213",
                    "province": "La Rioja",
                    "city": "Ochánduri",
                    "multiple": "1"
                },
                {
                    "zipcode": "26214",
                    "province": "La Rioja",
                    "city": "Cuzcurrita de Río Tirón",
                    "multiple": "0"
                },
                {
                    "zipcode": "26214",
                    "province": "La Rioja",
                    "city": "Rodezno",
                    "multiple": "1"
                },
                {
                    "zipcode": "26215",
                    "province": "La Rioja",
                    "city": "Treviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "26216",
                    "province": "La Rioja",
                    "city": "San Millán de Yécora",
                    "multiple": "0"
                },
                {
                    "zipcode": "26220",
                    "province": "La Rioja",
                    "city": "Ollauri",
                    "multiple": "0"
                },
                {
                    "zipcode": "26221",
                    "province": "La Rioja",
                    "city": "Gimileo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26222",
                    "province": "La Rioja",
                    "city": "Rodezno",
                    "multiple": "0"
                },
                {
                    "zipcode": "26223",
                    "province": "La Rioja",
                    "city": "Hormilleja",
                    "multiple": "0"
                },
                {
                    "zipcode": "26224",
                    "province": "La Rioja",
                    "city": "Torrecilla sobre Alesanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "26230",
                    "province": "La Rioja",
                    "city": "Casalarreina",
                    "multiple": "0"
                },
                {
                    "zipcode": "26240",
                    "province": "La Rioja",
                    "city": "Castañares de Rioja",
                    "multiple": "0"
                },
                {
                    "zipcode": "26241",
                    "province": "La Rioja",
                    "city": "Baños de Rioja",
                    "multiple": "0"
                },
                {
                    "zipcode": "26250",
                    "province": "La Rioja",
                    "city": "Arnedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26250",
                    "province": "La Rioja",
                    "city": "Santo Domingo de la Calzada",
                    "multiple": "1"
                },
                {
                    "zipcode": "26256",
                    "province": "La Rioja",
                    "city": "Villalobar de Rioja",
                    "multiple": "0"
                },
                {
                    "zipcode": "26257",
                    "province": "La Rioja",
                    "city": "Bañares",
                    "multiple": "0"
                },
                {
                    "zipcode": "26257",
                    "province": "La Rioja",
                    "city": "Hervías",
                    "multiple": "1"
                },
                {
                    "zipcode": "26258",
                    "province": "La Rioja",
                    "city": "Cirueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "26258",
                    "province": "La Rioja",
                    "city": "Manzanares de Rioja",
                    "multiple": "1"
                },
                {
                    "zipcode": "26259",
                    "province": "La Rioja",
                    "city": "Corporales",
                    "multiple": "0"
                },
                {
                    "zipcode": "26259",
                    "province": "La Rioja",
                    "city": "Grañón",
                    "multiple": "1"
                },
                {
                    "zipcode": "26259",
                    "province": "La Rioja",
                    "city": "Villarta-Quintana",
                    "multiple": "1"
                },
                {
                    "zipcode": "26260",
                    "province": "La Rioja",
                    "city": "Santurde de Rioja",
                    "multiple": "0"
                },
                {
                    "zipcode": "26261",
                    "province": "La Rioja",
                    "city": "Santurdejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26261",
                    "province": "La Rioja",
                    "city": "Pazuengos",
                    "multiple": "1"
                },
                {
                    "zipcode": "26270",
                    "province": "La Rioja",
                    "city": "Ojacastro",
                    "multiple": "0"
                },
                {
                    "zipcode": "26280",
                    "province": "La Rioja",
                    "city": "Ezcaray",
                    "multiple": "0"
                },
                {
                    "zipcode": "26288",
                    "province": "La Rioja",
                    "city": "Zorraquín",
                    "multiple": "0"
                },
                {
                    "zipcode": "26288",
                    "province": "La Rioja",
                    "city": "Valgañón",
                    "multiple": "1"
                },
                {
                    "zipcode": "26289",
                    "province": "La Rioja",
                    "city": "Ezcaray",
                    "multiple": "0"
                },
                {
                    "zipcode": "26290",
                    "province": "La Rioja",
                    "city": "Briñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "26291",
                    "province": "La Rioja",
                    "city": "Zarratón",
                    "multiple": "0"
                },
                {
                    "zipcode": "26291",
                    "province": "La Rioja",
                    "city": "San Torcuato",
                    "multiple": "1"
                },
                {
                    "zipcode": "26291",
                    "province": "La Rioja",
                    "city": "Cidamón",
                    "multiple": "1"
                },
                {
                    "zipcode": "26292",
                    "province": "La Rioja",
                    "city": "Villalba de Rioja",
                    "multiple": "0"
                },
                {
                    "zipcode": "26292",
                    "province": "La Rioja",
                    "city": "Haro",
                    "multiple": "1"
                },
                {
                    "zipcode": "26300",
                    "province": "La Rioja",
                    "city": "Nájera",
                    "multiple": "0"
                },
                {
                    "zipcode": "26310",
                    "province": "La Rioja",
                    "city": "Badarán",
                    "multiple": "0"
                },
                {
                    "zipcode": "26311",
                    "province": "La Rioja",
                    "city": "Cárdenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "26311",
                    "province": "La Rioja",
                    "city": "Camprovín",
                    "multiple": "1"
                },
                {
                    "zipcode": "26311",
                    "province": "La Rioja",
                    "city": "Cordovín",
                    "multiple": "1"
                },
                {
                    "zipcode": "26311",
                    "province": "La Rioja",
                    "city": "Arenzana de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26312",
                    "province": "La Rioja",
                    "city": "Arenzana de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "26312",
                    "province": "La Rioja",
                    "city": "Tricio",
                    "multiple": "1"
                },
                {
                    "zipcode": "26312",
                    "province": "La Rioja",
                    "city": "Bezares",
                    "multiple": "1"
                },
                {
                    "zipcode": "26313",
                    "province": "La Rioja",
                    "city": "Uruñuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "26314",
                    "province": "La Rioja",
                    "city": "Huércanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "26315",
                    "province": "La Rioja",
                    "city": "Santa Coloma",
                    "multiple": "0"
                },
                {
                    "zipcode": "26315",
                    "province": "La Rioja",
                    "city": "Alesón",
                    "multiple": "1"
                },
                {
                    "zipcode": "26315",
                    "province": "La Rioja",
                    "city": "Castroviejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26315",
                    "province": "La Rioja",
                    "city": "Bezares",
                    "multiple": "1"
                },
                {
                    "zipcode": "26315",
                    "province": "La Rioja",
                    "city": "Manjarrés",
                    "multiple": "1"
                },
                {
                    "zipcode": "26320",
                    "province": "La Rioja",
                    "city": "Baños de Río Tobía",
                    "multiple": "0"
                },
                {
                    "zipcode": "26321",
                    "province": "La Rioja",
                    "city": "Pedroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "26321",
                    "province": "La Rioja",
                    "city": "Bobadilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "26321",
                    "province": "La Rioja",
                    "city": "Villaverde de Rioja",
                    "multiple": "1"
                },
                {
                    "zipcode": "26321",
                    "province": "La Rioja",
                    "city": "Ledesma de la Cogolla",
                    "multiple": "1"
                },
                {
                    "zipcode": "26321",
                    "province": "La Rioja",
                    "city": "Tobía",
                    "multiple": "1"
                },
                {
                    "zipcode": "26321",
                    "province": "La Rioja",
                    "city": "Matute",
                    "multiple": "1"
                },
                {
                    "zipcode": "26322",
                    "province": "La Rioja",
                    "city": "Anguiano",
                    "multiple": "0"
                },
                {
                    "zipcode": "26322",
                    "province": "La Rioja",
                    "city": "Brieva de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "26323",
                    "province": "La Rioja",
                    "city": "Hormilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "26323",
                    "province": "La Rioja",
                    "city": "Azofra",
                    "multiple": "1"
                },
                {
                    "zipcode": "26324",
                    "province": "La Rioja",
                    "city": "Alesanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "26325",
                    "province": "La Rioja",
                    "city": "Cañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "26325",
                    "province": "La Rioja",
                    "city": "Villarejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26325",
                    "province": "La Rioja",
                    "city": "Villar de Torre",
                    "multiple": "1"
                },
                {
                    "zipcode": "26325",
                    "province": "La Rioja",
                    "city": "Canillas de Río Tuerto",
                    "multiple": "1"
                },
                {
                    "zipcode": "26326",
                    "province": "La Rioja",
                    "city": "San Millán de la Cogolla",
                    "multiple": "0"
                },
                {
                    "zipcode": "26327",
                    "province": "La Rioja",
                    "city": "Berceo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26328",
                    "province": "La Rioja",
                    "city": "Estollo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26329",
                    "province": "La Rioja",
                    "city": "Villavelayo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26329",
                    "province": "La Rioja",
                    "city": "Mansilla de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "26329",
                    "province": "La Rioja",
                    "city": "Ventrosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "26329",
                    "province": "La Rioja",
                    "city": "Camprovín",
                    "multiple": "1"
                },
                {
                    "zipcode": "26329",
                    "province": "La Rioja",
                    "city": "Viniegra de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "26329",
                    "province": "La Rioja",
                    "city": "Canales de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "26329",
                    "province": "La Rioja",
                    "city": "Viniegra de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26330",
                    "province": "La Rioja",
                    "city": "Briones",
                    "multiple": "0"
                },
                {
                    "zipcode": "26338",
                    "province": "La Rioja",
                    "city": "San Vicente de la Sonsierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "26339",
                    "province": "La Rioja",
                    "city": "San Vicente de la Sonsierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "26339",
                    "province": "La Rioja",
                    "city": "Ábalos",
                    "multiple": "1"
                },
                {
                    "zipcode": "26340",
                    "province": "La Rioja",
                    "city": "San Asensio",
                    "multiple": "0"
                },
                {
                    "zipcode": "26350",
                    "province": "La Rioja",
                    "city": "Cenicero",
                    "multiple": "0"
                },
                {
                    "zipcode": "26359",
                    "province": "La Rioja",
                    "city": "Torremontalbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26360",
                    "province": "La Rioja",
                    "city": "Fuenmayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "26370",
                    "province": "La Rioja",
                    "city": "Navarrete",
                    "multiple": "0"
                },
                {
                    "zipcode": "26371",
                    "province": "La Rioja",
                    "city": "Ventosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "26371",
                    "province": "La Rioja",
                    "city": "Sotés",
                    "multiple": "1"
                },
                {
                    "zipcode": "26372",
                    "province": "La Rioja",
                    "city": "Hornos de Moncalvillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26373",
                    "province": "La Rioja",
                    "city": "Daroca de Rioja",
                    "multiple": "0"
                },
                {
                    "zipcode": "26374",
                    "province": "La Rioja",
                    "city": "Medrano",
                    "multiple": "0"
                },
                {
                    "zipcode": "26375",
                    "province": "La Rioja",
                    "city": "Entrena",
                    "multiple": "0"
                },
                {
                    "zipcode": "26376",
                    "province": "La Rioja",
                    "city": "Sojuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "26500",
                    "province": "La Rioja",
                    "city": "Calahorra",
                    "multiple": "0"
                },
                {
                    "zipcode": "26509",
                    "province": "La Rioja",
                    "city": "Alcanadre",
                    "multiple": "0"
                },
                {
                    "zipcode": "26510",
                    "province": "La Rioja",
                    "city": "Pradejón",
                    "multiple": "0"
                },
                {
                    "zipcode": "26511",
                    "province": "La Rioja",
                    "city": "Villar de Arnedo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "26512",
                    "province": "La Rioja",
                    "city": "Tudelilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "26513",
                    "province": "La Rioja",
                    "city": "Ausejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26520",
                    "province": "La Rioja",
                    "city": "Cervera del Río Alhama",
                    "multiple": "0"
                },
                {
                    "zipcode": "26525",
                    "province": "La Rioja",
                    "city": "Igea",
                    "multiple": "0"
                },
                {
                    "zipcode": "26526",
                    "province": "La Rioja",
                    "city": "Cornago",
                    "multiple": "0"
                },
                {
                    "zipcode": "26527",
                    "province": "La Rioja",
                    "city": "Cervera del Río Alhama",
                    "multiple": "0"
                },
                {
                    "zipcode": "26527",
                    "province": "La Rioja",
                    "city": "Cornago",
                    "multiple": "1"
                },
                {
                    "zipcode": "26528",
                    "province": "La Rioja",
                    "city": "Cervera del Río Alhama",
                    "multiple": "0"
                },
                {
                    "zipcode": "26529",
                    "province": "La Rioja",
                    "city": "Cervera del Río Alhama",
                    "multiple": "0"
                },
                {
                    "zipcode": "26530",
                    "province": "La Rioja",
                    "city": "Aguilar del Río Alhama",
                    "multiple": "0"
                },
                {
                    "zipcode": "26531",
                    "province": "La Rioja",
                    "city": "Aguilar del Río Alhama",
                    "multiple": "0"
                },
                {
                    "zipcode": "26532",
                    "province": "La Rioja",
                    "city": "Valdemadera",
                    "multiple": "0"
                },
                {
                    "zipcode": "26533",
                    "province": "La Rioja",
                    "city": "Navajún",
                    "multiple": "0"
                },
                {
                    "zipcode": "26540",
                    "province": "La Rioja",
                    "city": "Alfaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "26550",
                    "province": "La Rioja",
                    "city": "Rincón de Soto",
                    "multiple": "0"
                },
                {
                    "zipcode": "26559",
                    "province": "La Rioja",
                    "city": "Aldeanueva de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "26560",
                    "province": "La Rioja",
                    "city": "Autol",
                    "multiple": "0"
                },
                {
                    "zipcode": "26570",
                    "province": "La Rioja",
                    "city": "Quel",
                    "multiple": "0"
                },
                {
                    "zipcode": "26580",
                    "province": "La Rioja",
                    "city": "Arnedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "26584",
                    "province": "La Rioja",
                    "city": "Herce",
                    "multiple": "0"
                },
                {
                    "zipcode": "26585",
                    "province": "La Rioja",
                    "city": "Santa Eulalia Bajera",
                    "multiple": "0"
                },
                {
                    "zipcode": "26585",
                    "province": "La Rioja",
                    "city": "Arnedillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "26586",
                    "province": "La Rioja",
                    "city": "Enciso",
                    "multiple": "0"
                },
                {
                    "zipcode": "26586",
                    "province": "La Rioja",
                    "city": "Munilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "26586",
                    "province": "La Rioja",
                    "city": "Zarzosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "26587",
                    "province": "La Rioja",
                    "city": "Grávalos",
                    "multiple": "0"
                },
                {
                    "zipcode": "26587",
                    "province": "La Rioja",
                    "city": "Muro de Aguas",
                    "multiple": "1"
                },
                {
                    "zipcode": "26587",
                    "province": "La Rioja",
                    "city": "Villarroya",
                    "multiple": "1"
                },
                {
                    "zipcode": "26588",
                    "province": "La Rioja",
                    "city": "Bergasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "26588",
                    "province": "La Rioja",
                    "city": "Bergasillas Bajera",
                    "multiple": "1"
                },
                {
                    "zipcode": "26589",
                    "province": "La Rioja",
                    "city": "Préjano",
                    "multiple": "0"
                },
                {
                    "zipcode": "26589",
                    "province": "La Rioja",
                    "city": "Arnedillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "27001",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27002",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27003",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27004",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27100",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27110",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27111",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27112",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27113",
                    "province": "Lugo",
                    "city": "Negueira de Muñiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27113",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "27114",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27115",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27116",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27117",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27118",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27120",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27122",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27123",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27124",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27125",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27126",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27127",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27128",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27129",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27130",
                    "province": "Lugo",
                    "city": "Baleira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27131",
                    "province": "Lugo",
                    "city": "Baleira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27132",
                    "province": "Lugo",
                    "city": "Baleira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27133",
                    "province": "Lugo",
                    "city": "Baleira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27134",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27135",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27136",
                    "province": "Lugo",
                    "city": "Fonsagrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27140",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27141",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27142",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27143",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27144",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27145",
                    "province": "Lugo",
                    "city": "Corgo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27146",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27146",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "27150",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27151",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27152",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27152",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "1"
                },
                {
                    "zipcode": "27153",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27154",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27155",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27156",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27157",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27160",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27161",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27162",
                    "province": "Lugo",
                    "city": "Corgo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27163",
                    "province": "Lugo",
                    "city": "Corgo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27164",
                    "province": "Lugo",
                    "city": "Corgo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27166",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "27168",
                    "province": "Lugo",
                    "city": "Corgo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27169",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27170",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27177",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27178",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27179",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27180",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27181",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27181",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "1"
                },
                {
                    "zipcode": "27182",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27183",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27184",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27185",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27185",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "1"
                },
                {
                    "zipcode": "27186",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27187",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27188",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27188",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "1"
                },
                {
                    "zipcode": "27190",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27190",
                    "province": "Lugo",
                    "city": "Corgo, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "27191",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27192",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27200",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27203",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27204",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27205",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27206",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27207",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27208",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27209",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27210",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27210",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "1"
                },
                {
                    "zipcode": "27211",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27212",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27213",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27214",
                    "province": "Lugo",
                    "city": "Portomarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27215",
                    "province": "Lugo",
                    "city": "Monterroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "27216",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27217",
                    "province": "Lugo",
                    "city": "Palas de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27220",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27220",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "1"
                },
                {
                    "zipcode": "27226",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27227",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27228",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27229",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27230",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27231",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27232",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27233",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27233",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "1"
                },
                {
                    "zipcode": "27234",
                    "province": "Lugo",
                    "city": "Folgoso do Courel",
                    "multiple": "0"
                },
                {
                    "zipcode": "27234",
                    "province": "Lugo",
                    "city": "Guntín",
                    "multiple": "1"
                },
                {
                    "zipcode": "27235",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27240",
                    "province": "Lugo",
                    "city": "Meira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27241",
                    "province": "Lugo",
                    "city": "Meira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27243",
                    "province": "Lugo",
                    "city": "Ribeira de Piquín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27244",
                    "province": "Lugo",
                    "city": "Ribeira de Piquín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27245",
                    "province": "Lugo",
                    "city": "Ribeira de Piquín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27246",
                    "province": "Lugo",
                    "city": "Pastoriza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27247",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27247",
                    "province": "Lugo",
                    "city": "Meira",
                    "multiple": "1"
                },
                {
                    "zipcode": "27248",
                    "province": "Lugo",
                    "city": "Pastoriza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27250",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27256",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27257",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27258",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27259",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27260",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27266",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27267",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27268",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27269",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27270",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27271",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27272",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27273",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27274",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27275",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27276",
                    "province": "Lugo",
                    "city": "Baleira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27277",
                    "province": "Lugo",
                    "city": "Baleira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27278",
                    "province": "Lugo",
                    "city": "Baleira",
                    "multiple": "0"
                },
                {
                    "zipcode": "27279",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27280",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27283",
                    "province": "Lugo",
                    "city": "Pol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27286",
                    "province": "Lugo",
                    "city": "Pastoriza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27287",
                    "province": "Lugo",
                    "city": "Pastoriza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27289",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27289",
                    "province": "Lugo",
                    "city": "Castro de Rei",
                    "multiple": "1"
                },
                {
                    "zipcode": "27290",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27293",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27293",
                    "province": "Lugo",
                    "city": "Castroverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "27294",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27296",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27297",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27298",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27299",
                    "province": "Lugo",
                    "city": "Lugo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27299",
                    "province": "Lugo",
                    "city": "Friol",
                    "multiple": "1"
                },
                {
                    "zipcode": "27300",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27305",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27306",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27307",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27308",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27309",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27310",
                    "province": "Lugo",
                    "city": "Ribas de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "27317",
                    "province": "Lugo",
                    "city": "Ribas de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "27318",
                    "province": "Lugo",
                    "city": "Ribas de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "27320",
                    "province": "Lugo",
                    "city": "Quiroga",
                    "multiple": "0"
                },
                {
                    "zipcode": "27324",
                    "province": "Lugo",
                    "city": "Folgoso do Courel",
                    "multiple": "0"
                },
                {
                    "zipcode": "27325",
                    "province": "Lugo",
                    "city": "Folgoso do Courel",
                    "multiple": "0"
                },
                {
                    "zipcode": "27326",
                    "province": "Lugo",
                    "city": "Folgoso do Courel",
                    "multiple": "0"
                },
                {
                    "zipcode": "27327",
                    "province": "Lugo",
                    "city": "Folgoso do Courel",
                    "multiple": "0"
                },
                {
                    "zipcode": "27328",
                    "province": "Lugo",
                    "city": "Folgoso do Courel",
                    "multiple": "0"
                },
                {
                    "zipcode": "27328",
                    "province": "Lugo",
                    "city": "Quiroga",
                    "multiple": "1"
                },
                {
                    "zipcode": "27329",
                    "province": "Lugo",
                    "city": "Quiroga",
                    "multiple": "0"
                },
                {
                    "zipcode": "27330",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27331",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27332",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27333",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27334",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27335",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27336",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27338",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27339",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27339",
                    "province": "Lugo",
                    "city": "Folgoso do Courel",
                    "multiple": "1"
                },
                {
                    "zipcode": "27340",
                    "province": "Lugo",
                    "city": "Bóveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "27341",
                    "province": "Lugo",
                    "city": "Incio, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27342",
                    "province": "Lugo",
                    "city": "Incio, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27343",
                    "province": "Lugo",
                    "city": "Bóveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "27344",
                    "province": "Lugo",
                    "city": "Bóveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "27345",
                    "province": "Lugo",
                    "city": "Incio, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27346",
                    "province": "Lugo",
                    "city": "Incio, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27347",
                    "province": "Lugo",
                    "city": "Incio, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27347",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "27348",
                    "province": "Lugo",
                    "city": "Incio, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27349",
                    "province": "Lugo",
                    "city": "Bóveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "27350",
                    "province": "Lugo",
                    "city": "Bóveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "27359",
                    "province": "Lugo",
                    "city": "Bóveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "27360",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27362",
                    "province": "Lugo",
                    "city": "Páramo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27363",
                    "province": "Lugo",
                    "city": "Páramo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27364",
                    "province": "Lugo",
                    "city": "Páramo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27366",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27367",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27368",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27369",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27369",
                    "province": "Lugo",
                    "city": "Páramo, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "27370",
                    "province": "Lugo",
                    "city": "Begonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "27370",
                    "province": "Lugo",
                    "city": "Rábade",
                    "multiple": "1"
                },
                {
                    "zipcode": "27372",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27372",
                    "province": "Lugo",
                    "city": "Begonte",
                    "multiple": "1"
                },
                {
                    "zipcode": "27373",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27373",
                    "province": "Lugo",
                    "city": "Begonte",
                    "multiple": "1"
                },
                {
                    "zipcode": "27374",
                    "province": "Lugo",
                    "city": "Outeiro de Rei",
                    "multiple": "0"
                },
                {
                    "zipcode": "27375",
                    "province": "Lugo",
                    "city": "Cospeito",
                    "multiple": "0"
                },
                {
                    "zipcode": "27375",
                    "province": "Lugo",
                    "city": "Begonte",
                    "multiple": "1"
                },
                {
                    "zipcode": "27375",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "1"
                },
                {
                    "zipcode": "27376",
                    "province": "Lugo",
                    "city": "Cospeito",
                    "multiple": "0"
                },
                {
                    "zipcode": "27377",
                    "province": "Lugo",
                    "city": "Cospeito",
                    "multiple": "0"
                },
                {
                    "zipcode": "27378",
                    "province": "Lugo",
                    "city": "Cospeito",
                    "multiple": "0"
                },
                {
                    "zipcode": "27379",
                    "province": "Lugo",
                    "city": "Cospeito",
                    "multiple": "0"
                },
                {
                    "zipcode": "27380",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27388",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27389",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27390",
                    "province": "Lugo",
                    "city": "Quiroga",
                    "multiple": "0"
                },
                {
                    "zipcode": "27391",
                    "province": "Lugo",
                    "city": "Quiroga",
                    "multiple": "0"
                },
                {
                    "zipcode": "27391",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "27392",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27400",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27400",
                    "province": "Lugo",
                    "city": "Guitiriz",
                    "multiple": "1"
                },
                {
                    "zipcode": "27410",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27411",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27412",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27413",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27413",
                    "province": "Lugo",
                    "city": "Pobra do Brollón, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "27414",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27415",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27416",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27417",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27418",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27419",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27419",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "1"
                },
                {
                    "zipcode": "27420",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27421",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27422",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27423",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27424",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27425",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27430",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27437",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27438",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27439",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27440",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27450",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27460",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27466",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27468",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27469",
                    "province": "Lugo",
                    "city": "Sober",
                    "multiple": "0"
                },
                {
                    "zipcode": "27470",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27500",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27510",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27511",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27512",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27513",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27514",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27515",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27515",
                    "province": "Lugo",
                    "city": "Monterroso",
                    "multiple": "1"
                },
                {
                    "zipcode": "27516",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27517",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27518",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27519",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27520",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27527",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27528",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27529",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27530",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27531",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27532",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27532",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "27533",
                    "province": "Lugo",
                    "city": "Carballedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27533",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "1"
                },
                {
                    "zipcode": "27540",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27543",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27544",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27545",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27546",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27547",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27548",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27549",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27549",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "1"
                },
                {
                    "zipcode": "27550",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27554",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27555",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27556",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27557",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27558",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27559",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27560",
                    "province": "Lugo",
                    "city": "Monterroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "27568",
                    "province": "Lugo",
                    "city": "Monterroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "27569",
                    "province": "Lugo",
                    "city": "Monterroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "27570",
                    "province": "Lugo",
                    "city": "Antas de Ulla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27576",
                    "province": "Lugo",
                    "city": "Antas de Ulla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27577",
                    "province": "Lugo",
                    "city": "Antas de Ulla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27578",
                    "province": "Lugo",
                    "city": "Antas de Ulla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27579",
                    "province": "Lugo",
                    "city": "Antas de Ulla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27590",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27591",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27592",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27592",
                    "province": "Lugo",
                    "city": "Monforte de Lemos",
                    "multiple": "1"
                },
                {
                    "zipcode": "27593",
                    "province": "Lugo",
                    "city": "Pantón",
                    "multiple": "0"
                },
                {
                    "zipcode": "27594",
                    "province": "Lugo",
                    "city": "Saviñao, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27595",
                    "province": "Lugo",
                    "city": "Chantada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27596",
                    "province": "Lugo",
                    "city": "Taboada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27600",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27610",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27611",
                    "province": "Lugo",
                    "city": "Paradela",
                    "multiple": "0"
                },
                {
                    "zipcode": "27612",
                    "province": "Lugo",
                    "city": "Paradela",
                    "multiple": "0"
                },
                {
                    "zipcode": "27612",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "1"
                },
                {
                    "zipcode": "27613",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27614",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27614",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "1"
                },
                {
                    "zipcode": "27615",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27615",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "1"
                },
                {
                    "zipcode": "27616",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27617",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27618",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27619",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27620",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27623",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27624",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27625",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27626",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27627",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27628",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27630",
                    "province": "Lugo",
                    "city": "Triacastela",
                    "multiple": "0"
                },
                {
                    "zipcode": "27631",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27631",
                    "province": "Lugo",
                    "city": "Triacastela",
                    "multiple": "1"
                },
                {
                    "zipcode": "27632",
                    "province": "Lugo",
                    "city": "Triacastela",
                    "multiple": "0"
                },
                {
                    "zipcode": "27633",
                    "province": "Lugo",
                    "city": "Páramo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27633",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "1"
                },
                {
                    "zipcode": "27634",
                    "province": "Lugo",
                    "city": "Triacastela",
                    "multiple": "0"
                },
                {
                    "zipcode": "27635",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27636",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27637",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27638",
                    "province": "Lugo",
                    "city": "Samos",
                    "multiple": "0"
                },
                {
                    "zipcode": "27639",
                    "province": "Lugo",
                    "city": "Triacastela",
                    "multiple": "0"
                },
                {
                    "zipcode": "27640",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27646",
                    "province": "Lugo",
                    "city": "Nogais, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "27647",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27648",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27649",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27650",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27650",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "1"
                },
                {
                    "zipcode": "27651",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27652",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27653",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27654",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27655",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27656",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27657",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27658",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27659",
                    "province": "Lugo",
                    "city": "Navia de Suarna",
                    "multiple": "0"
                },
                {
                    "zipcode": "27659",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "27660",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27661",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27661",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "27662",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "27663",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27664",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27664",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "27665",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "27666",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "27667",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "27668",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27670",
                    "province": "Lugo",
                    "city": "Pedrafita do Cebreiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27671",
                    "province": "Lugo",
                    "city": "Pedrafita do Cebreiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27672",
                    "province": "Lugo",
                    "city": "Pedrafita do Cebreiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27673",
                    "province": "Lugo",
                    "city": "Pedrafita do Cebreiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27675",
                    "province": "Lugo",
                    "city": "Nogais, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "27676",
                    "province": "Lugo",
                    "city": "Nogais, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "27677",
                    "province": "Lugo",
                    "city": "Nogais, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "27677",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "1"
                },
                {
                    "zipcode": "27677",
                    "province": "Lugo",
                    "city": "Cervantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "27678",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27679",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27680",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27685",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27686",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27687",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27688",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27689",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27690",
                    "province": "Lugo",
                    "city": "Sarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "27691",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27692",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27693",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27694",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27695",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27695",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "1"
                },
                {
                    "zipcode": "27696",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27697",
                    "province": "Lugo",
                    "city": "Baralla",
                    "multiple": "0"
                },
                {
                    "zipcode": "27698",
                    "province": "Lugo",
                    "city": "Becerreá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27699",
                    "province": "Lugo",
                    "city": "Láncara",
                    "multiple": "0"
                },
                {
                    "zipcode": "27700",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27700",
                    "province": "Lugo",
                    "city": "Valadouro, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "27710",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27711",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27712",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27713",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27714",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27715",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27720",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27721",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27722",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27723",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27724",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27725",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27726",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27727",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27728",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27729",
                    "province": "Lugo",
                    "city": "Pontenova, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "27730",
                    "province": "Lugo",
                    "city": "Abadín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27737",
                    "province": "Lugo",
                    "city": "Abadín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27738",
                    "province": "Lugo",
                    "city": "Abadín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27740",
                    "province": "Lugo",
                    "city": "Mondoñedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27742",
                    "province": "Lugo",
                    "city": "Mondoñedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27743",
                    "province": "Lugo",
                    "city": "Riotorto",
                    "multiple": "0"
                },
                {
                    "zipcode": "27744",
                    "province": "Lugo",
                    "city": "Riotorto",
                    "multiple": "0"
                },
                {
                    "zipcode": "27745",
                    "province": "Lugo",
                    "city": "Riotorto",
                    "multiple": "0"
                },
                {
                    "zipcode": "27747",
                    "province": "Lugo",
                    "city": "Mondoñedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27748",
                    "province": "Lugo",
                    "city": "Mondoñedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27749",
                    "province": "Lugo",
                    "city": "Mondoñedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27750",
                    "province": "Lugo",
                    "city": "Lourenzá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27751",
                    "province": "Lugo",
                    "city": "Lourenzá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27752",
                    "province": "Lugo",
                    "city": "Mondoñedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27760",
                    "province": "Lugo",
                    "city": "Lourenzá",
                    "multiple": "0"
                },
                {
                    "zipcode": "27765",
                    "province": "Lugo",
                    "city": "Trabada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27766",
                    "province": "Lugo",
                    "city": "Trabada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27767",
                    "province": "Lugo",
                    "city": "Trabada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27768",
                    "province": "Lugo",
                    "city": "Trabada",
                    "multiple": "0"
                },
                {
                    "zipcode": "27770",
                    "province": "Lugo",
                    "city": "Valadouro, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27773",
                    "province": "Lugo",
                    "city": "Alfoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27774",
                    "province": "Lugo",
                    "city": "Alfoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27775",
                    "province": "Lugo",
                    "city": "Alfoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27776",
                    "province": "Lugo",
                    "city": "Alfoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27777",
                    "province": "Lugo",
                    "city": "Valadouro, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27778",
                    "province": "Lugo",
                    "city": "Alfoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27778",
                    "province": "Lugo",
                    "city": "Valadouro, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "27779",
                    "province": "Lugo",
                    "city": "Valadouro, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27780",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27785",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27786",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27787",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27788",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27789",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27790",
                    "province": "Lugo",
                    "city": "Barreiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "27791",
                    "province": "Lugo",
                    "city": "Mondoñedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27792",
                    "province": "Lugo",
                    "city": "Barreiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "27793",
                    "province": "Lugo",
                    "city": "Barreiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "27794",
                    "province": "Lugo",
                    "city": "Barreiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "27796",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27797",
                    "province": "Lugo",
                    "city": "Ribadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27798",
                    "province": "Lugo",
                    "city": "Barreiros",
                    "multiple": "0"
                },
                {
                    "zipcode": "27800",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27810",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27811",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27812",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27813",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27814",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27815",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27816",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27817",
                    "province": "Lugo",
                    "city": "Muras",
                    "multiple": "0"
                },
                {
                    "zipcode": "27818",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27820",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27821",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27822",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27823",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27824",
                    "province": "Lugo",
                    "city": "Xermade",
                    "multiple": "0"
                },
                {
                    "zipcode": "27825",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27826",
                    "province": "Lugo",
                    "city": "Xermade",
                    "multiple": "0"
                },
                {
                    "zipcode": "27830",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27832",
                    "province": "Lugo",
                    "city": "Xermade",
                    "multiple": "0"
                },
                {
                    "zipcode": "27833",
                    "province": "Lugo",
                    "city": "Xermade",
                    "multiple": "0"
                },
                {
                    "zipcode": "27834",
                    "province": "Lugo",
                    "city": "Xermade",
                    "multiple": "0"
                },
                {
                    "zipcode": "27835",
                    "province": "Lugo",
                    "city": "Xermade",
                    "multiple": "0"
                },
                {
                    "zipcode": "27836",
                    "province": "Lugo",
                    "city": "Muras",
                    "multiple": "0"
                },
                {
                    "zipcode": "27837",
                    "province": "Lugo",
                    "city": "Muras",
                    "multiple": "0"
                },
                {
                    "zipcode": "27840",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27841",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27842",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "27843",
                    "province": "Lugo",
                    "city": "Abadín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27843",
                    "province": "Lugo",
                    "city": "Vilalba",
                    "multiple": "1"
                },
                {
                    "zipcode": "27845",
                    "province": "Lugo",
                    "city": "Abadín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27849",
                    "province": "Lugo",
                    "city": "Abadín",
                    "multiple": "0"
                },
                {
                    "zipcode": "27850",
                    "province": "Lugo",
                    "city": "Viveiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27860",
                    "province": "Lugo",
                    "city": "Vicedo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27861",
                    "province": "Lugo",
                    "city": "Viveiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27861",
                    "province": "Lugo",
                    "city": "Vicedo, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "27863",
                    "province": "Lugo",
                    "city": "Viveiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27864",
                    "province": "Lugo",
                    "city": "Viveiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27865",
                    "province": "Lugo",
                    "city": "Muras",
                    "multiple": "0"
                },
                {
                    "zipcode": "27865",
                    "province": "Lugo",
                    "city": "Ourol",
                    "multiple": "1"
                },
                {
                    "zipcode": "27866",
                    "province": "Lugo",
                    "city": "Ourol",
                    "multiple": "0"
                },
                {
                    "zipcode": "27866",
                    "province": "Lugo",
                    "city": "Viveiro",
                    "multiple": "1"
                },
                {
                    "zipcode": "27867",
                    "province": "Lugo",
                    "city": "Viveiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27867",
                    "province": "Lugo",
                    "city": "Ourol",
                    "multiple": "1"
                },
                {
                    "zipcode": "27868",
                    "province": "Lugo",
                    "city": "Vicedo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "27869",
                    "province": "Lugo",
                    "city": "Viveiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "27870",
                    "province": "Lugo",
                    "city": "Xove",
                    "multiple": "0"
                },
                {
                    "zipcode": "27876",
                    "province": "Lugo",
                    "city": "Xove",
                    "multiple": "0"
                },
                {
                    "zipcode": "27877",
                    "province": "Lugo",
                    "city": "Xove",
                    "multiple": "0"
                },
                {
                    "zipcode": "27878",
                    "province": "Lugo",
                    "city": "Xove",
                    "multiple": "0"
                },
                {
                    "zipcode": "27879",
                    "province": "Lugo",
                    "city": "Xove",
                    "multiple": "0"
                },
                {
                    "zipcode": "27880",
                    "province": "Lugo",
                    "city": "Burela",
                    "multiple": "0"
                },
                {
                    "zipcode": "27888",
                    "province": "Lugo",
                    "city": "Cervo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27889",
                    "province": "Lugo",
                    "city": "Cervo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27890",
                    "province": "Lugo",
                    "city": "Cervo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27891",
                    "province": "Lugo",
                    "city": "Cervo",
                    "multiple": "0"
                },
                {
                    "zipcode": "27892",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "27893",
                    "province": "Lugo",
                    "city": "Foz",
                    "multiple": "0"
                },
                {
                    "zipcode": "28000",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28001",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28002",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28003",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28004",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28005",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28006",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28007",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28008",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28009",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28010",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28011",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28012",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28013",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28014",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28015",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28016",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28017",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28018",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28019",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28020",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28021",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28022",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28023",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28024",
                    "province": "Madrid",
                    "city": "Pozuelo de Alarcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28024",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "1"
                },
                {
                    "zipcode": "28025",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28026",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28027",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28028",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28029",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28030",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28031",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28032",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28033",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28034",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28035",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28036",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28037",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28038",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28039",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28040",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28041",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28042",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28043",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28044",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28045",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28046",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28047",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28048",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28049",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28050",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28051",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28052",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28053",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28054",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28055",
                    "province": "Madrid",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28100",
                    "province": "Madrid",
                    "city": "Alcobendas",
                    "multiple": "0"
                },
                {
                    "zipcode": "28108",
                    "province": "Madrid",
                    "city": "Alcobendas",
                    "multiple": "0"
                },
                {
                    "zipcode": "28109",
                    "province": "Madrid",
                    "city": "Alcobendas",
                    "multiple": "0"
                },
                {
                    "zipcode": "28110",
                    "province": "Madrid",
                    "city": "Algete",
                    "multiple": "0"
                },
                {
                    "zipcode": "28120",
                    "province": "Madrid",
                    "city": "Algete",
                    "multiple": "0"
                },
                {
                    "zipcode": "28120",
                    "province": "Madrid",
                    "city": "Colmenar Viejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "28130",
                    "province": "Madrid",
                    "city": "Valdeolmos-Alalpardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28140",
                    "province": "Madrid",
                    "city": "Fuente el Saz de Jarama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28150",
                    "province": "Madrid",
                    "city": "Valdetorres de Jarama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28160",
                    "province": "Madrid",
                    "city": "Talamanca de Jarama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28170",
                    "province": "Madrid",
                    "city": "Valdepiélagos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28180",
                    "province": "Madrid",
                    "city": "Torrelaguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "28189",
                    "province": "Madrid",
                    "city": "Torremocha de Jarama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28189",
                    "province": "Madrid",
                    "city": "Atazar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "28189",
                    "province": "Madrid",
                    "city": "Patones",
                    "multiple": "1"
                },
                {
                    "zipcode": "28190",
                    "province": "Guadalajara",
                    "city": "Cardoso de la Sierra, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28190",
                    "province": "Guadalajara",
                    "city": "Montejo de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "28190",
                    "province": "Guadalajara",
                    "city": "Puebla de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "28191",
                    "province": "Madrid",
                    "city": "Hiruela, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "28191",
                    "province": "Madrid",
                    "city": "Prádena del Rincón",
                    "multiple": "1"
                },
                {
                    "zipcode": "28191",
                    "province": "Madrid",
                    "city": "Horcajuelo de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "28192",
                    "province": "Madrid",
                    "city": "Berrueco, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28193",
                    "province": "Madrid",
                    "city": "Cervera de Buitrago",
                    "multiple": "0"
                },
                {
                    "zipcode": "28194",
                    "province": "Madrid",
                    "city": "Berzosa del Lozoya",
                    "multiple": "0"
                },
                {
                    "zipcode": "28194",
                    "province": "Madrid",
                    "city": "Robledillo de la Jara",
                    "multiple": "1"
                },
                {
                    "zipcode": "28195",
                    "province": "Madrid",
                    "city": "Puentes Viejas",
                    "multiple": "0"
                },
                {
                    "zipcode": "28196",
                    "province": "Madrid",
                    "city": "Puentes Viejas",
                    "multiple": "0"
                },
                {
                    "zipcode": "28200",
                    "province": "Madrid",
                    "city": "San Lorenzo de El Escorial",
                    "multiple": "0"
                },
                {
                    "zipcode": "28210",
                    "province": "Madrid",
                    "city": "Navalagamella",
                    "multiple": "0"
                },
                {
                    "zipcode": "28210",
                    "province": "Madrid",
                    "city": "Valdemorillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "28211",
                    "province": "Madrid",
                    "city": "Escorial, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28212",
                    "province": "Madrid",
                    "city": "Navalagamella",
                    "multiple": "0"
                },
                {
                    "zipcode": "28212",
                    "province": "Madrid",
                    "city": "Escorial, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "28213",
                    "province": "Madrid",
                    "city": "Colmenar del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28214",
                    "province": "Madrid",
                    "city": "Fresnedillas de la Oliva",
                    "multiple": "0"
                },
                {
                    "zipcode": "28219",
                    "province": "Madrid",
                    "city": "Escorial, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28220",
                    "province": "Madrid",
                    "city": "Majadahonda",
                    "multiple": "0"
                },
                {
                    "zipcode": "28221",
                    "province": "Madrid",
                    "city": "Majadahonda",
                    "multiple": "0"
                },
                {
                    "zipcode": "28222",
                    "province": "Madrid",
                    "city": "Majadahonda",
                    "multiple": "0"
                },
                {
                    "zipcode": "28223",
                    "province": "Madrid",
                    "city": "Pozuelo de Alarcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28224",
                    "province": "Madrid",
                    "city": "Pozuelo de Alarcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28229",
                    "province": "Madrid",
                    "city": "Villanueva del Pardillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28231",
                    "province": "Madrid",
                    "city": "Rozas de Madrid, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "28232",
                    "province": "Madrid",
                    "city": "Rozas de Madrid, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "28240",
                    "province": "Madrid",
                    "city": "Hoyo de Manzanares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28248",
                    "province": "Madrid",
                    "city": "Hoyo de Manzanares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28250",
                    "province": "Madrid",
                    "city": "Torrelodones",
                    "multiple": "0"
                },
                {
                    "zipcode": "28250",
                    "province": "Madrid",
                    "city": "Galapagar",
                    "multiple": "1"
                },
                {
                    "zipcode": "28260",
                    "province": "Madrid",
                    "city": "Galapagar",
                    "multiple": "0"
                },
                {
                    "zipcode": "28270",
                    "province": "Madrid",
                    "city": "Galapagar",
                    "multiple": "0"
                },
                {
                    "zipcode": "28270",
                    "province": "Madrid",
                    "city": "Colmenarejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "28279",
                    "province": "Madrid",
                    "city": "Colmenarejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28280",
                    "province": "Madrid",
                    "city": "Escorial, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28290",
                    "province": "Madrid",
                    "city": "Rozas de Madrid, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "28292",
                    "province": "Madrid",
                    "city": "Galapagar",
                    "multiple": "0"
                },
                {
                    "zipcode": "28292",
                    "province": "Madrid",
                    "city": "Escorial, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "28293",
                    "province": "Madrid",
                    "city": "Zarzalejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28294",
                    "province": "Madrid",
                    "city": "Robledo de Chavela",
                    "multiple": "0"
                },
                {
                    "zipcode": "28295",
                    "province": "Madrid",
                    "city": "Valdemaqueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "28296",
                    "province": "Madrid",
                    "city": "Santa María de la Alameda",
                    "multiple": "0"
                },
                {
                    "zipcode": "28297",
                    "province": "Madrid",
                    "city": "Santa María de la Alameda",
                    "multiple": "0"
                },
                {
                    "zipcode": "28300",
                    "province": "Madrid",
                    "city": "Aranjuez",
                    "multiple": "0"
                },
                {
                    "zipcode": "28320",
                    "province": "Madrid",
                    "city": "Pinto",
                    "multiple": "0"
                },
                {
                    "zipcode": "28330",
                    "province": "Madrid",
                    "city": "San Martín de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "28340",
                    "province": "Madrid",
                    "city": "Valdemoro",
                    "multiple": "0"
                },
                {
                    "zipcode": "28341",
                    "province": "Madrid",
                    "city": "Valdemoro",
                    "multiple": "0"
                },
                {
                    "zipcode": "28342",
                    "province": "Madrid",
                    "city": "Valdemoro",
                    "multiple": "0"
                },
                {
                    "zipcode": "28343",
                    "province": "Madrid",
                    "city": "Valdemoro",
                    "multiple": "0"
                },
                {
                    "zipcode": "28350",
                    "province": "Madrid",
                    "city": "Ciempozuelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28359",
                    "province": "Madrid",
                    "city": "Titulcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "28360",
                    "province": "Madrid",
                    "city": "Villaconejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28370",
                    "province": "Madrid",
                    "city": "Chinchón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28380",
                    "province": "Madrid",
                    "city": "Colmenar de Oreja",
                    "multiple": "0"
                },
                {
                    "zipcode": "28390",
                    "province": "Madrid",
                    "city": "Belmonte de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28391",
                    "province": "Madrid",
                    "city": "Valdelaguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "28400",
                    "province": "Madrid",
                    "city": "Galapagar",
                    "multiple": "0"
                },
                {
                    "zipcode": "28400",
                    "province": "Madrid",
                    "city": "Collado Villalba",
                    "multiple": "1"
                },
                {
                    "zipcode": "28410",
                    "province": "Madrid",
                    "city": "Manzanares el Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "28411",
                    "province": "Madrid",
                    "city": "Moralzarzal",
                    "multiple": "0"
                },
                {
                    "zipcode": "28412",
                    "province": "Madrid",
                    "city": "Boalo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28413",
                    "province": "Madrid",
                    "city": "Boalo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28420",
                    "province": "Madrid",
                    "city": "Galapagar",
                    "multiple": "0"
                },
                {
                    "zipcode": "28430",
                    "province": "Madrid",
                    "city": "Alpedrete",
                    "multiple": "0"
                },
                {
                    "zipcode": "28440",
                    "province": "Madrid",
                    "city": "Guadarrama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28450",
                    "province": "Madrid",
                    "city": "Collado Mediano",
                    "multiple": "0"
                },
                {
                    "zipcode": "28460",
                    "province": "Madrid",
                    "city": "Molinos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "28470",
                    "province": "Madrid",
                    "city": "Manzanares el Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "28470",
                    "province": "Madrid",
                    "city": "Cercedilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "28480",
                    "province": "Madrid",
                    "city": "Guadarrama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28490",
                    "province": "Madrid",
                    "city": "Becerril de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "28491",
                    "province": "Madrid",
                    "city": "Navacerrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28492",
                    "province": "Madrid",
                    "city": "Boalo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28492",
                    "province": "Madrid",
                    "city": "Manzanares el Real",
                    "multiple": "1"
                },
                {
                    "zipcode": "28500",
                    "province": "Madrid",
                    "city": "Arganda del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "28510",
                    "province": "Madrid",
                    "city": "Campo Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "28511",
                    "province": "Madrid",
                    "city": "Valdilecha",
                    "multiple": "0"
                },
                {
                    "zipcode": "28512",
                    "province": "Madrid",
                    "city": "Villar del Olmo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28514",
                    "province": "Madrid",
                    "city": "Nuevo Baztán",
                    "multiple": "0"
                },
                {
                    "zipcode": "28514",
                    "province": "Madrid",
                    "city": "Villar del Olmo",
                    "multiple": "1"
                },
                {
                    "zipcode": "28515",
                    "province": "Madrid",
                    "city": "Olmeda de las Fuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28521",
                    "province": "Madrid",
                    "city": "Rivas-Vaciamadrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28522",
                    "province": "Madrid",
                    "city": "Rivas-Vaciamadrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28523",
                    "province": "Madrid",
                    "city": "Rivas-Vaciamadrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28524",
                    "province": "Madrid",
                    "city": "Rivas-Vaciamadrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28530",
                    "province": "Madrid",
                    "city": "Morata de Tajuña",
                    "multiple": "0"
                },
                {
                    "zipcode": "28540",
                    "province": "Madrid",
                    "city": "Perales de Tajuña",
                    "multiple": "0"
                },
                {
                    "zipcode": "28550",
                    "province": "Madrid",
                    "city": "Tielmes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28560",
                    "province": "Madrid",
                    "city": "Carabaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "28570",
                    "province": "Madrid",
                    "city": "Orusco de Tajuña",
                    "multiple": "0"
                },
                {
                    "zipcode": "28580",
                    "province": "Madrid",
                    "city": "Ambite",
                    "multiple": "0"
                },
                {
                    "zipcode": "28590",
                    "province": "Madrid",
                    "city": "Villarejo de Salvanés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28594",
                    "province": "Madrid",
                    "city": "Valdaracete",
                    "multiple": "0"
                },
                {
                    "zipcode": "28595",
                    "province": "Madrid",
                    "city": "Estremera",
                    "multiple": "0"
                },
                {
                    "zipcode": "28596",
                    "province": "Madrid",
                    "city": "Brea de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28597",
                    "province": "Madrid",
                    "city": "Fuentidueña de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28598",
                    "province": "Madrid",
                    "city": "Villamanrique de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28600",
                    "province": "Madrid",
                    "city": "Navalcarnero",
                    "multiple": "0"
                },
                {
                    "zipcode": "28607",
                    "province": "Madrid",
                    "city": "Álamo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28609",
                    "province": "Madrid",
                    "city": "Sevilla la Nueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "28609",
                    "province": "Madrid",
                    "city": "Villamantilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "28609",
                    "province": "Madrid",
                    "city": "Villanueva de Perales",
                    "multiple": "1"
                },
                {
                    "zipcode": "28610",
                    "province": "Madrid",
                    "city": "Villamanta",
                    "multiple": "0"
                },
                {
                    "zipcode": "28620",
                    "province": "Madrid",
                    "city": "Aldea del Fresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "28630",
                    "province": "Madrid",
                    "city": "Villa del Prado",
                    "multiple": "0"
                },
                {
                    "zipcode": "28635",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28640",
                    "province": "Madrid",
                    "city": "Cadalso de los Vidrios",
                    "multiple": "0"
                },
                {
                    "zipcode": "28648",
                    "province": "Madrid",
                    "city": "Cadalso de los Vidrios",
                    "multiple": "0"
                },
                {
                    "zipcode": "28649",
                    "province": "Madrid",
                    "city": "Rozas de Puerto Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "28650",
                    "province": "Madrid",
                    "city": "Cenicientos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28660",
                    "province": "Madrid",
                    "city": "Boadilla del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "28670",
                    "province": "Madrid",
                    "city": "Villaviciosa de Odón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28680",
                    "province": "Madrid",
                    "city": "San Martín de Valdeiglesias",
                    "multiple": "0"
                },
                {
                    "zipcode": "28690",
                    "province": "Madrid",
                    "city": "Brunete",
                    "multiple": "0"
                },
                {
                    "zipcode": "28691",
                    "province": "Madrid",
                    "city": "Villanueva de la Cañada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28692",
                    "province": "Madrid",
                    "city": "Villanueva de la Cañada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28693",
                    "province": "Madrid",
                    "city": "Quijorna",
                    "multiple": "0"
                },
                {
                    "zipcode": "28694",
                    "province": "Madrid",
                    "city": "Chapinería",
                    "multiple": "0"
                },
                {
                    "zipcode": "28695",
                    "province": "Madrid",
                    "city": "Navas del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "28696",
                    "province": "Madrid",
                    "city": "Pelayos de la Presa",
                    "multiple": "0"
                },
                {
                    "zipcode": "28700",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28701",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28702",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28703",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28706",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28707",
                    "province": "Madrid",
                    "city": "Colmenar Viejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28707",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "1"
                },
                {
                    "zipcode": "28708",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28709",
                    "province": "Madrid",
                    "city": "San Sebastián de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "28710",
                    "province": "Madrid",
                    "city": "Molar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28720",
                    "province": "Madrid",
                    "city": "Bustarviejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28721",
                    "province": "Madrid",
                    "city": "Redueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "28721",
                    "province": "Madrid",
                    "city": "Cabanillas de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "28722",
                    "province": "Madrid",
                    "city": "Vellón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28723",
                    "province": "Madrid",
                    "city": "Pedrezuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "28729",
                    "province": "Madrid",
                    "city": "Venturada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28729",
                    "province": "Madrid",
                    "city": "Valdemanco",
                    "multiple": "1"
                },
                {
                    "zipcode": "28729",
                    "province": "Madrid",
                    "city": "Navalafuente",
                    "multiple": "1"
                },
                {
                    "zipcode": "28730",
                    "province": "Madrid",
                    "city": "Buitrago del Lozoya",
                    "multiple": "0"
                },
                {
                    "zipcode": "28737",
                    "province": "Madrid",
                    "city": "Serna del Monte, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "28737",
                    "province": "Madrid",
                    "city": "Piñuécar-Gandullas",
                    "multiple": "1"
                },
                {
                    "zipcode": "28737",
                    "province": "Madrid",
                    "city": "Braojos",
                    "multiple": "1"
                },
                {
                    "zipcode": "28737",
                    "province": "Madrid",
                    "city": "Gascones",
                    "multiple": "1"
                },
                {
                    "zipcode": "28739",
                    "province": "Madrid",
                    "city": "Navarredonda y San Mamés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28739",
                    "province": "Madrid",
                    "city": "Villavieja del Lozoya",
                    "multiple": "1"
                },
                {
                    "zipcode": "28739",
                    "province": "Madrid",
                    "city": "Gargantilla del Lozoya y Pinilla de Buitrago",
                    "multiple": "1"
                },
                {
                    "zipcode": "28739",
                    "province": "Madrid",
                    "city": "Garganta de los Montes",
                    "multiple": "1"
                },
                {
                    "zipcode": "28740",
                    "province": "Madrid",
                    "city": "Rascafría",
                    "multiple": "0"
                },
                {
                    "zipcode": "28742",
                    "province": "Madrid",
                    "city": "Lozoya",
                    "multiple": "0"
                },
                {
                    "zipcode": "28742",
                    "province": "Madrid",
                    "city": "Alameda del Valle",
                    "multiple": "1"
                },
                {
                    "zipcode": "28743",
                    "province": "Madrid",
                    "city": "Canencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "28743",
                    "province": "Madrid",
                    "city": "Garganta de los Montes",
                    "multiple": "1"
                },
                {
                    "zipcode": "28749",
                    "province": "Madrid",
                    "city": "Rascafría",
                    "multiple": "0"
                },
                {
                    "zipcode": "28749",
                    "province": "Madrid",
                    "city": "Pinilla del Valle",
                    "multiple": "1"
                },
                {
                    "zipcode": "28750",
                    "province": "Madrid",
                    "city": "Colmenar Viejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28750",
                    "province": "Madrid",
                    "city": "San Lorenzo de El Escorial",
                    "multiple": "1"
                },
                {
                    "zipcode": "28750",
                    "province": "Madrid",
                    "city": "San Agustín del Guadalix",
                    "multiple": "1"
                },
                {
                    "zipcode": "28751",
                    "province": "Madrid",
                    "city": "Cabrera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "28752",
                    "province": "Madrid",
                    "city": "Lozoyuela-Navas-Sieteiglesias",
                    "multiple": "0"
                },
                {
                    "zipcode": "28753",
                    "province": "Madrid",
                    "city": "Lozoyuela-Navas-Sieteiglesias",
                    "multiple": "0"
                },
                {
                    "zipcode": "28754",
                    "province": "Madrid",
                    "city": "Lozoyuela-Navas-Sieteiglesias",
                    "multiple": "0"
                },
                {
                    "zipcode": "28754",
                    "province": "Madrid",
                    "city": "Puentes Viejas",
                    "multiple": "1"
                },
                {
                    "zipcode": "28755",
                    "province": "Madrid",
                    "city": "Horcajo de la Sierra-Aoslos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28755",
                    "province": "Madrid",
                    "city": "Acebeda, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "28755",
                    "province": "Madrid",
                    "city": "Robregordo",
                    "multiple": "1"
                },
                {
                    "zipcode": "28755",
                    "province": "Madrid",
                    "city": "Madarcos",
                    "multiple": "1"
                },
                {
                    "zipcode": "28756",
                    "province": "Madrid",
                    "city": "Somosierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "28760",
                    "province": "Madrid",
                    "city": "Tres Cantos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28770",
                    "province": "Madrid",
                    "city": "Colmenar Viejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28791",
                    "province": "Madrid",
                    "city": "Soto del Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "28792",
                    "province": "Madrid",
                    "city": "Miraflores de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "28794",
                    "province": "Madrid",
                    "city": "Guadalix de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "28801",
                    "province": "Madrid",
                    "city": "Alcalá de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28802",
                    "province": "Madrid",
                    "city": "Alcalá de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28803",
                    "province": "Madrid",
                    "city": "Alcalá de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28804",
                    "province": "Madrid",
                    "city": "Alcalá de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28805",
                    "province": "Madrid",
                    "city": "Alcalá de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28806",
                    "province": "Madrid",
                    "city": "Alcalá de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28807",
                    "province": "Madrid",
                    "city": "Álamo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "28807",
                    "province": "Madrid",
                    "city": "Alcalá de Henares",
                    "multiple": "1"
                },
                {
                    "zipcode": "28810",
                    "province": "Madrid",
                    "city": "Villalbilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "28811",
                    "province": "Madrid",
                    "city": "Corpa",
                    "multiple": "0"
                },
                {
                    "zipcode": "28812",
                    "province": "Madrid",
                    "city": "Pezuela de las Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "28812",
                    "province": "Madrid",
                    "city": "Valverde de Alcalá",
                    "multiple": "1"
                },
                {
                    "zipcode": "28813",
                    "province": "Madrid",
                    "city": "Pozuelo del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "28813",
                    "province": "Madrid",
                    "city": "Torres de la Alameda",
                    "multiple": "1"
                },
                {
                    "zipcode": "28814",
                    "province": "Madrid",
                    "city": "Daganzo de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "28815",
                    "province": "Madrid",
                    "city": "Fresno de Torote",
                    "multiple": "0"
                },
                {
                    "zipcode": "28815",
                    "province": "Madrid",
                    "city": "Ribatejada",
                    "multiple": "1"
                },
                {
                    "zipcode": "28816",
                    "province": "Madrid",
                    "city": "Valdeavero",
                    "multiple": "0"
                },
                {
                    "zipcode": "28816",
                    "province": "Madrid",
                    "city": "Camarma de Esteruelas",
                    "multiple": "1"
                },
                {
                    "zipcode": "28817",
                    "province": "Madrid",
                    "city": "Santos de la Humosa, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "28818",
                    "province": "Madrid",
                    "city": "Anchuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28818",
                    "province": "Madrid",
                    "city": "Santorcaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "28821",
                    "province": "Madrid",
                    "city": "Coslada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28822",
                    "province": "Madrid",
                    "city": "Coslada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28823",
                    "province": "Madrid",
                    "city": "Coslada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28830",
                    "province": "Madrid",
                    "city": "San Fernando de Henares",
                    "multiple": "0"
                },
                {
                    "zipcode": "28840",
                    "province": "Madrid",
                    "city": "Mejorada del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "28850",
                    "province": "Madrid",
                    "city": "Torrejón de Ardoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "28860",
                    "province": "Madrid",
                    "city": "Paracuellos de Jarama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28861",
                    "province": "Madrid",
                    "city": "Paracuellos de Jarama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28862",
                    "province": "Madrid",
                    "city": "Paracuellos de Jarama",
                    "multiple": "0"
                },
                {
                    "zipcode": "28863",
                    "province": "Madrid",
                    "city": "Cobeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "28864",
                    "province": "Madrid",
                    "city": "Ajalvir",
                    "multiple": "0"
                },
                {
                    "zipcode": "28880",
                    "province": "Madrid",
                    "city": "Meco",
                    "multiple": "0"
                },
                {
                    "zipcode": "28890",
                    "province": "Madrid",
                    "city": "Loeches",
                    "multiple": "0"
                },
                {
                    "zipcode": "28891",
                    "province": "Madrid",
                    "city": "Velilla de San Antonio",
                    "multiple": "0"
                },
                {
                    "zipcode": "28901",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28902",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28903",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28904",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28905",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28906",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28907",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28909",
                    "province": "Madrid",
                    "city": "Getafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "28911",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28912",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28913",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28914",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28915",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28916",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28917",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28918",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28919",
                    "province": "Madrid",
                    "city": "Leganés",
                    "multiple": "0"
                },
                {
                    "zipcode": "28921",
                    "province": "Madrid",
                    "city": "Alcorcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28922",
                    "province": "Madrid",
                    "city": "Alcorcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28923",
                    "province": "Madrid",
                    "city": "Alcorcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28924",
                    "province": "Madrid",
                    "city": "Collado Villalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "28924",
                    "province": "Madrid",
                    "city": "Alcorcón",
                    "multiple": "1"
                },
                {
                    "zipcode": "28925",
                    "province": "Madrid",
                    "city": "Alcorcón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28931",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28932",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28933",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28934",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28935",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28936",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28937",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28938",
                    "province": "Madrid",
                    "city": "Móstoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "28939",
                    "province": "Madrid",
                    "city": "Arroyomolinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28939",
                    "province": "Madrid",
                    "city": "Batres",
                    "multiple": "1"
                },
                {
                    "zipcode": "28941",
                    "province": "Madrid",
                    "city": "Fuenlabrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28942",
                    "province": "Madrid",
                    "city": "Fuenlabrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28943",
                    "province": "Madrid",
                    "city": "Fuenlabrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28944",
                    "province": "Madrid",
                    "city": "Fuenlabrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28945",
                    "province": "Madrid",
                    "city": "Fuenlabrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28946",
                    "province": "Madrid",
                    "city": "Fuenlabrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28947",
                    "province": "Madrid",
                    "city": "Fuenlabrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "28950",
                    "province": "Madrid",
                    "city": "Moraleja de Enmedio",
                    "multiple": "0"
                },
                {
                    "zipcode": "28954",
                    "province": "Madrid",
                    "city": "Navalagamella",
                    "multiple": "0"
                },
                {
                    "zipcode": "28970",
                    "province": "Madrid",
                    "city": "Humanes de Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "28971",
                    "province": "Madrid",
                    "city": "Griñón",
                    "multiple": "0"
                },
                {
                    "zipcode": "28976",
                    "province": "Madrid",
                    "city": "Batres",
                    "multiple": "0"
                },
                {
                    "zipcode": "28977",
                    "province": "Madrid",
                    "city": "Casarrubuelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "28978",
                    "province": "Madrid",
                    "city": "Cubas de la Sagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "28979",
                    "province": "Madrid",
                    "city": "Serranillos del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "28981",
                    "province": "Madrid",
                    "city": "Parla",
                    "multiple": "0"
                },
                {
                    "zipcode": "28982",
                    "province": "Madrid",
                    "city": "Parla",
                    "multiple": "0"
                },
                {
                    "zipcode": "28983",
                    "province": "Madrid",
                    "city": "Parla",
                    "multiple": "0"
                },
                {
                    "zipcode": "28984",
                    "province": "Madrid",
                    "city": "Parla",
                    "multiple": "0"
                },
                {
                    "zipcode": "28990",
                    "province": "Madrid",
                    "city": "Torrejón de Velasco",
                    "multiple": "0"
                },
                {
                    "zipcode": "28991",
                    "province": "Madrid",
                    "city": "Torrejón de la Calzada",
                    "multiple": "0"
                },
                {
                    "zipcode": "29001",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29002",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29003",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29004",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29005",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29006",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29007",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29008",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29009",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29010",
                    "province": "Málaga",
                    "city": "Cártama",
                    "multiple": "0"
                },
                {
                    "zipcode": "29010",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "29011",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29012",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29013",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29014",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29015",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29016",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29017",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29018",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29100",
                    "province": "Málaga",
                    "city": "Coín",
                    "multiple": "0"
                },
                {
                    "zipcode": "29108",
                    "province": "Málaga",
                    "city": "Guaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "29109",
                    "province": "Málaga",
                    "city": "Tolox",
                    "multiple": "0"
                },
                {
                    "zipcode": "29110",
                    "province": "Málaga",
                    "city": "Monda",
                    "multiple": "0"
                },
                {
                    "zipcode": "29120",
                    "province": "Málaga",
                    "city": "Alhaurín el Grande",
                    "multiple": "0"
                },
                {
                    "zipcode": "29130",
                    "province": "Málaga",
                    "city": "Alhaurín de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "29140",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29150",
                    "province": "Málaga",
                    "city": "Almogía",
                    "multiple": "0"
                },
                {
                    "zipcode": "29160",
                    "province": "Málaga",
                    "city": "Casabermeja",
                    "multiple": "0"
                },
                {
                    "zipcode": "29170",
                    "province": "Málaga",
                    "city": "Colmenar",
                    "multiple": "0"
                },
                {
                    "zipcode": "29180",
                    "province": "Málaga",
                    "city": "Riogordo",
                    "multiple": "0"
                },
                {
                    "zipcode": "29190",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29191",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29193",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29194",
                    "province": "Málaga",
                    "city": "Alfarnate",
                    "multiple": "0"
                },
                {
                    "zipcode": "29194",
                    "province": "Málaga",
                    "city": "Alfarnatejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "29195",
                    "province": "Málaga",
                    "city": "Comares",
                    "multiple": "0"
                },
                {
                    "zipcode": "29196",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29197",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29197",
                    "province": "Málaga",
                    "city": "Totalán",
                    "multiple": "1"
                },
                {
                    "zipcode": "29200",
                    "province": "Málaga",
                    "city": "Antequera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29210",
                    "province": "Málaga",
                    "city": "Cuevas de San Marcos",
                    "multiple": "0"
                },
                {
                    "zipcode": "29220",
                    "province": "Málaga",
                    "city": "Cuevas Bajas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29230",
                    "province": "Málaga",
                    "city": "Antequera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29230",
                    "province": "Málaga",
                    "city": "Villanueva de la Concepción",
                    "multiple": "1"
                },
                {
                    "zipcode": "29240",
                    "province": "Málaga",
                    "city": "Valle de Abdalajís",
                    "multiple": "0"
                },
                {
                    "zipcode": "29250",
                    "province": "Málaga",
                    "city": "Antequera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29260",
                    "province": "Málaga",
                    "city": "Antequera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29300",
                    "province": "Málaga",
                    "city": "Archidona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29309",
                    "province": "Málaga",
                    "city": "Archidona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29310",
                    "province": "Málaga",
                    "city": "Villanueva de Algaidas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29311",
                    "province": "Málaga",
                    "city": "Villanueva de Algaidas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29312",
                    "province": "Málaga",
                    "city": "Villanueva del Rosario",
                    "multiple": "0"
                },
                {
                    "zipcode": "29313",
                    "province": "Málaga",
                    "city": "Villanueva del Trabuco",
                    "multiple": "0"
                },
                {
                    "zipcode": "29314",
                    "province": "Málaga",
                    "city": "Antequera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29314",
                    "province": "Málaga",
                    "city": "Archidona",
                    "multiple": "1"
                },
                {
                    "zipcode": "29315",
                    "province": "Málaga",
                    "city": "Archidona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29315",
                    "province": "Málaga",
                    "city": "Villanueva de Tapia",
                    "multiple": "1"
                },
                {
                    "zipcode": "29320",
                    "province": "Málaga",
                    "city": "Campillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "29327",
                    "province": "Málaga",
                    "city": "Teba",
                    "multiple": "0"
                },
                {
                    "zipcode": "29328",
                    "province": "Málaga",
                    "city": "Sierra de Yeguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29329",
                    "province": "Málaga",
                    "city": "Sierra de Yeguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29330",
                    "province": "Málaga",
                    "city": "Almargen",
                    "multiple": "0"
                },
                {
                    "zipcode": "29340",
                    "province": "Málaga",
                    "city": "Cañete la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "29350",
                    "province": "Málaga",
                    "city": "Arriate",
                    "multiple": "0"
                },
                {
                    "zipcode": "29360",
                    "province": "Málaga",
                    "city": "Montejaque",
                    "multiple": "0"
                },
                {
                    "zipcode": "29370",
                    "province": "Málaga",
                    "city": "Benaoján",
                    "multiple": "0"
                },
                {
                    "zipcode": "29380",
                    "province": "Málaga",
                    "city": "Cortes de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29391",
                    "province": "Málaga",
                    "city": "Cortes de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29392",
                    "province": "Málaga",
                    "city": "Jimera de Líbar",
                    "multiple": "0"
                },
                {
                    "zipcode": "29394",
                    "province": "Málaga",
                    "city": "Ronda",
                    "multiple": "0"
                },
                {
                    "zipcode": "29400",
                    "province": "Málaga",
                    "city": "Serrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "29400",
                    "province": "Málaga",
                    "city": "Montecorto",
                    "multiple": "1"
                },
                {
                    "zipcode": "29400",
                    "province": "Málaga",
                    "city": "Ronda",
                    "multiple": "1"
                },
                {
                    "zipcode": "29400",
                    "province": "Málaga",
                    "city": "Gaucín",
                    "multiple": "1"
                },
                {
                    "zipcode": "29410",
                    "province": "Málaga",
                    "city": "Yunquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29420",
                    "province": "Málaga",
                    "city": "Burgo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "29430",
                    "province": "Málaga",
                    "city": "Montecorto",
                    "multiple": "0"
                },
                {
                    "zipcode": "29430",
                    "province": "Málaga",
                    "city": "Ronda",
                    "multiple": "1"
                },
                {
                    "zipcode": "29440",
                    "province": "Málaga",
                    "city": "Igualeja",
                    "multiple": "0"
                },
                {
                    "zipcode": "29450",
                    "province": "Málaga",
                    "city": "Pujerra",
                    "multiple": "0"
                },
                {
                    "zipcode": "29451",
                    "province": "Málaga",
                    "city": "Parauta",
                    "multiple": "0"
                },
                {
                    "zipcode": "29452",
                    "province": "Málaga",
                    "city": "Cartajima",
                    "multiple": "0"
                },
                {
                    "zipcode": "29460",
                    "province": "Málaga",
                    "city": "Alpandeire",
                    "multiple": "0"
                },
                {
                    "zipcode": "29461",
                    "province": "Málaga",
                    "city": "Faraján",
                    "multiple": "0"
                },
                {
                    "zipcode": "29462",
                    "province": "Málaga",
                    "city": "Júzcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "29470",
                    "province": "Málaga",
                    "city": "Cuevas del Becerro",
                    "multiple": "0"
                },
                {
                    "zipcode": "29470",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "29471",
                    "province": "Málaga",
                    "city": "Serrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "29480",
                    "province": "Málaga",
                    "city": "Gaucín",
                    "multiple": "0"
                },
                {
                    "zipcode": "29490",
                    "province": "Málaga",
                    "city": "Benarrabá",
                    "multiple": "0"
                },
                {
                    "zipcode": "29490",
                    "province": "Málaga",
                    "city": "Cortes de la Frontera",
                    "multiple": "1"
                },
                {
                    "zipcode": "29491",
                    "province": "Málaga",
                    "city": "Benalauría",
                    "multiple": "0"
                },
                {
                    "zipcode": "29491",
                    "province": "Málaga",
                    "city": "Algatocín",
                    "multiple": "1"
                },
                {
                    "zipcode": "29492",
                    "province": "Málaga",
                    "city": "Jubrique",
                    "multiple": "0"
                },
                {
                    "zipcode": "29492",
                    "province": "Málaga",
                    "city": "Genalguacil",
                    "multiple": "1"
                },
                {
                    "zipcode": "29493",
                    "province": "Málaga",
                    "city": "Benadalid",
                    "multiple": "0"
                },
                {
                    "zipcode": "29494",
                    "province": "Málaga",
                    "city": "Atajate",
                    "multiple": "0"
                },
                {
                    "zipcode": "29500",
                    "province": "Málaga",
                    "city": "Álora",
                    "multiple": "0"
                },
                {
                    "zipcode": "29510",
                    "province": "Málaga",
                    "city": "Álora",
                    "multiple": "0"
                },
                {
                    "zipcode": "29520",
                    "province": "Málaga",
                    "city": "Fuente de Piedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "29530",
                    "province": "Málaga",
                    "city": "Alameda",
                    "multiple": "0"
                },
                {
                    "zipcode": "29531",
                    "province": "Málaga",
                    "city": "Humilladero",
                    "multiple": "0"
                },
                {
                    "zipcode": "29532",
                    "province": "Málaga",
                    "city": "Mollina",
                    "multiple": "0"
                },
                {
                    "zipcode": "29533",
                    "province": "Málaga",
                    "city": "Humilladero",
                    "multiple": "0"
                },
                {
                    "zipcode": "29540",
                    "province": "Málaga",
                    "city": "Antequera",
                    "multiple": "0"
                },
                {
                    "zipcode": "29550",
                    "province": "Málaga",
                    "city": "Ardales",
                    "multiple": "0"
                },
                {
                    "zipcode": "29551",
                    "province": "Málaga",
                    "city": "Carratraca",
                    "multiple": "0"
                },
                {
                    "zipcode": "29552",
                    "province": "Málaga",
                    "city": "Ardales",
                    "multiple": "0"
                },
                {
                    "zipcode": "29552",
                    "province": "Málaga",
                    "city": "Álora",
                    "multiple": "1"
                },
                {
                    "zipcode": "29560",
                    "province": "Málaga",
                    "city": "Pizarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "29566",
                    "province": "Málaga",
                    "city": "Casarabonela",
                    "multiple": "0"
                },
                {
                    "zipcode": "29567",
                    "province": "Málaga",
                    "city": "Alozaina",
                    "multiple": "0"
                },
                {
                    "zipcode": "29568",
                    "province": "Málaga",
                    "city": "Pizarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "29569",
                    "province": "Málaga",
                    "city": "Pizarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "29569",
                    "province": "Málaga",
                    "city": "Cártama",
                    "multiple": "1"
                },
                {
                    "zipcode": "29570",
                    "province": "Málaga",
                    "city": "Cártama",
                    "multiple": "0"
                },
                {
                    "zipcode": "29580",
                    "province": "Málaga",
                    "city": "Cártama",
                    "multiple": "0"
                },
                {
                    "zipcode": "29590",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29591",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29592",
                    "province": "Málaga",
                    "city": "Cártama",
                    "multiple": "0"
                },
                {
                    "zipcode": "29593",
                    "province": "Málaga",
                    "city": "Álora",
                    "multiple": "0"
                },
                {
                    "zipcode": "29601",
                    "province": "Málaga",
                    "city": "Marbella",
                    "multiple": "0"
                },
                {
                    "zipcode": "29602",
                    "province": "Málaga",
                    "city": "Marbella",
                    "multiple": "0"
                },
                {
                    "zipcode": "29603",
                    "province": "Málaga",
                    "city": "Marbella",
                    "multiple": "0"
                },
                {
                    "zipcode": "29604",
                    "province": "Málaga",
                    "city": "Marbella",
                    "multiple": "0"
                },
                {
                    "zipcode": "29610",
                    "province": "Málaga",
                    "city": "Ojén",
                    "multiple": "0"
                },
                {
                    "zipcode": "29611",
                    "province": "Málaga",
                    "city": "Istán",
                    "multiple": "0"
                },
                {
                    "zipcode": "29612",
                    "province": "Málaga",
                    "city": "Ojén",
                    "multiple": "0"
                },
                {
                    "zipcode": "29620",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29620",
                    "province": "Málaga",
                    "city": "Torremolinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "29630",
                    "province": "Málaga",
                    "city": "Benalmádena",
                    "multiple": "0"
                },
                {
                    "zipcode": "29631",
                    "province": "Málaga",
                    "city": "Benalmádena",
                    "multiple": "0"
                },
                {
                    "zipcode": "29639",
                    "province": "Málaga",
                    "city": "Benalmádena",
                    "multiple": "0"
                },
                {
                    "zipcode": "29640",
                    "province": "Málaga",
                    "city": "Fuengirola",
                    "multiple": "0"
                },
                {
                    "zipcode": "29649",
                    "province": "Málaga",
                    "city": "Mijas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29650",
                    "province": "Málaga",
                    "city": "Mijas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29651",
                    "province": "Málaga",
                    "city": "Mijas",
                    "multiple": "0"
                },
                {
                    "zipcode": "29660",
                    "province": "Málaga",
                    "city": "Marbella",
                    "multiple": "0"
                },
                {
                    "zipcode": "29670",
                    "province": "Málaga",
                    "city": "Marbella",
                    "multiple": "0"
                },
                {
                    "zipcode": "29679",
                    "province": "Málaga",
                    "city": "Benahavís",
                    "multiple": "0"
                },
                {
                    "zipcode": "29679",
                    "province": "Málaga",
                    "city": "Marbella",
                    "multiple": "1"
                },
                {
                    "zipcode": "29680",
                    "province": "Málaga",
                    "city": "Estepona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29688",
                    "province": "Málaga",
                    "city": "Estepona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29689",
                    "province": "Málaga",
                    "city": "Estepona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29690",
                    "province": "Málaga",
                    "city": "Casares",
                    "multiple": "0"
                },
                {
                    "zipcode": "29691",
                    "province": "Málaga",
                    "city": "Manilva",
                    "multiple": "0"
                },
                {
                    "zipcode": "29692",
                    "province": "Málaga",
                    "city": "Manilva",
                    "multiple": "0"
                },
                {
                    "zipcode": "29692",
                    "province": "Málaga",
                    "city": "Casares",
                    "multiple": "1"
                },
                {
                    "zipcode": "29693",
                    "province": "Málaga",
                    "city": "Estepona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29698",
                    "province": "Málaga",
                    "city": "Estepona",
                    "multiple": "0"
                },
                {
                    "zipcode": "29700",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29710",
                    "province": "Málaga",
                    "city": "Periana",
                    "multiple": "0"
                },
                {
                    "zipcode": "29711",
                    "province": "Málaga",
                    "city": "Alcaucín",
                    "multiple": "0"
                },
                {
                    "zipcode": "29712",
                    "province": "Málaga",
                    "city": "Viñuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "29713",
                    "province": "Málaga",
                    "city": "Alcaucín",
                    "multiple": "0"
                },
                {
                    "zipcode": "29713",
                    "province": "Málaga",
                    "city": "Viñuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "29714",
                    "province": "Málaga",
                    "city": "Salares",
                    "multiple": "0"
                },
                {
                    "zipcode": "29715",
                    "province": "Málaga",
                    "city": "Sedella",
                    "multiple": "0"
                },
                {
                    "zipcode": "29716",
                    "province": "Málaga",
                    "city": "Canillas de Aceituno",
                    "multiple": "0"
                },
                {
                    "zipcode": "29718",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29718",
                    "province": "Málaga",
                    "city": "Cútar",
                    "multiple": "1"
                },
                {
                    "zipcode": "29718",
                    "province": "Málaga",
                    "city": "Borge, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "29718",
                    "province": "Málaga",
                    "city": "Benamargosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "29718",
                    "province": "Málaga",
                    "city": "Almáchar",
                    "multiple": "1"
                },
                {
                    "zipcode": "29719",
                    "province": "Málaga",
                    "city": "Viñuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "29719",
                    "province": "Málaga",
                    "city": "Sedella",
                    "multiple": "1"
                },
                {
                    "zipcode": "29719",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "29719",
                    "province": "Málaga",
                    "city": "Benamocarra",
                    "multiple": "1"
                },
                {
                    "zipcode": "29719",
                    "province": "Málaga",
                    "city": "Canillas de Aceituno",
                    "multiple": "1"
                },
                {
                    "zipcode": "29720",
                    "province": "Málaga",
                    "city": "Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29720",
                    "province": "Málaga",
                    "city": "Rincón de la Victoria",
                    "multiple": "1"
                },
                {
                    "zipcode": "29730",
                    "province": "Málaga",
                    "city": "Rincón de la Victoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "29738",
                    "province": "Málaga",
                    "city": "Rincón de la Victoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "29738",
                    "province": "Málaga",
                    "city": "Moclinejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "29740",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29749",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29750",
                    "province": "Málaga",
                    "city": "Algarrobo",
                    "multiple": "0"
                },
                {
                    "zipcode": "29750",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "29751",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29752",
                    "province": "Málaga",
                    "city": "Sayalonga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29753",
                    "province": "Málaga",
                    "city": "Árchez",
                    "multiple": "0"
                },
                {
                    "zipcode": "29753",
                    "province": "Málaga",
                    "city": "Sayalonga",
                    "multiple": "1"
                },
                {
                    "zipcode": "29753",
                    "province": "Málaga",
                    "city": "Arenas",
                    "multiple": "1"
                },
                {
                    "zipcode": "29754",
                    "province": "Málaga",
                    "city": "Cómpeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "29755",
                    "province": "Málaga",
                    "city": "Canillas de Albaida",
                    "multiple": "0"
                },
                {
                    "zipcode": "29760",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29770",
                    "province": "Málaga",
                    "city": "Torrox",
                    "multiple": "0"
                },
                {
                    "zipcode": "29780",
                    "province": "Málaga",
                    "city": "Nerja",
                    "multiple": "0"
                },
                {
                    "zipcode": "29787",
                    "province": "Málaga",
                    "city": "Nerja",
                    "multiple": "0"
                },
                {
                    "zipcode": "29788",
                    "province": "Málaga",
                    "city": "Frigiliana",
                    "multiple": "0"
                },
                {
                    "zipcode": "29789",
                    "province": "Málaga",
                    "city": "Frigiliana",
                    "multiple": "0"
                },
                {
                    "zipcode": "29790",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "29791",
                    "province": "Málaga",
                    "city": "Macharaviaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "29792",
                    "province": "Málaga",
                    "city": "Iznate",
                    "multiple": "0"
                },
                {
                    "zipcode": "29792",
                    "province": "Málaga",
                    "city": "Vélez-Málaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "29793",
                    "province": "Málaga",
                    "city": "Torrox",
                    "multiple": "0"
                },
                {
                    "zipcode": "29796",
                    "province": "Málaga",
                    "city": "Torrox",
                    "multiple": "0"
                },
                {
                    "zipcode": "30001",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30002",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30003",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30004",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30005",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30006",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30007",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30008",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30009",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30010",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30011",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30012",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30100",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30107",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30108",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30110",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30120",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30130",
                    "province": "Murcia",
                    "city": "Beniel",
                    "multiple": "0"
                },
                {
                    "zipcode": "30139",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30140",
                    "province": "Murcia",
                    "city": "Santomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "30148",
                    "province": "Murcia",
                    "city": "Santomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "30149",
                    "province": "Murcia",
                    "city": "Santomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "30150",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30151",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30152",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30153",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30154",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30155",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30156",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30157",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30158",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30160",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30161",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30162",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30163",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30164",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30165",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30166",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30167",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30168",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30169",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30170",
                    "province": "Murcia",
                    "city": "Mula",
                    "multiple": "0"
                },
                {
                    "zipcode": "30176",
                    "province": "Murcia",
                    "city": "Pliego",
                    "multiple": "0"
                },
                {
                    "zipcode": "30177",
                    "province": "Murcia",
                    "city": "Mula",
                    "multiple": "0"
                },
                {
                    "zipcode": "30178",
                    "province": "Murcia",
                    "city": "Mula",
                    "multiple": "0"
                },
                {
                    "zipcode": "30179",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30180",
                    "province": "Murcia",
                    "city": "Bullas",
                    "multiple": "0"
                },
                {
                    "zipcode": "30189",
                    "province": "Murcia",
                    "city": "Bullas",
                    "multiple": "0"
                },
                {
                    "zipcode": "30189",
                    "province": "Murcia",
                    "city": "Cehegín",
                    "multiple": "1"
                },
                {
                    "zipcode": "30190",
                    "province": "Murcia",
                    "city": "Albudeite",
                    "multiple": "0"
                },
                {
                    "zipcode": "30191",
                    "province": "Murcia",
                    "city": "Campos del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "30192",
                    "province": "Murcia",
                    "city": "Campos del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "30193",
                    "province": "Murcia",
                    "city": "Mula",
                    "multiple": "0"
                },
                {
                    "zipcode": "30194",
                    "province": "Murcia",
                    "city": "Mula",
                    "multiple": "0"
                },
                {
                    "zipcode": "30195",
                    "province": "Murcia",
                    "city": "Caravaca de la Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "30201",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30202",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30203",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30204",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30205",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30300",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30310",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30319",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30320",
                    "province": "Murcia",
                    "city": "Fuente Álamo de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30330",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30331",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30332",
                    "province": "Murcia",
                    "city": "Fuente Álamo de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30333",
                    "province": "Murcia",
                    "city": "Fuente Álamo de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30334",
                    "province": "Murcia",
                    "city": "Fuente Álamo de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30335",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30335",
                    "province": "Murcia",
                    "city": "Fuente Álamo de Murcia",
                    "multiple": "1"
                },
                {
                    "zipcode": "30338",
                    "province": "Murcia",
                    "city": "Fuente Álamo de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30350",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30351",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30360",
                    "province": "Murcia",
                    "city": "Unión, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "30364",
                    "province": "Murcia",
                    "city": "Unión, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "30365",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30366",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30367",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30368",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30369",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30369",
                    "province": "Murcia",
                    "city": "Unión, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "30370",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30370",
                    "province": "Murcia",
                    "city": "San Javier",
                    "multiple": "1"
                },
                {
                    "zipcode": "30380",
                    "province": "Murcia",
                    "city": "San Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "30380",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "1"
                },
                {
                    "zipcode": "30381",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30382",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30383",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30384",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30385",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30386",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30387",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30387",
                    "province": "Murcia",
                    "city": "Moratalla",
                    "multiple": "1"
                },
                {
                    "zipcode": "30390",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30391",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30392",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30393",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30394",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30395",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30396",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30397",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30398",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30399",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30400",
                    "province": "Murcia",
                    "city": "Caravaca de la Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "30410",
                    "province": "Murcia",
                    "city": "Caravaca de la Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "30411",
                    "province": "Murcia",
                    "city": "Caravaca de la Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "30412",
                    "province": "Murcia",
                    "city": "Caravaca de la Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "30413",
                    "province": "Murcia",
                    "city": "Moratalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30413",
                    "province": "Murcia",
                    "city": "Caravaca de la Cruz",
                    "multiple": "1"
                },
                {
                    "zipcode": "30420",
                    "province": "Murcia",
                    "city": "Cehegín",
                    "multiple": "0"
                },
                {
                    "zipcode": "30420",
                    "province": "Murcia",
                    "city": "Calasparra",
                    "multiple": "1"
                },
                {
                    "zipcode": "30430",
                    "province": "Murcia",
                    "city": "Cehegín",
                    "multiple": "0"
                },
                {
                    "zipcode": "30439",
                    "province": "Murcia",
                    "city": "Cehegín",
                    "multiple": "0"
                },
                {
                    "zipcode": "30440",
                    "province": "Murcia",
                    "city": "Moratalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30441",
                    "province": "Murcia",
                    "city": "Moratalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30442",
                    "province": "Murcia",
                    "city": "Moratalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30500",
                    "province": "Murcia",
                    "city": "Molina de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "30506",
                    "province": "Murcia",
                    "city": "Molina de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "30507",
                    "province": "Murcia",
                    "city": "Molina de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "30508",
                    "province": "Murcia",
                    "city": "Molina de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "30509",
                    "province": "Murcia",
                    "city": "Molina de Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "30510",
                    "province": "Murcia",
                    "city": "Yecla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30520",
                    "province": "Murcia",
                    "city": "Jumilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30528",
                    "province": "Murcia",
                    "city": "Jumilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30529",
                    "province": "Murcia",
                    "city": "Jumilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30530",
                    "province": "Murcia",
                    "city": "Cieza",
                    "multiple": "0"
                },
                {
                    "zipcode": "30540",
                    "province": "Murcia",
                    "city": "Blanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30550",
                    "province": "Murcia",
                    "city": "Abarán",
                    "multiple": "0"
                },
                {
                    "zipcode": "30559",
                    "province": "Murcia",
                    "city": "Abarán",
                    "multiple": "0"
                },
                {
                    "zipcode": "30560",
                    "province": "Murcia",
                    "city": "Alguazas",
                    "multiple": "0"
                },
                {
                    "zipcode": "30561",
                    "province": "Murcia",
                    "city": "Alguazas",
                    "multiple": "0"
                },
                {
                    "zipcode": "30562",
                    "province": "Murcia",
                    "city": "Ceutí",
                    "multiple": "0"
                },
                {
                    "zipcode": "30563",
                    "province": "Murcia",
                    "city": "Ceutí",
                    "multiple": "0"
                },
                {
                    "zipcode": "30564",
                    "province": "Murcia",
                    "city": "Lorquí",
                    "multiple": "0"
                },
                {
                    "zipcode": "30565",
                    "province": "Murcia",
                    "city": "Torres de Cotillas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "30570",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30579",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30580",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30588",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30589",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30590",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30590",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "1"
                },
                {
                    "zipcode": "30591",
                    "province": "Murcia",
                    "city": "Torre-Pacheco",
                    "multiple": "0"
                },
                {
                    "zipcode": "30592",
                    "province": "Murcia",
                    "city": "San Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "30592",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "1"
                },
                {
                    "zipcode": "30592",
                    "province": "Murcia",
                    "city": "Torre-Pacheco",
                    "multiple": "1"
                },
                {
                    "zipcode": "30593",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30594",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30600",
                    "province": "Murcia",
                    "city": "Archena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30609",
                    "province": "Murcia",
                    "city": "Archena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30610",
                    "province": "Murcia",
                    "city": "Ricote",
                    "multiple": "0"
                },
                {
                    "zipcode": "30611",
                    "province": "Murcia",
                    "city": "Ojós",
                    "multiple": "0"
                },
                {
                    "zipcode": "30612",
                    "province": "Murcia",
                    "city": "Ulea",
                    "multiple": "0"
                },
                {
                    "zipcode": "30613",
                    "province": "Murcia",
                    "city": "Villanueva del Río Segura",
                    "multiple": "0"
                },
                {
                    "zipcode": "30620",
                    "province": "Murcia",
                    "city": "Fortuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "30626",
                    "province": "Murcia",
                    "city": "Fortuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "30626",
                    "province": "Murcia",
                    "city": "Abanilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "30628",
                    "province": "Murcia",
                    "city": "Fortuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "30629",
                    "province": "Murcia",
                    "city": "Fortuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "30640",
                    "province": "Murcia",
                    "city": "Abanilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30648",
                    "province": "Murcia",
                    "city": "Abanilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30649",
                    "province": "Murcia",
                    "city": "Abanilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30700",
                    "province": "Murcia",
                    "city": "Torre-Pacheco",
                    "multiple": "0"
                },
                {
                    "zipcode": "30708",
                    "province": "Murcia",
                    "city": "Torre-Pacheco",
                    "multiple": "0"
                },
                {
                    "zipcode": "30709",
                    "province": "Murcia",
                    "city": "Torre-Pacheco",
                    "multiple": "0"
                },
                {
                    "zipcode": "30710",
                    "province": "Murcia",
                    "city": "Torre-Pacheco",
                    "multiple": "0"
                },
                {
                    "zipcode": "30710",
                    "province": "Murcia",
                    "city": "Alcázares, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "30720",
                    "province": "Murcia",
                    "city": "San Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "30729",
                    "province": "Murcia",
                    "city": "San Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "30730",
                    "province": "Murcia",
                    "city": "San Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "30739",
                    "province": "Murcia",
                    "city": "San Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "30739",
                    "province": "Murcia",
                    "city": "Torre-Pacheco",
                    "multiple": "1"
                },
                {
                    "zipcode": "30740",
                    "province": "Murcia",
                    "city": "San Pedro del Pinatar",
                    "multiple": "0"
                },
                {
                    "zipcode": "30749",
                    "province": "Murcia",
                    "city": "San Pedro del Pinatar",
                    "multiple": "0"
                },
                {
                    "zipcode": "30800",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30810",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30811",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30812",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30813",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30814",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30815",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30816",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30817",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30818",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30820",
                    "province": "Murcia",
                    "city": "Alcantarilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30830",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30831",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30832",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30833",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30834",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30834",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "1"
                },
                {
                    "zipcode": "30835",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30835",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "1"
                },
                {
                    "zipcode": "30836",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30837",
                    "province": "Murcia",
                    "city": "Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30840",
                    "province": "Murcia",
                    "city": "Alhama de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30848",
                    "province": "Murcia",
                    "city": "Alhama de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30849",
                    "province": "Murcia",
                    "city": "Alhama de Murcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "30850",
                    "province": "Murcia",
                    "city": "Totana",
                    "multiple": "0"
                },
                {
                    "zipcode": "30858",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30858",
                    "province": "Murcia",
                    "city": "Totana",
                    "multiple": "1"
                },
                {
                    "zipcode": "30859",
                    "province": "Murcia",
                    "city": "Aledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "30860",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30868",
                    "province": "Murcia",
                    "city": "Cartagena",
                    "multiple": "0"
                },
                {
                    "zipcode": "30868",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "1"
                },
                {
                    "zipcode": "30870",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30875",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30876",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30876",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "1"
                },
                {
                    "zipcode": "30877",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30878",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30878",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "1"
                },
                {
                    "zipcode": "30879",
                    "province": "Murcia",
                    "city": "Mazarrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "30880",
                    "province": "Murcia",
                    "city": "Águilas",
                    "multiple": "0"
                },
                {
                    "zipcode": "30889",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "30889",
                    "province": "Murcia",
                    "city": "Águilas",
                    "multiple": "1"
                },
                {
                    "zipcode": "30890",
                    "province": "Murcia",
                    "city": "Puerto Lumbreras",
                    "multiple": "0"
                },
                {
                    "zipcode": "30890",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "1"
                },
                {
                    "zipcode": "30891",
                    "province": "Murcia",
                    "city": "Puerto Lumbreras",
                    "multiple": "0"
                },
                {
                    "zipcode": "30892",
                    "province": "Murcia",
                    "city": "Librilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "30893",
                    "province": "Murcia",
                    "city": "Lorca",
                    "multiple": "0"
                },
                {
                    "zipcode": "31001",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31002",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31003",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31004",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31005",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31006",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31007",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31008",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31009",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31010",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31010",
                    "province": "Navarra",
                    "city": "Barañain",
                    "multiple": "1"
                },
                {
                    "zipcode": "31011",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31012",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31013",
                    "province": "Navarra",
                    "city": "Berriozar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31013",
                    "province": "Navarra",
                    "city": "Berrioplano/Berriobeiti",
                    "multiple": "1"
                },
                {
                    "zipcode": "31013",
                    "province": "Navarra",
                    "city": "Ansoáin/Antsoain",
                    "multiple": "1"
                },
                {
                    "zipcode": "31014",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31015",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31016",
                    "province": "Navarra",
                    "city": "Pamplona/Iruña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31100",
                    "province": "Navarra",
                    "city": "Puente la Reina/Gares",
                    "multiple": "0"
                },
                {
                    "zipcode": "31109",
                    "province": "Navarra",
                    "city": "Artazu",
                    "multiple": "0"
                },
                {
                    "zipcode": "31110",
                    "province": "Navarra",
                    "city": "Noáin (Valle de Elorz)/Noain (Elortzibar)",
                    "multiple": "0"
                },
                {
                    "zipcode": "31119",
                    "province": "Navarra",
                    "city": "Noáin (Valle de Elorz)/Noain (Elortzibar)",
                    "multiple": "0"
                },
                {
                    "zipcode": "31130",
                    "province": "Navarra",
                    "city": "Mañeru",
                    "multiple": "0"
                },
                {
                    "zipcode": "31131",
                    "province": "Navarra",
                    "city": "Cirauqui/Zirauki",
                    "multiple": "0"
                },
                {
                    "zipcode": "31132",
                    "province": "Navarra",
                    "city": "Villatuerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "31133",
                    "province": "Navarra",
                    "city": "Uterga",
                    "multiple": "0"
                },
                {
                    "zipcode": "31133",
                    "province": "Navarra",
                    "city": "Legarda",
                    "multiple": "1"
                },
                {
                    "zipcode": "31140",
                    "province": "Navarra",
                    "city": "Artajona",
                    "multiple": "0"
                },
                {
                    "zipcode": "31150",
                    "province": "Navarra",
                    "city": "Mendigorría",
                    "multiple": "0"
                },
                {
                    "zipcode": "31151",
                    "province": "Navarra",
                    "city": "Obanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "31152",
                    "province": "Navarra",
                    "city": "Muruzábal",
                    "multiple": "0"
                },
                {
                    "zipcode": "31153",
                    "province": "Navarra",
                    "city": "Enériz/Eneritz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31153",
                    "province": "Navarra",
                    "city": "Adiós",
                    "multiple": "1"
                },
                {
                    "zipcode": "31154",
                    "province": "Navarra",
                    "city": "Añorbe",
                    "multiple": "0"
                },
                {
                    "zipcode": "31154",
                    "province": "Navarra",
                    "city": "Tirapu",
                    "multiple": "1"
                },
                {
                    "zipcode": "31154",
                    "province": "Navarra",
                    "city": "Ucar",
                    "multiple": "1"
                },
                {
                    "zipcode": "31160",
                    "province": "Navarra",
                    "city": "Orkoien",
                    "multiple": "0"
                },
                {
                    "zipcode": "31170",
                    "province": "Navarra",
                    "city": "Iza/Itza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31170",
                    "province": "Navarra",
                    "city": "Cendea de Olza/Oltza Zendea",
                    "multiple": "1"
                },
                {
                    "zipcode": "31171",
                    "province": "Navarra",
                    "city": "Cendea de Olza/Oltza Zendea",
                    "multiple": "0"
                },
                {
                    "zipcode": "31172",
                    "province": "Navarra",
                    "city": "Iza/Itza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31172",
                    "province": "Navarra",
                    "city": "Ollo",
                    "multiple": "1"
                },
                {
                    "zipcode": "31172",
                    "province": "Navarra",
                    "city": "Goñi",
                    "multiple": "1"
                },
                {
                    "zipcode": "31173",
                    "province": "Navarra",
                    "city": "Cendea de Olza/Oltza Zendea",
                    "multiple": "0"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Ciriza/Ziritza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Bidaurreta",
                    "multiple": "1"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Cizur",
                    "multiple": "1"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Etxauri",
                    "multiple": "1"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Guirguillano",
                    "multiple": "1"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Zabalza/Zabaltza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Belascoáin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31174",
                    "province": "Navarra",
                    "city": "Echarri",
                    "multiple": "1"
                },
                {
                    "zipcode": "31175",
                    "province": "Navarra",
                    "city": "Salinas de Oro/Jaitz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31175",
                    "province": "Navarra",
                    "city": "Guesálaz/Gesalatz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31176",
                    "province": "Navarra",
                    "city": "Guesálaz/Gesalatz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31176",
                    "province": "Navarra",
                    "city": "Valle de Yerri/Deierri",
                    "multiple": "1"
                },
                {
                    "zipcode": "31177",
                    "province": "Navarra",
                    "city": "Lezáun",
                    "multiple": "0"
                },
                {
                    "zipcode": "31177",
                    "province": "Navarra",
                    "city": "Valle de Yerri/Deierri",
                    "multiple": "1"
                },
                {
                    "zipcode": "31178",
                    "province": "Navarra",
                    "city": "Abárzuza/Abartzuza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31179",
                    "province": "Navarra",
                    "city": "Valle de Yerri/Deierri",
                    "multiple": "0"
                },
                {
                    "zipcode": "31180",
                    "province": "Navarra",
                    "city": "Zizur Mayor/Zizur Nagusia",
                    "multiple": "0"
                },
                {
                    "zipcode": "31190",
                    "province": "Navarra",
                    "city": "Cizur",
                    "multiple": "0"
                },
                {
                    "zipcode": "31191",
                    "province": "Navarra",
                    "city": "Galar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31191",
                    "province": "Navarra",
                    "city": "Beriáin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31192",
                    "province": "Navarra",
                    "city": "Aranguren",
                    "multiple": "0"
                },
                {
                    "zipcode": "31192",
                    "province": "Navarra",
                    "city": "Valle de Egüés/Eguesibar",
                    "multiple": "1"
                },
                {
                    "zipcode": "31193",
                    "province": "Navarra",
                    "city": "Atez/Atetz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31193",
                    "province": "Navarra",
                    "city": "Juslapeña",
                    "multiple": "1"
                },
                {
                    "zipcode": "31194",
                    "province": "Navarra",
                    "city": "Ezcabarte",
                    "multiple": "0"
                },
                {
                    "zipcode": "31194",
                    "province": "Navarra",
                    "city": "Juslapeña",
                    "multiple": "1"
                },
                {
                    "zipcode": "31195",
                    "province": "Navarra",
                    "city": "Berriozar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31195",
                    "province": "Navarra",
                    "city": "Berrioplano/Berriobeiti",
                    "multiple": "1"
                },
                {
                    "zipcode": "31200",
                    "province": "Navarra",
                    "city": "Estella-Lizarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "31208",
                    "province": "Navarra",
                    "city": "Ayegui/Aiegi",
                    "multiple": "0"
                },
                {
                    "zipcode": "31210",
                    "province": "Navarra",
                    "city": "Arcos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "31219",
                    "province": "Navarra",
                    "city": "Sorlada",
                    "multiple": "0"
                },
                {
                    "zipcode": "31219",
                    "province": "Navarra",
                    "city": "Mendaza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31219",
                    "province": "Navarra",
                    "city": "Mues",
                    "multiple": "1"
                },
                {
                    "zipcode": "31219",
                    "province": "Navarra",
                    "city": "Mirafuentes",
                    "multiple": "1"
                },
                {
                    "zipcode": "31219",
                    "province": "Navarra",
                    "city": "Piedramillera",
                    "multiple": "1"
                },
                {
                    "zipcode": "31219",
                    "province": "Navarra",
                    "city": "Torralba del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "31220",
                    "province": "Navarra",
                    "city": "Sansol",
                    "multiple": "0"
                },
                {
                    "zipcode": "31227",
                    "province": "Navarra",
                    "city": "Genevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "31227",
                    "province": "Navarra",
                    "city": "Cabredo",
                    "multiple": "1"
                },
                {
                    "zipcode": "31227",
                    "province": "Navarra",
                    "city": "Marañón",
                    "multiple": "1"
                },
                {
                    "zipcode": "31227",
                    "province": "Navarra",
                    "city": "Lapoblación",
                    "multiple": "1"
                },
                {
                    "zipcode": "31228",
                    "province": "Navarra",
                    "city": "Aguilar de Codés",
                    "multiple": "0"
                },
                {
                    "zipcode": "31228",
                    "province": "Navarra",
                    "city": "Armañanzas",
                    "multiple": "1"
                },
                {
                    "zipcode": "31228",
                    "province": "Navarra",
                    "city": "Espronceda",
                    "multiple": "1"
                },
                {
                    "zipcode": "31228",
                    "province": "Navarra",
                    "city": "Azuelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "31228",
                    "province": "Navarra",
                    "city": "Torralba del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "31229",
                    "province": "Navarra",
                    "city": "Torres del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "31229",
                    "province": "Navarra",
                    "city": "Bargota",
                    "multiple": "1"
                },
                {
                    "zipcode": "31229",
                    "province": "Navarra",
                    "city": "Busto, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "31229",
                    "province": "Navarra",
                    "city": "Desojo",
                    "multiple": "1"
                },
                {
                    "zipcode": "31230",
                    "province": "Navarra",
                    "city": "Viana",
                    "multiple": "0"
                },
                {
                    "zipcode": "31239",
                    "province": "Navarra",
                    "city": "Aras",
                    "multiple": "0"
                },
                {
                    "zipcode": "31240",
                    "province": "Navarra",
                    "city": "Ayegui/Aiegi",
                    "multiple": "0"
                },
                {
                    "zipcode": "31241",
                    "province": "Navarra",
                    "city": "Allín/Allin",
                    "multiple": "0"
                },
                {
                    "zipcode": "31241",
                    "province": "Navarra",
                    "city": "Metauten",
                    "multiple": "1"
                },
                {
                    "zipcode": "31241",
                    "province": "Navarra",
                    "city": "Igúzquiza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31242",
                    "province": "Navarra",
                    "city": "Villamayor de Monjardín",
                    "multiple": "0"
                },
                {
                    "zipcode": "31243",
                    "province": "Navarra",
                    "city": "Luquin",
                    "multiple": "0"
                },
                {
                    "zipcode": "31243",
                    "province": "Navarra",
                    "city": "Arróniz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31243",
                    "province": "Navarra",
                    "city": "Barbarin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31243",
                    "province": "Navarra",
                    "city": "Igúzquiza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31250",
                    "province": "Navarra",
                    "city": "Oteiza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31251",
                    "province": "Navarra",
                    "city": "Larraga",
                    "multiple": "0"
                },
                {
                    "zipcode": "31252",
                    "province": "Navarra",
                    "city": "Berbinzana",
                    "multiple": "0"
                },
                {
                    "zipcode": "31253",
                    "province": "Navarra",
                    "city": "Miranda de Arga",
                    "multiple": "0"
                },
                {
                    "zipcode": "31260",
                    "province": "Navarra",
                    "city": "Lerín",
                    "multiple": "0"
                },
                {
                    "zipcode": "31261",
                    "province": "Navarra",
                    "city": "Andosilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "31262",
                    "province": "Navarra",
                    "city": "Allo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31263",
                    "province": "Navarra",
                    "city": "Dicastillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31263",
                    "province": "Navarra",
                    "city": "Arellano",
                    "multiple": "1"
                },
                {
                    "zipcode": "31264",
                    "province": "Navarra",
                    "city": "Aberin",
                    "multiple": "0"
                },
                {
                    "zipcode": "31264",
                    "province": "Navarra",
                    "city": "Morentin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31270",
                    "province": "Navarra",
                    "city": "Larraona",
                    "multiple": "0"
                },
                {
                    "zipcode": "31271",
                    "province": "Navarra",
                    "city": "Eulate",
                    "multiple": "0"
                },
                {
                    "zipcode": "31271",
                    "province": "Navarra",
                    "city": "Aranarache/Aranaratxe",
                    "multiple": "1"
                },
                {
                    "zipcode": "31272",
                    "province": "Navarra",
                    "city": "Améscoa Baja",
                    "multiple": "0"
                },
                {
                    "zipcode": "31280",
                    "province": "Navarra",
                    "city": "Murieta",
                    "multiple": "0"
                },
                {
                    "zipcode": "31280",
                    "province": "Navarra",
                    "city": "Ancín/Antzin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31280",
                    "province": "Navarra",
                    "city": "Abáigar",
                    "multiple": "1"
                },
                {
                    "zipcode": "31281",
                    "province": "Navarra",
                    "city": "Oco",
                    "multiple": "0"
                },
                {
                    "zipcode": "31281",
                    "province": "Navarra",
                    "city": "Legaria",
                    "multiple": "1"
                },
                {
                    "zipcode": "31281",
                    "province": "Navarra",
                    "city": "Etayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "31281",
                    "province": "Navarra",
                    "city": "Olejua",
                    "multiple": "1"
                },
                {
                    "zipcode": "31281",
                    "province": "Navarra",
                    "city": "Ancín/Antzin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31282",
                    "province": "Navarra",
                    "city": "Nazar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31282",
                    "province": "Navarra",
                    "city": "Mendaza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31283",
                    "province": "Navarra",
                    "city": "Lana",
                    "multiple": "0"
                },
                {
                    "zipcode": "31284",
                    "province": "Navarra",
                    "city": "Zúñiga",
                    "multiple": "0"
                },
                {
                    "zipcode": "31290",
                    "province": "Navarra",
                    "city": "Valle de Yerri/Deierri",
                    "multiple": "0"
                },
                {
                    "zipcode": "31290",
                    "province": "Navarra",
                    "city": "Allín/Allin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31291",
                    "province": "Navarra",
                    "city": "Guirguillano",
                    "multiple": "0"
                },
                {
                    "zipcode": "31291",
                    "province": "Navarra",
                    "city": "Guesálaz/Gesalatz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31292",
                    "province": "Navarra",
                    "city": "Valle de Yerri/Deierri",
                    "multiple": "0"
                },
                {
                    "zipcode": "31292",
                    "province": "Navarra",
                    "city": "Villatuerta",
                    "multiple": "1"
                },
                {
                    "zipcode": "31292",
                    "province": "Navarra",
                    "city": "Aberin",
                    "multiple": "1"
                },
                {
                    "zipcode": "31293",
                    "province": "Navarra",
                    "city": "Sesma",
                    "multiple": "0"
                },
                {
                    "zipcode": "31300",
                    "province": "Navarra",
                    "city": "Tafalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "31310",
                    "province": "Navarra",
                    "city": "Carcastillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31311",
                    "province": "Navarra",
                    "city": "Carcastillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31312",
                    "province": "Navarra",
                    "city": "Cáseda",
                    "multiple": "0"
                },
                {
                    "zipcode": "31313",
                    "province": "Navarra",
                    "city": "Murillo el Fruto",
                    "multiple": "0"
                },
                {
                    "zipcode": "31314",
                    "province": "Navarra",
                    "city": "Santacara",
                    "multiple": "0"
                },
                {
                    "zipcode": "31315",
                    "province": "Navarra",
                    "city": "Murillo el Cuende",
                    "multiple": "0"
                },
                {
                    "zipcode": "31320",
                    "province": "Navarra",
                    "city": "Milagro",
                    "multiple": "0"
                },
                {
                    "zipcode": "31330",
                    "province": "Navarra",
                    "city": "Villafranca",
                    "multiple": "0"
                },
                {
                    "zipcode": "31340",
                    "province": "Navarra",
                    "city": "Marcilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "31350",
                    "province": "Navarra",
                    "city": "Peralta/Azkoien",
                    "multiple": "0"
                },
                {
                    "zipcode": "31360",
                    "province": "Navarra",
                    "city": "Funes",
                    "multiple": "0"
                },
                {
                    "zipcode": "31370",
                    "province": "Navarra",
                    "city": "Falces",
                    "multiple": "0"
                },
                {
                    "zipcode": "31380",
                    "province": "Navarra",
                    "city": "Caparroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "31381",
                    "province": "Navarra",
                    "city": "Caparroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "31382",
                    "province": "Navarra",
                    "city": "Mélida",
                    "multiple": "0"
                },
                {
                    "zipcode": "31383",
                    "province": "Navarra",
                    "city": "Murillo el Cuende",
                    "multiple": "0"
                },
                {
                    "zipcode": "31390",
                    "province": "Navarra",
                    "city": "Olite/Erriberri",
                    "multiple": "0"
                },
                {
                    "zipcode": "31391",
                    "province": "Navarra",
                    "city": "Murillo el Cuende",
                    "multiple": "0"
                },
                {
                    "zipcode": "31392",
                    "province": "Navarra",
                    "city": "Pitillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "31393",
                    "province": "Navarra",
                    "city": "Beire",
                    "multiple": "0"
                },
                {
                    "zipcode": "31394",
                    "province": "Navarra",
                    "city": "Pueyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31395",
                    "province": "Navarra",
                    "city": "Barásoain",
                    "multiple": "0"
                },
                {
                    "zipcode": "31395",
                    "province": "Navarra",
                    "city": "Garínoain",
                    "multiple": "1"
                },
                {
                    "zipcode": "31395",
                    "province": "Navarra",
                    "city": "Olóriz/Oloritz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31395",
                    "province": "Navarra",
                    "city": "Leoz/Leotz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31395",
                    "province": "Navarra",
                    "city": "Orísoain",
                    "multiple": "1"
                },
                {
                    "zipcode": "31396",
                    "province": "Navarra",
                    "city": "Unzué/Untzue",
                    "multiple": "0"
                },
                {
                    "zipcode": "31396",
                    "province": "Navarra",
                    "city": "Olóriz/Oloritz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31397",
                    "province": "Navarra",
                    "city": "Tiebas-Muruarte de Reta",
                    "multiple": "0"
                },
                {
                    "zipcode": "31398",
                    "province": "Navarra",
                    "city": "Biurrun-Olcoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31398",
                    "province": "Navarra",
                    "city": "Tiebas-Muruarte de Reta",
                    "multiple": "1"
                },
                {
                    "zipcode": "31400",
                    "province": "Navarra",
                    "city": "Sangüesa/Zangoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31409",
                    "province": "Navarra",
                    "city": "Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "31409",
                    "province": "Navarra",
                    "city": "Sangüesa/Zangoza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31410",
                    "province": "Navarra",
                    "city": "Yesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "31411",
                    "province": "Navarra",
                    "city": "Javier",
                    "multiple": "0"
                },
                {
                    "zipcode": "31412",
                    "province": "Navarra",
                    "city": "Burgui/Burgi",
                    "multiple": "0"
                },
                {
                    "zipcode": "31413",
                    "province": "Navarra",
                    "city": "Vidángoz/Bidankoze",
                    "multiple": "0"
                },
                {
                    "zipcode": "31414",
                    "province": "Navarra",
                    "city": "Garde",
                    "multiple": "0"
                },
                {
                    "zipcode": "31415",
                    "province": "Navarra",
                    "city": "Roncal/Erronkari",
                    "multiple": "0"
                },
                {
                    "zipcode": "31416",
                    "province": "Navarra",
                    "city": "Urzainqui/Urzainki",
                    "multiple": "0"
                },
                {
                    "zipcode": "31417",
                    "province": "Navarra",
                    "city": "Isaba/Izaba",
                    "multiple": "0"
                },
                {
                    "zipcode": "31418",
                    "province": "Navarra",
                    "city": "Uztárroz/Uztarroze",
                    "multiple": "0"
                },
                {
                    "zipcode": "31420",
                    "province": "Navarra",
                    "city": "Urroz-Villa",
                    "multiple": "0"
                },
                {
                    "zipcode": "31421",
                    "province": "Navarra",
                    "city": "Izagaondoa",
                    "multiple": "0"
                },
                {
                    "zipcode": "31422",
                    "province": "Navarra",
                    "city": "Unciti",
                    "multiple": "0"
                },
                {
                    "zipcode": "31430",
                    "province": "Navarra",
                    "city": "Aoiz/Agoitz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31438",
                    "province": "Navarra",
                    "city": "Arce/Artzi",
                    "multiple": "0"
                },
                {
                    "zipcode": "31438",
                    "province": "Navarra",
                    "city": "Lónguida/Longida",
                    "multiple": "1"
                },
                {
                    "zipcode": "31439",
                    "province": "Navarra",
                    "city": "Lónguida/Longida",
                    "multiple": "0"
                },
                {
                    "zipcode": "31439",
                    "province": "Navarra",
                    "city": "Oroz-Betelu/Orotz-Betelu",
                    "multiple": "1"
                },
                {
                    "zipcode": "31439",
                    "province": "Navarra",
                    "city": "Arce/Artzi",
                    "multiple": "1"
                },
                {
                    "zipcode": "31440",
                    "province": "Navarra",
                    "city": "Lumbier",
                    "multiple": "0"
                },
                {
                    "zipcode": "31448",
                    "province": "Navarra",
                    "city": "Urraul Alto",
                    "multiple": "0"
                },
                {
                    "zipcode": "31448",
                    "province": "Navarra",
                    "city": "Romanzado",
                    "multiple": "1"
                },
                {
                    "zipcode": "31448",
                    "province": "Navarra",
                    "city": "Urraul Bajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "31449",
                    "province": "Navarra",
                    "city": "Urraul Bajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31450",
                    "province": "Navarra",
                    "city": "Navascués/Nabaskoze",
                    "multiple": "0"
                },
                {
                    "zipcode": "31451",
                    "province": "Navarra",
                    "city": "Navascués/Nabaskoze",
                    "multiple": "0"
                },
                {
                    "zipcode": "31451",
                    "province": "Navarra",
                    "city": "Sarriés/Sartze",
                    "multiple": "1"
                },
                {
                    "zipcode": "31451",
                    "province": "Navarra",
                    "city": "Oronz/Orontze",
                    "multiple": "1"
                },
                {
                    "zipcode": "31451",
                    "province": "Navarra",
                    "city": "Gallués/Galoze",
                    "multiple": "1"
                },
                {
                    "zipcode": "31452",
                    "province": "Navarra",
                    "city": "Güesa/Gorza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31453",
                    "province": "Navarra",
                    "city": "Esparza de Salazar/Espartza Zaraitzu",
                    "multiple": "0"
                },
                {
                    "zipcode": "31454",
                    "province": "Navarra",
                    "city": "Navascués/Nabaskoze",
                    "multiple": "0"
                },
                {
                    "zipcode": "31454",
                    "province": "Navarra",
                    "city": "Urraul Alto",
                    "multiple": "1"
                },
                {
                    "zipcode": "31454",
                    "province": "Navarra",
                    "city": "Castillonuevo",
                    "multiple": "1"
                },
                {
                    "zipcode": "31454",
                    "province": "Navarra",
                    "city": "Romanzado",
                    "multiple": "1"
                },
                {
                    "zipcode": "31460",
                    "province": "Navarra",
                    "city": "Aibar/Oibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31460",
                    "province": "Navarra",
                    "city": "Leache/Leatxe",
                    "multiple": "1"
                },
                {
                    "zipcode": "31470",
                    "province": "Navarra",
                    "city": "Noáin (Valle de Elorz)/Noain (Elortzibar)",
                    "multiple": "0"
                },
                {
                    "zipcode": "31471",
                    "province": "Navarra",
                    "city": "Monreal/Elo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31472",
                    "province": "Navarra",
                    "city": "Ibargoiti",
                    "multiple": "0"
                },
                {
                    "zipcode": "31472",
                    "province": "Navarra",
                    "city": "Unciti",
                    "multiple": "1"
                },
                {
                    "zipcode": "31473",
                    "province": "Navarra",
                    "city": "Ibargoiti",
                    "multiple": "0"
                },
                {
                    "zipcode": "31480",
                    "province": "Navarra",
                    "city": "Urraul Bajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31481",
                    "province": "Navarra",
                    "city": "Arce/Artzi",
                    "multiple": "0"
                },
                {
                    "zipcode": "31481",
                    "province": "Navarra",
                    "city": "Lónguida/Longida",
                    "multiple": "1"
                },
                {
                    "zipcode": "31482",
                    "province": "Navarra",
                    "city": "Lizoáin-Arriasgoiti",
                    "multiple": "0"
                },
                {
                    "zipcode": "31483",
                    "province": "Navarra",
                    "city": "Lizoáin-Arriasgoiti",
                    "multiple": "0"
                },
                {
                    "zipcode": "31484",
                    "province": "Navarra",
                    "city": "Lizoáin-Arriasgoiti",
                    "multiple": "0"
                },
                {
                    "zipcode": "31485",
                    "province": "Navarra",
                    "city": "Lizoáin-Arriasgoiti",
                    "multiple": "0"
                },
                {
                    "zipcode": "31486",
                    "province": "Navarra",
                    "city": "Valle de Egüés/Eguesibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31487",
                    "province": "Navarra",
                    "city": "Liédena",
                    "multiple": "0"
                },
                {
                    "zipcode": "31490",
                    "province": "Navarra",
                    "city": "Cáseda",
                    "multiple": "0"
                },
                {
                    "zipcode": "31491",
                    "province": "Navarra",
                    "city": "Ezprogui",
                    "multiple": "0"
                },
                {
                    "zipcode": "31491",
                    "province": "Navarra",
                    "city": "Sada",
                    "multiple": "1"
                },
                {
                    "zipcode": "31492",
                    "province": "Navarra",
                    "city": "Ezprogui",
                    "multiple": "0"
                },
                {
                    "zipcode": "31493",
                    "province": "Navarra",
                    "city": "Gallipienzo/Galipentzu",
                    "multiple": "0"
                },
                {
                    "zipcode": "31494",
                    "province": "Navarra",
                    "city": "Eslava",
                    "multiple": "0"
                },
                {
                    "zipcode": "31494",
                    "province": "Navarra",
                    "city": "Leoz/Leotz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31494",
                    "province": "Navarra",
                    "city": "Lerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "31495",
                    "province": "Navarra",
                    "city": "San Martín de Unx",
                    "multiple": "0"
                },
                {
                    "zipcode": "31496",
                    "province": "Navarra",
                    "city": "Ujué",
                    "multiple": "0"
                },
                {
                    "zipcode": "31500",
                    "province": "Navarra",
                    "city": "Tudela",
                    "multiple": "0"
                },
                {
                    "zipcode": "31510",
                    "province": "Navarra",
                    "city": "Fustiñana",
                    "multiple": "0"
                },
                {
                    "zipcode": "31511",
                    "province": "Navarra",
                    "city": "Cabanillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "31512",
                    "province": "Navarra",
                    "city": "Fontellas",
                    "multiple": "0"
                },
                {
                    "zipcode": "31513",
                    "province": "Navarra",
                    "city": "Arguedas",
                    "multiple": "0"
                },
                {
                    "zipcode": "31514",
                    "province": "Navarra",
                    "city": "Valtierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "31515",
                    "province": "Navarra",
                    "city": "Cadreita",
                    "multiple": "0"
                },
                {
                    "zipcode": "31520",
                    "province": "Navarra",
                    "city": "Cascante",
                    "multiple": "0"
                },
                {
                    "zipcode": "31521",
                    "province": "Navarra",
                    "city": "Murchante",
                    "multiple": "0"
                },
                {
                    "zipcode": "31522",
                    "province": "Navarra",
                    "city": "Monteagudo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31522",
                    "province": "Navarra",
                    "city": "Tulebras",
                    "multiple": "1"
                },
                {
                    "zipcode": "31523",
                    "province": "Navarra",
                    "city": "Miranda de Arga",
                    "multiple": "0"
                },
                {
                    "zipcode": "31523",
                    "province": "Navarra",
                    "city": "Barillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "31523",
                    "province": "Navarra",
                    "city": "Ablitas",
                    "multiple": "1"
                },
                {
                    "zipcode": "31530",
                    "province": "Navarra",
                    "city": "Cortes",
                    "multiple": "0"
                },
                {
                    "zipcode": "31540",
                    "province": "Navarra",
                    "city": "Buñuel",
                    "multiple": "0"
                },
                {
                    "zipcode": "31550",
                    "province": "Navarra",
                    "city": "Ribaforada",
                    "multiple": "0"
                },
                {
                    "zipcode": "31560",
                    "province": "Navarra",
                    "city": "Azagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "31570",
                    "province": "Navarra",
                    "city": "San Adrián",
                    "multiple": "0"
                },
                {
                    "zipcode": "31579",
                    "province": "Navarra",
                    "city": "Cárcar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31580",
                    "province": "Navarra",
                    "city": "Lodosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "31587",
                    "province": "Navarra",
                    "city": "Mendavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "31588",
                    "province": "Navarra",
                    "city": "Lazagurría",
                    "multiple": "0"
                },
                {
                    "zipcode": "31589",
                    "province": "Navarra",
                    "city": "Sartaguda",
                    "multiple": "0"
                },
                {
                    "zipcode": "31590",
                    "province": "Navarra",
                    "city": "Castejón",
                    "multiple": "0"
                },
                {
                    "zipcode": "31591",
                    "province": "Navarra",
                    "city": "Corella",
                    "multiple": "0"
                },
                {
                    "zipcode": "31592",
                    "province": "Navarra",
                    "city": "Cintruénigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "31593",
                    "province": "Navarra",
                    "city": "Fitero",
                    "multiple": "0"
                },
                {
                    "zipcode": "31600",
                    "province": "Navarra",
                    "city": "Burlada/Burlata",
                    "multiple": "0"
                },
                {
                    "zipcode": "31610",
                    "province": "Navarra",
                    "city": "Villava/Atarrabia",
                    "multiple": "0"
                },
                {
                    "zipcode": "31620",
                    "province": "Navarra",
                    "city": "Valle de Egüés/Eguesibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31620",
                    "province": "Navarra",
                    "city": "Huarte/Uharte",
                    "multiple": "1"
                },
                {
                    "zipcode": "31621",
                    "province": "Navarra",
                    "city": "Valle de Egüés/Eguesibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31630",
                    "province": "Navarra",
                    "city": "Esteribar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31638",
                    "province": "Navarra",
                    "city": "Erro",
                    "multiple": "0"
                },
                {
                    "zipcode": "31638",
                    "province": "Navarra",
                    "city": "Esteribar",
                    "multiple": "1"
                },
                {
                    "zipcode": "31639",
                    "province": "Navarra",
                    "city": "Erro",
                    "multiple": "0"
                },
                {
                    "zipcode": "31639",
                    "province": "Navarra",
                    "city": "Esteribar",
                    "multiple": "1"
                },
                {
                    "zipcode": "31640",
                    "province": "Navarra",
                    "city": "Auritz/Burguete",
                    "multiple": "0"
                },
                {
                    "zipcode": "31650",
                    "province": "Navarra",
                    "city": "Orreaga/Roncesvalles",
                    "multiple": "0"
                },
                {
                    "zipcode": "31660",
                    "province": "Navarra",
                    "city": "Luzaide/Valcarlos",
                    "multiple": "0"
                },
                {
                    "zipcode": "31669",
                    "province": "Navarra",
                    "city": "Luzaide/Valcarlos",
                    "multiple": "0"
                },
                {
                    "zipcode": "31670",
                    "province": "Navarra",
                    "city": "Orbaizeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "31671",
                    "province": "Navarra",
                    "city": "Orbara",
                    "multiple": "0"
                },
                {
                    "zipcode": "31671",
                    "province": "Navarra",
                    "city": "Aribe",
                    "multiple": "1"
                },
                {
                    "zipcode": "31671",
                    "province": "Navarra",
                    "city": "Hiriberri/Villanueva de Aezkoa",
                    "multiple": "1"
                },
                {
                    "zipcode": "31671",
                    "province": "Navarra",
                    "city": "Aria",
                    "multiple": "1"
                },
                {
                    "zipcode": "31680",
                    "province": "Navarra",
                    "city": "Ochagavía/Otsagabia",
                    "multiple": "0"
                },
                {
                    "zipcode": "31689",
                    "province": "Navarra",
                    "city": "Izalzu/Itzaltzu",
                    "multiple": "0"
                },
                {
                    "zipcode": "31690",
                    "province": "Navarra",
                    "city": "Ezcároz/Ezkaroze",
                    "multiple": "0"
                },
                {
                    "zipcode": "31691",
                    "province": "Navarra",
                    "city": "Jaurrieta",
                    "multiple": "0"
                },
                {
                    "zipcode": "31692",
                    "province": "Navarra",
                    "city": "Abaurregaina/Abaurrea Alta",
                    "multiple": "0"
                },
                {
                    "zipcode": "31692",
                    "province": "Navarra",
                    "city": "Abaurrepea/Abaurrea Baja",
                    "multiple": "1"
                },
                {
                    "zipcode": "31692",
                    "province": "Navarra",
                    "city": "Garaioa",
                    "multiple": "1"
                },
                {
                    "zipcode": "31693",
                    "province": "Navarra",
                    "city": "Garralda",
                    "multiple": "0"
                },
                {
                    "zipcode": "31694",
                    "province": "Navarra",
                    "city": "Erro",
                    "multiple": "0"
                },
                {
                    "zipcode": "31695",
                    "province": "Navarra",
                    "city": "Erro",
                    "multiple": "0"
                },
                {
                    "zipcode": "31696",
                    "province": "Navarra",
                    "city": "Erro",
                    "multiple": "0"
                },
                {
                    "zipcode": "31697",
                    "province": "Navarra",
                    "city": "Erro",
                    "multiple": "0"
                },
                {
                    "zipcode": "31698",
                    "province": "Navarra",
                    "city": "Esteribar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31699",
                    "province": "Navarra",
                    "city": "Esteribar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31699",
                    "province": "Navarra",
                    "city": "Erro",
                    "multiple": "1"
                },
                {
                    "zipcode": "31700",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31710",
                    "province": "Navarra",
                    "city": "Zugarramurdi",
                    "multiple": "0"
                },
                {
                    "zipcode": "31711",
                    "province": "Navarra",
                    "city": "Urdazubi/Urdax",
                    "multiple": "0"
                },
                {
                    "zipcode": "31712",
                    "province": "Navarra",
                    "city": "Urdazubi/Urdax",
                    "multiple": "0"
                },
                {
                    "zipcode": "31713",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31714",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31715",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31720",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31720",
                    "province": "Navarra",
                    "city": "Bertizarana",
                    "multiple": "1"
                },
                {
                    "zipcode": "31730",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31740",
                    "province": "Navarra",
                    "city": "Doneztebe/Santesteban",
                    "multiple": "0"
                },
                {
                    "zipcode": "31744",
                    "province": "Navarra",
                    "city": "Elgorriaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "31745",
                    "province": "Navarra",
                    "city": "Ituren",
                    "multiple": "0"
                },
                {
                    "zipcode": "31746",
                    "province": "Navarra",
                    "city": "Zubieta",
                    "multiple": "0"
                },
                {
                    "zipcode": "31747",
                    "province": "Navarra",
                    "city": "Saldías",
                    "multiple": "0"
                },
                {
                    "zipcode": "31748",
                    "province": "Navarra",
                    "city": "Eratsun",
                    "multiple": "0"
                },
                {
                    "zipcode": "31749",
                    "province": "Navarra",
                    "city": "Ezkurra",
                    "multiple": "0"
                },
                {
                    "zipcode": "31750",
                    "province": "Navarra",
                    "city": "Donamaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "31751",
                    "province": "Navarra",
                    "city": "Oiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31752",
                    "province": "Navarra",
                    "city": "Urroz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31753",
                    "province": "Navarra",
                    "city": "Beintza-Labaien",
                    "multiple": "0"
                },
                {
                    "zipcode": "31754",
                    "province": "Navarra",
                    "city": "Arano",
                    "multiple": "0"
                },
                {
                    "zipcode": "31754",
                    "province": "Navarra",
                    "city": "Goizueta",
                    "multiple": "1"
                },
                {
                    "zipcode": "31760",
                    "province": "Navarra",
                    "city": "Etxalar",
                    "multiple": "0"
                },
                {
                    "zipcode": "31770",
                    "province": "Navarra",
                    "city": "Lesaka",
                    "multiple": "0"
                },
                {
                    "zipcode": "31780",
                    "province": "Navarra",
                    "city": "Bera",
                    "multiple": "0"
                },
                {
                    "zipcode": "31789",
                    "province": "Navarra",
                    "city": "Bera",
                    "multiple": "0"
                },
                {
                    "zipcode": "31789",
                    "province": "Navarra",
                    "city": "Lesaka",
                    "multiple": "1"
                },
                {
                    "zipcode": "31790",
                    "province": "Navarra",
                    "city": "Arantza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31790",
                    "province": "Navarra",
                    "city": "Igantzi",
                    "multiple": "1"
                },
                {
                    "zipcode": "31791",
                    "province": "Navarra",
                    "city": "Sunbilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "31792",
                    "province": "Navarra",
                    "city": "Bertizarana",
                    "multiple": "0"
                },
                {
                    "zipcode": "31793",
                    "province": "Navarra",
                    "city": "Bertizarana",
                    "multiple": "0"
                },
                {
                    "zipcode": "31794",
                    "province": "Navarra",
                    "city": "Ezkurra",
                    "multiple": "0"
                },
                {
                    "zipcode": "31794",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "1"
                },
                {
                    "zipcode": "31795",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31796",
                    "province": "Navarra",
                    "city": "Baztan",
                    "multiple": "0"
                },
                {
                    "zipcode": "31797",
                    "province": "Navarra",
                    "city": "Ultzama",
                    "multiple": "0"
                },
                {
                    "zipcode": "31798",
                    "province": "Navarra",
                    "city": "Lantz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31798",
                    "province": "Navarra",
                    "city": "Anue",
                    "multiple": "1"
                },
                {
                    "zipcode": "31799",
                    "province": "Navarra",
                    "city": "Ultzama",
                    "multiple": "0"
                },
                {
                    "zipcode": "31799",
                    "province": "Navarra",
                    "city": "Odieta",
                    "multiple": "1"
                },
                {
                    "zipcode": "31799",
                    "province": "Navarra",
                    "city": "Ezcabarte",
                    "multiple": "1"
                },
                {
                    "zipcode": "31799",
                    "province": "Navarra",
                    "city": "Olaibar",
                    "multiple": "1"
                },
                {
                    "zipcode": "31800",
                    "province": "Navarra",
                    "city": "Altsasu/Alsasua",
                    "multiple": "0"
                },
                {
                    "zipcode": "31809",
                    "province": "Navarra",
                    "city": "Olazti/Olazagutía",
                    "multiple": "0"
                },
                {
                    "zipcode": "31809",
                    "province": "Navarra",
                    "city": "Ziordia",
                    "multiple": "1"
                },
                {
                    "zipcode": "31810",
                    "province": "Navarra",
                    "city": "Urdiain",
                    "multiple": "0"
                },
                {
                    "zipcode": "31810",
                    "province": "Navarra",
                    "city": "Bakaiku",
                    "multiple": "1"
                },
                {
                    "zipcode": "31810",
                    "province": "Navarra",
                    "city": "Iturmendi",
                    "multiple": "1"
                },
                {
                    "zipcode": "31820",
                    "province": "Navarra",
                    "city": "Etxarri Aranatz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31829",
                    "province": "Navarra",
                    "city": "Ergoiena",
                    "multiple": "0"
                },
                {
                    "zipcode": "31830",
                    "province": "Navarra",
                    "city": "Lakuntza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31839",
                    "province": "Navarra",
                    "city": "Arbizu",
                    "multiple": "0"
                },
                {
                    "zipcode": "31840",
                    "province": "Navarra",
                    "city": "Uharte Arakil",
                    "multiple": "0"
                },
                {
                    "zipcode": "31840",
                    "province": "Navarra",
                    "city": "Arruazu",
                    "multiple": "1"
                },
                {
                    "zipcode": "31849",
                    "province": "Navarra",
                    "city": "Arakil",
                    "multiple": "0"
                },
                {
                    "zipcode": "31849",
                    "province": "Navarra",
                    "city": "Irañeta",
                    "multiple": "1"
                },
                {
                    "zipcode": "31850",
                    "province": "Navarra",
                    "city": "Arakil",
                    "multiple": "0"
                },
                {
                    "zipcode": "31860",
                    "province": "Navarra",
                    "city": "Irurtzun",
                    "multiple": "0"
                },
                {
                    "zipcode": "31866",
                    "province": "Navarra",
                    "city": "Basaburua",
                    "multiple": "0"
                },
                {
                    "zipcode": "31867",
                    "province": "Navarra",
                    "city": "Arakil",
                    "multiple": "0"
                },
                {
                    "zipcode": "31867",
                    "province": "Navarra",
                    "city": "Odieta",
                    "multiple": "1"
                },
                {
                    "zipcode": "31867",
                    "province": "Navarra",
                    "city": "Imotz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31867",
                    "province": "Navarra",
                    "city": "Atez/Atetz",
                    "multiple": "1"
                },
                {
                    "zipcode": "31867",
                    "province": "Navarra",
                    "city": "Iza/Itza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31868",
                    "province": "Navarra",
                    "city": "Arakil",
                    "multiple": "0"
                },
                {
                    "zipcode": "31868",
                    "province": "Navarra",
                    "city": "Iza/Itza",
                    "multiple": "1"
                },
                {
                    "zipcode": "31869",
                    "province": "Navarra",
                    "city": "Imotz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31869",
                    "province": "Navarra",
                    "city": "Basaburua",
                    "multiple": "1"
                },
                {
                    "zipcode": "31870",
                    "province": "Navarra",
                    "city": "Lekunberri",
                    "multiple": "0"
                },
                {
                    "zipcode": "31876",
                    "province": "Navarra",
                    "city": "Areso",
                    "multiple": "0"
                },
                {
                    "zipcode": "31877",
                    "province": "Navarra",
                    "city": "Larraun",
                    "multiple": "0"
                },
                {
                    "zipcode": "31878",
                    "province": "Navarra",
                    "city": "Larraun",
                    "multiple": "0"
                },
                {
                    "zipcode": "31879",
                    "province": "Navarra",
                    "city": "Larraun",
                    "multiple": "0"
                },
                {
                    "zipcode": "31880",
                    "province": "Navarra",
                    "city": "Leitza",
                    "multiple": "0"
                },
                {
                    "zipcode": "31890",
                    "province": "Navarra",
                    "city": "Betelu",
                    "multiple": "0"
                },
                {
                    "zipcode": "31891",
                    "province": "Navarra",
                    "city": "Araitz",
                    "multiple": "0"
                },
                {
                    "zipcode": "31891",
                    "province": "Navarra",
                    "city": "Larraun",
                    "multiple": "1"
                },
                {
                    "zipcode": "31892",
                    "province": "Navarra",
                    "city": "Juslapeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "31892",
                    "province": "Navarra",
                    "city": "Iza/Itza",
                    "multiple": "1"
                },
                {
                    "zipcode": "32001",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32002",
                    "province": "Ourense",
                    "city": "Barbadás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32002",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "1"
                },
                {
                    "zipcode": "32003",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32004",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32005",
                    "province": "Ourense",
                    "city": "Barbadás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32005",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "1"
                },
                {
                    "zipcode": "32005",
                    "province": "Ourense",
                    "city": "San Cibrao das Viñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "32100",
                    "province": "Ourense",
                    "city": "Coles",
                    "multiple": "0"
                },
                {
                    "zipcode": "32101",
                    "province": "Ourense",
                    "city": "Vilamarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32102",
                    "province": "Ourense",
                    "city": "Vilamarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32103",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32110",
                    "province": "Ourense",
                    "city": "Paderne de Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32111",
                    "province": "Ourense",
                    "city": "Paderne de Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32120",
                    "province": "Ourense",
                    "city": "Peroxa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32120",
                    "province": "Ourense",
                    "city": "Vilamarín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32130",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "32133",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "32134",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "32135",
                    "province": "Ourense",
                    "city": "Piñor",
                    "multiple": "0"
                },
                {
                    "zipcode": "32135",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "1"
                },
                {
                    "zipcode": "32136",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "32137",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "32137",
                    "province": "Ourense",
                    "city": "Piñor",
                    "multiple": "1"
                },
                {
                    "zipcode": "32138",
                    "province": "Ourense",
                    "city": "Piñor",
                    "multiple": "0"
                },
                {
                    "zipcode": "32139",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "0"
                },
                {
                    "zipcode": "32140",
                    "province": "Ourense",
                    "city": "Vilamarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32141",
                    "province": "Ourense",
                    "city": "Vilamarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32141",
                    "province": "Ourense",
                    "city": "San Cristovo de Cea",
                    "multiple": "1"
                },
                {
                    "zipcode": "32150",
                    "province": "Ourense",
                    "city": "Peroxa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32151",
                    "province": "Ourense",
                    "city": "Peroxa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32152",
                    "province": "Ourense",
                    "city": "Coles",
                    "multiple": "0"
                },
                {
                    "zipcode": "32153",
                    "province": "Ourense",
                    "city": "Peroxa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32153",
                    "province": "Ourense",
                    "city": "Coles",
                    "multiple": "1"
                },
                {
                    "zipcode": "32160",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32161",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32162",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32163",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32164",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32170",
                    "province": "Ourense",
                    "city": "Amoeiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32171",
                    "province": "Ourense",
                    "city": "Amoeiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32172",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32172",
                    "province": "Ourense",
                    "city": "Amoeiro",
                    "multiple": "1"
                },
                {
                    "zipcode": "32200",
                    "province": "Ourense",
                    "city": "Cortegada",
                    "multiple": "0"
                },
                {
                    "zipcode": "32211",
                    "province": "Ourense",
                    "city": "Cortegada",
                    "multiple": "0"
                },
                {
                    "zipcode": "32212",
                    "province": "Ourense",
                    "city": "Gomesende",
                    "multiple": "0"
                },
                {
                    "zipcode": "32213",
                    "province": "Ourense",
                    "city": "Cortegada",
                    "multiple": "0"
                },
                {
                    "zipcode": "32220",
                    "province": "Ourense",
                    "city": "Padrenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32226",
                    "province": "Ourense",
                    "city": "Padrenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32227",
                    "province": "Ourense",
                    "city": "Padrenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32228",
                    "province": "Ourense",
                    "city": "Padrenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32229",
                    "province": "Ourense",
                    "city": "Padrenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32235",
                    "province": "Ourense",
                    "city": "Pontedeva",
                    "multiple": "0"
                },
                {
                    "zipcode": "32236",
                    "province": "Ourense",
                    "city": "Padrenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32300",
                    "province": "Ourense",
                    "city": "Barco de Valdeorras, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32310",
                    "province": "Ourense",
                    "city": "Rubiá",
                    "multiple": "0"
                },
                {
                    "zipcode": "32311",
                    "province": "Ourense",
                    "city": "Rubiá",
                    "multiple": "0"
                },
                {
                    "zipcode": "32312",
                    "province": "Ourense",
                    "city": "Rubiá",
                    "multiple": "0"
                },
                {
                    "zipcode": "32313",
                    "province": "Ourense",
                    "city": "Barco de Valdeorras, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32314",
                    "province": "Ourense",
                    "city": "Barco de Valdeorras, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32315",
                    "province": "Ourense",
                    "city": "Barco de Valdeorras, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32315",
                    "province": "Ourense",
                    "city": "Vilamartín de Valdeorras",
                    "multiple": "1"
                },
                {
                    "zipcode": "32316",
                    "province": "Ourense",
                    "city": "Vilamartín de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32317",
                    "province": "Ourense",
                    "city": "Barco de Valdeorras, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32318",
                    "province": "Ourense",
                    "city": "Barco de Valdeorras, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32320",
                    "province": "Ourense",
                    "city": "Rubiá",
                    "multiple": "0"
                },
                {
                    "zipcode": "32329",
                    "province": "Ourense",
                    "city": "Rubiá",
                    "multiple": "0"
                },
                {
                    "zipcode": "32329",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "1"
                },
                {
                    "zipcode": "32330",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32334",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32335",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32336",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32337",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32338",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32339",
                    "province": "Ourense",
                    "city": "Carballeda de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32340",
                    "province": "Ourense",
                    "city": "Vilamartín de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32348",
                    "province": "Ourense",
                    "city": "Vilamartín de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32349",
                    "province": "Ourense",
                    "city": "Vilamartín de Valdeorras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32350",
                    "province": "Ourense",
                    "city": "Rúa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32356",
                    "province": "Ourense",
                    "city": "Petín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32357",
                    "province": "Ourense",
                    "city": "Larouco",
                    "multiple": "0"
                },
                {
                    "zipcode": "32357",
                    "province": "Ourense",
                    "city": "Petín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32357",
                    "province": "Ourense",
                    "city": "Bolo, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "32357",
                    "province": "Ourense",
                    "city": "Rúa, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32357",
                    "province": "Ourense",
                    "city": "Veiga, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32358",
                    "province": "Ourense",
                    "city": "Larouco",
                    "multiple": "0"
                },
                {
                    "zipcode": "32359",
                    "province": "Ourense",
                    "city": "Rúa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32360",
                    "province": "Ourense",
                    "city": "Veiga, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32365",
                    "province": "Ourense",
                    "city": "Veiga, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32366",
                    "province": "Ourense",
                    "city": "Veiga, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32367",
                    "province": "Ourense",
                    "city": "Veiga, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32368",
                    "province": "Ourense",
                    "city": "Veiga, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32369",
                    "province": "Ourense",
                    "city": "Veiga, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32370",
                    "province": "Ourense",
                    "city": "Larouco",
                    "multiple": "0"
                },
                {
                    "zipcode": "32370",
                    "province": "Ourense",
                    "city": "Petín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32371",
                    "province": "Ourense",
                    "city": "Larouco",
                    "multiple": "0"
                },
                {
                    "zipcode": "32371",
                    "province": "Ourense",
                    "city": "Petín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32372",
                    "province": "Ourense",
                    "city": "Bolo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32373",
                    "province": "Ourense",
                    "city": "Bolo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32374",
                    "province": "Ourense",
                    "city": "Bolo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32375",
                    "province": "Ourense",
                    "city": "Bolo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32375",
                    "province": "Ourense",
                    "city": "Manzaneda",
                    "multiple": "1"
                },
                {
                    "zipcode": "32400",
                    "province": "Ourense",
                    "city": "Ribadavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32410",
                    "province": "Ourense",
                    "city": "Melón",
                    "multiple": "0"
                },
                {
                    "zipcode": "32411",
                    "province": "Ourense",
                    "city": "Melón",
                    "multiple": "0"
                },
                {
                    "zipcode": "32412",
                    "province": "Ourense",
                    "city": "Carballeda de Avia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32413",
                    "province": "Ourense",
                    "city": "Carballeda de Avia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32414",
                    "province": "Ourense",
                    "city": "Ribadavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32415",
                    "province": "Ourense",
                    "city": "Ribadavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32416",
                    "province": "Ourense",
                    "city": "Carballeda de Avia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32416",
                    "province": "Ourense",
                    "city": "Ribadavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "32417",
                    "province": "Ourense",
                    "city": "Ribadavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32417",
                    "province": "Ourense",
                    "city": "Arnoia, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32417",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32418",
                    "province": "Ourense",
                    "city": "Ribadavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32420",
                    "province": "Ourense",
                    "city": "Leiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32425",
                    "province": "Ourense",
                    "city": "Leiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32426",
                    "province": "Ourense",
                    "city": "Leiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32427",
                    "province": "Ourense",
                    "city": "Leiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32428",
                    "province": "Ourense",
                    "city": "Leiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32429",
                    "province": "Ourense",
                    "city": "Leiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32430",
                    "province": "Ourense",
                    "city": "Castrelo de Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "32431",
                    "province": "Ourense",
                    "city": "Beade",
                    "multiple": "0"
                },
                {
                    "zipcode": "32432",
                    "province": "Ourense",
                    "city": "Beade",
                    "multiple": "0"
                },
                {
                    "zipcode": "32433",
                    "province": "Ourense",
                    "city": "Carballeda de Avia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32434",
                    "province": "Ourense",
                    "city": "Carballeda de Avia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32440",
                    "province": "Ourense",
                    "city": "Peroxa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32448",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32449",
                    "province": "Ourense",
                    "city": "Peroxa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32450",
                    "province": "Ourense",
                    "city": "Cenlle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32452",
                    "province": "Ourense",
                    "city": "Punxín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32453",
                    "province": "Ourense",
                    "city": "San Amaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32454",
                    "province": "Ourense",
                    "city": "Cenlle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32455",
                    "province": "Ourense",
                    "city": "San Amaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32456",
                    "province": "Ourense",
                    "city": "Punxín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32456",
                    "province": "Ourense",
                    "city": "Gudiña, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32457",
                    "province": "Ourense",
                    "city": "Punxín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32459",
                    "province": "Ourense",
                    "city": "Cenlle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32499",
                    "province": "Ourense",
                    "city": "Mezquita, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32500",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32510",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32511",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32512",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32513",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32514",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32515",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32515",
                    "province": "Ourense",
                    "city": "San Amaro",
                    "multiple": "1"
                },
                {
                    "zipcode": "32516",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32520",
                    "province": "Ourense",
                    "city": "Avión",
                    "multiple": "0"
                },
                {
                    "zipcode": "32520",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "1"
                },
                {
                    "zipcode": "32520",
                    "province": "Ourense",
                    "city": "Beariz",
                    "multiple": "1"
                },
                {
                    "zipcode": "32521",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32522",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32523",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32524",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32525",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32526",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32526",
                    "province": "Ourense",
                    "city": "Irixo, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "32530",
                    "province": "Ourense",
                    "city": "Irixo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32530",
                    "province": "Ourense",
                    "city": "Boborás",
                    "multiple": "1"
                },
                {
                    "zipcode": "32534",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32535",
                    "province": "Ourense",
                    "city": "Irixo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32536",
                    "province": "Ourense",
                    "city": "Irixo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32537",
                    "province": "Ourense",
                    "city": "Irixo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32538",
                    "province": "Ourense",
                    "city": "Irixo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32539",
                    "province": "Ourense",
                    "city": "Irixo, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32540",
                    "province": "Ourense",
                    "city": "Gudiña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32545",
                    "province": "Ourense",
                    "city": "Gudiña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32546",
                    "province": "Ourense",
                    "city": "Gudiña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32547",
                    "province": "Ourense",
                    "city": "Gudiña, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32547",
                    "province": "Ourense",
                    "city": "Vilariño de Conso",
                    "multiple": "1"
                },
                {
                    "zipcode": "32548",
                    "province": "Ourense",
                    "city": "Mezquita, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32549",
                    "province": "Ourense",
                    "city": "Mezquita, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32550",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32554",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32555",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32556",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32557",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32557",
                    "province": "Ourense",
                    "city": "Vilariño de Conso",
                    "multiple": "1"
                },
                {
                    "zipcode": "32558",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32560",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32562",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32563",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32570",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "0"
                },
                {
                    "zipcode": "32572",
                    "province": "Ourense",
                    "city": "Punxín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32572",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "1"
                },
                {
                    "zipcode": "32574",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "0"
                },
                {
                    "zipcode": "32574",
                    "province": "Ourense",
                    "city": "Carballiño, O",
                    "multiple": "1"
                },
                {
                    "zipcode": "32575",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "0"
                },
                {
                    "zipcode": "32576",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "0"
                },
                {
                    "zipcode": "32577",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "0"
                },
                {
                    "zipcode": "32578",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "0"
                },
                {
                    "zipcode": "32578",
                    "province": "Ourense",
                    "city": "Punxín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32579",
                    "province": "Ourense",
                    "city": "Maside",
                    "multiple": "0"
                },
                {
                    "zipcode": "32590",
                    "province": "Ourense",
                    "city": "Mezquita, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32591",
                    "province": "Ourense",
                    "city": "Castrelo do Val",
                    "multiple": "0"
                },
                {
                    "zipcode": "32591",
                    "province": "Ourense",
                    "city": "Vilariño de Conso",
                    "multiple": "1"
                },
                {
                    "zipcode": "32592",
                    "province": "Ourense",
                    "city": "Paderne de Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32592",
                    "province": "Ourense",
                    "city": "Baños de Molgas",
                    "multiple": "1"
                },
                {
                    "zipcode": "32593",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32600",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32610",
                    "province": "Ourense",
                    "city": "Vilardevós",
                    "multiple": "0"
                },
                {
                    "zipcode": "32610",
                    "province": "Ourense",
                    "city": "Riós",
                    "multiple": "1"
                },
                {
                    "zipcode": "32611",
                    "province": "Ourense",
                    "city": "Riós",
                    "multiple": "0"
                },
                {
                    "zipcode": "32611",
                    "province": "Ourense",
                    "city": "Monterrei",
                    "multiple": "1"
                },
                {
                    "zipcode": "32612",
                    "province": "Ourense",
                    "city": "Riós",
                    "multiple": "0"
                },
                {
                    "zipcode": "32612",
                    "province": "Ourense",
                    "city": "Castrelo do Val",
                    "multiple": "1"
                },
                {
                    "zipcode": "32613",
                    "province": "Ourense",
                    "city": "Oímbra",
                    "multiple": "0"
                },
                {
                    "zipcode": "32614",
                    "province": "Ourense",
                    "city": "Vilardevós",
                    "multiple": "0"
                },
                {
                    "zipcode": "32615",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32616",
                    "province": "Ourense",
                    "city": "Vilardevós",
                    "multiple": "0"
                },
                {
                    "zipcode": "32617",
                    "province": "Ourense",
                    "city": "Vilardevós",
                    "multiple": "0"
                },
                {
                    "zipcode": "32618",
                    "province": "Ourense",
                    "city": "Monterrei",
                    "multiple": "0"
                },
                {
                    "zipcode": "32619",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32620",
                    "province": "Ourense",
                    "city": "Laza",
                    "multiple": "0"
                },
                {
                    "zipcode": "32621",
                    "province": "Ourense",
                    "city": "Laza",
                    "multiple": "0"
                },
                {
                    "zipcode": "32622",
                    "province": "Ourense",
                    "city": "Laza",
                    "multiple": "0"
                },
                {
                    "zipcode": "32623",
                    "province": "Ourense",
                    "city": "Laza",
                    "multiple": "0"
                },
                {
                    "zipcode": "32624",
                    "province": "Ourense",
                    "city": "Castrelo do Val",
                    "multiple": "0"
                },
                {
                    "zipcode": "32624",
                    "province": "Ourense",
                    "city": "Monterrei",
                    "multiple": "1"
                },
                {
                    "zipcode": "32625",
                    "province": "Ourense",
                    "city": "Castrelo do Val",
                    "multiple": "0"
                },
                {
                    "zipcode": "32626",
                    "province": "Ourense",
                    "city": "Castrelo do Val",
                    "multiple": "0"
                },
                {
                    "zipcode": "32630",
                    "province": "Ourense",
                    "city": "Xinzo de Limia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32631",
                    "province": "Ourense",
                    "city": "Xinzo de Limia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32631",
                    "province": "Ourense",
                    "city": "Trasmiras",
                    "multiple": "1"
                },
                {
                    "zipcode": "32631",
                    "province": "Ourense",
                    "city": "Sarreaus",
                    "multiple": "1"
                },
                {
                    "zipcode": "32632",
                    "province": "Ourense",
                    "city": "Blancos, Os",
                    "multiple": "0"
                },
                {
                    "zipcode": "32632",
                    "province": "Ourense",
                    "city": "Xinzo de Limia",
                    "multiple": "1"
                },
                {
                    "zipcode": "32632",
                    "province": "Ourense",
                    "city": "Baltar",
                    "multiple": "1"
                },
                {
                    "zipcode": "32633",
                    "province": "Ourense",
                    "city": "Baltar",
                    "multiple": "0"
                },
                {
                    "zipcode": "32634",
                    "province": "Ourense",
                    "city": "Blancos, Os",
                    "multiple": "0"
                },
                {
                    "zipcode": "32635",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32635",
                    "province": "Ourense",
                    "city": "Xinzo de Limia",
                    "multiple": "1"
                },
                {
                    "zipcode": "32636",
                    "province": "Ourense",
                    "city": "Sarreaus",
                    "multiple": "0"
                },
                {
                    "zipcode": "32636",
                    "province": "Ourense",
                    "city": "Xinzo de Limia",
                    "multiple": "1"
                },
                {
                    "zipcode": "32637",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32637",
                    "province": "Ourense",
                    "city": "Xinzo de Limia",
                    "multiple": "1"
                },
                {
                    "zipcode": "32640",
                    "province": "Ourense",
                    "city": "Xinzo de Limia",
                    "multiple": "0"
                },
                {
                    "zipcode": "32641",
                    "province": "Ourense",
                    "city": "Porqueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32643",
                    "province": "Ourense",
                    "city": "Porqueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32644",
                    "province": "Ourense",
                    "city": "Calvos de Randín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32644",
                    "province": "Ourense",
                    "city": "Porqueira",
                    "multiple": "1"
                },
                {
                    "zipcode": "32645",
                    "province": "Ourense",
                    "city": "Porqueira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32645",
                    "province": "Ourense",
                    "city": "Calvos de Randín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32646",
                    "province": "Ourense",
                    "city": "Baltar",
                    "multiple": "0"
                },
                {
                    "zipcode": "32646",
                    "province": "Ourense",
                    "city": "Calvos de Randín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32647",
                    "province": "Ourense",
                    "city": "Calvos de Randín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32648",
                    "province": "Ourense",
                    "city": "Calvos de Randín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32650",
                    "province": "Ourense",
                    "city": "Vilar de Santos",
                    "multiple": "0"
                },
                {
                    "zipcode": "32651",
                    "province": "Ourense",
                    "city": "Vilar de Santos",
                    "multiple": "0"
                },
                {
                    "zipcode": "32652",
                    "province": "Ourense",
                    "city": "Rairiz de Veiga",
                    "multiple": "0"
                },
                {
                    "zipcode": "32653",
                    "province": "Ourense",
                    "city": "Rairiz de Veiga",
                    "multiple": "0"
                },
                {
                    "zipcode": "32654",
                    "province": "Ourense",
                    "city": "Rairiz de Veiga",
                    "multiple": "0"
                },
                {
                    "zipcode": "32655",
                    "province": "Ourense",
                    "city": "Rairiz de Veiga",
                    "multiple": "0"
                },
                {
                    "zipcode": "32660",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32664",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32665",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32666",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32667",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32668",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32669",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "32670",
                    "province": "Ourense",
                    "city": "Xunqueira de Ambía",
                    "multiple": "0"
                },
                {
                    "zipcode": "32678",
                    "province": "Ourense",
                    "city": "Xunqueira de Ambía",
                    "multiple": "0"
                },
                {
                    "zipcode": "32679",
                    "province": "Ourense",
                    "city": "Xunqueira de Ambía",
                    "multiple": "0"
                },
                {
                    "zipcode": "32680",
                    "province": "Ourense",
                    "city": "Cualedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32688",
                    "province": "Ourense",
                    "city": "Cualedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32689",
                    "province": "Ourense",
                    "city": "Cualedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "32690",
                    "province": "Ourense",
                    "city": "Taboadela",
                    "multiple": "0"
                },
                {
                    "zipcode": "32690",
                    "province": "Ourense",
                    "city": "Allariz",
                    "multiple": "1"
                },
                {
                    "zipcode": "32691",
                    "province": "Ourense",
                    "city": "Taboadela",
                    "multiple": "0"
                },
                {
                    "zipcode": "32692",
                    "province": "Ourense",
                    "city": "Sandiás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32693",
                    "province": "Ourense",
                    "city": "Sandiás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32694",
                    "province": "Ourense",
                    "city": "Trasmiras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32695",
                    "province": "Ourense",
                    "city": "Trasmiras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32696",
                    "province": "Ourense",
                    "city": "Trasmiras",
                    "multiple": "0"
                },
                {
                    "zipcode": "32696",
                    "province": "Ourense",
                    "city": "Sarreaus",
                    "multiple": "1"
                },
                {
                    "zipcode": "32697",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32698",
                    "province": "Ourense",
                    "city": "Oímbra",
                    "multiple": "0"
                },
                {
                    "zipcode": "32698",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32699",
                    "province": "Ourense",
                    "city": "Verín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32700",
                    "province": "Ourense",
                    "city": "Maceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32701",
                    "province": "Ourense",
                    "city": "Baños de Molgas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32702",
                    "province": "Ourense",
                    "city": "Vilar de Barrio",
                    "multiple": "0"
                },
                {
                    "zipcode": "32702",
                    "province": "Ourense",
                    "city": "Baños de Molgas",
                    "multiple": "1"
                },
                {
                    "zipcode": "32703",
                    "province": "Ourense",
                    "city": "Maceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32704",
                    "province": "Ourense",
                    "city": "Baños de Molgas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32705",
                    "province": "Ourense",
                    "city": "Baños de Molgas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32705",
                    "province": "Ourense",
                    "city": "Vilar de Barrio",
                    "multiple": "1"
                },
                {
                    "zipcode": "32706",
                    "province": "Ourense",
                    "city": "Maceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32707",
                    "province": "Ourense",
                    "city": "Maceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32708",
                    "province": "Ourense",
                    "city": "Xunqueira de Espadanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32708",
                    "province": "Ourense",
                    "city": "Maceda",
                    "multiple": "1"
                },
                {
                    "zipcode": "32710",
                    "province": "Ourense",
                    "city": "Pereiro de Aguiar, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32711",
                    "province": "Ourense",
                    "city": "Pereiro de Aguiar, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32720",
                    "province": "Ourense",
                    "city": "Maceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32720",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "1"
                },
                {
                    "zipcode": "32720",
                    "province": "Ourense",
                    "city": "Esgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "32720",
                    "province": "Ourense",
                    "city": "Xunqueira de Espadanedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "32730",
                    "province": "Ourense",
                    "city": "Xunqueira de Espadanedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32740",
                    "province": "Ourense",
                    "city": "Parada de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "32746",
                    "province": "Ourense",
                    "city": "Parada de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "32747",
                    "province": "Ourense",
                    "city": "Parada de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "32748",
                    "province": "Ourense",
                    "city": "Parada de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "32749",
                    "province": "Ourense",
                    "city": "Parada de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "32750",
                    "province": "Ourense",
                    "city": "Montederramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32751",
                    "province": "Ourense",
                    "city": "Montederramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32760",
                    "province": "Ourense",
                    "city": "Castro Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32764",
                    "province": "Ourense",
                    "city": "Teixeira, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32764",
                    "province": "Ourense",
                    "city": "Castro Caldelas",
                    "multiple": "1"
                },
                {
                    "zipcode": "32765",
                    "province": "Ourense",
                    "city": "Teixeira, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32766",
                    "province": "Ourense",
                    "city": "Castro Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32767",
                    "province": "Ourense",
                    "city": "Chandrexa de Queixa",
                    "multiple": "0"
                },
                {
                    "zipcode": "32768",
                    "province": "Ourense",
                    "city": "Castro Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32768",
                    "province": "Ourense",
                    "city": "Chandrexa de Queixa",
                    "multiple": "1"
                },
                {
                    "zipcode": "32769",
                    "province": "Ourense",
                    "city": "Montederramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32769",
                    "province": "Ourense",
                    "city": "Castro Caldelas",
                    "multiple": "1"
                },
                {
                    "zipcode": "32770",
                    "province": "Ourense",
                    "city": "San Xoán de Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "32774",
                    "province": "Ourense",
                    "city": "Castro Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32779",
                    "province": "Ourense",
                    "city": "San Xoán de Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "32780",
                    "province": "Ourense",
                    "city": "Pobra de Trives, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32781",
                    "province": "Ourense",
                    "city": "Manzaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32782",
                    "province": "Ourense",
                    "city": "Manzaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32782",
                    "province": "Ourense",
                    "city": "Pobra de Trives, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32783",
                    "province": "Ourense",
                    "city": "Manzaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32784",
                    "province": "Ourense",
                    "city": "Manzaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32785",
                    "province": "Ourense",
                    "city": "Manzaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32786",
                    "province": "Ourense",
                    "city": "Chandrexa de Queixa",
                    "multiple": "0"
                },
                {
                    "zipcode": "32787",
                    "province": "Ourense",
                    "city": "Chandrexa de Queixa",
                    "multiple": "0"
                },
                {
                    "zipcode": "32787",
                    "province": "Ourense",
                    "city": "Pobra de Trives, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32788",
                    "province": "Ourense",
                    "city": "Pobra de Trives, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32789",
                    "province": "Ourense",
                    "city": "Viana do Bolo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32789",
                    "province": "Ourense",
                    "city": "Pobra de Trives, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32790",
                    "province": "Ourense",
                    "city": "Maceda",
                    "multiple": "0"
                },
                {
                    "zipcode": "32790",
                    "province": "Ourense",
                    "city": "Montederramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "32790",
                    "province": "Ourense",
                    "city": "Parada de Sil",
                    "multiple": "1"
                },
                {
                    "zipcode": "32791",
                    "province": "Ourense",
                    "city": "Nogueira de Ramuín",
                    "multiple": "0"
                },
                {
                    "zipcode": "32792",
                    "province": "Ourense",
                    "city": "Pereiro de Aguiar, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32793",
                    "province": "Ourense",
                    "city": "Pereiro de Aguiar, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "32793",
                    "province": "Ourense",
                    "city": "Esgos",
                    "multiple": "1"
                },
                {
                    "zipcode": "32794",
                    "province": "Ourense",
                    "city": "Castro Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32794",
                    "province": "Ourense",
                    "city": "San Xoán de Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "32794",
                    "province": "Ourense",
                    "city": "Chandrexa de Queixa",
                    "multiple": "1"
                },
                {
                    "zipcode": "32800",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32810",
                    "province": "Ourense",
                    "city": "Ramirás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32811",
                    "province": "Ourense",
                    "city": "Ramirás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32812",
                    "province": "Ourense",
                    "city": "Bola, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32812",
                    "province": "Ourense",
                    "city": "Ribadavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "32813",
                    "province": "Ourense",
                    "city": "Verea",
                    "multiple": "0"
                },
                {
                    "zipcode": "32814",
                    "province": "Ourense",
                    "city": "Quintela de Leirado",
                    "multiple": "0"
                },
                {
                    "zipcode": "32815",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32815",
                    "province": "Ourense",
                    "city": "Ramirás",
                    "multiple": "1"
                },
                {
                    "zipcode": "32816",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32816",
                    "province": "Ourense",
                    "city": "Merca, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "32817",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32820",
                    "province": "Ourense",
                    "city": "Cartelle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32821",
                    "province": "Ourense",
                    "city": "Cartelle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32822",
                    "province": "Ourense",
                    "city": "Cartelle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32823",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32823",
                    "province": "Ourense",
                    "city": "Cartelle",
                    "multiple": "1"
                },
                {
                    "zipcode": "32824",
                    "province": "Ourense",
                    "city": "Cartelle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32825",
                    "province": "Ourense",
                    "city": "Cartelle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32826",
                    "province": "Ourense",
                    "city": "Cartelle",
                    "multiple": "0"
                },
                {
                    "zipcode": "32827",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32828",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32829",
                    "province": "Ourense",
                    "city": "Celanova",
                    "multiple": "0"
                },
                {
                    "zipcode": "32830",
                    "province": "Ourense",
                    "city": "Merca, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32838",
                    "province": "Ourense",
                    "city": "Merca, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32839",
                    "province": "Ourense",
                    "city": "Merca, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "32840",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "0"
                },
                {
                    "zipcode": "32845",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "0"
                },
                {
                    "zipcode": "32846",
                    "province": "Ourense",
                    "city": "Lobeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32846",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "1"
                },
                {
                    "zipcode": "32847",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "0"
                },
                {
                    "zipcode": "32848",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "0"
                },
                {
                    "zipcode": "32849",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "0"
                },
                {
                    "zipcode": "32850",
                    "province": "Ourense",
                    "city": "Lobeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32857",
                    "province": "Ourense",
                    "city": "Lobeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32858",
                    "province": "Ourense",
                    "city": "Lobeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32859",
                    "province": "Ourense",
                    "city": "Lobeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32859",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "1"
                },
                {
                    "zipcode": "32860",
                    "province": "Ourense",
                    "city": "Entrimo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32867",
                    "province": "Ourense",
                    "city": "Entrimo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32868",
                    "province": "Ourense",
                    "city": "Entrimo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32869",
                    "province": "Ourense",
                    "city": "Entrimo",
                    "multiple": "0"
                },
                {
                    "zipcode": "32869",
                    "province": "Ourense",
                    "city": "Lobios",
                    "multiple": "1"
                },
                {
                    "zipcode": "32870",
                    "province": "Ourense",
                    "city": "Lobios",
                    "multiple": "0"
                },
                {
                    "zipcode": "32879",
                    "province": "Ourense",
                    "city": "Lobios",
                    "multiple": "0"
                },
                {
                    "zipcode": "32880",
                    "province": "Ourense",
                    "city": "Muíños",
                    "multiple": "0"
                },
                {
                    "zipcode": "32890",
                    "province": "Ourense",
                    "city": "Barbadás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32891",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "0"
                },
                {
                    "zipcode": "32892",
                    "province": "Ourense",
                    "city": "Lobios",
                    "multiple": "0"
                },
                {
                    "zipcode": "32893",
                    "province": "Ourense",
                    "city": "Parada de Sil",
                    "multiple": "0"
                },
                {
                    "zipcode": "32893",
                    "province": "Ourense",
                    "city": "Lobios",
                    "multiple": "1"
                },
                {
                    "zipcode": "32894",
                    "province": "Ourense",
                    "city": "Lobios",
                    "multiple": "0"
                },
                {
                    "zipcode": "32895",
                    "province": "Ourense",
                    "city": "Lobios",
                    "multiple": "0"
                },
                {
                    "zipcode": "32896",
                    "province": "Ourense",
                    "city": "Muíños",
                    "multiple": "0"
                },
                {
                    "zipcode": "32897",
                    "province": "Ourense",
                    "city": "Lobeira",
                    "multiple": "0"
                },
                {
                    "zipcode": "32898",
                    "province": "Ourense",
                    "city": "Muíños",
                    "multiple": "0"
                },
                {
                    "zipcode": "32899",
                    "province": "Ourense",
                    "city": "Muíños",
                    "multiple": "0"
                },
                {
                    "zipcode": "32900",
                    "province": "Ourense",
                    "city": "San Cibrao das Viñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32900",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "1"
                },
                {
                    "zipcode": "32901",
                    "province": "Ourense",
                    "city": "San Cibrao das Viñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32910",
                    "province": "Ourense",
                    "city": "San Cibrao das Viñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32911",
                    "province": "Ourense",
                    "city": "San Cibrao das Viñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "32920",
                    "province": "Ourense",
                    "city": "Barbadás",
                    "multiple": "0"
                },
                {
                    "zipcode": "32920",
                    "province": "Ourense",
                    "city": "Toén",
                    "multiple": "1"
                },
                {
                    "zipcode": "32930",
                    "province": "Ourense",
                    "city": "Toén",
                    "multiple": "0"
                },
                {
                    "zipcode": "32930",
                    "province": "Ourense",
                    "city": "Barbadás",
                    "multiple": "1"
                },
                {
                    "zipcode": "32940",
                    "province": "Ourense",
                    "city": "Toén",
                    "multiple": "0"
                },
                {
                    "zipcode": "32940",
                    "province": "Ourense",
                    "city": "Barbadás",
                    "multiple": "1"
                },
                {
                    "zipcode": "32940",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "1"
                },
                {
                    "zipcode": "32941",
                    "province": "Ourense",
                    "city": "Toén",
                    "multiple": "0"
                },
                {
                    "zipcode": "32946",
                    "province": "Ourense",
                    "city": "Bande",
                    "multiple": "0"
                },
                {
                    "zipcode": "32950",
                    "province": "Ourense",
                    "city": "Coles",
                    "multiple": "0"
                },
                {
                    "zipcode": "32960",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32970",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32971",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32980",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32980",
                    "province": "Ourense",
                    "city": "Amoeiro",
                    "multiple": "1"
                },
                {
                    "zipcode": "32981",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "32990",
                    "province": "Ourense",
                    "city": "Ourense",
                    "multiple": "0"
                },
                {
                    "zipcode": "33001",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33002",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33003",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33004",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33005",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33006",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33007",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33008",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33009",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33010",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33010",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "1"
                },
                {
                    "zipcode": "33011",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33012",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33013",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33060",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33069",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33100",
                    "province": "Asturias",
                    "city": "Trubia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33110",
                    "province": "Asturias",
                    "city": "Teverga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33111",
                    "province": "Asturias",
                    "city": "Teverga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33112",
                    "province": "Asturias",
                    "city": "Proaza",
                    "multiple": "0"
                },
                {
                    "zipcode": "33114",
                    "province": "Asturias",
                    "city": "Proaza",
                    "multiple": "0"
                },
                {
                    "zipcode": "33115",
                    "province": "Asturias",
                    "city": "Santo Adriano",
                    "multiple": "0"
                },
                {
                    "zipcode": "33115",
                    "province": "Asturias",
                    "city": "Quirós",
                    "multiple": "1"
                },
                {
                    "zipcode": "33116",
                    "province": "Asturias",
                    "city": "Quirós",
                    "multiple": "0"
                },
                {
                    "zipcode": "33116",
                    "province": "Asturias",
                    "city": "Proaza",
                    "multiple": "1"
                },
                {
                    "zipcode": "33117",
                    "province": "Asturias",
                    "city": "Quirós",
                    "multiple": "0"
                },
                {
                    "zipcode": "33118",
                    "province": "Asturias",
                    "city": "Quirós",
                    "multiple": "0"
                },
                {
                    "zipcode": "33119",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33119",
                    "province": "Asturias",
                    "city": "Grado",
                    "multiple": "1"
                },
                {
                    "zipcode": "33120",
                    "province": "Asturias",
                    "city": "Pravia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33125",
                    "province": "Asturias",
                    "city": "Soto del Barco",
                    "multiple": "0"
                },
                {
                    "zipcode": "33126",
                    "province": "Asturias",
                    "city": "Soto del Barco",
                    "multiple": "0"
                },
                {
                    "zipcode": "33127",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33127",
                    "province": "Asturias",
                    "city": "Pravia",
                    "multiple": "1"
                },
                {
                    "zipcode": "33127",
                    "province": "Asturias",
                    "city": "Soto del Barco",
                    "multiple": "1"
                },
                {
                    "zipcode": "33128",
                    "province": "Asturias",
                    "city": "Pravia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33129",
                    "province": "Asturias",
                    "city": "Pravia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33130",
                    "province": "Asturias",
                    "city": "Muros de Nalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33138",
                    "province": "Asturias",
                    "city": "Muros de Nalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33140",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33150",
                    "province": "Asturias",
                    "city": "Cudillero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33155",
                    "province": "Asturias",
                    "city": "Cudillero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33156",
                    "province": "Asturias",
                    "city": "Cudillero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33157",
                    "province": "Asturias",
                    "city": "Cudillero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33159",
                    "province": "Asturias",
                    "city": "Cudillero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33160",
                    "province": "Asturias",
                    "city": "Riosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33161",
                    "province": "Asturias",
                    "city": "Morcín",
                    "multiple": "0"
                },
                {
                    "zipcode": "33162",
                    "province": "Asturias",
                    "city": "Morcín",
                    "multiple": "0"
                },
                {
                    "zipcode": "33163",
                    "province": "Asturias",
                    "city": "Morcín",
                    "multiple": "0"
                },
                {
                    "zipcode": "33170",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33170",
                    "province": "Asturias",
                    "city": "Ribera de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "33171",
                    "province": "Asturias",
                    "city": "Ribera de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "33171",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "33172",
                    "province": "Asturias",
                    "city": "Ribera de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "33173",
                    "province": "Asturias",
                    "city": "Ribera de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "33174",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33180",
                    "province": "Asturias",
                    "city": "Noreña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33186",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33187",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33188",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33189",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33190",
                    "province": "Asturias",
                    "city": "Regueras, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "33191",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33191",
                    "province": "Asturias",
                    "city": "Regueras, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "33192",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33192",
                    "province": "Asturias",
                    "city": "Regueras, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "33192",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "1"
                },
                {
                    "zipcode": "33193",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33194",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33195",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33196",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33199",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33199",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "1"
                },
                {
                    "zipcode": "33201",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33202",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33203",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33204",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33205",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33206",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33207",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33208",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33209",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33210",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33211",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33212",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33213",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33290",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33299",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33300",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33310",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33310",
                    "province": "Asturias",
                    "city": "Cabranes",
                    "multiple": "1"
                },
                {
                    "zipcode": "33311",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33312",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33313",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33314",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33315",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33316",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33317",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33318",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33320",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33324",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33325",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33326",
                    "province": "Asturias",
                    "city": "Villaviciosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "33326",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "1"
                },
                {
                    "zipcode": "33327",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33328",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33329",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33330",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33340",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33341",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33342",
                    "province": "Asturias",
                    "city": "Colunga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33343",
                    "province": "Asturias",
                    "city": "Caravia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33344",
                    "province": "Asturias",
                    "city": "Caravia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33345",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33346",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33347",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33350",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33390",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33391",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33392",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33393",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33394",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33401",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33401",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "1"
                },
                {
                    "zipcode": "33402",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33403",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33404",
                    "province": "Asturias",
                    "city": "Corvera de Asturias",
                    "multiple": "0"
                },
                {
                    "zipcode": "33405",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33410",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33410",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "1"
                },
                {
                    "zipcode": "33411",
                    "province": "Asturias",
                    "city": "Illas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33412",
                    "province": "Asturias",
                    "city": "Corvera de Asturias",
                    "multiple": "0"
                },
                {
                    "zipcode": "33412",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "1"
                },
                {
                    "zipcode": "33412",
                    "province": "Asturias",
                    "city": "Illas",
                    "multiple": "1"
                },
                {
                    "zipcode": "33414",
                    "province": "Asturias",
                    "city": "Illas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33414",
                    "province": "Asturias",
                    "city": "Regueras, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "33414",
                    "province": "Asturias",
                    "city": "Candamo",
                    "multiple": "1"
                },
                {
                    "zipcode": "33416",
                    "province": "Asturias",
                    "city": "Corvera de Asturias",
                    "multiple": "0"
                },
                {
                    "zipcode": "33417",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33417",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "1"
                },
                {
                    "zipcode": "33418",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33418",
                    "province": "Asturias",
                    "city": "Gozón",
                    "multiple": "1"
                },
                {
                    "zipcode": "33419",
                    "province": "Asturias",
                    "city": "Gozón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33420",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33423",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33424",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33425",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33426",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33427",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33428",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33429",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33430",
                    "province": "Asturias",
                    "city": "Carreño",
                    "multiple": "0"
                },
                {
                    "zipcode": "33438",
                    "province": "Asturias",
                    "city": "Carreño",
                    "multiple": "0"
                },
                {
                    "zipcode": "33439",
                    "province": "Asturias",
                    "city": "Carreño",
                    "multiple": "0"
                },
                {
                    "zipcode": "33440",
                    "province": "Asturias",
                    "city": "Gozón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33448",
                    "province": "Asturias",
                    "city": "Gozón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33449",
                    "province": "Asturias",
                    "city": "Gozón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33450",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33456",
                    "province": "Asturias",
                    "city": "Candamo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33456",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "1"
                },
                {
                    "zipcode": "33457",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33458",
                    "province": "Asturias",
                    "city": "Soto del Barco",
                    "multiple": "0"
                },
                {
                    "zipcode": "33459",
                    "province": "Asturias",
                    "city": "Castrillón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33460",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33468",
                    "province": "Asturias",
                    "city": "Corvera de Asturias",
                    "multiple": "0"
                },
                {
                    "zipcode": "33468",
                    "province": "Asturias",
                    "city": "Gozón",
                    "multiple": "1"
                },
                {
                    "zipcode": "33469",
                    "province": "Asturias",
                    "city": "Carreño",
                    "multiple": "0"
                },
                {
                    "zipcode": "33470",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33470",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "1"
                },
                {
                    "zipcode": "33470",
                    "province": "Asturias",
                    "city": "Corvera de Asturias",
                    "multiple": "1"
                },
                {
                    "zipcode": "33476",
                    "province": "Asturias",
                    "city": "Tapia de Casariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "33480",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33490",
                    "province": "Asturias",
                    "city": "Avilés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33491",
                    "province": "Asturias",
                    "city": "Carreño",
                    "multiple": "0"
                },
                {
                    "zipcode": "33492",
                    "province": "Asturias",
                    "city": "Carreño",
                    "multiple": "0"
                },
                {
                    "zipcode": "33500",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33507",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33509",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33509",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "1"
                },
                {
                    "zipcode": "33510",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33518",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33518",
                    "province": "Asturias",
                    "city": "Sariego",
                    "multiple": "1"
                },
                {
                    "zipcode": "33519",
                    "province": "Asturias",
                    "city": "Cudillero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33519",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "1"
                },
                {
                    "zipcode": "33520",
                    "province": "Asturias",
                    "city": "Nava",
                    "multiple": "0"
                },
                {
                    "zipcode": "33520",
                    "province": "Asturias",
                    "city": "Ribadedeva",
                    "multiple": "1"
                },
                {
                    "zipcode": "33527",
                    "province": "Asturias",
                    "city": "Bimenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33528",
                    "province": "Asturias",
                    "city": "Nava",
                    "multiple": "0"
                },
                {
                    "zipcode": "33528",
                    "province": "Asturias",
                    "city": "Bimenes",
                    "multiple": "1"
                },
                {
                    "zipcode": "33529",
                    "province": "Asturias",
                    "city": "Cabranes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33529",
                    "province": "Asturias",
                    "city": "Nava",
                    "multiple": "1"
                },
                {
                    "zipcode": "33530",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33534",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33535",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33535",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "33536",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33537",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33538",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33539",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33540",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33546",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33547",
                    "province": "Asturias",
                    "city": "Cangas de Onís",
                    "multiple": "0"
                },
                {
                    "zipcode": "33548",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33549",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33550",
                    "province": "Asturias",
                    "city": "Cangas de Onís",
                    "multiple": "0"
                },
                {
                    "zipcode": "33554",
                    "province": "Asturias",
                    "city": "Cabrales",
                    "multiple": "0"
                },
                {
                    "zipcode": "33555",
                    "province": "Asturias",
                    "city": "Cabrales",
                    "multiple": "0"
                },
                {
                    "zipcode": "33556",
                    "province": "Asturias",
                    "city": "Cangas de Onís",
                    "multiple": "0"
                },
                {
                    "zipcode": "33556",
                    "province": "Asturias",
                    "city": "Onís",
                    "multiple": "1"
                },
                {
                    "zipcode": "33557",
                    "province": "Asturias",
                    "city": "Ponga",
                    "multiple": "0"
                },
                {
                    "zipcode": "33557",
                    "province": "Asturias",
                    "city": "Amieva",
                    "multiple": "1"
                },
                {
                    "zipcode": "33558",
                    "province": "Asturias",
                    "city": "Amieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "33558",
                    "province": "Asturias",
                    "city": "Ponga",
                    "multiple": "1"
                },
                {
                    "zipcode": "33559",
                    "province": "Asturias",
                    "city": "Cangas de Onís",
                    "multiple": "0"
                },
                {
                    "zipcode": "33559",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "1"
                },
                {
                    "zipcode": "33560",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33566",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33567",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33568",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33569",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33570",
                    "province": "Asturias",
                    "city": "Peñamellera Baja",
                    "multiple": "0"
                },
                {
                    "zipcode": "33570",
                    "province": "Asturias",
                    "city": "Navia",
                    "multiple": "1"
                },
                {
                    "zipcode": "33576",
                    "province": "Asturias",
                    "city": "Peñamellera Alta",
                    "multiple": "0"
                },
                {
                    "zipcode": "33577",
                    "province": "Asturias",
                    "city": "Peñamellera Alta",
                    "multiple": "0"
                },
                {
                    "zipcode": "33578",
                    "province": "Asturias",
                    "city": "Peñamellera Alta",
                    "multiple": "0"
                },
                {
                    "zipcode": "33579",
                    "province": "Asturias",
                    "city": "Peñamellera Baja",
                    "multiple": "0"
                },
                {
                    "zipcode": "33580",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33581",
                    "province": "Asturias",
                    "city": "Nava",
                    "multiple": "0"
                },
                {
                    "zipcode": "33582",
                    "province": "Asturias",
                    "city": "Nava",
                    "multiple": "0"
                },
                {
                    "zipcode": "33583",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33583",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "1"
                },
                {
                    "zipcode": "33584",
                    "province": "Asturias",
                    "city": "Piloña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33585",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33586",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33587",
                    "province": "Asturias",
                    "city": "Parres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33588",
                    "province": "Asturias",
                    "city": "Ribadesella",
                    "multiple": "0"
                },
                {
                    "zipcode": "33589",
                    "province": "Asturias",
                    "city": "Cangas de Onís",
                    "multiple": "0"
                },
                {
                    "zipcode": "33590",
                    "province": "Asturias",
                    "city": "Ribadedeva",
                    "multiple": "0"
                },
                {
                    "zipcode": "33591",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33592",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33593",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33594",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33595",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33596",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33598",
                    "province": "Asturias",
                    "city": "Llanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "33600",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33610",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33611",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33612",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33614",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33615",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33616",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33616",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "1"
                },
                {
                    "zipcode": "33617",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33618",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33619",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33620",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33627",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33628",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33629",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33630",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33637",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33638",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33639",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33640",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33650",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33660",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33669",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33670",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33675",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33676",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33677",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33678",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33679",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33680",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33681",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33682",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33683",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33683",
                    "province": "Asturias",
                    "city": "Mieres",
                    "multiple": "1"
                },
                {
                    "zipcode": "33684",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33685",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33686",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33687",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33688",
                    "province": "Asturias",
                    "city": "Aller",
                    "multiple": "0"
                },
                {
                    "zipcode": "33690",
                    "province": "Asturias",
                    "city": "Llanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "33691",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33692",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33693",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33694",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33695",
                    "province": "Asturias",
                    "city": "Lena",
                    "multiple": "0"
                },
                {
                    "zipcode": "33696",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33696",
                    "province": "Asturias",
                    "city": "Ribera de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "33697",
                    "province": "Asturias",
                    "city": "Gijón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33700",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33707",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33708",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33709",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33710",
                    "province": "Asturias",
                    "city": "Navia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33716",
                    "province": "Asturias",
                    "city": "Coaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33717",
                    "province": "Asturias",
                    "city": "Villayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33718",
                    "province": "Asturias",
                    "city": "Villayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "33719",
                    "province": "Asturias",
                    "city": "Navia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33719",
                    "province": "Asturias",
                    "city": "Coaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "33720",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33724",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33725",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33726",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33726",
                    "province": "Asturias",
                    "city": "Coaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "33727",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33728",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33729",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33730",
                    "province": "Asturias",
                    "city": "Grandas de Salime",
                    "multiple": "0"
                },
                {
                    "zipcode": "33731",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33732",
                    "province": "Asturias",
                    "city": "Illano",
                    "multiple": "0"
                },
                {
                    "zipcode": "33733",
                    "province": "Asturias",
                    "city": "Illano",
                    "multiple": "0"
                },
                {
                    "zipcode": "33734",
                    "province": "Asturias",
                    "city": "Illano",
                    "multiple": "0"
                },
                {
                    "zipcode": "33735",
                    "province": "Asturias",
                    "city": "Pesoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "33736",
                    "province": "Asturias",
                    "city": "Pesoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "33740",
                    "province": "Asturias",
                    "city": "Tapia de Casariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "33746",
                    "province": "Asturias",
                    "city": "Tapia de Casariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "33746",
                    "province": "Asturias",
                    "city": "Franco, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "33747",
                    "province": "Asturias",
                    "city": "Tapia de Casariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "33749",
                    "province": "Asturias",
                    "city": "Tapia de Casariego",
                    "multiple": "0"
                },
                {
                    "zipcode": "33750",
                    "province": "Asturias",
                    "city": "Franco, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "33757",
                    "province": "Asturias",
                    "city": "Franco, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "33758",
                    "province": "Asturias",
                    "city": "Franco, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "33759",
                    "province": "Asturias",
                    "city": "Franco, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "33760",
                    "province": "Asturias",
                    "city": "Castropol",
                    "multiple": "0"
                },
                {
                    "zipcode": "33768",
                    "province": "Asturias",
                    "city": "Castropol",
                    "multiple": "0"
                },
                {
                    "zipcode": "33769",
                    "province": "Asturias",
                    "city": "Castropol",
                    "multiple": "0"
                },
                {
                    "zipcode": "33770",
                    "province": "Asturias",
                    "city": "Vegadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33774",
                    "province": "Asturias",
                    "city": "San Tirso de Abres",
                    "multiple": "0"
                },
                {
                    "zipcode": "33775",
                    "province": "Asturias",
                    "city": "Taramundi",
                    "multiple": "0"
                },
                {
                    "zipcode": "33776",
                    "province": "Asturias",
                    "city": "Vegadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33776",
                    "province": "Asturias",
                    "city": "Santa Eulalia de Oscos",
                    "multiple": "1"
                },
                {
                    "zipcode": "33777",
                    "province": "Asturias",
                    "city": "Villanueva de Oscos",
                    "multiple": "0"
                },
                {
                    "zipcode": "33777",
                    "province": "Asturias",
                    "city": "Pesoz",
                    "multiple": "1"
                },
                {
                    "zipcode": "33777",
                    "province": "Asturias",
                    "city": "San Martín de Oscos",
                    "multiple": "1"
                },
                {
                    "zipcode": "33778",
                    "province": "Asturias",
                    "city": "Castropol",
                    "multiple": "0"
                },
                {
                    "zipcode": "33778",
                    "province": "Asturias",
                    "city": "Vegadeo",
                    "multiple": "1"
                },
                {
                    "zipcode": "33779",
                    "province": "Asturias",
                    "city": "Vegadeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33779",
                    "province": "Asturias",
                    "city": "Santa Eulalia de Oscos",
                    "multiple": "1"
                },
                {
                    "zipcode": "33780",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33781",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33782",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33782",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "1"
                },
                {
                    "zipcode": "33783",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33784",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33785",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33787",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33788",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33789",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33790",
                    "province": "Asturias",
                    "city": "Navia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33791",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33792",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33793",
                    "province": "Asturias",
                    "city": "Navia",
                    "multiple": "0"
                },
                {
                    "zipcode": "33794",
                    "province": "Asturias",
                    "city": "Castropol",
                    "multiple": "0"
                },
                {
                    "zipcode": "33795",
                    "province": "Asturias",
                    "city": "Coaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33796",
                    "province": "Asturias",
                    "city": "Coaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33797",
                    "province": "Asturias",
                    "city": "Coaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33798",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33799",
                    "province": "Asturias",
                    "city": "Boal",
                    "multiple": "0"
                },
                {
                    "zipcode": "33800",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "0"
                },
                {
                    "zipcode": "33810",
                    "province": "Asturias",
                    "city": "Ibias",
                    "multiple": "0"
                },
                {
                    "zipcode": "33811",
                    "province": "Asturias",
                    "city": "Ibias",
                    "multiple": "0"
                },
                {
                    "zipcode": "33811",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "1"
                },
                {
                    "zipcode": "33812",
                    "province": "Asturias",
                    "city": "Degaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "33812",
                    "province": "Asturias",
                    "city": "Ibias",
                    "multiple": "1"
                },
                {
                    "zipcode": "33813",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "0"
                },
                {
                    "zipcode": "33813",
                    "province": "Asturias",
                    "city": "Degaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "33814",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "0"
                },
                {
                    "zipcode": "33815",
                    "province": "Asturias",
                    "city": "Allande",
                    "multiple": "0"
                },
                {
                    "zipcode": "33815",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "1"
                },
                {
                    "zipcode": "33816",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "0"
                },
                {
                    "zipcode": "33817",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "0"
                },
                {
                    "zipcode": "33818",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "0"
                },
                {
                    "zipcode": "33819",
                    "province": "Asturias",
                    "city": "Cangas del Narcea",
                    "multiple": "0"
                },
                {
                    "zipcode": "33820",
                    "province": "Asturias",
                    "city": "Grado",
                    "multiple": "0"
                },
                {
                    "zipcode": "33825",
                    "province": "Asturias",
                    "city": "Grado",
                    "multiple": "0"
                },
                {
                    "zipcode": "33826",
                    "province": "Asturias",
                    "city": "Yernes y Tameza",
                    "multiple": "0"
                },
                {
                    "zipcode": "33826",
                    "province": "Asturias",
                    "city": "Grado",
                    "multiple": "1"
                },
                {
                    "zipcode": "33827",
                    "province": "Asturias",
                    "city": "Grado",
                    "multiple": "0"
                },
                {
                    "zipcode": "33827",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "1"
                },
                {
                    "zipcode": "33828",
                    "province": "Asturias",
                    "city": "Candamo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33829",
                    "province": "Asturias",
                    "city": "Candamo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33829",
                    "province": "Asturias",
                    "city": "Grado",
                    "multiple": "1"
                },
                {
                    "zipcode": "33830",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33836",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33836",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "1"
                },
                {
                    "zipcode": "33837",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33838",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33839",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33840",
                    "province": "Asturias",
                    "city": "Somiedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33841",
                    "province": "Asturias",
                    "city": "Somiedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33842",
                    "province": "Asturias",
                    "city": "Somiedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33842",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "1"
                },
                {
                    "zipcode": "33843",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33844",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33845",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33846",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33846",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "1"
                },
                {
                    "zipcode": "33847",
                    "province": "Asturias",
                    "city": "Belmonte de Miranda",
                    "multiple": "0"
                },
                {
                    "zipcode": "33848",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33850",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33857",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33858",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33859",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33860",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33866",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33867",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33868",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33869",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33870",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33873",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33874",
                    "province": "Asturias",
                    "city": "Valdés",
                    "multiple": "0"
                },
                {
                    "zipcode": "33874",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "1"
                },
                {
                    "zipcode": "33875",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33876",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33877",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33878",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33879",
                    "province": "Asturias",
                    "city": "Tineo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33880",
                    "province": "Asturias",
                    "city": "Allande",
                    "multiple": "0"
                },
                {
                    "zipcode": "33885",
                    "province": "Asturias",
                    "city": "Allande",
                    "multiple": "0"
                },
                {
                    "zipcode": "33887",
                    "province": "Asturias",
                    "city": "Allande",
                    "multiple": "0"
                },
                {
                    "zipcode": "33888",
                    "province": "Asturias",
                    "city": "Allande",
                    "multiple": "0"
                },
                {
                    "zipcode": "33889",
                    "province": "Asturias",
                    "city": "Allande",
                    "multiple": "0"
                },
                {
                    "zipcode": "33890",
                    "province": "Asturias",
                    "city": "Allande",
                    "multiple": "0"
                },
                {
                    "zipcode": "33891",
                    "province": "Asturias",
                    "city": "Salas",
                    "multiple": "0"
                },
                {
                    "zipcode": "33900",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33909",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33910",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33919",
                    "province": "Asturias",
                    "city": "Oviedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33920",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33929",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33930",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33934",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33935",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33936",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33937",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33938",
                    "province": "Asturias",
                    "city": "Siero",
                    "multiple": "0"
                },
                {
                    "zipcode": "33939",
                    "province": "Asturias",
                    "city": "Langreo",
                    "multiple": "0"
                },
                {
                    "zipcode": "33940",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33945",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33946",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33947",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33948",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33950",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33957",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33958",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33959",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33960",
                    "province": "Asturias",
                    "city": "San Martín del Rey Aurelio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33970",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33979",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33980",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33986",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33987",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33989",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33990",
                    "province": "Asturias",
                    "city": "Caso",
                    "multiple": "0"
                },
                {
                    "zipcode": "33991",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33992",
                    "province": "Asturias",
                    "city": "Laviana",
                    "multiple": "0"
                },
                {
                    "zipcode": "33993",
                    "province": "Asturias",
                    "city": "Sobrescobio",
                    "multiple": "0"
                },
                {
                    "zipcode": "33995",
                    "province": "Asturias",
                    "city": "Caso",
                    "multiple": "0"
                },
                {
                    "zipcode": "33996",
                    "province": "Asturias",
                    "city": "Caso",
                    "multiple": "0"
                },
                {
                    "zipcode": "34001",
                    "province": "Palencia",
                    "city": "Palencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34002",
                    "province": "Palencia",
                    "city": "Palencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34003",
                    "province": "Palencia",
                    "city": "Palencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34004",
                    "province": "Palencia",
                    "city": "Palencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34005",
                    "province": "Palencia",
                    "city": "Palencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34006",
                    "province": "Palencia",
                    "city": "Palencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34100",
                    "province": "Palencia",
                    "city": "Saldaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34110",
                    "province": "Palencia",
                    "city": "Pino del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "34111",
                    "province": "Palencia",
                    "city": "Poza de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "34111",
                    "province": "Palencia",
                    "city": "Villaluenga de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "34111",
                    "province": "Palencia",
                    "city": "Pino del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "34111",
                    "province": "Palencia",
                    "city": "Saldaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "34111",
                    "province": "Palencia",
                    "city": "Villota del Páramo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34112",
                    "province": "Palencia",
                    "city": "Villota del Páramo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34112",
                    "province": "Palencia",
                    "city": "Villaluenga de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "34112",
                    "province": "Palencia",
                    "city": "Santervás de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "34113",
                    "province": "Palencia",
                    "city": "Villaluenga de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "34113",
                    "province": "Palencia",
                    "city": "Villarrabé",
                    "multiple": "1"
                },
                {
                    "zipcode": "34113",
                    "province": "Palencia",
                    "city": "Pedrosa de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "34114",
                    "province": "Palencia",
                    "city": "Quintanilla de Onsoña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34115",
                    "province": "Palencia",
                    "city": "Saldaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34115",
                    "province": "Palencia",
                    "city": "Valderrábano",
                    "multiple": "1"
                },
                {
                    "zipcode": "34116",
                    "province": "Palencia",
                    "city": "Villarrabé",
                    "multiple": "0"
                },
                {
                    "zipcode": "34116",
                    "province": "Palencia",
                    "city": "Pedrosa de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "34116",
                    "province": "Palencia",
                    "city": "Bustillo de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "34117",
                    "province": "Palencia",
                    "city": "Saldaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34118",
                    "province": "Palencia",
                    "city": "Loma de Ucieza",
                    "multiple": "0"
                },
                {
                    "zipcode": "34120",
                    "province": "Palencia",
                    "city": "Carrión de los Condes",
                    "multiple": "0"
                },
                {
                    "zipcode": "34126",
                    "province": "Palencia",
                    "city": "Renedo de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "34126",
                    "province": "Palencia",
                    "city": "Villamoronta",
                    "multiple": "1"
                },
                {
                    "zipcode": "34127",
                    "province": "Palencia",
                    "city": "Valde-Ucieza",
                    "multiple": "0"
                },
                {
                    "zipcode": "34127",
                    "province": "Palencia",
                    "city": "Loma de Ucieza",
                    "multiple": "1"
                },
                {
                    "zipcode": "34127",
                    "province": "Palencia",
                    "city": "San Mamés de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34128",
                    "province": "Palencia",
                    "city": "Quintanilla de Onsoña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34128",
                    "province": "Palencia",
                    "city": "Loma de Ucieza",
                    "multiple": "1"
                },
                {
                    "zipcode": "34128",
                    "province": "Palencia",
                    "city": "Nogal de las Huertas",
                    "multiple": "1"
                },
                {
                    "zipcode": "34128",
                    "province": "Palencia",
                    "city": "Serna, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "34129",
                    "province": "Palencia",
                    "city": "Calzada de los Molinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34129",
                    "province": "Palencia",
                    "city": "Villaturde",
                    "multiple": "1"
                },
                {
                    "zipcode": "34129",
                    "province": "Palencia",
                    "city": "Bustillo del Páramo de Carrión",
                    "multiple": "1"
                },
                {
                    "zipcode": "34131",
                    "province": "Palencia",
                    "city": "Carrión de los Condes",
                    "multiple": "0"
                },
                {
                    "zipcode": "34131",
                    "province": "Palencia",
                    "city": "Villoldo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34132",
                    "province": "Palencia",
                    "city": "Villasarracino",
                    "multiple": "0"
                },
                {
                    "zipcode": "34141",
                    "province": "Palencia",
                    "city": "Villoldo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34159",
                    "province": "Palencia",
                    "city": "Ampudia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34160",
                    "province": "Palencia",
                    "city": "Ampudia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34170",
                    "province": "Palencia",
                    "city": "Pedraza de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34170",
                    "province": "Palencia",
                    "city": "Villamartín de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34170",
                    "province": "Palencia",
                    "city": "Mazariegos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34170",
                    "province": "Palencia",
                    "city": "Villaumbrales",
                    "multiple": "1"
                },
                {
                    "zipcode": "34190",
                    "province": "Palencia",
                    "city": "Villamuriel de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "34191",
                    "province": "Palencia",
                    "city": "Santa Cecilia del Alcor",
                    "multiple": "0"
                },
                {
                    "zipcode": "34191",
                    "province": "Palencia",
                    "city": "Ampudia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34191",
                    "province": "Palencia",
                    "city": "Autilla del Pino",
                    "multiple": "1"
                },
                {
                    "zipcode": "34191",
                    "province": "Palencia",
                    "city": "Palencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34192",
                    "province": "Palencia",
                    "city": "Grijota",
                    "multiple": "0"
                },
                {
                    "zipcode": "34192",
                    "province": "Palencia",
                    "city": "Villaumbrales",
                    "multiple": "1"
                },
                {
                    "zipcode": "34200",
                    "province": "Palencia",
                    "city": "Venta de Baños",
                    "multiple": "0"
                },
                {
                    "zipcode": "34208",
                    "province": "Palencia",
                    "city": "Reinoso de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "34208",
                    "province": "Palencia",
                    "city": "Dueñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "34208",
                    "province": "Palencia",
                    "city": "Venta de Baños",
                    "multiple": "1"
                },
                {
                    "zipcode": "34209",
                    "province": "Palencia",
                    "city": "Soto de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "34209",
                    "province": "Palencia",
                    "city": "Valle de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34209",
                    "province": "Palencia",
                    "city": "Hontoria de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34209",
                    "province": "Palencia",
                    "city": "Tariego de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34210",
                    "province": "Palencia",
                    "city": "Dueñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "34218",
                    "province": "Palencia",
                    "city": "Cevico de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "34219",
                    "province": "Palencia",
                    "city": "Población de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "34219",
                    "province": "Palencia",
                    "city": "Castrillo de Onielo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34219",
                    "province": "Palencia",
                    "city": "Cubillas de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34219",
                    "province": "Palencia",
                    "city": "Vertavillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34219",
                    "province": "Palencia",
                    "city": "Alba de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34220",
                    "province": "Palencia",
                    "city": "Magaz de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34230",
                    "province": "Palencia",
                    "city": "Torquemada",
                    "multiple": "0"
                },
                {
                    "zipcode": "34239",
                    "province": "Palencia",
                    "city": "Valdeolmillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34239",
                    "province": "Palencia",
                    "city": "Villamediana",
                    "multiple": "1"
                },
                {
                    "zipcode": "34240",
                    "province": "Palencia",
                    "city": "Baltanás",
                    "multiple": "0"
                },
                {
                    "zipcode": "34246",
                    "province": "Palencia",
                    "city": "Castrillo de Don Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "34247",
                    "province": "Palencia",
                    "city": "Cevico Navero",
                    "multiple": "0"
                },
                {
                    "zipcode": "34247",
                    "province": "Palencia",
                    "city": "Hérmedes de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34247",
                    "province": "Palencia",
                    "city": "Villaconancio",
                    "multiple": "1"
                },
                {
                    "zipcode": "34248",
                    "province": "Palencia",
                    "city": "Espinosa de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "34248",
                    "province": "Palencia",
                    "city": "Cobos de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34248",
                    "province": "Palencia",
                    "city": "Antigüedad",
                    "multiple": "1"
                },
                {
                    "zipcode": "34249",
                    "province": "Palencia",
                    "city": "Hornillos de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "34249",
                    "province": "Palencia",
                    "city": "Baltanás",
                    "multiple": "1"
                },
                {
                    "zipcode": "34249",
                    "province": "Palencia",
                    "city": "Villaviudas",
                    "multiple": "1"
                },
                {
                    "zipcode": "34250",
                    "province": "Palencia",
                    "city": "Quintana del Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "34257",
                    "province": "Palencia",
                    "city": "Villodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34257",
                    "province": "Palencia",
                    "city": "Villahán",
                    "multiple": "1"
                },
                {
                    "zipcode": "34257",
                    "province": "Palencia",
                    "city": "Palenzuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "34257",
                    "province": "Palencia",
                    "city": "Tabanera de Cerrato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34258",
                    "province": "Palencia",
                    "city": "Cobos de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "34259",
                    "province": "Palencia",
                    "city": "Cordovilla la Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "34259",
                    "province": "Palencia",
                    "city": "Villalaco",
                    "multiple": "1"
                },
                {
                    "zipcode": "34259",
                    "province": "Palencia",
                    "city": "Herrera de Valdecañas",
                    "multiple": "1"
                },
                {
                    "zipcode": "34260",
                    "province": "Burgos",
                    "city": "Vallejera",
                    "multiple": "0"
                },
                {
                    "zipcode": "34260",
                    "province": "Burgos",
                    "city": "Villamedianilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "34260",
                    "province": "Burgos",
                    "city": "Revilla Vallejera",
                    "multiple": "1"
                },
                {
                    "zipcode": "34300",
                    "province": "Palencia",
                    "city": "Paredes de Nava",
                    "multiple": "0"
                },
                {
                    "zipcode": "34304",
                    "province": "Palencia",
                    "city": "Belmonte de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34305",
                    "province": "Palencia",
                    "city": "Castromocho",
                    "multiple": "0"
                },
                {
                    "zipcode": "34305",
                    "province": "Palencia",
                    "city": "Torremormojón",
                    "multiple": "1"
                },
                {
                    "zipcode": "34305",
                    "province": "Palencia",
                    "city": "Boada de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34305",
                    "province": "Palencia",
                    "city": "Meneses de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34305",
                    "province": "Palencia",
                    "city": "Baquerín de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34305",
                    "province": "Palencia",
                    "city": "Villerías de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34305",
                    "province": "Palencia",
                    "city": "Capillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "34306",
                    "province": "Palencia",
                    "city": "Guaza de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34306",
                    "province": "Palencia",
                    "city": "Castil de Vela",
                    "multiple": "1"
                },
                {
                    "zipcode": "34306",
                    "province": "Palencia",
                    "city": "Frechilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "34306",
                    "province": "Palencia",
                    "city": "Mazuecos de Valdeginate",
                    "multiple": "1"
                },
                {
                    "zipcode": "34307",
                    "province": "Palencia",
                    "city": "Valle del Retortillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34309",
                    "province": "Palencia",
                    "city": "Cervatos de la Cueza",
                    "multiple": "0"
                },
                {
                    "zipcode": "34309",
                    "province": "Palencia",
                    "city": "Villanueva del Rebollar",
                    "multiple": "1"
                },
                {
                    "zipcode": "34309",
                    "province": "Palencia",
                    "city": "Cardeñosa de Volpejera",
                    "multiple": "1"
                },
                {
                    "zipcode": "34309",
                    "province": "Palencia",
                    "city": "Villamuera de la Cueza",
                    "multiple": "1"
                },
                {
                    "zipcode": "34309",
                    "province": "Palencia",
                    "city": "Riberos de la Cueza",
                    "multiple": "1"
                },
                {
                    "zipcode": "34310",
                    "province": "Palencia",
                    "city": "Becerril de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34310",
                    "province": "Palencia",
                    "city": "Perales",
                    "multiple": "1"
                },
                {
                    "zipcode": "34320",
                    "province": "Palencia",
                    "city": "Cisneros",
                    "multiple": "0"
                },
                {
                    "zipcode": "34337",
                    "province": "Palencia",
                    "city": "Fuentes de Nava",
                    "multiple": "0"
                },
                {
                    "zipcode": "34338",
                    "province": "Palencia",
                    "city": "Abarca de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34338",
                    "province": "Palencia",
                    "city": "Autillo de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34340",
                    "province": "Palencia",
                    "city": "Villada",
                    "multiple": "0"
                },
                {
                    "zipcode": "34347",
                    "province": "Palencia",
                    "city": "Pozo de Urama",
                    "multiple": "0"
                },
                {
                    "zipcode": "34347",
                    "province": "Palencia",
                    "city": "Villalcón",
                    "multiple": "1"
                },
                {
                    "zipcode": "34347",
                    "province": "Palencia",
                    "city": "San Román de la Cuba",
                    "multiple": "1"
                },
                {
                    "zipcode": "34347",
                    "province": "Palencia",
                    "city": "Ledigos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34347",
                    "province": "Palencia",
                    "city": "Población de Arroyo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34347",
                    "province": "Palencia",
                    "city": "Lagartos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34348",
                    "province": "Palencia",
                    "city": "Villacidaler",
                    "multiple": "0"
                },
                {
                    "zipcode": "34349",
                    "province": "Palencia",
                    "city": "Moratinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34349",
                    "province": "Palencia",
                    "city": "Lagartos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34349",
                    "province": "Palencia",
                    "city": "Villacidaler",
                    "multiple": "1"
                },
                {
                    "zipcode": "34349",
                    "province": "Palencia",
                    "city": "Villada",
                    "multiple": "1"
                },
                {
                    "zipcode": "34349",
                    "province": "Palencia",
                    "city": "Boadilla de Rioseco",
                    "multiple": "1"
                },
                {
                    "zipcode": "34350",
                    "province": "Palencia",
                    "city": "Villarramiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "34400",
                    "province": "Palencia",
                    "city": "Herrera de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34404",
                    "province": "Palencia",
                    "city": "Dehesa de Montejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34405",
                    "province": "Palencia",
                    "city": "Espinosa de Villagonzalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34405",
                    "province": "Palencia",
                    "city": "Santa Cruz de Boedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34405",
                    "province": "Palencia",
                    "city": "Herrera de Pisuerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "34406",
                    "province": "Palencia",
                    "city": "Báscones de Ojeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "34406",
                    "province": "Palencia",
                    "city": "Dehesa de Romanos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34406",
                    "province": "Palencia",
                    "city": "Herrera de Pisuerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "34406",
                    "province": "Palencia",
                    "city": "Collazos de Boedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34407",
                    "province": "Palencia",
                    "city": "Collazos de Boedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34407",
                    "province": "Palencia",
                    "city": "Calahorra de Boedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34407",
                    "province": "Palencia",
                    "city": "Olea de Boedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34407",
                    "province": "Palencia",
                    "city": "Sotobañado y Priorato",
                    "multiple": "1"
                },
                {
                    "zipcode": "34407",
                    "province": "Palencia",
                    "city": "Páramo de Boedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34407",
                    "province": "Palencia",
                    "city": "Revilla de Collazos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34407",
                    "province": "Palencia",
                    "city": "Villameriel",
                    "multiple": "1"
                },
                {
                    "zipcode": "34408",
                    "province": "Palencia",
                    "city": "Villameriel",
                    "multiple": "0"
                },
                {
                    "zipcode": "34409",
                    "province": "Palencia",
                    "city": "Villameriel",
                    "multiple": "0"
                },
                {
                    "zipcode": "34410",
                    "province": "Palencia",
                    "city": "Monzón de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34419",
                    "province": "Palencia",
                    "city": "Villalobón",
                    "multiple": "0"
                },
                {
                    "zipcode": "34419",
                    "province": "Palencia",
                    "city": "Amusco",
                    "multiple": "1"
                },
                {
                    "zipcode": "34419",
                    "province": "Palencia",
                    "city": "Monzón de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34419",
                    "province": "Palencia",
                    "city": "Husillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34419",
                    "province": "Palencia",
                    "city": "Fuentes de Valdepero",
                    "multiple": "1"
                },
                {
                    "zipcode": "34420",
                    "province": "Palencia",
                    "city": "Amusco",
                    "multiple": "0"
                },
                {
                    "zipcode": "34429",
                    "province": "Palencia",
                    "city": "Perales",
                    "multiple": "0"
                },
                {
                    "zipcode": "34429",
                    "province": "Palencia",
                    "city": "San Cebrián de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34429",
                    "province": "Palencia",
                    "city": "Manquillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34429",
                    "province": "Palencia",
                    "city": "Ribas de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34429",
                    "province": "Palencia",
                    "city": "Amayuelas de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "34430",
                    "province": "Palencia",
                    "city": "Piña de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34439",
                    "province": "Palencia",
                    "city": "Támara de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34440",
                    "province": "Palencia",
                    "city": "Frómista",
                    "multiple": "0"
                },
                {
                    "zipcode": "34447",
                    "province": "Palencia",
                    "city": "Revenga de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34447",
                    "province": "Palencia",
                    "city": "Villarmentero de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34449",
                    "province": "Palencia",
                    "city": "Población de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "34449",
                    "province": "Palencia",
                    "city": "Villovieco",
                    "multiple": "1"
                },
                {
                    "zipcode": "34449",
                    "province": "Palencia",
                    "city": "Villalcázar de Sirga",
                    "multiple": "1"
                },
                {
                    "zipcode": "34449",
                    "province": "Palencia",
                    "city": "Arconada",
                    "multiple": "1"
                },
                {
                    "zipcode": "34449",
                    "province": "Palencia",
                    "city": "Lomas",
                    "multiple": "1"
                },
                {
                    "zipcode": "34450",
                    "province": "Palencia",
                    "city": "Astudillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34460",
                    "province": "Palencia",
                    "city": "Osorno la Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "34465",
                    "province": "Palencia",
                    "city": "Valbuena de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34466",
                    "province": "Palencia",
                    "city": "Villodre",
                    "multiple": "0"
                },
                {
                    "zipcode": "34467",
                    "province": "Palencia",
                    "city": "Melgar de Yuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "34468",
                    "province": "Palencia",
                    "city": "Itero de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "34468",
                    "province": "Palencia",
                    "city": "Boadilla del Camino",
                    "multiple": "1"
                },
                {
                    "zipcode": "34468",
                    "province": "Palencia",
                    "city": "Lantadilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "34468",
                    "province": "Palencia",
                    "city": "Osornillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34469",
                    "province": "Palencia",
                    "city": "Osorno la Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "34469",
                    "province": "Palencia",
                    "city": "Marcilla de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34469",
                    "province": "Palencia",
                    "city": "Villaherreros",
                    "multiple": "1"
                },
                {
                    "zipcode": "34469",
                    "province": "Palencia",
                    "city": "Requena de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34470",
                    "province": "Palencia",
                    "city": "Congosto de Valdavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34470",
                    "province": "Palencia",
                    "city": "Buenavista de Valdavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34470",
                    "province": "Palencia",
                    "city": "Puebla de Valdavia, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "34473",
                    "province": "Palencia",
                    "city": "Ayuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "34473",
                    "province": "Palencia",
                    "city": "Buenavista de Valdavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34473",
                    "province": "Palencia",
                    "city": "Tabanera de Valdavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34473",
                    "province": "Palencia",
                    "city": "Valderrábano",
                    "multiple": "1"
                },
                {
                    "zipcode": "34475",
                    "province": "Palencia",
                    "city": "Villabasta de Valdavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34475",
                    "province": "Palencia",
                    "city": "Villaeles de Valdavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34475",
                    "province": "Palencia",
                    "city": "Villasila de Valdavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34477",
                    "province": "Palencia",
                    "city": "Villanuño de Valdavia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34477",
                    "province": "Palencia",
                    "city": "Loma de Ucieza",
                    "multiple": "1"
                },
                {
                    "zipcode": "34477",
                    "province": "Palencia",
                    "city": "Bárcena de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34478",
                    "province": "Palencia",
                    "city": "Castrillo de Villavega",
                    "multiple": "0"
                },
                {
                    "zipcode": "34480",
                    "province": "Palencia",
                    "city": "Alar del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "34484",
                    "province": "Palencia",
                    "city": "Dehesa de Montejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34485",
                    "province": "Palencia",
                    "city": "Olmos de Ojeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "34485",
                    "province": "Palencia",
                    "city": "Vid de Ojeda, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "34485",
                    "province": "Palencia",
                    "city": "Micieces de Ojeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "34485",
                    "province": "Palencia",
                    "city": "Payo de Ojeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "34486",
                    "province": "Palencia",
                    "city": "Aguilar de Campoo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34486",
                    "province": "Palencia",
                    "city": "Santibáñez de Ecla",
                    "multiple": "1"
                },
                {
                    "zipcode": "34486",
                    "province": "Palencia",
                    "city": "Cervera de Pisuerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "34486",
                    "province": "Palencia",
                    "city": "Olmos de Ojeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "34486",
                    "province": "Palencia",
                    "city": "Prádanos de Ojeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "34487",
                    "province": "Palencia",
                    "city": "Alar del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "34488",
                    "province": "Palencia",
                    "city": "Cervera de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34488",
                    "province": "Palencia",
                    "city": "Olmos de Ojeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "34490",
                    "province": "Palencia",
                    "city": "Santoyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34490",
                    "province": "Palencia",
                    "city": "Astudillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34491",
                    "province": "Palencia",
                    "city": "Espinosa de Villagonzalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34491",
                    "province": "Palencia",
                    "city": "Villaherreros",
                    "multiple": "1"
                },
                {
                    "zipcode": "34491",
                    "province": "Palencia",
                    "city": "Abia de las Torres",
                    "multiple": "1"
                },
                {
                    "zipcode": "34491",
                    "province": "Palencia",
                    "city": "Santa Cruz de Boedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34491",
                    "province": "Palencia",
                    "city": "Villaprovedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34491",
                    "province": "Palencia",
                    "city": "San Cristóbal de Boedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34492",
                    "province": "Burgos",
                    "city": "Rebolledo de la Torre",
                    "multiple": "0"
                },
                {
                    "zipcode": "34492",
                    "province": "Burgos",
                    "city": "Alar del Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "34492",
                    "province": "Burgos",
                    "city": "Sotresgudo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34492",
                    "province": "Burgos",
                    "city": "Aguilar de Campoo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34800",
                    "province": "Palencia",
                    "city": "Aguilar de Campoo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34810",
                    "province": "Palencia",
                    "city": "Salinas de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34810",
                    "province": "Palencia",
                    "city": "Aguilar de Campoo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34811",
                    "province": "Palencia",
                    "city": "Aguilar de Campoo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34811",
                    "province": "Palencia",
                    "city": "Pomar de Valdivia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34813",
                    "province": "Palencia",
                    "city": "Pomar de Valdivia",
                    "multiple": "0"
                },
                {
                    "zipcode": "34813",
                    "province": "Palencia",
                    "city": "Aguilar de Campoo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34814",
                    "province": "Palencia",
                    "city": "Berzosilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "34814",
                    "province": "Palencia",
                    "city": "Pomar de Valdivia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34815",
                    "province": "Palencia",
                    "city": "Aguilar de Campoo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34815",
                    "province": "Palencia",
                    "city": "Pomar de Valdivia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34820",
                    "province": "Palencia",
                    "city": "Barruelo de Santullán",
                    "multiple": "0"
                },
                {
                    "zipcode": "34828",
                    "province": "Palencia",
                    "city": "Salinas de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34828",
                    "province": "Palencia",
                    "city": "San Cebrián de Mudá",
                    "multiple": "1"
                },
                {
                    "zipcode": "34828",
                    "province": "Palencia",
                    "city": "Barruelo de Santullán",
                    "multiple": "1"
                },
                {
                    "zipcode": "34829",
                    "province": "Palencia",
                    "city": "Brañosera",
                    "multiple": "0"
                },
                {
                    "zipcode": "34829",
                    "province": "Palencia",
                    "city": "Barruelo de Santullán",
                    "multiple": "1"
                },
                {
                    "zipcode": "34830",
                    "province": "Palencia",
                    "city": "Salinas de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34839",
                    "province": "Palencia",
                    "city": "Cervera de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34839",
                    "province": "Palencia",
                    "city": "San Cebrián de Mudá",
                    "multiple": "1"
                },
                {
                    "zipcode": "34839",
                    "province": "Palencia",
                    "city": "Mudá",
                    "multiple": "1"
                },
                {
                    "zipcode": "34840",
                    "province": "Palencia",
                    "city": "Cervera de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34844",
                    "province": "Palencia",
                    "city": "Dehesa de Montejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34844",
                    "province": "Palencia",
                    "city": "Triollo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34844",
                    "province": "Palencia",
                    "city": "Cervera de Pisuerga",
                    "multiple": "1"
                },
                {
                    "zipcode": "34846",
                    "province": "Palencia",
                    "city": "Cervera de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "34846",
                    "province": "Palencia",
                    "city": "Polentinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34847",
                    "province": "Palencia",
                    "city": "Pernía, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "34848",
                    "province": "Palencia",
                    "city": "Pernía, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "34849",
                    "province": "Palencia",
                    "city": "Pernía, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "34850",
                    "province": "Palencia",
                    "city": "Castrejón de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34858",
                    "province": "Palencia",
                    "city": "Castrejón de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34859",
                    "province": "Palencia",
                    "city": "Castrejón de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34869",
                    "province": "Palencia",
                    "city": "Santibáñez de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34870",
                    "province": "Palencia",
                    "city": "Respenda de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34870",
                    "province": "Palencia",
                    "city": "Santibáñez de la Peña",
                    "multiple": "1"
                },
                {
                    "zipcode": "34874",
                    "province": "Palencia",
                    "city": "Respenda de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34878",
                    "province": "Palencia",
                    "city": "Santibáñez de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34878",
                    "province": "Palencia",
                    "city": "Congosto de Valdavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34878",
                    "province": "Palencia",
                    "city": "Respenda de la Peña",
                    "multiple": "1"
                },
                {
                    "zipcode": "34879",
                    "province": "Palencia",
                    "city": "Guardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34879",
                    "province": "Palencia",
                    "city": "Santibáñez de la Peña",
                    "multiple": "1"
                },
                {
                    "zipcode": "34880",
                    "province": "Palencia",
                    "city": "Guardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34882",
                    "province": "Palencia",
                    "city": "Guardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "34882",
                    "province": "Palencia",
                    "city": "Congosto de Valdavia",
                    "multiple": "1"
                },
                {
                    "zipcode": "34884",
                    "province": "Palencia",
                    "city": "Santibáñez de la Peña",
                    "multiple": "0"
                },
                {
                    "zipcode": "34886",
                    "province": "Palencia",
                    "city": "Velilla del Río Carrión",
                    "multiple": "0"
                },
                {
                    "zipcode": "34888",
                    "province": "Palencia",
                    "city": "Velilla del Río Carrión",
                    "multiple": "0"
                },
                {
                    "zipcode": "34889",
                    "province": "Palencia",
                    "city": "Velilla del Río Carrión",
                    "multiple": "0"
                },
                {
                    "zipcode": "34889",
                    "province": "Palencia",
                    "city": "Fresno del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "34889",
                    "province": "Palencia",
                    "city": "Mantinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "34889",
                    "province": "Palencia",
                    "city": "Guardo",
                    "multiple": "1"
                },
                {
                    "zipcode": "34889",
                    "province": "Palencia",
                    "city": "Villalba de Guardo",
                    "multiple": "1"
                },
                {
                    "zipcode": "35000",
                    "province": "Las Palmas",
                    "city": "Valleseco",
                    "multiple": "0"
                },
                {
                    "zipcode": "35000",
                    "province": "Las Palmas",
                    "city": "Ingenio",
                    "multiple": "1"
                },
                {
                    "zipcode": "35000",
                    "province": "Las Palmas",
                    "city": "Santa Lucía de Tirajana",
                    "multiple": "1"
                },
                {
                    "zipcode": "35000",
                    "province": "Las Palmas",
                    "city": "Teror",
                    "multiple": "1"
                },
                {
                    "zipcode": "35000",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "1"
                },
                {
                    "zipcode": "35001",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35002",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35003",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35004",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35005",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35006",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35007",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35008",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35009",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35010",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35011",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35012",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35013",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35014",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35015",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35016",
                    "province": "Las Palmas",
                    "city": "Valsequillo de Gran Canaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35016",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "35017",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35018",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35019",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "35100",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35106",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35107",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35108",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35109",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35110",
                    "province": "Las Palmas",
                    "city": "Santa Lucía de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35118",
                    "province": "Las Palmas",
                    "city": "Agüimes",
                    "multiple": "0"
                },
                {
                    "zipcode": "35119",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35119",
                    "province": "Las Palmas",
                    "city": "Agüimes",
                    "multiple": "1"
                },
                {
                    "zipcode": "35120",
                    "province": "Las Palmas",
                    "city": "Mogán",
                    "multiple": "0"
                },
                {
                    "zipcode": "35120",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "1"
                },
                {
                    "zipcode": "35128",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35129",
                    "province": "Las Palmas",
                    "city": "Mogán",
                    "multiple": "0"
                },
                {
                    "zipcode": "35130",
                    "province": "Las Palmas",
                    "city": "Mogán",
                    "multiple": "0"
                },
                {
                    "zipcode": "35138",
                    "province": "Las Palmas",
                    "city": "Mogán",
                    "multiple": "0"
                },
                {
                    "zipcode": "35139",
                    "province": "Las Palmas",
                    "city": "Mogán",
                    "multiple": "0"
                },
                {
                    "zipcode": "35140",
                    "province": "Las Palmas",
                    "city": "Mogán",
                    "multiple": "0"
                },
                {
                    "zipcode": "35149",
                    "province": "Las Palmas",
                    "city": "Mogán",
                    "multiple": "0"
                },
                {
                    "zipcode": "35188",
                    "province": "Las Palmas",
                    "city": "Gáldar",
                    "multiple": "0"
                },
                {
                    "zipcode": "35200",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35210",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35211",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35212",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35213",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35214",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35214",
                    "province": "Las Palmas",
                    "city": "Valsequillo de Gran Canaria",
                    "multiple": "1"
                },
                {
                    "zipcode": "35215",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35216",
                    "province": "Las Palmas",
                    "city": "Valsequillo de Gran Canaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35217",
                    "province": "Las Palmas",
                    "city": "Valsequillo de Gran Canaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35218",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35219",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35220",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35220",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "35229",
                    "province": "Las Palmas",
                    "city": "Telde",
                    "multiple": "0"
                },
                {
                    "zipcode": "35229",
                    "province": "Las Palmas",
                    "city": "Palmas de Gran Canaria, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "35240",
                    "province": "Las Palmas",
                    "city": "Ingenio",
                    "multiple": "0"
                },
                {
                    "zipcode": "35250",
                    "province": "Las Palmas",
                    "city": "Ingenio",
                    "multiple": "0"
                },
                {
                    "zipcode": "35259",
                    "province": "Las Palmas",
                    "city": "Ingenio",
                    "multiple": "0"
                },
                {
                    "zipcode": "35260",
                    "province": "Las Palmas",
                    "city": "Agüimes",
                    "multiple": "0"
                },
                {
                    "zipcode": "35269",
                    "province": "Las Palmas",
                    "city": "Agüimes",
                    "multiple": "0"
                },
                {
                    "zipcode": "35270",
                    "province": "Las Palmas",
                    "city": "Agüimes",
                    "multiple": "0"
                },
                {
                    "zipcode": "35280",
                    "province": "Las Palmas",
                    "city": "Santa Lucía de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35280",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "1"
                },
                {
                    "zipcode": "35290",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35299",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "0"
                },
                {
                    "zipcode": "35300",
                    "province": "Las Palmas",
                    "city": "Santa Brígida",
                    "multiple": "0"
                },
                {
                    "zipcode": "35307",
                    "province": "Las Palmas",
                    "city": "Santa Brígida",
                    "multiple": "0"
                },
                {
                    "zipcode": "35308",
                    "province": "Las Palmas",
                    "city": "Santa Brígida",
                    "multiple": "0"
                },
                {
                    "zipcode": "35309",
                    "province": "Las Palmas",
                    "city": "Santa Brígida",
                    "multiple": "0"
                },
                {
                    "zipcode": "35310",
                    "province": "Las Palmas",
                    "city": "Santa Brígida",
                    "multiple": "0"
                },
                {
                    "zipcode": "35320",
                    "province": "Las Palmas",
                    "city": "Vega de San Mateo",
                    "multiple": "0"
                },
                {
                    "zipcode": "35328",
                    "province": "Las Palmas",
                    "city": "Vega de San Mateo",
                    "multiple": "0"
                },
                {
                    "zipcode": "35329",
                    "province": "Las Palmas",
                    "city": "Vega de San Mateo",
                    "multiple": "0"
                },
                {
                    "zipcode": "35330",
                    "province": "Las Palmas",
                    "city": "Teror",
                    "multiple": "0"
                },
                {
                    "zipcode": "35333",
                    "province": "Las Palmas",
                    "city": "Teror",
                    "multiple": "0"
                },
                {
                    "zipcode": "35338",
                    "province": "Las Palmas",
                    "city": "Teror",
                    "multiple": "0"
                },
                {
                    "zipcode": "35339",
                    "province": "Las Palmas",
                    "city": "Teror",
                    "multiple": "0"
                },
                {
                    "zipcode": "35340",
                    "province": "Las Palmas",
                    "city": "Valleseco",
                    "multiple": "0"
                },
                {
                    "zipcode": "35349",
                    "province": "Las Palmas",
                    "city": "Valleseco",
                    "multiple": "0"
                },
                {
                    "zipcode": "35350",
                    "province": "Las Palmas",
                    "city": "Artenara",
                    "multiple": "0"
                },
                {
                    "zipcode": "35360",
                    "province": "Las Palmas",
                    "city": "Tejeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "35368",
                    "province": "Las Palmas",
                    "city": "Tejeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "35369",
                    "province": "Las Palmas",
                    "city": "Antigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "35369",
                    "province": "Las Palmas",
                    "city": "San Bartolomé de Tirajana",
                    "multiple": "1"
                },
                {
                    "zipcode": "35370",
                    "province": "Las Palmas",
                    "city": "Yaiza",
                    "multiple": "0"
                },
                {
                    "zipcode": "35400",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35404",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35411",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35412",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35413",
                    "province": "Las Palmas",
                    "city": "Moya",
                    "multiple": "0"
                },
                {
                    "zipcode": "35413",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "1"
                },
                {
                    "zipcode": "35414",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35415",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35418",
                    "province": "Las Palmas",
                    "city": "Arucas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35420",
                    "province": "Las Palmas",
                    "city": "Gáldar",
                    "multiple": "0"
                },
                {
                    "zipcode": "35420",
                    "province": "Las Palmas",
                    "city": "Moya",
                    "multiple": "1"
                },
                {
                    "zipcode": "35421",
                    "province": "Las Palmas",
                    "city": "Moya",
                    "multiple": "0"
                },
                {
                    "zipcode": "35422",
                    "province": "Las Palmas",
                    "city": "Moya",
                    "multiple": "0"
                },
                {
                    "zipcode": "35423",
                    "province": "Las Palmas",
                    "city": "Moya",
                    "multiple": "0"
                },
                {
                    "zipcode": "35430",
                    "province": "Las Palmas",
                    "city": "Firgas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35431",
                    "province": "Las Palmas",
                    "city": "Firgas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35432",
                    "province": "Las Palmas",
                    "city": "Firgas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35435",
                    "province": "Las Palmas",
                    "city": "Firgas",
                    "multiple": "0"
                },
                {
                    "zipcode": "35450",
                    "province": "Las Palmas",
                    "city": "Santa María de Guía de Gran Canaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35457",
                    "province": "Las Palmas",
                    "city": "Santa María de Guía de Gran Canaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35458",
                    "province": "Las Palmas",
                    "city": "Santa María de Guía de Gran Canaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35460",
                    "province": "Las Palmas",
                    "city": "Gáldar",
                    "multiple": "0"
                },
                {
                    "zipcode": "35468",
                    "province": "Las Palmas",
                    "city": "Gáldar",
                    "multiple": "0"
                },
                {
                    "zipcode": "35469",
                    "province": "Las Palmas",
                    "city": "Gáldar",
                    "multiple": "0"
                },
                {
                    "zipcode": "35469",
                    "province": "Las Palmas",
                    "city": "Oliva, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "35470",
                    "province": "Las Palmas",
                    "city": "Aldea de San Nicolás, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "35478",
                    "province": "Las Palmas",
                    "city": "Aldea de San Nicolás, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "35479",
                    "province": "Las Palmas",
                    "city": "Aldea de San Nicolás, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "35480",
                    "province": "Las Palmas",
                    "city": "Agaete",
                    "multiple": "0"
                },
                {
                    "zipcode": "35488",
                    "province": "Las Palmas",
                    "city": "Gáldar",
                    "multiple": "0"
                },
                {
                    "zipcode": "35489",
                    "province": "Las Palmas",
                    "city": "Agaete",
                    "multiple": "0"
                },
                {
                    "zipcode": "35600",
                    "province": "Las Palmas",
                    "city": "Puerto del Rosario",
                    "multiple": "0"
                },
                {
                    "zipcode": "35500",
                    "province": "Las Palmas",
                    "city": "Arrecife",
                    "multiple": "1"
                },
                {
                    "zipcode": "35507",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35508",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35509",
                    "province": "Las Palmas",
                    "city": "San Bartolomé",
                    "multiple": "0"
                },
                {
                    "zipcode": "35509",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "1"
                },
                {
                    "zipcode": "35510",
                    "province": "Las Palmas",
                    "city": "Tías",
                    "multiple": "0"
                },
                {
                    "zipcode": "35520",
                    "province": "Las Palmas",
                    "city": "Haría",
                    "multiple": "0"
                },
                {
                    "zipcode": "35530",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35539",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35540",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35540",
                    "province": "Las Palmas",
                    "city": "Santa María de Guía de Gran Canaria",
                    "multiple": "1"
                },
                {
                    "zipcode": "35541",
                    "province": "Las Palmas",
                    "city": "Haría",
                    "multiple": "0"
                },
                {
                    "zipcode": "35542",
                    "province": "Las Palmas",
                    "city": "Haría",
                    "multiple": "0"
                },
                {
                    "zipcode": "35543",
                    "province": "Las Palmas",
                    "city": "Haría",
                    "multiple": "0"
                },
                {
                    "zipcode": "35543",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "1"
                },
                {
                    "zipcode": "35544",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35550",
                    "province": "Las Palmas",
                    "city": "Tinajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "35550",
                    "province": "Las Palmas",
                    "city": "San Bartolomé",
                    "multiple": "1"
                },
                {
                    "zipcode": "35558",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35559",
                    "province": "Las Palmas",
                    "city": "San Bartolomé",
                    "multiple": "0"
                },
                {
                    "zipcode": "35560",
                    "province": "Las Palmas",
                    "city": "Tinajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "35561",
                    "province": "Las Palmas",
                    "city": "Teguise",
                    "multiple": "0"
                },
                {
                    "zipcode": "35570",
                    "province": "Las Palmas",
                    "city": "Yaiza",
                    "multiple": "0"
                },
                {
                    "zipcode": "35571",
                    "province": "Las Palmas",
                    "city": "Tías",
                    "multiple": "0"
                },
                {
                    "zipcode": "35572",
                    "province": "Las Palmas",
                    "city": "Tías",
                    "multiple": "0"
                },
                {
                    "zipcode": "35579",
                    "province": "Las Palmas",
                    "city": "Aldea de San Nicolás, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "35580",
                    "province": "Las Palmas",
                    "city": "Yaiza",
                    "multiple": "0"
                },
                {
                    "zipcode": "35610",
                    "province": "Las Palmas",
                    "city": "Antigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "35610",
                    "province": "Las Palmas",
                    "city": "Puerto del Rosario",
                    "multiple": "1"
                },
                {
                    "zipcode": "35611",
                    "province": "Las Palmas",
                    "city": "Puerto del Rosario",
                    "multiple": "0"
                },
                {
                    "zipcode": "35612",
                    "province": "Las Palmas",
                    "city": "Puerto del Rosario",
                    "multiple": "0"
                },
                {
                    "zipcode": "35613",
                    "province": "Las Palmas",
                    "city": "Puerto del Rosario",
                    "multiple": "0"
                },
                {
                    "zipcode": "35620",
                    "province": "Las Palmas",
                    "city": "Tuineje",
                    "multiple": "0"
                },
                {
                    "zipcode": "35625",
                    "province": "Las Palmas",
                    "city": "Pájara",
                    "multiple": "0"
                },
                {
                    "zipcode": "35626",
                    "province": "Las Palmas",
                    "city": "Pájara",
                    "multiple": "0"
                },
                {
                    "zipcode": "35627",
                    "province": "Las Palmas",
                    "city": "Tuineje",
                    "multiple": "0"
                },
                {
                    "zipcode": "35627",
                    "province": "Las Palmas",
                    "city": "Puerto del Rosario",
                    "multiple": "1"
                },
                {
                    "zipcode": "35627",
                    "province": "Las Palmas",
                    "city": "Pájara",
                    "multiple": "1"
                },
                {
                    "zipcode": "35628",
                    "province": "Las Palmas",
                    "city": "Valsequillo de Gran Canaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35628",
                    "province": "Las Palmas",
                    "city": "Pájara",
                    "multiple": "1"
                },
                {
                    "zipcode": "35628",
                    "province": "Las Palmas",
                    "city": "Tuineje",
                    "multiple": "1"
                },
                {
                    "zipcode": "35629",
                    "province": "Las Palmas",
                    "city": "Pájara",
                    "multiple": "0"
                },
                {
                    "zipcode": "35629",
                    "province": "Las Palmas",
                    "city": "Tuineje",
                    "multiple": "1"
                },
                {
                    "zipcode": "35630",
                    "province": "Las Palmas",
                    "city": "Antigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "35637",
                    "province": "Las Palmas",
                    "city": "Betancuria",
                    "multiple": "0"
                },
                {
                    "zipcode": "35637",
                    "province": "Las Palmas",
                    "city": "Puerto del Rosario",
                    "multiple": "1"
                },
                {
                    "zipcode": "35638",
                    "province": "Las Palmas",
                    "city": "Antigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "35639",
                    "province": "Las Palmas",
                    "city": "Antigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "35640",
                    "province": "Las Palmas",
                    "city": "Gáldar",
                    "multiple": "0"
                },
                {
                    "zipcode": "35640",
                    "province": "Las Palmas",
                    "city": "Oliva, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "35649",
                    "province": "Las Palmas",
                    "city": "Oliva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "35650",
                    "province": "Las Palmas",
                    "city": "Oliva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "35660",
                    "province": "Las Palmas",
                    "city": "Oliva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "35660",
                    "province": "Las Palmas",
                    "city": "Tuineje",
                    "multiple": "1"
                },
                {
                    "zipcode": "36001",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36002",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36003",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36004",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36005",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36005",
                    "province": "Pontevedra",
                    "city": "Poio",
                    "multiple": "1"
                },
                {
                    "zipcode": "36110",
                    "province": "Pontevedra",
                    "city": "Campo Lameiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36116",
                    "province": "Pontevedra",
                    "city": "Cerdedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36117",
                    "province": "Pontevedra",
                    "city": "Cerdedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36118",
                    "province": "Pontevedra",
                    "city": "Campo Lameiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36119",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36119",
                    "province": "Pontevedra",
                    "city": "Campo Lameiro",
                    "multiple": "1"
                },
                {
                    "zipcode": "36120",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36121",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36130",
                    "province": "Pontevedra",
                    "city": "Cerdedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36139",
                    "province": "Pontevedra",
                    "city": "Cerdedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36140",
                    "province": "Pontevedra",
                    "city": "Vilaboa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36141",
                    "province": "Pontevedra",
                    "city": "Vilaboa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36142",
                    "province": "Pontevedra",
                    "city": "Vilaboa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36143",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36150",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36151",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36152",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36153",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36154",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36155",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36156",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36157",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36158",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36160",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36161",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36162",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36163",
                    "province": "Pontevedra",
                    "city": "Poio",
                    "multiple": "0"
                },
                {
                    "zipcode": "36164",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36190",
                    "province": "Pontevedra",
                    "city": "Barro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36191",
                    "province": "Pontevedra",
                    "city": "Barro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36192",
                    "province": "Pontevedra",
                    "city": "Meis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36193",
                    "province": "Pontevedra",
                    "city": "Barro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36194",
                    "province": "Pontevedra",
                    "city": "Barro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36201",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36202",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36203",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36204",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36205",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36206",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36207",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36208",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36209",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36210",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36211",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36212",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36213",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36214",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36215",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36216",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36300",
                    "province": "Pontevedra",
                    "city": "Baiona",
                    "multiple": "0"
                },
                {
                    "zipcode": "36307",
                    "province": "Pontevedra",
                    "city": "Baiona",
                    "multiple": "0"
                },
                {
                    "zipcode": "36308",
                    "province": "Pontevedra",
                    "city": "Baiona",
                    "multiple": "0"
                },
                {
                    "zipcode": "36309",
                    "province": "Pontevedra",
                    "city": "Baiona",
                    "multiple": "0"
                },
                {
                    "zipcode": "36309",
                    "province": "Pontevedra",
                    "city": "Oia",
                    "multiple": "1"
                },
                {
                    "zipcode": "36310",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36311",
                    "province": "Pontevedra",
                    "city": "Mos",
                    "multiple": "0"
                },
                {
                    "zipcode": "36312",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36313",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36314",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36315",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36316",
                    "province": "Pontevedra",
                    "city": "Gondomar",
                    "multiple": "0"
                },
                {
                    "zipcode": "36317",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36318",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36320",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36330",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36331",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36340",
                    "province": "Pontevedra",
                    "city": "Nigrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "36350",
                    "province": "Pontevedra",
                    "city": "Nigrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "36360",
                    "province": "Pontevedra",
                    "city": "Nigrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "36370",
                    "province": "Pontevedra",
                    "city": "Nigrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "36378",
                    "province": "Pontevedra",
                    "city": "Gondomar",
                    "multiple": "0"
                },
                {
                    "zipcode": "36379",
                    "province": "Pontevedra",
                    "city": "Nigrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "36380",
                    "province": "Pontevedra",
                    "city": "Gondomar",
                    "multiple": "0"
                },
                {
                    "zipcode": "36388",
                    "province": "Pontevedra",
                    "city": "Gondomar",
                    "multiple": "0"
                },
                {
                    "zipcode": "36389",
                    "province": "Pontevedra",
                    "city": "Gondomar",
                    "multiple": "0"
                },
                {
                    "zipcode": "36390",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36391",
                    "province": "Pontevedra",
                    "city": "Nigrán",
                    "multiple": "0"
                },
                {
                    "zipcode": "36392",
                    "province": "Pontevedra",
                    "city": "Vigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36393",
                    "province": "Pontevedra",
                    "city": "Baiona",
                    "multiple": "0"
                },
                {
                    "zipcode": "36400",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36410",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36411",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36412",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36413",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36414",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36415",
                    "province": "Pontevedra",
                    "city": "Mos",
                    "multiple": "0"
                },
                {
                    "zipcode": "36416",
                    "province": "Pontevedra",
                    "city": "Mos",
                    "multiple": "0"
                },
                {
                    "zipcode": "36417",
                    "province": "Pontevedra",
                    "city": "Mos",
                    "multiple": "0"
                },
                {
                    "zipcode": "36418",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36419",
                    "province": "Pontevedra",
                    "city": "Mos",
                    "multiple": "0"
                },
                {
                    "zipcode": "36420",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36428",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36429",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36430",
                    "province": "Pontevedra",
                    "city": "Arbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36435",
                    "province": "Pontevedra",
                    "city": "Arbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36436",
                    "province": "Pontevedra",
                    "city": "Arbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36437",
                    "province": "Pontevedra",
                    "city": "Arbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36438",
                    "province": "Pontevedra",
                    "city": "Arbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36439",
                    "province": "Pontevedra",
                    "city": "Arbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36440",
                    "province": "Pontevedra",
                    "city": "Neves, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "36446",
                    "province": "Pontevedra",
                    "city": "Neves, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "36447",
                    "province": "Pontevedra",
                    "city": "Neves, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "36448",
                    "province": "Pontevedra",
                    "city": "Neves, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "36449",
                    "province": "Pontevedra",
                    "city": "Neves, As",
                    "multiple": "0"
                },
                {
                    "zipcode": "36450",
                    "province": "Pontevedra",
                    "city": "Salvaterra de Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36455",
                    "province": "Pontevedra",
                    "city": "Salvaterra de Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36456",
                    "province": "Pontevedra",
                    "city": "Salvaterra de Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36457",
                    "province": "Pontevedra",
                    "city": "Salvaterra de Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36458",
                    "province": "Pontevedra",
                    "city": "Salvaterra de Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36459",
                    "province": "Pontevedra",
                    "city": "Salvaterra de Miño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36460",
                    "province": "Pontevedra",
                    "city": "Salceda de Caselas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36460",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "1"
                },
                {
                    "zipcode": "36470",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36470",
                    "province": "Pontevedra",
                    "city": "Salceda de Caselas",
                    "multiple": "1"
                },
                {
                    "zipcode": "36471",
                    "province": "Pontevedra",
                    "city": "Salceda de Caselas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36472",
                    "province": "Pontevedra",
                    "city": "Salceda de Caselas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36474",
                    "province": "Pontevedra",
                    "city": "Salceda de Caselas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36475",
                    "province": "Pontevedra",
                    "city": "Porriño, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36475",
                    "province": "Pontevedra",
                    "city": "Salceda de Caselas",
                    "multiple": "1"
                },
                {
                    "zipcode": "36490",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36491",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36492",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36493",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36494",
                    "province": "Pontevedra",
                    "city": "Arbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36500",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36510",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36511",
                    "province": "Pontevedra",
                    "city": "Rodeiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36511",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "1"
                },
                {
                    "zipcode": "36512",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36514",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36514",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "1"
                },
                {
                    "zipcode": "36515",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36516",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36516",
                    "province": "Pontevedra",
                    "city": "Dozón",
                    "multiple": "1"
                },
                {
                    "zipcode": "36517",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36518",
                    "province": "Pontevedra",
                    "city": "Dozón",
                    "multiple": "0"
                },
                {
                    "zipcode": "36518",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "1"
                },
                {
                    "zipcode": "36519",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36520",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36524",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36525",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36526",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36527",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36528",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36529",
                    "province": "Pontevedra",
                    "city": "Agolada",
                    "multiple": "0"
                },
                {
                    "zipcode": "36530",
                    "province": "Pontevedra",
                    "city": "Rodeiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36537",
                    "province": "Pontevedra",
                    "city": "Rodeiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36538",
                    "province": "Pontevedra",
                    "city": "Rodeiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36539",
                    "province": "Pontevedra",
                    "city": "Rodeiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36540",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36543",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36544",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36545",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36546",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36547",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36548",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36549",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36549",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "1"
                },
                {
                    "zipcode": "36550",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "0"
                },
                {
                    "zipcode": "36555",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "0"
                },
                {
                    "zipcode": "36556",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "0"
                },
                {
                    "zipcode": "36557",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "0"
                },
                {
                    "zipcode": "36558",
                    "province": "Pontevedra",
                    "city": "Cerdedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36558",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "1"
                },
                {
                    "zipcode": "36559",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36560",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "0"
                },
                {
                    "zipcode": "36567",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "0"
                },
                {
                    "zipcode": "36569",
                    "province": "Pontevedra",
                    "city": "Forcarei",
                    "multiple": "0"
                },
                {
                    "zipcode": "36570",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36579",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36580",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36581",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36582",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36583",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36583",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "1"
                },
                {
                    "zipcode": "36584",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36585",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36586",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36587",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36587",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "1"
                },
                {
                    "zipcode": "36588",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36589",
                    "province": "Pontevedra",
                    "city": "Silleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "36590",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36596",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36596",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "1"
                },
                {
                    "zipcode": "36597",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36598",
                    "province": "Pontevedra",
                    "city": "Lalín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36598",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "1"
                },
                {
                    "zipcode": "36599",
                    "province": "Pontevedra",
                    "city": "Vila de Cruces",
                    "multiple": "0"
                },
                {
                    "zipcode": "36600",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36610",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36611",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36612",
                    "province": "Pontevedra",
                    "city": "Catoira",
                    "multiple": "0"
                },
                {
                    "zipcode": "36613",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36614",
                    "province": "Pontevedra",
                    "city": "Vilanova de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36615",
                    "province": "Pontevedra",
                    "city": "Caldas de Reis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36616",
                    "province": "Pontevedra",
                    "city": "Meis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36616",
                    "province": "Pontevedra",
                    "city": "Vilanova de Arousa",
                    "multiple": "1"
                },
                {
                    "zipcode": "36617",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36618",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36619",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36620",
                    "province": "Pontevedra",
                    "city": "Vilanova de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36626",
                    "province": "Pontevedra",
                    "city": "Illa de Arousa, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36627",
                    "province": "Pontevedra",
                    "city": "Vilanova de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36628",
                    "province": "Pontevedra",
                    "city": "Vilanova de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36629",
                    "province": "Pontevedra",
                    "city": "Vilanova de Arousa",
                    "multiple": "0"
                },
                {
                    "zipcode": "36629",
                    "province": "Pontevedra",
                    "city": "Vilagarcía de Arousa",
                    "multiple": "1"
                },
                {
                    "zipcode": "36630",
                    "province": "Pontevedra",
                    "city": "Cambados",
                    "multiple": "0"
                },
                {
                    "zipcode": "36633",
                    "province": "Pontevedra",
                    "city": "Cambados",
                    "multiple": "0"
                },
                {
                    "zipcode": "36634",
                    "province": "Pontevedra",
                    "city": "Cambados",
                    "multiple": "0"
                },
                {
                    "zipcode": "36635",
                    "province": "Pontevedra",
                    "city": "Ribadumia",
                    "multiple": "0"
                },
                {
                    "zipcode": "36635",
                    "province": "Pontevedra",
                    "city": "Cambados",
                    "multiple": "1"
                },
                {
                    "zipcode": "36636",
                    "province": "Pontevedra",
                    "city": "Ribadumia",
                    "multiple": "0"
                },
                {
                    "zipcode": "36637",
                    "province": "Pontevedra",
                    "city": "Meis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36638",
                    "province": "Pontevedra",
                    "city": "Meaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36638",
                    "province": "Pontevedra",
                    "city": "Ribadumia",
                    "multiple": "1"
                },
                {
                    "zipcode": "36639",
                    "province": "Pontevedra",
                    "city": "Cambados",
                    "multiple": "0"
                },
                {
                    "zipcode": "36640",
                    "province": "Pontevedra",
                    "city": "Pontecesures",
                    "multiple": "0"
                },
                {
                    "zipcode": "36645",
                    "province": "Pontevedra",
                    "city": "Valga",
                    "multiple": "0"
                },
                {
                    "zipcode": "36646",
                    "province": "Pontevedra",
                    "city": "Valga",
                    "multiple": "0"
                },
                {
                    "zipcode": "36647",
                    "province": "Pontevedra",
                    "city": "Valga",
                    "multiple": "0"
                },
                {
                    "zipcode": "36649",
                    "province": "Pontevedra",
                    "city": "Pontecesures",
                    "multiple": "0"
                },
                {
                    "zipcode": "36650",
                    "province": "Pontevedra",
                    "city": "Caldas de Reis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36652",
                    "province": "Pontevedra",
                    "city": "Portas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36653",
                    "province": "Pontevedra",
                    "city": "Caldas de Reis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36654",
                    "province": "Pontevedra",
                    "city": "Caldas de Reis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36655",
                    "province": "Pontevedra",
                    "city": "Caldas de Reis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36656",
                    "province": "Pontevedra",
                    "city": "Caldas de Reis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36657",
                    "province": "Pontevedra",
                    "city": "Portas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36658",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36658",
                    "province": "Pontevedra",
                    "city": "Portas",
                    "multiple": "1"
                },
                {
                    "zipcode": "36659",
                    "province": "Pontevedra",
                    "city": "Caldas de Reis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36660",
                    "province": "Pontevedra",
                    "city": "Moraña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36668",
                    "province": "Pontevedra",
                    "city": "Moraña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36669",
                    "province": "Pontevedra",
                    "city": "Moraña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36670",
                    "province": "Pontevedra",
                    "city": "Cuntis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36675",
                    "province": "Pontevedra",
                    "city": "Cuntis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36676",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36677",
                    "province": "Pontevedra",
                    "city": "Cuntis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36678",
                    "province": "Pontevedra",
                    "city": "Cuntis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36679",
                    "province": "Pontevedra",
                    "city": "Cuntis",
                    "multiple": "0"
                },
                {
                    "zipcode": "36680",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36681",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36682",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36683",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36684",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36685",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36685",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "36686",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36687",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36688",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36689",
                    "province": "Pontevedra",
                    "city": "Estrada, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36690",
                    "province": "Pontevedra",
                    "city": "Soutomaior",
                    "multiple": "0"
                },
                {
                    "zipcode": "36690",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "1"
                },
                {
                    "zipcode": "36691",
                    "province": "Pontevedra",
                    "city": "Barro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36691",
                    "province": "Pontevedra",
                    "city": "Soutomaior",
                    "multiple": "1"
                },
                {
                    "zipcode": "36692",
                    "province": "Pontevedra",
                    "city": "Barro",
                    "multiple": "0"
                },
                {
                    "zipcode": "36693",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36700",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36710",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36711",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36712",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36713",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36714",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36715",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36720",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36729",
                    "province": "Pontevedra",
                    "city": "Tui",
                    "multiple": "0"
                },
                {
                    "zipcode": "36730",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36739",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36740",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36746",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36747",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36748",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36749",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36750",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36760",
                    "province": "Pontevedra",
                    "city": "Rosal, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36770",
                    "province": "Pontevedra",
                    "city": "Rosal, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36778",
                    "province": "Pontevedra",
                    "city": "Rosal, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36779",
                    "province": "Pontevedra",
                    "city": "Oia",
                    "multiple": "0"
                },
                {
                    "zipcode": "36780",
                    "province": "Pontevedra",
                    "city": "Guarda, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36788",
                    "province": "Pontevedra",
                    "city": "Guarda, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36789",
                    "province": "Pontevedra",
                    "city": "Guarda, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36790",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36791",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36792",
                    "province": "Pontevedra",
                    "city": "Tomiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36794",
                    "province": "Pontevedra",
                    "city": "Oia",
                    "multiple": "0"
                },
                {
                    "zipcode": "36800",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36810",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36811",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36812",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36813",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36814",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36815",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36816",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36817",
                    "province": "Pontevedra",
                    "city": "Pazos de Borbén",
                    "multiple": "0"
                },
                {
                    "zipcode": "36817",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "1"
                },
                {
                    "zipcode": "36818",
                    "province": "Pontevedra",
                    "city": "Redondela",
                    "multiple": "0"
                },
                {
                    "zipcode": "36820",
                    "province": "Pontevedra",
                    "city": "Ponte Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36826",
                    "province": "Pontevedra",
                    "city": "Ponte Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36827",
                    "province": "Pontevedra",
                    "city": "Ponte Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36828",
                    "province": "Pontevedra",
                    "city": "Ponte Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36829",
                    "province": "Pontevedra",
                    "city": "Ponte Caldelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36830",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36835",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36836",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36837",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36838",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36839",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36840",
                    "province": "Pontevedra",
                    "city": "Pazos de Borbén",
                    "multiple": "0"
                },
                {
                    "zipcode": "36841",
                    "province": "Pontevedra",
                    "city": "Pazos de Borbén",
                    "multiple": "0"
                },
                {
                    "zipcode": "36842",
                    "province": "Pontevedra",
                    "city": "Pazos de Borbén",
                    "multiple": "0"
                },
                {
                    "zipcode": "36843",
                    "province": "Pontevedra",
                    "city": "Pazos de Borbén",
                    "multiple": "0"
                },
                {
                    "zipcode": "36844",
                    "province": "Pontevedra",
                    "city": "Pazos de Borbén",
                    "multiple": "0"
                },
                {
                    "zipcode": "36845",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36846",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36847",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36848",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36849",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36850",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36851",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36852",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36853",
                    "province": "Pontevedra",
                    "city": "Lama, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36853",
                    "province": "Pontevedra",
                    "city": "Ponte Caldelas",
                    "multiple": "1"
                },
                {
                    "zipcode": "36854",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36855",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36856",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36857",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36858",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36859",
                    "province": "Pontevedra",
                    "city": "Cotobade",
                    "multiple": "0"
                },
                {
                    "zipcode": "36860",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36861",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36862",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36863",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36864",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36865",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36866",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36867",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36868",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36869",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36870",
                    "province": "Pontevedra",
                    "city": "Mondariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "36872",
                    "province": "Pontevedra",
                    "city": "Covelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36873",
                    "province": "Pontevedra",
                    "city": "Covelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36874",
                    "province": "Pontevedra",
                    "city": "Mondariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "36875",
                    "province": "Pontevedra",
                    "city": "Fornelos de Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "36875",
                    "province": "Pontevedra",
                    "city": "Mondariz",
                    "multiple": "1"
                },
                {
                    "zipcode": "36876",
                    "province": "Pontevedra",
                    "city": "Mondariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "36876",
                    "province": "Pontevedra",
                    "city": "Covelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "36877",
                    "province": "Pontevedra",
                    "city": "Covelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36877",
                    "province": "Pontevedra",
                    "city": "Mondariz",
                    "multiple": "1"
                },
                {
                    "zipcode": "36878",
                    "province": "Pontevedra",
                    "city": "Mondariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "36878",
                    "province": "Pontevedra",
                    "city": "Covelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "36879",
                    "province": "Pontevedra",
                    "city": "Mondariz",
                    "multiple": "0"
                },
                {
                    "zipcode": "36880",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36883",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36884",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36885",
                    "province": "Pontevedra",
                    "city": "Crecente",
                    "multiple": "0"
                },
                {
                    "zipcode": "36885",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "36886",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36887",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36888",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "0"
                },
                {
                    "zipcode": "36889",
                    "province": "Pontevedra",
                    "city": "Covelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36889",
                    "province": "Pontevedra",
                    "city": "Cañiza, A",
                    "multiple": "1"
                },
                {
                    "zipcode": "36890",
                    "province": "Pontevedra",
                    "city": "Mondariz-Balneario",
                    "multiple": "0"
                },
                {
                    "zipcode": "36891",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36892",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36893",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36895",
                    "province": "Pontevedra",
                    "city": "Ponteareas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36900",
                    "province": "Pontevedra",
                    "city": "Marín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36910",
                    "province": "Pontevedra",
                    "city": "Pontevedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "36911",
                    "province": "Pontevedra",
                    "city": "Marín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36912",
                    "province": "Pontevedra",
                    "city": "Marín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36913",
                    "province": "Pontevedra",
                    "city": "Marín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36914",
                    "province": "Pontevedra",
                    "city": "Marín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36915",
                    "province": "Pontevedra",
                    "city": "Marín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36920",
                    "province": "Pontevedra",
                    "city": "Marín",
                    "multiple": "0"
                },
                {
                    "zipcode": "36930",
                    "province": "Pontevedra",
                    "city": "Bueu",
                    "multiple": "0"
                },
                {
                    "zipcode": "36937",
                    "province": "Pontevedra",
                    "city": "Bueu",
                    "multiple": "0"
                },
                {
                    "zipcode": "36938",
                    "province": "Pontevedra",
                    "city": "Bueu",
                    "multiple": "0"
                },
                {
                    "zipcode": "36939",
                    "province": "Pontevedra",
                    "city": "Bueu",
                    "multiple": "0"
                },
                {
                    "zipcode": "36940",
                    "province": "Pontevedra",
                    "city": "Cangas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36945",
                    "province": "Pontevedra",
                    "city": "Cangas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36946",
                    "province": "Pontevedra",
                    "city": "Cangas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36947",
                    "province": "Pontevedra",
                    "city": "Cangas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36948",
                    "province": "Pontevedra",
                    "city": "Cangas",
                    "multiple": "0"
                },
                {
                    "zipcode": "36950",
                    "province": "Pontevedra",
                    "city": "Moaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36954",
                    "province": "Pontevedra",
                    "city": "Moaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36955",
                    "province": "Pontevedra",
                    "city": "Moaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36957",
                    "province": "Pontevedra",
                    "city": "Moaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36958",
                    "province": "Pontevedra",
                    "city": "Moaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36959",
                    "province": "Pontevedra",
                    "city": "Moaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "36960",
                    "province": "Pontevedra",
                    "city": "Sanxenxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36966",
                    "province": "Pontevedra",
                    "city": "Sanxenxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36967",
                    "province": "Pontevedra",
                    "city": "Meaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36968",
                    "province": "Pontevedra",
                    "city": "Meaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36969",
                    "province": "Pontevedra",
                    "city": "Meaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "36969",
                    "province": "Pontevedra",
                    "city": "Sanxenxo",
                    "multiple": "1"
                },
                {
                    "zipcode": "36970",
                    "province": "Pontevedra",
                    "city": "Sanxenxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36979",
                    "province": "Pontevedra",
                    "city": "Sanxenxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36980",
                    "province": "Pontevedra",
                    "city": "Grove, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36988",
                    "province": "Pontevedra",
                    "city": "Grove, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36989",
                    "province": "Pontevedra",
                    "city": "Grove, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36990",
                    "province": "Pontevedra",
                    "city": "Sanxenxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "36991",
                    "province": "Pontevedra",
                    "city": "Grove, O",
                    "multiple": "0"
                },
                {
                    "zipcode": "36992",
                    "province": "Pontevedra",
                    "city": "Poio",
                    "multiple": "0"
                },
                {
                    "zipcode": "36993",
                    "province": "Pontevedra",
                    "city": "Poio",
                    "multiple": "0"
                },
                {
                    "zipcode": "36995",
                    "province": "Pontevedra",
                    "city": "Poio",
                    "multiple": "0"
                },
                {
                    "zipcode": "37001",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37002",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37003",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37004",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37005",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37006",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37007",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37008",
                    "province": "Salamanca",
                    "city": "Carbajosa de la Sagrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37008",
                    "province": "Salamanca",
                    "city": "Salamanca",
                    "multiple": "1"
                },
                {
                    "zipcode": "37100",
                    "province": "Salamanca",
                    "city": "Ledesma",
                    "multiple": "0"
                },
                {
                    "zipcode": "37110",
                    "province": "Salamanca",
                    "city": "San Pelayo de Guareña",
                    "multiple": "0"
                },
                {
                    "zipcode": "37110",
                    "province": "Salamanca",
                    "city": "Zamayón",
                    "multiple": "1"
                },
                {
                    "zipcode": "37110",
                    "province": "Salamanca",
                    "city": "Santiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "37110",
                    "province": "Salamanca",
                    "city": "Torresmenudas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37110",
                    "province": "Salamanca",
                    "city": "Aldearrodrigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37110",
                    "province": "Salamanca",
                    "city": "Arco, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37111",
                    "province": "Salamanca",
                    "city": "San Pelayo de Guareña",
                    "multiple": "0"
                },
                {
                    "zipcode": "37111",
                    "province": "Salamanca",
                    "city": "Añover de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37111",
                    "province": "Salamanca",
                    "city": "Palacios del Arzobispo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37114",
                    "province": "Salamanca",
                    "city": "Gejuelo del Barro",
                    "multiple": "0"
                },
                {
                    "zipcode": "37114",
                    "province": "Salamanca",
                    "city": "Encina de San Silvestre",
                    "multiple": "1"
                },
                {
                    "zipcode": "37114",
                    "province": "Salamanca",
                    "city": "Villaseco de los Gamitos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37115",
                    "province": "Salamanca",
                    "city": "Villarmayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "37115",
                    "province": "Salamanca",
                    "city": "Valverdón",
                    "multiple": "1"
                },
                {
                    "zipcode": "37115",
                    "province": "Salamanca",
                    "city": "Almenara de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37115",
                    "province": "Salamanca",
                    "city": "Ledesma",
                    "multiple": "1"
                },
                {
                    "zipcode": "37115",
                    "province": "Salamanca",
                    "city": "Juzbado",
                    "multiple": "1"
                },
                {
                    "zipcode": "37115",
                    "province": "Salamanca",
                    "city": "Vega de Tirados",
                    "multiple": "1"
                },
                {
                    "zipcode": "37116",
                    "province": "Salamanca",
                    "city": "Ledesma",
                    "multiple": "0"
                },
                {
                    "zipcode": "37117",
                    "province": "Salamanca",
                    "city": "Doñinos de Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37120",
                    "province": "Salamanca",
                    "city": "Carrascal de Barregas",
                    "multiple": "0"
                },
                {
                    "zipcode": "37120",
                    "province": "Salamanca",
                    "city": "Doñinos de Salamanca",
                    "multiple": "1"
                },
                {
                    "zipcode": "37129",
                    "province": "Salamanca",
                    "city": "Doñinos de Salamanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37129",
                    "province": "Salamanca",
                    "city": "Carrascal de Barregas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37129",
                    "province": "Salamanca",
                    "city": "Parada de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "37129",
                    "province": "Salamanca",
                    "city": "Galindo y Perahuy",
                    "multiple": "1"
                },
                {
                    "zipcode": "37129",
                    "province": "Salamanca",
                    "city": "Florida de Liébana",
                    "multiple": "1"
                },
                {
                    "zipcode": "37130",
                    "province": "Salamanca",
                    "city": "Robliza de Cojos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37130",
                    "province": "Salamanca",
                    "city": "Villarmayor",
                    "multiple": "1"
                },
                {
                    "zipcode": "37130",
                    "province": "Salamanca",
                    "city": "Tabera de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37130",
                    "province": "Salamanca",
                    "city": "Canillas de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37130",
                    "province": "Salamanca",
                    "city": "Mata de Ledesma, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37130",
                    "province": "Salamanca",
                    "city": "Doñinos de Ledesma",
                    "multiple": "1"
                },
                {
                    "zipcode": "37140",
                    "province": "Salamanca",
                    "city": "Villaseco de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37147",
                    "province": "Salamanca",
                    "city": "Villar de Peralonso",
                    "multiple": "0"
                },
                {
                    "zipcode": "37148",
                    "province": "Salamanca",
                    "city": "Villar de Peralonso",
                    "multiple": "0"
                },
                {
                    "zipcode": "37148",
                    "province": "Salamanca",
                    "city": "Villaseco de los Reyes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37148",
                    "province": "Salamanca",
                    "city": "Tremedal de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37148",
                    "province": "Salamanca",
                    "city": "Espadaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "37149",
                    "province": "Salamanca",
                    "city": "Tremedal de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37149",
                    "province": "Salamanca",
                    "city": "Villaseco de los Reyes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37150",
                    "province": "Salamanca",
                    "city": "Villaseco de los Reyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37159",
                    "province": "Salamanca",
                    "city": "Puertas",
                    "multiple": "0"
                },
                {
                    "zipcode": "37159",
                    "province": "Salamanca",
                    "city": "Villaseco de los Reyes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37160",
                    "province": "Salamanca",
                    "city": "Villarino de los Aires",
                    "multiple": "0"
                },
                {
                    "zipcode": "37169",
                    "province": "Salamanca",
                    "city": "Villarino de los Aires",
                    "multiple": "0"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Vega de Tirados",
                    "multiple": "0"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Villarmayor",
                    "multiple": "1"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "San Pedro del Valle",
                    "multiple": "1"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Mata de Ledesma, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Carrascal de Barregas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Ciudad Rodrigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Golpejas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Pino de Tormes, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37170",
                    "province": "Salamanca",
                    "city": "Zarapicos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37171",
                    "province": "Salamanca",
                    "city": "Manzano, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37171",
                    "province": "Salamanca",
                    "city": "Monleras",
                    "multiple": "1"
                },
                {
                    "zipcode": "37172",
                    "province": "Salamanca",
                    "city": "Sardón de los Frailes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37173",
                    "province": "Salamanca",
                    "city": "Ahigal de Villarino",
                    "multiple": "0"
                },
                {
                    "zipcode": "37173",
                    "province": "Salamanca",
                    "city": "Trabanca",
                    "multiple": "1"
                },
                {
                    "zipcode": "37174",
                    "province": "Salamanca",
                    "city": "Villarino de los Aires",
                    "multiple": "0"
                },
                {
                    "zipcode": "37175",
                    "province": "Salamanca",
                    "city": "Pereña de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "37176",
                    "province": "Salamanca",
                    "city": "Almendra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37181",
                    "province": "Salamanca",
                    "city": "Pelabravo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37181",
                    "province": "Salamanca",
                    "city": "Calvarrasa de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37183",
                    "province": "Salamanca",
                    "city": "Mozárbez",
                    "multiple": "0"
                },
                {
                    "zipcode": "37183",
                    "province": "Salamanca",
                    "city": "Morille",
                    "multiple": "1"
                },
                {
                    "zipcode": "37183",
                    "province": "Salamanca",
                    "city": "San Pedro de Rozados",
                    "multiple": "1"
                },
                {
                    "zipcode": "37184",
                    "province": "Salamanca",
                    "city": "Villares de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "37185",
                    "province": "Salamanca",
                    "city": "Valverdón",
                    "multiple": "0"
                },
                {
                    "zipcode": "37185",
                    "province": "Salamanca",
                    "city": "Castellanos de Villiquera",
                    "multiple": "1"
                },
                {
                    "zipcode": "37185",
                    "province": "Salamanca",
                    "city": "Villamayor",
                    "multiple": "1"
                },
                {
                    "zipcode": "37186",
                    "province": "Salamanca",
                    "city": "San Pedro de Rozados",
                    "multiple": "0"
                },
                {
                    "zipcode": "37186",
                    "province": "Salamanca",
                    "city": "Aldeatejada",
                    "multiple": "1"
                },
                {
                    "zipcode": "37186",
                    "province": "Salamanca",
                    "city": "Barbadillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37186",
                    "province": "Salamanca",
                    "city": "Mozárbez",
                    "multiple": "1"
                },
                {
                    "zipcode": "37187",
                    "province": "Salamanca",
                    "city": "Aldeatejada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37187",
                    "province": "Salamanca",
                    "city": "Miranda de Azán",
                    "multiple": "1"
                },
                {
                    "zipcode": "37188",
                    "province": "Salamanca",
                    "city": "Arapiles",
                    "multiple": "0"
                },
                {
                    "zipcode": "37188",
                    "province": "Salamanca",
                    "city": "Carbajosa de la Sagrada",
                    "multiple": "1"
                },
                {
                    "zipcode": "37189",
                    "province": "Salamanca",
                    "city": "Villares de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "37190",
                    "province": "Salamanca",
                    "city": "Terradillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37191",
                    "province": "Salamanca",
                    "city": "Calvarrasa de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37191",
                    "province": "Salamanca",
                    "city": "Carbajosa de la Sagrada",
                    "multiple": "1"
                },
                {
                    "zipcode": "37192",
                    "province": "Salamanca",
                    "city": "Aldeatejada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37192",
                    "province": "Salamanca",
                    "city": "Carrascal de Barregas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37193",
                    "province": "Salamanca",
                    "city": "Cabrerizos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37196",
                    "province": "Salamanca",
                    "city": "Mozárbez",
                    "multiple": "0"
                },
                {
                    "zipcode": "37200",
                    "province": "Salamanca",
                    "city": "Fuente de San Esteban, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37208",
                    "province": "Salamanca",
                    "city": "Fuente de San Esteban, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37208",
                    "province": "Salamanca",
                    "city": "San Muñoz",
                    "multiple": "1"
                },
                {
                    "zipcode": "37209",
                    "province": "Salamanca",
                    "city": "Pelarrodríguez",
                    "multiple": "0"
                },
                {
                    "zipcode": "37209",
                    "province": "Salamanca",
                    "city": "Buenamadre",
                    "multiple": "1"
                },
                {
                    "zipcode": "37210",
                    "province": "Salamanca",
                    "city": "Vitigudino",
                    "multiple": "0"
                },
                {
                    "zipcode": "37214",
                    "province": "Salamanca",
                    "city": "Valsalabroso",
                    "multiple": "0"
                },
                {
                    "zipcode": "37214",
                    "province": "Salamanca",
                    "city": "Cabeza del Caballo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37214",
                    "province": "Salamanca",
                    "city": "Vídola, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37214",
                    "province": "Salamanca",
                    "city": "Peña, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37216",
                    "province": "Salamanca",
                    "city": "Cipérez",
                    "multiple": "0"
                },
                {
                    "zipcode": "37216",
                    "province": "Salamanca",
                    "city": "Moronta",
                    "multiple": "1"
                },
                {
                    "zipcode": "37216",
                    "province": "Salamanca",
                    "city": "Peralejos de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37216",
                    "province": "Salamanca",
                    "city": "Peralejos de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "37216",
                    "province": "Salamanca",
                    "city": "Pozos de Hinojo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37217",
                    "province": "Salamanca",
                    "city": "Villarmuerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "37217",
                    "province": "Salamanca",
                    "city": "Valsalabroso",
                    "multiple": "1"
                },
                {
                    "zipcode": "37217",
                    "province": "Salamanca",
                    "city": "Iruelos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37217",
                    "province": "Salamanca",
                    "city": "Brincones",
                    "multiple": "1"
                },
                {
                    "zipcode": "37217",
                    "province": "Salamanca",
                    "city": "Villar de Samaniego",
                    "multiple": "1"
                },
                {
                    "zipcode": "37217",
                    "province": "Salamanca",
                    "city": "Barceo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37217",
                    "province": "Salamanca",
                    "city": "Sanchón de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "37219",
                    "province": "Salamanca",
                    "city": "Retortillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37219",
                    "province": "Salamanca",
                    "city": "Guadramiro",
                    "multiple": "1"
                },
                {
                    "zipcode": "37219",
                    "province": "Salamanca",
                    "city": "Yecla de Yeltes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37220",
                    "province": "Salamanca",
                    "city": "Fregeneda, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37230",
                    "province": "Salamanca",
                    "city": "Hinojosa de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "37240",
                    "province": "Salamanca",
                    "city": "Lumbrales",
                    "multiple": "0"
                },
                {
                    "zipcode": "37246",
                    "province": "Salamanca",
                    "city": "Sobradillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37247",
                    "province": "Salamanca",
                    "city": "Redonda, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37248",
                    "province": "Salamanca",
                    "city": "Ahigal de los Aceiteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "37250",
                    "province": "Salamanca",
                    "city": "Aldeadávila de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "37251",
                    "province": "Salamanca",
                    "city": "Masueco",
                    "multiple": "0"
                },
                {
                    "zipcode": "37251",
                    "province": "Salamanca",
                    "city": "Aldeadávila de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "37253",
                    "province": "Salamanca",
                    "city": "Cerezal de Peñahorcada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37253",
                    "province": "Salamanca",
                    "city": "Aldeadávila de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "37253",
                    "province": "Salamanca",
                    "city": "Zarza de Pumareda, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37254",
                    "province": "Salamanca",
                    "city": "Mieza",
                    "multiple": "0"
                },
                {
                    "zipcode": "37255",
                    "province": "Salamanca",
                    "city": "Barruecopardo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37256",
                    "province": "Salamanca",
                    "city": "Encinasola de los Comendadores",
                    "multiple": "0"
                },
                {
                    "zipcode": "37256",
                    "province": "Salamanca",
                    "city": "Milano, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37256",
                    "province": "Salamanca",
                    "city": "Valderrodrigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37256",
                    "province": "Salamanca",
                    "city": "Villasbuenas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37257",
                    "province": "Salamanca",
                    "city": "Saucelle",
                    "multiple": "0"
                },
                {
                    "zipcode": "37258",
                    "province": "Salamanca",
                    "city": "Vilvestre",
                    "multiple": "0"
                },
                {
                    "zipcode": "37259",
                    "province": "Salamanca",
                    "city": "Saldeana",
                    "multiple": "0"
                },
                {
                    "zipcode": "37260",
                    "province": "Salamanca",
                    "city": "Villavieja de Yeltes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37267",
                    "province": "Salamanca",
                    "city": "Villares de Yeltes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37270",
                    "province": "Salamanca",
                    "city": "San Felices de los Gallegos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37271",
                    "province": "Salamanca",
                    "city": "Bañobárez",
                    "multiple": "0"
                },
                {
                    "zipcode": "37273",
                    "province": "Salamanca",
                    "city": "Cerralbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37281",
                    "province": "Salamanca",
                    "city": "Cubo de Don Sancho, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37281",
                    "province": "Salamanca",
                    "city": "Pozos de Hinojo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37290",
                    "province": "Salamanca",
                    "city": "Boada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37291",
                    "province": "Salamanca",
                    "city": "Cerralbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37291",
                    "province": "Salamanca",
                    "city": "Fuenteliante",
                    "multiple": "1"
                },
                {
                    "zipcode": "37291",
                    "province": "Salamanca",
                    "city": "Bermellar",
                    "multiple": "1"
                },
                {
                    "zipcode": "37291",
                    "province": "Salamanca",
                    "city": "Bogajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37292",
                    "province": "Salamanca",
                    "city": "Olmedo de Camaces",
                    "multiple": "0"
                },
                {
                    "zipcode": "37300",
                    "province": "Salamanca",
                    "city": "Peñaranda de Bracamonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "37310",
                    "province": "Salamanca",
                    "city": "Macotera",
                    "multiple": "0"
                },
                {
                    "zipcode": "37311",
                    "province": "Salamanca",
                    "city": "Santiago de la Puebla",
                    "multiple": "0"
                },
                {
                    "zipcode": "37312",
                    "province": "Salamanca",
                    "city": "Alaraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "37313",
                    "province": "Salamanca",
                    "city": "Malpartida",
                    "multiple": "0"
                },
                {
                    "zipcode": "37314",
                    "province": "Salamanca",
                    "city": "Salmoral",
                    "multiple": "0"
                },
                {
                    "zipcode": "37315",
                    "province": "Salamanca",
                    "city": "Mancera de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37316",
                    "province": "Salamanca",
                    "city": "Bóveda del Río Almar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37317",
                    "province": "Salamanca",
                    "city": "Campo de Peñaranda, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37317",
                    "province": "Salamanca",
                    "city": "Aldeaseca de la Frontera",
                    "multiple": "1"
                },
                {
                    "zipcode": "37318",
                    "province": "Salamanca",
                    "city": "Rágama",
                    "multiple": "0"
                },
                {
                    "zipcode": "37318",
                    "province": "Salamanca",
                    "city": "Paradinas de San Juan",
                    "multiple": "1"
                },
                {
                    "zipcode": "37319",
                    "province": "Salamanca",
                    "city": "Cantaracillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37320",
                    "province": "Salamanca",
                    "city": "Villar de Gallimazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37329",
                    "province": "Salamanca",
                    "city": "Alconada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37329",
                    "province": "Salamanca",
                    "city": "Ventosa del Río Almar",
                    "multiple": "1"
                },
                {
                    "zipcode": "37330",
                    "province": "Salamanca",
                    "city": "Babilafuente",
                    "multiple": "0"
                },
                {
                    "zipcode": "37336",
                    "province": "Salamanca",
                    "city": "Huerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "37337",
                    "province": "Salamanca",
                    "city": "Moríñigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37337",
                    "province": "Salamanca",
                    "city": "Cordovilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "37338",
                    "province": "Salamanca",
                    "city": "Villoruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "37339",
                    "province": "Salamanca",
                    "city": "Villoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "37340",
                    "province": "Salamanca",
                    "city": "Aldearrubia",
                    "multiple": "0"
                },
                {
                    "zipcode": "37340",
                    "province": "Salamanca",
                    "city": "San Morales",
                    "multiple": "1"
                },
                {
                    "zipcode": "37350",
                    "province": "Salamanca",
                    "city": "Aldealengua",
                    "multiple": "0"
                },
                {
                    "zipcode": "37400",
                    "province": "Salamanca",
                    "city": "Cantalapiedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37405",
                    "province": "Salamanca",
                    "city": "Cantalpino",
                    "multiple": "0"
                },
                {
                    "zipcode": "37406",
                    "province": "Salamanca",
                    "city": "Palaciosrubios",
                    "multiple": "0"
                },
                {
                    "zipcode": "37406",
                    "province": "Salamanca",
                    "city": "Poveda de las Cintas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37406",
                    "province": "Salamanca",
                    "city": "Villaflores",
                    "multiple": "1"
                },
                {
                    "zipcode": "37408",
                    "province": "Salamanca",
                    "city": "Zorita de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "37409",
                    "province": "Salamanca",
                    "city": "Tarazona de Guareña",
                    "multiple": "0"
                },
                {
                    "zipcode": "37410",
                    "province": "Salamanca",
                    "city": "Pedroso de la Armuña, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37418",
                    "province": "Salamanca",
                    "city": "Arabayona de Mógica",
                    "multiple": "0"
                },
                {
                    "zipcode": "37419",
                    "province": "Salamanca",
                    "city": "Espino de la Orbada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37419",
                    "province": "Salamanca",
                    "city": "Parada de Rubiales",
                    "multiple": "1"
                },
                {
                    "zipcode": "37420",
                    "province": "Salamanca",
                    "city": "Gomecello",
                    "multiple": "0"
                },
                {
                    "zipcode": "37426",
                    "province": "Salamanca",
                    "city": "Palencia de Negrilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "37426",
                    "province": "Salamanca",
                    "city": "Negrilla de Palencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "37427",
                    "province": "Salamanca",
                    "city": "Pedrosillo el Ralo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37427",
                    "province": "Salamanca",
                    "city": "Orbada, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37427",
                    "province": "Salamanca",
                    "city": "Vellés, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37428",
                    "province": "Salamanca",
                    "city": "Pajares de la Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "37428",
                    "province": "Salamanca",
                    "city": "Orbada, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37428",
                    "province": "Salamanca",
                    "city": "Villaverde de Guareña",
                    "multiple": "1"
                },
                {
                    "zipcode": "37429",
                    "province": "Salamanca",
                    "city": "Arcediano",
                    "multiple": "0"
                },
                {
                    "zipcode": "37429",
                    "province": "Salamanca",
                    "city": "Aldeanueva de Figueroa",
                    "multiple": "1"
                },
                {
                    "zipcode": "37429",
                    "province": "Salamanca",
                    "city": "Tardáguila",
                    "multiple": "1"
                },
                {
                    "zipcode": "37430",
                    "province": "Salamanca",
                    "city": "Moriscos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37439",
                    "province": "Salamanca",
                    "city": "Castellanos de Moriscos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37439",
                    "province": "Salamanca",
                    "city": "San Cristóbal de la Cuesta",
                    "multiple": "1"
                },
                {
                    "zipcode": "37440",
                    "province": "Salamanca",
                    "city": "Barbadillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37440",
                    "province": "Salamanca",
                    "city": "Carrascal de Barregas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37440",
                    "province": "Salamanca",
                    "city": "Calzada de Don Diego",
                    "multiple": "1"
                },
                {
                    "zipcode": "37446",
                    "province": "Salamanca",
                    "city": "Villalba de los Llanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37447",
                    "province": "Salamanca",
                    "city": "Rollán",
                    "multiple": "0"
                },
                {
                    "zipcode": "37448",
                    "province": "Salamanca",
                    "city": "Canillas de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37448",
                    "province": "Salamanca",
                    "city": "Calzada de Don Diego",
                    "multiple": "1"
                },
                {
                    "zipcode": "37449",
                    "province": "Salamanca",
                    "city": "Galindo y Perahuy",
                    "multiple": "0"
                },
                {
                    "zipcode": "37449",
                    "province": "Salamanca",
                    "city": "Parada de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "37449",
                    "province": "Salamanca",
                    "city": "Carrascal de Barregas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37450",
                    "province": "Salamanca",
                    "city": "Matilla de los Caños del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "37450",
                    "province": "Salamanca",
                    "city": "Vecinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37451",
                    "province": "Salamanca",
                    "city": "Carrascal del Obispo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37452",
                    "province": "Salamanca",
                    "city": "Narros de Matalayegua",
                    "multiple": "0"
                },
                {
                    "zipcode": "37452",
                    "province": "Salamanca",
                    "city": "Vecinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37452",
                    "province": "Salamanca",
                    "city": "Veguillas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "37453",
                    "province": "Salamanca",
                    "city": "Vecinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37453",
                    "province": "Salamanca",
                    "city": "Matilla de los Caños del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "37453",
                    "province": "Salamanca",
                    "city": "San Pedro de Rozados",
                    "multiple": "1"
                },
                {
                    "zipcode": "37454",
                    "province": "Salamanca",
                    "city": "Veguillas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "37455",
                    "province": "Salamanca",
                    "city": "San Pedro de Rozados",
                    "multiple": "0"
                },
                {
                    "zipcode": "37455",
                    "province": "Salamanca",
                    "city": "Veguillas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "37456",
                    "province": "Salamanca",
                    "city": "Veguillas, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "37456",
                    "province": "Salamanca",
                    "city": "Vecinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37460",
                    "province": "Salamanca",
                    "city": "Garcirrey",
                    "multiple": "0"
                },
                {
                    "zipcode": "37460",
                    "province": "Salamanca",
                    "city": "Aldehuela de la Bóveda",
                    "multiple": "1"
                },
                {
                    "zipcode": "37460",
                    "province": "Salamanca",
                    "city": "Sando",
                    "multiple": "1"
                },
                {
                    "zipcode": "37460",
                    "province": "Salamanca",
                    "city": "Tabera de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37465",
                    "province": "Salamanca",
                    "city": "Sagrada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37466",
                    "province": "Salamanca",
                    "city": "Aldehuela de la Bóveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "37466",
                    "province": "Salamanca",
                    "city": "Sanchón de la Sagrada",
                    "multiple": "1"
                },
                {
                    "zipcode": "37468",
                    "province": "Salamanca",
                    "city": "Santa María de Sando",
                    "multiple": "0"
                },
                {
                    "zipcode": "37468",
                    "province": "Salamanca",
                    "city": "Villasdardo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37468",
                    "province": "Salamanca",
                    "city": "Cipérez",
                    "multiple": "1"
                },
                {
                    "zipcode": "37468",
                    "province": "Salamanca",
                    "city": "Sando",
                    "multiple": "1"
                },
                {
                    "zipcode": "37470",
                    "province": "Salamanca",
                    "city": "Castillejo de Martín Viejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37470",
                    "province": "Salamanca",
                    "city": "Sancti-Spíritus",
                    "multiple": "1"
                },
                {
                    "zipcode": "37478",
                    "province": "Salamanca",
                    "city": "Alba de Yeltes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37478",
                    "province": "Salamanca",
                    "city": "Dios le Guarde",
                    "multiple": "1"
                },
                {
                    "zipcode": "37478",
                    "province": "Salamanca",
                    "city": "Aldehuela de Yeltes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37479",
                    "province": "Salamanca",
                    "city": "Sancti-Spíritus",
                    "multiple": "0"
                },
                {
                    "zipcode": "37480",
                    "province": "Salamanca",
                    "city": "Fuentes de Oñoro",
                    "multiple": "0"
                },
                {
                    "zipcode": "37481",
                    "province": "Salamanca",
                    "city": "Fuentes de Oñoro",
                    "multiple": "0"
                },
                {
                    "zipcode": "37488",
                    "province": "Salamanca",
                    "city": "Villar de la Yegua",
                    "multiple": "0"
                },
                {
                    "zipcode": "37488",
                    "province": "Salamanca",
                    "city": "Puerto Seguro",
                    "multiple": "1"
                },
                {
                    "zipcode": "37488",
                    "province": "Salamanca",
                    "city": "Villar de Ciervo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37488",
                    "province": "Salamanca",
                    "city": "Aldea del Obispo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37488",
                    "province": "Salamanca",
                    "city": "Bouza, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37490",
                    "province": "Salamanca",
                    "city": "Cabezabellosa de la Calzada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37490",
                    "province": "Salamanca",
                    "city": "Pitiegua",
                    "multiple": "1"
                },
                {
                    "zipcode": "37492",
                    "province": "Salamanca",
                    "city": "Robliza de Cojos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37493",
                    "province": "Salamanca",
                    "city": "Pelarrodríguez",
                    "multiple": "0"
                },
                {
                    "zipcode": "37493",
                    "province": "Salamanca",
                    "city": "Buenamadre",
                    "multiple": "1"
                },
                {
                    "zipcode": "37493",
                    "province": "Salamanca",
                    "city": "Fuente de San Esteban, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37494",
                    "province": "Salamanca",
                    "city": "Martín de Yeltes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37495",
                    "province": "Salamanca",
                    "city": "Retortillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37496",
                    "province": "Salamanca",
                    "city": "Martín de Yeltes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37496",
                    "province": "Salamanca",
                    "city": "Castraz",
                    "multiple": "1"
                },
                {
                    "zipcode": "37497",
                    "province": "Salamanca",
                    "city": "Villar de la Yegua",
                    "multiple": "0"
                },
                {
                    "zipcode": "37497",
                    "province": "Salamanca",
                    "city": "Gallegos de Argañán",
                    "multiple": "1"
                },
                {
                    "zipcode": "37497",
                    "province": "Salamanca",
                    "city": "Espeja",
                    "multiple": "1"
                },
                {
                    "zipcode": "37497",
                    "province": "Salamanca",
                    "city": "Alameda de Gardón, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37497",
                    "province": "Salamanca",
                    "city": "Villar de Argañán",
                    "multiple": "1"
                },
                {
                    "zipcode": "37497",
                    "province": "Salamanca",
                    "city": "Carpio de Azaba",
                    "multiple": "1"
                },
                {
                    "zipcode": "37500",
                    "province": "Salamanca",
                    "city": "Ciudad Rodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37510",
                    "province": "Salamanca",
                    "city": "Pastores",
                    "multiple": "0"
                },
                {
                    "zipcode": "37510",
                    "province": "Salamanca",
                    "city": "Herguijuela de Ciudad Rodrigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37510",
                    "province": "Salamanca",
                    "city": "Agallas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37510",
                    "province": "Salamanca",
                    "city": "Sahugo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37510",
                    "province": "Salamanca",
                    "city": "Martiago",
                    "multiple": "1"
                },
                {
                    "zipcode": "37515",
                    "province": "Salamanca",
                    "city": "Encina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37516",
                    "province": "Salamanca",
                    "city": "Herguijuela de Ciudad Rodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37520",
                    "province": "Salamanca",
                    "city": "Bodón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37521",
                    "province": "Salamanca",
                    "city": "Robleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "37522",
                    "province": "Salamanca",
                    "city": "Villasrubias",
                    "multiple": "0"
                },
                {
                    "zipcode": "37523",
                    "province": "Salamanca",
                    "city": "Peñaparda",
                    "multiple": "0"
                },
                {
                    "zipcode": "37524",
                    "province": "Salamanca",
                    "city": "Payo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37530",
                    "province": "Salamanca",
                    "city": "Serradilla del Llano",
                    "multiple": "0"
                },
                {
                    "zipcode": "37531",
                    "province": "Salamanca",
                    "city": "Serradilla del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37532",
                    "province": "Salamanca",
                    "city": "Monsagro",
                    "multiple": "0"
                },
                {
                    "zipcode": "37540",
                    "province": "Salamanca",
                    "city": "Fuenteguinaldo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37541",
                    "province": "Salamanca",
                    "city": "Casillas de Flores",
                    "multiple": "0"
                },
                {
                    "zipcode": "37542",
                    "province": "Salamanca",
                    "city": "Navasfrías",
                    "multiple": "0"
                },
                {
                    "zipcode": "37550",
                    "province": "Salamanca",
                    "city": "Campillo de Azaba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37551",
                    "province": "Salamanca",
                    "city": "Ituero de Azaba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37552",
                    "province": "Salamanca",
                    "city": "Puebla de Azaba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37553",
                    "province": "Salamanca",
                    "city": "Puebla de Azaba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37554",
                    "province": "Salamanca",
                    "city": "Alamedilla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37555",
                    "province": "Salamanca",
                    "city": "Alberguería de Argañán, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37589",
                    "province": "Salamanca",
                    "city": "Tenebrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "37590",
                    "province": "Salamanca",
                    "city": "Ciudad Rodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37590",
                    "province": "Salamanca",
                    "city": "Morasverdes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37591",
                    "province": "Salamanca",
                    "city": "Zamarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37591",
                    "province": "Salamanca",
                    "city": "Atalaya, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37591",
                    "province": "Salamanca",
                    "city": "Ciudad Rodrigo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37592",
                    "province": "Salamanca",
                    "city": "Castillejo de Martín Viejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37592",
                    "province": "Salamanca",
                    "city": "Saelices el Chico",
                    "multiple": "1"
                },
                {
                    "zipcode": "37593",
                    "province": "Salamanca",
                    "city": "Ciudad Rodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37594",
                    "province": "Salamanca",
                    "city": "Ciudad Rodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37595",
                    "province": "Salamanca",
                    "city": "Ciudad Rodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37596",
                    "province": "Salamanca",
                    "city": "Serradilla del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37600",
                    "province": "Salamanca",
                    "city": "Tamames",
                    "multiple": "0"
                },
                {
                    "zipcode": "37606",
                    "province": "Salamanca",
                    "city": "Puebla de Yeltes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37607",
                    "province": "Salamanca",
                    "city": "Barbalos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37607",
                    "province": "Salamanca",
                    "city": "Navarredonda de la Rinconada",
                    "multiple": "1"
                },
                {
                    "zipcode": "37607",
                    "province": "Salamanca",
                    "city": "Rinconada de la Sierra, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37607",
                    "province": "Salamanca",
                    "city": "Tejeda y Segoyuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "37609",
                    "province": "Salamanca",
                    "city": "Narros de Matalayegua",
                    "multiple": "0"
                },
                {
                    "zipcode": "37609",
                    "province": "Salamanca",
                    "city": "Carrascal del Obispo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37609",
                    "province": "Salamanca",
                    "city": "Tamames",
                    "multiple": "1"
                },
                {
                    "zipcode": "37609",
                    "province": "Salamanca",
                    "city": "Berrocal de Huebra",
                    "multiple": "1"
                },
                {
                    "zipcode": "37610",
                    "province": "Salamanca",
                    "city": "Mogarraz",
                    "multiple": "0"
                },
                {
                    "zipcode": "37617",
                    "province": "Salamanca",
                    "city": "Herguijuela de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37618",
                    "province": "Salamanca",
                    "city": "Monforte de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37619",
                    "province": "Salamanca",
                    "city": "Herguijuela de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37619",
                    "province": "Salamanca",
                    "city": "Madroñal",
                    "multiple": "1"
                },
                {
                    "zipcode": "37621",
                    "province": "Salamanca",
                    "city": "Maíllo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37621",
                    "province": "Salamanca",
                    "city": "Aldeanueva de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "37621",
                    "province": "Salamanca",
                    "city": "Bastida, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37621",
                    "province": "Salamanca",
                    "city": "Cilleros de la Bastida",
                    "multiple": "1"
                },
                {
                    "zipcode": "37621",
                    "province": "Salamanca",
                    "city": "Cabaco, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37621",
                    "province": "Salamanca",
                    "city": "Cereceda de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "37624",
                    "province": "Salamanca",
                    "city": "Alberca, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37630",
                    "province": "Salamanca",
                    "city": "Cabrillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "37638",
                    "province": "Salamanca",
                    "city": "Sepulcro-Hilario",
                    "multiple": "0"
                },
                {
                    "zipcode": "37639",
                    "province": "Salamanca",
                    "city": "Aldehuela de Yeltes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37640",
                    "province": "Salamanca",
                    "city": "Abusejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37650",
                    "province": "Salamanca",
                    "city": "Sequeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "37656",
                    "province": "Salamanca",
                    "city": "Cepeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "37657",
                    "province": "Salamanca",
                    "city": "Sotoserrano",
                    "multiple": "0"
                },
                {
                    "zipcode": "37658",
                    "province": "Salamanca",
                    "city": "Garcibuey",
                    "multiple": "0"
                },
                {
                    "zipcode": "37658",
                    "province": "Salamanca",
                    "city": "Villanueva del Conde",
                    "multiple": "1"
                },
                {
                    "zipcode": "37659",
                    "province": "Salamanca",
                    "city": "Nava de Francia",
                    "multiple": "0"
                },
                {
                    "zipcode": "37659",
                    "province": "Salamanca",
                    "city": "San Martín del Castañar",
                    "multiple": "1"
                },
                {
                    "zipcode": "37659",
                    "province": "Salamanca",
                    "city": "San Miguel del Robledo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37659",
                    "province": "Salamanca",
                    "city": "Casas del Conde, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "37660",
                    "province": "Salamanca",
                    "city": "Miranda del Castañar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37670",
                    "province": "Salamanca",
                    "city": "Santibáñez de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37671",
                    "province": "Salamanca",
                    "city": "San Esteban de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37680",
                    "province": "Salamanca",
                    "city": "Valdefuentes de Sangusín",
                    "multiple": "0"
                },
                {
                    "zipcode": "37682",
                    "province": "Salamanca",
                    "city": "Santibáñez de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37683",
                    "province": "Salamanca",
                    "city": "Molinillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37684",
                    "province": "Salamanca",
                    "city": "Cristóbal",
                    "multiple": "0"
                },
                {
                    "zipcode": "37690",
                    "province": "Salamanca",
                    "city": "Fuente de San Esteban, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37694",
                    "province": "Salamanca",
                    "city": "San Miguel del Robledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37700",
                    "province": "Salamanca",
                    "city": "Guijuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37700",
                    "province": "Salamanca",
                    "city": "Béjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "37710",
                    "province": "Salamanca",
                    "city": "Candelario",
                    "multiple": "0"
                },
                {
                    "zipcode": "37711",
                    "province": "Salamanca",
                    "city": "Colmenar de Montemayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "37712",
                    "province": "Salamanca",
                    "city": "Horcajo de Montemayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "37712",
                    "province": "Salamanca",
                    "city": "Pinedas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37713",
                    "province": "Salamanca",
                    "city": "Valdehijaderos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37714",
                    "province": "Salamanca",
                    "city": "Calzada de Béjar, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37716",
                    "province": "Salamanca",
                    "city": "Cantagallo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37716",
                    "province": "Salamanca",
                    "city": "Hoya, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37716",
                    "province": "Salamanca",
                    "city": "Navacarros",
                    "multiple": "1"
                },
                {
                    "zipcode": "37717",
                    "province": "Salamanca",
                    "city": "Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37717",
                    "province": "Salamanca",
                    "city": "Vallejera de Riofrío",
                    "multiple": "1"
                },
                {
                    "zipcode": "37718",
                    "province": "Salamanca",
                    "city": "Aldeacipreste",
                    "multiple": "0"
                },
                {
                    "zipcode": "37720",
                    "province": "Salamanca",
                    "city": "Puerto de Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37720",
                    "province": "Salamanca",
                    "city": "Cerro, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37724",
                    "province": "Salamanca",
                    "city": "Lagunilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "37724",
                    "province": "Salamanca",
                    "city": "Cerro, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37724",
                    "province": "Salamanca",
                    "city": "Valdelageve",
                    "multiple": "1"
                },
                {
                    "zipcode": "37726",
                    "province": "Salamanca",
                    "city": "Cerro, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37727",
                    "province": "Salamanca",
                    "city": "Montemayor del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "37727",
                    "province": "Salamanca",
                    "city": "Peñacaballera",
                    "multiple": "1"
                },
                {
                    "zipcode": "37729",
                    "province": "Salamanca",
                    "city": "Puerto de Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37730",
                    "province": "Salamanca",
                    "city": "Ledrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "37740",
                    "province": "Salamanca",
                    "city": "Santibáñez de Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37748",
                    "province": "Salamanca",
                    "city": "Puente del Congosto",
                    "multiple": "0"
                },
                {
                    "zipcode": "37749",
                    "province": "Salamanca",
                    "city": "Tejado, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "37749",
                    "province": "Salamanca",
                    "city": "Navamorales",
                    "multiple": "1"
                },
                {
                    "zipcode": "37750",
                    "province": "Salamanca",
                    "city": "Cespedosa de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37750",
                    "province": "Salamanca",
                    "city": "Puente del Congosto",
                    "multiple": "1"
                },
                {
                    "zipcode": "37751",
                    "province": "Salamanca",
                    "city": "Gallegos de Solmirón",
                    "multiple": "0"
                },
                {
                    "zipcode": "37752",
                    "province": "Salamanca",
                    "city": "Tala, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37753",
                    "province": "Salamanca",
                    "city": "Armenteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "37754",
                    "province": "Salamanca",
                    "city": "Armenteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "37755",
                    "province": "Salamanca",
                    "city": "Armenteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "37756",
                    "province": "Salamanca",
                    "city": "Armenteros",
                    "multiple": "0"
                },
                {
                    "zipcode": "37759",
                    "province": "Salamanca",
                    "city": "Herguijuela del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37759",
                    "province": "Salamanca",
                    "city": "Sierpe, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "37760",
                    "province": "Salamanca",
                    "city": "Linares de Riofrío",
                    "multiple": "0"
                },
                {
                    "zipcode": "37762",
                    "province": "Salamanca",
                    "city": "Escurial de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37763",
                    "province": "Salamanca",
                    "city": "San Miguel de Valero",
                    "multiple": "0"
                },
                {
                    "zipcode": "37764",
                    "province": "Salamanca",
                    "city": "Valero",
                    "multiple": "0"
                },
                {
                    "zipcode": "37765",
                    "province": "Salamanca",
                    "city": "Monleón",
                    "multiple": "0"
                },
                {
                    "zipcode": "37765",
                    "province": "Salamanca",
                    "city": "Tornadizo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "37765",
                    "province": "Salamanca",
                    "city": "Endrinal",
                    "multiple": "1"
                },
                {
                    "zipcode": "37766",
                    "province": "Salamanca",
                    "city": "Membribe de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37766",
                    "province": "Salamanca",
                    "city": "Frades de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "37766",
                    "province": "Salamanca",
                    "city": "Endrinal",
                    "multiple": "1"
                },
                {
                    "zipcode": "37767",
                    "province": "Salamanca",
                    "city": "Casafranca",
                    "multiple": "0"
                },
                {
                    "zipcode": "37768",
                    "province": "Salamanca",
                    "city": "Santos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "37768",
                    "province": "Salamanca",
                    "city": "Fuenterroble de Salvatierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "37770",
                    "province": "Salamanca",
                    "city": "Guijuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37773",
                    "province": "Salamanca",
                    "city": "Cabeza de Béjar, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37774",
                    "province": "Salamanca",
                    "city": "Guijo de Ávila",
                    "multiple": "0"
                },
                {
                    "zipcode": "37775",
                    "province": "Salamanca",
                    "city": "Fresnedoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "37776",
                    "province": "Salamanca",
                    "city": "Nava de Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37777",
                    "province": "Salamanca",
                    "city": "Sorihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "37778",
                    "province": "Salamanca",
                    "city": "Guijuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37779",
                    "province": "Salamanca",
                    "city": "Aldeavieja de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37779",
                    "province": "Salamanca",
                    "city": "Salvatierra de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37780",
                    "province": "Salamanca",
                    "city": "Maya, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "37781",
                    "province": "Salamanca",
                    "city": "Monterrubio de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "37785",
                    "province": "Salamanca",
                    "city": "Galinduste",
                    "multiple": "0"
                },
                {
                    "zipcode": "37786",
                    "province": "Salamanca",
                    "city": "Galinduste",
                    "multiple": "0"
                },
                {
                    "zipcode": "37787",
                    "province": "Salamanca",
                    "city": "Montejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37787",
                    "province": "Salamanca",
                    "city": "Pelayos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37788",
                    "province": "Salamanca",
                    "city": "Pedrosillo de los Aires",
                    "multiple": "0"
                },
                {
                    "zipcode": "37789",
                    "province": "Salamanca",
                    "city": "Buenavista",
                    "multiple": "0"
                },
                {
                    "zipcode": "37789",
                    "province": "Salamanca",
                    "city": "Beleña",
                    "multiple": "1"
                },
                {
                    "zipcode": "37789",
                    "province": "Salamanca",
                    "city": "Fresno Alhándiga",
                    "multiple": "1"
                },
                {
                    "zipcode": "37790",
                    "province": "Salamanca",
                    "city": "Fuentes de Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37791",
                    "province": "Salamanca",
                    "city": "Peromingo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37791",
                    "province": "Salamanca",
                    "city": "Valverde de Valdelacasa",
                    "multiple": "1"
                },
                {
                    "zipcode": "37791",
                    "province": "Salamanca",
                    "city": "Puebla de San Medel",
                    "multiple": "1"
                },
                {
                    "zipcode": "37791",
                    "province": "Salamanca",
                    "city": "Valdelacasa",
                    "multiple": "1"
                },
                {
                    "zipcode": "37792",
                    "province": "Salamanca",
                    "city": "Pedrosillo de los Aires",
                    "multiple": "0"
                },
                {
                    "zipcode": "37793",
                    "province": "Salamanca",
                    "city": "Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37794",
                    "province": "Salamanca",
                    "city": "Navalmoral de Béjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "37794",
                    "province": "Salamanca",
                    "city": "Sanchotello",
                    "multiple": "1"
                },
                {
                    "zipcode": "37795",
                    "province": "Salamanca",
                    "city": "Pizarral",
                    "multiple": "0"
                },
                {
                    "zipcode": "37795",
                    "province": "Salamanca",
                    "city": "Berrocal de Salvatierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "37795",
                    "province": "Salamanca",
                    "city": "Guijuelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37795",
                    "province": "Salamanca",
                    "city": "Montejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37796",
                    "province": "Salamanca",
                    "city": "Arapiles",
                    "multiple": "0"
                },
                {
                    "zipcode": "37796",
                    "province": "Salamanca",
                    "city": "Mozárbez",
                    "multiple": "1"
                },
                {
                    "zipcode": "37797",
                    "province": "Salamanca",
                    "city": "Castellanos de Villiquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "37797",
                    "province": "Salamanca",
                    "city": "Calzada de Valdunciel",
                    "multiple": "1"
                },
                {
                    "zipcode": "37798",
                    "province": "Salamanca",
                    "city": "Valdunciel",
                    "multiple": "0"
                },
                {
                    "zipcode": "37798",
                    "province": "Salamanca",
                    "city": "Castellanos de Villiquera",
                    "multiple": "1"
                },
                {
                    "zipcode": "37798",
                    "province": "Salamanca",
                    "city": "Monterrubio de Armuña",
                    "multiple": "1"
                },
                {
                    "zipcode": "37799",
                    "province": "Salamanca",
                    "city": "Valdunciel",
                    "multiple": "0"
                },
                {
                    "zipcode": "37799",
                    "province": "Salamanca",
                    "city": "San Pelayo de Guareña",
                    "multiple": "1"
                },
                {
                    "zipcode": "37799",
                    "province": "Salamanca",
                    "city": "Topas",
                    "multiple": "1"
                },
                {
                    "zipcode": "37799",
                    "province": "Salamanca",
                    "city": "Forfoleda",
                    "multiple": "1"
                },
                {
                    "zipcode": "37799",
                    "province": "Salamanca",
                    "city": "Valdelosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "37800",
                    "province": "Salamanca",
                    "city": "Alba de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37810",
                    "province": "Salamanca",
                    "city": "Garcihernández",
                    "multiple": "0"
                },
                {
                    "zipcode": "37820",
                    "province": "Salamanca",
                    "city": "Peñarandilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "37830",
                    "province": "Salamanca",
                    "city": "Coca de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37840",
                    "province": "Salamanca",
                    "city": "Tordillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "37850",
                    "province": "Salamanca",
                    "city": "Nava de Sotrobal",
                    "multiple": "0"
                },
                {
                    "zipcode": "37860",
                    "province": "Salamanca",
                    "city": "Horcajo Medianero",
                    "multiple": "0"
                },
                {
                    "zipcode": "37861",
                    "province": "Salamanca",
                    "city": "Chagarcía Medianero",
                    "multiple": "0"
                },
                {
                    "zipcode": "37863",
                    "province": "Salamanca",
                    "city": "Anaya de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37864",
                    "province": "Salamanca",
                    "city": "Anaya de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37865",
                    "province": "Salamanca",
                    "city": "Larrodrigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37870",
                    "province": "Salamanca",
                    "city": "Aldeaseca de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37871",
                    "province": "Salamanca",
                    "city": "Pedrosillo de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "37872",
                    "province": "Salamanca",
                    "city": "Gajates",
                    "multiple": "0"
                },
                {
                    "zipcode": "37873",
                    "province": "Salamanca",
                    "city": "Gajates",
                    "multiple": "0"
                },
                {
                    "zipcode": "37874",
                    "province": "Salamanca",
                    "city": "Garcihernández",
                    "multiple": "0"
                },
                {
                    "zipcode": "37874",
                    "province": "Salamanca",
                    "city": "Gajates",
                    "multiple": "1"
                },
                {
                    "zipcode": "37874",
                    "province": "Salamanca",
                    "city": "Encinas de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "37881",
                    "province": "Salamanca",
                    "city": "Valdecarros",
                    "multiple": "0"
                },
                {
                    "zipcode": "37882",
                    "province": "Salamanca",
                    "city": "Alba de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37882",
                    "province": "Salamanca",
                    "city": "Terradillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37882",
                    "province": "Salamanca",
                    "city": "Pedraza de Alba",
                    "multiple": "1"
                },
                {
                    "zipcode": "37882",
                    "province": "Salamanca",
                    "city": "Navales",
                    "multiple": "1"
                },
                {
                    "zipcode": "37890",
                    "province": "Salamanca",
                    "city": "Alba de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37891",
                    "province": "Salamanca",
                    "city": "Galisancho",
                    "multiple": "0"
                },
                {
                    "zipcode": "37891",
                    "province": "Salamanca",
                    "city": "Ejeme",
                    "multiple": "1"
                },
                {
                    "zipcode": "37891",
                    "province": "Salamanca",
                    "city": "Pelayos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37891",
                    "province": "Salamanca",
                    "city": "Valdemierque",
                    "multiple": "1"
                },
                {
                    "zipcode": "37891",
                    "province": "Salamanca",
                    "city": "Martinamor",
                    "multiple": "1"
                },
                {
                    "zipcode": "37892",
                    "province": "Salamanca",
                    "city": "Sieteiglesias de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "37892",
                    "province": "Salamanca",
                    "city": "Encinas de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "37892",
                    "province": "Salamanca",
                    "city": "Alba de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37893",
                    "province": "Salamanca",
                    "city": "Encinas de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "37893",
                    "province": "Salamanca",
                    "city": "Terradillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "37893",
                    "province": "Salamanca",
                    "city": "Villagonzalo de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37893",
                    "province": "Salamanca",
                    "city": "Machacón",
                    "multiple": "1"
                },
                {
                    "zipcode": "37893",
                    "province": "Salamanca",
                    "city": "Alba de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37894",
                    "province": "Salamanca",
                    "city": "Machacón",
                    "multiple": "0"
                },
                {
                    "zipcode": "37894",
                    "province": "Salamanca",
                    "city": "Villagonzalo de Tormes",
                    "multiple": "1"
                },
                {
                    "zipcode": "37900",
                    "province": "Salamanca",
                    "city": "Santa Marta de Tormes",
                    "multiple": "0"
                },
                {
                    "zipcode": "38001",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38002",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38003",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38004",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38005",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38006",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38007",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38008",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38009",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38010",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38107",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38108",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38108",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "1"
                },
                {
                    "zipcode": "38109",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38109",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Rosario, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "38110",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38111",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38120",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38129",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38130",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38139",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38140",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38150",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38160",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38170",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38180",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38190",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Rosario, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38201",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38202",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38203",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38204",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38205",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38206",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38207",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38208",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38240",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38250",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38260",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38270",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38280",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tegueste",
                    "multiple": "0"
                },
                {
                    "zipcode": "38289",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tegueste",
                    "multiple": "0"
                },
                {
                    "zipcode": "38290",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Rosario, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38291",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38292",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tegueste",
                    "multiple": "0"
                },
                {
                    "zipcode": "38293",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38293",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tegueste",
                    "multiple": "1"
                },
                {
                    "zipcode": "38294",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "0"
                },
                {
                    "zipcode": "38294",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "1"
                },
                {
                    "zipcode": "38296",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38297",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38297",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tegueste",
                    "multiple": "1"
                },
                {
                    "zipcode": "38300",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Orotava, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38310",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Orotava, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38311",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Orotava, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38312",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Orotava, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38313",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Orotava, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38314",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Orotava, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38315",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Orotava, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38320",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38320",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de Tenerife",
                    "multiple": "1"
                },
                {
                    "zipcode": "38329",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38330",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Cristóbal de La Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38340",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tacoronte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38350",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tacoronte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38355",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tacoronte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38356",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tacoronte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38357",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tacoronte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38358",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tacoronte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38359",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Sauzal, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38360",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Sauzal, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38370",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Matanza de Acentejo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38379",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Matanza de Acentejo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38380",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Victoria de Acentejo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38389",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Victoria de Acentejo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38390",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Úrsula",
                    "multiple": "0"
                },
                {
                    "zipcode": "38398",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Úrsula",
                    "multiple": "0"
                },
                {
                    "zipcode": "38399",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Úrsula",
                    "multiple": "0"
                },
                {
                    "zipcode": "38400",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Puerto de la Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "38410",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38412",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38413",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38414",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38415",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38416",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38417",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38418",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38419",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Realejos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38420",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Juan de la Rambla",
                    "multiple": "0"
                },
                {
                    "zipcode": "38428",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Juan de la Rambla",
                    "multiple": "0"
                },
                {
                    "zipcode": "38429",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Juan de la Rambla",
                    "multiple": "0"
                },
                {
                    "zipcode": "38430",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Icod de los Vinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "38434",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Icod de los Vinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "38435",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tanque, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38435",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Silos, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "38438",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Icod de los Vinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "38439",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Icod de los Vinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "38440",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guancha, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38441",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guancha, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38449",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guancha, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "38450",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Garachico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38458",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Garachico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38459",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Garachico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38460",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Garachico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38460",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Silos, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "38470",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Silos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38479",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Silos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38479",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Buenavista del Norte",
                    "multiple": "1"
                },
                {
                    "zipcode": "38480",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Buenavista del Norte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38489",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Buenavista del Norte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38500",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Güímar",
                    "multiple": "0"
                },
                {
                    "zipcode": "38508",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Güímar",
                    "multiple": "0"
                },
                {
                    "zipcode": "38509",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Candelaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "38509",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Güímar",
                    "multiple": "1"
                },
                {
                    "zipcode": "38509",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arafo",
                    "multiple": "1"
                },
                {
                    "zipcode": "38510",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Rosario, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38510",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Candelaria",
                    "multiple": "1"
                },
                {
                    "zipcode": "38520",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Candelaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "38530",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Candelaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "38540",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Candelaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "38550",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arafo",
                    "multiple": "0"
                },
                {
                    "zipcode": "38560",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Güímar",
                    "multiple": "0"
                },
                {
                    "zipcode": "38570",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Fasnia",
                    "multiple": "0"
                },
                {
                    "zipcode": "38579",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Fasnia",
                    "multiple": "0"
                },
                {
                    "zipcode": "38580",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38588",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38589",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38590",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Güímar",
                    "multiple": "0"
                },
                {
                    "zipcode": "38591",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Güímar",
                    "multiple": "0"
                },
                {
                    "zipcode": "38592",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38593",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arico",
                    "multiple": "0"
                },
                {
                    "zipcode": "38594",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38595",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38600",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38611",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38612",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38613",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vilaflor de Chasna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38614",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vilaflor de Chasna",
                    "multiple": "0"
                },
                {
                    "zipcode": "38615",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Adeje",
                    "multiple": "0"
                },
                {
                    "zipcode": "38615",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vilaflor de Chasna",
                    "multiple": "1"
                },
                {
                    "zipcode": "38616",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38617",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38618",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38620",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Miguel de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38626",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38627",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38628",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Miguel de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38629",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Miguel de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38629",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vilaflor de Chasna",
                    "multiple": "1"
                },
                {
                    "zipcode": "38630",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38631",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38632",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38639",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Granadilla de Abona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38639",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "1"
                },
                {
                    "zipcode": "38639",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Miguel de Abona",
                    "multiple": "1"
                },
                {
                    "zipcode": "38640",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38649",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38650",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38652",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Arona",
                    "multiple": "0"
                },
                {
                    "zipcode": "38660",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Adeje",
                    "multiple": "0"
                },
                {
                    "zipcode": "38670",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Adeje",
                    "multiple": "0"
                },
                {
                    "zipcode": "38677",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Adeje",
                    "multiple": "0"
                },
                {
                    "zipcode": "38678",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Adeje",
                    "multiple": "0"
                },
                {
                    "zipcode": "38679",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Adeje",
                    "multiple": "0"
                },
                {
                    "zipcode": "38680",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guía de Isora",
                    "multiple": "0"
                },
                {
                    "zipcode": "38683",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santiago del Teide",
                    "multiple": "0"
                },
                {
                    "zipcode": "38684",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santiago del Teide",
                    "multiple": "0"
                },
                {
                    "zipcode": "38685",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guía de Isora",
                    "multiple": "0"
                },
                {
                    "zipcode": "38686",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guía de Isora",
                    "multiple": "0"
                },
                {
                    "zipcode": "38687",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guía de Isora",
                    "multiple": "0"
                },
                {
                    "zipcode": "38688",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guía de Isora",
                    "multiple": "0"
                },
                {
                    "zipcode": "38689",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Guía de Isora",
                    "multiple": "0"
                },
                {
                    "zipcode": "38690",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santiago del Teide",
                    "multiple": "0"
                },
                {
                    "zipcode": "38700",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de la Palma",
                    "multiple": "0"
                },
                {
                    "zipcode": "38710",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Breña Alta",
                    "multiple": "0"
                },
                {
                    "zipcode": "38711",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Breña Baja",
                    "multiple": "0"
                },
                {
                    "zipcode": "38712",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de la Palma",
                    "multiple": "0"
                },
                {
                    "zipcode": "38712",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Breña Alta",
                    "multiple": "1"
                },
                {
                    "zipcode": "38712",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Breña Baja",
                    "multiple": "1"
                },
                {
                    "zipcode": "38713",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Breña Alta",
                    "multiple": "0"
                },
                {
                    "zipcode": "38713",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de la Palma",
                    "multiple": "1"
                },
                {
                    "zipcode": "38714",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Puntallana",
                    "multiple": "0"
                },
                {
                    "zipcode": "38714",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de la Palma",
                    "multiple": "1"
                },
                {
                    "zipcode": "38715",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Puntallana",
                    "multiple": "0"
                },
                {
                    "zipcode": "38715",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Santa Cruz de la Palma",
                    "multiple": "1"
                },
                {
                    "zipcode": "38720",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Andrés y Sauces",
                    "multiple": "0"
                },
                {
                    "zipcode": "38726",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Barlovento",
                    "multiple": "0"
                },
                {
                    "zipcode": "38727",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Barlovento",
                    "multiple": "0"
                },
                {
                    "zipcode": "38728",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Garafía",
                    "multiple": "0"
                },
                {
                    "zipcode": "38729",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Andrés y Sauces",
                    "multiple": "0"
                },
                {
                    "zipcode": "38730",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Villa de Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "38738",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Villa de Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "38739",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Villa de Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "38740",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Fuencaliente de la Palma",
                    "multiple": "0"
                },
                {
                    "zipcode": "38749",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Fuencaliente de la Palma",
                    "multiple": "0"
                },
                {
                    "zipcode": "38750",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Paso, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38758",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Paso, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38759",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Llanos de Aridane, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38759",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Paso, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "38760",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Llanos de Aridane, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38767",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Llanos de Aridane, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38768",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Llanos de Aridane, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "38768",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Paso, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "38769",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Paso, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38769",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Llanos de Aridane, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "38770",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tazacorte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38779",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tazacorte",
                    "multiple": "0"
                },
                {
                    "zipcode": "38780",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tijarafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "38787",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Garafía",
                    "multiple": "0"
                },
                {
                    "zipcode": "38788",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Garafía",
                    "multiple": "0"
                },
                {
                    "zipcode": "38789",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Tijarafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "38789",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Puntagorda",
                    "multiple": "1"
                },
                {
                    "zipcode": "38800",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Sebastián de la Gomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "38801",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Sebastián de la Gomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "38810",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Alajeró",
                    "multiple": "0"
                },
                {
                    "zipcode": "38811",
                    "province": "Santa Cruz de Tenerife",
                    "city": "San Sebastián de la Gomera",
                    "multiple": "0"
                },
                {
                    "zipcode": "38811",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Alajeró",
                    "multiple": "1"
                },
                {
                    "zipcode": "38812",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Alajeró",
                    "multiple": "0"
                },
                {
                    "zipcode": "38813",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Alajeró",
                    "multiple": "0"
                },
                {
                    "zipcode": "38820",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Hermigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "38829",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Hermigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "38830",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Agulo",
                    "multiple": "0"
                },
                {
                    "zipcode": "38840",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vallehermoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "38849",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vallehermoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "38850",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vallehermoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "38852",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valle Gran Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "38852",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vallehermoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "38860",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vallehermoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "38869",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Hermigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "38869",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vallehermoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "38870",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valle Gran Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "38879",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valle Gran Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "38890",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Hermigua",
                    "multiple": "0"
                },
                {
                    "zipcode": "38890",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Agulo",
                    "multiple": "1"
                },
                {
                    "zipcode": "38891",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Vallehermoso",
                    "multiple": "0"
                },
                {
                    "zipcode": "38892",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valle Gran Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "38900",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "38910",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "38911",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "38912",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "38913",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "38914",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Pinar de El Hierro, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "38915",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "38916",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Valverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "38917",
                    "province": "Santa Cruz de Tenerife",
                    "city": "Pinar de El Hierro, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "39001",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39002",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39003",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39004",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39005",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39006",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39007",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39008",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39009",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39010",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39011",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39012",
                    "province": "Cantabria",
                    "city": "Santander",
                    "multiple": "0"
                },
                {
                    "zipcode": "39100",
                    "province": "Cantabria",
                    "city": "Santa Cruz de Bezana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39108",
                    "province": "Cantabria",
                    "city": "Santa Cruz de Bezana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39110",
                    "province": "Cantabria",
                    "city": "Santa Cruz de Bezana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39120",
                    "province": "Cantabria",
                    "city": "Piélagos",
                    "multiple": "0"
                },
                {
                    "zipcode": "39130",
                    "province": "Cantabria",
                    "city": "Marina de Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39140",
                    "province": "Cantabria",
                    "city": "Ribamontán al Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39146",
                    "province": "Cantabria",
                    "city": "Ribamontán al Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39150",
                    "province": "Cantabria",
                    "city": "Ribamontán al Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39160",
                    "province": "Cantabria",
                    "city": "Ribamontán al Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39170",
                    "province": "Cantabria",
                    "city": "Bareyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39178",
                    "province": "Cantabria",
                    "city": "Liérganes",
                    "multiple": "0"
                },
                {
                    "zipcode": "39180",
                    "province": "Cantabria",
                    "city": "Noja",
                    "multiple": "0"
                },
                {
                    "zipcode": "39190",
                    "province": "Cantabria",
                    "city": "Bareyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39191",
                    "province": "Cantabria",
                    "city": "Bareyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39192",
                    "province": "Cantabria",
                    "city": "Meruelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39193",
                    "province": "Cantabria",
                    "city": "Arnuero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39193",
                    "province": "Cantabria",
                    "city": "Escalante",
                    "multiple": "1"
                },
                {
                    "zipcode": "39195",
                    "province": "Cantabria",
                    "city": "Arnuero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39197",
                    "province": "Cantabria",
                    "city": "Santoña",
                    "multiple": "0"
                },
                {
                    "zipcode": "39197",
                    "province": "Cantabria",
                    "city": "Argoños",
                    "multiple": "1"
                },
                {
                    "zipcode": "39200",
                    "province": "Cantabria",
                    "city": "Reinosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39200",
                    "province": "Cantabria",
                    "city": "Campoo de Enmedio",
                    "multiple": "1"
                },
                {
                    "zipcode": "39210",
                    "province": "Cantabria",
                    "city": "Hermandad de Campoo de Suso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39211",
                    "province": "Cantabria",
                    "city": "Hermandad de Campoo de Suso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39212",
                    "province": "Cantabria",
                    "city": "Hermandad de Campoo de Suso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39212",
                    "province": "Cantabria",
                    "city": "Campoo de Enmedio",
                    "multiple": "1"
                },
                {
                    "zipcode": "39213",
                    "province": "Cantabria",
                    "city": "Campoo de Enmedio",
                    "multiple": "0"
                },
                {
                    "zipcode": "39213",
                    "province": "Cantabria",
                    "city": "Rozas de Valdearroyo, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "39213",
                    "province": "Cantabria",
                    "city": "Hermandad de Campoo de Suso",
                    "multiple": "1"
                },
                {
                    "zipcode": "39220",
                    "province": "Cantabria",
                    "city": "Valderredible",
                    "multiple": "0"
                },
                {
                    "zipcode": "39229",
                    "province": "Cantabria",
                    "city": "Valderredible",
                    "multiple": "0"
                },
                {
                    "zipcode": "39230",
                    "province": "Cantabria",
                    "city": "Valderredible",
                    "multiple": "0"
                },
                {
                    "zipcode": "39232",
                    "province": "Burgos",
                    "city": "Alfoz de Bricia",
                    "multiple": "0"
                },
                {
                    "zipcode": "39232",
                    "province": "Burgos",
                    "city": "Valderredible",
                    "multiple": "1"
                },
                {
                    "zipcode": "39248",
                    "province": "Cantabria",
                    "city": "Valdeprado del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "39250",
                    "province": "Cantabria",
                    "city": "Berzosilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "39250",
                    "province": "Cantabria",
                    "city": "Valderredible",
                    "multiple": "1"
                },
                {
                    "zipcode": "39290",
                    "province": "Cantabria",
                    "city": "Campoo de Yuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39291",
                    "province": "Cantabria",
                    "city": "Campoo de Yuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39291",
                    "province": "Cantabria",
                    "city": "Campoo de Enmedio",
                    "multiple": "1"
                },
                {
                    "zipcode": "39292",
                    "province": "Cantabria",
                    "city": "Campoo de Yuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39293",
                    "province": "Cantabria",
                    "city": "Campoo de Yuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39294",
                    "province": "Cantabria",
                    "city": "Campoo de Yuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "39300",
                    "province": "Cantabria",
                    "city": "Torrelavega",
                    "multiple": "0"
                },
                {
                    "zipcode": "39310",
                    "province": "Cantabria",
                    "city": "Miengo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39311",
                    "province": "Cantabria",
                    "city": "Cartes",
                    "multiple": "0"
                },
                {
                    "zipcode": "39312",
                    "province": "Cantabria",
                    "city": "Polanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "39312",
                    "province": "Cantabria",
                    "city": "Miengo",
                    "multiple": "1"
                },
                {
                    "zipcode": "39313",
                    "province": "Cantabria",
                    "city": "Polanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "39314",
                    "province": "Cantabria",
                    "city": "Santillana del Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39315",
                    "province": "Cantabria",
                    "city": "Torrelavega",
                    "multiple": "0"
                },
                {
                    "zipcode": "39316",
                    "province": "Cantabria",
                    "city": "Torrelavega",
                    "multiple": "0"
                },
                {
                    "zipcode": "39317",
                    "province": "Cantabria",
                    "city": "Torrelavega",
                    "multiple": "0"
                },
                {
                    "zipcode": "39318",
                    "province": "Cantabria",
                    "city": "Miengo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39320",
                    "province": "Cantabria",
                    "city": "Alfoz de Lloredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39329",
                    "province": "Cantabria",
                    "city": "Alfoz de Lloredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39330",
                    "province": "Cantabria",
                    "city": "Santillana del Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39340",
                    "province": "Cantabria",
                    "city": "Suances",
                    "multiple": "0"
                },
                {
                    "zipcode": "39350",
                    "province": "Cantabria",
                    "city": "Suances",
                    "multiple": "0"
                },
                {
                    "zipcode": "39360",
                    "province": "Cantabria",
                    "city": "Suances",
                    "multiple": "0"
                },
                {
                    "zipcode": "39360",
                    "province": "Cantabria",
                    "city": "Santillana del Mar",
                    "multiple": "1"
                },
                {
                    "zipcode": "39390",
                    "province": "Cantabria",
                    "city": "Santillana del Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39400",
                    "province": "Cantabria",
                    "city": "Corrales de Buelna, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "39407",
                    "province": "Cantabria",
                    "city": "Cieza",
                    "multiple": "0"
                },
                {
                    "zipcode": "39408",
                    "province": "Cantabria",
                    "city": "Corrales de Buelna, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "39409",
                    "province": "Cantabria",
                    "city": "San Felices de Buelna",
                    "multiple": "0"
                },
                {
                    "zipcode": "39410",
                    "province": "Cantabria",
                    "city": "Valdeolea",
                    "multiple": "0"
                },
                {
                    "zipcode": "39417",
                    "province": "Cantabria",
                    "city": "Rozas de Valdearroyo, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "39417",
                    "province": "Cantabria",
                    "city": "Valdeprado del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "39418",
                    "province": "Cantabria",
                    "city": "Valdeolea",
                    "multiple": "0"
                },
                {
                    "zipcode": "39419",
                    "province": "Cantabria",
                    "city": "Valdeprado del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "39419",
                    "province": "Cantabria",
                    "city": "Valderredible",
                    "multiple": "1"
                },
                {
                    "zipcode": "39420",
                    "province": "Cantabria",
                    "city": "Bárcena de Pie de Concha",
                    "multiple": "0"
                },
                {
                    "zipcode": "39420",
                    "province": "Cantabria",
                    "city": "Molledo",
                    "multiple": "1"
                },
                {
                    "zipcode": "39430",
                    "province": "Cantabria",
                    "city": "Molledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39438",
                    "province": "Cantabria",
                    "city": "Molledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39439",
                    "province": "Cantabria",
                    "city": "Molledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39450",
                    "province": "Cantabria",
                    "city": "Arenas de Iguña",
                    "multiple": "0"
                },
                {
                    "zipcode": "39451",
                    "province": "Cantabria",
                    "city": "Arenas de Iguña",
                    "multiple": "0"
                },
                {
                    "zipcode": "39451",
                    "province": "Cantabria",
                    "city": "Anievas",
                    "multiple": "1"
                },
                {
                    "zipcode": "39460",
                    "province": "Cantabria",
                    "city": "Cartes",
                    "multiple": "0"
                },
                {
                    "zipcode": "39460",
                    "province": "Cantabria",
                    "city": "Corrales de Buelna, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "39470",
                    "province": "Cantabria",
                    "city": "Piélagos",
                    "multiple": "0"
                },
                {
                    "zipcode": "39477",
                    "province": "Cantabria",
                    "city": "Piélagos",
                    "multiple": "0"
                },
                {
                    "zipcode": "39478",
                    "province": "Cantabria",
                    "city": "Piélagos",
                    "multiple": "0"
                },
                {
                    "zipcode": "39479",
                    "province": "Cantabria",
                    "city": "Piélagos",
                    "multiple": "0"
                },
                {
                    "zipcode": "39490",
                    "province": "Cantabria",
                    "city": "Santiurde de Reinosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39491",
                    "province": "Cantabria",
                    "city": "Santiurde de Reinosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39491",
                    "province": "Cantabria",
                    "city": "San Miguel de Aguayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "39491",
                    "province": "Cantabria",
                    "city": "Pesquera",
                    "multiple": "1"
                },
                {
                    "zipcode": "39491",
                    "province": "Cantabria",
                    "city": "Campoo de Enmedio",
                    "multiple": "1"
                },
                {
                    "zipcode": "39500",
                    "province": "Cantabria",
                    "city": "Cabezón de la Sal",
                    "multiple": "0"
                },
                {
                    "zipcode": "39506",
                    "province": "Cantabria",
                    "city": "Cabezón de la Sal",
                    "multiple": "0"
                },
                {
                    "zipcode": "39507",
                    "province": "Cantabria",
                    "city": "Udías",
                    "multiple": "0"
                },
                {
                    "zipcode": "39507",
                    "province": "Cantabria",
                    "city": "Cabezón de la Sal",
                    "multiple": "1"
                },
                {
                    "zipcode": "39509",
                    "province": "Cantabria",
                    "city": "Mazcuerras",
                    "multiple": "0"
                },
                {
                    "zipcode": "39509",
                    "province": "Cantabria",
                    "city": "Cabezón de la Sal",
                    "multiple": "1"
                },
                {
                    "zipcode": "39510",
                    "province": "Cantabria",
                    "city": "Ruente",
                    "multiple": "0"
                },
                {
                    "zipcode": "39510",
                    "province": "Cantabria",
                    "city": "Cabuérniga",
                    "multiple": "1"
                },
                {
                    "zipcode": "39511",
                    "province": "Cantabria",
                    "city": "Cabuérniga",
                    "multiple": "0"
                },
                {
                    "zipcode": "39513",
                    "province": "Cantabria",
                    "city": "Ruente",
                    "multiple": "0"
                },
                {
                    "zipcode": "39517",
                    "province": "Cantabria",
                    "city": "Tojos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "39518",
                    "province": "Cantabria",
                    "city": "Tojos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "39518",
                    "province": "Cantabria",
                    "city": "Cabuérniga",
                    "multiple": "1"
                },
                {
                    "zipcode": "39520",
                    "province": "Cantabria",
                    "city": "Comillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "39525",
                    "province": "Cantabria",
                    "city": "Alfoz de Lloredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39526",
                    "province": "Cantabria",
                    "city": "Alfoz de Lloredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39527",
                    "province": "Cantabria",
                    "city": "Ruiloba",
                    "multiple": "0"
                },
                {
                    "zipcode": "39528",
                    "province": "Cantabria",
                    "city": "Comillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "39528",
                    "province": "Cantabria",
                    "city": "Valdáliga",
                    "multiple": "1"
                },
                {
                    "zipcode": "39530",
                    "province": "Cantabria",
                    "city": "Santillana del Mar",
                    "multiple": "0"
                },
                {
                    "zipcode": "39530",
                    "province": "Cantabria",
                    "city": "Reocín",
                    "multiple": "1"
                },
                {
                    "zipcode": "39538",
                    "province": "Cantabria",
                    "city": "Reocín",
                    "multiple": "0"
                },
                {
                    "zipcode": "39539",
                    "province": "Cantabria",
                    "city": "Alfoz de Lloredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39539",
                    "province": "Cantabria",
                    "city": "Reocín",
                    "multiple": "1"
                },
                {
                    "zipcode": "39540",
                    "province": "Cantabria",
                    "city": "San Vicente de la Barquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "39547",
                    "province": "Cantabria",
                    "city": "San Vicente de la Barquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "39548",
                    "province": "Cantabria",
                    "city": "San Vicente de la Barquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "39548",
                    "province": "Cantabria",
                    "city": "Val de San Vicente",
                    "multiple": "1"
                },
                {
                    "zipcode": "39549",
                    "province": "Cantabria",
                    "city": "San Vicente de la Barquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "39549",
                    "province": "Cantabria",
                    "city": "Val de San Vicente",
                    "multiple": "1"
                },
                {
                    "zipcode": "39549",
                    "province": "Cantabria",
                    "city": "Herrerías",
                    "multiple": "1"
                },
                {
                    "zipcode": "39550",
                    "province": "Cantabria",
                    "city": "Herrerías",
                    "multiple": "0"
                },
                {
                    "zipcode": "39550",
                    "province": "Cantabria",
                    "city": "Lamasón",
                    "multiple": "1"
                },
                {
                    "zipcode": "39551",
                    "province": "Cantabria",
                    "city": "Herrerías",
                    "multiple": "0"
                },
                {
                    "zipcode": "39553",
                    "province": "Cantabria",
                    "city": "Rionansa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39554",
                    "province": "Cantabria",
                    "city": "Rionansa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39555",
                    "province": "Cantabria",
                    "city": "Tudanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "39556",
                    "province": "Cantabria",
                    "city": "Polaciones",
                    "multiple": "0"
                },
                {
                    "zipcode": "39557",
                    "province": "Cantabria",
                    "city": "Polaciones",
                    "multiple": "0"
                },
                {
                    "zipcode": "39558",
                    "province": "Cantabria",
                    "city": "Cabuérniga",
                    "multiple": "0"
                },
                {
                    "zipcode": "39559",
                    "province": "Cantabria",
                    "city": "Rionansa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39560",
                    "province": "Cantabria",
                    "city": "Val de San Vicente",
                    "multiple": "0"
                },
                {
                    "zipcode": "39569",
                    "province": "Cantabria",
                    "city": "Val de San Vicente",
                    "multiple": "0"
                },
                {
                    "zipcode": "39570",
                    "province": "Cantabria",
                    "city": "Potes",
                    "multiple": "0"
                },
                {
                    "zipcode": "39571",
                    "province": "Cantabria",
                    "city": "Cabezón de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39572",
                    "province": "Cantabria",
                    "city": "Pesaguero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39573",
                    "province": "Cantabria",
                    "city": "Cabezón de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39574",
                    "province": "Cantabria",
                    "city": "Pesaguero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39574",
                    "province": "Cantabria",
                    "city": "Cabezón de Liébana",
                    "multiple": "1"
                },
                {
                    "zipcode": "39575",
                    "province": "Cantabria",
                    "city": "Vega de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39577",
                    "province": "Cantabria",
                    "city": "Vega de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39580",
                    "province": "Cantabria",
                    "city": "Cillorigo de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39580",
                    "province": "Cantabria",
                    "city": "Tresviso",
                    "multiple": "1"
                },
                {
                    "zipcode": "39580",
                    "province": "Cantabria",
                    "city": "Peñarrubia",
                    "multiple": "1"
                },
                {
                    "zipcode": "39582",
                    "province": "Cantabria",
                    "city": "Camaleño",
                    "multiple": "0"
                },
                {
                    "zipcode": "39583",
                    "province": "Cantabria",
                    "city": "Cillorigo de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39584",
                    "province": "Cantabria",
                    "city": "Cillorigo de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39585",
                    "province": "Cantabria",
                    "city": "Cillorigo de Liébana",
                    "multiple": "0"
                },
                {
                    "zipcode": "39586",
                    "province": "Cantabria",
                    "city": "Camaleño",
                    "multiple": "0"
                },
                {
                    "zipcode": "39587",
                    "province": "Cantabria",
                    "city": "Camaleño",
                    "multiple": "0"
                },
                {
                    "zipcode": "39588",
                    "province": "Cantabria",
                    "city": "Camaleño",
                    "multiple": "0"
                },
                {
                    "zipcode": "39589",
                    "province": "Cantabria",
                    "city": "Camaleño",
                    "multiple": "0"
                },
                {
                    "zipcode": "39590",
                    "province": "Cantabria",
                    "city": "Alfoz de Lloredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39590",
                    "province": "Cantabria",
                    "city": "Reocín",
                    "multiple": "1"
                },
                {
                    "zipcode": "39591",
                    "province": "Cantabria",
                    "city": "Cabezón de la Sal",
                    "multiple": "0"
                },
                {
                    "zipcode": "39591",
                    "province": "Cantabria",
                    "city": "Reocín",
                    "multiple": "1"
                },
                {
                    "zipcode": "39592",
                    "province": "Cantabria",
                    "city": "Cabezón de la Sal",
                    "multiple": "0"
                },
                {
                    "zipcode": "39592",
                    "province": "Cantabria",
                    "city": "Mazcuerras",
                    "multiple": "1"
                },
                {
                    "zipcode": "39592",
                    "province": "Cantabria",
                    "city": "Valdáliga",
                    "multiple": "1"
                },
                {
                    "zipcode": "39593",
                    "province": "Cantabria",
                    "city": "Valdáliga",
                    "multiple": "0"
                },
                {
                    "zipcode": "39594",
                    "province": "Cantabria",
                    "city": "Val de San Vicente",
                    "multiple": "0"
                },
                {
                    "zipcode": "39594",
                    "province": "Cantabria",
                    "city": "Herrerías",
                    "multiple": "1"
                },
                {
                    "zipcode": "39600",
                    "province": "Cantabria",
                    "city": "Camargo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39608",
                    "province": "Cantabria",
                    "city": "Camargo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39609",
                    "province": "Cantabria",
                    "city": "Camargo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39610",
                    "province": "Cantabria",
                    "city": "Astillero, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "39611",
                    "province": "Cantabria",
                    "city": "Astillero, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "39612",
                    "province": "Cantabria",
                    "city": "Piélagos",
                    "multiple": "0"
                },
                {
                    "zipcode": "39613",
                    "province": "Cantabria",
                    "city": "Astillero, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "39618",
                    "province": "Cantabria",
                    "city": "Marina de Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39619",
                    "province": "Cantabria",
                    "city": "Medio Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39620",
                    "province": "Cantabria",
                    "city": "Santa María de Cayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "39626",
                    "province": "Cantabria",
                    "city": "Santa María de Cayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "39627",
                    "province": "Cantabria",
                    "city": "Santa María de Cayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "39627",
                    "province": "Cantabria",
                    "city": "Penagos",
                    "multiple": "1"
                },
                {
                    "zipcode": "39630",
                    "province": "Cantabria",
                    "city": "Villafufre",
                    "multiple": "0"
                },
                {
                    "zipcode": "39638",
                    "province": "Cantabria",
                    "city": "Villafufre",
                    "multiple": "0"
                },
                {
                    "zipcode": "39639",
                    "province": "Cantabria",
                    "city": "Saro",
                    "multiple": "0"
                },
                {
                    "zipcode": "39639",
                    "province": "Cantabria",
                    "city": "Villacarriedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "39640",
                    "province": "Cantabria",
                    "city": "Villacarriedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39649",
                    "province": "Cantabria",
                    "city": "Villacarriedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39650",
                    "province": "Cantabria",
                    "city": "Santa María de Cayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "39650",
                    "province": "Cantabria",
                    "city": "Castañeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "39660",
                    "province": "Cantabria",
                    "city": "Castañeda",
                    "multiple": "0"
                },
                {
                    "zipcode": "39670",
                    "province": "Cantabria",
                    "city": "Puente Viesgo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39679",
                    "province": "Cantabria",
                    "city": "Puente Viesgo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39680",
                    "province": "Cantabria",
                    "city": "Corvera de Toranzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39682",
                    "province": "Cantabria",
                    "city": "Luena",
                    "multiple": "0"
                },
                {
                    "zipcode": "39682",
                    "province": "Cantabria",
                    "city": "Corvera de Toranzo",
                    "multiple": "1"
                },
                {
                    "zipcode": "39682",
                    "province": "Cantabria",
                    "city": "Vega de Pas",
                    "multiple": "1"
                },
                {
                    "zipcode": "39683",
                    "province": "Cantabria",
                    "city": "San Pedro del Romeral",
                    "multiple": "0"
                },
                {
                    "zipcode": "39683",
                    "province": "Cantabria",
                    "city": "Vega de Pas",
                    "multiple": "1"
                },
                {
                    "zipcode": "39685",
                    "province": "Cantabria",
                    "city": "Vega de Pas",
                    "multiple": "0"
                },
                {
                    "zipcode": "39686",
                    "province": "Cantabria",
                    "city": "San Pedro del Romeral",
                    "multiple": "0"
                },
                {
                    "zipcode": "39687",
                    "province": "Cantabria",
                    "city": "Luena",
                    "multiple": "0"
                },
                {
                    "zipcode": "39688",
                    "province": "Cantabria",
                    "city": "Luena",
                    "multiple": "0"
                },
                {
                    "zipcode": "39689",
                    "province": "Cantabria",
                    "city": "Santiurde de Toranzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39690",
                    "province": "Cantabria",
                    "city": "Villaescusa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39691",
                    "province": "Cantabria",
                    "city": "Santiurde de Toranzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39692",
                    "province": "Cantabria",
                    "city": "Villaescusa",
                    "multiple": "0"
                },
                {
                    "zipcode": "39694",
                    "province": "Cantabria",
                    "city": "Santa María de Cayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "39696",
                    "province": "Cantabria",
                    "city": "Selaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "39697",
                    "province": "Cantabria",
                    "city": "Corvera de Toranzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39698",
                    "province": "Cantabria",
                    "city": "Santiurde de Toranzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39699",
                    "province": "Cantabria",
                    "city": "Corvera de Toranzo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39700",
                    "province": "Cantabria",
                    "city": "Castro-Urdiales",
                    "multiple": "0"
                },
                {
                    "zipcode": "39706",
                    "province": "Cantabria",
                    "city": "Castro-Urdiales",
                    "multiple": "0"
                },
                {
                    "zipcode": "39707",
                    "province": "Cantabria",
                    "city": "Castro-Urdiales",
                    "multiple": "0"
                },
                {
                    "zipcode": "39708",
                    "province": "Cantabria",
                    "city": "Castro-Urdiales",
                    "multiple": "0"
                },
                {
                    "zipcode": "39709",
                    "province": "Cantabria",
                    "city": "Castro-Urdiales",
                    "multiple": "0"
                },
                {
                    "zipcode": "39710",
                    "province": "Cantabria",
                    "city": "Medio Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39715",
                    "province": "Cantabria",
                    "city": "Entrambasaguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "39716",
                    "province": "Cantabria",
                    "city": "Entrambasaguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "39717",
                    "province": "Cantabria",
                    "city": "Solórzano",
                    "multiple": "0"
                },
                {
                    "zipcode": "39718",
                    "province": "Cantabria",
                    "city": "Medio Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39718",
                    "province": "Cantabria",
                    "city": "Liérganes",
                    "multiple": "1"
                },
                {
                    "zipcode": "39719",
                    "province": "Cantabria",
                    "city": "Marina de Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39719",
                    "province": "Cantabria",
                    "city": "Entrambasaguas",
                    "multiple": "1"
                },
                {
                    "zipcode": "39720",
                    "province": "Cantabria",
                    "city": "Riotuerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39722",
                    "province": "Cantabria",
                    "city": "Liérganes",
                    "multiple": "0"
                },
                {
                    "zipcode": "39723",
                    "province": "Cantabria",
                    "city": "Riotuerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39723",
                    "province": "Cantabria",
                    "city": "Miera",
                    "multiple": "1"
                },
                {
                    "zipcode": "39724",
                    "province": "Cantabria",
                    "city": "Medio Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39725",
                    "province": "Cantabria",
                    "city": "Miera",
                    "multiple": "0"
                },
                {
                    "zipcode": "39726",
                    "province": "Cantabria",
                    "city": "San Roque de Riomiera",
                    "multiple": "0"
                },
                {
                    "zipcode": "39727",
                    "province": "Cantabria",
                    "city": "Liérganes",
                    "multiple": "0"
                },
                {
                    "zipcode": "39728",
                    "province": "Cantabria",
                    "city": "Ruesga",
                    "multiple": "0"
                },
                {
                    "zipcode": "39728",
                    "province": "Cantabria",
                    "city": "Soba",
                    "multiple": "1"
                },
                {
                    "zipcode": "39728",
                    "province": "Cantabria",
                    "city": "Miera",
                    "multiple": "1"
                },
                {
                    "zipcode": "39728",
                    "province": "Cantabria",
                    "city": "San Roque de Riomiera",
                    "multiple": "1"
                },
                {
                    "zipcode": "39730",
                    "province": "Cantabria",
                    "city": "Hazas de Cesto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39738",
                    "province": "Cantabria",
                    "city": "Hazas de Cesto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39738",
                    "province": "Cantabria",
                    "city": "Solórzano",
                    "multiple": "1"
                },
                {
                    "zipcode": "39739",
                    "province": "Cantabria",
                    "city": "Hazas de Cesto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39740",
                    "province": "Cantabria",
                    "city": "Santoña",
                    "multiple": "0"
                },
                {
                    "zipcode": "39749",
                    "province": "Cantabria",
                    "city": "Santoña",
                    "multiple": "0"
                },
                {
                    "zipcode": "39750",
                    "province": "Cantabria",
                    "city": "Colindres",
                    "multiple": "0"
                },
                {
                    "zipcode": "39760",
                    "province": "Cantabria",
                    "city": "Bárcena de Cicero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39761",
                    "province": "Cantabria",
                    "city": "Bárcena de Cicero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39761",
                    "province": "Cantabria",
                    "city": "Voto",
                    "multiple": "1"
                },
                {
                    "zipcode": "39762",
                    "province": "Cantabria",
                    "city": "Voto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39764",
                    "province": "Cantabria",
                    "city": "Voto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39766",
                    "province": "Cantabria",
                    "city": "Voto",
                    "multiple": "0"
                },
                {
                    "zipcode": "39770",
                    "province": "Cantabria",
                    "city": "Laredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39776",
                    "province": "Cantabria",
                    "city": "Liendo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39777",
                    "province": "Cantabria",
                    "city": "Laredo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39778",
                    "province": "Cantabria",
                    "city": "Guriezo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39778",
                    "province": "Cantabria",
                    "city": "Limpias",
                    "multiple": "1"
                },
                {
                    "zipcode": "39780",
                    "province": "Cantabria",
                    "city": "Guriezo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39780",
                    "province": "Cantabria",
                    "city": "Castro-Urdiales",
                    "multiple": "1"
                },
                {
                    "zipcode": "39786",
                    "province": "Cantabria",
                    "city": "Guriezo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39787",
                    "province": "Cantabria",
                    "city": "Guriezo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39788",
                    "province": "Cantabria",
                    "city": "Guriezo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39790",
                    "province": "Cantabria",
                    "city": "Bárcena de Cicero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39791",
                    "province": "Cantabria",
                    "city": "Bárcena de Cicero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39791",
                    "province": "Cantabria",
                    "city": "Solórzano",
                    "multiple": "1"
                },
                {
                    "zipcode": "39792",
                    "province": "Cantabria",
                    "city": "Marina de Cudeyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39792",
                    "province": "Cantabria",
                    "city": "Medio Cudeyo",
                    "multiple": "1"
                },
                {
                    "zipcode": "39793",
                    "province": "Cantabria",
                    "city": "Ribamontán al Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "39794",
                    "province": "Cantabria",
                    "city": "Ribamontán al Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "39795",
                    "province": "Cantabria",
                    "city": "Escalante",
                    "multiple": "0"
                },
                {
                    "zipcode": "39796",
                    "province": "Cantabria",
                    "city": "Bárcena de Cicero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39798",
                    "province": "Cantabria",
                    "city": "Castro-Urdiales",
                    "multiple": "0"
                },
                {
                    "zipcode": "39800",
                    "province": "Cantabria",
                    "city": "Ramales de la Victoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "39805",
                    "province": "Cantabria",
                    "city": "Soba",
                    "multiple": "0"
                },
                {
                    "zipcode": "39805",
                    "province": "Cantabria",
                    "city": "Ramales de la Victoria",
                    "multiple": "1"
                },
                {
                    "zipcode": "39806",
                    "province": "Cantabria",
                    "city": "Soba",
                    "multiple": "0"
                },
                {
                    "zipcode": "39807",
                    "province": "Cantabria",
                    "city": "Soba",
                    "multiple": "0"
                },
                {
                    "zipcode": "39808",
                    "province": "Cantabria",
                    "city": "Soba",
                    "multiple": "0"
                },
                {
                    "zipcode": "39809",
                    "province": "Cantabria",
                    "city": "Ramales de la Victoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "39811",
                    "province": "Cantabria",
                    "city": "Ramales de la Victoria",
                    "multiple": "0"
                },
                {
                    "zipcode": "39812",
                    "province": "Cantabria",
                    "city": "Ruesga",
                    "multiple": "0"
                },
                {
                    "zipcode": "39813",
                    "province": "Cantabria",
                    "city": "Arredondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39813",
                    "province": "Cantabria",
                    "city": "Soba",
                    "multiple": "1"
                },
                {
                    "zipcode": "39815",
                    "province": "Cantabria",
                    "city": "Ruesga",
                    "multiple": "0"
                },
                {
                    "zipcode": "39815",
                    "province": "Cantabria",
                    "city": "Ramales de la Victoria",
                    "multiple": "1"
                },
                {
                    "zipcode": "39820",
                    "province": "Cantabria",
                    "city": "Limpias",
                    "multiple": "0"
                },
                {
                    "zipcode": "39840",
                    "province": "Cantabria",
                    "city": "Ampuero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39849",
                    "province": "Cantabria",
                    "city": "Ampuero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39850",
                    "province": "Cantabria",
                    "city": "Ampuero",
                    "multiple": "0"
                },
                {
                    "zipcode": "39860",
                    "province": "Cantabria",
                    "city": "Rasines",
                    "multiple": "0"
                },
                {
                    "zipcode": "39880",
                    "province": "Cantabria",
                    "city": "Guriezo",
                    "multiple": "0"
                },
                {
                    "zipcode": "39880",
                    "province": "Cantabria",
                    "city": "Valle de Villaverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "40001",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40002",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40003",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40004",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40005",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40006",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40024",
                    "province": "Segovia",
                    "city": "Espinar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "40100",
                    "province": "Segovia",
                    "city": "Real Sitio de San Ildefonso",
                    "multiple": "0"
                },
                {
                    "zipcode": "40109",
                    "province": "Segovia",
                    "city": "Real Sitio de San Ildefonso",
                    "multiple": "0"
                },
                {
                    "zipcode": "40120",
                    "province": "Segovia",
                    "city": "Garcillán",
                    "multiple": "0"
                },
                {
                    "zipcode": "40121",
                    "province": "Segovia",
                    "city": "Anaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "40122",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40123",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40130",
                    "province": "Segovia",
                    "city": "Martín Miguel",
                    "multiple": "0"
                },
                {
                    "zipcode": "40131",
                    "province": "Segovia",
                    "city": "Juarros de Riomoros",
                    "multiple": "0"
                },
                {
                    "zipcode": "40132",
                    "province": "Segovia",
                    "city": "Marazoleja",
                    "multiple": "0"
                },
                {
                    "zipcode": "40133",
                    "province": "Segovia",
                    "city": "Marazuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "40134",
                    "province": "Segovia",
                    "city": "Sangarcía",
                    "multiple": "0"
                },
                {
                    "zipcode": "40135",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40136",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40140",
                    "province": "Segovia",
                    "city": "Valverde del Majano",
                    "multiple": "0"
                },
                {
                    "zipcode": "40141",
                    "province": "Segovia",
                    "city": "Abades",
                    "multiple": "0"
                },
                {
                    "zipcode": "40141",
                    "province": "Segovia",
                    "city": "Santiuste de Pedraza",
                    "multiple": "1"
                },
                {
                    "zipcode": "40142",
                    "province": "Segovia",
                    "city": "Marugán",
                    "multiple": "0"
                },
                {
                    "zipcode": "40142",
                    "province": "Segovia",
                    "city": "Lastras del Pozo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40142",
                    "province": "Segovia",
                    "city": "Monterrubio",
                    "multiple": "1"
                },
                {
                    "zipcode": "40144",
                    "province": "Segovia",
                    "city": "Sangarcía",
                    "multiple": "0"
                },
                {
                    "zipcode": "40144",
                    "province": "Segovia",
                    "city": "Bercial",
                    "multiple": "1"
                },
                {
                    "zipcode": "40145",
                    "province": "Segovia",
                    "city": "Muñopedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40146",
                    "province": "Segovia",
                    "city": "Labajos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40150",
                    "province": "Segovia",
                    "city": "Villacastín",
                    "multiple": "0"
                },
                {
                    "zipcode": "40151",
                    "province": "Segovia",
                    "city": "Ituero y Lama",
                    "multiple": "0"
                },
                {
                    "zipcode": "40152",
                    "province": "Segovia",
                    "city": "Zarzuela del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "40153",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40154",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40156",
                    "province": "Segovia",
                    "city": "Fresno de Cantespino",
                    "multiple": "0"
                },
                {
                    "zipcode": "40160",
                    "province": "Segovia",
                    "city": "Torrecaballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "40161",
                    "province": "Segovia",
                    "city": "Navafría",
                    "multiple": "0"
                },
                {
                    "zipcode": "40162",
                    "province": "Segovia",
                    "city": "Aldealengua de Pedraza",
                    "multiple": "0"
                },
                {
                    "zipcode": "40162",
                    "province": "Segovia",
                    "city": "Gallegos",
                    "multiple": "1"
                },
                {
                    "zipcode": "40163",
                    "province": "Segovia",
                    "city": "Matabuena",
                    "multiple": "0"
                },
                {
                    "zipcode": "40164",
                    "province": "Segovia",
                    "city": "Arcones",
                    "multiple": "0"
                },
                {
                    "zipcode": "40165",
                    "province": "Segovia",
                    "city": "Ventosilla y Tejadilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "40165",
                    "province": "Segovia",
                    "city": "Prádena",
                    "multiple": "1"
                },
                {
                    "zipcode": "40170",
                    "province": "Segovia",
                    "city": "Pelayos del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40170",
                    "province": "Segovia",
                    "city": "Collado Hermoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "40170",
                    "province": "Segovia",
                    "city": "Sotosalbos",
                    "multiple": "1"
                },
                {
                    "zipcode": "40171",
                    "province": "Segovia",
                    "city": "Torre Val de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40171",
                    "province": "Segovia",
                    "city": "Santiuste de Pedraza",
                    "multiple": "1"
                },
                {
                    "zipcode": "40172",
                    "province": "Segovia",
                    "city": "Pedraza",
                    "multiple": "0"
                },
                {
                    "zipcode": "40173",
                    "province": "Segovia",
                    "city": "Arahuetes",
                    "multiple": "0"
                },
                {
                    "zipcode": "40173",
                    "province": "Segovia",
                    "city": "Santiuste de Pedraza",
                    "multiple": "1"
                },
                {
                    "zipcode": "40173",
                    "province": "Segovia",
                    "city": "Pedraza",
                    "multiple": "1"
                },
                {
                    "zipcode": "40174",
                    "province": "Segovia",
                    "city": "Valleruela de Pedraza",
                    "multiple": "0"
                },
                {
                    "zipcode": "40175",
                    "province": "Segovia",
                    "city": "Matilla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "40176",
                    "province": "Segovia",
                    "city": "Valleruela de Sepúlveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "40176",
                    "province": "Segovia",
                    "city": "Orejana",
                    "multiple": "1"
                },
                {
                    "zipcode": "40180",
                    "province": "Segovia",
                    "city": "Santo Domingo de Pirón",
                    "multiple": "0"
                },
                {
                    "zipcode": "40180",
                    "province": "Segovia",
                    "city": "Basardilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "40180",
                    "province": "Segovia",
                    "city": "Brieva",
                    "multiple": "1"
                },
                {
                    "zipcode": "40180",
                    "province": "Segovia",
                    "city": "Pelayos del Arroyo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40181",
                    "province": "Segovia",
                    "city": "Turégano",
                    "multiple": "0"
                },
                {
                    "zipcode": "40182",
                    "province": "Segovia",
                    "city": "Caballar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40183",
                    "province": "Segovia",
                    "city": "Muñoveros",
                    "multiple": "0"
                },
                {
                    "zipcode": "40184",
                    "province": "Segovia",
                    "city": "Rebollo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40184",
                    "province": "Segovia",
                    "city": "Arahuetes",
                    "multiple": "1"
                },
                {
                    "zipcode": "40184",
                    "province": "Segovia",
                    "city": "Puebla de Pedraza",
                    "multiple": "1"
                },
                {
                    "zipcode": "40185",
                    "province": "Segovia",
                    "city": "Arevalillo de Cega",
                    "multiple": "0"
                },
                {
                    "zipcode": "40185",
                    "province": "Segovia",
                    "city": "Cubillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40185",
                    "province": "Segovia",
                    "city": "Valdevacas y Guijar",
                    "multiple": "1"
                },
                {
                    "zipcode": "40185",
                    "province": "Segovia",
                    "city": "Valdevacas de Montejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40190",
                    "province": "Segovia",
                    "city": "Bernuy de Porreros",
                    "multiple": "0"
                },
                {
                    "zipcode": "40191",
                    "province": "Segovia",
                    "city": "Armuña",
                    "multiple": "0"
                },
                {
                    "zipcode": "40191",
                    "province": "Segovia",
                    "city": "Espirdo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40191",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "1"
                },
                {
                    "zipcode": "40192",
                    "province": "Segovia",
                    "city": "Adrada de Pirón",
                    "multiple": "0"
                },
                {
                    "zipcode": "40192",
                    "province": "Segovia",
                    "city": "Torreiglesias",
                    "multiple": "1"
                },
                {
                    "zipcode": "40194",
                    "province": "Segovia",
                    "city": "Trescasas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40194",
                    "province": "Segovia",
                    "city": "Palazuelos de Eresma",
                    "multiple": "1"
                },
                {
                    "zipcode": "40195",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40196",
                    "province": "Segovia",
                    "city": "Lastrilla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "40196",
                    "province": "Segovia",
                    "city": "Segovia",
                    "multiple": "1"
                },
                {
                    "zipcode": "40197",
                    "province": "Segovia",
                    "city": "San Cristóbal de Segovia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40200",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40210",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40211",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40212",
                    "province": "Segovia",
                    "city": "San Cristóbal de Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40213",
                    "province": "Segovia",
                    "city": "Vallelado",
                    "multiple": "0"
                },
                {
                    "zipcode": "40214",
                    "province": "Segovia",
                    "city": "Mata de Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40214",
                    "province": "Segovia",
                    "city": "Ayllón",
                    "multiple": "1"
                },
                {
                    "zipcode": "40215",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40216",
                    "province": "Segovia",
                    "city": "Chañe",
                    "multiple": "0"
                },
                {
                    "zipcode": "40216",
                    "province": "Segovia",
                    "city": "Remondo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40217",
                    "province": "Segovia",
                    "city": "Fresneda de Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40218",
                    "province": "Segovia",
                    "city": "Fuente el Olmo de Íscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40219",
                    "province": "Segovia",
                    "city": "Villaverde de Íscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40220",
                    "province": "Segovia",
                    "city": "Olombrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "40230",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40231",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40232",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40233",
                    "province": "Segovia",
                    "city": "Olombrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "40234",
                    "province": "Segovia",
                    "city": "Membibre de la Hoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "40235",
                    "province": "Segovia",
                    "city": "Aldeasoña",
                    "multiple": "0"
                },
                {
                    "zipcode": "40236",
                    "province": "Segovia",
                    "city": "Laguna de Contreras",
                    "multiple": "0"
                },
                {
                    "zipcode": "40237",
                    "province": "Segovia",
                    "city": "Sacramenia",
                    "multiple": "0"
                },
                {
                    "zipcode": "40238",
                    "province": "Segovia",
                    "city": "Valtiendas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40239",
                    "province": "Segovia",
                    "city": "Cuevas de Provanco",
                    "multiple": "0"
                },
                {
                    "zipcode": "40240",
                    "province": "Segovia",
                    "city": "Gomezserracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "40241",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40242",
                    "province": "Segovia",
                    "city": "Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40250",
                    "province": "Segovia",
                    "city": "Mozoncillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40260",
                    "province": "Segovia",
                    "city": "Fuentepelayo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40270",
                    "province": "Segovia",
                    "city": "Carbonero el Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "40280",
                    "province": "Segovia",
                    "city": "Navalmanzano",
                    "multiple": "0"
                },
                {
                    "zipcode": "40290",
                    "province": "Segovia",
                    "city": "Roda de Eresma",
                    "multiple": "0"
                },
                {
                    "zipcode": "40291",
                    "province": "Segovia",
                    "city": "Tabanera la Luenga",
                    "multiple": "0"
                },
                {
                    "zipcode": "40291",
                    "province": "Segovia",
                    "city": "Escarabajosa de Cabezas",
                    "multiple": "1"
                },
                {
                    "zipcode": "40292",
                    "province": "Segovia",
                    "city": "Aldea Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "40293",
                    "province": "Segovia",
                    "city": "Zarzuela del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40294",
                    "province": "Segovia",
                    "city": "Pinarnegrillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40295",
                    "province": "Segovia",
                    "city": "San Martín y Mudrián",
                    "multiple": "0"
                },
                {
                    "zipcode": "40296",
                    "province": "Segovia",
                    "city": "Pinarejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40297",
                    "province": "Segovia",
                    "city": "Sanchonuño",
                    "multiple": "0"
                },
                {
                    "zipcode": "40298",
                    "province": "Segovia",
                    "city": "Frumales",
                    "multiple": "0"
                },
                {
                    "zipcode": "40300",
                    "province": "Segovia",
                    "city": "Sepúlveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "40310",
                    "province": "Segovia",
                    "city": "Santa Marta del Cerro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40310",
                    "province": "Segovia",
                    "city": "Sepúlveda",
                    "multiple": "1"
                },
                {
                    "zipcode": "40311",
                    "province": "Segovia",
                    "city": "Sepúlveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "40311",
                    "province": "Segovia",
                    "city": "Sotillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40312",
                    "province": "Segovia",
                    "city": "Duruelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40312",
                    "province": "Segovia",
                    "city": "Sepúlveda",
                    "multiple": "1"
                },
                {
                    "zipcode": "40313",
                    "province": "Segovia",
                    "city": "Torreadrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "40314",
                    "province": "Segovia",
                    "city": "Valtiendas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40314",
                    "province": "Segovia",
                    "city": "Fuentesoto",
                    "multiple": "1"
                },
                {
                    "zipcode": "40315",
                    "province": "Segovia",
                    "city": "Castrojimeno",
                    "multiple": "0"
                },
                {
                    "zipcode": "40315",
                    "province": "Segovia",
                    "city": "Castro de Fuentidueña",
                    "multiple": "1"
                },
                {
                    "zipcode": "40316",
                    "province": "Segovia",
                    "city": "Castroserracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "40317",
                    "province": "Segovia",
                    "city": "Sepúlveda",
                    "multiple": "0"
                },
                {
                    "zipcode": "40317",
                    "province": "Segovia",
                    "city": "Urueñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "40318",
                    "province": "Segovia",
                    "city": "Castroserna de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40318",
                    "province": "Segovia",
                    "city": "Prádena",
                    "multiple": "1"
                },
                {
                    "zipcode": "40318",
                    "province": "Segovia",
                    "city": "Condado de Castilnovo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40320",
                    "province": "Segovia",
                    "city": "Cantalejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40330",
                    "province": "Segovia",
                    "city": "Fuenterrebollo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40331",
                    "province": "Segovia",
                    "city": "Valle de Tabladillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40331",
                    "province": "Segovia",
                    "city": "Carrascal del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "40331",
                    "province": "Segovia",
                    "city": "Navalilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "40332",
                    "province": "Segovia",
                    "city": "San Miguel de Bernuy",
                    "multiple": "0"
                },
                {
                    "zipcode": "40332",
                    "province": "Segovia",
                    "city": "Cobos de Fuentidueña",
                    "multiple": "1"
                },
                {
                    "zipcode": "40340",
                    "province": "Segovia",
                    "city": "Sebúlcor",
                    "multiple": "0"
                },
                {
                    "zipcode": "40340",
                    "province": "Segovia",
                    "city": "Aguilafuente",
                    "multiple": "1"
                },
                {
                    "zipcode": "40350",
                    "province": "Segovia",
                    "city": "Escalona del Prado",
                    "multiple": "0"
                },
                {
                    "zipcode": "40351",
                    "province": "Segovia",
                    "city": "Sauquillo de Cabezas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40352",
                    "province": "Segovia",
                    "city": "Lastras de Cuéllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40353",
                    "province": "Segovia",
                    "city": "Hontalbilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "40354",
                    "province": "Segovia",
                    "city": "Adrados",
                    "multiple": "0"
                },
                {
                    "zipcode": "40354",
                    "province": "Segovia",
                    "city": "Cozuelos de Fuentidueña",
                    "multiple": "1"
                },
                {
                    "zipcode": "40354",
                    "province": "Segovia",
                    "city": "Perosillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40355",
                    "province": "Segovia",
                    "city": "Fuentesaúco de Fuentidueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "40356",
                    "province": "Segovia",
                    "city": "Calabazas de Fuentidueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "40357",
                    "province": "Segovia",
                    "city": "Fuentidueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "40358",
                    "province": "Segovia",
                    "city": "Fuentepiñel",
                    "multiple": "0"
                },
                {
                    "zipcode": "40359",
                    "province": "Segovia",
                    "city": "Torrecilla del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "40359",
                    "province": "Segovia",
                    "city": "Fuente el Olmo de Fuentidueña",
                    "multiple": "1"
                },
                {
                    "zipcode": "40360",
                    "province": "Segovia",
                    "city": "Cantimpalos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40370",
                    "province": "Segovia",
                    "city": "Turégano",
                    "multiple": "0"
                },
                {
                    "zipcode": "40380",
                    "province": "Segovia",
                    "city": "Sebúlcor",
                    "multiple": "0"
                },
                {
                    "zipcode": "40380",
                    "province": "Segovia",
                    "city": "Cantalejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40389",
                    "province": "Segovia",
                    "city": "Aldealcorvo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40389",
                    "province": "Segovia",
                    "city": "Cantalejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40389",
                    "province": "Segovia",
                    "city": "San Pedro de Gaíllos",
                    "multiple": "1"
                },
                {
                    "zipcode": "40389",
                    "province": "Segovia",
                    "city": "Sepúlveda",
                    "multiple": "1"
                },
                {
                    "zipcode": "40390",
                    "province": "Segovia",
                    "city": "Valseca",
                    "multiple": "0"
                },
                {
                    "zipcode": "40391",
                    "province": "Segovia",
                    "city": "Encinillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40392",
                    "province": "Segovia",
                    "city": "Escobar de Polendos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40392",
                    "province": "Segovia",
                    "city": "Cabañas de Polendos",
                    "multiple": "1"
                },
                {
                    "zipcode": "40393",
                    "province": "Segovia",
                    "city": "Escobar de Polendos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40394",
                    "province": "Segovia",
                    "city": "Torreiglesias",
                    "multiple": "0"
                },
                {
                    "zipcode": "40395",
                    "province": "Segovia",
                    "city": "Veganzones",
                    "multiple": "0"
                },
                {
                    "zipcode": "40396",
                    "province": "Segovia",
                    "city": "Cabezuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "40400",
                    "province": "Segovia",
                    "city": "Espinar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "40408",
                    "province": "Segovia",
                    "city": "Navas de San Antonio",
                    "multiple": "0"
                },
                {
                    "zipcode": "40410",
                    "province": "Segovia",
                    "city": "Espinar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "40418",
                    "province": "Segovia",
                    "city": "Fresno de Cantespino",
                    "multiple": "0"
                },
                {
                    "zipcode": "40419",
                    "province": "Segovia",
                    "city": "Espinar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "40420",
                    "province": "Segovia",
                    "city": "Navas de Riofrío",
                    "multiple": "0"
                },
                {
                    "zipcode": "40420",
                    "province": "Segovia",
                    "city": "Losa, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "40420",
                    "province": "Segovia",
                    "city": "Real Sitio de San Ildefonso",
                    "multiple": "1"
                },
                {
                    "zipcode": "40421",
                    "province": "Segovia",
                    "city": "Ortigosa del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "40422",
                    "province": "Segovia",
                    "city": "Otero de Herreros",
                    "multiple": "0"
                },
                {
                    "zipcode": "40423",
                    "province": "Segovia",
                    "city": "Vegas de Matute",
                    "multiple": "0"
                },
                {
                    "zipcode": "40423",
                    "province": "Segovia",
                    "city": "Valdeprados",
                    "multiple": "1"
                },
                {
                    "zipcode": "40424",
                    "province": "Segovia",
                    "city": "Espinar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "40430",
                    "province": "Segovia",
                    "city": "Bernardos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40440",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40441",
                    "province": "Segovia",
                    "city": "Migueláñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "40442",
                    "province": "Segovia",
                    "city": "Samboal",
                    "multiple": "0"
                },
                {
                    "zipcode": "40443",
                    "province": "Segovia",
                    "city": "Samboal",
                    "multiple": "0"
                },
                {
                    "zipcode": "40444",
                    "province": "Segovia",
                    "city": "Melque de Cercos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40445",
                    "province": "Segovia",
                    "city": "Juarros de Voltoya",
                    "multiple": "0"
                },
                {
                    "zipcode": "40446",
                    "province": "Segovia",
                    "city": "Martín Muñoz de las Posadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40447",
                    "province": "Segovia",
                    "city": "Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40448",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40449",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "0"
                },
                {
                    "zipcode": "40450",
                    "province": "Segovia",
                    "city": "Nava de la Asunción",
                    "multiple": "0"
                },
                {
                    "zipcode": "40460",
                    "province": "Segovia",
                    "city": "Santiuste de San Juan Bautista",
                    "multiple": "0"
                },
                {
                    "zipcode": "40461",
                    "province": "Segovia",
                    "city": "Nava de la Asunción",
                    "multiple": "0"
                },
                {
                    "zipcode": "40462",
                    "province": "Segovia",
                    "city": "Aldeanueva del Codonal",
                    "multiple": "0"
                },
                {
                    "zipcode": "40462",
                    "province": "Segovia",
                    "city": "Aldehuela del Codonal",
                    "multiple": "1"
                },
                {
                    "zipcode": "40463",
                    "province": "Segovia",
                    "city": "Codorniz",
                    "multiple": "0"
                },
                {
                    "zipcode": "40464",
                    "province": "Segovia",
                    "city": "Codorniz",
                    "multiple": "0"
                },
                {
                    "zipcode": "40465",
                    "province": "Segovia",
                    "city": "San Cristóbal de la Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "40466",
                    "province": "Segovia",
                    "city": "Martín Muñoz de la Dehesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "40466",
                    "province": "Segovia",
                    "city": "Rapariegos",
                    "multiple": "1"
                },
                {
                    "zipcode": "40467",
                    "province": "Segovia",
                    "city": "Tolocirio",
                    "multiple": "0"
                },
                {
                    "zipcode": "40468",
                    "province": "Segovia",
                    "city": "Montejo de Arévalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40469",
                    "province": "Segovia",
                    "city": "Donhierro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40470",
                    "province": "Segovia",
                    "city": "Navas de Oro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40480",
                    "province": "Segovia",
                    "city": "Coca",
                    "multiple": "0"
                },
                {
                    "zipcode": "40490",
                    "province": "Segovia",
                    "city": "Hontanares de Eresma",
                    "multiple": "0"
                },
                {
                    "zipcode": "40490",
                    "province": "Segovia",
                    "city": "Huertos, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "40491",
                    "province": "Segovia",
                    "city": "Armuña",
                    "multiple": "0"
                },
                {
                    "zipcode": "40492",
                    "province": "Segovia",
                    "city": "Añe",
                    "multiple": "0"
                },
                {
                    "zipcode": "40493",
                    "province": "Segovia",
                    "city": "Yanguas de Eresma",
                    "multiple": "0"
                },
                {
                    "zipcode": "40494",
                    "province": "Segovia",
                    "city": "Armuña",
                    "multiple": "0"
                },
                {
                    "zipcode": "40494",
                    "province": "Segovia",
                    "city": "Santa María la Real de Nieva",
                    "multiple": "1"
                },
                {
                    "zipcode": "40495",
                    "province": "Segovia",
                    "city": "Ortigosa de Pestaño",
                    "multiple": "0"
                },
                {
                    "zipcode": "40495",
                    "province": "Segovia",
                    "city": "Domingo García",
                    "multiple": "1"
                },
                {
                    "zipcode": "40496",
                    "province": "Segovia",
                    "city": "Coca",
                    "multiple": "0"
                },
                {
                    "zipcode": "40496",
                    "province": "Segovia",
                    "city": "Fuente de Santa Cruz",
                    "multiple": "1"
                },
                {
                    "zipcode": "40496",
                    "province": "Segovia",
                    "city": "Villeguillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40500",
                    "province": "Segovia",
                    "city": "Riaza",
                    "multiple": "0"
                },
                {
                    "zipcode": "40510",
                    "province": "Segovia",
                    "city": "Riaza",
                    "multiple": "0"
                },
                {
                    "zipcode": "40511",
                    "province": "Segovia",
                    "city": "Riaza",
                    "multiple": "0"
                },
                {
                    "zipcode": "40512",
                    "province": "Segovia",
                    "city": "Ayllón",
                    "multiple": "0"
                },
                {
                    "zipcode": "40512",
                    "province": "Segovia",
                    "city": "Riaza",
                    "multiple": "1"
                },
                {
                    "zipcode": "40513",
                    "province": "Segovia",
                    "city": "Ribota",
                    "multiple": "0"
                },
                {
                    "zipcode": "40514",
                    "province": "Segovia",
                    "city": "Ayllón",
                    "multiple": "0"
                },
                {
                    "zipcode": "40515",
                    "province": "Segovia",
                    "city": "Riofrío de Riaza",
                    "multiple": "0"
                },
                {
                    "zipcode": "40516",
                    "province": "Segovia",
                    "city": "Fresno de Cantespino",
                    "multiple": "0"
                },
                {
                    "zipcode": "40517",
                    "province": "Segovia",
                    "city": "Sequera de Fresno",
                    "multiple": "0"
                },
                {
                    "zipcode": "40517",
                    "province": "Segovia",
                    "city": "Riaza",
                    "multiple": "1"
                },
                {
                    "zipcode": "40518",
                    "province": "Segovia",
                    "city": "Fresno de Cantespino",
                    "multiple": "0"
                },
                {
                    "zipcode": "40520",
                    "province": "Segovia",
                    "city": "Ayllón",
                    "multiple": "0"
                },
                {
                    "zipcode": "40529",
                    "province": "Segovia",
                    "city": "Alconada de Maderuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40529",
                    "province": "Segovia",
                    "city": "Riaguas de San Bartolomé",
                    "multiple": "1"
                },
                {
                    "zipcode": "40529",
                    "province": "Segovia",
                    "city": "Corral de Ayllón",
                    "multiple": "1"
                },
                {
                    "zipcode": "40530",
                    "province": "Segovia",
                    "city": "Aldeonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "40530",
                    "province": "Segovia",
                    "city": "Barbolla",
                    "multiple": "1"
                },
                {
                    "zipcode": "40531",
                    "province": "Segovia",
                    "city": "Navares de Ayuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "40531",
                    "province": "Segovia",
                    "city": "Aldeonte",
                    "multiple": "1"
                },
                {
                    "zipcode": "40531",
                    "province": "Segovia",
                    "city": "Encinas",
                    "multiple": "1"
                },
                {
                    "zipcode": "40532",
                    "province": "Segovia",
                    "city": "Navares de Enmedio",
                    "multiple": "0"
                },
                {
                    "zipcode": "40532",
                    "province": "Segovia",
                    "city": "Aldeanueva de la Serrezuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "40532",
                    "province": "Segovia",
                    "city": "Navares de las Cuevas",
                    "multiple": "1"
                },
                {
                    "zipcode": "40533",
                    "province": "Segovia",
                    "city": "Aldehorno",
                    "multiple": "0"
                },
                {
                    "zipcode": "40540",
                    "province": "Segovia",
                    "city": "Fresno de la Fuente",
                    "multiple": "0"
                },
                {
                    "zipcode": "40540",
                    "province": "Segovia",
                    "city": "Pradales",
                    "multiple": "1"
                },
                {
                    "zipcode": "40541",
                    "province": "Segovia",
                    "city": "Honrubia de la Cuesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "40541",
                    "province": "Segovia",
                    "city": "Villaverde de Montejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40542",
                    "province": "Segovia",
                    "city": "Montejo de la Vega de la Serrezuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "40542",
                    "province": "Segovia",
                    "city": "Moral de Hornuez",
                    "multiple": "1"
                },
                {
                    "zipcode": "40542",
                    "province": "Segovia",
                    "city": "Villaverde de Montejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "40550",
                    "province": "Segovia",
                    "city": "Bercimuel",
                    "multiple": "0"
                },
                {
                    "zipcode": "40550",
                    "province": "Segovia",
                    "city": "Cedillo de la Torre",
                    "multiple": "1"
                },
                {
                    "zipcode": "40551",
                    "province": "Segovia",
                    "city": "Campo de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40551",
                    "province": "Segovia",
                    "city": "Cilleruelo de San Mamés",
                    "multiple": "1"
                },
                {
                    "zipcode": "40552",
                    "province": "Segovia",
                    "city": "Campo de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40553",
                    "province": "Segovia",
                    "city": "Campo de San Pedro",
                    "multiple": "0"
                },
                {
                    "zipcode": "40554",
                    "province": "Segovia",
                    "city": "Maderuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40555",
                    "province": "Segovia",
                    "city": "Aldealengua de Santa María",
                    "multiple": "0"
                },
                {
                    "zipcode": "40556",
                    "province": "Segovia",
                    "city": "Languilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "40560",
                    "province": "Segovia",
                    "city": "Boceguillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40561",
                    "province": "Segovia",
                    "city": "Navares de Ayuso",
                    "multiple": "0"
                },
                {
                    "zipcode": "40567",
                    "province": "Segovia",
                    "city": "Pajarejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "40568",
                    "province": "Segovia",
                    "city": "Boceguillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "40569",
                    "province": "Segovia",
                    "city": "Grajera",
                    "multiple": "0"
                },
                {
                    "zipcode": "40590",
                    "province": "Segovia",
                    "city": "Casla",
                    "multiple": "0"
                },
                {
                    "zipcode": "40590",
                    "province": "Segovia",
                    "city": "Santo Tomé del Puerto",
                    "multiple": "1"
                },
                {
                    "zipcode": "40591",
                    "province": "Segovia",
                    "city": "Cerezo de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "40592",
                    "province": "Segovia",
                    "city": "Cerezo de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "40593",
                    "province": "Segovia",
                    "city": "Castillejo de Mesleón",
                    "multiple": "0"
                },
                {
                    "zipcode": "40594",
                    "province": "Segovia",
                    "city": "Ayllón",
                    "multiple": "0"
                },
                {
                    "zipcode": "41001",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41002",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41003",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41004",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41005",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41006",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41007",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41008",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41009",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41010",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41011",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41012",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41013",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41014",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41015",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41016",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41017",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41018",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41019",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41020",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41073",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41089",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41092",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41100",
                    "province": "Sevilla",
                    "city": "Coria del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41110",
                    "province": "Sevilla",
                    "city": "Bollullos de la Mitación",
                    "multiple": "0"
                },
                {
                    "zipcode": "41111",
                    "province": "Sevilla",
                    "city": "Almensilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41120",
                    "province": "Sevilla",
                    "city": "Gelves",
                    "multiple": "0"
                },
                {
                    "zipcode": "41130",
                    "province": "Sevilla",
                    "city": "Puebla del Río, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41140",
                    "province": "Sevilla",
                    "city": "Isla Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "41150",
                    "province": "Sevilla",
                    "city": "Isla Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "41200",
                    "province": "Sevilla",
                    "city": "Alcalá del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41200",
                    "province": "Sevilla",
                    "city": "Burguillos",
                    "multiple": "1"
                },
                {
                    "zipcode": "41209",
                    "province": "Sevilla",
                    "city": "Alcalá del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41210",
                    "province": "Sevilla",
                    "city": "Guillena",
                    "multiple": "0"
                },
                {
                    "zipcode": "41218",
                    "province": "Sevilla",
                    "city": "Guillena",
                    "multiple": "0"
                },
                {
                    "zipcode": "41219",
                    "province": "Sevilla",
                    "city": "Guillena",
                    "multiple": "0"
                },
                {
                    "zipcode": "41220",
                    "province": "Sevilla",
                    "city": "Burguillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "41230",
                    "province": "Sevilla",
                    "city": "Castilblanco de los Arroyos",
                    "multiple": "0"
                },
                {
                    "zipcode": "41240",
                    "province": "Sevilla",
                    "city": "Almadén de la Plata",
                    "multiple": "0"
                },
                {
                    "zipcode": "41250",
                    "province": "Sevilla",
                    "city": "Real de la Jara, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41300",
                    "province": "Sevilla",
                    "city": "Rinconada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41309",
                    "province": "Sevilla",
                    "city": "Rinconada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41310",
                    "province": "Sevilla",
                    "city": "Brenes",
                    "multiple": "0"
                },
                {
                    "zipcode": "41318",
                    "province": "Sevilla",
                    "city": "Villaverde del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41319",
                    "province": "Sevilla",
                    "city": "Alcalá del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41320",
                    "province": "Sevilla",
                    "city": "Cantillana",
                    "multiple": "0"
                },
                {
                    "zipcode": "41330",
                    "province": "Sevilla",
                    "city": "Villanueva del Río y Minas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41330",
                    "province": "Sevilla",
                    "city": "Tocina",
                    "multiple": "1"
                },
                {
                    "zipcode": "41339",
                    "province": "Sevilla",
                    "city": "Carmona",
                    "multiple": "0"
                },
                {
                    "zipcode": "41340",
                    "province": "Sevilla",
                    "city": "Tocina",
                    "multiple": "0"
                },
                {
                    "zipcode": "41350",
                    "province": "Sevilla",
                    "city": "Villanueva del Río y Minas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41359",
                    "province": "Sevilla",
                    "city": "Villanueva del Río y Minas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41360",
                    "province": "Sevilla",
                    "city": "Pedroso, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41370",
                    "province": "Sevilla",
                    "city": "Cazalla de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "41380",
                    "province": "Sevilla",
                    "city": "Alanís",
                    "multiple": "0"
                },
                {
                    "zipcode": "41388",
                    "province": "Sevilla",
                    "city": "San Nicolás del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "41389",
                    "province": "Sevilla",
                    "city": "San Nicolás del Puerto",
                    "multiple": "0"
                },
                {
                    "zipcode": "41390",
                    "province": "Sevilla",
                    "city": "Guadalcanal",
                    "multiple": "0"
                },
                {
                    "zipcode": "41400",
                    "province": "Sevilla",
                    "city": "Écija",
                    "multiple": "0"
                },
                {
                    "zipcode": "41409",
                    "province": "Sevilla",
                    "city": "Écija",
                    "multiple": "0"
                },
                {
                    "zipcode": "41410",
                    "province": "Sevilla",
                    "city": "Carmona",
                    "multiple": "0"
                },
                {
                    "zipcode": "41420",
                    "province": "Sevilla",
                    "city": "Fuentes de Andalucía",
                    "multiple": "0"
                },
                {
                    "zipcode": "41429",
                    "province": "Sevilla",
                    "city": "Campana, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41430",
                    "province": "Sevilla",
                    "city": "Luisiana, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41439",
                    "province": "Sevilla",
                    "city": "Cañada Rosal",
                    "multiple": "0"
                },
                {
                    "zipcode": "41439",
                    "province": "Sevilla",
                    "city": "Fuentes de Andalucía",
                    "multiple": "1"
                },
                {
                    "zipcode": "41439",
                    "province": "Sevilla",
                    "city": "Luisiana, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "41440",
                    "province": "Sevilla",
                    "city": "Alcolea del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41440",
                    "province": "Sevilla",
                    "city": "Lora del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "41449",
                    "province": "Sevilla",
                    "city": "Alcolea del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41450",
                    "province": "Sevilla",
                    "city": "Constantina",
                    "multiple": "0"
                },
                {
                    "zipcode": "41460",
                    "province": "Sevilla",
                    "city": "Navas de la Concepción, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "41470",
                    "province": "Sevilla",
                    "city": "Puebla de los Infantes, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41470",
                    "province": "Sevilla",
                    "city": "Peñaflor",
                    "multiple": "1"
                },
                {
                    "zipcode": "41471",
                    "province": "Sevilla",
                    "city": "Utrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "41479",
                    "province": "Sevilla",
                    "city": "Puebla de los Infantes, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41489",
                    "province": "Sevilla",
                    "city": "Aznalcázar",
                    "multiple": "0"
                },
                {
                    "zipcode": "41500",
                    "province": "Sevilla",
                    "city": "Sevilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "41500",
                    "province": "Sevilla",
                    "city": "Alcalá de Guadaíra",
                    "multiple": "1"
                },
                {
                    "zipcode": "41510",
                    "province": "Sevilla",
                    "city": "Mairena del Alcor",
                    "multiple": "0"
                },
                {
                    "zipcode": "41520",
                    "province": "Sevilla",
                    "city": "Viso del Alcor, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41520",
                    "province": "Sevilla",
                    "city": "Alcalá de Guadaíra",
                    "multiple": "1"
                },
                {
                    "zipcode": "41530",
                    "province": "Sevilla",
                    "city": "Morón de la Frontera",
                    "multiple": "0"
                },
                {
                    "zipcode": "41540",
                    "province": "Sevilla",
                    "city": "Puebla de Cazalla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41550",
                    "province": "Sevilla",
                    "city": "Aguadulce",
                    "multiple": "0"
                },
                {
                    "zipcode": "41560",
                    "province": "Sevilla",
                    "city": "Estepa",
                    "multiple": "0"
                },
                {
                    "zipcode": "41563",
                    "province": "Sevilla",
                    "city": "Écija",
                    "multiple": "0"
                },
                {
                    "zipcode": "41564",
                    "province": "Sevilla",
                    "city": "Lora de Estepa",
                    "multiple": "0"
                },
                {
                    "zipcode": "41565",
                    "province": "Sevilla",
                    "city": "Corrales, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "41565",
                    "province": "Sevilla",
                    "city": "Gilena",
                    "multiple": "1"
                },
                {
                    "zipcode": "41566",
                    "province": "Sevilla",
                    "city": "Pedrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "41567",
                    "province": "Sevilla",
                    "city": "Écija",
                    "multiple": "0"
                },
                {
                    "zipcode": "41567",
                    "province": "Sevilla",
                    "city": "Herrera",
                    "multiple": "1"
                },
                {
                    "zipcode": "41568",
                    "province": "Sevilla",
                    "city": "Rubio, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41569",
                    "province": "Sevilla",
                    "city": "Marinaleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "41570",
                    "province": "Sevilla",
                    "city": "Badolatosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "41580",
                    "province": "Sevilla",
                    "city": "Casariche",
                    "multiple": "0"
                },
                {
                    "zipcode": "41590",
                    "province": "Sevilla",
                    "city": "Roda de Andalucía, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41599",
                    "province": "Sevilla",
                    "city": "Roda de Andalucía, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41599",
                    "province": "Sevilla",
                    "city": "Badolatosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "41600",
                    "province": "Sevilla",
                    "city": "Arahal",
                    "multiple": "0"
                },
                {
                    "zipcode": "41610",
                    "province": "Sevilla",
                    "city": "Paradas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41620",
                    "province": "Sevilla",
                    "city": "Marchena",
                    "multiple": "0"
                },
                {
                    "zipcode": "41630",
                    "province": "Sevilla",
                    "city": "Lantejuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "41640",
                    "province": "Sevilla",
                    "city": "Osuna",
                    "multiple": "0"
                },
                {
                    "zipcode": "41650",
                    "province": "Sevilla",
                    "city": "Saucejo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41657",
                    "province": "Sevilla",
                    "city": "Corrales, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "41658",
                    "province": "Sevilla",
                    "city": "Martín de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "41659",
                    "province": "Sevilla",
                    "city": "Saucejo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41660",
                    "province": "Sevilla",
                    "city": "Villanueva de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "41661",
                    "province": "Sevilla",
                    "city": "Algámitas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41670",
                    "province": "Sevilla",
                    "city": "Pruna",
                    "multiple": "0"
                },
                {
                    "zipcode": "41700",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41701",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41702",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41703",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41704",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41710",
                    "province": "Sevilla",
                    "city": "Utrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "41719",
                    "province": "Sevilla",
                    "city": "Utrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "41720",
                    "province": "Sevilla",
                    "city": "Utrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "41720",
                    "province": "Sevilla",
                    "city": "Palacios y Villafranca, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "41727",
                    "province": "Sevilla",
                    "city": "Palacios y Villafranca, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "41727",
                    "province": "Sevilla",
                    "city": "Utrera",
                    "multiple": "1"
                },
                {
                    "zipcode": "41728",
                    "province": "Sevilla",
                    "city": "Dos Hermanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41728",
                    "province": "Sevilla",
                    "city": "Utrera",
                    "multiple": "1"
                },
                {
                    "zipcode": "41728",
                    "province": "Sevilla",
                    "city": "Palacios y Villafranca, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "41729",
                    "province": "Sevilla",
                    "city": "Cabezas de San Juan, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "41730",
                    "province": "Sevilla",
                    "city": "Cazalla de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "41730",
                    "province": "Sevilla",
                    "city": "Cabezas de San Juan, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "41731",
                    "province": "Sevilla",
                    "city": "Cabezas de San Juan, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "41740",
                    "province": "Sevilla",
                    "city": "Lebrija",
                    "multiple": "0"
                },
                {
                    "zipcode": "41749",
                    "province": "Sevilla",
                    "city": "Cuervo de Sevilla, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41750",
                    "province": "Sevilla",
                    "city": "Molares, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "41760",
                    "province": "Sevilla",
                    "city": "Coronil, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41770",
                    "province": "Sevilla",
                    "city": "Montellano",
                    "multiple": "0"
                },
                {
                    "zipcode": "41780",
                    "province": "Sevilla",
                    "city": "Coripe",
                    "multiple": "0"
                },
                {
                    "zipcode": "41800",
                    "province": "Sevilla",
                    "city": "Sanlúcar la Mayor",
                    "multiple": "0"
                },
                {
                    "zipcode": "41804",
                    "province": "Sevilla",
                    "city": "Olivares",
                    "multiple": "0"
                },
                {
                    "zipcode": "41805",
                    "province": "Sevilla",
                    "city": "Benacazón",
                    "multiple": "0"
                },
                {
                    "zipcode": "41806",
                    "province": "Sevilla",
                    "city": "Umbrete",
                    "multiple": "0"
                },
                {
                    "zipcode": "41807",
                    "province": "Sevilla",
                    "city": "Espartinas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41808",
                    "province": "Sevilla",
                    "city": "Villanueva del Ariscal",
                    "multiple": "0"
                },
                {
                    "zipcode": "41809",
                    "province": "Sevilla",
                    "city": "Albaida del Aljarafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "41810",
                    "province": "Sevilla",
                    "city": "Castilleja del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "41820",
                    "province": "Sevilla",
                    "city": "Carrión de los Céspedes",
                    "multiple": "0"
                },
                {
                    "zipcode": "41830",
                    "province": "Sevilla",
                    "city": "Huévar del Aljarafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "41840",
                    "province": "Sevilla",
                    "city": "Pilas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41849",
                    "province": "Sevilla",
                    "city": "Aznalcázar",
                    "multiple": "0"
                },
                {
                    "zipcode": "41850",
                    "province": "Sevilla",
                    "city": "Villamanrique de la Condesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "41860",
                    "province": "Sevilla",
                    "city": "Gerena",
                    "multiple": "0"
                },
                {
                    "zipcode": "41870",
                    "province": "Sevilla",
                    "city": "Aznalcóllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "41880",
                    "province": "Sevilla",
                    "city": "Ronquillo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41888",
                    "province": "Sevilla",
                    "city": "Garrobo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41888",
                    "province": "Sevilla",
                    "city": "San Nicolás del Puerto",
                    "multiple": "1"
                },
                {
                    "zipcode": "41889",
                    "province": "Sevilla",
                    "city": "Castillo de las Guardas, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41890",
                    "province": "Sevilla",
                    "city": "Castillo de las Guardas, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41897",
                    "province": "Sevilla",
                    "city": "Madroño, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41898",
                    "province": "Sevilla",
                    "city": "Madroño, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41898",
                    "province": "Sevilla",
                    "city": "Castillo de las Guardas, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "41899",
                    "province": "Sevilla",
                    "city": "Castillo de las Guardas, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "41900",
                    "province": "Sevilla",
                    "city": "Camas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41907",
                    "province": "Sevilla",
                    "city": "Valencina de la Concepción",
                    "multiple": "0"
                },
                {
                    "zipcode": "41908",
                    "province": "Sevilla",
                    "city": "Castilleja de Guzmán",
                    "multiple": "0"
                },
                {
                    "zipcode": "41909",
                    "province": "Sevilla",
                    "city": "Salteras",
                    "multiple": "0"
                },
                {
                    "zipcode": "41910",
                    "province": "Sevilla",
                    "city": "Camas",
                    "multiple": "0"
                },
                {
                    "zipcode": "41920",
                    "province": "Sevilla",
                    "city": "San Juan de Aznalfarache",
                    "multiple": "0"
                },
                {
                    "zipcode": "41926",
                    "province": "Sevilla",
                    "city": "Mairena del Aljarafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "41927",
                    "province": "Sevilla",
                    "city": "Mairena del Aljarafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "41928",
                    "province": "Sevilla",
                    "city": "Palomares del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "41929",
                    "province": "Sevilla",
                    "city": "Mairena del Aljarafe",
                    "multiple": "0"
                },
                {
                    "zipcode": "41930",
                    "province": "Sevilla",
                    "city": "Bormujos",
                    "multiple": "0"
                },
                {
                    "zipcode": "41940",
                    "province": "Sevilla",
                    "city": "Tomares",
                    "multiple": "0"
                },
                {
                    "zipcode": "41950",
                    "province": "Sevilla",
                    "city": "Castilleja de la Cuesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "41960",
                    "province": "Sevilla",
                    "city": "Gines",
                    "multiple": "0"
                },
                {
                    "zipcode": "41970",
                    "province": "Sevilla",
                    "city": "Santiponce",
                    "multiple": "0"
                },
                {
                    "zipcode": "41980",
                    "province": "Sevilla",
                    "city": "Algaba, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "41989",
                    "province": "Sevilla",
                    "city": "Algaba, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "42001",
                    "province": "Soria",
                    "city": "Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42002",
                    "province": "Soria",
                    "city": "Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42003",
                    "province": "Soria",
                    "city": "Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42004",
                    "province": "Soria",
                    "city": "Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42005",
                    "province": "Soria",
                    "city": "Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42005",
                    "province": "Soria",
                    "city": "Ágreda",
                    "multiple": "1"
                },
                {
                    "zipcode": "42100",
                    "province": "Soria",
                    "city": "Ágreda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42100",
                    "province": "Soria",
                    "city": "Ólvega",
                    "multiple": "1"
                },
                {
                    "zipcode": "42107",
                    "province": "Soria",
                    "city": "Beratón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42107",
                    "province": "Soria",
                    "city": "Cueva de Ágreda",
                    "multiple": "1"
                },
                {
                    "zipcode": "42107",
                    "province": "Soria",
                    "city": "Ágreda",
                    "multiple": "1"
                },
                {
                    "zipcode": "42108",
                    "province": "Soria",
                    "city": "Ágreda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42108",
                    "province": "Soria",
                    "city": "Dévanos",
                    "multiple": "1"
                },
                {
                    "zipcode": "42108",
                    "province": "Soria",
                    "city": "Ólvega",
                    "multiple": "1"
                },
                {
                    "zipcode": "42108",
                    "province": "Soria",
                    "city": "Castilruiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42109",
                    "province": "Soria",
                    "city": "Vozmediano",
                    "multiple": "0"
                },
                {
                    "zipcode": "42110",
                    "province": "Soria",
                    "city": "Ólvega",
                    "multiple": "0"
                },
                {
                    "zipcode": "42111",
                    "province": "Soria",
                    "city": "Aldealpozo",
                    "multiple": "0"
                },
                {
                    "zipcode": "42111",
                    "province": "Soria",
                    "city": "Valdegeña",
                    "multiple": "1"
                },
                {
                    "zipcode": "42111",
                    "province": "Soria",
                    "city": "Arancón",
                    "multiple": "1"
                },
                {
                    "zipcode": "42112",
                    "province": "Soria",
                    "city": "Villar del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "42112",
                    "province": "Soria",
                    "city": "Pinilla del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42112",
                    "province": "Soria",
                    "city": "Tajahuerce",
                    "multiple": "1"
                },
                {
                    "zipcode": "42112",
                    "province": "Soria",
                    "city": "Hinojosa del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42112",
                    "province": "Soria",
                    "city": "Pozalmuro",
                    "multiple": "1"
                },
                {
                    "zipcode": "42113",
                    "province": "Soria",
                    "city": "Fuentestrún",
                    "multiple": "0"
                },
                {
                    "zipcode": "42113",
                    "province": "Soria",
                    "city": "Matalebreras",
                    "multiple": "1"
                },
                {
                    "zipcode": "42113",
                    "province": "Soria",
                    "city": "Trévago",
                    "multiple": "1"
                },
                {
                    "zipcode": "42113",
                    "province": "Soria",
                    "city": "Valdelagua del Cerro",
                    "multiple": "1"
                },
                {
                    "zipcode": "42113",
                    "province": "Soria",
                    "city": "Castilruiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42114",
                    "province": "Soria",
                    "city": "San Felices",
                    "multiple": "0"
                },
                {
                    "zipcode": "42114",
                    "province": "Soria",
                    "city": "Cigudosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "42114",
                    "province": "Soria",
                    "city": "Valdeprado",
                    "multiple": "1"
                },
                {
                    "zipcode": "42120",
                    "province": "Soria",
                    "city": "Gómara",
                    "multiple": "0"
                },
                {
                    "zipcode": "42124",
                    "province": "Soria",
                    "city": "Torlengua",
                    "multiple": "0"
                },
                {
                    "zipcode": "42124",
                    "province": "Soria",
                    "city": "Noviercas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42124",
                    "province": "Soria",
                    "city": "Cañamaque",
                    "multiple": "1"
                },
                {
                    "zipcode": "42124",
                    "province": "Soria",
                    "city": "Fuentelmonge",
                    "multiple": "1"
                },
                {
                    "zipcode": "42125",
                    "province": "Soria",
                    "city": "Deza",
                    "multiple": "0"
                },
                {
                    "zipcode": "42126",
                    "province": "Soria",
                    "city": "Cihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "42126",
                    "province": "Soria",
                    "city": "Deza",
                    "multiple": "1"
                },
                {
                    "zipcode": "42126",
                    "province": "Soria",
                    "city": "Almazul",
                    "multiple": "1"
                },
                {
                    "zipcode": "42127",
                    "province": "Soria",
                    "city": "Gómara",
                    "multiple": "0"
                },
                {
                    "zipcode": "42127",
                    "province": "Soria",
                    "city": "Montenegro de Cameros",
                    "multiple": "1"
                },
                {
                    "zipcode": "42127",
                    "province": "Soria",
                    "city": "Almazul",
                    "multiple": "1"
                },
                {
                    "zipcode": "42127",
                    "province": "Soria",
                    "city": "Serón de Nágima",
                    "multiple": "1"
                },
                {
                    "zipcode": "42128",
                    "province": "Soria",
                    "city": "Bliecos",
                    "multiple": "0"
                },
                {
                    "zipcode": "42128",
                    "province": "Soria",
                    "city": "Tejado",
                    "multiple": "1"
                },
                {
                    "zipcode": "42129",
                    "province": "Soria",
                    "city": "Gómara",
                    "multiple": "0"
                },
                {
                    "zipcode": "42129",
                    "province": "Soria",
                    "city": "Villaseca de Arciel",
                    "multiple": "1"
                },
                {
                    "zipcode": "42129",
                    "province": "Soria",
                    "city": "Aliud",
                    "multiple": "1"
                },
                {
                    "zipcode": "42129",
                    "province": "Soria",
                    "city": "Buberos",
                    "multiple": "1"
                },
                {
                    "zipcode": "42130",
                    "province": "Soria",
                    "city": "Almenar de Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42131",
                    "province": "Soria",
                    "city": "Almenar de Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42132",
                    "province": "Soria",
                    "city": "Noviercas",
                    "multiple": "0"
                },
                {
                    "zipcode": "42133",
                    "province": "Soria",
                    "city": "Alconaba",
                    "multiple": "0"
                },
                {
                    "zipcode": "42134",
                    "province": "Soria",
                    "city": "Candilichera",
                    "multiple": "0"
                },
                {
                    "zipcode": "42134",
                    "province": "Soria",
                    "city": "Renieblas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42135",
                    "province": "Soria",
                    "city": "Cabrejas del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "42135",
                    "province": "Soria",
                    "city": "Aldealafuente",
                    "multiple": "1"
                },
                {
                    "zipcode": "42136",
                    "province": "Soria",
                    "city": "Portillo de Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42136",
                    "province": "Soria",
                    "city": "Torrubia de Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42136",
                    "province": "Soria",
                    "city": "Almenar de Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42137",
                    "province": "Soria",
                    "city": "Carabantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "42137",
                    "province": "Soria",
                    "city": "Quiñonería",
                    "multiple": "1"
                },
                {
                    "zipcode": "42137",
                    "province": "Soria",
                    "city": "Reznos",
                    "multiple": "1"
                },
                {
                    "zipcode": "42138",
                    "province": "Soria",
                    "city": "Borobia",
                    "multiple": "0"
                },
                {
                    "zipcode": "42138",
                    "province": "Soria",
                    "city": "Ciria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42138",
                    "province": "Soria",
                    "city": "Almenar de Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42140",
                    "province": "Soria",
                    "city": "San Leonardo de Yagüe",
                    "multiple": "0"
                },
                {
                    "zipcode": "42141",
                    "province": "Soria",
                    "city": "Espeja de San Marcelino",
                    "multiple": "0"
                },
                {
                    "zipcode": "42141",
                    "province": "Soria",
                    "city": "Santa María de las Hoyas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42142",
                    "province": "Soria",
                    "city": "Espeja de San Marcelino",
                    "multiple": "0"
                },
                {
                    "zipcode": "42142",
                    "province": "Soria",
                    "city": "Espejón",
                    "multiple": "1"
                },
                {
                    "zipcode": "42143",
                    "province": "Soria",
                    "city": "Nafría de Ucero",
                    "multiple": "0"
                },
                {
                    "zipcode": "42143",
                    "province": "Soria",
                    "city": "Fuentearmegil",
                    "multiple": "1"
                },
                {
                    "zipcode": "42144",
                    "province": "Soria",
                    "city": "Casarejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "42144",
                    "province": "Soria",
                    "city": "Vadillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42144",
                    "province": "Soria",
                    "city": "Herrera de Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42145",
                    "province": "Soria",
                    "city": "Cidones",
                    "multiple": "0"
                },
                {
                    "zipcode": "42146",
                    "province": "Soria",
                    "city": "Cabrejas del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "42146",
                    "province": "Soria",
                    "city": "Abejar",
                    "multiple": "1"
                },
                {
                    "zipcode": "42147",
                    "province": "Soria",
                    "city": "Cabrejas del Pinar",
                    "multiple": "0"
                },
                {
                    "zipcode": "42148",
                    "province": "Soria",
                    "city": "Muriel Viejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "42148",
                    "province": "Soria",
                    "city": "Cubilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "42148",
                    "province": "Soria",
                    "city": "Talveila",
                    "multiple": "1"
                },
                {
                    "zipcode": "42149",
                    "province": "Soria",
                    "city": "Navaleno",
                    "multiple": "0"
                },
                {
                    "zipcode": "42150",
                    "province": "Soria",
                    "city": "Vinuesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "42151",
                    "province": "Soria",
                    "city": "Vinuesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "42152",
                    "province": "Soria",
                    "city": "Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42153",
                    "province": "Soria",
                    "city": "Royo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "42155",
                    "province": "Soria",
                    "city": "Molinos de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "42156",
                    "province": "Soria",
                    "city": "Molinos de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "42156",
                    "province": "Soria",
                    "city": "Salduero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42157",
                    "province": "Soria",
                    "city": "Covaleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42158",
                    "province": "Soria",
                    "city": "Duruelo de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "42159",
                    "province": "Soria",
                    "city": "Royo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "42160",
                    "province": "Soria",
                    "city": "Almarza",
                    "multiple": "0"
                },
                {
                    "zipcode": "42161",
                    "province": "Soria",
                    "city": "Almarza",
                    "multiple": "0"
                },
                {
                    "zipcode": "42161",
                    "province": "Soria",
                    "city": "Arévalo de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "42162",
                    "province": "Soria",
                    "city": "Fuentelsaz de Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42162",
                    "province": "Soria",
                    "city": "Fuentecantos",
                    "multiple": "1"
                },
                {
                    "zipcode": "42162",
                    "province": "Soria",
                    "city": "Garray",
                    "multiple": "1"
                },
                {
                    "zipcode": "42162",
                    "province": "Soria",
                    "city": "Buitrago",
                    "multiple": "1"
                },
                {
                    "zipcode": "42163",
                    "province": "Soria",
                    "city": "Garray",
                    "multiple": "0"
                },
                {
                    "zipcode": "42164",
                    "province": "Soria",
                    "city": "Almarza",
                    "multiple": "0"
                },
                {
                    "zipcode": "42165",
                    "province": "Soria",
                    "city": "Sotillo del Rincón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42165",
                    "province": "Soria",
                    "city": "Rebollar",
                    "multiple": "1"
                },
                {
                    "zipcode": "42165",
                    "province": "Soria",
                    "city": "Rollamienta",
                    "multiple": "1"
                },
                {
                    "zipcode": "42165",
                    "province": "Soria",
                    "city": "Valdeavellano de Tera",
                    "multiple": "1"
                },
                {
                    "zipcode": "42165",
                    "province": "Soria",
                    "city": "Villar del Ala",
                    "multiple": "1"
                },
                {
                    "zipcode": "42166",
                    "province": "Soria",
                    "city": "Sotillo del Rincón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42167",
                    "province": "Soria",
                    "city": "Almarza",
                    "multiple": "0"
                },
                {
                    "zipcode": "42169",
                    "province": "Soria",
                    "city": "Póveda de Soria, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "42169",
                    "province": "Soria",
                    "city": "Almarza",
                    "multiple": "1"
                },
                {
                    "zipcode": "42170",
                    "province": "Soria",
                    "city": "Fuentelsaz de Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42171",
                    "province": "Soria",
                    "city": "Castilfrío de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "42171",
                    "province": "Soria",
                    "city": "Estepa de San Juan",
                    "multiple": "1"
                },
                {
                    "zipcode": "42171",
                    "province": "Soria",
                    "city": "Villares de Soria, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "42171",
                    "province": "Soria",
                    "city": "Ausejo de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "42171",
                    "province": "Soria",
                    "city": "Aldealices",
                    "multiple": "1"
                },
                {
                    "zipcode": "42172",
                    "province": "Soria",
                    "city": "Oncala",
                    "multiple": "0"
                },
                {
                    "zipcode": "42172",
                    "province": "Soria",
                    "city": "Yanguas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42173",
                    "province": "Soria",
                    "city": "Vizmanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "42173",
                    "province": "Soria",
                    "city": "Santa Cruz de Yanguas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42173",
                    "province": "Soria",
                    "city": "Aldehuelas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "42174",
                    "province": "Soria",
                    "city": "Villar del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "42174",
                    "province": "Soria",
                    "city": "San Pedro Manrique",
                    "multiple": "1"
                },
                {
                    "zipcode": "42174",
                    "province": "Soria",
                    "city": "Aldehuelas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "42175",
                    "province": "Soria",
                    "city": "Valdeprado",
                    "multiple": "0"
                },
                {
                    "zipcode": "42175",
                    "province": "Soria",
                    "city": "San Pedro Manrique",
                    "multiple": "1"
                },
                {
                    "zipcode": "42175",
                    "province": "Soria",
                    "city": "Cerbón",
                    "multiple": "1"
                },
                {
                    "zipcode": "42176",
                    "province": "Soria",
                    "city": "Villar del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "42176",
                    "province": "Soria",
                    "city": "Santa Cruz de Yanguas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42177",
                    "province": "Soria",
                    "city": "Villar del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "42177",
                    "province": "Soria",
                    "city": "Yanguas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42180",
                    "province": "Soria",
                    "city": "Carrascosa de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "42180",
                    "province": "Soria",
                    "city": "Villares de Soria, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "42180",
                    "province": "Soria",
                    "city": "Almajano",
                    "multiple": "1"
                },
                {
                    "zipcode": "42180",
                    "province": "Soria",
                    "city": "Cirujales del Río",
                    "multiple": "1"
                },
                {
                    "zipcode": "42180",
                    "province": "Soria",
                    "city": "Aldealseñor",
                    "multiple": "1"
                },
                {
                    "zipcode": "42181",
                    "province": "Soria",
                    "city": "Cerbón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42181",
                    "province": "Soria",
                    "city": "Carrascosa de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "42181",
                    "province": "Soria",
                    "city": "Fuentes de Magaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "42181",
                    "province": "Soria",
                    "city": "Losilla, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "42181",
                    "province": "Soria",
                    "city": "Valtajeros",
                    "multiple": "1"
                },
                {
                    "zipcode": "42181",
                    "province": "Soria",
                    "city": "Magaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "42182",
                    "province": "Soria",
                    "city": "Aldehuela de Periáñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "42182",
                    "province": "Soria",
                    "city": "Arancón",
                    "multiple": "1"
                },
                {
                    "zipcode": "42189",
                    "province": "Soria",
                    "city": "Renieblas",
                    "multiple": "0"
                },
                {
                    "zipcode": "42189",
                    "province": "Soria",
                    "city": "Suellacabras",
                    "multiple": "1"
                },
                {
                    "zipcode": "42189",
                    "province": "Soria",
                    "city": "Narros",
                    "multiple": "1"
                },
                {
                    "zipcode": "42189",
                    "province": "Soria",
                    "city": "Velilla de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "42190",
                    "province": "Soria",
                    "city": "Golmayo",
                    "multiple": "0"
                },
                {
                    "zipcode": "42190",
                    "province": "Soria",
                    "city": "Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42191",
                    "province": "Soria",
                    "city": "Rábanos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "42191",
                    "province": "Soria",
                    "city": "Cubo de la Solana",
                    "multiple": "1"
                },
                {
                    "zipcode": "42192",
                    "province": "Soria",
                    "city": "Villaciervos",
                    "multiple": "0"
                },
                {
                    "zipcode": "42192",
                    "province": "Soria",
                    "city": "Golmayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42193",
                    "province": "Soria",
                    "city": "Muriel de la Fuente",
                    "multiple": "0"
                },
                {
                    "zipcode": "42193",
                    "province": "Soria",
                    "city": "Golmayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42193",
                    "province": "Soria",
                    "city": "Torreblacos",
                    "multiple": "1"
                },
                {
                    "zipcode": "42193",
                    "province": "Soria",
                    "city": "Blacos",
                    "multiple": "1"
                },
                {
                    "zipcode": "42193",
                    "province": "Soria",
                    "city": "Calatañazor",
                    "multiple": "1"
                },
                {
                    "zipcode": "42193",
                    "province": "Soria",
                    "city": "Valdenebro",
                    "multiple": "1"
                },
                {
                    "zipcode": "42194",
                    "province": "Soria",
                    "city": "Torreblacos",
                    "multiple": "0"
                },
                {
                    "zipcode": "42194",
                    "province": "Soria",
                    "city": "Rioseco de Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42195",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "0"
                },
                {
                    "zipcode": "42196",
                    "province": "Soria",
                    "city": "Valdenebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "42196",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "1"
                },
                {
                    "zipcode": "42200",
                    "province": "Soria",
                    "city": "Almazán",
                    "multiple": "0"
                },
                {
                    "zipcode": "42210",
                    "province": "Soria",
                    "city": "Barca",
                    "multiple": "0"
                },
                {
                    "zipcode": "42211",
                    "province": "Soria",
                    "city": "Centenera de Andaluz",
                    "multiple": "0"
                },
                {
                    "zipcode": "42211",
                    "province": "Soria",
                    "city": "Matamala de Almazán",
                    "multiple": "1"
                },
                {
                    "zipcode": "42212",
                    "province": "Soria",
                    "city": "Almazán",
                    "multiple": "0"
                },
                {
                    "zipcode": "42213",
                    "province": "Soria",
                    "city": "Alpanseque",
                    "multiple": "0"
                },
                {
                    "zipcode": "42213",
                    "province": "Soria",
                    "city": "Baraona",
                    "multiple": "1"
                },
                {
                    "zipcode": "42214",
                    "province": "Soria",
                    "city": "Baraona",
                    "multiple": "0"
                },
                {
                    "zipcode": "42214",
                    "province": "Soria",
                    "city": "Villasayas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42215",
                    "province": "Soria",
                    "city": "Frechilla de Almazán",
                    "multiple": "0"
                },
                {
                    "zipcode": "42216",
                    "province": "Soria",
                    "city": "Baraona",
                    "multiple": "0"
                },
                {
                    "zipcode": "42216",
                    "province": "Soria",
                    "city": "Taroda",
                    "multiple": "1"
                },
                {
                    "zipcode": "42216",
                    "province": "Soria",
                    "city": "Coscurita",
                    "multiple": "1"
                },
                {
                    "zipcode": "42216",
                    "province": "Soria",
                    "city": "Morón de Almazán",
                    "multiple": "1"
                },
                {
                    "zipcode": "42216",
                    "province": "Soria",
                    "city": "Adradas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42217",
                    "province": "Soria",
                    "city": "Coscurita",
                    "multiple": "0"
                },
                {
                    "zipcode": "42218",
                    "province": "Soria",
                    "city": "Borjabad",
                    "multiple": "0"
                },
                {
                    "zipcode": "42218",
                    "province": "Soria",
                    "city": "Nepas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42218",
                    "province": "Soria",
                    "city": "Tejado",
                    "multiple": "1"
                },
                {
                    "zipcode": "42218",
                    "province": "Soria",
                    "city": "Viana de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42220",
                    "province": "Soria",
                    "city": "Monteagudo de las Vicarías",
                    "multiple": "0"
                },
                {
                    "zipcode": "42221",
                    "province": "Zaragoza",
                    "city": "Pozuel de Ariza",
                    "multiple": "0"
                },
                {
                    "zipcode": "42222",
                    "province": "Soria",
                    "city": "Almaluez",
                    "multiple": "0"
                },
                {
                    "zipcode": "42223",
                    "province": "Soria",
                    "city": "Morón de Almazán",
                    "multiple": "0"
                },
                {
                    "zipcode": "42223",
                    "province": "Soria",
                    "city": "Coscurita",
                    "multiple": "1"
                },
                {
                    "zipcode": "42223",
                    "province": "Soria",
                    "city": "Soliedra",
                    "multiple": "1"
                },
                {
                    "zipcode": "42224",
                    "province": "Soria",
                    "city": "Soliedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "42224",
                    "province": "Soria",
                    "city": "Escobosa de Almazán",
                    "multiple": "1"
                },
                {
                    "zipcode": "42224",
                    "province": "Soria",
                    "city": "Nolay",
                    "multiple": "1"
                },
                {
                    "zipcode": "42225",
                    "province": "Soria",
                    "city": "Momblona",
                    "multiple": "0"
                },
                {
                    "zipcode": "42225",
                    "province": "Soria",
                    "city": "Velilla de los Ajos",
                    "multiple": "1"
                },
                {
                    "zipcode": "42225",
                    "province": "Soria",
                    "city": "Maján",
                    "multiple": "1"
                },
                {
                    "zipcode": "42225",
                    "province": "Soria",
                    "city": "Alentisque",
                    "multiple": "1"
                },
                {
                    "zipcode": "42230",
                    "province": "Soria",
                    "city": "Miño de Medinaceli",
                    "multiple": "0"
                },
                {
                    "zipcode": "42230",
                    "province": "Soria",
                    "city": "Yelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42230",
                    "province": "Soria",
                    "city": "Medinaceli",
                    "multiple": "1"
                },
                {
                    "zipcode": "42240",
                    "province": "Soria",
                    "city": "Medinaceli",
                    "multiple": "0"
                },
                {
                    "zipcode": "42248",
                    "province": "Soria",
                    "city": "Medinaceli",
                    "multiple": "0"
                },
                {
                    "zipcode": "42249",
                    "province": "Soria",
                    "city": "Arcos de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42249",
                    "province": "Soria",
                    "city": "Medinaceli",
                    "multiple": "1"
                },
                {
                    "zipcode": "42250",
                    "province": "Soria",
                    "city": "Arcos de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42257",
                    "province": "Soria",
                    "city": "Arcos de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42258",
                    "province": "Soria",
                    "city": "Arcos de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42258",
                    "province": "Soria",
                    "city": "Almaluez",
                    "multiple": "1"
                },
                {
                    "zipcode": "42259",
                    "province": "Soria",
                    "city": "Arcos de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42260",
                    "province": "Soria",
                    "city": "Santa María de Huerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "42269",
                    "province": "Soria",
                    "city": "Alconchel de Ariza",
                    "multiple": "0"
                },
                {
                    "zipcode": "42269",
                    "province": "Soria",
                    "city": "Pozuel de Ariza",
                    "multiple": "1"
                },
                {
                    "zipcode": "42269",
                    "province": "Soria",
                    "city": "Arcos de Jalón",
                    "multiple": "1"
                },
                {
                    "zipcode": "42269",
                    "province": "Soria",
                    "city": "Torrehermosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "42269",
                    "province": "Soria",
                    "city": "Monreal de Ariza",
                    "multiple": "1"
                },
                {
                    "zipcode": "42290",
                    "province": "Soria",
                    "city": "Cubo de la Solana",
                    "multiple": "0"
                },
                {
                    "zipcode": "42290",
                    "province": "Soria",
                    "city": "Rábanos, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "42290",
                    "province": "Soria",
                    "city": "Golmayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42291",
                    "province": "Soria",
                    "city": "Quintana Redonda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42292",
                    "province": "Soria",
                    "city": "Quintana Redonda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42293",
                    "province": "Soria",
                    "city": "Quintana Redonda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42293",
                    "province": "Soria",
                    "city": "Valderrodilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "42293",
                    "province": "Soria",
                    "city": "Fuentepinilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "42294",
                    "province": "Soria",
                    "city": "Tardelcuende",
                    "multiple": "0"
                },
                {
                    "zipcode": "42294",
                    "province": "Soria",
                    "city": "Golmayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42294",
                    "province": "Soria",
                    "city": "Fuentepinilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "42294",
                    "province": "Soria",
                    "city": "Quintana Redonda",
                    "multiple": "1"
                },
                {
                    "zipcode": "42295",
                    "province": "Soria",
                    "city": "Baraona",
                    "multiple": "0"
                },
                {
                    "zipcode": "42295",
                    "province": "Soria",
                    "city": "Alcubilla de las Peñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42296",
                    "province": "Soria",
                    "city": "Miño de Medinaceli",
                    "multiple": "0"
                },
                {
                    "zipcode": "42296",
                    "province": "Soria",
                    "city": "Yelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42300",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "0"
                },
                {
                    "zipcode": "42310",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "0"
                },
                {
                    "zipcode": "42311",
                    "province": "Soria",
                    "city": "Caracena",
                    "multiple": "0"
                },
                {
                    "zipcode": "42311",
                    "province": "Soria",
                    "city": "Montejo de Tiermes",
                    "multiple": "1"
                },
                {
                    "zipcode": "42311",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "1"
                },
                {
                    "zipcode": "42311",
                    "province": "Soria",
                    "city": "Carrascosa de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42312",
                    "province": "Soria",
                    "city": "Fresno de Caracena",
                    "multiple": "0"
                },
                {
                    "zipcode": "42312",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "1"
                },
                {
                    "zipcode": "42312",
                    "province": "Soria",
                    "city": "Villanueva de Gormaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42313",
                    "province": "Soria",
                    "city": "Recuerda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42313",
                    "province": "Soria",
                    "city": "Valdenebro",
                    "multiple": "1"
                },
                {
                    "zipcode": "42313",
                    "province": "Soria",
                    "city": "Gormaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42313",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "1"
                },
                {
                    "zipcode": "42313",
                    "province": "Soria",
                    "city": "Almenar de Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42313",
                    "province": "Soria",
                    "city": "Quintanas de Gormaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42314",
                    "province": "Soria",
                    "city": "Recuerda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42315",
                    "province": "Soria",
                    "city": "Montejo de Tiermes",
                    "multiple": "0"
                },
                {
                    "zipcode": "42315",
                    "province": "Soria",
                    "city": "Retortillo de Soria",
                    "multiple": "1"
                },
                {
                    "zipcode": "42315",
                    "province": "Soria",
                    "city": "Recuerda",
                    "multiple": "1"
                },
                {
                    "zipcode": "42316",
                    "province": "Soria",
                    "city": "Retortillo de Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42317",
                    "province": "Soria",
                    "city": "Valdemaluque",
                    "multiple": "0"
                },
                {
                    "zipcode": "42317",
                    "province": "Soria",
                    "city": "Ucero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42317",
                    "province": "Soria",
                    "city": "Talveila",
                    "multiple": "1"
                },
                {
                    "zipcode": "42318",
                    "province": "Soria",
                    "city": "Valdemaluque",
                    "multiple": "0"
                },
                {
                    "zipcode": "42318",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "1"
                },
                {
                    "zipcode": "42320",
                    "province": "Soria",
                    "city": "San Esteban de Gormaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "42320",
                    "province": "Soria",
                    "city": "Langa de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42328",
                    "province": "Soria",
                    "city": "Castillejo de Robledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "42328",
                    "province": "Soria",
                    "city": "Miño de San Esteban",
                    "multiple": "1"
                },
                {
                    "zipcode": "42328",
                    "province": "Soria",
                    "city": "Langa de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42329",
                    "province": "Soria",
                    "city": "Langa de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "42330",
                    "province": "Soria",
                    "city": "San Esteban de Gormaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "42339",
                    "province": "Soria",
                    "city": "Fuentecambrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "42340",
                    "province": "Soria",
                    "city": "San Esteban de Gormaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "42341",
                    "province": "Soria",
                    "city": "Montejo de Tiermes",
                    "multiple": "0"
                },
                {
                    "zipcode": "42341",
                    "province": "Soria",
                    "city": "Liceras",
                    "multiple": "1"
                },
                {
                    "zipcode": "42342",
                    "province": "Soria",
                    "city": "Montejo de Tiermes",
                    "multiple": "0"
                },
                {
                    "zipcode": "42342",
                    "province": "Soria",
                    "city": "San Esteban de Gormaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42343",
                    "province": "Soria",
                    "city": "Montejo de Tiermes",
                    "multiple": "0"
                },
                {
                    "zipcode": "42344",
                    "province": "Soria",
                    "city": "Retortillo de Soria",
                    "multiple": "0"
                },
                {
                    "zipcode": "42344",
                    "province": "Soria",
                    "city": "Montejo de Tiermes",
                    "multiple": "1"
                },
                {
                    "zipcode": "42345",
                    "province": "Soria",
                    "city": "San Esteban de Gormaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "42351",
                    "province": "Soria",
                    "city": "Alcubilla de Avellaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42351",
                    "province": "Soria",
                    "city": "Fuentearmegil",
                    "multiple": "1"
                },
                {
                    "zipcode": "42351",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "1"
                },
                {
                    "zipcode": "42351",
                    "province": "Soria",
                    "city": "San Esteban de Gormaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42353",
                    "province": "Soria",
                    "city": "Alcubilla de Avellaneda",
                    "multiple": "0"
                },
                {
                    "zipcode": "42360",
                    "province": "Soria",
                    "city": "Berlanga de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "42366",
                    "province": "Soria",
                    "city": "Tajueco",
                    "multiple": "0"
                },
                {
                    "zipcode": "42366",
                    "province": "Soria",
                    "city": "Bayubas de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "42366",
                    "province": "Soria",
                    "city": "Bayubas de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "42366",
                    "province": "Soria",
                    "city": "Berlanga de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42367",
                    "province": "Soria",
                    "city": "Caltojar",
                    "multiple": "0"
                },
                {
                    "zipcode": "42367",
                    "province": "Soria",
                    "city": "Berlanga de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42368",
                    "province": "Soria",
                    "city": "Barcones",
                    "multiple": "0"
                },
                {
                    "zipcode": "42368",
                    "province": "Soria",
                    "city": "Arenillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "42368",
                    "province": "Soria",
                    "city": "Berlanga de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "42368",
                    "province": "Soria",
                    "city": "Riba de Escalote, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "42368",
                    "province": "Soria",
                    "city": "Rello",
                    "multiple": "1"
                },
                {
                    "zipcode": "42369",
                    "province": "Soria",
                    "city": "Berlanga de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "42391",
                    "province": "Soria",
                    "city": "Burgo de Osma-Ciudad de Osma",
                    "multiple": "0"
                },
                {
                    "zipcode": "42391",
                    "province": "Soria",
                    "city": "San Esteban de Gormaz",
                    "multiple": "1"
                },
                {
                    "zipcode": "42392",
                    "province": "Soria",
                    "city": "Velamazán",
                    "multiple": "0"
                },
                {
                    "zipcode": "43001",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43002",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43003",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43004",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43005",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43006",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43007",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43008",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43100",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43110",
                    "province": "Tarragona",
                    "city": "Canonja, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43120",
                    "province": "Tarragona",
                    "city": "Constantí",
                    "multiple": "0"
                },
                {
                    "zipcode": "43130",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43140",
                    "province": "Tarragona",
                    "city": "Pobla de Mafumet, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43141",
                    "province": "Tarragona",
                    "city": "Vilallonga del Camp",
                    "multiple": "0"
                },
                {
                    "zipcode": "43142",
                    "province": "Tarragona",
                    "city": "Rourell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43143",
                    "province": "Tarragona",
                    "city": "Masó, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43143",
                    "province": "Tarragona",
                    "city": "Milà, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "43144",
                    "province": "Tarragona",
                    "city": "Vallmoll",
                    "multiple": "0"
                },
                {
                    "zipcode": "43150",
                    "province": "Tarragona",
                    "city": "Catllar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43151",
                    "province": "Tarragona",
                    "city": "Pallaresos, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "43152",
                    "province": "Tarragona",
                    "city": "Perafort",
                    "multiple": "0"
                },
                {
                    "zipcode": "43153",
                    "province": "Tarragona",
                    "city": "Garidells, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "43154",
                    "province": "Tarragona",
                    "city": "Secuita, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43155",
                    "province": "Tarragona",
                    "city": "Perafort",
                    "multiple": "0"
                },
                {
                    "zipcode": "43201",
                    "province": "Tarragona",
                    "city": "Reus",
                    "multiple": "0"
                },
                {
                    "zipcode": "43201",
                    "province": "Tarragona",
                    "city": "Tarragona",
                    "multiple": "1"
                },
                {
                    "zipcode": "43202",
                    "province": "Tarragona",
                    "city": "Reus",
                    "multiple": "0"
                },
                {
                    "zipcode": "43203",
                    "province": "Tarragona",
                    "city": "Reus",
                    "multiple": "0"
                },
                {
                    "zipcode": "43204",
                    "province": "Tarragona",
                    "city": "Reus",
                    "multiple": "0"
                },
                {
                    "zipcode": "43205",
                    "province": "Tarragona",
                    "city": "Reus",
                    "multiple": "0"
                },
                {
                    "zipcode": "43206",
                    "province": "Tarragona",
                    "city": "Vila-seca",
                    "multiple": "0"
                },
                {
                    "zipcode": "43206",
                    "province": "Tarragona",
                    "city": "Reus",
                    "multiple": "1"
                },
                {
                    "zipcode": "43300",
                    "province": "Tarragona",
                    "city": "Mont-roig del Camp",
                    "multiple": "0"
                },
                {
                    "zipcode": "43310",
                    "province": "Tarragona",
                    "city": "Colldejou",
                    "multiple": "0"
                },
                {
                    "zipcode": "43311",
                    "province": "Tarragona",
                    "city": "Vilanova d'Escornalbou",
                    "multiple": "0"
                },
                {
                    "zipcode": "43312",
                    "province": "Tarragona",
                    "city": "Vilanova d'Escornalbou",
                    "multiple": "0"
                },
                {
                    "zipcode": "43320",
                    "province": "Tarragona",
                    "city": "Tivissa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43320",
                    "province": "Tarragona",
                    "city": "Pratdip",
                    "multiple": "1"
                },
                {
                    "zipcode": "43330",
                    "province": "Tarragona",
                    "city": "Riudoms",
                    "multiple": "0"
                },
                {
                    "zipcode": "43340",
                    "province": "Tarragona",
                    "city": "Montbrió del Camp",
                    "multiple": "0"
                },
                {
                    "zipcode": "43350",
                    "province": "Tarragona",
                    "city": "Borges del Camp, Les",
                    "multiple": "0"
                },
                {
                    "zipcode": "43360",
                    "province": "Tarragona",
                    "city": "Cornudella de Montsant",
                    "multiple": "0"
                },
                {
                    "zipcode": "43361",
                    "province": "Tarragona",
                    "city": "Morera de Montsant, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43362",
                    "province": "Tarragona",
                    "city": "Cornudella de Montsant",
                    "multiple": "0"
                },
                {
                    "zipcode": "43363",
                    "province": "Tarragona",
                    "city": "Ulldemolins",
                    "multiple": "0"
                },
                {
                    "zipcode": "43364",
                    "province": "Tarragona",
                    "city": "Capafonts",
                    "multiple": "0"
                },
                {
                    "zipcode": "43364",
                    "province": "Tarragona",
                    "city": "Prades",
                    "multiple": "1"
                },
                {
                    "zipcode": "43364",
                    "province": "Tarragona",
                    "city": "Mont-ral",
                    "multiple": "1"
                },
                {
                    "zipcode": "43364",
                    "province": "Tarragona",
                    "city": "Febró, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "43365",
                    "province": "Tarragona",
                    "city": "Alforja",
                    "multiple": "0"
                },
                {
                    "zipcode": "43365",
                    "province": "Tarragona",
                    "city": "Arbolí",
                    "multiple": "1"
                },
                {
                    "zipcode": "43370",
                    "province": "Tarragona",
                    "city": "Palma d'Ebre, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43371",
                    "province": "Tarragona",
                    "city": "Margalef",
                    "multiple": "0"
                },
                {
                    "zipcode": "43372",
                    "province": "Tarragona",
                    "city": "Bisbal de Falset, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43373",
                    "province": "Tarragona",
                    "city": "Cabacés",
                    "multiple": "0"
                },
                {
                    "zipcode": "43374",
                    "province": "Tarragona",
                    "city": "Vilella Baixa, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43375",
                    "province": "Tarragona",
                    "city": "Vilella Alta, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43376",
                    "province": "Tarragona",
                    "city": "Poboleda",
                    "multiple": "0"
                },
                {
                    "zipcode": "43379",
                    "province": "Tarragona",
                    "city": "Morera de Montsant, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43380",
                    "province": "Tarragona",
                    "city": "Vilaplana",
                    "multiple": "0"
                },
                {
                    "zipcode": "43381",
                    "province": "Tarragona",
                    "city": "Aleixar, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43382",
                    "province": "Tarragona",
                    "city": "Maspujols",
                    "multiple": "0"
                },
                {
                    "zipcode": "43390",
                    "province": "Tarragona",
                    "city": "Riudecols",
                    "multiple": "0"
                },
                {
                    "zipcode": "43391",
                    "province": "Tarragona",
                    "city": "Vinyols i els Arcs",
                    "multiple": "0"
                },
                {
                    "zipcode": "43392",
                    "province": "Tarragona",
                    "city": "Castellvell del Camp",
                    "multiple": "0"
                },
                {
                    "zipcode": "43393",
                    "province": "Tarragona",
                    "city": "Almoster",
                    "multiple": "0"
                },
                {
                    "zipcode": "43400",
                    "province": "Tarragona",
                    "city": "Montblanc",
                    "multiple": "0"
                },
                {
                    "zipcode": "43410",
                    "province": "Tarragona",
                    "city": "Montblanc",
                    "multiple": "0"
                },
                {
                    "zipcode": "43411",
                    "province": "Tarragona",
                    "city": "Blancafort",
                    "multiple": "0"
                },
                {
                    "zipcode": "43412",
                    "province": "Tarragona",
                    "city": "Solivella",
                    "multiple": "0"
                },
                {
                    "zipcode": "43413",
                    "province": "Tarragona",
                    "city": "Passanant i Belltall",
                    "multiple": "0"
                },
                {
                    "zipcode": "43414",
                    "province": "Tarragona",
                    "city": "Montblanc",
                    "multiple": "0"
                },
                {
                    "zipcode": "43415",
                    "province": "Tarragona",
                    "city": "Montblanc",
                    "multiple": "0"
                },
                {
                    "zipcode": "43420",
                    "province": "Tarragona",
                    "city": "Santa Coloma de Queralt",
                    "multiple": "0"
                },
                {
                    "zipcode": "43421",
                    "province": "Barcelona",
                    "city": "Pontils",
                    "multiple": "0"
                },
                {
                    "zipcode": "43421",
                    "province": "Barcelona",
                    "city": "Bellprat",
                    "multiple": "1"
                },
                {
                    "zipcode": "43422",
                    "province": "Tarragona",
                    "city": "Barberà de la Conca",
                    "multiple": "0"
                },
                {
                    "zipcode": "43423",
                    "province": "Tarragona",
                    "city": "Pira",
                    "multiple": "0"
                },
                {
                    "zipcode": "43424",
                    "province": "Tarragona",
                    "city": "Sarral",
                    "multiple": "0"
                },
                {
                    "zipcode": "43425",
                    "province": "Tarragona",
                    "city": "Sarral",
                    "multiple": "0"
                },
                {
                    "zipcode": "43425",
                    "province": "Tarragona",
                    "city": "Forès",
                    "multiple": "1"
                },
                {
                    "zipcode": "43425",
                    "province": "Tarragona",
                    "city": "Passanant i Belltall",
                    "multiple": "1"
                },
                {
                    "zipcode": "43426",
                    "province": "Tarragona",
                    "city": "Rocafort de Queralt",
                    "multiple": "0"
                },
                {
                    "zipcode": "43427",
                    "province": "Tarragona",
                    "city": "Conesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43427",
                    "province": "Tarragona",
                    "city": "Savallà del Comtat",
                    "multiple": "1"
                },
                {
                    "zipcode": "43427",
                    "province": "Tarragona",
                    "city": "Vallfogona de Riucorb",
                    "multiple": "1"
                },
                {
                    "zipcode": "43427",
                    "province": "Tarragona",
                    "city": "Llorac",
                    "multiple": "1"
                },
                {
                    "zipcode": "43428",
                    "province": "Tarragona",
                    "city": "Conesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43428",
                    "province": "Tarragona",
                    "city": "Sarral",
                    "multiple": "1"
                },
                {
                    "zipcode": "43428",
                    "province": "Tarragona",
                    "city": "Piles, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "43429",
                    "province": "Tarragona",
                    "city": "Barberà de la Conca",
                    "multiple": "0"
                },
                {
                    "zipcode": "43429",
                    "province": "Tarragona",
                    "city": "Santa Coloma de Queralt",
                    "multiple": "1"
                },
                {
                    "zipcode": "43429",
                    "province": "Tarragona",
                    "city": "Piles, Les",
                    "multiple": "1"
                },
                {
                    "zipcode": "43430",
                    "province": "Tarragona",
                    "city": "Vimbodí i Poblet",
                    "multiple": "0"
                },
                {
                    "zipcode": "43439",
                    "province": "Tarragona",
                    "city": "Vallclara",
                    "multiple": "0"
                },
                {
                    "zipcode": "43439",
                    "province": "Tarragona",
                    "city": "Vilanova de Prades",
                    "multiple": "1"
                },
                {
                    "zipcode": "43440",
                    "province": "Tarragona",
                    "city": "Espluga de Francolí, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43442",
                    "province": "Tarragona",
                    "city": "Barberà de la Conca",
                    "multiple": "0"
                },
                {
                    "zipcode": "43448",
                    "province": "Tarragona",
                    "city": "Vimbodí i Poblet",
                    "multiple": "0"
                },
                {
                    "zipcode": "43449",
                    "province": "Tarragona",
                    "city": "Vimbodí i Poblet",
                    "multiple": "0"
                },
                {
                    "zipcode": "43449",
                    "province": "Tarragona",
                    "city": "Senan",
                    "multiple": "1"
                },
                {
                    "zipcode": "43450",
                    "province": "Tarragona",
                    "city": "Riba, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43459",
                    "province": "Tarragona",
                    "city": "Mont-ral",
                    "multiple": "0"
                },
                {
                    "zipcode": "43459",
                    "province": "Tarragona",
                    "city": "Montblanc",
                    "multiple": "1"
                },
                {
                    "zipcode": "43460",
                    "province": "Tarragona",
                    "city": "Alcover",
                    "multiple": "0"
                },
                {
                    "zipcode": "43470",
                    "province": "Tarragona",
                    "city": "Selva del Camp, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43479",
                    "province": "Tarragona",
                    "city": "Albiol, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43480",
                    "province": "Tarragona",
                    "city": "Vila-seca",
                    "multiple": "0"
                },
                {
                    "zipcode": "43481",
                    "province": "Tarragona",
                    "city": "Vila-seca",
                    "multiple": "0"
                },
                {
                    "zipcode": "43490",
                    "province": "Tarragona",
                    "city": "Vilaverd",
                    "multiple": "0"
                },
                {
                    "zipcode": "43491",
                    "province": "Tarragona",
                    "city": "Valls",
                    "multiple": "0"
                },
                {
                    "zipcode": "43500",
                    "province": "Tarragona",
                    "city": "Tortosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43510",
                    "province": "Tarragona",
                    "city": "Tortosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43511",
                    "province": "Tarragona",
                    "city": "Tivenys",
                    "multiple": "0"
                },
                {
                    "zipcode": "43512",
                    "province": "Tarragona",
                    "city": "Benifallet",
                    "multiple": "0"
                },
                {
                    "zipcode": "43513",
                    "province": "Tarragona",
                    "city": "Rasquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "43514",
                    "province": "Tarragona",
                    "city": "Mas de Barberans",
                    "multiple": "0"
                },
                {
                    "zipcode": "43515",
                    "province": "Tarragona",
                    "city": "Galera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43516",
                    "province": "Tarragona",
                    "city": "Godall",
                    "multiple": "0"
                },
                {
                    "zipcode": "43517",
                    "province": "Tarragona",
                    "city": "Tortosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43519",
                    "province": "Tarragona",
                    "city": "Perelló, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43520",
                    "province": "Tarragona",
                    "city": "Roquetes",
                    "multiple": "0"
                },
                {
                    "zipcode": "43527",
                    "province": "Tarragona",
                    "city": "Tortosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43528",
                    "province": "Tarragona",
                    "city": "Alfara de Carles",
                    "multiple": "0"
                },
                {
                    "zipcode": "43529",
                    "province": "Tarragona",
                    "city": "Roquetes",
                    "multiple": "0"
                },
                {
                    "zipcode": "43530",
                    "province": "Tarragona",
                    "city": "Alcanar",
                    "multiple": "0"
                },
                {
                    "zipcode": "43540",
                    "province": "Tarragona",
                    "city": "Sant Carles de la Ràpita",
                    "multiple": "0"
                },
                {
                    "zipcode": "43548",
                    "province": "Tarragona",
                    "city": "Sant Carles de la Ràpita",
                    "multiple": "0"
                },
                {
                    "zipcode": "43549",
                    "province": "Tarragona",
                    "city": "Amposta",
                    "multiple": "0"
                },
                {
                    "zipcode": "43550",
                    "province": "Tarragona",
                    "city": "Ulldecona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43558",
                    "province": "Tarragona",
                    "city": "Ulldecona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43558",
                    "province": "Tarragona",
                    "city": "Freginals",
                    "multiple": "1"
                },
                {
                    "zipcode": "43559",
                    "province": "Tarragona",
                    "city": "Ulldecona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43560",
                    "province": "Tarragona",
                    "city": "Sénia, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43569",
                    "province": "Tarragona",
                    "city": "Alcanar",
                    "multiple": "0"
                },
                {
                    "zipcode": "43570",
                    "province": "Tarragona",
                    "city": "Santa Bàrbara",
                    "multiple": "0"
                },
                {
                    "zipcode": "43580",
                    "province": "Tarragona",
                    "city": "Deltebre",
                    "multiple": "0"
                },
                {
                    "zipcode": "43590",
                    "province": "Tarragona",
                    "city": "Tortosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43591",
                    "province": "Tarragona",
                    "city": "Aldover",
                    "multiple": "0"
                },
                {
                    "zipcode": "43592",
                    "province": "Tarragona",
                    "city": "Xerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "43593",
                    "province": "Tarragona",
                    "city": "Paüls",
                    "multiple": "0"
                },
                {
                    "zipcode": "43594",
                    "province": "Tarragona",
                    "city": "Pinell de Brai, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43595",
                    "province": "Tarragona",
                    "city": "Prat de Comte",
                    "multiple": "0"
                },
                {
                    "zipcode": "43596",
                    "province": "Tarragona",
                    "city": "Horta de Sant Joan",
                    "multiple": "0"
                },
                {
                    "zipcode": "43597",
                    "province": "Tarragona",
                    "city": "Arnes",
                    "multiple": "0"
                },
                {
                    "zipcode": "43700",
                    "province": "Tarragona",
                    "city": "Vendrell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43710",
                    "province": "Tarragona",
                    "city": "Santa Oliva",
                    "multiple": "0"
                },
                {
                    "zipcode": "43711",
                    "province": "Tarragona",
                    "city": "Banyeres del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "43712",
                    "province": "Tarragona",
                    "city": "Llorenç del Penedès",
                    "multiple": "0"
                },
                {
                    "zipcode": "43713",
                    "province": "Tarragona",
                    "city": "Sant Jaume dels Domenys",
                    "multiple": "0"
                },
                {
                    "zipcode": "43714",
                    "province": "Tarragona",
                    "city": "Montmell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43714",
                    "province": "Tarragona",
                    "city": "Aiguamúrcia",
                    "multiple": "1"
                },
                {
                    "zipcode": "43715",
                    "province": "Tarragona",
                    "city": "Querol",
                    "multiple": "0"
                },
                {
                    "zipcode": "43715",
                    "province": "Tarragona",
                    "city": "Montmell, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "43715",
                    "province": "Tarragona",
                    "city": "Llorenç del Penedès",
                    "multiple": "1"
                },
                {
                    "zipcode": "43716",
                    "province": "Tarragona",
                    "city": "Albinyana",
                    "multiple": "0"
                },
                {
                    "zipcode": "43717",
                    "province": "Tarragona",
                    "city": "Bisbal del Penedès, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43718",
                    "province": "Tarragona",
                    "city": "Montmell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43718",
                    "province": "Tarragona",
                    "city": "Santa Oliva",
                    "multiple": "1"
                },
                {
                    "zipcode": "43718",
                    "province": "Tarragona",
                    "city": "Albinyana",
                    "multiple": "1"
                },
                {
                    "zipcode": "43718",
                    "province": "Tarragona",
                    "city": "Masllorenç",
                    "multiple": "1"
                },
                {
                    "zipcode": "43719",
                    "province": "Tarragona",
                    "city": "Bellvei",
                    "multiple": "0"
                },
                {
                    "zipcode": "43720",
                    "province": "Tarragona",
                    "city": "Arboç, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43730",
                    "province": "Tarragona",
                    "city": "Marçà",
                    "multiple": "0"
                },
                {
                    "zipcode": "43730",
                    "province": "Tarragona",
                    "city": "Falset",
                    "multiple": "1"
                },
                {
                    "zipcode": "43736",
                    "province": "Tarragona",
                    "city": "Figuera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43736",
                    "province": "Tarragona",
                    "city": "Masroig, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "43736",
                    "province": "Tarragona",
                    "city": "Molar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "43737",
                    "province": "Tarragona",
                    "city": "Torroja del Priorat",
                    "multiple": "0"
                },
                {
                    "zipcode": "43737",
                    "province": "Tarragona",
                    "city": "Gratallops",
                    "multiple": "1"
                },
                {
                    "zipcode": "43737",
                    "province": "Tarragona",
                    "city": "Lloar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "43738",
                    "province": "Tarragona",
                    "city": "Bellmunt del Priorat",
                    "multiple": "0"
                },
                {
                    "zipcode": "43739",
                    "province": "Tarragona",
                    "city": "Porrera",
                    "multiple": "0"
                },
                {
                    "zipcode": "43740",
                    "province": "Tarragona",
                    "city": "Móra d'Ebre",
                    "multiple": "0"
                },
                {
                    "zipcode": "43746",
                    "province": "Tarragona",
                    "city": "Tivissa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43747",
                    "province": "Tarragona",
                    "city": "Benissanet",
                    "multiple": "0"
                },
                {
                    "zipcode": "43747",
                    "province": "Tarragona",
                    "city": "Miravet",
                    "multiple": "1"
                },
                {
                    "zipcode": "43748",
                    "province": "Tarragona",
                    "city": "Ginestar",
                    "multiple": "0"
                },
                {
                    "zipcode": "43749",
                    "province": "Tarragona",
                    "city": "Garcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "43750",
                    "province": "Tarragona",
                    "city": "Flix",
                    "multiple": "0"
                },
                {
                    "zipcode": "43760",
                    "province": "Tarragona",
                    "city": "Morell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43761",
                    "province": "Tarragona",
                    "city": "Pobla de Montornès, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43762",
                    "province": "Tarragona",
                    "city": "Riera de Gaià, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43763",
                    "province": "Tarragona",
                    "city": "Nou de Gaià, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43763",
                    "province": "Tarragona",
                    "city": "Vespella de Gaià",
                    "multiple": "1"
                },
                {
                    "zipcode": "43764",
                    "province": "Tarragona",
                    "city": "Catllar, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43765",
                    "province": "Tarragona",
                    "city": "Secuita, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43770",
                    "province": "Tarragona",
                    "city": "Móra la Nova",
                    "multiple": "0"
                },
                {
                    "zipcode": "43771",
                    "province": "Tarragona",
                    "city": "Riudecanyes",
                    "multiple": "0"
                },
                {
                    "zipcode": "43772",
                    "province": "Tarragona",
                    "city": "Botarell",
                    "multiple": "0"
                },
                {
                    "zipcode": "43773",
                    "province": "Tarragona",
                    "city": "Argentera, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43773",
                    "province": "Tarragona",
                    "city": "Duesaigües",
                    "multiple": "1"
                },
                {
                    "zipcode": "43774",
                    "province": "Tarragona",
                    "city": "Torre de Fontaubella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43774",
                    "province": "Tarragona",
                    "city": "Pradell de la Teixeta",
                    "multiple": "1"
                },
                {
                    "zipcode": "43775",
                    "province": "Tarragona",
                    "city": "Marçà",
                    "multiple": "0"
                },
                {
                    "zipcode": "43776",
                    "province": "Tarragona",
                    "city": "Capçanes",
                    "multiple": "0"
                },
                {
                    "zipcode": "43777",
                    "province": "Tarragona",
                    "city": "Guiamets, Els",
                    "multiple": "0"
                },
                {
                    "zipcode": "43780",
                    "province": "Tarragona",
                    "city": "Gandesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "43781",
                    "province": "Tarragona",
                    "city": "Fatarella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43782",
                    "province": "Tarragona",
                    "city": "Vilalba dels Arcs",
                    "multiple": "0"
                },
                {
                    "zipcode": "43783",
                    "province": "Tarragona",
                    "city": "Pobla de Massaluca, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43783",
                    "province": "Tarragona",
                    "city": "Pobla de Massaluca, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "43784",
                    "province": "Tarragona",
                    "city": "Corbera d'Ebre",
                    "multiple": "0"
                },
                {
                    "zipcode": "43785",
                    "province": "Tarragona",
                    "city": "Bot",
                    "multiple": "0"
                },
                {
                    "zipcode": "43786",
                    "province": "Tarragona",
                    "city": "Batea",
                    "multiple": "0"
                },
                {
                    "zipcode": "43787",
                    "province": "Tarragona",
                    "city": "Caseres",
                    "multiple": "0"
                },
                {
                    "zipcode": "43790",
                    "province": "Tarragona",
                    "city": "Riba-roja d'Ebre",
                    "multiple": "0"
                },
                {
                    "zipcode": "43791",
                    "province": "Tarragona",
                    "city": "Ascó",
                    "multiple": "0"
                },
                {
                    "zipcode": "43792",
                    "province": "Tarragona",
                    "city": "Torre de l'Espanyol, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "43792",
                    "province": "Tarragona",
                    "city": "Vinebre",
                    "multiple": "1"
                },
                {
                    "zipcode": "43800",
                    "province": "Tarragona",
                    "city": "Valls",
                    "multiple": "0"
                },
                {
                    "zipcode": "43810",
                    "province": "Tarragona",
                    "city": "Pla de Santa Maria, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43811",
                    "province": "Tarragona",
                    "city": "Cabra del Camp",
                    "multiple": "0"
                },
                {
                    "zipcode": "43811",
                    "province": "Tarragona",
                    "city": "Figuerola del Camp",
                    "multiple": "1"
                },
                {
                    "zipcode": "43812",
                    "province": "Tarragona",
                    "city": "Puigpelat",
                    "multiple": "0"
                },
                {
                    "zipcode": "43812",
                    "province": "Tarragona",
                    "city": "Bràfim",
                    "multiple": "1"
                },
                {
                    "zipcode": "43812",
                    "province": "Tarragona",
                    "city": "Montmell, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "43812",
                    "province": "Tarragona",
                    "city": "Rodonyà",
                    "multiple": "1"
                },
                {
                    "zipcode": "43812",
                    "province": "Tarragona",
                    "city": "Montferri",
                    "multiple": "1"
                },
                {
                    "zipcode": "43813",
                    "province": "Tarragona",
                    "city": "Valls",
                    "multiple": "0"
                },
                {
                    "zipcode": "43813",
                    "province": "Tarragona",
                    "city": "Alió",
                    "multiple": "1"
                },
                {
                    "zipcode": "43814",
                    "province": "Tarragona",
                    "city": "Vila-rodona",
                    "multiple": "0"
                },
                {
                    "zipcode": "43815",
                    "province": "Tarragona",
                    "city": "Aiguamúrcia",
                    "multiple": "0"
                },
                {
                    "zipcode": "43815",
                    "province": "Tarragona",
                    "city": "Mont-roig del Camp",
                    "multiple": "1"
                },
                {
                    "zipcode": "43816",
                    "province": "Tarragona",
                    "city": "Querol",
                    "multiple": "0"
                },
                {
                    "zipcode": "43817",
                    "province": "Tarragona",
                    "city": "Pont d'Armentera, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43820",
                    "province": "Tarragona",
                    "city": "Calafell",
                    "multiple": "0"
                },
                {
                    "zipcode": "43830",
                    "province": "Tarragona",
                    "city": "Torredembarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "43839",
                    "province": "Tarragona",
                    "city": "Creixell",
                    "multiple": "0"
                },
                {
                    "zipcode": "43840",
                    "province": "Tarragona",
                    "city": "Salou",
                    "multiple": "0"
                },
                {
                    "zipcode": "43850",
                    "province": "Tarragona",
                    "city": "Cambrils",
                    "multiple": "0"
                },
                {
                    "zipcode": "43860",
                    "province": "Tarragona",
                    "city": "Ametlla de Mar, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43870",
                    "province": "Tarragona",
                    "city": "Amposta",
                    "multiple": "0"
                },
                {
                    "zipcode": "43877",
                    "province": "Tarragona",
                    "city": "Sant Jaume d'Enveja",
                    "multiple": "0"
                },
                {
                    "zipcode": "43878",
                    "province": "Tarragona",
                    "city": "Masdenverge",
                    "multiple": "0"
                },
                {
                    "zipcode": "43879",
                    "province": "Tarragona",
                    "city": "Amposta",
                    "multiple": "0"
                },
                {
                    "zipcode": "43879",
                    "province": "Tarragona",
                    "city": "Masdenverge",
                    "multiple": "1"
                },
                {
                    "zipcode": "43879",
                    "province": "Tarragona",
                    "city": "Sant Jaume d'Enveja",
                    "multiple": "1"
                },
                {
                    "zipcode": "43880",
                    "province": "Tarragona",
                    "city": "Vendrell, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "43881",
                    "province": "Tarragona",
                    "city": "Cunit",
                    "multiple": "0"
                },
                {
                    "zipcode": "43882",
                    "province": "Tarragona",
                    "city": "Calafell",
                    "multiple": "0"
                },
                {
                    "zipcode": "43883",
                    "province": "Tarragona",
                    "city": "Roda de Berà",
                    "multiple": "0"
                },
                {
                    "zipcode": "43884",
                    "province": "Tarragona",
                    "city": "Bonastre",
                    "multiple": "0"
                },
                {
                    "zipcode": "43885",
                    "province": "Tarragona",
                    "city": "Salomó",
                    "multiple": "0"
                },
                {
                    "zipcode": "43886",
                    "province": "Tarragona",
                    "city": "Renau",
                    "multiple": "0"
                },
                {
                    "zipcode": "43886",
                    "province": "Tarragona",
                    "city": "Vilabella",
                    "multiple": "1"
                },
                {
                    "zipcode": "43887",
                    "province": "Tarragona",
                    "city": "Nulles",
                    "multiple": "0"
                },
                {
                    "zipcode": "43890",
                    "province": "Tarragona",
                    "city": "Vandellòs i l'Hospitalet de l'Infant",
                    "multiple": "0"
                },
                {
                    "zipcode": "43891",
                    "province": "Tarragona",
                    "city": "Vandellòs i l'Hospitalet de l'Infant",
                    "multiple": "0"
                },
                {
                    "zipcode": "43892",
                    "province": "Tarragona",
                    "city": "Mont-roig del Camp",
                    "multiple": "0"
                },
                {
                    "zipcode": "43893",
                    "province": "Tarragona",
                    "city": "Altafulla",
                    "multiple": "0"
                },
                {
                    "zipcode": "43894",
                    "province": "Tarragona",
                    "city": "Camarles",
                    "multiple": "0"
                },
                {
                    "zipcode": "43895",
                    "province": "Tarragona",
                    "city": "Ampolla, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43896",
                    "province": "Tarragona",
                    "city": "Aldea, L'",
                    "multiple": "0"
                },
                {
                    "zipcode": "43897",
                    "province": "Tarragona",
                    "city": "Tortosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "44001",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44002",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44003",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44100",
                    "province": "Teruel",
                    "city": "Albarracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44110",
                    "province": "Teruel",
                    "city": "Gea de Albarracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44111",
                    "province": "Teruel",
                    "city": "Torres de Albarracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44112",
                    "province": "Teruel",
                    "city": "Tramacastilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "44113",
                    "province": "Teruel",
                    "city": "Noguera de Albarracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44114",
                    "province": "Teruel",
                    "city": "Griegos",
                    "multiple": "0"
                },
                {
                    "zipcode": "44114",
                    "province": "Teruel",
                    "city": "Villar del Cobo",
                    "multiple": "1"
                },
                {
                    "zipcode": "44115",
                    "province": "Teruel",
                    "city": "Guadalaviar",
                    "multiple": "0"
                },
                {
                    "zipcode": "44120",
                    "province": "Teruel",
                    "city": "Terriente",
                    "multiple": "0"
                },
                {
                    "zipcode": "44121",
                    "province": "Teruel",
                    "city": "Rubiales",
                    "multiple": "0"
                },
                {
                    "zipcode": "44121",
                    "province": "Teruel",
                    "city": "Albarracín",
                    "multiple": "1"
                },
                {
                    "zipcode": "44121",
                    "province": "Teruel",
                    "city": "Bezas",
                    "multiple": "1"
                },
                {
                    "zipcode": "44121",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "1"
                },
                {
                    "zipcode": "44122",
                    "province": "Teruel",
                    "city": "Valdecuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "44122",
                    "province": "Teruel",
                    "city": "Jabaloyas",
                    "multiple": "1"
                },
                {
                    "zipcode": "44122",
                    "province": "Teruel",
                    "city": "Saldón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44123",
                    "province": "Teruel",
                    "city": "Jabaloyas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44123",
                    "province": "Teruel",
                    "city": "Toril y Masegoso",
                    "multiple": "1"
                },
                {
                    "zipcode": "44123",
                    "province": "Teruel",
                    "city": "Albarracín",
                    "multiple": "1"
                },
                {
                    "zipcode": "44123",
                    "province": "Teruel",
                    "city": "Vallecillo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "44124",
                    "province": "Teruel",
                    "city": "Moscardón",
                    "multiple": "0"
                },
                {
                    "zipcode": "44125",
                    "province": "Teruel",
                    "city": "Royuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "44126",
                    "province": "Teruel",
                    "city": "Frías de Albarracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44126",
                    "province": "Teruel",
                    "city": "Calomarde",
                    "multiple": "1"
                },
                {
                    "zipcode": "44130",
                    "province": "Teruel",
                    "city": "Villastar",
                    "multiple": "0"
                },
                {
                    "zipcode": "44131",
                    "province": "Teruel",
                    "city": "Villel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44132",
                    "province": "Teruel",
                    "city": "Tramacastiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44132",
                    "province": "Teruel",
                    "city": "Libros",
                    "multiple": "1"
                },
                {
                    "zipcode": "44133",
                    "province": "Teruel",
                    "city": "Riodeva",
                    "multiple": "0"
                },
                {
                    "zipcode": "44133",
                    "province": "Teruel",
                    "city": "Tramacastiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "44134",
                    "province": "Teruel",
                    "city": "Veguillas de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44134",
                    "province": "Teruel",
                    "city": "Tormón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44134",
                    "province": "Teruel",
                    "city": "Alobras",
                    "multiple": "1"
                },
                {
                    "zipcode": "44134",
                    "province": "Teruel",
                    "city": "Cuervo, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "44140",
                    "province": "Teruel",
                    "city": "Cañada de Benatanduz",
                    "multiple": "0"
                },
                {
                    "zipcode": "44140",
                    "province": "Teruel",
                    "city": "Cantavieja",
                    "multiple": "1"
                },
                {
                    "zipcode": "44141",
                    "province": "Teruel",
                    "city": "Tronchón",
                    "multiple": "0"
                },
                {
                    "zipcode": "44141",
                    "province": "Teruel",
                    "city": "Cuba, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "44141",
                    "province": "Teruel",
                    "city": "Mirambel",
                    "multiple": "1"
                },
                {
                    "zipcode": "44142",
                    "province": "Teruel",
                    "city": "Iglesuela del Cid, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44143",
                    "province": "Teruel",
                    "city": "Fortanete",
                    "multiple": "0"
                },
                {
                    "zipcode": "44144",
                    "province": "Teruel",
                    "city": "Villarroya de los Pinares",
                    "multiple": "0"
                },
                {
                    "zipcode": "44145",
                    "province": "Teruel",
                    "city": "Allepuz",
                    "multiple": "0"
                },
                {
                    "zipcode": "44146",
                    "province": "Teruel",
                    "city": "Monteagudo del Castillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44147",
                    "province": "Teruel",
                    "city": "Cedrillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44150",
                    "province": "Teruel",
                    "city": "Aliaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "44155",
                    "province": "Teruel",
                    "city": "Pobo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "44155",
                    "province": "Teruel",
                    "city": "Ababuj",
                    "multiple": "1"
                },
                {
                    "zipcode": "44155",
                    "province": "Teruel",
                    "city": "Camarillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "44156",
                    "province": "Teruel",
                    "city": "Jorcas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44156",
                    "province": "Teruel",
                    "city": "Aguilar del Alfambra",
                    "multiple": "1"
                },
                {
                    "zipcode": "44157",
                    "province": "Teruel",
                    "city": "Hinojosa de Jarque",
                    "multiple": "0"
                },
                {
                    "zipcode": "44157",
                    "province": "Teruel",
                    "city": "Aliaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "44158",
                    "province": "Teruel",
                    "city": "Aliaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "44159",
                    "province": "Teruel",
                    "city": "Miravete de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44160",
                    "province": "Teruel",
                    "city": "Alfambra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44161",
                    "province": "Teruel",
                    "city": "Escorihuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "44161",
                    "province": "Teruel",
                    "city": "Orrios",
                    "multiple": "1"
                },
                {
                    "zipcode": "44161",
                    "province": "Teruel",
                    "city": "Perales del Alfambra",
                    "multiple": "1"
                },
                {
                    "zipcode": "44162",
                    "province": "Teruel",
                    "city": "Cuevas Labradas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44162",
                    "province": "Teruel",
                    "city": "Peralejos",
                    "multiple": "1"
                },
                {
                    "zipcode": "44162",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "1"
                },
                {
                    "zipcode": "44163",
                    "province": "Teruel",
                    "city": "Perales del Alfambra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44164",
                    "province": "Teruel",
                    "city": "Lidón",
                    "multiple": "0"
                },
                {
                    "zipcode": "44164",
                    "province": "Teruel",
                    "city": "Visiedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "44165",
                    "province": "Teruel",
                    "city": "Argente",
                    "multiple": "0"
                },
                {
                    "zipcode": "44166",
                    "province": "Teruel",
                    "city": "Rubielos de la Cérida",
                    "multiple": "0"
                },
                {
                    "zipcode": "44167",
                    "province": "Teruel",
                    "city": "Camañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44168",
                    "province": "Teruel",
                    "city": "Galve",
                    "multiple": "0"
                },
                {
                    "zipcode": "44168",
                    "province": "Teruel",
                    "city": "Cañada Vellida",
                    "multiple": "1"
                },
                {
                    "zipcode": "44169",
                    "province": "Teruel",
                    "city": "Mezquita de Jarque",
                    "multiple": "0"
                },
                {
                    "zipcode": "44169",
                    "province": "Teruel",
                    "city": "Cuevas de Almudén",
                    "multiple": "1"
                },
                {
                    "zipcode": "44169",
                    "province": "Teruel",
                    "city": "Jarque de la Val",
                    "multiple": "1"
                },
                {
                    "zipcode": "44190",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44191",
                    "province": "Teruel",
                    "city": "Cascante del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "44191",
                    "province": "Teruel",
                    "city": "Cubla",
                    "multiple": "1"
                },
                {
                    "zipcode": "44191",
                    "province": "Teruel",
                    "city": "Camarena de la Sierra",
                    "multiple": "1"
                },
                {
                    "zipcode": "44191",
                    "province": "Teruel",
                    "city": "Valacloche",
                    "multiple": "1"
                },
                {
                    "zipcode": "44192",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44193",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44193",
                    "province": "Teruel",
                    "city": "Corbalán",
                    "multiple": "1"
                },
                {
                    "zipcode": "44194",
                    "province": "Teruel",
                    "city": "Celadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44195",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44200",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44210",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44211",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44212",
                    "province": "Teruel",
                    "city": "Anadón",
                    "multiple": "0"
                },
                {
                    "zipcode": "44212",
                    "province": "Teruel",
                    "city": "Loscos",
                    "multiple": "1"
                },
                {
                    "zipcode": "44212",
                    "province": "Teruel",
                    "city": "Huesa del Común",
                    "multiple": "1"
                },
                {
                    "zipcode": "44213",
                    "province": "Teruel",
                    "city": "Huesa del Común",
                    "multiple": "0"
                },
                {
                    "zipcode": "44213",
                    "province": "Teruel",
                    "city": "Plou",
                    "multiple": "1"
                },
                {
                    "zipcode": "44220",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44220",
                    "province": "Teruel",
                    "city": "Barrachina",
                    "multiple": "1"
                },
                {
                    "zipcode": "44221",
                    "province": "Teruel",
                    "city": "Torrecilla del Rebollar",
                    "multiple": "0"
                },
                {
                    "zipcode": "44222",
                    "province": "Teruel",
                    "city": "Torrecilla del Rebollar",
                    "multiple": "0"
                },
                {
                    "zipcode": "44223",
                    "province": "Teruel",
                    "city": "Villanueva del Rebollar de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44230",
                    "province": "Teruel",
                    "city": "Tornos",
                    "multiple": "0"
                },
                {
                    "zipcode": "44231",
                    "province": "Teruel",
                    "city": "Castejón de Tornos",
                    "multiple": "0"
                },
                {
                    "zipcode": "44232",
                    "province": "Teruel",
                    "city": "Bello",
                    "multiple": "0"
                },
                {
                    "zipcode": "44233",
                    "province": "Teruel",
                    "city": "Odón",
                    "multiple": "0"
                },
                {
                    "zipcode": "44300",
                    "province": "Teruel",
                    "city": "Monreal del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44310",
                    "province": "Teruel",
                    "city": "Ródenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44311",
                    "province": "Teruel",
                    "city": "Villar del Salz",
                    "multiple": "0"
                },
                {
                    "zipcode": "44312",
                    "province": "Teruel",
                    "city": "Ojos Negros",
                    "multiple": "0"
                },
                {
                    "zipcode": "44313",
                    "province": "Teruel",
                    "city": "Ojos Negros",
                    "multiple": "0"
                },
                {
                    "zipcode": "44314",
                    "province": "Teruel",
                    "city": "Blancas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44315",
                    "province": "Teruel",
                    "city": "Pozuel del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44320",
                    "province": "Teruel",
                    "city": "Báguena",
                    "multiple": "0"
                },
                {
                    "zipcode": "44330",
                    "province": "Teruel",
                    "city": "Burbáguena",
                    "multiple": "0"
                },
                {
                    "zipcode": "44340",
                    "province": "Teruel",
                    "city": "Fuentes Claras",
                    "multiple": "0"
                },
                {
                    "zipcode": "44350",
                    "province": "Teruel",
                    "city": "Caminreal",
                    "multiple": "0"
                },
                {
                    "zipcode": "44357",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44357",
                    "province": "Teruel",
                    "city": "Bañón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44358",
                    "province": "Teruel",
                    "city": "Torre los Negros",
                    "multiple": "0"
                },
                {
                    "zipcode": "44358",
                    "province": "Teruel",
                    "city": "Cosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "44359",
                    "province": "Teruel",
                    "city": "Torralba de los Sisones",
                    "multiple": "0"
                },
                {
                    "zipcode": "44359",
                    "province": "Teruel",
                    "city": "Caminreal",
                    "multiple": "1"
                },
                {
                    "zipcode": "44360",
                    "province": "Teruel",
                    "city": "Santa Eulalia",
                    "multiple": "0"
                },
                {
                    "zipcode": "44366",
                    "province": "Teruel",
                    "city": "Orihuela del Tremedal",
                    "multiple": "0"
                },
                {
                    "zipcode": "44367",
                    "province": "Teruel",
                    "city": "Bronchales",
                    "multiple": "0"
                },
                {
                    "zipcode": "44368",
                    "province": "Teruel",
                    "city": "Monterde de Albarracín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44368",
                    "province": "Teruel",
                    "city": "Pozondón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44369",
                    "province": "Teruel",
                    "city": "Almohaja",
                    "multiple": "0"
                },
                {
                    "zipcode": "44369",
                    "province": "Teruel",
                    "city": "Peracense",
                    "multiple": "1"
                },
                {
                    "zipcode": "44370",
                    "province": "Teruel",
                    "city": "Cella",
                    "multiple": "0"
                },
                {
                    "zipcode": "44380",
                    "province": "Teruel",
                    "city": "Villarquemado",
                    "multiple": "0"
                },
                {
                    "zipcode": "44381",
                    "province": "Teruel",
                    "city": "Torremocha de Jiloca",
                    "multiple": "0"
                },
                {
                    "zipcode": "44382",
                    "province": "Teruel",
                    "city": "Singra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44382",
                    "province": "Teruel",
                    "city": "Torrelacárcel",
                    "multiple": "1"
                },
                {
                    "zipcode": "44382",
                    "province": "Teruel",
                    "city": "Aguatón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44390",
                    "province": "Teruel",
                    "city": "San Martín del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "44391",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44392",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44393",
                    "province": "Teruel",
                    "city": "Torrijo del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44394",
                    "province": "Teruel",
                    "city": "Bueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "44394",
                    "province": "Teruel",
                    "city": "Villafranca del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "44395",
                    "province": "Teruel",
                    "city": "Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "44396",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44397",
                    "province": "Teruel",
                    "city": "Teruel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44400",
                    "province": "Teruel",
                    "city": "Mora de Rubielos",
                    "multiple": "0"
                },
                {
                    "zipcode": "44409",
                    "province": "Teruel",
                    "city": "Cabra de Mora",
                    "multiple": "0"
                },
                {
                    "zipcode": "44409",
                    "province": "Teruel",
                    "city": "Castellar, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "44410",
                    "province": "Teruel",
                    "city": "Mosqueruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "44411",
                    "province": "Teruel",
                    "city": "Puertomingalvo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44412",
                    "province": "Teruel",
                    "city": "Linares de Mora",
                    "multiple": "0"
                },
                {
                    "zipcode": "44413",
                    "province": "Teruel",
                    "city": "Valdelinares",
                    "multiple": "0"
                },
                {
                    "zipcode": "44414",
                    "province": "Teruel",
                    "city": "Nogueruelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44415",
                    "province": "Teruel",
                    "city": "Rubielos de Mora",
                    "multiple": "0"
                },
                {
                    "zipcode": "44415",
                    "province": "Teruel",
                    "city": "Fuentes de Rubielos",
                    "multiple": "1"
                },
                {
                    "zipcode": "44420",
                    "province": "Teruel",
                    "city": "Manzanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "44421",
                    "province": "Teruel",
                    "city": "Torrijas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44421",
                    "province": "Teruel",
                    "city": "Arcos de las Salinas",
                    "multiple": "1"
                },
                {
                    "zipcode": "44422",
                    "province": "Teruel",
                    "city": "Abejuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "44422",
                    "province": "Teruel",
                    "city": "Manzanera",
                    "multiple": "1"
                },
                {
                    "zipcode": "44423",
                    "province": "Teruel",
                    "city": "Manzanera",
                    "multiple": "0"
                },
                {
                    "zipcode": "44424",
                    "province": "Teruel",
                    "city": "Albentosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "44424",
                    "province": "Teruel",
                    "city": "Sarrión",
                    "multiple": "1"
                },
                {
                    "zipcode": "44430",
                    "province": "Teruel",
                    "city": "Valbona",
                    "multiple": "0"
                },
                {
                    "zipcode": "44431",
                    "province": "Teruel",
                    "city": "Alcalá de la Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "44432",
                    "province": "Teruel",
                    "city": "Alcalá de la Selva",
                    "multiple": "0"
                },
                {
                    "zipcode": "44433",
                    "province": "Teruel",
                    "city": "Gúdar",
                    "multiple": "0"
                },
                {
                    "zipcode": "44440",
                    "province": "Teruel",
                    "city": "Formiche Alto",
                    "multiple": "0"
                },
                {
                    "zipcode": "44441",
                    "province": "Teruel",
                    "city": "Formiche Alto",
                    "multiple": "0"
                },
                {
                    "zipcode": "44450",
                    "province": "Teruel",
                    "city": "Puebla de Valverde, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44459",
                    "province": "Teruel",
                    "city": "Camarena de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44460",
                    "province": "Teruel",
                    "city": "Sarrión",
                    "multiple": "0"
                },
                {
                    "zipcode": "44477",
                    "province": "Teruel",
                    "city": "Albentosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "44478",
                    "province": "Teruel",
                    "city": "Olba",
                    "multiple": "0"
                },
                {
                    "zipcode": "44479",
                    "province": "Teruel",
                    "city": "Olba",
                    "multiple": "0"
                },
                {
                    "zipcode": "44480",
                    "province": "Teruel",
                    "city": "San Agustín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44490",
                    "province": "Teruel",
                    "city": "Ferreruela de Huerva",
                    "multiple": "0"
                },
                {
                    "zipcode": "44491",
                    "province": "Teruel",
                    "city": "Bádenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44491",
                    "province": "Teruel",
                    "city": "Cucalón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44491",
                    "province": "Teruel",
                    "city": "Lanzuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "44492",
                    "province": "Teruel",
                    "city": "Bea",
                    "multiple": "0"
                },
                {
                    "zipcode": "44492",
                    "province": "Teruel",
                    "city": "Lagueruela",
                    "multiple": "1"
                },
                {
                    "zipcode": "44492",
                    "province": "Teruel",
                    "city": "Allueva",
                    "multiple": "1"
                },
                {
                    "zipcode": "44492",
                    "province": "Teruel",
                    "city": "Fonfría",
                    "multiple": "1"
                },
                {
                    "zipcode": "44493",
                    "province": "Teruel",
                    "city": "Loscos",
                    "multiple": "0"
                },
                {
                    "zipcode": "44493",
                    "province": "Teruel",
                    "city": "Monforte de Moyuela",
                    "multiple": "1"
                },
                {
                    "zipcode": "44493",
                    "province": "Teruel",
                    "city": "Nogueras",
                    "multiple": "1"
                },
                {
                    "zipcode": "44494",
                    "province": "Teruel",
                    "city": "Villahermosa del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44495",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44496",
                    "province": "Teruel",
                    "city": "Calamocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44497",
                    "province": "Teruel",
                    "city": "Santa Cruz de Nogueras",
                    "multiple": "0"
                },
                {
                    "zipcode": "44500",
                    "province": "Teruel",
                    "city": "Andorra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44509",
                    "province": "Teruel",
                    "city": "Alloza",
                    "multiple": "0"
                },
                {
                    "zipcode": "44510",
                    "province": "Teruel",
                    "city": "Puebla de Híjar, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44511",
                    "province": "Teruel",
                    "city": "Puebla de Híjar, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44520",
                    "province": "Teruel",
                    "city": "Samper de Calanda",
                    "multiple": "0"
                },
                {
                    "zipcode": "44530",
                    "province": "Teruel",
                    "city": "Híjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "44540",
                    "province": "Teruel",
                    "city": "Albalate del Arzobispo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44547",
                    "province": "Teruel",
                    "city": "Ariño",
                    "multiple": "0"
                },
                {
                    "zipcode": "44548",
                    "province": "Teruel",
                    "city": "Oliete",
                    "multiple": "0"
                },
                {
                    "zipcode": "44549",
                    "province": "Teruel",
                    "city": "Alacón",
                    "multiple": "0"
                },
                {
                    "zipcode": "44550",
                    "province": "Teruel",
                    "city": "Alcorisa",
                    "multiple": "0"
                },
                {
                    "zipcode": "44555",
                    "province": "Teruel",
                    "city": "Pitarque",
                    "multiple": "0"
                },
                {
                    "zipcode": "44556",
                    "province": "Teruel",
                    "city": "Molinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "44556",
                    "province": "Teruel",
                    "city": "Berge",
                    "multiple": "1"
                },
                {
                    "zipcode": "44557",
                    "province": "Teruel",
                    "city": "Olmos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "44557",
                    "province": "Teruel",
                    "city": "Crivillén",
                    "multiple": "1"
                },
                {
                    "zipcode": "44557",
                    "province": "Teruel",
                    "city": "Mata de los Olmos, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "44558",
                    "province": "Teruel",
                    "city": "Gargallo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44558",
                    "province": "Teruel",
                    "city": "Estercuel",
                    "multiple": "1"
                },
                {
                    "zipcode": "44559",
                    "province": "Teruel",
                    "city": "Villarluengo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44559",
                    "province": "Teruel",
                    "city": "Ejulve",
                    "multiple": "1"
                },
                {
                    "zipcode": "44560",
                    "province": "Teruel",
                    "city": "Castellote",
                    "multiple": "0"
                },
                {
                    "zipcode": "44561",
                    "province": "Teruel",
                    "city": "Seno",
                    "multiple": "0"
                },
                {
                    "zipcode": "44562",
                    "province": "Teruel",
                    "city": "Castellote",
                    "multiple": "0"
                },
                {
                    "zipcode": "44563",
                    "province": "Teruel",
                    "city": "Castellote",
                    "multiple": "0"
                },
                {
                    "zipcode": "44563",
                    "province": "Teruel",
                    "city": "Bordón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44564",
                    "province": "Teruel",
                    "city": "Mas de las Matas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44565",
                    "province": "Teruel",
                    "city": "Castellote",
                    "multiple": "0"
                },
                {
                    "zipcode": "44566",
                    "province": "Teruel",
                    "city": "Parras de Castellote, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "44566",
                    "province": "Teruel",
                    "city": "Aguaviva",
                    "multiple": "1"
                },
                {
                    "zipcode": "44570",
                    "province": "Teruel",
                    "city": "Calanda",
                    "multiple": "0"
                },
                {
                    "zipcode": "44579",
                    "province": "Teruel",
                    "city": "Foz-Calanda",
                    "multiple": "0"
                },
                {
                    "zipcode": "44580",
                    "province": "Teruel",
                    "city": "Valderrobres",
                    "multiple": "0"
                },
                {
                    "zipcode": "44586",
                    "province": "Teruel",
                    "city": "Peñarroya de Tastavins",
                    "multiple": "0"
                },
                {
                    "zipcode": "44587",
                    "province": "Teruel",
                    "city": "Fuentespalda",
                    "multiple": "0"
                },
                {
                    "zipcode": "44588",
                    "province": "Teruel",
                    "city": "Beceite",
                    "multiple": "0"
                },
                {
                    "zipcode": "44589",
                    "province": "Teruel",
                    "city": "Portellada, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44589",
                    "province": "Teruel",
                    "city": "Ráfales",
                    "multiple": "1"
                },
                {
                    "zipcode": "44590",
                    "province": "Teruel",
                    "city": "Azaila",
                    "multiple": "0"
                },
                {
                    "zipcode": "44591",
                    "province": "Teruel",
                    "city": "Almochuel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44591",
                    "province": "Teruel",
                    "city": "Vinaceite",
                    "multiple": "1"
                },
                {
                    "zipcode": "44592",
                    "province": "Teruel",
                    "city": "Jatiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "44592",
                    "province": "Teruel",
                    "city": "Castelnou",
                    "multiple": "1"
                },
                {
                    "zipcode": "44593",
                    "province": "Teruel",
                    "city": "Urrea de Gaén",
                    "multiple": "0"
                },
                {
                    "zipcode": "44594",
                    "province": "Teruel",
                    "city": "Valdealgorfa",
                    "multiple": "0"
                },
                {
                    "zipcode": "44595",
                    "province": "Teruel",
                    "city": "Valjunquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "44596",
                    "province": "Teruel",
                    "city": "Fresneda, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44597",
                    "province": "Teruel",
                    "city": "Torre del Compte",
                    "multiple": "0"
                },
                {
                    "zipcode": "44600",
                    "province": "Teruel",
                    "city": "Alcañiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "44610",
                    "province": "Teruel",
                    "city": "Calaceite",
                    "multiple": "0"
                },
                {
                    "zipcode": "44620",
                    "province": "Teruel",
                    "city": "Valdeltormo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44621",
                    "province": "Teruel",
                    "city": "Mazaleón",
                    "multiple": "0"
                },
                {
                    "zipcode": "44622",
                    "province": "Teruel",
                    "city": "Arens de Lledó",
                    "multiple": "0"
                },
                {
                    "zipcode": "44623",
                    "province": "Teruel",
                    "city": "Cretas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44624",
                    "province": "Teruel",
                    "city": "Lledó",
                    "multiple": "0"
                },
                {
                    "zipcode": "44630",
                    "province": "Teruel",
                    "city": "Font de la Figuera, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "44630",
                    "province": "Teruel",
                    "city": "Castelserás",
                    "multiple": "1"
                },
                {
                    "zipcode": "44640",
                    "province": "Teruel",
                    "city": "Codoñera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44640",
                    "province": "Teruel",
                    "city": "Torrecilla de Alcañiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "44641",
                    "province": "Teruel",
                    "city": "Torrevelilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "44642",
                    "province": "Teruel",
                    "city": "Belmonte de San José",
                    "multiple": "0"
                },
                {
                    "zipcode": "44643",
                    "province": "Teruel",
                    "city": "Cañada de Verich, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44643",
                    "province": "Teruel",
                    "city": "Ginebrosa, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "44650",
                    "province": "Teruel",
                    "city": "Fórnoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "44651",
                    "province": "Teruel",
                    "city": "Cerollera, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "44652",
                    "province": "Teruel",
                    "city": "Monroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44653",
                    "province": "Teruel",
                    "city": "Torre de Arcas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44660",
                    "province": "Teruel",
                    "city": "Alcañiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "44661",
                    "province": "Teruel",
                    "city": "Alcañiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "44700",
                    "province": "Teruel",
                    "city": "Montalbán",
                    "multiple": "0"
                },
                {
                    "zipcode": "44706",
                    "province": "Teruel",
                    "city": "Castel de Cabra",
                    "multiple": "0"
                },
                {
                    "zipcode": "44707",
                    "province": "Teruel",
                    "city": "Cañizar del Olivar",
                    "multiple": "0"
                },
                {
                    "zipcode": "44707",
                    "province": "Teruel",
                    "city": "Zoma, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "44708",
                    "province": "Teruel",
                    "city": "Palomar de Arroyos",
                    "multiple": "0"
                },
                {
                    "zipcode": "44709",
                    "province": "Teruel",
                    "city": "Torre de las Arcas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44709",
                    "province": "Teruel",
                    "city": "Montalbán",
                    "multiple": "1"
                },
                {
                    "zipcode": "44710",
                    "province": "Teruel",
                    "city": "Rillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44711",
                    "province": "Teruel",
                    "city": "Fuentes Calientes",
                    "multiple": "0"
                },
                {
                    "zipcode": "44712",
                    "province": "Teruel",
                    "city": "Rillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44720",
                    "province": "Teruel",
                    "city": "Pancrudo",
                    "multiple": "0"
                },
                {
                    "zipcode": "44721",
                    "province": "Teruel",
                    "city": "Alpeñés",
                    "multiple": "0"
                },
                {
                    "zipcode": "44721",
                    "province": "Teruel",
                    "city": "Cosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "44730",
                    "province": "Teruel",
                    "city": "Martín del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "44730",
                    "province": "Teruel",
                    "city": "Pancrudo",
                    "multiple": "1"
                },
                {
                    "zipcode": "44740",
                    "province": "Teruel",
                    "city": "Vivel del Río Martín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44741",
                    "province": "Teruel",
                    "city": "Fuenferrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "44742",
                    "province": "Teruel",
                    "city": "Vivel del Río Martín",
                    "multiple": "0"
                },
                {
                    "zipcode": "44750",
                    "province": "Teruel",
                    "city": "Martín del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "44760",
                    "province": "Teruel",
                    "city": "Utrillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44761",
                    "province": "Teruel",
                    "city": "Utrillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44769",
                    "province": "Teruel",
                    "city": "Utrillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44770",
                    "province": "Teruel",
                    "city": "Escucha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44779",
                    "province": "Teruel",
                    "city": "Escucha",
                    "multiple": "0"
                },
                {
                    "zipcode": "44780",
                    "province": "Teruel",
                    "city": "Muniesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "44790",
                    "province": "Teruel",
                    "city": "Blesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "44791",
                    "province": "Teruel",
                    "city": "Maicas",
                    "multiple": "0"
                },
                {
                    "zipcode": "44791",
                    "province": "Teruel",
                    "city": "Cortes de Aragón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44791",
                    "province": "Teruel",
                    "city": "Hoz de la Vieja, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "44792",
                    "province": "Teruel",
                    "city": "Alcaine",
                    "multiple": "0"
                },
                {
                    "zipcode": "44792",
                    "province": "Teruel",
                    "city": "Obón",
                    "multiple": "1"
                },
                {
                    "zipcode": "44792",
                    "province": "Teruel",
                    "city": "Josa",
                    "multiple": "1"
                },
                {
                    "zipcode": "44793",
                    "province": "Teruel",
                    "city": "Segura de los Baños",
                    "multiple": "0"
                },
                {
                    "zipcode": "44793",
                    "province": "Teruel",
                    "city": "Salcedillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "45001",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45002",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45003",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45004",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45005",
                    "province": "Toledo",
                    "city": "Illescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45005",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "1"
                },
                {
                    "zipcode": "45006",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45007",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45008",
                    "province": "Toledo",
                    "city": "Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45100",
                    "province": "Toledo",
                    "city": "Sonseca",
                    "multiple": "0"
                },
                {
                    "zipcode": "45109",
                    "province": "Toledo",
                    "city": "Orgaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "45109",
                    "province": "Toledo",
                    "city": "Sonseca",
                    "multiple": "1"
                },
                {
                    "zipcode": "45110",
                    "province": "Toledo",
                    "city": "Ajofrín",
                    "multiple": "0"
                },
                {
                    "zipcode": "45111",
                    "province": "Toledo",
                    "city": "Cobisa",
                    "multiple": "0"
                },
                {
                    "zipcode": "45112",
                    "province": "Toledo",
                    "city": "Burguillos de Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45113",
                    "province": "Toledo",
                    "city": "Chueca",
                    "multiple": "0"
                },
                {
                    "zipcode": "45114",
                    "province": "Toledo",
                    "city": "Mazarambroz",
                    "multiple": "0"
                },
                {
                    "zipcode": "45120",
                    "province": "Toledo",
                    "city": "Menasalbas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45120",
                    "province": "Toledo",
                    "city": "San Pablo de los Montes",
                    "multiple": "1"
                },
                {
                    "zipcode": "45121",
                    "province": "Toledo",
                    "city": "Menasalbas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45122",
                    "province": "Toledo",
                    "city": "Argés",
                    "multiple": "0"
                },
                {
                    "zipcode": "45123",
                    "province": "Toledo",
                    "city": "Seseña",
                    "multiple": "0"
                },
                {
                    "zipcode": "45123",
                    "province": "Toledo",
                    "city": "Layos",
                    "multiple": "1"
                },
                {
                    "zipcode": "45124",
                    "province": "Toledo",
                    "city": "Casasbuenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45125",
                    "province": "Toledo",
                    "city": "Pulgar",
                    "multiple": "0"
                },
                {
                    "zipcode": "45126",
                    "province": "Toledo",
                    "city": "Cuerva",
                    "multiple": "0"
                },
                {
                    "zipcode": "45126",
                    "province": "Toledo",
                    "city": "Mazarambroz",
                    "multiple": "1"
                },
                {
                    "zipcode": "45127",
                    "province": "Toledo",
                    "city": "Ventas con Peña Aguilera, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "45128",
                    "province": "Toledo",
                    "city": "Menasalbas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45130",
                    "province": "Toledo",
                    "city": "Navalucillos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "45138",
                    "province": "Toledo",
                    "city": "Navalucillos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "45139",
                    "province": "Toledo",
                    "city": "Navalucillos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "45140",
                    "province": "Toledo",
                    "city": "Navalmorales, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "45150",
                    "province": "Toledo",
                    "city": "Navahermosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "45159",
                    "province": "Toledo",
                    "city": "Hontanar",
                    "multiple": "0"
                },
                {
                    "zipcode": "45159",
                    "province": "Toledo",
                    "city": "Navahermosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "45160",
                    "province": "Toledo",
                    "city": "Guadamur",
                    "multiple": "0"
                },
                {
                    "zipcode": "45161",
                    "province": "Toledo",
                    "city": "Polán",
                    "multiple": "0"
                },
                {
                    "zipcode": "45162",
                    "province": "Toledo",
                    "city": "Noez",
                    "multiple": "0"
                },
                {
                    "zipcode": "45163",
                    "province": "Toledo",
                    "city": "Totanés",
                    "multiple": "0"
                },
                {
                    "zipcode": "45164",
                    "province": "Toledo",
                    "city": "Gálvez",
                    "multiple": "0"
                },
                {
                    "zipcode": "45165",
                    "province": "Toledo",
                    "city": "San Martín de Montalbán",
                    "multiple": "0"
                },
                {
                    "zipcode": "45170",
                    "province": "Toledo",
                    "city": "San Martín de Pusa",
                    "multiple": "0"
                },
                {
                    "zipcode": "45179",
                    "province": "Toledo",
                    "city": "Villarejo de Montalbán",
                    "multiple": "0"
                },
                {
                    "zipcode": "45180",
                    "province": "Toledo",
                    "city": "Camarena",
                    "multiple": "0"
                },
                {
                    "zipcode": "45181",
                    "province": "Toledo",
                    "city": "Camarenilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "45182",
                    "province": "Toledo",
                    "city": "Arcicóllar",
                    "multiple": "0"
                },
                {
                    "zipcode": "45183",
                    "province": "Toledo",
                    "city": "Ventas de Retamosa, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "45190",
                    "province": "Toledo",
                    "city": "Nambroca",
                    "multiple": "0"
                },
                {
                    "zipcode": "45191",
                    "province": "Toledo",
                    "city": "Nambroca",
                    "multiple": "0"
                },
                {
                    "zipcode": "45200",
                    "province": "Toledo",
                    "city": "Illescas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45210",
                    "province": "Toledo",
                    "city": "Yuncos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45211",
                    "province": "Toledo",
                    "city": "Yuncos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45211",
                    "province": "Toledo",
                    "city": "Recas",
                    "multiple": "1"
                },
                {
                    "zipcode": "45212",
                    "province": "Toledo",
                    "city": "Lominchar",
                    "multiple": "0"
                },
                {
                    "zipcode": "45213",
                    "province": "Toledo",
                    "city": "Palomeque",
                    "multiple": "0"
                },
                {
                    "zipcode": "45214",
                    "province": "Toledo",
                    "city": "Cedillo del Condado",
                    "multiple": "0"
                },
                {
                    "zipcode": "45215",
                    "province": "Toledo",
                    "city": "Viso de San Juan, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45216",
                    "province": "Toledo",
                    "city": "Carranque",
                    "multiple": "0"
                },
                {
                    "zipcode": "45217",
                    "province": "Toledo",
                    "city": "Ugena",
                    "multiple": "0"
                },
                {
                    "zipcode": "45220",
                    "province": "Toledo",
                    "city": "Yeles",
                    "multiple": "0"
                },
                {
                    "zipcode": "45221",
                    "province": "Toledo",
                    "city": "Esquivias",
                    "multiple": "0"
                },
                {
                    "zipcode": "45222",
                    "province": "Toledo",
                    "city": "Borox",
                    "multiple": "0"
                },
                {
                    "zipcode": "45223",
                    "province": "Toledo",
                    "city": "Seseña",
                    "multiple": "0"
                },
                {
                    "zipcode": "45224",
                    "province": "Toledo",
                    "city": "Seseña",
                    "multiple": "0"
                },
                {
                    "zipcode": "45225",
                    "province": "Toledo",
                    "city": "Barcience",
                    "multiple": "0"
                },
                {
                    "zipcode": "45230",
                    "province": "Toledo",
                    "city": "Numancia de la Sagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "45240",
                    "province": "Toledo",
                    "city": "Alameda de la Sagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "45250",
                    "province": "Toledo",
                    "city": "Añover de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45260",
                    "province": "Toledo",
                    "city": "Villaseca de la Sagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "45270",
                    "province": "Toledo",
                    "city": "Mocejón",
                    "multiple": "0"
                },
                {
                    "zipcode": "45276",
                    "province": "Toledo",
                    "city": "Mohedas de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "45280",
                    "province": "Toledo",
                    "city": "Olías del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "45290",
                    "province": "Toledo",
                    "city": "Pantoja",
                    "multiple": "0"
                },
                {
                    "zipcode": "45291",
                    "province": "Toledo",
                    "city": "Cobeja",
                    "multiple": "0"
                },
                {
                    "zipcode": "45292",
                    "province": "Toledo",
                    "city": "Villaseca de la Sagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "45300",
                    "province": "Toledo",
                    "city": "Puebla de Montalbán, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45300",
                    "province": "Toledo",
                    "city": "Ocaña",
                    "multiple": "1"
                },
                {
                    "zipcode": "45310",
                    "province": "Toledo",
                    "city": "Villatobas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45311",
                    "province": "Toledo",
                    "city": "Dosbarrios",
                    "multiple": "0"
                },
                {
                    "zipcode": "45312",
                    "province": "Toledo",
                    "city": "Cabañas de Yepes",
                    "multiple": "0"
                },
                {
                    "zipcode": "45313",
                    "province": "Toledo",
                    "city": "Yepes",
                    "multiple": "0"
                },
                {
                    "zipcode": "45314",
                    "province": "Toledo",
                    "city": "Ciruelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45340",
                    "province": "Toledo",
                    "city": "Ontígola",
                    "multiple": "0"
                },
                {
                    "zipcode": "45350",
                    "province": "Toledo",
                    "city": "Noblejas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45360",
                    "province": "Toledo",
                    "city": "Villarrubia de Santiago",
                    "multiple": "0"
                },
                {
                    "zipcode": "45370",
                    "province": "Toledo",
                    "city": "Santa Cruz de la Zarza",
                    "multiple": "0"
                },
                {
                    "zipcode": "45370",
                    "province": "Toledo",
                    "city": "Villarrubia de Santiago",
                    "multiple": "1"
                },
                {
                    "zipcode": "45400",
                    "province": "Toledo",
                    "city": "Mora",
                    "multiple": "0"
                },
                {
                    "zipcode": "45400",
                    "province": "Toledo",
                    "city": "Quintanar de la Orden",
                    "multiple": "1"
                },
                {
                    "zipcode": "45410",
                    "province": "Toledo",
                    "city": "Villanueva de Bogas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45420",
                    "province": "Toledo",
                    "city": "Almonacid de Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45430",
                    "province": "Toledo",
                    "city": "Mascaraque",
                    "multiple": "0"
                },
                {
                    "zipcode": "45440",
                    "province": "Toledo",
                    "city": "Villaminaya",
                    "multiple": "0"
                },
                {
                    "zipcode": "45450",
                    "province": "Toledo",
                    "city": "Orgaz",
                    "multiple": "0"
                },
                {
                    "zipcode": "45460",
                    "province": "Toledo",
                    "city": "Manzaneque",
                    "multiple": "0"
                },
                {
                    "zipcode": "45460",
                    "province": "Toledo",
                    "city": "Real de San Vicente, El",
                    "multiple": "1"
                },
                {
                    "zipcode": "45470",
                    "province": "Toledo",
                    "city": "Yébenes, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "45479",
                    "province": "Toledo",
                    "city": "Marjaliza",
                    "multiple": "0"
                },
                {
                    "zipcode": "45480",
                    "province": "Toledo",
                    "city": "Yébenes, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "45480",
                    "province": "Toledo",
                    "city": "Urda",
                    "multiple": "1"
                },
                {
                    "zipcode": "45500",
                    "province": "Toledo",
                    "city": "Torrijos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45510",
                    "province": "Toledo",
                    "city": "Fuensalida",
                    "multiple": "0"
                },
                {
                    "zipcode": "45511",
                    "province": "Toledo",
                    "city": "Huecas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45512",
                    "province": "Toledo",
                    "city": "Portillo de Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45513",
                    "province": "Toledo",
                    "city": "Santa Cruz del Retamar",
                    "multiple": "0"
                },
                {
                    "zipcode": "45514",
                    "province": "Toledo",
                    "city": "Quismondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45515",
                    "province": "Toledo",
                    "city": "Maqueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "45516",
                    "province": "Toledo",
                    "city": "Puebla de Montalbán, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45517",
                    "province": "Toledo",
                    "city": "Escalonilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "45518",
                    "province": "Toledo",
                    "city": "Gerindote",
                    "multiple": "0"
                },
                {
                    "zipcode": "45519",
                    "province": "Toledo",
                    "city": "Novés",
                    "multiple": "0"
                },
                {
                    "zipcode": "45520",
                    "province": "Toledo",
                    "city": "Villaluenga de la Sagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "45521",
                    "province": "Toledo",
                    "city": "Burujón",
                    "multiple": "0"
                },
                {
                    "zipcode": "45522",
                    "province": "Toledo",
                    "city": "Albarreal de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45523",
                    "province": "Toledo",
                    "city": "Alcabón",
                    "multiple": "0"
                },
                {
                    "zipcode": "45524",
                    "province": "Toledo",
                    "city": "Rielves",
                    "multiple": "0"
                },
                {
                    "zipcode": "45525",
                    "province": "Toledo",
                    "city": "Barcience",
                    "multiple": "0"
                },
                {
                    "zipcode": "45526",
                    "province": "Toledo",
                    "city": "Santo Domingo-Caudilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "45529",
                    "province": "Toledo",
                    "city": "Yuncler",
                    "multiple": "0"
                },
                {
                    "zipcode": "45530",
                    "province": "Toledo",
                    "city": "Santa Olalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "45530",
                    "province": "Toledo",
                    "city": "Miguel Esteban",
                    "multiple": "1"
                },
                {
                    "zipcode": "45531",
                    "province": "Toledo",
                    "city": "Carmena",
                    "multiple": "0"
                },
                {
                    "zipcode": "45532",
                    "province": "Toledo",
                    "city": "Carriches",
                    "multiple": "0"
                },
                {
                    "zipcode": "45533",
                    "province": "Toledo",
                    "city": "Carpio de Tajo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45534",
                    "province": "Toledo",
                    "city": "Mata, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45540",
                    "province": "Toledo",
                    "city": "Fuensalida",
                    "multiple": "0"
                },
                {
                    "zipcode": "45540",
                    "province": "Toledo",
                    "city": "Erustes",
                    "multiple": "1"
                },
                {
                    "zipcode": "45541",
                    "province": "Toledo",
                    "city": "Mesegar de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45542",
                    "province": "Toledo",
                    "city": "Casar de Escalona, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45543",
                    "province": "Toledo",
                    "city": "Otero",
                    "multiple": "0"
                },
                {
                    "zipcode": "45544",
                    "province": "Toledo",
                    "city": "Domingo Pérez",
                    "multiple": "0"
                },
                {
                    "zipcode": "45551",
                    "province": "Toledo",
                    "city": "Illán de Vacas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45560",
                    "province": "Toledo",
                    "city": "Oropesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "45567",
                    "province": "Toledo",
                    "city": "Lagartera",
                    "multiple": "0"
                },
                {
                    "zipcode": "45568",
                    "province": "Toledo",
                    "city": "Ventas de San Julián, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "45569",
                    "province": "Toledo",
                    "city": "Torralba de Oropesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "45569",
                    "province": "Toledo",
                    "city": "Oropesa",
                    "multiple": "1"
                },
                {
                    "zipcode": "45570",
                    "province": "Toledo",
                    "city": "Puente del Arzobispo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45571",
                    "province": "Toledo",
                    "city": "Azután",
                    "multiple": "0"
                },
                {
                    "zipcode": "45571",
                    "province": "Toledo",
                    "city": "Alcolea de Tajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "45572",
                    "province": "Toledo",
                    "city": "Valdeverdeja",
                    "multiple": "0"
                },
                {
                    "zipcode": "45572",
                    "province": "Toledo",
                    "city": "Torrico",
                    "multiple": "1"
                },
                {
                    "zipcode": "45573",
                    "province": "Toledo",
                    "city": "Navalmoralejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45574",
                    "province": "Toledo",
                    "city": "Estrella, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45575",
                    "province": "Toledo",
                    "city": "Aldeanueva de San Bartolomé",
                    "multiple": "0"
                },
                {
                    "zipcode": "45576",
                    "province": "Toledo",
                    "city": "Aldeanueva de San Bartolomé",
                    "multiple": "0"
                },
                {
                    "zipcode": "45576",
                    "province": "Toledo",
                    "city": "Mohedas de la Jara",
                    "multiple": "1"
                },
                {
                    "zipcode": "45577",
                    "province": "Toledo",
                    "city": "Puerto de San Vicente",
                    "multiple": "0"
                },
                {
                    "zipcode": "45578",
                    "province": "Toledo",
                    "city": "Campillo de la Jara, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45580",
                    "province": "Toledo",
                    "city": "Calera y Chozas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45580",
                    "province": "Toledo",
                    "city": "Calzada de Oropesa",
                    "multiple": "1"
                },
                {
                    "zipcode": "45588",
                    "province": "Toledo",
                    "city": "Herreruela de Oropesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "45589",
                    "province": "Toledo",
                    "city": "Caleruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "45590",
                    "province": "Toledo",
                    "city": "Magán",
                    "multiple": "0"
                },
                {
                    "zipcode": "45591",
                    "province": "Toledo",
                    "city": "Yunclillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45592",
                    "province": "Toledo",
                    "city": "Cabañas de la Sagra",
                    "multiple": "0"
                },
                {
                    "zipcode": "45593",
                    "province": "Toledo",
                    "city": "Bargas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45594",
                    "province": "Toledo",
                    "city": "Villamiel de Toledo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45600",
                    "province": "Toledo",
                    "city": "Talavera de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "45600",
                    "province": "Toledo",
                    "city": "Oropesa",
                    "multiple": "1"
                },
                {
                    "zipcode": "45600",
                    "province": "Toledo",
                    "city": "Talavera de la Reina",
                    "multiple": "1"
                },
                {
                    "zipcode": "45610",
                    "province": "Toledo",
                    "city": "Navalcán",
                    "multiple": "0"
                },
                {
                    "zipcode": "45611",
                    "province": "Toledo",
                    "city": "Parrillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45612",
                    "province": "Toledo",
                    "city": "Velada",
                    "multiple": "0"
                },
                {
                    "zipcode": "45613",
                    "province": "Toledo",
                    "city": "Talavera de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "45614",
                    "province": "Toledo",
                    "city": "Talavera de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "45620",
                    "province": "Toledo",
                    "city": "Montesclaros",
                    "multiple": "0"
                },
                {
                    "zipcode": "45621",
                    "province": "Toledo",
                    "city": "Segurilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "45622",
                    "province": "Toledo",
                    "city": "Mejorada",
                    "multiple": "0"
                },
                {
                    "zipcode": "45630",
                    "province": "Toledo",
                    "city": "Navamorcuende",
                    "multiple": "0"
                },
                {
                    "zipcode": "45631",
                    "province": "Toledo",
                    "city": "Almendral de la Cañada",
                    "multiple": "0"
                },
                {
                    "zipcode": "45632",
                    "province": "Toledo",
                    "city": "Sartajada",
                    "multiple": "0"
                },
                {
                    "zipcode": "45633",
                    "province": "Toledo",
                    "city": "Iglesuela, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45634",
                    "province": "Toledo",
                    "city": "Buenaventura",
                    "multiple": "0"
                },
                {
                    "zipcode": "45635",
                    "province": "Toledo",
                    "city": "Sotillo de las Palomas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45636",
                    "province": "Toledo",
                    "city": "Marrupe",
                    "multiple": "0"
                },
                {
                    "zipcode": "45637",
                    "province": "Toledo",
                    "city": "Cervera de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "45638",
                    "province": "Toledo",
                    "city": "Pepino",
                    "multiple": "0"
                },
                {
                    "zipcode": "45640",
                    "province": "Toledo",
                    "city": "Real de San Vicente, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45641",
                    "province": "Toledo",
                    "city": "Castillo de Bayuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "45642",
                    "province": "Toledo",
                    "city": "Cardiel de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "45643",
                    "province": "Toledo",
                    "city": "Garciotum",
                    "multiple": "0"
                },
                {
                    "zipcode": "45644",
                    "province": "Toledo",
                    "city": "Nuño Gómez",
                    "multiple": "0"
                },
                {
                    "zipcode": "45645",
                    "province": "Toledo",
                    "city": "Hinojosa de San Vicente",
                    "multiple": "0"
                },
                {
                    "zipcode": "45646",
                    "province": "Toledo",
                    "city": "San Román de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "45650",
                    "province": "Toledo",
                    "city": "Espinoso del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "45651",
                    "province": "Toledo",
                    "city": "Torrecilla de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "45652",
                    "province": "Toledo",
                    "city": "Retamoso de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "45653",
                    "province": "Toledo",
                    "city": "Santa Ana de Pusa",
                    "multiple": "0"
                },
                {
                    "zipcode": "45654",
                    "province": "Toledo",
                    "city": "San Bartolomé de las Abiertas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45660",
                    "province": "Toledo",
                    "city": "Belvís de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "45661",
                    "province": "Toledo",
                    "city": "Aldeanueva de Barbarroya",
                    "multiple": "0"
                },
                {
                    "zipcode": "45662",
                    "province": "Toledo",
                    "city": "Mejorada",
                    "multiple": "0"
                },
                {
                    "zipcode": "45662",
                    "province": "Toledo",
                    "city": "Alcaudete de la Jara",
                    "multiple": "1"
                },
                {
                    "zipcode": "45663",
                    "province": "Toledo",
                    "city": "Herencias, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "45664",
                    "province": "Toledo",
                    "city": "Herencias, Las",
                    "multiple": "0"
                },
                {
                    "zipcode": "45670",
                    "province": "Toledo",
                    "city": "Nava de Ricomalillo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45671",
                    "province": "Toledo",
                    "city": "Sevilleja de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "45672",
                    "province": "Toledo",
                    "city": "Sevilleja de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "45673",
                    "province": "Toledo",
                    "city": "Sevilleja de la Jara",
                    "multiple": "0"
                },
                {
                    "zipcode": "45674",
                    "province": "Toledo",
                    "city": "Robledo del Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45675",
                    "province": "Toledo",
                    "city": "Robledo del Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45676",
                    "province": "Toledo",
                    "city": "Robledo del Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45677",
                    "province": "Toledo",
                    "city": "Robledo del Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45678",
                    "province": "Toledo",
                    "city": "Robledo del Mazo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45680",
                    "province": "Toledo",
                    "city": "Villacañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45680",
                    "province": "Toledo",
                    "city": "Cebolla",
                    "multiple": "1"
                },
                {
                    "zipcode": "45682",
                    "province": "Toledo",
                    "city": "Cerralbos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "45683",
                    "province": "Toledo",
                    "city": "Cazalegas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45684",
                    "province": "Toledo",
                    "city": "Lucillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45685",
                    "province": "Toledo",
                    "city": "Montearagón",
                    "multiple": "0"
                },
                {
                    "zipcode": "45686",
                    "province": "Toledo",
                    "city": "Calera y Chozas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45687",
                    "province": "Toledo",
                    "city": "Alcañizo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45690",
                    "province": "Toledo",
                    "city": "Pueblanueva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45691",
                    "province": "Toledo",
                    "city": "Pueblanueva, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45692",
                    "province": "Toledo",
                    "city": "Malpica de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45693",
                    "province": "Toledo",
                    "city": "Malpica de Tajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45694",
                    "province": "Toledo",
                    "city": "Talavera de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "45695",
                    "province": "Toledo",
                    "city": "Calera y Chozas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45700",
                    "province": "Toledo",
                    "city": "Consuegra",
                    "multiple": "0"
                },
                {
                    "zipcode": "45710",
                    "province": "Toledo",
                    "city": "Madridejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45720",
                    "province": "Toledo",
                    "city": "Camuñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45730",
                    "province": "Toledo",
                    "city": "Villafranca de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "45740",
                    "province": "Toledo",
                    "city": "Villasequilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "45749",
                    "province": "Toledo",
                    "city": "Villamuelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45750",
                    "province": "Toledo",
                    "city": "Cabañas de Yepes",
                    "multiple": "0"
                },
                {
                    "zipcode": "45750",
                    "province": "Toledo",
                    "city": "Huerta de Valdecarábanos",
                    "multiple": "1"
                },
                {
                    "zipcode": "45760",
                    "province": "Toledo",
                    "city": "Guardia, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45770",
                    "province": "Toledo",
                    "city": "Romeral, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45780",
                    "province": "Toledo",
                    "city": "Tembleque",
                    "multiple": "0"
                },
                {
                    "zipcode": "45789",
                    "province": "Toledo",
                    "city": "Turleque",
                    "multiple": "0"
                },
                {
                    "zipcode": "45790",
                    "province": "Toledo",
                    "city": "Quero",
                    "multiple": "0"
                },
                {
                    "zipcode": "45800",
                    "province": "Toledo",
                    "city": "Quintanar de la Orden",
                    "multiple": "0"
                },
                {
                    "zipcode": "45810",
                    "province": "Toledo",
                    "city": "Villanueva de Alcardete",
                    "multiple": "0"
                },
                {
                    "zipcode": "45820",
                    "province": "Toledo",
                    "city": "Toboso, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "45830",
                    "province": "Toledo",
                    "city": "Miguel Esteban",
                    "multiple": "0"
                },
                {
                    "zipcode": "45840",
                    "province": "Toledo",
                    "city": "Puebla de Almoradiel, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45850",
                    "province": "Toledo",
                    "city": "Villa de Don Fadrique, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45860",
                    "province": "Toledo",
                    "city": "Villacañas",
                    "multiple": "0"
                },
                {
                    "zipcode": "45870",
                    "province": "Toledo",
                    "city": "Lillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45880",
                    "province": "Toledo",
                    "city": "Corral de Almaguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "45890",
                    "province": "Toledo",
                    "city": "Cabezamesada",
                    "multiple": "0"
                },
                {
                    "zipcode": "45900",
                    "province": "Toledo",
                    "city": "Almorox",
                    "multiple": "0"
                },
                {
                    "zipcode": "45908",
                    "province": "Toledo",
                    "city": "Aldea en Cabo",
                    "multiple": "0"
                },
                {
                    "zipcode": "45908",
                    "province": "Toledo",
                    "city": "Paredes de Escalona",
                    "multiple": "1"
                },
                {
                    "zipcode": "45909",
                    "province": "Toledo",
                    "city": "Santa Cruz del Retamar",
                    "multiple": "0"
                },
                {
                    "zipcode": "45910",
                    "province": "Toledo",
                    "city": "Escalona",
                    "multiple": "0"
                },
                {
                    "zipcode": "45917",
                    "province": "Toledo",
                    "city": "Nombela",
                    "multiple": "0"
                },
                {
                    "zipcode": "45918",
                    "province": "Toledo",
                    "city": "Pelahustán",
                    "multiple": "0"
                },
                {
                    "zipcode": "45919",
                    "province": "Toledo",
                    "city": "Hormigos",
                    "multiple": "0"
                },
                {
                    "zipcode": "45920",
                    "province": "Toledo",
                    "city": "Torre de Esteban Hambrán, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "45930",
                    "province": "Toledo",
                    "city": "Méntrida",
                    "multiple": "0"
                },
                {
                    "zipcode": "45940",
                    "province": "Toledo",
                    "city": "Valmojado",
                    "multiple": "0"
                },
                {
                    "zipcode": "45950",
                    "province": "Toledo",
                    "city": "Casarrubios del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "45960",
                    "province": "Toledo",
                    "city": "Chozas de Canales",
                    "multiple": "0"
                },
                {
                    "zipcode": "46001",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46002",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46003",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46004",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46005",
                    "province": "Valencia",
                    "city": "Madrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "46005",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "46006",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46007",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46008",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46009",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46010",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46011",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46012",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46013",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46014",
                    "province": "Valencia",
                    "city": "Xirivella",
                    "multiple": "0"
                },
                {
                    "zipcode": "46014",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "1"
                },
                {
                    "zipcode": "46015",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46016",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46016",
                    "province": "Valencia",
                    "city": "Tavernes Blanques",
                    "multiple": "1"
                },
                {
                    "zipcode": "46017",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46018",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46019",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46020",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46021",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46022",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46023",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46024",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46025",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46026",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46035",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46100",
                    "province": "Valencia",
                    "city": "Burjassot",
                    "multiple": "0"
                },
                {
                    "zipcode": "46109",
                    "province": "Valencia",
                    "city": "Cheste",
                    "multiple": "0"
                },
                {
                    "zipcode": "46110",
                    "province": "Valencia",
                    "city": "Godella",
                    "multiple": "0"
                },
                {
                    "zipcode": "46111",
                    "province": "Valencia",
                    "city": "Rocafort",
                    "multiple": "0"
                },
                {
                    "zipcode": "46112",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46113",
                    "province": "Valencia",
                    "city": "Moncada",
                    "multiple": "0"
                },
                {
                    "zipcode": "46114",
                    "province": "Valencia",
                    "city": "Vinalesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "46115",
                    "province": "Valencia",
                    "city": "Alfara del Patriarca",
                    "multiple": "0"
                },
                {
                    "zipcode": "46117",
                    "province": "Valencia",
                    "city": "Bétera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46118",
                    "province": "Valencia",
                    "city": "Serra",
                    "multiple": "0"
                },
                {
                    "zipcode": "46119",
                    "province": "Valencia",
                    "city": "Náquera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46120",
                    "province": "Valencia",
                    "city": "Alboraya",
                    "multiple": "0"
                },
                {
                    "zipcode": "46128",
                    "province": "Valencia",
                    "city": "Alboraya",
                    "multiple": "0"
                },
                {
                    "zipcode": "46129",
                    "province": "Valencia",
                    "city": "Alboraya",
                    "multiple": "0"
                },
                {
                    "zipcode": "46130",
                    "province": "Valencia",
                    "city": "Massamagrell",
                    "multiple": "0"
                },
                {
                    "zipcode": "46131",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46131",
                    "province": "Valencia",
                    "city": "Bonrepòs i Mirambell",
                    "multiple": "1"
                },
                {
                    "zipcode": "46132",
                    "province": "Valencia",
                    "city": "Almàssera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46133",
                    "province": "Valencia",
                    "city": "Meliana",
                    "multiple": "0"
                },
                {
                    "zipcode": "46134",
                    "province": "Valencia",
                    "city": "Foios",
                    "multiple": "0"
                },
                {
                    "zipcode": "46135",
                    "province": "Valencia",
                    "city": "Valencia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46135",
                    "province": "Valencia",
                    "city": "Albalat dels Sorells",
                    "multiple": "1"
                },
                {
                    "zipcode": "46135",
                    "province": "Valencia",
                    "city": "Emperador",
                    "multiple": "1"
                },
                {
                    "zipcode": "46136",
                    "province": "Valencia",
                    "city": "Museros",
                    "multiple": "0"
                },
                {
                    "zipcode": "46137",
                    "province": "Valencia",
                    "city": "Pobla de Farnals, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "46138",
                    "province": "Valencia",
                    "city": "Rafelbunyol",
                    "multiple": "0"
                },
                {
                    "zipcode": "46139",
                    "province": "Valencia",
                    "city": "Pobla de Farnals, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "46140",
                    "province": "Valencia",
                    "city": "Ademuz",
                    "multiple": "0"
                },
                {
                    "zipcode": "46140",
                    "province": "Valencia",
                    "city": "Puebla de San Miguel",
                    "multiple": "1"
                },
                {
                    "zipcode": "46141",
                    "province": "Valencia",
                    "city": "Castielfabib",
                    "multiple": "0"
                },
                {
                    "zipcode": "46143",
                    "province": "Valencia",
                    "city": "Torrebaja",
                    "multiple": "0"
                },
                {
                    "zipcode": "46145",
                    "province": "Valencia",
                    "city": "Vallanca",
                    "multiple": "0"
                },
                {
                    "zipcode": "46146",
                    "province": "Valencia",
                    "city": "Casas Bajas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46147",
                    "province": "Valencia",
                    "city": "Casas Altas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46148",
                    "province": "Valencia",
                    "city": "Algimia de Alfara",
                    "multiple": "0"
                },
                {
                    "zipcode": "46149",
                    "province": "Valencia",
                    "city": "Gilet",
                    "multiple": "0"
                },
                {
                    "zipcode": "46160",
                    "province": "Valencia",
                    "city": "Llíria",
                    "multiple": "0"
                },
                {
                    "zipcode": "46162",
                    "province": "Valencia",
                    "city": "Higueruelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46163",
                    "province": "Valencia",
                    "city": "Marines",
                    "multiple": "0"
                },
                {
                    "zipcode": "46164",
                    "province": "Valencia",
                    "city": "Pedralba",
                    "multiple": "0"
                },
                {
                    "zipcode": "46165",
                    "province": "Valencia",
                    "city": "Bugarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "46166",
                    "province": "Valencia",
                    "city": "Gestalgar",
                    "multiple": "0"
                },
                {
                    "zipcode": "46167",
                    "province": "Valencia",
                    "city": "Chulilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "46168",
                    "province": "Valencia",
                    "city": "Sot de Chera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46169",
                    "province": "Valencia",
                    "city": "Gátova",
                    "multiple": "0"
                },
                {
                    "zipcode": "46169",
                    "province": "Valencia",
                    "city": "Olocau",
                    "multiple": "1"
                },
                {
                    "zipcode": "46170",
                    "province": "Valencia",
                    "city": "Andilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "46170",
                    "province": "Valencia",
                    "city": "Villar del Arzobispo",
                    "multiple": "1"
                },
                {
                    "zipcode": "46171",
                    "province": "Valencia",
                    "city": "Casinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "46172",
                    "province": "Valencia",
                    "city": "Alcublas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46173",
                    "province": "Valencia",
                    "city": "Losa del Obispo",
                    "multiple": "0"
                },
                {
                    "zipcode": "46173",
                    "province": "Valencia",
                    "city": "Benagéber",
                    "multiple": "1"
                },
                {
                    "zipcode": "46174",
                    "province": "Valencia",
                    "city": "Domeño",
                    "multiple": "0"
                },
                {
                    "zipcode": "46175",
                    "province": "Valencia",
                    "city": "Calles",
                    "multiple": "0"
                },
                {
                    "zipcode": "46176",
                    "province": "Valencia",
                    "city": "Chelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "46177",
                    "province": "Valencia",
                    "city": "Tuéjar",
                    "multiple": "0"
                },
                {
                    "zipcode": "46178",
                    "province": "Valencia",
                    "city": "Titaguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46178",
                    "province": "Valencia",
                    "city": "Alpuente",
                    "multiple": "1"
                },
                {
                    "zipcode": "46178",
                    "province": "Valencia",
                    "city": "Yesa, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "46179",
                    "province": "Valencia",
                    "city": "Aras de los Olmos",
                    "multiple": "0"
                },
                {
                    "zipcode": "46180",
                    "province": "Valencia",
                    "city": "Benaguasil",
                    "multiple": "0"
                },
                {
                    "zipcode": "46181",
                    "province": "Valencia",
                    "city": "Benisanó",
                    "multiple": "0"
                },
                {
                    "zipcode": "46181",
                    "province": "Valencia",
                    "city": "Oliva",
                    "multiple": "1"
                },
                {
                    "zipcode": "46182",
                    "province": "Valencia",
                    "city": "Paterna",
                    "multiple": "0"
                },
                {
                    "zipcode": "46183",
                    "province": "Valencia",
                    "city": "Eliana, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "46184",
                    "province": "Valencia",
                    "city": "San Antonio de Benagéber",
                    "multiple": "0"
                },
                {
                    "zipcode": "46185",
                    "province": "Valencia",
                    "city": "Pobla de Vallbona, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "46190",
                    "province": "Valencia",
                    "city": "Riba-roja de Túria",
                    "multiple": "0"
                },
                {
                    "zipcode": "46191",
                    "province": "Valencia",
                    "city": "Vilamarxant",
                    "multiple": "0"
                },
                {
                    "zipcode": "46192",
                    "province": "Valencia",
                    "city": "Montserrat",
                    "multiple": "0"
                },
                {
                    "zipcode": "46193",
                    "province": "Valencia",
                    "city": "Montroi/Montroy",
                    "multiple": "0"
                },
                {
                    "zipcode": "46194",
                    "province": "Valencia",
                    "city": "Real",
                    "multiple": "0"
                },
                {
                    "zipcode": "46195",
                    "province": "Valencia",
                    "city": "Llombai",
                    "multiple": "0"
                },
                {
                    "zipcode": "46196",
                    "province": "Valencia",
                    "city": "Catadau",
                    "multiple": "0"
                },
                {
                    "zipcode": "46197",
                    "province": "Valencia",
                    "city": "Alfarp",
                    "multiple": "0"
                },
                {
                    "zipcode": "46198",
                    "province": "Valencia",
                    "city": "Dos Aguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46199",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46199",
                    "province": "Valencia",
                    "city": "Millares",
                    "multiple": "1"
                },
                {
                    "zipcode": "46199",
                    "province": "Valencia",
                    "city": "Cortes de Pallás",
                    "multiple": "1"
                },
                {
                    "zipcode": "46200",
                    "province": "Valencia",
                    "city": "Paiporta",
                    "multiple": "0"
                },
                {
                    "zipcode": "46210",
                    "province": "Valencia",
                    "city": "Picanya",
                    "multiple": "0"
                },
                {
                    "zipcode": "46220",
                    "province": "Valencia",
                    "city": "Alcàsser",
                    "multiple": "0"
                },
                {
                    "zipcode": "46220",
                    "province": "Valencia",
                    "city": "Picassent",
                    "multiple": "1"
                },
                {
                    "zipcode": "46230",
                    "province": "Valencia",
                    "city": "Alginet",
                    "multiple": "0"
                },
                {
                    "zipcode": "46240",
                    "province": "Valencia",
                    "city": "Carlet",
                    "multiple": "0"
                },
                {
                    "zipcode": "46250",
                    "province": "Valencia",
                    "city": "Sagunto/Sagunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "46250",
                    "province": "Valencia",
                    "city": "Alcúdia, l'",
                    "multiple": "1"
                },
                {
                    "zipcode": "46260",
                    "province": "Valencia",
                    "city": "Alberic",
                    "multiple": "0"
                },
                {
                    "zipcode": "46266",
                    "province": "Valencia",
                    "city": "Antella",
                    "multiple": "0"
                },
                {
                    "zipcode": "46267",
                    "province": "Valencia",
                    "city": "Gavarda",
                    "multiple": "0"
                },
                {
                    "zipcode": "46268",
                    "province": "Valencia",
                    "city": "Alzira",
                    "multiple": "0"
                },
                {
                    "zipcode": "46269",
                    "province": "Valencia",
                    "city": "Tous",
                    "multiple": "0"
                },
                {
                    "zipcode": "46270",
                    "province": "Valencia",
                    "city": "Villanueva de Castellón",
                    "multiple": "0"
                },
                {
                    "zipcode": "46290",
                    "province": "Valencia",
                    "city": "Alcàsser",
                    "multiple": "0"
                },
                {
                    "zipcode": "46291",
                    "province": "Valencia",
                    "city": "Benimodo",
                    "multiple": "0"
                },
                {
                    "zipcode": "46292",
                    "province": "Valencia",
                    "city": "Massalavés",
                    "multiple": "0"
                },
                {
                    "zipcode": "46293",
                    "province": "Valencia",
                    "city": "Alcàntera de Xúquer",
                    "multiple": "0"
                },
                {
                    "zipcode": "46293",
                    "province": "Valencia",
                    "city": "Beneixida",
                    "multiple": "1"
                },
                {
                    "zipcode": "46294",
                    "province": "Valencia",
                    "city": "Càrcer",
                    "multiple": "0"
                },
                {
                    "zipcode": "46294",
                    "province": "Valencia",
                    "city": "Cotes",
                    "multiple": "1"
                },
                {
                    "zipcode": "46295",
                    "province": "Valencia",
                    "city": "Sellent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46295",
                    "province": "Valencia",
                    "city": "Sumacàrcer",
                    "multiple": "1"
                },
                {
                    "zipcode": "46300",
                    "province": "Valencia",
                    "city": "Utiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46310",
                    "province": "Valencia",
                    "city": "Venta del Moro",
                    "multiple": "0"
                },
                {
                    "zipcode": "46311",
                    "province": "Valencia",
                    "city": "Venta del Moro",
                    "multiple": "0"
                },
                {
                    "zipcode": "46312",
                    "province": "Valencia",
                    "city": "Utiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46313",
                    "province": "Valencia",
                    "city": "Utiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46314",
                    "province": "Valencia",
                    "city": "Fuenterrobles",
                    "multiple": "0"
                },
                {
                    "zipcode": "46315",
                    "province": "Valencia",
                    "city": "Caudete de las Fuentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "46317",
                    "province": "Valencia",
                    "city": "Villargordo del Cabriel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46318",
                    "province": "Valencia",
                    "city": "Villargordo del Cabriel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46320",
                    "province": "Valencia",
                    "city": "Sinarcas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46321",
                    "province": "Valencia",
                    "city": "Utiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46330",
                    "province": "Valencia",
                    "city": "Camporrobles",
                    "multiple": "0"
                },
                {
                    "zipcode": "46340",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46350",
                    "province": "Valencia",
                    "city": "Chera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46351",
                    "province": "Valencia",
                    "city": "Chelva",
                    "multiple": "0"
                },
                {
                    "zipcode": "46351",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "1"
                },
                {
                    "zipcode": "46352",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46353",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46354",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46355",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46356",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46357",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46360",
                    "province": "Valencia",
                    "city": "Buñol",
                    "multiple": "0"
                },
                {
                    "zipcode": "46367",
                    "province": "Valencia",
                    "city": "Yátova",
                    "multiple": "0"
                },
                {
                    "zipcode": "46368",
                    "province": "Valencia",
                    "city": "Macastre",
                    "multiple": "0"
                },
                {
                    "zipcode": "46369",
                    "province": "Valencia",
                    "city": "Alborache",
                    "multiple": "0"
                },
                {
                    "zipcode": "46370",
                    "province": "Valencia",
                    "city": "Chiva",
                    "multiple": "0"
                },
                {
                    "zipcode": "46380",
                    "province": "Valencia",
                    "city": "Cheste",
                    "multiple": "0"
                },
                {
                    "zipcode": "46388",
                    "province": "Valencia",
                    "city": "Godelleta",
                    "multiple": "0"
                },
                {
                    "zipcode": "46389",
                    "province": "Valencia",
                    "city": "Turís",
                    "multiple": "0"
                },
                {
                    "zipcode": "46390",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46391",
                    "province": "Valencia",
                    "city": "Requena",
                    "multiple": "0"
                },
                {
                    "zipcode": "46392",
                    "province": "Valencia",
                    "city": "Siete Aguas",
                    "multiple": "0"
                },
                {
                    "zipcode": "46393",
                    "province": "Valencia",
                    "city": "Loriguilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "46400",
                    "province": "Valencia",
                    "city": "Cullera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46408",
                    "province": "Valencia",
                    "city": "Cullera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46409",
                    "province": "Valencia",
                    "city": "Cullera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46410",
                    "province": "Valencia",
                    "city": "Sueca",
                    "multiple": "0"
                },
                {
                    "zipcode": "46417",
                    "province": "Valencia",
                    "city": "Riola",
                    "multiple": "0"
                },
                {
                    "zipcode": "46418",
                    "province": "Valencia",
                    "city": "Fortaleny",
                    "multiple": "0"
                },
                {
                    "zipcode": "46419",
                    "province": "Valencia",
                    "city": "Sueca",
                    "multiple": "0"
                },
                {
                    "zipcode": "46420",
                    "province": "Valencia",
                    "city": "Sueca",
                    "multiple": "0"
                },
                {
                    "zipcode": "46430",
                    "province": "Valencia",
                    "city": "Sollana",
                    "multiple": "0"
                },
                {
                    "zipcode": "46439",
                    "province": "Valencia",
                    "city": "Sollana",
                    "multiple": "0"
                },
                {
                    "zipcode": "46440",
                    "province": "Valencia",
                    "city": "Almussafes",
                    "multiple": "0"
                },
                {
                    "zipcode": "46450",
                    "province": "Valencia",
                    "city": "Puig de Santa Maria, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "46450",
                    "province": "Valencia",
                    "city": "Benifaió",
                    "multiple": "1"
                },
                {
                    "zipcode": "46460",
                    "province": "Valencia",
                    "city": "Silla",
                    "multiple": "0"
                },
                {
                    "zipcode": "46469",
                    "province": "Valencia",
                    "city": "Beniparrell",
                    "multiple": "0"
                },
                {
                    "zipcode": "46470",
                    "province": "Valencia",
                    "city": "Catarroja",
                    "multiple": "0"
                },
                {
                    "zipcode": "46470",
                    "province": "Valencia",
                    "city": "Albal",
                    "multiple": "1"
                },
                {
                    "zipcode": "46470",
                    "province": "Valencia",
                    "city": "Massanassa",
                    "multiple": "1"
                },
                {
                    "zipcode": "46500",
                    "province": "Valencia",
                    "city": "Sagunto/Sagunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "46501",
                    "province": "Valencia",
                    "city": "Petrés",
                    "multiple": "0"
                },
                {
                    "zipcode": "46510",
                    "province": "Valencia",
                    "city": "Quartell",
                    "multiple": "0"
                },
                {
                    "zipcode": "46511",
                    "province": "Valencia",
                    "city": "Benifairó de les Valls",
                    "multiple": "0"
                },
                {
                    "zipcode": "46512",
                    "province": "Valencia",
                    "city": "Faura",
                    "multiple": "0"
                },
                {
                    "zipcode": "46514",
                    "province": "Valencia",
                    "city": "Benavites",
                    "multiple": "0"
                },
                {
                    "zipcode": "46515",
                    "province": "Valencia",
                    "city": "Quart de les Valls",
                    "multiple": "0"
                },
                {
                    "zipcode": "46520",
                    "province": "Valencia",
                    "city": "Sagunto/Sagunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "46529",
                    "province": "Valencia",
                    "city": "Canet d'En Berenguer",
                    "multiple": "0"
                },
                {
                    "zipcode": "46530",
                    "province": "Valencia",
                    "city": "Sagunto/Sagunt",
                    "multiple": "0"
                },
                {
                    "zipcode": "46530",
                    "province": "Valencia",
                    "city": "Puçol",
                    "multiple": "1"
                },
                {
                    "zipcode": "46540",
                    "province": "Valencia",
                    "city": "Puig de Santa Maria, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "46550",
                    "province": "Valencia",
                    "city": "Albuixech",
                    "multiple": "0"
                },
                {
                    "zipcode": "46560",
                    "province": "Valencia",
                    "city": "Massalfassar",
                    "multiple": "0"
                },
                {
                    "zipcode": "46590",
                    "province": "Valencia",
                    "city": "Estivella",
                    "multiple": "0"
                },
                {
                    "zipcode": "46591",
                    "province": "Valencia",
                    "city": "Albalat dels Tarongers",
                    "multiple": "0"
                },
                {
                    "zipcode": "46592",
                    "province": "Valencia",
                    "city": "Segart",
                    "multiple": "0"
                },
                {
                    "zipcode": "46593",
                    "province": "Valencia",
                    "city": "Algar de Palancia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46594",
                    "province": "Valencia",
                    "city": "Alfara de la Baronia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46595",
                    "province": "Valencia",
                    "city": "Torres Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "46600",
                    "province": "Valencia",
                    "city": "Alzira",
                    "multiple": "0"
                },
                {
                    "zipcode": "46600",
                    "province": "Valencia",
                    "city": "Siete Aguas",
                    "multiple": "1"
                },
                {
                    "zipcode": "46610",
                    "province": "Valencia",
                    "city": "Guadassuar",
                    "multiple": "0"
                },
                {
                    "zipcode": "46611",
                    "province": "Valencia",
                    "city": "Benimuslem",
                    "multiple": "0"
                },
                {
                    "zipcode": "46612",
                    "province": "Valencia",
                    "city": "Corbera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46613",
                    "province": "Valencia",
                    "city": "Llaurí",
                    "multiple": "0"
                },
                {
                    "zipcode": "46614",
                    "province": "Valencia",
                    "city": "Favara",
                    "multiple": "0"
                },
                {
                    "zipcode": "46620",
                    "province": "Valencia",
                    "city": "Ayora",
                    "multiple": "0"
                },
                {
                    "zipcode": "46621",
                    "province": "Valencia",
                    "city": "Zarra",
                    "multiple": "0"
                },
                {
                    "zipcode": "46622",
                    "province": "Valencia",
                    "city": "Teresa de Cofrentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "46623",
                    "province": "Valencia",
                    "city": "Jarafuel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46624",
                    "province": "Valencia",
                    "city": "Jalance",
                    "multiple": "0"
                },
                {
                    "zipcode": "46625",
                    "province": "Valencia",
                    "city": "Cofrentes",
                    "multiple": "0"
                },
                {
                    "zipcode": "46630",
                    "province": "Valencia",
                    "city": "Font de la Figuera, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "46635",
                    "province": "Valencia",
                    "city": "Fontanars dels Alforins",
                    "multiple": "0"
                },
                {
                    "zipcode": "46640",
                    "province": "Valencia",
                    "city": "Mogente/Moixent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46650",
                    "province": "Valencia",
                    "city": "Canals",
                    "multiple": "0"
                },
                {
                    "zipcode": "46659",
                    "province": "Valencia",
                    "city": "Canals",
                    "multiple": "0"
                },
                {
                    "zipcode": "46660",
                    "province": "Valencia",
                    "city": "Manuel",
                    "multiple": "0"
                },
                {
                    "zipcode": "46661",
                    "province": "Valencia",
                    "city": "Ènova, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "46666",
                    "province": "Valencia",
                    "city": "Rafelguaraf",
                    "multiple": "0"
                },
                {
                    "zipcode": "46667",
                    "province": "Valencia",
                    "city": "Barxeta",
                    "multiple": "0"
                },
                {
                    "zipcode": "46668",
                    "province": "Valencia",
                    "city": "Xàtiva",
                    "multiple": "0"
                },
                {
                    "zipcode": "46668",
                    "province": "Valencia",
                    "city": "Llocnou d'En Fenollet",
                    "multiple": "1"
                },
                {
                    "zipcode": "46669",
                    "province": "Valencia",
                    "city": "Sant Joanet",
                    "multiple": "0"
                },
                {
                    "zipcode": "46669",
                    "province": "Valencia",
                    "city": "Senyera",
                    "multiple": "1"
                },
                {
                    "zipcode": "46670",
                    "province": "Valencia",
                    "city": "Pobla Llarga, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "46680",
                    "province": "Valencia",
                    "city": "Algemesí",
                    "multiple": "0"
                },
                {
                    "zipcode": "46687",
                    "province": "Valencia",
                    "city": "Albalat de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46688",
                    "province": "Valencia",
                    "city": "Polinyà de Xúquer",
                    "multiple": "0"
                },
                {
                    "zipcode": "46689",
                    "province": "Valencia",
                    "city": "Benicull de Xúquer",
                    "multiple": "0"
                },
                {
                    "zipcode": "46690",
                    "province": "Valencia",
                    "city": "Alcúdia de Crespins, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "46691",
                    "province": "Valencia",
                    "city": "Vallada",
                    "multiple": "0"
                },
                {
                    "zipcode": "46692",
                    "province": "Valencia",
                    "city": "Montesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "46701",
                    "province": "Valencia",
                    "city": "Gandia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46702",
                    "province": "Valencia",
                    "city": "Gandia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46703",
                    "province": "Valencia",
                    "city": "Benirredrà",
                    "multiple": "0"
                },
                {
                    "zipcode": "46710",
                    "province": "Valencia",
                    "city": "Daimús",
                    "multiple": "0"
                },
                {
                    "zipcode": "46711",
                    "province": "Valencia",
                    "city": "Miramar",
                    "multiple": "0"
                },
                {
                    "zipcode": "46711",
                    "province": "Valencia",
                    "city": "Guardamar de la Safor",
                    "multiple": "1"
                },
                {
                    "zipcode": "46712",
                    "province": "Valencia",
                    "city": "Piles",
                    "multiple": "0"
                },
                {
                    "zipcode": "46713",
                    "province": "Valencia",
                    "city": "Bellreguard",
                    "multiple": "0"
                },
                {
                    "zipcode": "46714",
                    "province": "Valencia",
                    "city": "Palmera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46715",
                    "province": "Valencia",
                    "city": "Alqueria de la Comtessa, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "46716",
                    "province": "Valencia",
                    "city": "Rafelcofer",
                    "multiple": "0"
                },
                {
                    "zipcode": "46717",
                    "province": "Valencia",
                    "city": "Casinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "46717",
                    "province": "Valencia",
                    "city": "Font d'En Carròs, la",
                    "multiple": "1"
                },
                {
                    "zipcode": "46720",
                    "province": "Valencia",
                    "city": "Vilallonga/Villalonga",
                    "multiple": "0"
                },
                {
                    "zipcode": "46721",
                    "province": "Valencia",
                    "city": "Potries",
                    "multiple": "0"
                },
                {
                    "zipcode": "46722",
                    "province": "Valencia",
                    "city": "Beniflá",
                    "multiple": "0"
                },
                {
                    "zipcode": "46722",
                    "province": "Valencia",
                    "city": "Beniarjó",
                    "multiple": "1"
                },
                {
                    "zipcode": "46723",
                    "province": "Valencia",
                    "city": "Almoines",
                    "multiple": "0"
                },
                {
                    "zipcode": "46724",
                    "province": "Valencia",
                    "city": "Palma de Gandía",
                    "multiple": "0"
                },
                {
                    "zipcode": "46725",
                    "province": "Valencia",
                    "city": "Alfauir",
                    "multiple": "0"
                },
                {
                    "zipcode": "46725",
                    "province": "Valencia",
                    "city": "Rótova",
                    "multiple": "1"
                },
                {
                    "zipcode": "46726",
                    "province": "Valencia",
                    "city": "Castellonet de la Conquesta",
                    "multiple": "0"
                },
                {
                    "zipcode": "46726",
                    "province": "Valencia",
                    "city": "Llocnou de Sant Jeroni",
                    "multiple": "1"
                },
                {
                    "zipcode": "46726",
                    "province": "Valencia",
                    "city": "Almiserà",
                    "multiple": "1"
                },
                {
                    "zipcode": "46727",
                    "province": "Valencia",
                    "city": "Real de Gandía",
                    "multiple": "0"
                },
                {
                    "zipcode": "46728",
                    "province": "Valencia",
                    "city": "Gandia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46729",
                    "province": "Valencia",
                    "city": "Ador",
                    "multiple": "0"
                },
                {
                    "zipcode": "46730",
                    "province": "Valencia",
                    "city": "Gandia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46740",
                    "province": "Valencia",
                    "city": "Carcaixent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46749",
                    "province": "Valencia",
                    "city": "Carcaixent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46750",
                    "province": "Valencia",
                    "city": "Simat de la Valldigna",
                    "multiple": "0"
                },
                {
                    "zipcode": "46758",
                    "province": "Valencia",
                    "city": "Barx",
                    "multiple": "0"
                },
                {
                    "zipcode": "46760",
                    "province": "Valencia",
                    "city": "Tavernes de la Valldigna",
                    "multiple": "0"
                },
                {
                    "zipcode": "46760",
                    "province": "Valencia",
                    "city": "Gandia",
                    "multiple": "1"
                },
                {
                    "zipcode": "46770",
                    "province": "Valencia",
                    "city": "Xeraco",
                    "multiple": "0"
                },
                {
                    "zipcode": "46780",
                    "province": "Valencia",
                    "city": "Oliva",
                    "multiple": "0"
                },
                {
                    "zipcode": "46790",
                    "province": "Valencia",
                    "city": "Xeresa",
                    "multiple": "0"
                },
                {
                    "zipcode": "46791",
                    "province": "Valencia",
                    "city": "Benifairó de la Valldigna",
                    "multiple": "0"
                },
                {
                    "zipcode": "46792",
                    "province": "Valencia",
                    "city": "Alzira",
                    "multiple": "0"
                },
                {
                    "zipcode": "46792",
                    "province": "Valencia",
                    "city": "Carcaixent",
                    "multiple": "1"
                },
                {
                    "zipcode": "46800",
                    "province": "Valencia",
                    "city": "Xàtiva",
                    "multiple": "0"
                },
                {
                    "zipcode": "46810",
                    "province": "Valencia",
                    "city": "Enguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46811",
                    "province": "Valencia",
                    "city": "Enguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "46812",
                    "province": "Valencia",
                    "city": "Aielo de Malferit",
                    "multiple": "0"
                },
                {
                    "zipcode": "46813",
                    "province": "Valencia",
                    "city": "Canals",
                    "multiple": "0"
                },
                {
                    "zipcode": "46813",
                    "province": "Valencia",
                    "city": "Cerdà",
                    "multiple": "1"
                },
                {
                    "zipcode": "46814",
                    "province": "Valencia",
                    "city": "Torrella",
                    "multiple": "0"
                },
                {
                    "zipcode": "46814",
                    "province": "Valencia",
                    "city": "Llanera de Ranes",
                    "multiple": "1"
                },
                {
                    "zipcode": "46814",
                    "province": "Valencia",
                    "city": "Granja de la Costera, la",
                    "multiple": "1"
                },
                {
                    "zipcode": "46815",
                    "province": "Valencia",
                    "city": "Llosa de Ranes, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "46816",
                    "province": "Valencia",
                    "city": "Rotglà i Corberà",
                    "multiple": "0"
                },
                {
                    "zipcode": "46817",
                    "province": "Valencia",
                    "city": "Estubeny",
                    "multiple": "0"
                },
                {
                    "zipcode": "46818",
                    "province": "Valencia",
                    "city": "Vallés",
                    "multiple": "0"
                },
                {
                    "zipcode": "46819",
                    "province": "Valencia",
                    "city": "Novelé/Novetlè",
                    "multiple": "0"
                },
                {
                    "zipcode": "46820",
                    "province": "Valencia",
                    "city": "Anna",
                    "multiple": "0"
                },
                {
                    "zipcode": "46821",
                    "province": "Valencia",
                    "city": "Chella",
                    "multiple": "0"
                },
                {
                    "zipcode": "46822",
                    "province": "Valencia",
                    "city": "Bolbaite",
                    "multiple": "0"
                },
                {
                    "zipcode": "46823",
                    "province": "Valencia",
                    "city": "Navarrés",
                    "multiple": "0"
                },
                {
                    "zipcode": "46824",
                    "province": "Valencia",
                    "city": "Quesa",
                    "multiple": "0"
                },
                {
                    "zipcode": "46825",
                    "province": "Valencia",
                    "city": "Bicorp",
                    "multiple": "0"
                },
                {
                    "zipcode": "46830",
                    "province": "Valencia",
                    "city": "Benigànim",
                    "multiple": "0"
                },
                {
                    "zipcode": "46837",
                    "province": "Valencia",
                    "city": "Quatretonda",
                    "multiple": "0"
                },
                {
                    "zipcode": "46838",
                    "province": "Valencia",
                    "city": "Pinet",
                    "multiple": "0"
                },
                {
                    "zipcode": "46838",
                    "province": "Valencia",
                    "city": "Benicolet",
                    "multiple": "1"
                },
                {
                    "zipcode": "46838",
                    "province": "Valencia",
                    "city": "Llutxent",
                    "multiple": "1"
                },
                {
                    "zipcode": "46839",
                    "province": "Valencia",
                    "city": "Bellús",
                    "multiple": "0"
                },
                {
                    "zipcode": "46839",
                    "province": "Valencia",
                    "city": "Sempere",
                    "multiple": "1"
                },
                {
                    "zipcode": "46839",
                    "province": "Valencia",
                    "city": "Benisuera",
                    "multiple": "1"
                },
                {
                    "zipcode": "46839",
                    "province": "Valencia",
                    "city": "Guadasséquies",
                    "multiple": "1"
                },
                {
                    "zipcode": "46840",
                    "province": "Valencia",
                    "city": "Pobla del Duc, la",
                    "multiple": "0"
                },
                {
                    "zipcode": "46841",
                    "province": "Valencia",
                    "city": "Castelló de Rugat",
                    "multiple": "0"
                },
                {
                    "zipcode": "46842",
                    "province": "Valencia",
                    "city": "Rugat",
                    "multiple": "0"
                },
                {
                    "zipcode": "46842",
                    "province": "Valencia",
                    "city": "Aielo de Rugat",
                    "multiple": "1"
                },
                {
                    "zipcode": "46842",
                    "province": "Valencia",
                    "city": "Terrateig",
                    "multiple": "1"
                },
                {
                    "zipcode": "46842",
                    "province": "Valencia",
                    "city": "Sot de Chera",
                    "multiple": "1"
                },
                {
                    "zipcode": "46842",
                    "province": "Valencia",
                    "city": "Montitxelvo/Montichelvo",
                    "multiple": "1"
                },
                {
                    "zipcode": "46843",
                    "province": "Valencia",
                    "city": "Salem",
                    "multiple": "0"
                },
                {
                    "zipcode": "46843",
                    "province": "Valencia",
                    "city": "Ráfol de Salem",
                    "multiple": "1"
                },
                {
                    "zipcode": "46844",
                    "province": "Valencia",
                    "city": "Otos",
                    "multiple": "0"
                },
                {
                    "zipcode": "46844",
                    "province": "Valencia",
                    "city": "Beniatjar",
                    "multiple": "1"
                },
                {
                    "zipcode": "46850",
                    "province": "Valencia",
                    "city": "Olleria, l'",
                    "multiple": "0"
                },
                {
                    "zipcode": "46860",
                    "province": "Valencia",
                    "city": "Albaida",
                    "multiple": "0"
                },
                {
                    "zipcode": "46868",
                    "province": "Valencia",
                    "city": "Bèlgida",
                    "multiple": "0"
                },
                {
                    "zipcode": "46869",
                    "province": "Valencia",
                    "city": "Benissoda",
                    "multiple": "0"
                },
                {
                    "zipcode": "46869",
                    "province": "Valencia",
                    "city": "Carrícola",
                    "multiple": "1"
                },
                {
                    "zipcode": "46869",
                    "province": "Valencia",
                    "city": "Atzeneta d'Albaida",
                    "multiple": "1"
                },
                {
                    "zipcode": "46870",
                    "province": "Valencia",
                    "city": "Ontinyent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46880",
                    "province": "Valencia",
                    "city": "Bocairent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46890",
                    "province": "Valencia",
                    "city": "Agullent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46891",
                    "province": "Valencia",
                    "city": "Palomar, el",
                    "multiple": "0"
                },
                {
                    "zipcode": "46891",
                    "province": "Valencia",
                    "city": "Bufali",
                    "multiple": "1"
                },
                {
                    "zipcode": "46892",
                    "province": "Valencia",
                    "city": "Montaverner",
                    "multiple": "0"
                },
                {
                    "zipcode": "46893",
                    "province": "Valencia",
                    "city": "Alfarrasí",
                    "multiple": "0"
                },
                {
                    "zipcode": "46894",
                    "province": "Valencia",
                    "city": "Genovés",
                    "multiple": "0"
                },
                {
                    "zipcode": "46900",
                    "province": "Valencia",
                    "city": "Torrent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46901",
                    "province": "Valencia",
                    "city": "Torrent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46909",
                    "province": "Valencia",
                    "city": "Torrent",
                    "multiple": "0"
                },
                {
                    "zipcode": "46910",
                    "province": "Valencia",
                    "city": "Benetússer",
                    "multiple": "0"
                },
                {
                    "zipcode": "46910",
                    "province": "Valencia",
                    "city": "Alfafar",
                    "multiple": "1"
                },
                {
                    "zipcode": "46910",
                    "province": "Valencia",
                    "city": "Llocnou de la Corona",
                    "multiple": "1"
                },
                {
                    "zipcode": "46910",
                    "province": "Valencia",
                    "city": "Sedaví",
                    "multiple": "1"
                },
                {
                    "zipcode": "46920",
                    "province": "Valencia",
                    "city": "Mislata",
                    "multiple": "0"
                },
                {
                    "zipcode": "46930",
                    "province": "Valencia",
                    "city": "Quart de Poblet",
                    "multiple": "0"
                },
                {
                    "zipcode": "46940",
                    "province": "Valencia",
                    "city": "Manises",
                    "multiple": "0"
                },
                {
                    "zipcode": "46950",
                    "province": "Valencia",
                    "city": "Xirivella",
                    "multiple": "0"
                },
                {
                    "zipcode": "46960",
                    "province": "Valencia",
                    "city": "Aldaia",
                    "multiple": "0"
                },
                {
                    "zipcode": "46970",
                    "province": "Valencia",
                    "city": "Alaquàs",
                    "multiple": "0"
                },
                {
                    "zipcode": "46980",
                    "province": "Valencia",
                    "city": "Paterna",
                    "multiple": "0"
                },
                {
                    "zipcode": "46988",
                    "province": "Valencia",
                    "city": "Paterna",
                    "multiple": "0"
                },
                {
                    "zipcode": "46989",
                    "province": "Valencia",
                    "city": "Paterna",
                    "multiple": "0"
                },
                {
                    "zipcode": "47001",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47002",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47003",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47004",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47005",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47006",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47007",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47008",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47009",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47010",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47011",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47012",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47013",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47014",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47015",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47016",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47017",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47018",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47100",
                    "province": "Valladolid",
                    "city": "Tordesillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47110",
                    "province": "Valladolid",
                    "city": "Casasola de Arión",
                    "multiple": "0"
                },
                {
                    "zipcode": "47111",
                    "province": "Valladolid",
                    "city": "Villalar de los Comuneros",
                    "multiple": "0"
                },
                {
                    "zipcode": "47112",
                    "province": "Valladolid",
                    "city": "Pedrosa del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "47113",
                    "province": "Valladolid",
                    "city": "Tordesillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47113",
                    "province": "Valladolid",
                    "city": "Villalbarba",
                    "multiple": "1"
                },
                {
                    "zipcode": "47114",
                    "province": "Valladolid",
                    "city": "Velilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "47114",
                    "province": "Valladolid",
                    "city": "Torrecilla de la Abadesa",
                    "multiple": "1"
                },
                {
                    "zipcode": "47114",
                    "province": "Valladolid",
                    "city": "Matilla de los Caños",
                    "multiple": "1"
                },
                {
                    "zipcode": "47115",
                    "province": "Valladolid",
                    "city": "Bercero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47115",
                    "province": "Valladolid",
                    "city": "Berceruelo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47116",
                    "province": "Valladolid",
                    "city": "Pollos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47120",
                    "province": "Valladolid",
                    "city": "Mota del Marqués",
                    "multiple": "0"
                },
                {
                    "zipcode": "47129",
                    "province": "Valladolid",
                    "city": "Barruelo del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "47129",
                    "province": "Valladolid",
                    "city": "Torrecilla de la Torre",
                    "multiple": "1"
                },
                {
                    "zipcode": "47129",
                    "province": "Valladolid",
                    "city": "San Cebrián de Mazote",
                    "multiple": "1"
                },
                {
                    "zipcode": "47129",
                    "province": "Valladolid",
                    "city": "Adalia",
                    "multiple": "1"
                },
                {
                    "zipcode": "47129",
                    "province": "Valladolid",
                    "city": "San Pelayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47130",
                    "province": "Valladolid",
                    "city": "Simancas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47131",
                    "province": "Valladolid",
                    "city": "Villán de Tordesillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47131",
                    "province": "Valladolid",
                    "city": "Geria",
                    "multiple": "1"
                },
                {
                    "zipcode": "47131",
                    "province": "Valladolid",
                    "city": "Robladillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47131",
                    "province": "Valladolid",
                    "city": "Velliza",
                    "multiple": "1"
                },
                {
                    "zipcode": "47132",
                    "province": "Valladolid",
                    "city": "Tordesillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47132",
                    "province": "Valladolid",
                    "city": "San Miguel del Pino",
                    "multiple": "1"
                },
                {
                    "zipcode": "47133",
                    "province": "Valladolid",
                    "city": "Marzales",
                    "multiple": "0"
                },
                {
                    "zipcode": "47133",
                    "province": "Valladolid",
                    "city": "Vega de Valdetronco",
                    "multiple": "1"
                },
                {
                    "zipcode": "47134",
                    "province": "Valladolid",
                    "city": "Villasexmir",
                    "multiple": "0"
                },
                {
                    "zipcode": "47134",
                    "province": "Valladolid",
                    "city": "Gallegos de Hornija",
                    "multiple": "1"
                },
                {
                    "zipcode": "47134",
                    "province": "Valladolid",
                    "city": "San Salvador",
                    "multiple": "1"
                },
                {
                    "zipcode": "47134",
                    "province": "Valladolid",
                    "city": "Torrelobatón",
                    "multiple": "1"
                },
                {
                    "zipcode": "47140",
                    "province": "Valladolid",
                    "city": "Laguna de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47140",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "1"
                },
                {
                    "zipcode": "47150",
                    "province": "Valladolid",
                    "city": "Viana de Cega",
                    "multiple": "0"
                },
                {
                    "zipcode": "47151",
                    "province": "Valladolid",
                    "city": "Boecillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47152",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47153",
                    "province": "Valladolid",
                    "city": "Laguna de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47153",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "1"
                },
                {
                    "zipcode": "47155",
                    "province": "Valladolid",
                    "city": "Santovenia de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "47160",
                    "province": "Valladolid",
                    "city": "Portillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47160",
                    "province": "Valladolid",
                    "city": "Aldea de San Miguel",
                    "multiple": "1"
                },
                {
                    "zipcode": "47161",
                    "province": "Valladolid",
                    "city": "Tudela de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47162",
                    "province": "Valladolid",
                    "city": "Aldeamayor de San Martín",
                    "multiple": "0"
                },
                {
                    "zipcode": "47164",
                    "province": "Valladolid",
                    "city": "San Miguel del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47165",
                    "province": "Valladolid",
                    "city": "Camporredondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47166",
                    "province": "Valladolid",
                    "city": "Viloria",
                    "multiple": "0"
                },
                {
                    "zipcode": "47169",
                    "province": "Valladolid",
                    "city": "Laguna de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47169",
                    "province": "Valladolid",
                    "city": "Aldeamayor de San Martín",
                    "multiple": "1"
                },
                {
                    "zipcode": "47169",
                    "province": "Valladolid",
                    "city": "Tudela de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "47170",
                    "province": "Valladolid",
                    "city": "Renedo de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47171",
                    "province": "Valladolid",
                    "city": "Castronuevo de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47172",
                    "province": "Valladolid",
                    "city": "Villarmentero de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47173",
                    "province": "Valladolid",
                    "city": "Olmos de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47174",
                    "province": "Valladolid",
                    "city": "Villanueva de los Infantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "47175",
                    "province": "Valladolid",
                    "city": "Piña de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47176",
                    "province": "Valladolid",
                    "city": "Esguevillas de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47177",
                    "province": "Valladolid",
                    "city": "Amusquillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47180",
                    "province": "Valladolid",
                    "city": "Villafuerte",
                    "multiple": "0"
                },
                {
                    "zipcode": "47181",
                    "province": "Valladolid",
                    "city": "Villaco",
                    "multiple": "0"
                },
                {
                    "zipcode": "47182",
                    "province": "Valladolid",
                    "city": "Castroverde de Cerrato",
                    "multiple": "0"
                },
                {
                    "zipcode": "47183",
                    "province": "Valladolid",
                    "city": "Torre de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47184",
                    "province": "Valladolid",
                    "city": "Fombellida",
                    "multiple": "0"
                },
                {
                    "zipcode": "47185",
                    "province": "Valladolid",
                    "city": "Canillas de Esgueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47186",
                    "province": "Valladolid",
                    "city": "Montealegre de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47186",
                    "province": "Valladolid",
                    "city": "Encinas de Esgueva",
                    "multiple": "1"
                },
                {
                    "zipcode": "47190",
                    "province": "Valladolid",
                    "city": "Wamba",
                    "multiple": "0"
                },
                {
                    "zipcode": "47191",
                    "province": "Valladolid",
                    "city": "Ciguñuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "47192",
                    "province": "Valladolid",
                    "city": "Castrodeza",
                    "multiple": "0"
                },
                {
                    "zipcode": "47193",
                    "province": "Valladolid",
                    "city": "Cistérniga",
                    "multiple": "0"
                },
                {
                    "zipcode": "47194",
                    "province": "Valladolid",
                    "city": "Fuensaldaña",
                    "multiple": "0"
                },
                {
                    "zipcode": "47194",
                    "province": "Valladolid",
                    "city": "Mucientes",
                    "multiple": "1"
                },
                {
                    "zipcode": "47195",
                    "province": "Valladolid",
                    "city": "Arroyo de la Encomienda",
                    "multiple": "0"
                },
                {
                    "zipcode": "47196",
                    "province": "Valladolid",
                    "city": "Pedraja de Portillo, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "47197",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47198",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47200",
                    "province": "Valladolid",
                    "city": "Valoria la Buena",
                    "multiple": "0"
                },
                {
                    "zipcode": "47209",
                    "province": "Valladolid",
                    "city": "San Martín de Valvení",
                    "multiple": "0"
                },
                {
                    "zipcode": "47210",
                    "province": "Valladolid",
                    "city": "Ataquines",
                    "multiple": "0"
                },
                {
                    "zipcode": "47219",
                    "province": "Valladolid",
                    "city": "Salvador de Zapardiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "47219",
                    "province": "Valladolid",
                    "city": "Muriel",
                    "multiple": "1"
                },
                {
                    "zipcode": "47219",
                    "province": "Valladolid",
                    "city": "San Pablo de la Moraleja",
                    "multiple": "1"
                },
                {
                    "zipcode": "47220",
                    "province": "Valladolid",
                    "city": "Pozaldez",
                    "multiple": "0"
                },
                {
                    "zipcode": "47230",
                    "province": "Valladolid",
                    "city": "Matapozuelos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47231",
                    "province": "Valladolid",
                    "city": "Serrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "47238",
                    "province": "Valladolid",
                    "city": "Hornillos de Eresma",
                    "multiple": "0"
                },
                {
                    "zipcode": "47238",
                    "province": "Valladolid",
                    "city": "Alcazarén",
                    "multiple": "1"
                },
                {
                    "zipcode": "47238",
                    "province": "Valladolid",
                    "city": "Matapozuelos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47239",
                    "province": "Valladolid",
                    "city": "Serrada",
                    "multiple": "0"
                },
                {
                    "zipcode": "47239",
                    "province": "Valladolid",
                    "city": "Ventosa de la Cuesta",
                    "multiple": "1"
                },
                {
                    "zipcode": "47239",
                    "province": "Valladolid",
                    "city": "Villanueva de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "47240",
                    "province": "Valladolid",
                    "city": "Valdestillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47250",
                    "province": "Valladolid",
                    "city": "Mojados",
                    "multiple": "0"
                },
                {
                    "zipcode": "47260",
                    "province": "Valladolid",
                    "city": "Cabezón de Pisuerga",
                    "multiple": "0"
                },
                {
                    "zipcode": "47270",
                    "province": "Valladolid",
                    "city": "Cigales",
                    "multiple": "0"
                },
                {
                    "zipcode": "47280",
                    "province": "Valladolid",
                    "city": "Corcos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47281",
                    "province": "Valladolid",
                    "city": "Corcos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47282",
                    "province": "Valladolid",
                    "city": "Trigueros del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "47283",
                    "province": "Valladolid",
                    "city": "Quintanilla de Trigueros",
                    "multiple": "0"
                },
                {
                    "zipcode": "47290",
                    "province": "Valladolid",
                    "city": "Cubillas de Santa Marta",
                    "multiple": "0"
                },
                {
                    "zipcode": "47300",
                    "province": "Valladolid",
                    "city": "Peñafiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "47310",
                    "province": "Valladolid",
                    "city": "Campaspero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47311",
                    "province": "Valladolid",
                    "city": "Canalejas de Peñafiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "47311",
                    "province": "Valladolid",
                    "city": "Fompedraza",
                    "multiple": "1"
                },
                {
                    "zipcode": "47312",
                    "province": "Valladolid",
                    "city": "Bahabón",
                    "multiple": "0"
                },
                {
                    "zipcode": "47313",
                    "province": "Valladolid",
                    "city": "Torre de Peñafiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "47313",
                    "province": "Valladolid",
                    "city": "Cogeces del Monte",
                    "multiple": "1"
                },
                {
                    "zipcode": "47313",
                    "province": "Valladolid",
                    "city": "Torrescárcela",
                    "multiple": "1"
                },
                {
                    "zipcode": "47313",
                    "province": "Valladolid",
                    "city": "Peñafiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "47314",
                    "province": "Valladolid",
                    "city": "Langayo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47314",
                    "province": "Valladolid",
                    "city": "Manzanillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47314",
                    "province": "Valladolid",
                    "city": "Peñafiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "47315",
                    "province": "Valladolid",
                    "city": "Pesquera de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47316",
                    "province": "Valladolid",
                    "city": "Roturas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47316",
                    "province": "Valladolid",
                    "city": "Piñel de Arriba",
                    "multiple": "1"
                },
                {
                    "zipcode": "47316",
                    "province": "Valladolid",
                    "city": "Piñel de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47316",
                    "province": "Valladolid",
                    "city": "Curiel de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "47317",
                    "province": "Valladolid",
                    "city": "Bocos de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47317",
                    "province": "Valladolid",
                    "city": "Corrales de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "47317",
                    "province": "Valladolid",
                    "city": "San Llorente",
                    "multiple": "1"
                },
                {
                    "zipcode": "47317",
                    "province": "Valladolid",
                    "city": "Valdearcos de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "47318",
                    "province": "Valladolid",
                    "city": "Olmos de Peñafiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "47318",
                    "province": "Valladolid",
                    "city": "Castrillo de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "47318",
                    "province": "Valladolid",
                    "city": "Peñafiel",
                    "multiple": "1"
                },
                {
                    "zipcode": "47319",
                    "province": "Valladolid",
                    "city": "Torre de Peñafiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "47319",
                    "province": "Valladolid",
                    "city": "Rábano",
                    "multiple": "1"
                },
                {
                    "zipcode": "47320",
                    "province": "Valladolid",
                    "city": "Tudela de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47320",
                    "province": "Valladolid",
                    "city": "Montemayor de Pililla",
                    "multiple": "1"
                },
                {
                    "zipcode": "47328",
                    "province": "Valladolid",
                    "city": "Parrilla, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "47329",
                    "province": "Valladolid",
                    "city": "Villabáñez",
                    "multiple": "0"
                },
                {
                    "zipcode": "47329",
                    "province": "Valladolid",
                    "city": "Castrillo-Tejeriego",
                    "multiple": "1"
                },
                {
                    "zipcode": "47329",
                    "province": "Valladolid",
                    "city": "Villavaquerín",
                    "multiple": "1"
                },
                {
                    "zipcode": "47330",
                    "province": "Valladolid",
                    "city": "Santibáñez de Valcorba",
                    "multiple": "0"
                },
                {
                    "zipcode": "47330",
                    "province": "Valladolid",
                    "city": "Traspinedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47331",
                    "province": "Valladolid",
                    "city": "Santibáñez de Valcorba",
                    "multiple": "0"
                },
                {
                    "zipcode": "47340",
                    "province": "Valladolid",
                    "city": "Sardón de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47350",
                    "province": "Valladolid",
                    "city": "Quintanilla de Onésimo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47359",
                    "province": "Valladolid",
                    "city": "Valbuena de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47359",
                    "province": "Valladolid",
                    "city": "Olivares de Duero",
                    "multiple": "1"
                },
                {
                    "zipcode": "47360",
                    "province": "Valladolid",
                    "city": "Quintanilla de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "47400",
                    "province": "Valladolid",
                    "city": "Medina del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47410",
                    "province": "Valladolid",
                    "city": "Olmedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47418",
                    "province": "Valladolid",
                    "city": "Aguasal",
                    "multiple": "0"
                },
                {
                    "zipcode": "47418",
                    "province": "Valladolid",
                    "city": "Fuente-Olmedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47418",
                    "province": "Valladolid",
                    "city": "Llano de Olmedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47419",
                    "province": "Valladolid",
                    "city": "Bocigas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47419",
                    "province": "Valladolid",
                    "city": "Almenara de Adaja",
                    "multiple": "1"
                },
                {
                    "zipcode": "47419",
                    "province": "Valladolid",
                    "city": "Puras",
                    "multiple": "1"
                },
                {
                    "zipcode": "47420",
                    "province": "Valladolid",
                    "city": "Íscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "47428",
                    "province": "Salamanca",
                    "city": "Pajares de la Laguna",
                    "multiple": "0"
                },
                {
                    "zipcode": "47430",
                    "province": "Valladolid",
                    "city": "Pedrajas de San Esteban",
                    "multiple": "0"
                },
                {
                    "zipcode": "47440",
                    "province": "Valladolid",
                    "city": "Cogeces de Íscar",
                    "multiple": "0"
                },
                {
                    "zipcode": "47440",
                    "province": "Valladolid",
                    "city": "Megeces",
                    "multiple": "1"
                },
                {
                    "zipcode": "47450",
                    "province": "Valladolid",
                    "city": "Pozal de Gallinas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47451",
                    "province": "Valladolid",
                    "city": "Olmedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47452",
                    "province": "Valladolid",
                    "city": "Zarza, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "47453",
                    "province": "Valladolid",
                    "city": "Ramiro",
                    "multiple": "0"
                },
                {
                    "zipcode": "47454",
                    "province": "Valladolid",
                    "city": "Moraleja de las Panaderas",
                    "multiple": "0"
                },
                {
                    "zipcode": "47460",
                    "province": "Valladolid",
                    "city": "Campillo, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "47461",
                    "province": "Valladolid",
                    "city": "Brahojos de Medina",
                    "multiple": "0"
                },
                {
                    "zipcode": "47462",
                    "province": "Valladolid",
                    "city": "Bobadilla del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47463",
                    "province": "Valladolid",
                    "city": "Velascálvaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "47464",
                    "province": "Valladolid",
                    "city": "Nueva Villa de las Torres",
                    "multiple": "0"
                },
                {
                    "zipcode": "47465",
                    "province": "Valladolid",
                    "city": "Villaverde de Medina",
                    "multiple": "0"
                },
                {
                    "zipcode": "47470",
                    "province": "Valladolid",
                    "city": "Carpio",
                    "multiple": "0"
                },
                {
                    "zipcode": "47480",
                    "province": "Valladolid",
                    "city": "Fresno el Viejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47490",
                    "province": "Valladolid",
                    "city": "Rueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "47491",
                    "province": "Valladolid",
                    "city": "Seca, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "47492",
                    "province": "Valladolid",
                    "city": "Rueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "47492",
                    "province": "Valladolid",
                    "city": "Medina del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47493",
                    "province": "Valladolid",
                    "city": "San Vicente del Palacio",
                    "multiple": "0"
                },
                {
                    "zipcode": "47493",
                    "province": "Valladolid",
                    "city": "Medina del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47494",
                    "province": "Valladolid",
                    "city": "Cervillego de la Cruz",
                    "multiple": "0"
                },
                {
                    "zipcode": "47494",
                    "province": "Valladolid",
                    "city": "Lomoviejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "47494",
                    "province": "Valladolid",
                    "city": "Rubí de Bracamonte",
                    "multiple": "1"
                },
                {
                    "zipcode": "47494",
                    "province": "Valladolid",
                    "city": "Fuente el Sol",
                    "multiple": "1"
                },
                {
                    "zipcode": "47500",
                    "province": "Valladolid",
                    "city": "Nava del Rey",
                    "multiple": "0"
                },
                {
                    "zipcode": "47509",
                    "province": "Valladolid",
                    "city": "Rueda",
                    "multiple": "0"
                },
                {
                    "zipcode": "47510",
                    "province": "Valladolid",
                    "city": "Alaejos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47511",
                    "province": "Valladolid",
                    "city": "Siete Iglesias de Trabancos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47512",
                    "province": "Valladolid",
                    "city": "Castrejón de Trabancos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47513",
                    "province": "Valladolid",
                    "city": "Torrecilla de la Orden",
                    "multiple": "0"
                },
                {
                    "zipcode": "47520",
                    "province": "Valladolid",
                    "city": "Castronuño",
                    "multiple": "0"
                },
                {
                    "zipcode": "47529",
                    "province": "Valladolid",
                    "city": "Villafranca de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "47530",
                    "province": "Valladolid",
                    "city": "San Román de Hornija",
                    "multiple": "0"
                },
                {
                    "zipcode": "47600",
                    "province": "Valladolid",
                    "city": "Villalón de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47606",
                    "province": "Valladolid",
                    "city": "Gatón de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47606",
                    "province": "Valladolid",
                    "city": "Villafrades de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47607",
                    "province": "Valladolid",
                    "city": "Villacid de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47607",
                    "province": "Valladolid",
                    "city": "Herrín de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47608",
                    "province": "Valladolid",
                    "city": "Villagómez la Nueva",
                    "multiple": "0"
                },
                {
                    "zipcode": "47608",
                    "province": "Valladolid",
                    "city": "Bustillo de Chaves",
                    "multiple": "1"
                },
                {
                    "zipcode": "47608",
                    "province": "Valladolid",
                    "city": "Villanueva de la Condesa",
                    "multiple": "1"
                },
                {
                    "zipcode": "47609",
                    "province": "Valladolid",
                    "city": "Fontihoyuelo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47609",
                    "province": "Valladolid",
                    "city": "Santervás de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47609",
                    "province": "Valladolid",
                    "city": "Villacarralón",
                    "multiple": "1"
                },
                {
                    "zipcode": "47609",
                    "province": "Valladolid",
                    "city": "Vega de Ruiponce",
                    "multiple": "1"
                },
                {
                    "zipcode": "47610",
                    "province": "Valladolid",
                    "city": "Zaratán",
                    "multiple": "0"
                },
                {
                    "zipcode": "47620",
                    "province": "Valladolid",
                    "city": "Villanubla",
                    "multiple": "0"
                },
                {
                    "zipcode": "47630",
                    "province": "Valladolid",
                    "city": "Mudarra, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "47639",
                    "province": "Valladolid",
                    "city": "Villalba de los Alcores",
                    "multiple": "0"
                },
                {
                    "zipcode": "47640",
                    "province": "Valladolid",
                    "city": "Peñaflor de Hornija",
                    "multiple": "0"
                },
                {
                    "zipcode": "47641",
                    "province": "Valladolid",
                    "city": "Castromonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "47650",
                    "province": "Valladolid",
                    "city": "Cuenca de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47664",
                    "province": "Valladolid",
                    "city": "Castroponce",
                    "multiple": "0"
                },
                {
                    "zipcode": "47670",
                    "province": "Valladolid",
                    "city": "Becilla de Valderaduey",
                    "multiple": "0"
                },
                {
                    "zipcode": "47670",
                    "province": "Valladolid",
                    "city": "Unión de Campos, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "47671",
                    "province": "Valladolid",
                    "city": "Urones de Castroponce",
                    "multiple": "0"
                },
                {
                    "zipcode": "47672",
                    "province": "Valladolid",
                    "city": "Valdunquillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47673",
                    "province": "Valladolid",
                    "city": "Quintanilla del Molar",
                    "multiple": "0"
                },
                {
                    "zipcode": "47673",
                    "province": "Valladolid",
                    "city": "Roales de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47674",
                    "province": "Valladolid",
                    "city": "Barcial de la Loma",
                    "multiple": "0"
                },
                {
                    "zipcode": "47675",
                    "province": "Valladolid",
                    "city": "Bolaños de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47675",
                    "province": "Valladolid",
                    "city": "Villalán de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47676",
                    "province": "Valladolid",
                    "city": "Villavicencio de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "47680",
                    "province": "Valladolid",
                    "city": "Mayorga",
                    "multiple": "0"
                },
                {
                    "zipcode": "47686",
                    "province": "Valladolid",
                    "city": "Melgar de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "47687",
                    "province": "Valladolid",
                    "city": "Melgar de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47688",
                    "province": "Valladolid",
                    "city": "Monasterio de Vega",
                    "multiple": "0"
                },
                {
                    "zipcode": "47689",
                    "province": "Valladolid",
                    "city": "Villalba de la Loma",
                    "multiple": "0"
                },
                {
                    "zipcode": "47689",
                    "province": "Valladolid",
                    "city": "Saelices de Mayorga",
                    "multiple": "1"
                },
                {
                    "zipcode": "47689",
                    "province": "Valladolid",
                    "city": "Castrobol",
                    "multiple": "1"
                },
                {
                    "zipcode": "47689",
                    "province": "Valladolid",
                    "city": "Cabezón de Valderaduey",
                    "multiple": "1"
                },
                {
                    "zipcode": "47690",
                    "province": "Valladolid",
                    "city": "Valverde de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47691",
                    "province": "Valladolid",
                    "city": "Moral de la Reina",
                    "multiple": "0"
                },
                {
                    "zipcode": "47692",
                    "province": "Valladolid",
                    "city": "Ceinos de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47800",
                    "province": "Valladolid",
                    "city": "Medina de Rioseco",
                    "multiple": "0"
                },
                {
                    "zipcode": "47801",
                    "province": "Valladolid",
                    "city": "Valladolid",
                    "multiple": "0"
                },
                {
                    "zipcode": "47810",
                    "province": "Valladolid",
                    "city": "Villafrechós",
                    "multiple": "0"
                },
                {
                    "zipcode": "47811",
                    "province": "Valladolid",
                    "city": "Santa Eufemia del Arroyo",
                    "multiple": "0"
                },
                {
                    "zipcode": "47811",
                    "province": "Valladolid",
                    "city": "Morales de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47812",
                    "province": "Valladolid",
                    "city": "Palazuelo de Vedija",
                    "multiple": "0"
                },
                {
                    "zipcode": "47813",
                    "province": "Valladolid",
                    "city": "Berrueces",
                    "multiple": "0"
                },
                {
                    "zipcode": "47813",
                    "province": "Valladolid",
                    "city": "Villanueva de San Mancio",
                    "multiple": "1"
                },
                {
                    "zipcode": "47814",
                    "province": "Valladolid",
                    "city": "Aguilar de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47814",
                    "province": "Valladolid",
                    "city": "Villamuriel de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47815",
                    "province": "Valladolid",
                    "city": "Villabaruz de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47815",
                    "province": "Valladolid",
                    "city": "Tamariz de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47816",
                    "province": "Valladolid",
                    "city": "Medina de Rioseco",
                    "multiple": "0"
                },
                {
                    "zipcode": "47816",
                    "province": "Valladolid",
                    "city": "Montealegre de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "47816",
                    "province": "Valladolid",
                    "city": "Valdenebro de los Valles",
                    "multiple": "1"
                },
                {
                    "zipcode": "47820",
                    "province": "Valladolid",
                    "city": "Villabrágima",
                    "multiple": "0"
                },
                {
                    "zipcode": "47830",
                    "province": "Valladolid",
                    "city": "Tordehumos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47831",
                    "province": "Valladolid",
                    "city": "Pozuelo de la Orden",
                    "multiple": "0"
                },
                {
                    "zipcode": "47832",
                    "province": "Valladolid",
                    "city": "Cabreros del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "47840",
                    "province": "Valladolid",
                    "city": "Villagarcía de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "47850",
                    "province": "Valladolid",
                    "city": "Villanueva de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "47851",
                    "province": "Valladolid",
                    "city": "San Pedro de Latarce",
                    "multiple": "0"
                },
                {
                    "zipcode": "47860",
                    "province": "Valladolid",
                    "city": "Villardefrades",
                    "multiple": "0"
                },
                {
                    "zipcode": "47862",
                    "province": "Valladolid",
                    "city": "Urueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "47870",
                    "province": "Valladolid",
                    "city": "Tiedra",
                    "multiple": "0"
                },
                {
                    "zipcode": "47880",
                    "province": "Valladolid",
                    "city": "Benafarces",
                    "multiple": "0"
                },
                {
                    "zipcode": "47882",
                    "province": "Valladolid",
                    "city": "Castromembibre",
                    "multiple": "0"
                },
                {
                    "zipcode": "47883",
                    "province": "Valladolid",
                    "city": "Villavellid",
                    "multiple": "0"
                },
                {
                    "zipcode": "48001",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48002",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48003",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48003",
                    "province": "Bizkaia",
                    "city": "Arrigorriaga",
                    "multiple": "1"
                },
                {
                    "zipcode": "48004",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48005",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48006",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48007",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48008",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48009",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48010",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48011",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48012",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48013",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48014",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48015",
                    "province": "Bizkaia",
                    "city": "Bilbao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48100",
                    "province": "Bizkaia",
                    "city": "Mungia",
                    "multiple": "0"
                },
                {
                    "zipcode": "48110",
                    "province": "Bizkaia",
                    "city": "Gatika",
                    "multiple": "0"
                },
                {
                    "zipcode": "48111",
                    "province": "Bizkaia",
                    "city": "Laukiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48112",
                    "province": "Bizkaia",
                    "city": "Maruri-Jatabe",
                    "multiple": "0"
                },
                {
                    "zipcode": "48113",
                    "province": "Bizkaia",
                    "city": "Gamiz-Fika",
                    "multiple": "0"
                },
                {
                    "zipcode": "48114",
                    "province": "Bizkaia",
                    "city": "Arrieta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48115",
                    "province": "Bizkaia",
                    "city": "Morga",
                    "multiple": "0"
                },
                {
                    "zipcode": "48116",
                    "province": "Bizkaia",
                    "city": "Fruiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48120",
                    "province": "Bizkaia",
                    "city": "Mungia",
                    "multiple": "0"
                },
                {
                    "zipcode": "48120",
                    "province": "Bizkaia",
                    "city": "Meñaka",
                    "multiple": "1"
                },
                {
                    "zipcode": "48130",
                    "province": "Bizkaia",
                    "city": "Bakio",
                    "multiple": "0"
                },
                {
                    "zipcode": "48140",
                    "province": "Bizkaia",
                    "city": "Arantzazu",
                    "multiple": "0"
                },
                {
                    "zipcode": "48140",
                    "province": "Bizkaia",
                    "city": "Igorre",
                    "multiple": "1"
                },
                {
                    "zipcode": "48141",
                    "province": "Bizkaia",
                    "city": "Dima",
                    "multiple": "0"
                },
                {
                    "zipcode": "48142",
                    "province": "Bizkaia",
                    "city": "Artea",
                    "multiple": "0"
                },
                {
                    "zipcode": "48143",
                    "province": "Bizkaia",
                    "city": "Areatza",
                    "multiple": "0"
                },
                {
                    "zipcode": "48144",
                    "province": "Bizkaia",
                    "city": "Zeanuri",
                    "multiple": "0"
                },
                {
                    "zipcode": "48145",
                    "province": "Bizkaia",
                    "city": "Ubide",
                    "multiple": "0"
                },
                {
                    "zipcode": "48150",
                    "province": "Bizkaia",
                    "city": "Sondika",
                    "multiple": "0"
                },
                {
                    "zipcode": "48160",
                    "province": "Bizkaia",
                    "city": "Derio",
                    "multiple": "0"
                },
                {
                    "zipcode": "48160",
                    "province": "Bizkaia",
                    "city": "Urduliz",
                    "multiple": "1"
                },
                {
                    "zipcode": "48170",
                    "province": "Bizkaia",
                    "city": "Zamudio",
                    "multiple": "0"
                },
                {
                    "zipcode": "48170",
                    "province": "Bizkaia",
                    "city": "Derio",
                    "multiple": "1"
                },
                {
                    "zipcode": "48180",
                    "province": "Bizkaia",
                    "city": "Loiu",
                    "multiple": "0"
                },
                {
                    "zipcode": "48190",
                    "province": "Bizkaia",
                    "city": "Sopuerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48191",
                    "province": "Bizkaia",
                    "city": "Sopuerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48191",
                    "province": "Bizkaia",
                    "city": "Galdames",
                    "multiple": "1"
                },
                {
                    "zipcode": "48192",
                    "province": "Bizkaia",
                    "city": "Gordexola",
                    "multiple": "0"
                },
                {
                    "zipcode": "48194",
                    "province": "Bizkaia",
                    "city": "Gordexola",
                    "multiple": "0"
                },
                {
                    "zipcode": "48195",
                    "province": "Bizkaia",
                    "city": "Larrabetzu",
                    "multiple": "0"
                },
                {
                    "zipcode": "48196",
                    "province": "Bizkaia",
                    "city": "Lezama",
                    "multiple": "0"
                },
                {
                    "zipcode": "48200",
                    "province": "Bizkaia",
                    "city": "Durango",
                    "multiple": "0"
                },
                {
                    "zipcode": "48200",
                    "province": "Bizkaia",
                    "city": "Garai",
                    "multiple": "1"
                },
                {
                    "zipcode": "48210",
                    "province": "Bizkaia",
                    "city": "Otxandio",
                    "multiple": "0"
                },
                {
                    "zipcode": "48212",
                    "province": "Bizkaia",
                    "city": "Mañaria",
                    "multiple": "0"
                },
                {
                    "zipcode": "48213",
                    "province": "Bizkaia",
                    "city": "Izurtza",
                    "multiple": "0"
                },
                {
                    "zipcode": "48215",
                    "province": "Bizkaia",
                    "city": "Iurreta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48220",
                    "province": "Bizkaia",
                    "city": "Abadiño",
                    "multiple": "0"
                },
                {
                    "zipcode": "48230",
                    "province": "Bizkaia",
                    "city": "Elorrio",
                    "multiple": "0"
                },
                {
                    "zipcode": "48240",
                    "province": "Bizkaia",
                    "city": "Berriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48249",
                    "province": "Bizkaia",
                    "city": "Berriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48250",
                    "province": "Bizkaia",
                    "city": "Zaldibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "48260",
                    "province": "Bizkaia",
                    "city": "Ermua",
                    "multiple": "0"
                },
                {
                    "zipcode": "48269",
                    "province": "Bizkaia",
                    "city": "Mallabia",
                    "multiple": "0"
                },
                {
                    "zipcode": "48270",
                    "province": "Bizkaia",
                    "city": "Markina-Xemein",
                    "multiple": "0"
                },
                {
                    "zipcode": "48276",
                    "province": "Bizkaia",
                    "city": "Markina-Xemein",
                    "multiple": "0"
                },
                {
                    "zipcode": "48277",
                    "province": "Bizkaia",
                    "city": "Etxebarria",
                    "multiple": "0"
                },
                {
                    "zipcode": "48278",
                    "province": "Bizkaia",
                    "city": "Ziortza-Bolibar",
                    "multiple": "0"
                },
                {
                    "zipcode": "48278",
                    "province": "Bizkaia",
                    "city": "Markina-Xemein",
                    "multiple": "1"
                },
                {
                    "zipcode": "48280",
                    "province": "Bizkaia",
                    "city": "Lekeitio",
                    "multiple": "0"
                },
                {
                    "zipcode": "48287",
                    "province": "Bizkaia",
                    "city": "Ea",
                    "multiple": "0"
                },
                {
                    "zipcode": "48288",
                    "province": "Bizkaia",
                    "city": "Ispaster",
                    "multiple": "0"
                },
                {
                    "zipcode": "48289",
                    "province": "Bizkaia",
                    "city": "Gizaburuaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "48289",
                    "province": "Bizkaia",
                    "city": "Mendexa",
                    "multiple": "1"
                },
                {
                    "zipcode": "48289",
                    "province": "Bizkaia",
                    "city": "Amoroto",
                    "multiple": "1"
                },
                {
                    "zipcode": "48291",
                    "province": "Bizkaia",
                    "city": "Atxondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48292",
                    "province": "Bizkaia",
                    "city": "Atxondo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48300",
                    "province": "Bizkaia",
                    "city": "Gernika-Lumo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48309",
                    "province": "Bizkaia",
                    "city": "Errigoiti",
                    "multiple": "0"
                },
                {
                    "zipcode": "48310",
                    "province": "Bizkaia",
                    "city": "Elantxobe",
                    "multiple": "0"
                },
                {
                    "zipcode": "48311",
                    "province": "Bizkaia",
                    "city": "Ea",
                    "multiple": "0"
                },
                {
                    "zipcode": "48311",
                    "province": "Bizkaia",
                    "city": "Ibarrangelu",
                    "multiple": "1"
                },
                {
                    "zipcode": "48312",
                    "province": "Bizkaia",
                    "city": "Nabarniz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48313",
                    "province": "Bizkaia",
                    "city": "Ereño",
                    "multiple": "0"
                },
                {
                    "zipcode": "48314",
                    "province": "Bizkaia",
                    "city": "Gautegiz Arteaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "48315",
                    "province": "Bizkaia",
                    "city": "Kortezubi",
                    "multiple": "0"
                },
                {
                    "zipcode": "48320",
                    "province": "Bizkaia",
                    "city": "Ajangiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48330",
                    "province": "Bizkaia",
                    "city": "Lemoa",
                    "multiple": "0"
                },
                {
                    "zipcode": "48340",
                    "province": "Bizkaia",
                    "city": "Amorebieta-Etxano",
                    "multiple": "0"
                },
                {
                    "zipcode": "48348",
                    "province": "Bizkaia",
                    "city": "Amorebieta-Etxano",
                    "multiple": "0"
                },
                {
                    "zipcode": "48350",
                    "province": "Bizkaia",
                    "city": "Busturia",
                    "multiple": "0"
                },
                {
                    "zipcode": "48360",
                    "province": "Bizkaia",
                    "city": "Mundaka",
                    "multiple": "0"
                },
                {
                    "zipcode": "48370",
                    "province": "Bizkaia",
                    "city": "Bermeo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48380",
                    "province": "Bizkaia",
                    "city": "Aulesti",
                    "multiple": "0"
                },
                {
                    "zipcode": "48381",
                    "province": "Bizkaia",
                    "city": "Munitibar-Arbatzegi Gerrikaitz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48382",
                    "province": "Bizkaia",
                    "city": "Mendata",
                    "multiple": "0"
                },
                {
                    "zipcode": "48383",
                    "province": "Bizkaia",
                    "city": "Arratzu",
                    "multiple": "0"
                },
                {
                    "zipcode": "48390",
                    "province": "Bizkaia",
                    "city": "Arratzu",
                    "multiple": "0"
                },
                {
                    "zipcode": "48390",
                    "province": "Bizkaia",
                    "city": "Bedia",
                    "multiple": "1"
                },
                {
                    "zipcode": "48391",
                    "province": "Bizkaia",
                    "city": "Muxika",
                    "multiple": "0"
                },
                {
                    "zipcode": "48391",
                    "province": "Bizkaia",
                    "city": "Arratzu",
                    "multiple": "1"
                },
                {
                    "zipcode": "48392",
                    "province": "Bizkaia",
                    "city": "Muxika",
                    "multiple": "0"
                },
                {
                    "zipcode": "48393",
                    "province": "Bizkaia",
                    "city": "Forua",
                    "multiple": "0"
                },
                {
                    "zipcode": "48394",
                    "province": "Bizkaia",
                    "city": "Murueta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48395",
                    "province": "Bizkaia",
                    "city": "Sukarrieta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48410",
                    "province": "Bizkaia",
                    "city": "Orozko",
                    "multiple": "0"
                },
                {
                    "zipcode": "48419",
                    "province": "Bizkaia",
                    "city": "Orozko",
                    "multiple": "0"
                },
                {
                    "zipcode": "48450",
                    "province": "Bizkaia",
                    "city": "Etxebarri",
                    "multiple": "0"
                },
                {
                    "zipcode": "48460",
                    "province": "Bizkaia",
                    "city": "Urduña/Orduña",
                    "multiple": "0"
                },
                {
                    "zipcode": "48480",
                    "province": "Bizkaia",
                    "city": "Arrigorriaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "48480",
                    "province": "Bizkaia",
                    "city": "Zaratamo",
                    "multiple": "1"
                },
                {
                    "zipcode": "48490",
                    "province": "Bizkaia",
                    "city": "Ugao-Miraballes",
                    "multiple": "0"
                },
                {
                    "zipcode": "48498",
                    "province": "Bizkaia",
                    "city": "Arrankudiaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "48498",
                    "province": "Bizkaia",
                    "city": "Urduña/Orduña",
                    "multiple": "1"
                },
                {
                    "zipcode": "48498",
                    "province": "Bizkaia",
                    "city": "Arakaldo",
                    "multiple": "1"
                },
                {
                    "zipcode": "48499",
                    "province": "Bizkaia",
                    "city": "Arrankudiaga",
                    "multiple": "0"
                },
                {
                    "zipcode": "48499",
                    "province": "Bizkaia",
                    "city": "Zeberio",
                    "multiple": "1"
                },
                {
                    "zipcode": "48500",
                    "province": "Bizkaia",
                    "city": "Abanto y Ciérvana-Abanto Zierbena",
                    "multiple": "0"
                },
                {
                    "zipcode": "48508",
                    "province": "Bizkaia",
                    "city": "Zierbena",
                    "multiple": "0"
                },
                {
                    "zipcode": "48510",
                    "province": "Bizkaia",
                    "city": "Valle de Trápaga-Trapagaran",
                    "multiple": "0"
                },
                {
                    "zipcode": "48520",
                    "province": "Bizkaia",
                    "city": "Valle de Trápaga-Trapagaran",
                    "multiple": "0"
                },
                {
                    "zipcode": "48530",
                    "province": "Bizkaia",
                    "city": "Ortuella",
                    "multiple": "0"
                },
                {
                    "zipcode": "48540",
                    "province": "Bizkaia",
                    "city": "Abanto y Ciérvana-Abanto Zierbena",
                    "multiple": "0"
                },
                {
                    "zipcode": "48550",
                    "province": "Bizkaia",
                    "city": "Muskiz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48600",
                    "province": "Bizkaia",
                    "city": "Sopela",
                    "multiple": "0"
                },
                {
                    "zipcode": "48610",
                    "province": "Bizkaia",
                    "city": "Urduliz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48620",
                    "province": "Bizkaia",
                    "city": "Gorliz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48620",
                    "province": "Bizkaia",
                    "city": "Plentzia",
                    "multiple": "1"
                },
                {
                    "zipcode": "48620",
                    "province": "Bizkaia",
                    "city": "Lemoiz",
                    "multiple": "1"
                },
                {
                    "zipcode": "48630",
                    "province": "Bizkaia",
                    "city": "Gorliz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48640",
                    "province": "Bizkaia",
                    "city": "Berango",
                    "multiple": "0"
                },
                {
                    "zipcode": "48650",
                    "province": "Bizkaia",
                    "city": "Barrika",
                    "multiple": "0"
                },
                {
                    "zipcode": "48700",
                    "province": "Bizkaia",
                    "city": "Ondarroa",
                    "multiple": "0"
                },
                {
                    "zipcode": "48710",
                    "province": "Bizkaia",
                    "city": "Berriatua",
                    "multiple": "0"
                },
                {
                    "zipcode": "48800",
                    "province": "Bizkaia",
                    "city": "Balmaseda",
                    "multiple": "0"
                },
                {
                    "zipcode": "48810",
                    "province": "Bizkaia",
                    "city": "Alonsotegi",
                    "multiple": "0"
                },
                {
                    "zipcode": "48820",
                    "province": "Bizkaia",
                    "city": "Güeñes",
                    "multiple": "0"
                },
                {
                    "zipcode": "48830",
                    "province": "Bizkaia",
                    "city": "Güeñes",
                    "multiple": "0"
                },
                {
                    "zipcode": "48840",
                    "province": "Bizkaia",
                    "city": "Güeñes",
                    "multiple": "0"
                },
                {
                    "zipcode": "48850",
                    "province": "Bizkaia",
                    "city": "Zalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "48860",
                    "province": "Bizkaia",
                    "city": "Zalla",
                    "multiple": "0"
                },
                {
                    "zipcode": "48869",
                    "province": "Bizkaia",
                    "city": "Sopuerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48869",
                    "province": "Bizkaia",
                    "city": "Zalla",
                    "multiple": "1"
                },
                {
                    "zipcode": "48870",
                    "province": "Bizkaia",
                    "city": "Sopuerta",
                    "multiple": "0"
                },
                {
                    "zipcode": "48879",
                    "province": "Bizkaia",
                    "city": "Artzentales",
                    "multiple": "0"
                },
                {
                    "zipcode": "48880",
                    "province": "Bizkaia",
                    "city": "Trucios-Turtzioz",
                    "multiple": "0"
                },
                {
                    "zipcode": "48890",
                    "province": "Bizkaia",
                    "city": "Karrantza Harana/Valle de Carranza",
                    "multiple": "0"
                },
                {
                    "zipcode": "48891",
                    "province": "Bizkaia",
                    "city": "Karrantza Harana/Valle de Carranza",
                    "multiple": "0"
                },
                {
                    "zipcode": "48895",
                    "province": "Bizkaia",
                    "city": "Lanestosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "48901",
                    "province": "Bizkaia",
                    "city": "Barakaldo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48902",
                    "province": "Bizkaia",
                    "city": "Barakaldo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48903",
                    "province": "Bizkaia",
                    "city": "Barakaldo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48910",
                    "province": "Bizkaia",
                    "city": "Sestao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48920",
                    "province": "Bizkaia",
                    "city": "Portugalete",
                    "multiple": "0"
                },
                {
                    "zipcode": "48930",
                    "province": "Bizkaia",
                    "city": "Getxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48940",
                    "province": "Bizkaia",
                    "city": "Leioa",
                    "multiple": "0"
                },
                {
                    "zipcode": "48950",
                    "province": "Bizkaia",
                    "city": "Erandio",
                    "multiple": "0"
                },
                {
                    "zipcode": "48960",
                    "province": "Bizkaia",
                    "city": "Galdakao",
                    "multiple": "0"
                },
                {
                    "zipcode": "48970",
                    "province": "Bizkaia",
                    "city": "Basauri",
                    "multiple": "0"
                },
                {
                    "zipcode": "48980",
                    "province": "Bizkaia",
                    "city": "Santurtzi",
                    "multiple": "0"
                },
                {
                    "zipcode": "48990",
                    "province": "Bizkaia",
                    "city": "Getxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48991",
                    "province": "Bizkaia",
                    "city": "Getxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48992",
                    "province": "Bizkaia",
                    "city": "Getxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "48993",
                    "province": "Bizkaia",
                    "city": "Getxo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49001",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49002",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49003",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49004",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49005",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49006",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49007",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49008",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49009",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49010",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49011",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49012",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49013",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49014",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49015",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49016",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49017",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49018",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49019",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49020",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49021",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49022",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49023",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49024",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49025",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49026",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49027",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49028",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49029",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49030",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49031",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49032",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49100",
                    "province": "Zamora",
                    "city": "Villanueva del Campo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49110",
                    "province": "Zamora",
                    "city": "Castroverde de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49120",
                    "province": "Zamora",
                    "city": "Molacillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49121",
                    "province": "Zamora",
                    "city": "Mombuey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49121",
                    "province": "Zamora",
                    "city": "Monfarracinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "49122",
                    "province": "Zamora",
                    "city": "Torres del Carrizal",
                    "multiple": "0"
                },
                {
                    "zipcode": "49123",
                    "province": "Zamora",
                    "city": "Benegiles",
                    "multiple": "0"
                },
                {
                    "zipcode": "49124",
                    "province": "Zamora",
                    "city": "Aspariegos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49125",
                    "province": "Zamora",
                    "city": "Cerecinos del Carrizal",
                    "multiple": "0"
                },
                {
                    "zipcode": "49126",
                    "province": "Zamora",
                    "city": "Arquillinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49126",
                    "province": "Zamora",
                    "city": "Villalba de la Lampreana",
                    "multiple": "1"
                },
                {
                    "zipcode": "49127",
                    "province": "Zamora",
                    "city": "Pobladura de Valderaduey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49127",
                    "province": "Zamora",
                    "city": "Castronuevo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49128",
                    "province": "Zamora",
                    "city": "Cañizo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49129",
                    "province": "Zamora",
                    "city": "San Martín de Valderaduey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49129",
                    "province": "Zamora",
                    "city": "Villárdiga",
                    "multiple": "1"
                },
                {
                    "zipcode": "49130",
                    "province": "Zamora",
                    "city": "Manganeses de la Lampreana",
                    "multiple": "0"
                },
                {
                    "zipcode": "49131",
                    "province": "Zamora",
                    "city": "Villamayor de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49132",
                    "province": "Zamora",
                    "city": "Villar de Fallaves",
                    "multiple": "0"
                },
                {
                    "zipcode": "49133",
                    "province": "Zamora",
                    "city": "Vega de Villalobos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49134",
                    "province": "Zamora",
                    "city": "Villalobos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49135",
                    "province": "Zamora",
                    "city": "San Agustín del Pozo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49135",
                    "province": "Zamora",
                    "city": "Vidayanes",
                    "multiple": "1"
                },
                {
                    "zipcode": "49135",
                    "province": "Zamora",
                    "city": "Revellinos",
                    "multiple": "1"
                },
                {
                    "zipcode": "49136",
                    "province": "Zamora",
                    "city": "Villafáfila",
                    "multiple": "0"
                },
                {
                    "zipcode": "49137",
                    "province": "Zamora",
                    "city": "Villafáfila",
                    "multiple": "0"
                },
                {
                    "zipcode": "49137",
                    "province": "Zamora",
                    "city": "Villarrín de Campos",
                    "multiple": "1"
                },
                {
                    "zipcode": "49140",
                    "province": "Zamora",
                    "city": "Tábara",
                    "multiple": "0"
                },
                {
                    "zipcode": "49141",
                    "province": "Zamora",
                    "city": "Faramontanos de Tábara",
                    "multiple": "0"
                },
                {
                    "zipcode": "49142",
                    "province": "Zamora",
                    "city": "Pajares de la Lampreana",
                    "multiple": "0"
                },
                {
                    "zipcode": "49143",
                    "province": "Zamora",
                    "city": "Piedrahita de Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49144",
                    "province": "Zamora",
                    "city": "San Cebrián de Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49145",
                    "province": "Zamora",
                    "city": "Perilla de Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49145",
                    "province": "Zamora",
                    "city": "Santa Eufemia del Barco",
                    "multiple": "1"
                },
                {
                    "zipcode": "49146",
                    "province": "Zamora",
                    "city": "Olmillos de Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49147",
                    "province": "Zamora",
                    "city": "Olmillos de Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49148",
                    "province": "Zamora",
                    "city": "Moreruela de Tábara",
                    "multiple": "0"
                },
                {
                    "zipcode": "49148",
                    "province": "Zamora",
                    "city": "Pozuelo de Tábara",
                    "multiple": "1"
                },
                {
                    "zipcode": "49149",
                    "province": "Zamora",
                    "city": "Montamarta",
                    "multiple": "0"
                },
                {
                    "zipcode": "49150",
                    "province": "Zamora",
                    "city": "Moraleja del Vino",
                    "multiple": "0"
                },
                {
                    "zipcode": "49151",
                    "province": "Zamora",
                    "city": "Casaseca de las Chanas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49151",
                    "province": "Zamora",
                    "city": "Gema",
                    "multiple": "1"
                },
                {
                    "zipcode": "49151",
                    "province": "Zamora",
                    "city": "Arcenillas",
                    "multiple": "1"
                },
                {
                    "zipcode": "49152",
                    "province": "Zamora",
                    "city": "Sanzoles",
                    "multiple": "0"
                },
                {
                    "zipcode": "49153",
                    "province": "Zamora",
                    "city": "Venialbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49154",
                    "province": "Zamora",
                    "city": "Pego, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "49155",
                    "province": "Zamora",
                    "city": "Bóveda de Toro, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "49156",
                    "province": "Zamora",
                    "city": "Guarrate",
                    "multiple": "0"
                },
                {
                    "zipcode": "49157",
                    "province": "Zamora",
                    "city": "Madridanos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49158",
                    "province": "Zamora",
                    "city": "Villalazán",
                    "multiple": "0"
                },
                {
                    "zipcode": "49159",
                    "province": "Zamora",
                    "city": "Villaralbo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49160",
                    "province": "Zamora",
                    "city": "Carbajales de Alba",
                    "multiple": "0"
                },
                {
                    "zipcode": "49161",
                    "province": "Zamora",
                    "city": "Santa Eufemia del Barco",
                    "multiple": "0"
                },
                {
                    "zipcode": "49162",
                    "province": "Zamora",
                    "city": "Andavías",
                    "multiple": "0"
                },
                {
                    "zipcode": "49162",
                    "province": "Zamora",
                    "city": "Palacios del Pan",
                    "multiple": "1"
                },
                {
                    "zipcode": "49163",
                    "province": "Zamora",
                    "city": "Manzanal del Barco",
                    "multiple": "0"
                },
                {
                    "zipcode": "49164",
                    "province": "Zamora",
                    "city": "Videmala",
                    "multiple": "0"
                },
                {
                    "zipcode": "49164",
                    "province": "Zamora",
                    "city": "Muelas del Pan",
                    "multiple": "1"
                },
                {
                    "zipcode": "49165",
                    "province": "Zamora",
                    "city": "Videmala",
                    "multiple": "0"
                },
                {
                    "zipcode": "49165",
                    "province": "Zamora",
                    "city": "Muelas del Pan",
                    "multiple": "1"
                },
                {
                    "zipcode": "49166",
                    "province": "Zamora",
                    "city": "Villalcampo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49167",
                    "province": "Zamora",
                    "city": "Muelas del Pan",
                    "multiple": "0"
                },
                {
                    "zipcode": "49170",
                    "province": "Zamora",
                    "city": "Perdigón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "49171",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49172",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49173",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49174",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49174",
                    "province": "Zamora",
                    "city": "Fresno de Sayago",
                    "multiple": "1"
                },
                {
                    "zipcode": "49176",
                    "province": "Zamora",
                    "city": "Peñausende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49177",
                    "province": "Zamora",
                    "city": "Alfaraz de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49177",
                    "province": "Zamora",
                    "city": "Moraleja de Sayago",
                    "multiple": "1"
                },
                {
                    "zipcode": "49177",
                    "province": "Zamora",
                    "city": "Almeida de Sayago",
                    "multiple": "1"
                },
                {
                    "zipcode": "49177",
                    "province": "Zamora",
                    "city": "Peñausende",
                    "multiple": "1"
                },
                {
                    "zipcode": "49178",
                    "province": "Zamora",
                    "city": "Peñausende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49180",
                    "province": "Zamora",
                    "city": "Almaraz de Duero",
                    "multiple": "0"
                },
                {
                    "zipcode": "49181",
                    "province": "Zamora",
                    "city": "Villaseco del Pan",
                    "multiple": "0"
                },
                {
                    "zipcode": "49182",
                    "province": "Zamora",
                    "city": "San Pedro de la Nave-Almendra",
                    "multiple": "0"
                },
                {
                    "zipcode": "49183",
                    "province": "Zamora",
                    "city": "San Pedro de la Nave-Almendra",
                    "multiple": "0"
                },
                {
                    "zipcode": "49190",
                    "province": "Zamora",
                    "city": "Morales del Vino",
                    "multiple": "0"
                },
                {
                    "zipcode": "49191",
                    "province": "Zamora",
                    "city": "Jambrina",
                    "multiple": "0"
                },
                {
                    "zipcode": "49191",
                    "province": "Zamora",
                    "city": "Cazurra",
                    "multiple": "1"
                },
                {
                    "zipcode": "49191",
                    "province": "Zamora",
                    "city": "Morales del Vino",
                    "multiple": "1"
                },
                {
                    "zipcode": "49191",
                    "province": "Zamora",
                    "city": "Peleas de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49192",
                    "province": "Zamora",
                    "city": "Roales",
                    "multiple": "0"
                },
                {
                    "zipcode": "49192",
                    "province": "Zamora",
                    "city": "Hiniesta, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "49192",
                    "province": "Zamora",
                    "city": "Valcabado",
                    "multiple": "1"
                },
                {
                    "zipcode": "49193",
                    "province": "Zamora",
                    "city": "Zamora",
                    "multiple": "0"
                },
                {
                    "zipcode": "49200",
                    "province": "Zamora",
                    "city": "Bermillo de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49210",
                    "province": "Zamora",
                    "city": "Almeida de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49211",
                    "province": "Zamora",
                    "city": "Salce",
                    "multiple": "0"
                },
                {
                    "zipcode": "49211",
                    "province": "Zamora",
                    "city": "Carbellino",
                    "multiple": "1"
                },
                {
                    "zipcode": "49211",
                    "province": "Zamora",
                    "city": "Bermillo de Sayago",
                    "multiple": "1"
                },
                {
                    "zipcode": "49211",
                    "province": "Zamora",
                    "city": "Roelos de Sayago",
                    "multiple": "1"
                },
                {
                    "zipcode": "49212",
                    "province": "Zamora",
                    "city": "Muga de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49213",
                    "province": "Zamora",
                    "city": "Fariza",
                    "multiple": "0"
                },
                {
                    "zipcode": "49214",
                    "province": "Zamora",
                    "city": "Fariza",
                    "multiple": "0"
                },
                {
                    "zipcode": "49215",
                    "province": "Zamora",
                    "city": "Luelmo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49215",
                    "province": "Zamora",
                    "city": "Bermillo de Sayago",
                    "multiple": "1"
                },
                {
                    "zipcode": "49216",
                    "province": "Zamora",
                    "city": "Bermillo de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49216",
                    "province": "Zamora",
                    "city": "Fresno de Sayago",
                    "multiple": "1"
                },
                {
                    "zipcode": "49220",
                    "province": "Zamora",
                    "city": "Fermoselle",
                    "multiple": "0"
                },
                {
                    "zipcode": "49230",
                    "province": "Zamora",
                    "city": "Villar del Buey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49231",
                    "province": "Zamora",
                    "city": "Villar del Buey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49232",
                    "province": "Zamora",
                    "city": "Villar del Buey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49240",
                    "province": "Zamora",
                    "city": "Villar del Buey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49250",
                    "province": "Zamora",
                    "city": "Villardiegua de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49250",
                    "province": "Zamora",
                    "city": "Villadepera",
                    "multiple": "1"
                },
                {
                    "zipcode": "49251",
                    "province": "Zamora",
                    "city": "Argañín",
                    "multiple": "0"
                },
                {
                    "zipcode": "49251",
                    "province": "Zamora",
                    "city": "Gamones",
                    "multiple": "1"
                },
                {
                    "zipcode": "49252",
                    "province": "Zamora",
                    "city": "Torregamones",
                    "multiple": "0"
                },
                {
                    "zipcode": "49253",
                    "province": "Zamora",
                    "city": "Moralina",
                    "multiple": "0"
                },
                {
                    "zipcode": "49254",
                    "province": "Zamora",
                    "city": "Moral de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49255",
                    "province": "Zamora",
                    "city": "Bermillo de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49260",
                    "province": "Zamora",
                    "city": "Bermillo de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49270",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49271",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49272",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49280",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49281",
                    "province": "Zamora",
                    "city": "Pereruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49300",
                    "province": "Zamora",
                    "city": "Puebla de Sanabria",
                    "multiple": "0"
                },
                {
                    "zipcode": "49310",
                    "province": "Zamora",
                    "city": "Mombuey",
                    "multiple": "0"
                },
                {
                    "zipcode": "49317",
                    "province": "Zamora",
                    "city": "Manzanal de los Infantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "49317",
                    "province": "Zamora",
                    "city": "Cernadilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "49318",
                    "province": "Zamora",
                    "city": "Rionegro del Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "49318",
                    "province": "Zamora",
                    "city": "Peque",
                    "multiple": "1"
                },
                {
                    "zipcode": "49318",
                    "province": "Zamora",
                    "city": "Mombuey",
                    "multiple": "1"
                },
                {
                    "zipcode": "49319",
                    "province": "Zamora",
                    "city": "Muelas de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "49319",
                    "province": "Zamora",
                    "city": "Manzanal de los Infantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "49320",
                    "province": "Zamora",
                    "city": "Robleda-Cervantes",
                    "multiple": "0"
                },
                {
                    "zipcode": "49320",
                    "province": "Zamora",
                    "city": "Palacios de Sanabria",
                    "multiple": "1"
                },
                {
                    "zipcode": "49321",
                    "province": "Zamora",
                    "city": "Palacios de Sanabria",
                    "multiple": "0"
                },
                {
                    "zipcode": "49321",
                    "province": "Zamora",
                    "city": "Robleda-Cervantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "49322",
                    "province": "Zamora",
                    "city": "Rosinos de la Requejada",
                    "multiple": "0"
                },
                {
                    "zipcode": "49322",
                    "province": "Zamora",
                    "city": "Palacios de Sanabria",
                    "multiple": "1"
                },
                {
                    "zipcode": "49323",
                    "province": "Zamora",
                    "city": "Rosinos de la Requejada",
                    "multiple": "0"
                },
                {
                    "zipcode": "49324",
                    "province": "Zamora",
                    "city": "Rosinos de la Requejada",
                    "multiple": "0"
                },
                {
                    "zipcode": "49324",
                    "province": "Zamora",
                    "city": "Asturianos",
                    "multiple": "1"
                },
                {
                    "zipcode": "49325",
                    "province": "Zamora",
                    "city": "Asturianos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49325",
                    "province": "Zamora",
                    "city": "Cernadilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "49326",
                    "province": "Zamora",
                    "city": "Rionegro del Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "49327",
                    "province": "Zamora",
                    "city": "Cubo de Benavente",
                    "multiple": "0"
                },
                {
                    "zipcode": "49327",
                    "province": "Zamora",
                    "city": "Uña de Quintana",
                    "multiple": "1"
                },
                {
                    "zipcode": "49327",
                    "province": "Zamora",
                    "city": "Molezuelas de la Carballeda",
                    "multiple": "1"
                },
                {
                    "zipcode": "49330",
                    "province": "Zamora",
                    "city": "Vega de Tera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49330",
                    "province": "Zamora",
                    "city": "Rionegro del Puente",
                    "multiple": "1"
                },
                {
                    "zipcode": "49331",
                    "province": "Zamora",
                    "city": "Vega de Tera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49331",
                    "province": "Zamora",
                    "city": "Calzadilla de Tera",
                    "multiple": "1"
                },
                {
                    "zipcode": "49332",
                    "province": "Zamora",
                    "city": "Camarzana de Tera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49332",
                    "province": "Zamora",
                    "city": "Vega de Tera",
                    "multiple": "1"
                },
                {
                    "zipcode": "49333",
                    "province": "Zamora",
                    "city": "Santa María de Valverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "49333",
                    "province": "Zamora",
                    "city": "Villanueva de las Peras",
                    "multiple": "1"
                },
                {
                    "zipcode": "49333",
                    "province": "Zamora",
                    "city": "Pueblica de Valverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "49334",
                    "province": "Zamora",
                    "city": "Ferreras de Abajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49335",
                    "province": "Zamora",
                    "city": "Ferreras de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "49335",
                    "province": "Zamora",
                    "city": "Ferreras de Abajo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49336",
                    "province": "Zamora",
                    "city": "Otero de Bodas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49337",
                    "province": "Zamora",
                    "city": "Ferreras de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "49337",
                    "province": "Zamora",
                    "city": "Otero de Bodas",
                    "multiple": "1"
                },
                {
                    "zipcode": "49340",
                    "province": "Zamora",
                    "city": "Justel",
                    "multiple": "0"
                },
                {
                    "zipcode": "49341",
                    "province": "Zamora",
                    "city": "Muelas de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "49341",
                    "province": "Zamora",
                    "city": "Espadañedo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49342",
                    "province": "Zamora",
                    "city": "Espadañedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49342",
                    "province": "Zamora",
                    "city": "Rosinos de la Requejada",
                    "multiple": "1"
                },
                {
                    "zipcode": "49343",
                    "province": "Zamora",
                    "city": "Justel",
                    "multiple": "0"
                },
                {
                    "zipcode": "49344",
                    "province": "Zamora",
                    "city": "Muelas de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "49345",
                    "province": "Zamora",
                    "city": "Espadañedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49346",
                    "province": "Zamora",
                    "city": "Espadañedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49347",
                    "province": "Zamora",
                    "city": "Espadañedo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49348",
                    "province": "Zamora",
                    "city": "Asturianos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49349",
                    "province": "Zamora",
                    "city": "Rosinos de la Requejada",
                    "multiple": "0"
                },
                {
                    "zipcode": "49350",
                    "province": "Zamora",
                    "city": "Galende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49352",
                    "province": "Zamora",
                    "city": "Galende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49357",
                    "province": "Zamora",
                    "city": "San Justo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49357",
                    "province": "Zamora",
                    "city": "Robleda-Cervantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "49358",
                    "province": "Zamora",
                    "city": "Trefacio",
                    "multiple": "0"
                },
                {
                    "zipcode": "49358",
                    "province": "Zamora",
                    "city": "San Justo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49359",
                    "province": "Zamora",
                    "city": "Trefacio",
                    "multiple": "0"
                },
                {
                    "zipcode": "49359",
                    "province": "Zamora",
                    "city": "San Justo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49360",
                    "province": "Zamora",
                    "city": "Galende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49361",
                    "province": "Zamora",
                    "city": "Galende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49362",
                    "province": "Zamora",
                    "city": "Galende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49390",
                    "province": "Zamora",
                    "city": "Puebla de Sanabria",
                    "multiple": "0"
                },
                {
                    "zipcode": "49390",
                    "province": "Zamora",
                    "city": "Robleda-Cervantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "49391",
                    "province": "Zamora",
                    "city": "Pedralba de la Pradería",
                    "multiple": "0"
                },
                {
                    "zipcode": "49392",
                    "province": "Zamora",
                    "city": "Pedralba de la Pradería",
                    "multiple": "0"
                },
                {
                    "zipcode": "49393",
                    "province": "Zamora",
                    "city": "Puebla de Sanabria",
                    "multiple": "0"
                },
                {
                    "zipcode": "49394",
                    "province": "Zamora",
                    "city": "Requejo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49394",
                    "province": "Zamora",
                    "city": "Cobreros",
                    "multiple": "1"
                },
                {
                    "zipcode": "49395",
                    "province": "Zamora",
                    "city": "Cobreros",
                    "multiple": "0"
                },
                {
                    "zipcode": "49395",
                    "province": "Zamora",
                    "city": "Galende",
                    "multiple": "1"
                },
                {
                    "zipcode": "49396",
                    "province": "Zamora",
                    "city": "Cobreros",
                    "multiple": "0"
                },
                {
                    "zipcode": "49400",
                    "province": "Zamora",
                    "city": "Fuentesaúco",
                    "multiple": "0"
                },
                {
                    "zipcode": "49410",
                    "province": "Zamora",
                    "city": "Fuentelapeña",
                    "multiple": "0"
                },
                {
                    "zipcode": "49419",
                    "province": "Zamora",
                    "city": "Castrillo de la Guareña",
                    "multiple": "0"
                },
                {
                    "zipcode": "49420",
                    "province": "Zamora",
                    "city": "Vadillo de la Guareña",
                    "multiple": "0"
                },
                {
                    "zipcode": "49430",
                    "province": "Zamora",
                    "city": "Villaescusa",
                    "multiple": "0"
                },
                {
                    "zipcode": "49440",
                    "province": "Zamora",
                    "city": "Cañizal",
                    "multiple": "0"
                },
                {
                    "zipcode": "49450",
                    "province": "Zamora",
                    "city": "Vallesa de la Guareña",
                    "multiple": "0"
                },
                {
                    "zipcode": "49500",
                    "province": "Zamora",
                    "city": "Alcañices",
                    "multiple": "0"
                },
                {
                    "zipcode": "49510",
                    "province": "Zamora",
                    "city": "Fonfría",
                    "multiple": "0"
                },
                {
                    "zipcode": "49511",
                    "province": "Zamora",
                    "city": "Fonfría",
                    "multiple": "0"
                },
                {
                    "zipcode": "49512",
                    "province": "Zamora",
                    "city": "Gallegos del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "49512",
                    "province": "Zamora",
                    "city": "Rabanales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49512",
                    "province": "Zamora",
                    "city": "Fonfría",
                    "multiple": "1"
                },
                {
                    "zipcode": "49513",
                    "province": "Zamora",
                    "city": "Samir de los Caños",
                    "multiple": "0"
                },
                {
                    "zipcode": "49513",
                    "province": "Zamora",
                    "city": "Fonfría",
                    "multiple": "1"
                },
                {
                    "zipcode": "49514",
                    "province": "Zamora",
                    "city": "Alcañices",
                    "multiple": "0"
                },
                {
                    "zipcode": "49514",
                    "province": "Zamora",
                    "city": "Pino del Oro",
                    "multiple": "1"
                },
                {
                    "zipcode": "49514",
                    "province": "Zamora",
                    "city": "Fonfría",
                    "multiple": "1"
                },
                {
                    "zipcode": "49515",
                    "province": "Zamora",
                    "city": "Viñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49515",
                    "province": "Zamora",
                    "city": "Rábano de Aliste",
                    "multiple": "1"
                },
                {
                    "zipcode": "49516",
                    "province": "Zamora",
                    "city": "Trabazos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49517",
                    "province": "Zamora",
                    "city": "Alcañices",
                    "multiple": "0"
                },
                {
                    "zipcode": "49517",
                    "province": "Zamora",
                    "city": "Viñas",
                    "multiple": "1"
                },
                {
                    "zipcode": "49517",
                    "province": "Zamora",
                    "city": "Trabazos",
                    "multiple": "1"
                },
                {
                    "zipcode": "49518",
                    "province": "Zamora",
                    "city": "Rábano de Aliste",
                    "multiple": "0"
                },
                {
                    "zipcode": "49518",
                    "province": "Zamora",
                    "city": "Trabazos",
                    "multiple": "1"
                },
                {
                    "zipcode": "49518",
                    "province": "Zamora",
                    "city": "Alcañices",
                    "multiple": "1"
                },
                {
                    "zipcode": "49519",
                    "province": "Zamora",
                    "city": "Trabazos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49519",
                    "province": "Zamora",
                    "city": "Rabanales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49520",
                    "province": "Zamora",
                    "city": "Figueruela de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "49521",
                    "province": "Zamora",
                    "city": "Figueruela de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "49522",
                    "province": "Zamora",
                    "city": "Mahide",
                    "multiple": "0"
                },
                {
                    "zipcode": "49523",
                    "province": "Zamora",
                    "city": "San Vitero",
                    "multiple": "0"
                },
                {
                    "zipcode": "49524",
                    "province": "Zamora",
                    "city": "Viñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49524",
                    "province": "Zamora",
                    "city": "San Vitero",
                    "multiple": "1"
                },
                {
                    "zipcode": "49525",
                    "province": "Zamora",
                    "city": "San Vitero",
                    "multiple": "0"
                },
                {
                    "zipcode": "49525",
                    "province": "Zamora",
                    "city": "Rábano de Aliste",
                    "multiple": "1"
                },
                {
                    "zipcode": "49530",
                    "province": "Zamora",
                    "city": "Coreses",
                    "multiple": "0"
                },
                {
                    "zipcode": "49539",
                    "province": "Zamora",
                    "city": "Villalube",
                    "multiple": "0"
                },
                {
                    "zipcode": "49539",
                    "province": "Zamora",
                    "city": "Algodre",
                    "multiple": "1"
                },
                {
                    "zipcode": "49539",
                    "province": "Zamora",
                    "city": "Gallegos del Pan",
                    "multiple": "1"
                },
                {
                    "zipcode": "49540",
                    "province": "Zamora",
                    "city": "Ferreruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49540",
                    "province": "Zamora",
                    "city": "Losacio",
                    "multiple": "1"
                },
                {
                    "zipcode": "49540",
                    "province": "Zamora",
                    "city": "Olmillos de Castro",
                    "multiple": "1"
                },
                {
                    "zipcode": "49541",
                    "province": "Zamora",
                    "city": "Losacino",
                    "multiple": "0"
                },
                {
                    "zipcode": "49542",
                    "province": "Zamora",
                    "city": "Gallegos del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "49542",
                    "province": "Zamora",
                    "city": "Vegalatrave",
                    "multiple": "1"
                },
                {
                    "zipcode": "49543",
                    "province": "Zamora",
                    "city": "Losacino",
                    "multiple": "0"
                },
                {
                    "zipcode": "49550",
                    "province": "Zamora",
                    "city": "Ferreruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49559",
                    "province": "Zamora",
                    "city": "Gallegos del Río",
                    "multiple": "0"
                },
                {
                    "zipcode": "49560",
                    "province": "Zamora",
                    "city": "Mahide",
                    "multiple": "0"
                },
                {
                    "zipcode": "49561",
                    "province": "Zamora",
                    "city": "Mahide",
                    "multiple": "0"
                },
                {
                    "zipcode": "49562",
                    "province": "Zamora",
                    "city": "Villardeciervos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49563",
                    "province": "Zamora",
                    "city": "Villardeciervos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49570",
                    "province": "Zamora",
                    "city": "Lubián",
                    "multiple": "0"
                },
                {
                    "zipcode": "49571",
                    "province": "Zamora",
                    "city": "Hermisende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49572",
                    "province": "Zamora",
                    "city": "Hermisende",
                    "multiple": "0"
                },
                {
                    "zipcode": "49573",
                    "province": "Zamora",
                    "city": "Lubián",
                    "multiple": "0"
                },
                {
                    "zipcode": "49574",
                    "province": "Zamora",
                    "city": "Lubián",
                    "multiple": "0"
                },
                {
                    "zipcode": "49580",
                    "province": "Zamora",
                    "city": "Pías",
                    "multiple": "0"
                },
                {
                    "zipcode": "49582",
                    "province": "Zamora",
                    "city": "Pías",
                    "multiple": "0"
                },
                {
                    "zipcode": "49583",
                    "province": "Zamora",
                    "city": "Porto",
                    "multiple": "0"
                },
                {
                    "zipcode": "49590",
                    "province": "Zamora",
                    "city": "Matilla la Seca",
                    "multiple": "0"
                },
                {
                    "zipcode": "49590",
                    "province": "Zamora",
                    "city": "Fresno de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "49591",
                    "province": "Zamora",
                    "city": "Riofrío de Aliste",
                    "multiple": "0"
                },
                {
                    "zipcode": "49592",
                    "province": "Zamora",
                    "city": "Riofrío de Aliste",
                    "multiple": "0"
                },
                {
                    "zipcode": "49592",
                    "province": "Zamora",
                    "city": "San Vicente de la Cabeza",
                    "multiple": "1"
                },
                {
                    "zipcode": "49593",
                    "province": "Zamora",
                    "city": "Manzanal de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "49594",
                    "province": "Zamora",
                    "city": "Manzanal de Arriba",
                    "multiple": "0"
                },
                {
                    "zipcode": "49600",
                    "province": "Zamora",
                    "city": "Benavente",
                    "multiple": "0"
                },
                {
                    "zipcode": "49610",
                    "province": "Zamora",
                    "city": "Ayoó de Vidriales",
                    "multiple": "0"
                },
                {
                    "zipcode": "49610",
                    "province": "Zamora",
                    "city": "Santibáñez de Vidriales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49618",
                    "province": "Zamora",
                    "city": "Santibáñez de Vidriales",
                    "multiple": "0"
                },
                {
                    "zipcode": "49618",
                    "province": "Zamora",
                    "city": "Fuente Encalada",
                    "multiple": "1"
                },
                {
                    "zipcode": "49618",
                    "province": "Zamora",
                    "city": "Villageriz",
                    "multiple": "1"
                },
                {
                    "zipcode": "49619",
                    "province": "Zamora",
                    "city": "Santibáñez de Vidriales",
                    "multiple": "0"
                },
                {
                    "zipcode": "49619",
                    "province": "Zamora",
                    "city": "Ayoó de Vidriales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49620",
                    "province": "Zamora",
                    "city": "Santa Cristina de la Polvorosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "49621",
                    "province": "Zamora",
                    "city": "Granucillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49621",
                    "province": "Zamora",
                    "city": "Santibáñez de Vidriales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49622",
                    "province": "Zamora",
                    "city": "Quiruelas de Vidriales",
                    "multiple": "0"
                },
                {
                    "zipcode": "49622",
                    "province": "Zamora",
                    "city": "Quintanilla de Urz",
                    "multiple": "1"
                },
                {
                    "zipcode": "49622",
                    "province": "Zamora",
                    "city": "Brime de Urz",
                    "multiple": "1"
                },
                {
                    "zipcode": "49622",
                    "province": "Zamora",
                    "city": "Granucillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49622",
                    "province": "Zamora",
                    "city": "Santibáñez de Vidriales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49623",
                    "province": "Zamora",
                    "city": "Villanázar",
                    "multiple": "0"
                },
                {
                    "zipcode": "49623",
                    "province": "Zamora",
                    "city": "Quiruelas de Vidriales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49624",
                    "province": "Zamora",
                    "city": "Santibáñez de Tera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49624",
                    "province": "Zamora",
                    "city": "Micereces de Tera",
                    "multiple": "1"
                },
                {
                    "zipcode": "49625",
                    "province": "Zamora",
                    "city": "Santibáñez de Tera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49626",
                    "province": "Zamora",
                    "city": "Melgar de Tera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49626",
                    "province": "Zamora",
                    "city": "Camarzana de Tera",
                    "multiple": "1"
                },
                {
                    "zipcode": "49626",
                    "province": "Zamora",
                    "city": "Santa Croya de Tera",
                    "multiple": "1"
                },
                {
                    "zipcode": "49627",
                    "province": "Zamora",
                    "city": "Camarzana de Tera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49628",
                    "province": "Zamora",
                    "city": "San Pedro de Ceque",
                    "multiple": "0"
                },
                {
                    "zipcode": "49629",
                    "province": "Zamora",
                    "city": "Brime de Sog",
                    "multiple": "0"
                },
                {
                    "zipcode": "49630",
                    "province": "Zamora",
                    "city": "Villalpando",
                    "multiple": "0"
                },
                {
                    "zipcode": "49638",
                    "province": "Zamora",
                    "city": "Quintanilla del Olmo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49638",
                    "province": "Zamora",
                    "city": "Prado",
                    "multiple": "1"
                },
                {
                    "zipcode": "49639",
                    "province": "Zamora",
                    "city": "Quintanilla del Monte",
                    "multiple": "0"
                },
                {
                    "zipcode": "49639",
                    "province": "Zamora",
                    "city": "Cotanes del Monte",
                    "multiple": "1"
                },
                {
                    "zipcode": "49639",
                    "province": "Zamora",
                    "city": "Tapioles",
                    "multiple": "1"
                },
                {
                    "zipcode": "49640",
                    "province": "Zamora",
                    "city": "Cerecinos de Campos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49650",
                    "province": "Zamora",
                    "city": "San Esteban del Molar",
                    "multiple": "0"
                },
                {
                    "zipcode": "49660",
                    "province": "Zamora",
                    "city": "Villanueva de Azoague",
                    "multiple": "0"
                },
                {
                    "zipcode": "49660",
                    "province": "Zamora",
                    "city": "Castrogonzalo",
                    "multiple": "1"
                },
                {
                    "zipcode": "49670",
                    "province": "Zamora",
                    "city": "Fuentes de Ropel",
                    "multiple": "0"
                },
                {
                    "zipcode": "49680",
                    "province": "Zamora",
                    "city": "San Miguel del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "49680",
                    "province": "Zamora",
                    "city": "Valdescorriel",
                    "multiple": "1"
                },
                {
                    "zipcode": "49690",
                    "province": "Zamora",
                    "city": "San Cristóbal de Entreviñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49691",
                    "province": "Zamora",
                    "city": "San Cristóbal de Entreviñas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49692",
                    "province": "Zamora",
                    "city": "Matilla de Arzón",
                    "multiple": "0"
                },
                {
                    "zipcode": "49693",
                    "province": "Zamora",
                    "city": "Fresno de la Polvorosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "49693",
                    "province": "Zamora",
                    "city": "Morales de Rey",
                    "multiple": "1"
                },
                {
                    "zipcode": "49694",
                    "province": "Zamora",
                    "city": "Manganeses de la Polvorosa",
                    "multiple": "0"
                },
                {
                    "zipcode": "49695",
                    "province": "Zamora",
                    "city": "Villaferrueña",
                    "multiple": "0"
                },
                {
                    "zipcode": "49696",
                    "province": "Zamora",
                    "city": "Arrabalde",
                    "multiple": "0"
                },
                {
                    "zipcode": "49696",
                    "province": "Zamora",
                    "city": "Santa María de la Vega",
                    "multiple": "1"
                },
                {
                    "zipcode": "49696",
                    "province": "Zamora",
                    "city": "Alcubilla de Nogales",
                    "multiple": "1"
                },
                {
                    "zipcode": "49697",
                    "province": "Zamora",
                    "city": "Pueblica de Valverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "49697",
                    "province": "Zamora",
                    "city": "Morales de Valverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "49697",
                    "province": "Zamora",
                    "city": "Navianos de Valverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "49697",
                    "province": "Zamora",
                    "city": "Villaveza de Valverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "49697",
                    "province": "Zamora",
                    "city": "Villanázar",
                    "multiple": "1"
                },
                {
                    "zipcode": "49698",
                    "province": "Zamora",
                    "city": "Friera de Valverde",
                    "multiple": "0"
                },
                {
                    "zipcode": "49698",
                    "province": "Zamora",
                    "city": "Burganes de Valverde",
                    "multiple": "1"
                },
                {
                    "zipcode": "49698",
                    "province": "Zamora",
                    "city": "Villanázar",
                    "multiple": "1"
                },
                {
                    "zipcode": "49698",
                    "province": "Zamora",
                    "city": "Bretocino",
                    "multiple": "1"
                },
                {
                    "zipcode": "49699",
                    "province": "Zamora",
                    "city": "Villanueva de Azoague",
                    "multiple": "0"
                },
                {
                    "zipcode": "49699",
                    "province": "Zamora",
                    "city": "Santa Colomba de las Monjas",
                    "multiple": "1"
                },
                {
                    "zipcode": "49699",
                    "province": "Zamora",
                    "city": "Arcos de la Polvorosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "49699",
                    "province": "Zamora",
                    "city": "Milles de la Polvorosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "49700",
                    "province": "Zamora",
                    "city": "Corrales del Vino",
                    "multiple": "0"
                },
                {
                    "zipcode": "49706",
                    "province": "Zamora",
                    "city": "Corrales del Vino",
                    "multiple": "0"
                },
                {
                    "zipcode": "49707",
                    "province": "Zamora",
                    "city": "Santa Clara de Avedillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49708",
                    "province": "Zamora",
                    "city": "Villanueva de Campeán",
                    "multiple": "0"
                },
                {
                    "zipcode": "49708",
                    "province": "Zamora",
                    "city": "Casaseca de Campeán",
                    "multiple": "1"
                },
                {
                    "zipcode": "49709",
                    "province": "Zamora",
                    "city": "Cabañas de Sayago",
                    "multiple": "0"
                },
                {
                    "zipcode": "49710",
                    "province": "Zamora",
                    "city": "Cubo de Tierra del Vino, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "49714",
                    "province": "Zamora",
                    "city": "Fuentespreadas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49715",
                    "province": "Zamora",
                    "city": "Piñero, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "49716",
                    "province": "Zamora",
                    "city": "Argujillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49717",
                    "province": "Zamora",
                    "city": "San Miguel de la Ribera",
                    "multiple": "0"
                },
                {
                    "zipcode": "49717",
                    "province": "Zamora",
                    "city": "Cuelgamures",
                    "multiple": "1"
                },
                {
                    "zipcode": "49718",
                    "province": "Zamora",
                    "city": "Mayalde",
                    "multiple": "0"
                },
                {
                    "zipcode": "49719",
                    "province": "Zamora",
                    "city": "Maderal, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "49719",
                    "province": "Zamora",
                    "city": "Villamor de los Escuderos",
                    "multiple": "1"
                },
                {
                    "zipcode": "49720",
                    "province": "Zamora",
                    "city": "Perdigón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "49721",
                    "province": "Zamora",
                    "city": "Entrala",
                    "multiple": "0"
                },
                {
                    "zipcode": "49722",
                    "province": "Zamora",
                    "city": "Perdigón, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "49730",
                    "province": "Zamora",
                    "city": "Cubillos",
                    "multiple": "0"
                },
                {
                    "zipcode": "49731",
                    "province": "Zamora",
                    "city": "Moreruela de los Infanzones",
                    "multiple": "0"
                },
                {
                    "zipcode": "49740",
                    "province": "Zamora",
                    "city": "Granja de Moreruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49741",
                    "province": "Zamora",
                    "city": "Granja de Moreruela",
                    "multiple": "0"
                },
                {
                    "zipcode": "49742",
                    "province": "Zamora",
                    "city": "Manganeses de la Lampreana",
                    "multiple": "0"
                },
                {
                    "zipcode": "49743",
                    "province": "Zamora",
                    "city": "San Cebrián de Castro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49750",
                    "province": "Zamora",
                    "city": "Santovenia",
                    "multiple": "0"
                },
                {
                    "zipcode": "49751",
                    "province": "Zamora",
                    "city": "Bretó",
                    "multiple": "0"
                },
                {
                    "zipcode": "49760",
                    "province": "Zamora",
                    "city": "Villaveza del Agua",
                    "multiple": "0"
                },
                {
                    "zipcode": "49760",
                    "province": "Zamora",
                    "city": "Barcial del Barco",
                    "multiple": "1"
                },
                {
                    "zipcode": "49770",
                    "province": "Zamora",
                    "city": "Villabrázaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49780",
                    "province": "Zamora",
                    "city": "Pobladura del Valle",
                    "multiple": "0"
                },
                {
                    "zipcode": "49781",
                    "province": "Zamora",
                    "city": "Torre del Valle, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "49782",
                    "province": "Zamora",
                    "city": "Villabrázaro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49783",
                    "province": "Zamora",
                    "city": "Coomonte",
                    "multiple": "0"
                },
                {
                    "zipcode": "49783",
                    "province": "Zamora",
                    "city": "Maire de Castroponce",
                    "multiple": "1"
                },
                {
                    "zipcode": "49800",
                    "province": "Zamora",
                    "city": "Toro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49810",
                    "province": "Zamora",
                    "city": "Morales de Toro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49820",
                    "province": "Zamora",
                    "city": "Villabuena del Puente",
                    "multiple": "0"
                },
                {
                    "zipcode": "49820",
                    "province": "Zamora",
                    "city": "Toro",
                    "multiple": "1"
                },
                {
                    "zipcode": "49830",
                    "province": "Zamora",
                    "city": "Belver de los Montes",
                    "multiple": "0"
                },
                {
                    "zipcode": "49831",
                    "province": "Zamora",
                    "city": "Bustillo del Oro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49832",
                    "province": "Zamora",
                    "city": "Malva",
                    "multiple": "0"
                },
                {
                    "zipcode": "49833",
                    "province": "Zamora",
                    "city": "Fuentesecas",
                    "multiple": "0"
                },
                {
                    "zipcode": "49834",
                    "province": "Zamora",
                    "city": "Abezames",
                    "multiple": "0"
                },
                {
                    "zipcode": "49835",
                    "province": "Zamora",
                    "city": "Pozoantiguo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49836",
                    "province": "Zamora",
                    "city": "Toro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49840",
                    "province": "Zamora",
                    "city": "Vezdemarbán",
                    "multiple": "0"
                },
                {
                    "zipcode": "49850",
                    "province": "Zamora",
                    "city": "Pinilla de Toro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49860",
                    "province": "Zamora",
                    "city": "Villalonso",
                    "multiple": "0"
                },
                {
                    "zipcode": "49870",
                    "province": "Zamora",
                    "city": "Villavendimio",
                    "multiple": "0"
                },
                {
                    "zipcode": "49871",
                    "province": "Zamora",
                    "city": "Villardondiego",
                    "multiple": "0"
                },
                {
                    "zipcode": "49880",
                    "province": "Zamora",
                    "city": "Peleagonzalo",
                    "multiple": "0"
                },
                {
                    "zipcode": "49881",
                    "province": "Zamora",
                    "city": "Toro",
                    "multiple": "0"
                },
                {
                    "zipcode": "49882",
                    "province": "Zamora",
                    "city": "Valdefinjas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50001",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50002",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50003",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50004",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50005",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50006",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50007",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50008",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50009",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50010",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50011",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50012",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50013",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50014",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50015",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50016",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50017",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50018",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50019",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50020",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50021",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50022",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50057",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50059",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50100",
                    "province": "Zaragoza",
                    "city": "Almunia de Doña Godina, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "50108",
                    "province": "Zaragoza",
                    "city": "Almonacid de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "50109",
                    "province": "Zaragoza",
                    "city": "Alpartir",
                    "multiple": "0"
                },
                {
                    "zipcode": "50120",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50130",
                    "province": "Zaragoza",
                    "city": "Belchite",
                    "multiple": "0"
                },
                {
                    "zipcode": "50131",
                    "province": "Zaragoza",
                    "city": "Lécera",
                    "multiple": "0"
                },
                {
                    "zipcode": "50132",
                    "province": "Zaragoza",
                    "city": "Codo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50133",
                    "province": "Zaragoza",
                    "city": "Almonacid de la Cuba",
                    "multiple": "0"
                },
                {
                    "zipcode": "50134",
                    "province": "Zaragoza",
                    "city": "Lagata",
                    "multiple": "0"
                },
                {
                    "zipcode": "50134",
                    "province": "Zaragoza",
                    "city": "Samper del Salz",
                    "multiple": "1"
                },
                {
                    "zipcode": "50135",
                    "province": "Zaragoza",
                    "city": "Fuentes de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50135",
                    "province": "Zaragoza",
                    "city": "Mediana de Aragón",
                    "multiple": "1"
                },
                {
                    "zipcode": "50136",
                    "province": "Zaragoza",
                    "city": "Letux",
                    "multiple": "0"
                },
                {
                    "zipcode": "50137",
                    "province": "Zaragoza",
                    "city": "Puebla de Albortón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50138",
                    "province": "Zaragoza",
                    "city": "Valmadrid",
                    "multiple": "0"
                },
                {
                    "zipcode": "50139",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50140",
                    "province": "Zaragoza",
                    "city": "Azuara",
                    "multiple": "0"
                },
                {
                    "zipcode": "50141",
                    "province": "Zaragoza",
                    "city": "Jaulín",
                    "multiple": "0"
                },
                {
                    "zipcode": "50142",
                    "province": "Zaragoza",
                    "city": "Fuendetodos",
                    "multiple": "0"
                },
                {
                    "zipcode": "50143",
                    "province": "Zaragoza",
                    "city": "Moyuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "50143",
                    "province": "Zaragoza",
                    "city": "Plenas",
                    "multiple": "1"
                },
                {
                    "zipcode": "50144",
                    "province": "Zaragoza",
                    "city": "Moneva",
                    "multiple": "0"
                },
                {
                    "zipcode": "50150",
                    "province": "Zaragoza",
                    "city": "Herrera de los Navarros",
                    "multiple": "0"
                },
                {
                    "zipcode": "50151",
                    "province": "Zaragoza",
                    "city": "Luesma",
                    "multiple": "0"
                },
                {
                    "zipcode": "50152",
                    "province": "Zaragoza",
                    "city": "Mezalocha",
                    "multiple": "0"
                },
                {
                    "zipcode": "50153",
                    "province": "Zaragoza",
                    "city": "Villanueva de Huerva",
                    "multiple": "0"
                },
                {
                    "zipcode": "50154",
                    "province": "Zaragoza",
                    "city": "Tosos",
                    "multiple": "0"
                },
                {
                    "zipcode": "50155",
                    "province": "Zaragoza",
                    "city": "Aguilón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50156",
                    "province": "Zaragoza",
                    "city": "Villar de los Navarros",
                    "multiple": "0"
                },
                {
                    "zipcode": "50160",
                    "province": "Zaragoza",
                    "city": "Leciñena",
                    "multiple": "0"
                },
                {
                    "zipcode": "50160",
                    "province": "Zaragoza",
                    "city": "Muela, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "50161",
                    "province": "Zaragoza",
                    "city": "Perdiguera",
                    "multiple": "0"
                },
                {
                    "zipcode": "50162",
                    "province": "Zaragoza",
                    "city": "Villamayor de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "50163",
                    "province": "Zaragoza",
                    "city": "Farlete",
                    "multiple": "0"
                },
                {
                    "zipcode": "50164",
                    "province": "Zaragoza",
                    "city": "Monegrillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50170",
                    "province": "Zaragoza",
                    "city": "Mequinenza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50170",
                    "province": "Zaragoza",
                    "city": "Puebla de Alfindén, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "50171",
                    "province": "Zaragoza",
                    "city": "Puebla de Alfindén, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "50172",
                    "province": "Zaragoza",
                    "city": "Alfajarín",
                    "multiple": "0"
                },
                {
                    "zipcode": "50173",
                    "province": "Zaragoza",
                    "city": "Nuez de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50174",
                    "province": "Zaragoza",
                    "city": "Villafranca de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50175",
                    "province": "Zaragoza",
                    "city": "Osera de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50177",
                    "province": "Zaragoza",
                    "city": "Bujaraloz",
                    "multiple": "0"
                },
                {
                    "zipcode": "50178",
                    "province": "Zaragoza",
                    "city": "Almolda, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "50180",
                    "province": "Zaragoza",
                    "city": "Utebo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50190",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50191",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50193",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50194",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50195",
                    "province": "Zaragoza",
                    "city": "Pastriz",
                    "multiple": "0"
                },
                {
                    "zipcode": "50196",
                    "province": "Zaragoza",
                    "city": "Muela, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "50200",
                    "province": "Zaragoza",
                    "city": "Ateca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50210",
                    "province": "Zaragoza",
                    "city": "Nuévalos",
                    "multiple": "0"
                },
                {
                    "zipcode": "50211",
                    "province": "Zaragoza",
                    "city": "Castejón de las Armas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50212",
                    "province": "Zaragoza",
                    "city": "Carenas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50213",
                    "province": "Zaragoza",
                    "city": "Cimballa",
                    "multiple": "0"
                },
                {
                    "zipcode": "50213",
                    "province": "Zaragoza",
                    "city": "Nuévalos",
                    "multiple": "1"
                },
                {
                    "zipcode": "50213",
                    "province": "Zaragoza",
                    "city": "Monterde",
                    "multiple": "1"
                },
                {
                    "zipcode": "50214",
                    "province": "Zaragoza",
                    "city": "Campillo de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50215",
                    "province": "Zaragoza",
                    "city": "Moros",
                    "multiple": "0"
                },
                {
                    "zipcode": "50216",
                    "province": "Zaragoza",
                    "city": "Villalengua",
                    "multiple": "0"
                },
                {
                    "zipcode": "50217",
                    "province": "Zaragoza",
                    "city": "Torrijo de la Cañada",
                    "multiple": "0"
                },
                {
                    "zipcode": "50219",
                    "province": "Zaragoza",
                    "city": "Valtorres",
                    "multiple": "0"
                },
                {
                    "zipcode": "50219",
                    "province": "Zaragoza",
                    "city": "Munébrega",
                    "multiple": "1"
                },
                {
                    "zipcode": "50219",
                    "province": "Zaragoza",
                    "city": "Vilueña, La",
                    "multiple": "1"
                },
                {
                    "zipcode": "50220",
                    "province": "Zaragoza",
                    "city": "Ariza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50227",
                    "province": "Zaragoza",
                    "city": "Sisamón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50228",
                    "province": "Zaragoza",
                    "city": "Cabolafuente",
                    "multiple": "0"
                },
                {
                    "zipcode": "50229",
                    "province": "Zaragoza",
                    "city": "Bordalba",
                    "multiple": "0"
                },
                {
                    "zipcode": "50230",
                    "province": "Zaragoza",
                    "city": "Alhama de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50236",
                    "province": "Zaragoza",
                    "city": "Ibdes",
                    "multiple": "0"
                },
                {
                    "zipcode": "50237",
                    "province": "Zaragoza",
                    "city": "Jaraba",
                    "multiple": "0"
                },
                {
                    "zipcode": "50238",
                    "province": "Zaragoza",
                    "city": "Calmarza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50238",
                    "province": "Zaragoza",
                    "city": "Godojos",
                    "multiple": "1"
                },
                {
                    "zipcode": "50239",
                    "province": "Zaragoza",
                    "city": "Bubierca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50239",
                    "province": "Zaragoza",
                    "city": "Embid de Ariza",
                    "multiple": "1"
                },
                {
                    "zipcode": "50239",
                    "province": "Zaragoza",
                    "city": "Contamina",
                    "multiple": "1"
                },
                {
                    "zipcode": "50240",
                    "province": "Zaragoza",
                    "city": "Cadrete",
                    "multiple": "0"
                },
                {
                    "zipcode": "50240",
                    "province": "Zaragoza",
                    "city": "Morés",
                    "multiple": "1"
                },
                {
                    "zipcode": "50246",
                    "province": "Zaragoza",
                    "city": "Brea de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50247",
                    "province": "Zaragoza",
                    "city": "Morés",
                    "multiple": "0"
                },
                {
                    "zipcode": "50248",
                    "province": "Zaragoza",
                    "city": "Sestrica",
                    "multiple": "0"
                },
                {
                    "zipcode": "50249",
                    "province": "Zaragoza",
                    "city": "Sestrica",
                    "multiple": "0"
                },
                {
                    "zipcode": "50250",
                    "province": "Zaragoza",
                    "city": "Illueca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50257",
                    "province": "Zaragoza",
                    "city": "Gotor",
                    "multiple": "0"
                },
                {
                    "zipcode": "50258",
                    "province": "Zaragoza",
                    "city": "Oseja",
                    "multiple": "0"
                },
                {
                    "zipcode": "50258",
                    "province": "Zaragoza",
                    "city": "Jarque",
                    "multiple": "1"
                },
                {
                    "zipcode": "50259",
                    "province": "Zaragoza",
                    "city": "Pomer",
                    "multiple": "0"
                },
                {
                    "zipcode": "50259",
                    "province": "Zaragoza",
                    "city": "Aranda de Moncayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "50260",
                    "province": "Zaragoza",
                    "city": "Morata de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50266",
                    "province": "Zaragoza",
                    "city": "Arándiga",
                    "multiple": "0"
                },
                {
                    "zipcode": "50267",
                    "province": "Zaragoza",
                    "city": "Mesones de Isuela",
                    "multiple": "0"
                },
                {
                    "zipcode": "50268",
                    "province": "Zaragoza",
                    "city": "Calcena",
                    "multiple": "0"
                },
                {
                    "zipcode": "50268",
                    "province": "Zaragoza",
                    "city": "Trasobares",
                    "multiple": "1"
                },
                {
                    "zipcode": "50268",
                    "province": "Zaragoza",
                    "city": "Purujosa",
                    "multiple": "1"
                },
                {
                    "zipcode": "50269",
                    "province": "Zaragoza",
                    "city": "Nigüella",
                    "multiple": "0"
                },
                {
                    "zipcode": "50269",
                    "province": "Zaragoza",
                    "city": "Tierga",
                    "multiple": "1"
                },
                {
                    "zipcode": "50269",
                    "province": "Zaragoza",
                    "city": "Chodes",
                    "multiple": "1"
                },
                {
                    "zipcode": "50270",
                    "province": "Zaragoza",
                    "city": "Ricla",
                    "multiple": "0"
                },
                {
                    "zipcode": "50280",
                    "province": "Zaragoza",
                    "city": "Calatorao",
                    "multiple": "0"
                },
                {
                    "zipcode": "50290",
                    "province": "Zaragoza",
                    "city": "Épila",
                    "multiple": "0"
                },
                {
                    "zipcode": "50291",
                    "province": "Zaragoza",
                    "city": "Monreal de Ariza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50292",
                    "province": "Zaragoza",
                    "city": "Cetina",
                    "multiple": "0"
                },
                {
                    "zipcode": "50293",
                    "province": "Zaragoza",
                    "city": "Terrer",
                    "multiple": "0"
                },
                {
                    "zipcode": "50294",
                    "province": "Zaragoza",
                    "city": "Lucena de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50294",
                    "province": "Zaragoza",
                    "city": "Salillas de Jalón",
                    "multiple": "1"
                },
                {
                    "zipcode": "50295",
                    "province": "Zaragoza",
                    "city": "Lumpiaque",
                    "multiple": "0"
                },
                {
                    "zipcode": "50295",
                    "province": "Zaragoza",
                    "city": "Rueda de Jalón",
                    "multiple": "1"
                },
                {
                    "zipcode": "50296",
                    "province": "Zaragoza",
                    "city": "Urrea de Jalón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50296",
                    "province": "Zaragoza",
                    "city": "Bardallur",
                    "multiple": "1"
                },
                {
                    "zipcode": "50296",
                    "province": "Zaragoza",
                    "city": "Plasencia de Jalón",
                    "multiple": "1"
                },
                {
                    "zipcode": "50297",
                    "province": "Zaragoza",
                    "city": "Pleitas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50297",
                    "province": "Zaragoza",
                    "city": "Grisén",
                    "multiple": "1"
                },
                {
                    "zipcode": "50297",
                    "province": "Zaragoza",
                    "city": "Bárboles",
                    "multiple": "1"
                },
                {
                    "zipcode": "50298",
                    "province": "Zaragoza",
                    "city": "Pinseque",
                    "multiple": "0"
                },
                {
                    "zipcode": "50298",
                    "province": "Zaragoza",
                    "city": "Pinseque",
                    "multiple": "1"
                },
                {
                    "zipcode": "50299",
                    "province": "Zaragoza",
                    "city": "Sabiñán",
                    "multiple": "0"
                },
                {
                    "zipcode": "50299",
                    "province": "Zaragoza",
                    "city": "Calatayud",
                    "multiple": "1"
                },
                {
                    "zipcode": "50299",
                    "province": "Zaragoza",
                    "city": "Paracuellos de la Ribera",
                    "multiple": "1"
                },
                {
                    "zipcode": "50300",
                    "province": "Zaragoza",
                    "city": "Calatayud",
                    "multiple": "0"
                },
                {
                    "zipcode": "50310",
                    "province": "Zaragoza",
                    "city": "Villarroya de la Sierra",
                    "multiple": "0"
                },
                {
                    "zipcode": "50311",
                    "province": "Zaragoza",
                    "city": "Torralba de Ribota",
                    "multiple": "0"
                },
                {
                    "zipcode": "50312",
                    "province": "Zaragoza",
                    "city": "Cervera de la Cañada",
                    "multiple": "0"
                },
                {
                    "zipcode": "50313",
                    "province": "Zaragoza",
                    "city": "Aniñón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50314",
                    "province": "Zaragoza",
                    "city": "Clarés de Ribota",
                    "multiple": "0"
                },
                {
                    "zipcode": "50315",
                    "province": "Zaragoza",
                    "city": "Malanquilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "50316",
                    "province": "Zaragoza",
                    "city": "Torrelapaja",
                    "multiple": "0"
                },
                {
                    "zipcode": "50316",
                    "province": "Zaragoza",
                    "city": "Bijuesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "50316",
                    "province": "Zaragoza",
                    "city": "Berdejo",
                    "multiple": "1"
                },
                {
                    "zipcode": "50320",
                    "province": "Zaragoza",
                    "city": "Frasno, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50321",
                    "province": "Zaragoza",
                    "city": "Frasno, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50322",
                    "province": "Zaragoza",
                    "city": "Frasno, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50323",
                    "province": "Zaragoza",
                    "city": "Frasno, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50324",
                    "province": "Zaragoza",
                    "city": "Santa Cruz de Grío",
                    "multiple": "0"
                },
                {
                    "zipcode": "50325",
                    "province": "Zaragoza",
                    "city": "Tobed",
                    "multiple": "0"
                },
                {
                    "zipcode": "50326",
                    "province": "Zaragoza",
                    "city": "Codos",
                    "multiple": "0"
                },
                {
                    "zipcode": "50330",
                    "province": "Zaragoza",
                    "city": "Miedes de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50331",
                    "province": "Zaragoza",
                    "city": "Mara",
                    "multiple": "0"
                },
                {
                    "zipcode": "50331",
                    "province": "Zaragoza",
                    "city": "Ruesca",
                    "multiple": "1"
                },
                {
                    "zipcode": "50331",
                    "province": "Zaragoza",
                    "city": "Orera",
                    "multiple": "1"
                },
                {
                    "zipcode": "50332",
                    "province": "Zaragoza",
                    "city": "Belmonte de Gracián",
                    "multiple": "0"
                },
                {
                    "zipcode": "50333",
                    "province": "Zaragoza",
                    "city": "Villalba de Perejil",
                    "multiple": "0"
                },
                {
                    "zipcode": "50334",
                    "province": "Zaragoza",
                    "city": "Sediles",
                    "multiple": "0"
                },
                {
                    "zipcode": "50335",
                    "province": "Zaragoza",
                    "city": "Calatayud",
                    "multiple": "0"
                },
                {
                    "zipcode": "50336",
                    "province": "Zaragoza",
                    "city": "Calatayud",
                    "multiple": "0"
                },
                {
                    "zipcode": "50340",
                    "province": "Zaragoza",
                    "city": "Maluenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "50341",
                    "province": "Zaragoza",
                    "city": "Olvés",
                    "multiple": "0"
                },
                {
                    "zipcode": "50342",
                    "province": "Zaragoza",
                    "city": "Paracuellos de Jiloca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50343",
                    "province": "Zaragoza",
                    "city": "Velilla de Jiloca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50344",
                    "province": "Zaragoza",
                    "city": "Morata de Jiloca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50345",
                    "province": "Zaragoza",
                    "city": "Alarba",
                    "multiple": "0"
                },
                {
                    "zipcode": "50346",
                    "province": "Zaragoza",
                    "city": "Castejón de Alarba",
                    "multiple": "0"
                },
                {
                    "zipcode": "50347",
                    "province": "Zaragoza",
                    "city": "Acered",
                    "multiple": "0"
                },
                {
                    "zipcode": "50348",
                    "province": "Zaragoza",
                    "city": "Atea",
                    "multiple": "0"
                },
                {
                    "zipcode": "50351",
                    "province": "Zaragoza",
                    "city": "Cubel",
                    "multiple": "0"
                },
                {
                    "zipcode": "50360",
                    "province": "Zaragoza",
                    "city": "Daroca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50366",
                    "province": "Zaragoza",
                    "city": "Orcajo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50366",
                    "province": "Zaragoza",
                    "city": "Balconchán",
                    "multiple": "1"
                },
                {
                    "zipcode": "50366",
                    "province": "Zaragoza",
                    "city": "Manchones",
                    "multiple": "1"
                },
                {
                    "zipcode": "50366",
                    "province": "Zaragoza",
                    "city": "Murero",
                    "multiple": "1"
                },
                {
                    "zipcode": "50367",
                    "province": "Zaragoza",
                    "city": "Retascón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50367",
                    "province": "Zaragoza",
                    "city": "Langa del Castillo",
                    "multiple": "1"
                },
                {
                    "zipcode": "50368",
                    "province": "Zaragoza",
                    "city": "Mainar",
                    "multiple": "0"
                },
                {
                    "zipcode": "50368",
                    "province": "Zaragoza",
                    "city": "Torralbilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "50368",
                    "province": "Zaragoza",
                    "city": "Villarroya del Campo",
                    "multiple": "1"
                },
                {
                    "zipcode": "50368",
                    "province": "Zaragoza",
                    "city": "Cerveruela",
                    "multiple": "1"
                },
                {
                    "zipcode": "50369",
                    "province": "Zaragoza",
                    "city": "Lechón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50369",
                    "province": "Zaragoza",
                    "city": "Anento",
                    "multiple": "1"
                },
                {
                    "zipcode": "50369",
                    "province": "Zaragoza",
                    "city": "cityvilla",
                    "multiple": "1"
                },
                {
                    "zipcode": "50370",
                    "province": "Zaragoza",
                    "city": "Villanueva de Jiloca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50371",
                    "province": "Zaragoza",
                    "city": "Valdehorna",
                    "multiple": "0"
                },
                {
                    "zipcode": "50372",
                    "province": "Zaragoza",
                    "city": "Val de San Martín",
                    "multiple": "0"
                },
                {
                    "zipcode": "50373",
                    "province": "Zaragoza",
                    "city": "Gallocanta",
                    "multiple": "0"
                },
                {
                    "zipcode": "50373",
                    "province": "Zaragoza",
                    "city": "Cuerlas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "50373",
                    "province": "Zaragoza",
                    "city": "Berrueco",
                    "multiple": "1"
                },
                {
                    "zipcode": "50373",
                    "province": "Zaragoza",
                    "city": "Santed",
                    "multiple": "1"
                },
                {
                    "zipcode": "50374",
                    "province": "Zaragoza",
                    "city": "Aldehuela de Liestos",
                    "multiple": "0"
                },
                {
                    "zipcode": "50374",
                    "province": "Zaragoza",
                    "city": "Used",
                    "multiple": "1"
                },
                {
                    "zipcode": "50374",
                    "province": "Zaragoza",
                    "city": "Torralba de los Frailes",
                    "multiple": "1"
                },
                {
                    "zipcode": "50375",
                    "province": "Zaragoza",
                    "city": "Abanto",
                    "multiple": "0"
                },
                {
                    "zipcode": "50376",
                    "province": "Zaragoza",
                    "city": "Cubel",
                    "multiple": "0"
                },
                {
                    "zipcode": "50390",
                    "province": "Zaragoza",
                    "city": "Fuentes de Jiloca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50391",
                    "province": "Zaragoza",
                    "city": "Montón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50391",
                    "province": "Zaragoza",
                    "city": "Villafeliche",
                    "multiple": "1"
                },
                {
                    "zipcode": "50400",
                    "province": "Zaragoza",
                    "city": "Cariñena",
                    "multiple": "0"
                },
                {
                    "zipcode": "50408",
                    "province": "Zaragoza",
                    "city": "Aguarón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50409",
                    "province": "Zaragoza",
                    "city": "Cosuenda",
                    "multiple": "0"
                },
                {
                    "zipcode": "50410",
                    "province": "Zaragoza",
                    "city": "Cuarte de Huerva",
                    "multiple": "0"
                },
                {
                    "zipcode": "50420",
                    "province": "Zaragoza",
                    "city": "Cadrete",
                    "multiple": "0"
                },
                {
                    "zipcode": "50430",
                    "province": "Zaragoza",
                    "city": "María de Huerva",
                    "multiple": "0"
                },
                {
                    "zipcode": "50440",
                    "province": "Zaragoza",
                    "city": "Mozota",
                    "multiple": "0"
                },
                {
                    "zipcode": "50441",
                    "province": "Zaragoza",
                    "city": "Botorrita",
                    "multiple": "0"
                },
                {
                    "zipcode": "50450",
                    "province": "Zaragoza",
                    "city": "Muel",
                    "multiple": "0"
                },
                {
                    "zipcode": "50460",
                    "province": "Zaragoza",
                    "city": "Longares",
                    "multiple": "0"
                },
                {
                    "zipcode": "50461",
                    "province": "Zaragoza",
                    "city": "Alfamén",
                    "multiple": "0"
                },
                {
                    "zipcode": "50470",
                    "province": "Zaragoza",
                    "city": "Encinacorba",
                    "multiple": "0"
                },
                {
                    "zipcode": "50480",
                    "province": "Zaragoza",
                    "city": "Paniza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50481",
                    "province": "Zaragoza",
                    "city": "Aladrén",
                    "multiple": "0"
                },
                {
                    "zipcode": "50482",
                    "province": "Zaragoza",
                    "city": "Vistabella",
                    "multiple": "0"
                },
                {
                    "zipcode": "50490",
                    "province": "Zaragoza",
                    "city": "Villarreal de Huerva",
                    "multiple": "0"
                },
                {
                    "zipcode": "50490",
                    "province": "Zaragoza",
                    "city": "Villadoz",
                    "multiple": "1"
                },
                {
                    "zipcode": "50491",
                    "province": "Zaragoza",
                    "city": "Badules",
                    "multiple": "0"
                },
                {
                    "zipcode": "50491",
                    "province": "Zaragoza",
                    "city": "Fombuena",
                    "multiple": "1"
                },
                {
                    "zipcode": "50491",
                    "province": "Zaragoza",
                    "city": "Romanos",
                    "multiple": "1"
                },
                {
                    "zipcode": "50500",
                    "province": "Zaragoza",
                    "city": "Tarazona",
                    "multiple": "0"
                },
                {
                    "zipcode": "50510",
                    "province": "Zaragoza",
                    "city": "Novallas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50511",
                    "province": "Zaragoza",
                    "city": "Malón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50512",
                    "province": "Zaragoza",
                    "city": "Villar de los Navarros",
                    "multiple": "0"
                },
                {
                    "zipcode": "50512",
                    "province": "Zaragoza",
                    "city": "Torrellas",
                    "multiple": "1"
                },
                {
                    "zipcode": "50513",
                    "province": "Zaragoza",
                    "city": "Tarazona",
                    "multiple": "0"
                },
                {
                    "zipcode": "50513",
                    "province": "Zaragoza",
                    "city": "Fayos, Los",
                    "multiple": "1"
                },
                {
                    "zipcode": "50513",
                    "province": "Zaragoza",
                    "city": "Grisel",
                    "multiple": "1"
                },
                {
                    "zipcode": "50513",
                    "province": "Zaragoza",
                    "city": "Santa Cruz de Moncayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "50513",
                    "province": "Zaragoza",
                    "city": "Vierlas",
                    "multiple": "1"
                },
                {
                    "zipcode": "50514",
                    "province": "Zaragoza",
                    "city": "Tarazona",
                    "multiple": "0"
                },
                {
                    "zipcode": "50520",
                    "province": "Zaragoza",
                    "city": "Magallón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50529",
                    "province": "Zaragoza",
                    "city": "Alberite de San Juan",
                    "multiple": "0"
                },
                {
                    "zipcode": "50529",
                    "province": "Zaragoza",
                    "city": "Pozuelo de Aragón",
                    "multiple": "1"
                },
                {
                    "zipcode": "50529",
                    "province": "Zaragoza",
                    "city": "Fuendejalón",
                    "multiple": "1"
                },
                {
                    "zipcode": "50530",
                    "province": "Zaragoza",
                    "city": "Novillas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50540",
                    "province": "Zaragoza",
                    "city": "Borja",
                    "multiple": "0"
                },
                {
                    "zipcode": "50540",
                    "province": "Zaragoza",
                    "city": "Muel",
                    "multiple": "1"
                },
                {
                    "zipcode": "50546",
                    "province": "Zaragoza",
                    "city": "Bulbuente",
                    "multiple": "0"
                },
                {
                    "zipcode": "50546",
                    "province": "Zaragoza",
                    "city": "Talamantes",
                    "multiple": "1"
                },
                {
                    "zipcode": "50546",
                    "province": "Zaragoza",
                    "city": "Ambel",
                    "multiple": "1"
                },
                {
                    "zipcode": "50547",
                    "province": "Zaragoza",
                    "city": "Tabuenca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50547",
                    "province": "Zaragoza",
                    "city": "Bureta",
                    "multiple": "1"
                },
                {
                    "zipcode": "50548",
                    "province": "Zaragoza",
                    "city": "Buste, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50549",
                    "province": "Zaragoza",
                    "city": "Maleján",
                    "multiple": "0"
                },
                {
                    "zipcode": "50549",
                    "province": "Zaragoza",
                    "city": "Albeta",
                    "multiple": "1"
                },
                {
                    "zipcode": "50550",
                    "province": "Zaragoza",
                    "city": "Mallén",
                    "multiple": "0"
                },
                {
                    "zipcode": "50560",
                    "province": "Zaragoza",
                    "city": "Agón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50561",
                    "province": "Zaragoza",
                    "city": "Bisimbre",
                    "multiple": "0"
                },
                {
                    "zipcode": "50562",
                    "province": "Zaragoza",
                    "city": "Fréscano",
                    "multiple": "0"
                },
                {
                    "zipcode": "50570",
                    "province": "Zaragoza",
                    "city": "Ainzón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50580",
                    "province": "Zaragoza",
                    "city": "Vera de Moncayo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50581",
                    "province": "Zaragoza",
                    "city": "Lituénigo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50582",
                    "province": "Zaragoza",
                    "city": "Vistabella",
                    "multiple": "0"
                },
                {
                    "zipcode": "50582",
                    "province": "Zaragoza",
                    "city": "Litago",
                    "multiple": "1"
                },
                {
                    "zipcode": "50583",
                    "province": "Zaragoza",
                    "city": "Trasmoz",
                    "multiple": "0"
                },
                {
                    "zipcode": "50584",
                    "province": "Zaragoza",
                    "city": "San Martín de la Virgen de Moncayo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50590",
                    "province": "Zaragoza",
                    "city": "Épila",
                    "multiple": "0"
                },
                {
                    "zipcode": "50590",
                    "province": "Zaragoza",
                    "city": "Añón de Moncayo",
                    "multiple": "1"
                },
                {
                    "zipcode": "50591",
                    "province": "Zaragoza",
                    "city": "Alcalá de Moncayo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50600",
                    "province": "Zaragoza",
                    "city": "Ejea de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "50610",
                    "province": "Zaragoza",
                    "city": "Frago, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50610",
                    "province": "Zaragoza",
                    "city": "Pedrosas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "50610",
                    "province": "Zaragoza",
                    "city": "Luna",
                    "multiple": "1"
                },
                {
                    "zipcode": "50611",
                    "province": "Zaragoza",
                    "city": "Frago, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50611",
                    "province": "Zaragoza",
                    "city": "Erla",
                    "multiple": "1"
                },
                {
                    "zipcode": "50612",
                    "province": "Zaragoza",
                    "city": "Sierra de Luna",
                    "multiple": "0"
                },
                {
                    "zipcode": "50612",
                    "province": "Zaragoza",
                    "city": "Pedrosas, Las",
                    "multiple": "1"
                },
                {
                    "zipcode": "50612",
                    "province": "Zaragoza",
                    "city": "Castejón de Valdejasa",
                    "multiple": "1"
                },
                {
                    "zipcode": "50613",
                    "province": "Zaragoza",
                    "city": "Castejón de Valdejasa",
                    "multiple": "0"
                },
                {
                    "zipcode": "50614",
                    "province": "Zaragoza",
                    "city": "Puendeluna",
                    "multiple": "0"
                },
                {
                    "zipcode": "50614",
                    "province": "Zaragoza",
                    "city": "Ardisa",
                    "multiple": "1"
                },
                {
                    "zipcode": "50615",
                    "province": "Zaragoza",
                    "city": "Valpalmas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50615",
                    "province": "Zaragoza",
                    "city": "Luna",
                    "multiple": "1"
                },
                {
                    "zipcode": "50616",
                    "province": "Zaragoza",
                    "city": "Marracos",
                    "multiple": "0"
                },
                {
                    "zipcode": "50616",
                    "province": "Zaragoza",
                    "city": "Piedratajada",
                    "multiple": "1"
                },
                {
                    "zipcode": "50617",
                    "province": "Zaragoza",
                    "city": "Ejea de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "50619",
                    "province": "Zaragoza",
                    "city": "Luesia",
                    "multiple": "0"
                },
                {
                    "zipcode": "50619",
                    "province": "Zaragoza",
                    "city": "Ejea de los Caballeros",
                    "multiple": "1"
                },
                {
                    "zipcode": "50619",
                    "province": "Zaragoza",
                    "city": "Biel",
                    "multiple": "1"
                },
                {
                    "zipcode": "50619",
                    "province": "Zaragoza",
                    "city": "Asín",
                    "multiple": "1"
                },
                {
                    "zipcode": "50619",
                    "province": "Zaragoza",
                    "city": "Orés",
                    "multiple": "1"
                },
                {
                    "zipcode": "50620",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50629",
                    "province": "Zaragoza",
                    "city": "Sobradiel",
                    "multiple": "0"
                },
                {
                    "zipcode": "50630",
                    "province": "Zaragoza",
                    "city": "Alagón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50637",
                    "province": "Zaragoza",
                    "city": "Remolinos",
                    "multiple": "0"
                },
                {
                    "zipcode": "50638",
                    "province": "Zaragoza",
                    "city": "Cabañas de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50639",
                    "province": "Zaragoza",
                    "city": "Figueruelas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50640",
                    "province": "Zaragoza",
                    "city": "Luceni",
                    "multiple": "0"
                },
                {
                    "zipcode": "50641",
                    "province": "Zaragoza",
                    "city": "Boquiñeni",
                    "multiple": "0"
                },
                {
                    "zipcode": "50650",
                    "province": "Zaragoza",
                    "city": "Gallur",
                    "multiple": "0"
                },
                {
                    "zipcode": "50660",
                    "province": "Zaragoza",
                    "city": "Tauste",
                    "multiple": "0"
                },
                {
                    "zipcode": "50667",
                    "province": "Zaragoza",
                    "city": "Pradilla de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50668",
                    "province": "Zaragoza",
                    "city": "Pradilla de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50669",
                    "province": "Zaragoza",
                    "city": "Tauste",
                    "multiple": "0"
                },
                {
                    "zipcode": "50670",
                    "province": "Zaragoza",
                    "city": "Sádaba",
                    "multiple": "0"
                },
                {
                    "zipcode": "50678",
                    "province": "Zaragoza",
                    "city": "Uncastillo",
                    "multiple": "0"
                },
                {
                    "zipcode": "50679",
                    "province": "Zaragoza",
                    "city": "Layana",
                    "multiple": "0"
                },
                {
                    "zipcode": "50679",
                    "province": "Zaragoza",
                    "city": "Sádaba",
                    "multiple": "1"
                },
                {
                    "zipcode": "50680",
                    "province": "Zaragoza",
                    "city": "Sos del Rey Católico",
                    "multiple": "0"
                },
                {
                    "zipcode": "50682",
                    "province": "Zaragoza",
                    "city": "Sigüés",
                    "multiple": "0"
                },
                {
                    "zipcode": "50683",
                    "province": "Zaragoza",
                    "city": "Artieda",
                    "multiple": "0"
                },
                {
                    "zipcode": "50683",
                    "province": "Zaragoza",
                    "city": "Sigüés",
                    "multiple": "1"
                },
                {
                    "zipcode": "50683",
                    "province": "Zaragoza",
                    "city": "Mianos",
                    "multiple": "1"
                },
                {
                    "zipcode": "50684",
                    "province": "Zaragoza",
                    "city": "Salvatierra de Esca",
                    "multiple": "0"
                },
                {
                    "zipcode": "50685",
                    "province": "Zaragoza",
                    "city": "Pintanos, Los",
                    "multiple": "0"
                },
                {
                    "zipcode": "50685",
                    "province": "Zaragoza",
                    "city": "Bagüés",
                    "multiple": "1"
                },
                {
                    "zipcode": "50685",
                    "province": "Zaragoza",
                    "city": "Navardún",
                    "multiple": "1"
                },
                {
                    "zipcode": "50685",
                    "province": "Zaragoza",
                    "city": "Urriés",
                    "multiple": "1"
                },
                {
                    "zipcode": "50686",
                    "province": "Navarra",
                    "city": "Petilla de Aragón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50686",
                    "province": "Navarra",
                    "city": "Navardún",
                    "multiple": "1"
                },
                {
                    "zipcode": "50687",
                    "province": "Zaragoza",
                    "city": "Lobera de Onsella",
                    "multiple": "0"
                },
                {
                    "zipcode": "50687",
                    "province": "Zaragoza",
                    "city": "Isuerre",
                    "multiple": "1"
                },
                {
                    "zipcode": "50688",
                    "province": "Zaragoza",
                    "city": "Longás",
                    "multiple": "0"
                },
                {
                    "zipcode": "50689",
                    "province": "Zaragoza",
                    "city": "Undués de Lerda",
                    "multiple": "0"
                },
                {
                    "zipcode": "50689",
                    "province": "Zaragoza",
                    "city": "Sos del Rey Católico",
                    "multiple": "1"
                },
                {
                    "zipcode": "50690",
                    "province": "Zaragoza",
                    "city": "Pedrola",
                    "multiple": "0"
                },
                {
                    "zipcode": "50691",
                    "province": "Zaragoza",
                    "city": "Alcalá de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50692",
                    "province": "Zaragoza",
                    "city": "Joyosa, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "50692",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "1"
                },
                {
                    "zipcode": "50693",
                    "province": "Zaragoza",
                    "city": "Torres de Berrellén",
                    "multiple": "0"
                },
                {
                    "zipcode": "50694",
                    "province": "Zaragoza",
                    "city": "Ejea de los Caballeros",
                    "multiple": "0"
                },
                {
                    "zipcode": "50695",
                    "province": "Zaragoza",
                    "city": "Biota",
                    "multiple": "0"
                },
                {
                    "zipcode": "50696",
                    "province": "Zaragoza",
                    "city": "Sos del Rey Católico",
                    "multiple": "0"
                },
                {
                    "zipcode": "50696",
                    "province": "Zaragoza",
                    "city": "Castiliscar",
                    "multiple": "1"
                },
                {
                    "zipcode": "50697",
                    "province": "Zaragoza",
                    "city": "Sos del Rey Católico",
                    "multiple": "0"
                },
                {
                    "zipcode": "50700",
                    "province": "Zaragoza",
                    "city": "Caspe",
                    "multiple": "0"
                },
                {
                    "zipcode": "50709",
                    "province": "Zaragoza",
                    "city": "Caspe",
                    "multiple": "0"
                },
                {
                    "zipcode": "50710",
                    "province": "Zaragoza",
                    "city": "Maella",
                    "multiple": "0"
                },
                {
                    "zipcode": "50720",
                    "province": "Zaragoza",
                    "city": "Ricla",
                    "multiple": "0"
                },
                {
                    "zipcode": "50720",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "1"
                },
                {
                    "zipcode": "50730",
                    "province": "Zaragoza",
                    "city": "Burgo de Ebro, El",
                    "multiple": "0"
                },
                {
                    "zipcode": "50740",
                    "province": "Zaragoza",
                    "city": "Fuentes de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50750",
                    "province": "Zaragoza",
                    "city": "Pina de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50760",
                    "province": "Zaragoza",
                    "city": "Velilla de Ebro",
                    "multiple": "0"
                },
                {
                    "zipcode": "50770",
                    "province": "Zaragoza",
                    "city": "Quinto",
                    "multiple": "0"
                },
                {
                    "zipcode": "50780",
                    "province": "Zaragoza",
                    "city": "Sástago",
                    "multiple": "0"
                },
                {
                    "zipcode": "50781",
                    "province": "Zaragoza",
                    "city": "Alborge",
                    "multiple": "0"
                },
                {
                    "zipcode": "50782",
                    "province": "Zaragoza",
                    "city": "Cinco Olivas",
                    "multiple": "0"
                },
                {
                    "zipcode": "50783",
                    "province": "Zaragoza",
                    "city": "Alforque",
                    "multiple": "0"
                },
                {
                    "zipcode": "50784",
                    "province": "Zaragoza",
                    "city": "Zaida, La",
                    "multiple": "0"
                },
                {
                    "zipcode": "50786",
                    "province": "Zaragoza",
                    "city": "Gelsa",
                    "multiple": "0"
                },
                {
                    "zipcode": "50790",
                    "province": "Zaragoza",
                    "city": "Escatrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50791",
                    "province": "Zaragoza",
                    "city": "Escatrón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50792",
                    "province": "Zaragoza",
                    "city": "Chiprana",
                    "multiple": "0"
                },
                {
                    "zipcode": "50793",
                    "province": "Zaragoza",
                    "city": "Fabara",
                    "multiple": "0"
                },
                {
                    "zipcode": "50794",
                    "province": "Zaragoza",
                    "city": "Nonaspe",
                    "multiple": "0"
                },
                {
                    "zipcode": "50795",
                    "province": "Zaragoza",
                    "city": "Fayón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50800",
                    "province": "Zaragoza",
                    "city": "Zuera",
                    "multiple": "0"
                },
                {
                    "zipcode": "50810",
                    "province": "Zaragoza",
                    "city": "Zuera",
                    "multiple": "0"
                },
                {
                    "zipcode": "50820",
                    "province": "Zaragoza",
                    "city": "Zaragoza",
                    "multiple": "0"
                },
                {
                    "zipcode": "50830",
                    "province": "Zaragoza",
                    "city": "Alagón",
                    "multiple": "0"
                },
                {
                    "zipcode": "50830",
                    "province": "Zaragoza",
                    "city": "Villanueva de Gállego",
                    "multiple": "1"
                },
                {
                    "zipcode": "50830",
                    "province": "Zaragoza",
                    "city": "Zuera",
                    "multiple": "1"
                },
                {
                    "zipcode": "50840",
                    "province": "Zaragoza",
                    "city": "San Mateo de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "50850",
                    "province": "Zaragoza",
                    "city": "Murillo de Gállego",
                    "multiple": "0"
                },
                {
                    "zipcode": "50850",
                    "province": "Zaragoza",
                    "city": "Santa Eulalia de Gállego",
                    "multiple": "1"
                },
                {
                    "zipcode": "51001",
                    "province": "Ceuta",
                    "city": "Ceuta",
                    "multiple": "0"
                },
                {
                    "zipcode": "51002",
                    "province": "Ceuta",
                    "city": "Ceuta",
                    "multiple": "0"
                },
                {
                    "zipcode": "51003",
                    "province": "Ceuta",
                    "city": "Ceuta",
                    "multiple": "0"
                },
                {
                    "zipcode": "51004",
                    "province": "Ceuta",
                    "city": "Ceuta",
                    "multiple": "0"
                },
                {
                    "zipcode": "51005",
                    "province": "Ceuta",
                    "city": "Ceuta",
                    "multiple": "0"
                },
                {
                    "zipcode": "52001",
                    "province": "Melilla",
                    "city": "Melilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "52002",
                    "province": "Melilla",
                    "city": "Melilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "52003",
                    "province": "Melilla",
                    "city": "Melilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "52004",
                    "province": "Melilla",
                    "city": "Melilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "52005",
                    "province": "Melilla",
                    "city": "Melilla",
                    "multiple": "0"
                },
                {
                    "zipcode": "52006",
                    "province": "Melilla",
                    "city": "Melilla",
                    "multiple": "0"
                }
            ]
    
    return of(zipCodeList);
  }
}