import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-viewer-docs',
  templateUrl: './viewer-docs.component.html',
  styleUrls: ['./viewer-docs.component.scss']
})
export class ViewerDocsComponent {


  public sanitizedUrl!: SafeResourceUrl;
  public currentSize: string = 'large';
  public showZoom: boolean = true;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: { url: string, extension:string }, private sanitizer: DomSanitizer) {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
  }

  ngOnInit(){
    console.log(this.currentSize)
  }

  zoomInImage() {
    this.showZoom = !this.showZoom
    this.currentSize = 'extra-large'
  }
  
  zoomOutImage() {
    this.showZoom = !this.showZoom
    this.currentSize = 'large'
  }
  
  }



