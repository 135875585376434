<div class="general-container" *ngIf="cardData && cardData.length >= 1">
    <div class="section_header">
        <p class="section_title"><mat-icon class="section_icon">person</mat-icon>{{_userData.name}}</p>
        <app-navigation-buttons [subLinks]="subLinks" [selectedLink]="selectedLink" (selected)="receiveSelectedLink($event)"></app-navigation-buttons>
    </div>
    <div>
         <ng-container *ngComponentOutlet="selectedLink.component; injector: dynamicInjector"></ng-container>
    </div>
   
</div>
<p *ngIf="cardData && cardData.length == 0"> No hay tarjetas</p>

