import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ShowStatusComponent } from '../showStatusDialog/showStatusDialog.component';

@Component({
  selector: 'app-header-docs',
  templateUrl: './header-docs.component.html',
  styleUrls: ['./header-docs.component.scss']
})
export class HeaderDocsComponent {
@Input() dataUser!: any;
public nameCompany!: string;
public docsData!: any;

constructor(
  private http: HttpClient , 
  ){}

ngOnInit() {
}





}

