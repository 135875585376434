import { Representative } from './../../../../core/models/user/businessData';
import { map } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Component, ComponentFactoryResolver, Injector, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { IconButton } from 'src/app/core/models/headers/link.model';
import { HeaderLinksService } from 'src/app/core/services/headerLinks/link.service';
import { BusinessMockService } from 'src/app/core/services/user/business.mock-service';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { EmployeeMockService } from 'src/app/core/services/user/employee.mock-service';
import { BusinessRole } from 'src/app/core/models/user/userData';


@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent {

  public usersData!: any;
  public businessData: any
  public offset: number = 10
  public nameCompany!: string;
  public employees: any;
  public idEmployee!: string;
  public subLinks!: any[]
  public selectedLink!: IconButton


  dynamicInjector!: Injector;
@Input() set userData(val:any){
  if (!val) {
    return
  }
  this.businessData = val
  this.getEmployeeList()
}

  constructor(private businessService: BusinessService, private employee: EmployeeMockService, private linksService: HeaderLinksService, private injector: Injector){
    this.subLinks = this.linksService.getEmployeesIconButtons()
    this.selectedLink = this.subLinks[0]
  }


  ngOnInit(): void {
  
  }


  getEmployeeList() {
    this.businessService.getEmployees(this.businessData.id).subscribe(res => {
      this.employees = res;
      const representative = this.businessData.representative;
      representative.role = BusinessRole.REPRESENTATIVE
      const partners = this.businessData.partners.map((partner:any) => {
        partner.role = BusinessRole.PARTNER
        return partner
      });
      const administrators = this.businessData.administrators.map((admin:any) => {
        admin.role = BusinessRole.ADMINISTRATOR
        return admin
      });
      this.employees.unshift( representative, ...partners, ...administrators );
      console.log(this.employees)
      this.dynamicInjector = Injector.create({
        providers: [{ provide: 'dynamicData', useValue: this.employees }],
        parent: this.injector
      });

    })
    
   
  }

  receiveSelectedLink(link: IconButton){
    this.selectedLink = link
  }



}
