import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { BusinessProfile } from '../../models/user/businessData';

@Injectable({
    providedIn: 'root'
})

export class EmployeeMockService {

    public getEmployeeList(): Observable<any>{
        const employees = [
            {
            id: '1',
            name: 'Alfonso',
            surname: 'Navarro',
            surname1: 'Hita',
            birthdate: '2000-11-01',
            gender: 'M',
            prefix: '+34',
            phone: '611111199',
            email: 'test@test.test',
            nationality: 'ES',
            expeditionCountry: 'ES',
            residenceCountry: 'ES',
            documentType: '',
            identifier: '02879481W',
            nie: '348285498D',
            role: '',
            createdAt: '2023-11-02',
            updatedAt: '2023-11-02',
        },
        {
            id: '2',
            name: 'Berta',
            surname: 'Prats',
            surname1: 'Hita',
            birthdate: '1996-11-02',
            gender: 'H',
            prefix: '+34',
            phone: '611111199',
            email: 'test@test.test',
            nationality: 'ES',
            expeditionCountry: 'ES',
            residenceCountry: 'ES',
            documentType: '',
            identifier: '05679481W',
            nie: '',
            role: 'Rol',
            createdAt: '2023-11-02',
            updatedAt: '2023-11-02',
        },

    ]
        
        return of(employees)
    
    }
    }
