export const kycConvert = (state?: string) => {
        switch (state) {
          case 'REGISTERED':
            return '0';
            break;
          case 'REPRESENTATIVE_PHONE_VERIFIED':
            return '0';
            break;
          case 'PHONE_VERIFIED':
            return '1';
            break;
          case 'REPRESENTATIVE_DOCUMENTS_VERIFIED':
            return '1';
            break;
          case 'DOCUMENTS_VERIFIED':
            return '2';
            break;
          case 'DIGITAL_SIGNATURE_REGISTERED':
            return '2';
            break;
          case 'CONTRACT_SIGNED':
            return '2';
            break;
          default:
            return 'KYC Desconocido';
        }
}