<div class="header_container">
    <div class="logo_container">
        <img src="../../../../assets/img/logowhite.png" alt="" routerLink="/">
    </div>
    <div class="buttons_container" *ngIf="hiddenLogout">
        <p>Hola, <span>admin</span></p>
        <button mat-icon-button class="header_button" (click)="logout()" routerLink="area-privada"><mat-icon class="hola">power_settings_new</mat-icon></button>
    </div>
</div>

