import { HttpParams } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/core/services/user/business.service';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { FilterParams } from 'src/app/core/models/filters/filters.model';
import { Country } from '../../../core/models/selects/selects';
import { Business } from 'src/app/core/models/user/businessData';
import { MatTableDataSource } from '@angular/material/table';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-dashboard-business',
  templateUrl: './dashboard-business.component.html',
  styleUrls: ['./dashboard-business.component.scss']
})
export class DashboardBusinessComponent {
  public listData: any;
  public dataSource: any = [];
  public userId!: string;
  public loader: boolean = false;
  public offset: number = 10;
  public currentPage: number = 1;
  public companyFilters: FilterParams = { active: true };
  public query: string = '';
  public placeholder = "Empresa, apoderado, marca o CIF";
  public stateValue = "active";

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private businessService: BusinessService, private router: Router) {}

  ngOnInit(): void {
    this.setPageAndSize();
    this.getCompanies();
  }

  setQuerySearch(query: FormControl) {
    this.query = query.value;
    if (query.value.length >= 1) {
      this.companyFilters.query = query.value;
    } else {
      delete this.companyFilters.query;
    }
    this.setPageAndSize();
    this.getCompanies();
  }

  onGetClientEvent(event: {userId: string , name: string}) {
    this.userId = event.userId;
    this.router.navigate(['empresas', event.userId]);
  }

  getCompanies() {
    this.loader = true;
    this.businessService.getCompanies(this.companyFilters).subscribe((res) => {
      this.listData = res;
      this.processCompanyList();
    });
  }

  processCompanyList() {
    this.dataSource = new MatTableDataSource(this.listData.data);
    setTimeout(() => {
      this.loader = false;
    }, 800);
  }

  receiveFilters(filters: FilterParams) {
    this.companyFilters = filters;
    this.stateValue == "active" ? this.companyFilters.active = true : this.companyFilters.active = false;
    if (this.query) {
      this.companyFilters.query = this.query;
    } else {
      delete this.companyFilters.query;
    }
    this.setPageAndSize();
    this.getCompanies();
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.companyFilters.page = event.pageIndex + 1;
    this.getCompanies();
  }

  setPageAndSize() {
    this.currentPage = 1;
    this.companyFilters.page = 1;
    this.companyFilters.size = this.offset;
  }

  activeFilter() {
    this.companyFilters.active = !this.companyFilters.active;
    this.getCompanies();
  }

  
}
