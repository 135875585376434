import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component,Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { containsNumbers, preventLetters, setCurrencyFormat } from 'src/app/core/extensions/functions';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  public filterForm!: FormGroup;
  public panelOpenState: boolean = false;
  public formattedMinAmount: any;
  public formattedMaxAmount: any;

  public minAmount!: string
  public maxAmount!: string

  public params = new HttpParams();
  numericInput!: FormControl;
  preventLetters = preventLetters
  containsNumbers = containsNumbers


  @Output() filtersEvent = new EventEmitter<HttpParams>();

  @Input() isCards?: boolean
  @Input() isReceipts?: boolean


  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe) {
    this.filterForm = this.formBuilder.group({
      startDate: [],
      endDate: [],
      minAmount: [],
      maxAmount: []
    })
  }

  ngOnInit() {
    this.numericInput = this.filterForm.get('minAmount') as FormControl;
  }

  onSubmit() {

    const data = {
      startDate: this.datePipe.transform(this.filterForm.get('startDate')?.value, 'YYYY-MM-dd'),
      endDate: this.datePipe.transform(this.filterForm.get('endDate')?.value, 'YYYY-MM-dd'),
      minAmount: this.filterForm.get('minAmount')?.value,
      maxAmount: this.filterForm.get('maxAmount')?.value
    }


    // Si hay valor, lo setea en el HttpParam, y si se borra el valor en el form, se borra el param
    data.startDate ? this.params = this.params.set('from', data.startDate!) : this.params = this.params.delete('from')
    data.endDate ? this.params = this.params.set('to', data.endDate!) : this.params = this.params.delete('to')
    data.minAmount ? this.params = this.params.set('amountFrom', data.minAmount!.replace(/[,]/g, '.')) : this.params = this.params.delete('amountFrom')
    data.maxAmount ? this.params = this.params.set('amountTo', data.maxAmount!.replace(/[,]/g, '.')) : this.params = this.params.delete('amountTo')

    //filtros listos para añadir a la llamada como this.paramsS
    this.filtersEvent.emit(this.params)
  }


  
  formatMinAmount() {
    if (!this.containsNumbers(this.filterForm.get('minAmount')?.value)) {
      this.formattedMinAmount = 0
      this.minAmount = ''
    }
    else {
      const cleanDots = this.filterForm.get('minAmount')?.value.replace(/[.]/g, '')
      const replaceComma = cleanDots.replace(/[,]/g, '.')
      this.minAmount = replaceComma
      this.formattedMinAmount = setCurrencyFormat(this.minAmount);
    }

  }

  formatMaxAmount() {
    if (!this.containsNumbers(this.filterForm.get('maxAmount')?.value)) {
      this.formattedMaxAmount = 0
      this.maxAmount = ''
    } else {
      const cleanDots = this.filterForm.get('maxAmount')?.value.replace(/[.]/g, '')
      const replaceComma = cleanDots.replace(/[,]/g, '.')
      this.maxAmount = replaceComma
      this.formattedMaxAmount = setCurrencyFormat(this.maxAmount);
    }

  }

  resetAll() {
    this.filterForm.reset()
    this.onSubmit()
  }



}
