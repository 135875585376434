<mat-sidenav-container class="example-container" [hasBackdrop]="false">
  <mat-sidenav
    #sidenav
    [mode]="mode"
    [opened]="isVisible"
  >
    <div class="action_btn">
      <button mat-icon-button (click)="hideNav()"><mat-icon>menu</mat-icon></button>
    </div>
    <mat-list>
      <div class="dropdown" *ngFor="let item of linkList">
        <div class="list_icons">
          <div class="list_btn" *ngIf="!item.deploy" routerLink="{{item.url}}" routerLinkActive="activeRoute" (click)="navigateAndClose()">
            <mat-icon>{{item.matIcon}}</mat-icon>
            <p>{{item.title}}</p>
          </div>
          <div class="list_btn" *ngIf="item.deploy" (click)="toggleConfig(item)">
            <mat-icon>{{item.matIcon}}</mat-icon>
            <p>{{item.title}}</p>
          </div>
          <div class="expand-collapse-icon" *ngIf="item.deploy" (click)="toggleConfig(item)">
            <mat-icon class="arrow">
              {{ item.linkDeployed ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </div>
        </div>
        <div class="group_segment"[@expandOnEnter] [@collapseOnLeave] *ngIf="item.linkDeployed" mat-ripple>
          <span *ngFor="let subtitle of item.subtitles" class="button_segment" [routerLink]="subtitle.url" routerLinkActive="activeRoute" (click)="navigateAndClose()">
            <mat-icon class="arrow-rigth">arrow_right</mat-icon>
            <p style="margin: 0;">{{subtitle.title}}</p>
          </span>
        </div>
      </div>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <button *ngIf="!isVisible" mat-icon-button (click)="showNav()"><mat-icon>menu</mat-icon></button>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
