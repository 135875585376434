import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AccountMovements } from "../../models/accounts/account-movements";
import { AccountsList, UserData } from "../../models/user/userData";
import { Observable } from "rxjs";
import { Card, Cards } from "../../models/cards/card";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  public getUserInfo(): Observable<any>{
    return this.http.get<any>(`${environment.coreUrl}/users/dashboard`)
  }

  public createUser(body: any): Observable<any> {
    return this.http.post<any>(`${environment.userUrl}/users`, body, {headers: {'Content-Type': 'application/json'}}) 
  }
  public requestSmsVerify(id: string, body?: any): Observable<any>{
    return this.http.post<any>(`${environment.userUrl}/users/${id}/request/sms/verification`, body)
  }
  public getUsersList(params?: any) {
    return this.http.get<any>(`${environment.userUrl}/users`, { params });
  }
  public getUser(userId: string):Observable<any>  {
    return this.http.get<any>(`${environment.userUrl}/users/${userId}`);
  }
  public editUser(userId: string, body:any) {
    return this.http.patch<any>(`${environment.userUrl}/users/${userId}`, body);
  }
  public getMovements(id: string, params?: HttpParams) {
    return this.http.get<AccountMovements[]>(`${environment.coreUrl}/users/accounts/${id}/movements`, { params })
  }
  public getAccount(userId: string, accountId: string) {
    return this.http.get<AccountsList>(`${environment.coreUrl}/${userId}/accounts/${accountId}`)
  }
  public getAllAccounts(id: string) {
    return this.http.get<AccountsList[]>(`${environment.coreUrl}/${id}/accounts`)
  }
  public getDniAnv(id: string) {
    let httpHeaders = new HttpHeaders().set('Accept', '*/*')
    return this.http.get<Blob>(`${environment.coreUrl}/${id}/documents/IDE/anv`, { headers: httpHeaders, responseType: 'blob' as 'json'});
  }
  public getDniRev(id: string) {
    let httpHeaders = new HttpHeaders().set('Accept', '*/*')
    return this.http.get<Blob>(`${environment.coreUrl}/${id}/documents/IDE/rev`, { headers: httpHeaders, responseType: 'blob' as 'json'});
  }
  public getAllCards(userId: string): Observable<Cards[]> {
    return this.http.get<Cards[]>(`${environment.coreUrl}/${userId}/cards`)
}
  public getCard(userId: string, accountId: string, cardId: string): Observable<Card[]> {
    return this.http.get<Card[]>(`${environment.coreUrl}/${userId}/account/${accountId}/cards/${cardId}`)
  }

  public getDocumentState(userId: string): Observable<any>{
    return this.http.get<any>(`${environment.userUrl}/${userId}/documents/state`)
  }

  public getUserMe(): Observable<any>{
    return this.http.get<any>(`${environment.userUrl}/users/me/state`)
  }
}