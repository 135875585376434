// stepper-client.component.ts
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UserData } from 'src/app/core/models/user/userData';
import { range } from 'rxjs';
import { ShowStatusComponent } from 'src/app/shared/components/user-documents/showStatusDialog/showStatusDialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ShowStepperComponent } from '../showStepperDialog/showStepperDialog.component';

@Component({
  selector: 'app-stepperClient',
  standalone: true,
  templateUrl: './stepperClient.component.html',
  styleUrls: ['./stepperClient.component.scss'],
  imports: [CommonModule, MatIconModule],
})
export class StepperClientComponent implements OnInit {

  @Input() userData!: UserData;


  constructor(    public dialog: MatDialog,){}

  selectedIndex = 0;
  steps = ['Registro', 'Teléfono verificado', 'Documentos Verificados', 'Firma del Contrato'];

  ngOnInit(): void {
    this.selectedIndex = this.userData.state!.length - 1;
  }

  isStepCompleted(stepNumber: number){
    let stepIndex: number;
    switch (this.userData.state) {
      case 'REGISTERED':
        stepIndex = 1
        break;
      case 'PHONE_VERIFIED':
        stepIndex = 2
        break;
      case 'DOCUMENTS_VERIFIED':
        stepIndex = 3
        break;
      case 'CONTRACT_SIGNED':
        stepIndex = 4
        break;
      default:
        stepIndex = 1
        break;
    }
    return stepIndex > stepNumber ;
    
  }
  statusList = [
    { statusTitle: '1', explanation: 'El usuario ha sido registrado', statusClass: 'statusRequired' },
    { statusTitle: '2', explanation: 'El teléfono del usuario ha sido verificado', statusClass: 'statusPending' },
    { statusTitle: '3', explanation: 'La documentación del usuario se ha verificado', statusClass: 'statusSf' },
    { statusTitle: '4', explanation: 'Ha firmado el contrato y su cuenta está completamente activa', statusClass: 'statusOk' },
  ];


  openDialog() {
    this.dialog.open(ShowStepperComponent, {
      data: { statusList: this.statusList }
    });
  }
}

