import { SideNavService } from './../../../core/services/side-nav/side-nav.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading/loading.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit{
  public loginForm!: FormGroup;
  public submitted: boolean = false;
  public passError: boolean = false
  _login: any;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private loadingService: LoadingService){
    this.loginForm = this.formBuilder.group({
      username:["",[Validators.required]],
      password:["",[Validators.required, Validators.minLength(3)]]
    })

  }
  ngOnInit(): void {
  
  }
  onSubmit() {
    const username = this.loginForm.get('username')?.value;
    const pass = this.loginForm.get('password')?.value;
    this.loadingService.showLoading();
    this.authService.signIn(username, pass).pipe(
      tap( () => {
          this.router.navigate(['/equipo']);
          this.loadingService.hideLoading();
        }
      ),
      catchError( (error) => {
        console.log('Error', error);
        
        if (error.code === 401) {
          this.passError = true;
          
        } else {
          console.error('Error en la autenticación:', error);
          
        }
        this.loadingService.hideLoading();
        return of (401)
      }
      )
      
    ).subscribe();
  }
  

}
