<div class="table-container">
  <table mat-table [dataSource]="docs" multiTemplateDataRows class="mat-elevation-z1 table-content">
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Documento </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Descripción </th>
      <td mat-cell *matCellDef="let element"> {{element.partnerName ? element.partnerName : null}} </td>
    </ng-container>
    <ng-container matColumnDef="status">

      <th mat-header-cell *matHeaderCellDef> <div class="flexEstado">Estado <mat-icon class="iconInfo" (click)="openDialog()">info</mat-icon></div></th>

      <td mat-cell *matCellDef="let element" class="{{getDocumentState(element.state).classCss}}">
        <p>{{getDocumentState(element.state).viewValue}}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef> Creado </th>
      <td mat-cell *matCellDef="let element"> {{element.createdAt | date: "dd/MM/yyyy HH:mm:ss"}} </td>
    </ng-container>
    <!--   <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef> Actualizado </th>
      <td mat-cell *matCellDef="let element"> {{element.updatedAt | date: "dd/MM/yyyy HH:mm:ss"}} </td>
    </ng-container> -->
    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><mat-icon *ngIf="element.state !== 'REQUIRED'" (click)="$event.stopPropagation(); downloadFile($event, element)">file_download</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="spinner-container" *ngIf="isDetailLoading">
            <div class="element-diagram">
              <div class="skeleton skeleton-img"></div>
              <div class="element-description">
                <div class="skeleton skeleton-text"></div>
                <div class="skeleton skeleton-text"></div>
              </div>
            </div>
          </div>
          <div *ngIf="!isDetailLoading" class="element-diagram">
            <div class="img-docs">
              <ng-container *ngFor="let file of filePreviews; let i = index">
                <img *ngIf="element.state !== 'REQUIRED' && !visibleUploadFile && fileType !== 'application/pdf'"
                  [src]="file" (click)="showFullFile({url: file, extension: 'jpeg'})">
              </ng-container>
              <pdf-viewer *ngIf="element.state !== 'REQUIRED' && !visibleUploadFile && fileType === 'application/pdf'"
                [src]="filePreview" [original-size]="false" [zoom-scale]="'page-width'"
                style="width: 300px; height: 190px; border-radius: 10px;"
                (click)="showFullFile({url: filePreview, extension: 'pdf'})"></pdf-viewer>
              <app-upload-file *ngIf="element.state === 'REQUIRED' || visibleUploadFile" [isDocumentsComponent]="true"
                [documentData]="element" (uploadedEvent)="askForOkDocs()" (sendError)="captureError($event)"></app-upload-file>
            </div>
            <div class="element-description" *ngIf="element.type !== 'IDE'">
              <div class="element-name" *ngIf="element.state !== 'REQUIRED'">
                <p class="element-text"> Creado: <span>{{element.createdAt | date: "dd/MM/yyyy"}}</span> </p>
              </div>
              <div *ngIf="element.state === 'REJECTED'" class="element-weight">
                <p class="element-text">Motivo de rechazo: <span>{{element.reason}}</span></p>
              </div>
              <button mat-stroked-button (click)="visibleUploadFile = !visibleUploadFile"> {{visibleUploadFile ? 'Cancelar' : 'Subir nuevo'}} </button>
            </div>
            <div class="validate-container" *ngIf="shouldShowValidation(element)">
              <mat-form-field color="primary" appearance="outline" *ngIf="availableActions.length >= 1">
                <mat-label>Cambiar estado</mat-label>
                <mat-select>
                  <mat-option *ngFor="let item of availableActions" [value]="item" (click)="statusSelector({element: element, statusSelected: item})">
                    {{item.action}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="$event.stopPropagation(); toggleExpandedRow(element)">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</div>
