import { Component, ComponentFactoryResolver, Injector, Input, SimpleChanges, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { HostDirective } from 'src/app/core/directives/component.host.directive';
import { Card } from 'src/app/core/models/cards/card';
import { IconButton, Link } from 'src/app/core/models/headers/link.model';
import { CardsMockService } from 'src/app/core/services/mocks/cards/cards.mock-service';
import { HeaderLinksService } from 'src/app/core/services/headerLinks/link.service';
import { UserService } from 'src/app/core/services/user/user.service';
@Component({
  selector: 'app-user-cards',
  templateUrl: './user-cards.component.html',
  styleUrls: ['./user-cards.component.scss']
})
export class UserCardsComponent {
  _userData:any;
  public cardData!: any;
  public userId: any;
  public subLinks!: any[]
  public selectedLink!: IconButton;
  @Input() set userData(val:any){
    if(!val){
      return
    }else {
     this._userData = val
    }
  }

  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })

    //inputData viene de Host. Son los datos de company
 

  contenidoSeleccionado: string | null = null;
  dynamicComponentContainer!: ViewContainerRef;
  dynamicInjector!: Injector;


  constructor(private cardService: UserService, private linksService: HeaderLinksService,  private injector: Injector, private cardServiceInfo: UserService) {
    this.subLinks = this.linksService.getCardsIconButtons()
    this.selectedLink = this.subLinks[0]
   }

  ngOnInit(): void {
    this.getCardData()
    this.getCardInfo()
  }

   getCardData() {
    this.userId = this._userData.id
    this.cardService.getAllCards(this.userId).subscribe(res => {
      const idClient = res.filter(elem => elem.holderId === this.userId);
      console.log(res)

      if (idClient) { this.cardData = idClient }
      console.log("Esto es cardData", this.cardData )
    })

    this.dynamicInjector = Injector.create({
      providers: [{ provide: 'dynamicData', useValue: this.cardData }],
      parent: this.injector
    });
  }

  getCardInfo() {
    this.cardServiceInfo.getUserInfo().subscribe(res => {
      console.log(res)
    })
  }

  receiveSelectedLink(link: IconButton){
    this.selectedLink = link
  }
}
