import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectListMockService } from 'src/app/core/services/selects/selectsList';

export enum Action {
    VALIDATE = 'Validar',
    REJECT = 'Rechazar'
}

@Component({
  selector: 'app-document-handler',
  templateUrl: './document-handler.component.html',
  styleUrls: ['./document-handler.component.scss']
})
export class DocumentHandlerComponent {

  public showInputConfirm: boolean = false;
  public showButtonConfirm!: boolean;
  public actionForm!: FormGroup;
  public validateForm!: FormGroup;
  public rejectForm!: FormGroup;
  public action!: Action
  public denyList!: string[]
  public spinner: boolean = false
  showInput = false;

  constructor(private dialogRef: MatDialogRef<DocumentHandlerComponent>,@Inject(MAT_DIALOG_DATA) public data: { status: string, statusSelected: any }, private dialog: MatDialog, private formBuilder: FormBuilder, private selectList: SelectListMockService) {
    console.log(data.status, data.statusSelected)
    this.actionForm =  this.formBuilder.group({
    
    })
    if (this.data.statusSelected.action == Action.VALIDATE) {
      this.action = Action.VALIDATE
      this.actionForm.addControl('expedition', new FormControl('', Validators.required)),
      this.actionForm.addControl('expiration', new FormControl(''))
    } else if (this.data.statusSelected.action == Action.REJECT) {
      this.action = Action.REJECT
      this.actionForm.addControl('reason', new FormControl('', Validators.required))
    }
  }

  ngOnInit(){
    this.selectList.denyList().subscribe(
      data => {
        this.denyList = data
      }
    )
  }


  onSubmit() {
    console.log(this.actionForm)
    this.spinner = true;
    const reasonSelected = this.actionForm.get('reason')?.value
    if (this.data.statusSelected.action == Action.REJECT && reasonSelected === 'Otros') {
      this.actionForm.get('reason')?.setValue(this.actionForm.get('other')?.value)
    }
    
    setTimeout(() => {
      this.spinner = false;
      this.dialogRef.close(this.actionForm);
    }, 3000);
  }

  onReasonChange() {
    const selectedValue = this.actionForm.get('reason')?.value
    if(selectedValue === 'Otros'){
      this.showInput = true
      this.actionForm.addControl('other', new FormControl('', Validators.required))
    } else {
      this.showInput = false
      this.actionForm.removeControl('other')
    }
    this.actionForm.get('other')?.updateValueAndValidity();
  }

}

