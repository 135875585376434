<div class="dialog-header">
  <h2 mat-dialog-title>Estados</h2>
  <button class="buttonCloseDialog" mat-button (click)="closeDialog()" mat-dialog-close></button>
</div>
<mat-dialog-content>
 
  <ul>
    <li *ngFor="let status of data.statusList; let i = index" >
    <span class="statusTitle-span" [ngClass]="{
      'statusSf': status.statusTitleSpan === 'Pt. Sefide',
      'statusPending': status.statusTitleSpan === 'Pendiente',
      'statusExpired': status.statusTitleSpan === 'Caducado',
      'statusRequired': status.statusTitleSpan === 'Requerido',
      'statusOk': status.statusTitleSpan === 'Verificado',
      'statusKo': status.statusTitleSpan === 'Rechazado'
    }" >{{ status.statusTitleSpan }}</span> <span class="explanationStatus-span">{{ status.explanationStatusSpan }}</span>
  </li>
</ul>

</mat-dialog-content> 
