import { Pipe, PipeTransform } from '@angular/core';
import { SelectFormMockService } from 'src/app/core/services/selects/countrySelect.mock-service';


@Pipe({name:'country'})

export class CountryPipe implements PipeTransform {
  
    constructor(private countryService: SelectFormMockService) {}
    transform(countryCode: string): any {
        // Llama al servicio para obtener el nombre del país según el código de país
        const countryName = this.countryService.selectList(countryCode);
    
        // Devuelve el nombre del país o una cadena vacía si no se encuentra
        return countryName || '';
      }
  }