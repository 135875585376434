import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconButton, Link } from 'src/app/core/models/headers/link.model';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss']
})
export class NavigationButtonsComponent {
@Input() subLinks!: IconButton[]
@Input() selectedLink!: IconButton
@Output() selected: EventEmitter<IconButton> = new EventEmitter<IconButton> ()
  contenidoSeleccionado!: string;



handleClick(link: IconButton) {
  this.selected.emit(link)
  console.log("hice click en", link)
}

}
