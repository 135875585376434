import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { slideInRightOnEnterAnimation, slideInLeftOnEnterAnimation } from 'angular-animations';
import { FinancingService } from 'src/app/core/services/financing/financing.service';
import { getStatusClass, stateFinancing } from '../../../../core/extensions/stateFinancing.extension';
import { PageEvent } from '@angular/material/paginator';
import { catchError, of, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FinancingList } from 'src/app/core/models/financing/financing';


@Component({
  selector: 'app-financing-list',
  templateUrl: './financing-list.component.html',
  styleUrls: ['./financing-list.component.scss'],
  animations: [slideInRightOnEnterAnimation({ anchor: 'enterRight', duration: 400 }),
  slideInLeftOnEnterAnimation({ anchor: 'enterLeft', duration: 400 })]
})
export class FinancingListComponent {
  public financingsData!: FinancingList[];
  public sortedData!: FinancingList[];

  public displayedColumns: string[] = [
    'link',
    'codeOperation',
    'date',
    'identifier',
    'name',
    'clientType',
    'totalAmount',
    'state',
  ];

  public offset: number = 10;
  public currentPage!: number;
  public paginationParams: HttpParams = new HttpParams();

  public loader: boolean = true;

  public length!: number;
  public pageSize: number = 10;
  public pageIndex: number = 0;
  public pageSizeOptions: number[] = [10, 20, 30];
  public hidePageSize: boolean = false;
  public showPageSizeOptions: boolean = true;
  public showFirstLastButtons: boolean = true;
  public disabled: boolean = false;
  public pageEvent!: PageEvent;

  public placeholder: string = "Operación, empresa o CIF/NIF";
  public searchValue!: string;
  public commercialId: string = "6b897243-c8ec-48de-a757-63added3dca2";
  stateFinancing = stateFinancing;
  getStatusClass = getStatusClass;

  constructor(private router: Router, private allFinancings: FinancingService
  ) { }


  ngOnInit() {
    this.getOperationsList();
  }

  getIdentifier(identifier: string, id: string) {
    localStorage.setItem('type', /^[a-zA-Z]/.test(identifier) ? 'Empresa' : 'Autónomo');
    this.router.navigate([`/contratacion/renting/${id}`], {
      state: { data: this.sortedData, identifier: identifier }
    });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getOperationsList();
  }

  getOperationsList(): void {
    this.loader = true;
    const size = this.pageSize;
    const page = this.pageIndex + 1;
    const value = this.searchValue|| '';
    console.log(value)
  
    this.allFinancings.findByFilter(value, size, page)
      .pipe(
        tap((res) => {
          this.sortedData = res.data;
          this.length = res.total;
          this.loader = false;
        }),
        catchError((error) => {
          console.error(error);
          this.loader = false;
          return of(null);
        })
      )
      .subscribe();
  }


  sortData(event: { active: string; direction: string }) {
    const data = this.sortedData.slice();
    if (!event.active || event.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'codeOperation': return this.compare(a.codeOperation, b.codeOperation, isAsc);
        case 'date': return this.compare(a.date, b.date, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSearch(searchControl: FormControl): void {
    this.searchValue = searchControl.value || '';
    this.getOperationsList()
  }

}

