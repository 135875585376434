<mat-form-field class="cc" appearance="outline">
    <mat-label>Añadir en copia</mat-label>
    <mat-chip-grid #chipGrid>
        @for (mail of ccMails; track mail) {
          <mat-chip-row (removed)="remove(mail)">
            {{mail}}
            <button matChipRemove [attr.aria-label]="'remove ' + mail">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
        <input [formControl]="ccSelected"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"/>
        <mat-error *ngIf="ccSelected.hasError('invalidEmail')">
            Ingresa una dirección de correo electrónico válida.
        </mat-error>
      </mat-chip-grid>
  </mat-form-field>