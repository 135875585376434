import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterParams } from 'src/app/core/models/filters/filters.model';

@Component({
  selector: 'app-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss']
})
export class UsersFilterComponent {
  public filterForm!: FormGroup;
  public maxDate = new Date();

  @Output() filtersEvent = new EventEmitter<FilterParams>();

  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe){
    this.filterForm = this.formBuilder.group({
      gender: [],
      documentType: [],
      bornFrom: [],
      bornTo: [],
      createdFrom: [],
      createdTo: []
    })
  }

  ngOnInit() {
   this.filterForm.valueChanges.subscribe(res => {
    this.onSubmit()
   })
  }

  onSubmit(){

    const dataFromForm = {
      gender: this.filterForm.get('gender')?.value,
      documentType: this.filterForm.get('documentType')?.value,
      bornFrom: this.datePipe.transform(this.filterForm.get('bornFrom')?.value, 'YYYY-MM-dd'),
      bornTo: this.datePipe.transform(this.filterForm.get('bornTo')?.value, 'YYYY-MM-dd'),
      createdFrom: this.datePipe.transform(this.filterForm.get('createdFrom')?.value, 'yyyy-MM-ddTHH:mm:ss'),
      createdTo: this.datePipe.transform(this.filterForm.get('createdTo')?.value, 'yyyy-MM-ddTHH:mm:ss'),
    }

    const filtersToSend: FilterParams = {}
    dataFromForm.gender ? filtersToSend.gender = dataFromForm.gender : null;
    dataFromForm.documentType ? filtersToSend.documentType = dataFromForm.documentType : null;
    dataFromForm.bornFrom ? filtersToSend.bornFrom = dataFromForm.bornFrom : null;
    dataFromForm.bornTo ? filtersToSend.bornTo = dataFromForm.bornTo : null;
    dataFromForm.createdFrom ? filtersToSend.createdFrom = dataFromForm.createdFrom : null;
    dataFromForm.createdTo ? filtersToSend.createdTo = dataFromForm.createdTo : null;


    console.log(filtersToSend)
    this.filtersEvent.emit(filtersToSend)
  }

  resetAll() {
    this.filterForm.reset()
  }

  

}
