import { CountryPrefix, Prefix } from './../../models/selects/selects';
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class PrefixMockService {
    public preList(): Observable <CountryPrefix> {
        const prefixList = new CountryPrefix()
        prefixList.prefix = [
            { country: 'España', prefix: '+34' },
            { country: 'Afganistán', prefix: '+93' },
  { country: 'Albania', prefix: '+355' },
  { country: 'Alemania', prefix: '+49' },
  { country: 'Algeria', prefix: '+213' },
  { country: 'Andorra', prefix: '+376' },
  { country: 'Angola', prefix: '+244' },
  { country: 'Antigua y Barbuda', prefix: '+1-268' },
  { country: 'Arabia Saudita', prefix: '+966' },
  { country: 'Argentina', prefix: '+54' },
  { country: 'Armenia', prefix: '+374' },
  { country: 'Australia', prefix: '+61' },
  { country: 'Austria', prefix: '+43' },
  { country: 'Azerbaiyán', prefix: '+994' },
  { country: 'Bahamas', prefix: '+1-242' },
  { country: 'Bangladesh', prefix: '+880' },
  { country: 'Barbados', prefix: '+1-246' },
  { country: 'Baréin', prefix: '+973' },
  { country: 'Bélgica', prefix: '+32' },
  { country: 'Belice', prefix: '+501' },
  { country: 'Benín', prefix: '+229' },
  { country: 'Bielorrusia', prefix: '+375' },
  { country: 'Bolivia', prefix: '+591' },
  { country: 'Bosnia y Herzegovina', prefix: '+387' },
  { country: 'Botsuana', prefix: '+267' },
  { country: 'Brasil', prefix: '+55' },
  { country: 'Brunéi', prefix: '+673' },
  { country: 'Bulgaria', prefix: '+359' },
  { country: 'Burkina Faso', prefix: '+226' },
  { country: 'Burundi', prefix: '+257' },
  { country: 'Bután', prefix: '+975' },
  { country: 'Cabo Verde', prefix: '+238' },
  { country: 'Camboya', prefix: '+855' },
  { country: 'Camerún', prefix: '+237' },
  { country: 'Canadá', prefix: '+1' },
  { country: 'Chad', prefix: '+235' },
  { country: 'Chile', prefix: '+56' },
  { country: 'China', prefix: '+86' },
  { country: 'Chipre', prefix: '+357' },
  { country: 'Colombia', prefix: '+57' },
  { country: 'Comoras', prefix: '+269' },
  { country: 'Congo', prefix: '+242' },
  { country: 'Corea del Norte', prefix: '+850' },
  { country: 'Corea del Sur', prefix: '+82' },
  { country: 'Costa de Marfil', prefix: '+225' },
  { country: 'Costa Rica', prefix: '+506' },
  { country: 'Croacia', prefix: '+385' },
  { country: 'Cuba', prefix: '+53' },
  { country: 'Dinamarca', prefix: '+45' },
  { country: 'Dominica', prefix: '+1-767' },
  { country: 'Ecuador', prefix: '+593' },
  { country: 'Egipto', prefix: '+20' },
  { country: 'El Salvador', prefix: '+503' },
  { country: 'Emiratos Árabes Unidos', prefix: '+971' },
  { country: 'Eritrea', prefix: '+291' },
  { country: 'Eslovaquia', prefix: '+421' },
  { country: 'Eslovenia', prefix: '+386' },
  { country: 'Estados Unidos', prefix: '+1' },
  { country: 'Estonia', prefix: '+372' },
  { country: 'Eswatini', prefix: '+268' },
  { country: 'Etiopía', prefix: '+251' },
  { country: 'Fiji', prefix: '+679' },
  { country: 'Filipinas', prefix: '+63' },
  { country: 'Finlandia', prefix: '+358' },
  { country: 'Francia', prefix: '+33' },
  { country: 'Gabón', prefix: '+241' },
  { country: 'Gambia', prefix: '+220' },
  { country: 'Georgia', prefix: '+995' },
  { country: 'Ghana', prefix: '+233' },
  { country: 'Grecia', prefix: '+30' },
  { country: 'Grenada', prefix: '+1-473' },
  { country: 'Guatemala', prefix: '+502' },
  { country: 'Guinea', prefix: '+224' },
  { country: 'Guinea-Bisáu', prefix: '+245' },
  { country: 'Guinea Ecuatorial', prefix: '+240' },
  { country: 'Guyana', prefix: '+592' },
  { country: 'Haití', prefix: '+509' },
  { country: 'Honduras', prefix: '+504' },
  { country: 'Hungría', prefix: '+36' },
  { country: 'India', prefix: '+91' },
  { country: 'Indonesia', prefix: '+62' },
  { country: 'Irak', prefix: '+964' },
  { country: 'Irán', prefix: '+98' },
  { country: 'Irlanda', prefix: '+353' },
  { country: 'Islandia', prefix: '+354' },
  { country: 'Islas Cook', prefix: '+682' },
  { country: 'Islas Marshall', prefix: '+692' },
  { country: 'Islas Salomón', prefix: '+677' },
  { country: 'Israel', prefix: '+972' },
  { country: 'Italia', prefix: '+39' },
  { country: 'Jamaica', prefix: '+1-876' },
  { country: 'Japón', prefix: '+81' },
  { country: 'Jordania', prefix: '+962' },
  { country: 'Kazajistán', prefix: '+7' },
  { country: 'Kenia', prefix: '+254' },
  { country: 'Kirguistán', prefix: '+996' },
  { country: 'Kiribati', prefix: '+686' },
  { country: 'Kuwait', prefix: '+965' },
  { country: 'Laos', prefix: '+856' },
  { country: 'Lesoto', prefix: '+266' },
  { country: 'Letonia', prefix: '+371' },
  { country: 'Líbano', prefix: '+961' },
  { country: 'Liberia', prefix: '+231' },
  { country: 'Libia', prefix: '+218' },
  { country: 'Liechtenstein', prefix: '+423' },
  { country: 'Lituania', prefix: '+370' },
  { country: 'Luxemburgo', prefix: '+352' },
  { country: 'Madagascar', prefix: '+261' },
  { country: 'Malasia', prefix: '+60' },
  { country: 'Malaui', prefix: '+265' },
  { country: 'Maldivas', prefix: '+960' },
  { country: 'Malí', prefix: '+223' },
  { country: 'Malta', prefix: '+356' },
  { country: 'Marruecos', prefix: '+212' },
  { country: 'Mauricio', prefix: '+230' },
  { country: 'Mauritania', prefix: '+222' },
  { country: 'México', prefix: '+52' },
  { country: 'Micronesia', prefix: '+691' },
  { country: 'Moldavia', prefix: '+373' },
  { country: 'Mónaco', prefix: '+377' },
  { country: 'Mongolia', prefix: '+976' },
  { country: 'Montenegro', prefix: '+382' },
  { country: 'Mozambique', prefix: '+258' },
  { country: 'Myanmar', prefix: '+95' },
  { country: 'Namibia', prefix: '+264' },
  { country: 'Nauru', prefix: '+674' },
  { country: 'Nepal', prefix: '+977' },
  { country: 'Nicaragua', prefix: '+505' },
  { country: 'Níger', prefix: '+227' },
  { country: 'Nigeria', prefix: '+234' },
  { country: 'Noruega', prefix: '+47' },
  { country: 'Nueva Zelanda', prefix: '+64' },
  { country: 'Omán', prefix: '+968' },
  { country: 'Países Bajos', prefix: '+31' },
  { country: 'Pakistán', prefix: '+92' },
  { country: 'Palaos', prefix: '+680' },
  { country: 'Panamá', prefix: '+507' },
  { country: 'Papúa Nueva Guinea', prefix: '+675' },
  { country: 'Paraguay', prefix: '+595' },
  { country: 'Perú', prefix: '+51' },
  { country: 'Polonia', prefix: '+48' },
  { country: 'Portugal', prefix: '+351' },
  { country: 'Reino Unido', prefix: '+44' },
  { country: 'República Centroafricana', prefix: '+236' },
  { country: 'República Checa', prefix: '+420' },
  { country: 'República del Congo', prefix: '+243' },
  { country: 'República Dominicana', prefix: '+1-809, +1-829, +1-849' },
  { country: 'Ruanda', prefix: '+250' },
  { country: 'Rumanía', prefix: '+40' },
  { country: 'Rusia', prefix: '+7' },
  { country: 'Samoa', prefix: '+685' },
  { country: 'San Cristóbal y Nieves', prefix: '+1-869' },
  { country: 'San Marino', prefix: '+378' },
  { country: 'Santa Lucía', prefix: '+1-758' },
  { country: 'San Vicente y las Granadinas', prefix: '+1-784' },
  { country: 'Santo Tomé y Príncipe', prefix: '+239' },
  { country: 'Senegal', prefix: '+221' },
  { country: 'Serbia', prefix: '+381' },
  { country: 'Seychelles', prefix: '+248' },
  { country: 'Sierra Leona', prefix: '+232' },
  { country: 'Singapur', prefix: '+65' },
  { country: 'Siria', prefix: '+963' },
  { country: 'Somalia', prefix: '+252' },
  { country: 'Sri Lanka', prefix: '+94' },
  { country: 'Sudáfrica', prefix: '+27' },
  { country: 'Sudán', prefix: '+249' },
  { country: 'Sudán del Sur', prefix: '+211' },
  { country: 'Suecia', prefix: '+46' },
  { country: 'Suiza', prefix: '+41' },
  { country: 'Surinam', prefix: '+597' },
  { country: 'Tailandia', prefix: '+66' },
  { country: 'Tanzania', prefix: '+255' },
  { country: 'Tayikistán', prefix: '+992' },
  { country: 'Timor Oriental', prefix: '+670' },
  { country: 'Togo', prefix: '+228' },
  { country: 'Tonga', prefix: '+676' },
  { country: 'Trinidad y Tobago', prefix: '+1-868' },
  { country: 'Túnez', prefix: '+216' },
  { country: 'Turkmenistán', prefix: '+993' },
  { country: 'Turquía', prefix: '+90' },
  { country: 'Tuvalu', prefix: '+688' },
  { country: 'Ucrania', prefix: '+380' },
  { country: 'Uganda', prefix: '+256' },
  { country: 'Uruguay', prefix: '+598' },
  { country: 'Uzbekistán', prefix: '+998' },
  { country: 'Vanuatu', prefix: '+678' },
  { country: 'Venezuela', prefix: '+58' },
  { country: 'Vietnam', prefix: '+84' },
  { country: 'Yemen', prefix: '+967' },
  { country: 'Yibuti', prefix: '+253' },
  { country: 'Zambia', prefix: '+260' },
  { country: 'Zimbabue', prefix: '+263' }
        ]
        return of (prefixList)
    }

}