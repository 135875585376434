import { map } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Component, Input ,HostListener, ViewChild, EventEmitter, Output} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccountMovements } from 'src/app/core/models/accounts/account-movements';
import { UserService } from 'src/app/core/services/user/user.service';
​
@Component({
  selector: 'app-accounts-table',
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.scss']
})
export class AccountsTableComponent {
  public movementsData! : AccountMovements[];
  public displayedColumns: string[] = ['accountingDate', 'date', 'description', 'amount', 'balance'];;
  public loader!: boolean;
  public accumulatedData: AccountMovements[] = [];
  ​
  // for Filters
  public _selectedAccountId!: string
  public _filterParams?: HttpParams;
  ​
  //For pagination
  public currentPage: number = 1
  public offset: number = 5
  public hideNextButton: boolean = false
  ​
  public allParams: HttpParams = new HttpParams()
  ​
  dataSource: any = [];
  ​
  //Changes account ID
  @Input() set selectedAccountId(val:string){
    if( !val){
      return
    }else{
      this._selectedAccountId = val
      this.resetData()
      this.getMovements()
    }
  }
  ​
  //Filters by date and amount
  @Input() set filterParams(val:HttpParams | undefined){
    if (!val){
      return
    }else{
      this.filterParams
      this._filterParams = val
      this.allParams = this._filterParams
      console.log(this.allParams)
      this.resetData()
      this.getMovements()
    }
  }
  ​
  @Output() sendMovementsDownload: EventEmitter<any> = new EventEmitter<any>();
  ​
  ​
  constructor(private userService: UserService) {
  }
  ​
  ​
  ​
  ngOnInit(){
    this.loader = true
  }
  ​
  ​
  getMovements(){
    this.loader = true
    if(this.currentPage == 1){
      this.allParams = this.allParams.set('page', this.currentPage).set('offset', this.offset)
    }
    this.userService.getMovements(this._selectedAccountId, this.allParams).subscribe((res) => {
      this.movementsData = res;
      this.sendMovementsDownload.emit(this.movementsData)
      this.accumulatedData.push(... this.movementsData)
      this.dataSource = new MatTableDataSource(this.accumulatedData)
  ​
      if(this.movementsData.length < this.offset){
        this.hideNextButton = true;
      }else{
        this.hideNextButton = false;
      }
      this.loader = false;
  ​
    })
  }
  ​
  resetData(){
    this.currentPage = 1;
    this.accumulatedData = []
  }
  ​
  nextPage(){
    this.currentPage = ++this.currentPage
    console.log(this.currentPage)
    this.allParams = this.allParams.set('page',this.currentPage).set('offset', this.offset)
    this.getMovements()
  }
}
