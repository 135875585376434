<div class="tableMaterial" *ngIf="allEmployees && showEmployee">
    <table mat-table [dataSource]="allEmployees" class="table-container">
        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef>Ver</th>
            <td mat-cell *matCellDef="let element" (click)="showEmployeeDetail(element.id)"><div class="icon-link"> <mat-icon style="cursor:pointer">person</mat-icon></div></td>
        </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nombre completo </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} {{element.surname1}} {{element.surname2 ? element.surname2 : null}} </td>
            </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef> Tipo usuario </th>
            <td mat-cell *matCellDef="let element"> {{element.role ? element.role : 'Empleado'}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
<!--         <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element" >
                <div class="table_item">
                    {{element.status}}
                    <mat-icon [ngClass]="{ 
                       'user_statusOk': element.status === 'Activo', 
                       'user_statusKo': element.status !== 'Activo' }">radio_button_checked
                   </mat-icon>
                </div>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;  let i = index"></tr>
    </table>
<!--     <mat-paginator
    (page)="onPageChange($event)"
    [length]="responseUser.total" 
    [pageIndex]="currentPage -1" 
    [pageSize]="offset"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
    </mat-paginator> -->
</div>
<app-detail-employee *ngIf="!showEmployee" (returnTableEmployee)="returnTableEmployee($event)" [dataEmployees]="dataEmployees"></app-detail-employee>



