import { Component, Inject, Input } from '@angular/core';
import { Card } from 'src/app/core/models/user/userData';

@Component({
  selector: 'app-cards-table',
  templateUrl: './cards-table.component.html',
  styleUrls: ['./cards-table.component.scss']
})
export class CardsTableComponent {
  @Input() cardData!: Card[];
/*   public displayedColumns: string[] = ['cardType', 'category', 'numberCard', 'account', 'active', 'edit', 'block', 'clone']; */
  public displayedColumns: string[] = ['numberCard', 'name', 'account',];


  constructor(/* @Inject('dynamicData') public cardData: any */){}
}
