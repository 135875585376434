export class CountrySelect{
    country!: Country[];
    
}

export interface Country{
    value: string;
    viewValue: string;
}

export class ZipCodeModel {
    zipcode?: string;
    province?: string;
    city?: string;
    multiple?: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export interface Prefix{
    country: string;
    prefix: string;
}

export class CountryPrefix{
    prefix!: Prefix[]
}

export class Coin{
    value!: string;
    viewValue!: string;
}
export interface ActivityList{
    code: string | number;
    name: string;
}
