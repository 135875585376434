import { Link } from './../../../core/models/headers/link.model';
import { Component, Input, SimpleChanges, Type, ViewChild } from '@angular/core';
import { HostDirective } from 'src/app/core/directives/component.host.directive';

@Component({
  selector: 'app-host',
  templateUrl: './host.component.html',
  styleUrls: ['./host.component.scss']
})
export class HostComponent {
  @Input() links: Link[] = []
  selectedLink!: Link

  @ViewChild(HostDirective, { static: true }) host!: HostDirective

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes")
    if (changes['links']) {
      if (this.links?.length > 0) {
        this.handleClick(this.links[0])
      }
    }
  }

  loadComponent(component: Type<any>, componentData?: any) {
    const viewContainerRef = this.host.viewContainerRef
    viewContainerRef.clear()
    if (componentData !== undefined) {
      const componentRef = viewContainerRef.createComponent<any>(component).setInput('userData', componentData);
    } else {
      const componentRef = viewContainerRef.createComponent<any>(component);
    }
  }

  handleClick(link: any) {
    //link.data son los datos que pasamos por parámetro al servicio de links, para que lleguen a los componentes. Es opcional ponerlo.
    console.log(link.data)
    this.loadComponent(link.component, link.data)
    this.selectedLink = link

  }
}
