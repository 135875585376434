<table mat-table [dataSource]="cardData">

    <ng-container matColumnDef="numberCard">
        <th mat-header-cell *matHeaderCellDef> Nº tarjeta </th>
        <td mat-cell *matCellDef="let element"> </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Titular </th>
        <td mat-cell *matCellDef="let element"> </td>
    </ng-container>

    <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef> Cuenta Asociada</th>
        <td mat-cell *matCellDef="let element"> {{element.iban}} </td>
    </ng-container>

<!--     <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> {{element.active === true ? 'Activa' : 'Desactivada'}} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>