import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { clearValidator, setValidator } from 'src/app/core/extensions/formFunctions.extensions';
import { allRegex } from 'src/app/core/extensions/regex';
import { Country, ZipCodeModel } from 'src/app/core/models/selects/selects';
import { SelectFormMockService } from 'src/app/core/services/selects/countrySelect.mock-service';
import { SelectListMockService } from 'src/app/core/services/selects/selectsList';
import { ZipcodelistMockService } from 'src/app/core/services/selects/zipcodelist.mock-service';

@Component({
  selector: 'app-personal-adress',
  templateUrl: './personal-adress.component.html',
  styleUrls: ['./personal-adress.component.scss']
})
export class PersonalAdressComponent implements OnInit {
  public addressData!: FormGroup
  public country!: Country[]
  public zipCode!: ZipCodeModel[]
  public zipCodeFiltered!: ZipCodeModel[];
  public cityValue: any = "";
  public submitted: boolean = false;
  public streetTypeList!: Country[];
 

  @Input() isIndividual!: boolean
 
  @Input() loader!: boolean;

  @Output() addressDataEvent = new EventEmitter<FormGroup>();
  
  constructor(private formBuilder: FormBuilder, private selectDataService: SelectFormMockService, private selectListService: SelectListMockService, private zipCodeService: ZipcodelistMockService,) {}


  ngOnInit() {
   
    this.selectDataService.selectList().subscribe(
      data => {
         this.country = data.country;
      }
    )
    this.selectListService.streetType().subscribe(
      res => {
        this.streetTypeList = res
      }
    )

    this.addressData = this.formBuilder.group({
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      flat: [''],
      door: [''],
      other: [''],
      city: [this.cityValue, [Validators.required]],
      zip:['', [Validators.required, Validators.maxLength(5), Validators.pattern(allRegex.regexCpTwo)]],
      country: ['', [Validators.required]],
      via: ['', [Validators.required]]
    })
   this.addressData.valueChanges.subscribe(val => {
    this.addressDataEvent.emit(this.addressData)
   })
    
  }

  zipCodeDetail(){
    this.zipCodeService.cpList().subscribe(
      data => {
        const zipCodeList = data;
        this.zipCodeFiltered = zipCodeList.filter(element => element.zipcode == this.addressData.get('zip')?.value);
        if(this.zipCodeFiltered.length > 1){
          this.cityValue = this.zipCodeFiltered.map(element => element.city);
          this.addressData.get('city')?.setValue(null)
        }else if(this.zipCodeFiltered.length == 1){
          this.cityValue = this.zipCodeFiltered.map(element => element.city)
          this.addressData.get('city')?.setValue(this.cityValue[0])
        }else{
          if(this.addressData.get('zip')?.value.length > 3)
          this.addressData.get('zip')?.setErrors({zipError: 'No pertenece a ninguna localidad'})
        } 
      }
    )
  }
 /*  subscribeToCityChanges() {
    const cityControl = this.addressData.get('city')?.value;
    this.cityValue = cityControl.valueChanges.subscribe(() => {
      this.updateValidate();
    });
  }
  updateValidate(){
    this.addressData.get('city')?.updateValueAndValidity();
  } */
  public onSubmit(): void {
    this.loader = true
    this.addressDataEvent.emit(this.addressData)
  }
  
}
