import { CountrySelect } from '../../models/selects/selects';
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class SelectFormMockService {

    public selectList(countryCode?: string): Observable<CountrySelect> {
        const selectForm = new CountrySelect()
        selectForm.country = [
          { value: "ES", viewValue: 'España' },
          { value: "AF", viewValue: 'Afganistán' },
          { value: "AX", viewValue: 'Aland, Islas' },
          { value: "AL", viewValue: 'Albania' },
          { value: "DE", viewValue: 'Alemania' },
          { value: "AD", viewValue: 'Andorra' },
          { value: "AO", viewValue: 'Angola' },
          { value: "AI", viewValue: 'Anguila' },
          { value: "AQ", viewValue: 'Antártida' },
          { value: "AG", viewValue: 'Antigua y Barbuda' },
          { value: "SA", viewValue: 'Arabia Saudita' },
          { value: "DZ", viewValue: 'Argelia' },
          { value: "AR", viewValue: 'Argentina' },
          { value: "AM", viewValue: 'Armenia' },
          { value: "AW", viewValue: 'Aruba' },
          { value: "AU", viewValue: 'Australia' },
          { value: "AT", viewValue: 'Austria' },
          { value: "AZ", viewValue: 'Azerbaiyán' },
          { value: "BS", viewValue: 'Bahamas(las)' },
          { value: "BD", viewValue: 'Bangladesh' },
          { value: "BB", viewValue: 'Barbados' },
          { value: "BH", viewValue: 'Bahrein' },
          { value: "BE", viewValue: 'Bélgica' },
          { value: "BZ", viewValue: 'Belice' },
          { value: "BJ", viewValue: 'Benín' },
          { value: "BM", viewValue: 'Bermudas' },
          { value: "BY", viewValue: 'Belarús' },
          { value: "BO", viewValue: 'Bolivia(Estado Plurinacional de)' },
          { value: "BQ", viewValue: 'Bonaire, San Eustaquio y Saba' },
          { value: "BA", viewValue: 'Boznia y Herzegovina' },
          { value: "BW", viewValue: 'Botsuana' },
          { value: "BR", viewValue: 'Brasil' },
          { value: "BN", viewValue: 'Brunéi Darussalam' },
          { value: "BG", viewValue: 'Bulgaria' },
          { value: "BF", viewValue: 'Burkina Faso' },
          { value: "BI", viewValue: 'Burundi' },
          { value: "BT", viewValue: 'Bhután' },
          { value: "CV", viewValue: 'Cabo Verde' },
          { value: "KH", viewValue: 'Camboya' },
          { value: "CM", viewValue: 'Camerún' },
          { value: "CA", viewValue: 'Canadá' },
          { value: "TD", viewValue: 'Chad' },
          { value: "CL", viewValue: 'Chile' },
          { value: "CN", viewValue: 'China' },
          { value: "CY", viewValue: 'Chipre' },
          { value: "VA", viewValue: 'Santa Sede' },
          { value: "CO", viewValue: 'Colombia' },
          { value: "KM", viewValue: 'Comoras' },
          { value: "CD", viewValue: 'Congo(República Democrática del)' },
          { value: "CG", viewValue: 'Congo' },
          { value: "KR", viewValue: 'Corea(República de)' },
          { value: "KP", viewValue: 'Corea(Democrática del Pueblo de)' },
          { value: "CR", viewValue: 'Costa Rica' },
          { value: "CI", viewValue: 'Côte d\'Ivoire' },
          { value: "HR", viewValue: 'Croacia' },
          { value: "CU", viewValue: 'Cuba' },
          { value: "CW", viewValue: 'Curaçao' },
          { value: "DK", viewValue: 'Dinamarca' },
          { value: "DM", viewValue: 'Dominica' },
          { value: "EC", viewValue: 'Ecuador' },
          { value: "EG", viewValue: 'Egipto' },
          { value: "SV", viewValue: 'El Salvador' },
          { value: "AE", viewValue: 'Emiratos Árabes Unidos' },
          { value: "ER", viewValue: 'Eritrea' },
          { value: "SK", viewValue: 'Eslovaquia' },
          { value: "SI", viewValue: 'Eslovenia' },
          { value: "ES", viewValue: 'España' },
          { value: "US", viewValue: 'Estados Unidos de América' },
          { value: "EE", viewValue: 'Estonia' },
          { value: "ET", viewValue: 'Etiopía' },
          { value: "PH", viewValue: 'Filipinas' },
          { value: "FI", viewValue: 'Finlandia' },
          { value: "FJ", viewValue: 'Fiyi' },
          { value: "FR", viewValue: 'Francia' },
          { value: "GA", viewValue: 'Gabón' },
          { value: "GM", viewValue: 'Gambia' },
          { value: "GE", viewValue: 'Georgia' },
          { value: "GS", viewValue: 'Islas Georgias del Sur y Sandwich del Sur' },
          { value: "GH", viewValue: 'Ghana' },
          { value: "GI", viewValue: 'Gibraltar' },
          { value: "GR", viewValue: 'Grecia' },
          { value: "GD", viewValue: 'Granada' },
          { value: "GL", viewValue: 'Groenlandia' },
          { value: "GP", viewValue: 'Guadalupe' },
          { value: "GU", viewValue: 'Guam' },
          { value: "GT", viewValue: 'Guatemala' },
          { value: "GF", viewValue: 'Guayana Francesa' },
          { value: "GG", viewValue: 'Guernsey' },
          { value: "GN", viewValue: 'Guinea' },
          { value: "GQ", viewValue: 'Guinea Ecuatorial' },
          { value: "GW", viewValue: 'Guinea-Bissau' },
          { value: "GY", viewValue: 'Guyana' },
          { value: "HT", viewValue: 'Haití' },
          { value: "HN", viewValue: 'Honduras' },
          { value: "HK", viewValue: 'Hong Kong' },
          { value: "HU", viewValue: 'Hungría' },
          { value: "IN", viewValue: 'India' },
          { value: "ID", viewValue: 'Indonesia' },
          { value: "IR", viewValue: 'Irán(República Islámica de)' },
          { value: "IQ", viewValue: 'Iraq' },
          { value: "IE", viewValue: 'Irlanda' },
          { value: "IS", viewValue: 'Islandia' },
          { value: "AC", viewValue: 'Isla de la Ascensión' },
          { value: "IM", viewValue: 'Isla de Man' },
          { value: "CX", viewValue: 'Isla de Navidad' },
          { value: "NF", viewValue: 'Isla Norfolk' },
          { value: "BV", viewValue: 'Isla Bouvet' },
          { value: "KY", viewValue: 'Islas Caimán' },
          { value: "CC", viewValue: 'Islas Cocos(Keeling)' },
          { value: "CK", viewValue: 'Islas Cook' },
          { value: "FO", viewValue: 'Islas Feroe' },
          { value: "FK", viewValue: 'Islas Falkland(Malvinas)' },
          { value: "MP", viewValue: 'Islas Marianas del Norte' },
          { value: "MH", viewValue: 'Islas Marshall' },
          { value: "PN", viewValue: 'Islas Pitcairn' },
          { value: "SB", viewValue: 'Islas Salomón' },
          { value: "TC", viewValue: 'Islas Turcas y Caicos' },
          { value: "UM", viewValue: 'Islas Ultramarinas Menores de Estados Unidos' },
          { value: "VG", viewValue: 'Islas Vírgenes Británicas' },
          { value: "VI", viewValue: 'Islas Vírgenes de los Estados Unidos' },
          { value: "IL", viewValue: 'Israel' },
          { value: "IT", viewValue: 'Italia' },
          { value: "JM", viewValue: 'Jamaica' },
          { value: "JP", viewValue: 'Japón' },
          { value: "JE", viewValue: 'Jersey' },
          { value: "JO", viewValue: 'Jordania' },
          { value: "KZ", viewValue: 'Kazajistán' },
          { value: "KE", viewValue: 'Kenya' },
          { value: "KG", viewValue: 'Kirguistán' },
          { value: "KI", viewValue: 'Kiribati' },
          { value: "KW", viewValue: 'Kuwait' },
          { value: "LA", viewValue: 'Lao, República Democrática Popular' },
          { value: "LS", viewValue: 'Lesoto' },
          { value: "LV", viewValue: 'Letonia' },
          { value: "LB", viewValue: 'Líbano' },
          { value: "LR", viewValue: 'Liberia' },
          { value: "LY", viewValue: 'Libia' },
          { value: "LI", viewValue: 'Liechtenstein' },
          { value: "LT", viewValue: 'Lituania' },
          { value: "LU", viewValue: 'Luxemburgo' },
          { value: "MO", viewValue: 'Macao' },
          { value: "MK", viewValue: 'Macedonia del Norte' },
          { value: "MG", viewValue: 'Madagascar' },
          { value: "MY", viewValue: 'Malasia' },
          { value: "MW", viewValue: 'Malawi' },
          { value: "MV", viewValue: 'Maldivas' },
          { value: "ML", viewValue: 'Malí' },
          { value: "MT", viewValue: 'Malta' },
          { value: "MA", viewValue: 'Marruecos' },
          { value: "MQ", viewValue: 'Martinica' },
          { value: "MU", viewValue: 'Mauricio' },
          { value: "MR", viewValue: 'Mauritania' },
          { value: "YT", viewValue: 'Mayotte' },
          { value: "MX", viewValue: 'México' },
          { value: "FM", viewValue: 'Micronesia(Estados Federados de)' },
          { value: "MD", viewValue: 'Moldova, República de' },
          { value: "MC", viewValue: 'Mónaco' },
          { value: "MN", viewValue: 'Mongolia' },
          { value: "ME", viewValue: 'Montenegro' },
          { value: "MS", viewValue: 'Montserrat' },
          { value: "MZ", viewValue: 'Mozambique' },
          { value: "MM", viewValue: 'Myanmar' },
          { value: "NA", viewValue: 'Namibia' },
          { value: "NR", viewValue: 'Nauru' },
          { value: "NP", viewValue: 'Nepal' },
          { value: "NI", viewValue: 'Nicaragua' },
          { value: "NE", viewValue: 'Níger' },
          { value: "NG", viewValue: 'Nigeria' },
          { value: "NU", viewValue: 'Niue' },
          { value: "NO", viewValue: 'Noruega' },
          { value: "NC", viewValue: 'Nueva Caledonia' },
          { value: "NZ", viewValue: 'Nueva Zelanda' },
          { value: "OM", viewValue: 'Omán' },
          { value: "NL", viewValue: 'Países Bajos' },
          { value: "PK", viewValue: 'Pakistán' },
          { value: "PW", viewValue: 'Palau' },
          { value: "PA", viewValue: 'Panamá' },
          { value: "PG", viewValue: 'Papúa Nueva Guinea' },
          { value: "PY", viewValue: 'Paraguay' },
          { value: "PE", viewValue: 'Perú' },
          { value: "PF", viewValue: 'Polinesia Francesa' },
          { value: "PL", viewValue: 'Polonia' },
          { value: "PT", viewValue: 'Portugal' },
          { value: "PR", viewValue: 'Puerto Rico' },
          { value: "QA", viewValue: 'Qatar' },
          { value: "GB", viewValue: 'Reino Unido de Gran Bretaña e Irlanda del Norte' },
          { value: "CF", viewValue: 'República Centroafricana' },
          { value: "CZ", viewValue: 'República Checa' },
          { value: "DO", viewValue: 'República Dominicana' },
          { value: "RE", viewValue: 'Reunión' },
          { value: "RW", viewValue: 'Ruanda' },
          { value: "RO", viewValue: 'Rumanía' },
          { value: "RU", viewValue: 'Rusia(Federación de)' },
          { value: "EH", viewValue: 'Sahara Occidental' },
          { value: "WS", viewValue: 'Samoa' },
          { value: "AS", viewValue: 'Samoa Americana' },
          { value: "BL", viewValue: 'San Bartolomé' },
          { value: "KN", viewValue: 'San Cristóbal y Nieves' },
          { value: "SM", viewValue: 'San Marino' },
          { value: "MF", viewValue: 'San Martín(territorio francés)' },
          { value: "PM", viewValue: 'San Pedro y Miquelón' },
          { value: "VC", viewValue: 'San Vicente y las Granadinas' },
          { value: "SH", viewValue: 'Santa Elena, Ascensión y Tristán de Acuña' },
          { value: "LC", viewValue: 'Santa Lucía' },
          { value: "ST", viewValue: 'Santo Tomé y Príncipe' },
          { value: "SN", viewValue: 'Senegal' },
          { value: "RS", viewValue: 'Serbia' },
          { value: "SC", viewValue: 'Seychelles' },
          { value: "SL", viewValue: 'Sierra Leona' },
          { value: "SG", viewValue: 'Singapur' },
          { value: "SX", viewValue: 'Sint Maarten(territorio neerlandés)' },
          { value: "SY", viewValue: 'Siria(República Árabe de)' },
          { value: "SO", viewValue: 'Somalia' },
          { value: "LK", viewValue: 'Sri Lanka' },
          { value: "ZA", viewValue: 'Sudáfrica' },
          { value: "SD", viewValue: 'Sudán' },
          { value: "SS", viewValue: 'Sudán del Sur' },
          { value: "SE", viewValue: 'Suecia' },
          { value: "CH", viewValue: 'Suiza' },
          { value: "SR", viewValue: 'Surinam' },
          { value: "SJ", viewValue: 'Svalbard y Jan Mayen' },
          { value: "SZ", viewValue: 'Swazilandia' },
          { value: "TJ", viewValue: 'Tayikistán' },
          { value: "TH", viewValue: 'Tailandia' },
          { value: "TW", viewValue: 'Taiwán(Provincia de China)' },
          { value: "TZ", viewValue: 'Tanzania, República Unida de' },
          { value: "IO", viewValue: 'Territorio Británico del Océano Índico' },
          { value: "TF", viewValue: 'Territorios Australes Franceses' },
          { value: "PS", viewValue: 'Territorios Palestinos Ocupados' },
          { value: "TL", viewValue: 'Timor-Leste' },
          { value: "TG", viewValue: 'Togo' },
          { value: "TK", viewValue: 'Tokelau' },
          { value: "TO", viewValue: 'Tonga' },
          { value: "TT", viewValue: 'Trinidad y Tobago' },
          { value: "TN", viewValue: 'Túnez' },
          { value: "TM", viewValue: 'Turkmenistán' },
          { value: "TR", viewValue: 'Turquía' },
          { value: "TV", viewValue: 'Tuvalu' },
          { value: "UA", viewValue: 'Ucrania' },
          { value: "UG", viewValue: 'Uganda' },
          { value: "UY", viewValue: 'Uruguay' },
          { value: "UZ", viewValue: 'Uzbekistán' },
          { value: "VU", viewValue: 'Vanuatu' },
          { value: "VE", viewValue: 'Venezuela(República Bolivariana de)' },
          { value: "VN", viewValue: 'Vietnam' },
          { value: "WF", viewValue: 'Wallis y Futuna' },
          { value: "YE", viewValue: 'Yemen' },
          { value: "DJ", viewValue: 'Yibuti' },
          { value: "ZM", viewValue: 'Zambia' },
          { value: "ZW", viewValue: 'Zimbabue' }
        ];
        return of (selectForm);
}
}

  