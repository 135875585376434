import { Component, EventEmitter, Input, Output } from '@angular/core';
import {  Plans, Segment } from 'src/app/core/models/plans/plans';
import { PlansService } from 'src/app/core/services/plans/plans.service';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent {
  public plans: Plans[] = [];
  public planSelected!: Plans;
  @Input() segment!: Segment;
  @Output() planSelectedToOnboarding = new EventEmitter<string>();

  constructor( private plansService: PlansService){}
  ngOnInit(): void {
    this.plansService.getPlansList().subscribe((res) => {
      this.plans = res.filter((item) => item.segmentId === this.segment);
    })
  }
  sendPlan(){
    this.planSelectedToOnboarding.emit(this.planSelected.id)
    console.log(this.planSelected.id)
  }
}
