import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Data, InfoTable } from 'src/app/core/models/info-table/info-table';

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss']
})
export class InfoTableComponent {
  public loader: boolean = true;
  @Input() infoTable!: InfoTable;
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() loaderHidden: any;

  public editedField: FormControl = new FormControl()
  


  ngOnInit() {
    this.loader = this.loaderHidden
  }



  showEdit(item: Data) {
    this.infoTable.data.forEach(elem => {
      if (elem == item) {
        elem.editing = true
      }
    })
  }
  hideEdit(item: Data) {
    this.infoTable.data.forEach(elem => {
      if (elem == item) {
        elem.editing = false
      }
    })
  }

  editField(item: Data) {
    this.infoTable.data.forEach(elem => {
      if (elem == item) {
        elem.value = this.editedField.value;
        this.editEvent.emit(elem)
      }
    })
  }
}
