<div class="section_header">
    <div class="business_name">
        <mat-icon>store</mat-icon>
        <p>{{businessData.name}}</p>
    </div>
    <app-stepperBussines [businessData]="businessData"></app-stepperBussines>
    
</div>
<div class="full-info-container" *ngIf="businessData">
<app-info-table [infoTable]="infoCompany"></app-info-table>
<app-info-table [infoTable]="infoRepresentative"></app-info-table>
<ng-container *ngFor="let adminInfo of infoAdministrators">
    <app-info-table [infoTable]="adminInfo"></app-info-table>
</ng-container>
<app-info-table [infoTable]="infoPartners"></app-info-table>
</div>
