<mat-dialog-actions class="actions-viewer" align="end">
    <mat-icon *ngIf="showZoom && data.extension != 'pdf'" matTooltip="Ampliar" (click)="zoomInImage()">zoom_in</mat-icon>
    <mat-icon *ngIf="!showZoom && data.extension != 'pdf'" matTooltip="Alejar" (click)="zoomOutImage()">zoom_out</mat-icon>
    <mat-icon role="button" mat-flat-button mat-dialog-close>cancel</mat-icon>
</mat-dialog-actions>
<mat-dialog-content class="mat-typography">
    <ng-container class="img" *ngIf="data.extension === 'jpeg' || data.extension === 'png' || data.extension === 'jpg'">
            <img [src]="data.url" alt="doc-image" (click)="currentSize == 'large' ? zoomInImage() : zoomOutImage()" [ngClass]="currentSize" />
    </ng-container>
    <ng-container class="img"  *ngIf="data.extension === 'pdf'">
            <pdf-viewer [src]="data.url" [rotation]="0"
            [original-size]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            style="width: 700px; height: 400px; object-fit: contain; max-width: 100%; padding: 0 5px; overflow: hidden;"></pdf-viewer>
       
    </ng-container>
</mat-dialog-content>