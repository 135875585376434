import { Component, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectFormMockService } from 'src/app/core/services/selects/countrySelect.mock-service';
import { allRegex } from 'src/app/core/extensions/regex';
import { Country, Prefix } from 'src/app/core/models/selects/selects';
import { PrefixMockService } from 'src/app/core/services/selects/prefix.mock-service';
import { DatePipe } from '@angular/common';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalDataComponent } from 'src/app/shared/components/onboarding-forms/personal-data/personal-data.component';
@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss']
})
export class CreateEmployeeComponent {

  @ViewChild(PersonalDataComponent) personaDataComponent!: PersonalDataComponent;
  public idCompany!: any;
  public pipe!: DatePipe;
  public statusOk!: boolean;
  public statusKo!: boolean;
  public employeeData: any;
  public validEmployee: boolean = false;

  public createdEmployeeMsg: string = "El usuario ha sido creado con éxito";
  public errorEmployeeMsg: string = "Se ha producido un error. El usuario no ha podido ser creado."

  public loader!: boolean;


  constructor(private createEmployeeService: BusinessService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.idCompany = this.route.snapshot.paramMap.get('id')
  }

  receiveEmployee(form: FormGroup) {
    this.employeeData = form.value
    this.validEmployee = form.valid
  }

  submit() {
    this.loader = true;
    this.createEmployeeService.createEmployee(this.idCompany, this.employeeData).subscribe({

      next: () => {
        this.loader = false;
        this.statusOk = true;
        setTimeout(() => {
        this.statusOk = false
        }, 5000)
        
        this.personaDataComponent.resetForm()
      },
      error: (error) => {
        this.loader = false;
        this.statusKo = true;
        setTimeout(() => {
          this.statusKo = false
        }, 5000)
      },
      complete: () => {
        console.log("Completed!")
      }

    })


  }

}


