import { HttpParams } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-table-employee',
  templateUrl: './table-employee.component.html',
  styleUrls: ['./table-employee.component.scss']
})
export class TableEmployeeComponent {

  public displayedColumns: string[] = ['link', 'name', 'role', 'email'];
  public allEmployees!: any[];
  public currentPage: number = 1;
  public filters: HttpParams = new HttpParams();
  public showEmployee: boolean = true;
  public dataEmployees: any;


  constructor(@Inject('dynamicData') public employees: any) {
    /* this.allEmployees = employees */
   }

  ngOnInit() {
    this.allEmployees = this.employees
    console.log(this.allEmployees)
  }

  showEmployeeDetail(idEmployee: string) {
    this.showEmployee = false;
    const dataEmployees = this.employees.find((item: { id: string; }) => {
      return item.id == idEmployee
    })
    console.log(dataEmployees)
    this.dataEmployees = dataEmployees
    console.log(this.dataEmployees)
  }

  /*  onPageChange(event: PageEvent){
    this.currentPage = event.pageIndex+1
    this.filters = this.filters.set("page", (event.pageIndex + 1).toString())
    this.getUserBusiness()
  } */

  returnTableEmployee(event: boolean) {
    this.showEmployee = event
  }

  issueCard(id:any) {
   this.showEmployee = false;
  }

  hasData(): boolean {
    return this.allEmployees && this.allEmployees.length > 0 && this.showEmployee;
  }

  createEmployeeOk(item: any) {
    console.log(item)
    if(item){
      
    }
  }
}
