<div class="personal-adress-content">
  <form [formGroup]="addressData" class="personal-adress-form">
    <div class="first">
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Código Postal</mat-label>
        <input
          matInput
          formControlName="zip"
          #zip
          (keyup)="zipCodeDetail()"
          maxlength="5"
        />
        <mat-error *ngIf="!addressData.get('zip')?.valid && addressData.get('zip')?.dirty && !addressData.get('zip')?.hasError('zipError')"> 
          Código Postal incorrecto
        </mat-error>
        <mat-error *ngIf="addressData.get('zip')?.hasError('zipError')">{{addressData.get("zip")?.getError("zipError")}}</mat-error>
      </mat-form-field>
      <mat-form-field
        appearance="fill"
        class="long_input"
        *ngIf="cityValue!.length <= 1"
      >
        <mat-label>Ciudad</mat-label>
        <input matInput formControlName="city" #city [value]="cityValue">
        <mat-error *ngIf="!addressData.get('city')?.valid">
          Debe introducir una ciudad
        </mat-error>
      </mat-form-field>
      <mat-form-field
        appearance="fill"
        class="long_input"
        *ngIf="cityValue!.length > 1"
      >
        <mat-label>Ciudad</mat-label>
        <mat-select name="city" formControlName="city">
          <mat-option *ngFor="let item of cityValue" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!addressData.get('city')?.valid">
          Debe introducir una ciudad
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="long_input">
        <mat-label>País</mat-label>
        <mat-select name="country" formControlName="country">
          <mat-option *ngFor="let item of country" [value]="item.value">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!addressData.get('country')?.valid">
          Debe introducir un país
        </mat-error>
      </mat-form-field>
      <div class="individual">
        <mat-form-field appearance="fill" class="mini_input">
          <mat-label>Tipo de vía</mat-label>
          <mat-select matInput formControlName="via">
            <mat-option *ngFor="let item of streetTypeList "value="{{item.value}}">{{item.viewValue}}</mat-option>
          </mat-select>
          <mat-error
           *ngIf="!addressData.get('via')?.valid"
          >Seleccione un tipo</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="short_input">
          <mat-label>Dirección</mat-label>
          <input matInput formControlName="street" />
          <mat-error *ngIf="!addressData.get('street')?.valid">
            Introduce un nombre de calle, plaza...
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Nº</mat-label>
        <input matInput formControlName="number" />
        <mat-error *ngIf="!addressData.get('number')?.valid">
          Introduce un número
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Piso</mat-label>
        <input matInput formControlName="flat" />
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Puerta</mat-label>
        <input matInput formControlName="door" />
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Otro</mat-label>
        <input matInput formControlName="other" />
      </mat-form-field>
    </div>
  </form>
<!--   <button
    *ngIf="!isIndividual"
    mat-flat-button
    class="nextStep_btn"
    [disabled]="!addressData.valid"
    (click)="onSubmit()"
  >
    Siguiente
  </button>
  <button
  *ngIf="isIndividual"
    mat-flat-button
    class="nextStep_btn"
    [disabled]="!addressData.valid"
    (click)="onSubmit()"
  >
    Crear Usuario
  </button>
  <div class="loader" *ngIf="loader"></div> -->
</div>
