import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  public hiddenLogout!: boolean;
  _isLoggedIn!: boolean;

  constructor(private auth: AuthService){
    
  }
  ngOnInit(){
    this.auth.setTokenSessionStorage();
    this.auth.getTokenSessionStorage().subscribe( 
      res => {
        this._isLoggedIn = res;
        if(this._isLoggedIn){
          this.hiddenLogout = true;
        }else{
          this.hiddenLogout = false;
        }
    })
      
      
  }
  logout(){
    this.auth.signOut();
  }
}
