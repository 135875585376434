 <fieldset class="fieldset">
            <legend class="legend" *ngIf="infoTable.icon || infoTable.title"> <p class="info_title"><mat-icon *ngIf="infoTable.icon">{{infoTable.icon}}</mat-icon>{{infoTable.title}}</p></legend> 
            <table class="info_table">
                @for (item of infoTable.data; track $index) {
                    <tr>
                        <th class="table_key">{{item.name}}</th>
                        <td>
                            <p *ngIf="!item.editing && !item.matIcon" class="td">{{item.value}} 
                                <mat-icon  *ngIf="item.editable" (click)="showEdit(item)">edit</mat-icon> 
                                <mat-icon *ngIf="item.link" routerLink="{{item.link}}" style="color: rgb(70, 70, 226);" matTooltip="{{item.tooltip ? item.tooltip : null}}">open_in_new</mat-icon>
                            </p>
                            <p *ngIf="!item.editing && item.matIcon" class="td">
                                <span *ngIf="item.value"> Sí </span>
                                <span *ngIf="!item.value"> - </span>
                            </p>
        
                            <div class="td" *ngIf="item.editing">
                                <input type="text" [formControl]="editedField" autofocus (keydown.enter)="editField(item)">
                                <div class="buttons_container">
                                    <button (click)="editField(item)" mat-icon-button>
                                        <mat-icon>check</mat-icon>
                                    </button>
                                    <button (click)="hideEdit(item)" mat-icon-button>
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                }
            </table>
        </fieldset>