import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name:'segmentFormat'})

export class SegmentFormatPipe implements PipeTransform {
  
  transform(segmentId: string): string {
    switch (segmentId) {
      case 'BUSINESS':
        return 'EMPRESAS';
      case 'INDIVIDUAL':
        return 'PARTICULARES';
      case 'FREELANCE':
        return 'AUTÓNOMOS';
      default:
        return 'TODOS LOS PLANES';
    }
  }
}