import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getProvinceByCp } from 'src/app/core/extensions/getProvince';
import { ZipCodeModel } from 'src/app/core/models/selects/selects';
import { UserData } from 'src/app/core/models/user/userData';
import { ZipcodelistMockService } from 'src/app/core/services/selects/zipcodelist.mock-service';
import { BusinessMockService } from 'src/app/core/services/user/business.mock-service';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { ShowStatusComponent } from 'src/app/shared/components/user-documents/showStatusDialog/showStatusDialog.component';

@Component({
  selector: 'app-info-business',
  templateUrl: './info-business.component.html',
  styleUrls: ['./info-business.component.scss'],
})
export class InfoBusinessComponent {
  public loader!: boolean;
  public businessData!: any;
  public infoCompany: any;
  public userId!: string;
  public provinceData!: ZipCodeModel;
  public zipCode!: string;
  public infoRepresentative: any;
  public infoAdministrators: any;
  public infoPartners: any;
  public representativeData: any;
  public administratorsData: any;
  public partnersData: any;

  //inputData viene de Host. Son los datos de company
  @Input() set userData(val: any) {
    if (!val) {
      return;
    } else {
      this.businessData = val;
      this.representativeData = this.businessData.representative;
      this.administratorsData = this.businessData.administrators!;
      this.partnersData = this.businessData.partners!;
      this.setTableData();
    }
  }

  constructor(
    private provinceList: ZipcodelistMockService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    public companyService: BusinessService
  ) {}

  ngOnInit(): void {
    this.loadBusinessData()
  }

  loadBusinessData() {
    const idCompany = localStorage.getItem('userId')!;
      this.companyService.getCompany(idCompany).subscribe(res => {
        this.businessData = res;
        this.representativeData = this.businessData.representative;
        this.administratorsData = this.businessData.administrators;
        this.partnersData = this.businessData.partners;
        this.setTableData();
      });
  }

  setTableData() {
    this.infoCompany = {
      icon: 'person',
      title: 'Información empresa',
      data: [
        {
          key: 'name',
          name: 'Nombre empresa',
          value: this.businessData.name,
          editable: false,
        },
        {
          key: 'brand',
          name: 'Marca',
          value: this.businessData.brand || 'No se ha definido',
          editable: false,
        },
        {
          key: 'phone',
          name: 'Teléfono',
          value: `${this.businessData.prefix} ${this.businessData.phone}`,
          editable: false,
        },
        {
          key: 'email',
          name: 'Email',
          value: this.businessData.email,
          editable: false,
        },
        /*         {
          key: 'country',
          name: 'Dirección',
          value: this.businessData.addresses[0].city + ', ' + this.businessData.addresses[0].street || "No se ha definido",
          editable: false,
        }, */
        {
          key: 'expeditionCountry',
          name: 'País residencia',
          value:
            this.businessData.country === 'ES'
              ? 'España'
              : this.businessData.country || 'No se ha definido',
          editable: false,
        },
        {
          key: 'incorporationDate',
          name: 'Creación empresa',
          value: this.businessData.incorporationDate || 'No se ha definido',
          editable: false,
        },
        {
          key: 'vatNumber',
          name: 'CIF',
          value: this.businessData.vatNumber,
          editable: false,
        },
        {
          key: 'cnae',
          name: 'CNAE',
          value: this.businessData.cnae,
          editable: false,
        },
      ],
    };

    this.infoRepresentative = {
      icon: 'person',
      title: 'Apoderado',
      data: [
        {
          key: 'name',
          name: 'Nombre Completo',
          value: `${this.representativeData.name} ${
            this.representativeData.surname1
          } ${
            this.representativeData.surname2
              ? this.representativeData.surname2
              : ''
          }`,
        },
        {
          key: 'birthdate',
          name: 'Fecha nacimiento',
          value: this.datePipe.transform(
            this.representativeData.birthdate,
            'dd-MM-YYYY'
          ),
        },
        {
          key: 'gender',
          name: 'Sexo',
          value:
            this.representativeData.gender === 'M' ? 'Masculino' : 'Femenino',
        },
        {
          key: 'phone',
          name: 'Teléfono',
          value: `${this.representativeData.prefix} ${this.representativeData.phone}`,
        },
        {
          key: 'email',
          name: 'Email',
          value: this.representativeData.email,
        },
        {
          key: 'nationality',
          name: 'Nacionalidad',
          value:
            this.representativeData.nationality === 'ES'
              ? 'España'
              : this.representativeData.nationality || 'No se ha definido',
        },
        {
          key: 'expeditionCountry',
          name: 'expeditionCountry',
          value:
            this.representativeData.residenceCountry === 'ES'
              ? 'España'
              : this.representativeData.residenceCountry || 'No se ha definido',
        },
        {
          key: 'residenceCountry',
          name: 'País de residencia',
          value:
            this.representativeData.residenceCountry === 'ES'
              ? 'España'
              : this.representativeData.residenceCountry || 'No se ha definido',
        },
        {
          key: 'documentType',
          name: 'Tipo documento identidad',
          value: this.representativeData.documentType,
        },
        {
          key: 'identifier',
          name: 'Nº documento',
          value: this.representativeData.identifier,
        },
        {
          key: 'pepRole',
          name: 'Pep Role',
          value: this.representativeData.pep || 'No se ha definido',
        },
        /*         {
          key: 'pepCountry',
          name: 'Pep Country',
          value: this.representativeData.pepCountry
        }, */
      ],
    };

    this.infoAdministrators = {
      icon: 'person',
      title: 'Administrador',
      data: [],
    };
    this.administratorsData.forEach((item: any) => {
      this.infoAdministrators.data.push(
        {
          key: 'name',
          name: 'Nombre Completo',
          value: `${item.name} ${item.surname1} ${
            item.surname2 ? item.surname2 : ''
          }`,
        },
        {
          key: 'birthdate',
          name: 'Fecha nacimiento',
          value: item.birthdate,
        },
        {
          key: 'gender',
          name: 'Sexo',
          value:
            this.representativeData.gender === 'M'
              ? 'Masculino'
              : 'Femenino' || 'No se ha definido',
        },
        {
          key: 'phone',
          name: 'Teléfono',
          value: `${item.prefix} ${item.phone}`,
        },
        {
          key: 'email',
          name: 'Email',
          value: item.email,
        },
        {
          key: 'nationality',
          name: 'Nacionalidad',
          value:
            item.nationality === 'ES'
              ? 'España'
              : item.nationality || 'No se ha definido',
        },
        {
          key: 'expeditionCountry',
          name: 'expeditionCountry',
          value:
            item.nationality === 'ES'
              ? 'España'
              : item.nationality || 'No se ha definido',
        },
        {
          key: 'residenceCountry',
          name: 'País de residencia',
          value:
            item.residenceCountry === 'ES'
              ? 'España'
              : item.residenceCountry || 'No se ha definido',
        },
        {
          key: 'documentType',
          name: 'Tipo documento identidad',
          value: item.documentType,
        },
        {
          key: 'identifier',
          name: 'Nº documento',
          value: item.identifier,
        }
      );
    });

    this.infoPartners = {
      icon: 'person',
      title: 'Socios',
      data: [],
    };

    if (this.partnersData.length === 0) {
      this.infoPartners.data.push({
        key: 'noPartners',
        name: 'Socios',
        value: 'No hay socios',
      });
    } else {
      this.partnersData.forEach((item: any) => {
        this.infoPartners.data.push(
          {
            key: 'name',
            name: 'Nombre Completo',
            value: `${item.name} ${item.surname1} ${
              item.surname2 ? item.surname2 : ''
            }`,
          },
          {
            key: 'birthdate',
            name: 'Fecha nacimiento',
            value: this.datePipe.transform(item.birthdate, 'dd-MM-YYYY'),
          },
          {
            key: 'gender',
            name: 'Sexo',
            value:
              this.representativeData.gender === 'M' ? 'Masculino' : 'Femenino',
          },
          {
            key: 'phone',
            name: 'Teléfono',
            value: `${item.prefix} ${item.phone}`,
          },
          {
            key: 'email',
            name: 'Email',
            value: item.email,
          },
          {
            key: 'nationality',
            name: 'Nacionalidad',
            value: item.nationality || 'No se ha definido',
          },
          {
            key: 'expeditionCountry',
            name: 'expeditionCountry',
            value: item.nationality || 'No se ha definido',
          },
          {
            key: 'residenceCountry',
            name: 'País de residencia',
            value: item.residenceCountry || 'No se ha definido',
          },
          {
            key: 'documentType',
            name: 'Tipo documento identidad',
            value: item.documentType,
          },
          {
            key: 'identifier',
            name: 'Nº documento',
            value: item.identifier,
          }
        );
      });
    }

    console.log('partners', this.partnersData);
  }

  showEdit(item: string) {
    this.infoCompany.find((element: { key: string; editing: boolean }) => {
      if (element.key == item) {
        element.editing = true;
      }
    });
  }

  hideEdit(item: string) {
    this.infoCompany.find((element: { key: string; editing: boolean }) => {
      if (element.key == item) {
        element.editing = false;
      }
    });
  }

  /*   editField(item: string) {
      let body: Record<string, string> = {}
      body[item] = this.editedField.value;
      this.businessService.editUser(this.userId, body).subscribe(res => {
        this.getUserData()
      })
    } */

  getProvinceList() {
    this.provinceList.cpList().subscribe((res) => {
      this.provinceData = getProvinceByCp(res, this.zipCode)!;
    });
  }
}
