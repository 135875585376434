import { SelectListMockService } from 'src/app/core/services/selects/selectsList';
import { PrefixMockService } from '../../../../core/services/selects/prefix.mock-service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { allRegex } from 'src/app/core/extensions/regex';
import { Country, Prefix } from 'src/app/core/models/selects/selects';
import { SelectFormMockService } from 'src/app/core/services/selects/countrySelect.mock-service';
import { ActivityListMockService } from 'src/app/core/services/selects/activityLists';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss']
})
export class CompanyDataComponent {
  companyData!: FormGroup
  public country!: Country[];
  public prefix!: Prefix[];
  public cnaeList!: any[];
  public rrppLevelList!: any[];
  public pipe!: any;
  public todayWithPipe!: any;
  /*  public minDate!: Date;
   public maxDate!: Date */

  public incomeSelectBusiness!: any[]
  public volumeSelectBusiness!: any[]
  public today = new Date();
  @Output() companyDataEvent = new EventEmitter<FormGroup>();

  constructor(
    private formBuilder: FormBuilder,
    private selectDataService: SelectFormMockService,
    private prefixMockService: PrefixMockService,
    private cnaeService: ActivityListMockService,
    private selectListService: SelectListMockService
  ) {
    this.volumeSelectBusiness = [
      {
        value: 'A',
        viewValue: 'Menos de 100.000€'
      },
      {
        value: 'B',
        viewValue: 'Entre 100.001€ y 500.000€'
      },
      {
        value: 'C',
        viewValue: 'Entre 500.001€ y 1.500.000€'
      },
      {
        value: 'D',
        viewValue: 'Más de 1.500.000€'
      }
    ],
    this.incomeSelectBusiness = [
      {
        value: 'A',
        viewValue: 'Menos de 100.000€'
      },
      {
        value: 'B',
        viewValue: 'Entre 100.001€ y 600.000€'
      },
      {
        value: 'C',
        viewValue: 'Entre 600.001€ y 1.200.000€'
      },
      {
        value: 'D',
        viewValue: 'Más de 1.200.000€'
      }
    ]

  }


  ngOnInit() {
    this.selectDataService.selectList().subscribe(
      data => {
        this.country = data.country;
      }
    )
    this.cnaeService.cnaeSelectList().subscribe(
      data => {
        console.log(data)
        this.cnaeList = data;
      }
    )
    this.prefixMockService.preList().subscribe(
      data => {
        this.prefix = data.prefix;
      }
    )
    this.selectListService.rrppLevel().subscribe(
      data => {
        this.rrppLevelList = data
      }
    )
    this.companyData = this.formBuilder.group({
      name: ['', [Validators.required]],
      brand: ['',],
      prefix: ['',],
      phone: ['',],
      email: ['',],
      country: ['', [Validators.required]],
      expeditionCountry: ['', [Validators.required]],
      incorporationDate: ['', [Validators.required]],
      vatNumber: ['', [Validators.required, Validators.pattern(allRegex.regexCIF)]],
      cnae: ['', [Validators.required]],
      salesVolume:['', [Validators.required]],
      income:['', [Validators.required]],
      levelOwnResources: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(allRegex.regexPin)]],
      rePassword: ['', [Validators.required]],

    });

    this.companyData.valueChanges.subscribe(res => {
      const pin = this.companyData.get('password')?.value
      const repeatPin = this.companyData.get('rePassword')?.value
      if (pin !== repeatPin) {
        this.companyData.get('rePassword')?.setErrors({ rePasswordError: 'cambia el pin' })
      }
      this.companyDataEvent.emit(this.companyData);
    })

  }

  formattedDate() {
    const birthdateValue = this.companyData.get('incorporationDate')?.value;
    this.pipe = new DatePipe('en-US');
    this.todayWithPipe = this.pipe.transform(birthdateValue, 'yyyy-MM-dd');
    this.companyData.get('incorporationDate')?.setValue(this.todayWithPipe);
  }

}

