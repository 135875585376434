<div class="login_page">
    <div class="login_container">
        <form class="login_form" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()">
            <mat-form-field floatLabel="always">
                <mat-label >Usuario</mat-label>
                <input matInput formControlName="username" #username>
                <mat-error *ngIf="!loginForm.get('username')?.valid && (loginForm.get('username')?.dirty) || submitted">Introduce un email correcto</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="always">
                <mat-label>Contraseña</mat-label>
                <input placeholder="Contraseña*" matInput formControlName="password" #password type="password">
                <mat-error *ngIf="!loginForm.get('password')?.valid && (loginForm.get('password')?.dirty) || submitted">Introduce tu password correctamente</mat-error>
            </mat-form-field>
        </form>
        <p *ngIf="passError" class="errorPass">Nombre de usuario o contraseña no válidos</p>
        <button [disabled]="!loginForm.valid" class="login-button" (click)="onSubmit()"  aria-label="register_btn" type="submit" [disabled]="!loginForm.valid">Acceder
        </button>
        <a href="">¿Has olvidado tu contraseña?</a>
    </div>
</div>

