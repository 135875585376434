import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-user-kyc',
  templateUrl: './user-kyc.component.html',
  styleUrls: ['./user-kyc.component.scss']
})
export class UserKycComponent implements OnInit{
  @Input() showEdit!: Function;
  @Input() editField!: Function;
  @Input() hideEdit!: Function;
  @Input() kycInfo!: any[];
  public editedField: FormControl = new FormControl()

  constructor(){}
  
  ngOnInit(): void {

  }
}
