export const stateFinancing = (state: string) => {
  switch (state.toLowerCase()) {
    case 'approved':
      return 'Aprobado';
    case 'rejected':
      return 'Denegado';
    case 'pending':
      return 'Pendiente';
    case 'completed':
      return 'Completado';
    case 'signed':
      return 'Tramitando';
    case 'cancelled':
      return 'Cancelado';
    default:
      return state;
  }
  }

  export function getStatusClass(state: string): string {
    switch (state.toLowerCase()) {
      case 'approved':
        return 'status-aprobado';
      case 'rejected':
        return 'status-denegado';
      case 'pending':
        return 'status-pendiente';
      case 'completed':
        return 'status-completado';
      case 'signed':
        return 'status-firmado';
      case 'cancelled':
        return 'status-cancelado';
      default:
        return '';
    }
  }