import { HttpClient, HttpParams} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class FinancingService {
  constructor(private http: HttpClient) { }

  public getCustomers(): Observable<any>{
    return this.http.get<any>(`${environment.financingUrl}/customers`)
  }


  public getDetailOperation(identifier: string): Observable<any>{
    return this.http.get<any>(`${environment.financingUrl}/customer/by-identifier/${identifier}`)
  }

  public getContract(idOperation: string): Observable<Blob> {
    return this.http.get<Blob>(`${environment.financingUrl}/financing/${idOperation}/download-contract`, {
      responseType: 'blob' as 'json' 
    });
  }

  public cancelOperation(idOperation: string): Observable<any>{
    return this.http.post<any>(`${environment.financingUrl}/financing/${idOperation}/cancel`, idOperation)
  }

  public rejectOperation(idOperation: string): Observable<any>{
    return this.http.post<any>(`${environment.financingUrl}/financing/${idOperation}/reject-documents`, idOperation)
  }

  public approveOperation(idOperation: string): Observable<any>{
    return this.http.post<any>(`${environment.financingUrl}/financing/${idOperation}/approve-documents`, idOperation)
  }

  public findByFilter(searchText: string, size: number, page: number): Observable<any> {
    let params = new HttpParams()
      .set('searchtext', searchText)
      .set('size', size)
      .set('page', page);
  
    return this.http.get<any>(`${environment.financingUrl}/financings`, { params });
  }
}