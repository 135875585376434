
<div class="user_container">
    <div class="align-StepperInfo">
    <div class="section_header" *ngIf="userData">
        <div class="img-perfil">
            <img *ngIf="!loader" src="{{userAvatar}}" alt="klyaksun-icon" />
            <div class="skeleton-container" *ngIf="loader">
                <ngx-skeleton-loader appearance="circle" [theme]="{ height: '65px', width: '65px' }">
                </ngx-skeleton-loader>
            </div>
        </div>
    
        <div class="section_title" *ngIf="!loader">
            <p *ngIf="!loader">{{userData.name}}
                {{userData.surname1}} {{userData.surname2 ? userData.surname2 : ''}}</p>
            <p class="alias" *ngIf="userData.alias">{{userData.alias}}</p>
        </div>
        
        <div class="skeleton-container" *ngIf="loader">
            <ngx-skeleton-loader appearance="line" [theme]="{ height: '50px', width: '182px' }">
            </ngx-skeleton-loader>
           
        </div>
       
    </div>
    <app-stepperClient  *ngIf="!loader" [userData]="userData" ></app-stepperClient>
</div>

    <div class="user_action" *ngIf="!loader">
        <p class="section_status">
            Estado: <mat-icon [ngClass]="{ 
            'user_statusOk': userStatus === 'CONTRACT_SIGNED', 
            'user_statusKo': userStatus !== 'CONTRACT_SIGNED' }">radio_button_checked</mat-icon>
            {{userStatus === 'CONTRACT_SIGNED' ? 'Activo' : 'Inactivo'}}
        </p>
        <div class="icon-links">
            <div class="icon-link" matTooltip="Consultar KYC" (click)="toggleUserInfo('kyc')"
                *ngIf="!showKyc && !showFailedOp">
                <mat-icon>fingerprint</mat-icon>
            </div>
            <div class="icon-link" matTooltip="Operaciones fallidas" (click)="toggleUserInfo('failedOp')"
                *ngIf="!showFailedOp && !showKyc">
                <mat-icon>priority_high</mat-icon>
            </div>
            <div class="icon-link" matTooltip="Volver" (click)="toggleUserInfo('back')" *ngIf="showKyc || showFailedOp">
                <mat-icon>undo</mat-icon>
            </div>
        </div>
    </div>
    <div *ngIf="!loader && showKyc" class="info_container">
        <app-user-kyc
            [kycInfo]="kycInfo"></app-user-kyc>
    </div>
    <div *ngIf="!loader && showFailedOp" class="info_failedContainer">
        <app-failed-operations
            [kycInfo]="kycInfo"></app-failed-operations>
    </div>
    <div class="info-tables">
    <app-info-table *ngIf="!loader && !showKyc && !showFailedOp" [infoTable]="addressInfo" (editEvent)="editField($event)"></app-info-table>
    <app-info-table *ngIf="!loader && !showKyc && !showFailedOp" [infoTable]="userInfo"></app-info-table>
</div>
    <div class="skeleton-container-personal" *ngIf="loader">
        <ngx-skeleton-loader appearance="line" count="7" [theme]="{ height: '20px' }">
        </ngx-skeleton-loader>
    </div>
</div>
