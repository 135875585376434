<div class="dialog-header">
  <h2 mat-dialog-title>Estados</h2>
</div>
<mat-dialog-content>
 
  <ul>
    <li *ngFor="let status of data.statusList; let i = index" >
    <div class="circle">{{ i + 1 }}</div> <span class="explanationStatus-span">{{ status.explanation }}</span>
  </li>
</ul>
  <div>
    <button class="buttonCloseDialog" mat-button (click)="closeDialog()" mat-dialog-close></button>
  </div>
</mat-dialog-content>  