import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewerDocsComponent } from '../viewer-docs/viewer-docs.component';
import { DocumentHandlerComponent } from '../document-handler/document-handler.component';
import { BusinessService } from 'src/app/core/services/user/business.service';
import {
  DocumentsService,
  METHOD,
} from 'src/app/core/services/documents/documents.service';
import {
  DOCUMENT_STATE,
  getDocumentState,
} from 'src/app/core/extensions/states.extension';
import { DomSanitizer } from '@angular/platform-browser';
import { downloadFile } from 'src/app/core/extensions/downloadFile';
import { FiltersBusinessComponent } from '../../../../modules/business/dashboard-business/filters-business/filters-business.component';
import { ShowStatusComponent } from '../showStatusDialog/showStatusDialog.component';

@Component({
  selector: 'app-table-docs',
  templateUrl: './table-docs.component.html',
  styleUrls: ['./table-docs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableDocsComponent {
  @Input() docs!: any;
  @Input() userId!: string;
  @Output() fullFile: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() refreshDocs: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showAlertOk: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendError: EventEmitter<boolean> = new EventEmitter<boolean>();
  public dataDocs!: any;
  public columnsToDisplay = [
    'type',
    'description',
    'status',
    'createdAt',
    'download' /* , 'upload', 'delete' */,
  ];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public expandedElement!: any;
  public filePreview!: any;
  public fileType!: string;
  public statusReject: boolean = false;
  public showDownloadIcon!: boolean;
  public visibleUploadFile!: boolean;
  public status: string[] = [
    'Validar',
    'Rechazar',
    'Documento caducado',
    'Cancelar',
  ];
  public filePreviews: any;
  public filePdf: any;
  public isDetailLoading: any;

  public actions: any[] = [
    {
      action: 'Validar',
      function: (docId: string, data: any) => this.validate(docId, data),
      availableWhen: ['PENDING'],
    },
    {
      action: 'Rechazar',
      function: (docId: string, data: any) => this.reject(docId, data),
      availableWhen: ['PENDING'],
    },
  ];
  public availableActions: any[] = [];
  getDocumentState = getDocumentState;

  constructor(
    public dialog: MatDialog,
    public documentsService: DocumentsService,
    private sanitizer: DomSanitizer
  ) {}



  statusList = [
    { statusTitleSpan: 'Requerido', explanationStatusSpan: 'Es necesario subir un documento' },
    { statusTitleSpan: 'Pendiente', explanationStatusSpan: 'Pendiente de revisión' },
    { statusTitleSpan: 'Pt. Sefide', explanationStatusSpan: 'Aprobado, pendiente de validación por Sefide' },
    { statusTitleSpan: 'Verificado', explanationStatusSpan: 'Aprobado por nosotros, y por Sefide' },
    { statusTitleSpan: 'Rechazado', explanationStatusSpan: 'Documento Rechazado' },
    { statusTitleSpan: 'Caducado', explanationStatusSpan: 'Documento Caducado' },
  ];



  ngOnInit() {
  }

  shouldShowValidation(element: any): boolean {
    if (element.partnerId) {
      return true;
    } else {
      return element.type !== 'IDE';
    }
  }


  uploadFile(event: any, element: any) {
    event.stopPropagation();
  }

  deleteFile(event: any, element: any) {
    event.stopPropagation();
  }

  statusSelector(statusInfo: { element: any; statusSelected: any }) {
    const dialogRef = this.dialog.open(DocumentHandlerComponent, {
      data: statusInfo,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.valid) {
        statusInfo.statusSelected.function(statusInfo.element.id, result.value);
      } else {
      }
    });
  }

  toggleExpandedRow(element: any): void {
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
      this.getDocumentFile(element);
      this.getAvailableStatus(element.state);
    }
  }

  getDocumentFile(document: any) {
    this.fileType = ''; 
    this.filePreviews = [];
    this.visibleUploadFile = false;

    if (document.state !== DOCUMENT_STATE.REQUIRED) {
      this.filePreview = '';
      const userId = localStorage.getItem('userId');

      this.documentsService
        .getDocument(userId!, document.id)
        .subscribe((res: any) => {
          const filesId = res.files.map((file: any) => {
            return file.id;
          });

          filesId.forEach((element: string) => {
            this.documentsService
              .downloadDocument(userId!, document.id, element)
              .subscribe((res: any) => {
                this.fileType = res.type;
                if (this.fileType !== 'application/pdf') {
                  this.filePreview = this.sanitizer.bypassSecurityTrustUrl(
                    this.readFile(res)
                  );
                } else {
                  var newBlob = new Blob([res], { type: res.type });
                  const pdf = window.URL.createObjectURL(newBlob);
                  this.filePreview = pdf;
                }
                this.filePreviews.push(this.filePreview);
              });
          });
        });
    }
  }

  downloadFile(event: any, document: any) {
    event.stopPropagation();
    this.filePreview = '';
    const userId = localStorage.getItem('userId');
    this.documentsService
      .getDocument(userId!, document.id)
      .subscribe((res: any) => {
        const filesId = res.files.map((file: any) => {
          return file.id;
        });
        filesId.forEach((element: string) => {
          this.documentsService
            .downloadDocument(userId!, document.id, element)
            .subscribe((res: any) => {
              downloadFile(res, document.type);
            });
        });
      });
  }

  askForDocsRefresh() {
    setTimeout(() => {
      this.refreshDocs.emit(true);
    }, 1000);
  }

  askForOkDocs() {
    setTimeout(() => {
      this.refreshDocs.emit(true);
      this.showAlertOk.emit(true);  
    }, 1000);

  }

  captureError(error: boolean){
    this.sendError.emit(true)
  }

  readFile(res: any) {
    const file = new Blob([res], { type: res.type });
    return URL.createObjectURL(file)
  }

  getAvailableStatus(status: any) {
    this.availableActions = [];
    const currentStatus = status;
    this.actions.forEach((action: any) => {
      action.availableWhen.forEach((item: string) => {
        if (item === currentStatus) {
          this.availableActions.push(action);
        }
      });
    });
  }

  validate(docId: string, data: any) {
    this.documentsService
      .verifyDocument(this.userId, docId, METHOD.DENARIUS, data)
      .subscribe((res: any) => {
        this.askForDocsRefresh();
      });
  }
  reject(docId: string, data: any) {
    this.documentsService
      .rejectDocument(this.userId, docId, METHOD.DENARIUS, data)
      .subscribe((res: any) => {
        this.askForDocsRefresh();
      });
  }

  showFullFile(fileInfo: { url: string; extension: string }) {
    this.dialog.open(ViewerDocsComponent, {
      data: fileInfo,
    });
  }

  openDialog() {
    this.dialog.open(ShowStatusComponent, {
      data: { statusList: this.statusList }
    });
  }
}
