import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-detail-employee',
  templateUrl: './detail-employee.component.html',
  styleUrls: ['./detail-employee.component.scss']
})
export class DetailEmployeeComponent {

  public infoEmployeeTable: any;
  public showComponent: boolean = false;
  @Output() returnTableEmployee: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() dataEmployees: any;


  constructor(private datePipe: DatePipe, @Inject('dynamicData') public employees: any) {}

  ngOnInit(): void {
    this.getDetailEmployee()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataEmployees']) {
      console.log('Datos de empleado recibidos:', this.dataEmployees);
    }
  }

  returnToTable(){
    this.returnTableEmployee.emit(true)
  }

  getDetailEmployee() {
    if (this.dataEmployees && this.employees) {
      const employeeDetail = this.employees.find((employee: { identifier: any; }) => employee.identifier == this.dataEmployees.identifier)
      if(employeeDetail) {
        this.infoEmployeeTable = {
          title: "Información personal",
          icon: "person",
          data: [
            {
              key:'name',
              name: 'Nombre',
              title: 'Nombre',
              value: employeeDetail.name,
              editable: false
            },
            {
              key:'surname',
              name: 'Apellidos',
              title: 'Apellidos',
              value: `${employeeDetail.surname1} ${employeeDetail.surname2 ? employeeDetail.surname2 : ''}`,
              editable: false
            },
            {
              key:'documentType',
              name: 'Tipo de documento',
              title: 'Tipo de documento',
              value: employeeDetail.documentType,
              editable: false
            },
            {
              key:'identifier',
              name: 'Nº documento',
              title: 'Nº documento',
              value: employeeDetail.identifier,
              editable: false
            },
            {
              key:'birthdate',
              name: 'Fecha de nacimiento',
              title: 'Fecha de nacimiento',
              value: employeeDetail.birthdate ,
              editable: false
            },
            {
              key:'nationality',
              name: 'Nacionalidad',
              title: 'Nacionalidad',
              value: employeeDetail.nationality === 'ES' ? 'España' : employeeDetail.nationality, 
              editable: false
            },
            {
              key:'residenceCountry',
              name: 'País de residencia',
              title: 'País de residencia',
              value: employeeDetail.residenceCountry === 'ES' ? 'España' : employeeDetail.residenceCountry , 
              editable: false
            },
            {
              key:'email',
              name: 'Email',
              title: 'Email',
              value: employeeDetail.email,
              editable: false
            },
            {
              key:'phone',
              name: 'Teléfono',
              title: 'Teléfono',
              value: `${employeeDetail.prefix} ${employeeDetail.phone}`,
              editable: false
            },
            {
              key:'gender',
              name: 'Género',
              title: 'Género',
              value: employeeDetail.gender === 'M' ? 'Masculino' : 'Femenino',
              editable: false
            },
    
          ]
        }
      }
    }

  }

  showEdit(item: string) {
    this.dataEmployees.find((element: { key: string; editing: boolean; }) => {
      if (element.key == item) {
        element.editing = true
      }
    })

  }
  hideEdit(item: string) {
    this.dataEmployees.find((element: { key: string; editing: boolean; }) => {
      if (element.key == item) {
        element.editing = false
      }
    })
  }

  
}
