<div class="select_container">
    <mat-form-field class="long_input">
    <mat-label>ELIGE UN PLAN:</mat-label>
    <mat-select color="primary" [(ngModel)]="planSelected" name="food" (selectionChange)="sendPlan()">
        <mat-option *ngFor="let plan of plans" [value]="plan">
            {{plan.name}}
        </mat-option>
    </mat-select>
</mat-form-field>
<!-- <p class="plan_detail" *ngIf="planSelected"> {{ planSelected.monthlyPrice |  currency: "€":"symbol":"1.2-2":"es"}}/ mes</p> -->
</div>
