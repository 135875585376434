import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Coin } from '../../models/selects/selects';

@Injectable({
  providedIn: 'root',
})
export class SelectListMockService {
  public companyDocs(): Observable<any> {
    const companyDocumentList = [
        {
          value:'documentNIF',
          viewValue:'Comunicación acreditativa AEAT NIF'
        },
        {
          value:'documentESC',
          viewValue:'Escritura de constitución de sociedad'
        },
        {
          value:'documentESA',
          viewValue:'Escritura de apoderamiento de los apoderados'
        },
        {
          value:'documentTIR',
          viewValue:'Certificado o acta notarial de Titularidad Real'
        },
        {
          value:'documentRMA',
          viewValue:'Escritura del órgano actual de administración'
        },
        {
          value:'documentPEP',
          viewValue:'Declaración de responsabilidad pública'
        },
        {
          value:'documentCAN',
          viewValue:'Cuentas Anuales empresa o Impuesto de Sociedades'
        }
       /*  {
          value:'IBAN',
          viewValue:'Certificado de titularidad de la cuenta'
        },
        {
          value:'DniNieAnverso',
          viewValue:'Dni o Nie anverso'
        },
        {
          value:'DniNieReverso',
          viewValue:'Dni o Nie reverso'
        }, */
    ]
    
    return of(companyDocumentList);
  }
  public retailDocs(): Observable<any>{
    const retailDocumentList = [
      {
          value:'DniNieAnverso',
          viewValue:'Dni o Nie anverso'
      },
      {
          value:'DniNieReverso',
          viewValue:'Dni o Nie reverso'
      },
    ]
    return of(retailDocumentList);
  }
  public streetType(): Observable<any>{
    const viaType = [
      {value: "Calle", viewValue: 'Calle'},
      {value: "Avenida", viewValue: 'Avenida'},
      {value: "Camino", viewValue: 'Camino'},
      {value: "Colonia", viewValue: 'Colonia'},
      {value: "Carretera", viewValue: 'Carretera'},
      {value: "Paseo", viewValue: 'Paseo'},
      {value: "Finca", viewValue: 'Finca'},
      {value: "Poblado", viewValue: 'Poblado'},
      {value: "Plaza", viewValue: 'Plaza'},
      {value: "Rambla", viewValue: 'Rambla'},
      {value: "Ronda", viewValue: 'Ronda'},
      {value: "Urbanización", viewValue: 'Urbanización'},
      {value: "No aplica", viewValue: 'No aplica'},
  ]
  return of (viaType)
  }
  public incomeList(): Observable<any>{
    const incomeSelect = [
      {
        value: 'A',
        viewValue: 'menos de 100.000 €'
      },
      {
        value: 'B',
        viewValue: 'entre 100.001 y 600.000 €'
      },
      {
        value: 'C',
        viewValue: 'entre 600.001 y 1.200.000 €'
      },
      {
        value: 'D',
        viewValue: 'más de 1.200.000 €'
      }
    ]
  return of (incomeSelect)
  }

  public rrppLevel(): Observable<any>{
    const rrppLevels = [
      {value: 'A', viewValue: 'Menos de 500.000'},
      {value: 'B', viewValue: 'Entre 500.001 y 1.000.000'},
      {value: 'C', viewValue: 'Entre 1.000.001 y 3.000.000'},
      {value: 'D', viewValue: 'Más de 3.000.000'},
    ]
    return of (rrppLevels)
  }

  public denyList(): Observable<any>{
    const denyList = [
      'Ilegible',
      'Datos erroneos',
      'Documento no correspondiente',
      'Caducado',
      'Otros'
    ]
    return of (denyList)
  }
}