<!-- <div class="spinner-container">
  <div class="lds-facebook"><div></div><div></div><div></div></div>
  <p i18n="@@loading.spinner">Espere, por favor</p>
  </div> -->
  
  <div class="spinner-content">
      <img src="../../../../assets/logos/logoblack.png">
  <lottie-player src="../../../../assets/lottie/spinercirclesGreen.json" speed="1.4"  style="width: 150px; height: 150px;" loop background="transparent" autoplay></lottie-player>
  <p>Cargando...</p>
  </div>
  