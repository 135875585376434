import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerComponent } from 'src/app/shared/layout/spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private dialog: MatDialog) { }

  public showLoading() {
    this.dialog.open(SpinnerComponent, { disableClose: true, panelClass: 'background-spinner' });
  }

  public hideLoading() {
    this.dialog.closeAll();
  }
}
