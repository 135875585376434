import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, of, tap } from 'rxjs';
import { Documents } from 'src/app/core/models/documents/documents';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerComponent } from 'src/app/shared/layout/spinner/spinner.component';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input() fileType!: string;
  @Input() isForm?: boolean;
  @Input() isDocumentsComponent?: boolean;
  @Input() isReceipts?: boolean;

  @Input() documentData?: Documents;
  @Output() file: EventEmitter<string> = new EventEmitter();
  @Output() uploadedEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitButton: EventEmitter<boolean> = new EventEmitter();
  @Output() sendError: EventEmitter<boolean> = new EventEmitter();

  buttonText: string = 'Seleccionar Imagen';
  buttonUpload: string = 'Subir';
  buttonTextSecondary: string = 'Seleccionar Archivo';

  public preview!: string;
  public previewIsImage!: boolean;
  public upload: boolean = false;
  _uploading: boolean = false;
  public uploaded: boolean = false;
  public selectedFile?: any;
  public nameFile?: string;
  public userId!: string;

  public uploadForm!: FormGroup;

  public element!: string;

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private businessService: BusinessService,
    private dialog: MatDialog // Inyecta MatDialog
  ) {}

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId')!;
    this.uploadForm = this.formBuilder.group({
      element: ['']
    });
  }

  onSubmit() {
    // Abre el diálogo del spinner
    const dialogRef = this.dialog.open(SpinnerComponent, {
      disableClose: true,
      panelClass: 'spinner-dialog-container'
    });

    this.element = this.uploadForm.get('element')?.value;
    this.file.emit(this.element);

    const formData: FormData = new FormData();
    formData.append('files', this.selectedFile);
    if (this.documentData!.type == 'TIR') {
      formData.append('data', JSON.stringify({ partnerId: this.documentData!.partnerId!, type: this.documentData!.type }));
    } else {
      formData.append('data', JSON.stringify({ type: this.documentData!.type }));
    }

    this._uploading = true;
    this.buttonUpload = 'Subiendo';

    this.businessService.uploadDocument(this.userId!, formData).pipe(
      tap(() => {
        this.buttonUpload = 'Subido correctamente';
        this.uploaded = true;
        this.checkFiles();
        this.uploadedEvent.emit(true);
        // Cierra el diálogo del spinner después de que la carga del archivo esté completa
        dialogRef.close();
      }),
      catchError((error) => {
        console.log("este es el error", error);
        if (error.code === 400) {
          this.buttonUpload = 'ERROR';
          this.uploaded = false;
          this.sendError.emit(true);
        }
        // Cierra el diálogo del spinner en caso de error
        dialogRef.close();
        return of(null);
      })
    ).subscribe();
  }

  checkFiles() {
    this.uploadedEvent.emit({ uploaded: this.uploaded, type: this.fileType });
    this.emitButton.emit(true);
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.nameFile = this.selectedFile.name;
    this.convertFileBase64(this.selectedFile).then((img: any) => {
      this.preview = img.base;
      this.preview.includes('image') ? this.previewIsImage = true : this.previewIsImage = false;
    });
    this.upload = true;
    this.buttonText = 'Seleccionar otra imagen';
    this.buttonTextSecondary = 'Cambiar';
  }

  convertFileBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };
    } catch (e) {
      null;
    }
  });
}
