
export enum DOCUMENT_STATE {
    REQUIRED = 'REQUIRED',
    PENDING = 'PENDING',
    PENDING_SEFIDE = 'PENDING-SEFIDE',
    VERIFIED = 'VERIFIED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED'
}
export function getDocumentState(state: string) {
    let result = {
        classCss: '',
        viewValue: ''
    };

    switch (state) {
        case DOCUMENT_STATE.REQUIRED:
            result.classCss = 'document_statusRequired';
            result.viewValue = 'Requerido';
            break;
        case DOCUMENT_STATE.PENDING:
            result.classCss = 'document_statusPending';
            result.viewValue = 'Pendiente';
            break;
        case DOCUMENT_STATE.PENDING_SEFIDE:
            result.classCss = 'document_statusSf';
            result.viewValue = 'Pte. Sefide';
            break;
        case DOCUMENT_STATE.VERIFIED:
            result.classCss = 'document_statusOk';
            result.viewValue = 'Verificado';
            break;
        case DOCUMENT_STATE.REJECTED:
            result.classCss = 'document_statusKo';
            result.viewValue = 'Rechazado';
            break;
        case DOCUMENT_STATE.EXPIRED:
            result.classCss = 'document_statusExpired';
            result.viewValue = 'Caducado';
            break;
        default:
            break;
    }
    return result;
}