import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { allRegex } from 'src/app/core/extensions/regex';

@Component({
  selector: 'app-input-email',
/*   standalone: true,
  imports: [], */
  templateUrl: './input-email.component.html',
  styleUrl: './input-email.component.scss'
})
export class InputEmailComponent {
  @Input() ccMails: string[] = [];
  public ccSelected: FormControl = new FormControl('',[Validators.required,Validators.pattern(allRegex.regexPhoneAndEmail)]);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur: boolean = true;


  constructor(){}

  remove(mail: string): void {
    const index = this.ccMails.indexOf(mail);

    if (index >= 0) {
      this.ccMails.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && this.ccSelected.valid) {
      this.ccMails.push(value);
      event.chipInput!.clear();
      this.ccSelected.setValue(null);
      console.log(this.ccMails)
    } else {
      this.ccSelected.setErrors({invalidEmail: "Email no válido"})
    }
  }


}
