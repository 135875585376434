
<div class="background-alert">
<div class="email-template">
    <div class="top-content">
<div class="income">
    <p>De:</p>
    <input type="text" disabled value="info@denariusfinance.com">
</div>
<div class="income">
    <p>Para:</p>
    <input type="text" disabled value="{{userEmail}}"> 
</div>
</div>
<angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
<div class="buttons">
    <button class="main-filled-button">
        Enviar
    </button>
</div>
</div>
</div>
