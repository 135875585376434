<mat-form-field class="accounts" appearance="outline">
    <mat-select style="text-transform: uppercase" [value]="selectedSegment"
      (selectionChange)="OnSegmentChange($event.value)">
      <mat-option style="text-transform: uppercase" *ngFor="let segmentId of arraySegment" class="mat-option"
        [value]="segmentId">
        {{segmentId | segmentFormat}}
      </mat-option>
    </mat-select>
    <mat-label>Seleccionar por segmento</mat-label>
  </mat-form-field>
  
  