<div [ngClass]="{
    'ok_alert':alertStatus == 'ok',
    'ko_alert':alertStatus == 'ko',
    'warning_alert':alertStatus == 'warning',
    'info_alert':alertStatus == 'info'
    }"
    [@visibleHidden]=" isVisible? 'visible' : 'hidden'"
>
    <div class="alert_left">
        <mat-icon>{{icon}}</mat-icon>
        {{alertMessage}}
    </div>


    <button *ngIf="showBtnClose" mat-icon-button (click)="toggle()">
        <mat-icon >close</mat-icon>
    </button>
</div>