<div class="detail-container">
    <mat-dialog-actions class="closeDialog" align="end">
      <button mat-icon-button mat-dialog-close> <mat-icon> close </mat-icon></button>
      </mat-dialog-actions>
      <div class="accounts-data-container">
          <h3>Datos de la cuenta</h3>
          <table class="tableHTML">
            <tr *ngFor="let item of accountTable; let index = index" [ngClass]="index % 2 == 0 ? 'par' : 'impar'">
              <td class="table-title">{{item.title}}</td>
              <td class="table-value">{{item.value}}</td>
            </tr>
          </table>
      </div>
      <div class="titular-container">
          <h3 >Titularidad</h3>
          <p>{{userName}}</p>
      </div>
  </div>
  
  <div class="detail-mobile-container">
    <mat-dialog-actions class="closeDialog" align="end">
      <button mat-icon-button mat-dialog-close> <mat-icon> close </mat-icon></button>
      </mat-dialog-actions>
      <div class="accounts-mobile-data-container">
          <h3>Datos de la cuenta</h3>
          <div class="table-mobile-HTML">
            <div *ngFor="let item of accountTable; let index = index" >
              <p class="table-title">{{item.title}}</p>
              <p class="table-value">{{item.value}}</p>
            </div>
          </div>
      </div>
      <div class="titular-container">
          <h3 >Titularidad</h3>
          <p>{{userName}}</p>
      </div>
  </div>