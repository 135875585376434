<div class="personal-data-content">
  <section class="autoFill_section"  *ngIf="_isPartner || isAdministrator">
    <mat-checkbox class="example-margin"[(ngModel)]="autoFill" (ngModelChange)="autoFillRepresentative()">Rellenar con los datos del apoderado</mat-checkbox>
  </section>
  <form [formGroup]="personalData" class="personal-data-form">
    <div class="first">
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="!personalData.get('name')?.valid">Introduce un nombre</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Primer Apellido</mat-label>
        <input matInput formControlName="surname1" />
        <mat-error *ngIf="!personalData.get('surname1')?.valid">Introduce un apellido</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Segundo Apellido</mat-label>
        <input matInput formControlName="surname2"/>
      </mat-form-field>

      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Sexo</mat-label>
        <mat-select matInput formControlName="gender">
          <mat-option value="F">Femenino</mat-option>
          <mat-option value="M">Masculino</mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('gender')?.valid">Seleccione una opción</mat-error>
      </mat-form-field>
      <mat-form-field class="long_input" appearance="fill">
        <mat-label>Fecha de nacimiento</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="birthdate"
          [max]="startDate"
        >
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
        <mat-error *ngIf="!personalData.get('birthdate')?.valid"
        >Introduzca una fecha correcta DD/MM/YYYY</mat-error
      >
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Nacionalidad</mat-label>
        <mat-select name="nationality" formControlName="nationality">
          <mat-option *ngFor="let item of country" [value]="item.value">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('nationality')?.valid">Debe introducir una nacionalidad</mat-error>
      </mat-form-field>
      
        <mat-form-field appearance="fill" class="long_input">
          <mat-label>Tipo</mat-label>
          <mat-select matInput formControlName="documentType" (selectionChange)="idDocumentSelect()">
            <mat-option value="DNI">DNI</mat-option>
            <mat-option value="NIE">NIE</mat-option>
          </mat-select>
          <mat-error *ngIf="!personalData.get('documentType')?.valid">Seleccione un tipo</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="long_input">
          <mat-label>Número de {{personalData.get('documentType')?.value}}</mat-label>
          <input *ngIf="personalData.get('documentType')?.value === 'DNI'" matInput formControlName="identifier"/>
          <input *ngIf="personalData.get('documentType')?.value === 'NIE'" matInput formControlName="nie"/>
          <mat-error *ngIf="!personalData.get('{{formControlDNI}}')?.valid">Introduce un {{personalData.get('documentType')?.value}} válido</mat-error>
        </mat-form-field>
        
       <!--  <mat-form-field appearance="fill" class="short_input">
          <mat-label i18n="@@personal.area.documents">Número de NIE</mat-label>
          <input matInput formControlName="nie" />
          <mat-error
            *ngIf="!personalData.get('nie')?.valid"
            i18n="@@form.document.error"
            >Introduce un DNI/NIE válido</mat-error
          >
        </mat-form-field> -->
     
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>país de expedición del {{personalData.get('documentType')?.value}}</mat-label>
        <mat-select name="expeditionCountry" formControlName="expeditionCountry">
          <mat-option *ngFor="let item of country" [value]="item.value">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error  *ngIf="!personalData.get('expeditionCountry')?.valid">Debe seleccionar un país</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label >País de residencia</mat-label>
        <mat-select name="residenceCountry" formControlName="residenceCountry">
          <mat-option *ngFor="let item of country" [value]="item.value">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('residenceCountry')?.valid">Debe seleccionar un país</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email" />
        <mat-error *ngIf="!personalData.get('email')?.valid">Debe introducir un email correcto</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Prefijo</mat-label>
        <mat-select name="prefix" formControlName="prefix">
          <mat-option *ngFor="let item of prefix" [value]="item.prefix">
            {{ item.prefix }} ({{ item.country }})
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('prefix')?.valid">Debe introducir un prefijo</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input">
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="phone" />
        <mat-error *ngIf="!personalData.get('phone')?.valid">Debe introducir un teléfono correcto</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input" *ngIf="_isIndividual">
        <mat-label>Actividad</mat-label>
        <mat-select name="activity" formControlName="activity">
          <mat-option *ngFor="let item of cnoList" [value]="item.code">
             - {{ item.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('activity')?.valid">Debe rellenar la actividad</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input" *ngIf="_isIndividual">
        <mat-label>Nivel de ingresos</mat-label>
        <mat-select name="income" formControlName="income">
          <mat-option *ngFor="let item of incomeSelect" [value]="item.value">
             {{ item.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('income')?.valid">Debe introducir una cantidad</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="long_input" *ngIf="_isIndividual">
        <mat-label>Crea un PIN</mat-label>
        <input
          id="dni"
          type="password"
          matInput
          formControlName="password"
          maxlength="6"
        />
        <mat-error *ngIf="!personalData.get('password')?.valid">Debe contener 6 números</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="long_input"  *ngIf="_isIndividual">
        <mat-label>Confirma el PIN</mat-label>
        <input
          type="password"
          matInput
          formControlName="rePassword"
          maxlength="6"
        />
        <mat-error *ngIf="personalData.get('rePassword')?.hasError('rePasswordError')">El PIN debe coincidir</mat-error>
      </mat-form-field>
      <mat-form-field  appearance="fill" class="long_input" *ngIf="_isPartner">
        <mat-label>Porcentaje participación</mat-label>
        <input
        type="number"
        matInput
        formControlName="equity"
      />
      <mat-icon matSuffix>percent</mat-icon>
      <mat-error
      *ngIf="!personalData.get('equity')?.valid"
        >Debe introducir un porcentaje
      </mat-error>
      </mat-form-field>
      <div class="pepRole" *ngIf="_isRepresentative">
        <p>¿Es una Persona Expuesta Políticamente?</p>
        <mat-radio-group [(ngModel)]="hasPepRole" [ngModelOptions]="{standalone: true}" (change)="togglePepRole()">
          <mat-radio-button class="pepRoleItem" [value]="true">Sí</mat-radio-button>
          <mat-radio-button class="pepRoleItem" [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field appearance="fill" class="long_input" *ngIf="hasPepRole">
        <mat-label >País de ejercicio</mat-label>
        <mat-select name="pepCountry" formControlName="pepCountry">
          <mat-option *ngFor="let item of country" [value]="item.value">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('pepCountry')?.valid"
          >Debe introducir una nacionalidad</mat-error
        >
      </mat-form-field>
      
      <mat-form-field appearance="fill" class="long_input" *ngIf="hasPepRole">
        <mat-label>Cargo</mat-label>
        <mat-select name="activity" formControlName="pepRole">
          <mat-option *ngFor="let item of pepRoleList" [value]="item.code">
           - {{ item.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!personalData.get('pepRole')?.valid">
          Debe rellenar la actividad
        </mat-error>
      </mat-form-field>
    </div>
   
  </form>
  <button
  *ngIf=" _isPartner || isAdministrator"
  [disabled]="(!personalData.valid && !isAdministrator) || (isAdministrator && !autoFill && !personalData.valid)" 
  (click)="onSubmit()"
  class="main-filled-button"
>
  Guardar
</button>
  <!-- <div class="buttons_container" >
    <button
    *ngIf="dataLength >=1"
    mat-flat-button 
    color="warn"
    (click)="cancelRegistration()"
    class="nextStep_btn"
  >
    Cancelar
  </button>
    <button
    *ngIf=" _isPartner || isAdministrator"
    mat-flat-button
    [disabled]="!personalData.valid"
    (click)="onSubmit()"
    class="nextStep_btn"
  >
    Guardar
  </button>
    <button
    [hidden]="_isPartner || isAdministrator"
    mat-flat-button
    [disabled]="!personalData.valid"
    class="nextStep_btn"
  >
    Siguiente
  </button>
  </div> -->
 
</div>
