import { Component, Input, OnInit } from '@angular/core';
import { UserData } from 'src/app/core/models/user/userData';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Business } from 'src/app/core/models/user/businessData';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ShowStepperComponent } from './showStepperDialog/showStepperDialog.component';
@Component({
  selector: 'app-stepperBussines',
  templateUrl: './stepperBussines.component.html',
  styleUrls: ['./stepperBussines.component.scss'],
  standalone: true,
  imports: [
    MatStepperModule,
    CommonModule,
    MatIconModule
  ],
})
export class StepperBussinesComponent implements OnInit {
  @Input() businessData!: any;
  constructor(    public dialog: MatDialog,){}
  selectedIndex = 0;
  steps = ['Registro', 'Teléfono Representante Verificado', 'Teléfono Verificado', 'Documentos Representante Verificados' ,'Documentos Verificados', 'Contrato Firmado'];

  ngOnInit(): void {
    this.selectedIndex = this.businessData.state!.length - 1;
  }


  isStepCompleted(stepNumber: number){
    let stepIndex: number;
    switch (this.businessData.state) {
      case 'REGISTERED':
        stepIndex = 1
        break;
      case 'REPRESENTATIVE_PHONE_VERIFIED':
        stepIndex = 2
        break;
      case 'PHONE_VERIFIED':
        stepIndex = 3
        break;
      case 'REPRESENTATIVE_DOCUMENTS_VERIFIED':
        stepIndex = 4
        break;
      case 'DOCUMENTS_VERIFIED':
        stepIndex = 5
        break;
      case 'CONTRACT_SIGNED':
        stepIndex = 6
        break;
      default:
        stepIndex = 0
        break;
    }
    return stepIndex > stepNumber ;
    
  }

   statusList = [
    { statusTitle: '1º paso', explanation: 'El usuario ha sido registrado', statusClass: 'statusReg' },
    { statusTitle: '2º paso', explanation: 'El teléfono del representante ha sido verificado', statusClass: 'statustlfr' },
    { statusTitle: '3º paso', explanation: 'El teléfono del usuario ha sido verificado', statusClass: 'statustlfu' },
    { statusTitle: '4º paso', explanation: 'El documento del representante ha sido verificado', statusClass: 'statusdcr' },
    { statusTitle: '5º paso', explanation: 'El documento del usuario ha sido verificado', statusClass: 'statusdcu' },
    { statusTitle: '6º paso', explanation: 'Ha firmado el contrato y su cuenta está activa', statusClass: 'statusOk' },
  ];

  openDialog() {
    this.dialog.open(ShowStepperComponent, {
      data: { statusList: this.statusList }
    });
  }
}      