<div class="products_container">
  <div class="product_container">
    <h3 class="title_card">Plan Contratado</h3>
    <div class="data-plan" *ngIf="!loader">
      <p class="product_data">{{ userPlan.name }}</p>
      <p class="product_description">{{ userPlan.description }}</p>
      <p class="product_data">
        {{
          userPlan.monthlyPrice | currency : "€" : "symbol" : "1.2-2" : "es"
        }}/mes
      </p>
    </div>
    <div class="no-plan">
      <p>{{ msgNoPlan }}</p>
    </div>
    <div class="skeleton-container" *ngIf="loader">
      <ngx-skeleton-loader
        count="3"
        appearance="line"
        [theme]="{ height: '20px', width: '250px' }"
      ></ngx-skeleton-loader>
    </div>
    <div class="buttons_container">
      <button mat-stroked-button (click)="toogleChangePlan()" [disabled]="disabledChange">
        Cambiar plan
      </button>
      <button mat-stroked-button (click)="redirectToPlans()">Ver plan</button>
    </div>

    <mat-form-field
      class="accounts"
      appearance="outline"
      *ngIf="isVisibleChangePlan"
    >
      <mat-select
        style="text-transform: uppercase"
        [formControl]="planSelected"
        (selectionChange)="onSubmit()"
      >
        <mat-option
          style="text-transform: uppercase"
          *ngFor="let item of segmentPlans"
          class="mat-option"
          [value]="item"
        >
          {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-label>Contratar otro plan</mat-label>
    </mat-form-field>
  </div>

<!--   <div class="product_container">
    <h3 class="title_card">Productos Contratados</h3>
    <p class="product_data">LEASING Y RENTING</p>
    <div class="buttons_container">
      <button mat-stroked-button color="primary">Ver producto</button>
    </div>
  </div> -->
</div>
