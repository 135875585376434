<form *ngIf="documentData || fileType" [formGroup]="uploadForm" [ngClass]="{
  'form_container': !upload,
  'form_upload': upload && (!_uploading && !uploaded),
  'form_uploaded': _uploading || uploaded,
  'form_employment': isForm,
  'personal_doc': isDocumentsComponent,
  'receipts': isReceipts
}">
  <div class="img-preview" *ngIf="!isForm && upload && (!_uploading && !uploaded)">
    <img *ngIf="!isReceipts && previewIsImage" src="{{preview}}" alt="preview" />
    <mat-icon *ngIf="!isReceipts && !previewIsImage">picture_as_pdf</mat-icon>
    <p>{{nameFile}}</p>
  </div>

  <div class="animation_container" *ngIf="!isForm && !isDocumentsComponent && !isReceipts">
    <lottie-player *ngIf="_uploading && !uploaded" src="../../../../assets/lottie/Loading State.json" background="transparent" speed="1" style="width: 150px; height: 150px;" loop autoplay></lottie-player>
    <lottie-player *ngIf="uploaded" src="../../../../assets/lottie/successfullyCheck.json" background="transparent" speed="1" style="width: 150px; height: 150px;" autoplay id="animacion" #animacion loop></lottie-player>
  </div>

  <label for="element" *ngIf="!uploaded">
    <div class="button-container">
      <div class="custom-input-file">
        <mat-icon>file_upload</mat-icon>
        <input (change)="onFileSelected($event)" id="element" type="file"
          accept="image/*, application/pdf,application/vnd.ms-excel" formControlName="element" class="input-file">
        <p *ngIf="!isForm && !isDocumentsComponent">{{buttonText}}</p>
        <p *ngIf="isForm || isDocumentsComponent">{{buttonTextSecondary}}</p>
      </div>
    </div>
  </label>

  <!-- BOTÓN DE SUBIR -->
  <div *ngIf="upload">
    <button *ngIf="!uploaded" type="button" class="button-upload" (click)="onSubmit()">
      <p>{{buttonUpload}}</p>
      <lottie-player *ngIf="_uploading && !uploaded" src="../../../../assets/lottie/loadingPoint.json" background="transparent" speed="1" style="width: 20px; height: 20px;" loop autoplay></lottie-player>
    </button>
    <button *ngIf="uploaded" type="button" class="button-uploaded" disabled>
      <p>{{buttonUpload}}</p>
    </button>
  </div>
</form>

<!-- Template para el diálogo del spinner -->
<ng-template #spinnerDialog>
  <div class="spinner-container">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</ng-template>
