import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-failed-operations',
  templateUrl: './failed-operations.component.html',
  styleUrls: ['./failed-operations.component.scss']
})
export class FailedOperationsComponent implements OnChanges {
  @Input() showEdit!: Function;
  @Input() editField!: Function;
  @Input() hideEdit!: Function;
  @Input() kycInfo!: any[];
  public editedField: FormControl = new FormControl();
  public displayedColumns: string[] = ['fullName', 'operationType', 'identifier', 'createdAt', 'updatedAt'];
  public dataSource = new MatTableDataSource<any>([]);

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['kycInfo'] && changes['kycInfo'].currentValue) {
      this.dataSource.data = changes['kycInfo'].currentValue || [];
    }
  }


}
